import React from "react";
import {parentPracticeAreaSlug, productIdMappedWithSlug} from "./bannerTextForProducts";
import * as path from "../../constants/path";
import {parentPracticeArea} from "../../constants/parentPracticeArea";

export const allFaqText = {
    4: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#01accordian">
                <h4 className="panel-title">Documents required for Income Tax Filing
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="01accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>All Form 16</li>
                        <li>Bank Statements of all Bank Accounts</li>
                        <li>Investment Declaration Proof - LIC, PPF, Tax Saving FD etc.</li>
                        <li>Form 26AS</li>
                        <li>PAN & AADHAR CARD Copy</li>
                        <li>Demat Statement for Trading Income</li>
                        <li>Sale and Purchase Deed Details for Capital Gain Income</li>
                        <li>Profit & Loss Account and Balance Sheet for Business Income</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What is the Income Tax slab for FY 20-21?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p class="para">For this FY, there are two options available to the <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">taxpayers</a> to choose.</p>
                    <p className="text-bold">New Tax slab:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Total Income ($)</th>
                            <th>Tax Rate (%)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>&lt;2.5 lakh</td>
                            <td>0</td>
                        </tr>
                        <tr>
                            <td>2.50,000-5,00,000</td>
                            <td>5</td>
                        </tr>
                        <tr>
                            <td>5,00,001- 7,50,000</td>
                            <td>10</td>
                        </tr>
                        <tr>
                            <td>7,50,001-10,00,000</td>
                            <td>15</td>
                        </tr>
                        <tr>
                            <td>10,00,001-12,50,000</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td>12,50,001- 15,00,000</td>
                            <td>25</td>
                        </tr>
                        <tr>
                            <td>&gt;15,00,000</td>
                            <td>30</td>
                        </tr>
                        </tbody>
                    </table>
                    <p className="para">If one is following this new tax slab, one shall not be entitled to enjoy any of
                        the deduction benefits under the Income Tax Act 1961. However, who are of opinion that the
                        deductions were more beneficial, they can still follow the tax slab of the FY 2019-20 to pay
                        their tax.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">Old Tax regime or the new one, which should be followed? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Which one you want to follow, is totally dependent on your income and
                        investments you have made.</p>
                    <p className="text-bold">Let us explain to you with an example:-</p>
                    <p className="text-bold"><a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                target="_blank">Income tax</a> payable as per old regime:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Particulars</th>
                            <th>Amount ($)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Gross Salary</td>
                            <td>10,00,000</td>
                        </tr>
                        <tr>
                            <td>Standard Deduction</td>
                            <td>37700</td>
                        </tr>
                        <tr>
                            <td>Income under the head salary</td>
                            <td>962300</td>
                        </tr>
                        <tr>
                            <td>Interest on home loan</td>
                            <td>2,00,000</td>
                        </tr>
                        <tr>
                            <td>Net Income</td>
                            <td>762300</td>
                        </tr>
                        <tr>
                            <td>Deduction u/s 80C</td>
                            <td>-1,50,000</td>
                        </tr>
                        <tr>
                            <td>Health insurance deduction u/s 80D</td>
                            <td>-75000</td>
                        </tr>

                        <tr>
                            <td>Deduction u/s 80CCD for NPS contribution</td>
                            <td>-47000</td>
                        </tr>
                        <tr>
                            <td>Taxable Income</td>
                            <td>4,90,300</td>
                        </tr>
                        <tr>
                            <td>Rate of tax levied</td>
                            <td>0% for &lt;= 2,50,000 and 5% 2,50,001 to 5,00,000</td>
                        </tr>
                        <tr>
                            <td>Amount of tax</td>
                            <td>24515</td>
                        </tr>
                        <tr>
                            <td>Health and education cess 4%</td>
                            <td>980.6</td>
                        </tr>
                        <tr>
                            <td>Tax Total</td>
                            <td>25,131.6</td>
                        </tr>
                        <tr>
                            <td>Tax rebate u/s 87A</td>
                            <td>12,500</td>
                        </tr>
                        <tr>
                            <td>Tax payable</td>
                            <td>12,631.6</td>
                        </tr>

                        </tbody>
                    </table>
                    <p className="text-bold"><a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                target="_blank">Income tax</a> payable as per the new regime:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Income ($)</th>
                            <th>Tax rate(%)</th>
                            <th>Tax ($)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>2,50,000</td>
                            <td>0</td>
                            <td>0</td>
                        </tr>
                        <tr>
                            <td>2,50,001- 5,00,000</td>
                            <td>5</td>
                            <td>12,500</td>
                        </tr>
                        <tr>
                            <td>5,00,001-7,50,000</td>
                            <td>10</td>
                            <td>25,000</td>
                        </tr>
                        <tr>
                            <td>7,50,001- 10,00,000</td>
                            <td>15</td>
                            <td>37,500</td>
                        </tr>
                        <tr>
                            <td>Total Tax</td>
                            <td></td>
                            <td>75,000</td>
                        </tr>
                        <tr>
                            <td>Health and education cess</td>
                            <td>4</td>
                            <td>3000</td>
                        </tr>
                        <tr>
                            <td>Tax Payable</td>
                            <td></td>
                            <td>78000</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">Benefits of Filing ITR even if you don’t have taxable income?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">There are then <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">benefits
                        if you file for ITR</a> under NIL:</p>
                    <ul>
                        <li>ITR receipt as an important document: For several financial, and Government jobs, the
                            receipt of ITR is considered as an important document.
                        </li>
                        <li>Address Proof: ITR receipt can be used as valid address proof for yourself.</li>
                        <li>Useful for Bank documentation: For any loan or investment purpose, ITR receipt is a document
                            on the priority list.
                        </li>
                        <li>Be ready for next FY: If you do not file for ITR, it is not possible to get the losses in
                            the previous FY. Therefore, If you face any loss in one FY, but have filed your ITR on time,
                            you can get it compensated in the next FY at the time of filing the ITR.
                        </li>
                        <li>Avoid Any Extra Charges: If you fail to file ITR, it may lead to a higher rate of interest
                            to be deducted. For example, if you do not file for ITR, then a certain amount may get
                            deducted from the interest amount you receive from your investment and FD.
                        </li>
                        <li>Avoid penalties: Missing out to file ITR may cost you a huge amount of penalties if you try
                            to file ITR post the assigned last date.
                        </li>
                        <li>Credit Card Processing: Even for proceeding with the credit card application or increase the
                            limit of the existing one, you have to furnish with the ITR documents.
                        </li>
                        <li>Visa Application: Along with the application form, you need to submit the document of ITR
                            return of the immediate past 3 years.
                        </li>
                        <li>Insurance Policy with a Higher Cover: If you want to buy an insurance policy with a high
                            cover, you need to submit the last filed ITR document, for smooth transactional proceedings.
                        </li>
                        <li>Freelancer and independent professionals: These professionals do not have a form 16 with
                            them, therefore the only document they are left with is the receipt of ITR as evidence of
                            their annual income.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">Procedure to file ITR yourself?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">For the procedure of <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">filing
                        the ITR</a> yourself, follow this official website of the Indian Tax Authority - <a
                        href="https://www.incometaxindiaefiling.gov.in/home"
                        target="_blank">https://www.incometaxindiaefiling.gov.in/home</a></p>
                    <p className="para">However, if you want professional assistance, hire your tax expert just in one
                        click.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">Is NIL ITR mandatory to be filed?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Definitely, <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">filing
                        NIL ITR</a> is important, and to know the reasons, please refer to question no. 3</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">What are the due dates for Income Tax return filing?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The <a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                               target="_blank">due date for ITR filing</a> this year is yet to be
                        declared.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">What is the penalty for a delay in filing ITR?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The deadline to <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file an
                        income tax return (ITR)</a> had been extended to August 31, 2019, for FY 2018-19. If you file
                        your return after midnight tonight then you would have to pay a late fee of up to Rs 10,000.
                        However, if your income is below the taxable limit then you won't have to pay it even if you
                        file after the deadline. Here's why:</p>
                    <p className="text-bold">The penalty you will have to pay</p>
                    <p className="para">An income taxpayer is liable to pay late ITR filing fees of:</p>
                    <ul>
                        <li>Rs 5,000 if the tax return is filed after the deadline but on or before December 31 of the
                            relevant assessment year (in this case December 31, 2019).
                        </li>
                        <li>Rs 10,000 if tax return is filed after December 31 but before the end of the relevant
                            assessment year, i.e., before March 31 (in this case between 1 January 2020 and March 31,
                            2020).
                        </li>
                    </ul>
                    <p className="text-bold">If you are a small taxpayer whose gross total income does not exceed Rs 5
                        lakh then the maximum fees you are liable to pay is Rs 1,000.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">Do I need to file a return if there is a loss in my business income?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">When your business is suffering from a loss, you must <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file the
                        ITR</a>, as the loss you suffer makes you eligible to get a tax exemption, in order to get the
                        loss amount compensated to a certain level.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#10accordian">
                <h4 className="panel-title">Can I file ITR once the last date of filing is gone?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="10accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, you can <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file the
                        ITR</a> post the last date of filing, but later you file, higher the penalty amount you end up
                        paying.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#11accordian">
                <h4 className="panel-title">Is it possible to revise the ITR even when the acknowledgment is generated?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="11accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">You can <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file a
                        revised return</a> correcting the error to avoid any penalty. Income tax laws allow taxpayers an
                        opportunity to correct the error or omission in their returns by submitting a revised tax return
                        under Section 139(5) of the IT Act.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#12accordian">
                <h4 className="panel-title">Can I file ITR for FY 18-19 and FY 17-18?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="12accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, you can <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file the
                        ITR</a> for FY 18-19 but with a huge penalty.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#13accordian">
                <h4 className="panel-title">What is form 16?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="13accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Form 16 is an <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">Income
                        tax</a> form. Form 16 is a certificate provided by your employer which certifies details
                        regarding the salary you have earned during the year and how much TDS has been deducted. It
                        consists of two parts i.e. Part A and Part B, where part A consists of information of the
                        employer & employee, like the PAN and TAN details, name and address, TDS deducted & deposited,
                        etc.</p>
                    <p className="para">It is used by the companies to provide their salaried individuals information on
                        the tax deducted. Alternatively, you can consider Form 16 as your Salary TDS certificate.</p>
                    <p className="para">Suppose the income from your salary for the financial year is more than the
                        basic exemption limit of 2,50,000. Then, in this case, your employer is required to deduct
                        TDS on your salary and deposit it with the government. When From 16 is provided to an employee
                        by their employer, it is considered as a source of proof of filing their Income Tax Returns. And
                        if your income does not fit the basic exemption limit, the employer does not deduct any TDS in
                        that case.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#14accordian">
                <h4 className="panel-title">What is form 26AS? How to get form 26AS?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="14accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Form 26AS is an annual consolidation credit statement issued under section 203AA
                        of the <a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                  target="_blank">Income Tax</a> 1961 which mentions all the tax deductions by the
                        employer, banks or someone else as defined under the Act.</p>
                    <p className="para">To download Form 26AS you can log into the income tax filing account on the
                        Income Tax Departments e filing <a href="https://www.incometaxindiaefiling.gov.in/home"
                                                           target="_blank">website</a> either directly or through the
                        Net Banking facility of authorised banks.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#15accordian">
                <h4 className="panel-title">When is a refund issued by the Income Tax Department?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="15accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The refund is usually issued within 120 days of <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">filing
                        the ITR</a>.</p>
                    <p className="para">To download Form 26AS you can log into the income tax filing account on the
                        Income Tax Departments e filing <a href="https://www.incometaxindiaefiling.gov.in/home"
                                                           target="_blank">website</a> either directly or through the
                        Net Banking facility of authorised banks.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#16accordian">
                <h4 className="panel-title">Income Tax Filing for Freelancers or professionals - Forms, Sections and
                    Exemptions? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="16accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <p className="text-bold"><a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                target="_blank">Filing ITR</a> for freelancer:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Forms</th>
                            <th>Sections</th>
                            <th className="col-xs-8">Exemptions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Form ITR 4</td>
                            <td>Sec. 44AB</td>
                            <td>
                                <ul>
                                    <li>Sec. 80C - Deduction u to INR 1.5 lakhs for payments made towards the life
                                        insurance policies, PF, superannuation, tuition fees, construction/purchase of
                                        residential property FD etc.
                                    </li>
                                    <li>Sec. 80 CCC - Tax Deductions for investment made towards the pension plans upto
                                        INR 1.5 lakhs.
                                    </li>
                                    <li>Sec. 80 CCD - Exemptions towards investments made in the Central Government
                                        Pension Scheme. Both the employer and employee contribution are exempted.
                                    </li>
                                    <li>Sec 80CCF- The exemptions are provided for investments made in the
                                        infrastructure bonds (long term) that are notified by the Government of India.
                                        The section offers a maximum exemption of Rs 20,000
                                    </li>
                                    <li>Sec 80CCG- The section provides a maximum deduction of Rs 25,000 for the
                                        investments made in the government Equity Saving Schemes, to certain specified
                                        Indian citizens and residents.
                                    </li>
                                    <li>SEC. 80D- Under this section, expenses made towards the payment of premiums of
                                        the health insurance policies are exempted. The freelancer can also buy the
                                        policy for spouse or child, and claim the deductions
                                    </li>
                                    <li>Sec. 80DD- The section provides deductions towards treatment of normal and
                                        severe disabilities, which may go up Rs 1.25 lakhs
                                    </li>
                                    <li>Sec. 80DDB- Exemptions towards treatment of certain specified diseases</li>
                                    <li>Sec. 80E- Deductions towards loan taken for education purposes</li>
                                    <li>Sec. 80 EE- The Section is exclusively for individuals, and exempts the payments
                                        made towards a loan, for buying a property for residential purposes
                                    </li>
                                    <li>Sec. 80G- The Section offers up to a 100% deduction for the donations made to
                                        the charitable funds, including the Prime Minister Relief Fund, and the National
                                        Defense Fund among others
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>],
    5: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#01accordian">
                <h4 className="panel-title">Documents required for Income Tax Filing
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="01accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>All Form 16</li>
                        <li>Bank Statements of all Bank Accounts</li>
                        <li>Investment Declaration Proof - LIC, PPF, Tax Saving FD etc.</li>
                        <li>Form 26AS</li>
                        <li>PAN & AADHAR CARD Copy</li>
                        <li>Demat Statement for Trading Income</li>
                        <li>Sale and Purchase Deed Details for Capital Gain Income</li>
                        <li>Profit & Loss Account and Balance Sheet for Business Income</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What is the Income Tax slab for FY 20-21?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <p class="para">For this FY, there are two options available to the <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">taxpayers</a> to choose.</p>
                    <p className="text-bold">New Tax slab:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Total Income ($)</th>
                            <th>Tax Rate (%)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>&lt;2.5 lakh</td>
                            <td>0</td>
                        </tr>
                        <tr>
                            <td>2.50,000-5,00,000</td>
                            <td>5</td>
                        </tr>
                        <tr>
                            <td>5,00,001- 7,50,000</td>
                            <td>10</td>
                        </tr>
                        <tr>
                            <td>7,50,001-10,00,000</td>
                            <td>15</td>
                        </tr>
                        <tr>
                            <td>10,00,001-12,50,000</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td>12,50,001- 15,00,000</td>
                            <td>25</td>
                        </tr>
                        <tr>
                            <td>&gt;15,00,000</td>
                            <td>30</td>
                        </tr>
                        </tbody>
                    </table>
                    <p className="para">If one is following this new tax slab, one shall not be entitled to enjoy any of
                        the deduction benefits under the Income Tax Act 1961. However, who are of opinion that the
                        deductions were more beneficial, they can still follow the tax slab of the FY 2019-20 to pay
                        their tax.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">Old Tax regime or the new one, which should be followed? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <p className="para">Which one you want to follow, is totally dependent on your income and
                        investments you have made.</p>
                    <p className="text-bold">Let us explain to you with an example:-</p>
                    <p className="text-bold"><a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                target="_blank">Income tax</a> payable as per old regime:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Particulars</th>
                            <th>Amount ($)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Gross Salary</td>
                            <td>10,00,000</td>
                        </tr>
                        <tr>
                            <td>Standard Deduction</td>
                            <td>37700</td>
                        </tr>
                        <tr>
                            <td>Income under the head salary</td>
                            <td>962300</td>
                        </tr>
                        <tr>
                            <td>Interest on home loan</td>
                            <td>2,00,000</td>
                        </tr>
                        <tr>
                            <td>Net Income</td>
                            <td>762300</td>
                        </tr>
                        <tr>
                            <td>Deduction u/s 80C</td>
                            <td>-1,50,000</td>
                        </tr>
                        <tr>
                            <td>Health insurance deduction u/s 80D</td>
                            <td>-75000</td>
                        </tr>

                        <tr>
                            <td>Deduction u/s 80CCD for NPS contribution</td>
                            <td>-47000</td>
                        </tr>
                        <tr>
                            <td>Taxable Income</td>
                            <td>4,90,300</td>
                        </tr>
                        <tr>
                            <td>Rate of tax levied</td>
                            <td>0% for &lt;= 2,50,000 and 5% 2,50,001 to 5,00,000</td>
                        </tr>
                        <tr>
                            <td>Amount of tax</td>
                            <td>24515</td>
                        </tr>
                        <tr>
                            <td>Health and education cess 4%</td>
                            <td>980.6</td>
                        </tr>
                        <tr>
                            <td>Tax Total</td>
                            <td>25,131.6</td>
                        </tr>
                        <tr>
                            <td>Tax rebate u/s 87A</td>
                            <td>12,500</td>
                        </tr>
                        <tr>
                            <td>Tax payable</td>
                            <td>12,631.6</td>
                        </tr>

                        </tbody>
                    </table>
                    <p className="text-bold"><a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                target="_blank">Income tax</a> payable as per the new regime:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Income ($)</th>
                            <th>Tax rate(%)</th>
                            <th>Tax ($)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>2,50,000</td>
                            <td>0</td>
                            <td>0</td>
                        </tr>
                        <tr>
                            <td>2,50,001- 5,00,000</td>
                            <td>5</td>
                            <td>12,500</td>
                        </tr>
                        <tr>
                            <td>5,00,001-7,50,000</td>
                            <td>10</td>
                            <td>25,000</td>
                        </tr>
                        <tr>
                            <td>7,50,001- 10,00,000</td>
                            <td>15</td>
                            <td>37,500</td>
                        </tr>
                        <tr>
                            <td>Total Tax</td>
                            <td></td>
                            <td>75,000</td>
                        </tr>
                        <tr>
                            <td>Health and education cess</td>
                            <td>4</td>
                            <td>3000</td>
                        </tr>
                        <tr>
                            <td>Tax Payable</td>
                            <td></td>
                            <td>78000</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">Benefits of Filing ITR even if you don’t have taxable income?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">There are then <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">benefits
                        if you file for ITR</a> under NIL:</p>
                    <ul>
                        <li>ITR receipt as an important document: For several financial, and Government jobs, the
                            receipt of ITR is considered as an important document.
                        </li>
                        <li>Address Proof: ITR receipt can be used as valid address proof for yourself.</li>
                        <li>Useful for Bank documentation: For any loan or investment purpose, ITR receipt is a document
                            on the priority list.
                        </li>
                        <li>Be ready for next FY: If you do not file for ITR, it is not possible to get the losses in
                            the previous FY. Therefore, If you face any loss in one FY, but have filed your ITR on time,
                            you can get it compensated in the next FY at the time of filing the ITR.
                        </li>
                        <li>Avoid Any Extra Charges: If you fail to file ITR, it may lead to a higher rate of interest
                            to be deducted. For example, if you do not file for ITR, then a certain amount may get
                            deducted from the interest amount you receive from your investment and FD.
                        </li>
                        <li>Avoid penalties: Missing out to file ITR may cost you a huge amount of penalties if you try
                            to file ITR post the assigned last date.
                        </li>
                        <li>Credit Card Processing: Even for proceeding with the credit card application or increase the
                            limit of the existing one, you have to furnish with the ITR documents.
                        </li>
                        <li>Visa Application: Along with the application form, you need to submit the document of ITR
                            return of the immediate past 3 years.
                        </li>
                        <li>Insurance Policy with a Higher Cover: If you want to buy an insurance policy with a high
                            cover, you need to submit the last filed ITR document, for smooth transactional proceedings.
                        </li>
                        <li>Freelancer and independent professionals: These professionals do not have a form 16 with
                            them, therefore the only document they are left with is the receipt of ITR as evidence of
                            their annual income.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">Procedure to file ITR yourself?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">For the procedure of <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">filing
                        the ITR</a> yourself, follow this official website of the Indian Tax Authority - <a
                        href="https://www.incometaxindiaefiling.gov.in/home"
                        target="_blank">https://www.incometaxindiaefiling.gov.in/home</a></p>
                    <p className="para">However, if you want professional assistance, hire your tax expert just in one
                        click.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">Is NIL ITR mandatory to be filed?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Definitely, <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">filing
                        NIL ITR</a> is important, and to know the reasons, please refer to question no. 3</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">What are the due dates for Income Tax return filing?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The <a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                               target="_blank">due date for ITR filing</a> this year is yet to be
                        declared.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">What is the penalty for a delay in filing ITR?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The deadline to <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file an
                        income tax return (ITR)</a> had been extended to August 31, 2019, for FY 2018-19. If you file
                        your return after midnight tonight then you would have to pay a late fee of up to Rs 10,000.
                        However, if your income is below the taxable limit then you won't have to pay it even if you
                        file after the deadline. Here's why:</p>
                    <p className="text-bold">The penalty you will have to pay</p>
                    <p className="para">An income taxpayer is liable to pay late ITR filing fees of:</p>
                    <ul>
                        <li>Rs 5,000 if the tax return is filed after the deadline but on or before December 31 of the
                            relevant assessment year (in this case December 31, 2019).
                        </li>
                        <li>Rs 10,000 if tax return is filed after December 31 but before the end of the relevant
                            assessment year, i.e., before March 31 (in this case between 1 January 2020 and March 31,
                            2020).
                        </li>
                    </ul>
                    <p className="text-bold">If you are a small taxpayer whose gross total income does not exceed Rs 5
                        lakh then the maximum fees you are liable to pay is Rs 1,000.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">Do I need to file a return if there is a loss in my business income?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">When your business is suffering from a loss, you must <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file the
                        ITR</a>, as the loss you suffer makes you eligible to get a tax exemption, in order to get the
                        loss amount compensated to a certain level.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#10accordian">
                <h4 className="panel-title">Can I file ITR once the last date of filing is gone?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="10accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, you can <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file the
                        ITR</a> post the last date of filing, but later you file, higher the penalty amount you end up
                        paying.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#11accordian">
                <h4 className="panel-title">Is it possible to revise the ITR even when the acknowledgment is generated?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="11accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">You can <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file a
                        revised return</a> correcting the error to avoid any penalty. Income tax laws allow taxpayers an
                        opportunity to correct the error or omission in their returns by submitting a revised tax return
                        under Section 139(5) of the IT Act.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#12accordian">
                <h4 className="panel-title">Can I file ITR for FY 18-19 and FY 17-18?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="12accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, you can <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file the
                        ITR</a> for FY 18-19 but with a huge penalty.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#13accordian">
                <h4 className="panel-title">What is form 16?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="13accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Form 16 is an <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">Income
                        tax</a> form. Form 16 is a certificate provided by your employer which certifies details
                        regarding the salary you have earned during the year and how much TDS has been deducted. It
                        consists of two parts i.e. Part A and Part B, where part A consists of information of the
                        employer & employee, like the PAN and TAN details, name and address, TDS deducted & deposited,
                        etc.</p>
                    <p className="para">It is used by the companies to provide their salaried individuals information on
                        the tax deducted. Alternatively, you can consider Form 16 as your Salary TDS certificate.</p>
                    <p className="para">Suppose the income from your salary for the financial year is more than the
                        basic exemption limit of 2,50,000. Then, in this case, your employer is required to deduct
                        TDS on your salary and deposit it with the government. When From 16 is provided to an employee
                        by their employer, it is considered as a source of proof of filing their Income Tax Returns. And
                        if your income does not fit the basic exemption limit, the employer does not deduct any TDS in
                        that case.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#14accordian">
                <h4 className="panel-title">What is form 26AS? How to get form 26AS?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="14accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Form 26AS is an annual consolidation credit statement issued under section 203AA
                        of the <a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                  target="_blank">Income Tax</a> 1961 which mentions all the tax deductions by the
                        employer, banks or someone else as defined under the Act.</p>
                    <p className="para">To download Form 26AS you can log into the income tax filing account on the
                        Income Tax Departments e filing <a href="https://www.incometaxindiaefiling.gov.in/home"
                                                           target="_blank">website</a> either directly or through the
                        Net Banking facility of authorised banks.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#15accordian">
                <h4 className="panel-title">When is a refund issued by the Income Tax Department?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="15accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The refund is usually issued within 120 days of <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">filing
                        the ITR</a>.</p>
                    <p className="para">To download Form 26AS you can log into the income tax filing account on the
                        Income Tax Departments e filing <a href="https://www.incometaxindiaefiling.gov.in/home"
                                                           target="_blank">website</a> either directly or through the
                        Net Banking facility of authorised banks.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#16accordian">
                <h4 className="panel-title">Income Tax Filing for Freelancers or professionals - Forms, Sections and
                    Exemptions? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="16accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <p className="text-bold"><a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                target="_blank">Filing ITR</a> for freelancer:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Forms</th>
                            <th>Sections</th>
                            <th className="col-xs-8">Exemptions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Form ITR 4</td>
                            <td>Sec. 44AB</td>
                            <td>
                                <ul>
                                    <li>Sec. 80C - Deduction u to INR 1.5 lakhs for payments made towards the life
                                        insurance policies, PF, superannuation, tuition fees, construction/purchase of
                                        residential property FD etc.
                                    </li>
                                    <li>Sec. 80 CCC - Tax Deductions for investment made towards the pension plans upto
                                        INR 1.5 lakhs.
                                    </li>
                                    <li>Sec. 80 CCD - Exemptions towards investments made in the Central Government
                                        Pension Scheme. Both the employer and employee contribution are exempted.
                                    </li>
                                    <li>Sec 80CCF- The exemptions are provided for investments made in the
                                        infrastructure bonds (long term) that are notified by the Government of India.
                                        The section offers a maximum exemption of Rs 20,000
                                    </li>
                                    <li>Sec 80CCG- The section provides a maximum deduction of Rs 25,000 for the
                                        investments made in the government Equity Saving Schemes, to certain specified
                                        Indian citizens and residents.
                                    </li>
                                    <li>SEC. 80D- Under this section, expenses made towards the payment of premiums of
                                        the health insurance policies are exempted. The freelancer can also buy the
                                        policy for spouse or child, and claim the deductions
                                    </li>
                                    <li>Sec. 80DD- The section provides deductions towards treatment of normal and
                                        severe disabilities, which may go up Rs 1.25 lakhs
                                    </li>
                                    <li>Sec. 80DDB- Exemptions towards treatment of certain specified diseases</li>
                                    <li>Sec. 80E- Deductions towards loan taken for education purposes</li>
                                    <li>Sec. 80 EE- The Section is exclusively for individuals, and exempts the payments
                                        made towards a loan, for buying a property for residential purposes
                                    </li>
                                    <li>Sec. 80G- The Section offers up to a 100% deduction for the donations made to
                                        the charitable funds, including the Prime Minister Relief Fund, and the National
                                        Defense Fund among others
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>],
    8: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#01accordian">
                <h4 className="panel-title">Documents required for Income Tax Filing
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="01accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>All Form 16</li>
                        <li>Bank Statements of all Bank Accounts</li>
                        <li>Investment Declaration Proof - LIC, PPF, Tax Saving FD etc.</li>
                        <li>Form 26AS</li>
                        <li>PAN & AADHAR CARD Copy</li>
                        <li>Demat Statement for Trading Income</li>
                        <li>Sale and Purchase Deed Details for Capital Gain Income</li>
                        <li>Profit & Loss Account and Balance Sheet for Business Income</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What is the Income Tax slab for FY 20-21?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <p class="para">For this FY, there are two options available to the <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">taxpayers</a> to choose.</p>
                    <p className="text-bold">New Tax slab:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Total Income ($)</th>
                            <th>Tax Rate (%)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>&lt;2.5 lakh</td>
                            <td>0</td>
                        </tr>
                        <tr>
                            <td>2.50,000-5,00,000</td>
                            <td>5</td>
                        </tr>
                        <tr>
                            <td>5,00,001- 7,50,000</td>
                            <td>10</td>
                        </tr>
                        <tr>
                            <td>7,50,001-10,00,000</td>
                            <td>15</td>
                        </tr>
                        <tr>
                            <td>10,00,001-12,50,000</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td>12,50,001- 15,00,000</td>
                            <td>25</td>
                        </tr>
                        <tr>
                            <td>&gt;15,00,000</td>
                            <td>30</td>
                        </tr>
                        </tbody>
                    </table>
                    <p className="para">If one is following this new tax slab, one shall not be entitled to enjoy any of
                        the deduction benefits under the Income Tax Act 1961. However, who are of opinion that the
                        deductions were more beneficial, they can still follow the tax slab of the FY 2019-20 to pay
                        their tax.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">Old Tax regime or the new one, which should be followed? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <p className="para">Which one you want to follow, is totally dependent on your income and
                        investments you have made.</p>
                    <p className="text-bold">Let us explain to you with an example:-</p>
                    <p className="text-bold"><a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                target="_blank">Income tax</a> payable as per old regime:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Particulars</th>
                            <th>Amount ($)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Gross Salary</td>
                            <td>10,00,000</td>
                        </tr>
                        <tr>
                            <td>Standard Deduction</td>
                            <td>37700</td>
                        </tr>
                        <tr>
                            <td>Income under the head salary</td>
                            <td>962300</td>
                        </tr>
                        <tr>
                            <td>Interest on home loan</td>
                            <td>2,00,000</td>
                        </tr>
                        <tr>
                            <td>Net Income</td>
                            <td>762300</td>
                        </tr>
                        <tr>
                            <td>Deduction u/s 80C</td>
                            <td>-1,50,000</td>
                        </tr>
                        <tr>
                            <td>Health insurance deduction u/s 80D</td>
                            <td>-75000</td>
                        </tr>

                        <tr>
                            <td>Deduction u/s 80CCD for NPS contribution</td>
                            <td>-47000</td>
                        </tr>
                        <tr>
                            <td>Taxable Income</td>
                            <td>4,90,300</td>
                        </tr>
                        <tr>
                            <td>Rate of tax levied</td>
                            <td>0% for &lt;= 2,50,000 and 5% 2,50,001 to 5,00,000</td>
                        </tr>
                        <tr>
                            <td>Amount of tax</td>
                            <td>24515</td>
                        </tr>
                        <tr>
                            <td>Health and education cess 4%</td>
                            <td>980.6</td>
                        </tr>
                        <tr>
                            <td>Tax Total</td>
                            <td>25,131.6</td>
                        </tr>
                        <tr>
                            <td>Tax rebate u/s 87A</td>
                            <td>12,500</td>
                        </tr>
                        <tr>
                            <td>Tax payable</td>
                            <td>12,631.6</td>
                        </tr>

                        </tbody>
                    </table>
                    <p className="text-bold"><a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                target="_blank">Income tax</a> payable as per the new regime:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Income ($)</th>
                            <th>Tax rate(%)</th>
                            <th>Tax ($)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>2,50,000</td>
                            <td>0</td>
                            <td>0</td>
                        </tr>
                        <tr>
                            <td>2,50,001- 5,00,000</td>
                            <td>5</td>
                            <td>12,500</td>
                        </tr>
                        <tr>
                            <td>5,00,001-7,50,000</td>
                            <td>10</td>
                            <td>25,000</td>
                        </tr>
                        <tr>
                            <td>7,50,001- 10,00,000</td>
                            <td>15</td>
                            <td>37,500</td>
                        </tr>
                        <tr>
                            <td>Total Tax</td>
                            <td></td>
                            <td>75,000</td>
                        </tr>
                        <tr>
                            <td>Health and education cess</td>
                            <td>4</td>
                            <td>3000</td>
                        </tr>
                        <tr>
                            <td>Tax Payable</td>
                            <td></td>
                            <td>78000</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">Benefits of Filing ITR even if you don’t have taxable income?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">There are then <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">benefits
                        if you file for ITR</a> under NIL:</p>
                    <ul>
                        <li>ITR receipt as an important document: For several financial, and Government jobs, the
                            receipt of ITR is considered as an important document.
                        </li>
                        <li>Address Proof: ITR receipt can be used as valid address proof for yourself.</li>
                        <li>Useful for Bank documentation: For any loan or investment purpose, ITR receipt is a document
                            on the priority list.
                        </li>
                        <li>Be ready for next FY: If you do not file for ITR, it is not possible to get the losses in
                            the previous FY. Therefore, If you face any loss in one FY, but have filed your ITR on time,
                            you can get it compensated in the next FY at the time of filing the ITR.
                        </li>
                        <li>Avoid Any Extra Charges: If you fail to file ITR, it may lead to a higher rate of interest
                            to be deducted. For example, if you do not file for ITR, then a certain amount may get
                            deducted from the interest amount you receive from your investment and FD.
                        </li>
                        <li>Avoid penalties: Missing out to file ITR may cost you a huge amount of penalties if you try
                            to file ITR post the assigned last date.
                        </li>
                        <li>Credit Card Processing: Even for proceeding with the credit card application or increase the
                            limit of the existing one, you have to furnish with the ITR documents.
                        </li>
                        <li>Visa Application: Along with the application form, you need to submit the document of ITR
                            return of the immediate past 3 years.
                        </li>
                        <li>Insurance Policy with a Higher Cover: If you want to buy an insurance policy with a high
                            cover, you need to submit the last filed ITR document, for smooth transactional proceedings.
                        </li>
                        <li>Freelancer and independent professionals: These professionals do not have a form 16 with
                            them, therefore the only document they are left with is the receipt of ITR as evidence of
                            their annual income.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">Procedure to file ITR yourself?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">For the procedure of <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">filing
                        the ITR</a> yourself, follow this official website of the Indian Tax Authority - <a
                        href="https://www.incometaxindiaefiling.gov.in/home"
                        target="_blank">https://www.incometaxindiaefiling.gov.in/home</a></p>
                    <p className="para">However, if you want professional assistance, hire your tax expert just in one
                        click.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">Is NIL ITR mandatory to be filed?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Definitely, <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">filing
                        NIL ITR</a> is important, and to know the reasons, please refer to question no. 3</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">What are the due dates for Income Tax return filing?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The <a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                               target="_blank">due date for ITR filing</a> this year is yet to be
                        declared.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">What is the penalty for a delay in filing ITR?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The deadline to <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file an
                        income tax return (ITR)</a> had been extended to August 31, 2019, for FY 2018-19. If you file
                        your return after midnight tonight then you would have to pay a late fee of up to Rs 10,000.
                        However, if your income is below the taxable limit then you won't have to pay it even if you
                        file after the deadline. Here's why:</p>
                    <p className="text-bold">The penalty you will have to pay</p>
                    <p className="para">An income taxpayer is liable to pay late ITR filing fees of:</p>
                    <ul>
                        <li>Rs 5,000 if the tax return is filed after the deadline but on or before December 31 of the
                            relevant assessment year (in this case December 31, 2019).
                        </li>
                        <li>Rs 10,000 if tax return is filed after December 31 but before the end of the relevant
                            assessment year, i.e., before March 31 (in this case between 1 January 2020 and March 31,
                            2020).
                        </li>
                    </ul>
                    <p className="text-bold">If you are a small taxpayer whose gross total income does not exceed Rs 5
                        lakh then the maximum fees you are liable to pay is Rs 1,000.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">Do I need to file a return if there is a loss in my business income?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">When your business is suffering from a loss, you must <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file the
                        ITR</a>, as the loss you suffer makes you eligible to get a tax exemption, in order to get the
                        loss amount compensated to a certain level.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#10accordian">
                <h4 className="panel-title">Can I file ITR once the last date of filing is gone?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="10accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, you can <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file the
                        ITR</a> post the last date of filing, but later you file, higher the penalty amount you end up
                        paying.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#11accordian">
                <h4 className="panel-title">Is it possible to revise the ITR even when the acknowledgment is generated?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="11accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">You can <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file a
                        revised return</a> correcting the error to avoid any penalty. Income tax laws allow taxpayers an
                        opportunity to correct the error or omission in their returns by submitting a revised tax return
                        under Section 139(5) of the IT Act.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#12accordian">
                <h4 className="panel-title">Can I file ITR for FY 18-19 and FY 17-18?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="12accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, you can <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file the
                        ITR</a> for FY 18-19 but with a huge penalty.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#13accordian">
                <h4 className="panel-title">What is form 16?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="13accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Form 16 is an <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">Income
                        tax</a> form. Form 16 is a certificate provided by your employer which certifies details
                        regarding the salary you have earned during the year and how much TDS has been deducted. It
                        consists of two parts i.e. Part A and Part B, where part A consists of information of the
                        employer & employee, like the PAN and TAN details, name and address, TDS deducted & deposited,
                        etc.</p>
                    <p className="para">It is used by the companies to provide their salaried individuals information on
                        the tax deducted. Alternatively, you can consider Form 16 as your Salary TDS certificate.</p>
                    <p className="para">Suppose the income from your salary for the financial year is more than the
                        basic exemption limit of 2,50,000. Then, in this case, your employer is required to deduct
                        TDS on your salary and deposit it with the government. When From 16 is provided to an employee
                        by their employer, it is considered as a source of proof of filing their Income Tax Returns. And
                        if your income does not fit the basic exemption limit, the employer does not deduct any TDS in
                        that case.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#14accordian">
                <h4 className="panel-title">What is form 26AS? How to get form 26AS?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="14accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Form 26AS is an annual consolidation credit statement issued under section 203AA
                        of the <a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                  target="_blank">Income Tax</a> 1961 which mentions all the tax deductions by the
                        employer, banks or someone else as defined under the Act.</p>
                    <p className="para">To download Form 26AS you can log into the income tax filing account on the
                        Income Tax Departments e filing <a href="https://www.incometaxindiaefiling.gov.in/home"
                                                           target="_blank">website</a> either directly or through the
                        Net Banking facility of authorised banks.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#15accordian">
                <h4 className="panel-title">When is a refund issued by the Income Tax Department?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="15accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The refund is usually issued within 120 days of <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">filing
                        the ITR</a>.</p>
                    <p className="para">To download Form 26AS you can log into the income tax filing account on the
                        Income Tax Departments e filing <a href="https://www.incometaxindiaefiling.gov.in/home"
                                                           target="_blank">website</a> either directly or through the
                        Net Banking facility of authorised banks.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#16accordian">
                <h4 className="panel-title">Income Tax Filing for Freelancers or professionals - Forms, Sections and
                    Exemptions? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="16accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold"><a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                target="_blank">Filing ITR</a> for freelancer:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Forms</th>
                            <th>Sections</th>
                            <th className="col-xs-8">Exemptions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Form ITR 4</td>
                            <td>Sec. 44AB</td>
                            <td>
                                <ul>
                                    <li>Sec. 80C - Deduction u to INR 1.5 lakhs for payments made towards the life
                                        insurance policies, PF, superannuation, tuition fees, construction/purchase of
                                        residential property FD etc.
                                    </li>
                                    <li>Sec. 80 CCC - Tax Deductions for investment made towards the pension plans upto
                                        INR 1.5 lakhs.
                                    </li>
                                    <li>Sec. 80 CCD - Exemptions towards investments made in the Central Government
                                        Pension Scheme. Both the employer and employee contribution are exempted.
                                    </li>
                                    <li>Sec 80CCF- The exemptions are provided for investments made in the
                                        infrastructure bonds (long term) that are notified by the Government of India.
                                        The section offers a maximum exemption of Rs 20,000
                                    </li>
                                    <li>Sec 80CCG- The section provides a maximum deduction of Rs 25,000 for the
                                        investments made in the government Equity Saving Schemes, to certain specified
                                        Indian citizens and residents.
                                    </li>
                                    <li>SEC. 80D- Under this section, expenses made towards the payment of premiums of
                                        the health insurance policies are exempted. The freelancer can also buy the
                                        policy for spouse or child, and claim the deductions
                                    </li>
                                    <li>Sec. 80DD- The section provides deductions towards treatment of normal and
                                        severe disabilities, which may go up Rs 1.25 lakhs
                                    </li>
                                    <li>Sec. 80DDB- Exemptions towards treatment of certain specified diseases</li>
                                    <li>Sec. 80E- Deductions towards loan taken for education purposes</li>
                                    <li>Sec. 80 EE- The Section is exclusively for individuals, and exempts the payments
                                        made towards a loan, for buying a property for residential purposes
                                    </li>
                                    <li>Sec. 80G- The Section offers up to a 100% deduction for the donations made to
                                        the charitable funds, including the Prime Minister Relief Fund, and the National
                                        Defense Fund among others
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>],
    6: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#01accordian">
                <h4 className="panel-title">Documents required for Accounting Services
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="01accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>All Bank Statements for Financial Year</li>
                        <li>All Cash Transactions Register</li>
                        <li>Sales Summary with Invoices</li>
                        <li>Purchases Summary with Invoices</li>
                        <li>Capital Contribution Details</li>
                        <li>Loan Details</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">Why is accounting important for a business?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Accounting is a procedure of storing, sorting and recording financial
                        transactions. At the time of tax returns, every business is required to submit their financial
                        records and transactions. It is really important for one to keep their accounts sorted from the
                        beginning and keeping the books updated as it is very difficult to deal with tax returns and
                        financial matters at the same time.</p>
                    <p className="para">Moreover, keeping your books updated gives a good impression to the shareholders
                        and investors, apart from the income tax view.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What needs to be tracked in the account books?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The Companies Act 2013, states that the account books should include everyday
                        financial transactions and their bills. Account books mostly have trial balance, copies of bills
                        and original bills, ledgers, etc. Thanks to the online accounting, matters have become easier
                        and streamlined to manage.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">Why do you need an accountant?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">It is unlikely for you to have your balance sheet tally at the end of the year
                        if you are not an accountant because there would be miss-categorization of assets, liabilities
                        and expenses. All the visible features of the business should be classified to avoid confusion
                        in the future. An expert accountant will maintain a proper record of Incomes and Expenses,
                        calculate right taxes, categorise expenses properly and provide you with monthly reports to keep
                        you updated about your finances and help you make smart money and tax saving decisions.</p>
                </div>
            </div>
        </div>
    </div>],
    7: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#01accordian">
                <h4 className="panel-title">Documents required for Accounting Services
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="01accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>All Bank Statements for Financial Year</li>
                        <li>All Cash Transactions Register</li>
                        <li>Sales Summary with Invoices</li>
                        <li>Purchases Summary with Invoices</li>
                        <li>Capital Contribution Details</li>
                        <li>Loan Details</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">Why is accounting important for a business?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Accounting is a procedure of storing, sorting and recording financial
                        transactions. At the time of tax returns, every business is required to submit their financial
                        records and transactions. It is really important for one to keep their accounts sorted from the
                        beginning and keeping the books updated as it is very difficult to deal with tax returns and
                        financial matters at the same time.</p>
                    <p className="para">Moreover, keeping your books updated gives a good impression to the shareholders
                        and investors, apart from the income tax view.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What needs to be tracked in the account books?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The Companies Act 2013, states that the account books should include everyday
                        financial transactions and their bills. Account books mostly have trial balance, copies of bills
                        and original bills, ledgers, etc. Thanks to the online accounting, matters have become easier
                        and streamlined to manage.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">Why do you need an accountant?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">It is unlikely for you to have your balance sheet tally at the end of the year
                        if you are not an accountant because there would be miss-categorization of assets, liabilities
                        and expenses. All the visible features of the business should be classified to avoid confusion
                        in the future. An expert accountant will maintain a proper record of Incomes and Expenses,
                        calculate right taxes, categorise expenses properly and provide you with monthly reports to keep
                        you updated about your finances and help you make smart money and tax saving decisions.</p>
                </div>
            </div>
        </div>
    </div>],
    9: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">Documents required for Private Limited Company
                    Registration
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>ID Proof in form of Scanned copy of PAN Card and Aadhar Card (Self attested) of Directors
                        </li>
                        <li>Scanned copy of Voter’s ID/Passport/Driver’s License (Self attested) of Directors</li>
                        <li>Address proof of Directors: Scanned copy of Latest Bank Statement/Telephone or Mobile
                            Bill/Electricity or Gas Bill, not older than 2 months (Self attested)
                        </li>
                        <li>Scanned passport-sized photograph of Directors</li>
                        <li>Specimen signature (blank document with signature [directors only]</li>
                        <li>Address proof of place of Business - Rent agreement with NOC from the owner if rented
                            property or Sale Deed if self owned with latest utility bill copy.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">Registration Process of a Private Limited
                    Company <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">RUN Name Approval</p>
                    <ul>
                        <li>Name approval is obtained for the proposed company names from
                            Ministry of Corporate Affairs.
                        </li>
                        <li>Two names can be provided.</li>
                        <li>If both the names get rejected, there is an opportunity given
                            for the resubmission of the form with two more new names.
                        </li>
                        <li>The name should be acceptable by the MCA.</li>
                    </ul>
                    <p className="text-bold">Digital Signature</p>
                    <ul>
                        <li>A digital signature must be acquired for all the proposed
                            Directors of the Company.
                        </li>
                        <li>Digital signature is needed to sign the incorporation
                            application and forms.
                        </li>
                        <li>Whereas, the digital signature isn't needed for obtaining name
                            approval.
                        </li>
                        <li>Therefore, this process of obtaining Digital signature can run
                            side by side to the name approval process.
                        </li>
                    </ul>
                    <p className="text-bold">Incorporation Application Submission</p>
                    <p className="para">On obtaining the Digital Signature, the
                        incorporation application can be submitted in SPICe Form with the
                        MCA. In case of non-availability of name, the incorporation
                        documents must be resubmitted. Hence, it's advisable to obtain RUN
                        name approval before submission of SPICe Form.</p>
                    <p className="para"><b>Timeline:</b> 12 -15 days.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">Advantages of a Private Limited Company
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">You'd be contented to learn that a Private Limited
                        Company has prodigious advantages, being the most famous form of
                        business entity in India as mentioned earlier. Let's take a peek at
                        some distinguished ones: </p>
                    <p className="para"><b>Transfer of Shares is Easy -</b> which which
                        means that the shares of the company, if any, can be transferred to
                        anybody by the shareholder.</p>
                    <p className="para"><b>There's Separate Legal Entity -</b> Which Which
                        means that the directors and the members are a part of the company
                        but none of their personal assets are at risk. The company can take
                        a loan on its name and no members or directors are liable in case
                        the company can't pay off the capital.</p>
                    <p className="para"><b>The Borrowing Capacity is Higher -</b> the
                        liability of the members is very limited since the company is a
                        separate entity. There is a high borrowing capacity, which means
                        that the company can issue debentures and take help from the venture
                        capital and financial sectors and also accept deposits from
                        outsiders and boost its capital.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">What is DIN?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">DIN, Director Identification Number, is a unique 8
                        digit number required by any person proposed to be a Director in the
                        Company.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">What is DSC?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">DSC, Digital Signature Certificate is the electronic
                        format of physical or paper certificate such as a passport, driving
                        License etc. A DSC is similar to a handwritten signature which
                        builds up the identity of the sender filing the documents through
                        the web. The sender can't repudiate or deny.</p>
                    <p className="para">A DSC isn't just a digital equivalent of a
                        handwritten signature it adds additional information electronically
                        to any document or a message where it is utilized to make it more
                        genuine, secure and more anchored.</p>
                </div>
            </div>
        </div>
    </div>],
    10: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">Documents required for LLP Registration.
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The required documents for <a
                        href={`/${productIdMappedWithSlug['10'].slug}/${parentPracticeAreaSlug['5'].slug}`} target="_blank">LLP
                        registration</a> are as follows:
                    </p>
                    <ul>
                        <li>Latest passport size Photographs of all partners</li>
                        <li>PAN (Permanent Account Number) of all Partners (Minimum 2)</li>
                        <li>Identity Proof of each partner, (Aadhar Card, Passport, Driving License or Voter ID Card)
                        </li>
                        <li>Address Proof of all partners (Bank Statement or Passbook, electricity bill, telephone bill,
                            Aadhar card or any utility bill)
                        </li>
                        <li>Copy of Mobile bill, telephone bill, electricity bill or Bank Statement of all Partners with
                            Present address
                        </li>
                        <li>Registered Office Address Proof – Rent Agreement with NOC if rented property or sale deed
                            with the 7. latest utility bill copy if self-owned
                        </li>
                        <li>Stamp paper for LLP Agreement of State where LLP is to be Incorporated. Documents Must be
                            self-attested.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">Difference between LLP and Partnership Firm? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th className="col-xs-3">Particulars</th>
                            <th className="col-xs-6 text-center"><a
                                href={`/${productIdMappedWithSlug['10'].slug}/${parentPracticeAreaSlug['5'].slug}`} target="_blank">LLP
                                registration</a></th>
                            <th className="col-xs-3">Partnership</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Registration Under Act</td>
                            <td>It is Registered under LLP Act 2008</td>
                            <td>It is registered under Partnership Act 1932</td>
                        </tr>
                        <tr>
                            <td>Registered to</td>
                            <td>LLP registration is done with to Ministry of Corporate Affairs</td>
                            <td>Partnership registration is done with to Registrar of firm</td>
                        </tr>

                        <tr>
                            <td>Liability</td>
                            <td>Partners have separate legal entity and the liability is limited to the investment.</td>
                            <td>No separate entity to the partners, therefore, there is no limit to liabilities.</td>
                        </tr>
                        <tr>
                            <td>Qualifications</td>
                            <td>Minimum two partners are required, and they must not be minor or ineligible to enter
                                into a contract as per the Contracts Act.
                            </td>
                            <td>Minimum two and maximum twenty partners and all must be eligible to enter into a
                                contract as per the Contract Act.
                            </td>
                        </tr>
                        <tr>
                            <td>Transferability/conversion</td>
                            <td>Shares can be easily transferred to another person subjected to consent of other
                                partners. Though the transferee does not become eligible to be a partner just by the
                                transfer of shares.
                            </td>
                            <td>Same can be done in a Partnership firm, but the process is bit complicated compared to
                                LLP
                            </td>
                        </tr>
                        <tr>
                            <td>Compliance</td>
                            <td>Annual filing is compulsory</td>
                            <td>Not compulsory</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">What are the minimum requirements to register an LLP in India?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para"><b>Number of Partners:</b> minimum two. There is no maximum limitation, but all
                        the partners must be eligible to enter into a contract.
                    </p>
                    <p className="text-bold">Government fee for <a
                        href={`/${productIdMappedWithSlug['10'].slug}/${parentPracticeAreaSlug['5'].slug}`} target="_blank">LLP
                        registration</a>: </p>
                    <ul>
                        <li>LLP whose contribution is limited to $1,00,000 – $500.</li>
                        <li>LLP whose contribution exceeds $1,00,000 but is limited to $5,00,000 – $2000.</li>
                        <li>LLP whose contribution exceeds $5,00,000 but is limited to $10,00,000 – $4000.</li>
                        <li>LLP whose contribution exceeds $10,00,000 – $5000.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">Is there any minimum capital requirement for LLP registration in India?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">There is no minimum capital requirement in <a
                        href={`/${productIdMappedWithSlug['10'].slug}/${parentPracticeAreaSlug['5'].slug}`} target="_blank">LLP
                        registration</a>. An LLP can be formed with the least possible capital. Moreover, the
                        contribution of a partner can consist of tangible, movable or immovable or intangible property
                        or other benefits to the LLP.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">How to reserve the name for an LLP?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">File LLP Form No. 1 (Application for reservation or change of name) by logging
                        on to the LLP portal along with the fee prescribed and attaching the digital signature of the
                        designated partner proposing to <a
                            href={`/${productIdMappedWithSlug['10'].slug}/${parentPracticeAreaSlug['5'].slug}`} target="_blank">incorporate
                            an LLP</a>.</p>
                    <p className="para">Also, refer to the LLP name availability guidelines prescribed under section-15
                        read with Rule-18 of LLP Rules, 2009.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">What are the requirements to be a Partner/designated partner for LLP?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">For the <a
                        href={`/${productIdMappedWithSlug['10'].slug}/${parentPracticeAreaSlug['5'].slug}`} target="_blank">LLP</a>,
                        the designated partners can only be individuals. Among the members of a Limited Liability
                        Partnership, two or more partners can be designated as a Designated Partner. In all LLP, at
                        least one of the Designated Partner must be an Indian Resident.</p>
                    <p className="text-bold">Disqualification:</p>
                    <ul>
                        <li>An undischarged insolvent.</li>
                        <li>A person who was declared involvement in the preceding five years.</li>
                        <li>A person who has withheld payments to his creditors at any point of time in the preceding
                            five years of time, and has not made a composition with the creditors.
                        </li>
                        <li>A person who has been imprisoned for any immoral acts, and where the period of the sentence
                            was at least 6 months.
                        </li>
                        <li>Minors below the age of 18 years.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">What is DIN? Is DIN required for LLP registration?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para"><a href={path.covid19FreeSignup} target="_blank">DIN</a> is a unique Director
                        identification number allotted by the Central Government to any person intending to be a
                        Director or an existing director of a company. It is an 8-digit unique identification number
                        which has lifetime validity. Through DIN, details of the directors are maintained in a database.
                    </p>
                    <p className="para">DIN is specific to a person, which means even if he is a director in 2 or more
                        companies, he has to obtain only 1 DIN. And if he leaves a company and joins some other, the
                        same DIN would work in the other company as well.</p>
                    <p className="text-bold">DIN for LLP:</p>
                    <p className="para">The form DIR – 3 will allow only application for DIN allotment by for the
                        individual willing to be appointed as Designated Partner of any <b>existing LLP</b>.</p>
                    <p className="para">To <a href={`/${productIdMappedWithSlug['10'].slug}/${parentPracticeAreaSlug['5'].slug}`}
                                              target="_blank">form a new LLP</a>, the proposed Designated Partners
                        cannot make an application for DIN allotment.</p>
                    <p className="para">The Designated Partners do not require DPIN (Designated Partner Identification
                        Number). The Director Identification Number allotted is sufficient for said purpose. The
                        amendment is also brought the Companies (Appointment and Qualification of Directors) Rules, 2014
                        along with LLP Rules for enforcement of said change.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">What is the DSC certificate? Who shall procure DSC for LLP?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para"><a href={`/${productIdMappedWithSlug['53'].slug}/${parentPracticeAreaSlug['3'].slug}`}
                                           target="_blank">Digital Signature Certificates</a> (DSC) are the digital
                        equivalent (that is electronic format) of physical or paper certificates. A few Examples of
                        physical certificates are drivers' licenses, passports or membership cards. Certificates serve
                        as proof of identity of an individual for a certain purpose; for example, a driver's license
                        identifies someone who can legally drive in a particular country. Likewise, a digital
                        certificate can be presented electronically to prove one’s identity, to access information or
                        services on the Internet or to sign certain documents digitally.</p>
                    <p className="para">Every form or application is filed online with the MCA, which requires to be
                        signed digitally by the applicants and partners of the LLP. Therefore, the DSC with the validity
                        of 2 years is procured for the Designated Partners of the Limited Liability Partnership.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#9accordian">
                <h4 className="panel-title">Whether a place of office is required for online LLP registration?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="9accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">It has been provided in the Act that a document may be served on an <a
                        href={`/${productIdMappedWithSlug['10'].slug}/${parentPracticeAreaSlug['5'].slug}`}
                        target="_blank">LLP</a> or a partner or designated partner by sending it by post or by any other
                        mode (to be prescribed under Rules) at the registered office and any other address specifically
                        declared by the LLP for the purpose in such form and manner as may be prescribed (in the rules).
                        Thus, an LLP shall have the option to declare one more address within the jurisdiction of the
                        same ROC (other than the registered office) for getting statutory notices/letters etc. from
                        Registrar.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#10accordian">
                <h4 className="panel-title">What is the LLP agreement? Does it require filing for MCA?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="10accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, it is mandatory to execute and file the <a href={path.covid19FreeSignup}>LLP
                        Agreement</a> in view of Section 2(0) & (q) , 22 and 23 of the Act.</p>
                    <p className="para">As per provisions of the LLP Act, in the absence of agreement as to any matter,
                        the mutual rights and liabilities shall be as provided for under Schedule I to the Act.
                        Therefore, in case any LLP proposes to exclude provisions/requirements of Schedule I to the Act,
                        it would have to enter into an LLP Agreement, specifically excluding applicability of any or all
                        paragraphs of Schedule I</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#11accordian">
                <h4 className="panel-title">Can an LLP carry on more than one business activity?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="11accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, but they should be relevant in nature.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#12accordian">
                <h4 className="panel-title">Whether LLP can be registered for Not for profit organization?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="12accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Sec. 25 of the Companies Act says In India nonprofit/public charitable
                        organizations can be registered as trusts, societies, or a private limited nonprofit
                        company.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#13accordian">
                <h4 className="panel-title">When can I get the PAN and TAN of the LLP?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="13accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Soon after LLP is incorporated, PAN and TAN application has to be made
                        separately.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#14accordian">
                <h4 className="panel-title">Whether a Body Corporate can be a partner in the LLP?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="14accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, Body corporate broadly means a corporate entity that has a legal existence.
                        The term "body corporate" is defined in Section 2(11) of the Companies Act 2013. This includes a
                        private company, public company, one-person company, small company, Limited Liability
                        Partnerships, a foreign company, etc.
                    </p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#15accordian">
                <h4 className="panel-title">Is Foreign Direct Investment FDI allowed in LLP?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="15accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">FDI in LLP is subject to the compliance of the conditions of the Limited
                        Liability Partnership Act, 2008. An Indian company or an LLP, having foreign investment, will be
                        permitted to make downstream investment in another company or LLP engaged in sectors in which
                        100% FDI is allowed under the automatic route and there are no FDI linked performance
                        conditions. The onus shall be on the Indian company / LLP accepting downstream investment to
                        ensure compliance with the above conditions.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#16accordian">
                <h4 className="panel-title">Can an existing partnership firm or company be converted to LLP?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="16accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, an existing <a path={path.covid19FreeSignup} targrt="_blank">partnership
                        firm can be converted into LLP</a> by complying with the provisions of clause 58 and Schedule II
                        of the LLP Act. Form 17 needs to be filed along with Form 2 for such conversion and
                        incorporation of LLP.</p>
                </div>
            </div>
        </div>
    </div>],
    107: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#01accordian">
                <h4 className="panel-title">Documents required for Partnership Firm.
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="01accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>PAN Card Copy & Aadhar Card Copy of All Partners</li>
                        <li>Address Proof of all Partners - Latest Bank Statement, Utility Bill, Passport, Aadhar,
                            Driving License
                        </li>
                        <li>Address Proof of Business - Rent Agreement if rented or Sale Deed if self-owned</li>
                        <li>Registered Partnership Deed</li>
                        <li>Photograph of Partners</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">Is partnership deed registration compulsory?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">No, it is not compulsory.</p>
                    <p className="para">There are strong reasons to <a
                        href={`/${productIdMappedWithSlug['107'].slug}/${parentPracticeAreaSlug['5'].slug}`} target="_blank">register
                        the partnership deed</a>, particularly because unregistered entities have severe restrictions
                        with respect to legal enforcement of the partnership deed.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What is the minimum capital requirement to start a partnership firm? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">No minimum capital is required for the <a
                        href={`/${productIdMappedWithSlug['107'].slug}/${parentPracticeAreaSlug['5'].slug}`} target="_blank">registration
                        of a partnership firm</a>.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">What are the effects of non-registration of partnership?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The effects of non-registration of a partnership firm are as follows:</p>
                    <ul>
                        <li><b>Cannot Sue Firm:</b> A partner in an unregistered firm cannot file a suit against said
                            firm or his/her partners to enforce any rights arising from the contract. It also includes
                            rights conferred by the Partnership Act unless the partner’s name is entered into the
                            Register of Firms as a partner in the firm.
                        </li>
                        <li><b>Cannot Sue Others:</b> An unregistered firm has no ability to file a suit against a third
                            party to enforce a right arising from a contract unless the firm is registered and the
                            persons suing are or have been shown in the Register of Firms as partners in the firm.
                        </li>
                        <li><b>No Set-Off:</b> As part of an unregistered firm, you, your partners or the firm cannot
                            claim a set-off (this refers to the mutual adjustment of debts owed) in a dispute with a
                            third party.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">How many persons are required to register a partnership firm? What are the
                    qualities a partner must have?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">A minimum of two partners is required for a partnership firm.</p>
                    <p className="text-bold">Qualifications:</p>
                    <ul>
                        <li><b>Individual:</b> An individual, who is competent to contract, can become a partner in the
                            partnership firm. If there are more than two partners in a firm, an individual can be a
                            partner in his individual capacity as well as in a representative capacity as Karta of the
                            Hindu undivided family.
                        </li>
                        <li><b>Firm:</b> A partnership firm is not a person and therefore a firm can not enter into a
                            partnership with any firm or individual. But a partner of the partnership firm can enter
                            into a partnership with other persons and he can share the profits of the said firm with his
                            other co-partners of the parent firm.
                        </li>
                        <li><b>Hindu Undivided Family:</b> A Karta of the Hindu undivided family can become a partner in
                            a partnership in his individual capacity, provided the member has contributed his self
                            acquired or personal skill and labor.
                        </li>
                        <li><b>Company:</b> A company is a juristic person and therefore can become a partner in a
                            partnership firm if it is authorized to do so by its objects.
                        </li>
                        <li><b>Trustees:</b> Trustees of private religious trust, family trust and trustees of Hindu
                            mutts or other religious endowments are juristic persons and can, therefore, enter into a
                            partnership, unless their constitution or objects forbid.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">Under which Government authority the application of registration is
                    submitted?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The application for <a
                        href={`/${productIdMappedWithSlug['107'].slug}/${parentPracticeAreaSlug['5'].slug}`} target="_blank">registration
                        of partnership firm</a> is filed with the Registrar of Firms having jurisdiction over the place
                        of business of the partnership firm. The registrar of firms after receipt of the application
                        complete in all aspects with all required documents registers the firm within 1-2 weeks and
                        issues the Certificate of Registration of Firm.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">What are the main factors to e mentioned at the time of drafting the
                    partnership deed?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The main factors of a <a
                        href={`/${productIdMappedWithSlug['107'].slug}/${parentPracticeAreaSlug['5'].slug}`} target="_blank">partnership
                        firm</a> are as follows:</p>
                    <ul>
                        <li>Name of the firm.</li>
                        <li>Names and addresses of partners.</li>
                        <li>Nature of business.</li>
                        <li>Place of business.</li>
                        <li>Capital contributed by each partner.</li>
                        <li>Profit-sharing ratio.</li>
                        <li>Duties, powers, and obligations of all partners.</li>
                        <li>Preparation of accounts and their auditing.</li>
                        <li>Whether interest is payable on capital.</li>
                        <li>Whether interest is charged on drawing.</li>
                        <li>Whether interest is payable on the loan provided by the partners.</li>
                        <li>Salary payable to partners.</li>
                        <li>Method of solving disputes.</li>
                        <li>Provisions regarding dissolution.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">Is the partnership deed has to be notarized?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">It is not mandatory to notarize the <a
                        href={`/${productIdMappedWithSlug['107'].slug}/${parentPracticeAreaSlug['5'].slug}`} target="_blank">partnership
                        deed</a>.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">How much stamp duty is required for partnership deed?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para"><b>A <a href={`/${productIdMappedWithSlug['107'].slug}/${parentPracticeAreaSlug['5'].slug}`}
                                                target="_blank">partnership deed</a></b> is created on a judicial <b>stamp
                        paper of $2,000/-</b> and has to be signed by all the partners. It contains the rights
                        and <b>duties</b> of the firm and the partners.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#9accordian">
                <h4 className="panel-title">How long does it take to register a partnership firm in India?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="9accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Minimum 12-14 working days, which may vary depending on the situation.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#10accordian">
                <h4 className="panel-title">What are the compliance requirements for partnership firm?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="10accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>GST filing</li>
                        <li>TDS filing</li>
                        <li>ESI Return</li>
                        <li>Service Tax or VAT.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#11accordian">
                <h4 className="panel-title">Is the audit required for a partnership firm?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="11accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">If the turnover exceeds one crore annually, then it is mandatory to proceed for
                        the audit.</p>
                </div>
            </div>
        </div>

    </div>],
    12: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">Documents Required For One Person Company Registration
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>ID Proof in the form of Scanned copy of PAN Card and Aadhar Card (Self-attested) of
                            Director
                        </li>
                        <li>Scanned copy of Voter’s ID/Passport/Driver’s License (Self attested) of Director</li>
                        <li>Address proof of Directors: Scanned copy of Latest Bank Statement/Telephone or Mobile
                            Bill/Electricity or Gas Bill, not older than 2 months (Self attested)
                        </li>
                        <li>Scanned passport-sized photograph of Director</li>
                        <li>Specimen signature (blank document with signature [directors only]</li>
                        <li>Address proof of the place of Business. Rent agreement with NOC from the owner if rented
                            property or Sale Deed if self-owned with latest utility bill copy.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">Process of Incorporation of OPC <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para"><b>As incorporation</b> is now done online, the director’s signature is needed
                        on electronic documents. To make this possible, the director in the OPC needs a Class-II Digital
                        Signature Certificate (DSC). </p>
                    <p className="text-bold">Apply for DSC:</p>
                    <p className="para">The first Step is to obtain the Digital Signature Certificate (DSC) of the
                        proposed Director which required the following documents:</p>
                    <ul>
                        <li>Address Proof</li>
                        <li>Aadhaar card</li>
                        <li>PAN card</li>
                        <li>Photo</li>
                        <li>Email Id</li>
                        <li>Phone Number</li>
                    </ul>
                    <p className="text-bold">Apply for DIN:</p>
                    <p className="para">Once the Digital Signature Certificate (DSC) is made, the next step is to apply
                        for the DIN of the proposed Director in SPICe Form along with the name and the address proof of
                        the director. Form DIR-3 is the option only available for existing companies. It means with
                        effect from January 2018, the applicant need not file Form DIR-3 separately. Now DIN can be
                        applied within SPICe form for up to three directors.</p>
                    <p className="text-bold">Name Approval Application:</p>
                    <p className="para">The next step while incorporating an OPC is to decide on the name of the
                        Company. The name of the Company will be in the form of “ABC (OPC) Private Limited”.</p>
                    <p className="para">There are 2 options available for getting name approved by making application in
                        Form SPICe 32 or by using RUN Web service of MCA by giving only 1 preferred name along with the
                        significance of keeping that name. However, with effect from March 23, 2018, Ministry has
                        decided to permit two proposed Names and one re-submission (RSUB) while reserving Unique Names
                        (RUN Service) for the Companies.</p>
                    <p className="para">Once the name is approved by the MCA we move on to the next step. </p>
                    <p className="text-bold">Documents Required:</p>
                    <p className="para">We have to prepare the following documents which are required to be submitted to
                        the ROC:</p>
                    <ul>
                        <li>The Memorandum Of Association (MoA) which are the objects to be followed by the Company or
                            stating the business for which the company is going to be incorporated.
                        </li>
                        <li>The Articles Of Association (AoA) which lays down the by-laws on which the company will
                            operate.
                        </li>
                        <li>Since there are only 1 Director and a member, a nominee on behalf of such person has to be
                            appointed because in case he becomes incapacitated or dies and cannot perform his duties the
                            nominee will perform on behalf of the director and take his place. His consent in Form INC –
                            3 will be taken along with his PAN card and Aadhar Card.
                        </li>
                        <li>Proof of the Registered office of the proposed Company along with the proof of ownership and
                            a NOC from the owner.
                        </li>
                        <li>Affidavit and Consent of the proposed Director of Form INC -9 and DIR – 2 resp.</li>
                        <li>A declaration by the professional certifying that all compliances have been made.</li>
                    </ul>
                    <p className="text-bold">Filing of forms with MCA:</p>
                    <p className="para">All these documents will be attached to SPICe Form, SPICe-MOA and SPICe-AOA
                        along with the DSC of the Director and the professional, and will be uploaded to the MCA site
                        for approval.</p>
                    <p className="para">After uploading, Form 49A and 49B will be generated for the PAN and TAN
                        generation of the Company which have to be uploaded to MCA after affixing the DSC of the
                        proposed Director.</p>
                    <p className="text-bold">ssue of the certificate of Incorporation:</p>
                    <p className="para">On verification, the Registrar of Companies (ROC) will issue a Certificate of
                        Incorporation and we can commence our business.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">Who can form a One Person Company?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Only adult Indian citizens residing in India can start an OPC. An individual
                        cannot start more than one OPC, but you’re free to be part of other businesses. A minor can be
                        neither member nor nominee in an OPC.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">What is DIN?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">DIN, Director Identification Number, is a unique 8 digit number required by any
                        person proposed to be a Director in the Company.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">What is DSC?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">DSC, Digital Signature Certificate is the electronic format of physical or paper
                        certificate such as a passport, driving License etc. A DSC is similar to a handwritten signature
                        which builds up the identity of the sender filing the documents through the web. The sender
                        can't repudiate or deny.</p>
                    <p className="para">A DSC isn't just a digital equivalent of a handwritten signature it adds
                        additional information electronically to any document or a message where it is utilized to make
                        it more genuine, secure and more anchored.</p>
                </div>
            </div>
        </div>
    </div>],
    13: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">Documents required for Registration of Sole Proprietorship
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>PAN Card Copy & Aadhar Card Copy of Sole Proprietor</li>
                        <li>Address Proof of Sole Proprietor- Latest Bank Statement, Utility Bill, Passport, Aadhar,
                            Driving License
                        </li>
                        <li>Address Proof of Business - Rent Agreement if rented or Sale Deed if self-owned</li>
                        <li>Photograph of Sole Proprietor</li>
                        <li>Bank Details - Cancelled Cheque and Latest 6 months Bank Statement</li>
                        <li>Fssai License if food business</li>
                        <li>Import Export Code is Import Export Business</li>
                        <li>MSME Registration</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">Registration Process of a Sole Proprietorship <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">A sole proprietorship in India is considered as an extension of the Proprietor,
                        hence there are no formal procedure to register it. There is only a requirement of opening a
                        bank account in the name of the proprietorship firm or obtaining the licenses required for
                        conducting the business. To open a bank account for a sole proprietorship, the RBI’s KYC norms
                        dictate that any of the two of following document must be submitted to the Bank:</p>
                    <ul>
                        <li>A certificate/license issued by Municipal authorities under the Shop & Establishment Act.
                        </li>
                        <li>The license issued by Registering authority like the Certificate of Practice issued by the
                            Institute of Chartered Accountants of India, the Institute of Cost Accountants of India,
                            Institute of Company Secretaries of India, the Indian Medical Council, Food and Drug Control
                            Authorities.
                        </li>
                        <li>The registration/licensing document issued in name of the proprietary concern by the Central
                            Government or the State Government Authority/ Department, etc.
                        </li>
                        <li>The banks may also accept the IEC (Importer Exporter Code) issued to the proprietary concern
                            by office of the DGFT as an identity document for opening of the bank account etc.
                        </li>
                        <li>Complete Income Tax return (not just the acknowledgement) in name of the sole proprietor
                            where the firm’s income is reflected, duly authenticated and acknowledged by the Income Tax
                            Authorities,
                            The utility bills such as the electricity, water, and the landline telephone bills in the
                            name of the proprietary concern.
                        </li>
                        <li>GST Registration/Certificate.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">Does Sole Proprietorship firm need registration?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Sole Proprietorship Firms do not need to register with any government agency.
                        But there are some licenses that are required for the business
                    </p>
                </div>
            </div>
        </div>
    </div>],
    14: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#01accordian">
                <h4 className="panel-title">Documents required for Section-8 Company registration.
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="01accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>ID Proof in form of Scanned copy of PAN Card and Aadhar Card (Self attested) of Directors
                        </li>
                        <li>Scanned copy of Voter’s ID/Passport/Driver’s License (Self attested) of Directors</li>
                        <li>Address proof of Directors: Scanned copy of Latest Bank Statement/Telephone or Mobile
                            Bill/Electricity or Gas Bill, not older than 2 months (Self attested)
                        </li>
                        <li>Scanned passport-sized photograph of Directors</li>
                        <li>Specimen signature (blank document with signature [directors only]</li>
                        <li>Address proof of place of Business. Rent agreement with NOC from the owner if rented
                            property or Sale Deed if self owned with latest utility bill copy.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">Procedure needed for registration of Section-8 Company <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Obtain a DSC of the proposed Directors of the Section 8 Company. After a DSC is
                        collected, file Form DIR-3 with the ROC for receiving a DIN.</p>
                    <p className="para"><b>Note:</b> Documents for application of DIN - Identity Proof and Address Proof
                    </p>
                    <p className="para">After DIR-3 is approved, the ROC shall provide a DIN to the proposed
                        directors.</p>
                    <p className="para">File Form INC-1 with the ROC to apply for a company name. Six names can be
                        applied according to the preference, based on availability one name would be finalised. </p>
                    <p className="para">After the approval, Form INC-12 will be filed with the ROC - to apply for a
                        licence for the formation of Section 8 company.</p>
                    <p className="para">Subscription pages of the Memorandum and AoA of the proposed company will be
                        signed by each subscriber together with mentioning his/her address, name and occupation. This
                        should happen in front of a witness who will attest the signature and sign and also add his/her
                        name occupation and address.</p>
                    <p className="para">After the Form is accepted, a license under section 8 shall be issued in Form
                        INC-16.</p>
                    <p className="para">Once the licence is obtained, file SPICE Form 32 (with ROC) for incorporation
                        together with the following attachments:</p>
                    <ul>
                        <li>Declaration of deposits</li>
                        <li>An affidavit from all the directors’ cum subscribers – INC-9</li>
                        <li>Form DIR-2 with its attachments i.e PAN Card and address proof of directors</li>
                        <li>Utility bill as a proof of Office address</li>
                        <li>NOC in case the premises are leased/rented</li>
                        <li>KYC of all the Directors</li>
                        <li>Interest in other entities of the directors</li>
                        <li>Consent letter of all the directors</li>
                        <li>Draft MOA and AOA</li>
                    </ul>
                    <p className="para">A unique Company Identification Number (CIN) and the Certificate of
                        Incorporation is proved by the ROC if he is satisfied with the submitted forms.</p>
                </div>
            </div>
        </div>
    </div>],
    16: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#01accordian">
                <h4 className="panel-title">Documents required for Annual ROC Filing.
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="01accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>Copy of MoA, AoA</li>
                        <li>Copy of board resolution</li>
                        <li>List of shareholders with holdings</li>
                        <li>Details of board composition</li>
                        <li>Financial Statements (P&L and Balance Sheet) with the Secretarial Audit Report</li>
                        <li>Last year's tax return</li>
                        <li>LLP Partnership Deed</li>
                        <li>Particulars of penalties or compounding offences (if applicable)</li>
                    </ul>
                </div>
            </div>
        </div>

        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What are the compulsory compliance for a private limited company?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">Following are the <a
                        href={`/${productIdMappedWithSlug['17'].slug}/${parentPracticeAreaSlug['3'].slug}`} target="_blank">mandatory
                        Compliances</a> for <a herf={`/${productIdMappedWithSlug['9'].slug}/${parentPracticeAreaSlug['5'].slug}`}
                                               target="_blank">Pvt limited company</a>:</p>
                    <ul>
                        <li><b>First Board Meeting:</b> First Meeting of Board of Directors is required to be held
                            within 30 days of Incorporation of Company. Notice of Board Meeting must be send to every
                            director at least 7 days before the meeting.
                        </li>
                        <li><b>Subsequent Board Meetings:</b> Minimum 4 Board Meetings to be held every year with not
                            more than 120 days gap between two meetings.
                        </li>
                        <li><b>Filing of Disclosure of interest by Directors:</b> Every director at:
                            <ul>
                                <li>First meeting in which he participates as director; or</li>
                                <li>First meeting of Board in every FY; or</li>
                                <li>Whenever there is change in disclosures Shall disclose in Form MBP‐1 (along with
                                    list of relatives and concern of relatives in the Company as per RPT definition),
                                    his concern or interest in any company, body corporate, firm or other association of
                                    individuals (including shareholding interest). Form MBP‐1 shall be kept in the
                                    records of the company.
                                </li>
                            </ul>
                        </li>
                        <li><b>First Auditor:</b> First Auditor of the company shall be appointed by the <b>BOD within
                            30 days</b> of Incorporation who shall hold the office till the conclusion of 1st AGM. In
                            case of First Auditor, filing of ADT-1 is not mandatory.
                        </li>
                        <li><b>Subsequent Auditor:</b> The BOD shall appoint the auditor in first AGM of company who
                            shall hold the office till the conclusion of 6th AGM and shall inform the same to ROC by
                            filing <b>ADT-1</b>. The responsibility to file Form ADT 1 is that of the company and not of
                            the auditor within <b>15 days</b> from the date of appointment.
                        </li>
                        <li><b>Annual General Meeting:</b> Every Company is required to hold an Annual General Meeting
                            on or before <b>30th September</b> every year during business hours (9 am to 6pm), on a day
                            that is not a public holiday and either at the registered office of the Company or within
                            the city, town or village where the registered office is situated. A 21 clear days’ notice
                            is required to be given for the same.
                        </li>
                        <li><b>Filing of Annual Return (Form MGT-7):</b> Every Private Limited Company is required to
                            file its Annual Return within 60 days of holding of Annual General Meeting. Annual Return
                            will be for the period 1st April to 31st March.
                        </li>
                        <li><b>Filing of Financial Statements (Form AOC-4):</b> Every Private Limited Company is
                            required to file its Balance Sheet along with statement of Profit and Loss Account and
                            Director Report in this form within 30 days of holding of Annual General Meeting.
                        </li>
                        <li><b>Statutory Audit of Accounts:</b> Every Company shall prepare its Accounts and get the
                            same audited by a Chartered Accountant at the end of the Financial Year compulsorily. The
                            Auditor shall provide an Audit Report and the Audited Financial Statements for the purpose
                            of filing it with the Registrar.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What are the compulsory compliance for a LLP? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">There are three mandatory <a
                        href={`/${productIdMappedWithSlug['17'].slug}/${parentPracticeAreaSlug['3'].slug}`} target="_blank">compliance
                        requirements</a> to be followed by <a
                        herf={`/${productIdMappedWithSlug['10'].slug}/${parentPracticeAreaSlug['5'].slug}`} target="_blank">LLPs</a>:
                    </p>
                    <ul>
                        <li>Filing of Annual Return.</li>
                        <li>Filing of Statement of the Accounts or Financial Statements.</li>
                        <li>Filing of Income Tax Returns.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">What is ROC Filing for Private Limited Company?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para"><a herf={`/${productIdMappedWithSlug['9'].slug}/${parentPracticeAreaSlug['5'].slug}`}
                                           target="_blank">Private Limited Companies</a> are required to file its <a
                        href={`/${productIdMappedWithSlug['17'].slug}/${parentPracticeAreaSlug['3'].slug}`} target="_blank">Annual
                        Accounts</a> and Returns disclosing details of its shareholders, directors etc to the Registrar
                        of Companies. Such compliances are required to be made once a year.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">What is ROC Filing for a LLP?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para"><a herf={`/${productIdMappedWithSlug['10'].slug}/${parentPracticeAreaSlug['5'].slug}`}
                                           target="_blank">LLPs</a> in India must file its <a
                        href={`/${productIdMappedWithSlug['17'].slug}/${parentPracticeAreaSlug['3'].slug}`} target="_blank">Annual
                        Return</a> within 60 days from the end of the close of the financial year and Statement of
                        Account & Solvency within 30 days from the end of six months of close of financial year. In
                        addition to the MCA annual return, LLPs must also mandatorily file income tax return every year.
                    </p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">When do we have to file AOC 4 and MGT 7?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para"><b>Every Company</b> registered under Companies Act, 2013 or Companies Act, 1956
                        is required to file their Financial Statements in <b>e-Form AOC-4</b> including Directors Report
                        along with other relevant attachments (ANNUAL REPORT) <b>within 30 days</b> and <a
                            href={`/${productIdMappedWithSlug['17'].slug}/${parentPracticeAreaSlug['3'].slug}`} target="_blank">Annual
                            Return</a> in <b>e-Form MGT-7 within 60 days</b> from conclusion of its Annual General
                        Meeting (AGM).</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">What is the penalty for not filing AOC 4 and MGT 7?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Every Company is required to file the annual accounts and the annual returns
                        within 30 days and 60 days respectively from the conclusion of the Annual General Meeting.</p>
                    <p className="para">The managing director and the Chief Financial Officer of the company, if any,
                        and, in the absence of the managing director and the Chief Financial Officer, any other director
                        who is charged by the Board with the responsibility of complying with the provisions of this
                        section, and, in the absence of any such director, all the directors of the company, shall be
                        liable to a penalty of $1,00,000/- and in case of continuing failure, with further penalty of
                        $1,000/- for each day after the first during which such failure continues, subject to a
                        maximum of $5,00,000/-.</p>
                    <p className="para"><b>Penalty for not filing MGT 7:</b> Company and its every officer who is in
                        default shall be liable to a penalty of $50,000/- and in case of continuing failure, with
                        further penalty of $100/- for each day during which such failure continues, subject to a
                        maximum of $5,00,000/-.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">Whether the Annual filing necessary for all companies?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Every Company is required to file the <a
                        href={`/${productIdMappedWithSlug['17'].slug}/${parentPracticeAreaSlug['3'].slug}`} target="_blank">annual
                        accounts</a> and the annual returns within 30 days and 60 days respectively from the conclusion
                        of the Annual General Meeting.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">Whether Statutory Audit is compulsory for all Private Limited Companies?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, it is mandatory.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#9accordian">
                <h4 className="panel-title">Is the appointment of statutory auditor falls under annual compliance?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="9accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#10accordian">
                <h4 className="panel-title">In case of no operations in the company, do directors require to sign the
                    director's report?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="10accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#11accordian">
                <h4 className="panel-title">Which form is an attachment to the companies Director Report?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="11accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">MGT 09 is a report which has to be furnished along with the director’s report in
                        the Companies Act 2013. The MGT 09 is prepared by the company when the director’s report gets
                        approved in the board meeting.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#12accordian">
                <h4 className="panel-title">Whether Annual Filing is necessary for the LLPs?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="12accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, Annual Returns are to be filed in the prescribed Form-11. This form is
                        considered as the summary of management affairs of LLP, like numbers of partners along with
                        their names. Moreover, the form 11 has to be filed by 30th May every year.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#13accordian">
                <h4 className="panel-title">What is the penalty for non-filing/delayed filing of annual compliance forms
                    for LLP?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="13accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Late filing or not filing of LLP annual return will attract a penalty of $100
                        per day from the last day of filing to the payment processing date.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#14accordian">
                <h4 className="panel-title">Whether the Statutory Audit of Financial Statements is required for LLP ROC
                    filing?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="14accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Every LLP has to file the annual return within 60 days of closure of the
                        financial year. This is prepared by filling of Form 11. Statutory Audit is mandatory for LLP
                        only if its annual turnover exceeds INR 40 lakh and/or its total paid-up capital exceeds INR 25
                        lakh.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#15accordian">
                <h4 className="panel-title">What is the difference between Statutory Audit and Tax Audit?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="15accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The differences between statutory audit and tax audit are drawn clearly on
                        the following grounds:</p>
                    <ul>
                        <li>An audit, which is required by the statute (law) is known as a Statutory audit. Tax Audit is
                            an audit made compulsory by the Income Tax Act if the turnover of the assesses reaches the
                            specified limit.
                        </li>
                        <li>Statutory Audit is performed by external auditors whereas tax audit is conducted by a
                            practising Chartered Accountant.
                        </li>
                        <li>Statutory Audit is the audit of complete accounting records. Conversely, Tax Audit is the
                            audit of tax related transactions.
                        </li>
                        <li>The purpose of the statutory audit is to ensure reliability and transparency, truthfulness
                            and fairness of financial statements. As opposed to a tax audit, which ensures proper
                            maintenance of books of accounts and they truly reflect the taxable income of the assessee
                            as well as the deductions claimed are actually made by the assessee.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#16accordian">
                <h4 className="panel-title">Is Tax Audit Compulsory for Companies and LLPs?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="16accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">No, Tax Audit is not compulsory for Companies & LLPs. It depends on the turnover
                        of the company.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#17accordian">
                <h4 className="panel-title">What are the Board Meeting compliances to be done for a Company?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="17accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">Pre – Meeting Compliances:</p>
                    <ul>
                        <li>Date of Sending Notice to Stock Exchange</li>
                        <li>Press Release for Board Meeting in Newspaper on website of the company</li>
                        <li>Press Release for Board Meeting in Newspaper</li>
                        <li>Agenda of the Meeting & Notes thereof to Directors</li>
                        <li>Intimation to Directors regarding Board Meeting</li>
                        <li>Closure of Trading Window</li>
                    </ul>
                    <p className="text-bold">Post – Meeting Compliances:</p>
                    <ul>
                        <li>Disclosure to Stock Exchange</li>
                        <li>Publication of Results in Newspaper</li>
                        <li>Minutes Preparation</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#18accordian">
                <h4 className="panel-title">What are the Annual General Meeting compliances to be done for a Company?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="18accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">A company must fulfil the following compliance post its AGM: </p>
                    <ul>
                        <li>Forward a copy of proceedings of Annual General Meeting to Stock Exchange.</li>
                        <li>Submit the details of a voting result to the Stock Exchange within 48 hours of the
                            conclusion of AGM.
                        </li>
                        <li>Pay the dividend or dispatch the warrants within 30 days from the date of declaration.</li>
                        <li>Submit the notice of Auditor’s appointment with the Registrar in Form ADT-1 within 15 days
                            of Annual General Meeting.
                        </li>
                        <li>File the Audited Financial Statements along with consolidated financial statement with the
                            Registrar within 30 days of Annual General Meeting.
                        </li>
                        <li>File Annual Return with the Registrar in MGT-7 within 60 days of Annual General Meeting. The
                            Annual Return must be signed by a Director and Company Secretary of the Company.
                        </li>
                        <li>File the report on AGM (in case of a listed public company) within 30 days of AGM.</li>
                        <li>File an explanatory statement annexed to the notice in Form MGT-14 with the Registrar for
                            special resolution and other resolutions passed under Section 117 of the Companies Act, 2013
                            within 30 days.
                        </li>
                        <li>Prepare and file a report on the Annual General Meeting with Registrar in Form MGT-15 within
                            30 days of Annual General Meeting.
                        </li>
                        <li>Prepare a statement containing the names, last known addresses and unpaid dividend to be
                            paid to each such person within 90 days of transfer to Unpaid Dividend Account and display
                            it on the website of the Company.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#19accordian">
                <h4 className="panel-title">What is DIR3 KYC? What is the last date and the penalty for non-compliance?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="19accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">DIR-3-KYC is a new E-form launched by the Ministry of Corporate Affairs. This
                        form will be used to update KYC of all directors. Every individual who is allotted DIN as on
                        31st March of a financial year must submit his KYC on or before 30th September of the
                        immediately next financial year. If the DIN holder does not file his annual KYC within the due
                        date of each financial year, such DIN shall be marked as ‘Deactivated due to non-filing of DIR-3
                        KYC’ and shall remain in such Deactivated status until KYC is done with a fee of $5000.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#20accordian">
                <h4 className="panel-title">What happens if I don’t get statutory audit done?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="20accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">If Company contravened the provisions, the company shall be punishable with fine
                        which shall be $25,000/- to $5,00,000/- and every officer of the company who is in default
                        shall be punishable with imprisonment for a term which may extend to 1 year or with fine which
                        shall not be less than $10,000/- but which may extend to $1,00,000/-, or with both.</p>
                </div>
            </div>
        </div>
    </div>],
    17: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#01accordian">
                <h4 className="panel-title">Documents required for Annual ROC Compliance.
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="01accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>Copy of MoA, AoA</li>
                        <li>Copy of board resolution</li>
                        <li>List of shareholders with holdings</li>
                        <li>Details of board composition</li>
                        <li>Financial Statements (P&L and Balance Sheet) with the Secretarial Audit Report</li>
                        <li>Last year's tax return</li>
                        <li>LLP Partnership Deed</li>
                        <li>Particulars of penalties or compounding offences (if applicable)</li>
                    </ul>
                </div>
            </div>
        </div>

        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What are the compulsory compliance for a private limited company?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">Following are the <a
                        href={`/${productIdMappedWithSlug['17'].slug}/${parentPracticeAreaSlug['3'].slug}`} target="_blank">mandatory
                        Compliances</a> for <a herf={`/${productIdMappedWithSlug['9'].slug}/${parentPracticeAreaSlug['5'].slug}`}
                                               target="_blank">Pvt limited company</a>:</p>
                    <ul>
                        <li><b>First Board Meeting:</b> First Meeting of Board of Directors is required to be held
                            within 30 days of Incorporation of Company. Notice of Board Meeting must be send to every
                            director at least 7 days before the meeting.
                        </li>
                        <li><b>Subsequent Board Meetings:</b> Minimum 4 Board Meetings to be held every year with not
                            more than 120 days gap between two meetings.
                        </li>
                        <li><b>Filing of Disclosure of interest by Directors:</b> Every director at:
                            <ul>
                                <li>First meeting in which he participates as director; or</li>
                                <li>First meeting of Board in every FY; or</li>
                                <li>Whenever there is change in disclosures Shall disclose in Form MBP‐1 (along with
                                    list of relatives and concern of relatives in the Company as per RPT definition),
                                    his concern or interest in any company, body corporate, firm or other association of
                                    individuals (including shareholding interest). Form MBP‐1 shall be kept in the
                                    records of the company.
                                </li>
                            </ul>
                        </li>
                        <li><b>First Auditor:</b> First Auditor of the company shall be appointed by the <b>BOD within
                            30 days</b> of Incorporation who shall hold the office till the conclusion of 1st AGM. In
                            case of First Auditor, filing of ADT-1 is not mandatory.
                        </li>
                        <li><b>Subsequent Auditor:</b> The BOD shall appoint the auditor in first AGM of company who
                            shall hold the office till the conclusion of 6th AGM and shall inform the same to ROC by
                            filing <b>ADT-1</b>. The responsibility to file Form ADT 1 is that of the company and not of
                            the auditor within <b>15 days</b> from the date of appointment.
                        </li>
                        <li><b>Annual General Meeting:</b> Every Company is required to hold an Annual General Meeting
                            on or before <b>30th September</b> every year during business hours (9 am to 6pm), on a day
                            that is not a public holiday and either at the registered office of the Company or within
                            the city, town or village where the registered office is situated. A 21 clear days’ notice
                            is required to be given for the same.
                        </li>
                        <li><b>Filing of Annual Return (Form MGT-7):</b> Every Private Limited Company is required to
                            file its Annual Return within 60 days of holding of Annual General Meeting. Annual Return
                            will be for the period 1st April to 31st March.
                        </li>
                        <li><b>Filing of Financial Statements (Form AOC-4):</b> Every Private Limited Company is
                            required to file its Balance Sheet along with statement of Profit and Loss Account and
                            Director Report in this form within 30 days of holding of Annual General Meeting.
                        </li>
                        <li><b>Statutory Audit of Accounts:</b> Every Company shall prepare its Accounts and get the
                            same audited by a Chartered Accountant at the end of the Financial Year compulsorily. The
                            Auditor shall provide an Audit Report and the Audited Financial Statements for the purpose
                            of filing it with the Registrar.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What are the compulsory compliance for a LLP? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">There are three mandatory <a
                        href={`/${productIdMappedWithSlug['17'].slug}/${parentPracticeAreaSlug['3'].slug}`} target="_blank">compliance
                        requirements</a> to be followed by <a
                        herf={`/${productIdMappedWithSlug['10'].slug}/${parentPracticeAreaSlug['5'].slug}`} target="_blank">LLPs</a>:
                    </p>
                    <ul>
                        <li>Filing of Annual Return.</li>
                        <li>Filing of Statement of the Accounts or Financial Statements.</li>
                        <li>Filing of Income Tax Returns.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">What is ROC Filing for Private Limited Company?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para"><a herf={`/${productIdMappedWithSlug['9'].slug}/${parentPracticeAreaSlug['5'].slug}`}
                                           target="_blank">Private Limited Companies</a> are required to file its <a
                        href={`/${productIdMappedWithSlug['17'].slug}/${parentPracticeAreaSlug['3'].slug}`} target="_blank">Annual
                        Accounts</a> and Returns disclosing details of its shareholders, directors etc to the Registrar
                        of Companies. Such compliances are required to be made once a year.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">What is ROC Filing for a LLP?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para"><a herf={`/${productIdMappedWithSlug['10'].slug}/${parentPracticeAreaSlug['5'].slug}`}
                                           target="_blank">LLPs</a> in India must file its <a
                        href={`/${productIdMappedWithSlug['17'].slug}/${parentPracticeAreaSlug['3'].slug}`} target="_blank">Annual
                        Return</a> within 60 days from the end of the close of the financial year and Statement of
                        Account & Solvency within 30 days from the end of six months of close of financial year. In
                        addition to the MCA annual return, LLPs must also mandatorily file income tax return every year.
                    </p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">When do we have to file AOC 4 and MGT 7?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para"><b>Every Company</b> registered under Companies Act, 2013 or Companies Act, 1956
                        is required to file their Financial Statements in <b>e-Form AOC-4</b> including Directors Report
                        along with other relevant attachments (ANNUAL REPORT) <b>within 30 days</b> and <a
                            href={`/${productIdMappedWithSlug['17'].slug}/${parentPracticeAreaSlug['3'].slug}`} target="_blank">Annual
                            Return</a> in <b>e-Form MGT-7 within 60 days</b> from conclusion of its Annual General
                        Meeting (AGM).</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">What is the penalty for not filing AOC 4 and MGT 7?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Every Company is required to file the annual accounts and the annual returns
                        within 30 days and 60 days respectively from the conclusion of the Annual General Meeting.</p>
                    <p className="para">The managing director and the Chief Financial Officer of the company, if any,
                        and, in the absence of the managing director and the Chief Financial Officer, any other director
                        who is charged by the Board with the responsibility of complying with the provisions of this
                        section, and, in the absence of any such director, all the directors of the company, shall be
                        liable to a penalty of $1,00,000/- and in case of continuing failure, with further penalty of
                        $1,000/- for each day after the first during which such failure continues, subject to a
                        maximum of $5,00,000/-.</p>
                    <p className="para"><b>Penalty for not filing MGT 7:</b> Company and its every officer who is in
                        default shall be liable to a penalty of $50,000/- and in case of continuing failure, with
                        further penalty of $100/- for each day during which such failure continues, subject to a
                        maximum of $5,00,000/-.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">Whether the Annual filing necessary for all companies?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Every Company is required to file the <a
                        href={`/${productIdMappedWithSlug['17'].slug}/${parentPracticeAreaSlug['3'].slug}`} target="_blank">annual
                        accounts</a> and the annual returns within 30 days and 60 days respectively from the conclusion
                        of the Annual General Meeting.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">Whether Statutory Audit is compulsory for all Private Limited Companies?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, it is mandatory.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#9accordian">
                <h4 className="panel-title">Is the appointment of statutory auditor falls under annual compliance?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="9accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#10accordian">
                <h4 className="panel-title">In case of no operations in the company, do directors require to sign the
                    director's report?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="10accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#11accordian">
                <h4 className="panel-title">Which form is an attachment to the companies Director Report?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="11accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">MGT 09 is a report which has to be furnished along with the director’s report in
                        the Companies Act 2013. The MGT 09 is prepared by the company when the director’s report gets
                        approved in the board meeting.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#12accordian">
                <h4 className="panel-title">Whether Annual Filing is necessary for the LLPs?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="12accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, Annual Returns are to be filed in the prescribed Form-11. This form is
                        considered as the summary of management affairs of LLP, like numbers of partners along with
                        their names. Moreover, the form 11 has to be filed by 30th May every year.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#13accordian">
                <h4 className="panel-title">What is the penalty for non-filing/delayed filing of annual compliance forms
                    for LLP?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="13accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Late filing or not filing of LLP annual return will attract a penalty of $100
                        per day from the last day of filing to the payment processing date.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#14accordian">
                <h4 className="panel-title">Whether the Statutory Audit of Financial Statements is required for LLP ROC
                    filing?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="14accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Every LLP has to file the annual return within 60 days of closure of the
                        financial year. This is prepared by filling of Form 11. Statutory Audit is mandatory for LLP
                        only if its annual turnover exceeds INR 40 lakh and/or its total paid-up capital exceeds INR 25
                        lakh.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#15accordian">
                <h4 className="panel-title">What is the difference between Statutory Audit and Tax Audit?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="15accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The differences between statutory audit and tax audit are drawn clearly on
                        the following grounds:</p>
                    <ul>
                        <li>An audit, which is required by the statute (law) is known as a Statutory audit. Tax Audit is
                            an audit made compulsory by the Income Tax Act if the turnover of the assesses reaches the
                            specified limit.
                        </li>
                        <li>Statutory Audit is performed by external auditors whereas tax audit is conducted by a
                            practising Chartered Accountant.
                        </li>
                        <li>Statutory Audit is the audit of complete accounting records. Conversely, Tax Audit is the
                            audit of tax related transactions.
                        </li>
                        <li>The purpose of the statutory audit is to ensure reliability and transparency, truthfulness
                            and fairness of financial statements. As opposed to a tax audit, which ensures proper
                            maintenance of books of accounts and they truly reflect the taxable income of the assessee
                            as well as the deductions claimed are actually made by the assessee.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#16accordian">
                <h4 className="panel-title">Is Tax Audit Compulsory for Companies and LLPs?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="16accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">No, Tax Audit is not compulsory for Companies & LLPs. It depends on the turnover
                        of the company.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#17accordian">
                <h4 className="panel-title">What are the Board Meeting compliances to be done for a Company?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="17accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">Pre – Meeting Compliances:</p>
                    <ul>
                        <li>Date of Sending Notice to Stock Exchange</li>
                        <li>Press Release for Board Meeting in Newspaper on website of the company</li>
                        <li>Press Release for Board Meeting in Newspaper</li>
                        <li>Agenda of the Meeting & Notes thereof to Directors</li>
                        <li>Intimation to Directors regarding Board Meeting</li>
                        <li>Closure of Trading Window</li>
                    </ul>
                    <p className="text-bold">Post – Meeting Compliances:</p>
                    <ul>
                        <li>Disclosure to Stock Exchange</li>
                        <li>Publication of Results in Newspaper</li>
                        <li>Minutes Preparation</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#18accordian">
                <h4 className="panel-title">What are the Annual General Meeting compliances to be done for a Company?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="18accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">A company must fulfil the following compliance post its AGM: </p>
                    <ul>
                        <li>Forward a copy of proceedings of Annual General Meeting to Stock Exchange.</li>
                        <li>Submit the details of a voting result to the Stock Exchange within 48 hours of the
                            conclusion of AGM.
                        </li>
                        <li>Pay the dividend or dispatch the warrants within 30 days from the date of declaration.</li>
                        <li>Submit the notice of Auditor’s appointment with the Registrar in Form ADT-1 within 15 days
                            of Annual General Meeting.
                        </li>
                        <li>File the Audited Financial Statements along with consolidated financial statement with the
                            Registrar within 30 days of Annual General Meeting.
                        </li>
                        <li>File Annual Return with the Registrar in MGT-7 within 60 days of Annual General Meeting. The
                            Annual Return must be signed by a Director and Company Secretary of the Company.
                        </li>
                        <li>File the report on AGM (in case of a listed public company) within 30 days of AGM.</li>
                        <li>File an explanatory statement annexed to the notice in Form MGT-14 with the Registrar for
                            special resolution and other resolutions passed under Section 117 of the Companies Act, 2013
                            within 30 days.
                        </li>
                        <li>Prepare and file a report on the Annual General Meeting with Registrar in Form MGT-15 within
                            30 days of Annual General Meeting.
                        </li>
                        <li>Prepare a statement containing the names, last known addresses and unpaid dividend to be
                            paid to each such person within 90 days of transfer to Unpaid Dividend Account and display
                            it on the website of the Company.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#19accordian">
                <h4 className="panel-title">What is DIR3 KYC? What is the last date and the penalty for non-compliance?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="19accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">DIR-3-KYC is a new E-form launched by the Ministry of Corporate Affairs. This
                        form will be used to update KYC of all directors. Every individual who is allotted DIN as on
                        31st March of a financial year must submit his KYC on or before 30th September of the
                        immediately next financial year. If the DIN holder does not file his annual KYC within the due
                        date of each financial year, such DIN shall be marked as ‘Deactivated due to non-filing of DIR-3
                        KYC’ and shall remain in such Deactivated status until KYC is done with a fee of $5000.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#20accordian">
                <h4 className="panel-title">What happens if I don’t get statutory audit done?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="20accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">If Company contravened the provisions, the company shall be punishable with fine
                        which shall be $25,000/- to $5,00,000/- and every officer of the company who is in default
                        shall be punishable with imprisonment for a term which may extend to 1 year or with fine which
                        shall not be less than $10,000/- but which may extend to $1,00,000/-, or with both.</p>
                </div>
            </div>
        </div>
    </div>],
    18: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#01accordian">
                <h4 className="panel-title">Documents required for Income Tax Filing
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="01accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>All Form 16</li>
                        <li>Bank Statements of all Bank Accounts</li>
                        <li>Investment Declaration Proof - LIC, PPF, Tax Saving FD etc.</li>
                        <li>Form 26AS</li>
                        <li>PAN & AADHAR CARD Copy</li>
                        <li>Demat Statement for Trading Income</li>
                        <li>Sale and Purchase Deed Details for Capital Gain Income</li>
                        <li>Profit & Loss Account and Balance Sheet for Business Income</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What is the Income Tax slab for FY 20-21?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <p class="para">For this FY, there are two options available to the <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">taxpayers</a> to choose.</p>
                    <p className="text-bold">New Tax slab:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Total Income ($)</th>
                            <th>Tax Rate (%)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>&lt;2.5 lakh</td>
                            <td>0</td>
                        </tr>
                        <tr>
                            <td>2.50,000-5,00,000</td>
                            <td>5</td>
                        </tr>
                        <tr>
                            <td>5,00,001- 7,50,000</td>
                            <td>10</td>
                        </tr>
                        <tr>
                            <td>7,50,001-10,00,000</td>
                            <td>15</td>
                        </tr>
                        <tr>
                            <td>10,00,001-12,50,000</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td>12,50,001- 15,00,000</td>
                            <td>25</td>
                        </tr>
                        <tr>
                            <td>&gt;15,00,000</td>
                            <td>30</td>
                        </tr>
                        </tbody>
                    </table>
                    <p className="para">If one is following this new tax slab, one shall not be entitled to enjoy any of
                        the deduction benefits under the Income Tax Act 1961. However, who are of opinion that the
                        deductions were more beneficial, they can still follow the tax slab of the FY 2019-20 to pay
                        their tax.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">Old Tax regime or the new one, which should be followed? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <p className="para">Which one you want to follow, is totally dependent on your income and
                        investments you have made.</p>
                    <p className="text-bold">Let us explain to you with an example:-</p>
                    <p className="text-bold"><a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                target="_blank">Income tax</a> payable as per old regime:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Particulars</th>
                            <th>Amount ($)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Gross Salary</td>
                            <td>10,00,000</td>
                        </tr>
                        <tr>
                            <td>Standard Deduction</td>
                            <td>37700</td>
                        </tr>
                        <tr>
                            <td>Income under the head salary</td>
                            <td>962300</td>
                        </tr>
                        <tr>
                            <td>Interest on home loan</td>
                            <td>2,00,000</td>
                        </tr>
                        <tr>
                            <td>Net Income</td>
                            <td>762300</td>
                        </tr>
                        <tr>
                            <td>Deduction u/s 80C</td>
                            <td>-1,50,000</td>
                        </tr>
                        <tr>
                            <td>Health insurance deduction u/s 80D</td>
                            <td>-75000</td>
                        </tr>

                        <tr>
                            <td>Deduction u/s 80CCD for NPS contribution</td>
                            <td>-47000</td>
                        </tr>
                        <tr>
                            <td>Taxable Income</td>
                            <td>4,90,300</td>
                        </tr>
                        <tr>
                            <td>Rate of tax levied</td>
                            <td>0% for &lt;= 2,50,000 and 5% 2,50,001 to 5,00,000</td>
                        </tr>
                        <tr>
                            <td>Amount of tax</td>
                            <td>24515</td>
                        </tr>
                        <tr>
                            <td>Health and education cess 4%</td>
                            <td>980.6</td>
                        </tr>
                        <tr>
                            <td>Tax Total</td>
                            <td>25,131.6</td>
                        </tr>
                        <tr>
                            <td>Tax rebate u/s 87A</td>
                            <td>12,500</td>
                        </tr>
                        <tr>
                            <td>Tax payable</td>
                            <td>12,631.6</td>
                        </tr>

                        </tbody>
                    </table>
                    <p className="text-bold"><a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                target="_blank">Income tax</a> payable as per the new regime:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Income ($)</th>
                            <th>Tax rate(%)</th>
                            <th>Tax ($)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>2,50,000</td>
                            <td>0</td>
                            <td>0</td>
                        </tr>
                        <tr>
                            <td>2,50,001- 5,00,000</td>
                            <td>5</td>
                            <td>12,500</td>
                        </tr>
                        <tr>
                            <td>5,00,001-7,50,000</td>
                            <td>10</td>
                            <td>25,000</td>
                        </tr>
                        <tr>
                            <td>7,50,001- 10,00,000</td>
                            <td>15</td>
                            <td>37,500</td>
                        </tr>
                        <tr>
                            <td>Total Tax</td>
                            <td></td>
                            <td>75,000</td>
                        </tr>
                        <tr>
                            <td>Health and education cess</td>
                            <td>4</td>
                            <td>3000</td>
                        </tr>
                        <tr>
                            <td>Tax Payable</td>
                            <td></td>
                            <td>78000</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">Benefits of Filing ITR even if you don’t have taxable income?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">There are then <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">benefits
                        if you file for ITR</a> under NIL:</p>
                    <ul>
                        <li>ITR receipt as an important document: For several financial, and Government jobs, the
                            receipt of ITR is considered as an important document.
                        </li>
                        <li>Address Proof: ITR receipt can be used as valid address proof for yourself.</li>
                        <li>Useful for Bank documentation: For any loan or investment purpose, ITR receipt is a document
                            on the priority list.
                        </li>
                        <li>Be ready for next FY: If you do not file for ITR, it is not possible to get the losses in
                            the previous FY. Therefore, If you face any loss in one FY, but have filed your ITR on time,
                            you can get it compensated in the next FY at the time of filing the ITR.
                        </li>
                        <li>Avoid Any Extra Charges: If you fail to file ITR, it may lead to a higher rate of interest
                            to be deducted. For example, if you do not file for ITR, then a certain amount may get
                            deducted from the interest amount you receive from your investment and FD.
                        </li>
                        <li>Avoid penalties: Missing out to file ITR may cost you a huge amount of penalties if you try
                            to file ITR post the assigned last date.
                        </li>
                        <li>Credit Card Processing: Even for proceeding with the credit card application or increase the
                            limit of the existing one, you have to furnish with the ITR documents.
                        </li>
                        <li>Visa Application: Along with the application form, you need to submit the document of ITR
                            return of the immediate past 3 years.
                        </li>
                        <li>Insurance Policy with a Higher Cover: If you want to buy an insurance policy with a high
                            cover, you need to submit the last filed ITR document, for smooth transactional proceedings.
                        </li>
                        <li>Freelancer and independent professionals: These professionals do not have a form 16 with
                            them, therefore the only document they are left with is the receipt of ITR as evidence of
                            their annual income.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">Procedure to file ITR yourself?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">For the procedure of <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">filing
                        the ITR</a> yourself, follow this official website of the Indian Tax Authority - <a
                        href="https://www.incometaxindiaefiling.gov.in/home"
                        target="_blank">https://www.incometaxindiaefiling.gov.in/home</a></p>
                    <p className="para">However, if you want professional assistance, hire your tax expert just in one
                        click.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">Is NIL ITR mandatory to be filed?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Definitely, <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">filing
                        NIL ITR</a> is important, and to know the reasons, please refer to question no. 3</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">What are the due dates for Income Tax return filing?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The <a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                               target="_blank">due date for ITR filing</a> this year is yet to be
                        declared.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">What is the penalty for a delay in filing ITR?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The deadline to <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file an
                        income tax return (ITR)</a> had been extended to August 31, 2019, for FY 2018-19. If you file
                        your return after midnight tonight then you would have to pay a late fee of up to Rs 10,000.
                        However, if your income is below the taxable limit then you won't have to pay it even if you
                        file after the deadline. Here's why:</p>
                    <p className="text-bold">The penalty you will have to pay</p>
                    <p className="para">An income taxpayer is liable to pay late ITR filing fees of:</p>
                    <ul>
                        <li>Rs 5,000 if the tax return is filed after the deadline but on or before December 31 of the
                            relevant assessment year (in this case December 31, 2019).
                        </li>
                        <li>Rs 10,000 if tax return is filed after December 31 but before the end of the relevant
                            assessment year, i.e., before March 31 (in this case between 1 January 2020 and March 31,
                            2020).
                        </li>
                    </ul>
                    <p className="text-bold">If you are a small taxpayer whose gross total income does not exceed Rs 5
                        lakh then the maximum fees you are liable to pay is Rs 1,000.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">Do I need to file a return if there is a loss in my business income?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">When your business is suffering from a loss, you must <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file the
                        ITR</a>, as the loss you suffer makes you eligible to get a tax exemption, in order to get the
                        loss amount compensated to a certain level.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#10accordian">
                <h4 className="panel-title">Can I file ITR once the last date of filing is gone?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="10accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, you can <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file the
                        ITR</a> post the last date of filing, but later you file, higher the penalty amount you end up
                        paying.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#11accordian">
                <h4 className="panel-title">Is it possible to revise the ITR even when the acknowledgment is generated?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="11accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">You can <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file a
                        revised return</a> correcting the error to avoid any penalty. Income tax laws allow taxpayers an
                        opportunity to correct the error or omission in their returns by submitting a revised tax return
                        under Section 139(5) of the IT Act.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#12accordian">
                <h4 className="panel-title">Can I file ITR for FY 18-19 and FY 17-18?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="12accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, you can <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file the
                        ITR</a> for FY 18-19 but with a huge penalty.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#13accordian">
                <h4 className="panel-title">What is form 16?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="13accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Form 16 is an <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">Income
                        tax</a> form. Form 16 is a certificate provided by your employer which certifies details
                        regarding the salary you have earned during the year and how much TDS has been deducted. It
                        consists of two parts i.e. Part A and Part B, where part A consists of information of the
                        employer & employee, like the PAN and TAN details, name and address, TDS deducted & deposited,
                        etc.</p>
                    <p className="para">It is used by the companies to provide their salaried individuals information on
                        the tax deducted. Alternatively, you can consider Form 16 as your Salary TDS certificate.</p>
                    <p className="para">Suppose the income from your salary for the financial year is more than the
                        basic exemption limit of $2,50,000. Then, in this case, your employer is required to deduct
                        TDS on your salary and deposit it with the government. When From 16 is provided to an employee
                        by their employer, it is considered as a source of proof of filing their Income Tax Returns. And
                        if your income does not fit the basic exemption limit, the employer does not deduct any TDS in
                        that case.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#14accordian">
                <h4 className="panel-title">What is form 26AS? How to get form 26AS?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="14accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Form 26AS is an annual consolidation credit statement issued under section 203AA
                        of the <a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                  target="_blank">Income Tax</a> 1961 which mentions all the tax deductions by the
                        employer, banks or someone else as defined under the Act.</p>
                    <p className="para">To download Form 26AS you can log into the income tax filing account on the
                        Income Tax Departments e filing <a href="https://www.incometaxindiaefiling.gov.in/home"
                                                           target="_blank">website</a> either directly or through the
                        Net Banking facility of authorised banks.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#15accordian">
                <h4 className="panel-title">When is a refund issued by the Income Tax Department?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="15accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The refund is usually issued within 120 days of <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">filing
                        the ITR</a>.</p>
                    <p className="para">To download Form 26AS you can log into the income tax filing account on the
                        Income Tax Departments e filing <a href="https://www.incometaxindiaefiling.gov.in/home"
                                                           target="_blank">website</a> either directly or through the
                        Net Banking facility of authorised banks.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#16accordian">
                <h4 className="panel-title">Income Tax Filing for Freelancers or professionals - Forms, Sections and
                    Exemptions? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="16accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold"><a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                target="_blank">Filing ITR</a> for freelancer:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Forms</th>
                            <th>Sections</th>
                            <th className="col-xs-8">Exemptions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Form ITR 4</td>
                            <td>Sec. 44AB</td>
                            <td>
                                <ul>
                                    <li>Sec. 80C - Deduction u to INR 1.5 lakhs for payments made towards the life
                                        insurance policies, PF, superannuation, tuition fees, construction/purchase of
                                        residential property FD etc.
                                    </li>
                                    <li>Sec. 80 CCC - Tax Deductions for investment made towards the pension plans upto
                                        INR 1.5 lakhs.
                                    </li>
                                    <li>Sec. 80 CCD - Exemptions towards investments made in the Central Government
                                        Pension Scheme. Both the employer and employee contribution are exempted.
                                    </li>
                                    <li>Sec 80CCF- The exemptions are provided for investments made in the
                                        infrastructure bonds (long term) that are notified by the Government of India.
                                        The section offers a maximum exemption of Rs 20,000
                                    </li>
                                    <li>Sec 80CCG- The section provides a maximum deduction of Rs 25,000 for the
                                        investments made in the government Equity Saving Schemes, to certain specified
                                        Indian citizens and residents.
                                    </li>
                                    <li>SEC. 80D- Under this section, expenses made towards the payment of premiums of
                                        the health insurance policies are exempted. The freelancer can also buy the
                                        policy for spouse or child, and claim the deductions
                                    </li>
                                    <li>Sec. 80DD- The section provides deductions towards treatment of normal and
                                        severe disabilities, which may go up Rs 1.25 lakhs
                                    </li>
                                    <li>Sec. 80DDB- Exemptions towards treatment of certain specified diseases</li>
                                    <li>Sec. 80E- Deductions towards loan taken for education purposes</li>
                                    <li>Sec. 80 EE- The Section is exclusively for individuals, and exempts the payments
                                        made towards a loan, for buying a property for residential purposes
                                    </li>
                                    <li>Sec. 80G- The Section offers up to a 100% deduction for the donations made to
                                        the charitable funds, including the Prime Minister Relief Fund, and the National
                                        Defense Fund among others
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>],
    19: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#01accordian">
                <h4 className="panel-title">Documents required for Accounting Services
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="01accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>All Bank Statements for Financial Year</li>
                        <li>All Cash Transactions Register</li>
                        <li>Sales Summary with Invoices</li>
                        <li>Purchases Summary with Invoices</li>
                        <li>Capital Contribution Details</li>
                        <li>Loan Details</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">Why is accounting important for a business?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Accounting is a procedure of storing, sorting and recording financial
                        transactions. At the time of tax returns, every business is required to submit their financial
                        records and transactions. It is really important for one to keep their accounts sorted from the
                        beginning and keeping the books updated as it is very difficult to deal with tax returns and
                        financial matters at the same time.</p>
                    <p className="para">Moreover, keeping your books updated gives a good impression to the shareholders
                        and investors, apart from the income tax view.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What needs to be tracked in the account books?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The Companies Act 2013, states that the account books should include everyday
                        financial transactions and their bills. Account books mostly have trial balance, copies of bills
                        and original bills, ledgers, etc. Thanks to the online accounting, matters have become easier
                        and streamlined to manage.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">Why do you need an accountant?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">It is unlikely for you to have your balance sheet tally at the end of the year
                        if you are not an accountant because there would be miss-categorization of assets, liabilities
                        and expenses. All the visible features of the business should be classified to avoid confusion
                        in the future. An expert accountant will maintain a proper record of Incomes and Expenses,
                        calculate right taxes, categorise expenses properly and provide you with monthly reports to keep
                        you updated about your finances and help you make smart money and tax saving decisions.</p>
                </div>
            </div>
        </div>
    </div>],
    20: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What are the documents required for GST Registration? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The necessary document for <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                        Registrations</a> are as follows:</p>
                    <ul>
                        <li>PAN card of the Sole Proprietor/partners/Directors</li>
                        <li>Aadhar card or Address proof Sole Proprietor/partners/Directors (Passport, driving license,
                            Voters identity card, Aadhar card etc.)
                        </li>
                        <li>Photograph of the Sole Proprietor/partners/Directors (in JPEG format, maximum size – 100
                            KB)
                        </li>
                        <li>Bank account details (Cancelled Cheque and Statement for 3 months)</li>
                        <li>Address proof - Rent Agreement with NOC if rented or Property Sale Deed if self-owned with a
                            Utility bill
                        </li>
                        <li>PAN Card and Partnership Deed only for Partnership Firm</li>
                        <li>PAN & Incorporation Certificate and Board Resolution only for LLP or Private Limited
                            Companies
                        </li>
                        <li>Board Resolution appointing Authorised Signatory only for LLP and Private Limited Company
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What is CGST? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Under GST, <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">CGST</a> is a tax levied on Intra State supplies of both goods and services by
                        the Central Government and will be governed by the CGST Act. SGST will also be levied on the
                        same Intra State supply but will be governed by the State Government.</p>
                    <p className="para">This implies that both the Central and the State governments will agree on
                        combining their levies with an appropriate proportion for revenue sharing between them. However,
                        it is clearly mentioned in Section 8 of the GST Act that the taxes be levied on all Intra-State
                        supplies of goods and/or services but the rate of tax shall not be exceeding 14%, each.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">What is SGST? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Under GST, <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">SGST</a> is a tax levied on Intra State supplies of both goods and services by
                        the State Government and will be governed by the SGST Act. As explained above, CGST will also be
                        levied on the same Intra State supply but will be governed by the Central Government.</p>
                    <p className="para">Any tax liability obtained under SGST can be set off against SGST or IGST input
                        tax credit only.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">What is IGST? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Under GST, <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">IGST</a> is a tax levied on all Inter-State supplies of goods and/or services
                        and will be governed by the IGST Act. IGST will be applicable on any supply of goods and/or
                        services in both cases of import into India and export from India.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">What is the procedure for GST registration? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The step-by-step procedure that individuals must follow to complete <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                        Registration</a> is mentioned below:</p>
                    <ul>
                        <li><b>Step 1:</b> Visit the GST portal <a href="https://www.gst.gov.in/"
                                                                   target="_blank">https://www.gst.gov.in/</a>.
                        </li>
                        <li><b>Step 2:</b> Click on the <b>"Register Now"</b> link which can be found under
                            the <b>"Taxpayers"</b> tab
                        </li>
                        <li><b>Step 3:</b> Select <b>"New Registration"</b>.</li>
                        <li><b>Step 4:</b> Fill the below-mentioned details:
                            <ul>
                                <li>Under the <b>"I am a"</b> drop-down menu, select <b>"Taxpayer"</b>.</li>
                                <li>Select the respective state and district.</li>
                                <li>Enter the name of the business.</li>
                                <li>Enter the PAN of the business.</li>
                                <li>Enter the email ID and mobile number in the respective boxes. The entered email ID
                                    and mobile number must be active as OTPs will be sent to them.
                                </li>
                                <li>Enter the image that is shown on the screen and click on <b>"Proceed"</b>.</li>
                            </ul>
                        </li>
                        <li><b>Step 5:</b> On the next page, enter the OTP that was sent to the email ID and mobile
                            number in the respective boxes.
                        </li>
                        <li><b>Step 6:</b> Once the details have been entered, click on <b>"Proceed"</b>.</li>
                        <li><b>Step 7:</b> You will be shown the Temporary Reference Number (TRN) on the screen. Make a
                            note of the TRN.
                        </li>
                        <li><b>Step 8:</b> Visit the GST portal again and click on <b>"Register"</b> under
                            the <b>"Taxpayers"</b> menu.
                        </li>
                        <li><b>Step 9:</b> Select <b>"Temporary Reference Number (TRN)"</b>.</li>
                        <li><b>Step 10:</b> Enter the TRN and the captcha details.</li>
                        <li><b>Step 11:</b> Click on <b>"Proceed"</b>.</li>
                        <li><b>Step 12:</b> You will receive an OTP on your email ID and registered mobile number. Enter
                            the OTP on the next page and click on <b>"Proceed"</b>.
                        </li>
                        <li><b>Step 13:</b> The status of your application will be available on the next page. On the
                            right side, there will be an Edit icon, click on it.
                        </li>
                        <li><b>Step 14:</b> There will be 10 sections on the next page. All the relevant details must be
                            filled, and the necessary documents must be submitted. The list of documents that must be
                            uploaded are mentioned below:
                            <ul>
                                <li>Photographs</li>
                                <li>Business address proof</li>
                                <li>Bank details such as account number, bank name, bank branch, and IFSC code.</li>
                                <li>Authorisation form</li>
                                <li>The constitution of the taxpayer.</li>
                            </ul>
                        </li>
                        <li><b>Step 15:</b> Step 15: Visit the <b>"Verification"</b> page and check the declaration,
                            Then submit the application by using one of the below mentioned methods:
                            <ul>
                                <li>By Electronic Verification Code (EVC). The code will be sent to the registered
                                    mobile number.
                                </li>
                                <li>By e-Sign method. An OTP will be sent to the mobile number linked to the Aadhaar
                                    card.
                                </li>
                                <li>In case companies are registering, the application must be submitted by using the
                                    Digital Signature Certificate (DSC).
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p className="para">Once completed, a success message will be shown on the screen. The Application
                        Reference Number (ARN) will be sent to the registered mobile number and email ID.
                        You can check the status of the ARN on the GST portal.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">What is the GST return? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The <a href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                               target="_blank">GST return</a> is the documentary accounts of all the
                        sales and purchases made by an Input Service Provider, as well as the tax collected or paid by
                        him. The GST return is generally filed on the basis of the quarter or annual every year, i.e.
                        the taxpayer can furnish this document at an interval of 3 months or once a year.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">Who is liable to file returns? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Any person who carries on a business or any economic activities at any place in
                        India and has registered himself under the <a
                            href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                            Act</a> is liable to file the returns.</p>
                    <p className="para">The word <b>person</b> does not mean only an individual, it also includes HUF,
                        company, firm, LLP, an AOP/BOI, any incorporation or Government company, a body corporate
                        incorporated with any foreign country but carrying its business in India, co-operative society,
                        local authority, government, trust, artificial person.</p>

                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">If there is no transaction in the business, is it necessary to file GST? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Even if there is no business transaction during the period covered under the
                        return, it is necessary to <a
                            href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file
                            GST</a>, but it shall be a <b>"Nil Return"</b>.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#9accordian">
                <h4 className="panel-title">Are there any exceptions, who are not liable to file GST returns? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="9accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">Certain entities are excluded from filing the <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                        return</a>:</p>
                    <ul>
                        <li>Has to register under GST but not required to file returns: UN bodies and foreign consulates
                            are exempted from GST return filing, but on the months they have made any purchase, has to
                            file returns only for those months.
                        </li>
                        <li>Neither required to register nor GST return: The Public Sector Undertakings, any business or
                            trade-in non-GST supplies, are not required to register under GST and eventually not liable
                            to file GST returns.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#10accordian">
                <h4 className="panel-title">What are the types of GST return and their due dates?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="10accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th className="text-center"><a
                                href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                target="_blank">GSTR</a></th>
                            <th className="text-center">Definition</th>
                            <th className="text-center">Documents Required/Details</th>
                            <th className="text-center">Due Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="text-center">GSTR-1</td>
                            <td>It is a monthly return of outward supplies of monthly return showcasing the sales
                                transactions made by the taxpayer
                            </td>
                            <td>
                                <ul>
                                    <li>Invoices</li>
                                    <li>Debit notes</li>
                                    <li>Credit notes</li>
                                    <li>Revised notes</li>
                                </ul>
                            </td>
                            <td>10th day of every month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-2</td>
                            <td>It is a monthly return of inward supply of goods and services, i.e. the purchase made by
                                the taxpayer in a particular month.
                            </td>
                            <td>
                                <ul>
                                    <li>Invoices</li>
                                    <li>Debit notes</li>
                                    <li>Credit notes</li>
                                </ul>
                            </td>
                            <td>Between 11th to 15th of the succeeding month.</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-2A</td>
                            <td>It is a necessary document, auto-populated which can be used for the acceptance,
                                rejection or rectification of the invoices uploaded in GSTR-1/2.
                            </td>
                            <td>
                                It is a read-only document
                            </td>
                            <td>Between 11th to 15th of the succeeding month.</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-3B</td>
                            <td>
                                It is the self-declaration of of the taxability of the taxpayer comparing the inwards
                                and outward supplies conducted in a month
                            </td>
                            <td>
                                If there is no transaction in a particular month, the taxpayer has to submit a Nil
                                return
                            </td>
                            <td>20th of the Succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-4</td>
                            <td>
                                Taxpayers having quarterly turnover upto 1.5 Crore has to pay a fixed rate of tax
                            </td>
                            <td>
                                Has to be paid in every quarter
                            </td>
                            <td>18th of the following month of the specified quarter for which GST return filing has to
                                be done.
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-5</td>
                            <td>
                                It is for the taxpayer who has an economic activity in India, but himself stays out of
                                India. It is a monthly return filing
                            </td>
                            <td>
                                <ul>
                                    <li>Inwards Supply</li>
                                    <li>Outward Supply</li>
                                    <li>Any interest/penalty/fees</li>
                                    <li>Tax amount</li>
                                    <li>Any other amount payable</li>
                                </ul>
                            </td>
                            <td>20th of the succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-6</td>
                            <td>It is a monthly GST return filing done by the Input Service Distributor
                            </td>
                            <td>
                                <ul>
                                    <li>Summary of the total input tax credit available for distribution.</li>
                                    <li>Invoices</li>
                                    <li>Debit Notes</li>
                                    <li>Credit Notes</li>
                                </ul>
                            </td>
                            <td>13th Day of the succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-7</td>
                            <td>This GST Return filing has to be done monthly by the ones who are deducting TDS
                            </td>
                            <td>
                                <ul>
                                    <li>Tax Deducted at source</li>
                                    <li>Liability towards TDS</li>
                                    <li>TDS refund claimed</li>
                                    <li>Interest, late fees</li>
                                </ul>
                            </td>
                            <td>10th Day of the Succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-8</td>
                            <td>

                                This is the GST Return filing which has to be filed by every e-commerce operator who
                                deducts TDS

                            </td>
                            <td>
                                <ul>
                                    <li>Details of sale/supplies made through an e-commerce portal</li>
                                    <li>TDS deducted</li>
                                </ul>
                            </td>
                            <td>10th day of succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-9</td>
                            <td>This is for each and every taxpayer registered under the GST Act. They have to furnish
                                the annual GST return filing electronically
                            </td>
                            <td>
                                There are certain exceptions:
                                <ul>
                                    <li>Input Service Provider
                                    </li>
                                    <li>Taxpayer U/S 51/52</li>
                                    <li>Casual Taxpayer</li>
                                    <li>NRI Taxpayer</li>
                                </ul>
                            </td>
                            <td>30th November 2019</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR 9A</td>
                            <td>Annual return in addition to the quarterly return by a composition dealer in a financial
                                year
                            </td>
                            <td>
                                <ul>
                                    <li>Inward and outward supplies4</li>
                                    <li>Tax paid</li>
                                    <li>Input credit availed/reversed</li>
                                    <li>Tax refunds</li>
                                    <li>Penalties</li>
                                </ul>
                            </td>
                            <td>December 31st succeeding the close of a particular financial year</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 9B</td>
                            <td>It is the GST return filing furnished by the e-commerce operators monthly</td>
                            <td></td>
                            <td>31st December of the succeeding year</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 9C</td>
                            <td>If the taxpayer’s turnover is more than $200k has to proceed for this GST return
                                filing after being audited by a CA
                            </td>
                            <td>
                                <ul>
                                    <li>Annual return</li>
                                    <li>Copy of audited account</li>
                                    <li>Reconciliation statement</li>
                                </ul>
                            </td>
                            <td>31st December of the subsequent financial year</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 10</td>
                            <td>This GST return filing has to be filed by the person whose GST registration has been
                                cancelled
                            </td>
                            <td>It has to be filed electronically on the portal or through a facilitation Center
                                prescribed by the Commissioner
                            </td>
                            <td>3 months following the date of cancellation</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 11</td>
                            <td>The foreign embassies or diplomatic mission who are not liable to pay tax in India, are
                                allotted a UIN. This GST return filing has to be conducted by them.
                            </td>
                            <td>The UIN is allotted to:
                                <ul>
                                    <li>Specialized agency of the UNO</li>
                                    <li>Consulate/embassy of a foreign country</li>
                                    <li>Multilateral financial institution and organization under the UN (Privileges and
                                        Immunities) Act 1947
                                    </li>
                                    <li>Any other person/class of persons specified by the COmmissioner</li>
                                </ul>
                            </td>
                            <td>28th of the succeeding month.</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#11accordian">
                <h4 className="panel-title">How to apply for GST cancellation? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="11accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">You can do that yourself, or can <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">hire a
                        professional</a> for a smoother and expert proceeding.</p>
                    <ul>
                        <li><b>Step 1:</b> Visit the official GST website using the following link <a
                            href="https://www.gst.gov.in/" target="_blank">https://www.gst.gov.in</a> and login to your
                            GST portal
                        </li>
                        <li><b>Step 2:</b> Under the service option on your dashboard a drop-down will appear. Choose
                            Registration and then select Application for Cancellation of Registration.
                        </li>
                    </ul>
                    <p className="text-bold">Once you select Application for Cancellation of Registration three option
                        will appear:</p>
                    <ul>
                        <li>Basic Details</li>
                        <li>Cancellation details</li>
                        <li>Verification</li>
                    </ul>
                    <ul>
                        <li><b>Step 3:</b> Fill in the Basic Details that is required and click on the Save and Continue
                            Button <br/> In the Address for a place of business and for future correspondence is same
                            then click on <b>same as the above option</b></li>
                        <li><b>Step 4:</b> Fill in the reason for cancellation. <b>6 option will appear</b> as the
                            Reason for cancellation
                            <ol>
                                <li>Change in the constitution of business leading to change in PAN</li>
                                <li>Ceased to be liable to pay tax</li>
                                <li>Discontinuance of business / Closure of business</li>
                                <li>Others</li>
                                <li>Transfer of business on account of amalgamation, de-merger, sale, leased or
                                    otherwise
                                </li>
                                <li>Death of Sole Proprietor</li>
                            </ol>
                        </li>
                    </ul>
                    <p className="text-bold">Information Required to fill the cancellation form</p>
                    <ul>
                        <li>Address, Email-id and Registered Mobile Number</li>
                        <li>Reason for cancellation and the desired date to Cancel</li>
                        <li>Particulars on which tax is payable: input available on semi-finished and finished goods,
                            Stock of input and stock of capital. On filling the tax liability, you would be manually
                            required to fill in the value.
                        </li>
                        <li>You would be required to fill in either Electronic Cash ledger or Electronic Credit ledger
                            to set off the tax liability
                        </li>
                        <li>Details of the last return filed by the Taxpayer</li>
                    </ul>
                    <p className="para">When the existing unit is merged/de-merger/transferred, etc., then the details
                        of registration of the entity, i.e., GSTIN on the transferee entity.</p>
                    <ul>
                        <li><b>Step 5:</b> On filing the required reason and details, the verification option will
                            appear. Fill in the name of the authorized signatory and the place of making the
                            declaration. Depending upon the signatory, fill in the details and choose the relevant
                            option to submit. In the case of a company or LLP, DSC is mandatory. OTP will be generated
                        </li>
                    </ul>
                    <p className="text-bold">In case the taxpayer has issued an invoice, he/she would be required to
                        fill GST REG-16. The Above step is valid when the invoice is not issued.</p>
                    <p className="text-bold">Where GST REG-16 is filed by the Taxpayer, the officer will within 30 days
                        issue the order of cancellation.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#12accordian">
                <h4 className="panel-title">What is a Composition Scheme and eligibility for applying in Composition
                    Scheme? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="12accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Getting registered under composition scheme is optional and voluntary. Any
                        business which has a turnover of less than One crore or $75k for the specified states
                        can opt for this scheme but on any given day, if turnover crosses the above-mentioned limit,
                        then he becomes ineligible and has to take registration under the regular scheme. There are
                        certain conditions that need to be fulfilled before opting for composition levy.</p>
                    <p className="text-bold">They are as follows:</p>
                    <ul>
                        <li>Any assessee who only deals in supply of goods can opt for this scheme that means this
                            provision is not applicable for service providers. However, restaurant service providers are
                            excluded.
                        </li>
                        <li>There should not be any interstate supply of goods that means businesses having only an
                            intra-state supply of goods are eligible.
                        </li>
                        <li>Any dealer who is supplying goods through electronic commerce operator will be barred from
                            being registered under the composition scheme. For example: If M/s ABC sells its products
                            through Flipkart or Amazon (Electronic Commerce Operator), then M/s. ABC cannot opt for
                            composition scheme.
                        </li>
                        <li>The composition scheme is levied for all business verticals with the same PAN. A taxable
                            person will not have the option to select a composition scheme for one, opt to pay taxes for
                            others. For example, A taxable person has the following Business verticals separately
                            registered – Sale of footwear, the sale of mobiles, Franchisee of McDonald’s. Here the
                            composition scheme will be available to all 3 business verticals.
                        </li>
                        <li>Dealers are not allowed to collect composition tax from the recipient of supplies, and
                            neither are they allowed to take Input Tax Credit.
                        </li>
                        <li>If the person is not eligible under composition scheme, tax liability shall be TAX +
                            Interest and penalty which shall be equal to the amount of tax.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>],
    21: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What are the documents required for Trademark Registration for Small
                    Business - Basic <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>Identify proof of the trademark owner - PAN and AADHAR Copy</li>
                        <li>Address proof of the trademark owner - Passport, Driving License</li>
                        <li>Copy of TM to be registered - Logo on the Letterhead or Brand Name on the Letterhead</li>
                        <li>Logo in JPG Format</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>],
    22: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What are the documents required for Trademark Registration for Small
                    Business - Company<i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>Identify proof of the trademark owner - PAN and AADHAR Copy</li>
                        <li>Address proof of the trademark owner - Passport, Driving License</li>
                        <li>Copy of TM to be registered - Logo on the Letterhead or Brand Name on the Letterhead</li>
                        <li>Logo in JPG Format</li>
                        <li>Incorporation Certificate</li>
                        <li>Address Proof of Company - Rent Agreement with NOC if Rented or Sale Deed with latest
                            Utility Bill if self owned
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>],
    35: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What are the documents required for GST return Filing? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The necessary document for <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                        return Filing</a> are as follows:</p>
                    <ul>
                        <li>GST Number and Login Details</li>
                        <li>Sales Invoices or Summary</li>
                        <li>purchase Invoice s or Summary</li>
                        <li>Debit notes & Credit Note Summary</li>
                        <li>Bank Statements if required</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What is CGST? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Under GST, <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">CGST</a> is a tax levied on Intra State supplies of both goods and services by
                        the Central Government and will be governed by the CGST Act. SGST will also be levied on the
                        same Intra State supply but will be governed by the State Government.</p>
                    <p className="para">This implies that both the Central and the State governments will agree on
                        combining their levies with an appropriate proportion for revenue sharing between them. However,
                        it is clearly mentioned in Section 8 of the GST Act that the taxes be levied on all Intra-State
                        supplies of goods and/or services but the rate of tax shall not be exceeding 14%, each.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">What is SGST? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Under GST, <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">SGST</a> is a tax levied on Intra State supplies of both goods and services by
                        the State Government and will be governed by the SGST Act. As explained above, CGST will also be
                        levied on the same Intra State supply but will be governed by the Central Government.</p>
                    <p className="para">Any tax liability obtained under SGST can be set off against SGST or IGST input
                        tax credit only.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">What is IGST? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Under GST, <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">IGST</a> is a tax levied on all Inter-State supplies of goods and/or services
                        and will be governed by the IGST Act. IGST will be applicable on any supply of goods and/or
                        services in both cases of import into India and export from India.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">What is the procedure for GST registration? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The step-by-step procedure that individuals must follow to complete <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                        Registration</a> is mentioned below:</p>
                    <ul>
                        <li><b>Step 1:</b> Visit the GST portal <a href="https://www.gst.gov.in/"
                                                                   target="_blank">https://www.gst.gov.in/</a>.
                        </li>
                        <li><b>Step 2:</b> Click on the <b>"Register Now"</b> link which can be found under
                            the <b>"Taxpayers"</b> tab
                        </li>
                        <li><b>Step 3:</b> Select <b>"New Registration"</b>.</li>
                        <li><b>Step 4:</b> Fill the below-mentioned details:
                            <ul>
                                <li>Under the <b>"I am a"</b> drop-down menu, select <b>"Taxpayer"</b>.</li>
                                <li>Select the respective state and district.</li>
                                <li>Enter the name of the business.</li>
                                <li>Enter the PAN of the business.</li>
                                <li>Enter the email ID and mobile number in the respective boxes. The entered email ID
                                    and mobile number must be active as OTPs will be sent to them.
                                </li>
                                <li>Enter the image that is shown on the screen and click on <b>"Proceed"</b>.</li>
                            </ul>
                        </li>
                        <li><b>Step 5:</b> On the next page, enter the OTP that was sent to the email ID and mobile
                            number in the respective boxes.
                        </li>
                        <li><b>Step 6:</b> Once the details have been entered, click on <b>"Proceed"</b>.</li>
                        <li><b>Step 7:</b> You will be shown the Temporary Reference Number (TRN) on the screen. Make a
                            note of the TRN.
                        </li>
                        <li><b>Step 8:</b> Visit the GST portal again and click on <b>"Register"</b> under
                            the <b>"Taxpayers"</b> menu.
                        </li>
                        <li><b>Step 9:</b> Select <b>"Temporary Reference Number (TRN)"</b>.</li>
                        <li><b>Step 10:</b> Enter the TRN and the captcha details.</li>
                        <li><b>Step 11:</b> Click on <b>"Proceed"</b>.</li>
                        <li><b>Step 12:</b> You will receive an OTP on your email ID and registered mobile number. Enter
                            the OTP on the next page and click on <b>"Proceed"</b>.
                        </li>
                        <li><b>Step 13:</b> The status of your application will be available on the next page. On the
                            right side, there will be an Edit icon, click on it.
                        </li>
                        <li><b>Step 14:</b> There will be 10 sections on the next page. All the relevant details must be
                            filled, and the necessary documents must be submitted. The list of documents that must be
                            uploaded are mentioned below:
                            <ul>
                                <li>Photographs</li>
                                <li>Business address proof</li>
                                <li>Bank details such as account number, bank name, bank branch, and IFSC code.</li>
                                <li>Authorisation form</li>
                                <li>The constitution of the taxpayer.</li>
                            </ul>
                        </li>
                        <li><b>Step 15:</b> Step 15: Visit the <b>"Verification"</b> page and check the declaration,
                            Then submit the application by using one of the below mentioned methods:
                            <ul>
                                <li>By Electronic Verification Code (EVC). The code will be sent to the registered
                                    mobile number.
                                </li>
                                <li>By e-Sign method. An OTP will be sent to the mobile number linked to the Aadhaar
                                    card.
                                </li>
                                <li>In case companies are registering, the application must be submitted by using the
                                    Digital Signature Certificate (DSC).
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p className="para">Once completed, a success message will be shown on the screen. The Application
                        Reference Number (ARN) will be sent to the registered mobile number and email ID.
                        You can check the status of the ARN on the GST portal.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">What is the GST return? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The <a href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                               target="_blank">GST return</a> is the documentary accounts of all the
                        sales and purchases made by an Input Service Provider, as well as the tax collected or paid by
                        him. The GST return is generally filed on the basis of the quarter or annual every year, i.e.
                        the taxpayer can furnish this document at an interval of 3 months or once a year.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">Who is liable to file returns? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Any person who carries on a business or any economic activities at any place in
                        India and has registered himself under the <a
                            href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                            Act</a> is liable to file the returns.</p>
                    <p className="para">The word <b>person</b> does not mean only an individual, it also includes HUF,
                        company, firm, LLP, an AOP/BOI, any incorporation or Government company, a body corporate
                        incorporated with any foreign country but carrying its business in India, co-operative society,
                        local authority, government, trust, artificial person.</p>

                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">If there is no transaction in the business, is it necessary to file GST? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Even if there is no business transaction during the period covered under the
                        return, it is necessary to <a
                            href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file
                            GST</a>, but it shall be a <b>"Nil Return"</b>.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#9accordian">
                <h4 className="panel-title">Are there any exceptions, who are not liable to file GST returns? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="9accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">Certain entities are excluded from filing the <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                        return</a>:</p>
                    <ul>
                        <li>Has to register under GST but not required to file returns: UN bodies and foreign consulates
                            are exempted from GST return filing, but on the months they have made any purchase, has to
                            file returns only for those months.
                        </li>
                        <li>Neither required to register nor GST return: The Public Sector Undertakings, any business or
                            trade-in non-GST supplies, are not required to register under GST and eventually not liable
                            to file GST returns.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#10accordian">
                <h4 className="panel-title">What are the types of GST return and their due dates?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="10accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th className="text-center"><a
                                href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                target="_blank">GSTR</a></th>
                            <th className="text-center">Definition</th>
                            <th className="text-center">Documents Required/Details</th>
                            <th className="text-center">Due Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="text-center">GSTR-1</td>
                            <td>It is a monthly return of outward supplies of monthly return showcasing the sales
                                transactions made by the taxpayer
                            </td>
                            <td>
                                <ul>
                                    <li>Invoices</li>
                                    <li>Debit notes</li>
                                    <li>Credit notes</li>
                                    <li>Revised notes</li>
                                </ul>
                            </td>
                            <td>10th day of every month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-2</td>
                            <td>It is a monthly return of inward supply of goods and services, i.e. the purchase made by
                                the taxpayer in a particular month.
                            </td>
                            <td>
                                <ul>
                                    <li>Invoices</li>
                                    <li>Debit notes</li>
                                    <li>Credit notes</li>
                                </ul>
                            </td>
                            <td>Between 11th to 15th of the succeeding month.</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-2A</td>
                            <td>It is a necessary document, auto-populated which can be used for the acceptance,
                                rejection or rectification of the invoices uploaded in GSTR-1/2.
                            </td>
                            <td>
                                It is a read-only document
                            </td>
                            <td>Between 11th to 15th of the succeeding month.</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-3B</td>
                            <td>
                                It is the self-declaration of of the taxability of the taxpayer comparing the inwards
                                and outward supplies conducted in a month
                            </td>
                            <td>
                                If there is no transaction in a particular month, the taxpayer has to submit a Nil
                                return
                            </td>
                            <td>20th of the Succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-4</td>
                            <td>
                                Taxpayers having quarterly turnover upto $1.5k has to pay a fixed rate of tax
                            </td>
                            <td>
                                Has to be paid in every quarter
                            </td>
                            <td>18th of the following month of the specified quarter for which GST return filing has to
                                be done.
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-5</td>
                            <td>
                                It is for the taxpayer who has an economic activity in India, but himself stays out of
                                India. It is a monthly return filing
                            </td>
                            <td>
                                <ul>
                                    <li>Inwards Supply</li>
                                    <li>Outward Supply</li>
                                    <li>Any interest/penalty/fees</li>
                                    <li>Tax amount</li>
                                    <li>Any other amount payable</li>
                                </ul>
                            </td>
                            <td>20th of the succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-6</td>
                            <td>It is a monthly GST return filing done by the Input Service Distributor
                            </td>
                            <td>
                                <ul>
                                    <li>Summary of the total input tax credit available for distribution.</li>
                                    <li>Invoices</li>
                                    <li>Debit Notes</li>
                                    <li>Credit Notes</li>
                                </ul>
                            </td>
                            <td>13th Day of the succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-7</td>
                            <td>This GST Return filing has to be done monthly by the ones who are deducting TDS
                            </td>
                            <td>
                                <ul>
                                    <li>Tax Deducted at source</li>
                                    <li>Liability towards TDS</li>
                                    <li>TDS refund claimed</li>
                                    <li>Interest, late fees</li>
                                </ul>
                            </td>
                            <td>10th Day of the Succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-8</td>
                            <td>

                                This is the GST Return filing which has to be filed by every e-commerce operator who
                                deducts TDS

                            </td>
                            <td>
                                <ul>
                                    <li>Details of sale/supplies made through an e-commerce portal</li>
                                    <li>TDS deducted</li>
                                </ul>
                            </td>
                            <td>10th day of succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-9</td>
                            <td>This is for each and every taxpayer registered under the GST Act. They have to furnish
                                the annual GST return filing electronically
                            </td>
                            <td>
                                There are certain exceptions:
                                <ul>
                                    <li>Input Service Provider
                                    </li>
                                    <li>Taxpayer U/S 51/52</li>
                                    <li>Casual Taxpayer</li>
                                    <li>NRI Taxpayer</li>
                                </ul>
                            </td>
                            <td>30th November 2019</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR 9A</td>
                            <td>Annual return in addition to the quarterly return by a composition dealer in a financial
                                year
                            </td>
                            <td>
                                <ul>
                                    <li>Inward and outward supplies4</li>
                                    <li>Tax paid</li>
                                    <li>Input credit availed/reversed</li>
                                    <li>Tax refunds</li>
                                    <li>Penalties</li>
                                </ul>
                            </td>
                            <td>December 31st succeeding the close of a particular financial year</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 9B</td>
                            <td>It is the GST return filing furnished by the e-commerce operators monthly</td>
                            <td></td>
                            <td>31st December of the succeeding year</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 9C</td>
                            <td>If the taxpayer’s turnover is more than 200k has to proceed for this GST return
                                filing after being audited by a CA
                            </td>
                            <td>
                                <ul>
                                    <li>Annual return</li>
                                    <li>Copy of audited account</li>
                                    <li>Reconciliation statement</li>
                                </ul>
                            </td>
                            <td>31st December of the subsequent financial year</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 10</td>
                            <td>This GST return filing has to be filed by the person whose GST registration has been
                                cancelled
                            </td>
                            <td>It has to be filed electronically on the portal or through a facilitation Center
                                prescribed by the Commissioner
                            </td>
                            <td>3 months following the date of cancellation</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 11</td>
                            <td>The foreign embassies or diplomatic mission who are not liable to pay tax in India, are
                                allotted a UIN. This GST return filing has to be conducted by them.
                            </td>
                            <td>The UIN is allotted to:
                                <ul>
                                    <li>Specialized agency of the UNO</li>
                                    <li>Consulate/embassy of a foreign country</li>
                                    <li>Multilateral financial institution and organization under the UN (Privileges and
                                        Immunities) Act 1947
                                    </li>
                                    <li>Any other person/class of persons specified by the COmmissioner</li>
                                </ul>
                            </td>
                            <td>28th of the succeeding month.</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#11accordian">
                <h4 className="panel-title">How to apply for GST cancellation? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="11accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">You can do that yourself, or can <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">hire a
                        professional</a> for a smoother and expert proceeding.</p>
                    <ul>
                        <li><b>Step 1:</b> Visit the official GST website using the following link <a
                            href="https://www.gst.gov.in/" target="_blank">https://www.gst.gov.in</a> and login to your
                            GST portal
                        </li>
                        <li><b>Step 2:</b> Under the service option on your dashboard a drop-down will appear. Choose
                            Registration and then select Application for Cancellation of Registration.
                        </li>
                    </ul>
                    <p className="text-bold">Once you select Application for Cancellation of Registration three option
                        will appear:</p>
                    <ul>
                        <li>Basic Details</li>
                        <li>Cancellation details</li>
                        <li>Verification</li>
                    </ul>
                    <ul>
                        <li><b>Step 3:</b> Fill in the Basic Details that is required and click on the Save and Continue
                            Button <br/> In the Address for a place of business and for future correspondence is same
                            then click on <b>same as the above option</b></li>
                        <li><b>Step 4:</b> Fill in the reason for cancellation. <b>6 option will appear</b> as the
                            Reason for cancellation
                            <ol>
                                <li>Change in the constitution of business leading to change in PAN</li>
                                <li>Ceased to be liable to pay tax</li>
                                <li>Discontinuance of business / Closure of business</li>
                                <li>Others</li>
                                <li>Transfer of business on account of amalgamation, de-merger, sale, leased or
                                    otherwise
                                </li>
                                <li>Death of Sole Proprietor</li>
                            </ol>
                        </li>
                    </ul>
                    <p className="text-bold">Information Required to fill the cancellation form</p>
                    <ul>
                        <li>Address, Email-id and Registered Mobile Number</li>
                        <li>Reason for cancellation and the desired date to Cancel</li>
                        <li>Particulars on which tax is payable: input available on semi-finished and finished goods,
                            Stock of input and stock of capital. On filling the tax liability, you would be manually
                            required to fill in the value.
                        </li>
                        <li>You would be required to fill in either Electronic Cash ledger or Electronic Credit ledger
                            to set off the tax liability
                        </li>
                        <li>Details of the last return filed by the Taxpayer</li>
                    </ul>
                    <p className="para">When the existing unit is merged/de-merger/transferred, etc., then the details
                        of registration of the entity, i.e., GSTIN on the transferee entity.</p>
                    <ul>
                        <li><b>Step 5:</b> On filing the required reason and details, the verification option will
                            appear. Fill in the name of the authorized signatory and the place of making the
                            declaration. Depending upon the signatory, fill in the details and choose the relevant
                            option to submit. In the case of a company or LLP, DSC is mandatory. OTP will be generated
                        </li>
                    </ul>
                    <p className="text-bold">In case the taxpayer has issued an invoice, he/she would be required to
                        fill GST REG-16. The Above step is valid when the invoice is not issued.</p>
                    <p className="text-bold">Where GST REG-16 is filed by the Taxpayer, the officer will within 30 days
                        issue the order of cancellation.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#12accordian">
                <h4 className="panel-title">What is a Composition Scheme and eligibility for applying in Composition
                    Scheme? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="12accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Getting registered under composition scheme is optional and voluntary. Any
                        business which has a turnover of less than $100k or $75k for the specified states
                        can opt for this scheme but on any given day, if turnover crosses the above-mentioned limit,
                        then he becomes ineligible and has to take registration under the regular scheme. There are
                        certain conditions that need to be fulfilled before opting for composition levy.</p>
                    <p className="text-bold">They are as follows:</p>
                    <ul>
                        <li>Any assessee who only deals in supply of goods can opt for this scheme that means this
                            provision is not applicable for service providers. However, restaurant service providers are
                            excluded.
                        </li>
                        <li>There should not be any interstate supply of goods that means businesses having only an
                            intra-state supply of goods are eligible.
                        </li>
                        <li>Any dealer who is supplying goods through electronic commerce operator will be barred from
                            being registered under the composition scheme. For example: If M/s ABC sells its products
                            through Flipkart or Amazon (Electronic Commerce Operator), then M/s. ABC cannot opt for
                            composition scheme.
                        </li>
                        <li>The composition scheme is levied for all business verticals with the same PAN. A taxable
                            person will not have the option to select a composition scheme for one, opt to pay taxes for
                            others. For example, A taxable person has the following Business verticals separately
                            registered – Sale of footwear, the sale of mobiles, Franchisee of McDonald’s. Here the
                            composition scheme will be available to all 3 business verticals.
                        </li>
                        <li>Dealers are not allowed to collect composition tax from the recipient of supplies, and
                            neither are they allowed to take Input Tax Credit.
                        </li>
                        <li>If the person is not eligible under composition scheme, tax liability shall be TAX +
                            Interest and penalty which shall be equal to the amount of tax.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>],
    41: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#01accordian">
                <h4 className="panel-title">Documents required for Income Tax Filing
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="01accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>All Form 16</li>
                        <li>Bank Statements of all Bank Accounts</li>
                        <li>Investment Declaration Proof - LIC, PPF, Tax Saving FD etc.</li>
                        <li>Form 26AS</li>
                        <li>PAN & AADHAR CARD Copy</li>
                        <li>Demat Statement for Trading Income</li>
                        <li>Sale and Purchase Deed Details for Capital Gain Income</li>
                        <li>Profit & Loss Account and Balance Sheet for Business Income</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What is the Income Tax slab for FY 20-21?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p class="para">For this FY, there are two options available to the <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">taxpayers</a> to choose.</p>
                    <p className="text-bold">New Tax slab:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Total Income ($)</th>
                            <th>Tax Rate (%)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>&lt;2.5 lakh</td>
                            <td>0</td>
                        </tr>
                        <tr>
                            <td>2.50,000-5,00,000</td>
                            <td>5</td>
                        </tr>
                        <tr>
                            <td>5,00,001- 7,50,000</td>
                            <td>10</td>
                        </tr>
                        <tr>
                            <td>7,50,001-10,00,000</td>
                            <td>15</td>
                        </tr>
                        <tr>
                            <td>10,00,001-12,50,000</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td>12,50,001- 15,00,000</td>
                            <td>25</td>
                        </tr>
                        <tr>
                            <td>&gt;15,00,000</td>
                            <td>30</td>
                        </tr>
                        </tbody>
                    </table>
                    <p className="para">If one is following this new tax slab, one shall not be entitled to enjoy any of
                        the deduction benefits under the Income Tax Act 1961. However, who are of opinion that the
                        deductions were more beneficial, they can still follow the tax slab of the FY 2019-20 to pay
                        their tax.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">Old Tax regime or the new one, which should be followed? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <p className="para">Which one you want to follow, is totally dependent on your income and
                        investments you have made.</p>
                    <p className="text-bold">Let us explain to you with an example:-</p>
                    <p className="text-bold"><a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                target="_blank">Income tax</a> payable as per old regime:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Particulars</th>
                            <th>Amount ($)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Gross Salary</td>
                            <td>10,00,000</td>
                        </tr>
                        <tr>
                            <td>Standard Deduction</td>
                            <td>37700</td>
                        </tr>
                        <tr>
                            <td>Income under the head salary</td>
                            <td>962300</td>
                        </tr>
                        <tr>
                            <td>Interest on home loan</td>
                            <td>2,00,000</td>
                        </tr>
                        <tr>
                            <td>Net Income</td>
                            <td>762300</td>
                        </tr>
                        <tr>
                            <td>Deduction u/s 80C</td>
                            <td>-1,50,000</td>
                        </tr>
                        <tr>
                            <td>Health insurance deduction u/s 80D</td>
                            <td>-75000</td>
                        </tr>

                        <tr>
                            <td>Deduction u/s 80CCD for NPS contribution</td>
                            <td>-47000</td>
                        </tr>
                        <tr>
                            <td>Taxable Income</td>
                            <td>4,90,300</td>
                        </tr>
                        <tr>
                            <td>Rate of tax levied</td>
                            <td>0% for &lt;= 2,50,000 and 5% 2,50,001 to 5,00,000</td>
                        </tr>
                        <tr>
                            <td>Amount of tax</td>
                            <td>24515</td>
                        </tr>
                        <tr>
                            <td>Health and education cess 4%</td>
                            <td>980.6</td>
                        </tr>
                        <tr>
                            <td>Tax Total</td>
                            <td>25,131.6</td>
                        </tr>
                        <tr>
                            <td>Tax rebate u/s 87A</td>
                            <td>12,500</td>
                        </tr>
                        <tr>
                            <td>Tax payable</td>
                            <td>12,631.6</td>
                        </tr>

                        </tbody>
                    </table>
                    <p className="text-bold"><a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                target="_blank">Income tax</a> payable as per the new regime:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Income ($)</th>
                            <th>Tax rate(%)</th>
                            <th>Tax ($)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>2,50,000</td>
                            <td>0</td>
                            <td>0</td>
                        </tr>
                        <tr>
                            <td>2,50,001- 5,00,000</td>
                            <td>5</td>
                            <td>12,500</td>
                        </tr>
                        <tr>
                            <td>5,00,001-7,50,000</td>
                            <td>10</td>
                            <td>25,000</td>
                        </tr>
                        <tr>
                            <td>7,50,001- 10,00,000</td>
                            <td>15</td>
                            <td>37,500</td>
                        </tr>
                        <tr>
                            <td>Total Tax</td>
                            <td></td>
                            <td>75,000</td>
                        </tr>
                        <tr>
                            <td>Health and education cess</td>
                            <td>4</td>
                            <td>3000</td>
                        </tr>
                        <tr>
                            <td>Tax Payable</td>
                            <td></td>
                            <td>78000</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">Benefits of Filing ITR even if you don’t have taxable income?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">There are then <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">benefits
                        if you file for ITR</a> under NIL:</p>
                    <ul>
                        <li>ITR receipt as an important document: For several financial, and Government jobs, the
                            receipt of ITR is considered as an important document.
                        </li>
                        <li>Address Proof: ITR receipt can be used as valid address proof for yourself.</li>
                        <li>Useful for Bank documentation: For any loan or investment purpose, ITR receipt is a document
                            on the priority list.
                        </li>
                        <li>Be ready for next FY: If you do not file for ITR, it is not possible to get the losses in
                            the previous FY. Therefore, If you face any loss in one FY, but have filed your ITR on time,
                            you can get it compensated in the next FY at the time of filing the ITR.
                        </li>
                        <li>Avoid Any Extra Charges: If you fail to file ITR, it may lead to a higher rate of interest
                            to be deducted. For example, if you do not file for ITR, then a certain amount may get
                            deducted from the interest amount you receive from your investment and FD.
                        </li>
                        <li>Avoid penalties: Missing out to file ITR may cost you a huge amount of penalties if you try
                            to file ITR post the assigned last date.
                        </li>
                        <li>Credit Card Processing: Even for proceeding with the credit card application or increase the
                            limit of the existing one, you have to furnish with the ITR documents.
                        </li>
                        <li>Visa Application: Along with the application form, you need to submit the document of ITR
                            return of the immediate past 3 years.
                        </li>
                        <li>Insurance Policy with a Higher Cover: If you want to buy an insurance policy with a high
                            cover, you need to submit the last filed ITR document, for smooth transactional proceedings.
                        </li>
                        <li>Freelancer and independent professionals: These professionals do not have a form 16 with
                            them, therefore the only document they are left with is the receipt of ITR as evidence of
                            their annual income.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">Procedure to file ITR yourself?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">For the procedure of <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">filing
                        the ITR</a> yourself, follow this official website of the Indian Tax Authority - <a
                        href="https://www.incometaxindiaefiling.gov.in/home"
                        target="_blank">https://www.incometaxindiaefiling.gov.in/home</a></p>
                    <p className="para">However, if you want professional assistance, hire your tax expert just in one
                        click.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">Is NIL ITR mandatory to be filed?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Definitely, <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">filing
                        NIL ITR</a> is important, and to know the reasons, please refer to question no. 3</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">What are the due dates for Income Tax return filing?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The <a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                               target="_blank">due date for ITR filing</a> this year is yet to be
                        declared.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">What is the penalty for a delay in filing ITR?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The deadline to <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file an
                        income tax return (ITR)</a> had been extended to August 31, 2019, for FY 2018-19. If you file
                        your return after midnight tonight then you would have to pay a late fee of up to Rs 10,000.
                        However, if your income is below the taxable limit then you won't have to pay it even if you
                        file after the deadline. Here's why:</p>
                    <p className="text-bold">The penalty you will have to pay</p>
                    <p className="para">An income taxpayer is liable to pay late ITR filing fees of:</p>
                    <ul>
                        <li>Rs 5,000 if the tax return is filed after the deadline but on or before December 31 of the
                            relevant assessment year (in this case December 31, 2019).
                        </li>
                        <li>Rs 10,000 if tax return is filed after December 31 but before the end of the relevant
                            assessment year, i.e., before March 31 (in this case between 1 January 2020 and March 31,
                            2020).
                        </li>
                    </ul>
                    <p className="text-bold">If you are a small taxpayer whose gross total income does not exceed Rs 5
                        lakh then the maximum fees you are liable to pay is Rs 1,000.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">Do I need to file a return if there is a loss in my business income?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">When your business is suffering from a loss, you must <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file the
                        ITR</a>, as the loss you suffer makes you eligible to get a tax exemption, in order to get the
                        loss amount compensated to a certain level.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#10accordian">
                <h4 className="panel-title">Can I file ITR once the last date of filing is gone?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="10accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, you can <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file the
                        ITR</a> post the last date of filing, but later you file, higher the penalty amount you end up
                        paying.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#11accordian">
                <h4 className="panel-title">Is it possible to revise the ITR even when the acknowledgment is generated?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="11accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">You can <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file a
                        revised return</a> correcting the error to avoid any penalty. Income tax laws allow taxpayers an
                        opportunity to correct the error or omission in their returns by submitting a revised tax return
                        under Section 139(5) of the IT Act.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#12accordian">
                <h4 className="panel-title">Can I file ITR for FY 18-19 and FY 17-18?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="12accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, you can <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file the
                        ITR</a> for FY 18-19 but with a huge penalty.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#13accordian">
                <h4 className="panel-title">What is form 16?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="13accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Form 16 is an <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">Income
                        tax</a> form. Form 16 is a certificate provided by your employer which certifies details
                        regarding the salary you have earned during the year and how much TDS has been deducted. It
                        consists of two parts i.e. Part A and Part B, where part A consists of information of the
                        employer & employee, like the PAN and TAN details, name and address, TDS deducted & deposited,
                        etc.</p>
                    <p className="para">It is used by the companies to provide their salaried individuals information on
                        the tax deducted. Alternatively, you can consider Form 16 as your Salary TDS certificate.</p>
                    <p className="para">Suppose the income from your salary for the financial year is more than the
                        basic exemption limit of  $2500. Then, in this case, your employer is required to deduct
                        TDS on your salary and deposit it with the government. When From 16 is provided to an employee
                        by their employer, it is considered as a source of proof of filing their Income Tax Returns. And
                        if your income does not fit the basic exemption limit, the employer does not deduct any TDS in
                        that case.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#14accordian">
                <h4 className="panel-title">What is form 26AS? How to get form 26AS?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="14accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Form 26AS is an annual consolidation credit statement issued under section 203AA
                        of the <a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                  target="_blank">Income Tax</a> 1961 which mentions all the tax deductions by the
                        employer, banks or someone else as defined under the Act.</p>
                    <p className="para">To download Form 26AS you can log into the income tax filing account on the
                        Income Tax Departments e filing <a href="https://www.incometaxindiaefiling.gov.in/home"
                                                           target="_blank">website</a> either directly or through the
                        Net Banking facility of authorised banks.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#15accordian">
                <h4 className="panel-title">When is a refund issued by the Income Tax Department?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="15accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The refund is usually issued within 120 days of <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">filing
                        the ITR</a>.</p>
                    <p className="para">To download Form 26AS you can log into the income tax filing account on the
                        Income Tax Departments e filing <a href="https://www.incometaxindiaefiling.gov.in/home"
                                                           target="_blank">website</a> either directly or through the
                        Net Banking facility of authorised banks.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#16accordian">
                <h4 className="panel-title">Income Tax Filing for Freelancers or professionals - Forms, Sections and
                    Exemptions? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="16accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <p className="text-bold"><a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                target="_blank">Filing ITR</a> for freelancer:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Forms</th>
                            <th>Sections</th>
                            <th className="col-xs-8">Exemptions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Form ITR 4</td>
                            <td>Sec. 44AB</td>
                            <td>
                                <ul>
                                    <li>Sec. 80C - Deduction u to INR 1.5 lakhs for payments made towards the life
                                        insurance policies, PF, superannuation, tuition fees, construction/purchase of
                                        residential property FD etc.
                                    </li>
                                    <li>Sec. 80 CCC - Tax Deductions for investment made towards the pension plans upto
                                        INR 1.5 lakhs.
                                    </li>
                                    <li>Sec. 80 CCD - Exemptions towards investments made in the Central Government
                                        Pension Scheme. Both the employer and employee contribution are exempted.
                                    </li>
                                    <li>Sec 80CCF- The exemptions are provided for investments made in the
                                        infrastructure bonds (long term) that are notified by the Government of India.
                                        The section offers a maximum exemption of Rs 20,000
                                    </li>
                                    <li>Sec 80CCG- The section provides a maximum deduction of Rs 25,000 for the
                                        investments made in the government Equity Saving Schemes, to certain specified
                                        Indian citizens and residents.
                                    </li>
                                    <li>SEC. 80D- Under this section, expenses made towards the payment of premiums of
                                        the health insurance policies are exempted. The freelancer can also buy the
                                        policy for spouse or child, and claim the deductions
                                    </li>
                                    <li>Sec. 80DD- The section provides deductions towards treatment of normal and
                                        severe disabilities, which may go up Rs 1.25 lakhs
                                    </li>
                                    <li>Sec. 80DDB- Exemptions towards treatment of certain specified diseases</li>
                                    <li>Sec. 80E- Deductions towards loan taken for education purposes</li>
                                    <li>Sec. 80 EE- The Section is exclusively for individuals, and exempts the payments
                                        made towards a loan, for buying a property for residential purposes
                                    </li>
                                    <li>Sec. 80G- The Section offers up to a 100% deduction for the donations made to
                                        the charitable funds, including the Prime Minister Relief Fund, and the National
                                        Defense Fund among others
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>],
    42: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What are the documents required for GST return Filing? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The necessary document for <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                        return Filing</a> are as follows:</p>
                    <ul>
                        <li>GST Number and Login Details</li>
                        <li>Sales Invoices or Summary</li>
                        <li>purchase Invoice s or Summary</li>
                        <li>Debit notes & Credit Note Summary</li>
                        <li>Bank Statements if required</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What is CGST? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Under GST, <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">CGST</a> is a tax levied on Intra State supplies of both goods and services by
                        the Central Government and will be governed by the CGST Act. SGST will also be levied on the
                        same Intra State supply but will be governed by the State Government.</p>
                    <p className="para">This implies that both the Central and the State governments will agree on
                        combining their levies with an appropriate proportion for revenue sharing between them. However,
                        it is clearly mentioned in Section 8 of the GST Act that the taxes be levied on all Intra-State
                        supplies of goods and/or services but the rate of tax shall not be exceeding 14%, each.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">What is SGST? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Under GST, <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">SGST</a> is a tax levied on Intra State supplies of both goods and services by
                        the State Government and will be governed by the SGST Act. As explained above, CGST will also be
                        levied on the same Intra State supply but will be governed by the Central Government.</p>
                    <p className="para">Any tax liability obtained under SGST can be set off against SGST or IGST input
                        tax credit only.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">What is IGST? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Under GST, <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">IGST</a> is a tax levied on all Inter-State supplies of goods and/or services
                        and will be governed by the IGST Act. IGST will be applicable on any supply of goods and/or
                        services in both cases of import into India and export from India.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">What is the procedure for GST registration? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The step-by-step procedure that individuals must follow to complete <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                        Registration</a> is mentioned below:</p>
                    <ul>
                        <li><b>Step 1:</b> Visit the GST portal <a href="https://www.gst.gov.in/"
                                                                   target="_blank">https://www.gst.gov.in/</a>.
                        </li>
                        <li><b>Step 2:</b> Click on the <b>"Register Now"</b> link which can be found under
                            the <b>"Taxpayers"</b> tab
                        </li>
                        <li><b>Step 3:</b> Select <b>"New Registration"</b>.</li>
                        <li><b>Step 4:</b> Fill the below-mentioned details:
                            <ul>
                                <li>Under the <b>"I am a"</b> drop-down menu, select <b>"Taxpayer"</b>.</li>
                                <li>Select the respective state and district.</li>
                                <li>Enter the name of the business.</li>
                                <li>Enter the PAN of the business.</li>
                                <li>Enter the email ID and mobile number in the respective boxes. The entered email ID
                                    and mobile number must be active as OTPs will be sent to them.
                                </li>
                                <li>Enter the image that is shown on the screen and click on <b>"Proceed"</b>.</li>
                            </ul>
                        </li>
                        <li><b>Step 5:</b> On the next page, enter the OTP that was sent to the email ID and mobile
                            number in the respective boxes.
                        </li>
                        <li><b>Step 6:</b> Once the details have been entered, click on <b>"Proceed"</b>.</li>
                        <li><b>Step 7:</b> You will be shown the Temporary Reference Number (TRN) on the screen. Make a
                            note of the TRN.
                        </li>
                        <li><b>Step 8:</b> Visit the GST portal again and click on <b>"Register"</b> under
                            the <b>"Taxpayers"</b> menu.
                        </li>
                        <li><b>Step 9:</b> Select <b>"Temporary Reference Number (TRN)"</b>.</li>
                        <li><b>Step 10:</b> Enter the TRN and the captcha details.</li>
                        <li><b>Step 11:</b> Click on <b>"Proceed"</b>.</li>
                        <li><b>Step 12:</b> You will receive an OTP on your email ID and registered mobile number. Enter
                            the OTP on the next page and click on <b>"Proceed"</b>.
                        </li>
                        <li><b>Step 13:</b> The status of your application will be available on the next page. On the
                            right side, there will be an Edit icon, click on it.
                        </li>
                        <li><b>Step 14:</b> There will be 10 sections on the next page. All the relevant details must be
                            filled, and the necessary documents must be submitted. The list of documents that must be
                            uploaded are mentioned below:
                            <ul>
                                <li>Photographs</li>
                                <li>Business address proof</li>
                                <li>Bank details such as account number, bank name, bank branch, and IFSC code.</li>
                                <li>Authorisation form</li>
                                <li>The constitution of the taxpayer.</li>
                            </ul>
                        </li>
                        <li><b>Step 15:</b> Step 15: Visit the <b>"Verification"</b> page and check the declaration,
                            Then submit the application by using one of the below mentioned methods:
                            <ul>
                                <li>By Electronic Verification Code (EVC). The code will be sent to the registered
                                    mobile number.
                                </li>
                                <li>By e-Sign method. An OTP will be sent to the mobile number linked to the Aadhaar
                                    card.
                                </li>
                                <li>In case companies are registering, the application must be submitted by using the
                                    Digital Signature Certificate (DSC).
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p className="para">Once completed, a success message will be shown on the screen. The Application
                        Reference Number (ARN) will be sent to the registered mobile number and email ID.
                        You can check the status of the ARN on the GST portal.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">What is the GST return? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The <a href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                               target="_blank">GST return</a> is the documentary accounts of all the
                        sales and purchases made by an Input Service Provider, as well as the tax collected or paid by
                        him. The GST return is generally filed on the basis of the quarter or annual every year, i.e.
                        the taxpayer can furnish this document at an interval of 3 months or once a year.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">Who is liable to file returns? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Any person who carries on a business or any economic activities at any place in
                        India and has registered himself under the <a
                            href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                            Act</a> is liable to file the returns.</p>
                    <p className="para">The word <b>person</b> does not mean only an individual, it also includes HUF,
                        company, firm, LLP, an AOP/BOI, any incorporation or Government company, a body corporate
                        incorporated with any foreign country but carrying its business in India, co-operative society,
                        local authority, government, trust, artificial person.</p>

                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">If there is no transaction in the business, is it necessary to file GST? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Even if there is no business transaction during the period covered under the
                        return, it is necessary to <a
                            href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file
                            GST</a>, but it shall be a <b>"Nil Return"</b>.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#9accordian">
                <h4 className="panel-title">Are there any exceptions, who are not liable to file GST returns? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="9accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">Certain entities are excluded from filing the <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                        return</a>:</p>
                    <ul>
                        <li>Has to register under GST but not required to file returns: UN bodies and foreign consulates
                            are exempted from GST return filing, but on the months they have made any purchase, has to
                            file returns only for those months.
                        </li>
                        <li>Neither required to register nor GST return: The Public Sector Undertakings, any business or
                            trade-in non-GST supplies, are not required to register under GST and eventually not liable
                            to file GST returns.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#10accordian">
                <h4 className="panel-title">What are the types of GST return and their due dates?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="10accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th className="text-center"><a
                                href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                target="_blank">GSTR</a></th>
                            <th className="text-center">Definition</th>
                            <th className="text-center">Documents Required/Details</th>
                            <th className="text-center">Due Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="text-center">GSTR-1</td>
                            <td>It is a monthly return of outward supplies of monthly return showcasing the sales
                                transactions made by the taxpayer
                            </td>
                            <td>
                                <ul>
                                    <li>Invoices</li>
                                    <li>Debit notes</li>
                                    <li>Credit notes</li>
                                    <li>Revised notes</li>
                                </ul>
                            </td>
                            <td>10th day of every month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-2</td>
                            <td>It is a monthly return of inward supply of goods and services, i.e. the purchase made by
                                the taxpayer in a particular month.
                            </td>
                            <td>
                                <ul>
                                    <li>Invoices</li>
                                    <li>Debit notes</li>
                                    <li>Credit notes</li>
                                </ul>
                            </td>
                            <td>Between 11th to 15th of the succeeding month.</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-2A</td>
                            <td>It is a necessary document, auto-populated which can be used for the acceptance,
                                rejection or rectification of the invoices uploaded in GSTR-1/2.
                            </td>
                            <td>
                                It is a read-only document
                            </td>
                            <td>Between 11th to 15th of the succeeding month.</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-3B</td>
                            <td>
                                It is the self-declaration of of the taxability of the taxpayer comparing the inwards
                                and outward supplies conducted in a month
                            </td>
                            <td>
                                If there is no transaction in a particular month, the taxpayer has to submit a Nil
                                return
                            </td>
                            <td>20th of the Succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-4</td>
                            <td>
                                Taxpayers having quarterly turnover upto $150k has to pay a fixed rate of tax
                            </td>
                            <td>
                                Has to be paid in every quarter
                            </td>
                            <td>18th of the following month of the specified quarter for which GST return filing has to
                                be done.
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-5</td>
                            <td>
                                It is for the taxpayer who has an economic activity in India, but himself stays out of
                                India. It is a monthly return filing
                            </td>
                            <td>
                                <ul>
                                    <li>Inwards Supply</li>
                                    <li>Outward Supply</li>
                                    <li>Any interest/penalty/fees</li>
                                    <li>Tax amount</li>
                                    <li>Any other amount payable</li>
                                </ul>
                            </td>
                            <td>20th of the succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-6</td>
                            <td>It is a monthly GST return filing done by the Input Service Distributor
                            </td>
                            <td>
                                <ul>
                                    <li>Summary of the total input tax credit available for distribution.</li>
                                    <li>Invoices</li>
                                    <li>Debit Notes</li>
                                    <li>Credit Notes</li>
                                </ul>
                            </td>
                            <td>13th Day of the succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-7</td>
                            <td>This GST Return filing has to be done monthly by the ones who are deducting TDS
                            </td>
                            <td>
                                <ul>
                                    <li>Tax Deducted at source</li>
                                    <li>Liability towards TDS</li>
                                    <li>TDS refund claimed</li>
                                    <li>Interest, late fees</li>
                                </ul>
                            </td>
                            <td>10th Day of the Succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-8</td>
                            <td>

                                This is the GST Return filing which has to be filed by every e-commerce operator who
                                deducts TDS

                            </td>
                            <td>
                                <ul>
                                    <li>Details of sale/supplies made through an e-commerce portal</li>
                                    <li>TDS deducted</li>
                                </ul>
                            </td>
                            <td>10th day of succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-9</td>
                            <td>This is for each and every taxpayer registered under the GST Act. They have to furnish
                                the annual GST return filing electronically
                            </td>
                            <td>
                                There are certain exceptions:
                                <ul>
                                    <li>Input Service Provider
                                    </li>
                                    <li>Taxpayer U/S 51/52</li>
                                    <li>Casual Taxpayer</li>
                                    <li>NRI Taxpayer</li>
                                </ul>
                            </td>
                            <td>30th November 2019</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR 9A</td>
                            <td>Annual return in addition to the quarterly return by a composition dealer in a financial
                                year
                            </td>
                            <td>
                                <ul>
                                    <li>Inward and outward supplies4</li>
                                    <li>Tax paid</li>
                                    <li>Input credit availed/reversed</li>
                                    <li>Tax refunds</li>
                                    <li>Penalties</li>
                                </ul>
                            </td>
                            <td>December 31st succeeding the close of a particular financial year</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 9B</td>
                            <td>It is the GST return filing furnished by the e-commerce operators monthly</td>
                            <td></td>
                            <td>31st December of the succeeding year</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 9C</td>
                            <td>If the taxpayer’s turnover is more than 2 crore has to proceed for this GST return
                                filing after being audited by a CA
                            </td>
                            <td>
                                <ul>
                                    <li>Annual return</li>
                                    <li>Copy of audited account</li>
                                    <li>Reconciliation statement</li>
                                </ul>
                            </td>
                            <td>31st December of the subsequent financial year</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 10</td>
                            <td>This GST return filing has to be filed by the person whose GST registration has been
                                cancelled
                            </td>
                            <td>It has to be filed electronically on the portal or through a facilitation center
                                prescribed by the Commissioner
                            </td>
                            <td>3 months following the date of cancellation</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 11</td>
                            <td>The foreign embassies or diplomatic mission who are not liable to pay tax in India, are
                                allotted a UIN. This GST return filing has to be conducted by them.
                            </td>
                            <td>The UIN is allotted to:
                                <ul>
                                    <li>Specialized agency of the UNO</li>
                                    <li>Consulate/embassy of a foreign country</li>
                                    <li>Multilateral financial institution and organization under the UN (Privileges and
                                        Immunities) Act 1947
                                    </li>
                                    <li>Any other person/class of persons specified by the COmmissioner</li>
                                </ul>
                            </td>
                            <td>28th of the succeeding month.</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#11accordian">
                <h4 className="panel-title">How to apply for GST cancellation? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="11accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">You can do that yourself, or can <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">hire a
                        professional</a> for a smoother and expert proceeding.</p>
                    <ul>
                        <li><b>Step 1:</b> Visit the official GST website using the following link <a
                            href="https://www.gst.gov.in/" target="_blank">https://www.gst.gov.in</a> and login to your
                            GST portal
                        </li>
                        <li><b>Step 2:</b> Under the service option on your dashboard a drop-down will appear. Choose
                            Registration and then select Application for Cancellation of Registration.
                        </li>
                    </ul>
                    <p className="text-bold">Once you select Application for Cancellation of Registration three option
                        will appear:</p>
                    <ul>
                        <li>Basic Details</li>
                        <li>Cancellation details</li>
                        <li>Verification</li>
                    </ul>
                    <ul>
                        <li><b>Step 3:</b> Fill in the Basic Details that is required and click on the Save and Continue
                            Button <br/> In the Address for a place of business and for future correspondence is same
                            then click on <b>same as the above option</b></li>
                        <li><b>Step 4:</b> Fill in the reason for cancellation. <b>6 option will appear</b> as the
                            Reason for cancellation
                            <ol>
                                <li>Change in the constitution of business leading to change in PAN</li>
                                <li>Ceased to be liable to pay tax</li>
                                <li>Discontinuance of business / Closure of business</li>
                                <li>Others</li>
                                <li>Transfer of business on account of amalgamation, de-merger, sale, leased or
                                    otherwise
                                </li>
                                <li>Death of Sole Proprietor</li>
                            </ol>
                        </li>
                    </ul>
                    <p className="text-bold">Information Required to fill the cancellation form</p>
                    <ul>
                        <li>Address, Email-id and Registered Mobile Number</li>
                        <li>Reason for cancellation and the desired date to Cancel</li>
                        <li>Particulars on which tax is payable: input available on semi-finished and finished goods,
                            Stock of input and stock of capital. On filling the tax liability, you would be manually
                            required to fill in the value.
                        </li>
                        <li>You would be required to fill in either Electronic Cash ledger or Electronic Credit ledger
                            to set off the tax liability
                        </li>
                        <li>Details of the last return filed by the Taxpayer</li>
                    </ul>
                    <p className="para">When the existing unit is merged/de-merger/transferred, etc., then the details
                        of registration of the entity, i.e., GSTIN on the transferee entity.</p>
                    <ul>
                        <li><b>Step 5:</b> On filing the required reason and details, the verification option will
                            appear. Fill in the name of the authorized signatory and the place of making the
                            declaration. Depending upon the signatory, fill in the details and choose the relevant
                            option to submit. In the case of a company or LLP, DSC is mandatory. OTP will be generated
                        </li>
                    </ul>
                    <p className="text-bold">In case the taxpayer has issued an invoice, he/she would be required to
                        fill GST REG-16. The Above step is valid when the invoice is not issued.</p>
                    <p className="text-bold">Where GST REG-16 is filed by the Taxpayer, the officer will within 30 days
                        issue the order of cancellation.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#12accordian">
                <h4 className="panel-title">What is a Composition Scheme and eligibility for applying in Composition
                    Scheme? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="12accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Getting registered under composition scheme is optional and voluntary. Any
                        business which has a turnover of less than One crore or 75 lakhs for the specified states
                        can opt for this scheme but on any given day, if turnover crosses the above-mentioned limit,
                        then he becomes ineligible and has to take registration under the regular scheme. There are
                        certain conditions that need to be fulfilled before opting for composition levy.</p>
                    <p className="text-bold">They are as follows:</p>
                    <ul>
                        <li>Any assessee who only deals in supply of goods can opt for this scheme that means this
                            provision is not applicable for service providers. However, restaurant service providers are
                            excluded.
                        </li>
                        <li>There should not be any interstate supply of goods that means businesses having only an
                            intra-state supply of goods are eligible.
                        </li>
                        <li>Any dealer who is supplying goods through electronic commerce operator will be barred from
                            being registered under the composition scheme. For example: If M/s ABC sells its products
                            through Flipkart or Amazon (Electronic Commerce Operator), then M/s. ABC cannot opt for
                            composition scheme.
                        </li>
                        <li>The composition scheme is levied for all business verticals with the same PAN. A taxable
                            person will not have the option to select a composition scheme for one, opt to pay taxes for
                            others. For example, A taxable person has the following Business verticals separately
                            registered – Sale of footwear, the sale of mobiles, Franchisee of McDonald’s. Here the
                            composition scheme will be available to all 3 business verticals.
                        </li>
                        <li>Dealers are not allowed to collect composition tax from the recipient of supplies, and
                            neither are they allowed to take Input Tax Credit.
                        </li>
                        <li>If the person is not eligible under composition scheme, tax liability shall be TAX +
                            Interest and penalty which shall be equal to the amount of tax.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>],
    43: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What are the documents required for GST return Filing? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The necessary document for <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                        return Filing</a> are as follows:</p>
                    <ul>
                        <li>GST Number and Login Details</li>
                        <li>Sales Invoices or Summary</li>
                        <li>purchase Invoice s or Summary</li>
                        <li>Debit notes & Credit Note Summary</li>
                        <li>Bank Statements if required</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What is CGST? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Under GST, <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">CGST</a> is a tax levied on Intra State supplies of both goods and services by
                        the Central Government and will be governed by the CGST Act. SGST will also be levied on the
                        same Intra State supply but will be governed by the State Government.</p>
                    <p className="para">This implies that both the Central and the State governments will agree on
                        combining their levies with an appropriate proportion for revenue sharing between them. However,
                        it is clearly mentioned in Section 8 of the GST Act that the taxes be levied on all Intra-State
                        supplies of goods and/or services but the rate of tax shall not be exceeding 14%, each.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">What is SGST? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Under GST, <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">SGST</a> is a tax levied on Intra State supplies of both goods and services by
                        the State Government and will be governed by the SGST Act. As explained above, CGST will also be
                        levied on the same Intra State supply but will be governed by the Central Government.</p>
                    <p className="para">Any tax liability obtained under SGST can be set off against SGST or IGST input
                        tax credit only.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">What is IGST? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Under GST, <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">IGST</a> is a tax levied on all Inter-State supplies of goods and/or services
                        and will be governed by the IGST Act. IGST will be applicable on any supply of goods and/or
                        services in both cases of import into India and export from India.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">What is the procedure for GST registration? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The step-by-step procedure that individuals must follow to complete <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                        Registration</a> is mentioned below:</p>
                    <ul>
                        <li><b>Step 1:</b> Visit the GST portal <a href="https://www.gst.gov.in/"
                                                                   target="_blank">https://www.gst.gov.in/</a>.
                        </li>
                        <li><b>Step 2:</b> Click on the <b>"Register Now"</b> link which can be found under
                            the <b>"Taxpayers"</b> tab
                        </li>
                        <li><b>Step 3:</b> Select <b>"New Registration"</b>.</li>
                        <li><b>Step 4:</b> Fill the below-mentioned details:
                            <ul>
                                <li>Under the <b>"I am a"</b> drop-down menu, select <b>"Taxpayer"</b>.</li>
                                <li>Select the respective state and district.</li>
                                <li>Enter the name of the business.</li>
                                <li>Enter the PAN of the business.</li>
                                <li>Enter the email ID and mobile number in the respective boxes. The entered email ID
                                    and mobile number must be active as OTPs will be sent to them.
                                </li>
                                <li>Enter the image that is shown on the screen and click on <b>"Proceed"</b>.</li>
                            </ul>
                        </li>
                        <li><b>Step 5:</b> On the next page, enter the OTP that was sent to the email ID and mobile
                            number in the respective boxes.
                        </li>
                        <li><b>Step 6:</b> Once the details have been entered, click on <b>"Proceed"</b>.</li>
                        <li><b>Step 7:</b> You will be shown the Temporary Reference Number (TRN) on the screen. Make a
                            note of the TRN.
                        </li>
                        <li><b>Step 8:</b> Visit the GST portal again and click on <b>"Register"</b> under
                            the <b>"Taxpayers"</b> menu.
                        </li>
                        <li><b>Step 9:</b> Select <b>"Temporary Reference Number (TRN)"</b>.</li>
                        <li><b>Step 10:</b> Enter the TRN and the captcha details.</li>
                        <li><b>Step 11:</b> Click on <b>"Proceed"</b>.</li>
                        <li><b>Step 12:</b> You will receive an OTP on your email ID and registered mobile number. Enter
                            the OTP on the next page and click on <b>"Proceed"</b>.
                        </li>
                        <li><b>Step 13:</b> The status of your application will be available on the next page. On the
                            right side, there will be an Edit icon, click on it.
                        </li>
                        <li><b>Step 14:</b> There will be 10 sections on the next page. All the relevant details must be
                            filled, and the necessary documents must be submitted. The list of documents that must be
                            uploaded are mentioned below:
                            <ul>
                                <li>Photographs</li>
                                <li>Business address proof</li>
                                <li>Bank details such as account number, bank name, bank branch, and IFSC code.</li>
                                <li>Authorisation form</li>
                                <li>The constitution of the taxpayer.</li>
                            </ul>
                        </li>
                        <li><b>Step 15:</b> Step 15: Visit the <b>"Verification"</b> page and check the declaration,
                            Then submit the application by using one of the below mentioned methods:
                            <ul>
                                <li>By Electronic Verification Code (EVC). The code will be sent to the registered
                                    mobile number.
                                </li>
                                <li>By e-Sign method. An OTP will be sent to the mobile number linked to the Aadhaar
                                    card.
                                </li>
                                <li>In case companies are registering, the application must be submitted by using the
                                    Digital Signature Certificate (DSC).
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p className="para">Once completed, a success message will be shown on the screen. The Application
                        Reference Number (ARN) will be sent to the registered mobile number and email ID.
                        You can check the status of the ARN on the GST portal.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">What is the GST return? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The <a href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                               target="_blank">GST return</a> is the documentary accounts of all the
                        sales and purchases made by an Input Service Provider, as well as the tax collected or paid by
                        him. The GST return is generally filed on the basis of the quarter or annual every year, i.e.
                        the taxpayer can furnish this document at an interval of 3 months or once a year.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">Who is liable to file returns? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Any person who carries on a business or any economic activities at any place in
                        India and has registered himself under the <a
                            href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                            Act</a> is liable to file the returns.</p>
                    <p className="para">The word <b>person</b> does not mean only an individual, it also includes HUF,
                        company, firm, LLP, an AOP/BOI, any incorporation or Government company, a body corporate
                        incorporated with any foreign country but carrying its business in India, co-operative society,
                        local authority, government, trust, artificial person.</p>

                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">If there is no transaction in the business, is it necessary to file GST? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Even if there is no business transaction during the period covered under the
                        return, it is necessary to <a
                            href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file
                            GST</a>, but it shall be a <b>"Nil Return"</b>.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#9accordian">
                <h4 className="panel-title">Are there any exceptions, who are not liable to file GST returns? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="9accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">Certain entities are excluded from filing the <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                        return</a>:</p>
                    <ul>
                        <li>Has to register under GST but not required to file returns: UN bodies and foreign consulates
                            are exempted from GST return filing, but on the months they have made any purchase, has to
                            file returns only for those months.
                        </li>
                        <li>Neither required to register nor GST return: The Public Sector Undertakings, any business or
                            trade-in non-GST supplies, are not required to register under GST and eventually not liable
                            to file GST returns.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#10accordian">
                <h4 className="panel-title">What are the types of GST return and their due dates?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="10accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th className="text-center"><a
                                href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                target="_blank">GSTR</a></th>
                            <th className="text-center">Definition</th>
                            <th className="text-center">Documents Required/Details</th>
                            <th className="text-center">Due Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="text-center">GSTR-1</td>
                            <td>It is a monthly return of outward supplies of monthly return showcasing the sales
                                transactions made by the taxpayer
                            </td>
                            <td>
                                <ul>
                                    <li>Invoices</li>
                                    <li>Debit notes</li>
                                    <li>Credit notes</li>
                                    <li>Revised notes</li>
                                </ul>
                            </td>
                            <td>10th day of every month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-2</td>
                            <td>It is a monthly return of inward supply of goods and services, i.e. the purchase made by
                                the taxpayer in a particular month.
                            </td>
                            <td>
                                <ul>
                                    <li>Invoices</li>
                                    <li>Debit notes</li>
                                    <li>Credit notes</li>
                                </ul>
                            </td>
                            <td>Between 11th to 15th of the succeeding month.</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-2A</td>
                            <td>It is a necessary document, auto-populated which can be used for the acceptance,
                                rejection or rectification of the invoices uploaded in GSTR-1/2.
                            </td>
                            <td>
                                It is a read-only document
                            </td>
                            <td>Between 11th to 15th of the succeeding month.</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-3B</td>
                            <td>
                                It is the self-declaration of of the taxability of the taxpayer comparing the inwards
                                and outward supplies conducted in a month
                            </td>
                            <td>
                                If there is no transaction in a particular month, the taxpayer has to submit a Nil
                                return
                            </td>
                            <td>20th of the Succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-4</td>
                            <td>
                                Taxpayers having quarterly turnover upto 1.5 Crore has to pay a fixed rate of tax
                            </td>
                            <td>
                                Has to be paid in every quarter
                            </td>
                            <td>18th of the following month of the specified quarter for which GST return filing has to
                                be done.
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-5</td>
                            <td>
                                It is for the taxpayer who has an economic activity in India, but himself stays out of
                                India. It is a monthly return filing
                            </td>
                            <td>
                                <ul>
                                    <li>Inwards Supply</li>
                                    <li>Outward Supply</li>
                                    <li>Any interest/penalty/fees</li>
                                    <li>Tax amount</li>
                                    <li>Any other amount payable</li>
                                </ul>
                            </td>
                            <td>20th of the succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-6</td>
                            <td>It is a monthly GST return filing done by the Input Service Distributor
                            </td>
                            <td>
                                <ul>
                                    <li>Summary of the total input tax credit available for distribution.</li>
                                    <li>Invoices</li>
                                    <li>Debit Notes</li>
                                    <li>Credit Notes</li>
                                </ul>
                            </td>
                            <td>13th Day of the succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-7</td>
                            <td>This GST Return filing has to be done monthly by the ones who are deducting TDS
                            </td>
                            <td>
                                <ul>
                                    <li>Tax Deducted at source</li>
                                    <li>Liability towards TDS</li>
                                    <li>TDS refund claimed</li>
                                    <li>Interest, late fees</li>
                                </ul>
                            </td>
                            <td>10th Day of the Succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-8</td>
                            <td>

                                This is the GST Return filing which has to be filed by every e-commerce operator who
                                deducts TDS

                            </td>
                            <td>
                                <ul>
                                    <li>Details of sale/supplies made through an e-commerce portal</li>
                                    <li>TDS deducted</li>
                                </ul>
                            </td>
                            <td>10th day of succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-9</td>
                            <td>This is for each and every taxpayer registered under the GST Act. They have to furnish
                                the annual GST return filing electronically
                            </td>
                            <td>
                                There are certain exceptions:
                                <ul>
                                    <li>Input Service Provider
                                    </li>
                                    <li>Taxpayer U/S 51/52</li>
                                    <li>Casual Taxpayer</li>
                                    <li>NRI Taxpayer</li>
                                </ul>
                            </td>
                            <td>30th November 2019</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR 9A</td>
                            <td>Annual return in addition to the quarterly return by a composition dealer in a financial
                                year
                            </td>
                            <td>
                                <ul>
                                    <li>Inward and outward supplies4</li>
                                    <li>Tax paid</li>
                                    <li>Input credit availed/reversed</li>
                                    <li>Tax refunds</li>
                                    <li>Penalties</li>
                                </ul>
                            </td>
                            <td>December 31st succeeding the close of a particular financial year</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 9B</td>
                            <td>It is the GST return filing furnished by the e-commerce operators monthly</td>
                            <td></td>
                            <td>31st December of the succeeding year</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 9C</td>
                            <td>If the taxpayer’s turnover is more than 2 crore has to proceed for this GST return
                                filing after being audited by a CA
                            </td>
                            <td>
                                <ul>
                                    <li>Annual return</li>
                                    <li>Copy of audited account</li>
                                    <li>Reconciliation statement</li>
                                </ul>
                            </td>
                            <td>31st December of the subsequent financial year</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 10</td>
                            <td>This GST return filing has to be filed by the person whose GST registration has been
                                cancelled
                            </td>
                            <td>It has to be filed electronically on the portal or through a facilitation center
                                prescribed by the Commissioner
                            </td>
                            <td>3 months following the date of cancellation</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 11</td>
                            <td>The foreign embassies or diplomatic mission who are not liable to pay tax in India, are
                                allotted a UIN. This GST return filing has to be conducted by them.
                            </td>
                            <td>The UIN is allotted to:
                                <ul>
                                    <li>Specialized agency of the UNO</li>
                                    <li>Consulate/embassy of a foreign country</li>
                                    <li>Multilateral financial institution and organization under the UN (Privileges and
                                        Immunities) Act 1947
                                    </li>
                                    <li>Any other person/class of persons specified by the COmmissioner</li>
                                </ul>
                            </td>
                            <td>28th of the succeeding month.</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#11accordian">
                <h4 className="panel-title">How to apply for GST cancellation? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="11accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">You can do that yourself, or can <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">hire a
                        professional</a> for a smoother and expert proceeding.</p>
                    <ul>
                        <li><b>Step 1:</b> Visit the official GST website using the following link <a
                            href="https://www.gst.gov.in/" target="_blank">https://www.gst.gov.in</a> and login to your
                            GST portal
                        </li>
                        <li><b>Step 2:</b> Under the service option on your dashboard a drop-down will appear. Choose
                            Registration and then select Application for Cancellation of Registration.
                        </li>
                    </ul>
                    <p className="text-bold">Once you select Application for Cancellation of Registration three option
                        will appear:</p>
                    <ul>
                        <li>Basic Details</li>
                        <li>Cancellation details</li>
                        <li>Verification</li>
                    </ul>
                    <ul>
                        <li><b>Step 3:</b> Fill in the Basic Details that is required and click on the Save and Continue
                            Button <br/> In the Address for a place of business and for future correspondence is same
                            then click on <b>same as the above option</b></li>
                        <li><b>Step 4:</b> Fill in the reason for cancellation. <b>6 option will appear</b> as the
                            Reason for cancellation
                            <ol>
                                <li>Change in the constitution of business leading to change in PAN</li>
                                <li>Ceased to be liable to pay tax</li>
                                <li>Discontinuance of business / Closure of business</li>
                                <li>Others</li>
                                <li>Transfer of business on account of amalgamation, de-merger, sale, leased or
                                    otherwise
                                </li>
                                <li>Death of Sole Proprietor</li>
                            </ol>
                        </li>
                    </ul>
                    <p className="text-bold">Information Required to fill the cancellation form</p>
                    <ul>
                        <li>Address, Email-id and Registered Mobile Number</li>
                        <li>Reason for cancellation and the desired date to Cancel</li>
                        <li>Particulars on which tax is payable: input available on semi-finished and finished goods,
                            Stock of input and stock of capital. On filling the tax liability, you would be manually
                            required to fill in the value.
                        </li>
                        <li>You would be required to fill in either Electronic Cash ledger or Electronic Credit ledger
                            to set off the tax liability
                        </li>
                        <li>Details of the last return filed by the Taxpayer</li>
                    </ul>
                    <p className="para">When the existing unit is merged/de-merger/transferred, etc., then the details
                        of registration of the entity, i.e., GSTIN on the transferee entity.</p>
                    <ul>
                        <li><b>Step 5:</b> On filing the required reason and details, the verification option will
                            appear. Fill in the name of the authorized signatory and the place of making the
                            declaration. Depending upon the signatory, fill in the details and choose the relevant
                            option to submit. In the case of a company or LLP, DSC is mandatory. OTP will be generated
                        </li>
                    </ul>
                    <p className="text-bold">In case the taxpayer has issued an invoice, he/she would be required to
                        fill GST REG-16. The Above step is valid when the invoice is not issued.</p>
                    <p className="text-bold">Where GST REG-16 is filed by the Taxpayer, the officer will within 30 days
                        issue the order of cancellation.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#12accordian">
                <h4 className="panel-title">What is a Composition Scheme and eligibility for applying in Composition
                    Scheme? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="12accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Getting registered under composition scheme is optional and voluntary. Any
                        business which has a turnover of less than One crore or 75 lakhs for the specified states
                        can opt for this scheme but on any given day, if turnover crosses the above-mentioned limit,
                        then he becomes ineligible and has to take registration under the regular scheme. There are
                        certain conditions that need to be fulfilled before opting for composition levy.</p>
                    <p className="text-bold">They are as follows:</p>
                    <ul>
                        <li>Any assessee who only deals in supply of goods can opt for this scheme that means this
                            provision is not applicable for service providers. However, restaurant service providers are
                            excluded.
                        </li>
                        <li>There should not be any interstate supply of goods that means businesses having only an
                            intra-state supply of goods are eligible.
                        </li>
                        <li>Any dealer who is supplying goods through electronic commerce operator will be barred from
                            being registered under the composition scheme. For example: If M/s ABC sells its products
                            through Flipkart or Amazon (Electronic Commerce Operator), then M/s. ABC cannot opt for
                            composition scheme.
                        </li>
                        <li>The composition scheme is levied for all business verticals with the same PAN. A taxable
                            person will not have the option to select a composition scheme for one, opt to pay taxes for
                            others. For example, A taxable person has the following Business verticals separately
                            registered – Sale of footwear, the sale of mobiles, Franchisee of McDonald’s. Here the
                            composition scheme will be available to all 3 business verticals.
                        </li>
                        <li>Dealers are not allowed to collect composition tax from the recipient of supplies, and
                            neither are they allowed to take Input Tax Credit.
                        </li>
                        <li>If the person is not eligible under composition scheme, tax liability shall be TAX +
                            Interest and penalty which shall be equal to the amount of tax.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>],
    49: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">Who can send a consumer protection notice?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Any person who has been the buyer of a product or service but is unsatisfied
                        with the product or service can send a notice for consumer protection.
                        The <a href={`/${productIdMappedWithSlug['49'].slug}/${parentPracticeAreaSlug['2'].slug}`} target="_blank"
                               style={{color: '#ff337d'}}>consumer protection notice</a> by nature is a legal notice
                        from the unsatisfied buyer drafted by an Advocate on a lawyer’s letterhead clearly mentioning
                        the incident and claims relevant to the product and service.
                        The word ‘unsatisfactory’ is a relative term. For sending notice, one has to understand this
                        basic feature of consumer protection. It will be easier to understand with examples - you have
                        bought an earphone of a well-known brand, but it stops working within one month, in that
                        situation even after informing the company and the shop, it is not resolved within a reasonable
                        period, then you can send a legal notice. However, if you are buying it from a not an authorized
                        dealer of the product and you know the product you are buying at a discounted/non-discounted
                        price is a first or second copy of the original product, then in such a situation, you shall not
                        get the protection of the Consumer Protection Act.</p>

                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">To whom the consumer protection legal notice be sent? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The legal notice under the Consumer Protection Act can be sent to the seller,
                        company or dealer.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">When can a consumer protection legal notice be sent? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">Before sending a legal notice under the Consumer Protection Act, certain
                        steps have to be followed, which are explained here:</p>
                    <p className="para"><b>Step 1:</b> Inform the shop/dealer/company through their website, email,
                        phone call or face to face.</p>
                    <p className="para"><b>Step 2:</b> Find out the reason of such damage or faulty product or service,
                        and note how they are responding to it.</p>
                    <p className="para"><b>Step 3:</b> If they pretend to help but not in a real sense, or not helping
                        at all, you can find a lawyer for sending a legal notice.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">What you can do if the prescribed time to send the notice crosses? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Under Sec 24-A of the Consumer Protection Act, the <a
                        href={path.covid19FreeSignup} target="_blank">consumer complaint</a> has to be filed within two
                        years from the date of cause of action arises. Two years is enough good long time, but mind one
                        thing, it is the limitation period of filing a case, and if you are sending a legal notice, you
                        have to do definitely before that.
                        As soon as you get to know about the defect, connect the service provider/seller or the company.
                        Every company has its defined time period to address your problem, generally 10-15 working days,
                        but if it takes longer than that, they must keep you updated. In short, unless there is a reason
                        for taking a longer time, it is better to send a legal notice within 30-45 days if no proper
                        response is provided by the service provider or seller.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">Which one is better, filing a consumer forum complaint or sending a legal
                    notice? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">It is totally dependent on your case, which should be a suitable choice. Anyhow,
                        even before filing a case it is advised to send a legal notice because it is a procedural way of
                        giving the last warning to resolve the matter without going to the Court.
                        This may result in a settlement procedure in which you with the help of your consumer protection
                        lawyer shall negotiate for the faulty service and product as well as the compensation.</p>
                    <p className="para">In case you <a href={path.askLegalQuestion}>file a complaint</a> in the consumer
                        forum, it is true that the Court shall send a notice to respond, which apparently shall have a
                        better effect on them to initiate a negotiation, but for a complaint, once filed, has to follow
                        a procedure for closing, which you need not do if you are sending a legal notice.
                        However, it does not mean the negotiation shall have no record, it shall follow a method
                        prescribed by law, and all the paperwork shall be done, for which definitely your lawyer shall
                        help you in.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">What should be the format of the consumer protection legal notice? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The format of the legal notice of consumer protection has to be written or
                        printed on the lawyer’s letterhead or sent from the lawyer’s official email ID, signed by the
                        sender and if it is sent on Email, the sender shall be marked as CC.</p>
                    <p className="para">In most of the places in India, still, the email is not considered as a legally
                        approved mode of communication in procedural matters. It is preferred to be sent by post.
                        However, in a legal notice, the following points are to be mentioned:
                    </p>
                    <ul>
                        <li>Name of the seller/service provider and name of the complainant</li>
                        <li>The brief of the problem arisen and the time when it has arisen.</li>
                        <li>Claim the compensation and remedy.</li>
                        <li>Loss caused to the complainant due to the fault.</li>
                        <li>Reasonable time (not less than 30 days) to solve this</li>
                        <li>Signature of the complainant and the lawyer.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">What should one do if the legal notice is not accepted by the other
                    party? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">If the other party does not acknowledge the legal notice, it becomes a strong
                        point for the complainant to show the accused has been careless towards the issue.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">What shall be the cost of sending a consumer protection legal notice? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">No, it is not expensive at all. It may cost you less than $1500 for sending
                        the legal notice, however, it is your wish if you want to go for a more experienced, more
                        efficient lawyer, Workbeam gets you in touch with the lawyer you want.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#9accordian">
                <h4 className="panel-title">Why should a lawyer be hired for sending a legal notice? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="9accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <p className="text-bold">It is a valid doubt that when customer forum does not require a lawyer to
                        be hired, then why would you hire a lawyer to send a legal notice, to answer that, here is the
                        difference:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th className="col-xs-2 text-center">Difference</th>
                            <th className="col-xs-5 text-center">Without lawyer</th>
                            <th className="col-xs-5 text-center">With Lawyer</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="text-center">1</td>
                            <td>If it goes for out of Court settlement it will be you vs. an expert with years of
                                experience
                            </td>
                            <td>In out of Court settlement, your lawyer knows how to negotiate profitably</td>
                        </tr>
                        <tr>
                            <td className="text-center">2</td>
                            <td>Post settlement, a procedure has to be followed to withdraw the complaint</td>
                            <td>These procedures are taken care of by your lawyer.</td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>],
    50: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What is mutual consent divorce?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">When both the parties agree for proceeding for the separation, then they have to
                        follow a legally prescribed procedure, which is known as mutual consent divorce.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">When can one proceed for mutual divorce? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">As soon as you and your spouse make up your mind that you want to separate from
                        each other for lifelong, you can proceed for the mutual divorce.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">How much time usually Mutual divorce takes to finish? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">7-9 months, including the 6 months of judicial separation period. However, you
                        can try to get it waived off, but it is totally in the discretionary of the Magistrate whether
                        he shall waive it off or not. If it gets waived off, then it may get completed within 2-3
                        months.
                        However, be aware you are taking a risk if you are assured that the cooling period shall be
                        waived off, it is never in the hands of lawyers.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">Does it matter who among the spouses filing for divorce? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">No it never matters who is filing for the divorce, but for mutual divorce, the
                        petition has to be filed together through their respective lawyers.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">What is the procedure of mutual divorce? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The procedure of mutual divorce on a broad sense, involves 3 steps:</p>
                    <p className="para"><b>Step 1:</b> File the petition for divorce, to which a date of hearing shall
                        be fixed. On the date of hearing, the Court shall be questioning on our intention, to which both
                        of you have to affirm or oath that you have taken this divorce decision without any influence.
                    </p>
                    <p className="para"><b>Step 2:</b> Wait for the cooling off period or judicial separation period to
                        get over and meanwhile if you decide to change your decision, file another petition expressing
                        your decision before Court, otherwise proceed with the same, and the second hearing date shall
                        be allotted.</p>
                    <p className="para"><b>Step 3:</b> On the second hearing date, again you have to affirm on your
                        decision on oath, and the divorce is finalized and the order gets passed on the next date.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">Is there a time limit for remarriage after mutual divorce? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">No, for mutual divorce you do not need to follow any waiting period for
                        remarriage.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">What is the approximate cost of mutual divorce? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Basically depends on your lawyer’s fees. With Workbeam, you can get a number
                        of proposals from lawyers to take care of your case, and you can choose the one you feel to be
                        suitable.</p>
                    <p className="para">Coming back to the money, Workbeam provides a 100% money security for each
                        penny you spend for your case, when you pay the lawyer through them.</p>
                    {/* <p className="para">Actually, you actually get reduced on your stress, as you can now do all these
                        procedure of finding a lawyer, discussing about the matter and hiring them totally online.
                        Moreover, you do not find out time from your busy schedule to run to the lawyer’s chamber for
                        updates, you can chat, call and share your files through the Workbeam App.</p> */}
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">Can alimony be demanded in case of mutual divorce? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, alimony can be demanded in case of mutual divorce, but it is a matter to be
                        decided mutually. In situations like both of the parties are ready to separate but there has
                        been an issue on alimony; the Court shall decide an amount which is reasonable for the survival
                        of the wife and economic capabilities of the husband.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#9accordian">
                <h4 className="panel-title">What will happen with child custody in case of mutual divorce? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="9accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">For child custody, if you have decided who will take care of the child and how,
                        then it is advisable to file the application along with the divorce filing application.
                        If the child custody matter can not be concluded to a decision by both parties, the Court take
                        it up as a proceeding and passes an order most appropriate for the welfare of the child.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#10accordian">
                <h4 className="panel-title">How can the petition file for divorce be withdrawn? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="10accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, once a petition filed for divorce can be withdrawn anytime citing proper
                        reason before passing an order.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#11accordian">
                <h4 className="panel-title">Can mutually divorced couple can remarry? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="11accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Even if you are divorced, law does not stop you from marrying each other, if in
                        future you change your decision.</p>
                </div>
            </div>
        </div>
    </div>],
    51: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What is considered as delayed possession in RERA?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">When a flat or property, under construction, is invested in, the seller, through
                        the sale agreement, mentions a date or time within which the project shall be deliverable.
                        Considering logical hindrances, a grace period of 3 months is granted to the builder to finish
                        the project. If the builder is unable to deliver within that time, it shall be considered as <a
                            href={`/${productIdMappedWithSlug['51'].slug}/${parentPracticeAreaSlug['2'].slug}`} target="_blank"
                            style={{color: '#ff337d'}}>delayed possession</a> in RERA.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What are the remedies available to the buyer on delayed possession? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The buyer can proceed to the tribunal/court for any of the following <a
                        href={path.covid19FreeSignup} target="_blank" style={{color: '#ff337d'}}>remedies in case of a
                        delayed possession</a>:</p>
                    <ul>
                        <li>Full refund of the money along with interest charged on it</li>
                        <li>Alternate place to be allotted with similar facilities.</li>
                        <li>Wait till the builder has asked you to do so, but no legal provision on this is
                            applicable.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">What should be the first step taken in case of a delayed possession? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">when there is a delayed possession in RERA, the first you must send a legal
                        notice to the builder mentioning your claim in an appropriate way. It is an attempt to resolve
                        the matter without going to the Court, as well as it conveys that if the matter is not resolved
                        within a reasonable time mentioned in the notice, the matter shall be taken to a tribunal or
                        Court.</p>
                    <p className="para">As an additional matter of fact, RERA has a time limit to finish the hearing of
                        any case that must be completed within 90 days from filing without any delay except for <a
                            href={path.askLegalQuestion} target="_blank" style={{color: '#ff337d'}}>valid reason for
                            delay</a>.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">How much compensation can be received in case of delayed possession? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Depending on the monetary loss you have faced and is facing due to this delayed
                        possession, the percentage of the interest is calculated on the principal amount.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">What is the time limit for sending a legal notice for delayed possession? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">There is no time limit for sending a legal notice for delayed possession. For
                        example, the date of possession was March 2018, but the builder on a personal note asked for 9
                        more months, he’ll get it done with, but still fails and ignores your calls. In such situation
                        even after a year, you can send a legal notice.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">Can a legal notice be initiated online for delayed possession? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Absolutely, with Workbeam you can hire a lawyer online to send your legal
                        notice, pay them online with 100% payment security, for which you shall get a receipt online,
                        and also share your documents online with the lawyer through their dashboard which assures a
                        total security of your documents.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">What is the format of the legal notice for delayed possession? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The format of the legal notice for delayed possession has to be written or
                        printed on the <a href={parentPracticeArea["2"].product['2'].path}>property
                            lawyer’s</a> letterhead or sent from the lawyer’s official email ID, signed by the sender
                        and if it is sent on Email, the sender shall be marked as CC.</p>
                    <p className="para">In most of the places in India, still, the email is not considered as a legally
                        approved mode of communication in procedural matters. It is preferred to be sent by post.
                        However, in a legal notice, the following points are to be mentioned:
                    </p>
                    <ul>
                        <li>Name of the seller/builder and name of the complainant</li>
                        <li>The brief about the delay and builder/seller’s response to it</li>
                        <li>Claim the compensation and remedy.</li>
                        <li>Loss caused to the complainant due to the fault.</li>
                        <li>Reasonable time (not less than 30 days) to solve this</li>
                        <li>Signature of the complainant and the lawyer.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">What if the possession delay is regarding a flat bought before RERA came
                    into force? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">After RERA came into action each and every project has came under RERA,
                        including the ongoing projects. Therefore, any delay in such projects shall be regulated under
                        RERA laws.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#9accordian">
                <h4 className="panel-title">What should be done if the builder does not respond to legal notice, or
                    threatens after receiving the legal notice? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="9accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">If the builder/seller does not acknowledge the legal notice, it becomes a strong
                        point for the complainant to show the builder has been careless towards the issue.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#10accordian">
                <h4 className="panel-title">Can a negotiation for refund be initiated through legal notice? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="10accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, if you prefer to go for negotiation, you can mention that in the legal
                        notice. However, if the builder does not come up with a satisfactory solution, still you can
                        proceed for the case in the tribunal. </p>
                </div>
            </div>
        </div>
    </div>],
    52: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">When can a buyer claim for a money refund?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The buyer can claim money under these following situations:</p>
                    <ul>
                        <li>When there is a delay in possession of the property in RERA.</li>
                        <li>When the property delivered is of a substandard quality than what has been promised.</li>
                        <li>If you come to know that the builder has made certain changes unnecessarily deviating from
                            the agreement, and you do not like it.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What is the refund policy formulated by RERA? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">There is no ‘policy’ of refund formulated by the RERA Act, but the provisions
                        that enable the Court or Tribunal to pass an order directing the builder to refund the
                        money. </p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">Can an amount of interest be also claimed with the principal amount for a
                    refund? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, you can claim interest on the principal amount when you are filing the
                        petition for refund. However, this rate of interest is always decided by the Court based on the
                        direct losses caused to the complainant due to this delay.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">When can the legal notice for the refund of money be sent? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">There is no such time limit is mentioned, but based on the situation, the
                        legal notice is sent to the builder following this timeline:</p>
                    <ul>
                        <li><b>Delayed possession:</b> There is no time limit for sending a legal notice for delayed
                            possession. For example, the date of possession was March 2018, but the builder on a
                            personal note asked for 9 more months, he’ll get it done with, but still fails and ignores
                            your calls. In such a situation even after a year, you can send a legal notice.
                        </li>
                        <li><b>Substandard material:</b> RERA has introduced a new rule that the builder has to take
                            responsibility to maintenance for a period of five years from the date of delivery.
                            Therefore, if you find out that the substandard material is used, you can send the legal
                            notice any time within these 5 years of delivery.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">What is the format of the legal notice for refund of money? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The format of the legal notice for money refund has to be written or printed on
                        the lawyer’s letterhead or sent from the lawyer’s official email ID, signed by the sender and if
                        it is sent on Email, the sender shall be marked as CC.</p>
                    <p className="para">In most of the places in India, still, the email is not considered a legally
                        approved mode of communication in procedural matters. It is preferred to be sent by post.
                        However, in a legal notice, the following points are to be mentioned:</p>
                    <ul>
                        <li>Name of the seller/builder and name of the complainant</li>
                        <li>The <a href={path.askLegalQuestion} target="_blank" style={{color: '#ff337d'}}>brief about
                            the problem</a> and builder/seller’s response to it
                        </li>
                        <li>Loss caused to the complainant due to the fault.</li>
                        <li>Reasonable time (not less than 30 days) to solve this</li>
                        <li>Signature of the complainant and the lawyer.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">In which forum the matter of money refund in RERA shall be taken? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">IN the RERA tribunal. As many States are not ready with the RERA tribunal yet,
                        it shall be taken to the Consumer Court or Civil Court. Meanwhile, if the RERA tribunal gets
                        established, it shall be transferred to the tribunal.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">What if the builder declares himself insolvent to avoid paying? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">In such a situation, the properties of the builder shall be attached, and money
                        shall be recovered by the appropriate authority with the instructions of the Court. In such a
                        situation you shall be treated as the creditor to the builder</p>
                </div>
            </div>
        </div>
    </div>],
    53: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What is a Digital Signature Certificate?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Digital Signature Certificate of DSC is the electronic prove of one’s identity
                        for the purpose of digitalized access information or services on the internet or to sign any
                        document digitally</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What is the necessity of a Digital Signature Certificate? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The DSC or Digital Signature Certificate has certain advantages in the
                        present period, they are as follows:</p>
                    <ul>
                        <li>Authenticates your identity online</li>
                        <li>Secured online transactions by ensuring privacy to the information exchanged</li>
                        <li>Controlling the visibility or accessibility of the information</li>
                        <li>It can be used for exchanging emails carrying a digital signature, it shall carry an
                            authenticity
                        </li>
                        <li>Sign documents digitally, eg., MS Office, PDFS, JPEF.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">How can Digital Signature Certificate be obtained? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The steps to apply for the Digital Signature Certificate are as
                        follows:</p>
                    <p className="para"><b>Step 1:</b> Select your entity: On logging on to the website of the
                        Certifying Authority and under the ‘Digital Certification Services’ section, select the kind of
                        entity for which you want to obtain the DSC - individual or organization. Now, download the
                        form.</p>
                    <p className="para"><b>Step 2:</b> Post download, go through the form thoroughly - </p>
                    <ul>
                        <li>Mention the class of DSC</li>
                        <li>For how long you want it to use</li>
                        <li>The type of digital signature, only a sign or sign along with encrypting</li>
                        <li>Detail of the applicant - applicant name, contact, residential address, GST Number and
                            documents proving the identity.
                        </li>
                        <li> Declaration along with recent photograph and scanned copy of your signature</li>
                        <li>Attestation</li>
                        <li>Payment details.</li>
                    </ul>
                    <p className="text-bold">Now, print the filled form.</p>
                    <p className="para"><b>Step 3:</b> Attach the hard copy of documents verifying your identity,
                        attested by the attesting officer prescribed by the authority, Attach the DD and cheque through
                        which the payment has to be made.</p>
                    <p className="text-bold">Now submit it to the Local Registration Authority.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">Can Digital Signature be used for official purposes? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Following the provision of the Information Technology Act 2000, Digital
                        Signature is legally valid as it is issued by the Licensed Certifying Authorities. However, for
                        different purposes, <a href={path.askLegalQuestion} target="_blank" style={{color: '#ff337d'}}>different
                            classes of DSC are allotted.</a></p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">What are the different classes of DSC and use of them? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <p className="text-bold">The different classes of Digital Signature Certificate and their usages are
                        listed here:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th className="text-center">Class of DSC</th>
                            <th className="text-center">Use</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="text-center">Class 0 Certificate</td>
                            <td>This DSC can be obtained only for testing purposes. As DSC is still not a very old or
                                well-known facility to many, one can obtain a class 0 certificate and use it to
                                understand the advantages of using it.
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">Class 1 Certificate</td>
                            <td>This DSC is granted to the individuals or private subscribers</td>
                        </tr>
                        <tr>
                            <td className="text-center"><a
                                href={`/${productIdMappedWithSlug['53'].slug}/${parentPracticeAreaSlug['3'].slug}`} target="_blank"
                                style={{color: '#ff337d'}}>Class 2 Certificate</a></td>
                            <td>This is granted to both business organizations as well as to private individuals, also
                                to <a href={`/${productIdMappedWithSlug['55'].slug}/${parentPracticeAreaSlug['3'].slug}`}
                                      target="_blank" style={{color: '#ff337d'}}>NRI and foreign individuals</a>.<br/>It
                                is used for important official purposes like - ITR, ROC and MCA filing.
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center"><a
                                href={`/${productIdMappedWithSlug['54'].slug}/${parentPracticeAreaSlug['3'].slug}`} target="_blank"
                                style={{color: '#ff337d'}}>Class 3 Certificate</a></td>
                            <td>This DSC granted to the individual or organization carries a higher assurance for
                                commerce applications.<br/>This is used for e tendering, procurement, trademark/patent
                                filing.<br/>It is the DSC with the highest value. It is valid only for 1 or 2 years.
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">Root Certificate</td>
                            <td>By definition, it is either an unsigned public key certificate or a self-signed
                                certificate to figure out the appropriate authority. It is basically validating the
                                entire certification tree.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">Can two digital signatures be obtained and used simultaneously? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, two digital signature certificates, one for official and another for
                        personal usage, can be obtained by one person simultaneously.</p>
                </div>
            </div>
        </div>
    </div>],
    54: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What is a Digital Signature Certificate?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Digital Signature Certificate of DSC is the electronic prove of one’s identity
                        for the purpose of digitalized access information or services on the internet or to sign any
                        document digitally</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What is the necessity of a Digital Signature Certificate? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The DSC or Digital Signature Certificate has certain advantages in the
                        present period, they are as follows:</p>
                    <ul>
                        <li>Authenticates your identity online</li>
                        <li>Secured online transactions by ensuring privacy to the information exchanged</li>
                        <li>Controlling the visibility or accessibility of the information</li>
                        <li>It can be used for exchanging emails carrying a digital signature, it shall carry an
                            authenticity
                        </li>
                        <li>Sign documents digitally, eg., MS Office, PDFS, JPEF.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">How can Digital Signature Certificate be obtained? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The steps to apply for the Digital Signature Certificate are as
                        follows:</p>
                    <p className="para"><b>Step 1:</b> Select your entity: On logging on to the website of the
                        Certifying Authority and under the ‘Digital Certification Services’ section, select the kind of
                        entity for which you want to obtain the DSC - individual or organization. Now, download the
                        form.</p>
                    <p className="para"><b>Step 2:</b> Post download, go through the form thoroughly - </p>
                    <ul>
                        <li>Mention the class of DSC</li>
                        <li>For how long you want it to use</li>
                        <li>The type of digital signature, only a sign or sign along with encrypting</li>
                        <li>Detail of the applicant - applicant name, contact, residential address, GST Number and
                            documents proving the identity.
                        </li>
                        <li> Declaration along with recent photograph and scanned copy of your signature</li>
                        <li>Attestation</li>
                        <li>Payment details.</li>
                    </ul>
                    <p className="text-bold">Now, print the filled form.</p>
                    <p className="para"><b>Step 3:</b> Attach the hard copy of documents verifying your identity,
                        attested by the attesting officer prescribed by the authority, Attach the DD and cheque through
                        which the payment has to be made.</p>
                    <p className="text-bold">Now submit it to the Local Registration Authority.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">Can Digital Signature be used for official purposes? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Following the provision of the Information Technology Act 2000, Digital
                        Signature is legally valid as it is issued by the Licensed Certifying Authorities. However, for
                        different purposes, <a href={path.askLegalQuestion} target="_blank" style={{color: '#ff337d'}}>different
                            classes of DSC are allotted.</a></p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">What are the different classes of DSC and use of them? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <p className="text-bold">The different classes of Digital Signature Certificate and their usages are
                        listed here:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th className="text-center">Class of DSC</th>
                            <th className="text-center">Use</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="text-center">Class 0 Certificate</td>
                            <td>This DSC can be obtained only for testing purposes. As DSC is still not a very old or
                                well-known facility to many, one can obtain a class 0 certificate and use it to
                                understand the advantages of using it.
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">Class 1 Certificate</td>
                            <td>This DSC is granted to the individuals or private subscribers</td>
                        </tr>
                        <tr>
                            <td className="text-center"><a
                                href={`/${productIdMappedWithSlug['53'].slug}/${parentPracticeAreaSlug['3'].slug}`} target="_blank"
                                style={{color: '#ff337d'}}>Class 2 Certificate</a></td>
                            <td>This is granted to both business organizations as well as to private individuals, also
                                to <a href={`/${productIdMappedWithSlug['55'].slug}/${parentPracticeAreaSlug['3'].slug}`}
                                      target="_blank" style={{color: '#ff337d'}}>NRI and foreign individuals</a>.<br/>It
                                is used for important official purposes like - ITR, ROC and MCA filing.
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center"><a
                                href={`/${productIdMappedWithSlug['54'].slug}/${parentPracticeAreaSlug['3'].slug}`} target="_blank"
                                style={{color: '#ff337d'}}>Class 3 Certificate</a></td>
                            <td>This DSC granted to the individual or organization carries a higher assurance for
                                commerce applications.<br/>This is used for e tendering, procurement, trademark/patent
                                filing.<br/>It is the DSC with the highest value. It is valid only for 1 or 2 years.
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">Root Certificate</td>
                            <td>By definition, it is either an unsigned public key certificate or a self-signed
                                certificate to figure out the appropriate authority. It is basically validating the
                                entire certification tree.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">Can two digital signatures be obtained and used simultaneously? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, two digital signature certificates, one for official and another for
                        personal usage, can be obtained by one person simultaneously.</p>
                </div>
            </div>
        </div>
    </div>],
    55: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What is a Digital Signature Certificate?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Digital Signature Certificate of DSC is the electronic prove of one’s identity
                        for the purpose of digitalized access information or services on the internet or to sign any
                        document digitally</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What is the necessity of a Digital Signature Certificate? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The DSC or Digital Signature Certificate has certain advantages in the
                        present period, they are as follows:</p>
                    <ul>
                        <li>Authenticates your identity online</li>
                        <li>Secured online transactions by ensuring privacy to the information exchanged</li>
                        <li>Controlling the visibility or accessibility of the information</li>
                        <li>It can be used for exchanging emails carrying a digital signature, it shall carry an
                            authenticity
                        </li>
                        <li>Sign documents digitally, eg., MS Office, PDFS, JPEF.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">How can Digital Signature Certificate be obtained? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The steps to apply for the Digital Signature Certificate are as
                        follows:</p>
                    <p className="para"><b>Step 1:</b> Select your entity: On logging on to the website of the
                        Certifying Authority and under the ‘Digital Certification Services’ section, select the kind of
                        entity for which you want to obtain the DSC - individual or organization. Now, download the
                        form.</p>
                    <p className="para"><b>Step 2:</b> Post download, go through the form thoroughly - </p>
                    <ul>
                        <li>Mention the class of DSC</li>
                        <li>For how long you want it to use</li>
                        <li>The type of digital signature, only a sign or sign along with encrypting</li>
                        <li>Detail of the applicant - applicant name, contact, residential address, GST Number and
                            documents proving the identity.
                        </li>
                        <li> Declaration along with recent photograph and scanned copy of your signature</li>
                        <li>Attestation</li>
                        <li>Payment details.</li>
                    </ul>
                    <p className="text-bold">Now, print the filled form.</p>
                    <p className="para"><b>Step 3:</b> Attach the hard copy of documents verifying your identity,
                        attested by the attesting officer prescribed by the authority, Attach the DD and cheque through
                        which the payment has to be made.</p>
                    <p className="text-bold">Now submit it to the Local Registration Authority.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">Can Digital Signature be used for official purposes? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Following the provision of the Information Technology Act 2000, Digital
                        Signature is legally valid as it is issued by the Licensed Certifying Authorities. However, for
                        different purposes, <a href={path.askLegalQuestion} target="_blank" style={{color: '#ff337d'}}>different
                            classes of DSC are allotted.</a></p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">What are the different classes of DSC and use of them? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <p className="text-bold">The different classes of Digital Signature Certificate and their usages are
                        listed here:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th className="text-center">Class of DSC</th>
                            <th className="text-center">Use</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="text-center">Class 0 Certificate</td>
                            <td>This DSC can be obtained only for testing purposes. As DSC is still not a very old or
                                well-known facility to many, one can obtain a class 0 certificate and use it to
                                understand the advantages of using it.
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">Class 1 Certificate</td>
                            <td>This DSC is granted to the individuals or private subscribers</td>
                        </tr>
                        <tr>
                            <td className="text-center"><a
                                href={`/${productIdMappedWithSlug['53'].slug}/${parentPracticeAreaSlug['3'].slug}`} target="_blank"
                                style={{color: '#ff337d'}}>Class 2 Certificate</a></td>
                            <td>This is granted to both business organizations as well as to private individuals, also
                                to <a href={`/${productIdMappedWithSlug['55'].slug}/${parentPracticeAreaSlug['3'].slug}`}
                                      target="_blank" style={{color: '#ff337d'}}>NRI and foreign individuals</a>.<br/>It
                                is used for important official purposes like - ITR, ROC and MCA filing.
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center"><a
                                href={`/${productIdMappedWithSlug['54'].slug}/${parentPracticeAreaSlug['3'].slug}`} target="_blank"
                                style={{color: '#ff337d'}}>Class 3 Certificate</a></td>
                            <td>This DSC granted to the individual or organization carries a higher assurance for
                                commerce applications.<br/>This is used for e tendering, procurement, trademark/patent
                                filing.<br/>It is the DSC with the highest value. It is valid only for 1 or 2 years.
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">Root Certificate</td>
                            <td>By definition, it is either an unsigned public key certificate or a self-signed
                                certificate to figure out the appropriate authority. It is basically validating the
                                entire certification tree.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">Can two digital signatures be obtained and used simultaneously? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, two digital signature certificates, one for official and another for
                        personal usage, can be obtained by one person simultaneously.</p>
                </div>
            </div>
        </div>
    </div>],
    56: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">Who is liable to file DIR-3 KYC?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The persons who are allotted with DIN (Director Identification Number) are
                        liable to file DIR-3 KYC. The procedure is divided into two categories:</p>
                    <ul>
                        <li><b>For FY 2018-19</b> - Only those who had been allotted approved DIN on or before 31st
                            March, they had to update the KYC details on or before 31st August of that year.
                        </li>
                        <li><b>FY 2019-20 onwards</b> - The Directors who have their DIN approved on or before the end
                            of each FY, it requires to file form DIR-3 KYC before April 30th of the immediate succeeding
                            FY.
                        </li>
                    </ul>
                    <p className="para">For filing the KYC details for any DIN holder, the first time with the Ministry
                        of Corporate Affairs should furnish all the official information through the eform DIR-3 KYC and
                        for updating any information relevant to KYC must be conducted in the same procedure. No web
                        service for DIR-3 KYC can be used for this purpose.</p>
                    <p className="para">In case a DIN holder who has already submitted eForm DIR-3KYC in any previous
                        FY, and have all the information updated, can perform the annual KYC procedure by DIR-3 KYC web
                        series.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What if the due date of the DIR-3 KYC filing date is missed? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">If you miss the due date, the non-complying DINs against which DIR-3 KYC form
                        has not been submitted shall be deactivated due to the non-filing of DIR-3 KYC.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">What is the procedure for filing DIR-3 KYC? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The <a href={path.covid19FreeSignup} target="_blank"
                                                    style={{color: '#ff337d'}}>procedure of filing form DIR-3
                        KYC</a> are as follows:</p>
                    <p className="para"><b>Step 1:</b> Login the official website of Ministry of Corporate Affairs, and
                        follow this path - MCA service - DIN services - DIR-3 KYC Web</p>
                    <p className="para"><b>Step 2:</b> If the mobile number and email ID are already entered, then click
                        to generate OTP and complete the verification process. However, if it shows an instruction to
                        file e-Form DIR-3 KYC, then initiate a fresh filing.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">What is the cost of DIR-3 KYC? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <p className="text-bold">The cost for each kind of DIR-3 KYC has been explained here through this
                        table:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th className="col-xs-6 text-center">DIR- 3 KYC</th>
                            <th className="col-xs-6 text-center">Cost</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>E-Form DIR-3 KYC or web-form DIR-3 KYC-WEB through the web service</td>
                            <td className="text-center">0</td>
                        </tr>
                        <tr>
                            <td>Delayed filing</td>
                            <td className="text-center">$5000</td>
                        </tr>
                        <tr>
                            <td>If failed to file e-form DIR-3 KYC or DIR-3 KYC-WEB for the previous the FY</td>
                            <td className="text-center">$5000</td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">What is the remedy if the due date for the DIR-3 KYC filing is missed? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">If the due date for the <a href={path.askLegalQuestion} target="_blank"
                                                                   style={{color: '#ff337d'}}>DIR-3 KYC filing is
                        missed</a> and the DIN is marked as deactivated, then the KYC can be done post the due date
                        paying a fine of $5000. If the KYC is done post the due date paying the fine, then for that
                        FY, you need not do it for the same FY.</p>
                    <p className="para">For example, if you have missed the due date for KYC for the FY 2018-19, then
                        you can do the same paying the fine post the due date in the FY 2019-20. As you are already
                        doing the KYC process in the FY 2019-20, no requirement of filing the KYC for the year
                        2019-20.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">What are the necessary details to be furnished for DIR-3 KYC filing? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The details which are to be furnished for DIR-3 KYC filing are listed
                        here:</p>
                    <ul>
                        <li>Name (as per PAN database)</li>
                        <li>Father’s Name (as per PAN database)</li>
                        <li>Date of Birth ( as per PAN database)</li>
                        <li>PAN number</li>
                        <li>Personal Mobile number</li>
                        <li>Personal email address</li>
                        <li>Permanent/present address</li>
                        <li>Aadhaar (if not yet received, Voter ID/Passport/Driving license) along with an attested
                            photocopy.
                        </li>
                    </ul>
                    <p className="para">It is necessary to enter the personal mobile number and personal email ID, as
                        the DIR-3 KYC shall be verified by entering the OTP generated. Moreover, this personal detail
                        authenticates the unique identity of the DIN holder.</p>
                    <p className="para">The option for sending the OTP shall be enabled only after the pre scrutiny of
                        the form is successful.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">If a director is disqualified, is he required to file DIR-3 KYC? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Absolutely, even if a director is disqualified, the director is allotted a DIN.
                        As per the rules, any director who is allotted with a DIN is liable to file DIR-3 KYC, so, even
                        a disqualified director is required to file.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">What procedure is to be followed when multiple DINs are allotted? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">If multiple DINs are allotted, then the director shall retain the oldest DIN and
                        surrender rest by filing the DIR-5 form. Post that, follow the prescribed procedure of the DIR-3
                        KYC.</p>
                </div>
            </div>
        </div>
    </div>],
    57: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What is the purpose of the Reserve Unique Name of a company?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">For any person who wants to have a <a href={path.covid19FreeSignup}
                                                                              target="_blank"
                                                                              style={{color: '#ff337d'}}>unique name for
                        the company</a>, has to fill the e-form for reserving a unique name, which has never been used
                        before. This procedure is regulated by the Central Registration center.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What should be the suffixes while reserving the name? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <p className="text-bold">Here is the list of suffixes assigned for each type of companies which are
                        to be included in the unique name of the company:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th className="text-center">Sr. No</th>
                            <th className="text-center">Company name</th>
                            <th className="text-center">Suffix</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="text-center col-sm-2">1</td>
                            <td>New Company</td>
                            <td>Limited/Private Limited</td>
                        </tr>
                        <tr>
                            <td className="text-center col-sm-2">2</td>
                            <td>Non-profit or Sec 8 company</td>
                            <td>Private Limited/Other than ‘Limited’/OPC Private Limited</td>
                        </tr>
                        <tr>
                            <td className="text-center col-sm-2">3</td>
                            <td>IFSC company</td>
                            <td>IFSC Limited/IFSC private limited</td>
                        </tr>
                        <tr>
                            <td className="text-center col-sm-2">4</td>
                            <td>Unlimited Company</td>
                            <td>Unlimited</td>
                        </tr>
                        <tr>
                            <td className="text-center col-sm-2">5</td>
                            <td>Nidhi company</td>
                            <td>Nidhi Limited</td>
                        </tr>
                        <tr>
                            <td className="text-center col-sm-2">6</td>
                            <td>Producer Company</td>
                            <td>Producer Company Limited</td>
                        </tr>
                        <tr>
                            <td className="text-center col-sm-2">7</td>
                            <td>One person company</td>
                            <td>MNO Private limited</td>
                        </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">What is the procedure of registering the RUN? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">Following the guidelines of Companies (Incorporation) Amendment Rules 2018
                        issued by MCA, the following steps are to be observed in the <a href={path.askLegalQuestion}
                                                                                        target="_blank"
                                                                                        style={{color: '#ff337d'}}>procedure
                            of RUN</a>:</p>
                    <p className="para"><b>Step 1:</b> Log in to the website of MCA and create an account. Click on the
                        option of “RUN” in MCA service.</p>
                    <p className="para"><b>Step 2:</b> Select the kind of company or entity. Here if you have an
                        existing company and want to change the name, enter the CIN number.</p>
                    <p className="para">Enter the proposed name and the object of the company. You can also upload any
                        file if it is asked and required.</p>
                    <p className="para"><b>Now, submit.</b></p>
                    <p className="para"><b>Step 3:</b> Make the payment for completing the submission by using the
                        available methods.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">When can the resubmission of RUN be made on being rejected? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">No resubmission can be made whether the application is accepted or rejected.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">What is the validity of RUN on being approved? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">If it is a new company, the RUN shall be valid for 20 days since the date of
                        approval and for the change of name for an existing company, it shall be for 60 days.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">What shall be the cost of the whole procedure? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The government fee for the procedure is $1000 and for doing your work,
                        hire your professional with Workbeam.</p>
                    <p className="text-bold">Hiring a professional from Workbeam shall benefit you in the following
                        ways:</p>
                    <ul>
                        <li>As the procedure involves a careful filing and input of all the details, a professional has
                            prior experience of the work.
                        </li>
                        <li>You can relax on this part and spare more time and patience for the
                            operations/sales/marketing of your new company.
                        </li>
                        <li>Each penny you spend shall come under “100% payment security” where the money shall be
                            deposited in an escrow account and shall be paid to the professional only after he/she has
                            completed the work.
                        </li>
                        {/* <li>You can share your documents, chat and call the professional using the Workbeam App on
                            your Android or IOS phone, in which the security is totally assured.
                        </li> */}
                    </ul>
                </div>
            </div>
        </div>
    </div>],
    60: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#01accordian">
                <h4 className="panel-title">Documents required for FSSAI Basic.
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="01accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>Photo Identity proof of promoters</li>
                        <li>Business Constitution certificate (Which can be partnership deed, certificate of
                            incorporation or any other registration, eg: trust)
                        </li>
                        <li>Proof of possession of business premises (Can be rent agreement, or any other utility
                            bill)
                        </li>
                        <li>List of food products manufactured or processed</li>
                        <li>Bank account information ( Opening page of bank passbook held in the name of business or
                            proprietor)
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What is FSSAI exactly?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">If you are a home baker, providing tiffin service or opening a small cafe or
                        food joint, <a href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`}
                                       target="_blank">FSSAI</a> is the basic license you must arrange for when you are
                        starting with a food business.</p>
                    <p className="para">This is a mechanism by the Food Safety and Standards Authority of India, a
                        governmental body to ensure the level of consumable foods and raw materials - it checks the
                        hygiene and cleanliness maintaining in the cooking and baking area, packaging level, and how the
                        food and raw materials are preserved.
                        You can take FSSAI Certificate as the lifeline of your food business.
                    </p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What is the Procedure for getting FSSAI registered? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The procedure for <a
                        href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`} target="_blank">FSSAI
                        Registration</a> are as follows:</p>
                    <ul>
                        <li><b>Step one:</b> Depending on the size of the food business, fill Form A or Form B.</li>
                        <li><b>Step two:</b> The intimation from the department of Food and security shall be
                            communicated to the applicant within seven working days - whether the application is
                            accepted or rejected or has certain issues.
                        </li>
                        <li><b>Step three:</b> If rejected, get back to step one, and if accepted proceed with step
                            three. The department may come for an inspection and clarify if there is any doubt, and
                            grant a certificate of registration along with the number and photo of the applicant.
                        </li>
                        <li><b>Step Four:</b> Frame the certificate the way you like it, and find a good place to
                            display to the public.
                        </li>
                    </ul>
                    <p className="text-bold">For the procedure, certain documents are required which are listed
                        here:</p>
                    <ul>
                        <li>Form A and Form B - duly filled and signed</li>
                        <li>Photo identity of the food business organization</li>
                        <li>Proof of the possession of the property (ownership/rent/lease)</li>
                        <li>Certificate of incorporation/deed of partnership/ Articles of Association</li>
                        <li>List of food products to be served or sold.</li>
                        <li>A plan on how the hygiene, health, and quality of food shall be maintained.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">Can we have a list of each and every document required? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>License</th>
                            <th className="text-center">Documents</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><a href={`/${productIdMappedWithSlug['61'].slug}/${parentPracticeAreaSlug['106'].slug}`}
                                   target="_blank">State license</a></td>
                            <td>
                                <ul>
                                    <li>Form B duly filled and signed</li>
                                    <li>Proper documentation of area allocation for the activities to be carried on -
                                        where the food will be prepared, stored, served, garbage disposal, etc.
                                    </li>
                                    <li>List of directors/partners/proprietors with address proof, contact details and
                                        photo identity of each
                                    </li>
                                    <li>Name and the list of equipment and machinery used with the number and installed
                                        capacity
                                    </li>
                                    <li>List of food category to be manufactured</li>
                                    <li>Authority letter from manufacturer nominated a responsible person name and
                                        address
                                    </li>
                                    <li>The analysis report of water to be used in the process to confirm the
                                        portability
                                    </li>
                                    <li>Proof of possession of premises</li>
                                    <li>Partnership deed/ affidavit of proprietorship</li>
                                    <li>NOC and copy of License from the manufacturer</li>
                                    <li>Copy of certificate obtained under Coop Act 1861/Multi state Coop Act 2002</li>
                                    <li>Food safety management system plan or certificate</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td><a href={`/${productIdMappedWithSlug['62'].slug}/${parentPracticeAreaSlug['106'].slug}`}
                                   target="_blank">Central License</a></td>
                            <td>
                                <ul>
                                    <li>Form B duly completed and signed</li>
                                    <li>Plan of the processing unit showing the dimensions and operation-wise area
                                        allocation
                                    </li>
                                    <li>List of Directors/ Partners/ Proprietor with address, contact details, and photo
                                        ID
                                    </li>
                                    <li>Name and list of equipment and machinery used with the number and installed
                                        capacity
                                    </li>
                                    <li>List of food category to be manufactured</li>
                                    <li>Authority letter from manufacturer nominated a responsible person name and
                                        address
                                    </li>
                                    <li>The analysis report of water to be used in the process to confirm the
                                        portability
                                    </li>
                                    <li>Source of raw material for milk, meat etc</li>
                                    <li>Recall plan wherever applicable</li>
                                    <li>Ministry of Commerce Certificate for 100% EOU</li>
                                    <li><a href="https://cleartax.in/s/no-objection-certificate-noc-format-download"
                                           target="_blan">NOC/PA</a> document issued by FSSAI
                                    </li>
                                    <li>IE code document issued by DGFT</li>
                                    <li>Form IX</li>
                                    <li>Certificate from Ministry of Tourism</li>
                                    <li>Proof of possession of premises</li>
                                    <li>Partnership deed/ affidavit of proprietorship</li>
                                    <li>NOC and copy of License from the manufacturer</li>
                                    <li>Food safety management system plan or certificate</li>
                                    <li>NOC from the municipality or local body</li>
                                    <li>Supporting document for proof of turnover and transportation</li>
                                    <li>Declaration form</li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">What are the kinds of FSSAI registration? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <p className="text-bold">The entities intending to obtain FSSAI licenses are divided into three
                        categories:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th className="text-center">Registration</th>
                            <th className="text-center">Form</th>
                            <th className="text-center">Annual Turnover</th>
                            <th className="text-center col-sm-4">Kind of food</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><a href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`}>Basic
                                Registration</a></td>
                            <td>Form A</td>
                            <td>Not exceeding $12 lakhs</td>
                            <td>
                                <ul>
                                    <li>Daily production of animal products is not exceeding 100kg/liter.</li>
                                    <li>Daily procurement of milk products not exceeding 500 liters</li>
                                    <li>Daily slaughtering capacity not exceeding - 2 large animals/10 small animals/50
                                        poultry birds.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td><a href={`/${productIdMappedWithSlug['61'].slug}/${parentPracticeAreaSlug['106'].slug}`}
                                   target="_blank" style={{color: '#ff337d'}}>State Registration</a></td>
                            <td>Form B</td>
                            <td>Between 12 to 20 lakhs</td>
                            <td>
                                <ul>
                                    <li>Daily production of animal products is not exceeding 500kg/liter.</li>
                                    <li>Daily procurement of milk products not exceeding 5000 liters</li>
                                    <li>Daily slaughtering capacity not exceeding - 50 large animals/150 small
                                        animals/1000 poultry birds.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td><a href={`/${productIdMappedWithSlug['62'].slug}/${parentPracticeAreaSlug['106'].slug}`}
                                   target="_blank" style={{color: '#ff337d'}}>Central License</a></td>
                            <td>Form B</td>
                            <td>Above 20 crores</td>
                            <td>
                                <ul>
                                    <li>Dairy units - 50,000 litres daily/2500 metric tonnes milk solid annually</li>
                                    <li>Vegetable oil products - 2 metric tonnes daily</li>
                                    <li>Slaughterhouses - 50 large animals/150 small animals/1000 poultry birds daily.
                                    </li>
                                    <li>Meat processing - 500kg daily/150 metric tonnes annually</li>
                                    <li>All the export oriented units</li>
                                    <li>Imported food items for commercial use</li>
                                    <li>Has business operating units in atleast two states</li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">Once rejected, can a fresh application be filed for an FSSAI license? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, a fresh application for <a
                        href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`} target="_blank">FSSAI
                        registration</a> can be filed only after 90 days from the date of rejection of the previous
                        application.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">Why do I need to hire a professionals when I can apply it myself? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Hiring a professional reduces your stress, and assures an expected result, and
                        when you <a href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`}
                                    target="_blank">hire an FSSAI professional</a> through Workbeam, you get the money
                        paid to the insured and easy track of the work going on.</p>
                    <p className="para">Why to take this risk, when Workbeam is offering a professional within a
                        reasonable price, that’s definitely a fact to think upon. Moreover, hiring a professional online
                        through Workbeam is easy, convenient, as well as the whole track of work and communication is
                        online. It is all meant to reduce your stress, not to increase it, indeed.</p>
                    <p className="para">Talking about the fees, and expecting a quality work in return, Workbeam
                        provides a 100% money security, under which each penny you are paying the professional through
                        Workbeam, remains in an escrow account, and only after the professional completes the work,
                        the payment is released.</p>
                    <p className="para">Therefore, think twice, act wise.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">When does a Notice for Improvement is issued? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Any business obtained FSSAI license has to update itself to comply with the
                        rules introduced and updated by the FSSAI, time to time. The Authority also conducts an
                        inspection, both previously noticed as well as sudden.</p>
                    <p className="text-bold">At the time of inspection, the level of compliance is reported based on the
                        following list:</p>
                    <ul>
                        <li>Compliance</li>
                        <li>Partial Compliance</li>
                        <li>Non-COmpliance</li>
                        <li>Not Applicable</li>
                    </ul>
                    <p className="para">Based on this, a notice of improvement may be sent to the FBO which mentions the
                        changes to be made for the purpose of compliance. For making this change, a time limit is also
                        specified in this notice. If the FBO fails to comply with the changes within this time, a
                        show-cause notice may be sent to appear before the Commissioner for Food Safety of that state
                        and explain the reason behind such failure.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">What are the penalties for non-compliance? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">In case an FBO has failed to <a
                        href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`} target="_blank">comply
                        with the guidelines</a>, it is penalized based on the following conditions:</p>
                    <ul>
                        <li>If the FBO is carrying on the operation without even applying to FSSAI, then the penalty can
                            be up to 5 lakhs.
                        </li>
                        <li>If any substandard quality of food is served, then the penalty can be upto 5 lakhs</li>
                        <li>If the food product contains any flawed ingredients or extra ingredients which is not
                            mentioned, then the fine can be up to 1 lakh.
                        </li>
                    </ul>
                    <p className="para">Based on this, a notice of improvement may be sent to the FBO which mentions the
                        changes to be made for the purpose of compliance. For making this change, a time limit is also
                        specified in this notice. If the FBO fails to comply with the changes within this time, a
                        show-cause notice may be sent to appear before the Commissioner for Food Safety of that state
                        and explain the reason behind such failure.
                    </p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#9accordian">
                <h4 className="panel-title">What is Food Recall? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="9accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Any food item which is sold/distributed or consumed, with the <a
                        href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`} target="_blank">FSSAI
                        license</a>, but later on it caused any harm or found to be harmful for the consumers, then it
                        can be removed from the market under the policy of food recall.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#10accordian">
                <h4 className="panel-title">How can the FSSAI license be renewed? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="10accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The <a href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`}
                                               target="_blank">FSSAI license</a> can be renewed on the portal itself by
                        filling the form C.
                        In case you fail to apply for the renewal before the expiry of the license, you shall have to
                        count penalty of $100/day for the gap between the expiry date and application of renewal day.
                    </p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#11accordian">
                <h4 className="panel-title">Is there any microbiological category of food specified under the rules of
                    FSSAI? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="11accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">There are no such rules on the microbiological items are specified under the
                        rules of <a href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`}
                                    target="_blank">FSSAI</a> yet, but they can be covered under Nonspecified
                        categories. The non-specified categories include:</p>
                    <ul>
                        <li>Novel food items, or ingredients prepared using a novel technology</li>
                        <li>New food additives</li>
                        <li>New processing aids and enzymes</li>
                        <li>Food ingredients consisting of any different kinds of algae, fungi or microorganisms.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>],
    61: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#01accordian">
                <h4 className="panel-title">Documents required for FSSAI State.
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="01accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>Photo Identity proof of promoters</li>
                        <li>Business Constitution certificate (Which can be partnership deed, certificate of
                            incorporation or any other registration, eg: trust)
                        </li>
                        <li>Proof of possession of business premises (Can be rent agreement, or any other utility
                            bill)
                        </li>
                        <li>List of food products manufactured or processed</li>
                        <li>Bank account information ( Opening page of bank passbook held in the name of business or
                            proprietor)
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What is FSSAI exactly?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">If you are a home baker, providing tiffin service or opening a small cafe or
                        food joint, <a href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`}
                                       target="_blank">FSSAI</a> is the basic license you must arrange for when you are
                        starting with a food business.</p>
                    <p className="para">This is a mechanism by the Food Safety and Standards Authority of India, a
                        governmental body to ensure the level of consumable foods and raw materials - it checks the
                        hygiene and cleanliness maintaining in the cooking and baking area, packaging level, and how the
                        food and raw materials are preserved.
                        You can take FSSAI Certificate as the lifeline of your food business.
                    </p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What is the Procedure for getting FSSAI registered? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The procedure for <a
                        href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`} target="_blank">FSSAI
                        Registration</a> are as follows:</p>
                    <ul>
                        <li><b>Step one:</b> Depending on the size of the food business, fill Form A or Form B.</li>
                        <li><b>Step two:</b> The intimation from the department of Food and security shall be
                            communicated to the applicant within seven working days - whether the application is
                            accepted or rejected or has certain issues.
                        </li>
                        <li><b>Step three:</b> If rejected, get back to step one, and if accepted proceed with step
                            three. The department may come for an inspection and clarify if there is any doubt, and
                            grant a certificate of registration along with the number and photo of the applicant.
                        </li>
                        <li><b>Step Four:</b> Frame the certificate the way you like it, and find a good place to
                            display to the public.
                        </li>
                    </ul>
                    <p className="text-bold">For the procedure, certain documents are required which are listed
                        here:</p>
                    <ul>
                        <li>Form A and Form B - duly filled and signed</li>
                        <li>Photo identity of the food business organization</li>
                        <li>Proof of the possession of the property (ownership/rent/lease)</li>
                        <li>Certificate of incorporation/deed of partnership/ Articles of Association</li>
                        <li>List of food products to be served or sold.</li>
                        <li>A plan on how the hygiene, health, and quality of food shall be maintained.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">Can we have a list of each and every document required? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>License</th>
                            <th className="text-center">Documents</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><a href={`/${productIdMappedWithSlug['61'].slug}/${parentPracticeAreaSlug['106'].slug}`}
                                   target="_blank">State license</a></td>
                            <td>
                                <ul>
                                    <li>Form B duly filled and signed</li>
                                    <li>Proper documentation of area allocation for the activities to be carried on -
                                        where the food will be prepared, stored, served, garbage disposal, etc.
                                    </li>
                                    <li>List of directors/partners/proprietors with address proof, contact details and
                                        photo identity of each
                                    </li>
                                    <li>Name and the list of equipment and machinery used with the number and installed
                                        capacity
                                    </li>
                                    <li>List of food category to be manufactured</li>
                                    <li>Authority letter from manufacturer nominated a responsible person name and
                                        address
                                    </li>
                                    <li>The analysis report of water to be used in the process to confirm the
                                        portability
                                    </li>
                                    <li>Proof of possession of premises</li>
                                    <li>Partnership deed/ affidavit of proprietorship</li>
                                    <li>NOC and copy of License from the manufacturer</li>
                                    <li>Copy of certificate obtained under Coop Act 1861/Multi state Coop Act 2002</li>
                                    <li>Food safety management system plan or certificate</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td><a href={`/${productIdMappedWithSlug['62'].slug}/${parentPracticeAreaSlug['106'].slug}`}
                                   target="_blank">Central License</a></td>
                            <td>
                                <ul>
                                    <li>Form B duly completed and signed</li>
                                    <li>Plan of the processing unit showing the dimensions and operation-wise area
                                        allocation
                                    </li>
                                    <li>List of Directors/ Partners/ Proprietor with address, contact details, and photo
                                        ID
                                    </li>
                                    <li>Name and list of equipment and machinery used with the number and installed
                                        capacity
                                    </li>
                                    <li>List of food category to be manufactured</li>
                                    <li>Authority letter from manufacturer nominated a responsible person name and
                                        address
                                    </li>
                                    <li>The analysis report of water to be used in the process to confirm the
                                        portability
                                    </li>
                                    <li>Source of raw material for milk, meat etc</li>
                                    <li>Recall plan wherever applicable</li>
                                    <li>Ministry of Commerce Certificate for 100% EOU</li>
                                    <li><a href="https://cleartax.in/s/no-objection-certificate-noc-format-download"
                                           target="_blan">NOC/PA</a> document issued by FSSAI
                                    </li>
                                    <li>IE code document issued by DGFT</li>
                                    <li>Form IX</li>
                                    <li>Certificate from Ministry of Tourism</li>
                                    <li>Proof of possession of premises</li>
                                    <li>Partnership deed/ affidavit of proprietorship</li>
                                    <li>NOC and copy of License from the manufacturer</li>
                                    <li>Food safety management system plan or certificate</li>
                                    <li>NOC from the municipality or local body</li>
                                    <li>Supporting document for proof of turnover and transportation</li>
                                    <li>Declaration form</li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">What are the kinds of FSSAI registration? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <p className="text-bold">The entities intending to obtain FSSAI licenses are divided into three
                        categories:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th className="text-center">Registration</th>
                            <th className="text-center">Form</th>
                            <th className="text-center">Annual Turnover</th>
                            <th className="text-center col-sm-4">Kind of food</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><a href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`}>Basic
                                Registration</a></td>
                            <td>Form A</td>
                            <td>Not exceeding 12 lakhs</td>
                            <td>
                                <ul>
                                    <li>Daily production of animal products is not exceeding 100kg/liter.</li>
                                    <li>Daily procurement of milk products not exceeding 500 liters</li>
                                    <li>Daily slaughtering capacity not exceeding - 2 large animals/10 small animals/50
                                        poultry birds.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td><a href={`/${productIdMappedWithSlug['61'].slug}/${parentPracticeAreaSlug['106'].slug}`}
                                   target="_blank" style={{color: '#ff337d'}}>State Registration</a></td>
                            <td>Form B</td>
                            <td>Between 12 to 20 lakhs</td>
                            <td>
                                <ul>
                                    <li>Daily production of animal products is not exceeding 500kg/liter.</li>
                                    <li>Daily procurement of milk products not exceeding 5000 liters</li>
                                    <li>Daily slaughtering capacity not exceeding - 50 large animals/150 small
                                        animals/1000 poultry birds.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td><a href={`/${productIdMappedWithSlug['62'].slug}/${parentPracticeAreaSlug['106'].slug}`}
                                   target="_blank" style={{color: '#ff337d'}}>Central License</a></td>
                            <td>Form B</td>
                            <td>Above 20 crores</td>
                            <td>
                                <ul>
                                    <li>Dairy units - 50,000 litres daily/2500 metric tonnes milk solid annually</li>
                                    <li>Vegetable oil products - 2 metric tonnes daily</li>
                                    <li>Slaughterhouses - 50 large animals/150 small animals/1000 poultry birds daily.
                                    </li>
                                    <li>Meat processing - 500kg daily/150 metric tonnes annually</li>
                                    <li>All the export oriented units</li>
                                    <li>Imported food items for commercial use</li>
                                    <li>Has business operating units in atleast two states</li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">Once rejected, can a fresh application be filed for an FSSAI license? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, a fresh application for <a
                        href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`} target="_blank">FSSAI
                        registration</a> can be filed only after 90 days from the date of rejection of the previous
                        application.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">Why do I need to hire a professionals when I can apply it myself? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Hiring a professional reduces your stress, and assures an expected result, and
                        when you <a href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`}
                                    target="_blank">hire an FSSAI professional</a> through Workbeam, you get the money
                        paid to the insured and easy track of the work going on.</p>
                    <p className="para">Why to take this risk, when Workbeam is offering a professional within a
                        reasonable price, that’s definitely a fact to think upon. Moreover, hiring a professional online
                        through Workbeam is easy, convenient, as well as the whole track of work and communication is
                        online. It is all meant to reduce your stress, not to increase it, indeed.</p>
                    <p className="para">Talking about the fees, and expecting a quality work in return, Workbeam
                        provides a 100% money security, under which each penny you are paying the professional through
                        Workbeam, remains in an escrow account, and only after the professional completes the work,
                        the payment is released.</p>
                    <p className="para">Therefore, think twice, act wise.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">When does a Notice for Improvement is issued? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Any business obtained FSSAI license has to update itself to comply with the
                        rules introduced and updated by the FSSAI, time to time. The Authority also conducts an
                        inspection, both previously noticed as well as sudden.</p>
                    <p className="text-bold">At the time of inspection, the level of compliance is reported based on the
                        following list:</p>
                    <ul>
                        <li>Compliance</li>
                        <li>Partial Compliance</li>
                        <li>Non-COmpliance</li>
                        <li>Not Applicable</li>
                    </ul>
                    <p className="para">Based on this, a notice of improvement may be sent to the FBO which mentions the
                        changes to be made for the purpose of compliance. For making this change, a time limit is also
                        specified in this notice. If the FBO fails to comply with the changes within this time, a
                        show-cause notice may be sent to appear before the Commissioner for Food Safety of that state
                        and explain the reason behind such failure.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">What are the penalties for non-compliance? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">In case an FBO has failed to <a
                        href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`} target="_blank">comply
                        with the guidelines</a>, it is penalized based on the following conditions:</p>
                    <ul>
                        <li>If the FBO is carrying on the operation without even applying to FSSAI, then the penalty can
                            be up to 5 lakhs.
                        </li>
                        <li>If any substandard quality of food is served, then the penalty can be upto 5 lakhs</li>
                        <li>If the food product contains any flawed ingredients or extra ingredients which is not
                            mentioned, then the fine can be up to 1 lakh.
                        </li>
                    </ul>
                    <p className="para">Based on this, a notice of improvement may be sent to the FBO which mentions the
                        changes to be made for the purpose of compliance. For making this change, a time limit is also
                        specified in this notice. If the FBO fails to comply with the changes within this time, a
                        show-cause notice may be sent to appear before the Commissioner for Food Safety of that state
                        and explain the reason behind such failure.
                    </p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#9accordian">
                <h4 className="panel-title">What is Food Recall? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="9accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Any food item which is sold/distributed or consumed, with the <a
                        href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`} target="_blank">FSSAI
                        license</a>, but later on it caused any harm or found to be harmful for the consumers, then it
                        can be removed from the market under the policy of food recall.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#10accordian">
                <h4 className="panel-title">How can the FSSAI license be renewed? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="10accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The <a href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`}
                                               target="_blank">FSSAI license</a> can be renewed on the portal itself by
                        filling the form C.
                        In case you fail to apply for the renewal before the expiry of the license, you shall have to
                        count penalty of $100/day for the gap between the expiry date and application of renewal day.
                    </p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#11accordian">
                <h4 className="panel-title">Is there any microbiological category of food specified under the rules of
                    FSSAI? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="11accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">There are no such rules on the microbiological items are specified under the
                        rules of <a href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`}
                                    target="_blank">FSSAI</a> yet, but they can be covered under Nonspecified
                        categories. The non-specified categories include:</p>
                    <ul>
                        <li>Novel food items, or ingredients prepared using a novel technology</li>
                        <li>New food additives</li>
                        <li>New processing aids and enzymes</li>
                        <li>Food ingredients consisting of any different kinds of algae, fungi or microorganisms.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>],
    62: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What is FSSAI exactly?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">If you are a home baker, providing tiffin service or opening a small cafe or
                        food joint, <a href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`}
                                       target="_blank">FSSAI</a> is the basic license you must arrange for when you are
                        starting with a food business.</p>
                    <p className="para">This is a mechanism by the Food Safety and Standards Authority of India, a
                        governmental body to ensure the level of consumable foods and raw materials - it checks the
                        hygiene and cleanliness maintaining in the cooking and baking area, packaging level, and how the
                        food and raw materials are preserved.
                        You can take FSSAI Certificate as the lifeline of your food business.
                    </p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What is the Procedure for getting FSSAI registered? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The procedure for <a
                        href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`} target="_blank">FSSAI
                        Registration</a> are as follows:</p>
                    <ul>
                        <li><b>Step one:</b> Depending on the size of the food business, fill Form A or Form B.</li>
                        <li><b>Step two:</b> The intimation from the department of Food and security shall be
                            communicated to the applicant within seven working days - whether the application is
                            accepted or rejected or has certain issues.
                        </li>
                        <li><b>Step three:</b> If rejected, get back to step one, and if accepted proceed with step
                            three. The department may come for an inspection and clarify if there is any doubt, and
                            grant a certificate of registration along with the number and photo of the applicant.
                        </li>
                        <li><b>Step Four:</b> Frame the certificate the way you like it, and find a good place to
                            display to the public.
                        </li>
                    </ul>
                    <p className="text-bold">For the procedure, certain documents are required which are listed
                        here:</p>
                    <ul>
                        <li>Form A and Form B - duly filled and signed</li>
                        <li>Photo identity of the food business organization</li>
                        <li>Proof of the possession of the property (ownership/rent/lease)</li>
                        <li>Certificate of incorporation/deed of partnership/ Articles of Association</li>
                        <li>List of food products to be served or sold.</li>
                        <li>A plan on how the hygiene, health, and quality of food shall be maintained.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">Can we have a list of each and every document required? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>License</th>
                            <th className="text-center">Documents</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><a href={`/${productIdMappedWithSlug['61'].slug}/${parentPracticeAreaSlug['106'].slug}`}
                                   target="_blank">State license</a></td>
                            <td>
                                <ul>
                                    <li>Form B duly filled and signed</li>
                                    <li>Proper documentation of area allocation for the activities to be carried on -
                                        where the food will be prepared, stored, served, garbage disposal, etc.
                                    </li>
                                    <li>List of directors/partners/proprietors with address proof, contact details and
                                        photo identity of each
                                    </li>
                                    <li>Name and the list of equipment and machinery used with the number and installed
                                        capacity
                                    </li>
                                    <li>List of food category to be manufactured</li>
                                    <li>Authority letter from manufacturer nominated a responsible person name and
                                        address
                                    </li>
                                    <li>The analysis report of water to be used in the process to confirm the
                                        portability
                                    </li>
                                    <li>Proof of possession of premises</li>
                                    <li>Partnership deed/ affidavit of proprietorship</li>
                                    <li>NOC and copy of License from the manufacturer</li>
                                    <li>Copy of certificate obtained under Coop Act 1861/Multi state Coop Act 2002</li>
                                    <li>Food safety management system plan or certificate</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td><a href={`/${productIdMappedWithSlug['62'].slug}/${parentPracticeAreaSlug['106'].slug}`}
                                   target="_blank">Central License</a></td>
                            <td>
                                <ul>
                                    <li>Form B duly completed and signed</li>
                                    <li>Plan of the processing unit showing the dimensions and operation-wise area
                                        allocation
                                    </li>
                                    <li>List of Directors/ Partners/ Proprietor with address, contact details, and photo
                                        ID
                                    </li>
                                    <li>Name and list of equipment and machinery used with the number and installed
                                        capacity
                                    </li>
                                    <li>List of food category to be manufactured</li>
                                    <li>Authority letter from manufacturer nominated a responsible person name and
                                        address
                                    </li>
                                    <li>The analysis report of water to be used in the process to confirm the
                                        portability
                                    </li>
                                    <li>Source of raw material for milk, meat etc</li>
                                    <li>Recall plan wherever applicable</li>
                                    <li>Ministry of Commerce Certificate for 100% EOU</li>
                                    <li><a href="https://cleartax.in/s/no-objection-certificate-noc-format-download"
                                           target="_blan">NOC/PA</a> document issued by FSSAI
                                    </li>
                                    <li>IE code document issued by DGFT</li>
                                    <li>Form IX</li>
                                    <li>Certificate from Ministry of Tourism</li>
                                    <li>Proof of possession of premises</li>
                                    <li>Partnership deed/ affidavit of proprietorship</li>
                                    <li>NOC and copy of License from the manufacturer</li>
                                    <li>Food safety management system plan or certificate</li>
                                    <li>NOC from the municipality or local body</li>
                                    <li>Supporting document for proof of turnover and transportation</li>
                                    <li>Declaration form</li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">What are the kinds of FSSAI registration? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <p className="text-bold">The entities intending to obtain FSSAI licenses are divided into three
                        categories:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th className="text-center">Registration</th>
                            <th className="text-center">Form</th>
                            <th className="text-center">Annual Turnover</th>
                            <th className="text-center col-sm-4">Kind of food</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><a href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`}>Basic
                                Registration</a></td>
                            <td>Form A</td>
                            <td>Not exceeding 12 lakhs</td>
                            <td>
                                <ul>
                                    <li>Daily production of animal products is not exceeding 100kg/liter.</li>
                                    <li>Daily procurement of milk products not exceeding 500 liters</li>
                                    <li>Daily slaughtering capacity not exceeding - 2 large animals/10 small animals/50
                                        poultry birds.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td><a href={`/${productIdMappedWithSlug['61'].slug}/${parentPracticeAreaSlug['106'].slug}`}
                                   target="_blank" style={{color: '#ff337d'}}>State Registration</a></td>
                            <td>Form B</td>
                            <td>Between 12 to 20 lakhs</td>
                            <td>
                                <ul>
                                    <li>Daily production of animal products is not exceeding 500kg/liter.</li>
                                    <li>Daily procurement of milk products not exceeding 5000 liters</li>
                                    <li>Daily slaughtering capacity not exceeding - 50 large animals/150 small
                                        animals/1000 poultry birds.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td><a href={`/${productIdMappedWithSlug['62'].slug}/${parentPracticeAreaSlug['106'].slug}`}
                                   target="_blank" style={{color: '#ff337d'}}>Central License</a></td>
                            <td>Form B</td>
                            <td>Above 20 crores</td>
                            <td>
                                <ul>
                                    <li>Dairy units - 50,000 litres daily/2500 metric tonnes milk solid annually</li>
                                    <li>Vegetable oil products - 2 metric tonnes daily</li>
                                    <li>Slaughterhouses - 50 large animals/150 small animals/1000 poultry birds daily.
                                    </li>
                                    <li>Meat processing - 500kg daily/150 metric tonnes annually</li>
                                    <li>All the export oriented units</li>
                                    <li>Imported food items for commercial use</li>
                                    <li>Has business operating units in atleast two states</li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">Once rejected, can a fresh application be filed for an FSSAI license? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, a fresh application for <a
                        href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`} target="_blank">FSSAI
                        registration</a> can be filed only after 90 days from the date of rejection of the previous
                        application.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">Why do I need to hire a professionals when I can apply it myself? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Hiring a professional reduces your stress, and assures an expected result, and
                        when you <a href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`}
                                    target="_blank">hire an FSSAI professional</a> through Workbeam, you get the money
                        paid to the insured and easy track of the work going on.</p>
                    <p className="para">Why to take this risk, when Workbeam is offering a professional within a
                        reasonable price, that’s definitely a fact to think upon. Moreover, hiring a professional online
                        through Workbeam is easy, convenient, as well as the whole track of work and communication is
                        online. It is all meant to reduce your stress, not to increase it, indeed.</p>
                    <p className="para">Talking about the fees, and expecting a quality work in return, Workbeam
                        provides a 100% money security, under which each penny you are paying the professional through
                        Workbeam, remains in an escrow account, and only after the professional completes the work,
                        the payment is released.</p>
                    <p className="para">Therefore, think twice, act wise.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">When does a Notice for Improvement is issued? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Any business obtained FSSAI license has to update itself to comply with the
                        rules introduced and updated by the FSSAI, time to time. The Authority also conducts an
                        inspection, both previously noticed as well as sudden.</p>
                    <p className="text-bold">At the time of inspection, the level of compliance is reported based on the
                        following list:</p>
                    <ul>
                        <li>Compliance</li>
                        <li>Partial Compliance</li>
                        <li>Non-COmpliance</li>
                        <li>Not Applicable</li>
                    </ul>
                    <p className="para">Based on this, a notice of improvement may be sent to the FBO which mentions the
                        changes to be made for the purpose of compliance. For making this change, a time limit is also
                        specified in this notice. If the FBO fails to comply with the changes within this time, a
                        show-cause notice may be sent to appear before the Commissioner for Food Safety of that state
                        and explain the reason behind such failure.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">What are the penalties for non-compliance? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">In case an FBO has failed to <a
                        href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`} target="_blank">comply
                        with the guidelines</a>, it is penalized based on the following conditions:</p>
                    <ul>
                        <li>If the FBO is carrying on the operation without even applying to FSSAI, then the penalty can
                            be up to 5 lakhs.
                        </li>
                        <li>If any substandard quality of food is served, then the penalty can be upto 5 lakhs</li>
                        <li>If the food product contains any flawed ingredients or extra ingredients which is not
                            mentioned, then the fine can be up to 1 lakh.
                        </li>
                    </ul>
                    <p className="para">Based on this, a notice of improvement may be sent to the FBO which mentions the
                        changes to be made for the purpose of compliance. For making this change, a time limit is also
                        specified in this notice. If the FBO fails to comply with the changes within this time, a
                        show-cause notice may be sent to appear before the Commissioner for Food Safety of that state
                        and explain the reason behind such failure.
                    </p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#9accordian">
                <h4 className="panel-title">What is Food Recall? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="9accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Any food item which is sold/distributed or consumed, with the <a
                        href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`} target="_blank">FSSAI
                        license</a>, but later on it caused any harm or found to be harmful for the consumers, then it
                        can be removed from the market under the policy of food recall.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#10accordian">
                <h4 className="panel-title">How can the FSSAI license be renewed? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="10accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The <a href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`}
                                               target="_blank">FSSAI license</a> can be renewed on the portal itself by
                        filling the form C.
                        In case you fail to apply for the renewal before the expiry of the license, you shall have to
                        count penalty of $100/day for the gap between the expiry date and application of renewal day.
                    </p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#11accordian">
                <h4 className="panel-title">Is there any microbiological category of food specified under the rules of
                    FSSAI? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="11accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">There are no such rules on the microbiological items are specified under the
                        rules of <a href={`/${productIdMappedWithSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`}
                                    target="_blank">FSSAI</a> yet, but they can be covered under Nonspecified
                        categories. The non-specified categories include:</p>
                    <ul>
                        <li>Novel food items, or ingredients prepared using a novel technology</li>
                        <li>New food additives</li>
                        <li>New processing aids and enzymes</li>
                        <li>Food ingredients consisting of any different kinds of algae, fungi or microorganisms.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>],
    63: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">Who is liable to pay professional tax?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Any person carrying on any profession, trade or commerce is liable to pay tax to
                        the State Government authority.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">When One has to register to pay the professional tax? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The taxpayer has to apply to register within 30 days of starting off with the
                        profession.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">What are the kinds of Certificates for Professional taxpayers? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Enrollment Certificate: Any professional other than a person earning salary or
                        wages for which the tax is payable.</p>
                    <p className="para">Registration Certificate: All the non-government organizations' employees should
                        obtain a registration certificate.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">What procedure for registration? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">Here is the step how the Professional Tax registration shall be done:</p>
                    <ul>
                        <li>The form I shall be filed for the RC and for EC, form II should be filed.</li>
                        <li>It can be filed by creating a profile on the website of the respective State authority.</li>
                        <li>The taxpayer shall create the profile under any of the following options - "new dealer
                            registration" or " Other Acts Registration"
                        </li>
                        <li>Along with the form, also submit the following documents - proof that describes the date of
                            business started, a record of accounts, detail of the employees, details of paid salaries
                            and address proof.
                        </li>
                        <li>On acceptance of the application, a mail shall be sent to the registered email ID containing
                            digitally signed RC/EC. The taxpayer can check the status by entering the PAN number.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">What is the penalty for failure of registration? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para"><a href={path.askLegalQuestion} target="_blank" style={{color: '#ff337d'}}>On
                        failure to register</a>, as per Sec. 5(5) of the Professional Tax Act, for each delayed date
                        post the allowed period, a penalty of rupees five shall be imposed.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">How to change the address of the business? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">To change the address, it can be done under the option of 'amendment'.
                        Similarly, you can change your email ID or phone number as well.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">How the payment shall be done? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The employee is not liable to pay the tax amount, rather the employer must pay
                        it and deduct the amount to the employee's tax liability from the.monthly salary of the
                        employee. The employer can do so online on the website of the respective authority.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">Is there any other option of paying the tax other than online? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The taxpayer can pay the professional tax by using "Payment Across the Bank
                        Counter" provided by the authority. It can be submitted at the bank by filling the challan.</p>
                </div>
            </div>
        </div>
    </div>],
    64: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What are the documents required for MSME Registration <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>Business Address Proof - Rent Agreement with NOC if Rented or Sale Deed with latest Utility
                            Bill if self owned
                        </li>
                        <li>Copies of Sale Bill and Purchase Bill</li>
                        <li>Partnership Deed if a partnership firm or MoA and AoA if Company or LLP</li>
                        <li>Copy of Licenses and Bills of Machinery Purchased</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>],
    65: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What is the definition and main purpose of the Shop and Establishment Act?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Establishment by definition means a shop or commercial establishment, hotel to
                        stay, restaurants, cafes, theatre, or any other place for public amusement or entertainment as
                        per the rules established by the State Government by notification in the Official Gazette.
                        Along with Central, the State Governments also has its own set of rules in regard to the working
                        condition of its labors. This Act helps the government to regulate the working conditions of the
                        employees of different establishments mean for public amusement and entertainment.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What is the regulatory body for the Shop and Establishment Act? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">There is no common law for regulating this all over the country, Different laws
                        are available for different regions in the country, governed by the State government.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">How to register under the Shop and Establishment Act? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">Every <a href={path.covid19FreeSignup} target="_blank"
                                                      style={{color: '#ff337d'}}>shop and establishment has to register
                        itself</a> within 30 days of commencement of the Act. The registration application must be
                        submitted along with the copies of these documents:</p>
                    <ul>
                        <li>Photo of the applicant</li>
                        <li>Photo of the shop, with the owner/owners in the same frame.</li>
                        <li>List of employees, wages, and bonuses.</li>
                        <li>Address proof of the shop or establishment</li>
                        <li>Affidavit.</li>
                    </ul>
                    <p className="para">A certain amount of fee also has to be paid along with the application which is
                        specified by the law based on the number of workers. The authority on being satisfied with the
                        information furnished a license shall be issued, which must be displayed at a publicly visible
                        place inside the shop or establishment.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">What aspects are regulated by the Shop and Establishment Act? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The <a href={path.askLegalQuestion} target="_blank"
                                                    style={{color: '#ff337d'}}>aspects which are regulated by the Shop
                        and Establishment Act</a> are as follows:</p>
                    <ul>
                        <li>Working hours</li>
                        <li>Intervals for rest and meals</li>
                        <li>No employment of children below 14 years (for nonhazardous works only)</li>
                        <li>Opening and closing hours</li>
                        <li>Week offs</li>
                        <li>Holidays</li>
                        <li>No wages deduction for holidays and week offs</li>
                        <li>Time and conditions of payment of wages.</li>
                        <li>Deduction rate from the wages</li>
                        <li>Leave policy</li>
                        <li>Dismissal</li>
                        <li>Healthy environment- access of enough light and ventilation</li>
                        <li>Fire extinguisher</li>
                        <li>Accidents</li>
                        <li>Record Keeping.</li>
                    </ul>
                    <p className="para">It may differ from state to state, and the state government has the power to
                        make such adjustment and difference.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">If one sells its own manufactured goods and is already registered as a
                    company, is such entity required to register under Shops and Establishment Act? <i
                        className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, it has to register under the Shop and Establishment Act, even it is
                        registered under the Companies Act 2013.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">If one shifts the shop/establishment, is any formality to be followed? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">If there is any change is address or the shop or establishment closes down, then
                        the owner has to inform the authority within 15 days of such action or incident.</p>
                </div>
            </div>
        </div>
    </div>],
    93: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#01accordian">
                <h4 className="panel-title">Documents required for Income Tax Filing
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="01accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>All Form 16</li>
                        <li>Bank Statements of all Bank Accounts</li>
                        <li>Investment Declaration Proof - LIC, PPF, Tax Saving FD etc.</li>
                        <li>Form 26AS</li>
                        <li>PAN & AADHAR CARD Copy</li>
                        <li>Demat Statement for Trading Income</li>
                        <li>Sale and Purchase Deed Details for Capital Gain Income</li>
                        <li>Profit & Loss Account and Balance Sheet for Business Income</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What is the Income Tax slab for FY 20-21?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <p class="para">For this FY, there are two options available to the <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">taxpayers</a> to choose.</p>
                    <p className="text-bold">New Tax slab:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Total Income ($)</th>
                            <th>Tax Rate (%)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>&lt;2.5 lakh</td>
                            <td>0</td>
                        </tr>
                        <tr>
                            <td>2.50,000-5,00,000</td>
                            <td>5</td>
                        </tr>
                        <tr>
                            <td>5,00,001- 7,50,000</td>
                            <td>10</td>
                        </tr>
                        <tr>
                            <td>7,50,001-10,00,000</td>
                            <td>15</td>
                        </tr>
                        <tr>
                            <td>10,00,001-12,50,000</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td>12,50,001- 15,00,000</td>
                            <td>25</td>
                        </tr>
                        <tr>
                            <td>&gt;15,00,000</td>
                            <td>30</td>
                        </tr>
                        </tbody>
                    </table>
                    <p className="para">If one is following this new tax slab, one shall not be entitled to enjoy any of
                        the deduction benefits under the Income Tax Act 1961. However, who are of opinion that the
                        deductions were more beneficial, they can still follow the tax slab of the FY 2019-20 to pay
                        their tax.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">Old Tax regime or the new one, which should be followed? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <p className="para">Which one you want to follow, is totally dependent on your income and
                        investments you have made.</p>
                    <p className="text-bold">Let us explain to you with an example:-</p>
                    <p className="text-bold"><a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                target="_blank">Income tax</a> payable as per old regime:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Particulars</th>
                            <th>Amount ($)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Gross Salary</td>
                            <td>10,00,000</td>
                        </tr>
                        <tr>
                            <td>Standard Deduction</td>
                            <td>37700</td>
                        </tr>
                        <tr>
                            <td>Income under the head salary</td>
                            <td>962300</td>
                        </tr>
                        <tr>
                            <td>Interest on home loan</td>
                            <td>2,00,000</td>
                        </tr>
                        <tr>
                            <td>Net Income</td>
                            <td>762300</td>
                        </tr>
                        <tr>
                            <td>Deduction u/s 80C</td>
                            <td>-1,50,000</td>
                        </tr>
                        <tr>
                            <td>Health insurance deduction u/s 80D</td>
                            <td>-75000</td>
                        </tr>

                        <tr>
                            <td>Deduction u/s 80CCD for NPS contribution</td>
                            <td>-47000</td>
                        </tr>
                        <tr>
                            <td>Taxable Income</td>
                            <td>4,90,300</td>
                        </tr>
                        <tr>
                            <td>Rate of tax levied</td>
                            <td>0% for &lt;= 2,50,000 and 5% 2,50,001 to 5,00,000</td>
                        </tr>
                        <tr>
                            <td>Amount of tax</td>
                            <td>24515</td>
                        </tr>
                        <tr>
                            <td>Health and education cess 4%</td>
                            <td>980.6</td>
                        </tr>
                        <tr>
                            <td>Tax Total</td>
                            <td>25,131.6</td>
                        </tr>
                        <tr>
                            <td>Tax rebate u/s 87A</td>
                            <td>12,500</td>
                        </tr>
                        <tr>
                            <td>Tax payable</td>
                            <td>12,631.6</td>
                        </tr>

                        </tbody>
                    </table>
                    <p className="text-bold"><a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                target="_blank">Income tax</a> payable as per the new regime:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Income ($)</th>
                            <th>Tax rate(%)</th>
                            <th>Tax ($)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>2,50,000</td>
                            <td>0</td>
                            <td>0</td>
                        </tr>
                        <tr>
                            <td>2,50,001- 5,00,000</td>
                            <td>5</td>
                            <td>12,500</td>
                        </tr>
                        <tr>
                            <td>5,00,001-7,50,000</td>
                            <td>10</td>
                            <td>25,000</td>
                        </tr>
                        <tr>
                            <td>7,50,001- 10,00,000</td>
                            <td>15</td>
                            <td>37,500</td>
                        </tr>
                        <tr>
                            <td>Total Tax</td>
                            <td></td>
                            <td>75,000</td>
                        </tr>
                        <tr>
                            <td>Health and education cess</td>
                            <td>4</td>
                            <td>3000</td>
                        </tr>
                        <tr>
                            <td>Tax Payable</td>
                            <td></td>
                            <td>78000</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">Benefits of Filing ITR even if you don’t have taxable income?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">There are then <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">benefits
                        if you file for ITR</a> under NIL:</p>
                    <ul>
                        <li>ITR receipt as an important document: For several financial, and Government jobs, the
                            receipt of ITR is considered as an important document.
                        </li>
                        <li>Address Proof: ITR receipt can be used as valid address proof for yourself.</li>
                        <li>Useful for Bank documentation: For any loan or investment purpose, ITR receipt is a document
                            on the priority list.
                        </li>
                        <li>Be ready for next FY: If you do not file for ITR, it is not possible to get the losses in
                            the previous FY. Therefore, If you face any loss in one FY, but have filed your ITR on time,
                            you can get it compensated in the next FY at the time of filing the ITR.
                        </li>
                        <li>Avoid Any Extra Charges: If you fail to file ITR, it may lead to a higher rate of interest
                            to be deducted. For example, if you do not file for ITR, then a certain amount may get
                            deducted from the interest amount you receive from your investment and FD.
                        </li>
                        <li>Avoid penalties: Missing out to file ITR may cost you a huge amount of penalties if you try
                            to file ITR post the assigned last date.
                        </li>
                        <li>Credit Card Processing: Even for proceeding with the credit card application or increase the
                            limit of the existing one, you have to furnish with the ITR documents.
                        </li>
                        <li>Visa Application: Along with the application form, you need to submit the document of ITR
                            return of the immediate past 3 years.
                        </li>
                        <li>Insurance Policy with a Higher Cover: If you want to buy an insurance policy with a high
                            cover, you need to submit the last filed ITR document, for smooth transactional proceedings.
                        </li>
                        <li>Freelancer and independent professionals: These professionals do not have a form 16 with
                            them, therefore the only document they are left with is the receipt of ITR as evidence of
                            their annual income.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">Procedure to file ITR yourself?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">For the procedure of <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">filing
                        the ITR</a> yourself, follow this official website of the Indian Tax Authority - <a
                        href="https://www.incometaxindiaefiling.gov.in/home"
                        target="_blank">https://www.incometaxindiaefiling.gov.in/home</a></p>
                    <p className="para">However, if you want professional assistance, hire your tax expert just in one
                        click.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">Is NIL ITR mandatory to be filed?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Definitely, <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">filing
                        NIL ITR</a> is important, and to know the reasons, please refer to question no. 3</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">What are the due dates for Income Tax return filing?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The <a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                               target="_blank">due date for ITR filing</a> this year is yet to be
                        declared.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">What is the penalty for a delay in filing ITR?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The deadline to <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file an
                        income tax return (ITR)</a> had been extended to August 31, 2019, for FY 2018-19. If you file
                        your return after midnight tonight then you would have to pay a late fee of up to Rs 10,000.
                        However, if your income is below the taxable limit then you won't have to pay it even if you
                        file after the deadline. Here's why:</p>
                    <p className="text-bold">The penalty you will have to pay</p>
                    <p className="para">An income taxpayer is liable to pay late ITR filing fees of:</p>
                    <ul>
                        <li>Rs 5,000 if the tax return is filed after the deadline but on or before December 31 of the
                            relevant assessment year (in this case December 31, 2019).
                        </li>
                        <li>Rs 10,000 if tax return is filed after December 31 but before the end of the relevant
                            assessment year, i.e., before March 31 (in this case between 1 January 2020 and March 31,
                            2020).
                        </li>
                    </ul>
                    <p className="text-bold">If you are a small taxpayer whose gross total income does not exceed Rs 5
                        lakh then the maximum fees you are liable to pay is Rs 1,000.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">Do I need to file a return if there is a loss in my business income?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">When your business is suffering from a loss, you must <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file the
                        ITR</a>, as the loss you suffer makes you eligible to get a tax exemption, in order to get the
                        loss amount compensated to a certain level.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#10accordian">
                <h4 className="panel-title">Can I file ITR once the last date of filing is gone?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="10accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, you can <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file the
                        ITR</a> post the last date of filing, but later you file, higher the penalty amount you end up
                        paying.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#11accordian">
                <h4 className="panel-title">Is it possible to revise the ITR even when the acknowledgment is generated?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="11accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">You can <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file a
                        revised return</a> correcting the error to avoid any penalty. Income tax laws allow taxpayers an
                        opportunity to correct the error or omission in their returns by submitting a revised tax return
                        under Section 139(5) of the IT Act.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#12accordian">
                <h4 className="panel-title">Can I file ITR for FY 18-19 and FY 17-18?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="12accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, you can <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file the
                        ITR</a> for FY 18-19 but with a huge penalty.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#13accordian">
                <h4 className="panel-title">What is form 16?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="13accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Form 16 is an <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">Income
                        tax</a> form. Form 16 is a certificate provided by your employer which certifies details
                        regarding the salary you have earned during the year and how much TDS has been deducted. It
                        consists of two parts i.e. Part A and Part B, where part A consists of information of the
                        employer & employee, like the PAN and TAN details, name and address, TDS deducted & deposited,
                        etc.</p>
                    <p className="para">It is used by the companies to provide their salaried individuals information on
                        the tax deducted. Alternatively, you can consider Form 16 as your Salary TDS certificate.</p>
                    <p className="para">Suppose the income from your salary for the financial year is more than the
                        basic exemption limit of 2,50,000. Then, in this case, your employer is required to deduct
                        TDS on your salary and deposit it with the government. When From 16 is provided to an employee
                        by their employer, it is considered as a source of proof of filing their Income Tax Returns. And
                        if your income does not fit the basic exemption limit, the employer does not deduct any TDS in
                        that case.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#14accordian">
                <h4 className="panel-title">What is form 26AS? How to get form 26AS?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="14accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Form 26AS is an annual consolidation credit statement issued under section 203AA
                        of the <a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                  target="_blank">Income Tax</a> 1961 which mentions all the tax deductions by the
                        employer, banks or someone else as defined under the Act.</p>
                    <p className="para">To download Form 26AS you can log into the income tax filing account on the
                        Income Tax Departments e filing <a href="https://www.incometaxindiaefiling.gov.in/home"
                                                           target="_blank">website</a> either directly or through the
                        Net Banking facility of authorised banks.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#15accordian">
                <h4 className="panel-title">When is a refund issued by the Income Tax Department?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="15accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The refund is usually issued within 120 days of <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">filing
                        the ITR</a>.</p>
                    <p className="para">To download Form 26AS you can log into the income tax filing account on the
                        Income Tax Departments e filing <a href="https://www.incometaxindiaefiling.gov.in/home"
                                                           target="_blank">website</a> either directly or through the
                        Net Banking facility of authorised banks.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#16accordian">
                <h4 className="panel-title">Income Tax Filing for Freelancers or professionals - Forms, Sections and
                    Exemptions? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="16accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold"><a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                target="_blank">Filing ITR</a> for freelancer:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Forms</th>
                            <th>Sections</th>
                            <th className="col-xs-8">Exemptions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Form ITR 4</td>
                            <td>Sec. 44AB</td>
                            <td>
                                <ul>
                                    <li>Sec. 80C - Deduction u to INR 1.5 lakhs for payments made towards the life
                                        insurance policies, PF, superannuation, tuition fees, construction/purchase of
                                        residential property FD etc.
                                    </li>
                                    <li>Sec. 80 CCC - Tax Deductions for investment made towards the pension plans upto
                                        INR 1.5 lakhs.
                                    </li>
                                    <li>Sec. 80 CCD - Exemptions towards investments made in the Central Government
                                        Pension Scheme. Both the employer and employee contribution are exempted.
                                    </li>
                                    <li>Sec 80CCF- The exemptions are provided for investments made in the
                                        infrastructure bonds (long term) that are notified by the Government of India.
                                        The section offers a maximum exemption of Rs 20,000
                                    </li>
                                    <li>Sec 80CCG- The section provides a maximum deduction of Rs 25,000 for the
                                        investments made in the government Equity Saving Schemes, to certain specified
                                        Indian citizens and residents.
                                    </li>
                                    <li>SEC. 80D- Under this section, expenses made towards the payment of premiums of
                                        the health insurance policies are exempted. The freelancer can also buy the
                                        policy for spouse or child, and claim the deductions
                                    </li>
                                    <li>Sec. 80DD- The section provides deductions towards treatment of normal and
                                        severe disabilities, which may go up Rs 1.25 lakhs
                                    </li>
                                    <li>Sec. 80DDB- Exemptions towards treatment of certain specified diseases</li>
                                    <li>Sec. 80E- Deductions towards loan taken for education purposes</li>
                                    <li>Sec. 80 EE- The Section is exclusively for individuals, and exempts the payments
                                        made towards a loan, for buying a property for residential purposes
                                    </li>
                                    <li>Sec. 80G- The Section offers up to a 100% deduction for the donations made to
                                        the charitable funds, including the Prime Minister Relief Fund, and the National
                                        Defense Fund among others
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>],
    94: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#01accordian">
                <h4 className="panel-title">Documents required for Income Tax Filing
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="01accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>All Form 16</li>
                        <li>Bank Statements of all Bank Accounts</li>
                        <li>Investment Declaration Proof - LIC, PPF, Tax Saving FD etc.</li>
                        <li>Form 26AS</li>
                        <li>PAN & AADHAR CARD Copy</li>
                        <li>Demat Statement for Trading Income</li>
                        <li>Sale and Purchase Deed Details for Capital Gain Income</li>
                        <li>Profit & Loss Account and Balance Sheet for Business Income</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What is the Income Tax slab for FY 20-21?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <p className="para">For this FY, there are two options available to the <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">taxpayers</a> to choose.</p>
                    <p className="text-bold">New Tax slab:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Total Income ($)</th>
                            <th>Tax Rate (%)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>&lt;2.5 lakh</td>
                            <td>0</td>
                        </tr>
                        <tr>
                            <td>2.50,000-5,00,000</td>
                            <td>5</td>
                        </tr>
                        <tr>
                            <td>5,00,001- 7,50,000</td>
                            <td>10</td>
                        </tr>
                        <tr>
                            <td>7,50,001-10,00,000</td>
                            <td>15</td>
                        </tr>
                        <tr>
                            <td>10,00,001-12,50,000</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td>12,50,001- 15,00,000</td>
                            <td>25</td>
                        </tr>
                        <tr>
                            <td>&gt;15,00,000</td>
                            <td>30</td>
                        </tr>
                        </tbody>
                    </table>
                    <p className="para">If one is following this new tax slab, one shall not be entitled to enjoy any of
                        the deduction benefits under the Income Tax Act 1961. However, who are of opinion that the
                        deductions were more beneficial, they can still follow the tax slab of the FY 2019-20 to pay
                        their tax.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">Old Tax regime or the new one, which should be followed? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <p className="para">Which one you want to follow, is totally dependent on your income and
                        investments you have made.</p>
                    <p className="text-bold">Let us explain to you with an example:-</p>
                    <p className="text-bold"><a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                target="_blank">Income tax</a> payable as per old regime:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Particulars</th>
                            <th>Amount ($)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Gross Salary</td>
                            <td>10,00,000</td>
                        </tr>
                        <tr>
                            <td>Standard Deduction</td>
                            <td>37700</td>
                        </tr>
                        <tr>
                            <td>Income under the head salary</td>
                            <td>962300</td>
                        </tr>
                        <tr>
                            <td>Interest on home loan</td>
                            <td>2,00,000</td>
                        </tr>
                        <tr>
                            <td>Net Income</td>
                            <td>762300</td>
                        </tr>
                        <tr>
                            <td>Deduction u/s 80C</td>
                            <td>-1,50,000</td>
                        </tr>
                        <tr>
                            <td>Health insurance deduction u/s 80D</td>
                            <td>-75000</td>
                        </tr>

                        <tr>
                            <td>Deduction u/s 80CCD for NPS contribution</td>
                            <td>-47000</td>
                        </tr>
                        <tr>
                            <td>Taxable Income</td>
                            <td>4,90,300</td>
                        </tr>
                        <tr>
                            <td>Rate of tax levied</td>
                            <td>0% for &lt;= 2,50,000 and 5% 2,50,001 to 5,00,000</td>
                        </tr>
                        <tr>
                            <td>Amount of tax</td>
                            <td>24515</td>
                        </tr>
                        <tr>
                            <td>Health and education cess 4%</td>
                            <td>980.6</td>
                        </tr>
                        <tr>
                            <td>Tax Total</td>
                            <td>25,131.6</td>
                        </tr>
                        <tr>
                            <td>Tax rebate u/s 87A</td>
                            <td>12,500</td>
                        </tr>
                        <tr>
                            <td>Tax payable</td>
                            <td>12,631.6</td>
                        </tr>

                        </tbody>
                    </table>
                    <p className="text-bold"><a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                target="_blank">Income tax</a> payable as per the new regime:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Income ($)</th>
                            <th>Tax rate(%)</th>
                            <th>Tax ($)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>2,50,000</td>
                            <td>0</td>
                            <td>0</td>
                        </tr>
                        <tr>
                            <td>2,50,001- 5,00,000</td>
                            <td>5</td>
                            <td>12,500</td>
                        </tr>
                        <tr>
                            <td>5,00,001-7,50,000</td>
                            <td>10</td>
                            <td>25,000</td>
                        </tr>
                        <tr>
                            <td>7,50,001- 10,00,000</td>
                            <td>15</td>
                            <td>37,500</td>
                        </tr>
                        <tr>
                            <td>Total Tax</td>
                            <td></td>
                            <td>75,000</td>
                        </tr>
                        <tr>
                            <td>Health and education cess</td>
                            <td>4</td>
                            <td>3000</td>
                        </tr>
                        <tr>
                            <td>Tax Payable</td>
                            <td></td>
                            <td>78000</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">Benefits of Filing ITR even if you don’t have taxable income?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">There are then <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">benefits
                        if you file for ITR</a> under NIL:</p>
                    <ul>
                        <li>ITR receipt as an important document: For several financial, and Government jobs, the
                            receipt of ITR is considered as an important document.
                        </li>
                        <li>Address Proof: ITR receipt can be used as valid address proof for yourself.</li>
                        <li>Useful for Bank documentation: For any loan or investment purpose, ITR receipt is a document
                            on the priority list.
                        </li>
                        <li>Be ready for next FY: If you do not file for ITR, it is not possible to get the losses in
                            the previous FY. Therefore, If you face any loss in one FY, but have filed your ITR on time,
                            you can get it compensated in the next FY at the time of filing the ITR.
                        </li>
                        <li>Avoid Any Extra Charges: If you fail to file ITR, it may lead to a higher rate of interest
                            to be deducted. For example, if you do not file for ITR, then a certain amount may get
                            deducted from the interest amount you receive from your investment and FD.
                        </li>
                        <li>Avoid penalties: Missing out to file ITR may cost you a huge amount of penalties if you try
                            to file ITR post the assigned last date.
                        </li>
                        <li>Credit Card Processing: Even for proceeding with the credit card application or increase the
                            limit of the existing one, you have to furnish with the ITR documents.
                        </li>
                        <li>Visa Application: Along with the application form, you need to submit the document of ITR
                            return of the immediate past 3 years.
                        </li>
                        <li>Insurance Policy with a Higher Cover: If you want to buy an insurance policy with a high
                            cover, you need to submit the last filed ITR document, for smooth transactional proceedings.
                        </li>
                        <li>Freelancer and independent professionals: These professionals do not have a form 16 with
                            them, therefore the only document they are left with is the receipt of ITR as evidence of
                            their annual income.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">Procedure to file ITR yourself?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">For the procedure of <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">filing
                        the ITR</a> yourself, follow this official website of the Indian Tax Authority - <a
                        href="https://www.incometaxindiaefiling.gov.in/home"
                        target="_blank">https://www.incometaxindiaefiling.gov.in/home</a></p>
                    <p className="para">However, if you want professional assistance, hire your tax expert just in one
                        click.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">Is NIL ITR mandatory to be filed?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Definitely, <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">filing
                        NIL ITR</a> is important, and to know the reasons, please refer to question no. 3</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">What are the due dates for Income Tax return filing?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The <a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                               target="_blank">due date for ITR filing</a> this year is yet to be
                        declared.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">What is the penalty for a delay in filing ITR?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The deadline to <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file an
                        income tax return (ITR)</a> had been extended to August 31, 2019, for FY 2018-19. If you file
                        your return after midnight tonight then you would have to pay a late fee of up to Rs 10,000.
                        However, if your income is below the taxable limit then you won't have to pay it even if you
                        file after the deadline. Here's why:</p>
                    <p className="text-bold">The penalty you will have to pay</p>
                    <p className="para">An income taxpayer is liable to pay late ITR filing fees of:</p>
                    <ul>
                        <li>Rs 5,000 if the tax return is filed after the deadline but on or before December 31 of the
                            relevant assessment year (in this case December 31, 2019).
                        </li>
                        <li>Rs 10,000 if tax return is filed after December 31 but before the end of the relevant
                            assessment year, i.e., before March 31 (in this case between 1 January 2020 and March 31,
                            2020).
                        </li>
                    </ul>
                    <p className="text-bold">If you are a small taxpayer whose gross total income does not exceed Rs 5
                        lakh then the maximum fees you are liable to pay is Rs 1,000.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">Do I need to file a return if there is a loss in my business income?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">When your business is suffering from a loss, you must <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file the
                        ITR</a>, as the loss you suffer makes you eligible to get a tax exemption, in order to get the
                        loss amount compensated to a certain level.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#10accordian">
                <h4 className="panel-title">Can I file ITR once the last date of filing is gone?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="10accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, you can <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file the
                        ITR</a> post the last date of filing, but later you file, higher the penalty amount you end up
                        paying.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#11accordian">
                <h4 className="panel-title">Is it possible to revise the ITR even when the acknowledgment is generated?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="11accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">You can <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file a
                        revised return</a> correcting the error to avoid any penalty. Income tax laws allow taxpayers an
                        opportunity to correct the error or omission in their returns by submitting a revised tax return
                        under Section 139(5) of the IT Act.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#12accordian">
                <h4 className="panel-title">Can I file ITR for FY 18-19 and FY 17-18?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="12accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, you can <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file the
                        ITR</a> for FY 18-19 but with a huge penalty.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#13accordian">
                <h4 className="panel-title">What is form 16?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="13accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Form 16 is an <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">Income
                        tax</a> form. Form 16 is a certificate provided by your employer which certifies details
                        regarding the salary you have earned during the year and how much TDS has been deducted. It
                        consists of two parts i.e. Part A and Part B, where part A consists of information of the
                        employer & employee, like the PAN and TAN details, name and address, TDS deducted & deposited,
                        etc.</p>
                    <p className="para">It is used by the companies to provide their salaried individuals information on
                        the tax deducted. Alternatively, you can consider Form 16 as your Salary TDS certificate.</p>
                    <p className="para">Suppose the income from your salary for the financial year is more than the
                        basic exemption limit of 2,50,000. Then, in this case, your employer is required to deduct
                        TDS on your salary and deposit it with the government. When From 16 is provided to an employee
                        by their employer, it is considered as a source of proof of filing their Income Tax Returns. And
                        if your income does not fit the basic exemption limit, the employer does not deduct any TDS in
                        that case.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#14accordian">
                <h4 className="panel-title">What is form 26AS? How to get form 26AS?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="14accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Form 26AS is an annual consolidation credit statement issued under section 203AA
                        of the <a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                  target="_blank">Income Tax</a> 1961 which mentions all the tax deductions by the
                        employer, banks or someone else as defined under the Act.</p>
                    <p className="para">To download Form 26AS you can log into the income tax filing account on the
                        Income Tax Departments e filing <a href="https://www.incometaxindiaefiling.gov.in/home"
                                                           target="_blank">website</a> either directly or through the
                        Net Banking facility of authorised banks.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#15accordian">
                <h4 className="panel-title">When is a refund issued by the Income Tax Department?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="15accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The refund is usually issued within 120 days of <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">filing
                        the ITR</a>.</p>
                    <p className="para">To download Form 26AS you can log into the income tax filing account on the
                        Income Tax Departments e filing <a href="https://www.incometaxindiaefiling.gov.in/home"
                                                           target="_blank">website</a> either directly or through the
                        Net Banking facility of authorised banks.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#16accordian">
                <h4 className="panel-title">Income Tax Filing for Freelancers or professionals - Forms, Sections and
                    Exemptions? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="16accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <p className="text-bold"><a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                target="_blank">Filing ITR</a> for freelancer:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Forms</th>
                            <th>Sections</th>
                            <th>Exemptions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Form ITR 4</td>
                            <td>Sec. 44AB</td>
                            <td>
                                <ul>
                                    <li>Sec. 80C - Deduction u to INR 1.5 lakhs for payments made towards the life
                                        insurance policies, PF, superannuation, tuition fees, construction/purchase of
                                        residential property FD etc.
                                    </li>
                                    <li>Sec. 80 CCC - Tax Deductions for investment made towards the pension plans upto
                                        INR 1.5 lakhs.
                                    </li>
                                    <li>Sec. 80 CCD - Exemptions towards investments made in the Central Government
                                        Pension Scheme. Both the employer and employee contribution are exempted.
                                    </li>
                                    <li>Sec 80CCF- The exemptions are provided for investments made in the
                                        infrastructure bonds (long term) that are notified by the Government of India.
                                        The section offers a maximum exemption of Rs 20,000
                                    </li>
                                    <li>Sec 80CCG- The section provides a maximum deduction of Rs 25,000 for the
                                        investments made in the government Equity Saving Schemes, to certain specified
                                        Indian citizens and residents.
                                    </li>
                                    <li>SEC. 80D- Under this section, expenses made towards the payment of premiums of
                                        the health insurance policies are exempted. The freelancer can also buy the
                                        policy for spouse or child, and claim the deductions
                                    </li>
                                    <li>Sec. 80DD- The section provides deductions towards treatment of normal and
                                        severe disabilities, which may go up Rs 1.25 lakhs
                                    </li>
                                    <li>Sec. 80DDB- Exemptions towards treatment of certain specified diseases</li>
                                    <li>Sec. 80E- Deductions towards loan taken for education purposes</li>
                                    <li>Sec. 80 EE- The Section is exclusively for individuals, and exempts the payments
                                        made towards a loan, for buying a property for residential purposes
                                    </li>
                                    <li>Sec. 80G- The Section offers up to a 100% deduction for the donations made to
                                        the charitable funds, including the Prime Minister Relief Fund, and the National
                                        Defense Fund among others
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>],
    95: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#01accordian">
                <h4 className="panel-title">Documents required for Income Tax Filing
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="01accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>All Form 16</li>
                        <li>Bank Statements of all Bank Accounts</li>
                        <li>Investment Declaration Proof - LIC, PPF, Tax Saving FD etc.</li>
                        <li>Form 26AS</li>
                        <li>PAN & AADHAR CARD Copy</li>
                        <li>Demat Statement for Trading Income</li>
                        <li>Sale and Purchase Deed Details for Capital Gain Income</li>
                        <li>Profit & Loss Account and Balance Sheet for Business Income</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What is the Income Tax slab for FY 20-21?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">For this FY, there are two options available to the <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">taxpayers</a> to choose.</p>
                    <p className="text-bold">New Tax slab:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Total Income ($)</th>
                            <th>Tax Rate (%)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>&lt;2.5 lakh</td>
                            <td>0</td>
                        </tr>
                        <tr>
                            <td>2.50,000-5,00,000</td>
                            <td>5</td>
                        </tr>
                        <tr>
                            <td>5,00,001- 7,50,000</td>
                            <td>10</td>
                        </tr>
                        <tr>
                            <td>7,50,001-10,00,000</td>
                            <td>15</td>
                        </tr>
                        <tr>
                            <td>10,00,001-12,50,000</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td>12,50,001- 15,00,000</td>
                            <td>25</td>
                        </tr>
                        <tr>
                            <td>&gt;15,00,000</td>
                            <td>30</td>
                        </tr>
                        </tbody>
                    </table>
                    <p className="para">If one is following this new tax slab, one shall not be entitled to enjoy any of
                        the deduction benefits under the Income Tax Act 1961. However, who are of opinion that the
                        deductions were more beneficial, they can still follow the tax slab of the FY 2019-20 to pay
                        their tax.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">Old Tax regime or the new one, which should be followed? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <p className="para">Which one you want to follow, is totally dependent on your income and
                        investments you have made.</p>
                    <p className="text-bold">Let us explain to you with an example:-</p>
                    <p className="text-bold"><a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                target="_blank">Income tax</a> payable as per old regime:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Particulars</th>
                            <th>Amount ($)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Gross Salary</td>
                            <td>10,00,000</td>
                        </tr>
                        <tr>
                            <td>Standard Deduction</td>
                            <td>37700</td>
                        </tr>
                        <tr>
                            <td>Income under the head salary</td>
                            <td>962300</td>
                        </tr>
                        <tr>
                            <td>Interest on home loan</td>
                            <td>2,00,000</td>
                        </tr>
                        <tr>
                            <td>Net Income</td>
                            <td>762300</td>
                        </tr>
                        <tr>
                            <td>Deduction u/s 80C</td>
                            <td>-1,50,000</td>
                        </tr>
                        <tr>
                            <td>Health insurance deduction u/s 80D</td>
                            <td>-75000</td>
                        </tr>

                        <tr>
                            <td>Deduction u/s 80CCD for NPS contribution</td>
                            <td>-47000</td>
                        </tr>
                        <tr>
                            <td>Taxable Income</td>
                            <td>4,90,300</td>
                        </tr>
                        <tr>
                            <td>Rate of tax levied</td>
                            <td>0% for &lt;= 2,50,000 and 5% 2,50,001 to 5,00,000</td>
                        </tr>
                        <tr>
                            <td>Amount of tax</td>
                            <td>24515</td>
                        </tr>
                        <tr>
                            <td>Health and education cess 4%</td>
                            <td>980.6</td>
                        </tr>
                        <tr>
                            <td>Tax Total</td>
                            <td>25,131.6</td>
                        </tr>
                        <tr>
                            <td>Tax rebate u/s 87A</td>
                            <td>12,500</td>
                        </tr>
                        <tr>
                            <td>Tax payable</td>
                            <td>12,631.6</td>
                        </tr>

                        </tbody>
                    </table>
                    <p className="text-bold"><a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                target="_blank">Income tax</a> payable as per the new regime:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Income ($)</th>
                            <th>Tax rate(%)</th>
                            <th>Tax ($)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>2,50,000</td>
                            <td>0</td>
                            <td>0</td>
                        </tr>
                        <tr>
                            <td>2,50,001- 5,00,000</td>
                            <td>5</td>
                            <td>12,500</td>
                        </tr>
                        <tr>
                            <td>5,00,001-7,50,000</td>
                            <td>10</td>
                            <td>25,000</td>
                        </tr>
                        <tr>
                            <td>7,50,001- 10,00,000</td>
                            <td>15</td>
                            <td>37,500</td>
                        </tr>
                        <tr>
                            <td>Total Tax</td>
                            <td></td>
                            <td>75,000</td>
                        </tr>
                        <tr>
                            <td>Health and education cess</td>
                            <td>4</td>
                            <td>3000</td>
                        </tr>
                        <tr>
                            <td>Tax Payable</td>
                            <td></td>
                            <td>78000</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">Benefits of Filing ITR even if you don’t have taxable income?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">There are then <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">benefits
                        if you file for ITR</a> under NIL:</p>
                    <ul>
                        <li>ITR receipt as an important document: For several financial, and Government jobs, the
                            receipt of ITR is considered as an important document.
                        </li>
                        <li>Address Proof: ITR receipt can be used as valid address proof for yourself.</li>
                        <li>Useful for Bank documentation: For any loan or investment purpose, ITR receipt is a document
                            on the priority list.
                        </li>
                        <li>Be ready for next FY: If you do not file for ITR, it is not possible to get the losses in
                            the previous FY. Therefore, If you face any loss in one FY, but have filed your ITR on time,
                            you can get it compensated in the next FY at the time of filing the ITR.
                        </li>
                        <li>Avoid Any Extra Charges: If you fail to file ITR, it may lead to a higher rate of interest
                            to be deducted. For example, if you do not file for ITR, then a certain amount may get
                            deducted from the interest amount you receive from your investment and FD.
                        </li>
                        <li>Avoid penalties: Missing out to file ITR may cost you a huge amount of penalties if you try
                            to file ITR post the assigned last date.
                        </li>
                        <li>Credit Card Processing: Even for proceeding with the credit card application or increase the
                            limit of the existing one, you have to furnish with the ITR documents.
                        </li>
                        <li>Visa Application: Along with the application form, you need to submit the document of ITR
                            return of the immediate past 3 years.
                        </li>
                        <li>Insurance Policy with a Higher Cover: If you want to buy an insurance policy with a high
                            cover, you need to submit the last filed ITR document, for smooth transactional proceedings.
                        </li>
                        <li>Freelancer and independent professionals: These professionals do not have a form 16 with
                            them, therefore the only document they are left with is the receipt of ITR as evidence of
                            their annual income.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">Procedure to file ITR yourself?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">For the procedure of <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">filing
                        the ITR</a> yourself, follow this official website of the Indian Tax Authority - <a
                        href="https://www.incometaxindiaefiling.gov.in/home"
                        target="_blank">https://www.incometaxindiaefiling.gov.in/home</a></p>
                    <p className="para">However, if you want professional assistance, hire your tax expert just in one
                        click.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">Is NIL ITR mandatory to be filed?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Definitely, <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">filing
                        NIL ITR</a> is important, and to know the reasons, please refer to question no. 3</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">What are the due dates for Income Tax return filing?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The <a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                               target="_blank">due date for ITR filing</a> this year is yet to be
                        declared.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">What is the penalty for a delay in filing ITR?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The deadline to <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file an
                        income tax return (ITR)</a> had been extended to August 31, 2019, for FY 2018-19. If you file
                        your return after midnight tonight then you would have to pay a late fee of up to Rs 10,000.
                        However, if your income is below the taxable limit then you won't have to pay it even if you
                        file after the deadline. Here's why:</p>
                    <p className="text-bold">The penalty you will have to pay</p>
                    <p className="para">An income taxpayer is liable to pay late ITR filing fees of:</p>
                    <ul>
                        <li>Rs 5,000 if the tax return is filed after the deadline but on or before December 31 of the
                            relevant assessment year (in this case December 31, 2019).
                        </li>
                        <li>Rs 10,000 if tax return is filed after December 31 but before the end of the relevant
                            assessment year, i.e., before March 31 (in this case between 1 January 2020 and March 31,
                            2020).
                        </li>
                    </ul>
                    <p className="text-bold">If you are a small taxpayer whose gross total income does not exceed Rs 5
                        lakh then the maximum fees you are liable to pay is Rs 1,000.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">Do I need to file a return if there is a loss in my business income?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">When your business is suffering from a loss, you must <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file the
                        ITR</a>, as the loss you suffer makes you eligible to get a tax exemption, in order to get the
                        loss amount compensated to a certain level.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#10accordian">
                <h4 className="panel-title">Can I file ITR once the last date of filing is gone?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="10accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, you can <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file the
                        ITR</a> post the last date of filing, but later you file, higher the penalty amount you end up
                        paying.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#11accordian">
                <h4 className="panel-title">Is it possible to revise the ITR even when the acknowledgment is generated?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="11accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">You can <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file a
                        revised return</a> correcting the error to avoid any penalty. Income tax laws allow taxpayers an
                        opportunity to correct the error or omission in their returns by submitting a revised tax return
                        under Section 139(5) of the IT Act.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#12accordian">
                <h4 className="panel-title">Can I file ITR for FY 18-19 and FY 17-18?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="12accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Yes, you can <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file the
                        ITR</a> for FY 18-19 but with a huge penalty.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#13accordian">
                <h4 className="panel-title">What is form 16?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="13accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Form 16 is an <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">Income
                        tax</a> form. Form 16 is a certificate provided by your employer which certifies details
                        regarding the salary you have earned during the year and how much TDS has been deducted. It
                        consists of two parts i.e. Part A and Part B, where part A consists of information of the
                        employer & employee, like the PAN and TAN details, name and address, TDS deducted & deposited,
                        etc.</p>
                    <p className="para">It is used by the companies to provide their salaried individuals information on
                        the tax deducted. Alternatively, you can consider Form 16 as your Salary TDS certificate.</p>
                    <p className="para">Suppose the income from your salary for the financial year is more than the
                        basic exemption limit of 2,50,000. Then, in this case, your employer is required to deduct
                        TDS on your salary and deposit it with the government. When From 16 is provided to an employee
                        by their employer, it is considered as a source of proof of filing their Income Tax Returns. And
                        if your income does not fit the basic exemption limit, the employer does not deduct any TDS in
                        that case.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#14accordian">
                <h4 className="panel-title">What is form 26AS? How to get form 26AS?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="14accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Form 26AS is an annual consolidation credit statement issued under section 203AA
                        of the <a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                  target="_blank">Income Tax</a> 1961 which mentions all the tax deductions by the
                        employer, banks or someone else as defined under the Act.</p>
                    <p className="para">To download Form 26AS you can log into the income tax filing account on the
                        Income Tax Departments e filing <a href="https://www.incometaxindiaefiling.gov.in/home"
                                                           target="_blank">website</a> either directly or through the
                        Net Banking facility of authorised banks.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#15accordian">
                <h4 className="panel-title">When is a refund issued by the Income Tax Department?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="15accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The refund is usually issued within 120 days of <a
                        href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">filing
                        the ITR</a>.</p>
                    <p className="para">To download Form 26AS you can log into the income tax filing account on the
                        Income Tax Departments e filing <a href="https://www.incometaxindiaefiling.gov.in/home"
                                                           target="_blank">website</a> either directly or through the
                        Net Banking facility of authorised banks.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#16accordian">
                <h4 className="panel-title">Income Tax Filing for Freelancers or professionals - Forms, Sections and
                    Exemptions? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="16accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold"><a href={`/${productIdMappedWithSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                target="_blank">Filing ITR</a> for freelancer:</p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Forms</th>
                            <th>Sections</th>
                            <th>Exemptions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Form ITR 4</td>
                            <td>Sec. 44AB</td>
                            <td>
                                <ul>
                                    <li>Sec. 80C - Deduction u to INR 1.5 lakhs for payments made towards the life
                                        insurance policies, PF, superannuation, tuition fees, construction/purchase of
                                        residential property FD etc.
                                    </li>
                                    <li>Sec. 80 CCC - Tax Deductions for investment made towards the pension plans upto
                                        INR 1.5 lakhs.
                                    </li>
                                    <li>Sec. 80 CCD - Exemptions towards investments made in the Central Government
                                        Pension Scheme. Both the employer and employee contribution are exempted.
                                    </li>
                                    <li>Sec 80CCF- The exemptions are provided for investments made in the
                                        infrastructure bonds (long term) that are notified by the Government of India.
                                        The section offers a maximum exemption of Rs 20,000
                                    </li>
                                    <li>Sec 80CCG- The section provides a maximum deduction of Rs 25,000 for the
                                        investments made in the government Equity Saving Schemes, to certain specified
                                        Indian citizens and residents.
                                    </li>
                                    <li>SEC. 80D- Under this section, expenses made towards the payment of premiums of
                                        the health insurance policies are exempted. The freelancer can also buy the
                                        policy for spouse or child, and claim the deductions
                                    </li>
                                    <li>Sec. 80DD- The section provides deductions towards treatment of normal and
                                        severe disabilities, which may go up Rs 1.25 lakhs
                                    </li>
                                    <li>Sec. 80DDB- Exemptions towards treatment of certain specified diseases</li>
                                    <li>Sec. 80E- Deductions towards loan taken for education purposes</li>
                                    <li>Sec. 80 EE- The Section is exclusively for individuals, and exempts the payments
                                        made towards a loan, for buying a property for residential purposes
                                    </li>
                                    <li>Sec. 80G- The Section offers up to a 100% deduction for the donations made to
                                        the charitable funds, including the Prime Minister Relief Fund, and the National
                                        Defense Fund among others
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>],
    97: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#01accordian">
                <h4 className="panel-title">Documents required for Accounting Services
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="01accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>All Bank Statements for Financial Year</li>
                        <li>All Cash Transactions Register</li>
                        <li>Sales Summary with Invoices</li>
                        <li>Purchases Summary with Invoices</li>
                        <li>Capital Contribution Details</li>
                        <li>Loan Details</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">Why is accounting important for a business?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Accounting is a procedure of storing, sorting and recording financial
                        transactions. At the time of tax returns, every business is required to submit their financial
                        records and transactions. It is really important for one to keep their accounts sorted from the
                        beginning and keeping the books updated as it is very difficult to deal with tax returns and
                        financial matters at the same time.</p>
                    <p className="para">Moreover, keeping your books updated gives a good impression to the shareholders
                        and investors, apart from the income tax view.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What needs to be tracked in the account books?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The Companies Act 2013, states that the account books should include everyday
                        financial transactions and their bills. Account books mostly have trial balance, copies of bills
                        and original bills, ledgers, etc. Thanks to the online accounting, matters have become easier
                        and streamlined to manage.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">Why do you need an accountant?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">It is unlikely for you to have your balance sheet tally at the end of the year
                        if you are not an accountant because there would be miss-categorization of assets, liabilities
                        and expenses. All the visible features of the business should be classified to avoid confusion
                        in the future. An expert accountant will maintain a proper record of Incomes and Expenses,
                        calculate right taxes, categorise expenses properly and provide you with monthly reports to keep
                        you updated about your finances and help you make smart money and tax saving decisions.</p>
                </div>
            </div>
        </div>
    </div>],
    99: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#01accordian">
                <h4 className="panel-title">Documents required for Accounting & Compliance Services
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="01accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>All Bank Statements for Financial Year</li>
                        <li>All Cash Transactions Register</li>
                        <li>Sales Summary with Invoices</li>
                        <li>Purchases Summary with Invoices</li>
                        <li>Capital Contribution Details</li>
                        <li>Loan Details</li>
                        <li>Copy of MoA, AoA</li>
                        <li>Copy of board resolution</li>
                        <li>List of shareholders with holdings</li>
                        <li>Details of board composition</li>
                        <li>Financial Statements (P&L and Balance Sheet) with the Secretarial Audit Report</li>
                        <li>Last year's tax return</li>
                        <li>Particulars of penalties or compounding offences (if applicable)</li>
                        <li>Particulars of penalties or compounding offences (if applicable)</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">Why is accounting important for a business?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Accounting is a procedure of storing, sorting and recording financial
                        transactions. At the time of tax returns, every business is required to submit their financial
                        records and transactions. It is really important for one to keep their accounts sorted from the
                        beginning and keeping the books updated as it is very difficult to deal with tax returns and
                        financial matters at the same time.</p>
                    <p className="para">Moreover, keeping your books updated gives a good impression to the shareholders
                        and investors, apart from the income tax view.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What needs to be tracked in the account books?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The Companies Act 2013, states that the account books should include everyday
                        financial transactions and their bills. Account books mostly have trial balance, copies of bills
                        and original bills, ledgers, etc. Thanks to the online accounting, matters have become easier
                        and streamlined to manage.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">Why do you need an accountant?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">It is unlikely for you to have your balance sheet tally at the end of the year
                        if you are not an accountant because there would be miss-categorization of assets, liabilities
                        and expenses. All the visible features of the business should be classified to avoid confusion
                        in the future. An expert accountant will maintain a proper record of Incomes and Expenses,
                        calculate right taxes, categorise expenses properly and provide you with monthly reports to keep
                        you updated about your finances and help you make smart money and tax saving decisions.</p>
                </div>
            </div>
        </div>
    </div>],
    100: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#01accordian">
                <h4 className="panel-title">Documents required for Accounting & Compliance Services
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="01accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>All Bank Statements for Financial Year</li>
                        <li>All Cash Transactions Register</li>
                        <li>Sales Summary with Invoices</li>
                        <li>Purchases Summary with Invoices</li>
                        <li>Capital Contribution Details</li>
                        <li>Loan Details</li>
                        <li>Copy of MoA, AoA</li>
                        <li>Copy of board resolution</li>
                        <li>List of shareholders with holdings</li>
                        <li>Details of board composition</li>
                        <li>Financial Statements (P&L and Balance Sheet) with the Secretarial Audit Report</li>
                        <li>Last year's tax return</li>
                        <li>Particulars of penalties or compounding offences (if applicable)</li>
                        <li>Particulars of penalties or compounding offences (if applicable)</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">Why is accounting important for a business?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Accounting is a procedure of storing, sorting and recording financial
                        transactions. At the time of tax returns, every business is required to submit their financial
                        records and transactions. It is really important for one to keep their accounts sorted from the
                        beginning and keeping the books updated as it is very difficult to deal with tax returns and
                        financial matters at the same time.</p>
                    <p className="para">Moreover, keeping your books updated gives a good impression to the shareholders
                        and investors, apart from the income tax view.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What needs to be tracked in the account books?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The Companies Act 2013, states that the account books should include everyday
                        financial transactions and their bills. Account books mostly have trial balance, copies of bills
                        and original bills, ledgers, etc. Thanks to the online accounting, matters have become easier
                        and streamlined to manage.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">Why do you need an accountant?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">It is unlikely for you to have your balance sheet tally at the end of the year
                        if you are not an accountant because there would be miss-categorization of assets, liabilities
                        and expenses. All the visible features of the business should be classified to avoid confusion
                        in the future. An expert accountant will maintain a proper record of Incomes and Expenses,
                        calculate right taxes, categorise expenses properly and provide you with monthly reports to keep
                        you updated about your finances and help you make smart money and tax saving decisions.</p>
                </div>
            </div>
        </div>
    </div>],
    101: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#01accordian">
                <h4 className="panel-title">Documents required for Accounting & Compliance Services
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="01accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <ul>
                        <li>All Bank Statements for Financial Year</li>
                        <li>All Cash Transactions Register</li>
                        <li>Sales Summary with Invoices</li>
                        <li>Purchases Summary with Invoices</li>
                        <li>Capital Contribution Details</li>
                        <li>Loan Details</li>
                        <li>Copy of MoA, AoA</li>
                        <li>Copy of board resolution</li>
                        <li>List of shareholders with holdings</li>
                        <li>Details of board composition</li>
                        <li>Financial Statements (P&L and Balance Sheet) with the Secretarial Audit Report</li>
                        <li>Last year's tax return</li>
                        <li>Particulars of penalties or compounding offences (if applicable)</li>
                        <li>Particulars of penalties or compounding offences (if applicable)</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">Why is accounting important for a business?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Accounting is a procedure of storing, sorting and recording financial
                        transactions. At the time of tax returns, every business is required to submit their financial
                        records and transactions. It is really important for one to keep their accounts sorted from the
                        beginning and keeping the books updated as it is very difficult to deal with tax returns and
                        financial matters at the same time.</p>
                    <p className="para">Moreover, keeping your books updated gives a good impression to the shareholders
                        and investors, apart from the income tax view.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What needs to be tracked in the account books?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The Companies Act 2013, states that the account books should include everyday
                        financial transactions and their bills. Account books mostly have trial balance, copies of bills
                        and original bills, ledgers, etc. Thanks to the online accounting, matters have become easier
                        and streamlined to manage.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">Why do you need an accountant?
                    <i className="fa fa-angle-down pull-right"></i>
                </h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">It is unlikely for you to have your balance sheet tally at the end of the year
                        if you are not an accountant because there would be miss-categorization of assets, liabilities
                        and expenses. All the visible features of the business should be classified to avoid confusion
                        in the future. An expert accountant will maintain a proper record of Incomes and Expenses,
                        calculate right taxes, categorise expenses properly and provide you with monthly reports to keep
                        you updated about your finances and help you make smart money and tax saving decisions.</p>
                </div>
            </div>
        </div>
    </div>],
    102: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What are the documents required for GST return Filing? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The necessary document for <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                        return Filing</a> are as follows:</p>
                    <ul>
                        <li>GST Number and Login Details</li>
                        <li>Sales Invoices or Summary</li>
                        <li>purchase Invoice s or Summary</li>
                        <li>Debit notes & Credit Note Summary</li>
                        <li>Bank Statements if required</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What is CGST? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Under GST, <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">CGST</a> is a tax levied on Intra State supplies of both goods and services by
                        the Central Government and will be governed by the CGST Act. SGST will also be levied on the
                        same Intra State supply but will be governed by the State Government.</p>
                    <p className="para">This implies that both the Central and the State governments will agree on
                        combining their levies with an appropriate proportion for revenue sharing between them. However,
                        it is clearly mentioned in Section 8 of the GST Act that the taxes be levied on all Intra-State
                        supplies of goods and/or services but the rate of tax shall not be exceeding 14%, each.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">What is SGST? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Under GST, <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">SGST</a> is a tax levied on Intra State supplies of both goods and services by
                        the State Government and will be governed by the SGST Act. As explained above, CGST will also be
                        levied on the same Intra State supply but will be governed by the Central Government.</p>
                    <p className="para">Any tax liability obtained under SGST can be set off against SGST or IGST input
                        tax credit only.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">What is IGST? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Under GST, <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">IGST</a> is a tax levied on all Inter-State supplies of goods and/or services
                        and will be governed by the IGST Act. IGST will be applicable on any supply of goods and/or
                        services in both cases of import into India and export from India.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">What is the procedure for GST registration? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The step-by-step procedure that individuals must follow to complete <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                        Registration</a> is mentioned below:</p>
                    <ul>
                        <li><b>Step 1:</b> Visit the GST portal <a href="https://www.gst.gov.in/"
                                                                   target="_blank">https://www.gst.gov.in/</a>.
                        </li>
                        <li><b>Step 2:</b> Click on the <b>"Register Now"</b> link which can be found under
                            the <b>"Taxpayers"</b> tab
                        </li>
                        <li><b>Step 3:</b> Select <b>"New Registration"</b>.</li>
                        <li><b>Step 4:</b> Fill the below-mentioned details:
                            <ul>
                                <li>Under the <b>"I am a"</b> drop-down menu, select <b>"Taxpayer"</b>.</li>
                                <li>Select the respective state and district.</li>
                                <li>Enter the name of the business.</li>
                                <li>Enter the PAN of the business.</li>
                                <li>Enter the email ID and mobile number in the respective boxes. The entered email ID
                                    and mobile number must be active as OTPs will be sent to them.
                                </li>
                                <li>Enter the image that is shown on the screen and click on <b>"Proceed"</b>.</li>
                            </ul>
                        </li>
                        <li><b>Step 5:</b> On the next page, enter the OTP that was sent to the email ID and mobile
                            number in the respective boxes.
                        </li>
                        <li><b>Step 6:</b> Once the details have been entered, click on <b>"Proceed"</b>.</li>
                        <li><b>Step 7:</b> You will be shown the Temporary Reference Number (TRN) on the screen. Make a
                            note of the TRN.
                        </li>
                        <li><b>Step 8:</b> Visit the GST portal again and click on <b>"Register"</b> under
                            the <b>"Taxpayers"</b> menu.
                        </li>
                        <li><b>Step 9:</b> Select <b>"Temporary Reference Number (TRN)"</b>.</li>
                        <li><b>Step 10:</b> Enter the TRN and the captcha details.</li>
                        <li><b>Step 11:</b> Click on <b>"Proceed"</b>.</li>
                        <li><b>Step 12:</b> You will receive an OTP on your email ID and registered mobile number. Enter
                            the OTP on the next page and click on <b>"Proceed"</b>.
                        </li>
                        <li><b>Step 13:</b> The status of your application will be available on the next page. On the
                            right side, there will be an Edit icon, click on it.
                        </li>
                        <li><b>Step 14:</b> There will be 10 sections on the next page. All the relevant details must be
                            filled, and the necessary documents must be submitted. The list of documents that must be
                            uploaded are mentioned below:
                            <ul>
                                <li>Photographs</li>
                                <li>Business address proof</li>
                                <li>Bank details such as account number, bank name, bank branch, and IFSC code.</li>
                                <li>Authorisation form</li>
                                <li>The constitution of the taxpayer.</li>
                            </ul>
                        </li>
                        <li><b>Step 15:</b> Step 15: Visit the <b>"Verification"</b> page and check the declaration,
                            Then submit the application by using one of the below mentioned methods:
                            <ul>
                                <li>By Electronic Verification Code (EVC). The code will be sent to the registered
                                    mobile number.
                                </li>
                                <li>By e-Sign method. An OTP will be sent to the mobile number linked to the Aadhaar
                                    card.
                                </li>
                                <li>In case companies are registering, the application must be submitted by using the
                                    Digital Signature Certificate (DSC).
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p className="para">Once completed, a success message will be shown on the screen. The Application
                        Reference Number (ARN) will be sent to the registered mobile number and email ID.
                        You can check the status of the ARN on the GST portal.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">What is the GST return? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The <a href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                               target="_blank">GST return</a> is the documentary accounts of all the
                        sales and purchases made by an Input Service Provider, as well as the tax collected or paid by
                        him. The GST return is generally filed on the basis of the quarter or annual every year, i.e.
                        the taxpayer can furnish this document at an interval of 3 months or once a year.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">Who is liable to file returns? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Any person who carries on a business or any economic activities at any place in
                        India and has registered himself under the <a
                            href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                            Act</a> is liable to file the returns.</p>
                    <p className="para">The word <b>person</b> does not mean only an individual, it also includes HUF,
                        company, firm, LLP, an AOP/BOI, any incorporation or Government company, a body corporate
                        incorporated with any foreign country but carrying its business in India, co-operative society,
                        local authority, government, trust, artificial person.</p>

                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">If there is no transaction in the business, is it necessary to file GST? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Even if there is no business transaction during the period covered under the
                        return, it is necessary to <a
                            href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file
                            GST</a>, but it shall be a <b>"Nil Return"</b>.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#9accordian">
                <h4 className="panel-title">Are there any exceptions, who are not liable to file GST returns? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="9accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">Certain entities are excluded from filing the <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                        return</a>:</p>
                    <ul>
                        <li>Has to register under GST but not required to file returns: UN bodies and foreign consulates
                            are exempted from GST return filing, but on the months they have made any purchase, has to
                            file returns only for those months.
                        </li>
                        <li>Neither required to register nor GST return: The Public Sector Undertakings, any business or
                            trade-in non-GST supplies, are not required to register under GST and eventually not liable
                            to file GST returns.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#10accordian">
                <h4 className="panel-title">What are the types of GST return and their due dates?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="10accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th className="text-center"><a
                                href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                target="_blank">GSTR</a></th>
                            <th className="text-center">Definition</th>
                            <th className="text-center">Documents Required/Details</th>
                            <th className="text-center">Due Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="text-center">GSTR-1</td>
                            <td>It is a monthly return of outward supplies of monthly return showcasing the sales
                                transactions made by the taxpayer
                            </td>
                            <td>
                                <ul>
                                    <li>Invoices</li>
                                    <li>Debit notes</li>
                                    <li>Credit notes</li>
                                    <li>Revised notes</li>
                                </ul>
                            </td>
                            <td>10th day of every month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-2</td>
                            <td>It is a monthly return of inward supply of goods and services, i.e. the purchase made by
                                the taxpayer in a particular month.
                            </td>
                            <td>
                                <ul>
                                    <li>Invoices</li>
                                    <li>Debit notes</li>
                                    <li>Credit notes</li>
                                </ul>
                            </td>
                            <td>Between 11th to 15th of the succeeding month.</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-2A</td>
                            <td>It is a necessary document, auto-populated which can be used for the acceptance,
                                rejection or rectification of the invoices uploaded in GSTR-1/2.
                            </td>
                            <td>
                                It is a read-only document
                            </td>
                            <td>Between 11th to 15th of the succeeding month.</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-3B</td>
                            <td>
                                It is the self-declaration of of the taxability of the taxpayer comparing the inwards
                                and outward supplies conducted in a month
                            </td>
                            <td>
                                If there is no transaction in a particular month, the taxpayer has to submit a Nil
                                return
                            </td>
                            <td>20th of the Succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-4</td>
                            <td>
                                Taxpayers having quarterly turnover upto 1.5 Crore has to pay a fixed rate of tax
                            </td>
                            <td>
                                Has to be paid in every quarter
                            </td>
                            <td>18th of the following month of the specified quarter for which GST return filing has to
                                be done.
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-5</td>
                            <td>
                                It is for the taxpayer who has an economic activity in India, but himself stays out of
                                India. It is a monthly return filing
                            </td>
                            <td>
                                <ul>
                                    <li>Inwards Supply</li>
                                    <li>Outward Supply</li>
                                    <li>Any interest/penalty/fees</li>
                                    <li>Tax amount</li>
                                    <li>Any other amount payable</li>
                                </ul>
                            </td>
                            <td>20th of the succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-6</td>
                            <td>It is a monthly GST return filing done by the Input Service Distributor
                            </td>
                            <td>
                                <ul>
                                    <li>Summary of the total input tax credit available for distribution.</li>
                                    <li>Invoices</li>
                                    <li>Debit Notes</li>
                                    <li>Credit Notes</li>
                                </ul>
                            </td>
                            <td>13th Day of the succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-7</td>
                            <td>This GST Return filing has to be done monthly by the ones who are deducting TDS
                            </td>
                            <td>
                                <ul>
                                    <li>Tax Deducted at source</li>
                                    <li>Liability towards TDS</li>
                                    <li>TDS refund claimed</li>
                                    <li>Interest, late fees</li>
                                </ul>
                            </td>
                            <td>10th Day of the Succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-8</td>
                            <td>

                                This is the GST Return filing which has to be filed by every e-commerce operator who
                                deducts TDS

                            </td>
                            <td>
                                <ul>
                                    <li>Details of sale/supplies made through an e-commerce portal</li>
                                    <li>TDS deducted</li>
                                </ul>
                            </td>
                            <td>10th day of succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-9</td>
                            <td>This is for each and every taxpayer registered under the GST Act. They have to furnish
                                the annual GST return filing electronically
                            </td>
                            <td>
                                There are certain exceptions:
                                <ul>
                                    <li>Input Service Provider
                                    </li>
                                    <li>Taxpayer U/S 51/52</li>
                                    <li>Casual Taxpayer</li>
                                    <li>NRI Taxpayer</li>
                                </ul>
                            </td>
                            <td>30th November 2019</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR 9A</td>
                            <td>Annual return in addition to the quarterly return by a composition dealer in a financial
                                year
                            </td>
                            <td>
                                <ul>
                                    <li>Inward and outward supplies4</li>
                                    <li>Tax paid</li>
                                    <li>Input credit availed/reversed</li>
                                    <li>Tax refunds</li>
                                    <li>Penalties</li>
                                </ul>
                            </td>
                            <td>December 31st succeeding the close of a particular financial year</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 9B</td>
                            <td>It is the GST return filing furnished by the e-commerce operators monthly</td>
                            <td></td>
                            <td>31st December of the succeeding year</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 9C</td>
                            <td>If the taxpayer’s turnover is more than 2 crore has to proceed for this GST return
                                filing after being audited by a CA
                            </td>
                            <td>
                                <ul>
                                    <li>Annual return</li>
                                    <li>Copy of audited account</li>
                                    <li>Reconciliation statement</li>
                                </ul>
                            </td>
                            <td>31st December of the subsequent financial year</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 10</td>
                            <td>This GST return filing has to be filed by the person whose GST registration has been
                                cancelled
                            </td>
                            <td>It has to be filed electronically on the portal or through a facilitation center
                                prescribed by the Commissioner
                            </td>
                            <td>3 months following the date of cancellation</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 11</td>
                            <td>The foreign embassies or diplomatic mission who are not liable to pay tax in India, are
                                allotted a UIN. This GST return filing has to be conducted by them.
                            </td>
                            <td>The UIN is allotted to:
                                <ul>
                                    <li>Specialized agency of the UNO</li>
                                    <li>Consulate/embassy of a foreign country</li>
                                    <li>Multilateral financial institution and organization under the UN (Privileges and
                                        Immunities) Act 1947
                                    </li>
                                    <li>Any other person/class of persons specified by the COmmissioner</li>
                                </ul>
                            </td>
                            <td>28th of the succeeding month.</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#11accordian">
                <h4 className="panel-title">How to apply for GST cancellation? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="11accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">You can do that yourself, or can <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">hire a
                        professional</a> for a smoother and expert proceeding.</p>
                    <ul>
                        <li><b>Step 1:</b> Visit the official GST website using the following link <a
                            href="https://www.gst.gov.in/" target="_blank">https://www.gst.gov.in</a> and login to your
                            GST portal
                        </li>
                        <li><b>Step 2:</b> Under the service option on your dashboard a drop-down will appear. Choose
                            Registration and then select Application for Cancellation of Registration.
                        </li>
                    </ul>
                    <p className="text-bold">Once you select Application for Cancellation of Registration three option
                        will appear:</p>
                    <ul>
                        <li>Basic Details</li>
                        <li>Cancellation details</li>
                        <li>Verification</li>
                    </ul>
                    <ul>
                        <li><b>Step 3:</b> Fill in the Basic Details that is required and click on the Save and Continue
                            Button <br/> In the Address for a place of business and for future correspondence is same
                            then click on <b>same as the above option</b></li>
                        <li><b>Step 4:</b> Fill in the reason for cancellation. <b>6 option will appear</b> as the
                            Reason for cancellation
                            <ol>
                                <li>Change in the constitution of business leading to change in PAN</li>
                                <li>Ceased to be liable to pay tax</li>
                                <li>Discontinuance of business / Closure of business</li>
                                <li>Others</li>
                                <li>Transfer of business on account of amalgamation, de-merger, sale, leased or
                                    otherwise
                                </li>
                                <li>Death of Sole Proprietor</li>
                            </ol>
                        </li>
                    </ul>
                    <p className="text-bold">Information Required to fill the cancellation form</p>
                    <ul>
                        <li>Address, Email-id and Registered Mobile Number</li>
                        <li>Reason for cancellation and the desired date to Cancel</li>
                        <li>Particulars on which tax is payable: input available on semi-finished and finished goods,
                            Stock of input and stock of capital. On filling the tax liability, you would be manually
                            required to fill in the value.
                        </li>
                        <li>You would be required to fill in either Electronic Cash ledger or Electronic Credit ledger
                            to set off the tax liability
                        </li>
                        <li>Details of the last return filed by the Taxpayer</li>
                    </ul>
                    <p className="para">When the existing unit is merged/de-merger/transferred, etc., then the details
                        of registration of the entity, i.e., GSTIN on the transferee entity.</p>
                    <ul>
                        <li><b>Step 5:</b> On filing the required reason and details, the verification option will
                            appear. Fill in the name of the authorized signatory and the place of making the
                            declaration. Depending upon the signatory, fill in the details and choose the relevant
                            option to submit. In the case of a company or LLP, DSC is mandatory. OTP will be generated
                        </li>
                    </ul>
                    <p className="text-bold">In case the taxpayer has issued an invoice, he/she would be required to
                        fill GST REG-16. The Above step is valid when the invoice is not issued.</p>
                    <p className="text-bold">Where GST REG-16 is filed by the Taxpayer, the officer will within 30 days
                        issue the order of cancellation.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#12accordian">
                <h4 className="panel-title">What is a Composition Scheme and eligibility for applying in Composition
                    Scheme? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="12accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Getting registered under composition scheme is optional and voluntary. Any
                        business which has a turnover of less than One crore or 75 lakhs for the specified states
                        can opt for this scheme but on any given day, if turnover crosses the above-mentioned limit,
                        then he becomes ineligible and has to take registration under the regular scheme. There are
                        certain conditions that need to be fulfilled before opting for composition levy.</p>
                    <p className="text-bold">They are as follows:</p>
                    <ul>
                        <li>Any assessee who only deals in supply of goods can opt for this scheme that means this
                            provision is not applicable for service providers. However, restaurant service providers are
                            excluded.
                        </li>
                        <li>There should not be any interstate supply of goods that means businesses having only an
                            intra-state supply of goods are eligible.
                        </li>
                        <li>Any dealer who is supplying goods through electronic commerce operator will be barred from
                            being registered under the composition scheme. For example: If M/s ABC sells its products
                            through Flipkart or Amazon (Electronic Commerce Operator), then M/s. ABC cannot opt for
                            composition scheme.
                        </li>
                        <li>The composition scheme is levied for all business verticals with the same PAN. A taxable
                            person will not have the option to select a composition scheme for one, opt to pay taxes for
                            others. For example, A taxable person has the following Business verticals separately
                            registered – Sale of footwear, the sale of mobiles, Franchisee of McDonald’s. Here the
                            composition scheme will be available to all 3 business verticals.
                        </li>
                        <li>Dealers are not allowed to collect composition tax from the recipient of supplies, and
                            neither are they allowed to take Input Tax Credit.
                        </li>
                        <li>If the person is not eligible under composition scheme, tax liability shall be TAX +
                            Interest and penalty which shall be equal to the amount of tax.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>],
    103: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What are the documents required for GST return Filing? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The necessary document for <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                        return Filing</a> are as follows:</p>
                    <ul>
                        <li>GST Number and Login Details</li>
                        <li>Sales Invoices or Summary</li>
                        <li>purchase Invoice s or Summary</li>
                        <li>Debit notes & Credit Note Summary</li>
                        <li>Bank Statements if required</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What is CGST? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Under GST, <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">CGST</a> is a tax levied on Intra State supplies of both goods and services by
                        the Central Government and will be governed by the CGST Act. SGST will also be levied on the
                        same Intra State supply but will be governed by the State Government.</p>
                    <p className="para">This implies that both the Central and the State governments will agree on
                        combining their levies with an appropriate proportion for revenue sharing between them. However,
                        it is clearly mentioned in Section 8 of the GST Act that the taxes be levied on all Intra-State
                        supplies of goods and/or services but the rate of tax shall not be exceeding 14%, each.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">What is SGST? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Under GST, <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">SGST</a> is a tax levied on Intra State supplies of both goods and services by
                        the State Government and will be governed by the SGST Act. As explained above, CGST will also be
                        levied on the same Intra State supply but will be governed by the Central Government.</p>
                    <p className="para">Any tax liability obtained under SGST can be set off against SGST or IGST input
                        tax credit only.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">What is IGST? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Under GST, <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">IGST</a> is a tax levied on all Inter-State supplies of goods and/or services
                        and will be governed by the IGST Act. IGST will be applicable on any supply of goods and/or
                        services in both cases of import into India and export from India.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">What is the procedure for GST registration? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The step-by-step procedure that individuals must follow to complete <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                        Registration</a> is mentioned below:</p>
                    <ul>
                        <li><b>Step 1:</b> Visit the GST portal <a href="https://www.gst.gov.in/"
                                                                   target="_blank">https://www.gst.gov.in/</a>.
                        </li>
                        <li><b>Step 2:</b> Click on the <b>"Register Now"</b> link which can be found under
                            the <b>"Taxpayers"</b> tab
                        </li>
                        <li><b>Step 3:</b> Select <b>"New Registration"</b>.</li>
                        <li><b>Step 4:</b> Fill the below-mentioned details:
                            <ul>
                                <li>Under the <b>"I am a"</b> drop-down menu, select <b>"Taxpayer"</b>.</li>
                                <li>Select the respective state and district.</li>
                                <li>Enter the name of the business.</li>
                                <li>Enter the PAN of the business.</li>
                                <li>Enter the email ID and mobile number in the respective boxes. The entered email ID
                                    and mobile number must be active as OTPs will be sent to them.
                                </li>
                                <li>Enter the image that is shown on the screen and click on <b>"Proceed"</b>.</li>
                            </ul>
                        </li>
                        <li><b>Step 5:</b> On the next page, enter the OTP that was sent to the email ID and mobile
                            number in the respective boxes.
                        </li>
                        <li><b>Step 6:</b> Once the details have been entered, click on <b>"Proceed"</b>.</li>
                        <li><b>Step 7:</b> You will be shown the Temporary Reference Number (TRN) on the screen. Make a
                            note of the TRN.
                        </li>
                        <li><b>Step 8:</b> Visit the GST portal again and click on <b>"Register"</b> under
                            the <b>"Taxpayers"</b> menu.
                        </li>
                        <li><b>Step 9:</b> Select <b>"Temporary Reference Number (TRN)"</b>.</li>
                        <li><b>Step 10:</b> Enter the TRN and the captcha details.</li>
                        <li><b>Step 11:</b> Click on <b>"Proceed"</b>.</li>
                        <li><b>Step 12:</b> You will receive an OTP on your email ID and registered mobile number. Enter
                            the OTP on the next page and click on <b>"Proceed"</b>.
                        </li>
                        <li><b>Step 13:</b> The status of your application will be available on the next page. On the
                            right side, there will be an Edit icon, click on it.
                        </li>
                        <li><b>Step 14:</b> There will be 10 sections on the next page. All the relevant details must be
                            filled, and the necessary documents must be submitted. The list of documents that must be
                            uploaded are mentioned below:
                            <ul>
                                <li>Photographs</li>
                                <li>Business address proof</li>
                                <li>Bank details such as account number, bank name, bank branch, and IFSC code.</li>
                                <li>Authorisation form</li>
                                <li>The constitution of the taxpayer.</li>
                            </ul>
                        </li>
                        <li><b>Step 15:</b> Step 15: Visit the <b>"Verification"</b> page and check the declaration,
                            Then submit the application by using one of the below mentioned methods:
                            <ul>
                                <li>By Electronic Verification Code (EVC). The code will be sent to the registered
                                    mobile number.
                                </li>
                                <li>By e-Sign method. An OTP will be sent to the mobile number linked to the Aadhaar
                                    card.
                                </li>
                                <li>In case companies are registering, the application must be submitted by using the
                                    Digital Signature Certificate (DSC).
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p className="para">Once completed, a success message will be shown on the screen. The Application
                        Reference Number (ARN) will be sent to the registered mobile number and email ID.
                        You can check the status of the ARN on the GST portal.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">What is the GST return? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The <a href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                               target="_blank">GST return</a> is the documentary accounts of all the
                        sales and purchases made by an Input Service Provider, as well as the tax collected or paid by
                        him. The GST return is generally filed on the basis of the quarter or annual every year, i.e.
                        the taxpayer can furnish this document at an interval of 3 months or once a year.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">Who is liable to file returns? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Any person who carries on a business or any economic activities at any place in
                        India and has registered himself under the <a
                            href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                            Act</a> is liable to file the returns.</p>
                    <p className="para">The word <b>person</b> does not mean only an individual, it also includes HUF,
                        company, firm, LLP, an AOP/BOI, any incorporation or Government company, a body corporate
                        incorporated with any foreign country but carrying its business in India, co-operative society,
                        local authority, government, trust, artificial person.</p>

                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">If there is no transaction in the business, is it necessary to file GST? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Even if there is no business transaction during the period covered under the
                        return, it is necessary to <a
                            href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file
                            GST</a>, but it shall be a <b>"Nil Return"</b>.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#9accordian">
                <h4 className="panel-title">Are there any exceptions, who are not liable to file GST returns? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="9accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">Certain entities are excluded from filing the <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                        return</a>:</p>
                    <ul>
                        <li>Has to register under GST but not required to file returns: UN bodies and foreign consulates
                            are exempted from GST return filing, but on the months they have made any purchase, has to
                            file returns only for those months.
                        </li>
                        <li>Neither required to register nor GST return: The Public Sector Undertakings, any business or
                            trade-in non-GST supplies, are not required to register under GST and eventually not liable
                            to file GST returns.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#10accordian">
                <h4 className="panel-title">What are the types of GST return and their due dates?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="10accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th className="text-center"><a
                                href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                target="_blank">GSTR</a></th>
                            <th className="text-center">Definition</th>
                            <th className="text-center">Documents Required/Details</th>
                            <th className="text-center">Due Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="text-center">GSTR-1</td>
                            <td>It is a monthly return of outward supplies of monthly return showcasing the sales
                                transactions made by the taxpayer
                            </td>
                            <td>
                                <ul>
                                    <li>Invoices</li>
                                    <li>Debit notes</li>
                                    <li>Credit notes</li>
                                    <li>Revised notes</li>
                                </ul>
                            </td>
                            <td>10th day of every month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-2</td>
                            <td>It is a monthly return of inward supply of goods and services, i.e. the purchase made by
                                the taxpayer in a particular month.
                            </td>
                            <td>
                                <ul>
                                    <li>Invoices</li>
                                    <li>Debit notes</li>
                                    <li>Credit notes</li>
                                </ul>
                            </td>
                            <td>Between 11th to 15th of the succeeding month.</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-2A</td>
                            <td>It is a necessary document, auto-populated which can be used for the acceptance,
                                rejection or rectification of the invoices uploaded in GSTR-1/2.
                            </td>
                            <td>
                                It is a read-only document
                            </td>
                            <td>Between 11th to 15th of the succeeding month.</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-3B</td>
                            <td>
                                It is the self-declaration of of the taxability of the taxpayer comparing the inwards
                                and outward supplies conducted in a month
                            </td>
                            <td>
                                If there is no transaction in a particular month, the taxpayer has to submit a Nil
                                return
                            </td>
                            <td>20th of the Succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-4</td>
                            <td>
                                Taxpayers having quarterly turnover upto 1.5 Crore has to pay a fixed rate of tax
                            </td>
                            <td>
                                Has to be paid in every quarter
                            </td>
                            <td>18th of the following month of the specified quarter for which GST return filing has to
                                be done.
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-5</td>
                            <td>
                                It is for the taxpayer who has an economic activity in India, but himself stays out of
                                India. It is a monthly return filing
                            </td>
                            <td>
                                <ul>
                                    <li>Inwards Supply</li>
                                    <li>Outward Supply</li>
                                    <li>Any interest/penalty/fees</li>
                                    <li>Tax amount</li>
                                    <li>Any other amount payable</li>
                                </ul>
                            </td>
                            <td>20th of the succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-6</td>
                            <td>It is a monthly GST return filing done by the Input Service Distributor
                            </td>
                            <td>
                                <ul>
                                    <li>Summary of the total input tax credit available for distribution.</li>
                                    <li>Invoices</li>
                                    <li>Debit Notes</li>
                                    <li>Credit Notes</li>
                                </ul>
                            </td>
                            <td>13th Day of the succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-7</td>
                            <td>This GST Return filing has to be done monthly by the ones who are deducting TDS
                            </td>
                            <td>
                                <ul>
                                    <li>Tax Deducted at source</li>
                                    <li>Liability towards TDS</li>
                                    <li>TDS refund claimed</li>
                                    <li>Interest, late fees</li>
                                </ul>
                            </td>
                            <td>10th Day of the Succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-8</td>
                            <td>

                                This is the GST Return filing which has to be filed by every e-commerce operator who
                                deducts TDS

                            </td>
                            <td>
                                <ul>
                                    <li>Details of sale/supplies made through an e-commerce portal</li>
                                    <li>TDS deducted</li>
                                </ul>
                            </td>
                            <td>10th day of succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-9</td>
                            <td>This is for each and every taxpayer registered under the GST Act. They have to furnish
                                the annual GST return filing electronically
                            </td>
                            <td>
                                There are certain exceptions:
                                <ul>
                                    <li>Input Service Provider
                                    </li>
                                    <li>Taxpayer U/S 51/52</li>
                                    <li>Casual Taxpayer</li>
                                    <li>NRI Taxpayer</li>
                                </ul>
                            </td>
                            <td>30th November 2019</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR 9A</td>
                            <td>Annual return in addition to the quarterly return by a composition dealer in a financial
                                year
                            </td>
                            <td>
                                <ul>
                                    <li>Inward and outward supplies4</li>
                                    <li>Tax paid</li>
                                    <li>Input credit availed/reversed</li>
                                    <li>Tax refunds</li>
                                    <li>Penalties</li>
                                </ul>
                            </td>
                            <td>December 31st succeeding the close of a particular financial year</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 9B</td>
                            <td>It is the GST return filing furnished by the e-commerce operators monthly</td>
                            <td></td>
                            <td>31st December of the succeeding year</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 9C</td>
                            <td>If the taxpayer’s turnover is more than 2 crore has to proceed for this GST return
                                filing after being audited by a CA
                            </td>
                            <td>
                                <ul>
                                    <li>Annual return</li>
                                    <li>Copy of audited account</li>
                                    <li>Reconciliation statement</li>
                                </ul>
                            </td>
                            <td>31st December of the subsequent financial year</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 10</td>
                            <td>This GST return filing has to be filed by the person whose GST registration has been
                                cancelled
                            </td>
                            <td>It has to be filed electronically on the portal or through a facilitation center
                                prescribed by the Commissioner
                            </td>
                            <td>3 months following the date of cancellation</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 11</td>
                            <td>The foreign embassies or diplomatic mission who are not liable to pay tax in India, are
                                allotted a UIN. This GST return filing has to be conducted by them.
                            </td>
                            <td>The UIN is allotted to:
                                <ul>
                                    <li>Specialized agency of the UNO</li>
                                    <li>Consulate/embassy of a foreign country</li>
                                    <li>Multilateral financial institution and organization under the UN (Privileges and
                                        Immunities) Act 1947
                                    </li>
                                    <li>Any other person/class of persons specified by the COmmissioner</li>
                                </ul>
                            </td>
                            <td>28th of the succeeding month.</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#11accordian">
                <h4 className="panel-title">How to apply for GST cancellation? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="11accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">You can do that yourself, or can <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">hire a
                        professional</a> for a smoother and expert proceeding.</p>
                    <ul>
                        <li><b>Step 1:</b> Visit the official GST website using the following link <a
                            href="https://www.gst.gov.in/" target="_blank">https://www.gst.gov.in</a> and login to your
                            GST portal
                        </li>
                        <li><b>Step 2:</b> Under the service option on your dashboard a drop-down will appear. Choose
                            Registration and then select Application for Cancellation of Registration.
                        </li>
                    </ul>
                    <p className="text-bold">Once you select Application for Cancellation of Registration three option
                        will appear:</p>
                    <ul>
                        <li>Basic Details</li>
                        <li>Cancellation details</li>
                        <li>Verification</li>
                    </ul>
                    <ul>
                        <li><b>Step 3:</b> Fill in the Basic Details that is required and click on the Save and Continue
                            Button <br/> In the Address for a place of business and for future correspondence is same
                            then click on <b>same as the above option</b></li>
                        <li><b>Step 4:</b> Fill in the reason for cancellation. <b>6 option will appear</b> as the
                            Reason for cancellation
                            <ol>
                                <li>Change in the constitution of business leading to change in PAN</li>
                                <li>Ceased to be liable to pay tax</li>
                                <li>Discontinuance of business / Closure of business</li>
                                <li>Others</li>
                                <li>Transfer of business on account of amalgamation, de-merger, sale, leased or
                                    otherwise
                                </li>
                                <li>Death of Sole Proprietor</li>
                            </ol>
                        </li>
                    </ul>
                    <p className="text-bold">Information Required to fill the cancellation form</p>
                    <ul>
                        <li>Address, Email-id and Registered Mobile Number</li>
                        <li>Reason for cancellation and the desired date to Cancel</li>
                        <li>Particulars on which tax is payable: input available on semi-finished and finished goods,
                            Stock of input and stock of capital. On filling the tax liability, you would be manually
                            required to fill in the value.
                        </li>
                        <li>You would be required to fill in either Electronic Cash ledger or Electronic Credit ledger
                            to set off the tax liability
                        </li>
                        <li>Details of the last return filed by the Taxpayer</li>
                    </ul>
                    <p className="para">When the existing unit is merged/de-merger/transferred, etc., then the details
                        of registration of the entity, i.e., GSTIN on the transferee entity.</p>
                    <ul>
                        <li><b>Step 5:</b> On filing the required reason and details, the verification option will
                            appear. Fill in the name of the authorized signatory and the place of making the
                            declaration. Depending upon the signatory, fill in the details and choose the relevant
                            option to submit. In the case of a company or LLP, DSC is mandatory. OTP will be generated
                        </li>
                    </ul>
                    <p className="text-bold">In case the taxpayer has issued an invoice, he/she would be required to
                        fill GST REG-16. The Above step is valid when the invoice is not issued.</p>
                    <p className="text-bold">Where GST REG-16 is filed by the Taxpayer, the officer will within 30 days
                        issue the order of cancellation.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#12accordian">
                <h4 className="panel-title">What is a Composition Scheme and eligibility for applying in Composition
                    Scheme? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="12accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Getting registered under composition scheme is optional and voluntary. Any
                        business which has a turnover of less than One crore or 75 lakhs for the specified states
                        can opt for this scheme but on any given day, if turnover crosses the above-mentioned limit,
                        then he becomes ineligible and has to take registration under the regular scheme. There are
                        certain conditions that need to be fulfilled before opting for composition levy.</p>
                    <p className="text-bold">They are as follows:</p>
                    <ul>
                        <li>Any assessee who only deals in supply of goods can opt for this scheme that means this
                            provision is not applicable for service providers. However, restaurant service providers are
                            excluded.
                        </li>
                        <li>There should not be any interstate supply of goods that means businesses having only an
                            intra-state supply of goods are eligible.
                        </li>
                        <li>Any dealer who is supplying goods through electronic commerce operator will be barred from
                            being registered under the composition scheme. For example: If M/s ABC sells its products
                            through Flipkart or Amazon (Electronic Commerce Operator), then M/s. ABC cannot opt for
                            composition scheme.
                        </li>
                        <li>The composition scheme is levied for all business verticals with the same PAN. A taxable
                            person will not have the option to select a composition scheme for one, opt to pay taxes for
                            others. For example, A taxable person has the following Business verticals separately
                            registered – Sale of footwear, the sale of mobiles, Franchisee of McDonald’s. Here the
                            composition scheme will be available to all 3 business verticals.
                        </li>
                        <li>Dealers are not allowed to collect composition tax from the recipient of supplies, and
                            neither are they allowed to take Input Tax Credit.
                        </li>
                        <li>If the person is not eligible under composition scheme, tax liability shall be TAX +
                            Interest and penalty which shall be equal to the amount of tax.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>],
    113: [<div className="panel-group d-accordion">
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#1accordian">
                <h4 className="panel-title">What are the documents required for GST return Filing? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="1accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The necessary document for <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                        return Filing</a> are as follows:</p>
                    <ul>
                        <li>GST Number and Login Details</li>
                        <li>Sales Invoices or Summary</li>
                        <li>purchase Invoice s or Summary</li>
                        <li>Debit notes & Credit Note Summary</li>
                        <li>Bank Statements if required</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#2accordian">
                <h4 className="panel-title">What is CGST? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="2accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Under GST, <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">CGST</a> is a tax levied on Intra State supplies of both goods and services by
                        the Central Government and will be governed by the CGST Act. SGST will also be levied on the
                        same Intra State supply but will be governed by the State Government.</p>
                    <p className="para">This implies that both the Central and the State governments will agree on
                        combining their levies with an appropriate proportion for revenue sharing between them. However,
                        it is clearly mentioned in Section 8 of the GST Act that the taxes be levied on all Intra-State
                        supplies of goods and/or services but the rate of tax shall not be exceeding 14%, each.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#3accordian">
                <h4 className="panel-title">What is SGST? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="3accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Under GST, <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">SGST</a> is a tax levied on Intra State supplies of both goods and services by
                        the State Government and will be governed by the SGST Act. As explained above, CGST will also be
                        levied on the same Intra State supply but will be governed by the Central Government.</p>
                    <p className="para">Any tax liability obtained under SGST can be set off against SGST or IGST input
                        tax credit only.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#4accordian">
                <h4 className="panel-title">What is IGST? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="4accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Under GST, <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                        target="_blank">IGST</a> is a tax levied on all Inter-State supplies of goods and/or services
                        and will be governed by the IGST Act. IGST will be applicable on any supply of goods and/or
                        services in both cases of import into India and export from India.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#5accordian">
                <h4 className="panel-title">What is the procedure for GST registration? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="5accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">The step-by-step procedure that individuals must follow to complete <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                        Registration</a> is mentioned below:</p>
                    <ul>
                        <li><b>Step 1:</b> Visit the GST portal <a href="https://www.gst.gov.in/"
                                                                   target="_blank">https://www.gst.gov.in/</a>.
                        </li>
                        <li><b>Step 2:</b> Click on the <b>"Register Now"</b> link which can be found under
                            the <b>"Taxpayers"</b> tab
                        </li>
                        <li><b>Step 3:</b> Select <b>"New Registration"</b>.</li>
                        <li><b>Step 4:</b> Fill the below-mentioned details:
                            <ul>
                                <li>Under the <b>"I am a"</b> drop-down menu, select <b>"Taxpayer"</b>.</li>
                                <li>Select the respective state and district.</li>
                                <li>Enter the name of the business.</li>
                                <li>Enter the PAN of the business.</li>
                                <li>Enter the email ID and mobile number in the respective boxes. The entered email ID
                                    and mobile number must be active as OTPs will be sent to them.
                                </li>
                                <li>Enter the image that is shown on the screen and click on <b>"Proceed"</b>.</li>
                            </ul>
                        </li>
                        <li><b>Step 5:</b> On the next page, enter the OTP that was sent to the email ID and mobile
                            number in the respective boxes.
                        </li>
                        <li><b>Step 6:</b> Once the details have been entered, click on <b>"Proceed"</b>.</li>
                        <li><b>Step 7:</b> You will be shown the Temporary Reference Number (TRN) on the screen. Make a
                            note of the TRN.
                        </li>
                        <li><b>Step 8:</b> Visit the GST portal again and click on <b>"Register"</b> under
                            the <b>"Taxpayers"</b> menu.
                        </li>
                        <li><b>Step 9:</b> Select <b>"Temporary Reference Number (TRN)"</b>.</li>
                        <li><b>Step 10:</b> Enter the TRN and the captcha details.</li>
                        <li><b>Step 11:</b> Click on <b>"Proceed"</b>.</li>
                        <li><b>Step 12:</b> You will receive an OTP on your email ID and registered mobile number. Enter
                            the OTP on the next page and click on <b>"Proceed"</b>.
                        </li>
                        <li><b>Step 13:</b> The status of your application will be available on the next page. On the
                            right side, there will be an Edit icon, click on it.
                        </li>
                        <li><b>Step 14:</b> There will be 10 sections on the next page. All the relevant details must be
                            filled, and the necessary documents must be submitted. The list of documents that must be
                            uploaded are mentioned below:
                            <ul>
                                <li>Photographs</li>
                                <li>Business address proof</li>
                                <li>Bank details such as account number, bank name, bank branch, and IFSC code.</li>
                                <li>Authorisation form</li>
                                <li>The constitution of the taxpayer.</li>
                            </ul>
                        </li>
                        <li><b>Step 15:</b> Step 15: Visit the <b>"Verification"</b> page and check the declaration,
                            Then submit the application by using one of the below mentioned methods:
                            <ul>
                                <li>By Electronic Verification Code (EVC). The code will be sent to the registered
                                    mobile number.
                                </li>
                                <li>By e-Sign method. An OTP will be sent to the mobile number linked to the Aadhaar
                                    card.
                                </li>
                                <li>In case companies are registering, the application must be submitted by using the
                                    Digital Signature Certificate (DSC).
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p className="para">Once completed, a success message will be shown on the screen. The Application
                        Reference Number (ARN) will be sent to the registered mobile number and email ID.
                        You can check the status of the ARN on the GST portal.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#6accordian">
                <h4 className="panel-title">What is the GST return? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="6accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">The <a href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                               target="_blank">GST return</a> is the documentary accounts of all the
                        sales and purchases made by an Input Service Provider, as well as the tax collected or paid by
                        him. The GST return is generally filed on the basis of the quarter or annual every year, i.e.
                        the taxpayer can furnish this document at an interval of 3 months or once a year.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#7accordian">
                <h4 className="panel-title">Who is liable to file returns? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="7accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Any person who carries on a business or any economic activities at any place in
                        India and has registered himself under the <a
                            href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                            Act</a> is liable to file the returns.</p>
                    <p className="para">The word <b>person</b> does not mean only an individual, it also includes HUF,
                        company, firm, LLP, an AOP/BOI, any incorporation or Government company, a body corporate
                        incorporated with any foreign country but carrying its business in India, co-operative society,
                        local authority, government, trust, artificial person.</p>

                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#8accordian">
                <h4 className="panel-title">If there is no transaction in the business, is it necessary to file GST? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="8accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Even if there is no business transaction during the period covered under the
                        return, it is necessary to <a
                            href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">file
                            GST</a>, but it shall be a <b>"Nil Return"</b>.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#9accordian">
                <h4 className="panel-title">Are there any exceptions, who are not liable to file GST returns? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="9accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">Certain entities are excluded from filing the <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">GST
                        return</a>:</p>
                    <ul>
                        <li>Has to register under GST but not required to file returns: UN bodies and foreign consulates
                            are exempted from GST return filing, but on the months they have made any purchase, has to
                            file returns only for those months.
                        </li>
                        <li>Neither required to register nor GST return: The Public Sector Undertakings, any business or
                            trade-in non-GST supplies, are not required to register under GST and eventually not liable
                            to file GST returns.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#10accordian">
                <h4 className="panel-title">What are the types of GST return and their due dates?
                    <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="10accordian" className="panel-collapse collapse">
                <div className="panel-body table-responsive">
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th className="text-center"><a
                                href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                target="_blank">GSTR</a></th>
                            <th className="text-center">Definition</th>
                            <th className="text-center">Documents Required/Details</th>
                            <th className="text-center">Due Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="text-center">GSTR-1</td>
                            <td>It is a monthly return of outward supplies of monthly return showcasing the sales
                                transactions made by the taxpayer
                            </td>
                            <td>
                                <ul>
                                    <li>Invoices</li>
                                    <li>Debit notes</li>
                                    <li>Credit notes</li>
                                    <li>Revised notes</li>
                                </ul>
                            </td>
                            <td>10th day of every month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-2</td>
                            <td>It is a monthly return of inward supply of goods and services, i.e. the purchase made by
                                the taxpayer in a particular month.
                            </td>
                            <td>
                                <ul>
                                    <li>Invoices</li>
                                    <li>Debit notes</li>
                                    <li>Credit notes</li>
                                </ul>
                            </td>
                            <td>Between 11th to 15th of the succeeding month.</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-2A</td>
                            <td>It is a necessary document, auto-populated which can be used for the acceptance,
                                rejection or rectification of the invoices uploaded in GSTR-1/2.
                            </td>
                            <td>
                                It is a read-only document
                            </td>
                            <td>Between 11th to 15th of the succeeding month.</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-3B</td>
                            <td>
                                It is the self-declaration of of the taxability of the taxpayer comparing the inwards
                                and outward supplies conducted in a month
                            </td>
                            <td>
                                If there is no transaction in a particular month, the taxpayer has to submit a Nil
                                return
                            </td>
                            <td>20th of the Succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-4</td>
                            <td>
                                Taxpayers having quarterly turnover upto 1.5 Crore has to pay a fixed rate of tax
                            </td>
                            <td>
                                Has to be paid in every quarter
                            </td>
                            <td>18th of the following month of the specified quarter for which GST return filing has to
                                be done.
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-5</td>
                            <td>
                                It is for the taxpayer who has an economic activity in India, but himself stays out of
                                India. It is a monthly return filing
                            </td>
                            <td>
                                <ul>
                                    <li>Inwards Supply</li>
                                    <li>Outward Supply</li>
                                    <li>Any interest/penalty/fees</li>
                                    <li>Tax amount</li>
                                    <li>Any other amount payable</li>
                                </ul>
                            </td>
                            <td>20th of the succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-6</td>
                            <td>It is a monthly GST return filing done by the Input Service Distributor
                            </td>
                            <td>
                                <ul>
                                    <li>Summary of the total input tax credit available for distribution.</li>
                                    <li>Invoices</li>
                                    <li>Debit Notes</li>
                                    <li>Credit Notes</li>
                                </ul>
                            </td>
                            <td>13th Day of the succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-7</td>
                            <td>This GST Return filing has to be done monthly by the ones who are deducting TDS
                            </td>
                            <td>
                                <ul>
                                    <li>Tax Deducted at source</li>
                                    <li>Liability towards TDS</li>
                                    <li>TDS refund claimed</li>
                                    <li>Interest, late fees</li>
                                </ul>
                            </td>
                            <td>10th Day of the Succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-8</td>
                            <td>

                                This is the GST Return filing which has to be filed by every e-commerce operator who
                                deducts TDS

                            </td>
                            <td>
                                <ul>
                                    <li>Details of sale/supplies made through an e-commerce portal</li>
                                    <li>TDS deducted</li>
                                </ul>
                            </td>
                            <td>10th day of succeeding month</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR-9</td>
                            <td>This is for each and every taxpayer registered under the GST Act. They have to furnish
                                the annual GST return filing electronically
                            </td>
                            <td>
                                There are certain exceptions:
                                <ul>
                                    <li>Input Service Provider
                                    </li>
                                    <li>Taxpayer U/S 51/52</li>
                                    <li>Casual Taxpayer</li>
                                    <li>NRI Taxpayer</li>
                                </ul>
                            </td>
                            <td>30th November 2019</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR 9A</td>
                            <td>Annual return in addition to the quarterly return by a composition dealer in a financial
                                year
                            </td>
                            <td>
                                <ul>
                                    <li>Inward and outward supplies4</li>
                                    <li>Tax paid</li>
                                    <li>Input credit availed/reversed</li>
                                    <li>Tax refunds</li>
                                    <li>Penalties</li>
                                </ul>
                            </td>
                            <td>December 31st succeeding the close of a particular financial year</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 9B</td>
                            <td>It is the GST return filing furnished by the e-commerce operators monthly</td>
                            <td></td>
                            <td>31st December of the succeeding year</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 9C</td>
                            <td>If the taxpayer’s turnover is more than 2 crore has to proceed for this GST return
                                filing after being audited by a CA
                            </td>
                            <td>
                                <ul>
                                    <li>Annual return</li>
                                    <li>Copy of audited account</li>
                                    <li>Reconciliation statement</li>
                                </ul>
                            </td>
                            <td>31st December of the subsequent financial year</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 10</td>
                            <td>This GST return filing has to be filed by the person whose GST registration has been
                                cancelled
                            </td>
                            <td>It has to be filed electronically on the portal or through a facilitation center
                                prescribed by the Commissioner
                            </td>
                            <td>3 months following the date of cancellation</td>
                        </tr>
                        <tr>
                            <td className="text-center">GSTR- 11</td>
                            <td>The foreign embassies or diplomatic mission who are not liable to pay tax in India, are
                                allotted a UIN. This GST return filing has to be conducted by them.
                            </td>
                            <td>The UIN is allotted to:
                                <ul>
                                    <li>Specialized agency of the UNO</li>
                                    <li>Consulate/embassy of a foreign country</li>
                                    <li>Multilateral financial institution and organization under the UN (Privileges and
                                        Immunities) Act 1947
                                    </li>
                                    <li>Any other person/class of persons specified by the COmmissioner</li>
                                </ul>
                            </td>
                            <td>28th of the succeeding month.</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#11accordian">
                <h4 className="panel-title">How to apply for GST cancellation? <i
                    className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="11accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="text-bold">You can do that yourself, or can <a
                        href={`/${productIdMappedWithSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`} target="_blank">hire a
                        professional</a> for a smoother and expert proceeding.</p>
                    <ul>
                        <li><b>Step 1:</b> Visit the official GST website using the following link <a
                            href="https://www.gst.gov.in/" target="_blank">https://www.gst.gov.in</a> and login to your
                            GST portal
                        </li>
                        <li><b>Step 2:</b> Under the service option on your dashboard a drop-down will appear. Choose
                            Registration and then select Application for Cancellation of Registration.
                        </li>
                    </ul>
                    <p className="text-bold">Once you select Application for Cancellation of Registration three option
                        will appear:</p>
                    <ul>
                        <li>Basic Details</li>
                        <li>Cancellation details</li>
                        <li>Verification</li>
                    </ul>
                    <ul>
                        <li><b>Step 3:</b> Fill in the Basic Details that is required and click on the Save and Continue
                            Button <br/> In the Address for a place of business and for future correspondence is same
                            then click on <b>same as the above option</b></li>
                        <li><b>Step 4:</b> Fill in the reason for cancellation. <b>6 option will appear</b> as the
                            Reason for cancellation
                            <ol>
                                <li>Change in the constitution of business leading to change in PAN</li>
                                <li>Ceased to be liable to pay tax</li>
                                <li>Discontinuance of business / Closure of business</li>
                                <li>Others</li>
                                <li>Transfer of business on account of amalgamation, de-merger, sale, leased or
                                    otherwise
                                </li>
                                <li>Death of Sole Proprietor</li>
                            </ol>
                        </li>
                    </ul>
                    <p className="text-bold">Information Required to fill the cancellation form</p>
                    <ul>
                        <li>Address, Email-id and Registered Mobile Number</li>
                        <li>Reason for cancellation and the desired date to Cancel</li>
                        <li>Particulars on which tax is payable: input available on semi-finished and finished goods,
                            Stock of input and stock of capital. On filling the tax liability, you would be manually
                            required to fill in the value.
                        </li>
                        <li>You would be required to fill in either Electronic Cash ledger or Electronic Credit ledger
                            to set off the tax liability
                        </li>
                        <li>Details of the last return filed by the Taxpayer</li>
                    </ul>
                    <p className="para">When the existing unit is merged/de-merger/transferred, etc., then the details
                        of registration of the entity, i.e., GSTIN on the transferee entity.</p>
                    <ul>
                        <li><b>Step 5:</b> On filing the required reason and details, the verification option will
                            appear. Fill in the name of the authorized signatory and the place of making the
                            declaration. Depending upon the signatory, fill in the details and choose the relevant
                            option to submit. In the case of a company or LLP, DSC is mandatory. OTP will be generated
                        </li>
                    </ul>
                    <p className="text-bold">In case the taxpayer has issued an invoice, he/she would be required to
                        fill GST REG-16. The Above step is valid when the invoice is not issued.</p>
                    <p className="text-bold">Where GST REG-16 is filed by the Taxpayer, the officer will within 30 days
                        issue the order of cancellation.</p>
                </div>
            </div>
        </div>
        <div className="panel panel-default">
            <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                 href="#12accordian">
                <h4 className="panel-title">What is a Composition Scheme and eligibility for applying in Composition
                    Scheme? <i className="fa fa-angle-down pull-right"></i></h4>
            </div>
            <div id="12accordian" className="panel-collapse collapse">
                <div className="panel-body">
                    <p className="para">Getting registered under composition scheme is optional and voluntary. Any
                        business which has a turnover of less than One crore or 75 lakhs for the specified states
                        can opt for this scheme but on any given day, if turnover crosses the above-mentioned limit,
                        then he becomes ineligible and has to take registration under the regular scheme. There are
                        certain conditions that need to be fulfilled before opting for composition levy.</p>
                    <p className="text-bold">They are as follows:</p>
                    <ul>
                        <li>Any assessee who only deals in supply of goods can opt for this scheme that means this
                            provision is not applicable for service providers. However, restaurant service providers are
                            excluded.
                        </li>
                        <li>There should not be any interstate supply of goods that means businesses having only an
                            intra-state supply of goods are eligible.
                        </li>
                        <li>Any dealer who is supplying goods through electronic commerce operator will be barred from
                            being registered under the composition scheme. For example: If M/s ABC sells its products
                            through Flipkart or Amazon (Electronic Commerce Operator), then M/s. ABC cannot opt for
                            composition scheme.
                        </li>
                        <li>The composition scheme is levied for all business verticals with the same PAN. A taxable
                            person will not have the option to select a composition scheme for one, opt to pay taxes for
                            others. For example, A taxable person has the following Business verticals separately
                            registered – Sale of footwear, the sale of mobiles, Franchisee of McDonald’s. Here the
                            composition scheme will be available to all 3 business verticals.
                        </li>
                        <li>Dealers are not allowed to collect composition tax from the recipient of supplies, and
                            neither are they allowed to take Input Tax Credit.
                        </li>
                        <li>If the person is not eligible under composition scheme, tax liability shall be TAX +
                            Interest and penalty which shall be equal to the amount of tax.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>],
}
