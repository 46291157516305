import React from 'react'
function getTypeOfData({type, value}) {
    switch (type) {
        case Number:
            return (value !== undefined && value) ? Number(value) : 1
        case String:
            return (value !== undefined && value) ? value : ''
        case Boolean:
            return (value !== undefined && value) ? Boolean(JSON.parse(value)) : ''
        default:
            return (value !== undefined && value) ? value : ''
    }
}

function ShowJsonDataInUi({data1, data2}) {
    data1 = JSON.stringify(data1)
    data2 = JSON.stringify(data2)
    return(
        <div>
            {data1}
            {data2}
        </div>
    )
}

export {
    getTypeOfData,
    ShowJsonDataInUi
}