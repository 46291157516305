import React, {Component} from "react";
import {validateFeedbackFOrJob} from "./validation/FormValidator";
import {apiv1} from "../../api";
import $ from "jquery";
import axios from "axios";
import {status as jobStatus} from "../../constants/job";
import LoadingComponent from "../../utils/LoadingComponent";
import * as path from '../../constants/path'
import {withRouter} from "react-router-dom";
import connect from "react-redux/lib/connect/connect";
import {decreaseJobInProgressCount} from "../../actions/job_count";
import UserJobsHolder from "../../utils/UserJobsHolder";
import localStorage from "../../utils/localStorage";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class JobFeedBack extends Component {
    constructor(props) {
        super(props)
        const {is_super_user} = this.props
        this.state = {
            reason_for_termination: '',
            proposalAmount: '',
            other_reason_for_termination: '',
            comment: '',
            rating: is_super_user ? 5 : '',
            responsive_rating: is_super_user ? 5 : '',
            milestone_tracking_rating: is_super_user ? 5 : '',
            errors: {},
            amountNeedToPay: 0,
            recommend: true,
            loading: false,
        }
    }

    componentDidMount() {
        // this.getUserAndProfessionalData()
    }


    markCompleteJob = () => {
        const {jobId} = this.props
        const {doDispatch} = this.props
        let url = `/job/job/${jobId}/`
        const request = {
            marked_complete_by_user: true
        }
        return apiv1.auth.patch(url, request).then(resp =>{
            return apiv1.parseAndAutoHandleIssues(resp, doDispatch)
        })
    }

    submitFeedback = (request) => {
        const url = `/job/job-feedback/`
        const {doDispatch} = this.props
        return apiv1.auth.post(url, request)
            .then(resp => {
                return apiv1.parseAndAutoHandleIssues(resp, doDispatch);
            })
    }

    submitFeedbackAndMarkComplete = (JobId) => {
        const { amountNeedToPay, comment, rating, other_reason_for_termination, reason_for_termination, recommend, responsive_rating, milestone_tracking_rating } = this.state;
        const self = this
        const errors = {};
        const request = {
            feedback_for: "Professional",
            rating,
            responsive_rating,
            recommend,
            reason_for_termination,
            other_reason_for_termination,
            comment,
            job: JobId,
            amountNeedToPay: amountNeedToPay
        };
        const isFormValidated = validateFeedbackFOrJob(errors, request, "Professional");
        if (isFormValidated === true) {
            this.setState({loading: true})
            const {generateCashfreeLink, updateJobStatus, history, userAndProfessionalData, onJobComplete } = self.props
            axios.all([this.markCompleteJob(), this.submitFeedback(request)])
                .then(({...resp}) => {
                    if (resp[0] !== undefined && resp[1] !== undefined){
                        this.setState({loading: false}, () => {
                            if (generateCashfreeLink){
                                generateCashfreeLink(JobId, true)
                                return
                            } else {
                                updateJobStatus(jobStatus.Completed)
                            }
                            const {job_details} = userAndProfessionalData || {};
                            const {title} = job_details
                            if (onJobComplete){
                                onJobComplete(decreaseJobInProgressCount(1))
                            }
                            UserJobsHolder.removeJonInProgressJob({jobId: Number(JobId)})
                            history.push(`${path.jobCompleted}?title=${title}`)
                        })
                    }
                })
                .catch((err) => {
                    const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
                    for (let key in resErrors) {
                        errors[key] = resErrors[key][0]
                        if (resErrors[key] !== undefined &&  resErrors[key][0] === feedbackError){
                            if (generateCashfreeLink){
                                generateCashfreeLink(JobId, true)
                            }
                        }
                    }
                    this.setState({loading: false, errors} )
                })
        }
        else {
            this.setState({ loading: false, errors })
        }
    };
    skipFeedbackAndMarkComplete = (JobId) => {
        const { amountNeedToPay, comment, rating, other_reason_for_termination, reason_for_termination, recommend, responsive_rating, milestone_tracking_rating } = this.state;
        const self = this
        const errors = {};
        const request = {
            feedback_for: "Professional",
            rating,
            responsive_rating,
            reason_for_termination,
            recommend,
            other_reason_for_termination,
            job: JobId,
            amountNeedToPay: amountNeedToPay
        };
        const isFormValidated = validateFeedbackFOrJob(errors, request, "Professional");
        if (isFormValidated === true) {
            this.setState({loading: true})
            const {generateCashfreeLink, updateJobStatus, history, userAndProfessionalData, onJobComplete } = self.props
            axios.all([this.markCompleteJob(), this.submitFeedback(request)])
                .then(({...resp}) => {
                    if (resp[0] !== undefined && resp[1] !== undefined){
                        this.setState({loading: false}, () => {
                            if (generateCashfreeLink){
                                generateCashfreeLink(JobId, true)
                                return
                            } else {
                                updateJobStatus(jobStatus.Completed)
                            }
                            const {job_details} = userAndProfessionalData || {};
                            const {title} = job_details
                            if (onJobComplete){
                                onJobComplete(decreaseJobInProgressCount(1))
                            }
                            UserJobsHolder.removeJonInProgressJob({jobId: Number(JobId)})
                            history.push(`${path.jobCompleted}?title=${title}`)
                        })
                    }
                })
                .catch((err) => {
                    const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
                    for (let key in resErrors) {
                        errors[key] = resErrors[key][0]
                        if (resErrors[key] !== undefined &&  resErrors[key][0] === feedbackError){
                            if (generateCashfreeLink){
                                generateCashfreeLink(JobId, true)
                            }
                        }
                    }
                    this.setState({loading: false, errors} )
                })
        }
        else {
            this.setState({ loading: false, errors })
        }
    };

    selectedRating = (value) => {
        $("#" + value).addClass('active');
        this.setState({rating: value})
    };

    getUserAndProfessionalData = () => {
        const {proposalId, doDispatch} = this.props
        this.setState({loading: true});
        const url = `/job/proposal-user-professional/${proposalId}/`;
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                if (data) {
                    const {fixed_amount} = data
                    this.setState({
                        loading: false,
                        proposalAmount: fixed_amount,
                    })
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, doDispatch)
            })
    };
    onChangeRadioInput = (e) => {
        let {name, value} = e.target
        this.setState({[name]: JSON.parse(value)})
    }


    render() {
        const {rating, errors, loading, responsive_rating, recommend, milestone_tracking_rating } = this.state;
        const {jobId, userAndProfessionalData, is_super_user} = this.props
        const {first_name, last_name, id } = userAndProfessionalData.Professional_details;
        return (
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">Feedback for {first_name + ' ' + last_name}</h4>
                    </div>
                    {loading && (
                        <div className="lazy-loader" style={{position: 'fixed', zIndex: '1'}}>
                            <LoadingComponent pastDelay/></div>)}
                    <div className="modal-body">
                        <div className="feedback-box" style={{padding:'0px'}}>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="ui-rating-field" style={{padding:'10px'}}>
                                        <h4 className="text-dark"><b>{first_name + ' ' + last_name}</b> would love to have a feedback.</h4>
                                        <div>
                                            <p className="para" style={{marginBottom: '10px'}}>Rating for professional</p>
                                            <ul className="list-inline">
                                                {[1, 2, 3, 4, 5].map(val => {
                                                    return (
                                                        <li key={val} className={val === rating ? 'active' : ''}
                                                            onClick={(e) => {
                                                                const {errors} = this.state
                                                                errors['rating'] = ''
                                                                this.setState({rating: val, errors}, () => {
                                                                    $("#" + val).addClass('active');
                                                                })
                                                            }}>
                                                            {val}
                                                        </li>
                                                    )
                                                })}
                                                <p className={errors.rating ? 'error' : 'no-error'}>{errors.rating}</p>
                                            </ul>
                                            <p className="para" style={{marginBottom: '10px'}}>Responsiveness rating</p>
                                            <ul className="list-inline">
                                                {[1, 2, 3, 4, 5].map(val => {
                                                    return (
                                                        <li key={val} className={val === responsive_rating ? 'active' : ''}
                                                            onClick={(e) => {
                                                                const {errors} = this.state
                                                                errors['responsive_rating'] = ''
                                                                this.setState({responsive_rating: val, errors}, () => {
                                                                    $("#responsiveness" + val).addClass('active');
                                                                })
                                                            }}>
                                                            {val}
                                                        </li>
                                                    )
                                                })}
                                                <p className={errors.responsive_rating ? 'error' : 'no-error'}>{errors.responsive_rating}</p>
                                            </ul>
                                            {/*<p className="para" style={{marginBottom: '10px'}}>Milestone track rating</p>*/}
                                            {/*<ul className="list-inline">*/}
                                                {/*{[1, 2, 3, 4, 5].map(val => {*/}
                                                    {/*return (*/}
                                                        {/*<li key={val} className={val === milestone_tracking_rating ? 'active' : ''}*/}
                                                            {/*onClick={(e) => {*/}
                                                                {/*const {errors} = this.state*/}
                                                                {/*errors['milestone_tracking_rating'] = ''*/}
                                                                {/*this.setState({milestone_tracking_rating: val, errors}, () => {*/}
                                                                    {/*$("#milestone_track" + val).addClass('active');*/}
                                                                {/*})*/}
                                                            {/*}}>*/}
                                                            {/*{val}*/}
                                                        {/*</li>*/}
                                                    {/*)*/}
                                                {/*})}*/}
                                                {/*{errors.milestone_tracking_rating && <p className={'error'}>{errors.milestone_tracking_rating}</p>}*/}
                                            {/*</ul>*/}
                                            <p className="para" style={{marginBottom: '30px'}}>Would you recommend Workbeam to other Businesses?</p>
                                            <div className="clearfix" />
                                            <RadioGroup
                                                value={recommend}
                                                name='recommend'
                                                onChange={this.onChangeRadioInput}
                                                className="inlineRadio"
                                            >
                                                {[{label: 'Yes', value: true}, {label: 'No', value: false}].map(inputDetails => {
                                                    const {label, value} = inputDetails
                                                    return (
                                                        <FormControlLabel
                                                            key={label}
                                                            value={value}
                                                            control={<Radio/>}
                                                            label={label}
                                                        />
                                                    )
                                                })}
                                            </RadioGroup>
                                            <p className={errors.responsive_rating ? 'error' : 'no-error'}>{errors.responsive_rating}</p>
                                            <p className="para" style={{marginBottom: '0px'}}>Review for professional</p>
                                            <textarea rows="5"
                                                      name="comment"
                                                      onChange={(e) => {
                                                          const {name, value} = e.target
                                                          const {errors} = this.state
                                                          errors[name] = ''
                                                          this.setState({[name]: value})
                                            }}>

                                              </textarea>
                                            <p className={errors.comment ? 'error' : 'no-error'}>{errors.comment}</p>
                                            <div className="text-center">
                                                <button className="btn btn-save-profile"
                                                        onClick={(e) => this.submitFeedbackAndMarkComplete(jobId)}>Submit
                                                </button>
                                                {is_super_user &&
                                                <button className="btn btn-save-profile" style={{background:'#ff337d', color:'#fff', marginLeft:'10px'}}
                                                        onClick={(e) => this.skipFeedbackAndMarkComplete(jobId)}>Skip
                                                </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

const feedbackError = 'The fields job, feedback_for must make a unique set.'

const mapDispatchToProps = (dispatch) => ({
    onJobComplete: (action) => {
        dispatch(action)
    },
});
const mapStateToProps = (state) => {
    return {
        is_super_user: JSON.parse(localStorage.isSuperUser)
    }
};
export default withRouter(connect(null,mapStateToProps)(JobFeedBack))