import {Component, Fragment} from "react";
import React from "react";
// import {Link} from "react-router-dom";
// import {apiv1} from "../api"
import '../static/css/wizcounsel.home.css'
import * as path from '../constants/path'
// import * as keyWords from '../constants/keyWord'
import GigzoeLogo from "../static/images/gigzoe-logo.png"
import {errorActionDispatchers} from "../actions/errors";
import connect from "react-redux/es/connect/connect";
import localStorage, {KEY_ONBOARDING_DATA, KEY_USER_TYPE} from './../utils/localStorage'
import sessionStorage from '../utils/sessionStorage'
import {withRouter} from "react-router-dom";
// import {profession} from './../constants/job'
// import {parentPracticeAreaSlug, productSlug} from "./common/bannerTextForProducts";
import {becomeAGigzoeAffiliate, becomeExpert, requestProposals} from "../constants/keyWord"
import {baseUrl, mainDomain, showDefault} from "../constants/common"
import {siteLogoOnSharedMedia} from "./common/bannerTextForProducts"
import Header from "./functional/beforeHire"
import {defaultPageMetaDescription, defaultPageTitle} from "../constants/common";

export const fiveStar = () => {
    return (
        <label>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
        </label>
    )
}

export function SiteFooter({ history}) {
    const affiliateMarketingDetails = JSON.parse(sessionStorage.affiliateMarketingDetails) || {}
    let isHideDefaultFooter = false
    if (affiliateMarketingDetails.footer_ui){
        isHideDefaultFooter = affiliateMarketingDetails.footer_ui === showDefault.hf
    }
    // let baseLink = siteBaseUrl.substring(0, siteBaseUrl.length - 1)
    return (
        <Fragment>
            {isHideDefaultFooter ?
                <CustomSiteFooter  history={history}/>
                :
                <footer className="wiz-footer" style={{paddingTop: '30px'}}>
                    <div className="container">
                        <div className="row" style={{borderTop: '1.5px solid #f2f2f2'}}>
                            <div className="col-sm-4 col-md-3 col-xs-12 footer-column">
                                <a href={mainDomain}>
                                    <img src={GigzoeLogo} alt="logo"/>
                                </a>
                                <ul className="list-unstyled list-inline social">
                                    <li className="list-inline-item">
                                        <a href="https://www.facebook.com/Workbeam/" target="_blank"> <i
                                            className="fa fa-facebook"></i>
                                        </a>
                                    </li>
                                    <li className="list-inline-item"><a href="https://twitter.com/Workbeam"
                                                                        target="_blank"><i
                                        className="fa fa-twitter"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://www.instagram.com/" target="_blank"> <i
                                            className="fa fa-instagram"></i>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://www.linkedin.com/company/Workbeam/" target="_blank"> <i
                                            className="fa fa-linkedin"></i>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="#" target="_blank"> <i
                                            className="fa fa-youtube-play"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-sm-4 col-md-2 col-xs-6  footer-column">
                                <h4>Company</h4>
                                <ul className="list-unstyled quick-links">
                                    <li><a href={path.getInTouch}>About</a>
                                    </li>
                                    {/* <li><a href={`${baseUrl}/blog/`} target="_blank">Blog</a>
                                    </li> */}
                                    {/* <li><a href={`${mainDomain}${path.helpCenter}`} target="_blank">Help</a></li> */}
                                    {/*<li><a href={path.wizRewards}>Rewards</a></li>*/}
                                    {/* <li>
                                        <a href={path.testimonials}>Customer Stories</a>
                                    </li> */}
                                </ul>
                            </div>
                            {/* <div className="col-sm-4 col-md-3 col-xs-6  footer-column">
                                <h4>Mobile</h4>
                                <ul className="list-unstyled quick-links">
                                    <li><a href="https://play.google.com/store/apps/details?id=com.blizzcraft.wizuser"
                                           target="_blank">Android for user</a>
                                    </li>
                                    <li><a href="https://play.google.com/store/apps/details?id=com.blizzcraft.wizpro&hl=en"
                                           target="_blank">Android for professionals</a>
                                    </li>
                                    <li><a href="https://itunes.apple.com/us/app/wizcounsel/id1458978743?ls=1&mt=8"
                                           target="_blank">iOS for user</a>
                                    </li>
                                    <li><a href="https://itunes.apple.com/us/app/wizcounsel-professionals/id1447086305?ls=1&mt=8"
                                           target="_blank">iOS for professionals</a>
                                    </li>
                                </ul>
                            </div> */}
                            <div className="clearfix visible-xs visible-sm"></div>
                            <div className="col-sm-8 col-md-2 col-xs-6  footer-column">
                                <h4>Sign up</h4>
                                <ul className="list-unstyled quick-links">
                                    <li><a href={`${mainDomain}/${path.professionalLanding}`}>{becomeExpert}</a>
                                    </li>
                                    <li><a href={`${mainDomain}/${path.covid19FreeSignup}`}>{requestProposals}</a>
                                    </li>
                                    {/* <li><a href={path.affiliateHome}>{becomeAGigzoeAffiliate}</a> */}
                                    {/* </li> */}
                                    {/*<li><a href={`${baseLink}${path.askLegalQuestion}`}>Free Legal Advice</a></li>*/}

                                </ul>
                            </div>
                            <div className="col-sm-4 col-md-2 col-xs-6 footer-column">
                                <h4 className="text-right">Contact Us</h4>
                                <ul className="list-unstyled quick-links">
                                <li className="text-right">
                                    <b>For Sales & Order Related:</b><br/>
                                    <a href="mailto:support@workbeam.com">support@workbeam.com</a>
                                </li>
                                    <li className="text-right">
                                        <b>For Professional Profile related:</b><br/>
                                        <a href="mailto:seller@workbeam.com">seller@workbeam.com</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row" style={{borderTop: '1.5px solid #f2f2f2'}}>
                            <div className="col-sm-5 hidden-xs footer-bottom">
                                <p>&copy; iVedha Inc.</p>
                            </div>
                            <div className="col-sm-7 footer-bottom">
                                <p className="pull-right hidden-xs"><a href={path.termsAndConditions} target="_blank">
                                    Terms of Service.</a>&#x00A0;&#x00A0;&#x00A0; <a href={path.privacyPolicy} target="_blank">
                                    Privacy Policy.</a>
                                </p>
                                <p className="visible-xs"><a href={path.termsAndConditions} target="_blank">Terms of
                                    Service.</a>&#x00A0;&#x00A0;&#x00A0; <a href={path.privacyPolicy} target="_blank">Privacy
                                    Policy.</a>
                                </p>
                            </div>
                            <div className="col-xs-12 visible-xs footer-bottom">
                                <p>&copy; iVedha Inc.</p>
                            </div>
                        </div>
                    </div>
                </footer>
            }
        </Fragment>
    )
}

export function CustomSiteFooter({history}) {
    const {location} = history
    let {search} = location || {}
    search = search || ''
    return (
        <Fragment>
            <footer className="wiz-footer" style={{paddingTop: '30px'}}>
                <div className="container">
                    <div className="row" style={{borderTop: '1.5px solid #f2f2f2'}}>
                        <div className="col-sm-3 col-xs-12 footer-column">
                            <a href={mainDomain}>
                                <img src={GigzoeLogo} alt="logo"/>
                            </a>
                            <ul className="list-unstyled list-inline social">
                                <li className="list-inline-item">
                                    <a href="https://www.facebook.com/Workbeam/" target="_blank"> <i
                                        className="fa fa-facebook"></i>
                                    </a>
                                </li>
                                <li className="list-inline-item"><a href="https://twitter.com/Workbeam"
                                                                    target="_blank"><i
                                    className="fa fa-twitter"></i></a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="https://www.instagram.com/Gigzoe_" target="_blank"> <i
                                        className="fa fa-instagram"></i>
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="https://www.linkedin.com/company/Workbeam/" target="_blank"> <i
                                        className="fa fa-linkedin"></i>
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="https://www.youtube.com/channel/UCHd4aKnLNHsInUgVTWewoFQ" target="_blank"> <i
                                        className="fa fa-youtube-play"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-3 col-xs-6 footer-column">
                            <h4>Company</h4>
                            <ul className="list-unstyled quick-links">
                                <li><a href={path.getInTouch + search}>About</a>
                                </li>
                                <li><a href={`${baseUrl}/blog/`}>Blog</a>
                                </li>
                                <li><a href={path.helpCenter + search} target="_blank">Help</a></li>
                                {/*<li><a href={path.wizRewards + search}>Rewards</a></li>*/}
                                <li>
                                    <a href={path.testimonials + search}>Customer Stories</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-3 col-xs-6 footer-column">
                            <h4>Mobile</h4>
                            <ul className="list-unstyled quick-links">
                                <li><a href="https://play.google.com/store/apps/details?id=com.blizzcraft.wizuser"
                                       target="_blank">Android for user</a>
                                </li>
                                <li><a href="https://play.google.com/store/apps/details?id=com.blizzcraft.wizpro&hl=en"
                                       target="_blank">Android for professionals</a>
                                </li>
                                <li><a href="https://itunes.apple.com/us/app/wizcounsel/id1458978743?ls=1&mt=8"
                                       target="_blank">iOS for user</a>
                                </li>
                                <li><a href="https://itunes.apple.com/us/app/wizcounsel-professionals/id1447086305?ls=1&mt=8"
                                       target="_blank">iOS for professionals</a>
                                </li>
                            </ul>
                        </div>
                        <div className="clearfix visible-xs"></div>
                        <div className="col-sm-3 col-xs-6 footer-column">
                            <h4 className="text-right">Contact Us</h4>
                            <h5 className="text-right" style={{marginBottom:'5px'}}>
                                <a href="mailto:support@workbeam.com">support@workbeam.com</a></h5>
                        </div>
                        {/*<div className="clearfix"></div>*/}
                        {/*<p className="para">Workbeam is a product of Blizzcraft Infotech Pvt. Ltd. We are not a law firm*/}
                            {/*or Chartered Accountancy firm and do not provide any legal services, legal advice, audit*/}
                            {/*services, ”lawyer or Chartered Accountant or Company secretary referral services" and do not*/}
                            {/*provide or participate in any legal representation.The site is not intended to be a source of*/}
                            {/*advertising or solicitation. By visiting this site, you acknowledge that you are doing so*/}
                            {/*voluntarily and of your own free will. You should not consider the information on the site to be*/}
                            {/*an invitation for a lawyer-client relationship, and transmission, receipt or use of the site*/}
                            {/*does not constitute or create a lawyer-client relationship.</p>*/}
                    </div>
                    <div className="row" style={{borderTop: '1.5px solid #f2f2f2'}}>
                        <div className="col-sm-5 hidden-xs footer-bottom">
                            <p>&copy; iVedha Inc.</p>
                        </div>
                        <div className="col-sm-7 footer-bottom">
                            <p className="pull-right hidden-xs"><a href={path.termsAndConditions + search} target="_blank">
                                Terms of Service.</a>&#x00A0;&#x00A0;&#x00A0; <a href={path.privacyPolicy + search} target="_blank">
                                Privacy Policy.</a>
                            </p>
                            <p className="visible-xs"><a href={path.termsAndConditions + search} target="_blank">Terms of
                                Service.</a>&#x00A0;&#x00A0;&#x00A0; <a href={path.privacyPolicy + search} target="_blank">Privacy
                                Policy.</a>
                            </p>
                        </div>
                        <div className="col-xs-12 visible-xs footer-bottom">
                            <p>&copy; iVedha Inc.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </Fragment>
    )
}
// export function intercomScript() {
//     // if (process.env.REACT_APP_ENV === env.dev || process.env.REACT_APP_ENV === env.production) {
//         if (window.intercomSettings === undefined) {
//             window.intercomSettings = {}
//         }
//         window.intercomSettings = {
//             app_id: "qimcl7t1",
//             // custom_launcher_selector: '#intercom_clicked_id'
//         };
//         var w = window;
//         var ic = w.Intercom;
//         if (typeof ic === "function") {
//             ic('reattach_activator');
//             ic('update', window.intercomSettings);
//         } else {
//             var d = document;
//             var i = function () {
//                 i.c(arguments)
//             };
//             i.q = [];
//             i.c = function (args) {
//                 i.q.push(args)
//             };
//             w.Intercom = i;
//
//             function l() {
//                 var s = d.createElement('script');
//                 s.type = 'text/javascript';
//                 s.async = true;
//                 s.src = 'https://widget.intercom.io/widget/qimcl7t1';
//                 var x = d.getElementsByTagName('script')[0];
//                 x.parentNode.insertBefore(s, x);
//             }
//
//             if (w.attachEvent) {
//                 w.attachEvent('onload', l);
//             } else {
//                 w.addEventListener('load', l, false);
//             }
//         }
//
// }
export function chatwoot() {
    (function(d,t) {
        var BASE_URL="https://app.chatwoot.com";
        var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
        g.src=BASE_URL+"/packs/js/sdk.js";
        s.parentNode.insertBefore(g,s);
        g.onload=function(){
            window.chatwootSDK.run({
                websiteToken: 'pRQJ7v85CU4V78smBBKuDShf',
                baseUrl: BASE_URL
            })
        }
    })(document,"script");
}

class AboutUs extends Component {

    constructor(props) {
        super(props);
        // const {history, match} = props
        // const {location} = history
        // let {pathname} = location
        // pathname = pathname || ''
        this.state = {
            services: [],
            lawyerId: 2,
            caId: 1,
            bookKeepingId: 3,
            secretarialId: 5,
            limit: 5,
            page: 1,
            pageLink: baseUrl,
            lawyerList: [],
            caList: [],
            bookKeepingList: [],
            secretarialList: [],
        }
    }

    componentDidMount() {
         if (!localStorage.isSessionValid() && localStorage.userType) {
            delete localStorage.removeKey(KEY_USER_TYPE)
        }
         if (localStorage.onBoardingData){
             delete localStorage.removeKey(KEY_ONBOARDING_DATA)
         }
    }

    // getProfessional = (professionId) => {
    //     const {limit, page} = this.state
    //     let url = `/consultants/list-of-consultant/?limit=${limit}&page=${page}`
    //     url += `&profession=${professionId}`
    //     apiv1.noAuth(url)
    //         .then(resp => {
    //             const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
    //             if (data) {
    //                 this.setState({[profession[professionId].professional_name]: data.results, loading: false})
    //             }
    //         })
    //         .catch(err => {
    //             this.setState({loading: false});
    //             apiv1.handleErrors(err, this.props.doDispatch)
    //         })
    // }

    render() {
        const {pageLink} = this.state
        // const title = `Book Digital Services for your Business with 100% Assurance | Workbeam`
        // const metaDesc = `Workbeam is India's most trusted Business Services platform and Freelance Marketplace. Hire Workbeam's verified Freelancers and Service Experts to grow your business.`
        return (
            <div className='App'>
                <Header link={pageLink} avatar={siteLogoOnSharedMedia} metaDescription={defaultPageMetaDescription} title={defaultPageTitle}/>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    ...(errorActionDispatchers(dispatch)),
});

export default withRouter(connect(null, mapDispatchToProps)(AboutUs))
