
const users = {
    "USER_LOGOUT": "USER_LOGOUT",
    "GET_USER": "GET_USER",
    "SET_USER": "SET_USER",
    "UPDATE_USER_PASSWORD": "UPDATE_USER_PASSWORD",
    "UPDATE_USER_PROFILE": "UPDATE_USER_PROFILE",
    "UPDATE_USER_EMAIL": "UPDATE_USER_EMAIL",
    "RESEND_VERIFICATION_EMAIL": "RESEND_VERIFICATION_EMAIL",
    "RECOVER_USER_PASSWORD": "RECOVER_USER_PASSWORD",
    "RESET_USER_PASSWORD": "RESET_USER_PASSWORD",
    "UPDATE_PROFILE_PIC": "UPDATE_PROFILE_PIC",
    "UPDATE_TRAINING_AND_QUESTION": "UPDATE_TRAINING_AND_QUESTION",
};

module.exports = {
    users,
};