import * as types from "../constants/auth";

export const userJobInProgress = (sidebarCount) => {
    return {
        type: types.USER_JOB_IN_PROGRESS_COUNT,
        payload: sidebarCount
    }
}

export const userJobPostingCount = (sidebarCount) => {
    return {
        type: types.USER_JOB_POSTING_COUNT,
        payload: sidebarCount
    }
};

export const professionalJobInProgress = (sidebarCount) => {
    return {
        type: types.PROFESSIONAL_JOB_IN_PROGRESS_COUNT,
        payload: sidebarCount
    }
};

export const professionalJobProposalSentCount = (sidebarCount) => {
    return {
        type: types.PROFESSIONAL_PROPOSAL_SENT_COUNT,
        payload: sidebarCount
    }
};

export const professionalSidebarJobCount = (sidebarCount) => {
    return {
        type: types.PROFESSIONAL_SIDEBAR_JOB_COUNT,
        payload: sidebarCount
    }
};

export const userSidebarJobCount = (sidebarCount) => {
    return {
        type: types.USER_SIDEBAR_JOB_COUNT,
        payload: sidebarCount
    }
};

export const affiliatePartnerStats = (stats) => {
    return {
        type: types.AFFILIATE_PARTNER_STATS,
        payload: stats
    }
};

export const professionalJobInvitesCount = (inviteCount) => {
    return {
        type: types.PROFESSIONAL_JOB_INVITES_COUNT,
        payload: inviteCount
    }
};

export const increaseJobPostingCount = (count) => {
    return {
        type: types.INCREASE_JOB_POSTING_COUNT,
        payload: count
    }
};

export const decreaseJobInProgressCount = (count) => {
    return {
        type: types.DECREASE_JOB_INPROGRESS_COUNT,
        payload: count
    }
};