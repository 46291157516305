import React, {Component, Fragment} from "react";
import GigzoeLogo from "../../static/images/gigzoe-logo.png";
import {Footer} from "../common/Footer";
import {OnboardingPageHeading} from "../functional/beforeHire"
import {OnboardingPageHeadingEdit} from "../functional/beforeHire"
import Select from "react-select";
import './../user/../../static/css/style.css'
import {apiv1} from "../../api";
import * as apiUrl from "../../api/apiUrls";
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as path from './../../constants/path'
import LoadingComponent from "../../utils/LoadingComponent";
import {onBoardingValidation, onBoardingFirstScreenValidator} from "../common/validation/onBoardingValidator";
import {errorActionDispatchers} from "../../actions/errors";
import connect from "react-redux/es/connect/connect";
import localStorage from './../../utils/localStorage'
import $ from "jquery";
import queryString from 'query-string'
import FileBase64 from 'react-file-base64';
import {allowedDocumentType} from "../professional/ProfessionalProfile";
import {TRUE} from "./UserChatToProfessionalAfterHire";
import Checkbox from '@material-ui/core/Checkbox';
import axios from "axios";
import {htmlToString} from "../../functions/htmlToString"
import {status as job_status} from './../../constants/job'
import {increaseJobPostingCount} from "../../actions/job_count";
import {sendJobInvite} from './../user/UserSignUp'
import OnboardingSignup from './../user/OnboardingSignup'
import UserJobsHolder from "../../utils/UserJobsHolder";

export function hideDivClickOutside({containerClass}) {
    $(document).click(function(e)
    {
        let container = $(containerClass);
        if (!container.is(e.target) && container.has(e.target).length === 0)
        {
            container.hide();
        }
    });
}

export function LogedinUserUi() {
    return (
        <div className="col-xs-10 login-page-header">
            <div className="pull-right"
                 style={{visibility: `${localStorage.isSessionValid() ? '' : 'hidden'}`}}
            >
                <span className="glyphicon glyphicon-user"></span>
                <span className="text-dark" style={{fontSize: '18px', marginLeft: '5px'}}
                      id="first_name">{localStorage.firstName + ' ' + localStorage.lastName}</span>
            </div>
        </div>
    )
}

export function PublicLogedInHeader() {
    return (
        <div className="collapse navbar-collapse" id="fixedNavbarHelp">
            <ul className="nav navbar-nav navbar-right">
                <li style={{visibility: `${localStorage.isSessionValid() ? '' : 'hidden'}`}}>
                    <a style={{fontSize: '18px', textTransform: 'capitalize'}}>
                        <span className="glyphicon glyphicon-user"></span> <span
                        id="first_name">{localStorage.firstName + ' ' + localStorage.lastName}</span>
                    </a>
                </li>
            </ul>
        </div>
    )
}

const current_screen = {
    1: 1,
    2: 2,
    3: 3,
}

class OnBoarding extends Component {
    constructor(props) {
        super(props)
        const {history, client} = props
        const {location} = history
        const {search, pathname} = location
        const {rehire, professional, name, jobId, cityId, is_direct, projectId, clientId } = queryString.parse(search)
        if (pathname === path.editJob && !jobId) {
            alert('Something went wrong')
            history.goBack()
            return
        }
        this.signUpRef = React.createRef()
        this.state = {
            questionId: -1,
            services: [],
            loading: false,
            title: '',
            description: '',
            jobQuestions: [],
            questionAnswers: [],
            city: [],
            jobStatus: '',
            errors: this.getInitialErrorObj(),
            selectedServiceId: '',
            childServicesIds: [],
            rehire,
            professional,
            name,
            is_direct,
            document_file_name: '',
            document_base64: '',
            documentLink: '',
            budget: '',
            avg_price: '',
            cityId: cityId !== undefined ? cityId : '',
            jobId: jobId !== undefined ? jobId : '',
            send_notification_to_all: false,
            send_notification_to_all_in_super_admin: '',
            professionalList: [],
            userList: [],
            endClientList: [],
            disableEndClient: false,
            disableProject: false,
            projectId,
            endClientId: clientId,
            selectedProject: { label: '', value: '' },
            projectsList: [],
            professionalId: '',
            userId: '',
            actual_amount_enter_by_professional: '',
            professional_amount_tax: '',
            wcFees: '',
            wcGst: '',
            fixed_amount: '',
            unit: '',
            isDescriptionCompleted: false,
            isQuestionCompleted: false,
            isSignUpCompleted: false,
            currentScreen: current_screen[1],
            client: client || {},
            selectedEndClient: null
        }
    }

    componentDidMount() {
        const {jobId} = this.state
        const {location} = this.props
        const {pathname} = location
        this.getCity()
        if (jobId) {
            // this.getJobDetails(jobId)
            this.getServicesAndJobDetails(jobId)
        } else {
            this.getPracticeArea(false)
        }
        if (pathname === path.manuallyJobCreate) {
            this.getProfessional()
            this.getUsers()
            this.getEndClients();
            this.getProjects();
        }
        if (pathname === path.userOnboarding) {
            $('body').css('background-color', '#fff')
        }
    }

    componentWillReceiveProps(nextProps) {
        const {client} = nextProps
        if (client) {
            this.setState({client})
        }
    }

    getProfessional = () => {
        this.setState({loading: true});
        const url = `/job/professional-dropdown/`;
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp);
                if (data) {
                    this.setState({professionalList: data, loading: false})
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    getUsers = () => {
        this.setState({loading: true});
        const url = `/job/user-dropdown/`;
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp);
                if (data) {
                    this.setState({userList: data, loading: false})
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    getEndClients = () => {
        this.setState({loading: true});
        const url = `/users/endclient-list/`;
        // /users/projects/
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp);
                if (data) {
                    let clientId = this.state.endClientId;
                    const found = data.find((d) => d.user == clientId);
                    this.setState({endClientList: data, loading: false});
                    if(found) {
                        this.setState({...this.state, disableEndClient: true, selectedEndClient: { label: found.company_name, value: found } });
                    }
                } else {
                    this.setState({loading: false});
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }
    
    getProjects = () => {
        this.setState({loading: true});
        let userId = localStorage.clientId;
        const url = `/users/${userId}/projects/`;
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp);
                
                if (data) {
                    let updatedState = {...this.state, projectsList: data, loading: false};
                    
                    if(this.state.projectId) {
                        let foundProject = data.find((p) => p.id === Number(this.state.projectId));
                        updatedState = {
                            ...updatedState,
                            selectedProject: { label: foundProject?.title, value: foundProject?.id },
                            disableProject: true
                        }
                        
                    }
                    this.setState({ ...updatedState });
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    getServicesAndJobDetails = (jobId) => {
        const self = this
        self.setState({loading: true})
        axios.all([this.getPracticeArea(jobId), this.getJobDetails(jobId)])
            .then(axios.spread(function (services, jobDetails) {
                let {budget, city_data, description, document_file_name, document, job_question, selected_services, title, status, avg_price, send_notification_to_all} = jobDetails
                description = htmlToString(description)
                if (document_file_name.toLowerCase() === TRUE) {
                    document_file_name = ''
                }
                // let {services} = this.state
                let errorsObj = {...self.state.errors}
                const {id} = city_data
                const jobQuestions = job_question.map(((questionDetails, index) => {
                    let {practise_area_question_details, practise_area_question, id, mandatory, practise_area, answer} = questionDetails
                    return {
                        question: practise_area_question_details,
                        mandatory,
                        practise_area,
                        id: practise_area_question,
                        data_created_id: id,
                        answered: answer && answer[0]
                    }
                }))
                const questionAnswers = jobQuestions.map((jobQuestionDetails, index) => {
                    const {singleQuestionAnswer, errors} = self.questionAnswersObj([{
                        ...jobQuestionDetails,
                    }], errorsObj)
                    if (index + 1 === jobQuestions.length) {
                        errorsObj = {...errors}
                    }
                    return {...singleQuestionAnswer[0]}
                })
                const selectedServicesText = selected_services.map(childServiceId => {
                    return services.filter(childServiceData => {
                        return childServiceData.id === childServiceId
                    })[0].name
                }).join(', ')
                $('.selected_services').html(selectedServicesText)
                self.setState({
                    childServicesIds: selected_services,
                    title,
                    description,
                    cityId: id.toString(),
                    budget,
                    document_file_name,
                    documentLink: document,
                    jobQuestions,
                    errors: errorsObj,
                    questionAnswers,
                    avg_price,
                    send_notification_to_all,
                    send_notification_to_all_in_super_admin: send_notification_to_all,
                    services,
                    loading: false,
                    jobStatus: status
                }, () => self.showDropdown())
            })).catch((err) => {
            self.setState({loading: false});
            apiv1.handleErrors(err, this.props.doDispatch)
        })
    }

    getCity = () => {
        this.setState({loading: true});
        const url = `/geo/city/`;
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp);
                if (data) {
                    this.setState({city: data, loading: false})
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    getPracticeArea = (jobId) => {
        this.setState({loading: true});
        let url = `${apiUrl.getPractice}?is_software=${false}`
        if (jobId) {
            return apiv1.auth.get(url)
                .then(resp => {
                    return apiv1.parseAndAutoHandleIssues(resp);
                })
        } else {
            apiv1.auth.get(url)
                .then(resp => {
                    const data = apiv1.parseAndAutoHandleIssues(resp);
                    if (data) {
                        this.setState({services: data, loading: false}, () => this.showDropdown())
                    } else {
                        this.setState({loading: false})
                    }
                })
                .catch(err => {
                    this.setState({loading: false});
                    apiv1.handleErrors(err, this.props.doDispatch)
                })
        }
    };

    getQuestions = (child_service_id) => {
        this.setState({loading: true});
        const url = `/job/question/?practise_area=${[child_service_id]}`;
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp);
                if (data) {
                    const errorsObj = {...this.state.errors}
                    const {singleQuestionAnswer, errors} = this.questionAnswersObj(data, errorsObj)
                    let {services, jobQuestions} = this.state
                    let questionAnswers = [...this.state.questionAnswers]
                    let childServicesIds = [...this.state.childServicesIds]
                    jobQuestions = jobQuestions.concat(data)
                    questionAnswers = questionAnswers.concat(singleQuestionAnswer)
                    childServicesIds.push(child_service_id)
                    const title = childServicesIds.map(childServiceId => {
                        return services.filter(childServiceData => {
                            return childServiceData.id === childServiceId
                        })[0].name
                    }).join(', ')
                    $('.selected_services').html(title)
                    if (!title) {
                        $('.selected_services').html('What Service are you looking for ?')
                    }

                    this.setState({
                        loading: false,
                        questionAnswers,
                        errors,
                        title,
                        childServicesIds,
                        jobQuestions
                    })
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    };


    getJobDetails = (jobId) => {
        const url = `/job/job/${jobId}/`
        return apiv1.auth.get(url)
            .then(resp => {
                return apiv1.parseAndAutoHandleIssues(resp);
            })
    }

    acceptProposal = (jobId, proposalId) => {
        const {doDispatch, history} = this.props
        const request = {
            job: jobId,
            proposal: proposalId
        }

        apiv1.auth.post('/job/proposal-accept/', request)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                if (data) {
                    history.replace({
                        pathname: path.userJobInProgress
                    })
                }
            })
            .catch(err => {
                if (err.response !== undefined) {
                    if (err.response.status === 400) {
                        history.push(path.userJobInProgress)
                    }
                }
                apiv1.handleErrors(err, doDispatch)
            })
    }

    sendProposal = (professionalDetails, jobId, phone) => {
        const {wcFees, wcGst, gstAmount, price, actual_amount_enter_by_professional, professionalId} = professionalDetails
        const request = {
            message: 'Hello',
            fixed_amount: price,
            commission: wcFees,
            commission_tax: wcGst,
            professional_amount_tax: gstAmount,
            actual_amount_enter_by_professional,
            schedule_unit_proposed: 15,
            sender: professionalId,
            job: jobId,
        }
        apiv1.auth.post(apiUrl.sendProposal, request)
            .then(res => {
                const data = apiv1.parseAndAutoHandleIssues(res, this.props.doDispatch);
                if (data) {
                    const {id} = data
                    this.acceptProposal(jobId, id, phone)
                }
            })
            .catch(err => {
                this.setState({loading: false})
                apiv1.handleErrors(err, this.props.doDispatch);
            })
    }

    showDropdown = () => {
        $('li .menu-item').click(function (event) {
            $(this).children('ul')
            event.stopPropagation();
        });
        $('.submenu-item').click(function (e) {
            $('#open_dropdown').addClass('open');
            $('.open>.dropdown-menu').css('display', 'block')
        })
        $(document).on('click', function (e) {
            var container = $("#open_dropdown");

            // if the target of the click isn't the container nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                $("#practise_area_dropdown").addClass('hidden')
            }
        });
        $('#dropdown-button').click(function (e) {
            $("#practise_area_dropdown").removeClass('hidden')
        })

    }

    getInitialErrorObj = () => {
        let error1 = {}
        let errors = {}
        error1['mandatory'] = true
        error1['message'] = ''
        error1['type'] = TEXT
        errors['title'] = {...error1}
        errors['description'] = {...error1}
        errors['budget'] = {...error1}
        return errors
    };


    onChange = (e) => {
        this.setState({questionId: Number(e.target.value)})
    };

    questionAnswersObj = (questions, errors) => {
        const singleQuestionAnswer = []
        questions.map(question => {
            const {answered, mandatory, id, data_created_id} = question
            const {metadata} = question.question
            const {type} = metadata
            const practise_area_question = id
            let answer = {
                practise_area_question,
            }
            if (data_created_id) {
                answer.id = data_created_id
            }
            errors[practise_area_question] = {
                mandatory,
                type,
                message: ''
            }
            switch (type) {
                case DROPDOWN:
                case RADIO:
                case TEXT:
                    answer.answer = answered ? answered : ''
                    singleQuestionAnswer.push(answer)
                    break;
                case CHECKBOX:
                    answer.answer = [];
                    singleQuestionAnswer.push(answer)
                    break;
                default:
                    break
            }
        });
        return {singleQuestionAnswer, errors}
    };

    removeQuestionOnEditJob = ({jobId, practise_area, jobQuestions, questionAnswers, childServicesIds, title}) => {
        const {removeAnsOfPracticeAreaQuestions} = apiUrl
        const request = {
            practise_area,
            job: jobId
        }
        apiv1.auth.post(removeAnsOfPracticeAreaQuestions, request)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp);
                if (data) {
                    $('.selected_services').html(title)
                    if (!title) {
                        $('.selected_services').html('What Service are you looking for ?')
                    }
                    this.setState({
                        childServicesIds,
                        jobQuestions,
                        questionAnswers,
                        title,
                        loading: false
                    }, () => this.showDropdown())
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    subServiceUi = (subService) => {
        return (
            subService.map(data => {
                const {name, id} = data
                const {childServicesIds, loading} = this.state
                return <li key={id}>
                    <button id={id}
                            className="btn submenu-item btn-link"
                            disabled={loading}
                            onClick={(e) => {
                                e.preventDefault()
                                let {errors, questionAnswers, jobQuestions, services, jobId} = this.state
                                let title = ''
                                const copyOfChildServicesIds = [...childServicesIds]
                                const getIndexOfId = copyOfChildServicesIds.indexOf(id)
                                let isSavedQuestionDeleting = false
                                errors['practise_area'] = ''
                                errors['title']['message'] = ''
                                localStorage.selectedServiceId = id
                                if (getIndexOfId > -1) {
                                    jobQuestions = jobQuestions.filter(question => {
                                        const {data_created_id} = question
                                        isSavedQuestionDeleting = !!data_created_id
                                        return question.practise_area !== id
                                    })
                                    questionAnswers = questionAnswers.filter(answer => {
                                        return jobQuestions.filter(question => question.id === answer.practise_area_question).length > 0
                                    })
                                    copyOfChildServicesIds.splice(getIndexOfId, 1)
                                    title = copyOfChildServicesIds.map(childServiceId => {
                                        return services.filter(childServiceData => {
                                            return childServiceData.id === childServiceId
                                        })[0].name
                                    }).join(', ')
                                    if (isSavedQuestionDeleting) {
                                        this.removeQuestionOnEditJob({
                                            jobId,
                                            practise_area: id,
                                            questionAnswers,
                                            jobQuestions,
                                            childServicesIds: copyOfChildServicesIds,
                                            title,
                                        })
                                    } else {
                                        $('.selected_services').html(title)
                                        if (!title) {
                                            $('.selected_services').html('What Service are you looking for ?')
                                        }
                                        this.setState({
                                            errors,
                                            title,
                                            questionAnswers,
                                            jobQuestions,
                                            childServicesIds: copyOfChildServicesIds
                                        })
                                    }
                                } else {
                                    this.setState({errors}, () => this.getQuestions(id))
                                }
                            }}
                    >
                        <Checkbox
                            checked={childServicesIds.indexOf(id) > -1}
                            color="primary"
                            style={{padding: '0px'}}
                        />
                        {/*<input type="checkbox" value={id} checked={childServicesIds.indexOf(id) > -1} />*/}
                        {` ${name}`}
                    </button>
                </li>
            })
        )

    };


    servicesUi = () => {
        const {services} = this.state;
        return (
            services.map(data => {
                if (data.parent === null) {
                    var subService = services.filter(function (item) {
                        if (item.parent === data.id)
                            return item
                    });
                    return <li className={subService.length > 0 ? "dropdown-submenu" : ""} key={data.id}>
                        <a className="menu-item">{data.name}</a>
                        {(subService.length) > 0 &&
                        <ul className="dropdown-menu"
                        >
                            {this.subServiceUi(subService)}
                        </ul>
                        }
                    </li>
                }
            })
        )
    };

    changeMultipleAnswersType = (e, practice_area_question_id) => {
        const {value} = e.target
        const questionAnswers = [...this.state.questionAnswers]
        let jobQuestions = [...this.state.jobQuestions]
        const errors = {...this.state.errors}
        errors[practice_area_question_id].message = ''
        questionAnswers.find(obj => obj.practise_area_question === practice_area_question_id).answer = [value.toString()]
        jobQuestions.find(obj => obj.id === practice_area_question_id).answered = value.toString()
        this.setState({questionAnswers, errors, jobQuestions})
    };

    singleJobQuestion = (questionDetails) => {
        const errors = {...this.state.errors}
        const questionAnswers = [...this.state.questionAnswers]
        const {question, answered} = questionDetails
        const practise_area_question = questionDetails.id
        const {metadata, text, id} = question
        const {data, type} = metadata
        switch (type) {
            case CHECKBOX:
                return (
                    <div key={id}>
                        <div className="clearfix"></div>
                        <div className="form-group">
                            <label>{text}</label>
                            <div className="clearfix"></div>
                            {data.map(inputValue => {
                                return (
                                    <div className='col-xs-3' style={{padding: '0px'}} key={inputValue}>
                                        <div className="checkbox">
                                            <label>
                                                <input type="checkbox"
                                                       value={inputValue}
                                                       name={inputValue}
                                                       onChange={(e) => {
                                                           errors[id].message = '';
                                                           let answer = questionAnswers.find(obj => obj.practise_area_question === practise_area_question).answer;
                                                           if (e.target.checked) {
                                                               answer.push(inputValue.toString())
                                                           } else {
                                                               answer = answer.splice(answer.indexOf(inputValue, 1))
                                                           }
                                                           questionAnswers.find(obj => {
                                                               if (obj.practise_area_question === answer.id) {
                                                                   obj.answer.answer = answer
                                                               }
                                                           });
                                                           this.setState({questionAnswers, errors})
                                                       }}
                                                /> <span className="cr"><i
                                                className="cr-icon glyphicon glyphicon-ok"></i></span>
                                                {inputValue}</label>
                                        </div>
                                    </div>
                                )
                            })}
                            <div className="clearfix"></div>
                            <span
                                className={errors[practise_area_question].message ? 'error' : 'no-error'}>{errors[practise_area_question].message}</span>
                        </div>
                    </div>
                )
            case DROPDOWN:
                return (
                    <div key={practise_area_question}>
                        <label>{text}</label>
                        <div className='col-xs-12' style={{padding: '0px'}}>
                            <div className="form-group">
                                <select className="form-control input-lg"
                                        onChange={(e) => this.changeMultipleAnswersType(e, practise_area_question)}
                                >
                                    {
                                        data.map(inputValue => {
                                            return (<option value={inputValue}
                                                            key={inputValue}
                                                            name={inputValue}
                                            >
                                                {inputValue}
                                            </option>)
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        <span
                            className={errors[practise_area_question].message ? 'error' : 'no-error'}>{errors[practise_area_question].message}</span>
                    </div>
                );
            case RADIO:
                return (
                    <div
                        key={practise_area_question}
                    >
                        <label style={{marginBottom: '15px'}}>{text}</label>
                        <div className="clearfix"></div>
                        <RadioGroup
                            value={answered}
                            onChange={(e) => this.changeMultipleAnswersType(e, practise_area_question)}
                            className="inlineRadio"

                        >
                            {data.map(inputValue => {
                                return (
                                    <FormControlLabel
                                        key={inputValue}
                                        value={inputValue}
                                        control={<Radio/>}
                                        label={inputValue}
                                    />
                                )
                            })}
                        </RadioGroup>
                        <div className="clearfix"></div>
                        <span
                            className={errors[practise_area_question].message ? 'error' : 'no-error'}>{errors[practise_area_question].message}</span>
                    </div>
                )
            default:
                return (
                    <div key={practise_area_question}>
                        <div className="clearfix"></div>
                        <div className="form-group"
                        >
                            <div className="clearfix"></div>
                            <label>{text}</label>
                            <input type="text"
                                   className="form-control input-lg"
                                   name={practise_area_question}
                                   value={questionAnswers.find(obj => obj.practise_area_question === practise_area_question).answer}
                                   onChange={(e) => this.changeMultipleAnswersType(e, practise_area_question)}
                            />
                            <span
                                className={errors[practise_area_question].message ? 'error' : 'no-error'}>{errors[practise_area_question].message}</span>
                        </div>

                    </div>

                )

        }
    };

    changeTextTypeInput = (e) => {
        const errors = {...this.state.errors}
        const {name, value} = e.target
        const {location} = this.props
        const {pathname} = location
        let regex = /^(?=.*[a-zA-Z])(?=.*[\W_]).+$/;
        let valid =  new RegExp(regex).test(value);

        if (pathname !== path.manuallyJobCreate) {
            errors[name].message = '';
        }
        // if(errors[name]) {
        //     errors[name].message = valid ? '' : 'Special Characters / Numeric Not Allowed';
        // }
        this.setState({[name]: value, errors})
    };

    handleOnBlur = () => {
        const { actual_amount_enter_by_professional, professional_amount_tax, wcFee, wcGst } = this.state;
        let amount;
        try {
            amount = Number(actual_amount_enter_by_professional || '0') + Number(professional_amount_tax|| '0') + Number(wcFee || '0') + Number(wcGst || '0'); 
        } catch(e) {
            amount = 0;
        }
        this.setState({ ...this.state, fixed_amount: amount });
    };

    jobQuestionsUi = () => {
        const {jobQuestions} = this.state;
        return (
            <Fragment>
                {jobQuestions.map(question => {
                    return this.singleJobQuestion(question)
                })}
            </Fragment>
        )
    };


    dataValidate = (errors) => {
        const {questionAnswers} = this.state;
        for (let i = 0; i < questionAnswers.length; i++) {
            //console.log(typeof questionAnswers.answer)
            if (typeof questionAnswers[i].answer === 'string') {
                const answer = [];
                answer.push(questionAnswers[i].answer.toString());
                questionAnswers[i].answer = answer;
                this.setState({questionAnswers})

            }
            //console.log(questionAnswers.answer)
        }
    };

    manuallyAssignJob = (e) => {
        e.preventDefault();
        const {description, title, questionAnswers, document_file_name, document_base64, cityId, childServicesIds, actual_amount_enter_by_professional, professional_amount_tax, fixed_amount, unit, professionalId, userId, wcFee, wcGst} = this.state;
        const {history, doDispatch} = this.props
        const errors = {...this.state.errors};
        // TODO update request send project Id 
        const request = {
            selected_services: childServicesIds,
            practise_area: childServicesIds.length > 0 ? childServicesIds[0].toString() : '',
            title,
            description,
            city: cityId,
            job_question: questionAnswers,
            actual_amount_enter_by_professional,
            professional_amount_tax,
            commission: wcFee,
            commission_tax: wcGst,
            fixed_amount,
            unit,
            professional: professionalId,
            user: this.state?.selectedEndClient?.value?.user,
            project: this.state?.selectedProject?.value,
            // endclient: this.state?.selectedEndClient
        }
        if (document_base64) {
            request.document_file_name = document_file_name;
            request.document_base64 = document_base64
        }
        this.dataValidate();
        const isFormValidated = onBoardingValidation(errors, request);
        this.setState({loading: true})
        if (isFormValidated) {
            apiv1.auth.post(apiUrl.createJobManually, request)
                .then(res => {
                    // api call for creating chat room. 
                    let profId = request.professional;
                    let profUserId = this.state.professionalList.find((p) => p.id == profId)?.user_id;
                    let clientId = this.state.endClientList.find((client) => client?.user == request.user)?.user;
                    let createChatUrl = `${apiUrl.createChatRoomWithoutProfessionalProduct}${clientId}/${profUserId}`;
                    apiv1.auth.get(createChatUrl).then((res) => {
                    });
                    const data = apiv1.parseAndAutoHandleIssues(res, doDispatch);
                    if (data) {
                        history.push(path.userJobInProgress)
                    } else {
                        this.setState({loading: false})
                    }
                })
                .catch(err => {
                    const resErrors = apiv1.handleErrors(err, doDispatch);
                    for (let key in resErrors) {
                        errors[key]['message'] = resErrors[key][0]
                    }
                    this.setState({errors, loading: false})
                })
        } else {
            this.setState({errors, loading: false})
        }
    }

    firstScreenRequestObj = () => {
        const {description, title, budget, cityId, childServicesIds} = this.state;
        return {
            selected_services: childServicesIds,
            practise_area: childServicesIds.length > 0 ? childServicesIds[0].toString() : '',
            title,
            city: cityId,
            description,
            budget,
        }
    }

    jobSubmitRequestObj = () => {
        const {questionAnswers, send_notification_to_all, send_notification_to_all_in_super_admin, jobId} = this.state;
        const {is_super_user} = this.props
        const reqObj = {
            ...this.firstScreenRequestObj(),
            job_question: questionAnswers,
        }
        if (!jobId) {
            reqObj.send_notification_to_all = send_notification_to_all
        }
        if (jobId && is_super_user && send_notification_to_all_in_super_admin !== send_notification_to_all) {
            reqObj.send_notification_to_all = send_notification_to_all
        }
        return reqObj
    }

    calculateCommission = (enteredAmount, jobId) => {
        apiv1.auth.post(`/job/calculate-proposal-amount`,
            {
                actual_amount_enter_by_professional: enteredAmount
            })
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    let {actual_amount_enter_by_professional, commission, commission_tax, professional_amount_tax} = data
                    professional_amount_tax = 0
                    let totalAmount = actual_amount_enter_by_professional + professional_amount_tax + commission + commission_tax
                    totalAmount = totalAmount.toFixed(2)
                    this.setState({
                        gstAmount: professional_amount_tax.toFixed(2),
                        wcFees: commission.toFixed(2),
                        wcGst: commission_tax.toFixed(2),
                        totalAmount,
                        actual_amount_enter_by_professional,
                    }, () => {
                        let {wcFees, wcGst, gstAmount, actual_amount_enter_by_professional, professional} = this.state
                        let professionalDetails = {
                            wcFees,
                            wcGst,
                            gstAmount,
                            price: totalAmount,
                            actual_amount_enter_by_professional,
                            professionalId: professional
                        }
                        const {client} = this.state
                        let {phone} = client || {}
                        let phoneNo = phone.phone
                        this.sendProposal(professionalDetails, jobId, phoneNo)
                    })
                }
            })
            .catch(err => {
                apiv1.handleErrors(err, this.props.doDispatch);
                this.setState({loading: false})
            })
    }


    submitRequest = ({toAllProfessional, signInWithPhoneNo, onSignInWithEmail}) => {
        const {jobStatus, document_file_name, document_base64, professional, jobId, avg_price} = this.state;
        const {history, is_super_user, location, increaseJobCount} = this.props
        let {search} = location
        const errors = {...this.state.errors};
        let apiRequest = ''
        const request = {
            ...this.jobSubmitRequestObj()
        }
        if (jobId && is_super_user) {
            if (avg_price) {
                request.avg_price = avg_price
            }
            if (jobStatus) {
                request.status = jobStatus
            }
        }
        if (document_base64) {
            request.document_file_name = document_file_name;
            request.document_base64 = document_base64
        }
        this.dataValidate()
        const isFormValidated = onBoardingValidation(errors, request)
        if (isFormValidated) {
            if (localStorage.isSessionValid()) {
                if (jobId) {
                    apiRequest = apiv1.auth.patch(`${apiUrl.createJob}${jobId}/`, request)
                } else {
                    apiRequest = apiv1.auth.post(apiUrl.createJob, request)
                }
                this.setState({loading: true})
                apiRequest.then(res => {
                    const data = apiv1.parseAndAutoHandleIssues(res, this.props.doDispatch);
                    if (data) {
                        if (jobId) {
                            UserJobsHolder.updatePostedJob({jobId, jobData: data})
                            history.push(`/${path.job}/${jobId}/${path.list}`)
                            return
                        }
                        increaseJobCount(increaseJobPostingCount(1))
                        if (toAllProfessional) {
                            window.jobPostRecord()
                            const {id} = data
                            history.replace({
                                pathname: `/${path.job}/${id}/${path.list}`,
                                search,
                            })
                        } else {
                            if (!is_super_user) {
                                const {is_direct} = this.state
                                const invitationRequest = {
                                    receiver: professional,
                                    job: data.id
                                }
                                if (is_direct && professional) {
                                    this.calculateCommission(1000, invitationRequest.job)
                                } else {
                                    sendJobInvite({request: invitationRequest, component: this})
                                }
                            }
                        }
                    } else {
                        this.setState({loading: false})
                    }
                })
                    .catch(err => {
                        const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
                        for (let key in resErrors) {
                            errors[key]['message'] = resErrors[key][0]
                        }
                        this.setState({errors, loading: false})
                    })
            } else {
                localStorage.onBoardingData = JSON.stringify(request)
                const {onSubmit, state} = this.signUpRef.current
                if (signInWithPhoneNo) {
                    signInWithPhoneNo()
                    return
                }
                if (onSignInWithEmail) {
                    onSignInWithEmail()
                    return
                }
                const {isSignUp} = state
                if (isSignUp && onSubmit) {
                    onSubmit()
                }
            }
        } else {
            this.setState({errors, loading: false}, () => {
            })
        }
    };

    PriceRange = () => {
        const {budget} = this.state
        return (
            priceRangeData.map((data, index) => {
                return <option key={index} id={data} value={data} selected={data === budget}>{data}</option>
            })
        )
    };

    uploadDocument = (files) => {
        const currentUrl = files.base64;
        let newUrl = '';
        for (let i = 0; i < allowedDocumentType.length; i++) {
            if (currentUrl.includes(allowedDocumentType[i])) {
                newUrl = currentUrl.replace(allowedDocumentType[i], "")
            }
        }
        if (newUrl === '') {
            alert('not a valid type document')
        } else {
            this.setState({document_base64: newUrl, document_file_name: files.name, documentLink: ''});
        }
    };

    setLoading = () => {
        this.setState(preState => ({loading: !preState.loading}))
    }

    manuallyJobCreateUi = () => {
        const {errors, professionalList, userList, projectsList, selectedProject, projectId, endClientList, unit, actual_amount_enter_by_professional, professional_amount_tax, wcFee, wcGst, fixed_amount} = this.state
        return (
            <Fragment>
                 <div className="col-sm-12">
                    <div className='form-group'>
                        <label>Select Project </label>
                        <Select
                            isSearchable
                            isClearable
                            isDisabled={this.state.disableProject}
                            value={selectedProject}
                            options={projectsList.map((p) => ({ label: `${p.title}`, value: p.id}))}
                            onChange={(e) => {
                                this.onChangeDropdown({ target: { name: 'selectedProject', value: e }});
                            }}
                        />
                        <span className={errors.project ? 'error' : 'no-error'}>{errors.project}</span>
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className='form-group'>
                        <label>Select Professional</label>
                        <Select
                            isSearchable
                            isClearable
                            options={professionalList.map((p) => ({ label: `${p.first_name} ${p.last_name}`, value: p.id}))}
                            onChange={(e) => {
                                this.onChangeDropdown({ target: { name: 'professionalId', value: e?.value}});
                                // this.handleFormChange({
                                //     target: { name: "client", value: e?.value },
                                // });
                            }}
                        />
                        {/* <select className="form-control input-lg"
                                name='professionalId'
                                onChange={(e) => {
                                    this.onChangeDropdown(e)
                                }}
                        >
                            <option disabled selected>Professional</option>
                            {professionalList.map((data, index) => {
                                return <option value={data.id}
                                               key={data.id}>{data.first_name} {data.last_name} ({data.email}) </option>
                            })}
                        </select> */}
                        <span className={errors.professional ? 'error' : 'no-error'}>{errors.professional}</span>
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className='form-group'>
                        <label>Select End Client</label>
                        <Select
                            isSearchable
                            isClearable
                            options={endClientList.map((p) => ({ label: `${p.company_name}`, value: p }))}
                            isDisabled={this.state.disableEndClient}
                            value={this.state.selectedEndClient}
                            onChange={(e) => {
                                this.onChangeDropdown({ target: { name: 'selectedEndClient', value: e } });
                            }}
                        />
                        <span className={errors.user ? 'error' : 'no-error'}>{errors.user}</span>
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className='form-group'>

                        <label style={{
                            fontWeight: 'normal',
                            fontSize: '16px',
                            marginBottom: '0px',
                            fontFamily: 'SofiaLight',
                            color: '#000'
                        }}>Professional Fee</label>
                        <input type="Number"
                               className="form-control input-lg"
                               maxLength="17"
                               name="actual_amount_enter_by_professional"
                               placeholder="Professional Fee"
                               style={{border: '1px solid #e8eef3', padding: '10px 5px'}}
                               value={actual_amount_enter_by_professional}
                               onBlur={() => this.handleOnBlur()}
                               onChange={this.changeTextTypeInput}
                        />
                        <span
                            className={errors.actual_amount_enter_by_professional ? 'error' : 'no-error'}>{errors.actual_amount_enter_by_professional}</span>
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className='form-group'>

                        <label style={{
                            fontWeight: 'normal',
                            fontSize: '16px',
                            marginBottom: '0px',
                            fontFamily: 'SofiaLight',
                            color: '#000'
                        }}>Professional Tax</label>
                        <input type="Number"
                               className="form-control input-lg"
                               maxLength="17"
                               name="professional_amount_tax"
                               placeholder="Professional Tax"
                               onBlur={() => this.handleOnBlur()}
                               style={{border: '1px solid #e8eef3', padding: '10px 5px'}}
                               value={professional_amount_tax}
                               onChange={this.changeTextTypeInput}
                        />
                        <span
                            className={errors.professional_amount_tax ? 'error' : 'no-error'}>{errors.professional_amount_tax}</span>
                    </div>
                </div>

                <div className="col-sm-12">
                    <div className='form-group'>
                        <label style={{
                            fontWeight: 'normal',
                            fontSize: '16px',
                            marginBottom: '0px',
                            fontFamily: 'SofiaLight',
                            color: '#000'
                        }}>Workbeam Fee</label>
                        <input type="Number"
                               className="form-control input-lg"
                               name="wcFee"
                               placeholder="Workbeam Fee"
                               onBlur={() => this.handleOnBlur()}
                               style={{border: '1px solid #e8eef3', padding: '10px 5px'}}
                               value={wcFee}
                               onChange={this.changeTextTypeInput}
                        />
                        <span className={errors.commission ? 'error' : 'no-error'}>{errors.commission}</span>
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className='form-group'>
                        <label style={{
                            fontWeight: 'normal',
                            fontSize: '16px',
                            marginBottom: '0px',
                            fontFamily: 'SofiaLight',
                            color: '#000'
                        }}>Workbeam Tax</label>
                        <input type="Number"
                               className="form-control input-lg"
                               name="wcGst"
                               placeholder="Workbeam Tax"
                               style={{border: '1px solid #e8eef3', padding: '10px 5px'}}
                               value={wcGst}
                               onBlur={() => this.handleOnBlur()}
                               onChange={this.changeTextTypeInput}
                        />
                        <span className={errors.commission_tax ? 'error' : 'no-error'}>{errors.commission_tax}</span>
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className='form-group'>
                        <label style={{
                            fontWeight: 'normal',
                            fontSize: '16px',
                            marginBottom: '0px',
                            fontFamily: 'SofiaLight',
                            color: '#000'
                        }}>Total Amount</label>
                        <input type="Number"
                               className="form-control input-lg"
                               name="fixed_amount"
                               placeholder="Total Amount"
                               style={{border: '1px solid #e8eef3', padding: '10px 5px'}}
                               value={fixed_amount}
                               onChange={this.changeTextTypeInput}
                        />
                        <span className={errors.fixed_amount ? 'error' : 'no-error'}>{errors.fixed_amount}</span>
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className='form-group'>

                        <label style={{
                            fontWeight: 'normal',
                            fontSize: '16px',
                            marginBottom: '0px',
                            fontFamily: 'SofiaLight',
                            color: '#000'
                        }}>Estimated no of days</label>
                        <input type="Number"
                               className="form-control input-lg"
                               maxLength="2"
                               name="unit"
                               placeholder="Unit in day"
                               style={{border: '1px solid #e8eef3', padding: '10px 5px'}}
                               value={unit}
                               onChange={this.changeTextTypeInput}
                        />
                        <span className={errors.unit ? 'error' : 'no-error'}>{errors.unit}</span>
                    </div>
                </div>
            </Fragment>
        )
    }

    onChangeDropdown = (e) => {
        const {errors} = this.state
        const {name, value} = e.target
        if (name === 'cityId') {
            errors['city'] = ''
        }
        this.setState({[name]: value, errors})
    }

    rehireUi = () => {
        const {name} = this.state
        return (
            <Fragment>
                <p id="open_send_invite_popup" data-toggle="modal"
                   data-target="#send_invite_popup"></p>
                <div className="modal fade" id="send_invite_popup"
                     role="dialog">
                    <div className="modal-dialog dialog-top-margin">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button type="button" className="close"
                                        onClick={() => {
                                            // $('#pay').css('display','none')
                                        }}
                                        data-dismiss="modal">&times;</button>
                                <h4 className="heading-md text-center">Do you
                                    want us to post this job to ...</h4>
                            </div>
                            <p>&#x00A0;</p>
                            <div className="modal-footer"
                                 style={{marginBottom: '10px', textAlign: 'center'}}>
                                <button data-dismiss="modal"
                                        className="btn btn-danger"
                                        onClick={(e) => {
                                            $('.modal-body .close').click()
                                            this.submitRequest({toAllProfessional: true})
                                        }}>To all professionals
                                </button>
                                <button className="btn btn-primary"
                                        style={{marginLeft: '20px'}}
                                        onClick={(e) => {
                                            $('.modal-body .close').click()
                                            this.submitRequest({toAllProfessional: false})
                                        }}>Only &nbsp;
                                    {name !== undefined ? name : ''}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    makeLiveUi = () => {
        const {send_notification_to_all, jobStatus} = this.state;
        return (
            <div className="row">
                <div className="col-sm-6">
                    <select className="form-control input-lg"
                            name='jobStatus'
                            onChange={this.onChangeDropdown}
                    >
                        {
                            Object.keys(job_status).map(statusKey => {
                                return <option value={job_status[statusKey]}
                                               selected={job_status[statusKey] === jobStatus}>{statusKey}</option>
                            })
                        }
                    </select>
                </div>
                <div className="col-sm-6">
                    <Checkbox
                        checked={send_notification_to_all}
                        color="primary"
                        style={{padding: '12px', fontSize: '18px'}}
                        onChange={() => {
                            this.setState({send_notification_to_all: !send_notification_to_all})
                        }
                        }
                    />
                    {send_notification_to_all ? 'Live' : 'Not Live'}
                </div>
            </div>
        )
    }

    updateState = ({name}) => {
        this.setState({[name]: name})
    }

    submitRequestButton = (btnClass) => {
        const {location} = this.props
        let {pathname} = location
        const {rehire, professional, name, is_direct} = this.state
        const {state} = this.signUpRef.current ? this.signUpRef.current : {}
        const {isSignUp} = state ? state : {}
        let buttonText = 'Request Proposals'
        if (localStorage.isSessionValid()) {
            if (pathname !== path.editJob) {
                buttonText = 'Request Proposals'
                if(this.state.projectId) {
                    buttonText = 'Create Job';
                }
            } else {
                buttonText = `Save Changes`
            }
        } else {
            if (isSignUp) {
                buttonText = `Signup & Request Proposals`
            } else {
                buttonText = 'Request Proposals'
            }
        }
        return (
            <button className={`btn btn-nxt ${btnClass ? btnClass : ''}`}
                    onClick={(e) => {
                        e.preventDefault()
                        if ((rehire !== undefined && rehire === TRUE) && professional !== undefined && is_direct === undefined) {
                            $('#open_send_invite_popup').click()
                        } else {
                            if (pathname !== path.manuallyJobCreate) {
                                let toAllProfessional = true
                                if ((rehire !== undefined && rehire === TRUE) && professional !== undefined && is_direct !== undefined && is_direct === TRUE) {
                                    toAllProfessional = false
                                }
                                this.submitRequest({toAllProfessional})
                            } else {
                                this.manuallyAssignJob(e)
                            }
                        }
                    }}
            >{(is_direct !== undefined && is_direct === TRUE) ? `Hire ${name}` : buttonText}
            </button>
        )
    }

    submitRequestUi = (btnClasses) => {
        return (
            <div className="col-sm-12">
                {this.submitRequestButton(btnClasses)}
            </div>
        )
    }

    nextButton = (classes) => {
        const {currentScreen, title} = this.state
        return (
            <button className={`btn btn-nxt pull-right ${classes ? classes : ''}`}
                    onClick={() => {
                        const errors = {...this.state.errors}
                        let request = {}
                        let isFormValidated = false
                        if (currentScreen === current_screen[1]) {
                            request = this.firstScreenRequestObj()
                            isFormValidated = onBoardingFirstScreenValidator({request, errors})
                        } else {
                            request = this.jobSubmitRequestObj()
                            this.dataValidate()
                            isFormValidated = onBoardingValidation(errors, request)
                        }
                        if (isFormValidated) {
                            this.setState({
                                isDescriptionCompleted: true,
                                currentScreen: Number(currentScreen) + 1
                            }, () => {
                            })
                        } else {
                            this.setState({errors}, () => {
                            })
                        }
                        if (title) {
                            $('.selected_services').html(title)
                        }
                        this.showDropdown()
                    }}
            >Next</button>
        )
    }

    progressBarUi = () => {
        const {isDescriptionCompleted, currentScreen, title, questionAnswers} = this.state
        return (
            <div>
                {isDescriptionCompleted && currentScreen > current_screen[1] &&
                <button className="btn btn-prev"
                        onClick={() => {
                            this.setState({currentScreen: Number(currentScreen) - 1}, () => {
                                $('.selected_services').html(title)
                                this.showDropdown()
                            })
                        }}
                >Previous</button>}
                {localStorage.isSessionValid() ? (questionAnswers.length === 0 ? this.submitRequestButton(`btn-block`) :
                        (currentScreen === current_screen[2] ? this.submitRequestButton(`pull-right`) :
                                this.nextButton()
                        )
                ) : (
                    ((currentScreen === current_screen[1]) || (currentScreen === current_screen[2] && questionAnswers.length > 0)) ? this.nextButton(`${currentScreen === current_screen[1] ? 'btn-block' : ''}`) : this.submitRequestButton(`pull-right`)
                )
                }
            </div>
        )
    }

    headerUi = () => {
        const {location, history} = this.props
        let {pathname, search} = location
        return (
            <Fragment>
                {(pathname === path.userOnboarding) &&
                <section className="header page-header-border">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-2">
                                <a onClick={() => {
                                    // search = `${search.indexOf('?') > -1 ? `${search}&cityId=${cityId}`: `${search}?cityId=${cityId}`}`
                                    history.push({
                                        pathname: localStorage.isSessionValid() ? path.userDashboard : path.root,
                                        search
                                    })
                                }}>
                                    <img src={GigzoeLogo}
                                         className="inside-logo"
                                         alt='Logo'
                                    />
                                </a>
                            </div>
                            <LogedinUserUi/>
                        </div>
                    </div>
                </section>}
            </Fragment>
        )
    }

    descriptionUi = () => {
        const {location, is_super_user} = this.props
        let {pathname} = location
        const {errors, description, document_file_name, city, cityId, documentLink, avg_price} = this.state;
        return (
            <form className="signup-form" style={{marginTop: '10px'}}>
                <div className="col-sm-12">
                    <lable
                        style={{
                            fontWeight: 'normal',
                            fontSize: '16px',
                            marginBottom: '0px',
                            fontFamily: 'SofiaLight',
                            color: '#000'
                        }}
                    >Select Service (you can select multiple services)
                    </lable>
                    <div className="dropdown" id="open_dropdown" style={{marginBottom: '40px'}}>
                        <button role="button" data-toggle="dropdown"
                                className="btn btn-default btn-block selected_services" id="dropdown-button">
                            What Service are you looking for ?
                        </button>
                        <ul className="dropdown-menu"
                            // style={{width: '60%'}}
                            id='practise_area_dropdown'
                        >
                            <li><a style={{cursor: 'notAllowed'}}>What Service are you looking for ?</a></li>
                            {this.servicesUi()}
                        </ul>
                        <span
                            className={errors.practise_area ? 'error' : 'no-error'}>{errors.practise_area}</span>
                    </div>
                </div>
                <div className="clearfix"></div>
                <Fragment>
                    <div className="col-sm-12">
                        <div className='form-group'>
                            <label>Select City</label>
                            <select className="form-control input-lg"
                                    name='cityId'
                                    onChange={(e) => {
                                        this.onChangeDropdown(e)
                                    }}
                            >
                                <option disabled selected>Select City</option>
                                {city.map((data, index) => {
                                    const {id, name} = data
                                    return <option value={id} key={id} selected={id === Number(cityId)}>{name}</option>
                                })}
                            </select>
                            <span className={errors.city ? 'error' : 'no-error'}>{errors.city}</span>
                        </div>
                    </div>
                    {pathname !== path.manuallyJobCreate &&
                    <Fragment>
                        <div className="col-sm-12">
                            <div className='form-group'>
                                <select className="form-control input-lg"
                                        name='budget'
                                        onChange={(e) => {
                                            const {name, value} = e.target
                                            const {errors} = this.state
                                            errors[name].message = ''
                                            this.setState({[name]: value, errors})
                                        }}
                                >
                                    <option disabled selected>Budget Range for this Service?</option>
                                    {this.PriceRange()}
                                </select>
                                <span
                                    className={errors.budget.message ? 'error' : 'no-error'}>{errors.budget.message}</span>
                            </div>
                        </div>
                        {is_super_user &&
                        <div className="col-sm-12">
                            <div className="form-group">
                                <input type="text"
                                       className="form-control input-lg"
                                       placeholder="Enter Avg. Price"
                                       name='avg_price'
                                       value={avg_price}
                                       onChange={(e) => {
                                           const {name, value} = e.target
                                           this.setState({[name]: value})
                                       }}
                                />
                            </div>
                        </div>
                        }
                    </Fragment>
                    }
                    <div className="col-sm-12">
                        <div className="form-group description-box" style={{marginBottom: '0px'}}>
                            <label style={{
                                fontWeight: 'normal',
                                fontSize: '16px',
                                marginBottom: '0px',
                                fontFamily: 'SofiaLight',
                                color: '#000'
                            }}>Write a brief description of the current job</label>
                            <textarea rows="4"
                                      style={{marginTop: '0px'}}
                                      id="description"
                                      name='description'
                                      value={description}
                                      maxLength={500}
                                      onChange={this.changeTextTypeInput}
                            >
                            </textarea>
                            <span
                                className={errors.description.message ? 'error' : 'no-error'}>{errors.description.message}</span>
                        </div>
                        <div className="pull-right doc-upload">
                            <p style={{
                                color: '#ff337d',
                                marginTop: (document_file_name ? '10px' : '0px')
                            }}>
                                {documentLink ?
                                    <a style={{color: '#ff337d'}} href={documentLink}
                                       target='_blank'>{document_file_name}</a>
                                    :
                                    <a style={{color: '#ff337d'}}>{document_file_name}</a>
                                }
                            </p>
                            <label className="btn-upload-file btn-bs-file" style={{
                                color: '#ff337d',
                                fontSize: '14px',
                                fontFamily: 'sofiaLight',
                                cursor: 'pointer',
                                borderBottom: '1px solid #ff337d'
                            }}>
                                {`${documentLink ? 'change document' : 'upload document(optional)'}`}
                                <FileBase64
                                    onDone={this.uploadDocument}>
                                </FileBase64>
                            </label>
                            <p className={errors.document_file_name ? 'error' : 'no-error'}>{errors.document_file_name}</p>
                        </div>
                        <div className="clearfix"></div>
                        <p className="description-tips-p">A good description includes:</p>
                        <ul className="description-tips-list">
                            <li>What the scope of work is</li>
                            {/* <li>Type of Expert you're looking for</li> */}
                            <li>Anything unique about the project/work</li>
                        </ul>
                    </div>
                    {pathname === path.manuallyJobCreate && this.manuallyJobCreateUi()}
                </Fragment>
            </form>
        )
    }

    render() {
        const {location, is_super_user} = this.props
        let {pathname} = location
        const {professional, name, city, loading, currentScreen, questionAnswers} = this.state;
        return (
            <div>
                {this.headerUi()}
                <div className="container"
                     style={{marginTop: `${(pathname === path.postAJob || pathname === path.editJob || pathname === path.manuallyJobCreate) ? '40px' : '-20px'}`}}>
                    <div className="row">
                        <div className="col-sm-8 col-sm-offset-2">
                            {(pathname === path.postAJob || pathname === path.userOnboarding || pathname === path.manuallyJobCreate) ?
                                <OnboardingPageHeading currentScreen={currentScreen}
                                                       questionAnswers={questionAnswers}/> :
                                <OnboardingPageHeadingEdit/>}
                        </div>
                        <div className="clearfix"></div>
                        <div className="col-sm-8 col-sm-offset-2 onboarding-container">
                            {loading &&
                            <div className="lazy-loader" style={{zIndex: '1', position: 'fixed'}}><LoadingComponent
                                pastDelay/></div>}
                            {currentScreen === current_screen[1] && this.descriptionUi()}
                            <div className="clearfix"></div>
                            <div className="col-sm-12">
                                <div id="questions">
                                    {(pathname === path.editJob || pathname === path.manuallyJobCreate) ?
                                        (this.jobQuestionsUi()) :
                                        (currentScreen === current_screen[2] && this.jobQuestionsUi())
                                    }
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            {!localStorage.isSessionValid() && (currentScreen === current_screen[3] || (currentScreen === current_screen[2] && questionAnswers.length === 0)) &&
                            <OnboardingSignup ref={this.signUpRef} {...this.props} name={name} city={city}
                                              setLoading={this.setLoading} updateState={this.updateState}
                                              submitRequest={this.submitRequest} isFromJobPost={true}/>}
                            {is_super_user && this.makeLiveUi()}
                        </div>
                        <div className="clearfix"></div>
                        <p>&#x00A0;</p>
                        <div className="col-sm-8 col-sm-offset-2">
                            {(pathname === path.editJob || pathname === path.manuallyJobCreate) && this.submitRequestUi(`btn-block`)}
                            {(pathname !== path.editJob && pathname !== path.manuallyJobCreate) && this.progressBarUi()}
                        </div>
                    </div>
                    {professional && this.rehireUi()}
                    <div className="clearfix"></div>
                    <p>&#x00A0;</p>
                    {pathname === path.userOnboarding && (<Footer/>)}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    client: state.data.client,
    is_super_user: state.data.is_super_user
});

const mapDispatchToProps = (dispatch) => ({
    ...(errorActionDispatchers(dispatch)),
    increaseJobCount: (action) => {
        dispatch(action);
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(OnBoarding)


export const CHECKBOX = 'checkbox';
export const DROPDOWN = 'dropdown';
export const RADIO = 'radio';
export const TEXT = 'text';
const priceRangeData = ['0-2k', '2k-5k', '5k-10k', '10k-20k', '20k-40k', 'Above 40k']

