import React, {Component, Fragment} from "react";
import {errorActionDispatchers} from "../../actions/errors";
import connect from "react-redux/es/connect/connect";
import $ from 'jquery'
import {apiv1, apiv2} from "../../api";
import {onlyZeroToNine, proposalErrorMsg} from "./validation/FormValidator";
import {getSocketRequestData} from "./Message";
import {messageType} from "../../constants/common";
import {roundOffCommissionForNationalAndInternationalAnd} from "../../utils/clientSide";
import * as apiUrl from "../../api/apiUrls"
import localStorage from './../../utils/localStorage'
import {processingCharges} from "../services/DefaultGigDetails";

class PaymentTerms extends Component {
    constructor(props) {
        super(props)
        this.isCommissionCalculated = true
        const {is_super_user, country_code, new_invoicing, hasGst, data, userAndProfessionalData} = props
        this.api = is_super_user ? apiv2 : apiv1
        this.state = {
            fixed_amount: '',
            schedule_unit_proposed: '',
            new_schedule_unit_proposed: '',
            actual_amount_enter_by_professional: '',
            new_actual_amount_enter_by_professional: '',
            updatedSuccessfully: false,
            gstAmount: 0,
            wcFees: 0,
            wcGst: 0,
            totalAmount: 0,
            service_fee: 0,
            data: data,
            hasGst,
            needToCalculateGST: true,
            errors: {},
            isPaymentTermsApiHit: false,
            country_code,
            new_invoicing,
            userAndProfessionalData: userAndProfessionalData || {}
        }
    }

    componentDidMount() {
        const {proposalId} = this.props
        this.getPaymentTerms(proposalId)
    }

    componentWillReceiveProps(nextProps) {
        const {proposalId, jobStatus, is_super_user} = nextProps
        if (nextProps.data && nextProps.data !== this.state.data) {
            this.setState({data: nextProps.data})
        }
        if (jobStatus !== undefined && this.props.proposalId !== proposalId) {
            const {isPaymentTermsApiHit} = this.state
            if (!isPaymentTermsApiHit) {
                this.setState({isPaymentTermsApiHit: true}, () => {
                    this.getPaymentTerms(proposalId)
                })
            }
        }
        if (is_super_user) {
            this.api = is_super_user ? apiv2 : apiv1
        }
    }

    getPaymentTerms = (proposalId) => {
        const url = `/job/proposal-update/${proposalId}/`;
        this.setState({loading: true});
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp);
                if (data) {
                    let {fixed_amount, professional_amount_tax, commission, commission_tax, actual_amount_enter_by_professional, schedule_unit_proposed, service_fee} = data
                    if (!actual_amount_enter_by_professional) {
                        actual_amount_enter_by_professional = fixed_amount
                        professional_amount_tax = 0
                        commission = 0
                        commission_tax = 0
                        service_fee = 0
                    }
                    this.setState({
                        loading: false,
                        schedule_unit_proposed,
                        new_schedule_unit_proposed: schedule_unit_proposed,
                        fixed_amount,
                        actual_amount_enter_by_professional,
                        new_actual_amount_enter_by_professional: actual_amount_enter_by_professional,
                        totalAmount: fixed_amount,
                        gstAmount: professional_amount_tax,
                        wcFees: commission,
                        wcGst: commission_tax,
                        service_fee
                    })
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    };

    calculateCommission = (enteredAmount) => {
        const {country_code, new_invoicing, userAndProfessionalData, hasGst} = this.state
        let {job_details} = userAndProfessionalData
        job_details = job_details || {}
        let {hiring_date} = job_details
        enteredAmount = parseFloat(enteredAmount)
        // hiring_date = moment(hiring_date).format('D MMM YYYY')
        let {commission_tax, price, service_fee} = roundOffCommissionForNationalAndInternationalAnd({
            actual_amount_enter_by_professional: enteredAmount,
            country_code,
            is_round_off_adding_to_wc_gst: true,
            hiring_date,
            is_commission_calculating_in_frontend: true,
            hasGst,
            service_fee: userAndProfessionalData.service_fee
        })
        let professional_amount_tax = 0
        let commission = 0
        if (!new_invoicing) {
            enteredAmount = parseFloat(enteredAmount)
            commission = enteredAmount * 0.1
            commission_tax = commission * .18
            price = enteredAmount + commission + commission_tax
            if (price % 10 !== 0) {

                let round_off_total = Math.ceil(price / 10) * 10
                let amountToSplit = Math.abs(round_off_total - price)
                price = round_off_total

                let wcCut = parseFloat((amountToSplit / 1.18).toFixed(2))
                let wcTaxCut = amountToSplit - wcCut

                commission += wcCut
                commission_tax += wcTaxCut
            }
            commission_tax = parseFloat((commission_tax).toFixed(2))
            commission = parseFloat((commission).toFixed(2))
            price = parseFloat((price).toFixed(2))
        }
        let totalAmount = price
        this.isCommissionCalculated = true
        this.setState({gstAmount: professional_amount_tax, wcFees: commission, wcGst: commission_tax, totalAmount, service_fee})
    }

    onChange = (e) => {
        const {name, value} = e.target
        const {errors} = this.state
        if (name === 'new_actual_amount_enter_by_professional') {
            errors['fixed_amount'] = ''
            if (value && onlyZeroToNine(errors, 'fixed_amount', parseFloat(value), proposalErrorMsg.notValid)) {
                this.calculateCommission(value)
            } else {
                this.setState({[name]: value, errors})
            }
        }
        this.setState({[name]: value, errors})
    }


    updateUi = () => {
        let {new_schedule_unit_proposed, gstAmount, totalAmount, wcFees, wcGst, new_actual_amount_enter_by_professional, errors, service_fee} = this.state;
        service_fee = service_fee || 0
        return (
            <div className="row">
                <div className="col-sm-12" id="custumize_prize">
                    <br/>
                    <div className="row">
                        <div className="col-xs-3">
                            <label>Your Fees</label>
                        </div>
                        <div className="col-xs-4">
                            <div className="form-group">
                                <input type="text"
                                       className="form-control"
                                       id="id_amount"
                                       name='new_actual_amount_enter_by_professional'
                                       value={new_actual_amount_enter_by_professional}
                                       onChange={(e) => this.onChange(e)}
                                />
                                <span
                                    className={errors.fixed_amount ? 'error' : 'no-error'}>{errors.fixed_amount}</span>

                            </div>
                        </div>
                        <div>
                            <div className="clearfix"/>
                            <br/>
                            {gstAmount > 0 &&
                            <Fragment>
                                <div className="col-xs-3">
                                    <label>Your GST</label>
                                </div>
                                <div className="col-xs-4">
                                    <div className="form-group">
                                        <span>${gstAmount}</span>
                                    </div>
                                </div>
                                <div className="clearfix"/>
                                <br/>
                            </Fragment>
                            }
                            <div className="col-xs-3">
                                <label>Workbeam Fees</label>
                            </div>
                            <div className="col-xs-4">
                                <div className="form-group">
                                    <span>${wcFees}</span>
                                </div>
                            </div>
                            <div className="clearfix"/>
                            <br/>
                            <div className="col-xs-3">
                                <label>Workbeam GST</label>
                            </div>
                            <div className="col-xs-4">
                                <div className="form-group">
                                    <span>${wcGst}</span>
                                </div>
                            </div>
                            <div className="clearfix"/>
                            <br/>
                            <div className="col-xs-3">
                                <label> {processingCharges}</label>
                            </div>
                            <div className="col-xs-4">
                                <div className="form-group">
                                    <span>${service_fee}</span>
                                </div>
                            </div>
                            <div className="clearfix"/>
                            <br/>
                            <div className="col-xs-3">
                                <label>Total</label>
                            </div>

                            <div className="col-xs-4">
                                <div className="form-group">
                                    <span>${totalAmount}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="clearfix"/>
                    <br/>
                    <p className="text-bold">Time</p>
                    <div className="row" style={{marginTop: "10px"}}>
                        <div className="col-sm-2">
                                <div className="form-group">
                                <input type="text"
                                       className="form-control"
                                       style={{textAlign: "center"}}
                                       id="time_term"
                                       name='new_schedule_unit_proposed'
                                       value={new_schedule_unit_proposed}
                                       onChange={(e) => this.onChange(e)}
                                />
                                </div>
                            </div>
                            <div className="col-sm-10">
                                <label className="control-label">Days from complete submission of information & documents</label>
                            </div>
                    </div>
                    <div className="clearfix"/>
                    <div className="text-center">
                        <p>&#x00A0;</p>
                        <button type="button"
                                className="btn btn-update"
                                id="update_amount"
                                disabled={!this.isCommissionCalculated}
                                onClick={this.updatePaymentTerms}
                        >UPDATE
                        </button>

                        <p>&#x00A0;</p>
                    </div>
                </div>
            </div>
        )
    }

    sendSocketDataViaRoomId = ({data}) => {
        const {type, message} = data
        const {chatRoomId} = this.props
        const request = {
            room: chatRoomId,
            chat: {
                message,
                message_type: type
            }
        }
        apiv1.auth.post(apiUrl.insertSocketData, request)
            .then(resp => {

            })
            .catch(err => {
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    updatePaymentTerms = () => {
        const {new_schedule_unit_proposed, schedule_unit_proposed, fixed_amount, totalAmount, wcFees, wcGst, gstAmount, new_actual_amount_enter_by_professional, actual_amount_enter_by_professional, service_fee} = this.state;
        const {proposalId} = this.props
        const url = `/job/proposal-update/${proposalId}/`
        if (Number(actual_amount_enter_by_professional) !== Number(new_actual_amount_enter_by_professional) || Number(new_schedule_unit_proposed) !== Number(schedule_unit_proposed)) {
            let request = {
                fixed_amount: totalAmount,
                schedule_unit_proposed: new_schedule_unit_proposed,
                actual_amount_enter_by_professional: new_actual_amount_enter_by_professional,
                commission: wcFees,
                commission_tax: wcGst,
                professional_amount_tax: gstAmount,
            }
            if (service_fee){
                request = {
                    ...request,
                    service_fee
                }
            }
            apiv1.auth.patch(url, request)
                .then(resp => {
                    const data = apiv1.parseAndAutoHandleIssues(resp);
                    if (data) {
                        const {updateProposalAmountOnChat, socket} = this.props
                        if (updateProposalAmountOnChat) {
                            updateProposalAmountOnChat(request)
                        }
                        let message = "Payment terms have been updated." + '\n';
                        if (totalAmount !== fixed_amount) {
                            message = message + '\n' + 'Amount : ' + '$' + data.fixed_amount
                        }
                        if (new_schedule_unit_proposed !== schedule_unit_proposed) {
                            message = message + '\n' + 'Completion time : ' + schedule_unit_proposed + ' days from complete submission of documents and information by client'
                        }
                        const messageToSend = getSocketRequestData({message, type: messageType.chat_message})
                        $('.modal-header .close').click()
                        if (socket && socket.isReady()) {
                            socket.socket.send(JSON.stringify(messageToSend))
                        } else {
                            this.sendSocketDataViaRoomId({data: messageToSend})
                        }

                    } else {
                        this.setState({loading: false})
                    }
                })
                .catch(err => {
                    this.setState({loading: false});
                    apiv1.handleErrors(err, this.props.doDispatch)
                })

        } else {
            alert('already updated')
        }
    };
    updatedSuccessfullyUi = () => {
        return (
            <div className="msg msg-success msg-success-text">
                <span className="glyphicon glyphicon-ok"/> Success
            </div>
        )
    };

    render() {
        const {updatedSuccessfully} = this.state;
        return (
            <div className="modal-dialog dialog-top-margin">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button"
                                className="close"
                                data-dismiss="modal"
                                onClick={() => {
                                    const {schedule_unit_proposed, new_schedule_unit_proposed, actual_amount_enter_by_professional, new_actual_amount_enter_by_professional} = this.state
                                    if (schedule_unit_proposed !== new_schedule_unit_proposed || actual_amount_enter_by_professional !== new_actual_amount_enter_by_professional) {
                                        const {proposalId, jobStatus} = this.props
                                        if (jobStatus === undefined) {
                                            this.getPaymentTerms(proposalId)
                                        }
                                    }
                                }}
                        >&times;
                        </button>
                        <h4 className="modal-title">Payments Terms</h4>
                    </div>
                    <div className="modal-body hire-summary">
                        {updatedSuccessfully && this.updatedSuccessfullyUi()}
                        <form>
                            {this.updateUi()}
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    data: state.data,
    is_super_user: state.data.is_super_user !== undefined ? state.data.is_super_user : '',
    hasGst: !!(JSON.parse(localStorage.logedInUserDetails).pro_gst_number)
});

const mapDispatchToProps = (dispatch) => ({
    ...(errorActionDispatchers(dispatch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTerms)