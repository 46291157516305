import React, {Component} from "react";
import {errorActionDispatchers} from "../../actions/errors";
import connect from "react-redux/es/connect/connect";
import {apiv1} from "../../api";
import {getAvatar, getFirstAndLastLetterOfName} from "../common/userAndProfessionalProfileFunctions";
import Moment from 'react-moment';
import localStorage from "../../utils/localStorage"
import {PROFESSIONAL, USER} from "../../constants/auth";
import JobPaymentSummary from '../common/JobPaymentSummary'
import JobInProgressDetailsPageHeaderLink from '../common/JobInProgressDetailsPageHeaderLink'
import LoadingComponent from "../../utils/LoadingComponent";
// import * as path from "../../constants/path";
import {Description} from "./ProfessionalJobFeed";
import {assignTaskToThisProfessional} from './../../constants/user'
import {mainDomain} from "../../constants/common";
import {freelancers} from "../../constants/keyWord";

// export const tabsOnHiredPage = (proposalId, jobId, userId, tabNo = 1) => {
//     return (
//         <div>
//             <li className={"margin-left" + tabNo === 1 ? ' active' : ''}>
//                 <Link
//                     className="hired-professional"
//                     to={`/job-details/${proposalId}/${jobId}/${userId}/${true}`}>
//                     CONTRACT DETAILS
//                 </Link>
//             </li>
//             <li className={"margin-left" + tabNo === 2 ? ' active' : ''}>
//                 <Link
//                     className="hired-professional"
//                     to={`/chat-to-professional-after-hire/${proposalId}/${jobId}/${userId}/${true}`}>
//                     MESSAGES
//                 </Link>
//             </li>
//             <li className="margin-left">
//                 <Link
//                     className={"hired-professional" + tabNo === 3 ? ' active' : ''}
//                     to={`/job-document/${proposalId}/${jobId}/${userId}/${true}`}>
//                     DOCUMNET
//                 </Link>
//             </li>
//             <li className="margin-left">
//                 <Link
//                     className={"hired-professional" + tabNo === 4 ? ' active' : ''}
//                     to={`/job-track/${proposalId}/${jobId}/${userId}/${true}`}>
//                     TRACK
//                 </Link>
//             </li>
//         </div>
//     )
// };

export function InstallAppText() {
    return (
        <div className='side-box-container'>
            {/* <p className="heading-text">Workbeam Protection</p>
            <div>
                <img src={require("../../static/images/shield.png").default} className="lock-verified-icon"
                     style={{width: '25px'}} alt="signup"/>
                <img src={require("../../static/images/lock.png").default} className="lock-verified-icon"
                     style={{width: '20px'}} alt="signup"/>
            </div>
            <p className='text-note' style={{color: '#000', marginTop: '15px'}}>Workbeam payment protection &
                acknowledgement is applicable only when you use Workbeam App for communicating and sharing files.</p> */}
        </div>
    )
}

export function UserAndProfessionalCardOnJobInProgress({userAndProfessionalData, firstName, lastName, avatar}) {
    const {Professional_details, job_details} = userAndProfessionalData || {}
    const {slug, slug_new, bio_caption} = Professional_details || {}
    const {id} = Professional_details
    const {title} = job_details || {}
    const {professionalId} = assignTaskToThisProfessional
    return (
        <div>
            {
                localStorage.userType === USER ?
                    (
                        <div>
                            <p className="heading-text">Workbeam Expert</p>
                            <div className="ui-img-container">
                                <div className="media">
                                    <div className="media-left">
                                        <a href={professionalId !== id ? `${mainDomain}/${freelancers}/${slug}` : null}
                                           target='_blank'>
                                            {avatar ? (
                                                    <img src={avatar} alt="user" className="img-circle"/>)
                                                :
                                                getFirstAndLastLetterOfName(firstName, lastName)
                                            }
                                        </a>

                                    </div>
                                    <div className="media-body">
                                        <a href={professionalId !== id ? `${mainDomain}/${freelancers}/${slug}` : null}
                                           target='_blank'>
                                            <p className="media-heading">{firstName + " " + lastName}</p>
                                        </a>
                                        <p className="text-title">{bio_caption}</p>
                                    </div>
                                </div>

                                {/*<div className="text-center">*/}
                                {/*{professionalId !== id &&*/}
                                {/*<Link*/}
                                {/*to={`${path.postAJob}?rehire=true&professional=${id}&name=${firstName}`}*/}
                                {/*className="btn btn-pink">*/}
                                {/*Rehire*/}
                                {/*</Link>*/}
                                {/*}*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    )
                    :
                    (<div>
                        <p className="heading-text">{ localStorage.userType === PROFESSIONAL ? 'Client': 'Service Provider'}</p>
                        <div className="ui-img-container">
                            <div className="media">
                                <div className="media-left">
                                    {avatar ? (<img src={avatar} alt="user" className="img-circle"/>)
                                        :
                                        getFirstAndLastLetterOfName(firstName, lastName)

                                    }
                                </div>
                                <div className="media-body">
                                    <p className="media-heading">{firstName + " " + lastName}</p>
                                    { localStorage.userType !== PROFESSIONAL ? <p className="text-title">{title}</p> : '' }
                                </div>
                            </div>
                        </div>
                    </div>)

            }
        </div>
    )
}


class ProfessionalJobDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            jobDetails: {},
            userAndProfessionalData: {},
            userData: {},
            loading: false,
            jobStatus: '',
            jobTitle: '',
            productId: ''
        }

    }

    componentDidMount() {

        this.getJobDetails()
        this.getJobQuestionsOfJob()
        // const url = `/job/proposal-professional-details/${proposalId}/`;
        // this.setState({loading: true});
        // apiv1.auth.get(url)
        //     .then(resp => {
        //         const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
        //         if (data) {
        //             this.setState({
        //                 loading: false,
        //                 jobDetails: data,
        //                 jobStatus: data.job_details.status,
        //                 jobTitle: data.job_details.title
        //             })
        //         } else {
        //             this.setState({loading: false})
        //         }
        //     })
        //     .catch(err => {
        //         this.setState({loading: false});
        //         apiv1.handleErrors(err, this.props.doDispatch)
        //     });
    }

    getJobQuestionsOfJob = () => {
        const {jobId} = this.props.match.params;
        const url = `/job/job-details-hiring-summary/${jobId}/`;
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    const {job_question, title} = data
                    let quantity_count = title.split(':')
                    quantity_count = isNaN(quantity_count[quantity_count.length - 1]) ? 1 : quantity_count[quantity_count.length - 1]
                    const userAndProfessionalData = {
                        ...this.state.userAndProfessionalData,
                        job_question_detail: job_question,
                        quantity_count
                    }
                    this.setState({userAndProfessionalData})
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.data.client.id !== undefined) {
            this.setState({userData: nextProps.data})
        }
    }

    updateAmountOnJobPaymentSummary = (updatedPaymentTerms) => {
        const {userAndProfessionalData} = this.state
        const updatedJobDetails = Object.assign({}, userAndProfessionalData, updatedPaymentTerms)
        this.setState({userAndProfessionalData: updatedJobDetails})
    }

    getJobDetails = (e) => {
        const {jobId, proposalId} = this.props.match.params;
        const url = `/job/proposal-user-professional/${proposalId}/`
        this.setState({loading: true})
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    const {job_details} = data
                    // let userAndProfessionalData = {...this.state.userAndProfessionalData}
                    const {product} = job_details
                    let userAndProfessionalData = {
                        ...this.state.userAndProfessionalData,
                        ...data
                    }
                    this.setState({
                        loading: false,
                        userAndProfessionalData,
                        jobDetails: job_details,
                        jobStatus: job_details.status,
                        jobTitle: job_details.title,
                        productId: product
                    })

                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    };


    render() {
        const {jobId, proposalId, userId, chatRoomId} = this.props.match.params;
        const {userAndProfessionalData, loading, jobStatus, jobTitle, jobDetails, productId} = this.state
        const {Professional_details, user_details, job_details, quantity_count} = userAndProfessionalData || {}
        let avatar = '';
        let firstName = '';
        let lastName = '';
        if (localStorage.userType === USER && Professional_details) {
            avatar = getAvatar(Professional_details.avatar_thumbnail);
            firstName = Professional_details.first_name;
            lastName = Professional_details.last_name
        } else if (localStorage.userType === PROFESSIONAL && Professional_details) {
            avatar = getAvatar(userAndProfessionalData.user_details.client.avatar_thumbnail);
            firstName = user_details.first_name;
            lastName = user_details.last_name
        }
        if (loading) return <div className='lazy-loader' style={{position: 'fixed', zIndex: '100'}}><LoadingComponent
            pastDelay/></div>

        return (
            <div id="PROGRESS_JOB">
                <div id="progress_list_case" className="ui-progress-job">

                    <div className="col-sm-9  job_title">
                        <div className="row">

                            {jobStatus && <JobInProgressDetailsPageHeaderLink jobId={jobId}
                                                                              proposalId={proposalId}
                                                                              userId={userId}
                                                                              chatRoomId={chatRoomId}
                                                                              pageName="details"
                                                                              props={this.props}
                                                                              jobStatus={jobStatus}
                                                                              jobTitle={jobTitle}
                                                                              productId={productId}
                                                                              updateAmountOnJobPaymentSummary={this.updateAmountOnJobPaymentSummary}
                                                                              userAndProfessionalData={userAndProfessionalData}
                            />
                            }
                        </div>
                        <div className="row scroll box-shadow"
                             style={{backgroundColor: '#fff', minHeight: '580px', borderRadius: '6px'}}>
                            <div className="col-sm-12">
                                {jobDetails.title !== undefined && (
                                    <div className="panel-body">
                                        <p className="text-title">Order Name</p>
                                        <p className="text-title-para">{jobDetails.title}</p>
                                        <p className="text-title">Quantities</p>
                                        <p className="text-title-para">{quantity_count}</p>
                                        <p className="text-title">Booking Date</p>
                                        <p className="text-title-para">
                                            <Moment format="D MMM YYYY" withTitle>
                                                {jobDetails.hiring_date}
                                            </Moment>
                                        </p>
                                        <p className="text-title">Fees</p>
                                        <p className="text-title-para">$ { localStorage?.userType == PROFESSIONAL ? parseFloat(userAndProfessionalData?.actual_amount_enter_by_professional): parseFloat(userAndProfessionalData.fixed_amount)}</p>
                                        <p className="text-title">Completion Time</p>
                                        <p className="text-title-para">{userAndProfessionalData.schedule_unit_proposed} days
                                            from submission of information by client</p>
                                        <p className="text-title">Job Description</p>
                                        <p className="text-title-para">
                                            <Description description={jobDetails.description} desLength={10000}/>

                                            {/*<Interweave*/}
                                            {/*tagName="span"*/}
                                            {/*content={jobDetails.description}/>*/}
                                        </p>
                                        {loading &&
                                        <div className="lazy-loader"
                                        >
                                            <LoadingComponent pastDelay/></div>}
                                        {userAndProfessionalData.job_question_detail && userAndProfessionalData.job_question_detail.length > 0 &&
                                        <h4 className="text-dark">Question answered</h4>}
                                        {userAndProfessionalData.job_question_detail && userAndProfessionalData.job_question_detail.map((question, i) => {
                                            const {answer, practise_area_question_details} = question
                                            const {text} = practise_area_question_details
                                            return (<div key={i}>
                                                <p className="text-bold">{text}</p>
                                                <p className="text-title-para">
                                                    {answer.map((data, answerIndex) => {
                                                        return <div><p
                                                            className="text-title-para">{data}</p></div>
                                                    })}
                                                </p>
                                            </div>)
                                        })}
                                    </div>
                                )}
                            </div>


                        </div>
                    </div>
                    <div className="col-sm-3 no-padding d-padding-r">
                        {jobDetails.title !== undefined && <JobPaymentSummary jobId={jobId} proposalId={proposalId}
                                                                              totalAmount={userAndProfessionalData.fixed_amount}
                                                                              jobStatus={jobDetails.status}
                                                                              proposalSender={userAndProfessionalData.sender}
                                                                              wcFees={userAndProfessionalData.commission}
                                                                              wcGst={userAndProfessionalData.commission_tax}
                                                                              gstAmount={userAndProfessionalData.professional_amount_tax ? userAndProfessionalData.professional_amount_tax : 0}
                                                                              actual_amount_enter_by_professional={userAndProfessionalData.actual_amount_enter_by_professional}
                                                                              userAndProfessionalData={userAndProfessionalData}

                        />}
                        <div className="side-box-container">
                            {userAndProfessionalData.sender !== undefined &&
                            <UserAndProfessionalCardOnJobInProgress userAndProfessionalData={userAndProfessionalData}
                                                                    firstName={firstName} lastName={lastName}
                                                                    avatar={avatar}/>}
                        </div>
                        {/* <InstallAppText/> */}
                    </div>
                </div>
                <p id="update_profile_msg" data-toggle="modal" data-target="#consultation_fee"></p>
            </div>


        )
    }
}


const mapStateToProps = state => ({
    data: state.data,
});

const mapDispatchToProps = (dispatch) => ({
    ...(errorActionDispatchers(dispatch)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProfessionalJobDetails)