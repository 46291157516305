import React, { Component, Fragment } from "react";
import profileNotFill from "../../static/images/profile-not-ok.png";
import profileFill from "../../static/images/profile-ok.png";
import documentImage from "../../static/images/upload-file.png";
import successImage from "../../static/images/success.png";
import FileBase64 from "react-file-base64";
import { connect } from "react-redux";
import Select from "react-select";
import $ from "jquery";
import Tooltip from "@material-ui/core/Tooltip";
import { ResumeEditorComponent } from "./ResumeEditorComponent";
import {
  isPhoneValid,
  onlyZeroToNine,
  validateProfile,
  validateProfileVerificationQAndA,
} from "../common/validation/FormValidator";
import { idEducationValid } from "../common/validation/educationValidator";
import { apiv1, apiv2 } from "../../api";
import { updateProfilePic, userDetails } from "../../actions/auth";
import LoadingComponent from "../../utils/LoadingComponent";
import {
  updateProfessionalPhone,
  updateProfessionalKey,
} from "./../../actions/auth";
import { updateTrainingAndQuestion } from "./../../actions/user_profile_actions";
import gstDocument from "./../../static/downloadableDocuments/Declaration_of_GST.docx";
// import ProfessionalPaymentDetails from './ProfessionalPaymentDetails'
import {
  getAvatar,
  getFirstAndLastLetterOfName,
  getPhoneNo,
} from "../common/userAndProfessionalProfileFunctions";
import axios from "axios";
import {
  experienceFieldValidator,
  reviewValidator,
} from "./../common/validation/educationValidator";
import { businessDetailsValidator } from "./../common/validation/businessDetailsValidation";
// import firebase from "firebase";
import * as path from "../../constants/path";
import { Link } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Switch from '@material-ui/core/Switch';
import { professionalStatus } from "../../constants/job";
import { getState } from "../../actions/geo";
import {
  designer,
  getProfessionalType,
  legalAndAccounting,
  professionalType as professional_type,
  isContentWriter as _isContentWriter,
  isWebDeveloper as _isWebDeveloper,
} from "./../../constants/businessPractiseArea";
import * as apiUrl from "../../api/apiUrls";
import localStorage from "./../../utils/localStorage";
import { getProfessions } from "../../actions/professions";
import {
  indiaCountryCodeCode,
  mainDomain,
  may20_2020,
  professionalProfileStage,
  skillsLevel,
} from "./../../constants/common";
import { showSnackbar } from "./../functional/afterHire";
import moment from "moment";
import { ProgressBar } from "./ProfessionalProfileProgressBar";
import ProfessionalBriefBio, {
  ClientReviewsTips,
  VimeoExampleVideo,
} from "./ProfessionalBriefBio";
import queryString from "query-string";
import PhoneInputWithCountrySelect, {
  parsePhoneNumber,
} from "react-phone-number-input";
import { indiaCallingCode } from "./../../constants/common";
import { _text, multi, single, yesno } from "./CreateDefaultGig";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import ProfessionalPaymentDetails from "./ProfessionalPaymentDetails";
import { freelancers } from "../../constants/keyWord";
import ExpertStandardGigList from "./ExpertStandardGigList";
import tips from "../../static/images/tips.png";
import graphLine from "../../static/images/graph-line.png";

// const hourlyPrice = Array(5).fill().map((e, i) => i*10 +1).concat(Array(19).fill().map((e, i) => (i+1)*50 +1))
export const hourlyPrice = Array(5)
  .fill()
  .map((e, i) => (i + 1) * 10)
  .concat(
    Array(19)
      .fill()
      .map((e, i) => (i + 2) * 50)
  )
  .concat(
    Array(5)
      .fill()
      .map((e, i) => (i + 1) * 200 + 1000)
  )
  .concat(
    Array(8)
      .fill()
      .map((e, i) => (i + 1) * 1000 + 2000)
  );

export const pricePerWord = [
  {
    text: "0.40 paisa - 0.80 paisa",
    value: "0.80",
  },
  {
    text: "0.80 paisa - $1.20",
    value: "1.20",
  },
  {
    text: "$1.20 - $1.50",
    value: "1.50",
  },
  {
    text: "$1.50 - $2",
    value: "2.00",
  },
  {
    text: "$2 - $3",
    value: "3.00",
  },
  {
    text: "$3 - $4",
    value: "4.00",
  },
  {
    text: "$4 - $5",
    value: "5.00",
  },
  {
    text: "$5 - $6",
    value: "6.00",
  },
  {
    text: "$6 - $8",
    value: "8.00",
  },
  {
    text: "Above Rs 8",
    value: "10.00",
  },
];

export const fromYear = Array(60)
  .fill()
  .map((e, i) => i + 1970);
export const toYear = Array(60)
  .fill()
  .map((e, i) => i + 1970);

function _isInTheWaitingList({ created, professionalType, verified }) {
  return (
    professionalType === professional_type.legalAndAccounting &&
    !verified &&
    created &&
    moment(created) > may20_2020
  );
}

function calculatePercentageProfile({
  professional,
  basicDetails,
  verificationDocuments = [],
}) {
  let { profile_completion } = professional;
  return profile_completion || 0;
}

export function ProfileHelpCenter() {
  return (
    <div className="d-update-profile">
      <div className="d-update-profile-body">
        <div className="row">
          <div className="col-sm-12">
            <h4 className="d-profile-heading">HelpCentre</h4>
            <p className="para">
              Read all FAQs, articles and see all video tutorials.
            </p>
            <a
              className="btn btn-save-profile"
              href={`${mainDomain}${path.helpCenter}`}
              target="_blank"
            >
              Help center
            </a>
          </div>
        </div>
        <p>&#x00A0;</p>
      </div>
      <div className="d-update-profile-body">
        <div className="row">
          <div className="col-sm-12">
            <h4 className="d-profile-heading">Slack Community</h4>
            <p className="para">
              Join our Slack Community of Seller Partners to interact with them
              and ask questions.{" "}
            </p>
            <a
              className="btn btn-save-profile"
              href={`https://join.slack.com/t/gigzoesellers/shared_invite/zt-in22nep3-mMz3GcMIGxuzBxrR88Nr~w`}
              target="_blank"
            >
              Join Slack
            </a>
          </div>
        </div>
        <p>&#x00A0;</p>
      </div>
    </div>
  );
}

export function ProfessionalSignupIntro({ history }) {
  return (
    <div id="signup_intro_popup" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => history.replace(path.professionalProfile)}
            >
              &times;
            </button>
            <h4 className="modal-title">
              Profile Verification & Approval Process
            </h4>
            <p
              className="para"
              style={{
                paddingLeft: "20px",
                marginBottom: "0px",
                lineHeight: "1.4em",
                marginTop: "10px",
              }}
            >
              Please fill the profile and create gigs in the best possible way
              and as per the guidelines.
            </p>
          </div>
          <div className="modal-body profile-approval-modal">
            <table className="table" style={{ marginBottom: "0px" }}>
              <tbody>
                <tr>
                  <td>
                    <img
                      src={
                        require("../../static/images/complete-icon.png").default
                      }
                      alt="icon"
                    />
                  </td>
                  <td>
                    Complete your profile and create Gigs as per guidelines and
                    then submit for approval.
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={
                        require("../../static/images/resume-icon.png").default
                      }
                      alt="icon"
                    />
                  </td>
                  <td>
                    Go through our training videos and standard operating
                    procedure and fill a questionnaire
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={
                        require("../../static/images/question-icon.png").default
                      }
                      alt="icon"
                    />
                  </td>
                  <td>
                    After questionnaire submission, we do a 30-minute call to
                    know you better.
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={require("../../static/images/flag.png").default}
                      alt="icon"
                    />
                  </td>
                  <td>
                    We approve you to be our partner and sell on Workbeam.
                  </td>
                </tr>
              </tbody>
            </table>
            <p
              className="para"
              style={{
                lineHeight: "1.4em",
                marginTop: "10px",
                paddingLeft: "20px",
              }}
            >
              <b>
                To provide high quality, secured and an assured experience to
                all customers, admission to Workbeam is competitive & approval
                based.
              </b>
            </p>
          </div>
          <div className="modal-footer" style={{ textAlign: "center" }}>
            <button
              type="button"
              className="btn btn-save-profile"
              data-dismiss="modal"
            >
              Start my Profile
            </button>
            <p>&#x00A0;</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function trainingQuestionAnsMapped(qAndAns) {
  let mappedWithAlphabet = new Map();
  qAndAns.forEach((details) => {
    let { category } = details;
    category = category || "";
    if (mappedWithAlphabet.get(category)) {
      let { answers } = mappedWithAlphabet.get(category);
      answers.push({ ...details });
    } else {
      let answers = [{ ...details }];
      mappedWithAlphabet.set(category, { answers });
    }
  });
  return mappedWithAlphabet;
}

class ProfessionalProfile extends Component {
  constructor(props) {
    super(props);
    let { data, geoState, professions, professional, history } = props;
    const { location } = history;
    const { search } = location;
    let { tab, skill_tab } = queryString.parse(search);
    data = data || {};
    let { basicDetails, client } = data;
    client = client || {};
    let { created } = client;
    professional = professional || {};
    let {
      education_details,
      address,
      company_name,
      price_per_hour,
      price_per_word,
      company_type,
      membership_id,
      gst_number,
      pan_number,
      court,
      professional_reviews,
      bank_account,
      status,
      profession,
      language,
      number_of_employee,
      has_submitted_for_review,
      training_question_answer_details,
      verified,
      is_agency,
      is_part_time,
      metadata,
    } = professional;
    metadata = metadata || {};
    let metaLanguage = metadata.language || [];
    let metaSkills = metadata.skills || [];
    let isContentWriter = _isContentWriter({ profession });
    let isWebDeveloper = _isWebDeveloper({ profession });
    let is_training_question_answer_details_null =
      training_question_answer_details === null;
    training_question_answer_details = training_question_answer_details || {};
    let { answers } = training_question_answer_details;
    if (price_per_hour) {
      price_per_hour = parseInt(price_per_hour);
    }
    answers = answers || [];
    let copyOfTrainingQAndAns = trainingQuestionAnsMapped(answers);
    training_question_answer_details = {
      is_training_question_answer_details_null,
      ...training_question_answer_details,
      answers,
      copyOfTrainingQAndAns,
    };
    number_of_employee = number_of_employee || "";
    language = language || [];
    profession = profession || [];
    let professionalType = getProfessionalType({ profession });
    let isInTheWaitingList = _isInTheWaitingList({
      created,
      professionalType,
      verified,
    });
    let { userProfessions, userProfessionsIds } =
      this.getUserSelectedProfession(professions, data);
    let profilePercentage = calculatePercentageProfile({
      professional,
      basicDetails,
      verificationDocuments: [],
    });
    let isProfileComplete =
      verified || has_submitted_for_review
        ? ""
        : this.checkProfileCompletion({
            professional,
            basicDetails,
          });
    address = address || {};
    let { city_data, state_data } = address;
    city_data = city_data || {};
    state_data = state_data || {};
    this.api = props.is_super_user ? apiv2 : apiv1;
    this.state = {
      errors: {},
      data: JSON.parse(JSON.stringify(data)),
      basicDetails: this.basicDetails({ ...basicDetails }, { ...professional }),
      otp: "",
      work_experience: this.workExperience(data),
      savedEducationList: education_details ? education_details : [],
      newEdu: this.eduObj(),
      practiceArea: [],
      practiceAreaIds: this.getPracticeAreaIds(data),
      bio: this.bioObj(data),
      consultationFee: this.consultationFeeObj(data),
      address: this.workAddressObj(data),
      loading: false,
      isOtpSent: false,
      isEmailVerificationSent: false,
      updatedSuccessfully: false,
      geoState,
      city: [],
      city_data,
      state_data,
      userDocuments: [],
      userCertificates: [{ ...this.addMoreCertificateObj() }],
      savedUserCertificates: [],
      // loader: true,
      professions,
      userProfessions,
      company_name: company_name ? company_name : "",
      company_type: company_type ? company_type : "",
      price_per_hour,
      price_per_word: price_per_word ? price_per_word : "",
      number_of_employee,
      membership_id: membership_id ? membership_id : "",
      userProfessionsIds,
      isAddMoreDisabled: false,
      gst_number: gst_number ? gst_number : "",
      pan_number: pan_number ? pan_number : "",
      allIndianCourts: [],
      stateWiseCourts: [],
      selectedCourtIds: court ? court : [],
      collegeList: [],
      newClientReviewList: [],
      savedClientReviewList: professional_reviews ? professional_reviews : [],
      newReview: this.reviewObj(),
      isEditReviewEnable: false,
      indexOfReview: 0,
      bankAccount: bank_account ? bank_account : {},
      selectedStateInCourt: "State",
      isAvailable: status === professionalStatus.active,
      professionalType,
      language,
      languages: [],
      metaLanguage,
      metaSkills,
      selected_meta_language: "",
      selected_meta_language_level: "",
      selectedId: "",
      isShowEducationUi: false,
      skillsProfession: [],
      selected_profession: "",
      has_submitted_for_review,
      isProfileComplete,
      isInTheWaitingList,
      profilePercentage,
      training_question_answer_details,
      is_agency,
      is_part_time,
      isContentWriter,
      metadata,
      skillsObj: {},
      isWebDeveloper,
      tab,
      skill_tab,
      active_tab: 2,
      // selectedEducationIds: []
    };
  }

  componentDidMount() {
    const { geoState, state_data, professions } = this.state;
    const { is_super_user, history } = this.props;
    const { location } = history;
    const { search } = location;
    let { signUp } = queryString.parse(search);
    if (signUp) {
      $("#signup_intro").click();
    }
    if (geoState.length === 0) {
      this.getState();
    }
    if (professions.length === 0 || is_super_user) {
      this.getProfessions();
    }
    if (is_super_user) {
      this.getUserDocument();
    }
    // this.getCollegeList()
    if (state_data.id) {
      this.getCity(state_data.id, false);
    }
    this.getLanguages();
    // this.getUploadedCertificates()
  }

  componentWillReceiveProps(nextProps) {
    let { geoState, data, is_super_user, history, professions } = nextProps;
    professions = professions ? professions : [];
    let { basicDetails, professional, client } = data;
    let professionalId = "";
    if (!professional) {
      data["professional"] = {};
    } else {
      professionalId = professional.id;
    }
    if (professionalId) {
      let {
        education_details,
        address,
        company_name,
        company_type,
        price_per_hour,
        price_per_word,
        membership_id,
        gst_number,
        pan_number,
        court,
        professional_reviews,
        bank_account,
        status,
        number_of_employee,
        has_submitted_for_review,
        verified,
        training_question_answer_details,
        is_agency,
        is_part_time,
        metadata,
      } = professional;
      metadata = metadata || {};
      let metaLanguage = metadata.language || [];
      let metaSkills = metadata.skills || [];
      if (price_per_hour) {
        price_per_hour = parseInt(price_per_hour);
      }
      let is_training_question_answer_details_null =
        training_question_answer_details === null;
      training_question_answer_details = training_question_answer_details || {};
      let { answers } = training_question_answer_details;
      answers = answers || [];
      let copyOfTrainingQAndAns = trainingQuestionAnsMapped(answers);
      training_question_answer_details = {
        is_training_question_answer_details_null,
        ...training_question_answer_details,
        answers,
        copyOfTrainingQAndAns,
      };
      const { created } = client;
      address = address || this.workAddressObj(data);
      let { city_data, state_data } = address;
      city_data = city_data || {};
      state_data = state_data || {};
      number_of_employee = number_of_employee || "";
      basicDetails = this.basicDetails(basicDetails, professional);
      const bio = this.bioObj(data);
      const consultationFee = this.consultationFeeObj(data);
      const practiceAreaIds = this.getPracticeAreaIds(data);
      const work_experience = this.workExperience(data);
      const savedEducationList = education_details ? education_details : [];
      const savedClientReviewList = professional_reviews
        ? professional_reviews
        : [];
      const isAvailable = status === professionalStatus.active;
      const selectedCourtIds = court ? court : [];
      let isProfileComplete =
        verified || has_submitted_for_review
          ? ""
          : this.checkProfileCompletion({
              professional,
              basicDetails,
            });
      if (this.state.city.length === 0 && state_data.id !== undefined) {
        this.getCity(state_data.id);
      }
      const appData = JSON.parse(JSON.stringify(data));
      const { userDocuments } = this.state;
      let profilePercentage = calculatePercentageProfile({
        professional,
        basicDetails,
        verificationDocuments: userDocuments,
      });
      this.setState({
        data: appData,
        basicDetails,
        bio,
        consultationFee,
        practiceAreaIds,
        work_experience,
        savedEducationList,
        address,
        membership_id,
        company_type,
        company_name,
        price_per_hour,
        price_per_word,
        number_of_employee,
        gst_number: gst_number ? gst_number : "",
        pan_number: pan_number ? pan_number : "",
        state_data,
        city_data,
        selectedCourtIds,
        savedClientReviewList,
        bankAccount: bank_account ? bank_account : {},
        isAvailable,
        has_submitted_for_review,
        isProfileComplete,
        profilePercentage,
        training_question_answer_details,
        is_agency,
        is_part_time,
        metaLanguage,
        metaSkills,
        metadata,
      });
      if (professions.length > 0) {
        let { userProfessions, userProfessionsIds } =
          this.getUserSelectedProfession(professions, data);
        let professionalType = getProfessionalType({
          profession: userProfessionsIds,
        });
        let isInTheWaitingList = _isInTheWaitingList({
          created,
          professionalType,
          verified,
        });
        this.setState({
          userProfessions,
          userProfessionsIds,
          professions,
          professionalType,
          isInTheWaitingList,
        });
      }
      const { languages } = this.state;
      if (languages.length > 0) {
        const { language } = this.getSelectedLanguages({
          languages,
          language: [...professional.language],
        });
        this.setState({ language });
      }
    } else if (is_super_user) {
      history.push(path.adminProfessionalList);
      alert("You can not refresh this page");
    }
    if (geoState.length > 0) {
      this.setState({ geoState });
    }
    if (is_super_user) {
      this.api = is_super_user ? apiv2 : apiv1;
    }
  }

  getSerialisedProfessions = (professions) => {
    const { professionalType } = this.state;
    let isTaxLegal = professionalType === professional_type.legalAndAccounting;
    professions = professions.filter(
      (details) => details.is_tax_legal === isTaxLegal
    );
    const serializedProfession = [];
    for (let i = 0; i < professions.length; i++) {
      const { name, id } = professions[i];
      serializedProfession.push({
        value: name,
        label: name,
        id,
      });
    }
    return serializedProfession;
  };

  getQuestionnaireQuestion = () => {
    let { data } = this.state;
    let { professional } = data;
    let { profession } = professional;
    this.api.auth
      .get(`${apiUrl.getTrainingQuestion}?profession=${profession[0]}`)
      .then((resp) => {
        const data = apiv1.parseAndAutoHandleIssues(
          resp,
          this.props.doDispatch
        );
        if (data) {
          let training_question_answer_details = {
            answers: data,
            copyOfTrainingQAndAns: trainingQuestionAnsMapped(data),
          };
          this.setState({ training_question_answer_details });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        apiv1.handleErrors(err, this.props.doDispatch);
      });
  };

  onChangeQuestionAnswer = (e, q_index, category) => {
    let { value } = e.target;
    let training_question_answer_details = {
      ...this.state.training_question_answer_details,
    };
    let errors = {
      ...this.state.errors,
      training_question_answer_details:
        { ...this.state.errors.training_question_answer_details } || {},
    };
    let { copyOfTrainingQAndAns } = training_question_answer_details;
    let { answers } = copyOfTrainingQAndAns.get(category);
    const { id } = answers[q_index];
    answers[q_index] = {
      ...answers[q_index],
      answer: value,
    };
    copyOfTrainingQAndAns.set(category, { answers });
    training_question_answer_details = {
      ...training_question_answer_details,
      copyOfTrainingQAndAns,
    };
    errors["training_question_answer_details"][id] = "";
    this.setState({ training_question_answer_details, errors });
  };

  getPracticeArea = () => {
    this.setState({ loading: true });
    this.api.auth
      .get(apiUrl.professionalPractiseArea)
      .then((resp) => {
        const data = apiv1.parseAndAutoHandleIssues(
          resp,
          this.props.doDispatch
        );
        if (data) {
          const practiceArea = this.practiceAreaSerialised(data);
          this.setState({ practiceArea, loading: false });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        apiv1.handleErrors(err, this.props.doDispatch);
      });
  };
  getProfessionSkills = () => {
    const { skillsProfession, selected_profession } = this.state;
    this.setState({ loading: true });
    apiv1.auth
      .get(
        `${apiUrl.professionalPractiseArea}?is_new=true&profession=${
          this.props.professional.profession.length === 1
            ? this.props.professional.profession
            : selected_profession
        }`
      )
      .then((res) => {
        const data = apiv1.parseAndAutoHandleIssues(res, this.props.doDispatch);
        if (data) {
          this.setState({ loading: false, skillsProfession: data });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        apiv1.handleErrors(err, this.props.doDispatch);
      });
  };
  getProfessions = () => {
    this.setState({ loading: true });
    apiv1.auth
      .get(apiUrl.getProfessions)
      .then((res) => {
        const data = apiv1.parseAndAutoHandleIssues(res, this.props.doDispatch);
        if (data) {
          // const professions = this.getSerialisedProfessions(data)
          const professions = data.map((d) => ({
            label: d?.name,
            value: d?.name,
            id: d?.id,
          }));
          this.setState({ ...this.state, loading: false, professions }, () => {
            this.props.onGetProfession(getProfessions(professions));
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        apiv1.handleErrors(err, this.props.doDispatch);
      });
  };
  checkProfileCompletion = ({ professional }) => {
    let {
      phone,
      bio_detailed,
      pan_number,
      practise_area,
      professional_product_added,
      work_experience,
    } = professional;
    return (
      phone &&
      phone.verified &&
      work_experience &&
      bio_detailed &&
      pan_number &&
      practise_area &&
      (practise_area.length > 0 || professional_product_added)
    );
  };

  showToast = ({ message }) => {
    var x = document.getElementById("snackbar");
    // document.body.appendChild(dummy);
    // x.setAttribute("id", "snackbar");
    x.className = "show"
    x.innerHTML = message
    setTimeout(function () {
        if (x) {
            x.className = x.className.replace("show", "");
        }
    }, 1500);
}

  submitForReview = () => {
    const { doDispatch, onUpdateAProfessionalKey, history } = this.props;
    let request = {
      approved_by_wiz_admin: false,
      professional: localStorage.professionalId,
    };
    this.setState({ loading: true });
    apiv1.auth
      .post(apiUrl.professionalProfileReview, request)
      .then((res) => {
        const data = apiv1.parseAndAutoHandleIssues(res, doDispatch);
        if (data) {
            // this.showToast('Submitted Profile For Review');
            // this.showToast({ message: 'Submitted Profiel For Review '});
            // showSnackbar({ message: 'Submitted Profile For Review', timeout: 3000});
          // let data = {...this.state.data}
          // let {professional} = data
          // professional = {
          //     ...professional,
          //     stage: 'Questionnaire'
          // }
          // data = {
          //     ...data,
          //     professional
          // }
          this.setState(
            { ...this.state, loading: false, has_submitted_for_review: true },
            () => {
              onUpdateAProfessionalKey(
                updateProfessionalKey({
                  value: true,
                  key: "has_submitted_for_review",
                })
              );
              showSnackbar({ message: `Profile Submitted For Review` });
              history.replace(`${path.professionalProfile}`);
            }
          );
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        apiv1.handleErrors(err, doDispatch);
        showSnackbar({ message: 'Error Submitting Profile For Review', timeout: 3000});
        // this.showToast({ message: 'Error Submitting Profile For Review'});
      });
  };

  restAuthPatch = ({ request }) => {
    const { doDispatch, onFetchDetails } = this.props;
    apiv1.auth
      .patch(apiUrl.restAuth, request)
      .then((res) => {
        const data = apiv1.parseAndAutoHandleIssues(res, doDispatch);
        if (data) {
          this.setState(
            { loading: false, has_submitted_for_review: true },
            () => {
              onFetchDetails(userDetails(data));
            }
          );
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        apiv1.handleErrors(err, doDispatch);
      });
  };

  getUploadedCertificates = () => {
    let apiPath = apiUrl.usersCertificates;
    apiPath += `?&professional=${localStorage.professionalId}`;
    this.api.auth
      .get(apiPath)
      .then((resp) => {
        let data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
        if (data) {
          let savedUserCertificates = JSON.parse(JSON.stringify([...data]));
          this.setState({ savedUserCertificates, loading: false });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        apiv1.handleErrors(err, this.props.doDispatch);
        this.setState({ loading: false });
      });
  };

  getLanguages = () => {
    this.setState({ loading: true });
    apiv1.auth
      .get(apiUrl.usersLanguages)
      .then((resp) => {
        const data = apiv1.parseAndAutoHandleIssues(
          resp,
          this.props.doDispatch
        );
        if (data) {
          let languages = data.map((languageDetails) => {
            const { language_native_form, name, id } = languageDetails;
            return {
              id,
              label: language_native_form,
              value: language_native_form,
              name: name,
            };
          });
          const { language } = this.getSelectedLanguages({
            languages,
            language: [...this.state.language],
          });
          this.setState({ languages, language, loading: false });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        apiv1.handleErrors(err, this.props.doDispatch);
        this.setState({ loading: false });
      });
  };

  professionalAvailable = (name) => (event) => {
    let request = {
      professional: {
        status: event.target.checked
          ? professionalStatus.active
          : professionalStatus.busy,
      },
    };
    this.api.auth
      .patch(apiUrl.restAuth, request)
      .then((resp) => {
        const data = apiv1.parseAndAutoHandleIssues(
          resp,
          this.props.doDispatch
        );
        const isAvailable =
          request.professional.status === professionalStatus.active;
        this.setState({ isAvailable });
        this.props.onSaveBasicDetails(userDetails(data));
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  getCollegeList = () => {
    axios({
      method: "get",
      url: "https://workbeam.blob.core.windows.net/wizcounsel/data/universities_canda.json ",
    })
      .then((resp) => {
        const data = apiv1.parseAndAutoHandleIssues(
          resp,
          this.props.doDispatch
        );
        if (data) {
          let collegeList = data.map((college) => {
            const { label } = college;
            return {
              label,
              value: label,
            };
          });
          this.setState({ collegeList, loading: false });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        apiv1.handleErrors(err, this.props.doDispatch);
        this.setState({ loading: false });
      });
  };
  getUserSelectedProfession = (professions, data) => {
    let userProfessions = [];
    let userProfessionsIds = [];
    const { professional } = data;
    let { profession } = professional || {};
    profession = profession ? profession : [];
    for (let i = 0; i < profession.length; i++) {
      const userSingleProfession = professions.find(
        (obj) => obj.id === profession[i]
      );
      if (userSingleProfession) {
        userProfessions.push(userSingleProfession);
        userProfessionsIds.push(userSingleProfession.id);
      }
    }
    return { userProfessions, userProfessionsIds, professions };
  };

  getUserDocument = () => {
    this.setState({ loading: true });
    this.api.auth
      .get("/users/professionaldocument")
      .then((resp) => {
        const data = apiv1.parseAndAutoHandleIssues(
          resp,
          this.props.doDispatch
        );
        if (data) {
          const { basicDetails } = this.state;
          let { professional } = this.state.data;
          professional = professional || {};
          let profilePercentage = calculatePercentageProfile({
            professional,
            basicDetails,
            verificationDocuments: data,
          });
          this.setState({
            userDocuments: data,
            loading: false,
            profilePercentage,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        apiv1.handleErrors(err, this.props.doDispatch);
        this.setState({ loading: false });
      });
  };

  getState = () => {
    this.setState({ loading: false });
    this.api.auth
      .get("/geo/state/?country=101")
      .then((res) => {
        const data = apiv1.parseAndAutoHandleIssues(res, this.props.doDispatch);
        if (data) {
          // const {result} = data
          const { onSaveSate } = this.props;
          this.setState({ geoState: data, loading: false }, () => {
            if (onSaveSate) {
              onSaveSate(getState(data));
            }
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        apiv1.handleErrors(err, this.props.doDispatch);
      });
  };

  workAddressObj = (data) => {
    let { professional } = data;
    professional = professional || {};
    let address = {
      line_one: "",
      line_two: "",
      zipcode: "",
      city: "",
    };
    if (professional.address) {
      address = {
        ...address,
        ...professional.address,
      };
    }
    return address;
  };

  workExperience = (data) => {
    return data.professional.work_experience !== undefined
      ? data.professional.work_experience
      : "";
  };

  getPracticeAreaIds = (data) => {
    return data.professional.practise_area !== undefined
      ? data.professional.practise_area
      : [];
  };

  consultationFeeObj = (data) => {
    const consultationFee = {};
    consultationFee.consultation_fee =
      data.professional.consultation_fee !== undefined &&
      data.professional.consultation_fee
        ? data.professional.consultation_fee
        : "";
    return consultationFee;
  };

  bioObj = (data) => {
    const professional = data.professional || {};
    let {
      bio_caption,
      bio_detailed,
      linkedin_profile,
      linked_profile_facebook,
      linked_profile_dribbble,
      linked_profile_behance,
      linked_profile_github,
      linked_profile_twitter,
      linked_profile_instagram,
      linked_profile_upwork,
      linked_profile_fiverr,
      linked_profile_other,
    } = professional;
    bio_caption = bio_caption || "";
    bio_detailed = bio_detailed || "";
    linkedin_profile = linkedin_profile || "";
    return {
      bio_caption,
      bio_detailed,
      linkedin_profile,
      linked_profile_facebook,
      linked_profile_dribbble,
      linked_profile_behance,
      linked_profile_github,
      linked_profile_twitter,
      linked_profile_instagram,
      linked_profile_upwork,
      linked_profile_fiverr,
      linked_profile_other,
    };
  };

  eduObj = () => {
    const newEdu = {};
    newEdu.institute = "";
    newEdu.degree = "";
    newEdu.from_year = new Date().getFullYear();
    newEdu.to_year = new Date().getFullYear();
    return newEdu;
  };

  reviewObj = () => {
    const newReview = {};
    newReview.project_name = "";
    newReview.rating = 5;
    newReview.comment = "";
    newReview.user_name = "";
    return newReview;
  };

  basicDetails = (profile, professional) => {
    const basicDetails = {};
    if (profile.first_name !== undefined) {
      basicDetails.first_name = profile.first_name;
      basicDetails.last_name = profile.last_name;
      basicDetails.email = profile.email;
      basicDetails.email_verified = profile.email_verified;
    } else {
      basicDetails.first_name = "";
      basicDetails.last_name = "";
      basicDetails.email = "";
      basicDetails.email_verified = "";
    }
    if (professional.phone !== undefined && professional.phone) {
      basicDetails.phone = professional.phone.phone;
    } else {
      basicDetails.phone = "";
    }
    if (professional.avatar) {
      basicDetails.avatar = professional.avatar;
    } else {
      basicDetails.avatar = "";
    }
    return basicDetails;
  };

  saveMetaSkills = (is_deleting) => {
    let { metaSkills, metadata, skillsObj } = this.state;
    const { id, skill_name, skills_level, is_editing } = skillsObj;
    metaSkills = metaSkills || [];

    if (is_deleting) {
      metaSkills = metaSkills.filter(
        (obj) => parseInt(obj.id) !== parseInt(id)
      );
    } else {
      if (is_editing) {
        let indexOfSkill = metaSkills.findIndex(
          (obj) => parseInt(obj.id) === parseInt(id)
        );
        if (indexOfSkill > -1) {
          metaSkills[indexOfSkill] = {
            id,
            name: skill_name,
            level: skills_level,
          };
        }
      } else {
        metaSkills.push({
          id,
          name: skill_name,
          level: skills_level,
        });
      }
    }

    const request = {
      professional: {
        metadata: {
          ...metadata,
          skills: metaSkills,
        },
      },
    };

    this.setState({ loading: true });
    axios
      .all([this.saveBasicDetails(request)])
      .then(
        axios.spread((userData) => {
          this.setState(
            {
              skillsObj: { skill_name: "", skills_level: "", active_tab: 6 },
              loading: false,
            },
            () => {
              $(`#skills_level`).val("");
            }
          );
        })
      )
      .catch((err) => {
        apiv1.parseAndAutoHandleIssues(err.response, this.props.doDispatch);
      });
  };

  saveMetaLanguages = () => {
    const { metaLanguage, metadata } = this.state;
    const request = {
      professional: {
        metadata: {
          ...metadata,
          language: metaLanguage,
        },
        language: metaLanguage.map((metaLanguage) => metaLanguage.id),
      },
    };
    const reqObjForValidation = {
      ...request,
      ...request.professional,
    };
    const errors = {};
    const isFormValidated = validateProfile(errors, reqObjForValidation);
    if (isFormValidated) {
      this.setState({ loading: true });
      axios
        .all([this.saveBasicDetails(request)])
        .then(
          axios.spread((userData, phoneData) => {
            this.setState({ loading: false });
          })
        )
        .catch((err) => {
          apiv1.parseAndAutoHandleIssues(err.response, this.props.doDispatch);
        });
    } else {
      this.setState({ errors });
    }
  };

  moveToNextTab = (tabNo, cb) => {
    this.setState({ active_tab: tabNo }, () => {
      const { history } = this.props;
      if (cb) {
        cb();
      }
      history.replace(path.professionalProfile);
    });
  };

  updateBasicDetails = (e) => {
    e.preventDefault();
    const {
      basicDetails,
      address,
      gst_number,
      membership_id,
      state_data,
      language,
      pan_number,
      company_name,
      price_per_hour,
      price_per_word,
      number_of_employee,
      is_agency,
      is_part_time,
      userProfessionsIds,
      metadata,
      work_experience,
      metaLanguage,
    } = this.state;
    const { first_name, last_name } = basicDetails;
    const { is_super_user } = this.props;

    const phoneDetailsObj = this.state.data.professional.phone;

    const request = {
      first_name,
      last_name,
      professional: {
        address,
        pan_number,
        gst_number,
        membership_id,
        company_name,
        phone: {
            ...phoneDetailsObj,
            phone: basicDetails.phone
        },
        price_per_hour,
        price_per_word,
        mobile: basicDetails.phone,
        number_of_employee: number_of_employee || 0,
        language: language.map((languageDetails) => languageDetails.id),
        metadata: {
          ...metadata,
          language: metaLanguage,
        },
        is_agency,
        is_part_time,
        profession: userProfessionsIds,
        work_experience,
      },
    };
    const reqObjForValidation = {
      ...request,
      ...request.professional,
      address: {
        ...address,
        state: state_data.id === undefined ? "" : state_data.id,
        phone: basicDetails.phone,
      },
    };
    const errors = {};
    const isFormValidated = validateProfile(errors, reqObjForValidation);
    if (isFormValidated || is_super_user) {
      reqObjForValidation.phone = reqObjForValidation.phone;
      axios
        .all([this.saveBasicDetails(request)])
        .then(
          axios.spread((userData, phoneData) => {
            if (!userData || !phoneData) {
              $("#verify_text").addClass("hidden");
              $(".modal-header .close").click();
            }
            this.moveToNextTab(3);
            // this.setState({active_tab: 3})
          })
        )
        .catch((err) => {
          apiv1.parseAndAutoHandleIssues(err.response, this.props.doDispatch);
        });
    } else {
      this.setState({ errors });
    }
  };

  saveBasicDetails = (request) => {
    const { onSaveBasicDetails } = this.props;
    $(".otp-box").addClass("hidden");
    this.setState({ loading: true, updatedSuccessfully: false });
    const errors = {};
    this.api.auth
      .patch(apiUrl.restAuth, request)
      .then((resp) => {
        const resData = apiv1.parseAndAutoHandleIssues(
          resp,
          this.props.doDispatch
        );
        if (resData) {
          onSaveBasicDetails(userDetails(resData));
          this.setState({ loading: false, updatedSuccessfully: true }, () => {
            showSnackbar({ message: updateMessage });
          });
        } else {
          this.setState({ loading: false, updatedSuccessfully: false });
        }
      })
      .catch((err) => {
        const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
        for (let key in resErrors) {
          errors[key] = resErrors[key][0];
        }
        this.setState({ loading: false, updatedSuccessfully: false, errors });
      });
  };

  changeOtp = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChange = (section, e) => {
    const { name, value } = e.target;
    let { professional } = this.props.data;
    const state = { ...this.state };
    state[section][name] = value;
    const newErrors = { ...this.state.errors };
    newErrors[name] = "";
    if (name === "phone") {
      const message = "Phone no is not valid";
      onlyZeroToNine(newErrors, name, value, message);
      $(".otp-box").addClass("hidden");
      professional = professional || {};
      let { phone } = professional || {};
      if (
        getPhoneNo(this.state.data.professional.phone) &&
        phone.phone &&
        state.basicDetails.phone !== phone.phone &&
        phone.verified
      ) {
        state.data.professional.phone.verified = false;
      }
    }
    state["errors"] = newErrors;
    this.setState(state);
  };

  uploadImage = (files) => {
    const imageName = files.name;
    let newImageUrl = "";
    const currentImageUrl = files.base64;
    const imageType = [
      "data:image/png;base64,",
      "data:image/jpeg;base64,",
      "data:image/jpg;base64,",
    ];
    for (let i = 0; i < imageType.length; i++) {
      if (currentImageUrl.includes(imageType[i])) {
        newImageUrl = currentImageUrl.replace(imageType[i], "");
      }
    }
    if (newImageUrl === "") {
      alert("not a valid image type");
      return;
    }
    const id = this.props.data.professional.id;
    const request = {
      avatar_base64: newImageUrl,
      avatar_file_name: imageName,
    };
    this.setState({ loading: true });
    const errors = {};
    this.api.auth
      .patch(`users/professionalprofile/${id}/`, request)
      .then((resp) => {
        const data = apiv1.parseAndAutoHandleIssues(
          resp,
          this.props.doDispatch
        );
        if (data) {
          this.setState({ loading: false, updatedSuccessfully: true });
          // $('#output')[0].src = resp.data.avatar
          this.props.onUserDetails(updateProfilePic(data.avatar));
        } else {
          this.setState({ loading: false, updatedSuccessfully: false });
        }
      })
      .catch((err) => {
        const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
        for (let key in resErrors) {
          errors[key] = resErrors[key][0];
        }
        this.setState({ loading: false, updatedSuccessfully: false, errors });
      });
  };

  VerifyOtpWitCountryCodeApiOnWizcounsel = () => {
    let { otp, basicDetails } = this.state;
    const { phone } = basicDetails;
    const { doDispatch } = this.props;
    let request = {
      phone,
      otp,
      country_code: indiaCallingCode,
    };
    this.setState({ loading: true });
    this.api.auth
      .post(apiUrl.wizcounselVerifyOtpWithCountryCode, request)
      .then((resp) => {
        const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
        if (data) {
          const { phone } = data;
          $(".otp-box").addClass("hidden");
          this.setState({ loading: false, updatedSuccessfully: true, otp: "" });
          this.props.OnUpdatePhone(updateProfessionalPhone(phone));
        } else {
          this.setState({
            loading: false,
            updatedSuccessfully: false,
            otp: "",
          });
        }
      })
      .catch((err) => {
        apiv1.handleErrors(err, this.props.doDispatch);
        this.setState({ loading: false, updatedSuccessfully: false, otp: "" });
      });
  };

  sendOtpOnWizcounsel = ({ phone }) => {
    const { doDispatch } = this.props;
    // NOTE: will have to remove sent otp section
    let request = {
      phone,
      country_code: "",
    };
    let errors = {};
    this.setState({ loading: true });
    this.api.auth
      .post(apiUrl.wizcounselSendOtpWithCountryCode, request)
      .then((resp) => {
        const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
        if (data) {
          this.setState({ loading: false, isOtpSent: true }, () => {
            $(".otp-box").removeClass("hidden");
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        const resErrors = apiv1.handleErrors(err, doDispatch);
        for (let key in resErrors) {
          errors[key] = resErrors[key][0];
        }
        this.setState({ loading: false, errors });
      });
  };

  verifyPhone = (phone) => {
    const errors = {};
    const { nationalNumber } = parsePhoneNumber(phone) || {};
    const message = "Phone no is not valid";
    if (
      isPhoneValid(
        errors,
        "phone",
        nationalNumber,
        message,
        indiaCountryCodeCode
      )
    ) {
      // phone = '+91' + phone
      this.sendOtpOnWizcounsel({ phone: nationalNumber });
      // this.firebaseOtp(phone)
    } else {
      this.setState({ errors, loading: false });
    }
  };

  updatePhoneNo = () => {
    const { basicDetails } = this.state;
    const errors = {};
    const request = {
      phone: basicDetails.phone,
      profile_type: "professional",
      send_otp: false,
    };
    // $('.otp-box').addClass('hidden');
    const message = "Phone no is not valid";
    if (isPhoneValid(errors, "phone", request.phone, message)) {
      // request.phone = '+91' + request.phone;
      this.setState({ loading: true, updatedSuccessfully: false, errors });
      this.api.auth
        .post(apiUrl.wizcounselSendOtpWithPhoneObj, request)
        .then((resp) => {
          const data = apiv1.parseAndAutoHandleIssues(
            resp,
            this.props.doDispatch
          );
          if (data) {
            const { phone } = data;
            this.verifyPhone(phone);
            this.props.OnUpdatePhone(updateProfessionalPhone(data));
          } else {
            this.setState({ loading: false, isOtpSent: false });
          }
        })
        .catch((err) => {
          const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
          for (let key in resErrors) {
            errors[key] = resErrors[key][0];
          }
          this.setState({ loading: false, isOtpSent: false, errors });
        });
    } else {
      this.setState({ errors, loading: false });
    }
  };

  addEducation = () => {
    let newEdu = { ...this.state.newEdu };
    newEdu.professional = localStorage.professionalId;
    const errors = {};
    if (idEducationValid(errors, newEdu)) {
      this.setState({ newEdu, errors }, this.saveEducation);
    } else {
      this.setState({ errors });
    }
  };

  editEducation = (e, educationId, isSaved) => {
    e.preventDefault();
    const savedEducationList = [...this.state.savedEducationList];
    let newEdu = {};
    const errors = {};
    if (isSaved) {
      let editEdu =
        savedEducationList.find((obj) => obj.id === educationId) || {};
      const { institute } = editEdu;
      newEdu = {
        ...editEdu,
        label: institute,
        value: institute,
      };
      this.setState({
        newEdu,
        isAddMoreDisabled: true,
        isShowEducationUi: true,
        errors,
      });
    } else {
      newEdu = savedEducationList[educationId];
      this.setState({
        newEdu,
        isAddMoreDisabled: true,
        isShowEducationUi: true,
        errors,
      });
    }
  };

  checkPublicEducationDeletion = (savedEducationList, educationId) => {
    const { data } = this.state;
    const { professional } = data;
    const { public_education } = professional;
    return public_education === educationId;
  };

  deleteEducation = (e, educationId) => {
    e.preventDefault();
    let savedEducationList = [...this.state.savedEducationList];
    const isPublicEducation = this.checkPublicEducationDeletion(
      savedEducationList,
      educationId
    );
    let request = {};
    if (!isPublicEducation) {
      this.setState({ loading: true });
      this.api.auth
        .delete(`/users/education/${educationId}/`)
        .then((resp) => {
          const data = apiv1.parseAndAutoHandleIssues(
            resp,
            this.props.doDispatch
          );
          if (data === "") {
            savedEducationList = savedEducationList.filter(
              (details) => details.id !== educationId
            );
            this.setState({ loading: false, savedEducationList });
          } else {
            this.setState({ loading: false });
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          apiv1.handleErrors(err, this.props.doDispatch);
        });
    } else {
      this.setState({ loading: true });
      let publicEducationId = null;
      if (savedEducationList.length <= 1) {
        savedEducationList = [];
        publicEducationId = null;
      } else {
        savedEducationList = savedEducationList.filter(
          (details) => details.id !== educationId
        );
      }
      request.professional = {
        education: savedEducationList,
        public_education: publicEducationId,
      };
      this.api.auth
        .patch(apiUrl.restAuth, request)
        .then((resp) => {
          const data = apiv1.parseAndAutoHandleIssues(
            resp,
            this.props.doDispatch
          );
          if (data) {
            this.props.onSaveBasicDetails(userDetails(data));
            this.setState({ loading: false, savedEducationList });
            $(".modal-header .close").click();
          } else {
            this.setState({
              loading: false,
            });
          }
        })
        .catch((err) => {
          const errors = apiv1.handleErrors(err, this.props.doDispatch);
          this.setState({ loading: false });
        });
    }
  };

  returnLatestEducationId = (education) => {
    let eduId = education[education.length - 1].id;
    let currentYear = 1950;
    education.find((edu) => {
      if (edu.from_year > currentYear) {
        currentYear = edu.from_year;
        eduId = edu.id;
      }
    });
    return eduId;
  };

  addPublicEducation = (educationId) => {
    const request = {
      professional: {
        public_education: educationId,
      },
    };
    this.api.auth
      .patch(apiUrl.restAuth, request)
      .then((resp) => {
        const data = apiv1.parseAndAutoHandleIssues(
          resp,
          this.props.doDispatch
        );
        showSnackbar({ message: updateMessage });
        this.props.onSaveBasicDetails(userDetails(data));
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  handleChange = (id, checked) => {
    this.setState((prevState) => {
      let selectedEducationIds = [...prevState.selectedEducationIds];
      if (checked) {
        selectedEducationIds.push(id);
        this.addPublicEducation(id);
      } else {
        selectedEducationIds = selectedEducationIds.filter(
          (item) => item !== id
        );
      }
      return { selectedEducationIds };
    });
  };

  saveWorkExp = () => {
    const { work_experience } = this.state;
    let request = {
      professional: {
        work_experience,
      },
    };
    const message = "Only integer value is allowed";
    const errors = {};
    if (onlyZeroToNine(errors, "work_experience", work_experience, message)) {
      this.setState({ loading: true });
      this.api.auth
        .patch(apiUrl.restAuth, request)
        .then((resp) => {
          const data = apiv1.parseAndAutoHandleIssues(
            resp,
            this.props.doDispatch
          );
          if (data) {
            this.props.onSaveBasicDetails(userDetails(data));
            this.setState({
              loading: false,
              errors: {},
              active_tab: 4,
            });
            showSnackbar({ message: updateMessage });
          } else {
            this.setState({
              loading: false,
              errors: {},
            });
          }
        })
        .catch((err) => {
          const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
          for (let key in resErrors) {
            errors[key] = resErrors[key][0];
          }
          this.setState({ loading: false });
        });
    } else {
      this.setState({ errors });
    }
  };

  addCertificate = () => {
    let userCertificates = [...this.state.userCertificates];
    userCertificates.map((certificateDetails) => {
      const { name, from_year, to_year, institute, document_base64, id } =
        certificateDetails;
      if (name && from_year && to_year && institute) {
        let requestApi = [];
        if (!document_base64) {
          delete certificateDetails["document_base64"];
          delete certificateDetails["document_file_name"];
        }
        requestApi.push(
          this.saveCertificateApi({ request: certificateDetails })
        );
        this.uploadCertificate({ requestApi });
      }
    });
  };

  saveEducation = () => {
    const { savedEducationList } = this.state;
    let newEduList = [];
    const errors = {};
    let isEducationValid = false;
    newEduList = newEduList.concat(savedEducationList);
    let newEdu = { ...this.state.newEdu };
    newEdu.professional = localStorage.professionalId;
    if (
      newEdu.degree &&
      newEdu.from_year &&
      newEdu.to_year &&
      newEdu.institute
    ) {
      isEducationValid = idEducationValid(errors, newEdu);
      if (isEducationValid) {
        newEduList.push(newEdu);
      }
    }
    let request = {
      professional: {
        education_details: newEduList,
      },
    };
    if (isEducationValid) {
      this.setState({ loading: true });
      this.api.auth
        .patch(apiUrl.restAuth, request)
        .then((resp) => {
          const data = apiv1.parseAndAutoHandleIssues(
            resp,
            this.props.doDispatch
          );
          if (data) {
            const { professional } = data;
            let { education_details } = professional;
            newEdu = this.eduObj();
            this.props.onSaveBasicDetails(userDetails(data));
            if (
              education_details.length > 0 &&
              !professional.public_education
            ) {
              let eduId = education_details[education_details.length - 1].id;
              this.addPublicEducation(eduId);
            }
            this.setState({
              loading: false,
              isAddMoreDisabled: false,
              newEdu,
              errors: {},
              isShowEducationUi: false,
            });
            $(".modal-header .close").click();
            showSnackbar({ message: updateMessage });
          } else {
            this.setState({
              loading: false,
              isAddMoreDisabled: false,
              errors: {},
            });
          }
        })
        .catch((err) => {
          const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
          for (let key in resErrors) {
            errors[key] = resErrors[key][0];
          }
          this.setState({
            loading: false,
            updatedSuccessfully: false,
            isAddMoreDisabled: false,
            errors,
          });
        });
    } else {
      this.setState({ errors });
    }
  };

  addReview = (isSaved) => {
    const savedClientReviewList = [...this.state.savedClientReviewList];
    const newClientReviewList = [...this.state.newClientReviewList];
    let newReview = { ...this.state.newReview };
    let indexOfReview = this.state.indexOfReview;
    const errors = {};
    if (reviewValidator(errors, newReview)) {
      if (isSaved) {
        savedClientReviewList[indexOfReview] = { ...newReview };
      } else if (!isSaved) {
        newClientReviewList.push(newReview);
      }
      newReview = this.reviewObj();
      this.setState(
        {
          newReview,
          isAddMoreDisabled: false,
          errors,
          savedClientReviewList,
          newClientReviewList,
        },
        () => {
          this.saveClientReview();
        }
      );
    } else {
      this.setState({ errors });
    }
  };

  editReview = (e, currReview, index) => {
    e.preventDefault();
    this.setState({
      newReview: currReview,
      isEditReviewEnable: true,
      indexOfreview: index,
    });
    const el = document.querySelector("#projects-form");
    el?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  deleteReview = (e, data, index) => {
    e.preventDefault();
    const savedClientReviewList = [...this.state.savedClientReviewList];
    savedClientReviewList.splice(index, 1);
    const request = {
      professional: {
        professional_reviews: savedClientReviewList,
      },
    };
    this.setState({ loading: true });
    this.api.auth
      .patch(apiUrl.restAuth, request)
      .then((resp) => {
        const data = apiv1.parseAndAutoHandleIssues(
          resp,
          this.props.doDispatch
        );
        if (data) {
          this.props.onSaveBasicDetails(userDetails(data));
          this.setState({ loading: false, savedClientReviewList });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        apiv1.handleErrors(err, this.props.doDispatch);
      });
  };

  saveClientReview = () => {
    const { savedClientReviewList } = this.state;
    let newClientReviewList = [...this.state.newClientReviewList];
    let isReviewValid = true;
    newClientReviewList.push(...savedClientReviewList);
    const errors = {};
    let newReview = { ...this.state.newReview };
    if (
      newReview.project_name.trim() &&
      newReview.comment.trim() &&
      newReview.user_name.trim()
    ) {
      isReviewValid = reviewValidator(errors, newReview);
      if (isReviewValid) {
        newClientReviewList.push(newReview);
      }
    }
    const request = {
      professional: {},
    };
    request.professional.professional_reviews = newClientReviewList;
    if (isReviewValid) {
      this.setState({ loading: true });
      this.api.auth
        .patch(apiUrl.restAuth, request)
        .then((resp) => {
          const data = apiv1.parseAndAutoHandleIssues(
            resp,
            this.props.doDispatch
          );
          if (data) {
            newReview = this.reviewObj();
            this.props.onSaveBasicDetails(userDetails(data));
            this.setState({
              loading: false,
              updatedSuccessfully: true,
              isAddMoreDisabled: false,
              newReview,
              newClientReviewList: [],
              errors: {},
            });
            $(".modal-header .close").click();
            showSnackbar({ message: updateMessage });
          } else {
            this.setState({
              loading: false,
              updatedSuccessfully: false,
              isAddMoreDisabled: false,
              errors: {},
              newClientReviewList: [],
            });
          }
        })
        .catch((err) => {
          const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
          for (let key in resErrors) {
            errors[key] = resErrors[key][0];
          }
          this.setState({
            loading: false,
            updatedSuccessfully: false,
            isAddMoreDisabled: false,
            errors,
          });
        });
    } else {
      this.setState({ errors });
    }
  };

  addedEducationList = () => {
    // const { savedEducationList, selectedEducationIds } = this.state;
    const { savedEducationList } = this.state;
    const { professional } = this.props.data;
    return (
      <Fragment>
        {savedEducationList.length > 0 && (
          <h4 className="d-profile-sub-heading">
            Education Qualifications added by you
          </h4>
        )}
        {savedEducationList.length > 0 && (
          <Fragment>
            {savedEducationList.map((data, index) => {
              const { from_year, degree, to_year, institute, id } = data;
              return (
                <div className="expert-reviews-added-list">
                  <div className="row" id={index}>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>University/College</label>
                        <input
                          type="text"
                          className="form-control input-lg"
                          name="institute"
                          value={institute}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Degree</label>
                        <input
                          type="text"
                          className="form-control input-lg"
                          name="degree"
                          value={degree}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>From</label>
                        <input
                          type="text"
                          className="form-control input-lg"
                          name="from_year"
                          value={from_year}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>To</label>
                        <input
                          type="text"
                          className="form-control input-lg"
                          name="to_year"
                          value={to_year}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="clearfix" />
                    <div className="col-sm-12">
                      <ul className="list-inline expert-education-footer">
                        {/* <li>
                                                    <input type='checkbox' id={`checkbox${index}`}
                                                        className='education_checkbox'
                                                        checked={selectedEducationIds.includes(id)}
                                                        onChange={(e) => this.handleChange(id, e.target.checked)} 

                                                    // onChange={(e) => {
                                                    //     const { checked } = e.target;
                                                    //     if (checked) {
                                                    //         this.addPublicEducation(id); 
                                                    //     }
                                                    // }}
                                                    /> Make Public
                                                </li> */}
                        <li>
                          <a
                            className="btn-edit"
                            style={{ color: "#ff337d" }}
                            onClick={(e) => {
                              $("html, body").animate(
                                {
                                  scrollTop:
                                    $("#add_education").offset().top - 80,
                                },
                                200
                              );
                              this.editEducation(e, id, true);
                            }}
                          >
                            <i className="fa fa-edit" /> Edit
                          </a>
                        </li>
                        <li>
                          <a
                            className="btn-delete"
                            onClick={(e) => this.deleteEducation(e, id)}
                          >
                            <i className="fa fa-trash" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </Fragment>
        )}
      </Fragment>
    );
  };

  reviewAddedList = () => {
    const { newClientReviewList, savedClientReviewList } = this.state;
    return (
      <div>
        {(newClientReviewList.length > 0 ||
          savedClientReviewList.length > 0) && (
          <Fragment>
            {savedClientReviewList.map((data, index) => {
              return (
                <div className="expert-reviews-added-list" key={index}>
                  <a
                    className="btn-delete pull-right"
                    onClick={(e) => this.deleteReview(e, data, index)}
                  >
                    <i className="fa fa-trash" />
                  </a>
                  <a
                    className="btn-edit"
                    onClick={(e) => this.editReview(e, data, index, true)}
                  >
                    <i className="fa fa-edit"></i>
                  </a>
                  <p className="added-review-para">Project Name</p>
                  <p className="added-review-name">{data.project_name}</p>
                  <p className="added-review-para">Customer Name</p>
                  <p className="added-review-name">{data.user_name}</p>
                  <p className="added-review-para">
                    Customer Review/Testimonial
                  </p>
                  <p className="para">{data.comment}</p>
                </div>
              );
            })}
            {newClientReviewList.map((data, index) => {
              return (
                <div
                  className="expert-reviews-added-list"
                  key={index + "child"}
                >
                  <a
                    className="btn-delete pull-right"
                    style={{ visibility: "hidden" }}
                    onClick={(e) => this.deleteReview(e, data, index)}
                  >
                    <i className="fa fa-trash" />
                  </a>
                  <p className="added-review-para">Project Name</p>
                  <p className="added-review-name">{data.project_name}</p>
                  <p className="added-review-para">Customer Name</p>
                  <p className="added-review-name">{data.user_name}</p>
                  <p className="added-review-para">
                    Customer Review/Testimonial
                  </p>
                  <p className="para">{data.comment}</p>
                </div>
              );
            })}
          </Fragment>
        )}
      </div>
    );
  };

  clientReviewUi = () => {
    const {
      newReview,
      newClientReviewList,
      errors,
      savedClientReviewList,
      isEditReviewEnable,
    } = this.state;
    return (
      <Fragment>
        <div className="d-update-profile-body" id="projects-form">
          <div className="row">
            <div className="col-sm-12">
              <div className="profile-tutorial-container">
                <div className="row">
                  <div className="col-sm-7">
                    <h2 className="profile-tutorial-heading">
                      <img
                        src={tips}
                        style={{
                          width: "30px",
                          float: "left",
                          marginRight: "5px",
                        }}
                        alt={`Create Gig Tips`}
                      />
                      These Projects are shown on your Projects Listings, Pages
                      and Profile.
                    </h2>
                    <h2 className="profile-tutorial-heading">
                      <img
                        src={tips}
                        style={{
                          width: "30px",
                          float: "left",
                          marginRight: "5px",
                        }}
                        alt={`Create Gig Tips`}
                      />
                      By adding at least 8 Projects you get 4x more leads on
                      Workbeam.
                    </h2>
                    <ul className="profile-tutorial-ul list-unstyled">
                      <li>
                        <img
                          src={graphLine}
                          alt={`See how Projects are shown on your Gigs`}
                        />
                        <a
                          data-toggle="modal"
                          data-target="#expert_client_reviews_tips_popup"
                        >
                          See how Projects are shown on your Projects{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-5">
                    <p className="profile-tutorial-sub-heading">
                      Why and how to add Past Projects & reviews?
                    </p>
                    <VimeoExampleVideo
                      videoLink={`https://player.vimeo.com/video/566551795`}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-10">
              <div className="row basic-detail-form">
                {savedClientReviewList.length + newClientReviewList.length <=
                  9 && (
                  <Fragment>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Client Name</label>
                        <input
                          type="text"
                          className="form-control input-lg"
                          id="user_name"
                          // placeholder="Degree Certification"
                          name="user_name"
                          value={newReview.user_name}
                          onChange={(e) => this.onChange(new_review, e)}
                        />
                        <span
                          className={errors.user_name ? "error" : "no-error"}
                        >
                          {errors.user_name}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Project Name</label>
                        <input
                          type="text"
                          className="form-control input-lg"
                          id="project_name"
                          // placeholder="Degree Certification"
                          name="project_name"
                          value={newReview.project_name}
                          onChange={(e) => this.onChange(new_review, e)}
                        />
                        <span
                          className={errors.project_name ? "error" : "no-error"}
                        >
                          {errors.project_name}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>Client Review</label>
                        <textarea
                          className="form-control"
                          rows="4"
                          id="comment"
                          // placeholder="Degree Certification"
                          name="comment"
                          value={newReview.comment}
                          onChange={(e) => this.onChange(new_review, e)}
                        />
                        <span className={errors.comment ? "error" : "no-error"}>
                          {errors.comment}
                        </span>
                      </div>
                    </div>
                    <div className="clearfix" />
                    <div className="col-sm-12">
                      <button
                        type="button"
                        className="btn btn-save-profile-gig"
                        onClick={() => {
                          this.addReview(isEditReviewEnable);
                        }}
                      >
                        {isEditReviewEnable
                          ? "Done"
                          : savedClientReviewList.length > 0
                          ? "Add More Reviews"
                          : "Add Reviews"}
                      </button>
                      <p>&#x00A0;</p>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="d-update-profile-body" style={{ border: "none" }}>
          <div className="row">
            <div className="col-sm-10">
              <h4 className="d-profile-sub-heading">Projects added by you</h4>
              {this.reviewAddedList()}
              {savedClientReviewList.length + newClientReviewList.length >
                0 && (
                <button
                  type="button"
                  className="btn btn-save-profile-gig"
                  onClick={() => {
                    this.moveToNextTab(7, this.getQuestion);
                    // this.setState({active_tab: 6})
                  }}
                >
                  Save & Continue
                </button>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  saveCertificateApi = ({ request }) => {
    let apiPath = apiUrl.usersCertificates;
    if (request.id) {
      apiPath += `${request.id}/`;
      return this.api.auth.patch(apiPath, request);
    }
    return this.api.auth.post(apiPath, request);
  };

  deleteCertificate = (certificateId) => {
    let apiPath = apiUrl.usersCertificates;
    apiPath += `${certificateId}/`;
    return this.api.auth
      .delete(apiPath)
      .then((resp) => {
        const data = apiv1.parseAndAutoHandleIssues(
          resp,
          this.props.doDispatch
        );
        if (data === "") {
          let savedUserCertificates = [...this.state.savedUserCertificates];
          savedUserCertificates = savedUserCertificates.filter(
            (details) => details.id !== certificateId
          );
          this.setState({ loading: false, savedUserCertificates });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        apiv1.handleErrors(err, this.props.doDispatch);
      });
  };

  uploadCertificate = ({ requestApi }) => {
    axios
      .all(requestApi)
      .then(
        axios.spread((...resp) => {
          const newCer = resp.map((savedData) => {
            return apiv1.parseAndAutoHandleIssues(
              savedData,
              this.props.doDispatch
            );
          });
          let savedUserCertificates = [...this.state.savedUserCertificates];
          savedUserCertificates = savedUserCertificates.concat(newCer);
          this.setState({
            loading: false,
            userCertificates: [{ ...this.addMoreCertificateObj() }],
            savedUserCertificates,
          });
        })
      )
      .catch((err) => {
        let errors = {};
        const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
        for (let key in resErrors) {
          errors[key] = resErrors[key][0];
        }
        this.setState({ loading: false, updatedSuccessfully: false, errors });
      });
  };

  returnProfessionalObj = () => {
    let { data } = this.props;
    let { professional } = data || {};
    return professional;
  };

  addMoreCertificateObj = () => {
    return {
      name: "",
      institute: "",
      from_year: new Date().getFullYear(),
      to_year: new Date().getFullYear(),
      document_base64: "",
      document_file_name: "",
      professional: localStorage.professionalId,
    };
  };

  handleCertificateChange = (index, fieldName, value) => {
    this.setState((prevState) => {
      const updatedCertificates = [...prevState.userCertificates];
      updatedCertificates[index][fieldName] = value;
      console.log("Updated certificate at index: ", index);
      return { userCertificates: updatedCertificates };
    });
  };

  renderCertificateInput = (fieldName, value, index) => (
    <input
      type="text"
      className="form-control input-lg"
      name={fieldName}
      placeholder={fieldName === "name" ? "Certification Name" : "Provided by"}
      value={value}
      onChange={(e) =>
        this.handleCertificateChange(index, fieldName, e.target.value)
      }
    />
  );

  onChangeCertificateInput = ({ e, index }) => {
    const { name, value } = e.target;
    let { userCertificates } = this.state;
    userCertificates[index][name] = value;
    this.setState({ userCertificates });
  };

  certificateList = (list) => {
    const { userCertificates } = this.state;

    return list.map((userCertificate, index) => {
      const {
        name,
        from_year,
        to_year,
        institute,
        document_file_name,
        id,
        document,
      } = userCertificate;

      const handleDocumentChange = (file, index) => {
        const { isDocumentValid, document_base64, document_file_name } =
          this.checkValidDocument({ file });
        if (isDocumentValid) {
          let updatedCertificates = [...userCertificates];
          updatedCertificates[index]["document_base64"] = document_base64;
          updatedCertificates[index]["document_file_name"] = document_file_name;
          updatedCertificates[index]["document"] = document_base64;
          this.setState({ userCertificates: updatedCertificates });
        }
      };

      console.log("In certificateList");
      return (
        <div className="expert-reviews-added-list">
          <div className="row" id={index}>
            <div className="col-sm-6">
              <div className="form-group">
                {this.renderCertificateInput(
                  "name",
                  userCertificate.name,
                  index
                )}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                {this.renderCertificateInput(
                  "institute",
                  userCertificate.institute,
                  index
                )}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <select
                  name="from_year"
                  value={from_year}
                  onChange={(e) => this.onChangeCertificateInput({ e, index })}
                  className="form-control input-lg"
                >
                  {fromYear.map((data, i) => {
                    return (
                      <option key={i} value={data}>
                        {" "}
                        {data}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <select
                  name="to_year"
                  value={to_year}
                  onChange={(e) => this.onChangeCertificateInput({ e, index })}
                  className="form-control input-lg"
                >
                  {fromYear.map((data, i) => {
                    return (
                      <option key={i} value={data}>
                        {" "}
                        {data}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-sm-6">
              <label
                className="btn-bs-file"
                style={{
                  cursor: "pointer",
                  color: "#ff337d",
                  fontSize: "14px",
                  marginTop: "0px",
                }}
              >
                {!document_file_name ? (
                  <span>
                    <i className="fa fa-upload" aria-hidden="true" />{" "}
                    certificate
                  </span>
                ) : (
                  "Change\n Document "
                )}
                <FileBase64
                  onDone={(file) => handleDocumentChange(file, index)}
                ></FileBase64>
              </label>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                {document && (
                  <p>
                    <a
                      href={document}
                      target="_blank"
                      style={{ color: "#ff337d" }}
                      download={document_file_name}
                    >
                      {document_file_name}
                    </a>
                  </p>
                )}
              </div>
            </div>
            <div className="col-sm-12" style={{ display: "flex" }}>
              <a
                className="btn btn-add-more"
                onClick={(e) => {
                  e.preventDefault();
                  this.addCertificate();
                }}
              >
                Add
              </a>
            </div>
          </div>
        </div>
      );
    });
  };

  certificatesUi = () => {
    const { errors, userCertificates, data, savedUserCertificates } =
      this.state;
    const { professional } = data;

    const handleDocumentChange = (file, index) => {
      const { isDocumentValid, document_base64, document_file_name } =
        this.checkValidDocument({ file });
      if (isDocumentValid) {
        let updatedCertificates = [...savedUserCertificates];
        updatedCertificates[index]["document_base64"] = document_base64;
        updatedCertificates[index]["document_file_name"] = document_file_name;
        updatedCertificates[index]["document"] = document_base64;
        this.setState({ savedUserCertificates: updatedCertificates });
      }
    };

    return (
      <Fragment>
        {professional.id && (
          <Fragment>{this.certificateList(userCertificates)}</Fragment>
        )}
        {savedUserCertificates.length > 0 && (
          <h4 className="d-profile-sub-heading">
            Online Certifications added by you
          </h4>
        )}
        {savedUserCertificates.map((userCertificate, index) => {
          const {
            name,
            from_year,
            to_year,
            institute,
            document_file_name,
            id,
            document,
          } = userCertificate;
          return (
            <div className="expert-reviews-added-list">
              <div className="row" id={index}>
                <div className="col-sm-6">
                  <div className="form-group">
                    {this.renderCertificateInput(
                      "name",
                      userCertificate.name,
                      index
                    )}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    {this.renderCertificateInput(
                      "institute",
                      userCertificate.institute,
                      index
                    )}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <select
                      name="from_year"
                      value={from_year}
                      onChange={(e) =>
                        this.onChangeCertificateInput({ e, index })
                      }
                      className="form-control input-lg"
                    >
                      {fromYear.map((data, i) => {
                        return (
                          <option key={i} value={data}>
                            {" "}
                            {data}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <select
                      name="to_year"
                      value={to_year}
                      onChange={(e) =>
                        this.onChangeCertificateInput({ e, index })
                      }
                      className="form-control input-lg"
                    >
                      {fromYear.map((data, i) => {
                        return (
                          <option key={i} value={data}>
                            {" "}
                            {data}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-sm-12">
                  <ul className="list-inline expert-education-footer">
                    <li>
                      <label
                        className="btn-bs-file"
                        style={{
                          cursor: "pointer",
                          color: "#2a64d3",
                          fontSize: "14px",
                          marginTop: "0px",
                        }}
                      >
                        {!document_file_name ? (
                          <span>
                            <i className="fa fa-upload" aria-hidden="true" />{" "}
                            Document
                          </span>
                        ) : (
                          "Change\n Document "
                        )}
                        <FileBase64
                          onDone={(file) => handleDocumentChange(file, index)}
                        ></FileBase64>
                      </label>
                    </li>
                    <li>
                      {document && (
                        <a
                          href={document}
                          target="_blank"
                          style={{ color: "#ff337d" }}
                          download={document_file_name}
                        >
                          {document_file_name}
                        </a>
                      )}
                    </li>
                    <li className="pull-right">
                      <a
                        className="btn btn-delete"
                        onClick={() => {
                          this.deleteCertificate(id);
                        }}
                      >
                        <i className="fa fa-trash" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          );
        })}

        <p>&#x00A;</p>
      </Fragment>
    );
  };

  educationAndExperience = () => {
    const {
      newEdu,
      work_experience,
      errors,
      savedEducationList,
      collegeList,
      isShowEducationUi,
    } = this.state;
    return (
      <Fragment>
        <form className="basic-detail-form">
          {/*<div className="d-update-profile-body">*/}
          {/*<div className="row">*/}
          {/*<div className="col-sm-10">*/}
          {/*<div className="row">*/}
          {/*<div className="col-sm-12">*/}
          {/*<h4 className="d-profile-sub-heading" style={{marginTop: '0px'}}>Work*/}
          {/*Experience <span className="star-note">*</span></h4>*/}
          {/*<div className="form-group">*/}
          {/*<div className="work-exp-input">*/}
          {/*<div className="input-field">*/}
          {/*<input type="text"*/}
          {/*className="form-control input-lg"*/}
          {/*id="experience"*/}
          {/*placeholder="Years"*/}
          {/*name='work_experience'*/}
          {/*value={work_experience}*/}
          {/*onChange={(e) => {*/}
          {/*const errors = {};*/}
          {/*const message = 'Only integer value is allowed';*/}
          {/*onlyZeroToNine(errors, e.target.name, e.target.value, message);*/}
          {/*this.setState({[e.target.name]: e.target.value, errors})*/}
          {/*}}*/}
          {/*/>*/}
          {/*<span*/}
          {/*className={errors.work_experience ? 'error' : 'no-error'}>{errors.work_experience}</span>*/}
          {/*</div>*/}
          {/*<div className="text-suggetion-area">*/}
          {/*<p className="para">Please put correct work experience. We verify*/}
          {/*your profile, past projects and proof of work based on your*/}
          {/*experience. </p>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*<a onClick={this.saveWorkExp} className="btn btn-add-more">Save to*/}
          {/*my profile</a>*/}
          {/*</div>*/}

          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          <div className="d-update-profile-body">
            <div className="row">
              <div className="col-sm-10">
                <h4 className="d-profile-sub-heading">
                  Education Qualifications (Optional)
                </h4>
                <div className="expert-reviews-added-list" id="add_education">
                  {(savedEducationList.length === 0 || isShowEducationUi) && (
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          {/* <Select
                                                    placeholder="Select University/College"
                                                    value={newEdu.id ? newEdu : undefined}
                                                    onChange={(e) => {
                                                        debugger;
                                                        console.log(':: univeristy', e);
                                                        const {label} = e
                                                        let errors = {...this.state.error}
                                                        const newEdu = {...this.state.newEdu}
                                                        newEdu['institute'] = label
                                                        errors['institute'] = ''
                                                        this.setState({newEdu, errors})
                                                    }}
                                                    options={collegeList}
                                                    className="react-select-box"
                                                /> */}
                          <input
                            type="text"
                            className="form-control input-lg"
                            id="institue_name"
                            placeholder="Select University/College"
                            name="institute"
                            value={newEdu.institute}
                            onChange={(e) => {
                              let value = e?.target?.value;
                              let errors = { ...this.state.error };
                              const newEdu = { ...this.state.newEdu };
                              newEdu["institute"] = value;
                              errors["institute"] = "";
                              this.setState({ ...this.state, newEdu, errors });
                            }}
                          />
                          <span
                            className={errors.institute ? "error" : "no-error"}
                          >
                            {errors.institute}
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control input-lg"
                            id="degree_name"
                            placeholder="Type Degree (Btech, Bcom)"
                            name="degree"
                            value={newEdu.degree}
                            onChange={(e) => this.onChange(new_edu, e)}
                          />
                          <span
                            className={errors.degree ? "error" : "no-error"}
                          >
                            {errors.degree}
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>From</label>
                          <select
                            name="from_year"
                            defaultValue={newEdu.from_year}
                            onChange={(e) => {
                              const { name, value } = e.target;
                              e = {
                                ...e,
                                name,
                                value: parseInt(value),
                              };
                              this.onChange(new_edu, e);
                            }}
                            className="form-control input-lg"
                          >
                            {fromYear.map((data, i) => {
                              return (
                                <option
                                  key={i}
                                  value={data}
                                  selected={data === newEdu.from_year}
                                >
                                  {" "}
                                  {data}
                                </option>
                              );
                            })}
                          </select>
                          <span
                            className={errors.from_year ? "error" : "no-error"}
                          >
                            {errors.from_year}
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>To</label>
                          <select
                            name="to_year"
                            defaultValue={newEdu.to_year}
                            onChange={(e) => {
                              const { name, value } = e.target;
                              e = {
                                ...e,
                                name,
                                value: parseInt(value),
                              };
                              this.onChange(new_edu, e);
                            }}
                            className="form-control input-lg"
                          >
                            {toYear.map((data, i) => {
                              return (
                                <option
                                  key={i}
                                  selected={data === newEdu.to_year}
                                >
                                  {data}
                                </option>
                              );
                            })}
                          </select>
                          <span
                            className={errors.to_year ? "error" : "no-error"}
                          >
                            {errors.to_year}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-sm-12">
                      {!isShowEducationUi && savedEducationList.length > 0 ? (
                        <button
                          type="button"
                          className="btn btn-add-more"
                          onClick={() => {
                            this.setState({
                              newEdu: this.eduObj(),
                              isShowEducationUi: true,
                            });
                          }}
                        >
                          {"Add More Education"}
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-add-more"
                          onClick={() => {
                            this.addEducation();
                          }}
                        >
                          {newEdu.id ? "Update Education" : "Add Education"}{" "}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {this.addedEducationList()}
              </div>
            </div>
          </div>
          <div className="d-update-profile-body">
            <div className="row">
              <div className="col-sm-10">
                <h4 className="d-profile-sub-heading">
                  Online Certifications or courses (optional)
                </h4>
                {this.certificatesUi()}
              </div>
            </div>
          </div>
          <div
            className="d-update-profile-body"
            style={{ border: "none", display: "flex" }}
          >
            <div className="row">
              <div className="col-sm-10">
                <button
                  type="button"
                  className="btn btn-save-profile-gig"
                  onClick={() => {
                    this.moveToNextTab(4);
                    // this.setState({active_tab: 4})
                  }}
                >
                  Save & Continue
                </button>
              </div>
            </div>
          </div>
        </form>
        <p>&#x00A0;</p>
      </Fragment>
    );
  };

  saveBioResponse = (resData) => {
    const { bio, data } = this.state;
    bio.bio_caption = resData.professional.bio_caption;
    bio.bio_detailed = resData.professional.bio_detailed;
    bio.linkedin_profile = resData.professional.linkedin_profile;
    data.professional.bio_caption = resData.professional.bio_caption;
    data.professional.bio_detailed = resData.professional.bio_detailed;
    data.professional.linkedin_profile = resData.professional.linkedin_profile;
    this.setState({ bio, data });
  };

  saveBio = () => {
    const bio = { ...this.state.bio };
    const request = {};
    request.professional = bio;
    const errors = {};
    const isFormValidated = validateProfile(errors, request.professional);
    if (isFormValidated) {
      this.setState({ loading: true });
      this.api.auth
        .patch(`/rest-auth/user/`, request)
        .then((resp) => {
          const resData = apiv1.parseAndAutoHandleIssues(
            resp,
            this.props.doDispatch
          );
          if (resData) {
            this.setState({ loading: false, updatedSuccessfully: true }, () => {
              const { onSaveBasicDetails } = this.props;
              onSaveBasicDetails(userDetails(resData));
              $(".modal-header .close").click();
              showSnackbar({ message: updateMessage });
            });
          } else {
            this.setState({ loading: false, updatedSuccessfully: false });
          }
        })
        .catch((err) => {
          const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
          if (resErrors.professional !== undefined && resErrors.professional) {
            for (let key in resErrors.professional) {
              errors[key] = resErrors["professional"][key][0];
            }
            this.setState({
              loading: false,
              updatedSuccessfully: false,
              errors,
            });
          }
        });
    } else {
      this.setState({ errors });
    }
  };
  socialMediaLink = () => {
    const { bio, errors } = this.state;
    return (
      <div className="row work-experience">
        <div className="col-sm-12">
          <p className="panel-text">Linked Profile ( Provide URLs )</p>
        </div>
        <div className="col-sm-6 form-group">
          <input
            type="text"
            className="form-control"
            name="linkedin_profile"
            placeholder="Linkedin"
            value={bio.linkedin_profile}
            style={{
              borderBottom: errors.linkedin_profile ? "1px solid #ff337d" : "",
            }}
            onChange={(e) => this.onChange("bio", e)}
          />
          <span className={errors.linkedin_profile ? "error" : "no-error"}>
            {errors.linkedin_profile}
          </span>
        </div>
        <div className="col-sm-6 form-group">
          <input
            type="text"
            className="form-control"
            name="linked_profile_twitter"
            placeholder="Twitter"
            value={bio.linked_profile_twitter}
            style={{
              borderBottom: errors.linked_profile_twitter
                ? "1px solid #ff337d"
                : "",
            }}
            onChange={(e) => this.onChange("bio", e)}
          />
          <span
            className={errors.linked_profile_twitter ? "error" : "no-error"}
          >
            {errors.linked_profile_twitter}
          </span>
        </div>
        <div className="col-sm-6 form-group">
          <input
            type="text"
            className="form-control"
            name="linked_profile_dribbble"
            placeholder="Dribble"
            value={bio.linked_profile_dribbble}
            style={{
              borderBottom: errors.linked_profile_dribbble
                ? "1px solid #ff337d"
                : "",
            }}
            onChange={(e) => this.onChange("bio", e)}
          />
          <span
            className={errors.linked_profile_dribbble ? "error" : "no-error"}
          >
            {errors.linked_profile_dribbble}
          </span>
        </div>
        <div className="col-sm-6 form-group">
          <input
            type="text"
            className="form-control"
            name="linked_profile_behance"
            placeholder="Behance"
            value={bio.linked_profile_behance}
            style={{
              borderBottom: errors.linked_profile_behance
                ? "1px solid #ff337d"
                : "",
            }}
            onChange={(e) => this.onChange("bio", e)}
          />
          <span
            className={errors.linked_profile_behance ? "error" : "no-error"}
          >
            {errors.linked_profile_behance}
          </span>
        </div>
        <div className="col-sm-6 form-group">
          <input
            type="text"
            className="form-control"
            name="linked_profile_github"
            placeholder="Github"
            value={bio.linked_profile_github}
            style={{
              borderBottom: errors.linked_profile_github
                ? "1px solid #ff337d"
                : "",
            }}
            onChange={(e) => this.onChange("bio", e)}
          />
          <span className={errors.linked_profile_github ? "error" : "no-error"}>
            {errors.linked_profile_github}
          </span>
        </div>
        <div className="col-sm-6 form-group">
          <input
            type="text"
            className="form-control"
            name="linked_profile_upwork"
            placeholder="Facebook"
            value={bio.linked_profile_upwork}
            style={{
              borderBottom: errors.linked_profile_upwork
                ? "1px solid #ff337d"
                : "",
            }}
            onChange={(e) => this.onChange("bio", e)}
          />
          <span className={errors.linked_profile_upwork ? "error" : "no-error"}>
            {errors.linked_profile_upwork}
          </span>
        </div>
        <div className="col-sm-6 form-group">
          <input
            type="text"
            className="form-control"
            name="linked_profile_fiverr"
            placeholder="Instagram"
            value={bio.linked_profile_fiverr}
            style={{
              borderBottom: errors.linked_profile_fiverr
                ? "1px solid #ff337d"
                : "",
            }}
            onChange={(e) => this.onChange("bio", e)}
          />
          <span className={errors.linked_profile_fiverr ? "error" : "no-error"}>
            {errors.linked_profile_fiverr}
          </span>
        </div>
        <div className="col-sm-6 form-group">
          <input
            type="text"
            className="form-control"
            name="linked_profile_other"
            placeholder="Other"
            value={bio.linked_profile_other}
            style={{
              borderBottom: errors.linked_profile_other
                ? "1px solid #ff337d"
                : "",
            }}
            onChange={(e) => this.onChange("bio", e)}
          />
          <span className={errors.linked_profile_other ? "error" : "no-error"}>
            {errors.linked_profile_other}
          </span>
        </div>
      </div>
    );
  };
  bioUi = () => {
    const { bio, data, errors, loading, professionalType } = this.state;
    return (
      <div className="modal fade" id="brief_bio" role="dialog">
        <div className="modal-dialog dialog-top-margin">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => {
                  const bio = this.bioObj(data);
                  const errors = {};
                  this.setState({ bio, updatedSuccessfully: false, errors });
                }}
              >
                &times;
              </button>
              <h3 className="modal-title">Bio</h3>
            </div>
            <div className="modal-body">
              <form className="signup-form">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control input-lg"
                        id="bio_caption"
                        name="bio_caption"
                        placeholder="Caption under your name"
                        value={bio.bio_caption}
                        style={{
                          borderBottom: errors.bio_caption
                            ? "1px solid #ff337d"
                            : "",
                        }}
                        onChange={(e) => this.onChange("bio", e)}
                      />
                      <span
                        className={errors.bio_caption ? "error" : "no-error"}
                      >
                        {errors.bio_caption}
                      </span>
                    </div>
                    <p>&#x00A0;</p>
                    <div className="form-group">
                      <p className="panel-text">Biography</p>
                      <div className="tutorial-box">
                        <textarea
                          rows="8"
                          className="form-control"
                          id="biography"
                          name="bio_detailed"
                          placeholder={
                            professionalType ===
                            professional_type.legalAndAccounting
                              ? "Ex. Raghav Arora is the Founder & Partner of Raghav Arora & Associates, where his areas of practice include Business and Corporate Law, Contracts, Immigration Law, General Litigation and Income Tax. Raghav holds a J.D. from Harvard Law School.  He is admitted to the Bar Council of Delhi. as well as the Bar of the US District Court for the Northern District of New York. In his 7 years of work experience, he has represented several notable clients and worked with reputed law firms. He is also a Chartered Accountant having strong expertise in Direct Taxes.\n" +
                                "Raghav has assisted several clients from startups to fortune companies during his tenure and looks forward to addressing any Legal matter coming his way."
                              : "Graphic Design is my Passion. I've been doing graphic designing for more than 6 years. I'm naturally very creative and apply new ideas to my design. I specialise in Logo, Brand Design and Package Design. I have worked for clients like PayTM and Reliance.  Check out my portfolio and buy my services on Workbeam.  Hire me to give your business an attractive and modern design."
                          }
                          style={{
                            borderBottom: errors.bio_detailed
                              ? "1px solid #ff337d"
                              : "",
                          }}
                          value={bio.bio_detailed}
                          onChange={(e) => this.onChange("bio", e)}
                        ></textarea>
                        <span
                          className={errors.bio_detailed ? "error" : "no-error"}
                        >
                          {errors.bio_detailed}
                        </span>
                        <div
                          className="tutorial-tips"
                          style={{ right: "-270px" }}
                        >
                          <p className="tutorial-para">
                            Write about yourself, give an introduction, tell
                            about your skills, experience and what you are good
                            at.
                          </p>
                          <p className="tutorial-para">Example:</p>
                          <p className="tutorial-link">
                            <a
                              href={path.article3}
                              target="_blank"
                              style={{
                                color: "#2b1de6",
                                fontStyle: "normal",
                              }}
                            >
                              http://www.workbeam.com/knowledge-base/article/profile-bio-and-product-descriptions
                            </a>
                          </p>
                        </div>
                      </div>
                      <p className="text-note" style={{ marginTop: "10px" }}>
                        Note: Please do not mention personal details like phone
                        number, address, social profiles, company name
                      </p>
                    </div>
                  </div>
                </div>
              </form>
              <p>&#x00A0;</p>
              {this.socialMediaLink()}
            </div>
            {loading && (
              <div
                className="lazy-loader"
                style={{ position: "fixed", zIndex: "1" }}
              >
                <LoadingComponent pastDelay />
              </div>
            )}
            <div className="modal-footer" style={{ textAlign: "center" }}>
              <button className="btn btn-save-profile" onClick={this.saveBio}>
                Save to my profile
              </button>
            </div>
            <br />
          </div>
        </div>
      </div>
    );
  };

  saveConsultationFee = () => {
    const request = { ...this.state.consultationFee };
    const id = this.props.data.professional.id;
    const errors = {};
    this.setState({ errors });
    if (
      experienceFieldValidator(
        errors,
        request.consultation_fee,
        "consultation_fee"
      )
    ) {
      this.setState({ loading: true });
      this.api.auth
        .patch(`/users/professionalprofile/${id}/`, request)
        .then((resp) => {
          const resData = apiv1.parseAndAutoHandleIssues(
            resp,
            this.props.doDispatch
          );
          if (resData) {
            const consultationFee = {};
            consultationFee.consultation_fee = resData.consultation_fee;
            const data = { ...this.state.data };
            data.professional.consultation_fee = resData.consultation_fee;
            this.setState({
              consultationFee,
              data,
              loading: false,
              updatedSuccessfully: true,
            });
            $(".modal-header .close").click();
            showSnackbar({ message: updateMessage });
          } else {
            this.setState({ loading: false, updatedSuccessfully: false });
          }
        })
        .catch((err) => {
          const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
          for (let key in resErrors) {
            errors[key] = resErrors[key][0];
          }
          this.setState({ loading: false, updatedSuccessfully: false, errors });
        });
    }
  };

  consultationFeesUi = () => {
    const { consultationFee, loading, errors } = this.state;
    return (
      <div className="modal fade" id="consultation_fee" role="dialog">
        <div className="modal-dialog dialog-top-margin">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => {
                  const consultationFee = this.consultationFeeObj(
                    this.state.data
                  );
                  this.setState({
                    consultationFee,
                    updatedSuccessfully: false,
                    errors: {},
                  });
                }}
              >
                &times;
              </button>
              <h3 className="modal-title">Consultation fee</h3>
            </div>

            <div className="modal-body">
              <div className="col-sm-12">
                <p className="para">
                  Please enter your consultation fees per hour.
                </p>
                <p className="para">
                  We encourage putting a reasonable fee between $1000 to Maximum
                  $4000.
                </p>
                <p className="para">
                  Average Consultation Fees is $1200. We want to focus more on
                  getting more Client Volume for you targeting PAN India.
                </p>
              </div>
              <form className="signup-form">
                <div className="col-sm-5">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control input-lg"
                      id="fees"
                      name="consultation_fee"
                      value={
                        consultationFee.consultation_fee === "0.00"
                          ? ""
                          : consultationFee.consultation_fee
                      }
                      placeholder="Consultation fee"
                      style={{
                        borderBottom: errors.consultation_fee
                          ? "1px solid #ff337d"
                          : "",
                      }}
                      onChange={(e) => {
                        const errors = {};
                        if (isNaN(e.target.value)) {
                          errors["consultation_fee"] =
                            "Only integer value is allowed";
                        }
                        const consultationFee = {
                          ...this.state.consultationFee,
                        };
                        consultationFee[e.target.name] = e.target.value;
                        this.setState({ errors, consultationFee });
                      }}
                    />{" "}
                    <span className="xs-small-text pull-right">Per hour</span>
                    <br />
                    <span
                      className={errors.consultation_fee ? "error" : "no-error"}
                    >
                      {errors.consultation_fee}
                    </span>
                  </div>
                </div>
              </form>
            </div>
            {loading && (
              <div
                style={{
                  marginLeft: "45%",
                  marginTop: "5%",
                  position: "fixed",
                  zIndex: "1",
                }}
              >
                <LoadingComponent pastDelay />
              </div>
            )}
            <div className="clearfix" />
            <div className="modal-footer">
              <div className="col-sm-12">
                <button
                  className="btn btn-login"
                  onClick={this.saveConsultationFee}
                >
                  Save
                </button>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    );
  };

  addRemoveCourts = (id) => {
    const selectedCourtIds = [...this.state.selectedCourtIds];
    const index = selectedCourtIds.indexOf(id);
    if (index > -1) {
      selectedCourtIds.splice(index, 1);
    } else {
      selectedCourtIds.push(id);
    }
    this.setState({ selectedCourtIds });
  };

  savePracticeArea = (request) => {
    return this.api.auth.patch(apiUrl.restAuth, request);
  };

  updatedSuccessfullyUi = () => {
    return (
      <div className="msg msg-success msg-success-text">
        <span className="glyphicon glyphicon-ok" /> Success
      </div>
    );
  };

  getCourts = () => {
    this.setState({ loading: true });
    this.api.auth
      .get("/users/court/")
      .then((resp) => {
        const data = apiv1.parseAndAutoHandleIssues(
          resp,
          this.props.doDispatch
        );
        if (data) {
          const selectedCourtIds = [...this.state.selectedCourtIds];
          const stateWiseCourts = data.filter((obj) => {
            const { id } = obj;
            return selectedCourtIds.indexOf(id) > -1;
          });
          this.setState(
            { allIndianCourts: data, loading: false, stateWiseCourts },
            () => {
              $("#court_ui_popup").click();
            }
          );
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        apiv1.handleErrors(err, this.props.doDispatch);
      });
  };

  filterCortStateWise = (e, stateId) => {
    const { allIndianCourts } = this.state;
    const stateWiseCourts = allIndianCourts.filter(
      (obj) => obj.state === stateId
    );
    this.setState({
      stateWiseCourts,
      selectedStateInCourt: e.target.children[stateId.toString()].id,
    });
  };

  saveCourts = () => {
    const { selectedCourtIds } = this.state;
    const request = {
      professional: {
        court: selectedCourtIds,
      },
    };
    this.setState({ loading: true });
    this.api.auth
      .patch(apiUrl.restAuth, request)
      .then((resp) => {
        const resData = apiv1.parseAndAutoHandleIssues(
          resp,
          this.props.doDispatch
        );
        if (resData) {
          let data = { ...this.state.data };
          data.professional.court = selectedCourtIds;
          this.setState({
            data,
            selectedCourtIds,
            loading: false,
            updatedSuccessfully: true,
          });
          $(".modal-header .close").click();
          showSnackbar({ message: updateMessage });
        } else {
          this.setState({ loading: false, updatedSuccessfully: false });
        }
      })
      .catch((err) => {
        apiv1.handleErrors(err, this.props.doDispatch);
        this.setState({ loading: false, updatedSuccessfully: false }, () => {
          $(".modal-header .close").click();
        });
      });
  };

  courtsUi = () => {
    const {
      geoState,
      stateWiseCourts,
      selectedCourtIds,
      data,
      loading,
      selectedStateInCourt,
    } = this.state;
    return (
      <div className="modal-dialog dialog-top-margin">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => {
                const selectedCourtIds =
                  data.professional.court !== undefined &&
                  data.professional.court
                    ? [...data.professional.court]
                    : [];
                $("#selected_state_for_court").val(-1);
                const { allIndianCourts } = this.state;
                const selectedStateInCourt = "State";
                const stateWiseCourts = allIndianCourts.filter((obj) => {
                  if (selectedCourtIds.indexOf(obj.id) !== -1) {
                    return obj;
                  }
                });
                this.setState({
                  selectedCourtIds,
                  stateWiseCourts,
                  selectedStateInCourt,
                });
              }}
            >
              &times;
            </button>
            <h3 className="modal-title">Courts</h3>
          </div>
          <div className="modal-body">
            <p className="para">
              Please select courts where you practice. We have filtered all
              courts state wise.
            </p>
            <div className="col-sm-12">
              <div className="form-group">
                <select
                  className="form-control"
                  name="state"
                  id="selected_state_for_court"
                  onChange={(e) => {
                    this.filterCortStateWise(e, Number(e.target.value));
                  }}
                >
                  <option value="-1" id="court_state_text" className="hidden">
                    State
                  </option>
                  {geoState.map((eachState) => {
                    return (
                      <option
                        value={eachState.id}
                        id={eachState.name}
                        key={eachState.id}
                      >
                        {eachState.name}
                      </option>
                    );
                  })}
                </select>
                <div className="court-container">
                  <ul className="list-inline">
                    {stateWiseCourts.map((court) => {
                      return (
                        <li
                          className={
                            "background-white" +
                            (selectedCourtIds.indexOf(court.id) !== -1
                              ? " background-pink"
                              : "")
                          }
                          key={court.id}
                          id={court.id}
                          onClick={() => {
                            $("#" + court.id).toggleClass("background-pink");
                            this.addRemoveCourts(court.id);
                          }}
                        >
                          <span>{court.name}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {loading && (
              <div
                style={{
                  marginLeft: "40%",
                  marginTop: "50%",
                  position: "fixed",
                  zIndex: "1",
                }}
              >
                <LoadingComponent pastDelay />
              </div>
            )}
            <div className="modal-footer" style={{ textAlign: "center" }}>
              <button
                className="btn btn-login"
                onClick={(e) => this.saveCourts(e)}
              >
                Save
              </button>
            </div>
            <br />
          </div>
        </div>
      </div>
    );
  };

  onClickVerifyEmail = () => {
    this.setState({ loading: true });
    this.api.auth
      .post("/users/email/resend-confirmation")
      .then((resp) => {
        const data = apiv1.parseAndAutoHandleIssues(
          resp,
          this.props.doDispatch
        );
        if (data.success) {
          this.setState({ loading: false });
          $("#verify_text").removeClass("hidden");
        } else {
          this.setState({ loading: false });
          $("#verify_text").html("Please try again.").removeClass("hidden");
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        $("#verify_text").html("Please try again.").removeClass("hidden");
        apiv1.handleErrors(err, this.props.doDispatch);
      });
  };

  basicDetailsUi = () => {
    let {
      basicDetails,
      errors,
      loading,
      gst_number,
      metaLanguage,
      number_of_employee,
      professionalType,
      isOtpSent,
      languages,
      metadata,
      pan_number,
      company_name,
      price_per_hour,
      price_per_word,
      is_agency,
      is_part_time,
      userProfessions,
      is_super_user,
      professions,
      isContentWriter,
      isWebDeveloper,
      work_experience,
    } = this.state;
    const { minimum_project_size } = metadata;
    let avatar = getAvatar(basicDetails.avatar);
    let firstName = basicDetails.first_name;
    let lastName = basicDetails.last_name;
    return (
      <Fragment>
        <div className="d-update-profile-body">
          <div className="row">
            <div className="col-sm-11">
              {loading && (
                <div
                  className="lazy-loader"
                  style={{ zIndex: "10", position: "fixed" }}
                >
                  <LoadingComponent pastDelay />
                </div>
              )}
              <div className="row basic-detail-form">
                <div className="col-sm-12 form-group">
                  <p>
                    <label>
                      Tap to Upload or Edit your DP - Make Sure you have a neat
                      and a professional DP
                    </label>
                  </p>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      $("#professional-dp").click();
                    }}
                  >
                    {avatar ? (
                      <img
                        src={avatar}
                        alt="user"
                        className="img-circle image-size"
                      />
                    ) : (
                      getFirstAndLastLetterOfName(firstName, lastName)
                    )}
                  </span>
                  <p style={{ marginBottom: "0px" }}>
                    <label
                      className="btn-bs-file"
                      style={{ cursor: "pointer", marginTop: "0px" }}
                      id="professional-dp"
                    >
                      <FileBase64 onDone={this.uploadImage.bind(this)} />
                    </label>
                  </p>
                </div>
                <div className="clearfix" />
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      className="form-control
                                                       input-lg"
                      id="first_name"
                      name="first_name"
                      placeholder="First Name"
                      value={basicDetails.first_name}
                      onChange={(e) => this.onChange(basicDetail, e)}
                      style={{
                        border: errors.first_name ? "1px solid #ff337d" : "",
                      }}
                    />
                    <span className={errors.first_name ? "error" : "no-error"}>
                      {errors.first_name}
                    </span>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      type="text"
                      className="form-control input-lg"
                      id="last_name"
                      name="last_name"
                      placeholder="Last Name"
                      value={basicDetails.last_name}
                      onChange={(e) => this.onChange(basicDetail, e)}
                      style={{
                        border: errors.last_name ? "1px solid #ff337d" : "",
                      }}
                    />
                    <span className={errors.last_name ? "error" : "no-error"}>
                      {errors.last_name}
                    </span>
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control input-lg"
                      id="email"
                      name="email"
                      placeholder="Email"
                      disabled={true}
                      value={basicDetails.email}
                      onChange={(e) => this.onChange(basicDetail, e)}
                      style={{
                        border: errors.email ? "1px solid #ff337d" : "",
                      }}
                    />

                    {this.props.data.basicDetails.email_verified !==
                      undefined &&
                    this.props.data.basicDetails.email_verified === true ? (
                      <img
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "15px",
                        }}
                        className="btn-link"
                        src={successImage}
                        id="verified_`"
                        alt="verified"
                      />
                    ) : (
                      <span
                        className="btn btn-link"
                        id="verify_email"
                        onClick={() => {
                          this.onClickVerifyEmail();
                        }}
                      >
                        Verify
                      </span>
                    )}
                    <span className={errors.email ? "error" : "no-error"}>
                      {errors.email}
                    </span>
                    <p
                      className="text-prompt text-right hidden"
                      id="verify_text"
                    >
                      We have sent a verification link on your email. Remember
                      to check your Spam Folder
                    </p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Mobile Number </label>
                    <PhoneInputWithCountrySelect
                      id="mobile_number"
                      name="phone"
                      className="form-control input-lg"
                      placeholder="Mobile E.g. 9999999999 "
                      value={
                        basicDetails.phone !== undefined
                          ? basicDetails.phone
                          : ""
                      }
                      defaultCountry="CA"
                      countryCallingCodeEditable={false}
                      onChange={(e) => {
                        this.onChange(basicDetail, {
                          target: { value: e, name: "phone" },
                        });
                      }}
                      style={{
                        border: errors.phone ? "1px solid #ff337d" : "",
                      }}
                    />
                    {/* <input type="text"
                                               className="form-control input-lg"
                                               id="mobile_number"
                                               name="phone"
                                               placeholder="Mobile E.g. 9999999999 "
                                               value={basicDetails.phone !== undefined ? basicDetails.phone : ""}
                                               onChange={(e) => this.onChange(basicDetail, e)}
                                               style={{border: errors.phone ? '1px solid #ff337d' : ''}}
                                        /> */}
                    {/* {JSON.stringify(this.state.data.professional.phone)} */}
                    {/* NOTE: verify phone commented for now */}
                    {/* {this.state.data.professional.phone &&
                    this.state.data.professional.phone.verified ? (
                      <img
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "15px",
                        }}
                        className="btn-link"
                        src={successImage}
                        id="verified_phone"
                        alt="verified"
                      />
                    ) : (
                      <span
                        className="btn btn-link"
                        id="otp-box"
                        onClick={() => this.updatePhoneNo()}
                      >
                        Verify
                      </span>
                    )} */}
                    <span className={errors.phone ? "error" : "no-error"}>
                      {errors.phone}
                    </span>
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-sm-12 otp-box hidden">
                  <div
                    className="form-group col-xs-6 col-xs-offset-3"
                    style={{ marginBottom: "10px" }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      name="otp"
                      value={this.state.otp}
                      onChange={this.changeOtp}
                    />
                    <span className={errors.otp ? "error" : "no-error"}>
                      {errors.otp}
                    </span>
                  </div>
                  <div className="clearfix" />
                  <div className="text-center">
                    <a
                      className="btn btn-link"
                      id="otp-box"
                      onClick={this.VerifyOtpWitCountryCodeApiOnWizcounsel}
                    >
                      Verify
                    </a>
                    <a className="btn btn-link" onClick={this.updatePhoneNo}>
                      Resend OTP
                    </a>
                  </div>
                  <div className="clearfix" />
                  <h4 className="text-dark text-center">
                    Enter 6 digit OTP sent on your mobile number.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-update-profile-body">
          <div className="row basic-detail-form">
            <div className="col-sm-11">
              <div className="form-group">
                <label>
                  Work Experience <span className="star-note">*</span>
                </label>
                <div className="work-exp-input">
                  <div className="input-field">
                    <input
                      type="number"
                      className="form-control input-lg"
                      id="experience"
                      placeholder="Years"
                      name="work_experience"
                      value={work_experience}
                      onChange={(e) => {
                        const errors = {};
                        const inputValue = e.target.value;
                        const message = "Only integer value is allowed";

                        if (parseInt(inputValue) < 0) {
                          errors.work_experience =
                            "Negative numbers are not allowed";
                        } else if (inputValue.includes(".")) {
                          errors.work_experience = message;
                        } else {
                          errors.work_experience = "";
                          this.setState({ [e.target.name]: inputValue });
                        }
                      }}
                    />
                    <span
                      className={errors.work_experience ? "error" : "no-error"}
                    >
                      {errors.work_experience}
                    </span>
                  </div>
                  <div className="text-suggetion-area">
                    <p className="para">
                      Please input full years of work experience. We verify your
                      profile, past projects and proof of work based on your
                      experience.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-update-profile-body">
          <div className="row">
            <div className="col-sm-11">
              <div className="row basic-detail-form">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Service Categories</label>
                    <Select
                      name="profession"
                      closeMenuOnSelect={true}
                      value={userProfessions}
                      isMulti={true}
                      // isDisabled={!is_super_user && userProfessions && userProfessions.length === 1}
                      placeholder="Select business practice"
                      onChange={(e) => {
                        this.onChangeMultiSelect(e);
                      }}
                      options={professions}
                      className="react-select-box"
                    />
                  </div>
                  <span className={errors.profession ? "error" : "no-error"}>
                    {errors.profession}
                  </span>
                </div>
                <div className="col-sm-12">
                  {/* <div className="form-group">
                                        <label>Hourly Price <span className="star-note">*</span></label>
                                        <select className="form-control input-lg"
                                                name={'price_per_hour'}
                                                onChange={(e) => {
                                                    let {target} = e
                                                    const {name, value} = target
                                                    target = {
                                                        ...target,
                                                        name,
                                                        value: parseInt(value)
                                                    }
                                                    e = {
                                                        ...e,
                                                        target
                                                    }
                                                    this.onChangeInputType(e)
                                                }}
                                        >
                                            <option value={''} disabled={true} selected={true}>Select hourly price
                                            </option>
                                            {hourlyPrice.map((toPrice, i) => {
                                                return <option value={toPrice}
                                                               selected={toPrice === price_per_hour}>${toPrice}</option>
                                            })}
                                        </select>
                                        <span
                                            className={errors.price_per_hour ? 'error' : 'no-error'}>{errors.price_per_hour}</span>
                                    </div> */}
                </div>
                {/* {isWebDeveloper &&
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label>Project Size</label>
                                        <select className="form-control input-lg"
                                                name={'minimum_project_size'}
                                                onChange={(e) => {
                                                    let {target} = e
                                                    const {name, value} = target
                                                    target = {
                                                        ...target,
                                                        name,
                                                        value
                                                    }
                                                    e = {
                                                        ...e,
                                                        target
                                                    }
                                                    this.onChangeProjectSize(e)
                                                }}
                                        >
                                            <option value={''} disabled={true} selected={true}>What is your minimum
                                                project size?
                                            </option>
                                            {projectSize.map((toPrice, i) => {
                                                return <option value={toPrice}
                                                               selected={toPrice === minimum_project_size}>${toPrice} +</option>
                                            })}
                                        </select>
                                        <span
                                            className={errors.minimum_project_size ? 'error' : 'no-error'}>{errors.minimum_project_size}</span>
                                    </div>
                                </div>
                                } */}
                {/* {isContentWriter &&
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label>Price per word</label>
                                        <select
                                            className="form-control input-lg"
                                            name={'price_per_word'}
                                            onChange={this.onChangeInputType}
                                        >
                                            <option value={''} disabled={true} selected={true}>Select price per word
                                            </option>
                                            {pricePerWord.map(details => {
                                                const {text, value} = details
                                                return <option value={value}
                                                               selected={price_per_word === value}>{text}</option>
                                            })}
                                        </select>
                                        <span
                                            className={errors.price_per_word ? 'error' : 'no-error'}>{errors.price_per_word}</span>
                                    </div>
                                </div>
                                } */}
              </div>
            </div>
          </div>
        </div>
        <div className="d-update-profile-body">
          <div className="row">
            <div className="col-sm-11">
              <div className="row basic-detail-form">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Select Conversational Language</label>
                    {languages.map((languageData, index) => {
                      const { id, label, name } = languageData;
                      let indexOfSelectedLan = metaLanguage.findIndex(
                        (details) => details.id === id
                      );
                      let lanDetails = {};
                      if (indexOfSelectedLan > -1) {
                        lanDetails = metaLanguage[indexOfSelectedLan];
                      }
                      return (
                        <ul
                          className="list-inline select-language-list"
                          key={index}
                        >
                          <li className="col-xs-2">{name}</li>
                          <li>
                            <RadioGroup
                              value={lanDetails.level}
                              onChange={(e) => {
                                const { value } = e.target;
                                let selectedMetaLanguage = {
                                  id: id,
                                  name: label,
                                  level: value,
                                };
                                if (lanDetails.id) {
                                  metaLanguage[indexOfSelectedLan] = {
                                    ...selectedMetaLanguage,
                                  };
                                } else {
                                  metaLanguage.push({
                                    ...selectedMetaLanguage,
                                  });
                                }
                                this.setState({ metaLanguage });
                              }}
                              className="inlineRadio"
                            >
                              {[
                                { label: "Basic", value: "basic" },
                                {
                                  label: "Fluent",
                                  value: "fluent",
                                },
                                {
                                  label: "Proficient",
                                  value: "proficient",
                                },
                              ].map((opt, indx) => {
                                const { label, value } = opt;
                                return (
                                  <FormControlLabel
                                    key={label}
                                    value={value}
                                    control={<Radio />}
                                    label={label}
                                  />
                                );
                              })}
                            </RadioGroup>
                          </li>
                          {/*<li><a className="btn btn-add-more" style={{marginTop: '0px'}}*/}
                          {/*onClick={this.saveMetaLanguages}>Save</a></li>*/}
                        </ul>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-update-profile-body">
          <div className="row">
            <div className="col-sm-11">
              <div className="row basic-detail-form">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label style={{ marginBottom: "20px" }}>Are you a?</label>
                    <RadioGroup
                      className="inlineRadio"
                      value={
                        is_agency
                          ? AgencyOrFirm
                          : is_part_time
                          ? PartTimeFreelancer
                          : FullTimeFreelancer
                      }
                      onChange={(e) => {
                        const { value } = e.target;
                        let is_agency = value === AgencyOrFirm;
                        let is_part_time = value === PartTimeFreelancer;
                        this.setState({ is_agency, is_part_time });
                      }}
                    >
                      {[
                        PartTimeFreelancer,
                        FullTimeFreelancer,
                        AgencyOrFirm,
                      ].map((opt, indx) => {
                        return (
                          <FormControlLabel
                            key={indx}
                            value={opt}
                            control={<Radio />}
                            label={opt}
                          />
                        );
                      })}
                    </RadioGroup>
                  </div>
                </div>
                {is_agency && (
                  <Fragment>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>
                          Enter Business Firm Name or Your Name if you're a
                          Freelancer
                        </label>
                        <input
                          type="text"
                          className="form-control input-lg"
                          id="firm_name"
                          name="company_name"
                          placeholder="Enter Business Firm Name or Your Name if you're a Freelancer"
                          value={company_name ? company_name : ""}
                          style={{
                            border: errors.company_name
                              ? "1px solid #ff337d"
                              : "",
                          }}
                          onChange={(e) => {
                            const errors = { ...this.state.errors };
                            errors["company_name"] = "";
                            this.setState({
                              [e.target.name]: e.target.value,
                              errors,
                            });
                          }}
                        />
                        <span
                          className={errors.company_name ? "error" : "no-error"}
                        >
                          {errors.company_name}
                        </span>
                      </div>
                      <div className="form-group">
                        <label>Number of Employees</label>
                        <input
                          type="text"
                          className="form-control input-lg"
                          id="number_of_employee"
                          name="number_of_employee"
                          placeholder="Number of Employees"
                          value={number_of_employee}
                          style={{
                            border: errors.number_of_employee
                              ? "1px solid #ff337d"
                              : "",
                          }}
                          onChange={this.onChangeInputType}
                        />
                        <span
                          className={
                            errors.number_of_employee ? "error" : "no-error"
                          }
                        >
                          {errors.number_of_employee}
                        </span>
                      </div>
                    </div>
                  </Fragment>
                )}
                {professionalType === professional_type.legalAndAccounting && (
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Membership Id/Enrolment Id.</label>
                      <input
                        type="text"
                        className="form-control input-lg"
                        id="m_id"
                        name="membership_id"
                        placeholder="Membership Id/Enrolment Id. (optional)"
                        value={
                          this.state.membership_id
                            ? this.state.membership_id
                            : ""
                        }
                        onChange={(e) => {
                          const errors = { ...this.state.errors };
                          errors["membership_id"] = "";
                          this.setState({
                            membership_id: e.target.value,
                            errors,
                          });
                        }}
                      />
                      <span className="btn btn-link" style={{ top: "35px" }}>
                        <i className="fa fa-info-circle" aria-hidden="true" />
                      </span>
                    </div>
                  </div>
                )}
                {/* <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Pan Number <span className="star-note">*</span></label>
                                        <input type="text" className="form-control input-lg"
                                               id="pan_na"
                                               name="pan_number"
                                               placeholder="Mandatory for Billing on your behalf"
                                               value={pan_number ? pan_number : ''}
                                               style={{border: errors.pan_number ? '1px solid #ff337d' : ''}}
                                               onChange={(e) => {
                                                   const {name, value} = e.target
                                                   const errors = {...this.state.errors};
                                                   errors[name] = '';
                                                   this.setState({[name]: value, errors})
                                               }}
                                        />
                                        <span class="small text-muted">
                                            Ex: <strong>ABCTY1234D</strong>
                                        </span>
                                        <span
                                            className={errors.pan_number ? 'error' : 'no-error'}>{errors.pan_number}</span>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>GST No. (Optional)</label>
                                        <input type="text" className="form-control input-lg"
                                               id="gst_id"
                                               name="gst_number"
                                               placeholder="GST No. (optional)"
                                               value={gst_number ? gst_number : ''}
                                               style={{border: errors.gst_number ? '1px solid #ff337d' : ''}}
                                               onChange={(e) => {
                                                   const errors = {...this.state.errors};
                                                   errors['gst_number'] = '';
                                                   this.setState({gst_number: e.target.value, errors})
                                               }}
                                        />
                                        <span
                                            className={errors.gst_number ? 'error' : 'no-error'}>{errors.gst_number}</span>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="d-update-profile-body">
          <div className="row">
            <div className="col-sm-11">
              {this.workAddressUi()}
              <button
                type="button"
                className="btn btn-save-profile-gig"
                onClick={this.updateBasicDetails}
              >
                Save & Continue
              </button>
              <p>&#x00A0;</p>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  getEducation = () => {
    this.setState({ loading: true });
    this.api.auth
      .get(`users/education/`)
      .then((resp) => {
        const resData = apiv1.parseAndAutoHandleIssues(
          resp,
          this.props.doDispatch
        );
        if (resData) {
          this.setState({ savedEducationList: resData, loading: false });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        apiv1.handleErrors(err, this.props.doDispatch);
      });
  };

  checkValidDocument = ({ file }) => {
    const { base64, name } = file;
    let document_base64 = "";
    let document_file_name = name;
    let isDocumentValid = false;
    for (let i = 0; i < allowedDocumentType.length; i++) {
      if (base64.includes(allowedDocumentType[i])) {
        document_base64 = base64.replace(allowedDocumentType[i], "");
        isDocumentValid = true;
      }
    }
    if (document_base64 === "") {
      alert("not a valid type document");
    }
    return { isDocumentValid, document_base64, document_file_name };
  };

  uploadDocuments = (file, documentId) => {
    const userDocuments = [...this.state.userDocuments];
    let { data } = this.props;
    const errors = {};
    data = data || {};
    let { professional } = data;
    professional = professional || {};
    const { isDocumentValid, document_base64, document_file_name } =
      this.checkValidDocument({ file });
    if (!isDocumentValid) {
      return;
    }
    const request = {
      document_base64,
      document_type: documentId,
      professional: professional.id,
      document_file_name,
    };

    this.setState({ loading: true });
    const uploadedDocument = userDocuments.find(
      (obj) => obj.document_type === documentId
    );
    if (uploadedDocument === undefined) {
      this.api.auth
        .post("/users/professionaldocument/", request)
        .then((resp) => {
          const data = apiv1.parseAndAutoHandleIssues(
            resp,
            this.props.doDispatch
          );
          if (data) {
            userDocuments.push(data);
            const { basicDetails } = this.state;
            let { professional } = this.state.data;
            professional = professional || {};
            let profilePercentage = calculatePercentageProfile({
              professional,
              basicDetails,
              verificationDocuments: userDocuments,
            });
            this.setState({
              loading: false,
              updatedSuccessfully: true,
              userDocuments,
              profilePercentage,
            });
            showSnackbar({ message: uploadDocumentMessage });
          } else {
            this.setState({ loading: false, updatedSuccessfully: false });
          }
        })
        .catch((err) => {
          const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
          for (let key in resErrors) {
            errors[key] = resErrors[key][0];
          }
          this.setState({ loading: false, updatedSuccessfully: false, errors });
        });
    } else {
      this.api.auth
        .patch(`/users/professionaldocument/${uploadedDocument.id}/`, request)
        .then((resp) => {
          const data = apiv1.parseAndAutoHandleIssues(
            resp,
            this.props.doDispatch
          );
          if (data) {
            userDocuments[userDocuments.indexOf(uploadedDocument)] = data;
            this.setState({
              loading: false,
              updatedSuccessfully: true,
              userDocuments,
            });
            showSnackbar({ message: uploadDocumentMessage });
          } else {
            this.setState({ loading: false, updatedSuccessfully: false });
          }
        })
        .catch((err) => {
          const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
          for (let key in resErrors) {
            errors[key] = resErrors[key][0];
          }
          this.setState({ loading: false, updatedSuccessfully: false, errors });
        });
    }
  };

  onChangeMultiSelect = (e) => {
    let userProfessions = [...e];
    let userProfessionsIds = userProfessions.map((details) => details.id);
    const errors = { ...this.state.errors };
    let isContentWriter = userProfessionsIds.indexOf(10) > -1;
    errors["profession"] = "";
    this.setState({
      userProfessionsIds,
      userProfessions,
      errors,
      isContentWriter,
    });
  };

  getSelectedLanguages = ({ languages, language }) => {
    return {
      language: language.map((languageId) => {
        return languages.find(
          (languageDetails) => languageDetails.id === languageId
        );
      }),
    };
  };

  onChangeMultiSelectLanguage = (e) => {
    const { name, value } = e.target;
    const errors = { ...this.state.errors };
    errors[name] = "";
    this.setState({ [name]: value, errors });
  };

  onChangeInputType = (e) => {
    const { name, value } = e.target;
    const errors = { ...this.state.errors };
    errors[name] = "";
    this.setState({ [name]: value, errors });
  };

  onChangeProjectSize = (e) => {
    const { name, value } = e.target;
    const errors = { ...this.state.errors };
    let metadata = { ...this.state.metadata };
    metadata = {
      ...metadata,
      [name]: value,
    };
    errors[name] = "";
    this.setState({ metadata, errors });
  };

  businessDetailsAndWorkAddress = () => {
    const {
      company_name,
      company_type,
      price_per_hour,
      price_per_word,
      membership_id,
      gst_number,
      number_of_employee,
    } = this.state;
    let professional = {
      company_name,
      price_per_hour,
      price_per_word,
      membership_id,
      company_type,
      gst_number,
    };
    // professional['profession'] = userProfessionsIds
    if (number_of_employee) {
      professional["number_of_employee"] = number_of_employee;
    }
    const errors = {};
    const request = {
      professional,
    };
    const validatorObj = { ...professional };
    // validatorObj.address.state = state_data.id === undefined ? '' : state_data.id;
    if (businessDetailsValidator(errors, validatorObj)) {
      this.setState({ loading: true });
      this.api.auth
        .patch(apiUrl.restAuth, request)
        .then((resp) => {
          const data = apiv1.parseAndAutoHandleIssues(
            resp,
            this.props.doDispatch
          );
          if (data) {
            this.setState({ loading: false, updatedSuccessfully: true });
            this.props.onUserDetails(userDetails(data));
            $(".modal-header .close").click();
            showSnackbar({ message: updateMessage });
          } else {
            this.setState({ loading: false, updatedSuccessfully: false });
          }
        })
        .catch((err) => {
          const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
          for (let key in resErrors) {
            errors[key] = resErrors[key][0];
          }
          this.setState({ loading: false, updatedSuccessfully: false, errors });
        });
    } else {
      this.setState({ errors });
    }
  };

  downloadDocument = (documentId) => {
    const { userDocuments } = this.state;
    const document = userDocuments.find(
      (obj) => obj.document_type === documentId
    );
    return document !== undefined ? (
      <a href={document.document} target="_blank">
        <span className="text-blue">{document.document_file_name}</span>
      </a>
    ) : (
      <span> </span>
    );
  };

  documentImageUi = (documentId) => {
    const { userDocuments } = this.state;
    const document = userDocuments.find(
      (obj) => obj.document_type === documentId
    );
    return document !== undefined ? (
      <Tooltip title={document.document_file_name}>
        <div className="doc-name">{document.document_file_name}</div>
      </Tooltip>
    ) : (
      <img
        src={documentImage}
        style={{ cursor: "pointer" }}
        id="output"
        alt="User-pic"
      />
    );
  };

  documentUploadUi = () => {
    const { loading } = this.state;
    return (
      <div className="modal fade" id="document_proof" role="dialog">
        <div className="modal-dialog dialog-top-margin">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => {
                  this.setState({ updatedSuccessfully: false });
                }}
              >
                &times;
              </button>
              <h3 className="modal-title">Verification Documents </h3>
            </div>
            <div className="modal-body">
              <ol>
                <li>
                  <div className="pull-right">
                    <label className="btn-bs-file">
                      {this.documentImageUi(1)}
                      <FileBase64
                        onDone={(file) => this.uploadDocuments(file, 1)}
                      />
                    </label>
                  </div>
                  Identity verification Proof
                  <br />
                  <small>Passport, Voter ID, or driving license</small>
                  <br />
                  {this.downloadDocument(1)}
                </li>
                <li>
                  <div className="pull-right">
                    <label className="btn-bs-file">
                      {this.documentImageUi(2)}
                      <FileBase64
                        onDone={(file) => this.uploadDocuments(file, 2)}
                      />
                    </label>
                  </div>
                  Address verification proof
                  <br />
                  <small>( Utility bill, rent agreement etc. )</small>
                  <br />
                  {this.downloadDocument(2)}
                </li>
                <li>
                  <div className="pull-right">
                    <label className="btn-bs-file">
                      {this.documentImageUi(3)}
                      <FileBase64
                        onDone={(file) => this.uploadDocuments(file, 3)}
                      />
                    </label>
                  </div>
                  Education Qualification verification proof
                  <br />
                  <small>( COP, Member Id card , Degree copy )</small>
                  <br />
                  {this.downloadDocument(3)}
                </li>
                <li>
                  <div className="pull-right">
                    <label className="btn-bs-file">
                      {this.documentImageUi(4)}
                      <FileBase64
                        onDone={(file) => this.uploadDocuments(file, 4)}
                      />
                    </label>
                  </div>
                  GST Declaration Proof
                  <br />
                  {this.downloadDocument(4)}
                  <br />
                  <a href={gstDocument} style={{ color: "#ff337d" }}>
                    &#x00A0;<b>GST Exemption declaration</b>
                  </a>
                </li>
              </ol>
            </div>
            <p className="text-note left-offset-1">
              *Upload at least 1 document
            </p>
            <p>&#x00A0;</p>
            {loading && (
              <div
                style={{
                  marginLeft: "45%",
                  marginTop: "-30%",
                  position: "fixed",
                  zIndex: "1",
                }}
              >
                <LoadingComponent pastDelay />
              </div>
            )}
            <br />
          </div>
        </div>
      </div>
    );
  };

  getCity = (selectedStateId, isOnChange) => {
    const errors = { ...this.state.errors };
    errors.state = "";
    this.setState({ errors });
    this.api.auth
      .get(`/geo/city/?state=${selectedStateId}`)
      .then((resp) => {
        const data = apiv1.parseAndAutoHandleIssues(
          resp,
          this.props.doDispatch
        );
        if (data) {
          const state_data = { ...this.state.state_data };
          state_data.id = selectedStateId;
          if (isOnChange) {
            const city_data = { ...this.state.city_data };
            city_data.id = "";
            const address = { ...this.state.address };
            address.city = "";
            this.setState({ city: data, state_data, city_data, address });
          } else {
            this.setState({ city: data, state_data });
          }
        }
      })
      .catch((err) => {
        this.setState({ loading: false, updatedSuccessfully: false });
        apiv1.handleErrors(err, this.props.doDispatch);
      });
  };

  submitQuestion = () => {
    let { training_question_answer_details } = this.state;
    const { doDispatch, onUpdateTrainingAndQuestion } = this.props;
    const { id, copyOfTrainingQAndAns } = training_question_answer_details;
    let apiUrlPath = apiUrl.professionalTrainingQuestion;
    let answersReq = [];
    copyOfTrainingQAndAns.forEach((value, category) => {
      const { answers } = copyOfTrainingQAndAns.get(category);
      answersReq = answersReq.concat(answers);
    });
    let request = {
      answers: answersReq,
    };
    let errors = { ...this.state.errors };
    if (validateProfileVerificationQAndA({ errors, answers: answersReq })) {
      let apiRequest = "";
      if (id) {
        apiUrlPath += `${id}/`;
        apiRequest = this.api.auth.patch(apiUrlPath, request);
      } else {
        apiRequest = this.api.auth.post(apiUrlPath, request);
      }
      this.setState({ loading: true });

      apiRequest
        .then((resp) => {
          const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
          onUpdateTrainingAndQuestion(updateTrainingAndQuestion(data));
          this.setState({ loading: false }, () => {
            const { getRestAuth } = this.props;
            if (getRestAuth) {
              setTimeout(() => {
                showSnackbar({ message: "Saved" });
                getRestAuth({ isOnlyGet: true });
              }, 1000);
            }
          });
        })
        .catch((err) => {
          apiv1.handleErrors(err, doDispatch);
          this.setState({ loading: false });
        });
    } else {
      this.setState({ errors });
    }
  };

  onClickSavePracticeArea = () => {
    const { practiceAreaIds, completing } = this.state;
    const { doDispatch, history, onUserDetails } = this.props;
    const practiceAreaRequest = {
      professional: {
        practise_area: practiceAreaIds,
      },
    };
    this.setState({ loading: true });
    axios
      .all([this.savePracticeArea(practiceAreaRequest)])
      .then(
        axios.spread((practiceArea) => {
          const data = apiv1.parseAndAutoHandleIssues(practiceArea, doDispatch);
          let practiceAreaIds = [];
          if (data) {
            const { professional } = data;
            let { practise_area } = professional || {};
            practise_area = practise_area || [];
            practiceAreaIds = practise_area;
            this.setState(
              { professional, practiceAreaIds, loading: false },
              () => {
                onUserDetails(userDetails(data));
                showSnackbar({ message: "Success!" });
              }
            );
          }
        })
      )
      .catch((err) => {
        apiv1.handleErrors(err, doDispatch);
        this.setState({ loading: false });
      });
  };

  addRemovePracticeArea = (id) => {
    const { practiceAreaIds } = this.state;
    const index = practiceAreaIds.indexOf(id);
    if (index > -1) {
      practiceAreaIds.splice(index, 1);
    } else {
      practiceAreaIds.push(id);
    }
    this.setState({ practiceAreaIds });
  };

  practiceAreaSerialised = (practiceArea) => {
    const dataArray = [];
    const { professionalType } = this.state;
    practiceArea.forEach((data) => {
      const { parent, is_core_legal_tax, id, name } = data || {};
      if (parent === null) {
        if (professionalType === legalAndAccounting && is_core_legal_tax) {
          dataArray.push({
            id,
            name,
            children: [],
          });
        } else if (professionalType === designer && !is_core_legal_tax) {
          dataArray.push({
            id,
            name,
            children: [],
          });
        }
      }
    });
    for (let i = 0; i < practiceArea.length; i++) {
      if (practiceArea[i].parent !== null) {
        for (let j = 0; j < dataArray.length; j++) {
          if (practiceArea[i].parent === dataArray[j].id) {
            dataArray[j].children.push(practiceArea[i]);
          }
        }
      }
    }
    return dataArray;
  };

  workAddressUi = () => {
    const { address, loading, geoState, city, state_data, city_data, errors } =
      this.state;
    return (
      <div className="row basic-detail-form">
        <div className="col-sm-12">
          <h4
            className="heading-md"
            style={{
              marginBottom: "20px",
              fontSize: "16px",
              marginTop: "0",
              color: "#25272E",
            }}
          >
            Work Address <span className="star-note">*</span>
          </h4>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <input
              type="text"
              className="form-control input-lg"
              id="address_1"
              placeholder="Address line 1"
              style={{ border: errors.line_one ? "1px solid #ff337d" : "" }}
              onChange={(e) => {
                const address = { ...this.state.address };
                address.line_one = e.target.value;
                const errors = { ...this.state.errors };
                errors.line_one = "";
                this.setState({ address, errors });
              }}
              value={address.line_one}
            />
            <span className={errors.line_one ? "error" : "no-error"}>
              {errors.line_one}
            </span>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <input
              type="text"
              className="form-control input-lg"
              id="address_2"
              name="line_two"
              placeholder="Address line 2"
              onChange={(e) => {
                const address = { ...this.state.address };
                address.line_two = e.target.value;
                this.setState({ address });
              }}
              value={address.line_two}
            />
          </div>
        </div>
        <div className="clearfix" />
        <div className="col-sm-6">
          <div className="form-group">
            <select
              className="form-control input-lg"
              id="work_address_state_text"
              name="state"
              onChange={(e) => {
                this.getCity(e.target.value, true);
              }}
            >
              <option value="-1" id="state_text" className="hidden">
                State
              </option>
              {geoState.map((eachState) => {
                return (
                  <option
                    value={eachState.id}
                    key={eachState.id}
                    selected={state_data.id === eachState.id}
                  >
                    {eachState.name}
                  </option>
                );
              })}
            </select>
            <span className={errors.state ? "error" : "no-error"}>
              {errors.state}
            </span>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <select
              className="form-control input-lg"
              id="work_address_city_text"
              onChange={(e) => {
                const address = { ...this.state.address };
                address.city = e.target.value;
                const errors = { ...this.state.errors };
                errors.city = "";
                this.setState({ address, errors });
              }}
              name="city"
            >
              <option value="-1" className="hidden">
                City
              </option>
              {city.map((city) => {
                return (
                  <option
                    value={city.id}
                    key={city.id}
                    selected={city.id === city_data.id}
                  >
                    {city.name}
                  </option>
                );
              })}
            </select>
            <span className={errors.city ? "error" : "no-error"}>
              {errors.city}
            </span>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <input
              type="text"
              className="form-control input-lg"
              id="pin"
              name="zipcode"
              placeholder="Pin code"
              value={address.zipcode}
              onChange={(e) => {
                const address = { ...this.state.address };
                const errors = { ...this.state.errors };
                errors[e.target.name] = "";
                const message = "Pin code is not valid";
                onlyZeroToNine(errors, e.target.name, e.target.value, message);
                address.zipcode = e.target.value;
                this.setState({ address, errors });
              }}
            />
            <span className={errors.zipcode ? "error" : "no-error"}>
              {errors.zipcode}
            </span>
          </div>
        </div>
      </div>
    );
  };

  // skillsUi = () => {
  //     const {practiceArea, practiceAreaIds, loading} = this.state
  //     return (
  //         <div className="row">
  //             {loading && (
  //                 <div className="lazy-loader" style={{position: 'fixed', zIndex: '1'}}>
  //                     <LoadingComponent pastDelay/></div>)}
  //             <div className="col-sm-12">
  //                 <div className="profile-tutorial-container">
  //                     <div className="row">
  //                         <div className="col-sm-12">
  //                             <h2 className="profile-tutorial-heading"><img
  //                                 src={tips}
  //                                 style={{width: '30px', float: 'left', marginRight: '5px'}} alt={`Create Gig Tips`}/>We
  //                                 help leads and customers to filter your gigs and profile based on below skills.
  //                                 Select skills only in which you have expertise.</h2>
  //                         </div>
  //                     </div>
  //                 </div>
  //             </div>
  //             <div className="col-sm-4 basic-detail-form" style={{marginTop: '0px'}}>
  //                 <div className="form-group">
  //                     <label>Select Service Category</label>
  //                     <select className="form-control input-lg"
  //                             onChange={(e) => {
  //                                 const {name, value} = e.target
  //                                 $(`#${value}_tab_list`).click()
  //                             }
  //                             }
  //                     >
  //                         {practiceArea.map(practiseAreaDetails => {
  //                             const {id, name} = practiseAreaDetails
  //                             return <option value={id}>{name}</option>
  //                         })}
  //
  //                     </select>
  //                 </div>
  //             </div>
  //             <div className="clearfix"/>
  //             <div className="col-sm-12">
  //                 <div className="form-group">
  //                     <p className="text-note" style={{color: '#ff337d'}}>*You can select Skills and Expertise in more
  //                         than 1 Service category.</p>
  //                 </div>
  //             </div>
  //             <div className="clearfix"/>
  //             <div className="col-sm-12">
  //                 <ul className="list-unstyled catagory-list">
  //                     {practiceArea.map((practiseAreaDetails, index) => {
  //                         const {id, name} = practiseAreaDetails
  //                         return (<p data-toggle="tab" id={`${id}_tab_list`} href={`#${id}_tab`}></p>)
  //                     })}
  //                 </ul>
  //                 <div className="tab-content">
  //                     {practiceArea.map((practiseAreaDetails, index) => {
  //                         const {id, children, name} = practiseAreaDetails
  //                         return (
  //                             <div id={`${id}_tab`} className={`tab-pane fade ${index === 0 ? 'in active' : ''}`}>
  //                                 <p className="category-p-heading"
  //                                 >{name} (Tap and Select)</p>
  //                                 <ul className="list-inline other-skill">
  //                                     {children.map((childData) => {
  //                                         const {id, name} = childData
  //                                         return (
  //                                             <li className={"background-white" + (practiceAreaIds.length > 0 && practiceAreaIds.indexOf(id) > -1 ? " background-pink" : '')}
  //                                                 key={id}
  //                                                 id={id}
  //                                                 onClick={() => {
  //                                                     $('#' + id).toggleClass('background-pink');
  //                                                     this.addRemovePracticeArea(id)
  //                                                 }}
  //                                             >
  //                                                 <span>{name}</span>
  //                                             </li>
  //                                         )
  //                                     })}
  //                                 </ul>
  //                             </div>
  //                         )
  //                     })}
  //                     {practiceArea.length > 0 &&
  //                     <a className="btn btn-save-profile-gig"
  //                        onClick={this.onClickSavePracticeArea}
  //                     >
  //                         Save to my profile
  //                     </a>
  //                     }
  //                     <p>&#x00A0;</p>
  //                 </div>
  //             </div>
  //         </div>
  //     )
  // }
  addSkillsUi = () => {
    const {
      userProfessions,
      skillsProfession,
      loading,
      skillsObj,
      metaSkills,
    } = this.state;
    const { skill_name, skills_level, is_editing } = skillsObj;
    return (
      <div className="row">
        {loading && (
          <div
            className="lazy-loader"
            style={{ position: "fixed", zIndex: "999" }}
          >
            <LoadingComponent pastDelay />
          </div>
        )}
        <div className="col-sm-12">
          <div className="profile-tutorial-container">
            <div className="row">
              <div className="col-sm-12">
                <h2 className="profile-tutorial-heading">
                  <img
                    src={tips}
                    style={{ width: "30px", float: "left", marginRight: "5px" }}
                    alt={`Create Gig Tips`}
                  />
                  We help leads and customers to filter your gigs and profile
                  based on below skills. Select skills only in which you have
                  expertise.
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="col-sm-12">
          <ul className="list-unstyled add-skill-ul basic-detail-form">
            {userProfessions.length > 1 && (
              <li>
                <div className="form-group">
                  <select
                    className="form-control input-lg"
                    name="selected_profession"
                    onChange={(e) => {
                      const { value } = e.target;
                      this.setState({ selected_profession: value }, () => {
                        this.getProfessionSkills();
                      });
                    }}
                  >
                    <option selected={true} value={-1}>
                      Select Profession
                    </option>
                    {userProfessions.map((professionData) => {
                      const { id, label } = professionData;
                      return <option value={id}>{label}</option>;
                    })}
                  </select>
                </div>
              </li>
            )}
            <li>
              <div className="form-group">
                <input
                  list="skill_list"
                  className="form-control input-lg"
                  placeholder={`Type To Select a Skill...`}
                  autoComplete="off"
                  name="skill_name"
                  value={skill_name}
                  disabled={is_editing}
                  onChange={(e) => {
                    const { value, name } = e.target;
                    let skillsObj = {
                      ...this.state.skillsObj,
                      [name]: value,
                    };
                    let options = $("datalist")[0].options;
                    for (let i = 0; i < options.length; i++) {
                      let { id } = options[i];
                      if (options[i].value === value) {
                        id = id.split("_");
                        id = id[id.length - 1];
                        skillsObj = {
                          ...this.state.skillsObj,
                          [name]: value,
                          id: id,
                        };
                        break;
                      }
                    }
                    this.setState({ skillsObj });
                  }}
                />
                <datalist id="skill_list">
                  {skillsProfession.map((skillDataList) => {
                    const { id, name } = skillDataList;
                    let isSelected =
                      metaSkills.findIndex(
                        (obj) => parseInt(obj.id) === parseInt(id)
                      ) > -1;
                    if (isSelected) {
                      return "";
                    }
                    return <option id={`skill_list_id_${id}`}>{name}</option>;
                  })}
                </datalist>
              </div>
            </li>
            <li>
              <div className="form-group">
                <select
                  className="form-control input-lg"
                  name="skills_level"
                  id={`skills_level`}
                  value={skills_level}
                  onChange={(e) => {
                    const { value, name } = e.target;
                    let skillsObj = { ...this.state.skillsObj };
                    skillsObj = {
                      ...skillsObj,
                      [name]: value,
                    };
                    this.setState({ skillsObj });
                  }}
                >
                  <option selected={true} disabled={true} value={""}>
                    Experience Level
                  </option>
                  {skillsLevel.map((levelData) => {
                    const { name, value } = levelData;
                    return (
                      <option
                        selected={name === skillsObj.skill_name}
                        value={value}
                      >
                        {name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </li>
            <li>
              <button
                className="btn btn-add-more"
                disabled={
                  !skills_level || (!skillsObj?.id && !skill_name?.trim())
                }
                onClick={() => this.saveMetaSkills()}
              >{`${is_editing ? "Update" : "Add"}`}</button>
            </li>
          </ul>
          <div className="added-skill-table">
            <table className="table table-bordered">
              {metaSkills.length > 0 && (
                <>
                  <thead>
                    <tr>
                      <th>Skill</th>
                      <th>Level</th>
                      <th style={{ textAlign: "center" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {metaSkills.map((details) => {
                      const { name, level, id } = details;
                      return (
                        <tr key={`selected_skills_${id}`}>
                          <td>{name}</td>
                          <td>{level}</td>
                          <td style={{ textAlign: "center" }}>
                            <a
                              className="btn btn-edit"
                              onClick={() => {
                                const skillsObj = {
                                  id: id,
                                  skill_name: name,
                                  skills_level: level,
                                  is_editing: true,
                                };
                                this.setState({ skillsObj });
                              }}
                            >
                              <div className="edit-icon">
                                <i className="fa fa-pencil" />
                                <span>Edit</span>
                              </div>
                            </a>

                            <a
                              className="btn btn-delete"
                              style={{ marginLeft: "150px" }}
                              onClick={() => {
                                const skillsObj = {
                                  id: id,
                                  skill_name: name,
                                  skills_level: level,
                                  is_editing: true,
                                };
                                this.setState(
                                  { ...this.state, skillsObj, loading: true },
                                  () => {
                                    this.saveMetaSkills(true);
                                  }
                                );
                              }}
                            >
                              <i className="fa fa-trash" />
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </>
              )}
            </table>
          </div>
        </div>
      </div>
    );
  };

  getQuestion = () => {
    const { training_question_answer_details } = this.state;
    const { is_training_question_answer_details_null } =
      training_question_answer_details;
    if (is_training_question_answer_details_null) {
      this.getQuestionnaireQuestion();
    }
  };

  listedUi = () => {
    let {
      userDocuments,
      savedUserCertificates,
      metaSkills,
      collegeList,
      work_experience,
      savedEducationList,
      training_question_answer_details,
      skill_tab,
      active_tab,
    } = this.state;
    const { data, history, is_super_user } = this.props;
    const { id } = training_question_answer_details;
    let { basicDetails, professional } = data || {};
    professional = professional || {};
    const { pan_number, verified } = professional;
    return (
      <ul className="list-unstyled update-list nav nav-tabs nav-stacked">
        <li className={`${active_tab === 2 && !skill_tab ? "active" : ""}`}>
          <a
            data-toggle="tab"
            href="#basic_details"
            onClick={() => {
              this.setState({ active_tab: 2 });
            }}
          >
            <img
              src={
                (
                  verified
                    ? profileFill
                    : basicDetails &&
                      basicDetails.email_verified &&
                      professional.phone
                )
                  ? profileFill
                  : profileNotFill
              }
              alt={`Basic Details`}
            />{" "}
            Basic Details
          </a>
        </li>
        <li className={`${active_tab === 3 ? "active" : ""}`}>
          <a
            data-toggle="tab"
            href="#education_and_experience"
            onClick={() => {
              this.setState({ active_tab: 3 });
              if (savedUserCertificates.length === 0) {
                this.getUploadedCertificates();
              }
              if (collegeList.length === 0) {
                this.getCollegeList();
              }
            }}
          >
             <img
              src={
                verified
                  ? profileFill
                  : savedEducationList?.length > 0
                  ? profileFill
                  : profileNotFill
              }
              alt={`Education & Qualifications`}
            />{" "}
            Education & Qualifications
          </a>
        </li>
        <li className={`${active_tab === 4 ? "active" : ""}`}>
          <a
            onClick={() => {
              this.setState({ active_tab: 4 });
            }}
            data-toggle="tab"
            href="#brief_your_bio"
            // style={(professional.bio_detailed !== undefined && professional.bio_detailed) ? filledListColor : {}}
          >
            <img
              src={
                verified
                  ? profileFill
                  : professional.bio_detailed !== undefined &&
                    professional.bio_detailed
                  ? profileFill
                  : profileNotFill
              }
              alt={`Brief Bio`}
            />{" "}
            Brief Bio
          </a>
        </li>
        <li className={`${skill_tab || active_tab === 5 ? "active" : ""}`}>
          <a
            style={metaSkills && metaSkills.length > 0 ? filledListColor : {}}
            // to={path.yourSkills}
            data-toggle="tab"
            href="#skills"
            onClick={() => {
              this.setState({ active_tab: 5 });
              if (this.props.professional.profession.length === 1) {
                this.getProfessionSkills();
              }
            }}
          >
            <img
              src={
                verified
                  ? profileFill
                  : metaSkills && metaSkills.length > 0
                  ? profileFill
                  : profileNotFill
              }
              alt={`Skills`}
            />{" "}
            Skills
          </a>
        </li>
        <li className={`${active_tab === 6 ? "active" : ""}`}>
          <a
            data-toggle="tab"
            href="#client_reviews"
            onClick={() => {
              this.setState({ active_tab: 6 });
            }}
          >
            <img
              src={
                verified
                  ? profileFill
                  : professional.professional_reviews &&
                    professional.professional_reviews.length > 0
                  ? profileFill
                  : profileNotFill
              }
              alt={`Client Review`}
            />{" "}
            Past Projects & Client Reviews
          </a>
        </li>
        <li className={`${active_tab === 8 ? "active" : ""}`}>
          <a
            data-toggle="tab"
            href="#resume-editor"
            onClick={() => {
              this.setState({ active_tab: 8 });
            }}
          >
            <img
              src={verified ? profileFill : profileNotFill}
              alt={`Client Review`}
            />{" "}
            Resume
          </a>
        </li>
        {/*<li>*/}
        {/*<Link*/}
        {/*to={path.yourServices}*/}
        {/*style={(professional.professional_product_added) ? filledListColor : {}}*/}
        {/*>*/}
        {/*<img src={(professional.professional_product_added) ? profileFill : profileNotFill}*/}
        {/*alt={`Create Gigs`}/> Services Store: Standard Gigs*/}
        {/*/!*<span className="label label-default">New</span>*!/*/}
        {/*</Link>*/}
        {/*</li>*/}
        {is_super_user && (
          <Fragment>
            <li>
              <a
                data-toggle="modal"
                data-target="#document_proof"
                style={userDocuments.length > 0 ? filledListColor : {}}
              >
                <img
                  src={userDocuments.length > 0 ? profileFill : profileNotFill}
                  alt={`Documents`}
                />{" "}
                KYC Documents
              </a>
            </li>
            <li>
              <a
                data-toggle="modal"
                data-target="#Payment_detail"
                style={
                  professional.bank_account !== undefined &&
                  professional.bank_account
                    ? filledListColor
                    : {}
                }
              >
                <img
                  src={
                    professional.bank_account !== undefined &&
                    professional.bank_account
                      ? profileFill
                      : profileNotFill
                  }
                  alt={`Payment Details`}
                />{" "}
                Payment Details
              </a>
            </li>
          </Fragment>
        )}
        {/* <li className={`${active_tab === 7 ? 'active' : ''}`}>
                    <a data-toggle="tab" href="#training_questionnaire"
                       onClick={() => {
                           this.setState({active_tab: 7}, ()=>{
                               this.getQuestion()
                           })
                       }}
                    >
                        <img
                            src={verified ? profileFill: id ? profileFill : profileNotFill}
                            alt={`Verification Questionnaire`}/> Verification Questionnaire
                    </a>
                </li> */}
      </ul>
    );
  };

  trainingQuestionAnsUi = (questionAnswerDetails, q_index, category) => {
    const { errors } = this.state;
    let { question, id, answer_options, youtube_link, type, answer } =
      questionAnswerDetails;
    type = type || _text;
    switch (type) {
      case _text:
        return (
          <div className="form-group">
            <label>
              {question}{" "}
              <span
                style={{
                  color: "#ff337d",
                  fontSize: "18px",
                }}
              >
                *
              </span>
            </label>
            {youtube_link && (
              <p
                className="video-link"
                style={{ marginTop: "0px", marginBottom: "15px" }}
              >
                <a href={youtube_link} target={`_blank`}>
                  <i className="fa fa-play" aria-hidden="true" /> {youtube_link}
                </a>
              </p>
            )}
            <textarea
              rows="4"
              className="form-control"
              name={`ans`}
              value={answer}
              onChange={(e) =>
                this.onChangeQuestionAnswer(e, q_index, category)
              }
            />
            {errors.training_question_answer_details && (
              <span className="error">
                {errors.training_question_answer_details[id]}
              </span>
            )}
          </div>
        );
      case multi:
        return (
          <div className="form-group">
            <label>
              {question}{" "}
              <span
                style={{
                  color: "#ff337d",
                  fontSize: "18px",
                }}
              >
                *
              </span>
            </label>
            {youtube_link && (
              <p className="video-link">
                <a href={youtube_link} target={`_blank`}>
                  <i className="fa fa-play" aria-hidden="true" /> {youtube_link}
                </a>
              </p>
            )}
            {answer_options.map((opt, index) => {
              return (
                <div className="checkbox" key={index}>
                  <label>
                    <input
                      type="checkbox"
                      value={opt}
                      checked={answer.indexOf(opt) > -1}
                      name={""}
                      onChange={(e) =>
                        this.onChangeQuestionAnswer(e, q_index, category)
                      }
                    />
                    <span className="cr">
                      <i className="cr-icon glyphicon glyphicon-ok" />
                    </span>
                    {opt}
                  </label>
                </div>
              );
            })}
            {errors.training_question_answer_details && (
              <span className="error">
                {errors.training_question_answer_details[id]}
              </span>
            )}
          </div>
        );
      case yesno:
        return (
          <div className="form-group">
            <label style={{ marginBottom: "25px" }}>
              {question}{" "}
              <span
                style={{
                  color: "#ff337d",
                  fontSize: "18px",
                }}
              >
                *
              </span>
            </label>
            {youtube_link && (
              <p className="video-link">
                <a href={youtube_link} target={`_blank`}>
                  <i className="fa fa-play" aria-hidden="true" /> {youtube_link}
                </a>
              </p>
            )}
            <RadioGroup
              value={answer}
              onChange={(e) =>
                this.onChangeQuestionAnswer(e, q_index, category)
              }
              className="inlineRadio"
            >
              {answer_options.map((opt, indx) => {
                return (
                  <FormControlLabel
                    key={indx}
                    value={opt}
                    control={<Radio />}
                    label={opt}
                  />
                );
              })}
            </RadioGroup>
            {errors.training_question_answer_details && (
              <span className="error">
                {errors.training_question_answer_details[id]}
              </span>
            )}
          </div>
        );
      case single:
        return (
          <div className="form-group">
            <label style={{ marginBottom: "25px" }}>
              {question}{" "}
              <span
                style={{
                  color: "#ff337d",
                  fontSize: "18px",
                }}
              >
                *
              </span>
            </label>
            {youtube_link && (
              <p className="video-link">
                <a href={youtube_link} target={`_blank`}>
                  <i className="fa fa-play" aria-hidden="true" /> {youtube_link}
                </a>
              </p>
            )}
            <RadioGroup
              value={answer}
              onChange={(e) =>
                this.onChangeQuestionAnswer(e, q_index, category)
              }
              className="inlineRadio"
            >
              {answer_options.map((opt, indx) => {
                return (
                  <FormControlLabel
                    key={indx}
                    value={opt}
                    control={<Radio />}
                    label={opt}
                  />
                );
              })}
            </RadioGroup>
            {errors.training_question_answer_details && (
              <span className="error">
                {errors.training_question_answer_details[id]}
              </span>
            )}
          </div>
        );
      default:
        return "";
    }
  };

  trainingAndQUi = () => {
    const { training_question_answer_details, loading, data } = this.state;
    let { professional } = data;
    professional = professional || {};
    const { verified } = professional;
    const { answers, copyOfTrainingQAndAns } = training_question_answer_details;
    return (
      <div className="row">
        <div className="col-sm-12 no-padding">
          <div className="d-update-profile" style={{ minHeight: "auto" }}>
            {loading && (
              <div
                className="lazy-loader"
                style={{ position: "fixed", zIndex: "10" }}
              >
                <LoadingComponent pastDelay />
              </div>
            )}
            <div className="d-update-profile-body">
              <h4 className="d-profile-heading">Verification Questionnaire</h4>
              <p className="para" style={{ marginBottom: "5px" }}>
                Watch Workbeam Tutorial Videos and Please fill this
                questionnaire.
              </p>
            </div>
          </div>
          {[...copyOfTrainingQAndAns.keys()].map((category) => {
            const { answers } = copyOfTrainingQAndAns.get(category);
            return (
              <div className="d-update-profile">
                {category && (
                  <div className="d-update-profile-body">
                    <h4 className="d-profile-heading">{category}</h4>
                  </div>
                )}
                <div
                  className="d-update-profile-body"
                  style={{ borderBottom: "none" }}
                >
                  {answers.map((questionAnswerDetails, q_index) => {
                    return this.trainingQuestionAnsUi(
                      questionAnswerDetails,
                      q_index,
                      category
                    );
                  })}
                </div>
              </div>
            );
          })}
          {answers.length > 0 && (
            <div className="text-center" style={{ marginBottom: "35px" }}>
              {verified ? (
                <div
                  className="d-update-profile-body"
                  style={{ borderBottom: "none" }}
                >
                  <p className="para text-center" style={{ color: "green" }}>
                    Your Profile is verified.
                  </p>
                </div>
              ) : (
                <button
                  className="btn btn-save-profile"
                  disabled={loading}
                  onClick={() => this.submitQuestion()}
                >
                  Save to my profile
                </button>
              )}
            </div>
          )}
          {/*<ProfileHelpCenter/>*/}
        </div>
      </div>
    );
  };

  resumeEditorUI = () => {
    return (
      <>
        <ResumeEditorComponent />
      </>
    );
  };

  render() {
    const {
      professionalType,
      has_submitted_for_review,
      profilePercentage,
      loading,
      errors,
      bankAccount,
      tab,
      skill_tab,
      active_tab,
      metaSkills,
    } = this.state;
    // const {answers, copyOfTrainingQAndAns} = training_question_answer_details
    const { data, history, is_super_user } = this.props;
    let { professional } = data || {};
    professional = professional || {};
    const { id, verified, slug_new, slug } = professional;
    return (
      <Fragment>
        {profilePercentage > 90 && !has_submitted_for_review && (
          <div className="profile-submit">
            <button
              className="btn btn-save-review"
              onClick={() => {
                this.submitForReview();
              }}
            >
              Submit Your Profile For Review
            </button>
          </div>
        )}
        <div className="col-sm-12 no-padding">
          <div className="d-update-profile-head">
            <div className="d-update-profile-body">
              <div className="row">
                <div className="col-sm-4">
                  <h4 className="d-profile-heading">
                    Profile Completion percentage
                  </h4>
                </div>
                <div className="col-sm-4 col-xs-10">
                  <ProgressBar percentage={profilePercentage} />
                </div>
                <div className="col-sm-1 col-xs-1">
                  <div className="bar-count">{profilePercentage}%</div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4 col-xs-7">
                  <h4 className="d-profile-heading">Profile Status</h4>
                </div>
                <div className="col-sm-3 col-xs-5">
                  <p style={{ marginTop: "5px" }}>
                    {!professional.verified && has_submitted_for_review && (
                      <span className="text-warning">Under review</span>
                    )}
                    {id && verified && (
                      <span className="text-warning" style={{ color: "green" }}>
                        <img
                          src={
                            require("../../static/images/verified-badge.png")
                              .default
                          }
                          style={{ width: "110px" }}
                          alt={`verified expert`}
                        />
                      </span>
                    )}
                    {id && !verified && !has_submitted_for_review && (
                      <span className="text-warning" style={{ color: "red" }}>
                        Not Verified
                      </span>
                    )}
                  </p>
                </div>
                <div className="col-sm-4 hidden-xs">
                  <h4 className="d-profile-heading">
                    Check public profile
                    <a
                      className="btn btn-view-profile pull-right"
                      disabled
                      style={{ marginTop: "-5px" }}
                      href={`#`}
                    >
                      Profile Preview
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="d-update-profile-head">
            <ul className="nav nav-tabs nav-justified">
              <li className={!tab ? "active" : ""}>
                <a
                  data-toggle="tab"
                  href="#basic_details_gigs"
                  onClick={() => {
                    history.replace(path.professionalProfile);
                  }}
                >
                  Basic Details
                </a>
              </li>
              {/* <li className={tab ? 'active' : `${(has_submitted_for_review || is_super_user || verified) ? '' : 'disable-tab'}`}>
                                <a data-toggle="tab" href="#create_standard_gigs" className="crate-gigs-tab"
                                   onClick={() => {
                                       history.replace(`${path.professionalProfile}?tab=${1}`)
                                   }}
                                >
                                    {!has_submitted_for_review && !verified ?
                                        <Fragment>
                                            <span><img src={require('../../static/images/create-gigs.png')}
                                                       alt="Create Standard Gigs & Packages"/></span>
                                            <span>Create Standard Gigs & Packages<br/>
                                        <span className="disabled-text">This Tab will be activated once you submit your profile.</span>
                                    </span>
                                        </Fragment>
                                        :
                                        <span>Create Standard Gigs & Packages</span>
                                    }
                                </a>
                            </li> */}
            </ul>
          </div>
        </div>
        <div className="tab-content">
          <div
            id="basic_details_gigs"
            className={`tab-pane fade ${tab ? "" : "in active"}`}
          >
            <div className="col-md-3 col-sm-4 d-parent-common-top no-padding">
              <div className="d-update-profile">
                <div
                  className="d-update-profile-body"
                  style={{ padding: "10px 15px" }}
                >
                  {is_super_user && (
                    <p className="para">
                      <a
                        onClick={() => {
                          history.goBack();
                        }}
                        style={{ fontSize: "14px", color: "#ff337d" }}
                      >
                        <i className="fa fa-long-arrow-left" /> Go Back
                      </a>
                    </p>
                  )}
                  <h4
                    className="d-title-heading"
                    style={{ marginBottom: "5px" }}
                  >
                    Edit Workbeam Profile
                  </h4>
                </div>
                <div
                  className="d-update-profile-body"
                  style={{ padding: "10px 15px" }}
                >
                  {this.listedUi()}
                </div>
                <div
                  className="text-center d-update-profile-body"
                  style={{ border: "none" }}
                >
                  {professional.id && !verified && (
                    <Fragment>
                      {!has_submitted_for_review && (
                        <Fragment>
                          <button
                            className="btn btn-save-review"
                            onClick={() => {
                              this.submitForReview();
                            }}
                          >
                            Submit For Review
                          </button>
                          <p className="para">
                            Activates on above 90% Completion
                          </p>
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-9 col-sm-8 d-parent-common-top no-padding">
              {loading && (
                <div
                  className="lazy-loader"
                  style={{ zIndex: "10", position: "fixed" }}
                >
                  <LoadingComponent pastDelay />
                </div>
              )}
              <div className="tab-content">
                <div
                  id="basic_details"
                  className={`tab-pane fade ${
                    active_tab === 2 && !skill_tab ? "in active" : ""
                  }`}
                >
                  <div className="d-update-profile">
                    <div className="d-update-profile-body">
                      <h4
                        className="d-title-heading"
                        style={{ marginBottom: "5px" }}
                      >
                        Basic Details
                      </h4>
                    </div>
                    {this.basicDetailsUi()}
                  </div>
                </div>
                <div
                  id="education_and_experience"
                  className={`tab-pane fade ${
                    active_tab === 3 ? "in active" : ""
                  }`}
                >
                  <div className="d-update-profile">
                    <div className="d-update-profile-body">
                      <h4
                        className="d-profile-heading"
                        style={{ marginBottom: "5px" }}
                      >
                        Education Qualifications
                      </h4>
                    </div>
                    {this.educationAndExperience()}
                  </div>
                </div>
                <div
                  id="brief_your_bio"
                  className={`tab-pane fade ${
                    active_tab === 4 ? "in active" : ""
                  }`}
                >
                  <div className="d-update-profile">
                    <div className="d-update-profile-body">
                      <h4
                        className="d-profile-heading"
                        style={{ marginBottom: "5px" }}
                      >
                        Brief Bio
                      </h4>
                    </div>
                    <div className="d-update-profile-body">
                      <ProfessionalBriefBio
                        expProfession={this.props.professional.profession}
                        doDispatch={this.props.doDispatch}
                        moveToNextTab={this.moveToNextTab}
                      />
                    </div>
                  </div>
                </div>
                <div
                  id="skills"
                  className={`tab-pane fade ${
                    skill_tab || active_tab === 5 ? "in active" : ""
                  }`}
                >
                  <div className="d-update-profile">
                    <div className="d-update-profile-body">
                      <h4
                        className="d-profile-heading"
                        style={{ marginBottom: "5px" }}
                      >
                        Skills
                      </h4>
                    </div>
                    <div
                      className="d-update-profile-body"
                      style={{ alignContent: "center" }}
                    >
                      {this.addSkillsUi()}
                      {metaSkills.length > 0 && (
                        <button
                          type="button"
                          className="btn btn-save-profile-gig"
                          onClick={() => {
                            this.moveToNextTab(6);
                            // this.setState({active_tab: 6})
                          }}
                        >
                          Save & Continue
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  id="client_reviews"
                  className={`tab-pane fade ${
                    active_tab === 6 ? "in active" : ""
                  }`}
                >
                  <div className="d-update-profile">
                    <div className="d-update-profile-body">
                      <h4 className="d-profile-heading">
                        Past Projects & Client Reviews
                      </h4>
                      <p className="para" style={{ marginBottom: "0px" }}>
                        Add up to 10 past projects and customer reviews <br />
                        These projects you must have done outside of Workbeam
                      </p>
                    </div>
                    {this.clientReviewUi()}
                    <ClientReviewsTips />
                  </div>
                </div>
                <div
                  id="training_questionnaire"
                  className={`tab-pane fade ${
                    active_tab === 7 ? "in active" : ""
                  }`}
                >
                  {this.trainingAndQUi()}
                </div>
                <div
                  id="resume-editor"
                  className={`tab-pane fade ${
                    active_tab === 8 ? "in active" : ""
                  }`}
                >
                  {this.resumeEditorUI()}
                </div>
              </div>
              <div
                className="d-update-profile visible-xs"
                style={{ minHeight: "100px" }}
              >
                <div
                  className="text-center d-update-profile-body"
                  style={{ border: "none" }}
                >
                  <h4 className="d-profile-heading">Check public profile</h4>
                  {professional.id && (
                    <a
                      className="btn btn-view-profile"
                      disabled
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                      target="_blank"
                      href={"#"}
                    >
                      Profile Preview
                    </a>
                    // Not working
                  )}
                </div>
              </div>
              {/*<ProfileHelpCenter/>*/}
            </div>
          </div>
          <div
            id="create_standard_gigs"
            className={`tab-pane fade ${tab ? "in active" : ""}`}
          >
            <div className="col-sm-12 d-parent-common-top no-padding">
              <ExpertStandardGigList
                history={history}
                isFromProfilePage={true}
              />
            </div>
          </div>
        </div>
        {this.bioUi()}
        {is_super_user && (
          <Fragment>
            {this.documentUploadUi()}
            <div className="modal fade" id="Payment_detail" role="dialog">
              <div className="modal-dialog dialog-top-margin">
                <div className="modal-content">
                  <div className="modal-body">
                    <ProfessionalPaymentDetails
                      bankAccount={bankAccount}
                      professionalId={professional.id}
                      api={this.api}
                      style={`col-sm-12`}
                    />
                  </div>
                  <div
                    className="modal-footer"
                    style={{ textAlign: "center", marginBottom: "20px" }}
                  >
                    <a className="btn btn-save-profile" data-dismiss="modal">
                      Close
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
        {professionalType === professional_type.legalAndAccounting && (
          <Fragment>
            {this.consultationFeesUi()}
            <p id="court_ui_popup" data-toggle="modal" data-target="#court" />
            <div className="modal fade" id="court" role="dialog">
              {this.courtsUi()}
            </div>
          </Fragment>
        )}
        <ProfessionalSignupIntro history={history} />
        <p
          id="signup_intro"
          data-toggle="modal"
          data-target="#signup_intro_popup"
        />
        <div
          id="firebase-recaptcha-container-for-verify-otp"
          name="firebase-recaptcha-container-for-otp"
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  professional: JSON.parse(localStorage.logedInUserDetails).professional || {},
  professions: state.professions.professions,
  geoState: state.state.state,
  is_super_user: state.data.is_super_user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onGetProfession: (action) => {
      dispatch(action);
    },
    OnUpdatePhone: (action) => {
      dispatch(action);
    },
    onUserDetails: (action) => {
      dispatch(action);
    },
    onSaveBasicDetails: (action) => {
      dispatch(action);
    },
    onVerifyPhone: (action) => {
      dispatch(action);
    },
    onSaveSate: (action) => {
      dispatch(action);
    },
    onUpdateAProfessionalKey: (action) => {
      dispatch(action);
    },
    onUpdateTrainingAndQuestion: (action) => {
      dispatch(action);
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfessionalProfile);

const basicDetail = "basicDetails";
const new_edu = "newEdu";
const new_review = "newReview";
const filledListColor = {
  color: "#000",
};
const updateMessage = "Updated";
export const uploadDocumentMessage = "Uploaded";
const profilePreview = "Submitted";
export const Freelancer = "Freelancer";
export const PartTimeFreelancer = "Part-time Freelancer";
export const FullTimeFreelancer = "Full-time Freelancer";
export const AgencyOrFirm = "Agency/Firm/Studio";
export const projectSize = [
  "5,000",
  "10,000",
  "20,000",
  "40,000",
  "80,000",
  "1,50,000",
  "3,00,000",
  "6,00,000",
  "10,00,000",
];

export const allowedImageType = [
  "data:application/jpeg;base64,",
  "data:application/png;base64,",
  "data:image/png;base64,",
  "data:image/jpeg;base64,",
  "data:image/jpg;base64,",
  "data:image/svg+xml;base64,",
];
export let allowedDocumentType = [
  "data:application/pdf;base64,",
  "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
  "data:application/vnd.ms-excel;base64,",
  "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,",
  "data:image/docx;base64,",
  "data:application/msword;base64,",
  "data:application/octet-stream;base64,",
  "data:application/zip;base64,",
  "data:application/x-zip-compressed;base64,",
  "data:application/x-rar;base64,",
].concat(...allowedImageType);
export const allowedVideoType = [
  "data:video/mp4;base64,",
  "data:video/quicktime;base64,",
  "data:video/x-ms-wmv;base64,",
  "data:video/avi;base64,",
];
