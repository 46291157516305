// import {formErrorMsg} from "./FormValidator";
const businessDetails = {
    please_enter: 'Please enter this field'
};
const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
export const onlyIntReg = /^[0-9]*$/;

export function businessDetailsValidator(errors, request) {
    let isAllValid = true;
    for (let key in request) {
        if (key === 'address') {
            for (let key2 in request[key]){
                if (key2 === 'city' && (request[key][key2] === null || request[key][key2].toString().trim() === '')){
                    errors['state'] = 'Please select state';
                    if (request['address']['state']){
                        errors['city'] = 'Please select city';
                        errors['state'] = ''
                    }
                    isAllValid = false
                }
                else if ((key2 === 'line_one') && (request[key][key2] === null || request[key][key2].trim() === '')){
                    errors[key2] = businessDetails.please_enter;
                    isAllValid = false
                }
                else if (key2 === 'zipcode' ){
                    if ((request[key][key2] === undefined || request[key][key2] === null || request[key][key2].trim() === '')){
                        errors[key2] = businessDetails.please_enter;
                        isAllValid = false
                    }else if (!onlyIntReg.test(request[key][key2])) {
                        errors[key2] = 'Pin code is not valid';
                        isAllValid = false
                    } else if (request[key][key2].length !== 6) {
                        errors[key2] = 'Pin code must be 6 digit';
                        isAllValid = false
                    }
                }
            }
        }
        if (key === 'company_name'){
            if (request[key] === null || request[key].trim() === ''){
                errors[key] = businessDetails.please_enter;
                isAllValid = false
            }
        }
        if (key === 'company_type'){
            if (request[key] === null || request[key].trim() === ''){
                errors[key] = businessDetails.please_enter;
                isAllValid = false
            }
        }
        if (key === 'gst_number'){
            if (request[key] && !isGstNoValid(errors, key, request[key])){
                isAllValid = false
            }
        }
        if (key === 'pan_number'){
            if (request[key] === null || request[key].trim() === ''){
                errors[key] = businessDetails.please_enter;
                isAllValid = false
            } else if (!isPanNoValid(errors, key, request[key])) {
                isAllValid = false
            }
        }
        if (key === 'profession'){
            if (request[key].length === 0){
                errors[key] = businessDetails.please_enter;
                isAllValid = false
            }
        }
    }

    return isAllValid

}

export function isGstNoValid(errors, key, value) {
    if (!gstRegex.test(value)){
        errors[key] = 'Gst no is not valid';
        return false
    }
    return true
}
export function isPanNoValid(errors, key, value) {
    if (!panRegex.test(value)){
        errors[key] = 'Pan no is not valid';
        return false
    }
    return true
}