import React, {Component, Fragment} from "react";
import {errorActionDispatchers} from "../../actions/errors";
import connect from "react-redux/es/connect/connect";
import * as path from '../../constants/path'
import {apiv1} from "../../api";
import {Link} from "react-router-dom";
import localStorage from "../../utils/localStorage"
import {PROFESSIONAL, USER} from "../../constants/auth";
import PaymentTerms from "./PaymentTerms";
import socket from "./Socket";
import {status} from './../../constants/job'
import {CustomToolTrip} from "../../functions/common";
import {withRouter} from 'react-router-dom';
import {assignTaskToThisProfessional} from "../../constants/user";
import {WhatHappenNextPopup} from "./Message";
import $ from 'jquery'
import * as apiUrl from "../../api/apiUrls";
import queryString from "query-string";
import {indiaCallingCode, indiaCountryCodeCode} from "../../constants/common";
import {confirmAlert} from "react-confirm-alert";
import {showSnackbar} from "../functional/afterHire";
import LoadingComponent from "../../utils/LoadingComponent";


class JobInProgressDetailsPageHeaderLink extends Component {

    constructor(props) {
        super(props);
        let {userAndProfessionalData, data} = props
        userAndProfessionalData = userAndProfessionalData || {}
        // const {job_details} = userAndProfessionalData
        // const {new_invoicing} = job_details
        data = data || {}
        this.socket = '';
        this.state = {
            userData: data,
            shouldPaymentTermsRender: false,
            checkList: [],
            userAndProfessionalData,
            country_code: '',
            loading: false,
        }
    }

    componentWillReceiveProps(nextProps) {
        const {data, userAndProfessionalData} = nextProps
        if (data.client.id !== undefined) {
            this.setState({userData: data})
        }
        if (userAndProfessionalData && userAndProfessionalData.Professional_detail) {
            this.setState({userAndProfessionalData})
        }
    }

    componentDidMount() {
        let {productId, userId, isFromHiringSummary, history} = this.props
        const {location} = history
        const {search} = location
        let {isFromPaymentConfirm} = queryString.parse(search)
        userId = Number(userId) || ''
        if (localStorage.userType === PROFESSIONAL || userId !== assignTaskToThisProfessional.useId) {
            this.initializeSocket()
        }
        if (isFromHiringSummary || isFromPaymentConfirm) {
            setTimeout(() => {
                $('#how_to_use_wc_popup').click()
            }, 500)
        }
        if (productId) {
            this.getCheckListData(productId)
        }
    }

    componentWillUnmount() {
        if (this.socket) {
            this.socket.close()
        }
    }

    getCheckListData = (productId) => {
        const url = `/job/check-list/?product=${productId}`
        apiv1.auth.get(url).then(resp => {
            const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
            if (data) {
                if (data.length > 0) {
                    this.setState({checkList: data[0], loading: false})
                }
                this.setState({loading: false})

            } else {
                this.setState({loading: false})
            }
        })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }
    markJobClose = (jobId) => {
        confirmAlert({
            title: 'Close this job',
            message: 'Are you sure to do this?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        var jobStatus = "Closed";
                        this.setState({loading: true})
                        apiv1.auth.patch(`/job/job/${jobId}/`, {
                            status: jobStatus,
                            // completed_by_super_admin: is_super_user ? true : false
                        }).then(resp => {
                            const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                            if (data) {
                                this.setState({loading: false}, () => {
                                    showSnackbar({message: 'Job closed successfully'})
                                })
                            }
                        })
                            .catch(err => {

                            })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ]
        })
    };
    markJobInDispute = (jobId) => {
        confirmAlert({
            title: 'Mark this job in Disputed',
            message: 'Are you sure to do this?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        var jobStatus = "In Dispute";
                        this.setState({loading: true})
                        apiv1.auth.patch(`/job/job/${jobId}/`, {
                            status: jobStatus,
                            // completed_by_super_admin: is_super_user ? true : false
                        }).then(resp => {
                            const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                            if (data) {
                                this.setState({loading: false}, () => {
                                    showSnackbar({message: 'Job In disputed'})
                                })
                            }
                        })
                            .catch(err => {

                            })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ]
        })


    };

    getPhoneAndEmail = ({}) => {
        const {userAndProfessionalData} = this.state;
        const {user_details} = userAndProfessionalData
        const {id} = user_details
        const url = `${apiUrl.phoneNumberByUserId}?client_profile__user=${id}`
        apiv1.auth.get(url).then(resp => {
            const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
            if (data) {
                let {country_code} = data[0] || []
                country_code = country_code === indiaCallingCode ? indiaCountryCodeCode : 'other'
                // let isIndianPhoneNo = country_code === indiaCallingCode
                this.setState({shouldPaymentTermsRender: true, country_code})
            }
        })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    initializeSocket = () => {
        const {jobId, userId, proposalId, is_super_user} = this.props;
        if (is_super_user) {
            return
        }
        if (!this.socket.socket) {
            this.socket = socket.startSocketConnection({
                jobId,
                proposalId,
                counterPartyUserId: userId,
                onMessage: this.onMessage
            })
            // this.socketEventListener()
        }
    };

    onMessage = (event) => {
        console.log('on message')
    };

    render() {
        let {
            jobId,
            proposalId,
            userId,
            pageName,
            props,
            loading,
            updateAmountOnJobPaymentSummary,
            jobTitle,
            jobStatus,
            is_super_user,
            chatRoomId
        } = this.props;
        const {userData, shouldPaymentTermsRender, userAndProfessionalData, country_code} = this.state;
        const {job_details} = userAndProfessionalData
        const {new_invoicing} = job_details || {}
        userId = Number(userId) || ''
        return (
            <div className="col-sm-12 d-tab-container">
                {loading && (
                    <div className="lazy-loader" style={{position: 'fixed', zIndex: '1'}}>
                        <LoadingComponent pastDelay/></div>)}
                <div className="col-sm-12">
                    <h5 className="heading-text" style={{border: 'none', fontSize: '16px'}}>
                        {localStorage.userType === USER && (jobStatus === status.InProgress || jobStatus === status.InDispute) && (
                            <a onClick={() => props.history.push(path.userJobInProgress)} style={{color: '#000'}}>Order
                                in
                                progress</a>)}
                        {localStorage.userType === PROFESSIONAL && (jobStatus === status.InProgress || jobStatus === status.InDispute) && (
                            <a onClick={() => props.history.push(path.professionalJobInProgress)}
                               style={{color: '#000'}}>Order in progress</a>)}
                        {(jobStatus === status.Completed || jobStatus === status.Closed || jobStatus === status.Migrated) && (
                            <a onClick={() => props.history.push(path.jobHistory)} style={{color: '#000'}}>Order
                                history</a>)}
                        &#x00A0;&gt;&#x00A0;
                        <span style={{color: '#ff337d'}}>
                            <CustomToolTrip text={jobTitle} maxCharLength={40}/>
                        </span></h5>
                </div>
                <div className="col-sm-8">
                    <ul className="nav nav-tabs" style={{marginTop: '0px'}}>
                        <li
                            className={pageName === 'chat' ? 'active' : ''}
                        >
                            {localStorage.userType === USER && (
                                assignTaskToThisProfessional.useId === userId ?
                                    <a className="hired-professional btn btn-link"
                                       onClick={() => {
                                           $(`#how_to_use_wc_popup`).click()
                                       }}
                                    >
                                        Message
                                    </a>
                                    :
                                    <a
                                        className="hired-professional btn btn-link"
                                        href={`/chat-to-professional-after-hire/${proposalId}/${jobId}/${userId}/${chatRoomId}`}
                                    >
                                        Message
                                    </a>
                            )}
                            {localStorage.userType === PROFESSIONAL && (
                                <a
                                    className="hired-professional"
                                    href={`/chat-to-user-after-hire/${proposalId}/${jobId}/${userId}/${chatRoomId}`}>
                                    Message
                                </a>
                            )}
                        </li>
                        <li className={pageName === 'files' ? 'margin-left active' : 'margin-left'}>
                            <a
                                className="hired-professional"
                                href={`/job-document/${proposalId}/${jobId}/${userId}/${chatRoomId}`}>
                                Files
                            </a>
                        </li>
                        <li className={pageName === 'timeline' ? 'margin-left active' : 'margin-left'}>
                            {assignTaskToThisProfessional.useId === userId ?
                                <a className="hired-professional btn btn-link"
                                   onClick={() => {
                                       $(`#how_to_use_wc_popup`).click()
                                   }}
                                >
                                    Milestones
                                </a>
                                :
                                <a
                                    className="hired-professional"
                                    href={`/job-track/${proposalId}/${jobId}/${userId}/${chatRoomId}`}>
                                    Milestones
                                </a>
                            }
                        </li>
                        <li className={pageName === 'details' ? 'margin-left active' : 'margin-left'}>
                            <a
                                className="hired-professional"
                                href={`/job-details/${proposalId}/${jobId}/${userId}/${chatRoomId}`}>
                                Order Info
                            </a>
                        </li>
                        <li className={pageName === 'deliveryInfo' ? 'margin-left active' : 'margin-left'}>
                            <a
                                className="hired-professional"
                                href={`${path.deliveryInfo}/${proposalId}/${jobId}/${userId}/${chatRoomId}`}>
                                Delivery Info
                            </a>
                        </li>
                        {/*{checkList.check_list !== undefined &&*/}
                        {/*<li className={pageName === 'checkList' ? 'margin-left active' : 'margin-left'}>*/}
                        {/*    <Link*/}
                        {/*        className="hired-professional"*/}
                        {/*        to={`${path.documentCheckList}/${proposalId}/${jobId}/${userId}`}>*/}
                        {/*       Document Checklist*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                        {/*}*/}
                        {localStorage.userType === PROFESSIONAL && !new_invoicing &&
                        <li className={pageName === 'invoice' ? 'margin-left active' : 'margin-left'}>
                            <a
                                className="hired-professional"
                                href={`${path.invoice}/${proposalId}/${jobId}/${userId}/${chatRoomId}`}>
                                Invoice
                            </a>
                        </li>
                        }
                        {localStorage.userType === USER &&
                        <li className={pageName === 'invoice' ? 'margin-left active' : 'margin-left'}>
                            <a
                                className="hired-professional"
                                href={`${path.invoice}/${proposalId}/${jobId}/${userId}/${chatRoomId}`}>
                                Invoice
                            </a>
                        </li>
                        }
                    </ul>
                </div>
                <div className="col-sm-4">
                    <ul className="list-inline pull-right">
                        {(is_super_user) && jobStatus !== status.Completed && (
                            <li>
                                <a data-toggle="modal" data-target="#PAYMENTS"
                                   onClick={() => {
                                       this.getPhoneAndEmail({})
                                   }}
                                >Edit Payment Terms </a>
                                <div className="modal fade" id="PAYMENTS" role="dialog">
                                    {shouldPaymentTermsRender &&
                                    <PaymentTerms jobId={jobId}
                                                  senderId={userId}
                                                  socket={this.socket}
                                                  proposalId={proposalId}
                                                  ishired={true}
                                                  updateProposalAmountOnChat={updateAmountOnJobPaymentSummary !== undefined ? updateAmountOnJobPaymentSummary : ''}
                                                  country_code={country_code}
                                                  new_invoicing={new_invoicing}
                                                  chatRoomId={chatRoomId}
                                                  userAndProfessionalData={userAndProfessionalData}

                                    />}
                                </div>
                            </li>
                        )}
                        <li className="left-margin">
                            <div className="dropdown">
                                <a className="dropdown-toggle" type="button" data-toggle="dropdown"
                                   aria-expanded="false">
                                    <i className="fa fa-circle"></i>
                                    <i className="fa fa-circle"></i>
                                    <i className="fa fa-circle"></i>
                                </a>

                                <ul className="dropdown-menu">
                                    {/*<li>*/}
                                    {/*<Link*/}
                                    {/*className="hired-professional"*/}
                                    {/*to={`${path.invoice}/${proposalId}/${jobId}/${userId}`}>*/}
                                    {/*Invoice*/}
                                    {/*</Link>*/}
                                    {/*</li>*/}
                                    {/*<li>*/}
                                    {/*<Link*/}
                                    {/*    to={`/job-details/${proposalId}/${jobId}/${userId}`}>*/}
                                    {/*    Order Info*/}
                                    {/*</Link>*/}
                                    {/*</li>*/}
                                    {jobStatus === status.InProgress && (
                                        ((localStorage.userType === USER && userData.basicDetails !== undefined && userData.basicDetails.email && userData.client.phone) || (localStorage.userType === PROFESSIONAL && userData.basicDetails !== undefined && userData.basicDetails.email && userData.professional.phone)) &&
                                        <li>
                                            {/*<a onClick={() => props.history.push(`/end/contract/${jobId}/${proposalId}`)}*/}
                                            {/*>*/}
                                            {/*Terminate Contract*/}
                                            {/*</a>*/}
                                            <a href="mailto:support@workbeam.com"
                                            >
                                                Terminate Contract
                                            </a>
                                        </li>
                                    )}
                                    {is_super_user &&
                                    <Fragment>
                                        <li><a
                                            onClick={(e) => {
                                                this.markJobClose(jobId)
                                            }}
                                        >Mark as closed</a></li>
                                        <li><a
                                            onClick={(e) => {
                                                this.markJobInDispute(jobId)
                                            }}
                                        >Mark as dispute</a></li>
                                    </Fragment>
                                    }
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
                <p id="how_to_use_wc_popup" data-toggle="modal" data-target="#how_to_use_wc"/>
                {<WhatHappenNextPopup clientDetails={userAndProfessionalData.user_detail || {}}
                                      professionalDetails={userAndProfessionalData.Professional_detail || {}}

                />}
            </div>
        )
    }
}


const mapStateToProps = state => ({
    data: state.data,
    is_super_user: state.data.is_super_user !== undefined ? state.data.is_super_user : ''
});

const mapDispatchToProps = (dispatch) => ({
    ...(errorActionDispatchers(dispatch)),
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(JobInProgressDetailsPageHeaderLink))