import {Component, Fragment} from "react";
import React from "react";
import './../user/../../static/css/style.css'
import {withRouter} from "react-router-dom"
import {apiv1} from './../../api/index'
import {onlyZeroToNine, validateProfile} from '../common/validation/FormValidator'
import LoadingComponent from '../../utils/LoadingComponent'
import {registration, sessionLogin} from "../../actions/auth"
import {errorActionDispatchers} from "../../actions/errors"
import connect from "react-redux/es/connect/connect";
import localStorage, {KEY_REFERRAL_CODE} from "../../utils/localStorage"
// import MetaTags from 'react-meta-tags'
import * as path from '../../constants/path'
import {InTheNews} from "../common/AllOwlCrousel"
import {ProfessionalCard} from "../common/AllOwlCrousel"
import {USER} from "../../constants/auth";
import $ from "jquery"
import UserUpdateAddress from "../user/UserUpdateAddress"
import {FaqForAdd} from "../common/taxAddPopup"
import Link from "react-router-dom/es/Link"
// import {getDiscount} from '../../utils/clientSide'
// import SiteHeader from "../SiteHeader";
// import HiredIcon from "../../static/images/hired-icon.png";
// import CreditCard from "../../static/images/credit-card.png";
// import ColoredBullseye from "../../static/images/colored-bullseye.png";
import * as apiUrl from "../../api/apiUrls";
import {getFirstAndLastLetterOfName, getFullName} from "../common/userAndProfessionalProfileFunctions";
import LoginWithPhone from "../common/LoginWithPhone";
import "react-image-gallery/styles/css/image-gallery.css";
import {
    hideSiteHeader,
    // cutoffAmount,
    // firstInstallment,
    // fullPaymentText,
    payBookingAmount
} from "../services/BusinessFormationPvtLtd";
import {setLocalStorage} from "./UserSignUp";
import {couponCodeMsg, indiaCallingCode} from '../../constants/common'
import PhoneInput, {getCountryCallingCode, parsePhoneNumber} from "react-phone-number-input";
import 'react-phone-number-input/style.css'
import {_bookingAmountSlab} from "../../utils/clientSide";
// import {siteLogoOnSharedMedia} from "../common/bannerTextForProducts";
import Header from "../functional/beforeHire";
import {validateCouponCode} from "../../api/commonApi";

function ProfessionalCardSummary({professionalDetails, productDetails, discountedAmount, discountDetails, changeProductCountCallBack}) {
    return (
        <Fragment>
            {(professionalDetails.productId !== undefined || productDetails) &&
            <ProfessionalCard professionalDetails={professionalDetails}
                              productDetails={productDetails}
                              discountedAmount={discountedAmount}
                              changeProductCountCallBack={changeProductCountCallBack}
                              discountDetails={discountDetails}
            />
            }
        </Fragment>
    )

}

export function PaymentSecurityInformation() {
    return (
        <section className="page-contant" style={{paddingTop: '0px'}}>
            <div className="container">
                <div className="row">
                    <div className="col-sm-10 col-sm-offset-1">
                        <h4 className="heading-sm text-center">Don't worry, you're safe and in good hands.</h4>
                        <p className="hidden-xs">&#x00A0;</p>
                        <div className="col-sm-4 work-box-padding">
                            <div className="work-icon-box">
                                <img src={require("../../static/images/locked-credit-card.png").default}
                                     style={{width: '60px'}} alt="private limited"/>
                            </div>
                            <h3 className="scope-sub-heading">Payment Protection</h3>
                            <p className="xs-para">You're protected by 100% Workbeam payment Insurance. Get easy
                                refunds on non delivery.</p>
                        </div>
                        <div className="col-sm-4 work-box-padding">
                            <div className="work-icon-box">
                                <img src={require("../../static/images/delivery-truck.png").default} style={{width: '60px'}}
                                     alt="private limited"/>
                            </div>
                            <h3 className="scope-sub-heading">Track work Delivery</h3>
                            <p className="xs-para">Track work delivery using milestones.</p>
                        </div>
                        <div className="col-sm-4 work-box-padding">
                            <div className="work-icon-box">
                                <img src={require("../../static/images/internet-logo.png").default} style={{width: '60px'}}
                                     alt="private limited"/>
                            </div>
                            <h3 className="scope-sub-heading">Safety</h3>
                            <p className="xs-para">Data and Payments are highly secured. 256-bit SSL encryption & ISO
                                27001 Datacenter</p>
                        </div>
                        <p className="hidden-xs">&#x00A0;</p>
                        <p className="hidden-xs">&#x00A0;</p>
                        <div className="row">
                            <div className="col-sm-4 work-box-padding">
                                <div className="work-icon-box">
                                    <img src={require("../../static/images/conversation.png").default}
                                         style={{width: '60px'}} alt="private limited"/>
                                </div>
                                <h3 className="scope-sub-heading">Communicate</h3>
                                <p className="xs-para">Communicate using Workbeam. Chat, Audio or Video Call.</p>
                            </div>
                            <div className="col-sm-4 work-box-padding">
                                <div className="work-icon-box">
                                    <img src={require("../../static/images/inventory.png").default} style={{width: '60px'}}
                                         alt="private limited"/>
                                </div>
                                <h3 className="scope-sub-heading">Share Files</h3>
                                <p className="xs-para">Share important files for getting our work done.</p>
                            </div>
                            <div className="col-sm-4 work-box-padding">
                                <div className="work-icon-box">
                                    <img src={require("../../static/images/live-chat.png").default} style={{width: '60px'}}
                                         alt="private limited"/>
                                </div>
                                <h3 className="scope-sub-heading">Dedicated Support</h3>
                                <p className="xs-para">We assign you a dedicated support manager to help you
                                    throughout.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export function CreateProfessionalAccountPopUp({message, url, image, name, children, closePopup, closeButtonName}) {
    return (
        <div className="modal-dialog dialog-top-margin">
            <div className="modal-content">
                <div className="modal-header" style={{border: 'none', padding: '10px 15px'}}>
                    <button type="button"
                            className="close"
                            data-dismiss="modal"
                    >&times;</button>
                </div>
                <div className="modal-body text-center">
                    {image && <img src={image} style={{width: '100px'}} alt="client-reviews"/>}
                    <h3 className="xs-para">{message}</h3>
                    {children}
                </div>
                <div className="modal-footer" style={{border: 'none', textAlign: 'center', marginBottom: '15px'}}>
                    {url && <Link className='btn btn-login' to={url}
                                  onClick={() => {
                                      $('.modal-header .close').click();
                                  }}
                    >{name} </Link>}
                    {closePopup &&
                    <button type="button"
                            className="btn btn-save-profile"
                            data-dismiss="modal"
                            onClick={() => {
                                closePopup()
                            }}
                    >
                        {closeButtonName ? closeButtonName : 'Okay'}
                    </button>
                    }
                </div>
            </div>
        </div>
    )
}

export function returnProfessionalDetailsFromLocalStorage({isApplyCoupon}){
    let professionalDetails = JSON.parse(localStorage.selectedProfessionalDetails)
    professionalDetails = professionalDetails || {}
    if (isApplyCoupon){
        const {original_price} = professionalDetails
        professionalDetails = {
            ...professionalDetails,
            ...original_price,
        }
    }
    const {quantity_count} = professionalDetails
    return {
        ...professionalDetails,
        quantity_count: quantity_count || 1,
    }
}

class GeneralOnboarding extends Component {
    constructor(props) {
        super(props);
        let referral_code = localStorage.referralCode ? localStorage.referralCode : ''
        let professionalDetails = returnProfessionalDetailsFromLocalStorage({isApplyCoupon: false})
        let {discountDetails} = professionalDetails || {}
        discountDetails = discountDetails || {}
        let discountCouponCode = discountDetails.coupon_code || ''
        referral_code = ''
        let {city} = props
        city = city || []
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            password1: '',
            password: '',
            loading: false,
            errors: {},
            phone: '',
            countryCallingCode: indiaCallingCode,
            geoCity: city,
            title: '',
            description: '',
            practise_area: '',
            cityId: '',
            userId: '',
            company_name: '',
            gst_number: '',
            professionalDetails,
            consultationDetails: localStorage.consultationDetails ? JSON.parse(localStorage.consultationDetails) : {},
            productDetails: localStorage.selectedProductDetails ? JSON.parse(localStorage.selectedProductDetails) : {},
            // isSignUp: true,
            isLoggedIn: localStorage.isSessionValid(),
            referral_code,
            discountCouponCode,
            isValidReferralCode: false,
            // discountedAmount: 0,
            discountDetails,
            isFullPayment: false,
            userAndProData: {},
            isUpdateAddressEnabled: false
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const {geoCity, productDetails} = this.state
        if (geoCity.length === 0) {
            this.getCity()
        }
        if (!localStorage.isSessionValid()) {
            // this.addEventListenerOnKeyDown()
        }
        const {productId, product_custom} = productDetails
        if (!productId && !product_custom) {
            const {history} = this.props
            setTimeout(function () {
                alert('something went wrong')
                history.replace(path.root)
            }, 1000);
        }
    }

    componentWillUnmount() {
        $(document).off('scroll', hideSiteHeader);
        $('.main-header').css('display', 'block')
        if (document.body.removeEventListener) {
            document.body.removeEventListener('keyup', this.onSignInClick);
        }
    }

    addEventListenerOnKeyDown = () => {
        document.getElementById("password").addEventListener("keyup", (event) => {
            if (event.keyCode === 13) {
                this.onSignInClick(event)
            }

        });
        document.getElementById("email").addEventListener("keyup", (event) => {
            if (event.keyCode === 13) {
                this.onSignInClick(event)
            }
            this.onSignInClick(event)
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({isLoggedIn: localStorage.isSessionValid()})
    }

    getCity = () => {
        apiv1.noAuth.get('/geo/city-for-user/')
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    this.setState({geoCity: data, loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    };

    generatePaymentLink = ({request}) => {
        apiv1.auth.post(apiUrl.cashfreePayment, request)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    window.location.href = data.link
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })

    }

    paymentApi = (jobId) => {
        const {professionalDetails, isFullPayment, userAndProData} = this.state
        let {first_name, email, client} = userAndProData || {}
        client = client || {}
        let clientPhoneObj = client.phone || {}
        const {phone} = clientPhoneObj || {}
        const {price, product_name, professionalId, isConsultation, first_installment} = professionalDetails
        const request = {
            email: email,
            phone,
            firstname: first_name,
            productinfo: product_name,
            amount: isFullPayment ? price : first_installment,
            milestone: [],
            professional: professionalId,
        }
        if (isConsultation) {
            request.consult = jobId

        } else {
            request.job = jobId
        }
        if (price > 0) {
            this.generatePaymentLink({request})
        } else {
            const {history} = this.props
            history.push(path.userDashboard)
        }
    };

    earnReferredMoney = (referral_code, jobId, phone) => {
        const request = {
            referral_code
        }
        const {doDispatch} = this.props
        apiv1.auth.post(apiUrl.earnMoneyFromReferral, request)
            .then(res => {
                const data = apiv1.parseAndAutoHandleIssues(res, doDispatch);
                if (data) {
                    this.paymentApi(jobId, phone)
                    localStorage.removeKey(KEY_REFERRAL_CODE)
                }
            })
            .catch(err => {
                const {response} = err
                const data = apiv1.parseAndAutoHandleIssues(response, doDispatch)
                if (data) {
                    const {Error} = data
                    if (Error) {
                        this.paymentApi(jobId, phone)
                        localStorage.removeKey(KEY_REFERRAL_CODE)
                    }
                }
                apiv1.handleErrors(err, doDispatch);
            })
    }

    acceptProposal = ({jobId, proposalId, phone, couponId}) => {
        let request = {
            job: jobId,
            proposal: proposalId
        }
        if (couponId) {
            request.discount = couponId
        }
        apiv1.auth.post(apiUrl.acceptProposal, request)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    const {referral_code} = this.state
                    if (referral_code) {
                        this.earnReferredMoney(referral_code, jobId, phone)
                    } else {
                        this.paymentApi(jobId, phone)
                    }
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    // redeemCoupon = ({jobId, proposalId, phone, couponId}) => {
    //     let request = {
    //         id: couponId,
    //         has_user_redeemed: true
    //     }
    //     apiv1.auth.post(apiUrl.discountCoupon, request)
    //         .then(res => {
    //             const data = apiv1.parseAndAutoHandleIssues(res, this.props.doDispatch);
    //             if (data) {
    //                 this.acceptProposal({jobId, proposalId, phone, couponId})
    //             }
    //         })
    //         .catch(err => {
    //             this.setState({loading: false})
    //             apiv1.handleErrors(err, this.props.doDispatch);
    //         })
    // }

    sendProposal = (professionalDetails, jobId, phone) => {
        let {wcFees, wcGst, gstAmount, price, actual_amount_enter_by_professional, professionalId, delivery_time, service_fee, commission, commission_tax} = professionalDetails
        const {discountDetails} = this.state
        const request = {
            message: 'Sent from web',
            fixed_amount: price,
            commission,
            commission_tax,
            professional_amount_tax: gstAmount,
            actual_amount_enter_by_professional: actual_amount_enter_by_professional,
            schedule_unit_proposed: delivery_time || 15,
            sender: professionalId,
            job: jobId,
            service_fee
        }
        apiv1.auth.post(apiUrl.sendProposal, request)
            .then(res => {
                const proposalData = apiv1.parseAndAutoHandleIssues(res, this.props.doDispatch);
                if (proposalData) {
                    const {id} = discountDetails || {}
                    if (id) {
                        this.acceptProposal({jobId, proposalId: proposalData.id, phone, couponId: id})
                    } else {
                        this.acceptProposal({jobId, proposalId: proposalData.id, phone})
                    }
                }
            })
            .catch(err => {
                this.setState({loading: false})
                apiv1.handleErrors(err, this.props.doDispatch);
            })
    }

    createConsultation = ({cityId, phone}) => {
        const {consultationDetails, professionalDetails} = this.state
        const {wcFees, wcGst, gstAmount, actual_amount_enter_by_professional, commission, commission_tax} = professionalDetails
        const request = {
            ...consultationDetails,
            commission,
            commission_tax,
            professional_amount_tax: gstAmount,
            actual_amount_enter_by_professional,
        }
        apiv1.auth.post(`${apiUrl.aboutConsultation}/`, request)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    const {id} = data
                    const {referral_code} = this.state
                    if (referral_code) {
                        this.earnReferredMoney(referral_code, id, phone)
                    } else {
                        this.paymentApi(id, phone)
                    }
                }
            })
            .catch(err => {
                this.setState({loading: false})
                apiv1.handleErrors(err, this.props.doDispatch);
            })
    }

    createJob = ({cityId, phone}) => {
        const {professionalDetails, productDetails} = this.state
        const {description} = productDetails || {}
        const {product_name, parentPracticeAreaId, productId, quantity_count, product_custom} = professionalDetails
        let request = {
            job_question: [],
            title: `${product_name}, Quantity : ${quantity_count || 1}`,
            description: `${description ? description : 'created from web'}`,
            practise_area: parentPracticeAreaId,
            city: cityId,
            send_notification_to_all: false,
            is_direct_hire: true,
        }
        if (product_custom) {
            request = {
                ...request,
                product_custom
            }
        } else {
            request = {
                ...request,
                product: productId
            }
        }
        apiv1.auth.post(apiUrl.createJob, request)
            .then(res => {
                const data = apiv1.parseAndAutoHandleIssues(res, this.props.doDispatch);
                if (data) {
                    this.sendProposal(professionalDetails, data.id, phone)
                }
            })
            .catch(err => {
                this.setState({loading: false})
                apiv1.handleErrors(err, this.props.doDispatch);
            })
    }


    updateProfileAndPostJob = ({data}) => {
        let {isConsultation, countryCallingCode} = this.state
        let userAndProData = {...this.state.userAndProData}
        localStorage.userType = USER
        let phone = ''
        let cityId = ''
        userAndProData = {
            ...userAndProData,
            ...data,
            client: {
                ...userAndProData.client,
                ...data.client,
            }
        }
        let {client} = userAndProData
        let {address} = client || {}
        address = address || {}
        let {city} = address
        if (client.phone) {
            let {nationalNumber} = parsePhoneNumber(client.phone.phone || '') || {}
            nationalNumber = nationalNumber || ''
            phone = nationalNumber
        }
        if (city) {
            cityId = city
        }
        let city_id = countryCallingCode === indiaCallingCode ? cityId : 'dummy'

        if (!city_id || !phone) {
            this.setState({loading: false, cityId, phone, userAndProData, isUpdateAddressEnabled: true}, () => {
                $('#login_with_otp_pop_up_on_general_onboarding .close').click()
                $('#update_profile_msg').click();
            })
        } else {
            let professionalDetails = {
                ...this.state.professionalDetails,
            }
            this.setState({professionalDetails, loading: true, userAndProData, cityId}, () => {
                if (isConsultation) {
                    this.createConsultation({cityId, phone})
                } else {
                    this.createJob({cityId, phone})
                }
            })
        }
    }


    restAuthPatchForUserTypeSwitch = ({request}) => {
        const {doDispatch} = this.props
        apiv1.auth.patch(apiUrl.restAuth, request)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                if (data) {
                    this.updateProfileAndPostJob({data})
                }
            })
            .catch(err => {
                apiv1.handleErrors(err, doDispatch)
            })
    }

    switchAsClientProfile = ({}) => {
        const request = {
            client: {
                last_session_as_client: true
            }
        }
        this.restAuthPatchForUserTypeSwitch({request})
    }

    applyCouponCode = ({}) => {
        const {discountDetails, userAndProData} = this.state
        let {is_default_professional} = userAndProData
        const {id} = discountDetails || {}
        let promise = new Promise((resolve, reject) => {
            if (id) {
                validateCouponCode({isLoggedIn: true, professionalDetails: returnProfessionalDetailsFromLocalStorage({isApplyCoupon: true}), discountDetails})
                    .then((props) => {
                        let {isProceedToPayment, newStateObj} = props
                        let {discountDetails} = newStateObj
                        discountDetails = discountDetails || {}
                        const {is_valid_coupon_code} = discountDetails
                        if (!is_valid_coupon_code){
                            const professionalDetails = returnProfessionalDetailsFromLocalStorage({isApplyCoupon: true})
                            newStateObj = {
                                ...newStateObj,
                                professionalDetails
                            }
                        }
                        this.setState(newStateObj, () => {
                            if (isProceedToPayment) {
                                resolve()
                            } else {
                                reject()
                            }
                        })
                    })
            } else {
                resolve()
            }
        })
        promise.then(() => {
            if (is_default_professional) {
                this.switchAsClientProfile({})
            } else {
                this.updateProfileAndPostJob({data: userAndProData})
            }
        })
            .catch((props) => {
                this.setState({loading: false})
            })
    }

    saveCity = (cityId) => {
        const {company_name, gst_number} = this.state
        const request = {
            client: {
                company_name,
                gst_number,
                address: {
                    city: cityId
                }
            }
        }
        apiv1.auth.patch(apiUrl.restAuth, request)
            .then(res => {
                const data = apiv1.parseAndAutoHandleIssues(res, this.props.doDispatch);
                if (data) {
                    this.setState({userAndProData: data}, () => {
                        this.applyCouponCode({})
                    })
                }
            })
            .catch(err => {
                this.setState({loading: false})
                apiv1.handleErrors(err, this.props.doDispatch);
            })
    }

    fetchUserAndProData = ({isSignUp}) => {
        this.setState({loading: true})
        apiv1.auth.get(apiUrl.restAuth)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    const {client} = data
                    const phoneObj = client.phone || {}
                    const {country_code} = phoneObj
                    this.setState({userAndProData: data, countryCallingCode: country_code}, () => {
                        const {first_name, last_name} = data
                        setLocalStorage({auth_token: '', USER, first_name, last_name})
                        const {cityId} = this.state
                        if (isSignUp && cityId) {
                            this.saveCity(cityId)
                        } else {
                            this.applyCouponCode({})
                        }
                    })
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    };

    onProceedToPayment = (e) => {
        const {first_name, last_name, email, password1, phone, cityId, countryCallingCode, company_name, gst_number} = this.state;
        const {nationalNumber} = parsePhoneNumber(phone) || {}
        let request = {
            username: email,
            first_name,
            last_name,
            email,
            password1,
            phone,
            client: {
                company_name,
                gst_number,
            },
            password2: password1,
            is_professional: false,
            country_code: countryCallingCode || indiaCallingCode
        };
        const validatorObj = {
            ...request,
            phone: nationalNumber || '',
            address: {
                state: countryCallingCode === indiaCallingCode ? cityId : 'dummy'
            }
        };
        const errors = {};
        const isFormValidated = validateProfile(errors, validatorObj, true)
        if (isFormValidated) {
            this.setState({loading: true});
            apiv1.noAuth.post(apiUrl.userAndProRegistration, request)
                .then((resp) => {
                    this.setState({loading: false});
                    const data = apiv1.parseAndAutoHandleIssues(resp);
                    if (data) {
                        this.props.onSignUp(data.key, this.props.type, first_name, last_name);
                        this.fetchUserAndProData({isSignUp: true})
                        window.userSignUpRecord()
                    } else {
                        this.setState({loading: false})
                    }
                })
                .catch((err) => {
                    const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
                    for (let key in resErrors) {
                        errors[key] = resErrors[key][0]
                    }
                    this.setState({loading: false, errors, email: '', password1: ''})
                })
        } else {
            this.setState({loading: false, errors})
        }
    };

    // signWithWithGoogle = (googleUser) => {
    //     const errors = {};
    //     const request = {
    //         access_token: googleUser.accessToken
    //     };
    //     this.setState({loading: true, error: {}});
    //     apiv1.noAuth.post('/rest-auth/google/connect/', request)
    //         .then((resp) => {
    //             this.setState({loading: false});
    //             const data = apiv1.parseAndAutoHandleIssues(resp);
    //             if (data) {
    //                 this.props.onSignUp(data.key, this.props.type, googleUser.w3.ofa, googleUser.w3.wea);
    //                 // askForPermissioToReceiveNotifications();
    //                 if (Boolean(localStorage.isBookConsultation)) {
    //                     this.props.history.push({
    //                         pathname: path.findAProfessionalByLocation
    //                     })
    //                 } else {
    //                     this.props.history.push({
    //                         pathname: userOnboarding
    //                     })
    //                 }
    //
    //             } else {
    //                 this.setState({loading: false})
    //             }
    //         })
    //         .catch((err) => {
    //             const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
    //             for (let key in resErrors) {
    //                 errors[key] = resErrors[key][0]
    //             }
    //             this.setState({loading: false, errors, email: '', password1: ''})
    //         })
    // };


    // validateCouponCode = ({isLoggedIn}) => {
    //     const {discountCouponCode, productDetails} = this.state
    //     const {doDispatch} = this.props
    //     this.setState({loading: true})
    //     let url = `${apiUrl.discountCoupon}?coupon_code=${discountCouponCode}`
    //     let couponCodeApi = isLoggedIn ? apiv1.auth.get(url) : apiv1.noAuth.get(url)
    //     return couponCodeApi
    //         .then(resp => {
    //             let data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
    //             if (data) {
    //                 data = data.slice(-1)
    //                 const {id, has_user_redeemed, product, is_expired} = data[0] ? data[0] : {}
    //                 let errors = {...this.state.errors}
    //                 errors.coupon_code = couponCodeMsg.invalid
    //                 let discountDetails = {...this.state.discountDetails, ...data[0]}
    //                 let newStateObj = {
    //                     // discountedAmount: 0,
    //                     discountDetails,
    //                     isValidCouponCode: false,
    //                     errors
    //                 }
    //                 const {productId} = productDetails
    //                 if (id) {
    //                     if (!is_expired) {
    //                         let professionalDetails = this.returnProfessionalDetailsFromLocalStorage({})
    //                         if (!has_user_redeemed) {
    //                             errors.coupon_code = ''
    //                             newStateObj = {
    //                                 ...newStateObj,
    //                                 errors,
    //                             }
    //                             let {price, actual_amount_enter_by_professional, wcFees, wcGst} = professionalDetails
    //                             if (product) {
    //                                 if (product === productId) {
    //                                     let {fee_pro, fee_commission, fee_gst, fee_total} = data[0]
    //                                     fee_pro = parseFloat(fee_pro)
    //                                     wcFees = parseFloat(fee_commission)
    //                                     wcGst = parseFloat(fee_gst)
    //                                     fee_total = parseFloat(fee_total)
    //                                     if (fee_total === 0) {
    //                                         fee_pro = 0
    //                                         wcFees = 0
    //                                         wcGst = 0
    //                                     }
    //                                     newStateObj = {
    //                                         ...newStateObj,
    //                                         isValidCouponCode: true,
    //                                         professionalDetails: {
    //                                             ...professionalDetails,
    //                                             price: fee_total,
    //                                             wcFees,
    //                                             wcGst,
    //                                             actual_amount_enter_by_professional: fee_pro,
    //                                         }
    //                                     }
    //                                 } else {
    //                                     errors.coupon_code = couponCodeMsg.not_applicable
    //                                     newStateObj = {
    //                                         ...newStateObj,
    //                                         errors,
    //                                         loading: false,
    //                                         isLoggedIn
    //                                     }
    //                                     return this.closeLoginPopUp({newStateObj})
    //                                 }
    //                             } else {
    //                                 price = discountDetails.discountAmount ? discountDetails.discountAmount + price : price
    //                                 actual_amount_enter_by_professional = discountDetails.discountAmount ? discountDetails.discountAmount + actual_amount_enter_by_professional : actual_amount_enter_by_professional
    //                                 professionalDetails = {
    //                                     ...professionalDetails,
    //                                     price,
    //                                     actual_amount_enter_by_professional,
    //                                 }
    //                                 let discountedAmount = getDiscount({discountSlab: data[0], professionalDetails})
    //                                 price = professionalDetails.price - discountedAmount
    //                                 actual_amount_enter_by_professional = professionalDetails.actual_amount_enter_by_professional - discountedAmount
    //                                 if (price === 0) {
    //                                     wcFees = 0
    //                                     wcGst = 0
    //                                     actual_amount_enter_by_professional = 0
    //                                     professionalDetails = {
    //                                         ...professionalDetails,
    //                                         wcFees,
    //                                         wcGst,
    //                                     }
    //                                 }
    //                                 newStateObj = {
    //                                     ...newStateObj,
    //                                     isValidCouponCode: true,
    //                                     professionalDetails: {
    //                                         ...professionalDetails,
    //                                         price,
    //                                         actual_amount_enter_by_professional,
    //                                     },
    //                                 }
    //                             }
    //                         } else {
    //                             errors.coupon_code = couponCodeMsg.already_used
    //                             discountDetails.isDiscountApplied = false
    //                             professionalDetails = {
    //                                 ...professionalDetails,
    //                                 price: discountDetails.discountAmount ? discountDetails.discountAmount + professionalDetails.price : professionalDetails.price,
    //                                 actual_amount_enter_by_professional: discountDetails.discountAmount ? discountDetails.discountAmount + professionalDetails.actual_amount_enter_by_professional : professionalDetails.actual_amount_enter_by_professional
    //                             }
    //                             localStorage.selectedProductDetails = JSON.stringify(professionalDetails)
    //                             newStateObj = {
    //                                 professionalDetails,
    //                                 ...newStateObj,
    //                                 discountDetails,
    //                                 errors,
    //                                 isLoggedIn,
    //                                 loading: false,
    //                             }
    //                         }
    //                     } else {
    //                         errors.coupon_code = couponCodeMsg.expired
    //                         newStateObj = {
    //                             ...newStateObj,
    //                             errors,
    //                             isLoggedIn,
    //                             loading: false,
    //                         }
    //                     }
    //                 }
    //                 if ((is_expired || has_user_redeemed)) {
    //                     return this.closeLoginPopUp({newStateObj})
    //                 }
    //                 return Promise.resolve({newStateObj, isProceedToPayment: true})
    //             }
    //         })
    //         .catch(err => {
    //             apiv1.handleErrors(err, doDispatch)
    //             Promise.resolve({newStateObj: {}, isProceedToPayment: false})
    //         })
    // }

    checkValidityForReferralCode = () => {
        const {referral_code, errors} = this.state
        const {doDispatch} = this.props
        this.setState({loading: true})
        apiv1.noAuth.post(`/users/referral-code-valid`, {referral_code})
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                if (data) {
                    const isValidReferralCode = true
                    const {referral_code} = data
                    localStorage.referralCode = referral_code
                    this.setState({isValidReferralCode, referral_code, loading: false})
                }
            })
            .catch(err => {
                const data = apiv1.parseAndAutoHandleIssues(err.response, doDispatch)
                apiv1.handleErrors(err, doDispatch)
                let {isValidReferralCode} = this.state
                if (data) {
                    const {Error} = data
                    if (Error) {
                        isValidReferralCode = false
                        errors['referral_code'] = Error
                    }
                }
                this.setState({loading: false, errors, isValidReferralCode})
            })
    }

    onSignInClick = (e) => {
        e.preventDefault();
        const {email, password} = this.state;
        const {doDispatch} = this.props
        let request = {
            email,
            password
        };
        const errors = {};
        const isFormValidated = validateProfile(errors, request);
        if (isFormValidated === true) {
            this.setState({loading: true, error: {}});
            apiv1.noAuth.post(apiUrl.restAuthLogin, request)
                .then((resp) => {
                    const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch)
                    if (data) {
                        this.props.onLogin(data.key);
                        this.fetchUserAndProData({isSignUp: false})
                    } else {
                        this.setState({loading: false})
                    }
                })
                .catch((err) => {
                    const resErrors = apiv1.handleErrors(err, doDispatch);
                    for (let key in resErrors) {
                        errors[key] = resErrors[key][0]
                    }
                    this.setState({loading: false, errors, email: '', password: ''})
                })
        } else {
            this.setState({loading: false, errors})
        }

    };

    onClickMakePayment = (e) => {
        if (localStorage.isSessionValid()) {
            this.fetchUserAndProData({isSignUp: false})
        } else {
            this.onProceedToPayment(e, false)
        }
        window.onProceedToPaymentButtonClick()
    }

    makeEscrowPayment = ({professionalDetails}) => {
        const {loading} = this.state
        const {first_installment} = professionalDetails
        return (
            <a className={`btn btn-make-payment btn-track-pay ${loading ? 'disabled' : ''}`}
               onClick={(e) => {
                   this.setState({isFullPayment: false}, () => {
                       this.onClickMakePayment(e)
                   })
               }}
            >
                {`${payBookingAmount} $${first_installment}`}
            </a>
        )

    }

    onChange = (e) => {
        let errors = {...this.state.errors}
        const {name, value} = e.target
        errors[name] = ''
        let message = ''
        if (name === 'phone') {
            message = 'Phone no is no valid'
            onlyZeroToNine(errors, name, value, message)
        }
        this.setState({[name]: value, errors})
    };

    removeCouponCode = ({newState}) => {
        let professionalDetails = returnProfessionalDetailsFromLocalStorage({isApplyCoupon: true})
        newState = {
            ...newState,
            professionalDetails,
            discountCouponCode: '',
            // isValidCouponCode: '',
            discountDetails: {is_valid_coupon_code: ''},
        }
        this.setState(newState)
    }

    changeProductCount = ({isIncreasing, isDecreasing}) => {
        let {professionalDetails} = this.state
        let {quantity_count, commission, commission_tax, discountAmount, price, professional_amount_tax, calculated_commissions, first_installment} = professionalDetails || {}
        calculated_commissions = calculated_commissions || {}
        if (isIncreasing) {
            quantity_count += 1
            commission += calculated_commissions.commission
            commission_tax += calculated_commissions.commission_tax
            professional_amount_tax += calculated_commissions.professional_amount_tax
            price += calculated_commissions.price
        }
        if (isDecreasing) {
            quantity_count -= 1
            commission -= calculated_commissions.commission
            commission_tax -= calculated_commissions.commission_tax
            professional_amount_tax -= calculated_commissions.professional_amount_tax
            price -= calculated_commissions.price
        }
        first_installment = _bookingAmountSlab({amount: price})
        professionalDetails = {
            ...professionalDetails,
            commission,
            commission_tax,
            professional_amount_tax,
            quantity_count,
            price,
            first_installment
        }
        localStorage.selectedProductDetails = JSON.stringify(professionalDetails)
        this.setState({professionalDetails})
    }

    couponCodeUi = () => {
        const {discountCouponCode, errors, loading, discountDetails, professionalDetails} = this.state
        const {is_valid_coupon_code} = discountDetails
        return (
            <div className="col-sm-8">
                <div className="form-group">
                    <input type="text"
                           className="form-control input-lg"
                           value={discountCouponCode}
                           onChange={(e) => {
                               const {name, value} = e.target
                               const errors = {...this.state.errors}
                               errors.coupon_code = ''
                               this.setState({
                                   [name]: value,
                                   // isValidCouponCode: '',
                                   errors,
                                   discountDetails: {is_valid_coupon_code: '',
                                       coupon_code: value
                                   },
                                   // discountedAmount: 0
                               })
                           }}
                           name="discountCouponCode"
                           placeholder="Enter Coupon Code"
                           style={{borderBottom: errors.coupon_code ? '1px solid #ff337d' : ''}}
                    />
                    <button type="button" className="btn btn-link"
                            onClick={(e) => {
                                if (discountCouponCode) {
                                    const {isLoggedIn} = this.state
                                    validateCouponCode({isLoggedIn, discountDetails, professionalDetails: returnProfessionalDetailsFromLocalStorage({isApplyCoupon: true})})
                                        .then((props) => {
                                            let {newStateObj} = props
                                            let {discountDetails} = newStateObj
                                            discountDetails = discountDetails || {}
                                            const {is_valid_coupon_code} = discountDetails
                                            if (!is_valid_coupon_code){
                                                const professionalDetails = returnProfessionalDetailsFromLocalStorage({isApplyCoupon: true})
                                                newStateObj = {
                                                    ...newStateObj,
                                                    professionalDetails
                                                }
                                            }
                                            newStateObj = {
                                                ...newStateObj,
                                                loading: false
                                            }
                                            this.setState(newStateObj)
                                        }).catch(() => {
                                        this.setState({loading: false})
                                    })

                                }
                            }}>
                        Apply
                    </button>
                    {(is_valid_coupon_code || Object.values(couponCodeMsg).indexOf(errors.coupon_code) > -1) &&
                    <button type="button"
                            className="btn btn-link remove"
                            disabled={loading}
                            onClick={() => {
                                const errors = {...this.state.errors}
                                errors.coupon_code = ''
                                let newState = {
                                    errors
                                }
                                this.removeCouponCode({newState})
                            }}
                    >
                        Remove
                    </button>}
                    <span className={errors.coupon_code ? 'error' : 'no-error'}>{errors.coupon_code}</span>
                    {is_valid_coupon_code && <span className="text-success">{couponCodeMsg.applied}</span>}
                </div>
            </div>
        )
    }


    signUpUi = () => {
        let {first_name, last_name, email, password1, errors, phone, geoCity, loading, discountDetails, company_name, gst_number, countryCallingCode} = this.state;
        return (
            <div className="col-sm-7">
                <div className="col-sm-12">
                    <h2 className="ui-sign-heading">Sign Up Details <span className="pull-right"><small
                        className="hidden-xs">Already have an Account?</small><a
                        onClick={() => {
                            $('#general_onBoarding_login_with_phone').click()
                            // this.setState({isSignUp: false, errors: {}})
                        }}
                        className="btn btn-link">Login</a></span></h2>
                </div>
                <div className={(errors.username) ? 'msg msg-danger msg-danger-text' : 'hidden'}>
                                    <span className="glyphicon glyphicon-remove pull-right"
                                          style={{cursor: 'pointer'}}
                                          onClick={() => this.setState({errors: {}})}
                                    >
                                    </span>
                    {errors.username}
                </div>
                <div className="clearfix"></div>
                <form className="signup-form">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <input type="text"
                                   className="form-control input-lg"
                                   value={first_name}
                                   onChange={this.onChange}
                                   name="first_name" placeholder="First Name"
                                   style={{borderBottom: errors.first_name ? '1px solid #ff337d' : ''}}
                            />
                            <span className={errors.first_name ? 'error' : 'no-error'}>{errors.first_name}</span>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <input type="text"
                                   className="form-control input-lg"
                                   value={last_name}
                                   onChange={this.onChange}
                                   name="last_name"
                                   placeholder="Last Name"
                                   style={{borderBottom: errors.last_name ? '1px solid #ff337d' : ''}}
                            />
                            <span className={errors.last_name ? 'error' : 'no-error'}>{errors.last_name}</span>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-6">
                        <div style={{marginTop: '5px'}}
                            // className={countryCallingCode === indiaCallingCode ? 'col-sm-6' : 'col-sm-12'}
                        >
                            <PhoneInput
                                className="form-control input-lg"
                                name="phone"
                                value={phone}
                                international
                                countryCallingCodeEditable={false}
                                onChange={(phone) => {
                                    let e = {
                                        target: {
                                            name: 'phone',
                                            value: phone
                                        }
                                    }
                                    const newErrors = {...this.state.errors}
                                    const {name, value} = e.target
                                    newErrors[name] = ''
                                    this.setState({[name]: value, errors: newErrors})
                                }}
                                onCountryChange={(country) => {
                                    country = country || ''
                                    if (country) {
                                        let countryCallingCode = `+${getCountryCallingCode(country)}`
                                        this.setState({countryCallingCode})
                                    }
                                }}
                                placeholder="Mobile E.g. 99XXXXXX99 "
                                defaultCountry="CA"
                                style={{borderBottom: errors.phone ? '1px solid #ff337d' : ''}}
                            />
                            <span className={errors.phone ? 'error' : 'no-error'}>{errors.phone}</span>
                        </div>
                    </div>
                    {countryCallingCode === indiaCallingCode &&
                    <div className="col-sm-6">
                        <div className="form-group" style={{marginTop: '18px'}}>
                            <select className="form-control"
                                    onChange={(e) => {
                                        const {errors} = this.state
                                        errors['state'] = ''
                                        const cityId = e.target.value.toString()
                                        this.setState({cityId, errors})
                                    }}
                            >
                                <option value='-1' id='court_state_text' className='hidden'>City</option>
                                {geoCity.map(cityData => {
                                    return <option value={cityData.id} id={cityData.name}
                                                   key={cityData.id}>{cityData.name}</option>
                                })}
                            </select>
                            <span className={errors.state ? 'error' : 'no-error'}>{errors.state}</span>
                        </div>
                    </div>
                    }
                    <div className="clearfix"></div>
                    <div className="col-sm-12">
                        <div className="form-group">
                            <input type="email"
                                   className="form-control input-lg"
                                   disabled={loading}
                                   value={email}
                                   onChange={this.onChange}
                                   name="email"
                                   placeholder="Email"
                                // id='email'
                                   style={{borderBottom: errors.email ? '1px solid #ff337d' : ''}}
                            />
                            <span className={errors.email ? 'error' : 'no-error'}>{errors.email}</span>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group">
                            <input type="password"
                                   className="form-control input-lg"
                                   value={password1}
                                   onChange={this.onChange}
                                   name="password1"
                                   placeholder="Password"
                                // id='password'
                                   style={{borderBottom: errors.password1 ? '1px solid #ff337d' : ''}}
                            />
                            <span className={errors.password1 ? 'error' : 'no-error'}>{errors.password1}</span>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <input type="text"
                                   className="form-control input-lg"
                                   value={company_name}
                                   onChange={this.onChange}
                                   name="company_name" placeholder="Company Name (optional)"
                                   style={{borderBottom: errors.company_name ? '1px solid #ff337d' : ''}}
                            />
                            <span className={errors.company_name ? 'error' : 'no-error'}>{errors.company_name}</span>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <input type="text"
                                   className="form-control input-lg"
                                   value={gst_number}
                                   onChange={this.onChange}
                                   name="gst_number" placeholder="Gst Number (optional)"
                                   style={{borderBottom: errors.gst_number ? '1px solid #ff337d' : ''}}
                            />
                            <span className={errors.gst_number ? 'error' : 'no-error'}>{errors.gst_number}</span>
                        </div>
                    </div>
                    {!discountDetails.isDiscountApplied &&
                    this.couponCodeUi()
                    }
                    {this.paymentAndTermAndConditionButtonUi()}
                    <div className="visible-xs">
                        <p className="brief-note"><img src={require("../../static/images/shield.png").default}
                                                       className="lock-verified-icon" style={{width: '20px'}}
                                                       alt="signup"/>100% money back guarantee</p>
                        <p className="brief-note"><img src={require("../../static/images/lock.png").default}
                                                       className="lock-verified-icon" style={{width: '20px'}}
                                                       alt="signup"/>256-bit SSL encryption & ISO 27001 Datacenter</p>
                    </div>
                </form>
            </div>
        )
    }

    paymentAndTermAndConditionButtonUi = () => {
        const {loading, professionalDetails} = this.state;
        // let professionalDetailsFromLocalStorage = this.returnProfessionalDetailsFromLocalStorage({})
        let {price, first_installment} = professionalDetails || {}
        return (
            <Fragment>
                <div className="col-sm-12">
                    <div className="d-button-container-box onboarding-box">
                        {price > first_installment &&
                        <Fragment>
                            <div className="btn-strip-box">
                                {this.makeEscrowPayment({professionalDetails})}
                            </div>
                            <div className="btn-strip-box">
                                <p className="para text-center" style={{marginTop: '10px'}}><b>Or</b></p>
                            </div>
                        </Fragment>
                        }
                        <div className="btn-strip-box">
                            <a className={`btn hire-designer-button-full-pay btn-track-pay ${loading ? 'disabled' : ''}`}
                               onClick={(e) => {
                                   this.setState({isFullPayment: true}, () => {
                                       this.onClickMakePayment(e)
                                   })
                               }}>
                                {`${price > 0 ? `Make Full Payment $${price}` : 'Book Service'}`}
                            </a>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
                <div className="col-sm-12">
                    <h4 className="text-dark text-center" style={{marginTop: '20px'}}>By clicking here you agree to
                        our <b>
                            <a href={path.termsAndConditions} target="_blank">Terms of Service</a> &amp;
                            <a href={path.privacyPolicy} style={{marginLeft: '5px'}} target="_blank">Privacy
                                policy</a>
                        </b>
                    </h4>
                </div>
            </Fragment>
        )
    }

    loggedInSessionUi = () => {
        const {loading, productDetails, professionalDetails, discountDetails} = this.state;
        let avatar = ''
        if (localStorage.avatar !== 'null') {
            avatar = localStorage.avatar
        }
        let professionalDetailsFromLocalStorage = returnProfessionalDetailsFromLocalStorage({isApplyCoupon: false})
        const {first_installment, price, original_price} = professionalDetails || {}
        return (
            <div className="row">
                <div className="col-sm-6 col-sm-offset-3 valid-session-ui">
                    <div className="media">
                        <div className="media-left">
                            {avatar ?
                                <img src={avatar} alt={`${localStorage.firstName}`} className="img-circle dp-image"/>
                                :
                                getFirstAndLastLetterOfName(localStorage.firstName, localStorage.lastName)
                            }
                        </div>
                        <div className="media-body">
                            <p className="para">Already logged in as <b>
                                {
                                    getFullName(localStorage.firstName, localStorage.lastName)
                                }
                            </b></p>
                        </div>
                    </div>
                    <br/>
                    {loading && (
                        <div style={{marginTop: '10%', marginLeft: '30%', position: 'fixed', zIndex: '10'}}>
                            <LoadingComponent pastDelay/></div>)
                    }
                    <ProfessionalCardSummary professionalDetails={professionalDetailsFromLocalStorage}
                                             productDetails={productDetails}
                                             discountedAmount={original_price.price - professionalDetails.price}
                                             discountDetails={discountDetails}
                    />
                    <p>&#x00A0;</p>
                    <div className="signup-form">
                        {!discountDetails.isDiscountApplied &&
                        this.couponCodeUi()
                        }
                    </div>
                    <p>&#x00A0;</p>
                    {this.paymentAndTermAndConditionButtonUi()}
                    <p>&#x00A0;</p>
                    <p>&#x00A0;</p>
                </div>
            </div>
        )
    }


    metaTag = () => {
        return (
            <Header isIndexing={false} title={`General Onboarding`} avatar='' link={''} metaDescription=''/>
        )
    }


    render() {
        let {loading, geoCity, phone, cityId, productDetails, isLoggedIn, professionalDetails, isUpdateAddressEnabled, countryCallingCode, discountDetails} = this.state;
        let professionalDetailsFromLocalStorage = returnProfessionalDetailsFromLocalStorage({isApplyCoupon: false})
        const {first_installment, price, original_price} = professionalDetails || {}
        return (
            <Fragment>
                {this.metaTag()}
                <p className="hidden-xs">&#x00A0;</p>
                <Fragment>
                    <section className="page-contant general-onboarding">
                        <div className="container">
                            {isLoggedIn ?
                                this.loggedInSessionUi()
                                :
                                <Fragment>
                                    <div className="row display-flex">
                                        {loading && (
                                            <div style={{
                                                marginTop: '10%',
                                                marginLeft: '30%',
                                                position: 'fixed',
                                                zIndex: '10'
                                            }}>
                                                <LoadingComponent pastDelay/></div>)}
                                        {this.signUpUi()}
                                        <div className="col-sm-4 col-sm-offset-1">
                                            <ProfessionalCardSummary
                                                professionalDetails={professionalDetails}
                                                productDetails={productDetails}
                                                discountedAmount={original_price.price-price}
                                                discountDetails={discountDetails}
                                                // changeProductCountCallBack={this.changeProductCount}
                                            />
                                            <br/>
                                            <ul className="list-inline list-card">
                                                <li><img src={require("../../static/images/Mastercard.png").default}
                                                         style={{width: '70px'}} alt="signup"/></li>
                                                <li><img src={require("../../static/images/Visa.png").default}
                                                         style={{width: '70px'}} alt="signup"/></li>
                                                <li><img src={require("../../static/images/Upi.png").default}
                                                         style={{width: '70px'}} alt="signup"/></li>
                                                <li><img src={require("../../static/images/Paytm.png").default}
                                                         style={{width: '70px'}} alt="signup"/></li>
                                            </ul>
                                            <div className="hidden-xs">
                                                <p className="brief-note"><img
                                                    src={require("../../static/images/shield.png").default}
                                                    className="lock-verified-icon" style={{width: '25px'}}
                                                    alt="signup"/>100% money back guarantee</p>
                                                <p className="brief-note"><img
                                                    src={require("../../static/images/lock.png").default}
                                                    className="lock-verified-icon" style={{width: '20px'}}
                                                    alt="signup"/>256-bit SSL encryption & ISO 27001 Data center</p>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            }
                        </div>
                    </section>
                    <section className="page-contant wiz-work hidden-xs">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h2 className="heading-sm text-center">Workbeam in the Press<span
                                        className="dot-pink">.</span></h2>
                                    <p>&#x00A0;</p>
                                    <InTheNews/>
                                </div>
                                <p>&#x00A0;</p>
                                {/*<p className="hidden-xs">&#x00A0;</p>*/}
                                {/*<p className="hidden-xs">&#x00A0;</p>*/}
                                {/*<p className="hidden-xs">&#x00A0;</p>*/}
                                {/*<div className="col-sm-12">*/}
                                {/*<h4 className="heading-sm text-center"><span*/}
                                {/*style={{color: '#ff1e9b'}}>Have Doubts?</span> Read how Workbeam works.</h4>*/}
                                {/*</div>*/}
                                {/*<div className="col-sm-4 work-box-padding">*/}
                                {/*<div className="work-icon-box">*/}
                                {/*<img src={CreditCard} style={{width: '70px'}} alt="icon"/>*/}
                                {/*</div>*/}
                                {/*<h3 className="scope-sub-heading">Make Escrow payment</h3>*/}
                                {/*<p className="xs-para">Make Payment. Your payment is 100% protected and refundable.*/}
                                {/*The payment stays with Workbeam and is sent to the expert after your work is*/}
                                {/*completed. Easy refunds on non-delivery.</p>*/}
                                {/*</div>*/}
                                {/*<div className="col-sm-4 work-box-padding">*/}
                                {/*<div className="work-icon-box">*/}
                                {/*<img src={HiredIcon} style={{width: '50px'}} alt="icon"/>*/}
                                {/*</div>*/}
                                {/*<h3 className="scope-sub-heading">Expert and Support Manager Assigned</h3>*/}
                                {/*<p className="xs-para">Enter your Workbeam Dashboard. Your selected Expert and*/}
                                {/*Workbeam Support Manager will be assigned to you. The expert will call you*/}
                                {/*max. by 16 hours to ask for documents.</p>*/}
                                {/*</div>*/}
                                {/*<div className="col-sm-4 work-box-padding">*/}
                                {/*<div className="work-icon-box">*/}
                                {/*<img src={ColoredBullseye} style={{width: '70px'}} alt="icon"/>*/}
                                {/*</div>*/}
                                {/*<h3 className="scope-sub-heading">Collaborate &amp; Get work done</h3>*/}
                                {/*<p className="xs-para">Talk to Expert, Share files, Track Milestones and get work*/}
                                {/*done easily.</p>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </section>
                    <p id='general_onBoarding_login_with_phone' data-toggle="modal"
                       data-target="#login_with_otp_pop_up_on_general_onboarding" />
                    <div className="modal center-modal" id="login_with_otp_pop_up_on_general_onboarding" role="dialog">
                        <LoginWithPhone generalOnboarding={'generalOnboarding'}
                                        fetchUserAndProData={this.fetchUserAndProData}
                                        emailButtonLoginName={price > 0 ? `Login And Pay $${price > first_installment ? first_installment : price}` : `Login And Book`}
                                        otpLoginBtnName={'Get OTP'}
                        />
                    </div>
                    <p id="update_profile_msg" data-toggle="modal" data-target="#consultation_fee" />
                    {isUpdateAddressEnabled &&
                    <UserUpdateAddress
                        geoCity={geoCity}
                        phoneNo={phone}
                        cityId={countryCallingCode === indiaCallingCode ? cityId : 'dummy'}
                        updateProfileAndPostJob={this.updateProfileAndPostJob}/>
                    }
                    <FaqForAdd/>
                </Fragment>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {city: state.state.city}
};

const mapDispatchToProps = dispatch => {
    return {
        onSignUp: (auth_token, userType, firstName, lastName) => {
            dispatch(registration({
                auth_token: auth_token,
                user_type: userType,
                first_name: firstName,
                last_name: lastName
            }))
        },
        onLogin: (authToken) => {
            dispatch(sessionLogin({
                auth_token: authToken,
            }))
        },
        onFetchDetails: (action) => {
            dispatch(action);
        },
        onGetCity: (action) => {
            dispatch(action)
        },
        ...(errorActionDispatchers(dispatch))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GeneralOnboarding))