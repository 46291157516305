import axios from 'axios';
import localStorage from '../utils/localStorage';
import { clearAndShowError } from '../actions/errors';
import { apiBaseUrl, mailerApiBaseUrl } from "../constants/common";

export const App_env = {
    development: 'development',
    staging: 'staging',
    production: 'production',
    local_host: 'local'
};
console.log('React app env', process.env);

// siteBaseUrl = 'https://web-dev.wizcounsel.com/';
// siteBaseUrl = 'https://wizcounsel.com/'

// apiBaseUrl = 'https://api-dev.wizcounsel.com/'
//                      const apiBaseUrl = 'http://20.231.48.250:8000/'

// Code by Abdhesh on 13/07/2020 for country on every request

class V1 {
    constructor() {
        this.actualUrl = apiBaseUrl;
        this.baseURL = `${this.actualUrl}`;
        this.authToken = localStorage.authToken || null;
        this._noAuth = null;
        this._auth = null;
        this.country = localStorage.country || null;
    }

    get url() {
        return this.actualUrl;
    }

    setUthTokenToNull(value) {
        this._auth = null
    }

    authHeaders() {
        this.authToken = localStorage.authToken;
        this.country = localStorage.country;
        return {
            'Authorization': this.authToken,
            'x-wiz-device-type': 'WEB',
            'COUNTRY': this.country
        };
    }

    noAuthHeaders() {
        this.country = localStorage.country;
        return {
            'x-wiz-device-type': 'WEB',
            'COUNTRY': this.country
        };
    }

    get noAuth() {
        // this.country = localStorage.country;
        // if (!this._noAuth) {
        //     this._noAuth = axios.create({
        //         baseURL: this.baseURL,
        //         headers: this.noAuthHeaders(),
        //     });
        // }
        this._noAuth = axios.create({
            baseURL: this.baseURL,
            headers: this.noAuthHeaders(),
        });

        return this._noAuth;
    }

    get auth() {
        this._auth = axios.create({
            baseURL: this.baseURL,
            headers: this.authHeaders(),
        });
        // console.log('existing auth',this._auth )
        return this._auth;
    }

    parse(axiosResponse) {
        try {
            const { data, status, statusText } = axiosResponse;
            switch (status) {
                case 201:
                case 200:
                case 204:
                    return {
                        data: data,
                        status: status,
                        error: null
                    };
                case 401:
                case 400:
                    return {
                        data: null,
                        status: status,
                        error: data
                    };
                case 404:
                    return {
                        data: null,
                        status: status,
                        error: statusText
                    };
                default:
                    return {
                        data: null,
                        status: status,
                        error: statusText
                    };
            }
        } catch (e) {
            return {
                data: null,
                status: null,
                error: 'Oops, something went wrong, please try again'
            };
        }
    }

    parseAndAutoHandleIssues(axiosResponse, doDispatch) {
        let {
            data, error, status
        } = this.parse(axiosResponse);
        if (status === 401 || status === 400) {
            doDispatch(clearAndShowError('Oops, something went wrong'))
            return error
        }
        else if (status === 404 && doDispatch) {
            doDispatch(clearAndShowError(error))
            data = null
        }
        else if (error && doDispatch) {
            doDispatch(clearAndShowError(error));
            data = null
        }
        return data;
    }

    handleErrors(axiosResponse, doDispatch) {
        console.log(axiosResponse)
        if (axiosResponse?.response?.data && axiosResponse?.response?.data?.message) {
            const err = axiosResponse?.response?.data?.message;
            if (doDispatch) {
                doDispatch(clearAndShowError(err))
            }
        } else if (axiosResponse.response !== undefined) {
            return this.parseAndAutoHandleIssues(axiosResponse.response, doDispatch)
        } else {
            const err = axiosResponse.message;
            if (doDispatch) {
                doDispatch(clearAndShowError(err))
            }
        }
    }

    showPopupError(err, doDispatch) {
        if (err) {
            doDispatch(clearAndShowError(err))
        }
    }
}

export const apiv1 = new V1();

class V2 {
    constructor(authToken) {
        this.actualUrl = apiBaseUrl;
        this.baseURL = `${this.actualUrl}`;
        this.authToken = authToken || localStorage.authToken;
        this._noAuth = null;
        this._auth = null;
        this.country = null;
    }

    get url() {
        return this.actualUrl;
    }

    setUthTokenToNull(value) {
        this._auth = null
    }
    setAuthToken(authToken) {
        this.authToken = authToken;
    }

    authHeaders() {
        this.country = localStorage.country
        return {
            'Authorization': this.authToken,
            'x-wiz-device-type': 'WEB',
            'COUNTRY': this.country
        };
    }

    noAuthHeaders() {
        this.country = localStorage.country
        return {
            'x-wiz-device-type': 'WEB',
            'COUNTRY': this.country
        };
    }

    get noAuth() {
        this.country = localStorage.country
        if (!this._noAuth) {
            this._noAuth = axios.create({
                baseURL: this.baseURL,
                headers: this.noAuthHeaders(),
            });
        }
        return this._noAuth;
    }

    get auth() {
        this._auth = axios.create({
            baseURL: this.baseURL,
            headers: this.authHeaders(),
        });
        // console.log('existing auth',this._auth )
        return this._auth;
    }

    parse(axiosResponse) {
        try {
            const { data, status, statusText } = axiosResponse;
            switch (status) {
                case 201:
                case 200:
                case 204:
                    return {
                        data: data,
                        status: status,
                        error: null
                    };
                case 401:
                case 400:
                    return {
                        data: null,
                        status: status,
                        error: data
                    };
                case 404:
                    return {
                        data: null,
                        status: status,
                        error: statusText
                    };
                default:
                    return {
                        data: null,
                        status: status,
                        error: statusText
                    };
            }
        } catch (e) {
            console.error(e);
            return {
                data: null,
                status: null,
                error: 'Oops, something went wrong, please try again'
            };
        }
    }

    parseAndAutoHandleIssues(axiosResponse, doDispatch) {
        let {
            data, error, status
        } = this.parse(axiosResponse);
        if (status === 401 || status === 400 || status === 404) {
            // doDispatch(clearAndShowError('Oops, something went wrong, please try again'))
            return error
        }
        else if (status === 404 && doDispatch) {
            doDispatch(clearAndShowError(error))
            data = null
        }
        if (error && doDispatch) {
            doDispatch(clearAndShowError(error));
            data = null;
        }
        return data;
    }

    handleErrors(axiosResponse, doDispatch) {
        if (axiosResponse.response !== undefined) {
            return this.parseAndAutoHandleIssues(axiosResponse.response, doDispatch)
        } else {
            const err = axiosResponse.message;
            if (doDispatch) {
                doDispatch(clearAndShowError(err))
            }
        }
        // }

    }
}
export const apiv2 = new V2();


class V3 {
    constructor() {
        this.actualUrl = mailerApiBaseUrl
        this.baseURL = `${this.actualUrl}`;
        this.authToken = null;
        this._noAuth = null;
        this._auth = null;
        this.country = null;
    }

    get url() {
        return this.actualUrl;
    }

    setUthTokenToNull(value) {
        this._auth = null
    }

    authHeaders() {
        this.authToken = localStorage.authToken;
        this.country = localStorage.country;
        return {
            'Authorization': this.authToken,
            'x-wiz-device-type': 'WEB',
            'COUNTRY': this.country
        };
    }

    noAuthHeaders() {
        this.country = localStorage.country;
        return {
            'x-wiz-device-type': 'WEB',
            'COUNTRY': this.country
        };
    }

    get noAuth() {
        if (!this._noAuth) {
            this._noAuth = axios.create({
                baseURL: this.baseURL,
                headers: this.noAuthHeaders(),
            });
        }
        return this._noAuth;
    }

    get auth() {
        this._auth = axios.create({
            baseURL: this.baseURL,
            headers: this.authHeaders(),
        });
        // console.log('existing auth',this._auth )
        return this._auth;
    }

    parse(axiosResponse) {
        try {
            const { data, status, statusText } = axiosResponse;
            switch (status) {
                case 201:
                case 200:
                case 204:
                    return {
                        data: data,
                        status: status,
                        error: null
                    };
                case 401:
                case 400:
                    return {
                        data: null,
                        status: status,
                        error: data
                    };
                case 404:
                    return {
                        data: null,
                        status: status,
                        error: statusText
                    };
                default:
                    return {
                        data: null,
                        status: status,
                        error: statusText
                    };
            }
        } catch (e) {
            return {
                data: null,
                status: null,
                error: 'Oops, something went wrong, please try again'
            };
        }
    }

    parseAndAutoHandleIssues(axiosResponse, doDispatch) {
        let {
            data, error, status
        } = this.parse(axiosResponse);
        if (status === 401 || status === 400) {
            return error
        }
        else if (status === 404 && doDispatch) {
            doDispatch(clearAndShowError(error))
            data = null
        }
        else if (error && doDispatch) {
            doDispatch(clearAndShowError(error));
            data = null
        }
        return data;
    }

    handleErrors(axiosResponse, doDispatch) {
        if (axiosResponse.response !== undefined) {
            return this.parseAndAutoHandleIssues(axiosResponse.response, doDispatch)
        } else {
            const err = axiosResponse.message;
            if (doDispatch) {
                doDispatch(clearAndShowError(err))
            }
        }
    }

    showPopupError(err, doDispatch) {
        if (err) {
            doDispatch(clearAndShowError(err))
        }
    }
}

export const apiv3 = new V3();
