import React, {Component, Fragment} from "react";
import {serviceFirstSectionAdd} from "./sectionEightCompany";
// import SiteHeader from "./../SiteHeader";
import {
    getFirstAndLastLetterOfName,
    getJobDone,
    // getReview,
    workExperience, workExperienceDesigner
} from "../common/userAndProfessionalProfileFunctions";
import * as path from "../../constants/path";
import {ChatResponceTime, MinimulBadgeUi, ratingUi, SamplesUi} from "../functional/beforeHire";
import $ from "jquery";
import {apiv1} from "../../api";
import localStorage, {KEY_REFERRAL_CODE} from '../../utils/localStorage'
import {
    productDetails,
    productPackages,
    productSlugMappedWithId,
    productIdMappedWithSlug
} from '../common/bannerTextForProducts'
import {allFaqText} from "../common/allServicesFaq";
import {showLegalAreaForProduct, consultationForProduct,} from "../user/ProductOnBoarding";
import {errorActionDispatchers} from "../../actions/errors";
import connect from "react-redux/es/connect/connect";
import PaginationWithArrow from "../common/PaginationWithArrow";
import LoadingComponent from "../../utils/LoadingComponent";
import Interweave from 'interweave'
import {getCity} from "../../actions/geo";
import {professionalStatus} from "../../constants/job";
import {GetFirstNameAndLastName, getProductIdWithSlug, ShortDescription} from "../../functions/common";
import {CreateProfessionalAccountPopUp} from "../user/GeneralOnboarding";
import queryString from "query-string";
// import {BrowseMore} from "../common/professionalByCities";
// import FooterLinkOfProduct from './../common/FooterLinkOfProduct'
// import {AccountingProduct} from './../common/professionalByCities'
import {ShareProfile} from "../professional/ProfessionalPublicProfile";
import {
    baseUrl,
    // couponCodeMsg,
    indiaCallingCode,
    // professionalBadge,
    // siteBaseUrl,
    // videoThumbnails
} from "../../constants/common";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import {
    professionalType as professional_type,
} from '../../constants/businessPractiseArea'
import {noOfSamples, priceType, base_multiplier} from '../professional/DesignerSingleProduct'
import * as apiUrl from "../../api/apiUrls";
import {USER} from "../../constants/auth";
import OnboardingSignup from "../user/OnboardingSignup";
import UserUpdateAddress from "../user/UserUpdateAddress";
import OwlCarousel from "react-owl-carousel";
import {Link} from "react-router-dom";
import {assignTaskToThisProfessional} from '../../constants/user'
// import {RewardSlide} from "./DeveloperProfessionalList";
import HiredIcon from "../../static/images/hired-icon.png";
import CreditCard from "../../static/images/credit-card.png";
import ColoredBullseye from "../../static/images/colored-bullseye.png";
import {freelancers, requestProposals, requestQuote} from "../../constants/keyWord";
import {InTheNews, OurBrand} from "../common/AllOwlCrousel";
import socket from "../common/Socket";
import popupMessages from '../../constants/popupMessages'
import {parsePhoneNumber} from "react-phone-number-input";
import ProductReviews from "../common/ProductReviews";
import {WizCommunity} from "./Covid19Help";
import Affiliate from '../common/Affiliate'
import {
    // _externalSiteLink,
    _bookingAmountSlab,
    dataWithCalculatedCommission,
    _parseToFloatCommission, _roundOfCommission, roundOffCommission, getGeoInfo,
    getDiscount, getFloatType,
} from "../../utils/clientSide";
// import {initFreshChat} from "../../functions/jqFuntions";
import {getProfile} from "../../reducers/auth";
import ReactPlayer from 'react-player/vimeo'
import {GeneralFaqForClient} from "../common/taxAddPopup";
import {validateCouponCode} from "../../api/commonApi";

// const imagesA = [
//     {
//         original: 'https://picsum.photos/id/1018/1000/600/',
//         thumbnail: 'https://picsum.photos/id/1018/250/150/',
//     },
//     {
//         original: 'https://picsum.photos/id/1015/1000/600/',
//         thumbnail: 'https://picsum.photos/id/1015/250/150/',
//     },
//     {
//         original: 'https://picsum.photos/id/1019/1000/600/',
//         thumbnail: 'https://picsum.photos/id/1019/250/150/',
//     },
// ];

export function ShareHowItWork() {
    return (
        <div className="panel panel-default">
            <div className="panel-body">
                <h2 className="heading-sm text-center">How It Works<span className="dot-pink">.</span></h2>
                <div className="row">
                    <div className="col-sm-4 s-how-it-work">
                        <div className="work-icon-box">
                            <img src={require('../../static/images/hired-icon.png').default} alt="Workbeam"/>
                        </div>
                        <h3 className="text-name">Verified and Interviewed Experts</h3>
                        <p className="text-para-reviews">Select & Hire Expert based on Price, Location and Profile</p>
                    </div>
                    <div className="col-sm-4 s-how-it-work">
                        <div className="work-icon-box">
                            <img src={require('../../static/images/credit-card.png').default} alt="Workbeam"/>
                        </div>
                        <h3 className="text-name">Make Escrow payment</h3>
                        <p className="text-para-reviews">Make Payment. Get Workbeam Payment Insurance. Easy refunds on
                            Non-Delivery.</p>
                    </div>
                    <div className="col-sm-4 s-how-it-work">
                        <div className="work-icon-box">
                            <img src={require('../../static/images/colored-bullseye.png').default} alt="Workbeam"/>
                        </div>
                        <h3 className="text-name">Collaborate & Get work done</h3>
                        <p className="text-para-reviews">Communicate, Share files, track milestones and get work
                            done.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function WhyBookOnGigzoe() {
    return (
        <div className="row">
            <div className="col-sm-12">
                <h3 className="d-designer-heading">Why book on Workbeam?</h3>
                <ul className="gig-assurance-ul">
                    <li>
                        <span>
                            <div className="service-grid-cards-icon">
                                <img
                                    src={require('../../static/images/credit-card-new.png').default}
                                    style={{maxWidth: '40px'}}
                                    alt="Workbeam Assurance"/>
                            </div>
                            <h4 className="service-grid-cards-heading">100% Refund Protection</h4>
                        </span>
                    </li>
                    <li>
                        <span>
                            <div className="service-grid-cards-icon">
                                <img src={require('../../static/images/checklist.png').default}
                                     alt="Workbeam Assurance"/>
                                </div>
                            <h4 className="service-grid-cards-heading">Pay in Milestones</h4>
                        </span>
                    </li>
                    <li>
                        <span>
                            <div className="service-grid-cards-icon">
                                <img src={require('../../static/images/fast-delivery.png').default}
                                     alt="Workbeam Assurance"/>
                            </div>
                            <h4 className="service-grid-cards-heading">Track Service Delivery</h4>
                        </span>
                    </li>
                    <li>
                        <span>
                            <div className="service-grid-cards-icon">
                                <img src={require('../../static/images/headphones.png').default}
                                     alt="Workbeam Assurance"/>
                            </div>
                            <h4 className="service-grid-cards-heading">Dedicated Support</h4>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export function ServiceHowItWork() {
    return (
        <div className="panel panel-default">
            <div className="panel-body">
                <h2 className="heading-sm">How It Works<span className="dot-pink">.</span></h2>

                <p className="text-name"><img src={require('../../static/images/hired-icon.png').default}
                                              alt="Workbeam"/><br/>Verified and Interviewed Experts</p>
                <p className="text-para-reviews">Select & Hire Expert based on Price, Location and Profile</p>
                <p className="text-name"><img src={require('../../static/images/credit-card.png').default}
                                              alt="Workbeam"/><br/>Make Escrow payment</p>
                <p className="text-para-reviews">Make Payment. Get Workbeam Payment Insurance. Easy refunds on
                    Non-Delivery.</p>
                <p className="text-name"><img src={require('../../static/images/colored-bullseye.png').default}
                                              alt="Workbeam"/><br/>Collaborate & Get work done</p>
                <p className="text-para-reviews">Communicate, Share files, track milestones and get work done.</p>
            </div>
        </div>
    )
}

export function WizAssurance() {
    return (
        <div className="assurance-card">
            <div className="text-center">
                <img src={require('../../static/images/wizassuerd.png').default}
                     style={{width: '150px', marginBottom: '20px'}}
                     alt="Workbeam Assurance"/>
            </div>
            {/*<p className="assurance-heading">Workbeam Assurance</p>*/}
            <ul className="list-unstyled assurance-ul">
                <li>
                    100% Payment Protection
                </li>
                <li>
                    100% Delivery Assurance
                </li>
                <li>
                    Pay in Instalments
                </li>
                <li>
                    Track work Delivery
                </li>
                <li>
                    Verified Subject Matter Experts
                </li>
                <li>
                    Dedicated Support Manager
                </li>
            </ul>
        </div>
    )
}

export function TimelineHowItWork() {
    return (
        <div className="qa-message-list how-it-work" id="wallmessages">
            <div className="message-item" id="m4">
                <div className="clearfix">
                    <div>
                        <p className="para" style={{
                            color: '#000',
                            marginTop: '5px'
                        }}>Select & Hire Expert based on Price, Location & Profile</p>
                    </div>
                </div>
            </div>
            <div className="message-item" id="m4">
                <div className="clearfix">
                    <div>
                        <p className="para" style={{
                            color: '#000',
                            marginTop: '5px'
                        }}>Make escrow payment and Book the Service. Easy refunds on Non-Delivery.</p>
                    </div>
                </div>
            </div>
            <div className="message-item" id="m4">
                <div className="clearfix">
                    <div>
                        <p className="para" style={{
                            color: '#000',
                            marginTop: '5px'
                        }}>Expert Messages you back within a few hours.</p>
                    </div>
                </div>
            </div>
            <div className="message-item" id="m4">
                <div className="clearfix">
                    <div>
                        <p className="para" style={{
                            color: '#000',
                            marginTop: '5px'
                        }}>Chat, Audio Call, Video Call & Share files</p>
                    </div>
                </div>
            </div>
            <div className="message-item" id="m4">
                <div className="clearfix">
                    <div>
                        <p className="para" style={{
                            color: '#000',
                            marginTop: '5px'
                        }}>Track Work Delivery with Milestones</p>
                    </div>
                </div>
            </div>
            <div className="message-item" id="m4">
                <div className="clearfix">
                    <div>
                        <p className="para" style={{
                            color: '#000',
                            marginTop: '5px'
                        }}>Make Balance Payments as per Milestones</p>
                    </div>
                </div>
            </div>
            <div className="message-item" id="m4">
                <div className="clearfix">
                    <div>
                        <p className="para" style={{
                            color: '#000',
                            marginTop: '5px'
                        }}>Get work Completed as per delivery time.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function GigTimelineHowItWork() {
    return (
        <div className="qa-message-list how-it-work" id="wallmessages">
            <div className="message-item" id="m4">
                <div className="clearfix">
                    <div>
                        <p className="para" style={{
                            color: '#000',
                            marginTop: '5px'
                        }}>Pay Booking Amount & Book Service</p>
                    </div>
                </div>
            </div>
            <div className="message-item" id="m4">
                <div className="clearfix">
                    <div>
                        <p className="para" style={{
                            color: '#000',
                            marginTop: '5px'
                        }}>Communicate with Expert - Both Chat, Audio & Video Calling</p>
                    </div>
                </div>
            </div>
            <div className="message-item" id="m4">
                <div className="clearfix">
                    <div>
                        <p className="para" style={{
                            color: '#000',
                            marginTop: '5px'
                        }}>Track Work Delivery with Milestones</p>
                    </div>
                </div>
            </div>
            <div className="message-item" id="m4">
                <div className="clearfix">
                    <div>
                        <p className="para" style={{
                            color: '#000',
                            marginTop: '5px'
                        }}>Pay Balance Payments as per Milestones</p>
                    </div>
                </div>
            </div>
            <div className="message-item" id="m4">
                <div className="clearfix">
                    <div>
                        <p className="para" style={{
                            color: '#000',
                            marginTop: '5px'
                        }}>Get work Done!</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function ShareAssurance() {
    return (
        <div className="panel-body">
            <h2 className="heading-sm">Workbeam Assurance<span className="dot-pink">.</span></h2>
            <p className="text-name"><img src={require('../../static/images/flat-color-icon-3.png').default}
                                          alt="Workbeam"/><br/>Verified and Interviewed Experts</p>
            <p className="text-para-reviews">Workbeam approves all its experts after KYC verification, vetting and
                interviews.</p>
            <p className="text-name"><img src={require('../../static/images/flat-color-icon-4.png').default}
                                          alt="Workbeam"/><br/>Save 60% cost</p>
            <p className="text-para-reviews">Workbeam helps save up to 60% on Market Price</p>
            <p className="text-name"><img src={require('../../static/images/flat-color-icon-2.png').default}
                                          alt="Workbeam"/><br/>Track work delivery and Milestones</p>
            <p className="text-para-reviews">You are able to keep track of your work. Experts create milestones and
                track your work to keep you fully updated.</p>
            <p className="text-name"><img src={require('../../static/images/flat-color-icon-5.png').default}
                                          alt="Workbeam"/><br/>Escrow payments & 100% Money Back Guarantee</p>
            <p className="text-para-reviews">Your payments are secured in an escrow account and given to the expert once
                your work is completed. You also get a 100% Money Back Guarantee.</p>
            <p className="text-name"><img src={require('../../static/images/flat-color-icon-1.png').default}
                                          alt="Workbeam"/><br/>Dedicated Support</p>
            <p className="text-para-reviews">We assign you a dedicated support manager to complete your work
                smoothly.</p>
        </div>
    )
}

export function ServiceAssurance() {
    return (
        <div className="panel-body">
            <h2 className="heading-sm">Workbeam Assurance<span className="dot-pink">.</span></h2>
            <p className="text-name"><img src={require('../../static/images/flat-icon-03.png').default} alt="Workbeam"/><br/>Verified
                and Interviewed Experts</p>
            <p className="text-para-reviews">Workbeam approves all its experts after KYC verification, vetting and
                interviews.</p>
            <p className="text-name"><img src={require('../../static/images/flat-icon-04.png').default} alt="Workbeam"/><br/>Save
                60% cost</p>
            <p className="text-para-reviews">Workbeam helps save up to 60% on Market Price</p>
            <p className="text-name"><img src={require('../../static/images/flat-icon-05.png').default} alt="Workbeam"/><br/>Track
                work delivery and Milestones</p>
            <p className="text-para-reviews">You are able to keep track of your work. Experts create milestones and
                track your work to keep you fully updated.</p>
            <p className="text-name"><img src={require('../../static/images/flat-icon-01.png').default} alt="Workbeam"/><br/>Escrow
                payments & 100% Money Back Guarantee</p>
            <p className="text-para-reviews">Your payments are secured in an escrow account and given to the expert once
                your work is completed. You also get a 100% Money Back Guarantee.</p>
            <p className="text-name"><img src={require('../../static/images/flat-icon-02.png').default} alt="Workbeam"/><br/>Dedicated
                Support</p>
            <p className="text-para-reviews">We assign you a dedicated support manager to complete your work
                smoothly.</p>
        </div>
    )
}

export function readMoreProposal(isFromChat) {
    $(".read-now").each(function (i) {
        var len = $(this).text().length;
        if (isFromChat) {
            if (len > 70) {
                $(this).text($(this).text().substr(0, 70) + '...');
            }
        } else {
            if (len > 180) {
                $(this).text($(this).text().substr(0, 180) + '...');
            }
        }
    });

};

export function ProductIncrease({changeProductCountCallBack, quantity_count, productId, serviceType, productDetails}) {
    return (
        <div className="product-increase">
            <div>
                <span className="q-increase">Increase Qty:-</span>
            </div>
            <div className="input-group">
                <span className="input-group-btn">
                    <button type="button"
                            className="btn quantity-left-minus"
                            disabled={quantity_count <= 1}
                            onClick={(e) => {
                                changeProductCountCallBack({isDecreasing: true, productId, serviceType, productDetails})
                            }}
                    >
                        <span className="fa fa-minus">
                        </span>
                    </button>
                </span>
                <div className="input-number">
                    {quantity_count}
                </div>
                <span className="input-group-btn">
                    <button type="button" className="btn quantity-right-plus"
                            onClick={(e) => {
                                changeProductCountCallBack({isIncreasing: true, productId, serviceType, productDetails})
                            }}
                    >
                        <span className="fa fa-plus">
                        </span>
                    </button>
                </span>
            </div>
        </div>
    )
}

export function hideSiteHeader() {
    if ($(document).scrollTop() > 300) {
        $('.main-header').fadeOut('slow');
    } else {
        $('.main-header').fadeIn('slow');
    }
};

export function viewFullScreen() {
    $('.image-gallery-image img').click(function () {
        alert("The paragraph was clicked.");
    })
}

export function HelpMeInHiring({isConsultation, url}) {
    if (url) {
        return (
            <a className="btn side-card-btn"
               href={url}
            >{requestProposals}</a>
        )
    }
    return (
        <a className="btn side-card-btn"
           href={isConsultation ? `${path.userRegistration}/${path.forConsultation}` : `${path.covid19FreeSignup}`}
        >{requestProposals}</a>
    )
}

const tabs = [
    {
        tabName: 'Packages',
        id: 'package_tab',
    },
    {
        tabName: 'Experts',
        id: 'Expert_tab',
    },
    // {
    //     tabName: 'Info Guide',
    //     id: 'Info_guid',
    // },
    // {
    //     tabName: 'Reviews',
    //     id: 'public_reviews',
    // },
    // {
    //     tabName: 'WC Assurance',
    //     id: 'wc_assurance',
    // }
]


class DefaultGigDetails extends Component {
    constructor(props) {
        super(props)
        let {city, history, affiliateMarketingDetails, match} = props
        const {params} = match
        let {
            productSlug,
            parentPracticeAreaSlug,
            childPracticeAreaSlug,
            citySlug,
            professionalProductId,
            professionalSlug
        } = params
        const {location} = history
        const {search} = location
        let {page, fee, isFromDashboard, isOneToOneChat, coupon_code} = queryString.parse(search)
        city = city || []
        professionalSlug = professionalSlug || ''
        professionalProductId = professionalProductId || ''
        isOneToOneChat = isOneToOneChat || ''
        page = page ? Number(page) : 1
        let professionalName = ''
        fee = fee || ''
        parentPracticeAreaSlug = parentPracticeAreaSlug || ''
        let productId = getProductIdWithSlug(productSlug)
        let product_type = ''
        let isConsultation = false
        let parentPracticeAreaId = ''
        let packageIds = []
        let discountCouponCode = affiliateMarketingDetails.coupon_code || ''
        if (!discountCouponCode){
            discountCouponCode = coupon_code
        }
        // discountCouponCode = discountCouponCode && discountCouponCode.coupon_code
        let discountDetails = {coupon_code: discountCouponCode}
        productId = productId ? Number(productId) : ''
        if (productId && !professionalSlug) {
            product_type = productIdMappedWithSlug[productId] ? productIdMappedWithSlug[productId].product_type : ''
            isConsultation = consultationForProduct.indexOf(productId) > -1
            let productDetailsLocally = productDetails({productId})
            const {parent_practice_area_id} = productDetailsLocally || {}
            parentPracticeAreaId = productDetailsLocally ? parent_practice_area_id : ''
            if (product_type !== professional_type.designer && !productSlug) {
                packageIds = productPackages({productId}).packageIds
                packageIds = packageIds ? packageIds.map(packageDetails => packageDetails.id) : []
            }
        }
        this.state = {
            city,
            legalArea: [],
            professionalList: [],
            citySlug,
            childPracticeAreaSlug,
            productId,
            productSlug,
            productDisplayName: '',
            parentPracticeAreaId,
            parentPracticeAreaSlug,
            product_details: {},
            limit: 10,
            linkLimit: 10,
            totalRecord: 0,
            page,
            professionalAvatar: '',
            professionalName,
            fee,
            footerPage: 1,
            loading: false,
            isConsultation,
            isProfessionalApiHit: false,
            isPackageApiHit: false,
            productLink: '',
            product_type,
            userAndProData: {},
            professionalDetails: {},
            phone: '',
            cityId: '',
            showVideo: {},
            otherServices: [],
            packageList: [],
            packageIds,
            totalPackage: 0,
            packagePageNo: 1,
            isSignUpOnPackage: false,
            isSignUpOnHire: false,
            isSignUpOnChat: false,
            isFromDashboard,
            isOneToOneChat,
            isSignUpEnabled: false,
            discountDetails,
            // isValidCouponCode: false,
            professionalProductId,
            professionalSlug,
            professionalCity: ''
        }
    }

    componentDidMount() {
        const {parentPracticeAreaId, city, productId, page, professionalSlug, product_type} = this.state
        if (product_type !== professional_type.designer) {
            $(document).on('scroll', hideSiteHeader)
        }
        if (!professionalSlug && city.length === 0) {
            this.getCity()
        }
        // if (productId && !professionalSlug) {
        //     if (showLegalAreaForProduct.indexOf(productId) > -1 && !professionalSlug) {
        //         this.getPracticeAreaById(parentPracticeAreaId)
        //     }
        //     this.checkDiscountCoupon()
        // }
        if (professionalSlug) {
            if (!localStorage.country) {
                getGeoInfo({cb: () => this.checkDiscountCoupon({cb: this.getProfessionals})})
                getGeoInfo({cb: () => this.checkDiscountCoupon({cb: this.getOtherServicesOfProfessional})})
                // getGeoInfo({cb: () => this.getOtherServicesOfProfessional({page: 1})})
            } else {
                this.checkDiscountCoupon({cb: this.getProfessionals})
                this.checkDiscountCoupon({cb: this.getOtherServicesOfProfessional})
                // this.getProfessionals(page)
                // this.getOtherServicesOfProfessional({page: 1})
            }
        }
    }

    componentWillUnmount() {
        $(document).off('scroll', hideSiteHeader);
        $('.main-header').css('display', 'block')
        if (this.socket) {
            this.socket.close()
        }
        // initFreshChat({ isHideChat: false })
    }

    checkDiscountCoupon = ({cb}) => {
        const {productId, packageIds, discountDetails, professionalDetails} = this.state
        const {coupon_code} = discountDetails
        this.setState({loading: true})
        if (productId) {
                let promise = new Promise((resolve, reject) => {
                    if (coupon_code) {
                        validateCouponCode({isLoggedIn: false, productId, discountDetails, professionalDetails})
                            .then((props) => {
                                const {newStateObj} = props
                                this.setState(newStateObj, () => {
                                    resolve()
                                })
                            })
                    } else {
                        resolve()
                    }
                })
                promise.then(() => {
                    if (!localStorage.country) {
                        getGeoInfo({cb: () => cb(1)})
                    } else {
                        cb(1)
                    }
                })
                    .catch((props) => {
                        this.setState({loading: false})
                    })
        }
    }

    closeLoginPopUp = ({newStateObj}) => {
        $('#login_with_otp_pop_up_on_general_onboarding .close').click()
        return Promise.resolve({newStateObj, isProceedToPayment: false})
    }

    // validateCouponCode = ({isLoggedIn}) => {
    //     const {productId, discountDetails} = this.state
    //     const {discountCouponCode} = discountDetails
    //     const {doDispatch} = this.props
    //     this.setState({loading: true})
    //     let url = `${apiUrl.discountCoupon}?coupon_code=${discountCouponCode}`
    //     let couponCodeApi = isLoggedIn ? apiv1.auth.get(url) : apiv1.noAuth.get(url)
    //     let errors = {...this.state.errors}
    //     let newStateObj = {
    //         discountDetails: {},
    //         isValidCouponCode: false,
    //         errors,
    //     }
    //     return couponCodeApi
    //         .then(resp => {
    //             const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
    //             if (data) {
    //                 const {id, has_user_redeemed, product, is_expired} = data[0] ? data[0] : {}
    //                 errors.coupon_code = couponCodeMsg.invalid
    //                 newStateObj = {
    //                     ...newStateObj,
    //                     discountDetails: data[0] || {},
    //                     loading: false,
    //                     errors
    //                 }
    //                 if (id) {
    //                     if (!is_expired) {
    //                         if (!has_user_redeemed) {
    //                             errors.coupon_code = ''
    //                             newStateObj = {
    //                                 ...newStateObj,
    //                                 errors,
    //                             }
    //                             let professionalDetails = {
    //                                 ...this.state.professionalDetails
    //                             }
    //                             let {price, actual_amount_enter_by_professional} = professionalDetails
    //                             if (product) {
    //                                 if (product === productId) {
    //                                     let {fee_pro, fee_commission, fee_gst, fee_total} = data[0]
    //                                     fee_pro = parseFloat(fee_pro)
    //                                     fee_commission = parseFloat(fee_commission)
    //                                     fee_gst = parseFloat(fee_gst)
    //                                     fee_total = parseFloat(fee_total)
    //                                     newStateObj = {
    //                                         ...newStateObj,
    //                                         isValidCouponCode: true,
    //                                         professionalDetails: {
    //                                             ...professionalDetails,
    //                                             price: fee_total,
    //                                             wcFees: fee_commission,
    //                                             wcGst: fee_gst,
    //                                             actual_amount_enter_by_professional: fee_pro,
    //                                         }
    //                                     }
    //                                 } else {
    //                                     errors.coupon_code = couponCodeMsg.not_applicable
    //                                     newStateObj = {
    //                                         ...newStateObj,
    //                                         errors,
    //                                         isLoggedIn
    //                                     }
    //                                     return this.closeLoginPopUp({newStateObj})
    //                                 }
    //                             } else {
    //                                 let discountedAmount = getDiscount({discountSlab: data[0], professionalDetails})
    //                                 newStateObj = {
    //                                     ...newStateObj,
    //                                     isValidCouponCode: true,
    //                                     professionalDetails: {
    //                                         ...professionalDetails,
    //                                         price: price - discountedAmount,
    //                                         actual_amount_enter_by_professional: actual_amount_enter_by_professional - discountedAmount,
    //                                     },
    //                                 }
    //                             }
    //                         } else {
    //                             errors.coupon_code = couponCodeMsg.already_used
    //                             newStateObj = {
    //                                 ...newStateObj,
    //                                 errors,
    //                                 isLoggedIn
    //                             }
    //                         }
    //                     } else {
    //                         errors.coupon_code = couponCodeMsg.expired
    //                         newStateObj = {
    //                             ...newStateObj,
    //                             errors,
    //                             isLoggedIn
    //                         }
    //                     }
    //                 }
    //                 if ((is_expired || has_user_redeemed)) {
    //                     return this.closeLoginPopUp({newStateObj})
    //                 }
    //                 return Promise.resolve({newStateObj, isProceedToPayment: true})
    //             }
    //         })
    //         .catch(err => {
    //             apiv1.handleErrors(err, doDispatch)
    //             return this.closeLoginPopUp({newStateObj})
    //         })
    // }

    otherSamplesData = (professional, samples) => {
        noOfSamples.map(sampleNumber => {
            let sample = professional[`sample${sampleNumber}`]
            let isPhoto = professional[`sample${sampleNumber}_is_photo`]
            if (sample && sample !== 'True') {
                samples.push({sample, isPhoto})
            }
        })
    }

    setData = ({results, count, isSettingTitle, otherServices, isPublicProfileApiNeeded}) => {
        const {product_type, isConsultation, professionalId, discountDetails} = this.state
        const {is_valid_coupon_code} = discountDetails
        let newState = {
            loading: false,
            totalRecord: count,
            isProfessionalApiHit: true,
        }
        let {ListData} = dataWithCalculatedCommission({
            results,
            count,
            otherServices,
            isConsultation,
            product_type,
            professionalId,
            discountDetails,
            isValidCouponCode: is_valid_coupon_code,
            _renderVideo: this._renderVideo,
            parentComponent: this,
        })
        ListData = ListData || []
        let product_details = ListData.length > 0 ? ListData[0].product_details : {};
        if (isSettingTitle && professionalId && ListData.length > 0) {
            let {professional_details, product_details} = ListData[0] || {}
            professional_details = {
                ...professional_details,
                reviews: []
            }
            ListData[0] = {
                ...ListData[0],
                professional_details
            }
            const {avatar} = professional_details || {}
            const {display_name} = product_details || {}
            let productDisplayName = display_name
            const {product_type} = this.state
            let professionalAvatar = avatar
            if (product_type === professional_type.designer) {
                const {samples} = ListData[0]
                for (let i = 0; i < samples.length; i++) {
                    const {isPhoto, original} = samples[i]
                    if (isPhoto) {
                        professionalAvatar = original
                        break
                    }
                }
            }
            newState = {
                ...newState,
                productDisplayName,
                professionalAvatar
            }
        }
        let professionalList = ListData.filter(productDetails => productDetails.price)
        if (otherServices) {
            newState = {
                ...newState,
                [otherServices]: professionalList ? professionalList : []
            }
        } else {
            newState = {
                ...newState,
                professionalList,
            }
        }
        this.setState({
            ...newState,
            product_details,

        }, () => {
            if (isPublicProfileApiNeeded) {
                const {professionalList} = this.state
                const {professional_details} = professionalList[0] || {}
                const {slug_new} = professional_details || {}
                if (slug_new) {
                    this.getProfessionalPublicProfileData({professionalSlug: slug_new})
                }
            }
        })
    }
    setLoading = () => {
        this.setState(preState => ({loading: !preState.loading}))
    }

    getPackages = (page) => {
        let url = apiUrl.userPackage
        url += `?limit=${1000}&page=${page}`
        this.setState({loading: true})
        apiv1.noAuth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp);
                if (data) {
                    if (page !== 1) {
                        window.scrollTo(350, 350);
                    }
                    const {packageIds, discountDetails} = this.state
                    const {is_valid_coupon_code} = discountDetails
                    let {results} = data
                    results = results.filter(productDetails => (packageIds.indexOf(productDetails.id) > -1 && Number(productDetails.avg_price) > 0))
                    results = results.map(productDetails => {
                        let {
                            avg_price,
                            avg_price_commission,
                            avg_price_commission_tax,
                            description,
                            commission,
                            commission_tax
                        } = productDetails
                        commission = commission || 0
                        commission_tax = commission_tax || 0
                        commission = parseFloat(commission)
                        commission_tax = parseFloat(commission_tax)
                        avg_price = parseFloat(avg_price)
                        let isMoreInfo = description && description.split('<li>').length > 4
                        return {
                            ...productDetails,
                            price: avg_price,
                            commission: commission ? commission : avg_price_commission,
                            commission_tax: commission_tax ? commission_tax : avg_price_commission_tax,
                            professional_amount_tax: 0,
                            isMoreInfo
                        }
                    })
                    let packageListWithOrder = []
                    packageIds.map(id => {
                        let currentPackage = results.find(productDetails => id === productDetails.id)
                        if (currentPackage) {
                            packageListWithOrder.push({...currentPackage})
                        }

                    })
                    let {ListData} = dataWithCalculatedCommission({
                        results: packageListWithOrder,
                        isStandardPackage: true,
                        discountDetails,
                        isValidCouponCode: is_valid_coupon_code
                    })
                    this.setState({
                        packageList: ListData,
                        packagePageNo: page,
                        totalPackage: ListData.length,
                        loading: false,
                        isPackageApiHit: true
                    })
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    getOtherServicesOfProfessional = (page) => {
        const {limit, isFromDashboard, professionalSlug} = this.state
        let url = apiUrl.getDefaultGig
        url += `?limit=${limit}&page=${page}`
        if (professionalSlug) {
            url += `&professional__slug_new=${professionalSlug}`
        }
        url += `&verified=${!isFromDashboard}`
        // if (isFromDashboard) {
        //     // url = `${apiUrl.professionalProduct}`
        //     url += `verified=${!isFromDashboard}`
        // }
        // if (professionalId) {
        //     url += `&professional=${professionalId}`
        // }
        this.setState({page: page})
        let apiReq = ''
        if (isFromDashboard) {
            apiReq = apiv1.auth.get(url)
        } else {
            apiReq = apiv1.noAuth.get(url)
        }
        apiReq.then(resp => {
            const data = apiv1.parseAndAutoHandleIssues(resp);
            if (data) {
                if (page !== 1) {
                    window.scrollTo(350, 350);
                }
                const {results, count} = data
                this.setData({results, count, otherServices: 'otherServices'})
            } else {
                this.setState({loading: false})
            }
        })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    recordDesignerProductClick = ({professionalProductId}) => {
        apiv1.auth.get(`${apiUrl.recordProductClickedCount}/${professionalProductId}`)
            .then(resp => {
            })
            .catch(err => {
            })
    }

    getProfessionalPublicProfileData = ({professionalSlug}) => {
        const {doDispatch} = this.props
        apiv1.noAuth.get(`${apiUrl.professionalPublicProfileBySlug}/${professionalSlug}`)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch)
                if (data) {
                    let {reviews} = data
                    let {professionalList} = this.state
                    let {professional_details} = professionalList[0] || {}
                    professional_details = {
                        ...professional_details,
                        reviews
                    }
                    professionalList[0] = {
                        ...professionalList[0],
                        professional_details
                    }
                    this.setState({professionalList}, () => {
                        // setTimeout(() => {
                        //     initFreshChat({ isHideChat: true })
                        // }, 5000)
                    })
                }
            })
            .catch(err => {
                this.setState({loading: false})
            })
    };

    getProfessionals = (page) => {
        const {
            citySlug,
            limit,
            professionalId,
            isFromDashboard,
            productId,
            professionalProductId,
            professionalSlug,
            productSlug
        } = this.state;
        let url = professionalSlug ? apiUrl.getDefaultGig : apiUrl.getLegalAndAccountingProfessionals
        url += `?limit=${limit}&page=${page}`
        if (professionalSlug) {
            url += `&professional__slug_new=${professionalSlug}`
        }
        if (productId) {
            url += `&product=${productId}`
        }
        // if (isFromDashboard) {
        //     url = `${apiUrl.professionalProduct}`
        //     url += `?limit=${limit}&page=${page}`
        //     url += `&product=${productId}`
        // }
        // else {
        //     if (citySlug) {
        //         url += `&city=${citySlug}`
        //     }
        // }
        let apiReq = ''
        if (isFromDashboard) {
            apiReq = apiv1.auth.get(url)
        } else {
            apiReq = apiv1.noAuth.get(url)
        }
        const {doDispatch} = this.props
        this.setState({loading: true, page: page})
        apiReq.then(resp => {
            const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch)
            if (data) {
                if (page !== 1) {
                    window.scrollTo(350, 350);
                }
                let {results} = data
                let professionalName = ''
                let productDisplayName = ''
                let professionalCity = ''
                results = results.splice(0, 1)
                results = results.map(professionalProductDetails => {
                    let {professional_details, product_details} = professionalProductDetails || {}
                    let {bio_detailed, name, city} = professional_details
                    const {display_name} = product_details
                    productDisplayName = display_name
                    bio_detailed = bio_detailed || ''
                    professionalName = name
                    professionalCity = city
                    // bio_detailed = htmlToString(bio_detailed)
                    return {
                        ...professionalProductDetails,
                        professional_details: {
                            ...professional_details,
                            bio_detailed
                        }
                    }
                })
                this.setState({professionalName, productDisplayName, professionalCity}, () => {
                    this.setData({
                        results,
                        count: 1,
                        isSettingTitle: true,
                        isPublicProfileApiNeeded: true,
                    })
                    if (professionalSlug) {
                        const {id} = results[0] || {}
                        if (id) {
                            this.recordDesignerProductClick({professionalProductId: id})
                        }
                    }
                })
            } else {
                this.setState({loading: false})
            }
        })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, doDispatch)
            })
    }

    getPracticeAreaById = (parentPracticeAreaId) => {
        apiv1.noAuth.get(`/users/practisearea/?parent=${parentPracticeAreaId}`)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp);
                if (data) {
                    this.setState({legalArea: data})
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    getCity = () => {
        apiv1.noAuth.get(`/geo/city-for-user/`)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp);
                if (data) {
                    const city = data.filter(cityData => cityData.slug)
                    this.setState({city}, () => {
                        this.props.onGetCity(getCity(city))
                    })
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    generatePaymentLink = ({request}) => {
        apiv1.auth.post(apiUrl.cashfreePayment, request)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    window.location.href = data.link
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })

    }

    paymentApi = (jobId) => {
        const {professionalDetails, userAndProData} = this.state
        let {first_name, email, client} = userAndProData || {}
        client = client || {}
        let clientPhoneObj = client.phone || {}
        const {phone} = clientPhoneObj || {}
        const {product_name, first_installment, professionalId} = professionalDetails || {}
        const request = {
            email,
            phone,
            firstname: first_name,
            productinfo: product_name,
            amount: first_installment,
            milestone: [],
            professional: professionalId,
        }
        if (professionalDetails.isConsultation) {
            request.consult = jobId

        } else {
            request.job = jobId
        }
        this.generatePaymentLink({request})
    };

    earnReferredMoney = (referral_code, jobId) => {
        const request = {
            referral_code
        }
        const {doDispatch} = this.props
        apiv1.auth.post(apiUrl.earnMoneyFromReferral, request)
            .then(res => {
                const data = apiv1.parseAndAutoHandleIssues(res, doDispatch);
                if (data) {
                    this.paymentApi(jobId)
                    localStorage.removeKey(KEY_REFERRAL_CODE)
                }
            })
            .catch(err => {
                const {response} = err
                const data = apiv1.parseAndAutoHandleIssues(response, doDispatch)
                if (data) {
                    const {Error} = data
                    if (Error) {
                        // this.showToast({message: Error})
                        this.paymentApi(jobId)
                        localStorage.removeKey(KEY_REFERRAL_CODE)
                    }
                }
                apiv1.handleErrors(err, doDispatch);
            })
    }

    acceptProposal = (jobId, proposalId) => {
        const {isOneToOneChat} = this.state
        let request = {
            job: jobId,
            proposal: proposalId
        }
        if (isOneToOneChat) {
            request.is_hire_from_1_to_1_chat = true
        }

        apiv1.auth.post(apiUrl.acceptProposal, request)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    if (localStorage.referralCode) {
                        this.earnReferredMoney(localStorage.referralCode, jobId)
                    } else {
                        this.paymentApi(jobId)
                    }
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    sendProposal = (professionalDetails, jobId) => {
        const {
            wcFees,
            wcGst,
            gstAmount,
            price,
            actual_amount_enter_by_professional,
            professionalId,
            delivery_time
        } = professionalDetails
        const request = {
            message: 'Hello',
            fixed_amount: price,
            commission: wcFees,
            commission_tax: wcGst,
            professional_amount_tax: gstAmount,
            actual_amount_enter_by_professional,
            schedule_unit_proposed: delivery_time || 15,
            sender: professionalId,
            job: jobId,
        }
        apiv1.auth.post(apiUrl.sendProposal, request)
            .then(res => {
                const data = apiv1.parseAndAutoHandleIssues(res, this.props.doDispatch);
                if (data) {
                    this.acceptProposal(jobId, data.id)
                }
            })
            .catch(err => {
                this.setState({loading: false})
                apiv1.handleErrors(err, this.props.doDispatch);
            })
    }

    createJob = ({cityId}) => {
        const {professionalDetails} = this.state;
        const {description, parentPracticeAreaId, productId, product_name, quantity_count} = professionalDetails || {}
        const request = {
            job_question: [],
            title: `${product_name} , Quantity : ${quantity_count || 1}`,
            description: `${description}`,
            practise_area: parentPracticeAreaId,
            city: cityId || '',
            send_notification_to_all: false,
            is_direct_hire: true,
            product: productId
        }
        apiv1.auth.post(apiUrl.createJob, request)
            .then(res => {
                const data = apiv1.parseAndAutoHandleIssues(res, this.props.doDispatch);
                if (data) {
                    this.sendProposal(professionalDetails, data.id)
                }
            })
            .catch(err => {
                this.setState({loading: false})
                apiv1.handleErrors(err, this.props.doDispatch);
            })
    }

    createChatRoom = () => {
        const {professionalDetails, userAndProData} = this.state
        const {client} = userAndProData
        const {id} = client
        let {professionalProductId, professionalId} = professionalDetails || {}
        const {doDispatch} = this.props
        let apiPath = `${apiUrl.createChatRoomWithProfessionalProduct}${id}/${professionalId}/${professionalProductId}`
        apiv1.auth.get(apiPath)
            .then(res => {
                const data = apiv1.parseAndAutoHandleIssues(res, doDispatch)
                if (data) {
                    this.navigateToChat()
                }
            })
            .catch(err => {
                this.setState({loading: false})
                apiv1.handleErrors(err, doDispatch);
            })
    }


    initializeSocket = () => {
        const {professionalDetails} = this.state
        let {professionalUserId, professionalProductId} = professionalDetails || {}
        this.socket = socket.startSocketConnection({
            onSocketOpen: this.onSocketConnect,
            onConnectionError: this.onSocketConnectionError,
            // onConnectionLost: this.onSocketConnectionLost,
            counterPartyUserId: professionalUserId,
            professionalProductId,
            is_one_to_one: true
        })
    };

    onSocketConnectionError = () => {
        const {doDispatch} = this.props
        apiv1.showPopupError(popupMessages.socketConnectionError, doDispatch)
        this.setState({loading: false})
    }

    onSocketConnectionLost = () => {
        this.setState({loading: false})
    }

    navigateToChat = () => {
        const {history} = this.props
        const {professionalDetails} = this.state
        let {professionalUserId} = professionalDetails || {}
        history.push(`${path.myChatHistory}?isLastModifiedChatOpen=${true}&receiverUserId=${professionalUserId}`)
    }

    onSocketConnect = () => {
        this.navigateToChat()
    }


    updateProfileAndPostJob = ({data}) => {
        let {isSignUpOnChat} = this.state
        let userAndProData = {...this.state.userAndProData}
        localStorage.userType = USER
        let phone = ''
        let cityId = ''
        userAndProData = {
            ...userAndProData,
            ...data,
            client: {
                ...userAndProData.client,
                ...data.client,
            }
        }
        const {client} = userAndProData
        let {address} = client
        let country_calling_code = indiaCallingCode
        address = address || {}
        if (client.phone) {
            let {nationalNumber, countryCallingCode} = parsePhoneNumber(client.phone.phone || '') || {}
            nationalNumber = nationalNumber || ''
            country_calling_code = countryCallingCode
            phone = nationalNumber
        }
        if (address && address.city) {
            cityId = address.city
        }
        if (!isSignUpOnChat && ((country_calling_code === indiaCallingCode && !address.city) || !phone)) {
            this.setState({loading: false, cityId, phone, userAndProData}, () => {
                $('#update_profile_msg').click();
            })
        } else {
            this.setState({loading: true, userAndProData}, () => {
                localStorage.logedInUserDetails = JSON.stringify(getProfile(userAndProData))
                if (isSignUpOnChat) {
                    this.createChatRoom()
                    // this.initializeSocket()
                } else {
                    this.createJob({cityId})
                }
            })
        }
    }

    restAuthPatchForUserTypeSwitch = ({request}) => {
        const {doDispatch} = this.props
        apiv1.auth.patch(apiUrl.restAuth, request)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                if (data) {
                    this.updateProfileAndPostJob({data})
                }
            })
            .catch(err => {
                this.setState({loading: false})
                apiv1.handleErrors(err, doDispatch)
            })
    }

    switchAsClientProfile = ({}) => {
        const request = {
            client: {
                last_session_as_client: true
            }
        }
        this.restAuthPatchForUserTypeSwitch({request})
    }

    fetchUserAndProData = ({isSignUp}) => {
        this.setState({loading: true})
        apiv1.auth.get(apiUrl.restAuth)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    this.setState({userAndProData: data}, () => {
                        const {client} = data
                        const {is_default_professional} = client
                        if (is_default_professional) {
                            this.switchAsClientProfile({})
                        } else {
                            this.updateProfileAndPostJob({data})
                        }
                    })
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    };

    onProceedToPayment = ({onSignInWithEmail, signInWithPhoneNo, onSignUp}) => {
        const isFormValidated = true
        if (onSignInWithEmail) {
            onSignInWithEmail()
            return
        } else if (signInWithPhoneNo) {
            signInWithPhoneNo()
            return
        } else if (onSignUp) {
            onSignUp()
            return
        }
        if (isFormValidated) {
            if (localStorage.isSessionValid()) {
                this.fetchUserAndProData({isSignUp: false})
            } else {
                this.setState({isSignUpEnabled: true}, () => {
                    $('#direct_hire_signup').click()
                })
            }
        }
    }

    changeProductCount = ({isIncreasing, isDecreasing, productId, productDetails, serviceType}) => {

        let {multiplier, price_type} = serviceType || {}
        multiplier = multiplier || base_multiplier
        price_type = price_type || ''
        const {discountDetails} = this.state
        const {is_valid_coupon_code} = discountDetails
        let {calculated_commissions, quantity_count, original_price} = productDetails || {}
        let {
            commission,
            commission_tax,
            price,
            professional_amount_tax,
            first_installment,
            actual_amount_enter_by_professional,
            service_fee
        } = calculated_commissions || {}
        calculated_commissions = calculated_commissions || {}
        let newState = {}
        let parsedToFloat = _parseToFloatCommission({
            commission: productDetails.commission * productDetails[`${multiplier}`],
            commission_tax: productDetails.commission_tax * productDetails[`${multiplier}`],
            service_fee: productDetails.service_fee * productDetails[`${multiplier}`],
            actual_amount_enter_by_professional: productDetails[`price${price_type}`],
        })
        let actual_amount_enter_by_professional_of_single_prod = parsedToFloat.actual_amount_enter_by_professional
        let commission_tax_of_single_prod = parsedToFloat.commission_tax
        let professional_amount_tax_of_single_prod = parsedToFloat.professional_amount_tax
        let service_fee_of_single_prod = parsedToFloat.service_fee

        let discountedAmountsKeys = is_valid_coupon_code ? getDiscount({
            discountSlab: {...discountDetails},
            professionalDetails: {...productDetails, actual_amount_enter_by_professional: actual_amount_enter_by_professional_of_single_prod}
        }) : {}
        if (!discountedAmountsKeys.discount_amount_of_professional_fee) {
            discountedAmountsKeys.discount_amount_of_professional_fee = 0
        }
        if (!discountedAmountsKeys.discount_amount_of_commission_tax) {
            discountedAmountsKeys.discount_amount_of_commission_tax = 0
        }
        if (!discountedAmountsKeys.discount_amount_of_service_fee) {
            discountedAmountsKeys.discount_amount_of_service_fee = 0
        }
        if (!discountedAmountsKeys.total_discount) {
            discountedAmountsKeys.total_discount = 0
        }

        actual_amount_enter_by_professional -= discountedAmountsKeys.discount_amount_of_professional_fee
        commission_tax -= discountedAmountsKeys.discount_amount_of_commission_tax
        service_fee -= discountedAmountsKeys.discount_amount_of_service_fee

        let rounded_of_values = roundOffCommission({
            commission_tax: commission_tax_of_single_prod,
            actual_amount_enter_by_professional: actual_amount_enter_by_professional_of_single_prod,
            service_fee: service_fee_of_single_prod
        })

        actual_amount_enter_by_professional_of_single_prod = rounded_of_values.actual_amount_enter_by_professional
        commission_tax_of_single_prod = rounded_of_values.commission_tax
        service_fee_of_single_prod =  rounded_of_values.service_fee
        // service_fee = rounded_of_values.service_fee
        if (isIncreasing) {
            quantity_count += 1
            actual_amount_enter_by_professional += actual_amount_enter_by_professional_of_single_prod
            commission_tax += commission_tax_of_single_prod
            professional_amount_tax += professional_amount_tax_of_single_prod
            service_fee += service_fee_of_single_prod

            original_price.actual_amount_enter_by_professional += actual_amount_enter_by_professional_of_single_prod
            original_price.commission_tax += commission_tax_of_single_prod
            original_price.professional_amount_tax += professional_amount_tax_of_single_prod
            original_price.service_fee += service_fee_of_single_prod
            original_price.price += (actual_amount_enter_by_professional_of_single_prod + commission_tax_of_single_prod + professional_amount_tax_of_single_prod + service_fee_of_single_prod)
        }
        if (isDecreasing) {
            quantity_count -= 1
            actual_amount_enter_by_professional -= actual_amount_enter_by_professional_of_single_prod
            commission_tax -= commission_tax_of_single_prod
            professional_amount_tax -= professional_amount_tax_of_single_prod
            service_fee -= service_fee_of_single_prod

            original_price.actual_amount_enter_by_professional -= actual_amount_enter_by_professional
            original_price.commission_tax -= commission_tax_of_single_prod
            original_price.professional_amount_tax -= professional_amount_tax_of_single_prod
            original_price.service_fee -= service_fee_of_single_prod
            original_price.price -= (actual_amount_enter_by_professional_of_single_prod + commission_tax_of_single_prod + professional_amount_tax_of_single_prod + service_fee_of_single_prod)
        }
        let roundedOffOriginalPrice = roundOffCommission({
           ...original_price
        })
        original_price = {
            ...original_price,
            ...roundedOffOriginalPrice
        }
        parsedToFloat = _parseToFloatCommission({
            commission,
            commission_tax,
            actual_amount_enter_by_professional,
            service_fee
        })

        commission = parsedToFloat.commission
        commission_tax = parsedToFloat.commission_tax
        actual_amount_enter_by_professional = parsedToFloat.actual_amount_enter_by_professional
        service_fee = parsedToFloat.service_fee
        price = parseInt(commission + commission_tax + actual_amount_enter_by_professional + professional_amount_tax + service_fee)
        first_installment = _bookingAmountSlab({amount: price})
        productDetails = {
            ...productDetails,
            original_price,
            calculated_commissions: {
                ...calculated_commissions,
                actual_amount_enter_by_professional,
                commission,
                commission_tax,
                professional_amount_tax,
                price,
                service_fee,
            },
            quantity_count,
            first_installment
        }
        if (serviceType) {
            let {professionalList} = this.state
            let productIndex = professionalList.findIndex(details => details.product_details.id === productId)
            professionalList[productIndex] = {
                ...professionalList[productIndex],
                ...productDetails,

            }
            newState = {
                ...newState
            }
        } else {
            let {packageList} = this.state
            let productIndex = packageList.findIndex(details => details.id === productId)
            packageList[productIndex] = {...productDetails}
            newState = {
                ...newState,
                packageList,
            }
        }

        this.setState(newState)
    }

    requestCallBack = () => {
        return (
            <div className="col-md-3 ui-sticky-sidebar-block">
                <div className="service-card visible-xs">
                    <h4 className="side-card-heading">Request Free quotes from Experts. Hire
                        in your budget</h4>
                    <HelpMeInHiring/>
                </div>
                <p className="visible-xs">&#x00A0;</p>
                <WizAssurance/>
                <div className="sidebar-card">
                    <h4 className="side-card-heading">How it works</h4>
                    <div className="text-center on-card-it-word">
                        <img src={CreditCard} style={{width: '35px'}} alt="icon"/>
                        <p>Make escrow payment and book the Service</p>
                        <img src={HiredIcon} style={{width: '35px'}} alt="icon"/>
                        <p>Expert Assigned</p>
                        <img src={require('../../static/images/flat-color-icon-1.png').default} style={{width: '35px'}}
                             alt="icon"/>
                        <p>Communicate and share files</p>
                        <img src={require('../../static/images/flat-color-icon-2.png').default} style={{width: '35px'}}
                             alt="icon"/>
                        <p>Track Milestones and Pay balance Instalments</p>
                        <img src={ColoredBullseye} style={{width: '35px'}} alt="icon"/>
                        <p>Get work done happily</p>
                    </div>
                </div>
            </div>
        )
    }

    loadingUi = () => {
        return (
            <div className='lazy-loader' style={{position: 'fixed', zIndex: '100'}}>
                <LoadingComponent pastDelay/>
            </div>
        )
    }

    professionals = () => {
        const {
            professionalList,
            product_details,
            productId,
            parentPracticeAreaId,
            loading,
            isConsultation,
            totalRecord,
            isProfessionalApiHit,
            professionalId,
            product_type,
            professionalProductId,
            discountDetails
        } = this.state
        const {coupon_code} = discountDetails
        if (loading && !isProfessionalApiHit) {
            return this.loadingUi()

        }
        return (
            totalRecord === 0 ?
                (<Fragment>
                        <div className="panel panel-default">
                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-sm-12 text-center">
                                        <h4 className="text-empty-space">Sorry. There are no professionals registered
                                            for this filter..</h4>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default quote-panel text-center">
                            <div className="panel-body">
                                <h3 className="heading-onboarding">Have a custom requirement or need help in booking the
                                    service?</h3>
                                <br/>
                                <Link to={path.covid19FreeSignup} className="btn btn-job-post">{requestQuote}</Link>
                            </div>
                        </div>
                    </Fragment>
                )
                :
                <Fragment>
                    {loading && this.loadingUi()}
                    {professionalList.map((gigDetails) => {
                        const {professional_details, price, professional, id} = gigDetails
                        const {
                            avatar,
                            first_name,
                            last_name,
                            slug,
                            work_experience,
                            bio_caption,
                            city,
                            bio_detailed,
                            job_completed_count
                        } = professional_details
                        const {display_name} = product_details
                        let publicProfile = `/${freelancers}/${slug}`
                        if (coupon_code){
                            publicProfile += `?coupon_code=${coupon_code}`
                        }
                        return (
                            <div
                                className={`${product_type === professional_type.designer ? '' : 'panel panel-default'}`}
                                key={id}>
                                <div className={`${product_type === professional_type.designer ? '' : 'panel-body'}`}>
                                    <div className="row">
                                        <a href={publicProfile} target='_blank'>
                                            <div className="col-sm-1 col-xs-2">
                                                {avatar ? (
                                                        <img src={avatar} alt="user" className="dp-image img-circle"/>)
                                                    :
                                                    getFirstAndLastLetterOfName(first_name, last_name)
                                                }
                                                <h5 className="hidden-xs" style={{width: '100px'}}>
                                                    <a className="heading-md"
                                                       href={publicProfile}
                                                       target='_blank'>See Profile
                                                    </a></h5
                                                >
                                            </div>
                                        </a>
                                        <div className="col-sm-8 col-xs-9">
                                            <p className="name">
                                                <GetFirstNameAndLastName firstname={first_name} lastname={last_name}/>
                                                <br className="visible-xs"/>
                                            </p>
                                            <p className="text-profile">{bio_caption}</p>
                                            <p className="text-city">{city} {workExperience(work_experience)} {getJobDone(job_completed_count)}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div
                                            className={`${professionalProductId ? 'col-sm-8' : 'col-sm-11'} col-sm-offset-1`}>
                                            <p className="para" style={{marginTop: '-15px', marginBottom: '0px'}}>
                                                <b>Bio</b></p>
                                            <p className="para read-now">
                                                <span>
                                                    <ShortDescription text={bio_detailed} maxCharLength={180}/>
                                                    <a target='_blank'
                                                       className={`${professionalProductId ? 'btn-link' : 'btn btn-share'} visible-xs`}
                                                       style={{color: '#ff337d', marginLeft: '0px', marginTop: '10px'}}
                                                       href={publicProfile}>{`See Profile`}</a>
                                                </span>
                                            </p>
                                            {/*<ul className="list-inline hidden-xs">*/}
                                            {/*{practise_area_details.slice(0, 3).map((practiseAreaDetails, index) => {*/}
                                            {/*return (*/}
                                            {/*<li key={index}><span*/}
                                            {/*className="li-practice-area">{practiseAreaDetails.name}</span>*/}
                                            {/*</li>*/}
                                            {/*)*/}
                                            {/*})}*/}
                                            {/*{(practise_area_details.length - 3) > 0 &&*/}
                                            {/*<a target='_blank' className='btn btn-link'*/}
                                            {/*href={`/${freelancers}/${slug}`}>{`...${practise_area_details.length - 3} more`}</a>}*/}
                                            {/*</ul>*/}
                                        </div>
                                        {professionalProductId &&
                                        (
                                            <div className="col-sm-3 text-center side-btn-container">
                                                <p className="title-xs-heading">{display_name}
                                                </p>
                                                <p className="title-xs-heading"
                                                   style={{marginTop: '20px', color: '#2d3feb'}}>${price}</p>
                                                <p className="text-note visible-xs" style={{marginLeft: '0px'}}><span>* Inclusive of all Taxes & Fees.</span>
                                                </p>
                                                <div className="btn-container">
                                                    <a className="btn btn-book"
                                                       onClick={() => {

                                                           const {
                                                               price,
                                                               commission,
                                                               commission_tax,
                                                               professional_amount_tax,
                                                               actual_amount_enter_by_professional
                                                           } = professional
                                                           const {status} = professional_details
                                                           if (status === professionalStatus.busy) {
                                                               $('#isAvailable').click();
                                                               return
                                                           }
                                                           const professionalDetails = {
                                                               avatar,
                                                               first_name,
                                                               last_name,
                                                               product_name: product_details.display_name,
                                                               description: `Job posted by me.`,
                                                               price,
                                                               wcFees: commission.toFixed(2),
                                                               wcGst: commission_tax.toFixed(2),
                                                               gstAmount: professional_amount_tax ? professional_amount_tax.toFixed(2) : 0,
                                                               actual_amount_enter_by_professional,
                                                               bio_caption,
                                                               productId,
                                                               parentPracticeAreaId: product_details.practise_area_child ? product_details.practise_area_child : parentPracticeAreaId,
                                                               professionalId: professional,
                                                               isConsultation
                                                           }
                                                           if (isConsultation) {
                                                               window.onBookConsultationButtonClickOnProfessionalList()
                                                           } else {
                                                               window.onHireButtonClickOnProfessionalList()
                                                           }
                                                           localStorage.selectedProfessionalDetails = JSON.stringify(professionalDetails)
                                                           this.props.history.push(!isConsultation ? path.generalOnboarding : `${path.consultationDetailsPage}/${professional.professional}`)
                                                       }}
                                                    >{!isConsultation ? 'Hire Now' : 'Book for 40 min'}</a>
                                                    <span className="include-tips-dropdown">
                                                            <a className="btn btn-book">Whats Included ?</a>
                                                            <div className="what-includes-content">
                                                                <div className="sidebar-card">
                                                                    <h4 className="side-card-heading">Whats Included in base price</h4>
                                                                    <Interweave
                                                                        tagName="span"
                                                                        content={product_details.description}
                                                                    />
                                                                    <div className="text-center">
                                                                        <p className="text-link">Have more scope of work?<br/>  Click on Support Below or  Call <br/> <a
                                                                            href="tel:+91 9999974258">+91 9999 974 258</a> </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                        }

                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Fragment>
        )
    }

    legalAreaUi = (legalArea, childPracticeAreaSlug) => {
        return (
            <li className="col-sm-2 col-md-3 col-xs-6">
                <select className="form-control"
                        name='childPracticeAreaSlug'
                        id='selected_legal_area'
                        onChange={(e) => {
                            this.setState({
                                [e.target.name]: e.target.value,
                                professionalList: []
                            }, () => {
                                this.getProfessionals(1)
                                this.replaceUrl()
                            })
                        }}
                >
                    <option selected={true} value={-1} disabled>Filter by Legal Area</option>
                    {legalArea.map((practice => {
                        const {slug, name, id} = practice
                        return <option value={slug} selected={slug === childPracticeAreaSlug}
                                       key={id}>{`${name}`}</option>
                    }))}
                </select>
            </li>
        )
    }

    replaceUrl = () => {
        const {parentPracticeAreaSlug, childPracticeAreaSlug, citySlug, productSlug} = this.state
        const {history} = this.props
        const {location} = history
        const {hash} = location
        let url = `/${productSlug}/${parentPracticeAreaSlug}${childPracticeAreaSlug ? `/${childPracticeAreaSlug}` : `${citySlug ? '/in' : ''}`}${citySlug ? `/${citySlug}` : ''}`
        history.replace({
            pathname: `${url}${hash}`,
        })
    }

    filterStripUi = () => {
        const {city, legalArea, productId, childPracticeAreaSlug, citySlug} = this.state
        return (

            <ul className="list-unstyled">
                {showLegalAreaForProduct.indexOf(productId) > -1 && this.legalAreaUi(legalArea, childPracticeAreaSlug)}
                <li className="col-sm-2 col-md-3 col-xs-6">
                    <select className="form-control"
                            name='citySlug'
                            id='selected_city'
                            onChange={(e) => {
                                const {name, value} = e.target
                                this.setState({
                                    [name]: value,
                                    professionalList: []
                                }, () => {
                                    this.getProfessionals(1)
                                    this.replaceUrl()
                                })
                            }}
                    >
                        <option selected={true} value={-1} disabled>Filter by City</option>
                        {city.map((cityData => {
                            let {id, name, slug, state_data} = cityData
                            state_data = state_data || {}
                            return <option value={slug} key={id}
                                           selected={slug === citySlug}>{`${name}, ${state_data.name}`}</option>
                        }))}
                    </select>
                </li>
                <li>
                    <a className='btn hidden-xs btn-filter-apply'
                       onClick={() => {
                           $('#selected_city').val(-1);
                           $('#selected_legal_area').val(-1);
                           this.setState({
                               childPracticeAreaSlug: '',
                               citySlug: '',
                               professionalList: []
                           }, () => this.getProfessionals(1))
                       }}
                    >Reset</a>
                </li>
            </ul>

        )
    }

    _toggleShowVideo(url) {
        this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
        this.setState({
            showVideo: this.state.showVideo
        });

        if (this.state.showVideo[url]) {
            if (this.state.showPlayButton) {
                this.setState({showGalleryPlayButton: false});
            }

            if (this.state.showFullscreenButton) {
                this.setState({showGalleryFullscreenButton: false});
            }
        }
    }

    _renderVideo(item) {
        return (
            <div className='image-gallery-image'>
                {
                    this.state.showVideo[item.vimeo_link] ?
                        <div className='video-wrapper'>
                            <a
                                className='close-video'
                                onClick={this._toggleShowVideo.bind(this, item.vimeo_link)}
                            >
                            </a>
                            <div className="embed-responsive embed-responsive-16by9">
                                {/*<video controls controlsList="nodownload">*/}
                                {/*<source*/}
                                {/*src={item.embedUrl}/>*/}
                                {/*</video>*/}
                                <ReactPlayer
                                    className='react-player'
                                    controls={true}
                                    pip={true}
                                    url={item.vimeo_link}
                                />
                            </div>
                        </div>
                        :
                        <Fragment>
                            <a onClick={this._toggleShowVideo.bind(this, item.vimeo_link)}>
                                <div className='play-button'/>
                                {/*<img src={item.original}/>*/}
                                {/*{*/}
                                {/*item.description &&*/}
                                {/*<span*/}
                                {/*className='image-gallery-description'*/}
                                {/*style={{right: '0', left: 'initial'}}*/}
                                {/*>*/}
                                {/*{item.description}*/}
                                {/*</span>*/}
                                {/*}*/}
                            </a>
                        </Fragment>
                }
            </div>
        );
    }


    // _renderVideo(item) {
    //
    //     return (
    //         <div className='image-gallery-image'>
    //             {/*<div className="embed-responsive embed-responsive-16by9">*/}
    //                 {/*<ReactPlayer controls url={item.vimeo_link}/>*/}
    //             {/*</div>*/}
    //              {
    //                 this.state.showVideo[item.embedUrl] ?
    //                     <div className='video-wrapper'>
    //                         <a
    //                             className='close-video'
    //                             onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
    //                         >
    //                         </a>
    //                         {/*<div className="embed-responsive embed-responsive-16by9">*/}
    //                             {/*<video controls controlsList="nodownload">*/}
    //                                 {/*<source*/}
    //                                     {/*src={item.embedUrl}/>*/}
    //                             {/*</video>*/}
    //                             {/*<ReactPlayer controls url={item.vimeo_link}  />*/}
    //                         {/*</div>*/}
    //                     </div>
    //                     :
    //                     <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
    //                         <div className='play-button'></div>
    //                         <img src={item.original}/>
    //                         {
    //                             item.description &&
    //                             <span
    //                                 className='image-gallery-description'
    //                                 style={{right: '0', left: 'initial'}}
    //                             >
    //                 {item.description}
    //               </span>
    //                         }
    //                     </a>
    //             }
    //         </div>
    //     );
    // }

    otherServicesOfferedByProfessional = () => {
        const {otherServices, discountDetails} = this.state
        const {coupon_code} = discountDetails
        const {history} = this.props
        return (
            <div className="row">
                {otherServices.map((gigDetails, professionalListIndex) => {
                    const {
                        professional_details,
                        samples,
                        calculated_commissions,
                        product_details,
                        professional
                    } = gigDetails
                    const {price} = calculated_commissions
                    const {
                        first_name,
                        last_name,
                        rating,
                        work_experience,
                        city,
                        avatar_thumbnail,
                        job_completed_count,
                        chat_average_response_time,
                        slug_new
                    } = professional_details
                    const {name} = product_details
                    let shareLink = `/${freelancers}/${slug_new}/${product_details.slug_new}`
                    if (coupon_code){
                        shareLink += `?coupon_code=${coupon_code}`
                    }
                    return (

                        <div className="col-sm-4 col-md-4">
                            <div className="designer-profile-card common-card">
                                <OwlCarousel
                                    className="owl-theme"
                                    loop={true}
                                    dots={false}
                                    margin={10}
                                    nav={true}
                                    navText={['<div class="fa fa-angle-left prev-slide" style="font-size:20px"></div>', '<div class="fa fa-angle-right prev-slide " style="font-size:20px"></div>']}
                                    items={1}
                                    responsiveClass={true}
                                    autoplay={false}
                                    autoplayTimeout={3000}
                                    autoplayHoverPause={true}
                                    lazyLoad={true}
                                >
                                    <SamplesUi samples={samples} productDisplayName={name}/>
                                </OwlCarousel>
                                <div className="profile-card-body">
                                    <div className="media">
                                        <div className="media-left">
                                            {avatar_thumbnail ? (
                                                    <img src={avatar_thumbnail} className="designer-dp img-circle"
                                                         alt="hire designer"/>)
                                                :
                                                getFirstAndLastLetterOfName(first_name, last_name)
                                            }
                                        </div>
                                        <div className="media-body">
                                            <h4 className="designer-name">
                                                <GetFirstNameAndLastName firstname={first_name}
                                                                         lastname={last_name}/>
                                                <span className="minimul-badge pull-right">
                                                    <span className="badge-gigzoe-product">
                                                        <ShortDescription text={name} maxCharLength={20}/>
                                                    </span>
                                                </span>
                                            </h4>
                                            <p className="designer-city">{city}</p>
                                        </div>
                                        <ul className="list-inline text-left">
                                            {rating &&
                                            <li className="span-text">
                                                <span className="fa fa-star"/> <span
                                                className="span-text">{rating.toFixed(1) + ' '}</span>
                                            </li>
                                            }
                                            {work_experience &&
                                            <li className="span-text">
                                                <span>{workExperienceDesigner(work_experience)}</span></li>
                                            }
                                            {job_completed_count > 0 &&
                                            <li className="span-text">
                                                {job_completed_count} Orders
                                            </li>
                                            }
                                            {chat_average_response_time > 0 &&
                                            <li className="span-text">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13"
                                                     fill="currentColor"
                                                     className="bi bi-clock hidden-xs" viewBox="0 0 16 16">
                                                    <path
                                                        d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                                                    <path
                                                        d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                                                </svg>
                                                <ChatResponceTime
                                                    chat_average_response_time={chat_average_response_time}/>
                                            </li>}
                                        </ul>
                                    </div>
                                    {/*<p className="designer-brief"><ShortDescription text={professional_description} maxCharLength={50}/></p>*/}
                                </div>
                                <div className="profile-card-footer">
                                    <Link to={shareLink}
                                          className="btn hire-designer-button btn-track-job"
                                          onClick={(e) => {
                                              e.preventDefault()
                                              let productDetails = JSON.parse(JSON.stringify(otherServices[professionalListIndex]))
                                              const {product_details, price, id} = productDetails || {}
                                              const {display_name} = product_details
                                              let productDisplayName = display_name
                                              let professionalList = []
                                              const {professional_details} = JSON.parse(JSON.stringify({...this.state.professionalList[0]})) || {}
                                              professionalList.push({
                                                  ...productDetails,
                                                  professional_details,
                                              })
                                              this.setState({
                                                  professionalList,
                                                  product_details,
                                                  productDisplayName,
                                                  fee: price,
                                                  // isOneToOneChat: false,
                                              }, () => {
                                                  priceType.map(serviceDetails => {
                                                      const {service} = serviceDetails
                                                      $(`#service_type${service}`).removeClass('active')
                                                  })
                                                  const {service} = priceType[0]
                                                  $(`#service_type${service}`).addClass('active')
                                                  history.replace(shareLink)
                                                  this.recordDesignerProductClick({professionalProductId: id})
                                              })
                                              window.scrollTo(0, 0);
                                          }}
                                          title={`Starting $${price}`}>Starting ${price}
                                        <span className="pull-right">
                                            Continue <i className="fa fa-long-arrow-right hidden-xs"/>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    getCurrentActivity = ({isSignUpOnPackage, isSignUpOnHire, isSignUpOnChat}) => {
        return {
            isSignUpOnPackage: isSignUpOnPackage !== undefined,
            isSignUpOnHire: isSignUpOnHire !== undefined,
            isSignUpOnChat: isSignUpOnChat !== undefined
        }
    }

    clickedOnDesignerPackageBook = ({
                                        productDetails,
                                        service,
                                        delivery_type,
                                        isFullPayment,
                                        isSignUpOnChat,
                                        package_description
                                    }) => {
        const {productId, parentPracticeAreaId, isConsultation, discountDetails} = this.state
        const {history} = this.props
        const {
            professional_details,
            calculated_commissions,
            product_details,
            id,
            professional,
            quantity_count,
            delivery_time,
            original_price

        } = productDetails
        const {avatar, first_name, last_name, bio_caption, user_id} = professional_details
        const {
            price,
            commission,
            commission_tax,
            professional_amount_tax,
            actual_amount_enter_by_professional,
            service_fee,
            discountAmount
        } = calculated_commissions
        
        console.log("debug")
        // let roundedOffCommission = roundOffCommission({
        //     commission: productDetails.commission,
        //     commission_tax: productDetails.commission_tax,
        //     professional_amount_tax: productDetails.professional_amount_tax,
        //     actual_amount_enter_by_professional: productDetails.actual_amount_enter_by_professional,
        //     service_fee: productDetails.service_fee
        // })
        // let original_price = {
        //     price: roundedOffCommission.price,
        //     commission: roundedOffCommission.commission,
        //     commission_tax: roundedOffCommission.commission_tax,
        //     professional_amount_tax: roundedOffCommission.professional_amount_tax,
        //     actual_amount_enter_by_professional: roundedOffCommission.actual_amount_enter_by_professional,
        //     service_fee: roundedOffCommission.service_fee
        // }
        const {status} = professional_details
        const {display_name, practise_area_child} = product_details
        if (status === professionalStatus.busy) {
            $('#isAvailable').click();
            return
        }
        const professionalDetails = {
            avatar,
            first_name,
            last_name,
            product_name: `${display_name} Plan : ${service} `,
            quantity_count,
            description: `Plan : ${service}`,
            price,
            first_installment: isFullPayment ? price : _bookingAmountSlab({amount: price}),
            commission,
            commission_tax,
            gstAmount: professional_amount_tax ? professional_amount_tax.toFixed(2) : 0,
            service_fee,
            actual_amount_enter_by_professional,
            bio_caption,
            productId,
            parentPracticeAreaId: practise_area_child ? practise_area_child : parentPracticeAreaId,
            professionalId: professional,
            isConsultation,
            professionalProductId: id,
            professionalUserId: user_id,
            delivery_time: productDetails[`delivery_time${delivery_type}`],
            package_description: package_description || '',
            discountDetails: {...discountDetails, discountAmount},
            original_price
        }
        let purposeForSignup = isSignUpOnChat ? 'isSignUpOnChat' : 'isSignUpOnHire'
        let currentActivity = this.getCurrentActivity({
            [purposeForSignup]: true
        })
        this.setState({
            professionalDetails,
            ...currentActivity,
            isOneToOneChat: true,
        }, () => {
            const {isSignUpOnHire} = this.state
            if (isSignUpOnHire) {
                localStorage.selectedProductDetails = JSON.stringify(professionalDetails)
                history.push(path.generalOnboarding)
            } else {
                this.onProceedToPayment({})
            }
        })
    }

    designerPackageUi = ({professional, professionalList, professionalListIndex}) => {
        const {loading} = this.state
        const {
            calculated_commissions,
            product_details,
            quantity_count,
            is_standard_active,
            is_premium_active,
            original_price,
        } = professional
        console.log('original_price', original_price)
        console.log('calculated_commissions', calculated_commissions)
        const {price, actual_amount_enter_by_professional, commission_tax, service_fee} = calculated_commissions
        const {display_name, practise_area, id} = product_details
        let professionalAmountWithTax = parseFloat(actual_amount_enter_by_professional + commission_tax).toFixed(2)
        return (
            <Fragment>
                <div className="price-compair-card">
                    <ul className="nav nav-tabs nav-justified">
                        {priceType.map((serviceType, index) => {
                            const {service, multiplier, price_type} = serviceType
                            let service_type = `service_type${service}`
                            if ((index === 1 && !is_standard_active) || (index === 2 && !is_premium_active)) {
                                return
                            }
                            return <li className={`${index === 0 ? ' active' : ''}${loading ? ' disabled' : ''}`}
                                       id={service_type}
                                       key={service}
                                       onClick={() => {
                                           if (loading) {
                                               return ''
                                           }
                                           let professionalDetails = JSON.parse(JSON.stringify(professional))
                                           const {discountDetails} = this.state
                                           const {is_valid_coupon_code} = discountDetails
                                           let actual_amount_enter_by_professional = Number(professional[`price${price_type}`])
                                           let {
                                               commission,
                                               commission_tax,
                                               professional_amount_tax,
                                               service_fee
                                           } = professionalDetails
                                           // actual_amount_enter_by_professional
                                           commission = 0
                                           commission_tax = commission_tax * professionalDetails[`${multiplier}`]
                                           service_fee = service_fee * professionalDetails[`${multiplier}`]
                                           professional_amount_tax = 0
                                           let discountedAmountsKeys = is_valid_coupon_code ? getDiscount({
                                               discountSlab: {...discountDetails},
                                               professionalDetails: {...professionalDetails, ...professional, actual_amount_enter_by_professional}
                                           }) : {}
                                           if (!discountedAmountsKeys.discount_amount_of_professional_fee){
                                               discountedAmountsKeys.discount_amount_of_professional_fee = 0
                                           }
                                           if (!discountedAmountsKeys.discount_amount_of_commission_tax){
                                               discountedAmountsKeys.discount_amount_of_commission_tax = 0
                                           }
                                           if (!discountedAmountsKeys.discount_amount_of_service_fee){
                                               discountedAmountsKeys.discount_amount_of_service_fee = 0
                                           }
                                           if (!discountedAmountsKeys.total_discount){
                                               discountedAmountsKeys.total_discount = 0
                                           }

                                           let roundedOffCommission = _roundOfCommission({
                                               commission,
                                               commission_tax,
                                               actual_amount_enter_by_professional,
                                               professional_amount_tax,
                                               service_fee
                                           })
                                           let original_price = {
                                               ...roundedOffCommission
                                           }

                                           let discountedCommission = {
                                               price: getFloatType(original_price.price-discountedAmountsKeys.discount_amount_of_commission_tax-discountedAmountsKeys.discount_amount_of_service_fee-discountedAmountsKeys.discount_amount_of_professional_fee),
                                               commission,
                                               commission_tax: getFloatType(commission_tax - discountedAmountsKeys.discount_amount_of_commission_tax),
                                               professional_amount_tax,
                                               service_fee: getFloatType(service_fee - discountedAmountsKeys.discount_amount_of_service_fee),
                                               actual_amount_enter_by_professional: getFloatType(actual_amount_enter_by_professional - discountedAmountsKeys.discount_amount_of_professional_fee),
                                               discountAmount: getFloatType(discountedAmountsKeys.total_discount)
                                           }
                                           let roundedOffDiscountedCommission = _roundOfCommission({
                                               ...discountedCommission
                                           })
                                           discountedCommission = {
                                               ...roundedOffDiscountedCommission
                                           }
                                           // commission = roundedOffCommission.commission
                                           // commission_tax = roundedOffCommission.commission_tax
                                           // actual_amount_enter_by_professional = roundedOffCommission.actual_amount_enter_by_professional
                                           // professional_amount_tax = roundedOffCommission.professional_amount_tax
                                           // price = roundedOffCommission.price
                                           // service_fee = roundedOffCommission.service_fee

                                           professionalList[professionalListIndex] = {
                                               ...professionalDetails,
                                               original_price,
                                               quantity_count: 1,
                                               calculated_commissions: {
                                                  ...discountedCommission
                                               }
                                           }
                                           this.setState({professionalList})
                                       }
                                       }
                            >
                                <a data-toggle="tab" href={`#${service}`} title={service}>{service}</a>
                            </li>
                        })}
                    </ul>
                    <div className="price-compair-card-body">
                        <div className="tab-content">
                            {priceType.map((serviceType, index) => {
                                const {service, price_type, delivery_type} = serviceType
                                let productDescription = professional[`desc${price_type}`] || product_details[`description${price_type}`]
                                return (
                                    <div id={service}
                                         className={`tab-pane in ${index === 0 ? 'active' : ''}`}>
                                        <h2 className="d-inline-text"><span>{display_name}</span>
                                            <span style={{width: '30%', textAlign: 'right'}}>${professionalAmountWithTax}</span>
                                        </h2>
                                        <Interweave
                                            tagName="span"
                                            content={productDescription}
                                        />
                                        <p className="text-timer"><img
                                            src={require('../../static/images/timer.png').default}
                                            alt="Delivery time"/> {professional[`delivery_time${delivery_type}`]}
                                            &#x00A0;Days Delivery time
                                        </p>
                                        <p className="d-inline-text"><span>{processingCharges}</span>
                                            <span style={{width: '30%', textAlign: 'right'}}>${service_fee}</span>
                                        </p>
                                        <p className="d-inline-text"><span>Total</span>
                                            <span style={{width: '30%', textAlign: 'right'}}>${price}</span>
                                        </p>
                                        <Fragment>
                                            <p className="d-inline-text">
                                                <span>First Instalment</span>
                                                <span>${_bookingAmountSlab({amount: price})}</span>
                                            </p>
                                            <ProductIncrease quantity_count={quantity_count}
                                                             changeProductCountCallBack={this.changeProductCount}
                                                             productId={id}
                                                             serviceType={serviceType}
                                                             productDetails={professional}
                                            />
                                            {price > _bookingAmountSlab({amount: price}) ?
                                                <button className="btn btn-pay-installment btn-track-pay"
                                                        title={`Pay Booking Amount of $${_bookingAmountSlab({amount: price})}`}
                                                        disabled={loading}
                                                        onClick={() => this.clickedOnDesignerPackageBook({
                                                            productDetails: professional,
                                                            service,
                                                            delivery_type,
                                                            isFullPayment: false,
                                                            package_description: productDescription
                                                        })}
                                                >
                                                    Pay Booking Amount of $
                                                    <span> {_bookingAmountSlab({amount: price})}</span>
                                                </button>
                                                :
                                                <button className="btn btn-pay-installment btn-track-pay"
                                                        disabled={loading}
                                                        title={`${fullPaymentText} ${price}`}
                                                        onClick={() => this.clickedOnDesignerPackageBook({
                                                            productDetails: professional,
                                                            service,
                                                            delivery_type,
                                                            isFullPayment: true,
                                                            package_description: productDescription
                                                        })}
                                                >
                                                    <span>{`${fullPaymentText} ${price}`}</span>
                                                </button>
                                            }
                                            <p className="para text-center" style={{margin: '0px'}}><b>Or</b></p>
                                        </Fragment>
                                        <div className="card-button-box">
                                            <button className="btn btn-share-expert-chat"
                                                    disabled={loading}
                                                    title={messageToExpert}
                                                    onClick={() => this.clickedOnDesignerPackageBook({
                                                        productDetails: professional,
                                                        service: '',
                                                        isFullPayment: false,
                                                        isSignUpOnChat: true,
                                                        package_description: productDescription
                                                    })}
                                            >
                                                <i className="fa fa-envelope" aria-hidden="true"/> {messageToExpert}
                                            </button>
                                            <button className="btn btn-share-expert-chat"
                                                    disabled={loading}
                                                    title={callToExpert}
                                                    onClick={() => this.clickedOnDesignerPackageBook({
                                                        productDetails: professional,
                                                        service: '',
                                                        isFullPayment: false,
                                                        isSignUpOnChat: true,
                                                        package_description: productDescription
                                                    })}
                                            >
                                                <i className="fa fa-phone" aria-hidden="true"/> {callToExpert}
                                            </button>
                                        </div>
                                        <p className="text-info-title" style={{marginTop: '15px'}}>*Terms &
                                            Conditions</p>
                                        <ul className="cp-ul">
                                            <li>100% Refund Protection for service non delivery</li>
                                            <li>Please check all deliverables. Refund shall only be applicable on these
                                                deliverables. Any extra features would require additional payments and
                                                time..
                                            </li>
                                        </ul>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    externalSiteLinkUi = ({externalSiteLink, professional_details, loading}) => {
        const {first_name, last_name} = professional_details || {}
        return (
            <Fragment>
                <h3 className="d-designer-heading">
                    See Portfolio of <GetFirstNameAndLastName firstname={first_name} lastname={last_name}/>
                </h3>
                {externalSiteLink.map(externalLink => {
                    return <p className="external-link">
                        <a href={externalLink} target='_blank' rel='nofollow'>
                            {externalLink}
                        </a>
                    </p>
                })}
                <h3 className="d-designer-heading">For more portfolio links</h3>
                {/*<a className="btn btn-book-service"*/}
                {/*disabled={loading}*/}
                {/*onClick={() => this.clickedOnDesignerPackageBook({*/}
                {/*// productDetails: professional,*/}
                {/*service: '',*/}
                {/*isFullPayment: false,*/}
                {/*isSignUpOnChat: true*/}
                {/*// package_description: productDescription*/}
                {/*})}*/}
                {/*>Message {first_name}</a>*/}
            </Fragment>
        )
    }
    publicReviewsUi = ({reviews}) => {
        return (
            <div className="panel panel-default gig-panel">
                <div className="reviews-box">
                    <div className="panel-body">
                        <h2 className="d-designer-heading">Customer Reviews</h2>
                        {reviews.map(review => {
                            const {user_name, comment, project_name, rating} = review
                            return <Fragment>
                                <p className="text-name">{user_name} {ratingUi(rating)}</p>
                                <p className="text-product-name">{project_name}</p>
                                <p className="text-para-reviews">{comment}</p>
                            </Fragment>
                        })}
                    </div>
                </div>
            </div>
        )
    }

    messageButton = ({productDetails, classes, first_name}) => {
        const {loading} = this.state
        return (
            <a className={classes}
               disabled={loading}
               onClick={() => this.clickedOnDesignerPackageBook({
                   productDetails,
                   service: '',
                   isFullPayment: false,
                   isSignUpOnChat: true,
               })}
            >
                {`Message`} {first_name}
            </a>
        )
    }


    designersProfessionals = () => {
        const {
            professionalList,
            product_details,
            loading,
            totalRecord,
            isProfessionalApiHit,
            otherServices,
            discountDetails
        } = this.state
        const {coupon_code} = discountDetails
        if (loading && !isProfessionalApiHit) {
            return this.loadingUi()

        }
        return (
            totalRecord === 0 ?
                (<div className="container">
                        <div className="panel panel-default">
                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-sm-12 text-center">
                                        <h4 className="text-empty-space">Sorry. There are no professionals registered
                                            for this filter..</h4>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default quote-panel text-center">
                            <div className="panel-body">
                                <h3 className="heading-sm">Looking for a custom quote or have a larger scope of
                                    work?</h3>
                                <p className="text-primary">We will get you the best-matched quote. No Spamming, No
                                    Bidding. Book Service quickly & affordably.</p>
                                <br/>
                                <Link to={path.covid19FreeSignup} className="btn btn-job-post">{requestQuote}</Link>
                            </div>
                        </div>
                    </div>
                )
                :
                <Fragment>
                    {loading && this.loadingUi()}
                    {professionalList.map((professional, professionalListIndex) => {
                        let {
                            professional_details,
                            samples,
                            professional_description,
                            calculated_commissions,
                            externalSiteLink
                        } = professional || {}
                        // externalSiteLink = externalSiteLink || []
                        let {
                            avatar_thumbnail,
                            first_name,
                            last_name,
                            slug_new,
                            status,
                            rating,
                            work_experience,
                            bio_caption,
                            city,
                            bio_detailed,
                            language_string,
                            job_completed_count,
                            reviews,
                            badge
                        } = professional_details || {}
                        reviews = reviews || []
                        let publicProfileLink = `${baseUrl}/${freelancers}/${slug_new}`
                        if (coupon_code){
                            publicProfileLink += `?coupon_code=${coupon_code}`
                        }
                        return (
                            <div className="container gig-detail-container">
                                <div className="row" key={professional.professional}>
                                    <div className="col-sm-4 col-md-6">
                                        <p className="visible-xs">&#x00A0;</p>
                                        <h2 className="d-designer-heading hidden-xs"
                                            style={{marginBottom: '20px'}}>Gallery</h2>
                                        <ImageGallery
                                            showPlayButton={false}
                                            showBullets={true}
                                            lazyLoad={true}
                                            useBrowserFullscreen={false}
                                            onClick={() => {
                                                $('.image-gallery-fullscreen-button').click()
                                            }}
                                            items={samples}/>
                                        <p className="hidden-xs">&#x00A0;</p>
                                    </div>
                                    <div className="col-sm-8 col-md-6">
                                        <div className="media designer-media">
                                            <div className="media-left media-middle">
                                                <a target='_blank'
                                                   href={publicProfileLink}>
                                                    {avatar_thumbnail ? (<img src={avatar_thumbnail} alt="user"
                                                                              className="designer-prfile-img img-circle"/>)
                                                        :
                                                        getFirstAndLastLetterOfName(first_name, last_name)
                                                    }
                                                </a>
                                            </div>
                                            <div className="media-body">
                                                <h1 className="d-name-text">
                                                    <GetFirstNameAndLastName firstname={first_name}
                                                                             lastname={last_name}/>
                                                    <span className="pull-right professional-badge-with-name">
                                                       {badge.priority > 0 && <MinimulBadgeUi
                                                           badge={badge}/>
                                                       }
                                                     </span>
                                                </h1>
                                                <p className="d-bio-text">{language_string}</p>
                                                <h2 className="d-about-text">
                                                    <span>{city}</span>
                                                    <span
                                                        className="span-text">{workExperienceDesigner(work_experience)}</span>
                                                    <span className="span-text"><span
                                                        className="fa fa-star"/> {' ' + rating.toFixed(1) + ' '}</span>
                                                    {reviews.length > 0 &&
                                                    <span className="span-text"> {job_completed_count} Orders</span>}
                                                </h2>
                                            </div>
                                        </div>

                                        <h3 className="d-designer-heading">Hi, {professional_description}. Hire me on
                                            Workbeam.</h3>
                                        {this.designerPackageUi({
                                            professional,
                                            professionalList,
                                            professionalListIndex
                                        })}
                                    </div>
                                </div>
                                <WhyBookOnGigzoe/>
                                <div className="row">
                                    <div className="col-sm-12">
                                        {/*{externalSiteLink.length > 0 &&*/}
                                        {/*}*/}
                                        <div className="panel panel-default gig-panel">
                                            <div className="panel-body">
                                                <h3 className="d-designer-heading">Portfolio or past work</h3>
                                                <p className="para">Message {first_name} and ask for portfolio and past
                                                    work</p>
                                                {this.messageButton({
                                                    productDetails: professional,
                                                    first_name,
                                                    classes: `btn btn-ask-portfolio`
                                                })}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h3 className="d-designer-heading">About
                                            <GetFirstNameAndLastName
                                                firstname={first_name}
                                                lastname={last_name}
                                            />
                                        </h3>
                                        <div className="listing-panel">
                                            <div className="panel panel-default">
                                                <div className="panel-body">
                                                    <div className="media designer-media-panel">
                                                        <div className="media-left">
                                                            <a target='_blank'
                                                               href={publicProfileLink}>
                                                                {avatar_thumbnail ? (
                                                                        <img src={avatar_thumbnail} alt="user"
                                                                             className="designer-prfile-img img-circle"/>)
                                                                    :
                                                                    getFirstAndLastLetterOfName(first_name, last_name)
                                                                }
                                                            </a>
                                                        </div>
                                                        <div className="media-body">
                                                            <h4 className="d-name-text"
                                                                title={first_name + ' ' + last_name}>
                                                                <GetFirstNameAndLastName firstname={first_name}
                                                                                         lastname={last_name}/>
                                                                <span
                                                                    className={professionalStatus.active === status ? 'label label-success' : 'label label-warning'}>{professional_details.status === professionalStatus.active ? 'Available' : 'Busy'}</span>
                                                            </h4>
                                                            <p className="d-bio-text"><ShortDescription
                                                                text={bio_caption} maxCharLength={50}/></p>

                                                            <p className="d-about-text">
                                                                <span>{city}</span></p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <ul className="list-inline more-info-li">
                                                                <li><span
                                                                    className="span-text">{workExperienceDesigner(work_experience)}</span>
                                                                </li>
                                                                <li>
                                                                    <span className="fa fa-star"/>
                                                                    <span
                                                                        className="span-text">{' ' + rating.toFixed(1) + ' '}</span>
                                                                </li>
                                                                <li>
                                                                    {reviews.length > 0 && <span
                                                                        className="span-text"> {reviews.length} Reviews</span>}
                                                                </li>
                                                            </ul>
                                                            <p className="para" style={{marginLeft: '0px'}}>
                                                                <Interweave
                                                                    tagName="span"
                                                                    content={bio_detailed}
                                                                />
                                                            </p>
                                                        </div>
                                                        <div className="col-sm-12 text-center">
                                                            <a target='_blank' className='btn btn-save-profile'
                                                               style={{marginLeft: '0px', marginTop: '10px'}}
                                                               href={publicProfileLink}
                                                               title={`See Full Profile`}>See Profile</a>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        {reviews && reviews.length > 0
                                        &&
                                        this.publicReviewsUi({reviews})
                                        }
                                    </div>
                                </div>
                                {otherServices.length > 0 && (
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h3 className="d-designer-heading" style={{marginBottom: '20px'}}>
                                                Other Services by <GetFirstNameAndLastName firstname={first_name}
                                                                                           lastname={last_name}/>
                                            </h3>
                                            {this.otherServicesOfferedByProfessional()}
                                        </div>
                                    </div>
                                )}
                                <GeneralFaqForClient productName={product_details.name}/>
                            </div>
                        )
                    })}
                </Fragment>
        )
    }

    onClickBookPackage = ({packageDetails, isFullPayment}) => {
        const {isConsultation, discountDetails} = this.state
        const {is_valid_coupon_code} = discountDetails
        const {history} = this.props
        let {
            id,
            description,
            display_name,
            practise_area,
            practise_area_child,
            calculated_commissions,
            first_installment
        } = packageDetails
        let {
            price,
            commission,
            commission_tax,
            professional_amount_tax,
            actual_amount_enter_by_professional,
            discountAmount
        } = calculated_commissions
        const {professionalId} = assignTaskToThisProfessional
        let professionalDetails = {...this.state.professionalDetails}
        professionalDetails = {
            ...professionalDetails,
            ...packageDetails,
            productId: id,
            product_name: display_name,
            price,
            commission: commission.toFixed(2),
            commission_tax: commission_tax.toFixed(2),
            gstAmount: professional_amount_tax ? professional_amount_tax.toFixed(2) : 0,
            actual_amount_enter_by_professional,
            parentPracticeAreaId: practise_area || practise_area_child,
            professionalId,
            first_installment: isFullPayment ? price : first_installment,
            package_description: description,
            discountDetails: is_valid_coupon_code ? {...discountDetails, isDiscountApplied: true, discountAmount} : {}
        }
        if (isConsultation) {
            window.onBookConsultationButtonClickOnProfessionalList()
        } else {
            window.onHireButtonClickOnProfessionalList()
        }
        localStorage.selectedProductDetails = JSON.stringify(professionalDetails)
        history.push(path.generalOnboarding)
    }

    packageListUi = () => {
        const {packageList, loading} = this.state
        return <div>
            {packageList.map(packageDetails => {
                const {
                    description,
                    display_name,
                    id,
                    isMoreInfo,
                    calculated_commissions,
                    off,
                    first_installment,
                    quantity_count
                } = packageDetails
                const {price} = calculated_commissions
                let totalPrice = price + off
                let selectedPackageUiId = `see_more_package_info${id}`
                return (
                    <div className="col-sm-6 col-lg-4">
                        <div className="package-card"
                             id={selectedPackageUiId}
                        >
                            <div className="package-card-header">
                                <p className="package-head-name">{display_name}</p>
                            </div>
                            <div className="package-card-body">
                                <p className="xs-heading"><b>Includes:</b></p>
                                <div className="package-text">
                                    <Interweave
                                        tagName="span"
                                        content={description}
                                    />
                                    {isMoreInfo &&
                                    <a className='btn btn-link'
                                       id={`see_more_package_info_hide_${id}`}
                                       onClick={() => {
                                           $(`#${selectedPackageUiId} li:hidden`).show()
                                           if ($(`#${selectedPackageUiId} li`).length === $(`#${selectedPackageUiId} li:visible`).length) {
                                               $(`#see_more_package_info_hide_${id}`).hide()

                                           }
                                       }
                                       }
                                    >
                                        See more
                                    </a>
                                    }

                                </div>
                            </div>
                            <div className="profile-card-footer">
                                <ul className="list-inline text-left"
                                    style={{borderBottom: '1px solid #eaeaea', marginLeft: '0px'}}>
                                </ul>
                                <p className="text-price">
                                    <strike className="actual-price">${totalPrice}</strike>
                                    ${price}
                                    <span className="inclusive-price">All Inclusive</span>
                                    <br/><span className="save-price">Save ${off}</span>

                                </p>
                                <ProductIncrease quantity_count={quantity_count}
                                                 changeProductCountCallBack={this.changeProductCount}
                                                 productId={id}
                                                 productDetails={packageDetails}
                                />
                                <button className="btn hire-designer-button btn-track-job"
                                        disabled={loading}
                                        onClick={() => this.onClickBookPackage({packageDetails, isFullPayment: false})}
                                >

                                    {`${payBookingAmount} $${first_installment}`}
                                </button>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    }


    designerProductOnboarding = () => {
        return (
            <section className="page-contant">
                {this.designersProfessionals()}
            </section>
        )
    }

    paginationUiForPackage = () => {
        const {loading, totalPackage, limit, packagePageNo} = this.state
        return (
            <Fragment>
                {!loading && totalPackage > limit &&
                <PaginationWithArrow onChange={this.getPackages} totalRecord={totalPackage} pageSize={limit}
                                     currentPage={packagePageNo}/>}
            </Fragment>
        )
    }

    paginationUi = () => {
        const {loading, totalRecord, limit, page} = this.state
        return (
            <Fragment>
                {!loading && totalRecord > 10 &&
                <PaginationWithArrow onChange={this.getProfessionals} totalRecord={totalRecord} pageSize={limit}
                                     currentPage={page}/>}
            </Fragment>
        )
    }

    render() {
        const {props} = this
        const {history} = props
        const {location} = history
        const {pathname, search, hash} = location
        let {
            totalRecord,
            loading,
            productSlug,
            productId,
            professionalList,
            parentPracticeAreaSlug,
            childPracticeAreaSlug,
            citySlug,
            fee,
            productDisplayName,
            productLink,
            professionalAvatar,
            product_type,
            city,
            cityId,
            phone,
            packageList,
            totalPackage,
            professionalDetails,
            isSignUpOnChat,
            isSignUpEnabled,
            professionalName,
            discountDetails,
            professionalSlug,
            cityName,
            professionalCity
        } = this.state
        const {first_installment} = professionalDetails || {}
        // if (childPracticeAreaSlug === 'in') {
        //     childPracticeAreaSlug = childPracticeAreaSlug.replace('in', '')
        // }
        let productDetailsLocally = productDetails({
            productId,
            productSlug,
            parentPracticeAreaSlug,
            childPracticeAreaSlug,
            citySlug,
            professionalSlug,
            professionalName,
            professionalFee: fee,
            productDisplayName,
            professionalAvatar,
            productLinkWitProfessionalFee: `${pathname}${search ? `/${search}` : ''}`,
            product_type,
            cityName: professionalCity
        })
        const {heading_text} = productDetailsLocally || {}
        const {
            backgroundColor,
            headingText,
            startingPriceText,
            image,
            bannerImage,
            subheadingText,
            hashTag,
            userRating,
            userCount
        } = heading_text || {}
        return (
            <Fragment>
                {productId && productDetailsLocally.meta_tag}
                {professionalSlug ? this.designerProductOnboarding() :
                    <Fragment>
                        {!professionalSlug &&
                        <Fragment>
                            <section className="service-head-box">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-sm-12 no-padding">
                                            <OwlCarousel
                                                className="owl-theme"
                                                loop={true}
                                                margin={20}
                                                dots={true}
                                                items={1}
                                                autoplay={true}
                                                autoHeight={true}
                                                autoHeightClass="owl-height"
                                                autoplayTimeout={4000}
                                                animateOut='fadeOut'
                                                id="owl-crousel-slider"
                                            >
                                                {discountDetails.id && discountDetails.logo &&
                                                <div className="item">
                                                    <Affiliate affiliatePartnerDetails={[{...discountDetails}]}/>
                                                </div>
                                                }
                                                <div className="item">
                                                    <div className={`add-crowsel${backgroundColor}`}>
                                                        {productId && serviceFirstSectionAdd({
                                                            headingText,
                                                            subheadingText,
                                                            backgroundColor,
                                                            startingPriceText,
                                                            image,
                                                            bannerImage,
                                                            hashTag,
                                                            userRating,
                                                            userCount
                                                        })}
                                                    </div>
                                                </div>
                                            </OwlCarousel>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div className="sticky-tab-bar">
                                <div className="hero-client-container legal-sm">
                                    <div className="container">
                                        <ul className="nav nav-tabs">
                                            {tabs.map((tabDetails, index) => {
                                                const {tabName, id} = tabDetails
                                                return <li className={index === 0 ? 'active' : ''}
                                                    // id={id}
                                                           key={tabName}
                                                           onClick={() => {
                                                               if (index === 0 && !loading && totalPackage === 0) {
                                                                   this.getPackages(1)
                                                               }
                                                               if (index === 1 && !loading && totalRecord === 0) {
                                                                   this.getProfessionals(1)
                                                               }
                                                               history.replace({
                                                                   pathname: `${pathname}${hash}`,
                                                                   search,
                                                               })
                                                           }

                                                           }
                                                >
                                                    <a
                                                        href={`#${id}`}
                                                        data-toggle={(index === 0 || index === 1) ? `tab` : null}
                                                    >{tabName}</a>
                                                </li>
                                            })}
                                            <li>
                                                <a href="#Info_guide"
                                                   onClick={(e) => {
                                                       e.preventDefault();
                                                       $('html, body').animate({
                                                           scrollTop: $("#Info_guide").offset().top - 80
                                                       }, 500);
                                                   }}
                                                >Info Guide</a>
                                            </li>
                                            <li>
                                                <a href="#public_reviews"
                                                   onClick={(e) => {
                                                       e.preventDefault();
                                                       $('html, body').animate({
                                                           scrollTop: $("#public_reviews").offset().top - 80
                                                       }, 500);
                                                   }}
                                                >Reviews</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                        }
                        <section className="package-section">
                            <div className="container">
                                {professionalSlug && !loading &&
                                <div className="row">
                                    <div className="col-sm-12">
                                        <p className="visible-xs">&#x00A0;</p>
                                        <h4 className="d-sub-heading"
                                            style={{color: '#1b3eef', fontSize: '22px', marginBottom: '20px'}}>
                                            Hi, I will perform {productDisplayName}. Hire me on Workbeam.</h4>
                                    </div>
                                </div>
                                }
                                <div className="row">
                                    <div className="col-md-9 listing-panel">
                                        {/*<p className="visible-xs text-bold-heading" style={{color:'#ff337d'}}>Save time and book directly.</p>*/}
                                        {professionalSlug && !loading &&
                                        <Fragment>
                                            {this.professionals()}
                                        </Fragment>
                                        }
                                        <div className="tab-content">
                                            <div id={tabs[0].id} className="tab-pane in fade active">
                                                <div className="row">
                                                    {loading && this.loadingUi()}
                                                    {this.packageListUi()}
                                                    <div className="col-sm-12">
                                                        {!loading && !professionalSlug && packageList.length > 0 &&
                                                        <div className='text-center'>
                                                            <span>{`${packageList.length} of ${totalPackage}`}</span>
                                                        </div>}
                                                        {this.paginationUiForPackage()}
                                                    </div>
                                                </div>
                                            </div>
                                            <div id={tabs[1].id} className="tab-pane fade">
                                                {!professionalSlug &&
                                                <div className="row" style={{marginBottom: '20px'}}>
                                                    <div className="col-sm-12">
                                                        {this.filterStripUi()}
                                                    </div>
                                                </div>
                                                }
                                                {this.professionals()}
                                                {!loading && !professionalSlug && professionalList.length > 0 &&
                                                <div className='text-center'>
                                                    <span>{`${professionalList.length} of ${totalRecord}`}</span>
                                                </div>}
                                                {this.paginationUi()}
                                            </div>
                                        </div>
                                        <p>&#x00A0;</p>
                                        <div id="Info_guide">
                                            {!professionalSlug && productSlug && allFaqText[productSlugMappedWithId[productSlug].product_id] !== undefined && (
                                                <Fragment>
                                                    <div className="panel panel-default">
                                                        <div className="panel-body">
                                                            <h2 className="heading-sm">Information Guide<span
                                                                className="dot-pink">.</span></h2>
                                                            {allFaqText[productSlugMappedWithId[productSlug].product_id]}
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            )}
                                            {professionalSlug ?
                                                (
                                                    <ShareHowItWork/>
                                                )
                                                :
                                                (
                                                    <Fragment>
                                                        {/*<ServiceHowItWork/>*/}
                                                    </Fragment>
                                                )
                                            }
                                        </div>
                                        <p>&#x00A0;</p>
                                        {professionalSlug &&
                                        <div id="wc_assurance">
                                            <div className="panel panel-default">
                                                <ShareAssurance/>
                                            </div>
                                        </div>
                                        }
                                        {!professionalSlug &&
                                        <div id="public_reviews">
                                            <ProductReviews productId={productId} userRating={userRating}
                                                            userCount={userCount}/>
                                        </div>
                                        }
                                        <div className="panel panel-default">
                                            <div className="panel-body">
                                                <OurBrand/>
                                            </div>
                                        </div>
                                        <div className="panel panel-default">
                                            <div className="panel-body">
                                                <h2 className="heading-sm">Workbeam in the Press<span
                                                    className="dot-pink">.</span></h2>
                                                <InTheNews/>
                                            </div>
                                        </div>
                                    </div>
                                    {productId && this.requestCallBack()}
                                </div>
                            </div>
                        </section>
                        // <WizCommunity/>
                    </Fragment>
                }
                <p id='share_product_on_social_media' data-toggle="modal"
                   data-target="#share_product_on_social_media_popup"/>
                <p id="isAvailable" data-toggle="modal" data-target="#professional-available-popup"/>
                <p id='direct_hire_signup' data-toggle="modal" data-target="#direct_hire_signup_popup"/>
                <p id="update_profile_msg" data-toggle="modal" data-target="#consultation_fee"/>
                <Fragment>
                    <div className="modal share-profile-modal"
                         id="share_product_on_social_media_popup"
                         role="dialog">
                        <ShareProfile link={productLink} popupHeading={`Share and show some love.`}/>
                    </div>
                    <div className="modal center-modal" id="direct_hire_signup_popup" role="dialog">
                        {isSignUpEnabled &&
                        <OnboardingSignup
                            {...this.props}
                            isFromDirectHire={true}
                            submitRequest={this.onProceedToPayment}
                            city={[]}
                            setLoading={this.setLoading}
                            updateProfileAndPostJob={this.updateProfileAndPostJob}
                            isHidingPasswordAndOptField={true}
                            signUpButtonName={isSignUpOnChat ? signUpAndChat : `${signUpAndPay} ${first_installment}`}
                        />
                        }
                    </div>
                    <div className="modal fade" id="professional-available-popup" role="dialog">
                        <CreateProfessionalAccountPopUp url={''}
                                                        name={''}
                                                        image={require("../../static/images/sendProposalPopupVerification.png")}
                                                        message={professional_busy}
                        />
                    </div>
                    <UserUpdateAddress
                        geoCity={city}
                        phoneNo={phone}
                        cityId={cityId}
                        updateProfileAndPostJob={this.updateProfileAndPostJob}/>
                </Fragment>
            </Fragment>
        );
    }
}


const mapStateToProps = state => ((state) => {
    const {city} = state.state
    return {city}
});

const mapDispatchToProps = (dispatch) => ({
    onGetCity: (action) => {
        dispatch(action)
    },
    ...(errorActionDispatchers(dispatch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultGigDetails)
export const professional_busy = 'This expert seems to be busy and might not be available to work on your job.'
export const firstInstallment = 500
export const cutoffAmount = 1000
export const firstInstallmentText = 'Pay 1st Instalment $'
export const processingCharges = 'Processing Charges 2%'
export const fullPaymentText = 'Make Payment $'
export const makeEscrowPayment = 'Make Escrow Payment'
export const payBookingAmount = 'Pay Booking Amount'
export const signUpAndPay = 'Sign Up And Pay $'
export const chatWithExpert = 'Chat & Discuss before booking'
export const messageToExpert = 'Send Message'
export const callToExpert = 'Call Now'
export const signUpAndChat = 'Sign Up And Chat'
export const signUpAndPayBookingAmount = 'Sign Up And Pay Booking Amount'
export const signUp = 'Sign Up'

