import React, {Fragment} from 'react';
import {
    createTheme,
    MuiThemeProvider,
    withStyles
} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip"
import Interweave from "interweave";
import localStorage from '../utils/localStorage'
// import {userLogin} from "../constants/path";
import * as path from "../constants/path";
import {imageColors, alphabetLargeChar} from "../components/common/userAndProfessionalProfileFunctions";
import {productIdMappedWithSlug} from "../components/common/bannerTextForProducts";

// function truncateString({text, maxCharLength}) {
//     if (text.length > maxCharLength){
//         return text.substring(0, maxCharLength) + '...'
//     } else {
//         return text
//     }
// }

function htmlParser(html) {
    let spanEle = document.createElement('span');
    spanEle.innerHTML = html
    return spanEle.textContent || spanEle.innerText;
}

const theme = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "1",
                color: "white",
                background: 'black'
            }
        }
    }
})
const styles = {
    tooltip: {
        color: "lightblue",
    }
}
function customToolTrip({text, maxCharLength, isLookingFor, children}){
    text = text || ''
    let truncatedText = text
    if (text.length > maxCharLength){
        truncatedText = text.substring(0, maxCharLength) + '...'
    }
    return(
        <MuiThemeProvider theme={theme}>
            <Tooltip title={text}
            >
                <span>{isLookingFor !== undefined ? `Looking for : ${truncatedText}`: truncatedText}</span>
            </Tooltip>
            {children}
        </MuiThemeProvider>

    )
}
function ShortDescription({text, maxCharLength}){
    let truncatedText = text
    if (text && text.length > maxCharLength){
        truncatedText = text.substring(0, maxCharLength) + '...'
    }
    return truncatedText || ''
}
const CustomToolTrip = withStyles(styles)(customToolTrip)
function GetFirstNameAndLastName({firstname, lastname}) {
    firstname = firstname ? firstname.charAt(0).toUpperCase() + firstname.slice(1) : ''
    lastname = lastname ? lastname.charAt(0).toUpperCase() : ''
    return `${firstname} ${lastname}.`
}

function UserNameAvatar ({firstName, lastName, avatar}) {
    return(
        <div className="row">
            <div className="col-sm-6 col-sm-offset-3">
            </div>
        </div>
    )
}

function ParseHtmlToString ({htmlContent}) {
    return(
        <Interweave
            tagName="span"
            content={htmlContent}/>
    )
}

function requestFreeProposalPath() {
    return localStorage.isSessionValid() ? `${path.userLogin}` : `/${path.userRegistration}/${path.requestFreeProposal}`
}

function GetAvatar({first_name, last_name, avatar, navUrl, avatarClass}) {
    let firstChar = first_name ? first_name.charAt(0).toUpperCase(): ''
    let lastChar = last_name ? last_name.charAt(0).toUpperCase(): ''
    let backGround = '#8927BE'
    let style = {
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        lineHeight: '40px',
        backGround,
        padding:'5px',
        color: '#fff',
        textAlign:'center',
        verticalAlign:'bottom',
        margin:'auto'
    }
    if ((alphabetLargeChar.indexOf(firstChar) >= 0)) {
        style.background = imageColors[((alphabetLargeChar.indexOf(firstChar))%4)]
    }
    return (
        <Fragment>
            {avatar ? <img src={avatar} alt={avatar} className={avatarClass} alt={first_name + ' ' + last_name}/>
            :
             <div className="blank-dp" style={style}>
                    <span style={{fontSize:'20px'}}>
                        {`${firstChar}${lastChar}`}
                    </span>
                </div>
            }

        </Fragment>
    )

}

export function getProductIdWithSlug(productSlug) {
    return Object.keys(productIdMappedWithSlug).find(product_id => {
        return productIdMappedWithSlug[product_id].slug === productSlug
    })
}

export {
    // truncateString,
    CustomToolTrip,
    GetFirstNameAndLastName,
    ShortDescription,
    UserNameAvatar,
    ParseHtmlToString,
    requestFreeProposalPath,
    GetAvatar,
    htmlParser
}