import React, {Component, Fragment} from "react";
import {errorActionDispatchers} from "../../actions/errors";
import connect from "react-redux/es/connect/connect";
import {apiv1} from "../../api";
import {getAvatar} from "../common/userAndProfessionalProfileFunctions";
// import {Link} from "react-router-dom";
// import Moment from 'react-moment';
import localStorage from "../../utils/localStorage"
import {PROFESSIONAL, USER} from "../../constants/auth";
// import JobPaymentSummary from '../common/JobPaymentSummary'
import JobInProgressDetailsPageHeaderLink from '../common/JobInProgressDetailsPageHeaderLink'
import LoadingComponent from "../../utils/LoadingComponent";
// import * as path from "../../constants/path";
// import {assignTaskToThisProfessional} from './../../constants/user'
import {InstallAppText, UserAndProfessionalCardOnJobInProgress} from "./../professional/ProfessionalJobDetails";
import JobPaymentSummary, {EditDeliveryTime} from '../common/JobPaymentSummary'
import $ from 'jquery'
import moment from "moment";
import * as path from "../../constants/path";


export function EditDeliveryTimeBox({expectedDeliveryDate, roomId}) {
    return (
        <Fragment>
        <div className="side-box-container">
            <p className="heading-text">Delivery Info <a className="btn btn-link pull-right">
                {/* See timeline */}
            </a></p>
            <h4 className="delivery-time">
                {moment(expectedDeliveryDate).format('Do')}<br/><span>
                                {moment(expectedDeliveryDate).format('MMMM')} {moment(expectedDeliveryDate).format('YYYY')}
                                    </span>
            </h4>
            {localStorage.userType === PROFESSIONAL &&
            <div className="text-center">
                <a className="btn btn-edit-delivery-time"
                   onClick={() => {
                       $('#edit_delivery_time').click()
                   }}
                >Edit Delivery Time</a>
            </div>
            }
        </div>
            <div className="side-box-container">
                <p className="heading-text">Need Help?</p>
                <div className="text-center">
                    <a className="btn btn-edit-delivery-time" href={`${path.raiseSupportTicket}?roomId=${roomId}`}>Need Workbeam help?</a>
                </div>
            </div>
        </Fragment>
    )
}

class DeliveryInfo extends Component {
    constructor(props) {
        super(props);
        const {chatRoomId} = props.match.params
        this.state = {
            jobDetails: {},
            userAndProfessionalData: {},
            userData: {},
            loading: false,
            jobStatus: '',
            jobTitle: '',
            productId: '',
            chatRoomId
        }
    }

    componentDidMount() {
        this.getJobDetails()
        this.getJobQuestionsOfJob()
    }

    getJobQuestionsOfJob = () => {
        const {jobId} = this.props.match.params;
        const url = `/job/job-details-hiring-summary/${jobId}/`;
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    const {job_question, title} = data
                    let quantity_count = title.split(':')
                    quantity_count = isNaN(quantity_count[quantity_count.length - 1]) ? 1 : quantity_count[quantity_count.length - 1]
                    const userAndProfessionalData = {
                        ...this.state.userAndProfessionalData,
                        job_question_detail: job_question,
                        quantity_count
                    }
                    this.setState({userAndProfessionalData})
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.data.client.id !== undefined) {
            this.setState({userData: nextProps.data})
        }
    }

    updateAmountOnJobPaymentSummary = (updatedPaymentTerms) => {
        const {userAndProfessionalData} = this.state
        const updatedJobDetails = Object.assign({}, userAndProfessionalData, updatedPaymentTerms)
        this.setState({userAndProfessionalData: updatedJobDetails})
    }
    upDateJobInfo = ({delivery_time_history}) => {
        let {jobDetails} = this.state
        delivery_time_history = delivery_time_history || []
        jobDetails = {
            ...jobDetails,
            delivery_time_history
        }
        this.setState({jobDetails})
    }

    getJobDetails = (e) => {
        const {jobId, proposalId} = this.props.match.params;
        const url = `/job/proposal-user-professional/${proposalId}/`
        this.setState({loading: true})
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    const {job_details} = data
                    const {product} = job_details
                    let userAndProfessionalData = {
                        ...this.state.userAndProfessionalData,
                        ...data
                    }
                    this.setState({
                        loading: false,
                        userAndProfessionalData,
                        jobDetails: job_details,
                        jobStatus: job_details.status,
                        jobTitle: job_details.title,
                        productId: product,
                        selectedDate: moment(job_details.completed_date_expected)
                    })

                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    };


    render() {
        const {jobId, proposalId, userId, chatRoomId} = this.props.match.params;
        const {userAndProfessionalData, loading, jobStatus, jobTitle, jobDetails, productId, selectedDate} = this.state
        const {Professional_details, user_details, job_details, quantity_count} = userAndProfessionalData || {}
        let avatar = '';
        let firstName = '';
        let lastName = '';
        if (localStorage.userType === USER && Professional_details) {
            avatar = getAvatar(Professional_details.avatar_thumbnail);
            firstName = Professional_details.first_name;
            lastName = Professional_details.last_name
        } else if (localStorage.userType === PROFESSIONAL && Professional_details) {
            avatar = getAvatar(userAndProfessionalData.user_details.client.avatar_thumbnail);
            firstName = user_details.first_name;
            lastName = user_details.last_name
        }
        if (loading) return <div className='lazy-loader' style={{position: 'fixed', zIndex: '100'}}><LoadingComponent pastDelay/></div>

        return (
            <div id="PROGRESS_JOB">
                <div id="progress_list_case" className="ui-progress-job">
                    <div className="col-sm-9  job_title">
                        <div className="row">
                            {jobStatus && <JobInProgressDetailsPageHeaderLink jobId={jobId}
                                                                              proposalId={proposalId}
                                                                              userId={userId}
                                                                              pageName="deliveryInfo"
                                                                              props={this.props}
                                                                              jobStatus={jobStatus}
                                                                              jobTitle={jobTitle}
                                                                              productId={productId}
                                                                              chatRoomId={chatRoomId}
                                                                              updateAmountOnJobPaymentSummary={this.updateAmountOnJobPaymentSummary}
                                                                              userAndProfessionalData={userAndProfessionalData}
                            />
                            }
                        </div>
                        <div className="row scroll box-shadow"
                             style={{backgroundColor: '#fff', minHeight: '580px', borderRadius: '6px'}}>
                            <div className="col-sm-12">
                                <h4 className="heading-onboarding text-center">Delivery Timeline</h4>
                            </div>
                            <p>&#x00A0;</p>
                            <div className="col-sm-6 col-sm-offset-3">
                                <div className="qa-message-list how-it-work delivery-status" id="wallmessages">
                                    <div className="message-item" id="m4">
                                        <div className="clearfix">
                                            <div>
                                                <p className="para" style={{
                                                    color: '#000',
                                                }}>Booking date
                                                    <span>
                                                        {moment(jobDetails.in_progress_date).format('D MMM YYYY')}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="message-item" id="m4">
                                        <div className="clearfix">
                                            <div>
                                                <p className="para" style={{
                                                    color: '#000',
                                                }}>Delivery
                                                    Time <span> {userAndProfessionalData.schedule_unit_proposed ? `${userAndProfessionalData.schedule_unit_proposed} days`: '-'}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="message-item" id="m4">
                                        <div className="clearfix">
                                            <div>
                                                <p className="para" style={{
                                                    color: '#000',
                                                }}>Delivery Date <span>
                                                        {moment(jobDetails.completed_date_expected).format('D MMM YYYY')}
                                                    </span></p>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        jobDetails.delivery_time_history !== null && jobDetails.delivery_time_history !== undefined &&
                                        jobDetails.delivery_time_history.map(data => {
                                            return (
                                                <div className="message-item" id="m4">
                                                    <div className="clearfix">
                                                        <div>
                                                            <p className="para" style={{
                                                                color: '#000',
                                                            }}>Next Delivery Time <span>
                                                                    {moment(data.date).format('D MMM YYYY')}
                                                                </span></p>
                                                            <p className="para" style={{
                                                                color: '#000', marginTop: '-10px'
                                                            }}><b>
                                                                {data.reason}
                                                            </b>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3 no-padding d-padding-r">
                        {jobDetails.title !== undefined && <JobPaymentSummary jobId={jobId} proposalId={proposalId}
                                                                              totalAmount={userAndProfessionalData.fixed_amount}
                                                                              jobStatus={jobDetails.status}
                                                                              proposalSender={userAndProfessionalData.sender}
                                                                              wcFees={userAndProfessionalData.commission}
                                                                              wcGst={userAndProfessionalData.commission_tax}
                                                                              gstAmount={userAndProfessionalData.professional_amount_tax ? userAndProfessionalData.professional_amount_tax : 0}
                                                                              actual_amount_enter_by_professional={userAndProfessionalData.actual_amount_enter_by_professional}
                                                                              userAndProfessionalData={userAndProfessionalData}
                                                                              updateDeliveryInfo={this.upDateJobInfo}
                        />}
                        <div className="side-box-container">
                            {userAndProfessionalData.sender !== undefined &&
                            <UserAndProfessionalCardOnJobInProgress userAndProfessionalData={userAndProfessionalData}
                                                                    firstName={firstName} lastName={lastName}
                                                                    avatar={avatar}/>}
                        </div>
                        {/* <InstallAppText/> */}
                    </div>
                </div>
                <p id="update_profile_msg" data-toggle="modal" data-target="#consultation_fee"></p>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    data: state.data,
});

const mapDispatchToProps = (dispatch) => ({
    ...(errorActionDispatchers(dispatch)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DeliveryInfo)