import React, {Component, Fragment} from 'react'
import Dropzone from 'react-dropzone'
import {apiv1, App_env} from "../../api";
import * as apiUrl from "../../api/apiUrls";
import {messageType} from './../../constants/common'
import {showSnackbar} from "../functional/afterHire";
import firebase from "firebase"
import {firebaseConfig} from './../../constants/common'
import {ProgressBar} from "../professional/ProfessionalProfileProgressBar";
import {errorActionDispatchers} from "../../actions/errors";
import connect from "react-redux/es/connect/connect";

const firebaseStorageRef = process.env.REACT_APP_ENV === App_env.production ? `room_` : `testing_`
firebase.initializeApp(firebaseConfig);
let storageRef = firebase.storage().ref();


class UploadDocuments extends Component {

    handleDrop = (files, self) => {
        let {jobId, proposalId, productId, consultationId, socket, setLoading, needToSendOnChat, senderId, receiverId, isOnlyForGeneratingBase64, updateDocuments, doDispatch, chatRoomId, isGeneratingDocPreview} = self.props
        jobId = jobId || ''
        proposalId = proposalId || ''
        productId = productId || ''
        files.map((file, index) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                if (isGeneratingDocPreview){
                    file = Object.assign(file, {
                        doc_preview: URL.createObjectURL(file)
                    })
                }
                const {result} = reader
                const {name, type} = file
                let document_base64 = result || ''
                document_base64 = document_base64.split(',')[1]
                let documentType = type.split('/')
                if (!document_base64) {
                    alert(`${name} is not a valid type document`);
                    return
                }
                let apiPath = ''
                let request = {
                    document_base64,
                    document_file_name: name,
                    documentType: documentType[0]
                }
                if (isGeneratingDocPreview){
                    const {doc_preview} = file
                    request = {
                        ...request,
                        doc_preview
                    }
                }
                if (jobId) {
                    apiPath = apiUrl.jobDocument
                    request.job = jobId
                }
                if (proposalId) {
                    apiPath = apiUrl.proposalDocument
                    request.proposal = proposalId
                } else if (productId) {
                    apiPath = apiUrl.customProductSample
                    request.custom_product = productId
                } else if (consultationId) {
                    apiPath = apiUrl.consultationDocument
                    request.consult = consultationId
                } else if (chatRoomId) {
                    apiPath = apiUrl.jobDocument
                    request.room = chatRoomId
                }
                if (isOnlyForGeneratingBase64) {
                    if (updateDocuments) {
                        updateDocuments(
                            {
                                documentArray: [{...request}],
                            }
                        )
                    }
                    return
                }
                if (setLoading) {
                    setLoading()
                }
                apiv1.auth.post(apiPath, request)
                    .then(resp => {
                        const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                        if (data) {
                            const {document} = data
                            setLoading(data)
                            if (needToSendOnChat) {
                                if (!jobId && chatRoomId) {
                                    jobId = 'None'
                                }
                                let message = `/job/${jobId}/document/${data.id};;${name},,${documentType[1]}`
                                message += `::;;,,..`
                                message += document
                                let socketDocument = {
                                    type: messageType.document_message,
                                    message,
                                    message_id: data.id,
                                    job_id: jobId,
                                    sender: senderId,
                                    receiverId: receiverId,
                                }
                                if (socket && socket.isReady()) {
                                    socket.socket.send(JSON.stringify(socketDocument))
                                }
                            }
                            if (consultationId && updateDocuments) {
                                updateDocuments({documentArray: [{...data}], hasDocumentFetched: true})
                                return
                            }
                            if (updateDocuments) {
                                updateDocuments({documentArray: [{...data}]})
                            }
                            if (index === files.length - 1) {
                                showSnackbar({message: `Uploaded`})
                            }
                        } else {
                            setLoading()
                        }
                    })
                    .catch(err => {
                        apiv1.handleErrors(err, doDispatch);
                        setLoading()
                    })
            }
        })
    }

    render() {
        const {children, inputTypes, multiple} = this.props
        return (
            <Dropzone
                onDrop={(files) => {
                    this.handleDrop(files, this)
                }}
                multiple
            >
                {({getRootProps, getInputProps}) => (
                    <div {...getRootProps({onClick: evt => evt.preventDefault()})}>
                        <input {...getInputProps()}
                               accept={`${inputTypes ?  inputTypes : `/*`}`}
                               multiple={!multiple}
                        />
                        {children}
                    </div>
                )}
            </Dropzone>
        )
    }
}

class FirebaseDocumentUploaderComp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isUploading: false,
            progress: 0,
        }
    }

    sendSocketDataViaRoomId = ({data}) => {
        const {type, message} = data
        const {chatRoomId, updateChatHistory, doDispatch} = this.props
        const request = {
            room: chatRoomId,
            chat: {
                message,
                message_type: type
            }
        }
        apiv1.auth.post(apiUrl.insertSocketData, request)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                if (updateChatHistory) {
                    updateChatHistory({newSocketData: data})
                }

            })
            .catch(err => {
                apiv1.handleErrors(err, doDispatch)
            })
    }

    postToAwsServer = ({document_firebase_url, filename}) => {
        let apiPath = apiUrl.jobDocument
        let {jobId, updateDocuments, socket, needToSendOnChat, chatRoomId, doDispatch, senderId, receiverId, basicRequestParams} = this.props
        let request = {
            ...basicRequestParams,
            room: chatRoomId,
            document_firebase_url,
            document_base64: null,
            document_file_name: filename
        }
        if (jobId) {
            request = {
                ...request,
                job: jobId
            }
        }
        let documentType = filename.split('.')
        documentType = documentType[documentType.length - 1]
        apiv1.auth.post(apiPath, request)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                if (data) {
                    const {id, document_firebase_url} = data
                    if (needToSendOnChat) {
                        if (!jobId && chatRoomId) {
                            jobId = 'None'
                        }
                        let message = `/job/${jobId}/document/${id};;${filename},,${documentType}`
                        message += `::;;,,..`
                        message += document_firebase_url
                        let socketDocument = {
                            type: messageType.document_message,
                            message,
                            message_id: id,
                            job_id: jobId,
                            sender: senderId,
                            receiverId,
                        }
                        if (socket && socket.isReady()) {
                            socket.socket.send(JSON.stringify(socketDocument))
                        } else {
                            this.sendSocketDataViaRoomId({data: socketDocument})
                        }
                    }
                    this.setState({isUploading: false})
                    if (updateDocuments) {
                        updateDocuments({documentArray: [{...data}]})
                    }
                }
            })
            .catch(err => {
                this.setState({isUploading: false})
                apiv1.handleErrors(err, doDispatch);
            })
    }

    handleDrop = (files, self) => {
        let {chatRoomId} = self.props
        files.map((file, index) => {
            let {name} = file
            let timeMillisecond = new Date().getUTCMilliseconds()
            let uploadTask = storageRef.child(`${firebaseStorageRef}${chatRoomId}`).child(`${timeMillisecond}_${name}`).put(file);
            const {STATE_CHANGED} = firebase.storage.TaskEvent
            const {PAUSED, RUNNING} = firebase.storage.TaskState
            this.setState({isUploading: true})
            uploadTask.on(STATE_CHANGED,
                function (snapshot) {
                    const {state, bytesTransferred, totalBytes} = snapshot
                    let progress = (bytesTransferred / totalBytes) * 100;
                    progress = parseInt(progress)
                    self.setState({progress})
                    switch (state) {
                        case PAUSED:
                            console.log('Upload is paused');
                            break;
                        case RUNNING:
                            console.log('Upload is running');
                            break;
                    }
                }, function (error) {

                    switch (error.code) {
                        case 'storage/unauthorized':
                            break;

                        case 'storage/canceled':
                            break;

                        case 'storage/unknown':
                            break;
                    }
                }, function () {
                    uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                        self.postToAwsServer({document_firebase_url: downloadURL, filename: name})
                    });
                });
        })
    }

    render() {
        const {children} = this.props
        const {progress, isUploading} = this.state
        return (
            <Fragment>
                <form>
                    {isUploading &&
                    <div className="doc-upload-processbar text-center">
                        {progress}%
                        <ProgressBar percentage={progress}/>
                    </div>
                    }
                    <Dropzone
                        onDrop={(files) => {
                            this.handleDrop(files, this)
                        }}
                        multiple
                    >
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps({onClick: evt => evt.preventDefault()})}>
                                <input {...getInputProps()} id='upload_document'/>
                                {children}
                            </div>
                        )}
                    </Dropzone>
                </form>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch) => ({
    ...(errorActionDispatchers(dispatch)),
});

let FirebaseDocumentUploader = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FirebaseDocumentUploaderComp)
export {
    FirebaseDocumentUploader,
    UploadDocuments
}
