import React from "react";
import {profession} from '../constants/job'
import {baseUrl} from "../constants/common"
import {siteLogoOnSharedMedia} from "../components/common/bannerTextForProducts"
import Header from "../components/functional/beforeHire"


// let baseUrl = siteBaseUrl.substring(0, siteBaseUrl.length - 1)
function getMetaTag(professionalType, partialUrl, citySlug) {
    let title = ''
    let description = ''
    let url = `${baseUrl}/${partialUrl}/${citySlug}`
    if (professionalType === profession["1"].text) {
        title = `Find Chartered Accountants in ${citySlug} | Find CA in ${citySlug} | Workbeam`
        description = `Looking for a CA in ${citySlug}? Find verified Chartered Accountants in ${citySlug} on Workbeam. Hire for Tax Filing, Tax Consultation, GST filing and Accounting Services.`
    } else if (professionalType === profession["2"].text) {
        title = `Find Lawyers in ${citySlug} | Hire verified Advocates in ${citySlug} | Workbeam`
        description = `Looking for Lawyers in ${citySlug}? Find verified Lawyers in ${citySlug} on Workbeam. Hire for Legal Advice, Legal Notice, Legal Drafting or Court Representation`
    } else {
        title = `Hire freelance Accountants & Bookkeeper in ${citySlug} | Workbeam`
        description = `Looking for an Accountant or a Bookkeeper in ${citySlug}? Find verified freelance Accountants in ${citySlug} on Workbeam. Hire for Tax Filing, Tax Consultation, GST filing and Accounting Services.`
    }

    return (
        [<Header link={url} avatar={siteLogoOnSharedMedia} metaDescription={description} title={title}/>]
    )
}


export const cityDetailsWithSlug = {
    'new-delhi': {
        id: 707,
        name: "Delhi",
        slug: "new-delhi",
        meta_tag: (professionalType, citySlug, partialUrl) => getMetaTag(professionalType, citySlug, partialUrl)
    },
    bengaluru: {
        id: 1558,
        name: "Bengaluru",
        slug: "bengaluru",
        meta_tag: (professionalType, citySlug, partialUrl) => getMetaTag(professionalType, citySlug, partialUrl)
    },
    ahmedabad: {
        id: 783,
        name: "Ahmedabad",
        slug: "ahmedabad",
        meta_tag: (professionalType, citySlug, partialUrl) => getMetaTag(professionalType, citySlug, partialUrl)
    },
    chennai: {
        id: 3659,
        name: "Chennai",
        slug: "chennai",
        meta_tag: (professionalType, citySlug, partialUrl) => getMetaTag(professionalType, citySlug, partialUrl)
    },
    mumbai: {
        id: 2707,
        name: "Mumbai",
        slug: "mumbai",
        meta_tag: (professionalType, citySlug, partialUrl) => getMetaTag(professionalType, citySlug, partialUrl)
    },
    hyderabad: {
        id: 4460,
        name: "Hyderabad",
        slug: "hyderabad",
        meta_tag: (professionalType, citySlug, partialUrl) => getMetaTag(professionalType, citySlug, partialUrl)
    },
    kolkata: {
        id: 5583,
        name: "Kolkata",
        slug: "kolkata",
        meta_tag: (professionalType, citySlug, partialUrl) => getMetaTag(professionalType, citySlug, partialUrl)
    },
}
export default cityDetailsWithSlug