import React, {Component, Fragment} from "react";
import * as path from "../../constants/path";
import {isPhoneValid, onlyZeroToNine, validateProfile} from "../common/validation/FormValidator";
import {apiv1} from "../../api";
import {USER} from "../../constants/auth";
import localStorage from "../../utils/localStorage";
import {postJob, setLocalStorage} from "./UserSignUp";
import $ from "jquery";
import * as apiUrl from "../../api/apiUrls";
import LoadingComponent from "../../utils/LoadingComponent";
import axios from 'axios'
import PhoneInput, {getCountryCallingCode, parsePhoneNumber} from "react-phone-number-input";
import 'react-phone-number-input/style.css'
import {indiaCallingCode, backendErrorCode, customBackendErrorText} from '../../constants/common'
// import {signUpAndChat, signUp} from "../services/BusinessFormationPvtLtd";
import {otpInputFields, otpInputArrayFields} from "../common/LoginWithPhone";
import {getProfile} from "../../reducers/auth";
import {userDetails} from "../../actions/auth"
import {showSnackbar} from "../functional/afterHire"

class OnboardingSignup extends Component {

    constructor(props) {
        super(props)
        let {city} = props
        city = city || []
        const referral_code = localStorage.referralCode ? localStorage.referralCode : ''
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            popupEmail: '',
            password1: '',
            password: '',
            popupPassword: '',
            loading: false,
            errors: {},
            phone: '',
            countryCallingCode: indiaCallingCode,
            popupPhone: '',
            geoCity: city,
            cityId: '',
            company_name: '',
            gst_number: '',
            isSignUp: true,
            referral_code,
            isValidReferralCode: false,
            firebaseVerificationId: '',
            otpInput: otpInputArrayFields,
            isOtpSent: false,
        }
    }

    componentDidMount() {
        this.updateParent()
        const {geoCity} = this.state
        if (geoCity.length === 0) {
            this.getCity()
        }
    }

    updateParent = () => {
        const {updateState, name} = this.props
        if (updateState) {
            updateState({name})
        }
    }

    getCity = () => {
        const {doDispatch} = this.props
        apiv1.noAuth.get(apiUrl.geoCity)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                if (data) {
                    this.setState({geoCity: data})
                }
            })
            .catch(err => {
                apiv1.handleErrors(err, doDispatch)
            })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {city} = nextProps
        const {geoCity} = this.state
        if (geoCity.length === 0 && city.length !== 0) {
            this.setState({geoCity: city})
        }
    }

    saveCity = ({cityId}) => {
        const {company_name, gst_number} = this.state
        const request = {
            client: {
                company_name,
                gst_number,
                address: {
                    city: cityId
                }
            }
        }
        apiv1.auth.patch(apiUrl.restAuth, request)
            .then(res => {
                const data = apiv1.parseAndAutoHandleIssues(res, this.props.doDispatch);
                if (data) {
                    const {history} = this.props
                    const {location} = history
                    let {search} = location
                    search = `${search.indexOf('?') > -1 ? `${search}&cityId=${cityId}` : `${search}?cityId=${cityId}`}`
                    this.postQuery({component: this, historySearchObj: search, userAndProData: data})
                }
            })
            .catch(err => {
                this.setState({loading: false})
                apiv1.handleErrors(err, this.props.doDispatch);
            })
    }

    onSubmit = () => {
        let {first_name, last_name, email, password1, phone, cityId, countryCallingCode} = this.state
        phone = phone || ''
        const {doDispatch, setLoading, isHidingPasswordAndOptField, history} = this.props
        const {nationalNumber} = parsePhoneNumber(phone) || {}
        let apiPath = apiUrl.userAndProRegistration
        let request = {
            username: email,
            first_name,
            last_name,
            email,
            phone,
            is_professional: false,
            country_code: countryCallingCode || indiaCallingCode,
        }
        let validatorObj = {
            ...request,
            phone: nationalNumber || '',
            address: {
                state: (countryCallingCode === indiaCallingCode && !isHidingPasswordAndOptField ) ? cityId : 'dummy'
            }
        }
        if (!isHidingPasswordAndOptField){
            request = {
                ...request,
                password1,
                password2 : password1
            }
            validatorObj = {
                ...validatorObj,
                password1: 'dummypass',
                password2: 'dummypass'
            }
        } else {
            const {location} = history
            const {pathname, search} = location
            request.path = `${pathname}${search}`
            apiPath = apiUrl.createClientType
        }

        let errors = {};
        const isFormValidated = validateProfile(errors, validatorObj)
        if (isFormValidated) {
            if (setLoading) {
                setLoading()
            }
            this.setState({loading: true})
            // request.phone = phone
            apiv1.noAuth.post(apiPath, request)
                .then((resp) => {
                    // this.setState({ loading: false });
                    const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                    if (data) {
                        const {key} = data
                        setLocalStorage({auth_token: key, USER, first_name, last_name})
                        window.userSignUpRecord()
                        if (cityId) {
                            this.saveCity({cityId})
                        } else {
                            const {history} = this.props
                            const {location} = history
                            const {search} = location
                            this.switchAsClientProfile({search})
                        }
                        showSnackbar({message: 'Sign up successful', timeout: 3000})
                    } else {
                        this.setState({loading: false})
                    }
                })
                .catch((err) => {
                    let {response} = err
                    response = response || {}
                    let {status} = response
                    if (status === backendErrorCode["500"]){
                        errors = {
                            ...errors,
                            username: customBackendErrorText.user_already_exits
                        }
                    }
                    const resErrors = apiv1.handleErrors(err, doDispatch)
                    for (let key in resErrors) {
                        errors[key] = resErrors[key][0]
                    }
                    this.setState({loading: false, errors, password1: ''}, () => {
                        if (setLoading) {
                            setLoading()
                        }
                    })
                })
        } else {
            this.setState({loading: false, errors})
        }
    };

    restAuthPatchForUserTypeSwitch = ({request}) => {
        const {doDispatch} = this.props
        return apiv1.auth.patch(apiUrl.restAuth, request)
            .then(resp => {
                const resData = apiv1.parseAndAutoHandleIssues(resp, doDispatch)
                return {
                    resData
                }
            })

    }

    switchAsClientProfile = ({search}) => {
        const request = {
            client: {
                last_session_as_client: true
            }
        }
        const self = this
        return axios.all([this.restAuthPatchForUserTypeSwitch({request})])
            .then(resp => {
                if (resp) {
                    const {resData} = resp[0] || {}
                    self.postQuery({historySearchObj: search, userAndProData: resData})
                }
            })
            .catch(err => {
                apiv1.handleErrors(err, self.props.doDispatch);
                self.setState({loading: false})
            })
    }

    postQuery = ({historySearchObj, userAndProData}) => {
        const {history, isFromForum, isFromJobPost, postQuery, updateProfileAndPostJob, isFromDirectHire, doDispatch} = this.props
        const {location} = history
        const {search} = location
        getProfile(userAndProData)
        doDispatch(userDetails(userAndProData))
        if ($('#direct_hire_signup')) {
            $('#direct_hire_signup .close').click()
        }
        if (isFromJobPost) {
            postJob({component: this, historySearchObj: historySearchObj ? historySearchObj : search})
        } else if (isFromForum && postQuery) {
            postQuery({})
        } else if (isFromDirectHire && updateProfileAndPostJob) {
            if (updateProfileAndPostJob) {
                updateProfileAndPostJob({data: userAndProData})
            }
        }
    }

    fetchUserAndProData = ({authToken}) => {
        const {history, doDispatch, setLoading} = this.props
        apiv1.auth.get(apiUrl.restAuth).then(resp => {
            const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
            if (data) {
                const {first_name, last_name, client} = data
                const {is_default_professional, last_session_as_client} = client
                setLocalStorage({auth_token: authToken, USER, first_name, last_name})
                const {location} = history
                const {search} = location
                if (is_default_professional && !last_session_as_client) {
                    this.switchAsClientProfile({search})
                } else {
                    this.postQuery({historySearchObj: search, userAndProData: data})
                }
            } else {
                this.setState({loading: false})
            }
        })
            .catch(err => {
                this.setState({loading: false}, () => {
                    if (setLoading) {
                        setLoading()
                    }
                });
                apiv1.handleErrors(err, doDispatch)
            })
    }

    nextTask = ({key}) => {
        setLocalStorage({auth_token: key, USER, first_name: '', last_name: ''})
        this.fetchUserAndProData({authToken: key})
    }

    onSignInClick = () => {
        const {doDispatch, setLoading} = this.props
        const {popupEmail, popupPassword} = this.state;
        let request = {
            popupEmail,
            popupPassword
        }
        const errors = {};
        const isFormValidated = validateProfile(errors, request)
        if (isFormValidated === true) {
            request = {
                email: popupEmail,
                password: popupPassword,
            }
            if (setLoading) {
                setLoading()
            }
            this.setState({loading: true})
            apiv1.noAuth.post(apiUrl.restAuthLogin, request)
                .then((resp) => {
                    const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                    if (data) {
                        const {key} = data
                        this.nextTask({key})
                    } else {
                        this.setState({loading: false})
                    }
                })
                .catch((err) => {
                    const resErrors = apiv1.handleErrors(err, doDispatch);
                    for (let key in resErrors) {
                        errors[key] = resErrors[key][0]
                    }
                    this.setState({loading: false, errors, email: '', password: ''}, () => {
                        if (setLoading) {
                            setLoading()
                        }
                    })
                })
        } else {
            this.setState({loading: false, errors})
        }

    };

    checkValidityForReferralCode = () => {
        const {referral_code, errors} = this.state
        const {doDispatch} = this.props
        this.setState({loading: true})
        apiv1.noAuth.post(`/users/referral-code-valid`, {referral_code})
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                if (data) {
                    const isValidReferralCode = true
                    const {referral_code} = data
                    localStorage.referralCode = referral_code
                    this.setState({isValidReferralCode, loading: false})
                }
            })
            .catch(err => {
                const data = apiv1.parseAndAutoHandleIssues(err.response, doDispatch)
                apiv1.handleErrors(err, doDispatch)
                let {isValidReferralCode} = this.state
                if (data) {
                    const {Error} = data
                    if (Error) {
                        isValidReferralCode = false
                        errors['referral_code'] = Error
                    }
                }
                this.setState({loading: false, errors, isValidReferralCode})
            })
    }

    // signInWithPhoneNo = () => {
    //     const {firebaseVerificationId, popupPhone} = this.state
    //     const {doDispatch, setLoading} = this.props
    //     const errors = {}
    //     let path = `${apiUrl.userLoginWithPhone}${popupPhone}/${firebaseVerificationId}/web`
    //     if (setLoading) {
    //         setLoading()
    //     }
    //     this.setState({loading: true})
    //     apiv1.noAuth.get(path)
    //         .then((resp) => {
    //             const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
    //             if (data) {
    //                 const {key} = data
    //                 this.nextTask({key})
    //             } else {
    //                 this.setState({loading: false})
    //             }
    //         })
    //         .catch((err) => {
    //             const resErrors = apiv1.handleErrors(err, doDispatch);
    //             for (let key in resErrors) {
    //                 errors[key] = resErrors[key][0]
    //             }
    //             this.setState({
    //                 loading: false,
    //                 errors,
    //                 email: '',
    //                 password: '',
    //                 otpInput: otpInputFields
    //             }, () => {
    //                 if (setLoading) {
    //                     setLoading()
    //                 }
    //             })
    //         })
    // }

    // verifyFirebaseOtp = () => {
    //     let {otpInput} = this.state;
    //     const self = this
    //     let otp = otpInput.join('')
    //     this.setState({loading: true})
    //     window.confirmationResult.confirm(otp).then(resp => {
    //         self.signInWithPhoneNo()
    //     })
    //         .catch(err => {
    //             this.setState({loading: false, otpInput: otpInputFields});
    //             apiv1.handleErrors(err, this.props.doDispatch)
    //         })
    // };

    // authenticatePhoneNo = (phNo) => {
    //     const errors = {};
    //     const self = this;
    //     this.setState({loading: true})
    //     firebase.auth().signInWithPhoneNumber(phNo, window.recaptchaVerifier)
    //         .then(function (confirmationResult) {
    //             const {verificationId} = confirmationResult
    //             $('.otp-box').removeClass('hidden');
    //             self.setState({loading: false, isOtpSent: true, firebaseVerificationId: verificationId}, () => {
    //
    //             })
    //             window.confirmationResult = confirmationResult;
    //         }).catch(function (error) {
    //         if (error.message !== undefined) {
    //             errors['popupPhone'] = error.message;
    //             self.setState({loading: false, isOtpSent: true, errors, otpInput: [[], [], [], [], [], []]})
    //         }
    //     });
    // }
    //
    // firebaseOtp = (phNo) => {
    //     if (!firebase.apps[0]) {
    //         firebase.initializeApp(firebaseConfig)
    //     }
    //     if (window.recaptchaVerifier === undefined) {
    //         window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-onboarding', {
    //             'size': 'invisible',
    //             'callback': function (response) {
    //             }
    //         });
    //     }
    //     this.authenticatePhoneNo(phNo)
    // };

    verifyOtpWitCountryCodeApiOnWizcounsel = () => {
        const {popupPhone, otpInput, countryCallingCode} = this.state
        const {doDispatch, setLoading} = this.props
        let otp = otpInput.join('')
        const errors = {}
        let {nationalNumber} = parsePhoneNumber(popupPhone) || {}
        if (setLoading) {
            setLoading()
        }
        let request = {
            phone: nationalNumber,
            otp,
            country_code: countryCallingCode,
        }
        this.setState({loading: true})
        apiv1.noAuth.post(apiUrl.wizcounselVerifyOtpWithCountryCode, request)
            .then((resp) => {
                const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                if (data) {
                    const {key} = data
                    this.nextTask({key})
                } else {
                    this.setState({loading: false})
                }
            })
            .catch((err) => {
                const resErrors = apiv1.handleErrors(err, doDispatch);
                for (let key in resErrors) {
                    errors[key] = resErrors[key][0]
                }
                this.setState({
                    loading: false,
                    errors,
                    email: '',
                    password: '',
                    otpInput: otpInputFields
                }, () => {
                    if (setLoading) {
                        setLoading()
                    }
                })
            })
    }

    sendOtpOnWizcounsel = ({phone}) => {
        const {doDispatch, setLoading} = this.props
        const {countryCallingCode} = this.state
        let request = {
            phone,
            country_code: countryCallingCode
        };
        let errors = {}
        this.setState({loading: true})
        apiv1.noAuth.post(apiUrl.wizcounselSendOtpWithCountryCode, request)
            .then((resp) => {
                const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                if (data) {
                    this.setState({loading: false, isOtpSent: true}, () => {
                        if (document.getElementById(`${'otpInputBox'}${0}`)){
                            $(`#${'otpInputBox'}${0}`).focus()
                        }
                        $('.otp-box').removeClass('hidden');
                    })
                } else {
                    this.setState({loading: false})
                }
            })
            .catch((err) => {
                const resErrors = apiv1.handleErrors(err, doDispatch);
                for (let key in resErrors) {
                    errors[key] = resErrors[key][0]
                }
                this.setState({loading: false, errors}, () => {
                    if (setLoading) {
                        setLoading()
                    }
                })
            })
    }

    // firebaseOtp = (phNo) => {
    //     if (!firebase.apps[0]) {
    //         firebase.initializeApp({
    //             apiKey: "AIzaSyAUL5YjS9ICtkAXGIh6q-P0uO4fOZPpmo4",
    //             authDomain: "wizcounsel-mobile-app.firebaseapp.com",
    //             databaseURL: "https://wizcounsel-mobile-app.firebaseio.com",
    //             projectId: "wizcounsel-mobile-app",
    //             storageBucket: "wizcounsel-mobile-app.appspot.com",
    //             messagingSenderId: "51764281267"
    //         });
    //     }
    //     if (window.recaptchaVerifier === undefined) {
    //         window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-onboarding', {
    //             'size': 'invisible',
    //             'callback': function (response) {
    //
    //             }
    //         });
    //     }
    //     this.authenticatePhoneNo(phNo)
    // };

    // verifyPhone = () => {
    //     let {phone, countryCallingCode} = this.state;
    //     const errors = {};
    //     const {nationalNumber} = parsePhoneNumber(phone) || {}
    //     const message = 'Phone no is not valid';
    //     if (isPhoneValid(errors, 'phone', nationalNumber, message, countryCallingCode)) {
    //         // phone = '+91' + phone
    //         this.sendOtpOnWizcounsel({phone: nationalNumber})
    //         // this.firebaseOtp(phone)
    //     } else {
    //         this.setState({errors, loading: false})
    //     }
    //
    // };

    verifyPhone = () => {
        let {popupPhone, countryCallingCode} = this.state;
        let errors = {}
        const {nationalNumber} = parsePhoneNumber(popupPhone) || {}
        const message = 'Phone no is not valid';
        if (isPhoneValid(errors, 'popupPhone', nationalNumber, message, countryCallingCode)) {
            this.sendOtpOnWizcounsel({phone: nationalNumber})
            // this.firebaseOtp(popupPhone)
        } else {
            this.setState({errors, loading: false})
        }

    };

    onOtpChange = (e, index) => {
        const eventCode = e.which || e.keyCode
        const {value} = e.target
        if (this.getCodeBoxElement(index).value.length === 1) {
            if (index !== 5) {
                this.getCodeBoxElement(index + 1).focus()
            } else {
                this.getCodeBoxElement(index).blur()
                // Submit code

            }
        }
        if (eventCode === 8 && index !== 0) {
            this.getCodeBoxElement(index - 1).focus();
        }
        const {otpInput} = this.state
        otpInput[index] = value ? Number(value) : ''
        this.setState({otpInput})
    }

    getCodeBoxElement = (index) => {
        return document.getElementById('otpInputBox' + index);
    }

    onOtpFocus = (e, index) => {
        for (let item = 1; item < index; item++) {
            const currentElement = this.getCodeBoxElement(item);
            if (!currentElement.value) {
                currentElement.focus();
                break;
            }
        }
    }

    onChange = (e) => {
        const newErrors = {...this.state.errors};
        newErrors[e.target.name] = '';
        const {name, value} = e.target;
        let message = '';
        if (name === 'phone' || name === 'popupPhone') {
            message = 'Phone no is no valid';
            onlyZeroToNine(newErrors, name, value, message)
        }
        this.setState({[e.target.name]: e.target.value, errors: newErrors})
    };

    termsAndConditions = () => {
        return (
            <div className="col-sm-12">
                <h5 className="text-dark">By clicking Submit button you agree to our
                    <br/>
                    <b>
                        <a href={path.termsAndConditions} target="_blank" style={{color:'#ff337d'}}>Terms of Service</a> &amp;
                        <a href={path.privacyPolicy} style={{marginLeft: '5px', color:'#ff337d'}} target="_blank">Privacy policy</a>
                    </b>
                </h5>
            </div>
        )
    }

    signUpUi = () => {
        const {first_name, last_name, email, password1, errors, phone, geoCity, referral_code, isValidReferralCode, loading, company_name, gst_number, countryCallingCode} = this.state;
        const {isFromForum, submitRequest, signUpButtonName, isFromDirectHire, isHidingPasswordAndOptField} = this.props

        return (
            <div className="col-sm-12 no-padding">
                <div className="col-sm-12">
                    <h2 className="ui-sign-heading text-center">Sign Up Details</h2>
                </div>
                <div className="clearfix"></div>
                <div className={(errors.username) ? 'msg msg-danger msg-danger-text' : 'hidden'}>
                                    <span className="glyphicon glyphicon-remove pull-right"
                                          style={{cursor: 'pointer'}}
                                          onClick={() => this.setState({errors: {}})}
                                    >
                                    </span>
                    {errors.username}
                </div>
                <div className="clearfix"></div>
                <form className="signup-form" style={{marginTop: '10px'}}>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <input type="text"
                                   className="form-control input-lg"
                                   value={first_name}
                                   onChange={this.onChange}
                                   name="first_name" placeholder="First Name"
                                   style={{borderBottom: errors.first_name ? '1px solid #ff337d' : ''}}
                            />
                            <span className={errors.first_name ? 'error' : 'no-error'}>{errors.first_name}</span>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <input type="text"
                                   className="form-control input-lg"
                                   value={last_name}
                                   onChange={this.onChange}
                                   name="last_name"
                                   placeholder="Last Name"
                                   style={{borderBottom: errors.last_name ? '1px solid #ff337d' : ''}}
                            />
                            <span className={errors.last_name ? 'error' : 'no-error'}>{errors.last_name}</span>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className={countryCallingCode === indiaCallingCode ? 'col-sm-6' : 'col-sm-6'}>
                        <div className="form-group">
                            <PhoneInput
                                className="form-control input-lg"
                                name="phone"
                                value={phone}
                                international
                                countryCallingCodeEditable={false}
                                onChange={(phone) => {
                                    let e = {
                                        target: {
                                            name: 'phone',
                                            value: phone
                                        }
                                    }
                                    const newErrors = {...this.state.errors}
                                    newErrors[e.target.name] = ''
                                    const {name, value} = e.target
                                    this.setState({[name]: value, errors: newErrors})
                                }}
                                onCountryChange={(country) => {
                                    country = country || ''
                                    if (country) {
                                        let countryCallingCode = `+${getCountryCallingCode(country)}`
                                        let {cityId} = this.state
                                        cityId = countryCallingCode === indiaCallingCode ? cityId : ''
                                        this.setState({countryCallingCode, cityId})
                                    }
                                }}
                                placeholder="Mobile E.g. 99XXXXXX99 "
                                defaultCountry="CA"
                                style={{borderBottom: errors.phone ? '1px solid #ff337d' : ''}}
                            />
                            <span className={errors.phone ? 'error' : 'no-error'}>{errors.phone}</span>
                        </div>
                    </div>
                    {(countryCallingCode === indiaCallingCode && !isHidingPasswordAndOptField) &&
                    <div className="col-sm-6">
                        <div className="form-group">
                            <select className="form-control input-lg"
                                    onChange={(e) => {
                                        const {errors} = this.state
                                        errors['state'] = ''
                                        const cityId = e.target.value.toString()
                                        this.setState({cityId, errors})
                                    }}
                            >
                                <option value='-1' id='court_state_text' className='hidden'>City</option>
                                {geoCity.map(cityData => {
                                    const {id, name} = cityData
                                    return <option value={id} id={name}
                                                   key={id}>{name}</option>
                                })}
                            </select>
                            <span className={errors.state ? 'error' : 'no-error'}>{errors.state}</span>
                        </div>
                    </div>
                    }
                    <div className="col-sm-6">
                        <div className="form-group">
                            <input type="email"
                                   className="form-control input-lg"
                                   value={email}
                                   onChange={this.onChange}
                                   name="email"
                                   placeholder="Email"
                                   id='email'
                                   style={{borderBottom: errors.email ? '1px solid #ff337d' : ''}}
                            />
                            <span className={errors.email ? 'error' : 'no-error'}>{errors.email}</span>
                        </div>
                    </div>
                    {!isHidingPasswordAndOptField &&
                    <Fragment>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <input type="password"
                                       className="form-control input-lg"
                                       value={password1}
                                       onChange={this.onChange}
                                       name="password1"
                                       placeholder="Password"
                                       id='password'
                                       style={{borderBottom: errors.password1 ? '1px solid #ff337d' : ''}}
                                />
                                <span className={errors.password1 ? 'error' : 'no-error'}>{errors.password1}</span>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <input type="text"
                                       className="form-control input-lg"
                                       value={company_name}
                                       onChange={this.onChange}
                                       name="company_name" placeholder="Company Name (optional)"
                                       style={{borderBottom: errors.company_name ? '1px solid #ff337d' : ''}}
                                />
                                <span className={errors.company_name ? 'error' : 'no-error'}>{errors.company_name}</span>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <input type="text"
                                       className="form-control input-lg"
                                       value={gst_number}
                                       onChange={this.onChange}
                                       name="gst_number" placeholder="Gst Number (optional)"
                                       style={{borderBottom: errors.gst_number ? '1px solid #ff337d' : ''}}
                                />
                                <span className={errors.gst_number ? 'error' : 'no-error'}>{errors.gst_number}</span>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <input type="text"
                                       className="form-control input-lg"
                                       value={referral_code}
                                       onChange={this.onChange}
                                       name="referral_code" placeholder="Enter Referral Code (optional)"
                                       style={{borderBottom: errors.referral_code ? '1px solid #ff337d' : ''}}
                                />
                                <button type="button" className="btn btn-link"
                                        style={{marginTop: '0px', fontSize: '16px'}}
                                        disabled={loading}
                                        onClick={(e) => {
                                            if (referral_code) {
                                                this.checkValidityForReferralCode(e)
                                            }
                                        }}>Apply
                                </button>
                                <span
                                    className={errors.referral_code ? 'error' : 'no-error'}>{errors.referral_code}</span>
                                {isValidReferralCode && <span className="text-success">{'Applied'}</span>}
                            </div>
                        </div>
                    </Fragment>
                    }
                    {(isFromForum || isFromDirectHire) &&
                    <div className='text-center col-sm-12'>
                        <button
                            className="btn log-in-button btn-block btn-track-pay"
                            disabled={loading}
                            onClick={(e) => {
                                e.preventDefault()
                                submitRequest({onSignUp: this.onSubmit})
                            }}>
                            {signUpButtonName ? signUpButtonName : 'Submit'}
                        </button>
                    </div>
                    }
                </form>
                <div className="text-center" style={{marginTop:'15px'}}>
                    {this.termsAndConditions()}
                    <p className="para">Existing User?
                            <a style={{color:'#2035ef', marginLeft:'5px'}}
                                // disabled={loading}
                                    onClick={() => {
                                        let setState = {
                                            errors: {}
                                        }
                                        if (isFromForum || isFromDirectHire) {
                                            setState.isSignUp = false
                                        }
                                        this.setState(setState, () => {
                                            $(`#popupEmail`).blur()
                                            // $(`#popupPhone`).focus()
                                            if (isFromForum || isFromDirectHire) {
                                                return
                                            }
                                            // $('#onBoarding_login_with_phone').click()
                                            // this.updateParent()
                                        })
                                    }}

                            ><b>Login here</b></a>
                        </p>
                </div>
            </div>
        )
    }

    otpLogin = () => {
        const {errors, loading, popupEmail, popupPhone, popupPassword, isOtpSent} = this.state
        const {submitRequest, isFromForum, isFromDirectHire} = this.props
        return (
            <Fragment>
                <div className="col-sm-12">
                    <h4 className="ui-sign-heading text-center">Happy to see you logging in…</h4>
                </div>
                <p className="hidden-xs">&#x00A0;</p>
                <div className="row grid-divider col-divider">
                    <div className="col-sm-6">
                        <div className="otp-col-padding">
                            <h1 className="ui-sign-sub-heading text-center">Login with OTP </h1>
                            <form className="login-form">
                                <div className="form-group input-box">
                                    <PhoneInput
                                        className="form-control input-lg"
                                        name="popupPhone"
                                        international
                                        countryCallingCodeEditable={false}
                                        value={popupPhone}
                                        id='popupPhone'
                                        onChange={(popupPhone) => {
                                            let e = {
                                                target: {
                                                    name: 'popupPhone',
                                                    value: popupPhone
                                                }
                                            }
                                            const newErrors = {...this.state.errors}
                                            const {name, value} = e.target
                                            newErrors[name] = ''
                                            this.setState({[name]: value, errors: newErrors})
                                        }}
                                        onCountryChange={(country) => {
                                            country = country || ''
                                            if (country) {
                                                let countryCallingCode = `+${getCountryCallingCode(country)}`
                                                this.setState({countryCallingCode})
                                            }
                                        }}
                                        placeholder="Mobile E.g. 99XXXXXX99 "
                                        defaultCountry="IN"
                                        style={{borderBottom: errors.popupPhone ? '1px solid #ff337d' : ''}}
                                    />
                                    <span
                                        className={errors.popupPhone ? 'error' : 'no-error'}>{errors.popupPhone}</span>
                                </div>
                            </form>
                            <div className="clearfix"></div>
                            {isOtpSent &&
                            <div className="text-center">
                                {otpInputArrayFields.map((val, index) => {
                                    return <input className="otp_input" id={`otpInputBox${index}`}
                                                  onKeyUp={(e) => this.onOtpChange(e, index)}
                                                  onFocus={(e) => this.onOtpFocus(e, index)} maxLength="1"/>
                                })}
                            </div>
                            }
                            <div className="text-center">
                                <button
                                    className={`btn ${isOtpSent ? 'btn-link' : 'log-in-button btn-block'}`}
                                    disabled={loading}
                                    onClick={this.verifyPhone}
                                >{isOtpSent ? 'Resend' : 'Get OTP'}
                                </button>
                            </div>
                            {isOtpSent &&
                            <Fragment>

                                <div className="text-center">
                                    <button className="btn log-in-button btn-block"
                                            onClick={() => submitRequest({signInWithPhoneNo: this.verifyOtpWitCountryCodeApiOnWizcounsel})}
                                    >Login
                                    </button>
                                </div>
                            </Fragment>
                            }
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="otp-col-padding left-offset-1">
                            <h1 className="ui-sign-sub-heading text-center">Login with Email</h1>
                            <div className={(errors.non_field_errors) ? 'msg msg-danger msg-danger-text' : 'hidden'}>
                                        <span className="glyphicon glyphicon-remove pull-right"
                                              style={{cursor: 'pointer'}}
                                              onClick={() => this.setState({errors: {}})}
                                        >

                                        </span>
                                {errors.non_field_errors}
                            </div>
                            <div className="clearfix"></div>
                            <form className="login-form">
                                <div className="form-group">
                                    <input type="text"
                                           className="form-control input-lg"
                                           value={popupEmail}
                                           onChange={this.onChange}
                                           name="popupEmail"
                                           id='popupEmail'
                                           autoFocus
                                           style={{borderBottom: errors.popupEmail ? '1px solid #ff337d' : ''}}
                                           placeholder="Email"
                                    />
                                    {errors.popupEmail && <span className={'error'}>{errors.popupEmail}</span>}
                                </div>
                                <div className="clearfix"></div>
                                <div className="form-group">
                                    <input type="password"
                                           className="form-control input-lg"
                                           value={popupPassword}
                                           onChange={this.onChange}
                                           name="popupPassword"
                                           id='popupPassword'
                                           style={{borderBottom: errors.popupPassword ? '1px solid #ff337d' : ''}}
                                           placeholder="Password"
                                    />
                                    <span
                                        className={errors.popupPassword ? 'error' : 'no-error'}>{errors.popupPassword}</span>
                                    <a className="pull-right text-dark"
                                       style={{color: '#2c3af4'}}
                                       href={path.resetPasswordLink}
                                       target='_blank'
                                    >Forgot Password ?</a>
                                </div>
                            </form>
                            <div className="clearfix"></div>
                            <div className="text-center">
                                <button className="btn log-in-button btn-block"
                                        disabled={loading}
                                        onClick={() => submitRequest({onSignInWithEmail: this.onSignInClick})}
                                >Login
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
                <div className="row">
                    <div className="col-sm-12 text-center">
                        {(isFromForum || isFromDirectHire) &&
                        <p className="para">New User?
                            <a style={{color:'#2035ef', marginLeft:'5px'}}
                                // disabled={loading}
                               onClick={(e) => {
                                   e.preventDefault()
                                   this.setState({isSignUp: true})
                               }}
                            >
                                <b>Sign up</b>
                            </a>
                        </p>
                        }
                    </div>
                </div>
            </Fragment>

        )
    }

    logInUi = () => {
        const {errors, email, password, loading} = this.state
        return (
            <div className="col-sm-12">
                <div className="hidden-xs">
                    <h1 className="ui-sign-heading">Sign in to Workbeam.
                        <a onClick={() => {
                            this.setState({isSignUp: true, errors: {}}, () => {
                                this.updateParent()
                            })
                        }}
                           className="btn btn-link pull-right">SignUp</a>
                    </h1>
                    <h4 className="text-dark">Enter your details below.</h4>
                </div>
                <div className={(errors.non_field_errors) ? 'msg msg-danger msg-danger-text' : 'hidden'}>
                                        <span className="glyphicon glyphicon-remove pull-right"
                                              style={{cursor: 'pointer'}}
                                              onClick={() => this.setState({errors: {}})}
                                        >

                                        </span>
                    {errors.non_field_errors}
                </div>
                <div className="clearfix"></div>
                <form className="signup-form" style={{marginTop: '10px'}}>
                    <div className="form-group">
                        <input type="text"
                               className="form-control input-lg"
                               value={email}
                               onChange={this.onChange}
                               name="email"
                               id='email'
                               autoFocus
                               style={{borderBottom: errors.email ? '1px solid #ff337d' : ''}}
                               placeholder="Email"
                        />
                        <span className={(errors.email) ? 'error' : 'no-error'}>{errors.email}</span>
                    </div>
                    <div className="clearfix"></div>
                    <div className="form-group">
                        <input type="password"
                               className="form-control input-lg"
                               value={password}
                               onChange={this.onChange}
                               name="password"
                               id='password'
                               style={{borderBottom: errors.password ? '1px solid #ff337d' : ''}}
                               placeholder="Password"
                        />
                        <span className={errors.password ? 'error' : 'no-error'}>{errors.password}</span>
                        <a className="pull-right text-dark"
                           href={path.resetPasswordLink}
                           target='_blank'
                        >Forgot Password ?</a>
                    </div>
                </form>
            </div>
        )
    }

    loadingUi = () => {
        return (
            <div className='lazy-loader' style={{position: 'fixed', zIndex: '100'}}>
                <LoadingComponent pastDelay/>
            </div>
        )
    }

    render() {
        const {isSignUp, loading} = this.state
        const {isFromForum, isFromJobPost, isFromDirectHire} = this.props
        return (
            <div>
                {loading && this.loadingUi()}
                {(isFromForum || isFromDirectHire) && (
                    <Fragment>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <button type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            onClick={() => {
                                                this.setState({errors: {}, loading: false})
                                            }}
                                    >&times;</button>
                                    {(isSignUp ? this.signUpUi() : this.otpLogin())}
                                </div>
                                {isSignUp &&
                                <div className="modal-footer">
                                </div>
                                }
                            </div>
                        </div>
                    </Fragment>
                )}
                {isFromJobPost && (
                    <Fragment>
                        {this.signUpUi()}
                        <p id='onBoarding_login_with_phone' data-toggle="modal"
                           data-target="#login_with_otp_pop_up_on_onboarding">
                        </p>
                        <div className="modal center-modal" id="login_with_otp_pop_up_on_onboarding" role="dialog">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <button type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                onClick={() => {
                                                    this.setState({errors: {}, loading: false})
                                                }}
                                        >&times;</button>
                                        {this.otpLogin()}
                                    </div>
                                    <div className="modal-footer">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
        );
    }
}

export default OnboardingSignup