import {Component} from "react";
import React from "react";
import './../user/../../static/css/style.css'
import {withRouter} from 'react-router'
import LogInSignUpHeader from '../common/LogInSignUpHeader'
import {Footer} from "../common/Footer";
import {apiv1} from './../../api/index'
import {onlyZeroToNine, validateProfile} from '../common/validation/FormValidator'
import {HowItWorkSection} from './../common/AboutProfessionals'
import LoadingComponent from '../../utils/LoadingComponent'
import {registration} from "../../actions/auth";
import {errorActionDispatchers} from "../../actions/errors";
import connect from "react-redux/es/connect/connect";
import {userOnboarding} from "../../constants/path";
import localStorage, {KEY_AUTH_TOKEN, KEY_ONBOARDING_DATA} from "../../utils/localStorage";
import MetaTags from 'react-meta-tags';
import * as path from '../../constants/path'
import {USER} from "../../constants/auth";
import queryString from "query-string";
import {TRUE} from "./UserChatToProfessionalAfterHire";
import {siteLogoOnSharedMedia} from "../common/bannerTextForProducts";
import {baseUrl} from "../../constants/common";

export const userCarouselText = [
    ["DON'T WASTE TIME & MONEY ON EXPENSIVE FIRMS.",],
    ["INDIA'S FIRST LEGAL, ACCOUNTING & COMPLIANCE TECHNOLOGY PLATFORM."],
    ["DEDICATED PROFESSIONALS TO PERFORM YOUR WORK."]
]

export function setLocalStorage({auth_token, USER, first_name, last_name}) {
    if (auth_token) {
        localStorage.authToken = `Bearer ${auth_token}`
    }
    if (USER){
        localStorage.userType = USER
    }
    if (first_name){
        localStorage.firstName = first_name
    }
    if (last_name){
        localStorage.lastName = last_name
    }
}

export function deleteAuthToken() {
    delete localStorage.removeKey(KEY_AUTH_TOKEN)
}

export function sendJobInvite({request, component, historySearchObj}) {
    const {history, doDispatch} = component.props
    const {location} = history
    const {search} = location
    apiv1.auth.post('/job/job-invite/', request)
        .then(resp => {
            const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
            if (data) {
                history.replace({
                    pathname: path.userDashboard,
                    search: historySearchObj ? historySearchObj : search
                })
            }
        })
        .catch(err => {
            // this.setState({loading: false, message: ''});
            apiv1.handleErrors(err, doDispatch)
            history.replace({
                pathname: path.userDashboard,
                search: historySearchObj ? historySearchObj : search
            })
        });
}

export function postJob({component, historySearchObj}) {
    const request = JSON.parse(localStorage.onBoardingData)
    const {doDispatch, history, state} = component.props
    // let {cityId} = state
    const {location} = history
    const {search} = location
    let {rehire, professional, name, jobId, cityId, is_direct} = queryString.parse(search)
    apiv1.auth.post('/job/job/', request)
        .then(resp => {
            const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch)
            if (localStorage.onBoardingData) {
                delete localStorage.removeKey(KEY_ONBOARDING_DATA)
            }
            if (rehire && rehire === TRUE && professional) {
                if (data) {
                    const {id} = data
                    const invitationRequest = {
                        receiver: professional,
                        job: id
                    }
                    sendJobInvite({request: invitationRequest, component, historySearchObj})
                }
            } else {
                history.replace({
                    pathname: path.userDashboard,
                    search: historySearchObj ? historySearchObj : search
                })
            }
        })
        .catch(err => {
            apiv1.handleErrors(err, doDispatch)
            history.replace(path.userDashboard)
        })
}

class UserSignUp extends Component {
    constructor(props) {
        super(props)
        // const {location} = props
        // const {search} = location
        const referral_code = localStorage.referralCode ? localStorage.referralCode : ''
        // if (referral_code){
        //     localStorage.referralCode = referral_code
        // }
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            password1: '',
            loading: false,
            errors: {},
            phone: '',
            geoCity: [],
            cityId: '',
            referral_code,
            isValidReferralCode: false
        }
    }

    componentDidMount() {
        this.getCity()
    }

    getCity = () => {
        apiv1.noAuth.get(`/geo/city/`)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    this.setState({geoCity: data, loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    onSubmit = (e, isSignInWithGoogle) => {
        if (!isSignInWithGoogle) {
            e.preventDefault()
        }
        const {first_name, last_name, email, password1, phone, cityId} = this.state;
        let request = {
            username: email,
            first_name,
            last_name,
            email,
            password1,
            phone,
            password2: password1,
            is_professional: false
        };
        const validatorObj = {
            ...request,
            address: {
                state: cityId
            }
        };

        const errors = {};
        const isFormValidated = validateProfile(errors, validatorObj);
        if (isFormValidated) {
            this.setState({loading: true, error: {}})
            request.phone = '+91' + phone;
            apiv1.noAuth.post('/rest-auth/registration/', request)
                .then((resp) => {
                    // this.setState({ loading: false });
                    const data = apiv1.parseAndAutoHandleIssues(resp);
                    if (data) {
                        const {key} = data
                        const {onSignUp, history, location} = this.props
                        let {search} = location
                        setLocalStorage({auth_token: key, USER, first_name, last_name})
                        // onSignUp(key, USER, first_name, last_name)
                        // askForPermissioToReceiveNotifications();
                        window.userSignUpRecord()
                        search = `${search.indexOf('?') > -1 ? `${search}&cityId=${cityId}` : `${search}?cityId=${cityId}`}`
                        if (localStorage.onBoardingData) {
                            postJob({authToken: key, component: this, historySearchObj: search})
                        } else {
                            history.replace({
                                pathname: path.userOnboarding,
                                search,
                            })
                        }
                    } else {
                        this.setState({loading: false})
                    }
                })
                .catch((err) => {
                    const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
                    for (let key in resErrors) {
                        errors[key] = resErrors[key][0]
                    }
                    this.setState({loading: false, errors, email: '', password1: ''})
                })
        } else {
            this.setState({loading: false, errors})
        }
    };
    onChange = (e) => {
        const newErrors = {...this.state.errors};
        let {isValidReferralCode} = this.state
        const {name, value,} = e.target;
        newErrors[e.target.name] = '';
        let message = '';
        if (name === 'phone') {
            message = 'Phone no is no valid';
            onlyZeroToNine(newErrors, name, value, message)
        }
        if (name === 'referral_code') {
            isValidReferralCode = false
        }
        this.setState({[name]: value, errors: newErrors, isValidReferralCode})
    };

    signWithWithGoogle = (googleUser) => {
        const errors = {};
        const request = {
            access_token: googleUser.accessToken
        };
        this.setState({loading: true, error: {}});
        apiv1.noAuth.post('/rest-auth/google/connect/', request)
            .then((resp) => {
                this.setState({loading: false});
                const data = apiv1.parseAndAutoHandleIssues(resp);
                if (data) {
                    this.props.onSignUp(data.key, this.props.type, googleUser.w3.ofa, googleUser.w3.wea);
                    // askForPermissioToReceiveNotifications();
                    if (Boolean(localStorage.isBookConsultation)) {
                        this.props.history.push({
                            pathname: path.findAProfessionalByLocation
                        })
                    } else {
                        this.props.history.push({
                            pathname: userOnboarding
                        })
                    }

                } else {
                    this.setState({loading: false})
                }
            })
            .catch((err) => {
                const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
                for (let key in resErrors) {
                    errors[key] = resErrors[key][0]
                }
                this.setState({loading: false, errors, email: '', password1: ''})
            })
    };

    onFailure = (data) => {

    };

    checkValidityForReferralCode = () => {
        const {referral_code, errors} = this.state
        const {doDispatch} = this.props
        this.setState({loading: true})
        apiv1.noAuth.post(`/users/referral-code-valid`, {referral_code})
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                if (data) {
                    const isValidReferralCode = true
                    const {referral_code} = data
                    localStorage.referralCode = referral_code
                    this.setState({isValidReferralCode, loading: false})
                }
            })
            .catch(err => {
                const data = apiv1.parseAndAutoHandleIssues(err.response, doDispatch)
                apiv1.handleErrors(err, doDispatch)
                let {isValidReferralCode} = this.state
                if (data) {
                    const {Error} = data
                    if (Error) {
                        isValidReferralCode = false
                        errors['referral_code'] = Error
                    }
                }
                this.setState({loading: false, errors, isValidReferralCode})
            })
    }


    render() {
        const {first_name, last_name, email, password1, loading, errors, phone, cityId, geoCity, referral_code, isValidReferralCode} = this.state;
        return (
            <div>
                <MetaTags>
                    <title>Sign up</title>
                    <meta name="title" content="Sign up"/>
                    <meta name="description" content="On Demand & affordable Legal & Accounting Services"/>
                    <meta name="url" content={`${baseUrl}/user-registration`}/>
                    <meta property="og:url" content={`${baseUrl}/user-registration`}/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:site_name" content="workbeam.com"/>
                    <meta property="og:image"
                          content={siteLogoOnSharedMedia}/>
                    <meta property="og:image:width" content="300"/>
                    <meta property="og:image:height" content="300"/>
                    <meta name="twitter:card" content="On Demand & affordable Legal & Accounting Services"/>
                    <meta name="twitter:site" content="@Workbeam"/>
                    <meta name="twitter:title" content="Sign up"/>
                    <meta name="twitter:description" content="On Demand & affordable Legal & Accounting Services"/>
                    <meta name="twitter:image"
                          content={siteLogoOnSharedMedia}/>
                    <link rel="canonical" href={`${baseUrl}/user-registration`}/>
                </MetaTags>
                <section className="header">
                    <div className="container-fluid">
                        <LogInSignUpHeader history={this.props.history} type={this.props.type} mode='signup' {...this.props}/>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row display-flex">
                            {loading &&
                            <div className="lazy-loader" style={{position: 'fixed'}}><LoadingComponent pastDelay/>
                            </div>}
                            <HowItWorkSection/>
                            <div className="col-sm-5 col-sm-offset-1">
                                <div className="col-sm-12">
                                    <h2 className="heading-sm">Let’s find you a wizard
                                        <br/>professional.</h2>
                                </div>
                                <div className="clearfix"></div>
                                <div className={(errors.username) ? 'msg msg-danger msg-danger-text' : 'hidden'}>
                                    <span className="glyphicon glyphicon-remove pull-right"
                                          style={{cursor: 'pointer'}}
                                          onClick={() => this.setState({errors: {}})}
                                    ></span>
                                    {errors.username}
                                </div>
                                <div className="clearfix"></div>
                                <form className="signup-form" style={{marginTop: '10px'}}>
                                    {/*<GoogleLogin*/}
                                    {/*clientId="995918006792-f1n8kout8sod6p8p1m99vl1cgbt0k34j.apps.googleusercontent.com"*/}
                                    {/*render={renderProps => (*/}
                                    {/*<button onClick={renderProps.onClick} className="btn btn-block btn-google-plus">Connect with Google</button>*/}
                                    {/*)}*/}
                                    {/*buttonText="Login"*/}
                                    {/*onSuccess={this.signWithWithGoogle}*/}
                                    {/*onFailure={this.onFailure}*/}
                                    {/*/>*/}
                                    {/*<h4 className="text-dark text-center">or</h4>*/}
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <input type="text"
                                                   className="form-control input-lg"
                                                   value={first_name}
                                                   onChange={this.onChange}
                                                   name="first_name" placeholder="First Name"
                                                   style={{borderBottom: errors.first_name ? '1px solid #ff337d' : ''}}
                                            />
                                            <span
                                                className={errors.first_name ? 'error' : 'no-error'}>{errors.first_name}</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <input type="text"
                                                   className="form-control input-lg"
                                                   value={last_name}
                                                   onChange={this.onChange}
                                                   name="last_name"
                                                   placeholder="Last Name"
                                                   style={{borderBottom: errors.last_name ? '1px solid #ff337d' : ''}}
                                            />
                                            <span
                                                className={errors.last_name ? 'error' : 'no-error'}>{errors.last_name}</span>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="col-sm-6">
                                        <div className="form-group" style={{marginTop: '5px'}}>
                                            <input type="text"
                                                   className="form-control input-lg"
                                                   name="phone"
                                                   value={phone}
                                                   onChange={this.onChange}
                                                   placeholder="Mobile E.g. 9XXXXXXXX9 "
                                                   style={{borderBottom: errors.phone ? '1px solid #ff337d' : ''}}
                                            />
                                            <span className={errors.phone ? 'error' : 'no-error'}>{errors.phone}</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group" style={{marginTop: '18px'}}>
                                            <select className="form-control"
                                                    onChange={(e) => {
                                                        const {errors} = this.state
                                                        errors['state'] = ''
                                                        const cityId = e.target.value.toString()
                                                        this.setState({cityId, errors})
                                                    }}
                                            >
                                                <option value='-1' id='court_state_text' className='hidden'>City
                                                </option>
                                                {geoCity.map(cityData => {
                                                    return <option value={cityData.id} id={cityData.name}
                                                                   key={cityData.id}>{cityData.name}</option>
                                                })}
                                            </select>
                                            <span className={errors.state ? 'error' : 'no-error'}>{errors.state}</span>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <input type="email"
                                                   className="form-control input-lg"
                                                   value={email}
                                                   onChange={this.onChange}
                                                   name="email" placeholder="Email"
                                                   style={{borderBottom: errors.email ? '1px solid #ff337d' : ''}}
                                            />
                                            <span className={errors.email ? 'error' : 'no-error'}>{errors.email}</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <input type="password"
                                                   className="form-control input-lg"
                                                   value={password1}
                                                   onChange={this.onChange}
                                                   name="password1" placeholder="Password"
                                                   style={{borderBottom: errors.password1 ? '1px solid #ff337d' : ''}}
                                            />
                                            <span
                                                className={errors.password1 ? 'error' : 'no-error'}>{errors.password1}</span>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <input type="text"
                                                   className="form-control input-lg"
                                                   value={referral_code}
                                                   onChange={this.onChange}
                                                   name="referral_code" placeholder="Enter Referral Code (optional)"
                                                   style={{borderBottom: errors.referral_code ? '1px solid #ff337d' : ' '}}
                                            />
                                            <span
                                                className={errors.referral_code ? 'error' : 'no-error'}>{errors.referral_code}</span>
                                            {isValidReferralCode && <span className="text-success">{'Applied'}</span>}
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <a className="btn btn-referral-code"
                                               onClick={(e) => {
                                                   if (referral_code) {
                                                       this.checkValidityForReferralCode(e)
                                                   }
                                               }}>Apply
                                            </a>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="col-sm-12 text-center">
                                        <p>&#x00A0;</p>
                                        <button type="submit" className="btn btn-submit btn-lg btn-user-signup"
                                                onClick={(e) => this.onSubmit(e, false)}>GET STARTED
                                        </button>
                                        <p>&#x00A0;</p>
                                        <h4 className="text-dark">By clicking here you agree to our
                                            <br/>
                                            <b>
                                                <a href={path.termsAndConditions}>Terms of Service</a> &amp;
                                                <a href={path.privacyPolicy} style={{marginLeft: '5px'}}>Privacy
                                                    policy</a>
                                            </b>
                                        </h4>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="page-contant">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-10 col-sm-offset-1">
                                <h4 className="heading-sm text-center">You’re Secured</h4>
                                <div className="col-sm-4 work-box-padding">
                                    <div className="work-icon-box">
                                        <img src={require("../../static/images/locked-credit-card.png").default}
                                             style={{width: '60px'}} alt="private limited"/>
                                    </div>
                                    <h3 className="scope-sub-heading">Payment Protection</h3>
                                    <p className="xs-para">We keep payment in escrow and release once work is done. Easy
                                        refunds on Non-Delivery.</p>
                                </div>
                                <div className="col-sm-4 work-box-padding">
                                    <div className="work-icon-box">
                                        <img src={require("../../static/images/delivery-truck.png").default}
                                             style={{width: '60px'}} alt="private limited"/>
                                    </div>
                                    <h3 className="scope-sub-heading">Track work Delivery</h3>
                                    <p className="xs-para">Track work delivery using milestones.</p>
                                </div>
                                <div className="col-sm-4 work-box-padding">
                                    <div className="work-icon-box">
                                        <img src={require("../../static/images/internet-logo.png").default}
                                             style={{width: '60px'}} alt="private limited"/>
                                    </div>
                                    <h3 className="scope-sub-heading">Safety</h3>
                                    <p className="xs-para">Data and Payments are highly secured.</p>
                                </div>
                                <p className="hidden-xs">&#x00A0;</p>
                                <p className="hidden-xs">&#x00A0;</p>
                                <div className="row">
                                    <div className="col-sm-4 work-box-padding">
                                        <div className="work-icon-box">
                                            <img src={require("../../static/images/conversation.png").default}
                                                 style={{width: '60px'}} alt="private limited"/>
                                        </div>
                                        <h3 className="scope-sub-heading">Communicate</h3>
                                        <p className="xs-para">Communicate using Workbeam. Chat, Audio or Video
                                            Call.</p>
                                    </div>
                                    <div className="col-sm-4 work-box-padding">
                                        <div className="work-icon-box">
                                            <img src={require("../../static/images/inventory.png").default}
                                                 style={{width: '60px'}} alt="private limited"/>
                                        </div>
                                        <h3 className="scope-sub-heading">Share Files</h3>
                                        <p className="xs-para">Share important files for getting our work done.</p>
                                    </div>
                                    <div className="col-sm-4 work-box-padding">
                                        <div className="work-icon-box">
                                            <img src={require("../../static/images/live-chat.png").default}
                                                 style={{width: '60px'}} alt="private limited"/>
                                        </div>
                                        <h3 className="scope-sub-heading">Dedicated Support</h3>
                                        <p className="xs-para">We assign you a dedicated support manager to help you
                                            throughout.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <p>&#x00A0;</p>
                <p className="visible-xs">&#x00A0;</p>
                <p className="visible-xs">&#x00A0;</p>
                <Footer/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {}
};

const mapDispatchToProps = dispatch => {
    return {
        onSignUp: (auth_token, userType, firstName, lastName) => {
            dispatch(registration({
                auth_token: auth_token,
                user_type: userType,
                first_name: firstName,
                last_name: lastName
            }))
        },
        ...(errorActionDispatchers(dispatch))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserSignUp))