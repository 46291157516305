import * as types from "../constants/auth";
const user = {
    jobInProgressCount: 0,
    jobPostingCount: 0,
    consultationCount: 0,
    jobCompletedCount: 0,
    consultationCompletedCount: 0,
};
const professional = {
    jobInProgressCount: 0,
    jobProposalSentCount: 0,
    jobCompletedCount : 0,
    consultationCount: 0,
    jobInviteCount: 0,
    totalGigCount: 0,
    gigLiveCount: 0,
    consultationCompletedCount: 0,
};
const initialState = {
    user,
    professional
};

export default function jobCount (state = initialState, action) {
    var out = {
        ...state
    };
    let user = {
        ...state.user
    };
    let professional = {
        ...state.professional
    };
    const {type, payload} = action
    switch (type) {
        case types.USER_JOB_POSTING_COUNT:
            user.jobPostingCount = action.payload;
            out.user = user;
            return out;
        case types.USER_JOB_IN_PROGRESS_COUNT:
            user.jobInProgressCount = action.payload;
            out.user = user;
            return out;
        case types.PROFESSIONAL_JOB_IN_PROGRESS_COUNT:
            professional.jobInProgressCount = action.payload;
            out.professional = professional;
            return out;
        case types.PROFESSIONAL_PROPOSAL_SENT_COUNT:
            professional.jobProposalSentCount = action.payload;
            out.professional = professional;
            return out;
        case types.PROFESSIONAL_SIDEBAR_JOB_COUNT:
            const {order_completed_amount, order_in_progress_amount} = payload
            const {fixed_amount__sum} = order_completed_amount
            professional.jobInProgressCount = payload.job_in_progress;
            professional.jobProposalSentCount = payload.proposal_count;
            professional.jobCompletedCount = payload.job_completed;
            professional.totalGigCount = payload.gigs_total;
            professional.gigLiveCount = payload.gigs_live;
            professional.consultationCount = payload.consult;
            professional.jobInviteCount = payload.invite_count;
            professional.consultationCompletedCount = payload.consult_completed
            professional.messageCount = payload.messaged_count
            professional.missingInfo = payload.missing
            professional.orderCompletedAmount = parseInt(order_completed_amount.fixed_amount__sum)
            professional.orderInProgressAmount = parseInt(order_in_progress_amount.fixed_amount__sum)
            out.professional = professional;
            return out;
        case types.PROFESSIONAL_JOB_INVITES_COUNT:
            professional.jobInviteCount = action.payload
            out.professional = professional;
            return out;
        case types.USER_SIDEBAR_JOB_COUNT:
            const {job_in_progress, job_post, consult, job_completed, consult_completed, messaged_count} = payload
            user.jobInProgressCount = job_in_progress;
            user.jobPostingCount = job_post;
            user.consultationCount = consult;
            user.jobCompletedCount = job_completed;
            user.consultationCompletedCount = consult_completed
            user.messageCount = messaged_count
            out.user = user;
            return out;
        case types.INCREASE_JOB_POSTING_COUNT:
            out.user.jobPostingCount += action.payload
            return out;
        case types.DECREASE_JOB_INPROGRESS_COUNT:
            out.user.jobInProgressCount -= action.payload
            return out;
        default:
            return state
    }
}