import React, {Component, Fragment} from "react";
import {apiv1} from "../../api";
import {ratingUi} from "../functional/beforeHire";
import $ from "jquery";
import * as path from '../../constants/path'
import {errorActionDispatchers} from "../../actions/errors";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";
import LoadingComponent from "../../utils/LoadingComponent";
import {urlRegex} from "./validation/FormValidator";
import happiness from "../../static/images/happiness.png"
class HomePageCustomerReviews extends Component {
    constructor(props){
        super(props)
        this.state = {
            loading: false,
            customerReviews: [],
            limit: 9,
            currentPage: 1,
            totalRecord: 0
        }
    }

    componentDidMount() {
        const {currentPage, limit} = this.state
        this.getCustomerReviews({currentPage, limit})
    }

    getCustomerReviews = ({currentPage}) =>{
        const {limit} = this.state
        this.setState({loading: true})
        apiv1.auth.get(`job/job-testimonial?page=${currentPage}&limit=${limit}`)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch)
                if (data) {
                    const {results, count} = data
                    let customerReviews = [...this.state.customerReviews]
                    customerReviews = customerReviews.concat(results)
                    this.setState({customerReviews, loading: false, totalRecord: count, currentPage})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }
    getLinkFromText = (rating) =>{
        let links = []
        if (urlRegex.test(rating)) {
            rating = rating.replace(urlRegex, function (url) {
                links.push(url)
                return `<a href=${url} class='hash-tag' target="_blank" >${url}</a>`
            })
        }
    }
    homeCustomerReviewsUi = ({customerReviews}) => {
        const {userRating, userCount} = this.props
        const {currentPage, limit, totalRecord} = this.state
        const rating = `saSDADADF https://web-dev.wizcounsel.com/login DaDadd ddDdD`
        return(
                <section className="professional-social-ui customer-review-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                            <div className="customer-review-container">
                                <h2 className="testimonials-h3"><img src={happiness} style={{width:'30px', margin:'0px 10px'}} alt={`Wall of Happiness!`}/>Wall of Happiness! {userRating && <span className="rating-ui-label hidden-xs"> <i className="fa fa-star"></i>&#x00A0;{userRating}/5&#x00A0;based on {userCount}+ Booked</span>}
                                </h2>
                                <p className="xs-para text-center visible-xs" style={{color:'#2244e0'}}>{userCount}+ Booked with {userRating} Ratings </p>
                                    <ul className="list-unstyled">
                                        {customerReviews.map((reviews, index )=>{
                                            const {rating, job_name, comment, user_name} = reviews || {}
                                            return(
                                                <li key={index}>
                                                    <p className="text-name">{user_name} {ratingUi(rating)}</p>
                                                    <p className="text-product-name">{job_name}</p>
                                                    <p className="text-para-reviews">{comment}</p>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    {totalRecord > customerReviews.length &&
                                    <div className="text-center">
                                        <a className='btn btn-load-more' style={{border:'none', background:'#ff337d', color:'#fff'}}
                                          href={path.testimonials}
                                        >
                                            Read Success Stories & Wall of Happiness
                                        </a>
                                    </div>
                                    }
                            </div>
                            </div>
                        </div>
                    </div>
                </section>
        )
    }

    render() {
        const {customerReviews,loading} = this.state
        return (
            <Fragment>
                {loading &&
                <div className='lazy-loader' style={{position: 'fixed', zIndex: '100'}}>
                    <LoadingComponent pastDelay/>
                </div>
                }
                {customerReviews && customerReviews.length > 0
                &&
                this.homeCustomerReviewsUi({customerReviews})
                }
            </Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ...(errorActionDispatchers(dispatch))
    }
};

export default connect(null, mapDispatchToProps)(withRouter(HomePageCustomerReviews))
