import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import {connect} from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar'
// import IconButton from '@material-ui/core/IconButton'
// import CloseIcon from '@material-ui/icons/Close'
import {popError} from '../actions/errors'
import CloseIcon from './../static/images/close-eoor-popup.svg'

const styles = theme => ({
    close: {
        width: theme.spacing.unit * 4,
        height: theme.spacing.unit * 4
    }
});

class Errors extends React.Component {
    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.updateStateAndTimer = this.updateStateAndTimer.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errId === this.props.errId) {
            return
        }
        this.updateStateAndTimer(Boolean(nextProps.errMessage))
    }

    componentWillMount() {
        this.updateStateAndTimer(Boolean(this.props.errMessage))
    }

    handleClose(event, reason) {
        if (reason === 'clickaway') {
            return
        }
        this.updateStateAndTimer(false);
        if (this.props.onDismiss) {
            this.props.onDismiss()
        }
    }

    updateStateAndTimer(show) {
        window.clearTimeout(this.timer);
        this.setState({
            open: show
        });
        if (show) {
            this.timer = window.setTimeout(() => {
                this.setState({
                    open: false
                })
            }, 6000)
        }
    }

    render() {
        const {classes} = this.props;
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                open={this.state.open}
                onClose={this.handleClose}
                snackbarcontentprops={{
                    'aria-describedby': 'message-id'
                }}
                action={[
                    <img src={CloseIcon}
                         onClick={this.handleClose}
                         className={classes.close}
                         style={{position: 'absolute', right: '10px', width: '25px', top: '10px', cursor: 'pointer'}}
                    ></img>
                ]}
                message={<div id="message-id"
                              style={{fontSize: '16px', padding: '0px 10px'}}>{this.props.errMessage}</div>}
            />
        )
    }
}

Errors.propTypes = {
    onDismiss: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        errId: state.errors.currentId,
        errMessage: state.errors.currentError
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onDismiss: () => {
            dispatch(popError())
        }
    }
};

const ConnectedErrors = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Errors));

export default ConnectedErrors
