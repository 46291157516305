import { useEffect, useState } from "react";
import { ResumeTemplate } from "./ResumeTemplate";
import LoadingComponent from "../../utils/LoadingComponent";

export const ResumeEditorComponent = () => {
  const [form, setForm] = useState({ username: "", resumeData: null, loading: false });

  const fetchResume = async () => {
    const parsedResume = {
      jd: null,
      recruiter: null,
      vanity_name: "mujahidsultan",
      first_name: "Mujahid",
      last_name: "Sultan",
      title: "Co-Founder and President at MLSoft",
      about:
        "With over 20 years of experience in Machine Learning, AI, Cloud Computing, and Data Science, he has accomplished various roles in research, development, and architecture. He co-founded an ML research and development company, served as an Enterprise Architect for Treasury Board Secretariat, and held leadership roles in Dubai Customs and the Ontario Public Service. His research in Artificial Intelligence, Machine Learning, and Statistics has been published in top journals, he presented at national and international conferences. His expertise lies in the Optimization of ML models, multi-cloud deployment, MLOps, cloud cost optimization, and information visualization.\n\nHe published peer-reviewed articles in high-impact journals in the areas of Machine Learning, Artificial Intelligence, Data Visualization, Genetics and Drug Discovery for Cancer, Requirements Engineering, Enterprise Architecture, and Cloud Computing. His publications can be found at https://orcid.org/0000-0001-6721-4044",
      location: "Canada",
      resume: 157,
      country: "Canada",
      languages: ["English"],
      skills: ["Machine Learning", "Python (Programming Language)"],
      education: [
        {
          grade: "Cumulative GPA 4.22",
          school: "Ryerson University",
          ends_at: {
            day: 31,
            year: 2022,
            month: 8,
          },
          starts_at: {
            day: 1,
            year: 2015,
            month: 9,
          },
          degree_name: "Ph.D.",
          description: "Skills: Machine Learning",
          field_of_study: "Computer Science",
          activities_and_societies: null,
          school_linkedin_profile_url:
            "https://www.linkedin.com/company/165799/",
        },
        {
          grade: null,
          school: "University of Toronto",
          ends_at: null,
          starts_at: null,
          degree_name: "MIS",
          description: "Machine Learning",
          field_of_study: "Information Systems",
          activities_and_societies: "Database group",
          school_linkedin_profile_url: "https://www.linkedin.com/company/3660/",
        },
        {
          grade: null,
          school: "University of Toronto",
          ends_at: null,
          starts_at: null,
          degree_name: "Ph.D Student",
          description: "Artificial Intelligence",
          field_of_study: "Computer Science",
          activities_and_societies: "AI group",
          school_linkedin_profile_url: "https://www.linkedin.com/company/3660/",
        },
      ],
      experience: [
        {
          title: "Assistant Professor",
          company: "Lahore University of Management Sciences",
          ends_at: null,
          location: null,
          starts_at: {
            day: 1,
            year: 2007,
            month: 1,
          },
          description:
            'Designed and taught a course titled "Intelligent Data Analysis methods in Functional Genomics.',
          company_linkedin_profile_url:
            "https://www.linkedin.com/company/lahore-university-of-management-sciences",
        },
        {
          title: "Co-Founder and President",
          company: "MLSoft",
          ends_at: null,
          location: "Toronto, Ontario, Canada",
          starts_at: {
            day: 1,
            year: 2020,
            month: 1,
          },
          description:
            "Machine Learning Research and Development | ML Models Optimization and Multi-Cloud Deployment | MLOps | Cloud Cost Optimization | Visualization | Machine Learning at the Edge",
          company_linkedin_profile_url:
            "https://www.linkedin.com/company/mlsoftai",
        },
        {
          title: "Senior Enterprise Architect",
          company: "Treasury Board Secrateriat, Government of Ontario ",
          ends_at: {
            day: 31,
            year: 2023,
            month: 12,
          },
          location: "Toronto, Canada Area",
          starts_at: {
            day: 1,
            year: 2012,
            month: 1,
          },
          description:
            "As Senior Enterprise Architect,  I provide architecture guidance and review of I and IT initiatives, provide technology and security advice, and architect/develop cutting edge Machine Learning and AI solutions.",
          company_linkedin_profile_url: null,
        },
        {
          title: "Enterprise Architect",
          company:
            "Office of the Corporate Chief Technology Officer, Government of Ontario",
          ends_at: {
            day: 31,
            year: 2010,
            month: 12,
          },
          location: "Toronto, Canada Area",
          starts_at: {
            day: 1,
            year: 2008,
            month: 7,
          },
          description:
            "Managed projects, developed Solutions Architecture of large initiatives in the Ontario Government. Provided security assessments and privacy guidelines. Developed technology standards and coached and guided project teams on the following best practices in overall IT. ",
          company_linkedin_profile_url: null,
        },
        {
          title: "Global PAC Member",
          company: "Association for Computing Machinery",
          ends_at: {
            day: 31,
            year: 2023,
            month: 1,
          },
          location: null,
          starts_at: {
            day: 1,
            year: 2017,
            month: 4,
          },
          description: null,
          company_linkedin_profile_url:
            "https://www.linkedin.com/company/association-for-computing-machinery",
        },
        {
          title: "Head of Technology Architecture",
          company: "Dubai Customs",
          ends_at: {
            day: 31,
            year: 2011,
            month: 12,
          },
          location: "Dubai, UAE",
          starts_at: {
            day: 1,
            year: 2010,
            month: 1,
          },
          description:
            "As Head of Technology Architecture, I was responsible for the overall design and direction of the technology architecture for Dubai Customs. This includes developing and maintaining the technology architecture plan, defining and governing the use of technology standards, and ensuring that the technology architecture supports the business goals and objectives of Dubai Customs. Led and directed the work of the technology architecture team, and coordinated with other teams within Dubai Customs to ensure that the technology architecture supports the overall business objectives.",
          company_linkedin_profile_url:
            "https://www.linkedin.com/company/dubai-customs",
        },
        {
          title: "Data Center Consolidation Expert",
          company: "Ontario Public Service",
          ends_at: {
            day: 30,
            year: 2008,
            month: 6,
          },
          location: null,
          starts_at: {
            day: 1,
            year: 2006,
            month: 1,
          },
          description:
            "Designed Ontario Government's first virtual data center, optimizing performance while streamlining costs. Analyzed components and standardized processes to ensure system security. Designed a new data center remodeling plan and overhauled the existing infrastructure to optimize performance. Calculated cost savings and increased efficiency by 20%, creating a model for future virtual data centers. Forecasted potential cost savings and managed the market. Executed, expanded, and maximized the efficiency of the data center. Implemented, inspected, and launched the remodeling plan. Prepared, processed, and purchased the necessary components.",
          company_linkedin_profile_url:
            "https://www.linkedin.com/company/ontario-public-service-leadership",
        },
        {
          title: "Machine Learning Engineer/Data Scientist",
          company: "University Health Network",
          ends_at: {
            day: 31,
            year: 2006,
            month: 12,
          },
          location: null,
          starts_at: {
            day: 1,
            year: 2001,
            month: 1,
          },
          description:
            "Authored novel lung cancer classification and visualization software (called Binary tree-structured vector quantization - BTSVQ)\nAuthored and implemented novel business intelligence and data mining tools, publicly available under GNU license\nConducted research and published first-authored research articles in top journals, such as Genetics and Cancer Research in the area of artificial intelligence, machine learning, and statistics\nPresented research findings at national and international conferences",
          company_linkedin_profile_url:
            "https://www.linkedin.com/company/university-health-network",
        },
      ],
      accomplishments: {
        honors: [
          {
            title: "IBM Fellowship",
            issuer: "IBM",
            issued_on: {
              day: 1,
              year: 2016,
              month: 9,
            },
            description: "IBM PhD Fellowship Award",
          },
        ],
        courses: [
          {
            name: "Advanced Machine Learning - Winter 2001",
            number: "CSC 2535 (U of Toronto)",
          },
          {
            name: "Advanced Topics in Data Management Systems - Fall 2000 ",
            number: "CSC 2531 (U of Toronto)",
          },
        ],
        patents: [],
        projects: [
          {
            url: null,
            title: "Cloud Cost Optimization",
            ends_at: {
              day: 31,
              year: 2022,
              month: 12,
            },
            starts_at: {
              day: 1,
              year: 2019,
              month: 1,
            },
            description:
              "Cloud cost optimization refers to the process of managing and minimizing the expenses associated with utilizing cloud services and resources. It involves evaluating and implementing strategies to maximize cost efficiency, reduce unnecessary spending, and optimize the overall utilization of cloud infrastructure.\n\nThere are several approaches to cloud cost optimization, including:\n\n1. Resource optimization: Analyzing the usage patterns of cloud resources and scaling them accordingly. This involves rightsizing instances, eliminating idle or underutilized resources, and resizing storage volumes.\n\n2. Reserved Instances: Making use of Reserved Instances, where available, to secure a long-term commitment at a discounted rate. This allows organizations to save costs by paying upfront or committing to a certain usage level.\n\n3. Spot instances: Utilizing spot instances, which are spare computing capacity offered at significantly lower prices. These instances can be used for non-critical workloads that can tolerate interruptions or be easily re-launched.\n\n4. Auto-scaling: Implementing automated scaling based on demand to ensure resources are provisioned as needed. This helps avoid over-provisioning and reduces unnecessary costs during periods of low utilization.\n\n5. Monitoring and analytics: Leveraging cloud monitoring tools and analytics to gain visibility into resource utilization, identify bottlenecks, and optimize performance. This helps in making informed decisions about resource allocation and",
          },
        ],
        test_scores: [],
        publications: [
          {
            url: "https://arxiv.org/abs/2003.04372",
            name: "Probabilistic Partitive Partitioning (PPP)",
            publisher: "https://arxiv.org/abs/2003.04372",
            description:
              "Clustering is a NP-hard problem. Thus, no optimal algorithm exists, heuristics are applied to cluster the data. Heuristics can be very resource-intensive, if not applied properly. For substantially large data sets computational efficiencies can be achieved by reducing the input space if a minimal loss of information can be achieved. Clustering algorithms, in general, face two common problems: 1) these converge to different settings with different initial conditions and; 2) the number of clusters has to be arbitrarily decided beforehand. This problem has become critical in the realm of big data. Recently, clustering algorithms have emerged which can speedup computations using parallel processing over the grid but face the aforementioned problems. Goals: Our goals are to find methods to cluster data which: 1) guarantee convergence to the same settings irrespective of the initial conditions; 2) eliminate the need to establish the number of clusters beforehand, and 3) can be applied to cluster large datasets. Methods: We introduce a method that combines probabilistic and combinatorial clustering methods to produce repeatable and compact clusters that are not sensitive to initial conditions. This method harnesses the power of k-means (a combinatorial clustering method) to cluster/partition very large dimensional datasets and uses the Gaussian Mixture Model (a probabilistic clustering method) to validate the k-means partitions. Results: We show that this method produces very compact clusters that are not sensitive to initial conditions. This method can be used to identify the most 'separable' set in a dataset which increases the 'clusterability' of a dataset. This method also eliminates the need to specify the number of clusters in advance. ",
            published_on: {
              day: 1,
              year: 2020,
              month: 12,
            },
          },
          {
            url: "https://arxiv.org/abs/2009.08492",
            name: "Designing knowledge plane to optimize leaf and spine data center",
            publisher:
              "2020 IEEE 13th International Conference on Cloud Computing (CLOUD)",
            description:
              "Abstract—In the last few decades, data center architecture evolved from the traditional client-server to access-aggregation-core architectures. Recently there is a new shift in the data center architecture due to the increasing need for low latency and high throughput between server-to-server communications, load balancing and, loop-free environment. This new architecture, known as leaf and spine architecture, provides low latency and minimum packet loss by enabling the addition and deletion of network nodes on demand. Network nodes can be added or deleted from the network based on network statistics like link speed, packet loss, latency, and throughput.\n\nWith the maturity of Open Virtual Switch (OvS) and Open-Flow based Software Defined Network (SDN) controllers, network automation through programmatic extensions has become possible based on network statistics. Separation of control plane and data plane has enabled automated management of network and Machine Learning (ML) can be applied to learn and optimize the network.\n\nIn this publication, we propose the design of an ML-based approach to gather network statistics and build a knowledge plane. We demonstrate that this knowledge plane enables data center optimization using southbound APIs and SDN controllers. We describe the design components of this approach - using a network simulator and show that it can maintain the historical patterns of network statistics to predict future growth or decline.",
            published_on: {
              day: 11,
              year: 2020,
              month: 10,
            },
          },
        ],
        organizationsal: [],
      },
      projects: [{
        title: " Project 1",
        description: 'description of the project',
        startDate: '2020-01-01',
        endDate: '2021-01-01'
      }],
      certifications: [
        "Certified Information Security Manager® (CISM)",
        "Certified Information Systems Security Professional (CISSP)",
      ],
      recommendations: [],
      activities: [],
      articles: [],
      interests: [],
      socials: {
        twitter: "None",
        facebook: "None",
      },
      contact: {
        email: ["mujahid@cs.toronto.edu"],
        phone: [],
      },
      github: "None",
      visibility: {
        skills: true,
        socials: true,
        articles: true,
        education: true,
        interests: true,
        languages: true,
        activities: true,
        experience: true,
        certifications: true,
        projects: true,
        accomplishments: {
          courses: true,
          publications: true,
          certifications: true,
        },
        recommendations: true,
      },
    };
    let data = null;
    setForm({...form, loading: true});
    try {
      const res = await fetch(
        `http://75.98.202.121:8800/client/fetchResume/?vanity_name=${form?.username}&recruiter_email=mujahid.sultan@mlsoft.ai`
      );
      console.log(":: res", res);
      data = res;
    } catch (e) {
      data = parsedResume;
    }
    setForm({...form, loading: false});
    // data = parsedResume;
    setForm({ ...form, resumeData: data });
  };

  useEffect(() => {
    console.log(":: form", form);
  }, [form]);

  return (
    <div>
      <h1>Resume Editor</h1>
      <div style={{ display: "flex", gap: "20px" }}>
        <input
          type="text"
          placeholder="linkedin-username"
          value={form?.username}
          onChange={(e) => setForm({ username: e?.target.value })}
          className="form-control"
        />
        <button className="btn primary-btn" onClick={() => fetchResume()}>
          Fetch Resume
        </button>
        { form?.loading && <LoadingComponent pastDelay />}
      </div>
      {/* Resume Editor Template Component goes here */}
      {form?.resumeData && <ResumeTemplate data={form?.resumeData} />}
    </div>
  );
};
