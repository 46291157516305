import React, {Component} from "react";
import Message from "../common/Message";
import {apiv1} from "../../api";
import localStorage from "../../utils/localStorage";
import {PROFESSIONAL, USER} from "../../constants/auth";
import {getAvatar} from "../common/userAndProfessionalProfileFunctions";
import {errorActionDispatchers} from "../../actions/errors";
import connect from "react-redux/es/connect/connect";
import JobPaymentSummary from '../common/JobPaymentSummary'
import JobInProgressDetailsPageHeaderLink from '../common/JobInProgressDetailsPageHeaderLink'
import {InstallAppText, UserAndProfessionalCardOnJobInProgress} from "../professional/ProfessionalJobDetails";
import queryString from "query-string";

class UserChatToProfessionalAfterHire extends Component {
    constructor(props) {
        super(props);
        const {match} = props
        const {params} = match
        let {chatRoomId, jobId, userId, proposalId} = params
        chatRoomId = chatRoomId || ''
        this.state = {
            job_title: '',
            jobId,
            senderId: userId,
            proposalId: proposalId,
            chatRoomId,
            jobDetails: {},
            userAndProfessionalData: {},
            clientDetails: {},
            professionalDetails: {},
            jobTitle: '',
            proposalAmount: 0,
            responseData: {},
            userData: {},
            loading: false,
            jobStatus: '',
            productId: ''
        }
    }

    componentDidMount() {
        this.getUserAndProfessionalData()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data.client.id !== undefined) {
            this.setState({userData: nextProps.data})
        }
    }

    getUserAndProfessionalData = (e) => {
        const {proposalId} = this.props.match.params;
        this.setState({loading: true});
        const url = `/job/proposal-user-professional/${proposalId}/`;
        apiv1.auth.get(url)
            .then(resp => {

                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    const {Professional_details, user_details, job_details, fixed_amount} = data
                    const {product} = job_details
                    this.setState({
                        loading: false,
                        userAndProfessionalData: data,
                        professionalDetails: Professional_details,
                        clientDetails: user_details,
                        jobTitle: job_details.title,
                        proposalAmount: fixed_amount,
                        jobStatus: job_details.status,
                        jobDetails: job_details,
                        productId: product
                    })

                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    };

    updateAmountOnJobPaymentSummary = (updatedPaymentTerms) => {
        const {userAndProfessionalData} = this.state
        const updatedJobDetails = Object.assign({}, userAndProfessionalData, updatedPaymentTerms)
        this.setState({userAndProfessionalData: updatedJobDetails})
    }

    render() {
        const {userAndProfessionalData, professionalDetails, clientDetails, jobTitle, proposalAmount, productId, chatRoomId, jobId, senderId, proposalId, jobStatus, jobDetails,} = this.state;
        // const { isFromHiringSummary } = this.props.match.params
        const {isFromHiringSummary, isFromPaymentConfirm} = queryString.parse(this.props.location.search)
        const {Professional_details, user_details} = userAndProfessionalData || {}
        let avatar = '';
        let firstName = '';
        let lastName = '';
        if (localStorage.userType === USER && Professional_details) {
            avatar = getAvatar(Professional_details.avatar_thumbnail);
            firstName = Professional_details.first_name;
            lastName = Professional_details.last_name
        } else if (localStorage.userType === PROFESSIONAL && Professional_details) {
            avatar = getAvatar(userAndProfessionalData.user_details.client.avatar_thumbnail);
            firstName = user_details.first_name;
            lastName = user_details.last_name
        }

        return (

            <div id="PROGRESS_JOB" className="tab-pane fade in active">
                <div id="progress_list_case" className="ui-progress-job">
                    <div className="col-sm-9 job_title">
                        <div className="row">
                            {jobStatus &&
                            <JobInProgressDetailsPageHeaderLink jobId={jobId}
                                                                proposalId={proposalId}
                                                                userId={senderId}
                                                                chatRoomId={chatRoomId}
                                                                pageName="chat"
                                                                props={this.props}
                                                                jobStatus={jobStatus}
                                                                jobTitle={jobTitle}
                                                                productId={productId}
                                                                userAndProfessionalData={userAndProfessionalData}
                            />}
                            <p style={{fontWeight: 700}}>Start your Conversation. Say Hi ! to {professionalDetails?.first_name} {professionalDetails?.last_name}</p>
                            {jobStatus &&
                            <Message jobId={jobId}
                                     senderId={senderId}
                                     proposalId={proposalId}
                                     chatRoomId={chatRoomId}
                                     ishired={'true'}
                                     professionalDetails={professionalDetails}
                                     clientDetails={clientDetails}
                                     jobTitle={jobTitle}
                                     proposalAmount={proposalAmount}
                                     jobStatus={jobStatus}
                                     isSomeoneHired={false}
                                     isFromHiringSummary={!!isFromHiringSummary}
                                     isFromPaymentConfirm={!!isFromPaymentConfirm}
                                     updateAmountOnJobPaymentSummary={this.updateAmountOnJobPaymentSummary}
                            />
                            }

                        </div>

                    </div>
                    <div className="col-sm-3 no-padding d-padding-r">
                        {jobDetails.title !== undefined && <JobPaymentSummary jobId={jobId}
                                                                              proposalId={proposalId}
                                                                              totalAmount={userAndProfessionalData.fixed_amount}
                                                                              jobStatus={jobDetails.status}
                                                                              proposalSender={userAndProfessionalData.sender}
                                                                              wcFees={userAndProfessionalData.commission}
                                                                              wcGst={userAndProfessionalData.commission_tax}
                                                                              gstAmount={userAndProfessionalData.professional_amount_tax ? userAndProfessionalData.professional_amount_tax : 0}
                                                                              actual_amount_enter_by_professional={userAndProfessionalData.actual_amount_enter_by_professional}
                                                                              userAndProfessionalData={userAndProfessionalData}
                        />}
                        <div className="side-box-container">
                            {userAndProfessionalData.sender !== undefined &&
                            <UserAndProfessionalCardOnJobInProgress userAndProfessionalData={userAndProfessionalData}
                                                                    firstName={firstName} lastName={lastName}
                                                                    avatar={avatar}/>}
                        </div>
                        {/* <InstallAppText/> */}
                    </div>
                </div>

                {/* {localStorage.userType === USER && userAndProfessionalData.job_details !== undefined && userAndProfessionalData.job_details.status === "Completed" && (
                    <JobMarkCompletedPopup jobId={jobId} proposalId={proposalId} />
                )} */}
            </div>


        )
    }
}

const mapStateToProps = state => ({
    data: state.data,
});

const mapDispatchToProps = (dispatch) => ({
    ...(errorActionDispatchers(dispatch)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserChatToProfessionalAfterHire)

export const TRUE = 'true'