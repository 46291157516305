import React, { Component, useEffect, useState  } from 'react';
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import Root from './components/Root'
import reducer from './reducers';
import logger from 'redux-logger'
import { useHMSActions } from "@100mslive/react-sdk";

import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { HMSRoomProvider } from "@100mslive/react-sdk";
import ErrorBoundary  from "./ErrorBoundary";
import { HMSReactiveStore } from '@100mslive/hms-video-store';

const middleware = [thunk, logger];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducer,
    composeEnhancers(
        applyMiddleware(...middleware)
    )
)


class App extends Component {

  constructor() {
    super();
    setInterval(() => {
      const el = document.querySelector('iframe');
      if(el) {
        el.style.display = 'none';
      }
    }, 1000);
  }

  render() {
    console.log(':: ENV', process.env.REACT_APP_mainDomain);
    // 100MS Code for auto Leaving Start 
    // const hmsActions = useHMSActions();
    // useEffect(() => {
    //   window.onunload = () => {
    //     hmsActions.leave();
    //   };
    // }, [hmsActions]);
    // 100MS Code for auto Leaving End

    return (
      //<StrictMode>
       <ErrorBoundary>
        <HMSRoomProvider>

            <Provider store={store}>
              <Router>
                <Root />
              </Router>
            </Provider>

        </HMSRoomProvider>   
       </ErrorBoundary>
    //</StrictMode>
      );
  }
}

export default App;
