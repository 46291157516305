export const legalAndAccountingProfessions = {
    1 :{
        name: '',
    },
    2: {
        name: '',
    },
    3: {
        name: '',
    },
    4: {
        name: '',
    },
    5: {
        name: '',
    }
}

export const designerProfessionIds = {
    6 :{
        name: '',
    },
    7 :{
        name: '',
    },
    8 :{
        name: '',
    },
    9 :{
        name: '',
    },
    10: {
        name: ''
    },
    11: {
        name: ''
    }

}
export const legalAndAccounting = 'legalAndAccounting'
export const designer = 'designer'

export const professionalType = {
    legalAndAccounting: legalAndAccounting,
    designer: designer,
}

export function getProfessionalType({profession}){
    let professional_type = ''
    if (profession && Object.keys(legalAndAccountingProfessions).some(legalAndAccountingPractiseAreaId => {
        legalAndAccountingPractiseAreaId = parseInt(legalAndAccountingPractiseAreaId)
        return profession.includes(legalAndAccountingPractiseAreaId)})) {
        professional_type = professionalType.legalAndAccounting
    } else if (profession && Object.keys(designerProfessionIds).some(designerPractiseAreaId => {
        designerPractiseAreaId = parseInt(designerPractiseAreaId)
        return profession.includes(parseInt(designerPractiseAreaId))})){
        professional_type = professionalType.designer
    }
    return professional_type || ''
}

export function isContentWriter({profession}){
    profession = profession || []
    let isContentWriter = false
    for (let i=0; i<profession.length; i++ ){
        if (profession[i] === 10){
            isContentWriter = true
        }
    }
    return isContentWriter
}

export function isWebDeveloper({profession}){
    profession = profession || []
    let isWebDeveloper = false
    for (let i=0; i<profession.length; i++ ){
        if (profession[i] === 7){
            isWebDeveloper = true
        }
    }
    return isWebDeveloper
}