import TimeAgo from "react-timeago";
import React, {Fragment} from "react";
// const alphabetSmallChar = 'abcdefghijklmnopqrstuvwxyz'.split('');
export const alphabetLargeChar = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');
export const imageColors = ['#f1719d', '#6e76ec', '#56E0BA', '#f5ca6b'];
export function getFullName(first_name, last_name) {
    return ((first_name ? first_name : '') + " " + (last_name ? last_name : ''))
}

export function getAvatar(avatar) {
    return avatar && avatar !== 'null' && avatar!== 'undefined' ? avatar : ''
}

export function getCity(address) {
    return (address ? (address.state_data ? address.state_data.name : '') : '');
}
export function workExperiencePublic(experience) {
    return (experience ? (experience > 1 ? `${experience} years exp.` : `${experience} years exp.`) : '')
}
export function workExperiencePublicMob(experience) {
    return (experience ? (experience > 1 ? `${experience} years` : `${experience} years`) : '')
}
export function workExperience(experience) {
    return (experience ? (experience > 1 ? ` | ${experience} yrs exp.` : ` | ${experience} yrs exp.`) : '')
}
export function workExperienceDesigner(experience) {
    return (experience ? (experience > 1 ? ` ${experience} Yrs Exp.` : ` ${experience} Yrs Exp.`) : '')
}

export function workExperienceIcon(experience) {
    return  (
        <Fragment>
            {experience ? <img src={require('../../static/images/experiance.png')} alt="icon"/> : ''}
            {(experience ? (experience > 1 ? `${experience} yrs exp.` : `${experience} yrs exp.`) : '')}
        </Fragment>
    )
}

export function getTime(createdAt) {
    return <TimeAgo date={createdAt} />
}

export function getReview(review) {
    return (review ? (review > 1 ? ` | ${review} reviews` : ` | ${review} review`) : '')
}

export function bio(bio) {
    return (bio ? bio : '')
}

export function getPhoneNo(phone){
    if (phone){
        if (phone.phone !== undefined){
            return phone.phone.substring(3)
        }
    }
    return ''
}

export function getJobDone(jobCount) {
    return (jobCount ? (jobCount > 1 ? ` | ${jobCount} jobs done` : ` | ${jobCount} job done`): '')
}
export function getJobDoneIcon(jobCount) {
    return  (
        <Fragment>
            {jobCount ? <img src={require('../../static/images/job-done.png')} alt="icon"/> : ''}
            {(jobCount ? (jobCount > 1 ? `${jobCount} jobs done` : `${jobCount} job done`): '')}
        </Fragment>
    )
}
export function getFirstAndLastLetterOfName(first_name, last_name){
    let firstChar = first_name ? first_name.charAt(0).toUpperCase(): '';
    let lastChar = last_name ? last_name.charAt(0).toUpperCase(): '';
    let backGround = '#8927BE';
    if ((alphabetLargeChar.indexOf(firstChar) >= 0)) {
        backGround = imageColors[((alphabetLargeChar.indexOf(firstChar))%4)]
    }
    const style = {
        width: '55px',
        height: '55px',
        borderRadius: '50%',
        background: backGround,
        padding:'5px',
        paddingTop:'7px',
        color: '#fff',
        textAlign:'center',
        verticalAlign:'bottom',
        margin:'auto'
    };
    return (
        <div className="blank-dp" style={style}>
            <span style={{fontSize:'20px',marginTop:'20px'}}>
            {firstChar +  lastChar}
            </span>
            <img src="" className="img-circle hidden" />
        </div>
    )
}
