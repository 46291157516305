import React, {Component, Fragment} from "react";
import {apiv1} from "../../api";
import LoadingComponent from "../../utils/LoadingComponent";
import {errorActionDispatchers} from "../../actions/errors";
import connect from "react-redux/es/connect/connect";
import localStorage from './../../utils/localStorage'
import {
    getFirstAndLastLetterOfName,
    // workExperiencePublic,
    workExperiencePublicMob
} from "../common/userAndProfessionalProfileFunctions";
// import {htmlToString} from './../../functions/htmlToString'
import {ChatResponceTime, MinimulBadgeUi, ratingUi, SamplesUi} from "../functional/beforeHire";
import * as path from '../../constants/path'
import SiteHeader from "../SiteHeader";
import {parentPracticeAreaSlug, siteLogoOnSharedMedia} from '../common/bannerTextForProducts'
import $ from "jquery"
import Link from "react-router-dom/es/Link";
import {dataWithCalculatedCommission, getGeoInfo, roundOffCommission} from '../../utils/clientSide'
// import {consultationForProduct} from "../user/ProductOnBoarding";
// import Interweave from "interweave";
// import {professionalStatus} from "../../constants/job";
import {CreateProfessionalAccountPopUp} from "../user/GeneralOnboarding";
import {professional_busy, signUpAndChat} from "../services/BusinessFormationPvtLtd";
import {GetFirstNameAndLastName, ParseHtmlToString, ShortDescription} from "../../functions/common";
import {indiaCallingCode, mainDomain, siteBaseUrl} from "../../constants/common";
import {professionalType as professional_type} from './../../constants/businessPractiseArea'
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton,
} from 'react-share';
import {
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon,
    EmailIcon,
} from 'react-share';
import {getProfessionalType} from "../../constants/businessPractiseArea";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import * as apiUrls from './../../api/apiUrls'
// import {noOfSamples} from "./DesignerSingleProduct";
import queryString from "query-string";
// import {LogoHeader} from './../functional/beforeHire'
import moment from "moment";
import {samplesData} from "../../utils/clientSide";
import OnboardingSignup from "./../user/OnboardingSignup";
import {USER} from "../../constants/auth";
import {parsePhoneNumber} from "react-phone-number-input";
import {getProfile} from "../../reducers/auth";
import socket from "../common/Socket";
import popupMessages from "../../constants/popupMessages";
import {professionalStatus} from "../../constants/job";
import * as apiUrl from "../../api/apiUrls";
import Interweave from "interweave";
import Header from "../functional/beforeHire";
import {freelancers} from "../../constants/keyWord";
import {validateCouponCode} from "../../api/commonApi";

export function showMoreList() {
    $('.read-more-link').click(function () {
        $('.package-card-body li:hidden').slice(0, 2).show()
        if ($('.package-card-body li').length == $('.package-card-body li:visible').length) {
            $('.read-more-link').hide();
        }
    })
}

export function readMoreLess({maxLength, navUrl}) {
    $(".show-read-more").each(function () {
        let myStr = $(this).text();
        if ($.trim(myStr).length > maxLength) {
            let newStr = myStr.substring(0, maxLength);
            let removedStr = myStr.substring(maxLength, $.trim(myStr).length);
            $(this).empty().html(newStr)
            $(this).append('<a href="javascript:void(0);" class="read-more">...read more</a>')
            $(this).append('<span class="more-text">' + removedStr + '</span>');
        }
    });
    $(".read-more").click(function () {
        $(this).siblings(".more-text").contents().unwrap()
        $(this).remove()
    });
}

function metaTagForPublicProfile({title, metaDescription, link, professionalAvatar, firstName, lastName, bioDetailed , state, bioCaption}) {
    link = siteBaseUrl.substring(0, siteBaseUrl.length - 1) + link
    title = `${firstName} ${lastName} | ${bioCaption} from ${state}, India - Hire me on Workbeam`
    professionalAvatar = professionalAvatar ? professionalAvatar : siteLogoOnSharedMedia
    metaDescription = ` Hi, I am ${firstName} ${lastName}, ${bioCaption} in ${state}, India. Book my services on Workbeam. Get 100% Refund Protection, Pay in Instalments, Track Service with dedicated support.`
    // metaDescription = htmlToString(bioDetailed)
    return <Header link={link} avatar={professionalAvatar} metaDescription={metaDescription} title={title}/>

}

export function ShareProfile({link, WhatsappTitle, popupHeading}) {
    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-body">
                    <div className="col-sm-12">
                        <div className="text-center">
                            <h4 className="modal-heading"
                                style={{margin: '10px 0px', textAlign: 'left'}}>{popupHeading}</h4>
                            <p className="hidden-xs">&#x00A0;</p>
                            <ul className="list-inline">
                                <li>
                                    <FacebookShareButton url={link}>
                                        <FacebookIcon size={42} round={true}/>
                                    </FacebookShareButton>
                                </li>
                                <li>
                                    <WhatsappShareButton url={link} title={WhatsappTitle || ''} separator={`\n`}>
                                        <WhatsappIcon size={42} round={true}/>
                                    </WhatsappShareButton>
                                </li>
                                <li>
                                    <LinkedinShareButton url={link}>
                                        <LinkedinIcon size={42} round={true}/>
                                    </LinkedinShareButton>
                                </li>
                                <li>
                                    <TwitterShareButton url={link}>
                                        <TwitterIcon size={42} round={true}/>
                                    </TwitterShareButton>
                                </li>
                                <li>
                                    <EmailShareButton url={link}>
                                        <EmailIcon size={42} round={true}/>
                                    </EmailShareButton>
                                </li>

                            </ul>
                            <p className="para text-center">Or</p>
                            <ul className="list-inline">
                                <li>
                                    <a className="btn btn-default"
                                       id="copy"
                                       onClick={() => {
                                           var dummy = document.createElement("input");
                                           document.body.appendChild(dummy);
                                           dummy.setAttribute("id", "copy_share_link");
                                           document.getElementById("copy_share_link").value = link
                                           dummy.select();
                                           document.execCommand("copy")
                                           document.body.removeChild(dummy)
                                           let x = document.getElementById("snackbar");
                                           x.innerHTML = 'Link Copied'
                                           x.className = "show"
                                           setTimeout(function () {
                                               if (x) {
                                                   x.className = x.className.replace("show", "");
                                               }
                                           }, 1500);
                                       }}
                                    >
                                        {/*<span className="link-icon"><i className="fa fa-link" aria-hidden="true"></i></span>*/}
                                        Copy Link
                                    </a>
                                </li>
                            </ul>
                            <div id='snackbar' className='snackbar' />
                            <p className="hidden-xs">&#x00A0;</p>
                        </div>
                    </div>
                    <div className="text-center" style={{margin: '15px'}}>
                        <button type="button" className="btn btn-save-profile" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

class ProfessionalPublicProfile extends Component {
    constructor(props) {
        super(props)
        let {professionalSlug} = props.match.params
        const practiceArea = Object.keys(parentPracticeAreaSlug).map((key) => {
            return {
                id: Number(key),
                name: parentPracticeAreaSlug[key].name,
                parent_practice_area_slug: parentPracticeAreaSlug[key].slug
            }
        })
        const {history} = props
        const {location} = history
        const {search} = location
        let affiliateMarketingDetails = {}
        let {isFromDashboard, coupon_code} = queryString.parse(search)
        let discountCouponCode = affiliateMarketingDetails.coupon_code || ''
        if (!discountCouponCode){
            discountCouponCode = coupon_code
        }
        let discountDetails = {coupon_code: discountCouponCode}
        this.state = {
            professionalDetails: {},
            professionalId: '',
            professionalSlug,
            practiceAreaPrice: [],
            practiceArea,
            reviews: [],
            productLink: '',
            avatar: '',
            avatar_thumbnail: '',
            firstName: '',
            lastName: '',
            bio_caption: '',
            bio_detailed: '',
            badge: {},
            professionalType: '',
            practise_area: [],
            isFromDashboard,
            professionalBlog: [],
            skill: [],
            userAndProData: {},
            isSignUpEnabled: false,
            discountCouponCode,
            discountDetails
        }
    }

    componentDidMount() {
        let {professionalSlug} = this.state
        if (professionalSlug) {
            this.getProfessionalData(professionalSlug)
        }
    }

    getPracticeAreaSelectedPrice = (professionalId) => {
        apiv1.noAuth.get(`${apiUrls.userProfessionalProductPublic}?professional=${professionalId}`)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch)
                if (data) {
                    let savedProducts = {}
                    // let practiceAreaPrice = []
                    const {discountDetails, isValidCouponCode} = this.state
                    const {is_valid_coupon_code} = discountDetails
                    let {ListData} = dataWithCalculatedCommission({
                        results: data,
                        discountDetails,
                        isValidCouponCode: is_valid_coupon_code,
                        _renderVideo: () => {
                        },
                        parentComponent: this,
                    })
                    ListData = ListData || []

                    // data.filter(productDetails => {
                    //     const {product_details, is_core_legal_tax_product} = productDetails
                    //     const {practise_area_name, practise_area_slug, practise_area} = product_details
                    //     if ((professionalType === professional_type.designer && is_core_legal_tax_product) || (professionalType === professional_type.legalAndAccounting && !is_core_legal_tax_product)) {
                    //         return
                    //     }
                    //     if (!savedProducts[practise_area]) {
                    //         savedProducts[practise_area] = {
                    //             name: practise_area_name,
                    //             slug: practise_area_slug,
                    //             id: practise_area,
                    //             children: []
                    //         }
                    //     }
                    //     if (Number(productDetails.price) > 0) {
                    //         const {children} = savedProducts[practise_area]
                    //         let {commission, commission_tax, professional_amount_tax, samples_migrated, service_fee} = productDetails
                    //         professional_amount_tax = 0
                    //         let price = Number(productDetails.price)
                    //         let actual_amount_enter_by_professional = price
                    //         commission = 0
                    //         let rounded_of_values = roundOffCommission({
                    //             commission_tax,
                    //             actual_amount_enter_by_professional,
                    //             service_fee
                    //         })
                    //         price = rounded_of_values.price
                    //         commission_tax = rounded_of_values.commission_tax
                    //         actual_amount_enter_by_professional = rounded_of_values.actual_amount_enter_by_professional
                    //         const productDetailsWithCommission = {
                    //             ...productDetails,
                    //             commission,
                    //             commission_tax,
                    //             professional_amount_tax,
                    //             price,
                    //             actual_amount_enter_by_professional,
                    //             service_fee
                    //         }
                    //         let samples = samples_migrated || [];
                    //
                    //         if (samples.length > 0) {
                    //             samples = samplesData({
                    //                 samples, _renderVideo: () => {
                    //                 }, parentComponent: this
                    //             })
                    //             productDetailsWithCommission['samples'] = samples
                    //             children.push(productDetailsWithCommission)
                    //         }
                    //         // children.push(productDetailsWithCommission)
                    //     }
                    // })
                    // for (let key in savedProducts) {
                    //     const {children} = savedProducts[key]
                    //     if (children.length > 0) {
                    //         practiceAreaPrice.push(savedProducts[key])
                    //     }
                    // }
                    let practiceAreaPrice = [
                        {children: ListData}
                    ]
                    this.setState({practiceAreaPrice, loading: false})
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })

    }
    getProfessionalBlog = (professionalId) => {
        apiv1.auth.get(`learning-center/professional-blog-public?professional=${professionalId}`)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch)
                if (data) {
                    this.setState({professionalBlog: data})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }
    checkDiscountCoupon = ({cb, professionalId}) => {
        const {productId, packageIds, discountDetails, professionalDetails} = this.state
        const {coupon_code} = discountDetails
            let promise = new Promise((resolve, reject) => {
                if (coupon_code) {
                    validateCouponCode({isLoggedIn: false, productId, discountDetails, professionalDetails})
                        .then((props) => {
                            const {newStateObj} = props
                            this.setState(newStateObj, () => {
                                resolve()
                            })
                        })
                } else {
                    resolve()
                }
            })
            promise.then(() => {
                if (!localStorage.country) {
                    getGeoInfo({cb: () => cb(professionalId)})
                } else {
                    cb(professionalId)
                }
            })
                .catch((props) => {
                    this.setState({loading: false})
                })

    }
    getProfessionalData = (professionalSlug) => {
        this.setState({loading: true})
        apiv1.noAuth.get(`${apiUrls.professionalPublicProfileBySlug}/${professionalSlug}`)
            .then(resp => {
                let data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    let {name, bio_caption, bio_detailed, id, reviews, badge, avatar, avatar_thumbnail, profession, practise_area_details, practise_area_parent, rating} = data
                    let practiseArea = {}
                    let Practise_area = []
                    let professionalType = getProfessionalType({profession})

                    practise_area_details.map(practiseAreaDetails => {
                        const {id, parent, is_core_legal_tax, parent_name, name, icon} = practiseAreaDetails
                        if ((professionalType === professional_type.designer && is_core_legal_tax) || (professionalType === professional_type.legalAndAccounting && !is_core_legal_tax)) {
                            return
                        }
                        if (!parent && !practiseArea[id]) {
                            practiseArea[id] = {
                                parent_name,
                                icon,
                                children: []
                            }
                            return
                        }
                        if (!practiseArea[parent]) {
                            practiseArea[parent] = {
                                parent_name,
                                children: []
                            }
                        }
                        let {children} = practiseArea[parent]
                        children.push({
                            name,
                            id,
                        })
                    })
                    for (let key in practiseArea) {
                        const {children} = practiseArea[key]
                        if (children.length > 0) {
                            Practise_area.push(practiseArea[key])
                        }
                    }
                    const namArr = name.split(' ');
                    const firstName = namArr[0];
                    const lastName = namArr[namArr.length - 1];
                    data = {
                        ...data,
                        rating: professionalType !== professional_type.legalAndAccounting ? rating : undefined
                    }
                    this.setState({
                            professionalDetails: data,
                            avatar,
                            avatar_thumbnail,
                            firstName,
                            lastName,
                            bio_caption,
                            bio_detailed,
                            badge,
                            professionalId: id,
                            reviews: professionalType !== professional_type.legalAndAccounting ? reviews : [],
                            professionalType,
                            practise_area: Practise_area,
                            skill: practise_area_details
                        }, () => {
                            if (!localStorage.country) {
                                getGeoInfo({cb: () => this.checkDiscountCoupon({professionalId: id, cb: this.getPracticeAreaSelectedPrice})})
                            } else {
                                this.checkDiscountCoupon({professionalId: id, cb: this.getPracticeAreaSelectedPrice})
                            }
                            // this.getProfessionalBlog(id)
                            readMoreLess({maxLength: 350})
                            // console.log('state', this.state.practise_area)
                        }
                    )
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    createChatRoom = () => {
        const {professionalDetails, userAndProData} = this.state
        const {client} = userAndProData
        const {id} = client
        const {doDispatch} = this.props
        let apiPath = `${apiUrl.createChatRoomWithoutProfessionalProduct}${id}/${professionalDetails.id}`
        apiv1.auth.get(apiPath)
            .then(res => {
                const data = apiv1.parseAndAutoHandleIssues(res, doDispatch)
                if (data) {
                    this.navigateToChat()
                }
            })
            .catch(err => {
                this.setState({loading: false})
                apiv1.handleErrors(err, doDispatch);
            })
    }

    initializeSocket = () => {
        const {professionalDetails} = this.state
        let {professionalUserId, professionalProductId} = professionalDetails || {}
        this.socket = socket.startSocketConnection({
            onSocketOpen: this.onSocketConnect,
            onConnectionError: this.onSocketConnectionError,
            // onConnectionLost: this.onSocketConnectionLost,
            counterPartyUserId: professionalUserId,
            professionalProductId,
            is_one_to_one: true
        })
    };

    onSocketConnectionError = () => {
        const {doDispatch} = this.props
        apiv1.showPopupError(popupMessages.socketConnectionError, doDispatch)
        this.setState({loading: false})
    }

    onSocketConnectionLost = () => {
        this.setState({loading: false})
    }

    navigateToChat = () => {
        const {history} = this.props
        const {professionalDetails} = this.state
        let {professionalUserId} = professionalDetails || {}
        history.push(`${path.myChatHistory}?isLastModifiedChatOpen=${true}&receiverUserId=${professionalUserId}`)
    }

    onSocketConnect = () => {
        this.navigateToChat()
    }

    // onSocketConnect = () => {
    //     const {history} = this.props
    //     const {professionalDetails} = this.state
    //     let {professionalUserId} = professionalDetails || {}
    //     history.push(`${path.myChatHistory}?isLastModifiedChatOpen=${true}&receiverUserId=${professionalUserId}`)
    // }

    restAuthPatchForUserTypeSwitch = ({request}) => {
        const {doDispatch} = this.props
        apiv1.auth.patch(apiUrl.restAuth, request)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                if (data) {
                    this.updateProfileAndPostJob({data})
                }
            })
            .catch(err => {
                this.setState({loading: false})
                apiv1.handleErrors(err, doDispatch)
            })
    }

    switchAsClientProfile = ({}) => {
        const request = {
            client: {
                last_session_as_client: true
            }
        }
        this.restAuthPatchForUserTypeSwitch({request})
    }

    updateProfileAndPostJob = ({data}) => {
        let userAndProData = {...this.state.userAndProData}
        localStorage.userType = USER
        let phone = ''
        let cityId = ''
        userAndProData = {
            ...userAndProData,
            ...data,
            client: {
                ...userAndProData.client,
                ...data.client,
            }
        }
        const {client} = userAndProData
        let {address} = client
        let country_calling_code = indiaCallingCode
        address = address || {}
        if (client.phone) {
            let {nationalNumber, countryCallingCode} = parsePhoneNumber(client.phone.phone || '') || {}
            nationalNumber = nationalNumber || ''
            country_calling_code = countryCallingCode
            phone = nationalNumber
        }
        if (address && address.city) {
            cityId = address.city
        }
        this.setState({loading: true, userAndProData}, () => {
            localStorage.logedInUserDetails = JSON.stringify(getProfile(userAndProData))
            this.createChatRoom()
        })
    }

    fetchUserAndProData = ({isSignUp}) => {
        this.setState({loading: true})
        apiv1.auth.get(apiUrl.restAuth)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    this.setState({userAndProData: data}, () => {
                        const {client} = data
                        const {is_default_professional} = client
                        if (is_default_professional) {
                            this.switchAsClientProfile({})
                        } else {
                            this.updateProfileAndPostJob({data})
                        }
                    })
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    };

    onProceedToPayment = ({onSignInWithEmail, signInWithPhoneNo, onSignUp}) => {
        const isFormValidated = true
        if (onSignInWithEmail) {
            onSignInWithEmail()
            return
        } else if (signInWithPhoneNo) {
            signInWithPhoneNo()
            return
        } else if (onSignUp) {
            onSignUp()
            return
        }
        if (isFormValidated) {
            if (localStorage.isSessionValid()) {
                this.fetchUserAndProData({isSignUp: false})
            } else {
                this.setState({isSignUpEnabled: true}, () => {
                    $('#direct_hire_signup').click()
                })
            }
        }
    }

    clickedOnDesignerPackageBook = ({}) => {
        // const {history} = this.props
        let {professionalDetails, firstName, lastName, avatar} = this.state
        const {professional, status, user_id} = professionalDetails
        if (status === professionalStatus.busy) {
            $('#notAvailableForWork').click();
            return
        }
        professionalDetails = {
            ...professionalDetails,
            avatar,
            first_name: firstName,
            last_name: lastName,
            professionalId: professional,
            professionalUserId: user_id,
        }
        this.setState({
            professionalDetails,
        }, () => {
            // const {isSignUpOnHire} = this.state
            localStorage.selectedProductDetails = JSON.stringify(professionalDetails)
            this.onProceedToPayment({})
        })
    }

    clientReviews = () => {
        const {reviews} = this.state;
        return (
            <div id='client_reviews' className='tab-pane fade in'>
                <div className="row">
                    <div className="col-sm-12">
                        <p className="xs-heading">CLIENT REVIEWS</p>
                        {reviews.map((review, i) => {
                            const {rating, user_name, project_name, comment} = review
                            let style = {};
                            if (i % 2 === 0) {
                                style = {
                                    content: '',
                                    clear: 'both',
                                    display: 'table'

                                }
                            }
                            return (<div className="col-sm-5 col-sm-offset-1" style={style} key={i}>
                                    <div className="text-name">{user_name}
                                        <label>
                                            {ratingUi(rating)}
                                        </label>
                                    </div>
                                    <p className="text-product-name">{project_name}</p>
                                    <p className="text-para">{comment}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    };

    courts = () => {
        const {professionalDetails} = this.state;
        return (
            <section className="court-section">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="panel panel-default">
                                <div className="panel-body">
                                    <p className="xs-heading">COURTS</p>
                                    <div className="col-sm-11 col-sm-offset-1">
                                        <ul className="list-inline">
                                            {
                                                professionalDetails.court.map(court => {
                                                    return <li key={court.name}>{court.name}</li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <p>&#x00A0;</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    };

    practiceArea = () => {
        const {practise_area} = this.state;
        return (
            <div id="expertise_areas_skills" className="tab-pane practice-area-section fade in">
                <div className="row">
                    <div className="chield-categor-box-p">
                        {practise_area.map((parentWithChild, index) => {
                            let {children, parent_name} = parentWithChild
                            children = children || []
                            return (
                                <div className="chield-categor-box-c">
                                    <p className="scope-sub-heading">{parent_name}</p>
                                    <ul className="list-unstyled chield-category">
                                        {children.map(ChildDetails => {
                                            const {name} = ChildDetails
                                            return <li>{name}</li>
                                        })}
                                    </ul>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
        )
    };

    gigListUi = () => {
        const {practiceAreaPrice, avatar_thumbnail, firstName, lastName, professionalId, professionalDetails, isFromDashboard, discountDetails} = this.state
        const {rating, state, work_experience, job_completed_count, chat_average_response_time, slug_new} = professionalDetails || {}
        const {coupon_code} = discountDetails
        return (
            <div className="row">
                {practiceAreaPrice.map(categoryList => {
                    let {children} = categoryList
                    children = children || []
                    return children.map(designerProductDetails => {
                        let {samples, product_details, calculated_commissions} = designerProductDetails
                        const {price} = calculated_commissions
                        samples = samples || []
                        const {slug, name} = product_details
                        let shareLink = `/${freelancers}/${slug_new}/${product_details.slug_new}`
                        if(isFromDashboard){
                            shareLink += `?isFromDashboard=${isFromDashboard}`
                            if (coupon_code){
                                shareLink += `&coupon_code=${coupon_code}`
                            }
                        } else {
                            if (coupon_code){
                                shareLink += `?coupon_code=${coupon_code}`
                            }
                        }
                        // shareLink += `?professionalId=${professionalId}&fee=${price}&professional=${`${firstName}-${lastName}`}${isFromDashboard ? `&isFromDashboard=${true}` : ''}`
                        return (
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="designer-profile-card common-card">
                                    <OwlCarousel
                                        className="owl-theme"
                                        loop={true}
                                        dots={false}
                                        margin={10}
                                        nav={true}
                                        navText={['<div class="fa fa-angle-left prev-slide" style="font-size:20px"></div>', '<div class="fa fa-angle-right prev-slide " style="font-size:20px"></div>']}
                                        items={1}
                                        responsiveClass={true}
                                        autoplay={false}
                                        autoplayTimeout={3000}
                                        autoplayHoverPause={true}
                                        lazyLoad={true}
                                    >
                                        <SamplesUi samples={samples}/>
                                    </OwlCarousel>
                                    <div className="profile-card-body">
                                        <div className="media">
                                            <div className="media-left">
                                                {avatar_thumbnail ? (
                                                        <img src={avatar_thumbnail} className="designer-dp img-circle"
                                                             alt="hire designer"/>)
                                                    :
                                                    getFirstAndLastLetterOfName(firstName, lastName)
                                                }
                                            </div>
                                            <div className="media-body">
                                                <h4 className="designer-name">
                                                    <GetFirstNameAndLastName firstname={firstName}
                                                                             lastname={lastName}/>
                                                    <span className="minimul-badge pull-right">
                                                        <span className="badge-gigzoe-product">
                                                            <ShortDescription text={name} maxCharLength={15}/>
                                                        </span>
                                                    </span>
                                                </h4>
                                                <p className="designer-city">{state}</p>
                                            </div>
                                            <ul className="list-inline text-left">
                                                {rating && <li className="span-text">
                                                        <span className="fa fa-star"/> <span
                                                    className="span-text">{rating.toFixed(1)}</span>
                                                    </li>
                                                }
                                                <li className="span-text">
                                                    <span>{work_experience} yrs Exp.</span></li>
                                                {job_completed_count > 0 &&
                                                <li className="span-text">
                                                    {job_completed_count} Orders
                                                </li>
                                                }
                                                {chat_average_response_time > 0 &&
                                                <li className="span-text">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor"
                                                         className="bi bi-clock hidden-xs" viewBox="0 0 16 16">
                                                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                                                    </svg> <ChatResponceTime chat_average_response_time={chat_average_response_time}/>
                                                </li>}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="profile-card-footer">
                                        <Link className="btn hire-designer-button" to={shareLink} title={`Starting
                                            $${price}`}>Starting
                                            ${price} <span
                                                className="pull-right">Continue <i
                                                className="fa fa-long-arrow-right hidden-xs"/></span></Link>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                })}
            </div>
        )
    }

    gigUi = () => {
        return (
            <div id="hire_for_services" className="tab-pane fade in active">
                {this.gigListUi()}
            </div>
        )
    }
    professionalProfileUi = () => {
        const {props} = this
        const {location} = props
        let {pathname} = location
        const {professionalDetails, firstName, lastName, avatar, avatar_thumbnail, reviews, badge, bio_caption} = this.state
        let {rating, repeat_clients, job_completed_count} = professionalDetails
        rating = rating || 0
        repeat_clients = repeat_clients || 0
        job_completed_count = job_completed_count || 0

        return (
            <div className="m-profile-box cp-professional">
                <div className="text-center" style={{padding: '10px'}}>
                    <div className="professional-image">
                        {avatar_thumbnail ? (
                                <img src={avatar_thumbnail} alt={avatar_thumbnail} className="img-circle profile-img"/>)
                            :
                            getFirstAndLastLetterOfName(firstName, lastName)
                        }
                    </div>
                    <p className="profile-name">
                        <GetFirstNameAndLastName firstname={firstName}
                                                 lastname={lastName}/>
                    </p>
                    <p className="bio-caption">{bio_caption}</p>

                    <p className="location"><i className="fa fa-map-marker"
                                               aria-hidden="true"/>&#x00A0;&#x00A0;{professionalDetails.state}
                    </p>
                    {(professionalDetails.public_education !== undefined && professionalDetails.public_education !== null) &&
                    <p className="location text-indent">&#x00A0;<i
                        className="fa fa-graduation-cap"
                        aria-hidden="true"/>&#x00A0;&#x00A0;{professionalDetails.public_education.institute}
                    </p>}
                    <ul className="list-inline text-center">
                        <li>
                            <a className="btn btn-invite btn-block"
                               onClick={() => this.clickedOnDesignerPackageBook({})}
                            >
                                Chat
                            </a>
                        </li>
                        <li>
                            <a
                                onClick={() => {
                                    this.setState({productLink: mainDomain.substring(0, siteBaseUrl.length - 1) + pathname}, () => {
                                        $('#share_product_on_social_media').click()
                                    })
                                }}
                                className="btn btn-share-profile btn-block">
                                <i className="fa fa-share-alt" aria-hidden="true"/> Share
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="statistic-box">
                    <p className="statistic-head">Statistics</p>
                    <table className="table">
                        <tbody>
                        {badge.priority > 0 &&
                        <tr>
                            <td>Badge</td>
                            <td className="text-center"><MinimulBadgeUi badge={badge}/></td>
                        </tr>
                        }
                        {professionalDetails.work_experience &&
                        <tr>
                            <td>Experience</td>
                            <td className="text-center">
                                <div className="stat-text">
                                    <span>{workExperiencePublicMob(professionalDetails.work_experience)}</span>
                                </div>
                            </td>
                        </tr>
                        }
                        {rating > 0 && (
                            <tr>
                                <td>Rating</td>
                                <td className="text-center">
                                    <div className="stat-text">
                                        <span>
                                            <span>
                                                {' ' + rating.toFixed(1) + ' '}
                                             </span>
                                            <label>
                                                <span className="fa fa-star"/>
                                            </label>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        )}
                        {reviews.length > 0 &&
                        <tr>
                            <td>Reviews</td>
                            <td className="text-center">

                                <div className="stat-text">
                                    <span>{reviews.length}</span>
                                </div>
                            </td>
                        </tr>
                        }
                        {job_completed_count > 0 &&
                        <tr>
                            <td>Jobs Completed</td>
                            <td className="text-center">

                                <div className="stat-text">
                                    <span>{job_completed_count}</span>
                                </div>
                            </td>
                        </tr>
                        }
                        {repeat_clients > 0 &&
                        <tr>
                            <td>Repeat clients</td>
                            <td className="text-center">
                                <div className="stat-text">
                                    <span>{repeat_clients}</span>
                                </div>
                            </td>
                        </tr>
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    render() {
        const {props} = this
        const {location} = props
        let {pathname} = location
        const {loading, professionalDetails, firstName, lastName, avatar, avatar_thumbnail, reviews, productLink, bio_detailed, bio_caption, state, practise_area, professionalBlog, isSignUpEnabled} = this.state

        return (
            <div>
                {metaTagForPublicProfile({
                    title: '',
                    link: pathname,
                    professionalAvatar: avatar_thumbnail,
                    firstName,
                    lastName,
                    bioDetailed: bio_detailed,
                    bioCaption: bio_caption,
                    state: professionalDetails.state
                })}
                {localStorage.isSessionValid() &&
                    <SiteHeader isServicesDataPassed={false} {...this.props} />}
                {loading &&
                <div className='lazy-loader' style={{position: 'fixed', zIndex: '10'}}><LoadingComponent pastDelay/>
                </div>}

                <section className="page-contant" style={{backgroundColor: '#f6eeeb', marginTop: '65px'}}>
                    <div className="container">
                        <div className="row display-flex">
                            <div className="col-sm-4 col-md-4">
                                <div className="hidden-xs">
                                    {this.professionalProfileUi()}
                                </div>
                                <div className="cp-professional">
                                    <p className="cp-head">Bio</p>
                                    <div className="cp-body">
                                        <p className="para">
                                            <Interweave
                                                tagName="span"
                                                content={bio_detailed}
                                            />
                                        </p>
                                        {/*{nl2br(bio_detailed)}</p>*/}
                                    </div>
                                </div>
                                {reviews.length > 0 &&
                                <div className="cp-professional">
                                    <p className="cp-head">Client Reviews</p>
                                    <div className="cp-body">
                                        <ul className="list-unstyled reviews-list">
                                            {reviews.map(details => {
                                                const {rating, user_name, project_name, comment} = details
                                                return (
                                                    <li>
                                                        <p className="text-name"> {user_name}
                                                            <label>{ratingUi(rating)}</label></p>
                                                        <p className="text-product-name">{project_name}</p>
                                                        <p className="text-para-reviews">{comment}</p>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                        {reviews.length > 5 &&
                                        <div className="text-center">
                                            <a className='btn btn-link'
                                               id={`see_more_package_info_hide`}
                                               onClick={() => {
                                                   $('.reviews-list li:hidden').slice(0, 3).show()
                                                   if ($('.reviews-list li').length === $('.reviews-list li:visible').length) {
                                                       $(`#see_more_package_info_hide`).hide()
                                                   }
                                               }
                                               }
                                            >
                                                Click To Read More
                                            </a>
                                        </div>
                                        }
                                    </div>
                                </div>
                                }
                            </div>
                            <div className="col-sm-8">
                                <div className="visible-xs">
                                    {this.professionalProfileUi()}
                                </div>
                                <div className="public-profile-box">
                                    <ul className="nav nav-tabs nav-justified">
                                        <li className="active">
                                            <a data-toggle="tab"
                                               href="#hire_for_services">Hire for Services</a>
                                        </li>
                                        <li><a data-toggle="tab" href="#expertise_areas_skills">Expertise Areas &
                                            Skills</a></li>
                                        {/*<li><a data-toggle="tab" href="#Blog">Blogs</a></li>*/}

                                    </ul>
                                    <div className="cp-body-box">
                                        <div className="tab-content">
                                            {this.gigUi()}
                                            {practise_area.length > 0 && this.practiceArea()}
                                            <div id="Blog" className='tab-pane'>
                                                <div className="row">
                                                    {professionalBlog.length > 0 ?
                                                        (
                                                            <Fragment>
                                                                {professionalBlog.map((lc, index) => {
                                                                    const {slug, document, category_details, user_details, heading, modified} = lc
                                                                    const {first_name, last_name} = user_details ? user_details : {}
                                                                    return (
                                                                        <div className="col-sm-6">
                                                                            <a href={path.categoryPage + '/' + category_details.slug + '/' + slug}>
                                                                                <div className="category-blog-box">
                                                                                    <div
                                                                                        className="category-blog-box-img">
                                                                                        {document && (
                                                                                            <img src={document}
                                                                                                 alt={slug}/>)}
                                                                                    </div>
                                                                                    <div className="blog-time">
                                                                                        <p className="blog-date">{moment(modified).format('ll')} {user_details &&
                                                                                        <span
                                                                                            className="pull-right">{first_name + ' ' + last_name}</span>}</p>
                                                                                    </div>
                                                                                    <h2 className="category-heading">
                                                                                        <ParseHtmlToString
                                                                                            htmlContent={heading}/>
                                                                                    </h2>
                                                                                    <div
                                                                                        className="category-read-more-box">
                                                                                        <p className="read-more-link">
                                                                                            Read More
                                                                                            <i className="fa fa-long-arrow-right"/>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </Fragment>
                                                        )
                                                        :
                                                        (
                                                            <p className="text-bold text-center">There are no blogs currently.</p>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p id='share_product_on_social_media' data-toggle="modal"
                       data-target="#share_product_on_social_media_popup" />
                    <div className="modal share-profile-modal" id="share_product_on_social_media_popup"
                         role="dialog">
                        <ShareProfile link={productLink} WhatsappTitle={``} popupHeading={`Share and show some love.`}/>
                    </div>
                    <p id="notAvailableForWork" data-toggle="modal"
                       data-target="#Professional-available-public" />
                    <div className="modal fade" id="Professional-available-public" role="dialog">
                        <CreateProfessionalAccountPopUp url={''} name={''}
                                                        image={require("../../static/images/sendProposalPopupVerification.png")}
                                                        message={professional_busy}/>
                    </div>
                    <p id='direct_hire_signup' data-toggle="modal" data-target="#direct_hire_signup_popup" />
                    <div className="modal center-modal" id="direct_hire_signup_popup" role="dialog">
                        {isSignUpEnabled &&
                        <OnboardingSignup
                            {...this.props}
                            isFromDirectHire={true}
                            submitRequest={this.onProceedToPayment}
                            city={[]}
                            setLoading={this.setLoading}
                            updateProfileAndPostJob={this.updateProfileAndPostJob}
                            signUpButtonName={signUpAndChat}
                            isHidingPasswordAndOptField={true}
                        />
                        }
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    professional: state.data.professional
});

const mapDispatchToProps = (dispatch) => ({
    ...(errorActionDispatchers(dispatch)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProfessionalPublicProfile)

