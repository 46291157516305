import React, { Component } from "react";
// import Secure from "../../static/images/Secure.png";
// import Verified from "../../static/images/verified.png";
// import $ from 'jquery'
import HiredIcon from "../../static/images/hired-icon.png";
import CreditCard from "../../static/images/credit-card.png";
import PostItIcon from "../../static/images/post-it-icon.png";
import ColoredBullseye from "../../static/images/colored-bullseye.png";

// export const AboutProfessionals = () => {
//     return (
//         <div className="row">
//                 <div className="col-sm-6">
//                     <div className="media">
//                         <div className="media-left media-top">
//                             <img src={Secure}
//                                 className="media-object"
//                                 style={{ width: '40px',height:'auto' }}
//                                 alt='User'
//                             />
//                         </div>
//                         <div className="media-body">
//                             <h4 className="media-heading text-dark">Safe & Secure</h4>
//                             <p className="text-dark">Your confidential information is encrypted to remain private, safe,
//                                 and secure.</p>
//                         </div>
//                     </div>
//                 </div>
//                 <p className='visible-xs'>&#x00A0;</p>
//                 <div className="col-sm-6">
//                     <div className="media">
//                         <div className="media-left media-top">
//                             <img src={Verified}
//                                 className="media-object"
//                                 style={{ width: '40px', height: 'auto' }}
//                                 alt='User'
//                              />
//                         </div>
//                         <div className="media-body">
//                             <h4 className="media-heading text-dark">Verified Professionals</h4>
//                             <p className="text-dark">Our lawyers are verified and approved through our screening
//                                 process.</p>
//                         </div>
//                     </div>
//                 </div>
//         </div>
//     )
// };

export class ImageSlider extends Component {
    constructor(props){
        super(props)

    }

    render() {
        return (

            <div className="col-sm-4 carousel-width hidden-xs ">
                <div id="main-slider" className="carousel slide carousel-fade" data-ride="carousel">
                    <div className="carousel-inner" role="listbox">
                        <div className="item active">
                            <div className="carousel-caption">

                                <h3 className="text-white">{this.props.message[0]}</h3>
                            </div>
                        </div>
                        <div className="item">
                            <div className="carousel-caption">

                                <h3 className="text-white">{this.props.message[1]}</h3>
                            </div>
                        </div>
                        <div className="item">
                            <div className="carousel-caption">

                                <h3 className="text-white">{this.props.message[2]}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}
export function HowItWorkSection(props){
    return(
        <div className="col-sm-5 left-offset">
            <h4 className="heading-sm">What will happen next</h4>
            <p className="service-text">Don't worry, we make it quick & easy.</p>
            <div className="common-top-bottom-margin">
                <div className="media">
                    <div className="media-left">
                        <img src={PostItIcon} style={{width:'40px'}} alt="icon"/>
                    </div>
                    <div className="media-body">
                        <h3 className="scope-sub-heading">Tell us about your legal/Accounting need</h3>
                        <p className="xs-para">Sign up and tell us about your legal & Accounting project. It takes less than a minute.</p>
                    </div>
                </div>
            </div>
            <div className="common-top-bottom-margin">
                <div className="media">
                    <div className="media-left">
                        <img src={HiredIcon} style={{width:'40px'}} alt="icon"/>
                    </div>
                    <div className="media-body">
                        <h3 className="scope-sub-heading">Get free Proposals</h3>
                        <p className="xs-para">Our team gets you 4-5 proposals and help you hire the right Expert based on Price, Location and Profile.</p>
                    </div>
                </div>
            </div>
            <div className="common-top-bottom-margin">
                <div className="media">
                    <div className="media-left">
                        <img src={CreditCard} style={{width:'40px'}} alt="icon"/>
                    </div>
                    <div className="media-body">
                        <h3 className="scope-sub-heading">Simplified Secure Payments</h3>
                        <p className="xs-para">Make Payment. We hold the payment in our escrow. Get Workbeam Payment Insurance.</p>
                    </div>
                </div>
            </div>
            <div className="common-top-bottom-margin">
                <div className="media">
                    <div className="media-left">
                        <img src={ColoredBullseye} style={{width:'40px'}} alt="icon"/>
                    </div>
                    <div className="media-body">
                        <h3 className="scope-sub-heading">Collaborate &amp; Get work done</h3>
                        <p className="xs-para">Communicate, Share files, track milestones and get work done.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
