import React, {Component} from "react";
import {clearAndShowError, errorActionDispatchers} from "../../actions/errors";
import connect from "react-redux/es/connect/connect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment";
import {apiv1} from "../../api";
import LoadingComponent from "../../utils/LoadingComponent";
import $ from "jquery";
import axios from 'axios'
import {TEXT} from "../user/OnBoarding";
import {getSocketRequestData} from "./Message";
import {messageType} from "../../constants/common";
import * as apiUrl from "../../api/apiUrls";

class Milestones extends Component {
    constructor(props) {
        super(props)
        const {proposalId, ishired, proposalAmount} = props

       
        const productId = props.productId
        this.state = {
            milestones: [],
            loading: false,
            milestoneInputs: [],
            customMilestones: [],
            updatedSuccessfully: false,
            ishired,
            proposalId,
            milestoneBeforeHire: {},
            isAllMilestoneCompleted: false,
            productId,
            errors: {
                editMilestone: []
            }
        }
    }

    componentDidMount() {
        const {isFromJobTrack, jobTrackDetails, ishired, customMilestones, productId} = this.props
        const {proposalId} = this.state
        if ((ishired && isFromJobTrack !== true)) {
            this.getMilestones(proposalId)
        } else if (ishired && isFromJobTrack === true) {
            const milestoneInputs = this.filterMilestoneInput([...customMilestones, ...jobTrackDetails])
            const isAllMilestoneCompleted = this.isAllMilestoneCompleted([...customMilestones, ...jobTrackDetails])
            this.setState({
                milestoneInputs,
                milestones: jobTrackDetails,
                isAllMilestoneCompleted,
                customMilestones,
                productId
            })
        } else {

        }
    }

    isAllMilestoneCompleted = (milestones) => {
        for (let i = 0; i < milestones.length; i++) {
            if (milestones[i].completed_date === null) {
                return false
            }
        }
        return true
    };

    componentWillReceiveProps(nextProps) {
        const {jobTrackDetails, customMilestones, productId} = nextProps
        if (jobTrackDetails !== undefined) {
            const milestoneInputs = this.filterMilestoneInput([...customMilestones, ...jobTrackDetails])
            const isAllMilestoneCompleted = this.isAllMilestoneCompleted([...customMilestones, ...jobTrackDetails]);
            this.setState({
                milestones: jobTrackDetails,
                milestoneInputs,
                isAllMilestoneCompleted,
                customMilestones,
                productId
            })
        }
    }

    getJobMilestonesBeforeHire = () => {
        this.setState({loading: true});
        const url = `/job/proposal/${this.state.proposalId}/`;
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    const milestoneInputs = this.filterMilestoneBeforeHireInput(data.milestone_json);
                    this.setState({
                        loading: false,
                        milestoneBeforeHire: data,
                        milestoneInputs,
                        milestones: data.milestone_json
                    })
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            });
    }

    getMilestones = (proposalId) => {
        const self = this
        axios.all([this.getCustomMilestone(1, self), this.getDefaultMilestones(1, self)])
            .then(axios.spread(function (customMilestoneDataResp, byDefaultMilestoneDataResp) {
                const customMilestoneData = apiv1.parseAndAutoHandleIssues(customMilestoneDataResp, self.props.doDispatch)
                const byDefaultMilestoneData = apiv1.parseAndAutoHandleIssues(byDefaultMilestoneDataResp, self.props.doDispatch)
                let isAllMilestoneCompleted = self.isAllMilestoneCompleted([...customMilestoneData, ...byDefaultMilestoneData])
                const milestoneInputs = self.filterMilestoneInput([...customMilestoneData, ...byDefaultMilestoneData])
                const productId = byDefaultMilestoneData.length > 0 ? 'dummy' : ''
                self.setState({
                    loading: false,
                    customMilestones: customMilestoneData,
                    milestones: byDefaultMilestoneData,
                    isAllMilestoneCompleted,
                    milestoneInputs,
                    productId,
                    proposalId
                })
            })).catch((err) => {
            this.setState({loading: false});
            apiv1.handleErrors(err, this.props.doDispatch)
        })
    }

    getCustomMilestone = (page, self) => {
        const {jobId, proposalId} = self.props
        return apiv1.auth.get(`job/job-milestone-custom/?proposal=${proposalId}`)
    }

    getDefaultMilestones = (page, self) => {
        const {jobId} = self.props
        const url = `/job/job-milestone/?job=${jobId}`
        return apiv1.auth.get(url)
    };

    filterMilestoneInput = (milestones) => {
        return milestones.map(milestoneDetails => {
            let {amount, due_date, id, text, milestone_practise_area_detail, notes} = milestoneDetails
            text = text || ''
            notes = notes || ''
            if (milestone_practise_area_detail) {
                text = milestone_practise_area_detail.text
            }
            return {
                amount,
                due_date,
                id,
                text,
                notes
            }
        })
    };

    filterMilestoneBeforeHireInput = (milestones) => {
        const milestoneInput = [];
        for (let i = 0; i < milestones.length; i++) {
            let milestone = {};
            milestone.amount = milestones[i].amount;
            milestone.due_date = milestones[i].due_date;
            milestone.job_id = milestones[i].job_id;
            milestone.completed_date = milestones[i].completed_date;
            milestone.amount_currency = milestones[i].amount_currency;
            milestone.milestone_practise_area_id = milestones[i].milestone_practise_area_id;
            milestone.milestone_practise_area_detail = {
                id: milestones[i].milestone_practise_area_detail.id,
                text: milestones[i].milestone_practise_area_detail.text,
            };
            milestoneInput.push(milestone)
        }
        return milestoneInput
    };

    onChange = (e, index, inputType, isCustomMilestone) => {
        const milestoneInputs = [...this.state.milestoneInputs]
        if (inputType === AMOUNT || inputType === TEXT || inputType === NOTES) {
            const {value} = e.target
            if (inputType === AMOUNT) {
                inputType = inputType.toLowerCase()
            }
            milestoneInputs[index][inputType] = value
        }  else {
            if (isCustomMilestone) {
                milestoneInputs[index].due_date = new Date(e).toISOString()
            } else {
                milestoneInputs[index].due_date = new Date(e).toISOString()
            }
        }
        this.setState({milestoneInputs})
    }

    updateDefaultMilestonesApi = (request) => {
        const {doDispatch, jobId} = this.props
        const url = `/job/milestone-bulk-update/${jobId}`
        return apiv1.auth.post(url, request)
            .then(resp => {
                return apiv1.parseAndAutoHandleIssues(resp, doDispatch).milestone

            })
    }

    bulkUpdateCustomMilestonesApi = (request) => {
        const {doDispatch, jobId} = this.props
        const url = `/job/milestone-custom-bulk-update/${jobId}`
        return apiv1.auth.post(url, request).then(resp => {
            return apiv1.parseAndAutoHandleIssues(resp, doDispatch);
        })
    }

    sendSocketDataViaRoomId = ({data}) => {
        const {type, message} = data
        const {chatRoomId} = this.props
        const request = {
            room: chatRoomId,
            chat: {
                message,
                message_type: type
            }
        }
        apiv1.auth.post(apiUrl.insertSocketData, request)
            .then(resp => {

            })
            .catch(err => {
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    updateMilestone = () => {
        const {milestones, milestoneInputs, customMilestones} = this.state
        let updateDefaultMilestones = []
        let updateCustomMilestones = []
        // const proposalAmount = this.props.proposalAmount
        let totalProposalAmount = 0;
        const customMilestonesLength = customMilestones.length
        let message = "The following milestones are updated."
        const self = this
        for (let i = 0; i < customMilestones.length; i++) {
            if (milestoneInputs[i].text !== customMilestones[i].text || milestoneInputs[i].amount !== customMilestones[i].amount || milestoneInputs[i].due_date !== customMilestones[i].due_date || milestoneInputs[i].notes !== customMilestones[i].notes) {
                message = message + '\n\n' + 'Milestone ' + ' : ' + customMilestones[i].text
                if (milestoneInputs[i].text !== customMilestones[i].text) {
                    message = message + '\n' + 'Text changed to' + ' : ' + milestoneInputs[i].text
                }
                if (milestoneInputs[i].amount !== customMilestones[i].amount) {
                    message = message + '\n' + 'Amount' + ' : ' + '$' + milestoneInputs[i].amount
                }
                if (milestoneInputs[i].due_date !== customMilestones[i].due_date) {
                    message = message + '\n' + 'Due date' + ' : ' + moment(milestoneInputs[i].due_date).format('LL')
                }
                if (milestoneInputs[i].notes !== customMilestones[i].notes) {
                    message = message + '\n' + 'Remarks' + ' : ' +  milestoneInputs[i].notes
                }
                const updatedMilestone = {
                    ...customMilestones[i],
                    ...milestoneInputs[i],
                    index: i,

                }
                updateCustomMilestones.push(updatedMilestone)
            } else {
                totalProposalAmount = totalProposalAmount + Number(milestoneInputs[i].amount);
            }
        }

        const total = milestoneInputs.reduce((acc, milestone) => Number(acc)+Number(milestone?.amount), 0);
        if(total > Number(this.props.proposalAmount)){
            this.setState({...this.state, errors: { editMilestone: ['Sum of Milestone amount cannot be greater than full payment']}});
            this.props.doDispatch(clearAndShowError('Sum of Milestone amount cannot be greater than full payment'))
            return ;
        } else {
            this.setState({...this.state, error: { editMilestone: [] }});
        }

        for (let i = 0; i < milestones.length; i++) {
            let newIndex = i + customMilestonesLength
            if (milestoneInputs[newIndex].amount !== milestones[i].amount || milestoneInputs[newIndex].due_date !== milestones[i].due_date) {
                message = message + '\n\n' + 'Milestone ' + ' : ' + milestones[i].milestone_practise_area_detail.text + '\n'
                if (milestoneInputs[newIndex].due_date !== milestones[i].due_date) {
                    message = message + 'Due date' + ' : ' + moment(milestoneInputs[newIndex].due_date).format('LL')
                }
                updateDefaultMilestones.push(
                    {
                        ...milestoneInputs[newIndex],
                        index: i,
                    }
                )
            } else {
                totalProposalAmount = totalProposalAmount + Number(milestoneInputs[i].amount)
            }
        }
        if (updateDefaultMilestones.length !== 0 || updateCustomMilestones.length !== 0) {
            let copyOfUpdateDefaultMilestones = [...updateDefaultMilestones]
            let copyOfUpdateCustomMilestones = [...updateCustomMilestones]
            let newCustomMilestones = [...customMilestones]
            let isBulkUpdate = false
            let isCustomUpdate = false
            const requestApi = []
            if (updateDefaultMilestones.length !== 0) {
                updateDefaultMilestones = updateDefaultMilestones.map(milestone => {
                    const {amount, due_date, id, text, notes} = milestone
                    return {
                        amount: amount ? amount : 0,
                        due_date,
                        id,
                        text,
                        notes
                    }
                })
                const request = {
                    milestone: updateDefaultMilestones
                }
                requestApi.push(this.updateDefaultMilestonesApi(request, message))
                isBulkUpdate = true
            }
            if (updateCustomMilestones.length !== 0) {
                updateCustomMilestones = updateCustomMilestones.map(milestone => {
                    const {amount} = milestone
                    milestone = {
                        ...milestone,
                        amount: amount ? amount : 0
                    }
                    delete milestone.index
                    return milestone
                })
                const request = {
                    milestone: updateCustomMilestones
                }
                requestApi.push(this.bulkUpdateCustomMilestonesApi(request))
                isCustomUpdate = true
            }
            axios.all(requestApi)
                .then(axios.spread((...resp) => {
                    let jobTrackDetails = [...milestones]
                    if (isBulkUpdate) {
                        copyOfUpdateDefaultMilestones.map(milestones => {
                            const {due_date, amount} = milestones
                            jobTrackDetails[milestones.index] = {
                                ...jobTrackDetails[milestones.index],
                                due_date,
                                amount
                            }
                        })
                    }
                    if (isCustomUpdate) {
                        copyOfUpdateCustomMilestones.map(updateMilestone => {
                            newCustomMilestones[updateMilestone.index] = updateMilestone
                        })
                    }
                    const messageToSend = getSocketRequestData({message, type: messageType.chat_message})
                    const {socket, updateMilestoneFrom} = self.props
                    if (socket && socket.isReady()) {
                        socket.socket.send(JSON.stringify(messageToSend))
                    } else {
                        // self.sendSocketDataViaRoomId({data: messageToSend})
                        // Remving Failing Socket APi call updates the chat.
                    }
                    $('.modal-header .close').click()
                    if (updateMilestoneFrom !== undefined) {
                        updateMilestoneFrom({customMilestones: [...newCustomMilestones], jobTrackDetails})
                    }
                    this.reset();
                })).catch((err) => {
                    this.props.doDispatch(clearAndShowError(err?.response?.data?.error[0] || 'Error updating Milestone'));
                    this.setState({...this.state, errors: { editMilestone : err?.response?.data?.error || [] }});
                    this.getMilestones();
                })
        } else {
            alert('already updated')
        }
    };

    updateMilestoneBeforeHire = () => {
        const {milestones, milestoneInputs} = this.state;
        const {proposalAmount} = this.props;
        let totalProposalAmount = 0;
        let updateMilestones = [];
        let message = "The following milestones are updated.";
        for (let i = 0; i < milestoneInputs.length; i++) {
            if (milestoneInputs[i].amount !== milestones[i].amount || milestoneInputs[i].due_date !== milestones[i].due_date) {
                message = message + '\n\n' + 'Milestone ' + (i + 1) + ' : ' + milestones[i].milestone_practise_area_detail.text + '\n';
                if (milestoneInputs[i].due_date !== milestones[i].due_date) {
                    message = message + 'Due date' + ' : ' + moment(milestoneInputs[i].due_date).format('LL')
                }
                if (milestoneInputs[i].amount !== milestones[i].amount) {
                    message = message + '\n' + 'Amount' + ' : ' + '$' + milestoneInputs[i].amount;
                    totalProposalAmount = totalProposalAmount + Number(milestoneInputs[i].amount)
                }
                updateMilestones.push(milestoneInputs[i])
            } else {
                totalProposalAmount = totalProposalAmount + Number(milestones[i].amount)
            }
        }
        if (totalProposalAmount > Number(proposalAmount)) {
            alert('Total milestone amount is greater than decided');
            return
        }
        const request = {
            milestone_json: milestoneInputs
        };
        const url = `/job/proposal/${this.state.proposalId}/`;
        if (updateMilestones.length !== 0) {
            this.setState({loading: true});
            apiv1.auth.patch(url, request)
                .then(resp => {
                    const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                    if (data) {
                        this.setState({
                            loading: false,
                            updatedSuccessfully: true,
                            milestoneInputs,
                            milestones: data.milestone_json
                        });
                        const messageToSend = getSocketRequestData({message, type: messageType.chat_message})
                        var jsonData = JSON.stringify(messageToSend);
                        if (this.props.socket && this.props.socket.readyState === 1) {
                            // this.props.socket.send(jsonData)
                        }
                        $('.modal-header .close').click()
                    } else {
                        this.setState({loading: false})
                    }
                })
                .catch(err => {
                    this.setState({loading: false});
                    apiv1.handleErrors(err, this.props.doDispatch)
                })

        } else {
            alert('already updated')
        }
    };

    milestoneRow = (milestone, index, isCustomMilestone) => {
        const {milestoneInputs, customMilestones, productId} = this.state
        const {completed_date, due_date, amount} = milestone
        let {notes} = milestoneInputs[index]
        let dueDate = milestoneInputs[index].due_date ? moment(milestoneInputs[index].due_date) : ''
        if (!isCustomMilestone) {
            index += customMilestones.length
            dueDate = milestoneInputs[index].due_date ? moment(milestoneInputs[index].due_date) : ''
        }

        return (
            <tr key={index}>
                <td>
                    {(!completed_date && isCustomMilestone) ?
                        <input className="form-control"
                               value={milestoneInputs[index].text}
                               onChange={(e) => this.onChange(e, index, TEXT)}
                        >
                        </input>
                        :
                        <p className="para">{isCustomMilestone ? milestone.text : milestone.milestone_practise_area_detail.text}</p>
                    }
                </td>
                <td>
                    {!productId && completed_date && <span> {'$' + amount}</span>}
                    {!productId && !completed_date &&
                    <input type="text"
                           className="form-control"
                           value={milestoneInputs[index].amount}
                           onChange={(e) => this.onChange(e, index, AMOUNT)}
                    />
                    }
                </td>
                <td>
                    {completed_date === null ? (
                            <DatePicker
                                className="form-control"
                                selected={dueDate}
                                onChange={(e) => this.onChange(e, index, DUE_DATE, isCustomMilestone)}
                                placeholderText=" Due Date"
                                dateFormat="DD/MM/YYYY" required
                            />
                        )
                        :
                        (due_date &&
                            (<span>
                                {moment(due_date).format("D MMM YYYY")}
                        </span>))
                    }


                </td>
                <td>
                    <input type="text"
                           className="form-control"
                           value={notes}
                           onChange={(e) => this.onChange(e, index, NOTES)}
                    />
                </td>
            </tr>
        )
    };

    milestoneBeforeHireRow = (milestone, index) => {
        // const {productId} = this.props
        const {milestoneInputs, productId} = this.state;
        const k = milestoneInputs[index].due_date ? moment(milestoneInputs[index].due_date) : '';
        return (
            <tr>
                <td className="col-xs-1 text-center">{index + 1}</td>
                <td className="col-xs-5">
                    <p className="para">{milestone.milestone_practise_area_detail.text}</p>
                </td>
                <td className="col-xs-3">
                    <DatePicker
                        selected={k}
                        onChange={(e) => this.onChange(e, index, DUE_DATE)}
                        className="form-control"
                    />
                </td>
            </tr>
        )
    };


    updatedSuccessfullyUi = () => {
        return (
            <div className="msg msg-success msg-success-text">
                <span className="glyphicon glyphicon-ok"></span> Success
            </div>
        )
    };

    reset = () => {
        this.setState({...this.state, errors: { editMilestone : [] }})
    }

    render() {
        const {milestones, loading, updatedSuccessfully, isAllMilestoneCompleted, customMilestones, productId} = this.state;
        const {ishired} = this.props

        if (ishired) {
            return (
                <div className="modal-dialog dialog-top-margin">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    onClick={() => {
                                       
                                        this.setState({updatedSuccessfully: false});
                                        this.reset();
                                    }}
                            >&times;</button>
                            <h4 className="modal-title text-center">Edit Milestones</h4>
                            {this.state?.errors?.editMilestone?.map((err) =><p className={'error'}>{err}</p>)}
                        </div>
                        <div className="modal-body">
                            {updatedSuccessfully && this.updatedSuccessfullyUi()}
                            {(milestones.length === 0 && customMilestones.length === 0) && (
                                <p className="text-empty-space">
                                    There are currently no milestones for this job
                                </p>
                            )}

                            {(milestones.length > 0 || customMilestones.length > 0) && (
                                <div className="col-sm-12 hire-summary">
                                    <form>
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th className="col-sm-4">Description</th>
                                                <th>{!productId && 'Amount'}</th>
                                                <th>Due date</th>
                                                <th className="col-sm-4">Add Notes</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {customMilestones.map((milestone, index) => {
                                                return this.milestoneRow(milestone, index, true)
                                            })}
                                            {milestones.map((milestone, index) => {
                                                return this.milestoneRow(milestone, index, false)
                                            })}
                                            </tbody>
                                        </table>
                                    </form>
                                </div>
                            )}

                        </div>
                        {loading && (
                            <div style={{marginTop: '25%', marginLeft: '50%'}}><LoadingComponent pastDelay/></div>)}
                        <div className="modal-footer">
                            {!isAllMilestoneCompleted && (
                                <div>
                                    <button type="button"
                                            className="btn btn-login"
                                            onClick={this.updateMilestone}
                                    >
                                        Set milestones
                                    </button>
                                    <p>&#x00A0;</p>
                                </div>
                            )
                            }
                        </div>
                    </div>
                </div>
            );
        } else {

            const {milestoneBeforeHire} = this.state;
            return (
                <div className="modal fade" id="MILESTONES" role="dialog">
                    <div className="modal-dialog dialog-top-margin">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        onClick={() => {
                                            this.setState({updatedSuccessfully: false})
                                        }}
                                >&times;</button>
                                <h4 className="modal-title">Milestones</h4>
                            </div>
                            <div className="modal-body">
                                {updatedSuccessfully && this.updatedSuccessfullyUi()}
                                {milestoneBeforeHire.milestone_json !== undefined && milestoneBeforeHire.milestone_json.length === 0 && (
                                    <p className="text-empty-space">
                                        There are currently no milestones for this job
                                    </p>
                                )}
                                {milestoneBeforeHire.milestone_json !== undefined && milestoneBeforeHire.milestone_json.length > 0 && (
                                    <div className="col-sm-12 hire-summary">
                                        <form>
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Description</th>
                                                    <td className="col-xs-1"></td>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {milestoneBeforeHire.milestone_json !== undefined && (
                                                    milestoneBeforeHire.milestone_json.map((milestone, index) => {
                                                        return this.milestoneBeforeHireRow(milestone, index)
                                                    })
                                                )}

                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                )}

                            </div>
                            {loading && (
                                <div style={{marginTop: '25%', marginLeft: '50%'}}><LoadingComponent pastDelay/></div>)}
                            <div className="modal-footer">
                                {milestoneBeforeHire.milestone_json !== undefined && milestoneBeforeHire.milestone_json.length > 0 && (
                                    <button type="button"
                                            className="btn btn-login"
                                            onClick={this.updateMilestoneBeforeHire}
                                    >Set milestones
                                    </button>)}

                                <p>&#x00A0;</p>
                            </div>


                        </div>
                    </div>
                </div>
            );
        }
    }
}

export const AMOUNT = 'AMOUNT'
export const DUE_DATE = 'DUE_DATE'
export const NOTES = 'notes'

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch) => ({
    ...(errorActionDispatchers(dispatch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Milestones)