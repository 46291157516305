import React, { Component, Fragment } from "react";
import { clearAndShowError, errorActionDispatchers } from "../../actions/errors";
import connect from "react-redux/es/connect/connect"
import $ from 'jquery'
import { apiv1 } from "../../api"
import localStorage from "../../utils/localStorage"
import { PROFESSIONAL, USER } from "../../constants/auth"
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { decimalValidator } from "./validation/FormValidator"
import { withRouter } from "react-router-dom"
import { status } from "../../constants/job"
import { apiBaseUrl, requestJobCompletionRequestMessage } from "../../constants/common"
import LoadingComponent from "../../utils/LoadingComponent"
import JobFeedBack from "./JobFeedBack"
import * as apiUrl from "../../api/apiUrls"
import { showSnackbar } from "../functional/afterHire"
import DatePicker from "react-datepicker"
import moment from "moment"
import { getSocketRequestData } from "./Message"
import { messageType } from "../../constants/common"
import { EditDeliveryTimeBox } from "./DeliveryInfo"
import * as path from '../../constants/path'

export function FeesInfo({ totalAmount, gstAmount, wcFees, wcGst, actual_amount_enter_by_professional, service_fee }) {
    service_fee = service_fee || 0
    return (
        <span className="price-info">&#x00A0;
            <img src={require("../../static/images/info-basic.svg").default} alt="fees-summary" />
            <div className="price-info-content">
                <table className="table">
                    <tbody>
                        <tr>
                            <td><b>{localStorage.userType === USER ? "Expert Fees" : "Your Fee"}</b></td>
                            <td>{actual_amount_enter_by_professional}</td>
                        </tr>
                        {/* {gstAmount > 0 &&
                            <tr>
                                <td><b>{localStorage.userType === USER ? "Expert GST" : "Your GST"}</b></td>
                                <td>{gstAmount}</td>
                            </tr>
                        } */}
                        <tr>
                            <td><b>Service Fee</b></td>
                            <td>{service_fee}</td>
                        </tr>
                        <tr>
                            <td><b>Workbeam Fee</b></td>
                            <td>{wcFees}</td>
                        </tr>
                        {/* <tr>
                            <td style={{ borderBottom: '1px dashed gray' }}><b>Workbeam GST</b></td>
                            <td style={{ borderBottom: '1px dashed gray' }}>{wcGst}</td>
                        </tr> */}
                        <tr>
                            <td><b>Total</b></td>
                            <td>{totalAmount}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </span>
    )
}



export function MakePaymentUi() {
    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                    <h4 className="modal-title">Make Payment</h4>
                </div>
                <div className="modal-body" style={{ padding: '15px 30px' }}>
                    <p className="text-note">*This is an Escrow Payment. Payment remains protected with Workbeam till
                        Order Completion. </p>
                    <ul className="list-unstyled request-payment-ul" style={{ padding: '10px 0px' }}>
                        <li>Pay Pending Amount</li>
                        <li><span>$400</span></li>
                        <li>
                            <a className="btn btn-edit-delivery-time">Pay</a>
                        </li>
                    </ul>
                    <p className="or-strike">Or</p>
                    <ul className="list-unstyled request-payment-ul" style={{ padding: '10px 0px' }}>
                        <li>Pay next Milestone Amount</li>
                    </ul>
                    <ul className="list-unstyled request-payment-ul" style={{ padding: '10px 0px' }}>
                        <li><span className="para">Milestone Name</span>
                        </li>
                        <li><span>$400</span></li>
                        <li>
                            <a className="btn btn-edit-delivery-time">Pay</a>
                        </li>
                    </ul>
                    <p className="or-strike">Or</p>
                    <ul className="list-unstyled request-payment-ul" style={{ padding: '10px 0px' }}>
                        <li>Pay any other amount<br />
                            <div className="form-group">
                                <input className="form-control" placeholder="Enter Amount" />
                            </div>
                        </li>
                        <li>
                            <a className="btn btn-edit-delivery-time">Pay</a>
                        </li>
                    </ul>
                </div>
                <div className="modal-footer">
                </div>
            </div>
        </div>
    )
}

class JobPaymentSummary extends Component {
    constructor(props) {
        super(props);
        const { data, jobStatus, proposalSender, totalAmount, userAndProfessionalData, match } = props
        const { chatRoomId } = match.params
        const { request_for_complete } = userAndProfessionalData.job_details
        this.state = {
            totalAmount,
            jobMilestoneDetails: [],
            milestonePaidAvailable: false,
            milestonePendingAvailable: false,
            milestoneWisePayment: false,
            responseData: {},
            userData: data,
            needToPayNow: 0,
            needToPayMilestoneIds: [],
            partialPaymentList: [],
            gigzoeInvoices: [],
            totalPaidAmount: 0,
            gstAmount: 0,
            wcFees: 0,
            wcGst: 0,
            actual_amount_enter_by_professional: 0,
            pendingAmount: 0,
            jobStatus,
            invoiceDocument: '',
            proposalSender,
            allMilestoneDone: true,
            errors: {},
            loading: false,
            chatRoomId,
            request_for_complete,
            selectedDate: '',
            reason: '',
            jobDetails: {},
            service_fee: 0,
            requestMilestoneWhole: {},
            requestMilestone: []
        }
    }

    componentDidMount() {
        this.getTotalAmount()
        this.paidAmount();
        this.getJobDetails()
        this.getUserInvoice()
        this.getRequestPayment()
    }

    updateJobStatus = (status) => {
        this.setState({ jobStatus: status })
    }

    componentWillReceiveProps(nextProps) {
        const { id } = nextProps.data.client
        const { totalAmount, gstAmount, wcFees, wcGst, actual_amount_enter_by_professional } = nextProps
        const { totalPaidAmount } = this.state;
        let pendingAmount = Number(parseFloat((localStorage.userType == PROFESSIONAL ? actual_amount_enter_by_professional : totalAmount) - totalPaidAmount).toFixed(2))
        if (id !== undefined) {
            this.setState({ userData: nextProps.data })
        }
        if (totalAmount !== undefined) {
            this.setState({
                totalAmount,
                pendingAmount,
                gstAmount,
                wcFees,
                wcGst,
                actual_amount_enter_by_professional
            })
        }
    }

    getTotalAmount = () => {
        const { jobStatus, totalAmount, proposalSender, gstAmount, wcFees, wcGst, actual_amount_enter_by_professional } = this.props;
        this.setState({
            totalAmount,
            jobStatus,
            proposalSender,
            gstAmount,
            wcFees,
            wcGst,
            actual_amount_enter_by_professional
        })
    }
    generateCashfreeLink = (jobId, isLoading) => {
        const { userData, needToPayNow, needToPayMilestoneIds, proposalSender } = this.state
        let { basicDetails, client } = userData
        client = client || {}
        const phoneObj = client.phone
        const phone = phoneObj?.phone;
        const { email, first_name } = basicDetails
        const request = {
            email,
            firstname: first_name,
            phone,
            productinfo: "Job " + this.state.jobDetails?.title,
            amount: needToPayNow.toString()+'00',
            milestone: needToPayMilestoneIds,
            professional: proposalSender,
            job: jobId
        }
        if (isLoading) {
            this.setState({ loading: true })
        }
        apiv1.auth.post(apiUrl.cashfreePayment, request, { cache: false })
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    let el = document.createElement('a');
                    el.href = `${apiBaseUrl}webhooks/checkout`;
                    // el.target = '_blank';
                    el.click();
                    // window.location = `${apiBaseUrl}webhooks/checkout`;
                } else {
                    this.setState({ loading: false })
                }
            })
            .catch(err => {
                this.setState({ loading: false });
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    payNow = (id) => {
        const { userData, needToPayNow, errors, pendingAmount } = this.state;
        if (!needToPayNow) {
            errors['needToPayNow'] = 'Please enter amount'
            this.setState({ errors })
            return
        }
        if (userData && userData.basicDetails.email && userData.client.phone) {
            if (Number(needToPayNow) > pendingAmount) {
                alert("Sorry, you have entered more than the pending amount.")
            } else {
                if (Number(needToPayNow) === pendingAmount) {

                    confirmAlert({
                        title: 'Confirmation',
                        message: "Please Select Carefully?",
                        buttons: [
                            {
                                label: 'Job Completed',
                                onClick: () => {
                                    $('#job_feedback').click()
                                }
                            },
                            {
                                label: 'Job still in progress',
                                onClick: () => {
                                    this.setState({ loading: true }, () => this.generateCashfreeLink(id))
                                }
                            }
                        ]
                    })


                } else {
                    this.setState({ loading: true }, () => this.generateCashfreeLink(id))
                }
            }
        } else {
            showSnackbar({ message: 'Please update your profile, Phone Number & Email are required' })
        }
    };

    requestPaymentUi = () => {
        const { requestMilestoneWhole, requestMilestone } = this.state;
        return (
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">Request Payments 2</h4>
                    </div>
                    <div className="modal-body" style={{ padding: '15px 0px' }}>

                        {requestMilestoneWhole !== undefined && requestMilestoneWhole !== null && (
                            <ul className="list-unstyled request-payment-ul" style={{ borderBottom: '1px solid #e8eef3' }}>
                                <li>{requestMilestoneWhole.text}<br /><span>${requestMilestoneWhole.amount}</span></li>
                                <li>
                                    {/* <a className="btn btn-edit-delivery-time" onClick={(e) => {
                                this.paymentRequestSave(0, "job")
                            }}>Request</a> */}
                                    {requestMilestoneWhole.request_for_payment_one === true ? <a style={{color:'green'}}>Request Sent</a> : <a className="btn btn-edit-delivery-time" onClick={(e) => {
                                        this.paymentRequestSave(0, "job")
                                    }}>Request</a>}
                                </li>
                            </ul>
                        )}
                        {requestMilestone !== undefined && requestMilestone !== null && requestMilestone.length > 0 &&(
                        <ul className="list-unstyled request-payment-ul">
                        <li>Request Milestone Payments</li>
                        </ul>
                        )}
                      
                        {requestMilestone !== undefined && requestMilestone !== null && requestMilestone.map((dataonebyone, index) => {
                            return <ul className="list-unstyled request-payment-ul">
                                <li>Milestone {index + 1} <br /><span className="para">
                                    {dataonebyone.text}
                                </span></li>
                                <li><span>${dataonebyone.amount}</span></li>
                                <li>
                                    {dataonebyone.request_for_payment_one === true ? <a className="btn btn-edit-delivery-time" >Request Sent</a> : <a className="btn btn-edit-delivery-time" onClick={(e) => {
                                        this.paymentRequestSave(dataonebyone.milestone, "milestone")
                                    }}>Request</a>}

                                </li>
                            </ul>
                        })}
                    </div>
                    <div className="modal-footer">
                    </div>
                </div>
            </div>
        )
    };

    getUserInvoice = () => {
        const { jobId } = this.props;
        const url = `/invoice/user-invoice/by-job/${jobId}`;
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    this.setState({ gigzoeInvoices: data })
                } else {
                    this.setState({ loading: false })
                }
            })
            .catch(err => {
                this.setState({ loading: false });
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    };

    getRequestPayment = () => {
        const { jobId, proposalId } = this.props;
        const url = `/job/payment-request?proposal_id=${proposalId}`;
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    this.setState({ requestMilestoneWhole: data.complete_order, requestMilestone: data.list_of_data })
                } else {
                    this.setState({ loading: false })
                }
            })
            .catch(err => {
                this.setState({ loading: false });
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    };


    paymentRequestSave = (milestoneId, type) => {
        let confirmationMessage = 'are you sure ?'
        confirmAlert({
            title: 'Confirmation',
            message: confirmationMessage,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        const { jobId, proposalId } = this.props;
                        const request = {
                            "job": jobId,
                            "proposal": proposalId,
                            "milestone": milestoneId,
                            "type": type
                        };

                        apiv1.auth.post('/job/payment-request', request)
                            .then(res => {
                                const data = apiv1.parseAndAutoHandleIssues(res, this.props.doDispatch);
                                if (data) {
                                    this.setState({ loading: false });
                                } else {
                                    this.setState({ loading: false })
                                }
                                this.getRequestPayment()

                            })
                            .catch(err => {

                                this.setState({ loading: false })
                            })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {

                    }
                }
            ]
        })
    };



    markCompleted = (jobId) => {
        const { history, doDispatch } = this.props
        let confirmationMessage = 'Is this job completed successfully?'
        let api = ''
        let url = `/job/job/${jobId}/`
        let request = {}

        const { is_super_user } = this.props
        if (is_super_user) {
            confirmationMessage = 'you want to send email ?'
            confirmAlert({
                title: 'Confirmation',
                message: confirmationMessage,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            $('#job_feedback').click()
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            request.send_email_on_complete = false
                            api = apiv1.auth.patch(url, request)
                            $('#job_feedback').click()
                        }
                    }
                ]
            })
        } else {

            confirmAlert({
                title: 'Confirmation',
                message: confirmationMessage,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            if (localStorage.userType === PROFESSIONAL) {
                                confirmationMessage = 'Are you sure ?'
                                request.request_for_complete = true
                                api = apiv1.auth.patch(url, request)
                            } else {
                                $('#job_feedback').click()
                                return
                            }
                            this.setState({ loading: true })
                            api.then(resp => {
                                const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                                if (data) {
                                    if (localStorage.userType === USER) {
                                        history.push(`/payment/confirm?status=SUCCESS&job=${jobId}&milestone=&feedback=true`)
                                    } else {
                                        this.setState({ loading: false })
                                        doDispatch(clearAndShowError(requestJobCompletionRequestMessage))
                                    }
                                }
                            }).catch(err => {
                                this.setState({ loading: false });
                                apiv1.handleErrors(err, doDispatch)
                            })
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                        }
                    }
                ]
            })
        }
    };
    sendSocketDataViaRoomId = ({ data }) => {
        const { type, message } = data
        const { chatRoomId } = this.state
        const request = {
            room: chatRoomId,
            chat: {
                message,
                message_type: type
            }
        }
        apiv1.auth.post(apiUrl.insertSocketData, request)
            .then(resp => {

            })
            .catch(err => {
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }
    requestForOrderComplete = (jobId) => {
        const { socket } = this.props
        let confirmationMessage = 'Are you sure, you want to send request for order complete.'
        let api = ''
        let url = `/job/job/${jobId}/`
        let request = {}
        confirmAlert({
            title: 'Confirmation',
            message: confirmationMessage,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        request.request_for_complete = true
                        api = apiv1.auth.patch(url, request)
                        this.setState({ request_for_complete: true })
                        let message = 'Hi,\n\nI have completed this Order. I request you to kindly review and approve it and give me a feedback.'
                        const messageToSend = getSocketRequestData({ message, type: messageType.chat_message })
                        if (socket && socket.isReady()) {
                            socket.socket.send(JSON.stringify(messageToSend))
                        } else {
                            this.sendSocketDataViaRoomId({ data: messageToSend })
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ]
        })
    };
    getProfessionalInvoice = () => {
        const { jobId, proposalId } = this.props;
        const url = `/invoice/professional-invoice/by-job/${jobId}`;
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    this.setState({ loading: false, invoiceDocument: data[0].document })
                } else {
                    this.setState({ loading: false })
                }
            })
            .catch(err => {
                this.setState({ loading: false });
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    };
    paidAmount = () => {
        const { jobId, totalAmount } = this.props;
        const url = `/invoice/milestone-payment-receipt/by-job/${jobId}`;
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    let totalPaidAmount = 0
                    data.map(partialPayment => (totalPaidAmount += Number(partialPayment.total_amount)))
                    let amt = (localStorage?.userType == PROFESSIONAL ? this.state?.actual_amount_enter_by_professional : totalAmount)
                    let pendingAmount = Number(parseFloat(amt - totalPaidAmount).toFixed(2))
                    this.setState({ loading: false, partialPaymentList: data, totalPaidAmount, pendingAmount })
                } else {
                    this.setState({ loading: false })
                }
            })
            .catch(err => {
                this.setState({ loading: false });
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    };

    getJobDetails = (e) => {
        const { jobId, proposalId } = this.props.match.params;
        const url = `/job/proposal-user-professional/${proposalId}/`
        this.setState({ loading: true })
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    const { job_details, service_fee } = data
                    const { product, completed_date_expected } = job_details
                    this.setState({
                        loading: false,
                        jobDetails: job_details,
                        service_fee,
                        selectedDate: completed_date_expected ? moment(completed_date_expected) : ''
                    })

                } else {
                    this.setState({ loading: false })
                }
            })
            .catch(err => {
                this.setState({ loading: false });
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    };
    changeTextTypeInput = (e) => {
        const errors = { ...this.state.errors };
        this.setState({ [e.target.name]: e.target.value, errors })
    };
    onChange = (date) => {
        this.setState({ selectedDate: date });
    };

    updateDeliveryInfo = (e) => {
        const { selectedDate, reason, jobDetails } = this.state
        const { socket, updateDeliveryInfo } = this.props
        let oldJson = jobDetails.delivery_time_history
        if (jobDetails.delivery_time_history == null) {
            oldJson = []
        }
        let newObject = { "date": selectedDate, "reason": reason }
        oldJson.push(newObject)
        apiv1.auth.patch(`/job/job/${jobDetails.id}/`, {
            completed_date_expected: selectedDate,
            delivery_time_history: oldJson
        }).then(resp => {
            const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
            if (data) {
                const { completed_date_expected, delivery_time_history } = data
                let job_details = { ...jobDetails, completed_date_expected }
                $('.modal .close').click()
                let message = "Hi, I have updated the expected delivery date based on our discussion \n\nUpdated Date : " + moment(selectedDate).format('D MMM YYYY')
                if (reason) {
                    message += `\nReason for change: ${reason}`
                }
                const messageToSend = getSocketRequestData({ message, type: messageType.chat_message })
                this.setState({ jobDetails: job_details }, () => {
                    if (updateDeliveryInfo) {
                        updateDeliveryInfo({ delivery_time_history })
                    }
                })
                if (socket && socket.isReady()) {
                    socket.socket.send(JSON.stringify(messageToSend))
                } else {
                    this.sendSocketDataViaRoomId({ data: messageToSend })
                }

            }
        })
            .catch(err => {
                this.setState({ loading: false });
                apiv1.handleErrors(err, this.props.doDispatch)
            });
    }
    finalTaxInvoiceUI = () => {
        const { gigzoeInvoices } = this.state
        return (
            <tbody>
                <tr>
                    <td><b>Tax Invoice</b></td>
                </tr>
                {gigzoeInvoices.map((taxInvoiceData) => {
                    const { document, processing_fee_invoice } = taxInvoiceData
                    return (
                        <Fragment>
                        <tr>
                            <td><a href={document} target='_blank'
                                style={{
                                    color: '#ff337d',
                                    fontSize: '14px'
                                }}><i className="fa fa-download" /> Tax Invoice</a></td>
                         </tr>
                            {processing_fee_invoice !== null &&
                            <tr>
                                <td><b>Processing Fee Invoice</b><br/><a href={processing_fee_invoice} target='_blank'
                                                                         style={{
                                                                             color: '#ff337d',
                                                                             fontSize: '14px',
                                                                             marginTop:'10px'
                                                                         }}><i className="fa fa-download" /> Processing Fee Invoice</a></td>
                            </tr>
                            }
                        </Fragment>
                    )
                })}
            </tbody>
        )
    }
    editExpectedDelivery = () => {
        return (
            <div className="modal fade center-modal" id="edit_delivery_time_popup" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button"
                                className="close"
                                data-dismiss="modal"
                            >&times;
                            </button>
                            <h4>Edit Delivery Info</h4>
                        </div>
                        <div className="modal-body update-profile" style={{ marginTop: '0px' }}>
                            <div className="col-sm-12">
                                <div className="form-group" style={{ marginBottom: '10px' }}>
                                    <p className="panel-text"
                                        style={{ marginBottom: '0px', marginTop: '5px' }}>Delivery Date</p>
                                    <DatePicker
                                        selected={this.state.selectedDate || ''}
                                        className="input-lg form-control"
                                        name="selectedDate"
                                        placeholderText="Select Delivery Date"
                                        dateFormat="DD/MM/YYYY" required
                                        onChange={this.onChange}
                                    />
                                </div>
                                <p className="text-note">*Make sure you increase milestone dates also as per
                                    delivery date from milestones tab</p>
                                <div className="form-group">
                                    <p className="panel-text" style={{ marginBottom: '0px' }}>Reason for Change</p>
                                    <textarea name='reason' onChange={this.changeTextTypeInput}
                                        className="form-control"
                                        rows={`4`}
                                        placeholder={`Write Reason for Change...`}
                                    >
                                    </textarea>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer" style={{ textAlign: 'center', marginBottom: '10px' }}>
                            <button type="button" onClick={(e) => {
                                this.updateDeliveryInfo(this.state.finalPendingAmount)
                            }} className="btn btn-save-profile">Update Delivery Info
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { jobId, proposalId, userAndProfessionalData, history } = this.props;
        const { user_details } = userAndProfessionalData
        const {
            totalAmount, partialPaymentList, jobStatus, loading, errors, pendingAmount, gstAmount, wcFees,
            wcGst, actual_amount_enter_by_professional, chatRoomId, request_for_complete, jobDetails,
            service_fee
        } = this.state
        return (
            <Fragment>
                <div className="side-box-container">
                    {loading &&
                        <div className="lazy-loader" style={{ position: 'fixed' }}>
                            <LoadingComponent pastDelay /></div>
                    }
                    <div className="payment-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Fees Summary</th>
                                    <th />
                                    <th className="text-right">$</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan='2'>Total Fees
                                        {/* <FeesInfo gstAmount={gstAmount} wcFees={wcFees} wcGst={wcGst}
                                            totalAmount={totalAmount}
                                            actual_amount_enter_by_professional={actual_amount_enter_by_professional}
                                                  service_fee={service_fee}
                                        /> */}
                                    </td>
                                    <td className="text-right">{localStorage.userType == PROFESSIONAL ? parseFloat(actual_amount_enter_by_professional): parseFloat(totalAmount)}</td>
                                </tr>
                            </tbody>
                            {partialPaymentList.length > 0 &&
                                (<tbody>
                                    <tr>
                                        <td>
                                            {localStorage.userType === USER && (<b>Paid</b>)}
                                            {localStorage.userType === PROFESSIONAL && (<b>Received</b>)}
                                        </td>
                                        <td />
                                    </tr>
                                    {partialPaymentList.map((data, i) => {
                                        const { total_amount, document, payment_date } = data
                                        return <tr>
                                            <td colSpan="2">Payment made &#x00A0;<a href={document} target='_blank'
                                                style={{
                                                    color: '#ff337d',
                                                    fontSize: '10px'
                                                }}>Payment receipt</a></td>
                                            <td className="text-right">
                                                <div >
                                                    {moment(payment_date).format('MMM DD, YYYY')}
                                                </div>
                                                <div style={{fontWeight: 700}}>
                                                $ {total_amount}
                                                </div>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>)}
                            {pendingAmount > 0 && (
                                <tbody>
                                    <tr>
                                        <td>
                                            <b>Pending</b>
                                        </td>
                                        <td />
                                    </tr>
                                    <tr>
                                        <td>Pending total amount</td>
                                        <td className="text-right" colSpan="2">{pendingAmount}</td>
                                    </tr>
                                </tbody>
                            )}
                            {jobStatus !== status.Completed && localStorage.userType === USER && localStorage.isSuperUser == 'false' && pendingAmount > 0 && (
                                <tbody>
                                    <tr>
                                        <td><b>Make Payment</b>
                                        </td>
                                        <td />
                                    </tr>
                                    <tr>
                                        <td className="text-center" colSpan="4">
                                            <div>
                                                <input type="text"
                                                    className="input-lg"
                                                    name="needToPayNow"
                                                    placeholder="Enter Amount"
                                                    onChange={(e) => {
                                                        const { errors } = this.state
                                                        errors[e.target.name] = ''
                                                        const message = 'Amount is not valid';
                                                        decimalValidator(errors, e.target.name, e.target.value, message)
                                                        this.setState({ [e.target.name]: e.target.value, errors })
                                                    }}
                                                />
                                                <p className={errors.needToPayNow ? 'error' : 'no-error'}>{errors.needToPayNow}</p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                            {/*{jobStatus === status.Completed && isBefore_1_06_21(jobDetails.created) && this.finalTaxInvoiceUI()}*/}
                            {jobStatus === status.Completed && this.finalTaxInvoiceUI()}
                        </table>
                        {jobStatus !== status.Completed && localStorage.userType === USER && localStorage.isSuperUser == 'false' && pendingAmount > 0 && (
                            <div className="text-center">
                                <button className="btn btn-md btn-pink" onClick={(e) => {
                                    this.payNow(jobId)
                                }}>Pay
                                </button>
                                <p className="text-note" style={{ marginTop: '15px' }}>*This is an escrow payment. Payment
                                    stays with Workbeam and is settled to Expert on work completion.</p>
                            </div>
                        )}
                        {jobStatus !== status.Completed && (pendingAmount === 0 || pendingAmount === 0.00) && (
                            <div className="text-center">
                                {localStorage.userType === USER &&
                                    <button className="btn btn-mark-complete" onClick={(e) => {
                                        this.markCompleted(jobId)
                                    }}>Mark Complete
                                    </button>
                                }
                            </div>
                        )}
                        {localStorage.userType === USER && localStorage.isSuperUser == 'true' &&
                        <div className="text-center">
                            <a className="btn btn-edit-delivery-time btn-block" id="request_payment" data-toggle="modal" data-target="#request_payment_popup">Request For Payment</a>
                            {!request_for_complete ?  <a className="btn btn-block btn-edit-delivery-time" onClick={() => {history.push(`${path.orderMarkComplete}/${proposalId}/${jobId}/${user_details.id}/${chatRoomId}`)}}>Request Order Completion</a> :  <a className="btn btn-block btn-after-success"><i className="fa fa-check"/> Requested Order Completion</a>}
                        </div>
                        }
                    </div>
                    <p id="job_feedback" data-toggle="modal" data-target="#job_feedback_popup">
                    </p>
                    <p id="request_payment" data-toggle="modal" data-target="#request_payment_popup">
                    </p>
                    <p id="make_payment" data-toggle="modal" data-target="#make_payment_popup">
                    </p>
                    <div className="modal fade center-modal" id="request_payment_popup" role="dialog">
                        {/* <RequestPaymentUi/> */}

                        {this.requestPaymentUi()}
                    </div>
                    <div className="modal fade center-modal" id="make_payment_popup" role="dialog">
                        <MakePaymentUi />
                    </div>
                    <div className="modal fade" id="job_feedback_popup" role="dialog">
                        <JobFeedBack doDispatch={this.props.doDispatch}
                            userAndProfessionalData={userAndProfessionalData} jobId={jobId}
                            updateJobStatus={this.updateJobStatus}
                            generateCashfreeLink={!(pendingAmount === 0 || pendingAmount === 0.00) ? this.generateCashfreeLink : ''} />
                    </div>
                </div>
                <EditDeliveryTimeBox expectedDeliveryDate={jobDetails.completed_date_expected} roomId={chatRoomId} />
                {/* delivery info popup start */}
                <p id="edit_delivery_time" data-toggle="modal" data-target="#edit_delivery_time_popup" />
                {this.editExpectedDelivery()}
                {/* delivery info popup end */}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    data: state.data,
    is_super_user: JSON.parse(localStorage.isSuperUser),
});
const mapDispatchToProps = (dispatch) => ({
    ...(errorActionDispatchers(dispatch)),
});
export default connect(mapStateToProps, mapDispatchToProps,)(withRouter(JobPaymentSummary))
