import React, {Component, Fragment} from "react";
import {apiv1} from "../../api";
import {errorActionDispatchers} from "../../actions/errors";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";
import {responsiveLegalItems} from "./AllOwlCrousel";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Link from "react-router-dom/es/Link";
import LoadingComponent from "../../utils/LoadingComponent";
import {professionalType as professional_type} from "../../constants/businessPractiseArea";
import * as apiUrl from "../../api/apiUrls";
import {productIdMappedWithSlug} from "./bannerTextForProducts"
// import {baseUrl} from './../../constants/common'
import {category} from "../../constants/keyWord"
import {mainDomain} from "../../constants/common";
// import moment from "./categoryPageLearning";
// import {ParseHtmlToString} from "../../functions/common";


class PopularServices extends Component {
    constructor(props){
        super(props)
        this.state = {
            loading: false,
            popularServices: [],
            page: 1,
            limit: 8,
        }
    }

    componentDidMount() {
        this.getPopularServices()
    }

    getPopularServices = () => {
        const {limit, page} = this.state
        this.setState({loading: true});
        apiv1.noAuth.get(`${apiUrl.productList}?is_popular=${true}&page=${page}&limit=${limit}`)
            .then(res => {
                const data = apiv1.parseAndAutoHandleIssues(res, this.props.doDispatch);
                if (data) {
                    let {results} = data
                    let isDesigner = true
                    let popularServices = results.map(productDetails => {
                        const {id} = productDetails
                        isDesigner = productIdMappedWithSlug[id] && productIdMappedWithSlug[id].product_type === professional_type.designer
                        return {
                            ...productDetails,
                            isDesigner
                        }
                    })
                    this.setState({loading: false, popularServices})
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    popularServicesUi = () => {
        const {popularServices} = this.state
        return (
                <OwlCarousel
                    className="owl-theme"
                    loop={true}
                    dots={true}
                    margin={15}
                    nav={false}
                    navText={['<i class="fa fa-angle-left" style="font-size:24px"></i>', '<i class="fa fa-angle-right" style="font-size:24px"></i>']}
                    items={4}
                    responsiveClass={true}
                    autoplay={true}
                    autoplayTimeout={10000}
                    autoplayHoverPause={true}
                    responsive={responsiveLegalItems}
                >
                    {popularServices.map((productDetail, index) => {
                        const {id, practise_area_slug_new, photo, name, slug_new} = productDetail
                        return (
                            <div className="item" key={id}>
                                <a href={`${mainDomain}/${category}/${practise_area_slug_new}/${slug_new}`} title={name}>
                                    <div className="category-blog-box popular-service-ui">
                                        <div className="popular-service-img">
                                            {photo && <img src={photo} className="img-responsive" alt={name}/>}
                                        </div>
                                        <h3 className="category-heading">
                                            {name}
                                        </h3>
                                    </div>
                                </a>
                            </div>
                        )
                    })}
                </OwlCarousel>
        )
    }

    render() {
        const {popularServices,loading} = this.state
        return (
            <Fragment>
                {loading &&
                <div className='lazy-loader' style={{position: 'fixed', zIndex: '100'}}>
                    <LoadingComponent pastDelay/>
                </div>
                }
                {popularServices && popularServices.length > 0
                &&
                this.popularServicesUi({popularServices})
                }
            </Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ...(errorActionDispatchers(dispatch))
    }
};

export default connect(null, mapDispatchToProps)(withRouter(PopularServices))
