import * as path from "./path";

export const parentPracticeArea = {
    1: {
        name: 'Tax & Accounting',
        product: {
            4: {
                path: `/${path.productFilter}?${path.product}=${8}&${path.practiceAreaId}=${1}`
            },
            5: {
                path: `/${path.productFilter}?${path.product}=${2}&${path.practiceAreaId}=${2}`
            },
            3: {
                path: `/${path.productFilter}?${path.product}=${3}&${path.practiceAreaId}=${2}`
            }
        },

    },
    2: {
        name: 'Legal',
        product: {
            1: {
                path: `/${path.productFilter}?${path.product}=${1}&${path.practiceAreaId}=${2}`
            },
            2: {
                path: `/${path.productFilter}?${path.product}=${2}&${path.practiceAreaId}=${2}`
            },
            3: {
                path: `/${path.productFilter}?${path.product}=${3}&${path.practiceAreaId}=${2}`
            }
        },
    },
    3: {
        name: 'Secretarial Compliance',
        product: {
            id: '',
            path: ''
        },
    },
    4: {
        name: 'Trademark & Copyrights',
        product: {
            id: '',
            path: ''
        },

    },
    5: {
        name: 'Business Formation',
        product: {
            id: '',
            path: ''
        },
    },

    6: {
        name: 'Contracts and Agreements',
        product: {
            id: '',
            path: ''
        },
    },
    7: {
        name: 'Fundraising and Esop Compliance',
        product: {
            id: '',
            path: ''
        },
    },
    106:{
        name: 'Business Licenses & Registrations',
        product: {
            id: '',
            path: ''
        },
    }
}