import moment from "moment";
// import {App_env} from "./../api";

let apiBaseUrl = process.env.REACT_APP_apiBaseUrl
let mailerApiBaseUrl = `http://20.231.48.250:8001/`
let siteBaseUrl = process.env.REACT_APP_siteBaseUrl
let mainDomain = process.env.REACT_APP_mainDomain
export let baseUrl = siteBaseUrl.substring(0, siteBaseUrl.length - 1)
// let wizcounseDotCom = `wizcounsel.com`
// let wizcounseWebDevDotCom = `web-dev.wizcounsel.com`
// const exotelCallerId = "01140849277"
// const exotelAuthToken = "Basic 27cb0140f69f18fdfa7765caa5e93954c739241dd83120f2"
// const apiKey = '4ac5330b9d49ef1f4fa5f531cd1a9d94cfc6351593110ae1'
// const exotelSid = 'wizcounsel'
export const indiaCallingCode = '+91'
const requestJobCompletionRequestMessage = 'Your job completion request has been sent, please wait till the user approves the request'
// const videoThumbnails = 'https://workbeam.blob.core.windows.net/wiz-public-cdn/testimonial-images/video-thumbnail.jpg'
const videoThumbnails = 'https://workbeam.blob.core.windows.net/wiz-public-cdn/video-thumb-big.jpg'
export const may20_2020 = moment("2020-05-20")
const indiaCountryCodeCode = 'IN'
const httpsConst = 'https://'
const httpConst = 'http://'
const Workbeam = 'workbeam.com'
const agoraAppId = 'fa63c0aa4530410da2c18f4ce9ffc9da'
export const defaultPageTitle = `Workbeam - Hire Trusted Freelancers and Service Experts`
export const defaultPageMetaDescription = `Workbeam is India's most trusted Business Services platform and Freelance Marketplace. Hire Workbeam's verified Freelancers and Service Experts to grow your business.`

const firebaseConfig = {
    apiKey: "AIzaSyAUL5YjS9ICtkAXGIh6q-P0uO4fOZPpmo4",
    authDomain: "wizcounsel-mobile-app.firebaseapp.com",
    databaseURL: "https://wizcounsel-mobile-app.firebaseio.com",
    projectId: "wizcounsel-mobile-app",
    storageBucket: "wizcounsel-mobile-app.appspot.com",
    messagingSenderId: "51764281267",
    appId: "1:51764281267:web:2f092946fb5d947d17d5c6",
}

const couponCodeMsg = {
    invalid: 'Invalid coupon code.',
    applied: 'Applied',
    already_used: 'This coupon code has already been used.',
    expired: 'This coupon code has expired.',
    not_applicable: `Sorry, this coupon is not applicable to this service.`
}
const professionalBadge = {
    Pro: 'Rising Talent',
    SuperPro: 'Top Rated',
    Recommended: 'Top Rated Plus',
    GigzoePreferred: 'Workbeam Preferred'
}
const professionalFlagColor = {
    YellowFlag: 'Yellow',
    OrangeFlag: 'Orange',
    RedFlag: 'Red',
    BlackFlag: 'Black'
}
const professionalProfileStage = {
    stageIncomplete: 'Incomplete',
    stageQuestionnaire: 'Questionnaire',
    stageInterview: 'Interview',
    stageCorrection: 'Correction',
    stageRejected: 'Rejected',
    stageSuspended: 'Suspended',

}
const media_type = {
    PHOTO: 'PHOTO',
    VIDEO: 'VIDEO',
    image: 'image',
    video: 'video',
    audio: 'audio',
    voice: 'voice',
    pdf: 'pdf',
    docx: 'docx',
    doc: 'doc'
}

const showDefault = {
    sh: 'SH',
    sf: 'SF',
    hf: 'HF',
    hh: 'HH'
}
const downloadApp = 'DA'

const messageType = {
    chat_message: 'chat_message',
    document_message: 'document_message',
    audio_miss_call_message: 'audio_miss_call_message',
    audio_call_message: 'audio_call_message',
    video_call_message: 'video_call_message',
    video_miss_call_message: 'video_miss_call_message',
    zoom_call_message: 'zoom_call_message',
    audio_call_blank: ''
}
const chatButtonType = {
    offer: 'offer',
    video: 'video',
    voice: 'voice',
    link: 'link',

}
const messageSentFrom = {
    web: 'web',
    ios: 'ios'
}
const exotelNumberToNumberCalled = {
    web_call: 'outbound-api',
    phone_call: 'incoming',
    agora_voice: 'agora_voice',
    outbound_api: 'outbound-api',
    agora_video: 'agora_video'
}

const adminDetails = {
    clientUserId: 1,
    first_name: 'Team',
    last_name: 'Workbeam'
}
const commissionPercentage = {
    national: .18,
    international: .05,
    service_fee_for_indian_customer: .02
}

const roundOffDivider = {
    one_point_one_eight: 1.18,
    one_point_one_five: 1.05,
}

const call_type = {
    audioCall: 'audioCall',
    videoCall: 'videoCall',
    voiceCall: 'voiceCall'
}
const callStatus = {
    completed: 'completed',
    failed: 'failed'
}

const incomingOutgoing = {
    outgoing: 'outgoing',
    incoming: 'incoming'
};

const backendErrorCode = {
    500: 500
}

const customBackendErrorText = {
    user_already_exits: 'Email or phone no already registered with us'
}
const skillsLevel = [
    {
        name: 'Basic (0-1 Year Exp)',
        value: 'basic',
    },
    {
        name: 'Intermediate (2-4 Yrs Exp)',
        value: 'intermediate',
    },
    {
        name: 'Advance (4-7 Yrs Exp)',
        value: 'advanced',
    },
    {
        name: 'Pro (Above 7 Yrs Exp)',
        value: 'pro',
    }
]

export {
    // exotelCallerId,
    // exotelAuthToken,
    // apiKey,
    // exotelSid,
    skillsLevel,
    professionalProfileStage,
    requestJobCompletionRequestMessage,
    apiBaseUrl,
    siteBaseUrl,
    mailerApiBaseUrl,
    // wizcounseDotCom,
    // wizcounseWebDevDotCom,
    videoThumbnails,
    firebaseConfig,
    couponCodeMsg,
    professionalBadge,
    professionalFlagColor,
    media_type,
    showDefault,
    messageType,
    messageSentFrom,
    downloadApp,
    exotelNumberToNumberCalled,
    adminDetails,
    commissionPercentage,
    indiaCountryCodeCode,
    roundOffDivider,
    httpConst,
    httpsConst,
    call_type,
    Workbeam,
    backendErrorCode,
    customBackendErrorText,
    agoraAppId,
    callStatus,
    incomingOutgoing,
    chatButtonType,
    mainDomain
}