import React, {Fragment} from "react";
import * as path from "../../constants/path";

function ShowQuestion(jobQuestions) {
    return (
        <div>
            <p className="panel-text">Job details</p>
            <div className="panel panel-default">
                <div className="panel-body">
                    <ol>
                        {jobQuestions.map((question, i) => {
                            return <li key={question.practise_area_question_detail.id}>{ question.practise_area_question_detail.text}
                                {question.answer.map((data, answerIndex) => {

                                    return <div>
                                        {data}
                                        {answerIndex > 1 && <span>,</span>}
                                    </div>
                                })}
                            </li>
                        })}
                    </ol>
                </div>
            </div>
        </div>
    )
}

export function copyText({text}) {
    let dummy = document.createElement("input")
    document.body.appendChild(dummy);
    dummy.setAttribute("id", "copy_text")
    document.getElementById("copy_text").value = text
    dummy.select()
    document.execCommand("copy")
    document.body.removeChild(dummy)
}

function snackBarPopupUi({message, timeout}) {
    let x = document.getElementById("custom-snackbar");
    x.innerHTML = message
    x.className = "show-snackbar custom-snackbar"
    setTimeout(function () {
        if (x) {
            x.className = x.className.replace("show-snackbar custom-snackbar", "hidden");
        }
    }, timeout || 1500);
}

export function showSnackbar({message, timeout, initialDelay}){
    if (initialDelay){
        setTimeout(() => {
            snackBarPopupUi({message, timeout})
        }, initialDelay)
    } else {
        snackBarPopupUi({message, timeout})
    }

}

function TransactionAmount({accounting, consultation_completed_count, history }) {
    const {
        job_in_progress_amount,
        user_invoice_total_amount,
        user_invoice_total_amount_tax,
        wizcounsel_invoice_total_amount,
        wizcounsel_invoice_total_amount_tax,
        user_invoice_total_amount_job_in_progress ,
        user_invoice_total_amount_tax_job_in_progress ,
        wizcounsel_invoice_total_amount_job_in_progress ,
        wizcounsel_invoice_total_amount_tax_job_in_progress ,
        user_invoice_total_amount_consultations ,
        user_invoice_total_amount_tax_consultations,
        wizcounsel_invoice_total_amount_consultations ,
        wizcounsel_invoice_total_amount_tax_consultations, 
        advance_payment,
        total_payment,
        total_invoice_payment
    } = accounting
    return(
        <Fragment>


<div className="user-dashboard-table">
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th>Current Updates</th>
                        <th>Number</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr  onClick={() => {
                             history.push(path.consultHistory)
                         }}>
                        <td>Consultation Completed</td>
                        <td><span className="text-number">{consultation_completed_count}</span></td>
                    </tr>
                    <tr>
                        <td>Still Job In Progress Amount</td>
                        <td><span className="text-number">${job_in_progress_amount}</span></td>
                    </tr>

                    <tr>
                        <td>User Invoice Amount (Job Completed)</td>
                        <td><span className="text-number">${user_invoice_total_amount}</span></td>
                    </tr>
                    <tr>
                        <td>User Invoice Tax Amount (Job Completed)</td>
                        <td><span className="text-number">${user_invoice_total_amount_tax}</span></td>
                    </tr>
                    <tr>
                        <td>Workbeam Invoice Amount (Job Completed)</td>
                        <td><span className="text-number">${wizcounsel_invoice_total_amount}</span></td>
                    </tr>
                    <tr>
                        <td>Workbeam Invoice Tax Amount (Job Completed)</td>
                        <td><span className="text-number">${wizcounsel_invoice_total_amount_tax}</span></td>
                    </tr>
                    <tr>
                        <td>User Invoice Amount (Job InProgress)</td>
                        <td><span className="text-number">${user_invoice_total_amount_job_in_progress}</span></td>
                    </tr>
                    <tr>
                        <td>User Invoice Tax Amount (Job InProgress)</td>
                        <td><span className="text-number">${user_invoice_total_amount_tax_job_in_progress}</span></td>
                    </tr>
                    <tr>
                        <td>Workbeam Invoice Amount (Job InProgress)</td>
                        <td><span className="text-number">${wizcounsel_invoice_total_amount_job_in_progress}</span></td>
                    </tr>
                    <tr>
                        <td>Workbeam Invoice Tax Amount (Job InProgress)</td>
                        <td><span className="text-number">${wizcounsel_invoice_total_amount_tax_job_in_progress}</span></td>
                    </tr>
                    <tr>
                        <td>User Invoice Amount (Consultation) </td>
                        <td><span className="text-number">${user_invoice_total_amount_consultations}</span></td>
                    </tr>
                    <tr>
                        <td>User Invoice Amount Tax (Consultation)</td>
                        <td><span className="text-number">${user_invoice_total_amount_tax_consultations}</span></td>
                    </tr>
                    <tr>
                        <td>Workbeam Invoice Amount (Consultation) </td>
                        <td><span className="text-number">${wizcounsel_invoice_total_amount_consultations}</span></td>
                    </tr>
                    <tr>
                        <td>Workbeam Invoice Tax Amount (Consultation) </td>
                        <td><span className="text-number">${wizcounsel_invoice_total_amount_tax_consultations}</span></td>
                    </tr>
                    <tr>
                        <td>Total Payment </td>
                        <td><span className="text-number">${total_payment}</span></td>
                    </tr>
                    <tr>
                        <td>Total Invoice Payment </td>
                        <td><span className="text-number">${total_invoice_payment}</span></td>
                    </tr>
                    <tr>
                        <td>Total Advance Payment </td>
                        <td><span className="text-number">${advance_payment}</span></td>
                    </tr>
                    </tbody>
                </table>
            </div>            
        </Fragment>
    )
}
 export {
    ShowQuestion,
     TransactionAmount
}

