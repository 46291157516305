import React, {Component} from 'react'
// import localStorage from './../../utils/localStorage'

class Affiliate extends Component {
    render() {
        const {affiliatePartnerDetails} = this.props
        return(
            <div>
                {affiliatePartnerDetails.map(details => {
                    const {logo} = details
                    return <div className="second-slide-add affiliate-slide">
                        <div className="col-md-12">
                            <img src={logo} className="img-responsive" style={{width:'110px'}} alt={logo}/>
                            <h1 className="banner-text-heading">Book Services with Verified Experts with 100% Assurance. Extra 10% off over all services.</h1>
                        </div>
                    </div>
                })}
            </div>
        )
    }
}
export default Affiliate

