import React, {Component, Fragment} from "react";
import {apiv1} from "../../api";
import localStorage from './../../utils/localStorage'
import * as path from './../../constants/path'
import {errorActionDispatchers} from "../../actions/errors";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";
import LoadingComponent from "../../utils/LoadingComponent";

class ExpertSuggestedGigs extends Component {
    constructor(props){
        super(props)
        const {professional} = this.props
        this.state = {
            loading: false,
            limit: 8,
            currentPage: 1,
            suggestedGigs: [],
            totalRecord: 0,
            professional
        }
    }

    componentDidMount() {
        const {currentPage, limit, professional} = this.state
        if (professional && professional.id){
            this.getSuggestedGigs({currentPage, limit})
        } else {
            setTimeout(() => {
                const {currentPage, limit, professional} = this.state
                if (professional && professional.id) {
                    this.getSuggestedGigs({currentPage, limit})
                }
            }, 2000)
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {professional} = nextProps
        if (professional.id){
            this.setState({professional})
        }
    }

    getSuggestedGigs = ({currentPage, limit}) => {
        const {professional} = this.state
        const {profession} = professional
        this.setState({loading: true});
        apiv1.auth.get(`/users/product?profession=${profession[0]}&is_popular=true&page=${currentPage}&limit=${limit}`)
            .then(res => {
                const data = apiv1.parseAndAutoHandleIssues(res, this.props.doDispatch);
                if (data) {
                    const {results} = data
                    this.setState({loading: false, suggestedGigs: results})
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    };
    suggestedExpertGigs = ({suggestedGigs}) => {
        const {history} = this.props
        return(
            <div className="row">
                <div className="col-sm-12">
                    <h4 className="d-profile-heading">Gig Suggestions based on your skill set. Create Gigs in these popular services for more leads.</h4>
                </div>
                <div className="col-sm-12">
                    <div className="d-report-panel">
                        <div className="d-report-panel-body">
                            <ul className="list-unstyled popular-service-ul">
                                {suggestedGigs.map((gigsData)=>{
                                    const {id, display_name, practise_area} = gigsData
                                    return(
                                        <li><a className="gig-label"
                                               onClick={() => {
                                                   history.push(`${path.createExpertStandardGig}?practise_area=${practise_area}&product=${id}#title_and_desc`)
                                               }}
                                               // href={`${path.createExpertStandardGig}?practise_area=${practise_area}&product=${id}#title_and_desc`}
                                        >  {display_name}
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const {suggestedGigs, loading} = this.state
        return (
            <Fragment>
                {loading &&
                <div className='lazy-loader' style={{position: 'fixed', zIndex: '100'}}>
                    <LoadingComponent pastDelay/>
                </div>
                }
                {suggestedGigs.length > 0 &&
                this.suggestedExpertGigs({suggestedGigs})
                }
            </Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ...(errorActionDispatchers(dispatch))
    }
};
const mapStateToProps = state => ({
    professional: JSON.parse(localStorage.logedInUserDetails) && JSON.parse(localStorage.logedInUserDetails).professional || {},
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExpertSuggestedGigs))
