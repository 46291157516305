import React, {Component, Fragment} from "react";
import {connect} from 'react-redux'
import {validateProfile} from "../common/validation/FormValidator";
import {apiv1, apiv2} from "../../api";
import {userDetails} from "../../actions/auth";
import LoadingComponent from "../../utils/LoadingComponent";
import * as path from '../../constants/path'
import * as apiUrl from "../../api/apiUrls";
import {withRouter} from 'react-router-dom'
import SunEditor, {buttonList} from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';
import {apiBaseUrl, mainDomain} from "../../constants/common";
// import Dropzone from "react-dropzone";
import ReactPlayer from 'react-player/vimeo'
import {showSnackbar} from "../functional/afterHire";
import FileBase64 from 'react-file-base64';
import {allowedDocumentType} from "./ProfessionalProfile";
import localStorage from "../../utils/localStorage";
import tips from '../../static/images/tips.png';
import graphLine from '../../static/images/graph-line.png';

export function VimeoExampleVideo({videoLink}) {
    return (
        <ReactPlayer
            controls={true}
            width={`260px`}
            height={`100%`}
            url={videoLink}
        />
    )
}

export function ClientReviewsTips() {
    return (
        <div id="expert_client_reviews_tips_popup" className="modal fade" role="dialog">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">How Projects & Orders are shown on your Gigs</h4>
                    </div>
                    <div className="modal-body">
                        <p className="para">The past projects you add and the projects you get on Workbeam are both on
                            shown as Orders on your Gigs and Profile. Make sure to add atleast 8 Past Projects and
                            reviews.</p>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="thumbnail" style={{width: '100%', height: '300px'}}>
                                    <img
                                        src="https://workbeam.blob.core.windows.net/wiz-public-cdn/home+page+images/images/giglist-mok.png"
                                        style={{maxHeight: '100%', maxWidth: '100%', display: 'block'}}
                                        alt={`Example of a Good Bio 1`}/>
                                </div>
                            </div>
                            <p>&#x00A0;</p>
                            <div className="col-sm-12">
                                <div className="thumbnail" style={{width: '100%', height: '300px'}}>
                                    <img
                                        src="https://workbeam.blob.core.windows.net/wiz-public-cdn/home+page+images/images/gig-detail-mok.png"
                                        style={{maxHeight: '100%', maxWidth: '100%', display: 'block'}}
                                        alt={`Example of a Good Bio 2`}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer" style={{textAlign: 'left', marginBottom: '15px'}}>
                        <button type="button"
                                className="btn btn-save-profile"
                                data-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function CreatGigTutorial() {
    return (
        <div id="create_gig_tutorial_popup" className="modal fade" role="dialog">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">How Gigs appear to leads and visitors</h4>
                    </div>
                    <div className="modal-body">
                        {/*<p className="para">The past projects you add and the projects you get on Workbeam are both on shown as Orders on your Gigs and Profile. Make sure to add atleast 8 Past Projects and reviews.</p>*/}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="thumbnail" style={{width: '100%', height: '400px'}}>
                                    <img
                                        src="https://workbeam.blob.core.windows.net/wiz-public-cdn/home+page+images/images/giglist.png"
                                        style={{maxHeight: '100%', maxWidth: '100%', display: 'block'}}
                                        alt={`Example of a Good Bio 1`}/>
                                </div>
                            </div>
                            <p>&#x00A0;</p>
                            <div className="col-sm-12">
                                <div className="thumbnail" style={{width: '100%', height: '300px'}}>
                                    <img
                                        src="https://workbeam.blob.core.windows.net/wiz-public-cdn/home+page+images/images/Gig-Booking-Page.png"
                                        style={{maxHeight: '100%', maxWidth: '100%', display: 'block'}}
                                        alt={`Example of a Good Bio 2`}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer" style={{textAlign: 'left', marginBottom: '15px'}}>
                        <button type="button"
                                className="btn btn-save-profile"
                                data-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function GigGalleryExample() {
    return (
        <div id="gig_gallery_example" className="modal fade" role="dialog">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Best examples for Gig Gallery</h4>
                    </div>
                    <div className="modal-body">
                        {/*<p className="para">The past projects you add and the projects you get on Workbeam are both on shown as Orders on your Gigs and Profile. Make sure to add atleast 8 Past Projects and reviews.</p>*/}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="thumbnail" style={{width: '100%', height: '400px'}}>
                                    <img
                                        src="https://workbeam.blob.core.windows.net/wiz-public-cdn/home+page+images/images/Gig-Booking-Page.png"
                                        style={{maxHeight: '100%', maxWidth: '100%', display: 'block'}}
                                        alt={`Example of a Good Bio 1`}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer" style={{textAlign: 'left', marginBottom: '15px'}}>
                        <button type="button"
                                className="btn btn-save-profile"
                                data-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function BriefBioTips() {
    return (
        <div id="expert_brief_bio_tips_popup" className="modal fade" role="dialog">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Example of a Good Bio</h4>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <p className="para">Hi, I am Raghav Sharma. I am a experienced Website Developer having
                                    developed over 200 Websites till now using CMSs like Wordpress, Shopify and
                                    Magento.</p>
                                <p className="para"><b>I develop all kinds of websites like:</b></p>
                                <ul className="tips-ul list-unstyled">
                                    <li>- Ecommerce Websites</li>
                                    <li>- Multi Vendor Websites</li>
                                    <li>- Listing Websites</li>
                                    <li>- Business Websites</li>
                                    <li>- Blog Websites</li>
                                </ul>
                                <p className="para"><b>I have expertise in wordpress, woocommerce, shopify and even
                                    Magento.</b></p>
                                <p className="para"><b>Here is my approach:</b></p>
                                <ul className="tips-ul list-unstyled">
                                    <li>- Understand your website requirements along with design preference</li>
                                    <li>- Suggest right CMS, design theme and features to be used</li>
                                    <li>- Divide the project in to milestones & phases for us to track and test
                                        website
                                    </li>
                                    <li>- Testing of website</li>
                                    <li>- Training using anydesk, zoom and providing a training video of admin panel
                                    </li>
                                </ul>
                                <p className="para"><b>Check out my packages and book my services on Workbeam.</b></p>

                                <p className="para">Click on <b>”Chat & Discuss”</b> to discuss my packages and request
                                    custom quotes and Discounts.
                                    Thank You! </p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer" style={{textAlign: 'left', marginBottom: '15px'}}>
                        <button type="button"
                                className="btn btn-save-profile"
                                data-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function BioCaptionTips() {
    return (
        <div id="expert_brief_caption_tips_popup" className="modal fade" role="dialog">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">What does a Good Bio Includes</h4>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="tips-ul list-unstyled">
                                    <li>- Brief about yourself</li>
                                    <li>- Your Experience</li>
                                    <li>- Your core skills & expertise</li>
                                    <li>- No. of Projects undertaken and completed</li>
                                    <li>- Your Approach to work</li>
                                    <li>- Why people should hire you</li>
                                </ul>
                                <p className="para"><b>- Include things like 100% Refund Protection, On Time Delivery,
                                    Regular Work Updates</b></p>
                                <p className="para"><b>- Include a Call to Action.</b> Example, Click on Chat button to
                                    discuss your project and ask for my portfolio and samples.</p>
                                <p className="para"><b>- Alert:</b> You can write an alert at the top asking Leads to
                                    message you first before directly booking your package.</p>
                                <p className="para"><b>- Discount or Offer:</b> If you offering any discount you can put
                                    the same in the Bio too.</p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer" style={{textAlign: 'left', marginBottom: '15px'}}>
                        <button type="button"
                                className="btn btn-save-profile"
                                data-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

class ProfessionalBriefBio extends Component {
    constructor(props) {
        super(props);
        let {data, history, loggedInUserDetails} = props
        let {professional} = loggedInUserDetails
        professional = professional || {}
        data = data || {}
        const {location} = history
        this.api = props.is_super_user ? apiv2 : apiv1
        let bio = this.bioObj(data)
        // let htmlContent = bio.bio_detailed
        let htmlInitialContent = professional.bio_detailed
        this.state = {
            errors: {},
            data: JSON.parse(JSON.stringify(data)),
            bio,
            updatedSuccessfully: false,
            // htmlContent,
            htmlInitialContent,
            captionExample: [],
            documentLink: '',
            document_file_name: '',
            document_base64: '',
        }
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        const {loggedInUserDetails} = this.props
        let {professional} = loggedInUserDetails
        professional = professional || {}
        if (professional.id){
            this.getExpertResume(professional.id)
        }
        this.getProfileCaptionExample()
    }

    componentWillReceiveProps(nextProps) {
        let {data, is_super_user, history} = nextProps
        let {professional} = data
        let professionalId = ''
        if (!professional) {
            data['professional'] = {}
        } else {
            professionalId = professional.id
        }
        if (professionalId) {
            const bio = this.bioObj(data)
            let htmlInitialContent = bio.bio_detailed
            const appData = JSON.parse(JSON.stringify(data))
            this.setState({
                data: appData,
                bio,
                htmlInitialContent
            })
        } else if (is_super_user) {
            history.push(path.adminProfessionalList)
            alert('You can not refresh this page')
        }
        if (is_super_user) {
            this.api = is_super_user ? apiv2 : apiv1
        }

    }

    handleChange = (content) => {
        this.setState({bio_detailed: content})
    }

    restAuthPatch = ({request}) => {
        const {doDispatch, onUserDetails} = this.props
        apiv1.auth.patch(apiUrl.restAuth, request)
            .then(res => {
                const data = apiv1.parseAndAutoHandleIssues(res, doDispatch);
                if (data) {
                    this.setState({loading: false, has_submitted_for_review: true}, () => {
                        onUserDetails(userDetails(data))
                    })
                }
            })
            .catch(err => {
                this.setState({loading: false})
                apiv1.handleErrors(err, doDispatch);
            })
    }
    getProfileCaptionExample = () => {
        const {loggedInUserDetails} = this.props
        let {professional} = loggedInUserDetails
        const {expProfession} = this.props
        this.setState({loading: true})
        apiv1.auth.get(`${apiUrl.getCaptionExample}${`1/`}${professional.profession}/`)
            .then(res => {
                const data = apiv1.parseAndAutoHandleIssues(res, this.props.doDispatch);
                if (data) {
                    this.setState({loading: false, captionExample: data})
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    };
    getExpertResume = (professionalId) => {
        this.setState({loading: true})
        apiv1.auth.get(`${apiUrl.uploadResume}?professional=${professionalId}`)
            .then(res => {
                const data = apiv1.parseAndAutoHandleIssues(res, this.props.doDispatch);
                if (data) {
                    const {document_file_name, document, id} = data[0] || {}
                    this.setState({loading: false, document_file_name, documentLink: document, resumeId: id})
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    };
    returnProfessionalObj = () => {
        let {data} = this.props
        let {professional} = data || {}
        return professional
    }

    bioObj = (data) => {
        const professional = this.returnProfessionalObj()
        let {bio_caption, bio_detailed, linkedin_profile, linked_profile_facebook, linked_profile_dribbble, linked_profile_behance, linked_profile_github, linked_profile_twitter, linked_profile_instagram, linked_profile_upwork, linked_profile_fiverr, linked_profile_other} = professional
        bio_caption = bio_caption || ''
        bio_detailed = bio_detailed || ''
        linkedin_profile = linkedin_profile || ''
        return {
            bio_caption,
            bio_detailed,
            linkedin_profile,
            linked_profile_facebook,
            linked_profile_dribbble,
            linked_profile_behance,
            linked_profile_github,
            linked_profile_twitter,
            linked_profile_instagram,
            linked_profile_upwork,
            linked_profile_fiverr,
            linked_profile_other
        }
    };

    onChange = (section, e) => {
        const {name, value} = e.target
        const state = {...this.state}
        state[section][name] = value
        const newErrors = {...this.state.errors};
        newErrors[name] = '';
        state['errors'] = newErrors;
        this.setState(state)
    };

    deleteResume = ({document_base64, document_file_name}) => {
        const {resumeId} = this.state
        const {doDispatch} = this.props
        this.api.auth.delete(`${apiUrl.uploadResume}${resumeId}`)
            .then(resp => {
                const resData = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                if (resData === '') {
                    this.setState({resumeId: ''}, () => {
                        this.uploadResume({document_base64, document_file_name})
                    })
                } else {
                    this.setState({loading: false, updatedSuccessfully: false})
                }
            })
            .catch(err => {
                apiv1.handleErrors(err, doDispatch);
                this.setState({loading: false})
            })
    }

    uploadResume = ({document_base64, document_file_name}) => {
        const {bio, data} = this.state
        let {professional} = data
        professional = professional || {}
        const request = {
            professional: professional.id,
            document_base64,
            document_file_name
        }
        const {doDispatch} = this.props
        this.setState({loading: true});
        this.api.auth.post(apiUrl.uploadResume, request)
            .then(resp => {
                const resData = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                if (resData) {
                    const {document_file_name, document, id} = resData
                    this.setState({loading: false, document_file_name, documentLink: document, resumeId: id}, () => {
                        showSnackbar({message: 'Uploaded'})
                    })
                } else {
                    this.setState({loading: false, updatedSuccessfully: false})
                }
            })
            .catch(err => {
                apiv1.handleErrors(err, doDispatch);
                this.setState({loading: false})
            })
    }

    uploadDocument = (files) => {
        const currentUrl = files.base64;
        let newUrl = '';
        for (let i = 0; i < allowedDocumentType.length; i++) {
            if (currentUrl.includes(allowedDocumentType[i])) {
                newUrl = currentUrl.replace(allowedDocumentType[i], "")
            }
        }
        if (newUrl === '') {
            alert('not a valid type document')
        } else {
            const {resumeId} = this.state
            if (resumeId){
                this.deleteResume({document_base64: newUrl, document_file_name: files.name})
            } else {
                this.uploadResume({document_base64: newUrl, document_file_name: files.name})
            }
            // this.setState({document_base64: newUrl, document_file_name: files.name, documentLink: ''});
        }
    };

    saveBio = () => {
        const {bio} = this.state
        const request = {
            professional: bio
        }
        const {doDispatch, moveToNextTab} = this.props
        const errors = {};
        const isFormValidated = validateProfile(errors, request.professional)
        if (isFormValidated) {
            this.setState({loading: true});
            this.api.auth.patch(apiUrl.restAuth, request)
                .then(resp => {
                    const resData = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                    if (resData) {
                        this.setState({loading: false, updatedSuccessfully: true}, () => {
                            const {onSaveBasicDetails} = this.props
                            onSaveBasicDetails(userDetails(resData))
                            showSnackbar({message: 'Updated'})
                            if (moveToNextTab){
                                moveToNextTab(5)
                            }
                        });
                    } else {
                        this.setState({loading: false, updatedSuccessfully: false})
                    }
                })
                .catch(err => {
                    const resErrors = apiv1.handleErrors(err, doDispatch);
                    if (resErrors && resErrors.professional) {
                        for (let key in resErrors.professional) {
                            errors[key] = resErrors['professional'][key][0]
                        }
                        this.setState({loading: false, updatedSuccessfully: false, errors}, () => {
                            showSnackbar({message: 'Please fill required fields'})
                        })
                    }
                })
        } else {
            this.setState({errors}, () => {
                showSnackbar({message: 'Please fill required fields'})
            })
        }
    }

    socialMediaLink = () => {
        const {bio, errors} = this.state
        return (
            <div className="row basic-detail-form">
                <div className="col-sm-12">
                    <h4 className="heading-md"
                        style={{marginBottom: '20px', marginTop: '0px', fontSize: '16px', color: '#25272E'}}>Add your
                        website & social Profiles (Optional)</h4>
                </div>
                <div className="col-sm-6 form-group">
                    <label>Linkedin Profile</label>
                    <input type="text" className="form-control input-lg"
                           name="linkedin_profile"
                           placeholder="Linkedin Profile"
                           value={bio.linkedin_profile}
                           style={{borderBottom: errors.linkedin_profile ? '1px solid #ff337d' : ''}}
                           onChange={(e) => this.onChange('bio', e)}

                    />
                    <span
                        className={errors.linkedin_profile ? 'error' : 'no-error'}>{errors.linkedin_profile}</span>
                </div>
                <div className="col-sm-6 form-group">
                    <label>Github Profile</label>
                    <input type="text" className="form-control input-lg"
                           name="linked_profile_github"
                           placeholder="Github Profile"
                           value={bio.linked_profile_github}
                           style={{borderBottom: errors.linked_profile_github ? '1px solid #ff337d' : ''}}
                           onChange={(e) => this.onChange('bio', e)}

                    />
                    <span
                        className={errors.linked_profile_github ? 'error' : 'no-error'}>{errors.linked_profile_github}</span>
                </div>
                {/* <div className="col-sm-6 form-group">
                    <label>Upwork Profile</label>
                    <input type="text" className="form-control input-lg"
                           name="linked_profile_upwork"
                           placeholder="Upwork Profile"
                           value={bio.linked_profile_upwork}
                           style={{borderBottom: errors.linked_profile_upwork ? '1px solid #ff337d' : ''}}
                           onChange={(e) => this.onChange('bio', e)}

                    />
                    <span
                        className={errors.linked_profile_upwork ? 'error' : 'no-error'}>{errors.linked_profile_upwork}</span>
                </div> */}
                {/* <div className="col-sm-6 form-group">
                    <label>Fiverr Profile</label>
                    <input type="text" className="form-control input-lg"
                           name="linked_profile_fiverr"
                           placeholder="Fiverr Profile"
                           value={bio.linked_profile_fiverr}
                           style={{borderBottom: errors.linked_profile_fiverr ? '1px solid #ff337d' : ''}}
                           onChange={(e) => this.onChange('bio', e)}

                    />
                    <span
                        className={errors.linked_profile_fiverr ? 'error' : 'no-error'}>{errors.linked_profile_fiverr}</span>
                </div> */}
                {/* <div className="col-sm-6 form-group">
                    <label>Dribbble Profile</label>
                    <input type="text" className="form-control input-lg"
                           name="linked_profile_dribbble"
                           placeholder="Dribbble Profile"
                           value={bio.linked_profile_dribbble}
                           style={{borderBottom: errors.linked_profile_dribbble ? '1px solid #ff337d' : ''}}
                           onChange={(e) => this.onChange('bio', e)}

                    />
                    <span
                        className={errors.linked_profile_dribbble ? 'error' : 'no-error'}>{errors.linked_profile_dribbble}</span>
                </div> */}
                {/* <div className="col-sm-6 form-group">
                    <label>Behance Profile</label>
                    <input type="text" className="form-control input-lg"
                           name="linked_profile_behance"
                           placeholder="Behance Profile"
                           value={bio.linked_profile_behance}
                           style={{borderBottom: errors.linked_profile_behance ? '1px solid #ff337d' : ''}}
                           onChange={(e) => this.onChange('bio', e)}

                    />
                    <span
                        className={errors.linked_profile_behance ? 'error' : 'no-error'}>{errors.linked_profile_behance}</span>
                </div> */}
                <div className="col-sm-6 form-group">
                    <label>Personal Portfolio</label>
                    <input type="text" className="form-control input-lg"
                           name="linked_profile_other"
                           placeholder="Personal Portfolio or Website"
                           value={bio.linked_profile_other}
                           style={{borderBottom: errors.linked_profile_other ? '1px solid #ff337d' : ''}}
                           onChange={(e) => this.onChange('bio', e)}

                    />
                    <span
                        className={errors.linked_profile_other ? 'error' : 'no-error'}>{errors.linked_profile_other}</span>
                </div>
            </div>

        )
    }

    bioUi = () => {
        const {bio, errors, loading, htmlInitialContent, documentLink, captionExample, resumeId} = this.state;
        return (
            <Fragment>
                <form className="basic-detail-form">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label>Bio Caption <span className="star-note">*</span></label>
                                <input type="text" className="form-control input-lg"
                                       id="bio_caption"
                                       name="bio_caption"
                                       placeholder="Caption under your name"
                                       value={bio.bio_caption}
                                       style={{borderBottom: errors.bio_caption ? '1px solid #ff337d' : ''}}
                                       onChange={(e) => this.onChange('bio', e)}

                                />
                                <span
                                    className={errors.bio_caption ? 'error' : 'no-error'}>{errors.bio_caption}</span>
                                {captionExample.length > 0 &&
                                <Fragment>
                                    <h4 className="text-note" style={{color: '#25272E'}}>Example of Good Captions:</h4>
                                    <ul className="tips-ul">
                                        {captionExample.slice(0, 8).map((exampleData, index) => {
                                            return (
                                                <li style={{color: '#000'}}>{exampleData}</li>
                                            )
                                        })}
                                    </ul>
                                </Fragment>
                                }
                            </div>
                            <div className="clearfix"/>
                            <div className="form-group">
                                <label>Write your Bio - Atleast 60 words <span className="star-note">*</span></label>
                                <p className="text-note" style={{color: '#ff337d'}}>*Please dont put your Company Name,
                                    Email, number and website or any other personal info.</p>
                                {/*{htmlInitialContent &&  }*/}
                                <Fragment>
                                    <SunEditor
                                        setDefaultStyle="font-size: 14px;height:300px;"
                                        setOptions={{
                                            imageUploadUrl: `${apiBaseUrl}${apiUrl.editorImageUpload}`,
                                            buttonList: buttonList.complex,
                                        }}
                                        placeholder="Please type here..."
                                        setContents={htmlInitialContent}
                                        autoFocus={true}
                                        onChange={(content) => {
                                            let event = {
                                                target: {
                                                    name: 'bio_detailed',
                                                    value: content
                                                }
                                            }
                                            this.onChange('bio', event)
                                        }}
                                    />
                                    <span
                                        className={errors.bio_detailed ? 'error' : 'no-error'}>{errors.bio_detailed}</span>
                                </Fragment>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="row">
                    <div className="col-sm-12">
                        <label className="btn-upload-file btn-bs-file" style={{
                            color: '#ff337d',
                            fontSize: '16px',
                            fontFamily: 'sofiaLight',
                            cursor: 'pointer',
                            marginBottom: '10px'
                        }}>
                            <i className="fa fa-cloud-upload"/> {`${resumeId ? 'Change Resume' : 'Upload Resume'}`}
                            <FileBase64
                                onDone={this.uploadDocument}>
                            </FileBase64>
                        </label>
                      {resumeId && <p className="para"><a style={{color:'#ff337d'}} href={documentLink} target='_blank'>See Attachment</a></p>}
                    </div>
                </div>
                {this.socialMediaLink()}
                {loading && (
                    <div className="lazy-loader" style={{position: 'fixed', zIndex: '1'}}>
                        <LoadingComponent pastDelay/></div>)}
                <div className="row">
                    <div className="col-sm-12">
                        <button className="btn btn-save-profile-gig"
                                onClick={this.saveBio}
                        >
                            Save & Continue
                        </button>
                        <p>&#x00A0;</p>
                    </div>
                </div>
            </Fragment>
        )
    };

    onChangeInputType = (e) => {
        const {name, value} = e.target
        const errors = {...this.state.errors};
        errors[name] = '';
        this.setState({[name]: value, errors})
    }

    render() {
        const {history} = this.props
        return (
            <div className="d-update-profile">
                <div className="d-update-profile-body">
            <div className="row">
                <div className="col-sm-12">
                    <div className="profile-tutorial-container">
                        <div className="row">
                            <div className="col-sm-6">
                                <h2 className="profile-tutorial-heading"><img
                                    src={tips}
                                    style={{width: '30px', float: 'left', marginRight: '5px'}} alt={`Create Gig Tips`}/>Good
                                    Bio means 2x more leads</h2>
                                <p className="profile-tutorial-sub-heading">Read Knowledge resources</p>
                                <ul className="profile-tutorial-ul list-unstyled">
                                    <li><img src={graphLine}
                                             alt={`What does a good Bio includes?`}/><a data-toggle="modal"
                                                                                        data-target="#expert_brief_caption_tips_popup">What
                                        does a good Bio includes</a></li>
                                    <li><img src={graphLine}
                                             alt={`Examples of Good Bio`}/><a data-toggle="modal"
                                                                              data-target="#expert_brief_bio_tips_popup">Examples
                                        of Good Bio</a></li>
                                    <li><img src={graphLine}
                                             alt={`Best Seller Profiles with Good Bio`}/><a
                                        href={`${mainDomain}${path.helpCenter}`} target={`_blank`}>Best Seller Profiles
                                        with Good Bio</a></li>
                                </ul>
                            </div>
                            <div className="col-sm-6">
                                <h2 className="profile-tutorial-sub-heading">How to write your Bio to get maximum
                                    leads</h2>
                                <VimeoExampleVideo videoLink={`https://player.vimeo.com/video/566547709`}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-10">
                    {this.bioUi()}
                </div>
                <BriefBioTips/>
                <BioCaptionTips/>
            </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    data: state.data,
    loggedInUserDetails: JSON.parse(localStorage.logedInUserDetails),
    is_super_user: state.data.is_super_user
});

const mapDispatchToProps = dispatch => {
    return {
        OnUpdatePhone: (action) => {
            dispatch(action);
        },
        onUserDetails: (action) => {
            dispatch(action);
        },
        onSaveBasicDetails: (action) => {
            dispatch(action);
        },
        onVerifyPhone: (action) => {
            dispatch(action);
        },
        onSaveSate: (action) => {
            dispatch(action);
        },
        onUpdateAProfessionalKey: (action) => {
            dispatch(action);
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)((withRouter(ProfessionalBriefBio)))
