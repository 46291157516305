const removeAnsOfPracticeAreaQuestions = `/job/job-answer`
const exhotelCall = `https://api.exotel.com/v1`
const transactionAmount = '/invoice/transection-value'
const restAuthLogin = `rest-auth/login/`
const earnMoneyFromReferral = `users/refer`
const proposalFilterByTitle = `/job/proposal-filter/`
const createJobManually = `/job/create-job-manually`
const assignJobToProfessional = `/job/assign-job-to-professional`
const jobTransferToOtherProfessional = `/job/job-transfer-to-other-professional`
const proposalListWithUserDetails = `/job/proposal-filter/`
const userExhotelCall = '/users/call'
const jobProposalList = '/job/proposal/'
const sendProposal = '/job/proposal/'
const updateProposal = '/job/proposal-update'
const phoneNumberByUserId = '/users/phone/'
const productList = '/users/product/'
const professionalList = '/job/professional-dropdown/'
const parentPracticeArea = '/users/practiseareaparent/'
const productListDropdown = '/users/product-dropdown/'
const projectsList = '/users/projects'
const getPractice = `users/practisearea/`
const getTestSkills = `users/skill-tests-list/`
const shareThisJobWithOther = '/job/access-share'
// const jobLead = '/job/lead/'
const manageCloseDisputeOrder = '/job/manage-close-dispute-order/'
const allConsultations = '/consultants/lead-consultation/'
// const jobFeedBack = `/job/job-feedback/`
const jobLeadWithFilter = '/users/lead-generations-with-date-filter-view/'
const checkNewAddedPractiseArea = '/users/practisearea-check/'
const checkNewAddedProduct = '/users/product-check/'
const professionalProfileById = '/users/professionalprofile'
const communityPost = '/community/post-admin'
const raiseTicket = '/crm/ticket/'
const professionalInvoiceList = '/invoice/all-invoices-professional'
const invoiceWhenPaymentList = '/invoice/invoice-when-payment'
const professionalInvoiceToWiz = '/invoice/professional-to-wizcounsel-invoice'
const superAdminFeedbackList = '/job/super-user-feedback-list/'
const freeConsultation = '/consultants/free-cons/'
const superAdminUserList = '/users/client-search'
const sendMailToUpdatePractiseAreaToForProfessionals = '/users/admin/send-practise-area-product-mail'
const jobListWithProposalCount = '/job/job-list-with-proposal-count/'
// const footerLinkBySlug = '/users/footer-link-by-slug'
const jobInProgressList = '/job/contracted-job-list/'
const jobMilestoneAddUpdate = '/job/milestone-add-update/'
// const userLoginWithPhone = '/users/login-phone/'
const postQuestion = `/community/post/`
const getAnswer = `/community/comment/`
const getLeftSideCategory = `/community/public-areas/`
const usersLanguages = `/users/language/`
const usersCertificates = `/users/certification/`
const professionalProfileReview = `/users/professionalprofilereviewrequest/`
const userProfessionalProductPublic = `users/professional-product-public/`
const sendWhatsappNotification = `/notifications/whatsapp-bulk-send`
const sendNotificationToProfessional = `/notifications/notify/`
const userPackage = `users/product`
const assignPackage = `/job/job-transfer-standard-package`
const aboutConsultation = `/consultants/consultant`
const createJob = `/job/job/`
const cashfreePayment = `/webhooks/payment/cashfree/link`
const checkReferralCode = `/users/referral-code-valid`
const recordProductClickedCount = `/marketing/save-product-click`
const listOfSupply = `/crm/professional-follow-up/`
const clientFeedback = `/crm/client-feedback/`
const jobInProgressSuccess = `/crm/job-in-progress-success/`
const sendAutoMessage = `/job/job-send-auto-msg`
const jobDocument =  `/job/document/`
const consultationDocument =  `/consultants/document/`
const professionalPublicProfileBySlug = `/users/public`
const proposalDocument =  `/job/proposal-document/`
const customProductSample =  `/users/sample/`
// const verifyOtpOnWizcounsel = `/users/verify-otp`
const professionalPractiseArea = `/users/practisearea/`
const getProfessions = `/users/profession/`
const getCaptionExample = `/users/get-captions-gig-titles/`
const discountCoupon = `/users/discount`
// const productSearch = `/users/product-search/`
const gigProfessionalList = `/users/min-professional/`
const logErrorOnServer = `/users/slack-error`

//Milestone
const customMilestone = '/job/job-milestone-custom/'
const customProductMilestone = '/job/milestone-custom-product-bulk-update/'

//professional Product
const getLegalAndAccountingProfessionals = `/users/professional-list-with-filter-by-slug-web`
const professionalProduct = 'users/professional-product/'
const getProfessionalProductList = 'users/professional-product-list'
// const getProfessionalProductMinList = '/users/professional-product-min'
const professionalCustomProduct = `/users/product-custom/`
const getProductIncludedQuestion = `/users/product-whats-included/`
const getTrainingQuestion = `/users/training-question/`
const setProductIncludedQuestion = `/users/product-whats-included-set/`
const setProfessionalProductWhatsIncluded = `/users/product-desc-set/`
const professionalProductSamples = `/users/pp-fix-sample/`
const gigNonPublicQuestion = `/users/gig-question-answer/`
const getGigList = `/users/professional-product-list-page`
const getDefaultGig = `users/professional-product`
const languageDeveloperGig = `users/product-service-options`
const ecommerceServices = `Ecommerce-Website-Development`
const mobileApp = `android-mobile-app`
const getIndustryByProduct = '/users/industry/'
const dashboardDefaultGigList = `users/list-product-by-professional`

//Custom product
const getCustomProductIncludedQuestion = `users/gig-store-whats-included/`
const setCustomProductIncludedQuestion = `users/gig-store-whats-included-set/`

//Blog
const saveBlog =  `/learning-center/content/`
const saveCommunityPost =  `/community/post/`
const saveFakeCommunityPost =  `/community/post-random-create/`
const saveEmail=  `/marketing/miscellaneous-emailer/`
const saveComplianceEmail=  `/marketing/compliance-of-the-month-emailer/`
const saveBlogOfTheDay =  `/marketing/blog-of-the-mailer/`
const saveTrendingEmail=  `/marketing/tranding-product-mailer/`

//Chat
const conferenceCallRequest =  `chat/conference/`
// const hasProfessionalMessageOnJob =  `/chat/has-professional-messaged`
// const triggerWhatsappAlert =  `/notifications/trigger-whatsapp-alert`
const triggerWhatsappAlertOnFirstMessage =  `/notifications/trigger-whatsapp-alert-new`
// const exotelPhoneCall =  `/job/before-call/`
// const attachProductOnChat = `/chat/attach-product`
// const getChatRooms = `/chat/history`
const getChatRoomsLite = `/chat/history-lite`
const getChatHistory = `/chat`
const getUserAndProChatHistory = `/chat/chat-new`
const updateChatRoom = `/chat/admin-room-new/`
const getAdminChatHistory = `/chat/chat-lite`
const createChatRoomWithProfessionalProduct = `/chat/create-roompp/`
const createChatRoomWithoutProfessionalProduct = `/chat/create-room/`
// const chatSuggestionOnProduct = `chat/chat-suggestion`


// const charRoomUsers =  `/chat/team/`
const insertSocketData = `/chat/chat-fallback-create/`
const chatGigJobLeadWithFilter = '/users/gig-chat-lead-generations-with-date-filter-view/'
// const getChatRoomDetails =  `/chat/room/`
const chatHistoryAdmin =  `/chat/history-admin`
const chatHistoryClient =  `/chat/history-client`
const chatHistoryProfessional =  `/chat/history-pro`
const hasProfessionalMessaged =  `/chat/has-messaged`
const getJobListByRoom = `chat/room-proposals/`
const agoraCallNotification = `notifications/notification/`
const saveAgoraStreamedToServer = `/users/agora-drop/`
const gigPrivateQuestion = `/users/gig-question`
const professionalTrainingQuestion = `/users/training-question-answer/`

//Phone
// const wizcounselSendOtp = `/users/send-otp`
const wizcounselSendOtpWithCountryCode = `/users/send-otp-with-country-code`
const wizcounselVerifyOtpWithCountryCode = `users/verify-otp-with-country-code`
const wizcounselSendOtpWithPhoneObj = `/users/otp/send`

//Geo
const getCitiesOfProfessionalRecord = `/geo/city-for-user/`
const geoCity = `/geo/city/`
const geoState = `/geo/state/`
const getGeoLocation = `https://ipapi.co/json/`

//Feedback
const adminFeedbackApprove = `/job/job-feedback`
const communityPostLive = `/community/post-admin`

//Proposal
const acceptProposal = `/job/proposal-accept/`

//Coupon, Referral, Affiliates
const getAffiliateRecord = `/users/affliate/`

//Rest-auth
const restAuth = `rest-auth/user/`
const blockUser = `users/blocked/`
const userAndProRegistration = `/rest-auth/registration/`
const createClientType = `/users/create-client/`
const fcmToken = `/device/gcm/`
const customFcmToken = `notifications/custom-device/`
const freebie = `users/freebie/`
const uploadResume = `users/resume/`
const generateEmailOtp = `/users/generate-otp`
const verifyEmailOtp = `/users/email-verify-otp`


//Job
const userContractedJobLite = `job/contracted-job-lite/`
const professionalContractedJobLite = `job/contracted-job-lite-professional/`
const jobHistoryLite = `job/job-completed-lite/`
export const addNotes = `/job/job-note/`

//Interview
const interviewCandidate = '/interview/interview-candidate/'
const interviewUnscheduled = '/interview/interview-unscheduled/'
const interviewUpcoming = '/interview/interview-upcoming/'
const pastInterview = '/interview/interview-past/'
const requestReschedule = '/interview/request-reschedule/'
const shortlistedInterview = '/interview/interviewers/'

//Document
const editorImageUpload = `webhooks/editor-image-upload`

// Call Scheduler
const callScheduler = `chat/schedule-call`

//Community
const getCommunityPosts = `/community/post-filter`
const addCommentOnAPost = `/community/comment/`
const likeOnAPost = `/community/like/`
const createPost = `/community/post/`
const publicCommunityComment = `/community/comment-filter`
const clientSearch = `/users/client-search/`
const publicProfileByUserId= `/users/public-by-user-id`
const uploadCommunitySamples = `community/community-sample/`
const migrateCommunitySample = `community/fix-sample/`

//Professional admin list
const professionalResponsiveFlagUpdate = `users/flag-update/`

// Global Search
const globalSearch = `users/universal-search`
const globalSearchResult = `users/universal-search-middle-page`
const professionalProfession = `users/professional-profession`
const portfolioLinkCheck = `users/product-portfolio-links-check`
const getProfessionalListFromHiringFlow = `/users/hire-flow-professional-list`

//Affiliate
// const getAffiliateEarning = `users/affliate/`

export {
    removeAnsOfPracticeAreaQuestions,
    // exhotelCall,
    transactionAmount,
    restAuth,
    earnMoneyFromReferral,
    // proposalFilterByTitle,
    createJobManually,
    assignJobToProfessional,
    jobTransferToOtherProfessional,
    proposalListWithUserDetails,
    userExhotelCall,
    jobProposalList,
    phoneNumberByUserId,
    productList,
    professionalList,
    parentPracticeArea,
    productListDropdown,
    getPractice,
    getTestSkills,
    shareThisJobWithOther,
    // jobLead,
    manageCloseDisputeOrder,
    // jobFeedBack,
    allConsultations,
    jobLeadWithFilter,
    checkNewAddedPractiseArea,
    checkNewAddedProduct,
    professionalProfileById,
    communityPost,
    raiseTicket,
    professionalInvoiceList,
    professionalInvoiceToWiz,
    professionalProduct,
    superAdminFeedbackList,
    freeConsultation,
    superAdminUserList,
    sendMailToUpdatePractiseAreaToForProfessionals,
    jobListWithProposalCount,
    // footerLinkBySlug,
    jobInProgressList,
    jobMilestoneAddUpdate,
    // userLoginWithPhone,
    customMilestone,
    postQuestion,
    getAnswer,
    getLeftSideCategory,
    usersLanguages,
    usersCertificates,
    professionalProfileReview,
    userProfessionalProductPublic,
    sendWhatsappNotification,
    sendNotificationToProfessional,
    userPackage,
    assignPackage,
    aboutConsultation,
    sendProposal,
    createJob,
    cashfreePayment,
    checkReferralCode,
    recordProductClickedCount,
    listOfSupply,
    clientFeedback,
    jobInProgressSuccess,
    sendAutoMessage,
    // attachProductOnChat,
    // getChatRooms,
    getChatHistory,
    jobDocument,
    consultationDocument,
    professionalPublicProfileBySlug,
    proposalDocument,
    customProductSample,
    // charRoomUsers,
    // wizcounselSendOtp,
    // verifyOtpOnWizcounsel,
    professionalPractiseArea,
    userAndProRegistration,
    getProfessions,
    getCaptionExample,
    geoCity,
    geoState,
    discountCoupon,
    // productSearch,
    chatGigJobLeadWithFilter,
    gigProfessionalList,
    restAuthLogin,
    getProfessionalProductList,
    updateProposal,
    getLegalAndAccountingProfessionals,
    saveBlog,
    saveEmail,
    saveComplianceEmail,
    saveBlogOfTheDay,
    saveTrendingEmail,
    conferenceCallRequest,
    wizcounselSendOtpWithCountryCode,
    wizcounselVerifyOtpWithCountryCode,
    getCitiesOfProfessionalRecord,
    // getProfessionalProductMinList,
    // hasProfessionalMessageOnJob,
    // triggerWhatsappAlert,
    adminFeedbackApprove,
    communityPostLive,
    wizcounselSendOtpWithPhoneObj,
    professionalCustomProduct,
    customProductMilestone,
    acceptProposal,
    logErrorOnServer,
    getAffiliateRecord,
    // exotelPhoneCall,
    insertSocketData,
    blockUser,
    // getChatRoomDetails,
    getProductIncludedQuestion,
    getTrainingQuestion,
    setProductIncludedQuestion,
    professionalProductSamples,
    getChatRoomsLite,
    getCustomProductIncludedQuestion,
    setCustomProductIncludedQuestion,
    getGeoLocation,
    chatHistoryAdmin,
    chatHistoryClient,
    chatHistoryProfessional,
    getUserAndProChatHistory,
    getAdminChatHistory,
    hasProfessionalMessaged,
    triggerWhatsappAlertOnFirstMessage,
    updateChatRoom,
    userContractedJobLite,
    professionalContractedJobLite,
    jobHistoryLite,
    interviewCandidate,
    interviewUnscheduled,
    interviewUpcoming,
    pastInterview,
    requestReschedule,
    shortlistedInterview,
    editorImageUpload,
    createClientType,
    getJobListByRoom,
    agoraCallNotification,
    saveAgoraStreamedToServer,
    fcmToken,
    // getConferenceRequest
    // getConferenceRequest,
    invoiceWhenPaymentList,
    saveCommunityPost,
    saveFakeCommunityPost,
    customFcmToken,
    createChatRoomWithProfessionalProduct,
    createChatRoomWithoutProfessionalProduct,
    gigPrivateQuestion,
    gigNonPublicQuestion,
    professionalTrainingQuestion,
    callScheduler,
    getCommunityPosts,
    addCommentOnAPost,
    likeOnAPost,
    createPost,
    publicCommunityComment,
    getGigList,
    professionalResponsiveFlagUpdate,
    clientSearch,
    publicProfileByUserId,
    globalSearch,
    globalSearchResult,
    languageDeveloperGig,
    portfolioLinkCheck,
    ecommerceServices,
    mobileApp,
    professionalProfession,
    // chatSuggestionOnProduct,
    uploadCommunitySamples,
    migrateCommunitySample,
    freebie,
    getDefaultGig,
    getProfessionalListFromHiringFlow,
    getIndustryByProduct,
    setProfessionalProductWhatsIncluded,
    dashboardDefaultGigList,
    uploadResume,
    generateEmailOtp,
    verifyEmailOtp
}
