import localStorage from './../../utils/localStorage'
import {App_env} from "../../api";
// const baseUrl = 'wss://api.wizcounsel.com:8001'
let baseUrl = 'wss://api-dev.wizcounsel.com:8001';

if (process.env.REACT_APP_ENV === App_env.production) {
    baseUrl = 'wss://api.wizcounsel.com:8001'
}

export function getSocketUrl({jobId, proposalId, counterPartyUserId, professionalProductId}) {
    const token = localStorage.authToken.substring(7)
    // if (jobId && proposalId) {
    //     return `${baseUrl}/chatnew/${jobId}/${proposalId}?token=${token}`
    // }
    if (counterPartyUserId && professionalProductId) {
        return `${baseUrl}/roompp/${counterPartyUserId}/${professionalProductId}?token=${token}`
    }
    return `${baseUrl}/room/${counterPartyUserId}?token=${token}`
}

class Socket {
    constructor() {
        this.socket = null;
        this.socketUrl = null
    }

    onConnection = (event) => {
        console.log('socket connected', event)
    };
    onConnectionLost = (event) => {
        console.log('connection lost', event)
    };
    onError = (event) => {
        console.log('error in connection', event)
    }

    isReady = () => {
        return this.socket && this.socket.readyState === 1

    }

    close = () => {
        if (this.socket) {
            this.socket.close()
            this.socket = ''
        }
    }

    dummyFunction = () => {

    }

    socketEventListener = ({onMessage, onSocketOpen, onConnectionError, onConnectionLost}) => {
        this.socket.onopen = onSocketOpen ? onSocketOpen : this.onConnection
        this.socket.onmessage = onMessage ? onMessage : this.dummyFunction
        this.socket.onclose = onConnectionLost ? onConnectionLost : this.onConnectionLost
        this.socket.onerror = onConnectionError ? onConnectionError : this.onError
    };

    startSocketConnection({jobId, proposalId, onMessage, onSocketOpen, onConnectionError, onConnectionLost, counterPartyUserId, professionalProductId}) {
        this.socketUrl = getSocketUrl({jobId, proposalId, counterPartyUserId, professionalProductId})
        // this.socket = ''
        this.socket = new WebSocket(this.socketUrl)
        console.log('this.socketUrl', this.socketUrl)
        // if (!JSON.parse( localStorage.isSuperUser)){
        //     this.socket = new WebSocket(this.socketUrl)
        // }
        if (this.socket && (onMessage || onSocketOpen || onConnectionError || onConnectionLost)) {
            this.socketEventListener({onMessage, onSocketOpen, onConnectionError, onConnectionLost})
        }
        return this
    }
}

const socket = new Socket();

export default socket