
export function idEducationValid(errors ,request){
    let isAllValid = true;
    for (let key in request) {
        if (key === 'to_year' && Number(request[key]) < Number(request['from_year'])) {
            errors[key] = "Start date cannot be greater than end date";
            isAllValid = false
        }
         else if (key === 'institute' && request[key].trim() === '') {
            errors[key] = 'This field is required';
            isAllValid = false
        } else if (key === 'degree' && request[key].trim() === '') {
            errors[key] = 'This field is required';
            isAllValid = false
        }
    }
    return isAllValid

}
export function experienceFieldValidator(errors, value, key) {
    if (isNaN(value)) {
        errors[key] = 'Only integer value is allowed';
        return false
    } else if (value === '0.00' || value===''){
        errors[key] = 'This field is required';
        return false
    }
    return true
}

export function reviewValidator(errors ,request){
    let isAllValid = true;
    for (let key in request) {
        if (key === 'user_name' && request[key].trim() === '') {
            errors[key] = 'This field is required';
            isAllValid = false
        }
        else if (key === 'project_name' && request[key].trim() === '') {
            errors[key] = 'This field is required';
            isAllValid = false
        }
        else if (key === 'comment' && request[key].trim() === '') {
            errors[key] = 'This field is required';
            isAllValid = false
        }
    }
    return isAllValid

}