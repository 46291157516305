export const LOGIN = 'auth.login';
export const AUTH_TOKEN = 'auth.token';
export const LOGOUT = 'auth.logout';
export const SIGNUP = 'auth.signup';
export const USER = 'user';
export const DETAILS = 'details';
export const PROFESSIONAL = 'professional';
export const UPLOAD_PROFILEPIC = 'UPLOAD_PROFILEPIC';
export const GET_STATE = 'GET_STATE';
export const GET_CITY = 'GET_CITY';
export const GET_PROFESSIONS = 'GET_PROFESSIONS';
export const VERIFY_PHONE = 'VERIFY_PHONE';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const USER_JOB_IN_PROGRESS_COUNT = 'USER_JOB_IN_PROGRESS_COUNT';
export const USER_JOB_POSTING_COUNT = 'USER_JOB_POSTING_COUNT';
export const INCREASE_JOB_POSTING_COUNT = 'INCREASE_JOB_POSTING_COUNT';
export const DECREASE_JOB_INPROGRESS_COUNT = 'DECREASE_JOB_INPROGRESS_COUNT';
export const PROFESSIONAL_JOB_IN_PROGRESS_COUNT = 'PROFESSIONAL_JOB_IN_PROGRESS_COUNT';
export const PROFESSIONAL_PROPOSAL_SENT_COUNT = 'PROFESSIONAL_PROPOSAL_SENT_COUNT';
export const PROFESSIONAL_SIDEBAR_JOB_COUNT = 'PROFESSIONAL_SIDEBAR_JOB_COUNT';
export const USER_SIDEBAR_JOB_COUNT = 'USER_SIDEBAR_JOB_COUNT';
export const GEO_STATE = 'geo_state';
export const UPDATE_USER_PHONE = 'update_user_phone';
export const UPDATE_PROFESSIONAL_PHONE = 'update_professional_phone';
export const UPDATE_PROFESSIONAL_BANK_DETAILS = 'update_professional_bank_details';
export const USER_DETAILS = 'user_details';
export const PROFESSIONAL_DETAILS = 'professional_details';
export const PROFESSIONAL_JOB_INVITES_COUNT = 'PROFESSIONAL_JOB_INVITES_COUNT';
export const SUPER_USER = 'super_user';
export const STAFF_USER = 'staff_user';
export const ACCOUNTING = 'accounting';
export const CLAIM_REFERRED_MONEY = 'claim_refereed_money';
export const UPDATE_PRACTISE_AREA = 'UPDATE_PRACTISE_AREA';
export const UPDATE_A_PROFESSIONAL_KEY = 'UPDATE_A_PROFESSIONAL_KEY'
export const AFFILIATE_PARTNER_STATS = 'affiliate_partner_stats'

