import React from "react";

export const ProgressBar = (props) => {
    const {percentage} = props
    return (
        <div className="progress_bar">
            <Filler percentage={percentage} />
        </div>
    )
};

const Filler = (props) => {
    return <div className="filler" style={{ width: `${props.percentage}%` }} />
};

// function getFieldAddressCount(address, totalInputFieldCount, completedFieldCount) {
//     for (let key in address){
//         totalInputFieldCount += 1;
//         if (address[key] !==''){
//             completedFieldCount += 1
//         }
//     }
// }

// export function basicDetailsCount(basicDetails, totalInputFieldCount, completedFieldCount ) {
//
//     totalInputFieldCount += 1;
//     if (basicDetails['email_verified'] !=='' && basicDetails['email_verified']){
//         completedFieldCount += 1
//     }
//     return { totalInputFieldCount, completedFieldCount}
// }
//
// export function bioCount(bio, totalInputFieldCount, completedFieldCount){
//
//     totalInputFieldCount += 1;
//     if (bio){
//         completedFieldCount += 1
//     }
//     return { totalInputFieldCount, completedFieldCount}
// }
//
// function consultationFeeCount(consultation_fee, totalInputFieldCount, completedFieldCount){
//
//     totalInputFieldCount += 1;
//     if (consultation_fee){
//         completedFieldCount += 1
//     }
//     return { totalInputFieldCount, completedFieldCount}
//
// }
//
//
// export function calculatePercentage(propdata, userDocuments) {
//     console.log(propdata);
//     let totalInputFieldCount = 0;
//     let completedFieldCount = 0;
//
//     totalInputFieldCount += 1;
//     if (propdata.basicDetails.email_verified){
//         completedFieldCount += 1
//     }
//
//     // let basicDetailsField = basicDetailsCount(propdata.basicDetails, totalInputFieldCount, completedFieldCount)
//     // totalInputFieldCount = basicDetailsField.totalInputFieldCount
//     // completedFieldCount = basicDetailsField.completedFieldCount
//     //for member ship id
//     // totalInputFieldCount += 1
//     // if (propdata.professional.membership_id){
//     //     completedFieldCount += 1
//     // }
//     //for company name
//     // totalInputFieldCount += 1
//     // if (propdata.professional.company_name){
//     //     completedFieldCount += 1
//     // }
//
//     //for work_experience
//     totalInputFieldCount += 1;
//     if (propdata.professional.work_experience){
//         completedFieldCount += 1
//     }
//     // for savedEducationList
//     totalInputFieldCount += 1;
//     if (propdata.professional.education.count > 0){
//         completedFieldCount += 1
//     }
//
//     totalInputFieldCount += 1;
//     if (propdata.professional.bio_detailed){
//         completedFieldCount += 1
//     }
//
//     // let bioFields = bioCount(propdata.professional.bio_detailed, totalInputFieldCount, completedFieldCount)
//     // totalInputFieldCount = bioFields.totalInputFieldCount
//     // completedFieldCount = bioFields.completedFieldCount
//
//     //for consultation_fee
//     totalInputFieldCount += 1;
//     if (propdata.professional.consultation_fee){
//         completedFieldCount += 1
//     }
//     // let consultationFields = consultationFeeCount(propdata.professional.consultation_fee, totalInputFieldCount, completedFieldCount)
//     // totalInputFieldCount = consultationFields.totalInputFieldCount
//     // completedFieldCount = consultationFields.completedFieldCount
//
//     //for gst_number
//     totalInputFieldCount += 1;
//     if (propdata.professional.gst_number){
//         completedFieldCount += 1
//     }
//
//     //for pan_number
//     // totalInputFieldCount += 1
//     // if (propdata.professional.pan_number){
//     //     completedFieldCount += 1
//     // }
//     //for practiceArea
//     // totalInputFieldCount += 1
//     // if (practiceArea.count > 0){
//     //     completedFieldCount += 1
//     // }
//     //for userDocuments
//     // totalInputFieldCount += 3
//     // for (let i=0; userDocuments.length; i++) {
//     //     completedFieldCount += 1
//     // }
//     //for userProfessions
//     // totalInputFieldCount += 1
//     // if ( propdata.professional.profession.count > 0){
//     //     completedFieldCount += 1
//     // }
//
//     return { totalInputFieldCount, completedFieldCount}
// }


class ProfessionalProfileProgressBar extends React.Component {

    render() {
        const { percentage } = this.props;
        return (
            <ProgressBar percentage={percentage} />
        )
    }
}

export default ProfessionalProfileProgressBar

