import React, {Component, Fragment} from "react";
import {apiv1} from "../../api";
import {ratingUi} from "../functional/beforeHire";
import $ from "jquery";
import {errorActionDispatchers} from "../../actions/errors";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";

class ProductReviews extends Component {
    constructor(props){
        super(props)
        this.state = {
            productReviews: [],
            limit: 6,
        }
    }

    componentDidMount() {
        this.getProductReviews()
    }

    getProductReviews = () =>{
        const {productId} = this.props
        const {limit} = this.state
        apiv1.auth.get(`job/product-testimonial/${productId}/100`)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch)
                if (data) {
                    this.setState({productReviews: data})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    productReviewsUi = ({productReviews}) => {
        const {userRating, userCount} = this.props
        return(
            <Fragment>
                <div className="panel panel-default reviews-box-ui">
                    <div className="panel-body">
                        <h2 className="scope-heading" style={{marginBottom:'10px'}}>Reviews {userRating && <span className="rating-ui-label"> <i className="fa fa-star"></i>&#x00A0;{userRating}/5&#x00A0;based on {userCount}+ Booked</span>}</h2>
                        <div className="reviews-box">
                            {/*{allServiceReviews[productId]}*/}
                            <ul className="list-unstyled reviews-list">
                                {productReviews.map((reviews, index )=>{
                                    const {rating, comment, author} = reviews || {}
                                    return(
                                        <li key={index}>
                                            <p className="text-name">{author} {ratingUi(rating)}</p>
                                            <p className="text-para-reviews">{comment}</p>
                                        </li>
                                    )
                                })}
                            </ul>
                            {productReviews.length > 10 &&
                            <div className="text-center">
                                <a className='btn btn-link'
                                   id={`see_more_package_info_hide`}
                                   onClick={() => {
                                       $('.reviews-list li:hidden').slice(0, 10).show()
                                       if ($('.reviews-list li').length === $('.reviews-list li:visible').length) {
                                           $(`#see_more_package_info_hide`).hide()
                                       }
                                   }
                                   }
                                >
                                    Click To Read More
                                </a>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    render() {
        const {productReviews,loading} = this.state
        return (
            <Fragment>
                {productReviews && productReviews.length > 0
                &&
                this.productReviewsUi({productReviews})
                }
            </Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ...(errorActionDispatchers(dispatch))
    }
};

export default connect(null, mapDispatchToProps)(withRouter(ProductReviews))
