import * as path from "./path";
import MetaTags from 'react-meta-tags';
import React from "react";
import {siteLogoOnSharedMedia} from "../components/common/bannerTextForProducts";
import {baseUrl} from "./common";

export const status = {
    Posted: 'Posted',
    Paid: 'Paid',
    Completed: 'Completed',
    Closed: 'Closed',
    InProgress: 'In Progess',
    InDispute: 'In Dispute',
    Migrated: 'Migrated',
    Dumped: 'Dumped'
}

export const consultationStatus = {
    Posted: 'Posted',
    Paid: 'Paid',
    Completed: 'Completed',
    Closed: 'Closed',
    Archive: 'Archive'
};

export const professionalStatus = {
    busy: 'busy',
    active: 'active'
}

export const jobHiringMechanism = {
    allJobs: '',
    directHired: true,
    byProposal: false,
}

export const paymentStatus = {
    paid: 'paid',
    cancelled: 'cancelled',
    expired: 'expired',
    deleted: 'deleted',
}

export const profession = {
    1: {
        text: 'Chartered Accountants',
        professional_name: 'caList',
        path: path.findCaOnline,
        profession_type: '/find-chartered-accountants',
        meta_tag:[
            <MetaTags>
                <title>Find Chartered Accountants in India for Tax & Accounting Services | Workbeam</title>
                <meta name="title"
                      content="Find Chartered Accountants in India for Tax & Accounting Services | Workbeam"/>
                <meta name="description" content="Find & hire freelance Chartered Accountants online for Tax & Accounting Services at affordable prices. Get Payment Insurance and dedicatedsupport manager."/>
                <meta name="keywords"
                      content="Chartered Accountant near me, CA near me, freelance accountant jobs, freelance accounting work, Civil Lawyers near me, Corporate Lawyer near me, Tax Attorney near me, Tax Lawyers near me, Online Lawyers India, Online legal work from home jobs, Startup Lawyers, Lawyers near me"/>
                <meta name="url" content={`${baseUrl}/chartered-accountants-near-me`}/>
                <meta property="og:url" content={`${baseUrl}/chartered-accountants-near-me`}/>
                <meta property="og:type" content="website"/>
                <meta property="og:site_name" content="Workbeam"/>
                <meta property="og:title"
                      content="Find Chartered Accountants in India for Tax & Accounting Services | Workbeam"/>
                <meta property="og:description"
                      content="Find & hire freelance Chartered Accountants online for Tax & Accounting Services at affordable prices. Get Payment Insurance and dedicatedsupport manager."/>
                <meta property="og:image" content={siteLogoOnSharedMedia}/>
                <meta property="og:image:width" content="300"/>
                <meta property="og:image:height" content="300"/>
                <meta name="twitter:card"
                      content="Find & hire freelance Chartered Accountants online for Tax & Accounting Services at affordable prices. Get Payment Insurance and dedicatedsupport manager."/>
                <meta name="twitter:site" content="Workbeam"/>
                <meta name="twitter:title"
                      content="Find Chartered Accountants in India for Tax & Accounting Services | Workbeam"/>
                <meta name="twitter:description"
                      content="Find & hire freelance Chartered Accountants online for Tax & Accounting Services at affordable prices. Get Payment Insurance and dedicatedsupport manager."/>
                <meta name="twitter:image" content={siteLogoOnSharedMedia}/>
                <link rel="canonical" href={`${baseUrl}/chartered-accountants-near-me`}/>
            </MetaTags>
        ]
    },
    2: {
        text: 'Lawyers',
        professional_name: 'lawyerList',
        path: path.findLawyerOnline,
        profession_type: '/find-lawyers',
        meta_tag:[
            <MetaTags>
                <title>Find Lawyers in India for Legal Services | Workbeam</title>
                <meta name="title"
                      content="Find Lawyers in India for Legal Services | Workbeam"/>
                <meta name="description" content="Find & hire Lawyers online for various Legal Services at affordable prices. Get Payment Insurance and dedicated legal manager."/>
                <meta name="keywords"
                      content="Chartered Accountant near me, CA near me, freelance accountant jobs, freelance accounting work, Civil Lawyers near me, Corporate Lawyer near me, Tax Attorney near me, Tax Lawyers near me, Online Lawyers India, Online legal work from home jobs, Startup Lawyers, Lawyers near me"/>
                <meta name="url" content={`${baseUrl}/find-lawyers-online`}/>
                <meta property="og:url" content={`${baseUrl}/find-lawyers-online`}/>
                <meta property="og:type" content="website"/>
                <meta property="og:site_name" content="Workbeam"/>
                <meta property="og:title"
                      content="Find Lawyers in India for Legal Services | Workbeam"/>
                <meta property="og:description"
                      content="Find & hire Lawyers online for various Legal Services at affordable prices. Get Payment Insurance and dedicated legal manager."/>
                <meta property="og:image" content={siteLogoOnSharedMedia}/>
                <meta property="og:image:width" content="300"/>
                <meta property="og:image:height" content="300"/>
                <meta name="twitter:card"
                      content="Find & hire Lawyers online for various Legal Services at affordable prices. Get Payment Insurance and dedicated legal manager."/>
                <meta name="twitter:site" content="Workbeam"/>
                <meta name="twitter:title"
                      content="Find Lawyers in India for Legal Services | gigzoe"/>
                <meta name="twitter:description"
                      content="Find & hire Lawyers online for various Legal Services at affordable prices. Get Payment Insurance and dedicated legal manager."/>
                <meta name="twitter:image" content={siteLogoOnSharedMedia}/>
                <link rel="canonical" href={`${baseUrl}/find-lawyers-online`}/>
            </MetaTags>
        ]
    },
    3: {
        text: 'Accountants & Bookkeepers',
        professional_name: 'bookKeepingList',
        path: path.findBookKeeperOnline,
        profession_type: '/find-bookkeepers',
        meta_tag:[
            <MetaTags>
                <title>Hire freelance Accoutants & Bookeepers Online | Workbeam</title>
                <meta name="title"
                      content="Hire freelance Accoutants & Bookeepers Online | Workbeam"/>
                <meta name="description" content="Find & hire freelance Accoutants & Bookeepers Online for Accounting & Bookkeeping services at affordable prices. Get Payment Insurance and dedicated support manager."/>
                <meta name="keywords"
                      content="Chartered Accountant near me, CA near me, freelance accountant jobs, freelance accounting work, Civil Lawyers near me, Corporate Lawyer near me, Tax Attorney near me, Tax Lawyers near me, Online Lawyers India, Online legal work from home jobs, Startup Lawyers, Lawyers near me"/>
                <meta name="url" content={`${baseUrl}/find-bookkeepers-online`}/>
                <meta property="og:url" content={`${baseUrl}/find-bookkeepers-online`}/>
                <meta property="og:type" content="website"/>
                <meta property="og:site_name" content="Workbeam"/>
                <meta property="og:title"
                      content="Hire freelance Accoutants & Bookeepers Online | Workbeam"/>
                <meta property="og:description"
                      content="Find & hire freelance Accoutants & Bookeepers Online for Accounting & Bookkeeping services at affordable prices. Get Payment Insurance and dedicated support manager."/>
                <meta property="og:image" content={siteLogoOnSharedMedia}/>
                <meta property="og:image:width" content="300"/>
                <meta property="og:image:height" content="300"/>
                <meta name="twitter:card"
                      content="Find & hire freelance Accoutants & Bookeepers Online for Accounting & Bookkeeping services at affordable prices. Get Payment Insurance and dedicated support manager."/>
                <meta name="twitter:site" content="Workbeam"/>
                <meta name="twitter:title"
                      content="Hire freelance Accoutants & Bookeepers Online | Workbeam"/>
                <meta name="twitter:description"
                      content="Find & hire freelance Accoutants & Bookeepers Online for Accounting & Bookkeeping services at affordable prices. Get Payment Insurance and dedicated support manager."/>
                <meta name="twitter:image" content={siteLogoOnSharedMedia}/>
                <link rel="canonical" href={`${baseUrl}/find-bookkeepers-online`}/>
            </MetaTags>
        ]
    },
    5: {
        text: 'Company Secretaries',
        professional_name: 'secretarialList',
        path: path.findCsOnline,
        profession_type: '/find-bookkeepers',
        meta_tag:[
            <MetaTags>
                <title>Find Company Secretaries in India for Compliance services| Workbeam</title>
                <meta name="title"
                      content="Find Company Secretaries in India for Compliance services| Workbeam"/>
                <meta name="description" content="Looking for a Company Secretary? Find & hire Company Secretaries online for various Compliance Services at affordable prices. Get Payment Insurance and dedicated support manager."/>
                <meta name="keywords"
                      content="Chartered Accountant near me, CA near me, freelance accountant jobs, freelance accounting work, Civil Lawyers near me, Corporate Lawyer near me, Tax Attorney near me, Tax Lawyers near me, Online Lawyers India, Online legal work from home jobs, Startup Lawyers, Lawyers near me"/>
                <meta name="url" content={`${baseUrl}/find-company-secretaries-online`}/>
                <meta property="og:url" content={`${baseUrl}/find-company-secretaries-online`}/>
                <meta property="og:type" content="website"/>
                <meta property="og:site_name" content="Workbeam"/>
                <meta property="og:title"
                      content="Find Company Secretaries in India for Compliance services| Workbeam"/>
                <meta property="og:description"
                      content="Looking for a Company Secretary? Find & hire Company Secretaries online for various Compliance Services at affordable prices. Get Payment Insurance and dedicated support manager."/>
                <meta property="og:image" content={siteLogoOnSharedMedia}/>
                <meta property="og:image:width" content="300"/>
                <meta property="og:image:height" content="300"/>
                <meta name="twitter:card"
                      content="Looking for a Company Secretary? Find & hire Company Secretaries online for various Compliance Services at affordable prices. Get Payment Insurance and dedicated support manager."/>
                <meta name="twitter:site" content="Workbeam"/>
                <meta name="twitter:title"
                      content="Find Company Secretaries in India for Compliance services| Workbeam"/>
                <meta name="twitter:description"
                      content="Looking for a Company Secretary? Find & hire Company Secretaries online for various Compliance Services at affordable prices. Get Payment Insurance and dedicated support manager."/>
                <meta name="twitter:image" content={siteLogoOnSharedMedia}/>
                <link rel="canonical" href={`${baseUrl}/find-company-secretaries-online`}/>
            </MetaTags>
        ]
    },
}

export const professionSlugMappedWithSlug = {
    'content-writer':{
        id: 10
    },
    'chartered-accountant':{
        id: 1
    }
}