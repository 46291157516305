import React from "react";
import {Fragment} from "react";
import cityDetailsWithSlug from "../../slug/cityDetailsWithSlug";
import * as path from "../../constants/path";
import {profession} from "../../constants/job";
import {siteBaseUrl} from "../../constants/common";

let baseUrl = siteBaseUrl.substring(0, siteBaseUrl.length - 1)

export function BrowseMore() {
    return (
        <Fragment>
            <section className="browse-more-padding" style={{marginTop: '40px', marginBottom: '0px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h4 className="heading-sm">Browse By Experts</h4>
                            {/*<h4 className="heading-sm text-center">Explore our network of Lawyers, Chartered Accountants, <br className="hidden-xs"/>Bookkeepers & Business Experts.</h4>*/}
                        </div>
                        <LawyersByCity/>
                        <CharteredAccountantsByCity/>
                        <div className="clearfix visible-xs" />
                        <BookkeeperAccountantsByCity/>
                        <TaxFilingByCity/>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

// export function AccountingProduct({}) {
//     return (
//         <div className="row">
//             <div className="col-sm-4 col-xs-6">
//                 <h4 className="browse-more-heading">Income Tax</h4>
//                 <ul className="list-unstyled browse-more-link">
//                     <li><a href={`${baseUrl}${path.legalAdvice}/${productSlug['8'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                         Online Tax Consultation</a></li>
//                     <li><a href={`${baseUrl}/${productSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                         Income
//                         Tax Filing for Salaried</a></li>
//                     <li><a href={`${baseUrl}/${productSlug['5'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                         ITR Filing for Professional/Freelance</a></li>
//                     <li><a href={`${baseUrl}/${productSlug['18'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                         Income
//                         Tax Filing for Capital gain Income</a></li>
//                     <li><a href={`${baseUrl}/${productSlug['41'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                         ITR Filing for Small Business</a></li>
//                     <li><a href={`${baseUrl}/${productSlug['93'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                         Income Tax Filing for Salaried - Multiple Form 16</a></li>
//                     <li><a href={`${baseUrl}/${productSlug['94'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                         Income Tax Filing for NGOs</a></li>
//                     <li><a href={`${baseUrl}/${productSlug['95'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                         Income Tax Filing for Traders</a></li>
//                 </ul>
//             </div>
//             <div className="col-sm-4 col-xs-6">
//                 <h4 className="browse-more-heading">GST Services</h4>
//                 <ul className="list-unstyled browse-more-link">
//                     <li><a href={`${baseUrl}/${productSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                         GST
//                         Registration</a></li>
//                     <li><a href={`${baseUrl}/${productSlug['35'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                         Monthly GST Return Filing - Basic</a></li>
//                     <li><a href={`${baseUrl}/${productSlug['42'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                         GST Return Filing - Standard</a></li>
//                     <li><a href={`${baseUrl}/${productSlug['43'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                         GST Return Filing - Deluxe</a></li>
//                     <li><a href={`${baseUrl}/${productSlug['102'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                         GST Filing - Premium</a></li>
//                     <li><a href={`${baseUrl}/${productSlug['103'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                         GST 3B Monthly Filing</a></li>
//                 </ul>
//             </div>
//             <div className="clearfix visible-xs"></div>
//             <div className="col-sm-4 col-xs-6">
//                 <h4 className="browse-more-heading">Accounting Services</h4>
//                 <ul className="list-unstyled browse-more-link">
//                     <li><a href={`${baseUrl}/${productSlug['6'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                         Accounting Services - 100 Transactions Yearly</a></li>
//                     <li><a href={`${baseUrl}/${productSlug['7'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                         Accounting Services - 300 Yearly Transactions</a></li>
//                     <li><a href={`${baseUrl}/${productSlug['19'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                         Accounting Services - 500 Yearly Transactions Yearly</a></li>
//                     <li><a href={`${baseUrl}/${productSlug['97'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                         Accounting Services - 1000 yearly Transactions</a></li>
//                     <li><a href={`${baseUrl}/${productSlug['99'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                         Accounting & Compliance Package - Basic</a></li>
//                     <li><a href={`${baseUrl}/${productSlug['100'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                         Accounting & Compliance package - Standard</a></li>
//                     <li><a href={`${baseUrl}/${productSlug['101'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                         Accounting & Compliance package - Premium</a></li>
//                 </ul>
//             </div>
//         </div>
//     )
// }

// export function BrowseByservice() {
//     return (
//         <section className="browse-more-padding hidden-xs"
//                  style={{marginTop: '40px', marginBottom: '0px', backgroundColor: '#fff5f5'}}>
//             <div className="container">
//                 <div className="row">
//                     <div className="col-sm-12">
//                         <h4 className="heading-sm">Browse by Services</h4>
//                     </div>
//                     <div className="clearfix"></div>
//                     <div className="col-sm-4 col-md-4 col-xs-6">
//                         <h4 className="browse-more-heading">Income Tax</h4>
//                         <ul className="list-unstyled browse-more-link">
//                             <li><a
//                                 href={`/${keyWords.consultation}/${productSlug['8'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                                 Online Tax Consultation</a></li>
//                             <li><a href={`/${productSlug['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                                 Income Tax Filing for Salaried</a></li>
//                             <li><a href={`/${productSlug['5'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                                 Income
//                                 Tax Filing for Capital gain Income</a></li>
//                             <li><a href={`/${productSlug['18'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                                 ITR Filing for Professional/Freelance</a></li>
//                             <li><a href={`/${productSlug['41'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                                 ITR Filing for Small Business</a></li>
//                             <li><a href={`/${productSlug['93'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                                 Income Tax Filing for Salaried - Multiple Form 16</a></li>
//                             <li><a href={`/${productSlug['94'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                                 Income Tax Filing for NGOs</a></li>
//                             <li><a href={`/${productSlug['95'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                                 Income Tax Filing for Traders</a></li>
//                         </ul>
//                     </div>
//                     <div className="col-sm-4 col-md-4 col-xs-6">
//                         <h4 className="browse-more-heading">GST Services</h4>
//                         <ul className="list-unstyled browse-more-link">
//                             <li><a href={`/${productSlug['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                                 GST
//                                 Registration</a></li>
//                             <li><a href={`/${productSlug['35'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                                 Monthly GST Return Filing - Basic</a></li>
//                             <li><a href={`/${productSlug['42'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                                 GST Return Filing - Standard</a></li>
//                             <li><a href={`/${productSlug['43'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                                 GST Return Filing - Deluxe</a></li>
//                             <li><a href={`/${productSlug['102'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                                 GST Filing - Premium</a></li>
//                             <li><a href={`/${productSlug['103'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                                 GST 3B Monthly Filing</a></li>
//                             <li><a href={`/${productSlug['113'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                                 GST Filing - NIL Return</a></li>
//                         </ul>
//                     </div>
//                     <div className="clearfix visible-xs"></div>
//                     <div className="col-sm-4 col-md-4 col-xs-6">
//                         <h4 className="browse-more-heading">Accounting Services</h4>
//                         <ul className="list-unstyled browse-more-link">
//                             <li><a href={`/${productSlug['6'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                                 Accounting Services - 100 Yearly Transactions</a></li>
//                             <li><a href={`/${productSlug['7'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                                 Accounting Services - 300 Yearly Transactions</a></li>
//                             <li><a href={`/${productSlug['19'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                                 Accounting Services - 500 Yearly Transactions</a></li>
//                             <li><a href={`/${productSlug['97'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                                 Accounting Services - 1000 yearly Transactions</a></li>
//                             <li><a href={`/${productSlug['99'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                                 Accounting & Compliance Package - Basic</a></li>
//                             <li><a href={`/${productSlug['100'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                                 Accounting & Compliance package - Standard</a></li>
//                             <li><a href={`/${productSlug['101'].slug}/${parentPracticeAreaSlug['1'].slug}`}>
//                                 Accounting & Compliance package - Premium</a></li>
//                         </ul>
//                     </div>
//                     <div className="clearfix hidden-xs"></div>
//                     <div className="col-sm-4 col-md-4 col-xs-6">
//                         <h4 className="browse-more-heading">Business Formation</h4>
//                         <ul className="list-unstyled browse-more-link">
//                             <li><a href={`/${productSlug['9'].slug}/${parentPracticeAreaSlug['5'].slug}`}>
//                                 Pvt.
//                                 Ltd. Company Registration</a></li>
//                             <li><a href={`/${productSlug['10'].slug}/${parentPracticeAreaSlug['5'].slug}`}>
//                                 LLP
//                                 Registration</a></li>
//                             <li><a href={`/${productSlug['107'].slug}/${parentPracticeAreaSlug['5'].slug}`}>
//                                 Partnership Firm Registration - Basic</a></li>
//                             <li><a href={`/${productSlug['11'].slug}/${parentPracticeAreaSlug['5'].slug}`}>
//                                 Partnership Firm Registration - Premium</a></li>
//                             <li><a href={`/${productSlug['12'].slug}/${parentPracticeAreaSlug['5'].slug}`}>
//                                 One
//                                 Person Company Registration</a></li>
//                             <li><a href={`/${productSlug['13'].slug}/${parentPracticeAreaSlug['5'].slug}`}>
//                                 Sole
//                                 Proprietorship Registration</a></li>
//                             <li><a href={`/${productSlug['14'].slug}/${parentPracticeAreaSlug['5'].slug}`}>
//                                 NGO/Section
//                                 8 Company Registration</a></li>
//                             <li><a href={`/${productSlug['15'].slug}/${parentPracticeAreaSlug['5'].slug}`}>
//                                 Company
//                                 Name Strike Off/Name Removal</a></li>
//                             <li><a href={`/${productSlug['57'].slug}/${parentPracticeAreaSlug['5'].slug}`}>
//                                 Reserve Company Name</a></li>
//                         </ul>
//                     </div>
//                     <div className="clearfix visible-xs"></div>
//                     <div className="col-sm-4 col-md-4 col-xs-6">
//                         <h4 className="browse-more-heading">Fundraising and Esop
//                             Compliance</h4>
//                         <ul className="list-unstyled browse-more-link">
//                             <li><a href={`/${productSlug['29'].slug}/${parentPracticeAreaSlug['7'].slug}`}>
//                                 Shareholding
//                                 Agreement</a></li>
//                             <li><a href={`/${productSlug['31'].slug}/${parentPracticeAreaSlug['7'].slug}`}>
//                                 Founder's
//                                 Agreement</a></li>
//                             <li><a href={`/${productSlug['30'].slug}/${parentPracticeAreaSlug['7'].slug}`}>
//                                 TermSheet
//                                 - Equity/Convertible Debt</a></li>
//                             <li><a href={`/${productSlug['33'].slug}/${parentPracticeAreaSlug['7'].slug}`}>
//                                 Employee
//                                 Stock Option Policy</a></li>
//                             <li><a href={`/${productSlug['32'].slug}/${parentPracticeAreaSlug['7'].slug}`}>
//                                 Debt
//                                 Financing/Loan Agreement</a></li>
//                             <li><a href={`/${productSlug['34'].slug}/${parentPracticeAreaSlug['7'].slug}`}>
//                                 Employment
//                                 Agreement</a></li>
//                         </ul>
//                     </div>
//                     <div className="col-sm-4 col-md-4 col-xs-6">
//                         <h4 className="browse-more-heading">Contracts & Agreements</h4>
//                         <ul className="list-unstyled browse-more-link">
//                             <li><a href={`/${productSlug['27'].slug}/${parentPracticeAreaSlug['6'].slug}`}>
//                                 Sale
//                                 Deed</a></li>
//                             <li><a href={`/${productSlug['36'].slug}/${parentPracticeAreaSlug['6'].slug}`}>
//                                 Rent Agreement</a></li>
//                             <li><a href={`/${productSlug['23'].slug}/${parentPracticeAreaSlug['6'].slug}`}>
//                                 Licensing
//                                 Agreement</a></li>
//                             <li><a href={`/${productSlug['25'].slug}/${parentPracticeAreaSlug['6'].slug}`}>
//                                 Franchisee
//                                 Agreement</a></li>
//                             <li><a href={`/${productSlug['28'].slug}/${parentPracticeAreaSlug['6'].slug}`}>
//                                 Vendor
//                                 Contract Agreement</a></li>
//                             <li><a href={`/${productSlug['24'].slug}/${parentPracticeAreaSlug['6'].slug}`}>
//                                 Web
//                                 Agreements - Terms of Use & Privacy Policy</a></li>
//                             <li><a href={`/${productSlug['26'].slug}/${parentPracticeAreaSlug['6'].slug}`}>
//                                 Intellectual
//                                 property Assignment Contract</a></li>
//                             <li><a href={`/${productSlug['37'].slug}/${parentPracticeAreaSlug['6'].slug}`}>
//                                 Non Disclosure Agreement</a></li>
//                         </ul>
//                     </div>
//                     <div className="clearfix"></div>
//                     <div className="col-sm-4 col-md-4 col-xs-6">
//                         <h4 className="browse-more-heading">Legal</h4>
//                         <ul className="list-unstyled browse-more-link">
//                             <li><a href={path.legalAdvice}>
//                                 Legal Advice Online</a></li>
//                             <li><a href={`/${productSlug['49'].slug}/${parentPracticeAreaSlug['2'].slug}`}>
//                                 Consumer Protection Legal Notice</a></li>
//                             <li><a href={`/${productSlug['50'].slug}/${parentPracticeAreaSlug['2'].slug}`}>
//                                 Mutual Consent Divorce</a></li>
//                             <li><a href={`/${productSlug['51'].slug}/${parentPracticeAreaSlug['2'].slug}`}>
//                                 RERA Legal Notice Delayed Possession</a></li>
//                             <li><a href={`/${productSlug['52'].slug}/${parentPracticeAreaSlug['2'].slug}`}>
//                                 RERA Legal Notice Amount Refund</a></li>
//                         </ul>
//                         <h4 className="browse-more-heading">Trademarks & Copyrights</h4>
//                         <ul className="list-unstyled browse-more-link">
//                             <li><a href={`/${productSlug['21'].slug}/${parentPracticeAreaSlug['4'].slug}`}>
//                                 Trademark Registration for Companies - Basic</a></li>
//                             <li><a href={`/${productSlug['22'].slug}/${parentPracticeAreaSlug['4'].slug}`}>
//                                 Trademark Registration for Small Business - Basic</a></li>
//                         </ul>
//                     </div>
//                     <div className="col-sm-4 col-md-4 col-xs-6">
//                         <h4 className="browse-more-heading">Secretarial Compliance</h4>
//                         <ul className="list-unstyled browse-more-link">
//                             <li><a href={`/${productSlug['16'].slug}/${parentPracticeAreaSlug['3'].slug}`}>
//                                 Annual
//                                 ROC Filing</a></li>
//                             <li><a href={`/${productSlug['17'].slug}/${parentPracticeAreaSlug['3'].slug}`}>
//                                 Annual
//                                 ROC Compliance</a></li>
//                             <li><a href={`/${productSlug['53'].slug}/${parentPracticeAreaSlug['3'].slug}`}>
//                                 DSC Class 2</a></li>
//                             <li><a href={`/${productSlug['54'].slug}/${parentPracticeAreaSlug['3'].slug}`}>
//                                 DSC Class 3</a></li>
//                             <li><a href={`/${productSlug['56'].slug}/${parentPracticeAreaSlug['3'].slug}`}>
//                                 DIR 3 KYC</a></li>
//                             <li><a href={`/${productSlug['55'].slug}/${parentPracticeAreaSlug['3'].slug}`}>
//                                 DSC for Foreign citizens and NRI</a></li>
//                         </ul>
//                     </div>
//                     <div className="clearfix visible-xs"></div>
//                     <div className="col-sm-4 col-md-4 col-xs-6">
//                         <h4 className="browse-more-heading">Licenses</h4>
//                         <ul className="list-unstyled browse-more-link">
//                             <li><a href={`/${productSlug['60'].slug}/${parentPracticeAreaSlug['106'].slug}`}>
//                                 FSSAI License - Basic</a></li>
//                             <li><a href={`/${productSlug['61'].slug}/${parentPracticeAreaSlug['106'].slug}`}>
//                                 FSSAI License - State</a></li>
//                             <li><a href={`/${productSlug['62'].slug}/${parentPracticeAreaSlug['106'].slug}`}>
//                                 FSSAI License - Central</a></li>
//                             <li><a href={`/${productSlug['58'].slug}/${parentPracticeAreaSlug['106'].slug}`}>
//                                 Import Export Code with DSC</a></li>
//                             <li><a href={`/${productSlug['59'].slug}/${parentPracticeAreaSlug['106'].slug}`}>
//                                 Import Export Code without DSC</a></li>
//                             <li><a href={`/${productSlug['63'].slug}/${parentPracticeAreaSlug['106'].slug}`}>
//                                 Professional Tax Registration</a></li>
//                             <li><a href={`/${productSlug['64'].slug}/${parentPracticeAreaSlug['106'].slug}`}>
//                                 MSME Registration</a></li>
//                             <li><a href={`/${productSlug['65'].slug}/${parentPracticeAreaSlug['106'].slug}`}>
//                                 Shop & Establishment Act License</a></li>
//                         </ul>
//                     </div>
//                 </div>
//             </div>
//         </section>
//     )
// }

export function LawyersByCity() {
    return (
        <div className="col-sm-4 col-md-3 col-xs-6">
            <h4 className="browse-more-heading">Find Lawyers</h4>
            <ul className="list-unstyled browse-more-link">
                {Object.keys(cityDetailsWithSlug).map(city => {
                    const {name, slug} = cityDetailsWithSlug[city]
                    return <li><a href={`${baseUrl}${profession["2"].profession_type}/${slug}`}>Lawyers in {name}</a>
                    </li>
                })}
                {/*<li><a href={`${baseUrl}${profession["2"].profession_type}/${cityDetailsWithSlug["new-delhi"].slug}`}>*/}
                {/*Lawyers in Delhi</a></li>*/}
                {/*<li><a href={`${profession["2"].profession_type}/${cityDetailsWithSlug["bengaluru"].slug}`}>*/}
                {/*Lawyers in Bengaluru</a></li>*/}
                {/*<li><a href={`${profession["2"].profession_type}/${cityDetailsWithSlug["ahmedabad"].slug}`}>*/}
                {/*Lawyers in Ahmedabad</a></li>*/}
                {/*<li><a href={`${profession["2"].profession_type}/${cityDetailsWithSlug["chennai"].slug}`}>*/}
                {/*Lawyers in Chennai</a></li>*/}
                {/*<li><a href={`${profession["2"].profession_type}/${cityDetailsWithSlug["mumbai"].slug}`}>*/}
                {/*Lawyers in Mumbai</a></li>*/}
                {/*<li><a href={`${profession["2"].profession_type}/${cityDetailsWithSlug["hyderabad"].slug}`}>*/}
                {/*Lawyers in Hyderabad</a></li>*/}
                {/*<li><a href={`${profession["2"].profession_type}/${cityDetailsWithSlug["kolkata"].slug}`}>*/}
                {/*Lawyers in Kolkata</a></li>*/}
                <li><a className="btn btn-link" href={`${path.findLawyerOnline}`}>View All>></a></li>
            </ul>
        </div>
    )
}

export function CharteredAccountantsByCity() {
    return (
        <div className="col-sm-4 col-md-3 col-xs-6">
            <h4 className="browse-more-heading">Find CAs &amp; Tax Experts</h4>
            <ul className="list-unstyled browse-more-link">
                {Object.keys(cityDetailsWithSlug).map(city => {
                    const {name, slug} = cityDetailsWithSlug[city]
                    return <li><a href={`${baseUrl}${profession["1"].profession_type}/${slug}`}>CA in {name}</a></li>
                })}

                {/*<li><a href={`${profession["1"].profession_type}/${cityDetailsWithSlug["new-delhi"].slug}`}>*/}
                {/*CA in Delhi</a></li>*/}
                {/*<li><a href={`${profession["1"].profession_type}/${cityDetailsWithSlug["bengaluru"].slug}`}>*/}
                {/*CA in Bengaluru</a></li>*/}
                {/*<li><a href={`${profession["1"].profession_type}/${cityDetailsWithSlug["chennai"].slug}`}>*/}
                {/*CA in Chennai</a></li>*/}
                {/*<li><a href={`${profession["1"].profession_type}/${cityDetailsWithSlug["mumbai"].slug}`}>*/}
                {/*CA in Mumbai</a></li>*/}
                {/*<li><a href={`${profession["1"].profession_type}/${cityDetailsWithSlug["hyderabad"].slug}`}>*/}
                {/*CA in Hyderabad</a></li>*/}
                {/*<li><a href={`${profession["1"].profession_type}/${cityDetailsWithSlug["kolkata"].slug}`}>*/}
                {/*CA in Kolkata</a></li>*/}
                <li><a className="btn btn-link" href={`${path.findCaOnline}`}>
                    View All>></a></li>
            </ul>
        </div>
    )
}

export function BookkeeperAccountantsByCity() {
    return (
        <div className="col-sm-4 col-md-3 col-xs-6">
            <h4 className="browse-more-heading">Bookkeepers &amp; Accountants online</h4>
            <ul className="list-unstyled browse-more-link">
                {Object.keys(cityDetailsWithSlug).map(city => {
                    const {name, slug} = cityDetailsWithSlug[city]
                    return <li><a href={`${baseUrl}${profession["1"].profession_type}/${slug}`}>Accountants
                        in {name}</a></li>
                })}
                {/*<li><a href={`${profession["3"].profession_type}/${cityDetailsWithSlug["new-delhi"].slug}`}>*/}
                {/*Accountants in Delhi</a></li>*/}
                {/*<li><a href={`${profession["3"].profession_type}/${cityDetailsWithSlug["bengaluru"].slug}`}>*/}
                {/*Accountants in Bengaluru</a></li>*/}
                {/*<li><a href={`${profession["3"].profession_type}/${cityDetailsWithSlug["ahmedabad"].slug}`}>*/}
                {/*Accountants in Ahmedabad</a></li>*/}
                {/*<li><a href={`${profession["3"].profession_type}/${cityDetailsWithSlug["chennai"].slug}`}>*/}
                {/*Accountants in Chennai</a></li>*/}
                {/*<li><a href={`${profession["3"].profession_type}/${cityDetailsWithSlug["mumbai"].slug}`}>*/}
                {/*Accountants in Mumbai</a></li>*/}
                {/*<li><a href={`${profession["3"].profession_type}/${cityDetailsWithSlug["hyderabad"].slug}`}>*/}
                {/*Accountants in Hyderabad</a></li>*/}
                {/*<li><a href={`${profession["3"].profession_type}/${cityDetailsWithSlug["kolkata"].slug}`}>*/}
                {/*Accountants in Kolkata</a></li>*/}
                <li><a className="btn btn-link" href={`${path.findBookKeeperOnline}`}>
                    View All>></a></li>
            </ul>
        </div>
    )
}

export function TaxFilingByCity() {
    return (
        <div className="col-sm-4 col-md-3 col-xs-6">
            <h4 className="browse-more-heading">Tax filing services near you</h4>
            <ul className="list-unstyled browse-more-link">
                {Object.keys(cityDetailsWithSlug).map(city => {
                    const {name} = cityDetailsWithSlug[city]
                    return <li><a href={baseUrl + path.fileItr}>Tax filing in {name}</a></li>
                })}

                {/*<li><a href={path.fileItr}>Tax filing in Delhi</a></li>*/}
                {/*<li><a href={path.fileItr}>*/}
                {/*Tax filing in Bengaluru</a></li>*/}
                {/*<li><a href={path.fileItr}>*/}
                {/*Tax filing in Ahmedabad</a></li>*/}
                {/*<li><a href={path.fileItr}>*/}
                {/*Tax filing in Chennai</a></li>*/}
                {/*<li><a href={path.fileItr}>*/}
                {/*Tax filing in Mumbai</a></li>*/}
                {/*<li><a href={path.fileItr}>*/}
                {/*Tax filing in Hyderabad</a></li>*/}
                {/*<li><a href={path.fileItr}>*/}
                {/*Tax filing in Kolkata</a></li>*/}
                {/*<li> <a className="btn btn-link" href={`${path.findBookKeeperOnline}`}>*/}
                {/*    View All>></a></li>*/}
            </ul>
        </div>
    )
}