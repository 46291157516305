import { useEffect, useState } from "react";
import "./ResumeTemplate.css";
import { Box, Modal, Tooltip, Typography } from "@material-ui/core";
import ExperienceModal from "./ExperienceModal";
import moment from "moment";

export const ResumeTemplate = ({ data }) => {
  const [profile, setProfile] = useState(null);
  const [selectedExperience, setSelectedExperience] = useState();
  const [editMap, setEditMap] = useState({
    name: false,
    title: false,
    profile: false,
    about: false,
    skills: false,
  });

  const [modalData, setModalData] = useState({ show: false, key: null });

  useEffect(() => {
    console.log(":: profile", data);
    setProfile(data);
  }, [data]);
  // Additional state variables as necessary for editing other parts of the form

  const renderEditNameSection = () => (
    <div className="row">
      <div className="col-md-5">
        <input
          id="newfname"
          type="text"
          className="mb-3 form-control curved"
          value={data.first_name}
          onChange={(e) =>
            setProfile({ ...profile, first_name: e.target.value })
          }
        />
      </div>
      <div className="col-md-5">
        <input
          id="newLname"
          type="text"
          className="mb-3 form-control curved"
          value={profile.last_name}
          onChange={(e) =>
            setProfile({ ...profile, last_name: e.target.value })
          }
        />
      </div>
      <div className="col-auto">
        <i
          className="fa fa-check edit-icon right-bar mt-3"
          aria-hidden="true"
          onClick={() => setEditMap({ ...editMap, name: !editMap.name })}
        ></i>
      </div>
    </div>
  );

  const handleSkillChange = (value, index) => {
    const newSkills = [...profile.skills];
    newSkills[index] = value;
    setProfile({ ...profile, skills: newSkills });
  };

  const handleRemoveSkill = (index) => {
    const newSkills = [...profile.skills];
    newSkills.splice(index, 1);
    setProfile({ ...profile, skills: newSkills });
  };

  const handleCertChange = (value, index) => {
    const newCertifications = [...profile.certifications];
    newCertifications[index] = value;
    setProfile({ ...profile, certifications: newCertifications });
  };

  const handleRemoveCert = (index) => {
    const newCertifications = [...profile.certifications];
    newCertifications.splice(index, 1);
    setProfile({ ...profile, certifications: newCertifications });
  };

  const handleLanguageChange = (value, index) => {
    const newLang = [...profile.languages];
    newLang[index] = value;
    setProfile({ ...profile, languages: newLang });
  };

  const handleRemoveLanguage = (index) => {
    const newLang = [...profile.languages];
    newLang.splice(index, 1);
    setProfile({ ...profile, languages: newLang });
  };

  const aboutSection = () => {
    return (
      <div className="row mb-3 about-section">
        {!editMap.about ? (
          <div className=" d-flex col-10 pb-2">
            <div className="cv-about col-md-11">{profile?.about}</div>
            <i
              className="fa fa-pencil-square-o edit-icon right-bar"
              aria-hidden="true"
              onClick={() => {
                setEditMap({ ...editMap, about: !editMap.about });
              }}
            ></i>
          </div>
        ) : (
          <div className=" d-flex col-10 w-100 pb-2">
            <div className="form-group col-md-11">
              <textarea
                className="form-control flex"
                id="aboutTextarea"
                rows="5"
                value={profile?.about}
                onChange={(e) => {
                  setProfile({ ...profile, about: e.target.value });
                }}
              ></textarea>
            </div>
            <i
              className="fa fa-check edit-icon right-bar"
              aria-hidden="true"
              onClick={() => {
                setEditMap({ ...editMap, about: !editMap.about });
              }}
            ></i>
          </div>
        )}
      </div>
    );
  };

  const contactsSection = ({ contact, vanity_name, updateContacts }) => {
    return (
      <div className="row pb-4 align-items-center">
        <div className="col-md-10">
          <div className="row">
            {contact && contact.email && contact.email.length > 0 ? (
              <div className="w-100">
                <span className="contact2">
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                  <a href={`mailto:${contact.email[0]}`}>{contact.email[0]}</a>
                </span>
              </div>
            ) : (
              <div className="col-auto">
                <span className="contact2">
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                  (Not Indicated)
                </span>
              </div>
            )}

            <div className="col-auto">
              <span className="contact2">
                <i className="fa fa-linkedin" aria-hidden="true"></i>
                <a
                  href={`https://www.linkedin.com/in/${vanity_name}/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {vanity_name}
                </a>
              </span>
            </div>

            <div className="col-auto">
              <span className="contact2">
                <i className="fa fa-twitter" aria-hidden="true"></i>{" "}
                {contact?.twitter}
              </span>
            </div>

            <div className="col-auto">
              <span className="contact2">
                <i className="fa fa-phone" aria-hidden="true"></i>
                {contact && contact.phone && contact.phone.length > 0
                  ? contact?.phone[0]
                  : "(Not Indicated)"}
              </span>
            </div>
          </div>
        </div>

        <div className="col-auto">
          <i
            className="fa fa-pencil-square-o edit-icon-nofloat2"
            aria-hidden="true"
            onClick={updateContacts}
          ></i>
        </div>
      </div>
    );
  };

  const renderPublications = () => {
    if (!profile?.visibility?.accomplishments?.publications) {
      return <div>(None Specified)</div>;
    }

    return (
      <ul className="course-list">
        {profile.accomplishments.publications.map((pub, index) => (
          <li key={index}>
            {pub.name}
            <Tooltip title="Remove">
              <i
                className="fa fa-times edit-icon-nofloat2 pl"
                aria-hidden="true"
                onClick={() => {}}
              ></i>
            </Tooltip>
            <Tooltip title="Edit">
              <i
                className="fa fa-pencil-square-o edit-icon-nofloat2"
                aria-hidden="true"
                onClick={() => {}}
              ></i>
            </Tooltip>
          </li>
        ))}
      </ul>
    );
  };

  const expSection = () => {
    return (
      <div className="row mt-4 resume-data">
        <div className="col-md-9">
          {profile?.experience && profile?.visibility?.experience === true && (
            <section className="cv-section">
              {/* Experience Section */}
              <div className="section-header">
                <h2>Experience</h2>
                <Tooltip title="Add Experience">
                  <i
                    className="fa fa-plus"
                    onClick={() =>
                      setModalData({ show: true, key: "add_experience" })
                    }
                  ></i>
                </Tooltip>
              </div>
              {profile?.experience && profile?.experience.length > 0 ? (
                profile?.experience.map((exp, index) => (
                  <div key={index} className="experience-item">
                    <div className="experience-details">
                      <div style={{display: 'flex'}}><h3>{exp.title}</h3>
                      <div className="experience-actions">
                      <Tooltip
                        title="Edit"
                        onClick={() => {
                          setSelectedExperience({ data: exp, index: index });
                          setModalData({
                            show: true,
                            key: "update_experience",
                          });
                        }}
                      >
                        <i className="fa fa-pencil-square-o edit-icon-nofloat2"></i>
                      </Tooltip>
                      <Tooltip
                        title="Remove"
                        onClick={() => {
                          handleDeleteExp(index);
                        }}
                      >
                        <i className="fa fa-remove"></i>
                      </Tooltip>
                    </div>
                    </div>
                      <p>
                        {exp.company} - {exp.location}
                      </p>
                      <span>
                        {moment(exp.starts_at).format("DD/MM/yyyy") || ""} -{" "}
                        {exp.ends_at
                          ? new Date(exp.ends_at)?.toString() || "Present"
                          : "Present"}
                      </span>
                      <p>{exp.description}</p>
                    </div>
                   
                  </div>
                ))
              ) : (
                <p>No experience added yet.</p>
              )}
            </section>
          )}

          {/* new cert Section */}
          {profile?.certifications && profile?.visibility?.certifications === true && (
            <section className="cv-sidebar-section">
              {profile?.visibility?.certifications && profile?.certifications ? (
                <section className="cv-sidebar-section">
                  <div className="d-flex align-items-center">
                    <div className="col-md">
                      <h2 className="cv-section__title">Certifications</h2>
                    </div>
                    <div className="col-md-6">
                      {!editMap.certifications ? (
                        <i
                          className="fa fa-pencil-square-o edit-icon right-bar"
                          aria-hidden="true"
                          onClick={() => {
                            setEditMap({ ...editMap, certifications: !editMap.certifications });
                          }}
                          title="Edit certifications"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-check edit-icon right-bar"
                          aria-hidden="true"
                          onClick={() => {
                            setEditMap({ ...editMap, certifications: !editMap.certifications });
                          }}
                          title="Update certifications"
                        ></i>
                      )}
                    </div>
                  </div>

                  {!editMap.certifications ? (
                    <ul className="">
                      {profile?.certifications.length > 0 ? (
                        profile?.certifications.slice(0, 7).map((cert, index) => (
                          <li key={index} className="list-inline-item">
                            {cert}
                          </li>
                        ))
                      ) : (
                        <div>(Not Indicated)</div>
                      )}
                    </ul>
                  ) : (
                    <div className="row">
                      <div className="col-md-12">
                        {profile?.certifications.map((skill, index) => (
                          <div key={index} className="d-flex p-2">
                            <input
                              className="form-control mb-3 exp-array"
                              value={skill}
                              onChange={(e) => {
                                handleCertChange(e.target.value, index);
                              }}
                              placeholder="Add certifications"
                            />
                            <i
                              className="fa fa-times"
                              aria-hidden="true"
                              onClick={() => {
                                handleRemoveCert(index);
                              }}
                              title="Remove certifications"
                            ></i>
                          </div>
                        ))}
                        <Tooltip
                          title="Add certifications"
                          onClick={() => {
                            setProfile({
                              ...profile,
                              certifications: [...profile.certifications, ""],
                            });
                          }}
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i>
                        </Tooltip>
                      </div>
                    </div>
                  )}

                  {/* {enableGPT && !isEditSkills && (
                    <div className="row">
                      <div className="col-md-12">
                        <button type="button" className="btn btn-info mb-1" onClick={() => showRevisedText('skills')}>
                          Revise with GPT
                        </button>
                      </div>
                    </div>
                  )} */}
                </section>
              ) : null}
            </section>
          )}

          {/* Publications Section */}
          {profile?.visibility &&
            profile?.visibility?.accomplishments?.publications === true && (
              <section
                className={`cv-section ${
                  profile.visibility &&
                  profile?.visibility?.accomplishments?.publications
                    ? ""
                    : "d-none"
                }`}
              >
                <div className="d-flex align-items-center">
                  <div className="col-auto">
                    <h2 className="cv-section__title">Publications</h2>
                  </div>
                  <div className="col">
                    <Tooltip title="Add Publications">
                      <i
                        className="fa fa-plus edit-icon-nofloat"
                        aria-hidden="true"
                        onClick={() => {}}
                      ></i>
                    </Tooltip>
                  </div>
                </div>

                <div className="cv-section__wrapper">
                  {renderPublications()}
                </div>
              </section>
            )}

          {/* Additional sections */}
        </div>
        <aside className="col-md-3 cv-sidebar">
          {/* Skills Section */}
          {profile?.skills && profile?.visibility?.skills === true && (
            <section className="cv-sidebar-section">
              {profile?.visibility?.skills && profile?.skills ? (
                <section className="cv-sidebar-section">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <h2 className="cv-section__title">Skills</h2>
                    </div>
                    <div className="col-md-6">
                      {!editMap.skills ? (
                        <i
                          className="fa fa-pencil-square-o edit-icon right-bar"
                          aria-hidden="true"
                          onClick={() => {
                            setEditMap({ ...editMap, skills: !editMap.skills });
                          }}
                          title="Edit Skills"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-check edit-icon right-bar"
                          aria-hidden="true"
                          onClick={() => {
                            setEditMap({ ...editMap, skills: !editMap.skills });
                          }}
                          title="Update Skills"
                        ></i>
                      )}
                    </div>
                  </div>

                  {!editMap.skills ? (
                    <ul className="list-inline">
                      {profile?.skills.length > 0 ? (
                        profile?.skills.slice(0, 7).map((skill, index) => (
                          <li key={index} className="list-inline-item">
                            {skill}
                          </li>
                        ))
                      ) : (
                        <div>(Not Indicated)</div>
                      )}
                    </ul>
                  ) : (
                    <div className="row">
                      <div className="col-md-12">
                        {profile?.skills.map((skill, index) => (
                          <div key={index} className="d-flex p-2">
                            <input
                              className="form-control mb-3 exp-array"
                              value={skill}
                              onChange={(e) => {
                                handleSkillChange(e.target.value, index);
                              }}
                              placeholder="Add skills"
                            />
                            <i
                              className="fa fa-times"
                              aria-hidden="true"
                              onClick={() => {
                                handleRemoveSkill(index);
                              }}
                              title="Remove Skill"
                            ></i>
                          </div>
                        ))}
                        <Tooltip
                          title="Add Skills"
                          onClick={() => {
                            setProfile({
                              ...profile,
                              skills: [...profile.skills, ""],
                            });
                          }}
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i>
                        </Tooltip>
                      </div>
                    </div>
                  )}

                  {/* {enableGPT && !isEditSkills && (
                    <div className="row">
                      <div className="col-md-12">
                        <button type="button" className="btn btn-info mb-1" onClick={() => showRevisedText('skills')}>
                          Revise with GPT
                        </button>
                      </div>
                    </div>
                  )} */}
                </section>
              ) : null}
            </section>
          )}

           {/* languages section */}
           {profile?.languages && profile?.visibility?.languages === true && (
            <section className="cv-sidebar-section">
              {profile?.visibility?.languages && profile?.languages ? (
                <section className="cv-sidebar-section">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <h2 className="cv-section__title">Languages</h2>
                    </div>
                    <div className="col-md-6">
                      {!editMap.languages ? (
                        <i
                          className="fa fa-pencil-square-o edit-icon right-bar"
                          aria-hidden="true"
                          onClick={() => {
                            setEditMap({ ...editMap, languages: !editMap.languages });
                          }}
                          title="Edit languages"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-check edit-icon right-bar"
                          aria-hidden="true"
                          onClick={() => {
                            setEditMap({
                              ...editMap,
                              languages: !editMap.languages,
                            });
                          }}
                          title="Update Languages"
                        ></i>
                      )}
                    </div>
                  </div>

                  {!editMap.languages ? (
                    <ul className="list-inline">
                      {profile?.languages.length > 0 ? (
                        profile?.languages.slice(0, 7).map((language, index) => (
                          <li key={index} className="list-inline-item">
                            {language}
                          </li>
                        ))
                      ) : (
                        <div>(Not Indicated)</div>
                      )}
                    </ul>
                  ) : (
                    <div className="row">
                      <div className="col-md-12">
                        {profile?.languages.map((skill, index) => (
                          <div key={index} className="d-flex p-2">
                            <input
                              className="form-control mb-3 exp-array"
                              value={skill}
                              onChange={(e) => {
                                handleLanguageChange(e.target.value, index);
                              }}
                              placeholder="Add languages"
                            />
                            <i
                              className="fa fa-times"
                              aria-hidden="true"
                              onClick={() => {
                                handleRemoveLanguage(index);
                              }}
                              title="Remove language"
                            ></i>
                          </div>
                        ))}
                        <Tooltip
                          title="Add languages"
                          onClick={() => {
                            setProfile({
                              ...profile,
                              languages: [...profile.languages, ""],
                            });
                          }}
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i>
                        </Tooltip>
                      </div>
                    </div>
                  )}

                  {/* {enableGPT && !isEditSkills && (
                    <div className="row">
                      <div className="col-md-12">
                        <button type="button" className="btn btn-info mb-1" onClick={() => showRevisedText('skills')}>
                          Revise with GPT
                        </button>
                      </div>
                    </div>
                  )} */}
                </section>
              ) : null}
            </section>
          )}

          {/* Education Section */}
          {profile?.education && profile?.visibility?.education === true && (
            <section className="cv-sidebar-section">
              {profile?.visibility?.education && profile?.education ? (
                <section className="cv-sidebar-section">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <h2 className="cv-section__title">Education</h2>
                    </div>
                    <div className="col-md-6">
                      {
                       <Tooltip title="Add Education">
                       <i
                         className="fa fa-plus"
                         onClick={() =>
                           setModalData({ show: true, key: "add_experience" })
                         }
                       ></i>
                     </Tooltip>
                      }
                    </div>
                  </div>

                 
                    <ul className="list-inline">
                      {profile?.education.length > 0 ? (
                        profile?.education.slice(0, 7).map((educationValue, index) => (
                          <div className="experience-details">
                          <div style={{display: 'flex'}}><h3>{educationValue.degree_name}</h3>
                          <div className="education-actions">
                          <Tooltip
                            title="Edit"
                            onClick={() => {
                              setSelectedExperience({ data: educationValue, index: index });
                              setModalData({
                                show: true,
                                key: "update_experience",
                              });
                            }}
                          >
                            <i className="fa fa-pencil-square-o edit-icon-nofloat2"></i>
                          </Tooltip>
                          <Tooltip
                            title="Remove"
                            onClick={() => {
                             
                            }}
                          >
                            <i className="fa fa-remove"></i>
                          </Tooltip>
                        </div>
                        </div>
                          {/* <p>
                            {educationValue.company} - {exp.location}
                          </p> */}
                          <span>
                            {moment(educationValue.starts_at).format("DD/MM/yyyy") || ""} -{" "}
                            {educationValue.ends_at
                              ? new Date(educationValue.ends_at)?.toString() || "Present"
                              : "Present"}
                          </span>
                          <p>{educationValue.description}</p>
                        </div>
                        ))
                      ) : (
                        <div>(Not Indicated)</div>
                      )}
                    </ul>
                   
                  {/* {enableGPT && !isEditSkills && (
                    <div className="row">
                      <div className="col-md-12">
                        <button type="button" className="btn btn-info mb-1" onClick={() => showRevisedText('skills')}>
                          Revise with GPT
                        </button>
                      </div>
                    </div>
                  )} */}
                </section>
              ) : null}
            </section>
          )}

          {/* Courses Section */}
          {profile?.accomplishments?.courses && profile?.visibility?.accomplishments?.courses === true && (
            <section className="cv-sidebar-section">
              {/* Courses content */}
              {profile?.visibility?.accomplishments?.courses === true && (
                <div>
                 <div className="d-flex">
                 <h2 className="cv-section__title">Courses</h2>
                  <div className="add-project-icon">
                    <Tooltip title="Add course">
                      <i
                        className="fa fa-plus edit-icon-nofloat"
                        aria-hidden="true"
                        onClick={() => {setModalData({show: true, key: 'add_course'})}}
                      ></i>
                    </Tooltip>
                  </div>
                 </div>
                  {profile?.accomplishments?.courses.map((course, index) => (
                    <div className="cv-section__wrapper" key={index}>
                      <div className="cv-section__experience">
                        <p className="cv-section__role">
                          {course.name}
                          <i
                            className="fa fa-pencil-square-o edit-icon-nofloat2 pl"
                            aria-hidden="true"
                            onClick={() => {
                              setModalData({
                                show: true,
                                key: "update_course",
                              });
                            }}
                            title="Edit course"
                          ></i>
                          <i
                            className="fa fa-times edit-icon-nofloat2 pl"
                            aria-hidden="true"
                            onClick={() => {}}
                            title="Remove course"
                          ></i>
                        </p>
                        <p className="cv-section__description">
                          {course.number}
                        </p>
                      </div>
                    </div>
                  ))}
                 
                </div>
              )}
            </section>
          )}

          {/* Projects Section */}
          {profile?.projects && profile.projects.length > 0 && (
            <section className="cv-sidebar-section">
              {/* Projects content */}
              {profile?.visibility?.projects === true && (
                <div>
                 <div className="d-flex">
                 <h2 className="cv-section__title">Projects</h2>
                  <div className="add-project-icon">
                    <Tooltip title="Add Project">
                      <i
                        className="fa fa-plus edit-icon-nofloat"
                        aria-hidden="true"
                        onClick={() => {}}
                      ></i>
                    </Tooltip>
                  </div>
                 </div>
                  {profile?.projects.map((project, index) => (
                    <div className="cv-section__wrapper" key={index}>
                      <div className="cv-section__experience">
                        <p className="cv-section__role">
                          {project.title}
                          <i
                            className="fa fa-pencil-square-o edit-icon-nofloat2 pl"
                            aria-hidden="true"
                            onClick={() => {
                              setModalData({
                                show: true,
                                key: "update_Project",
                              });
                            }}
                            title="Edit Project"
                          ></i>
                          <i
                            className="fa fa-times edit-icon-nofloat2 pl"
                            aria-hidden="true"
                            onClick={() => {}}
                            title="Remove Project"
                          ></i>
                        </p>
                        <p className="cv-section__description">
                          {project.description}
                        </p>
                        <p className="cv-section__period">
                          {`${project.startDate} - ${project.endDate}`}
                        </p>
                      </div>
                    </div>
                  ))}
                 
                </div>
              )}
            </section>
          )}

          {/* Additional sidebar sections */}
        </aside>
      </div>
    );
  };

  const handleAddExperience = (data) => {
    console.log(":: data", data);
    const newExp = [...profile.experience];
    newExp.push(data);
    setProfile({ ...profile, experience: newExp });
    setModalData({ show: false, key: null });
  };

  const handleUpdateExperience = (data, index) => {
    const newExp = [...profile.experience];
    newExp[index] = data;
    setProfile({ ...profile, experience: newExp });
    setModalData({ show: false, key: null });
  };

  const handleDeleteExp = (index) => {
    const newExp = [...profile.experience];
    newExp.splice(index, 1);
    setProfile({ ...profile, experience: newExp });
  };

  const modalBox = () => {
    const titleMap = {
      add_experience: "Add Experience",
      update_experience: "Update Experience",
      update_Project: "Update Project",
      add_course: "Add Course",
      update_course: "Update Course"
    };

    return (
      <Modal
        open={modalData?.show}
        onClose={() => setModalData({ ...modalBox, show: false })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            width: "700px",
            height: "90vh",
            overflow: "auto",
            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
            "@media (max-width: 768px)": {
              width: "calc(100% - 20px)",
              maxWidth: "95%",
              maxHeight: "calc(100vh - 40px)",
              padding: "5px",
            },
          }}
        >
          <h4>{titleMap[modalData?.key]}</h4>
          <div>
            {modalData.key === "add_experience" && (
              <div>
                <ExperienceModal
                  mode="add"
                  setModalData={setModalData}
                  onSubmit={(e) => handleAddExperience(e)}
                />
              </div>
            )}
            {modalData.key === "update_experience" && (
              <div>
                <ExperienceModal
                  mode="edit"
                  experienceData={selectedExperience?.data}
                  setModalData={setModalData}
                  onSubmit={(e) =>
                    handleUpdateExperience(e, selectedExperience.index)
                  }
                />
              </div>
            )}
            {modalData.key === "update_Project" && (
              <div>
                <ExperienceModal
                  mode="edit"
                  experienceData={selectedExperience?.data}
                  setModalData={setModalData}
                  onSubmit={(e) =>
                    handleUpdateExperience(e, selectedExperience.index)
                  }
                />
              </div>
            )}
             {modalData.key === "add_course" && (
              <div>
                <ExperienceModal
                  mode="edit"
                  experienceData={selectedExperience?.data}
                  setModalData={setModalData}
                  onSubmit={(e) =>
                    handleUpdateExperience(e, selectedExperience.index)
                  }
                />
              </div>
            )}
          </div>
        </Box>
      </Modal>
    );
  };

  // Similar methods for other sections...

  return (
    <div className={`container-fluid mb-5 cv `} id="content">
      {modalBox()}
      <form
        className="resume-form"
        style={{ background: "white", padding: "10px", borderRadius: "10px" }}
      >
        {!editMap.name ? (
          <div className="row">
            <div className="col-md-10">
              <h1 className="user-name">{`${data.first_name} ${data.last_name}`}</h1>
            </div>
            <div className="col-auto">
              <i
                className="fa fa-pencil-square-o edit-icon-nofloat2 mt-3"
                aria-hidden="true"
                onClick={() => setEditMap({ ...editMap, name: !editMap.name })}
              ></i>
            </div>
          </div>
        ) : (
          renderEditNameSection()
        )}
        {/* title */}
        <div className="row">
          <div className="col-md-10">
            {!editMap.title && <h4 className="title">{profile?.title}</h4>}
            {editMap.title && (
              <input
                id="newTitle"
                placeholder=""
                type="text"
                className=" mb-3 form-control curved"
                onChange={(e) =>
                  setProfile({ ...profile, title: e.target.value })
                }
                value={profile.title}
              />
            )}
          </div>
          <div className="col-auto">
            {!editMap?.title && (
              <Tooltip title="Edit Title">
                <i
                  onClick={() =>
                    setEditMap({ ...editMap, title: !editMap.title })
                  }
                  className="fa fa-pencil-square-o edit-icon-nofloat2"
                  aria-hidden="true"
                ></i>
              </Tooltip>
            )}
            {editMap?.title && (
              <i
                pTooltip="Update About"
                onClick={() =>
                  setEditMap({ ...editMap, title: !editMap.title })
                }
                className="fa fa-check edit-icon right-bar"
                aria-hidden="true"
              ></i>
            )}
          </div>
        </div>

        {/* contact sections */}
        {contactsSection({
          contact: profile?.contact,
          vanity_name: profile?.vanity_name,
          updateContacts: (e) => {
            console.log(":: updated", e);
          },
        })}
        {/* About Section */}
        {aboutSection()}
        {/* exp section */}
        {expSection()}
      </form>
    </div>
  );
};
