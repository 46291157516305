import * as types from '../constants/auth'
import {users} from '../actions/types'
import localStorage from '../utils/localStorage'
import {apiv1} from "../api";
import UserJobsHolder from './../utils/UserJobsHolder'
import {PROFESSIONAL} from "../constants/auth";
import {baseUrl, mainDomain} from "../constants/common";

export function getProfile(data) {
    let {first_name, last_name, email, username, email_verified, client, professional} = data
    client = client || {}
    professional = professional || {}
    let {is_default_professional, id, user, show_freebie_banner} = client
    const {gst_number} = professional
    let avatar = is_default_professional ? professional.avatar : client.avatar
    let basicDetails = {
        first_name,
        last_name,
        email,
        username,
        email_verified,
        clientUserId: user,
        avatar,
        clientId: id,
        pro_gst_number: gst_number || '',
        has_freebie: show_freebie_banner && !is_default_professional,
    }
    if (is_default_professional){
        const {profession, id} = professional
        basicDetails = {
            ...basicDetails,
            professional: {
                ...professional,
                profession,
                id
            }
        }
    }
    localStorage.logedInUserDetails = JSON.stringify(basicDetails)
    return basicDetails
}

const initialState = {
    client: {},
    professional: {},
    basicDetails: {},
    is_super_user: false,
    is_staff_user: false
};

export default function session(state = initialState, action) {
    var out = {
        ...state
    };
    const {payload} = action || {}
    const {client} = payload || {}
    switch (action.type) {
        case types.AUTH_TOKEN:
        case types.SIGNUP:
            localStorage.authToken = 'Bearer ' + action.payload.auth_token;
            if (action.type === types.SIGNUP){
                localStorage.userType = action.payload.user_type;
                localStorage.firstName = action.payload.first_name;
                localStorage.lastName = action.payload.last_name
            }
            out.auth_token = action.payload.auth_token;
            return out;
        case types.LOGOUT:
            out = {
                ...out,
                ...initialState,
            }
            apiv1.setUthTokenToNull(null)
            localStorage.clear();
            UserJobsHolder.suggestedProduct = []
            UserJobsHolder.postedJobs = []
            UserJobsHolder.jobInProgressData = []
            window.location.href = `${mainDomain}?&is_logout=${true}`
            return out;
        case types.DETAILS:
            out.client = action.payload.client || {}
            if (state.is_super_user && action.payload.professional && action.payload.client.is_default_professional){
                out.client = {...state.client}
            }
            localStorage.firstName = action.payload.first_name;
            localStorage.lastName = action.payload.last_name;
            localStorage.clientUserId = action.payload.client.user
            localStorage.clientId = client.id
            if (localStorage.userType === PROFESSIONAL){
                out.professional_user_id = action.payload.id
                localStorage.professionalId = action.payload.professional.id
            }
            out.professional = action.payload.professional || {}
            out.basicDetails = getProfile(action.payload)
            // localStorage.logedInUserDetails = JSON.stringify(out.basicDetails)
            return out;
        case types.UPLOAD_PROFILEPIC:
            out.professional.avatar = action.payload;
            return out;
        case types.UPDATE_USER_PHONE:
            if (!out.client.phone){
                out.client.phone = {}
            }
            out.client.phone = action.payload;
            return out;
        case types.UPDATE_PROFESSIONAL_PHONE:
            if (!out.professional.phone){
                out.professional.phone = {}
            }
            out.professional.phone = action.payload;
            return out;
        case types.VERIFY_PHONE:
            out.professional.phone.verified = action.payload.success;
            return out;
        case users.UPDATE_TRAINING_AND_QUESTION:
            out.professional.training_question_answer_details = action.payload
            return out;
        case types.VERIFY_EMAIL:
            out.basicDetails.email_verified = action.payload;
            return out;
        case types.UPDATE_PROFESSIONAL_BANK_DETAILS:
            out.professional.bank_account = action.payload;
            return out;
        case types.SUPER_USER:
            out.is_super_user = action.payload
            localStorage.isSuperUser = action.payload
            return out

        case types.CLAIM_REFERRED_MONEY:
            out.client.referral_credits_earned = action.payload;
            return out;
        case types.UPDATE_PRACTISE_AREA:
            out.professional.practise_area = action.payload
            return out;
        case types.UPDATE_A_PROFESSIONAL_KEY:
            const {payload, key} = action
            out.professional[key] = payload
            getProfile(out)
            return out;
        default:
            return state
    }
}

