import * as types from "../constants/auth";

const initialState = {
    professions: []
};

export default function profession (state = initialState, action) {
    var out = {
        ...state
    };
    switch (action.type) {
        case types.GET_PROFESSIONS:
            out.professions = action.payload;
            return out;
        default:
            return state
    }
}