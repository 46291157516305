const errMessage = 'This field is required';
const job_question = 'job_question';
// const job_description = 'job_description';
const practise_area = 'practise_area';
// const job_title = 'job_title';
const title = 'title';
const city = 'city';
const description = 'description';
export const CHECKBOX = 'checkbox';
export const DROPDOWN = 'dropdown';
export const RADIO = 'radio';
export const TEXT = 'text';
export const BUDGET = 'budget';
// import { TEXT, CHECKBOX, DROPDOWN, RADIO } from './../../user/OnBoarding'

export function onBoardingFirstScreenValidator({request, errors}){
    let isAllValid = true;
    for (let key in request) {
        if (key === description) {
            if (request[key].trim() === '') {
                errors[key].message = errMessage;
                isAllValid = false
            } else {
                errors.description.message = ''
            }
        } else if (key === title) {
            if (request[key].trim() === '') {
                errors.title.message = errMessage;
                isAllValid = false
            } else {
                errors.title.message = ''
            }
        }
        else if (key === BUDGET) {
            if (request[key].trim() === '') {
                errors[key].message = errMessage;
                isAllValid = false
            } else {
                errors[key].message = ''
            }
        }

        else if (key === city) {
            if (request[key].trim() === '') {
                errors[key] = errMessage;
                isAllValid = false
            }
        }
        else if (key === practise_area) {
            if (request[key].trim() === '') {
                errors[key] = errMessage;
                isAllValid = false
            } else {
                errors[key] = ''
            }
        }
    }
    return isAllValid
}

export function onBoardingValidation(errors, request) {
    let isAllValid = true;
    for (let key in request) {
        if (key === job_question) {
            for (let i = 0; i < request.job_question.length; i++) {
                if (errors[request.job_question[i].practise_area_question.toString()].mandatory && ((errors[request.job_question[i].practise_area_question.toString()].type === RADIO) || (errors[request.job_question[i].practise_area_question.toString()].type === TEXT) || (errors[request.job_question[i].practise_area_question.toString()].type === DROPDOWN))) {
                    if (request.job_question[i].answer[0].trim() === '') {
                        // if (request.job_question[i].answer.length === 0) {
                        errors[request.job_question[i].practise_area_question.toString()].message = errMessage;
                        isAllValid = false
                    } else {
                        errors[request.job_question[i].practise_area_question.toString()].message = ''
                    }
                } else if (errors[request.job_question[i].practise_area_question.toString()].mandatory && errors[request.job_question[i].practise_area_question.toString()].type === CHECKBOX && request.job_question[i].answer.length === 0) {
                    errors[request.job_question[i].practise_area_question.toString()].message = errMessage;
                    isAllValid = false
                } else {
                    errors[request.job_question[i].practise_area_question.toString()].message = ''
                }
            }
        } else if (key === description) {
            if (request[key].trim() === '') {
                errors[key].message = errMessage;
                isAllValid = false
            } else if(!(new RegExp(/^(?=.*[a-zA-Z])(?=.*[\W_]).+$/).test(request[key]))) {
                errors[key].message = 'Special Characters/ Numeric Characters Allowed';
                isAllValid = false
            } else {
                errors.description.message = ''
            }
        } else if (key === title) {
            if (request[key].trim() === '') {
                errors.title.message = errMessage;
                isAllValid = false
            } else {
                errors.title.message = ''
            }
        }
        else if (key === BUDGET) {
            if (request[key].trim() === '') {
                errors[key].message = errMessage;
                isAllValid = false
            } else {
                errors[key].message = ''
            }
        }

        else if (key === city) {
            if (request[key].trim() === '') {
                errors[key] = errMessage;
                isAllValid = false
            }
        }
        else if (key === practise_area) {
            if (request[key].trim() === '') {
                errors[key] = errMessage;
                isAllValid = false
            } else {
                errors[key] = ''
            }
        }
        else if(key === 'user') {
            if (request[key] === '') {
                errors[key] = errMessage;
                isAllValid = false
            } else {
                errors[key] = ''
            }
        }
        else if(key === 'professional') {
            // ;
            if (request[key] === '') {
                errors[key] = errMessage;
                isAllValid = false
            } else {
                errors[key] = ''
            }
        } else if(key === 'project') {
            if (request[key] === '') {
                errors[key] = errMessage;
                isAllValid = false
            } else {
                errors[key] = ''
            }
        } else if(key === 'professional_amount_tax') {
            if (!request[key]) {
                errors[key] = errMessage;
                isAllValid = false
            } else {
                errors[key] = ''
            }
        }
        else if(key === 'actual_amount_enter_by_professional') {
            if (!request[key]) {
                errors[key] = errMessage;
                isAllValid = false
            } else {
                errors[key] = ''
            }
        }
        else if(key === 'wcFee') {
            if (!request[key]) {
                errors['commission'] = errMessage;
                isAllValid = false
            } else {
                errors['commission'] = ''
            }
        }
        else if(key === 'wcGst') {
            if (!request[key]) {
                errors['commission_tax'] = errMessage;
                isAllValid = false
            } else {
                errors['commission_tax'] = ''
            }
        }
        else if (key === 'fixed_amount') {
            if (!request[key]) {
                errors[key] = errMessage;
                isAllValid = false
            } else {
                errors[key] = ''
            }
        }
        else if (key === 'unit') {
            if (!request[key]) {
                errors[key] = errMessage;
                isAllValid = false
            } else {
                errors[key] = ''
            }
        }
    }
    return isAllValid
}


export function ManuallyJobCreationValidation(errors, request) {
    let isAllValid = true;
    for (let key in request) {
        if (key === "description") {
            console.log(key)
            if (request[key].trim() === '') {
                errors.description.message = errMessage;
                isAllValid = false
            } else {
                errors.description.message = ''
            }
        } else if (key === "title") {
            if (request[key].trim() === '') {
                errors.title.message = errMessage;
                isAllValid = false
            } else {
                errors.title.message = ''
            }
        }
        else if (key === "city") {
            if (request[key].trim() === '') {
                errors[key] = errMessage;
                isAllValid = false
            }
        }
        else if (key === "practise_area") {
            if (request[key] === 0) {
                errors[key] = errMessage;
                isAllValid = false
            } else {
                errors[key] = ''
            }
        }
        else if (key === "professional") {
            console.log(request[key])
            if (request[key] === 0) {
                errors[key] = errMessage;
                isAllValid = false
            } else {
                errors[key] = ''
            }
        }
        else if (key === "user") {
            if (request[key] === 0) {
                errors[key] = errMessage;
                isAllValid = false
            } else {
                errors[key] = ''
            }
        }
        else if (key === "amount") {
            if (request[key].trim() === '') {
                errors[key] = errMessage;
                isAllValid = false
            } else {
                errors[key] = ''
            }
        }
        else if (key === "unit") {
            if (request[key].trim() === '') {
                errors[key] = errMessage;
                isAllValid = false
            } else {
                errors[key] = ''
            }
        }
    }
    return isAllValid
}


export function ManuallyAddPaymnetValidation(errors, request) {
    let isAllValid = true;
    for (let key in request) {
        if (key === "job_id") {
            if (request[key] === 0) {
                errors[key] = errMessage;
                isAllValid = false
            } else {
                errors[key] = ''
            }
        } else if (key === "amount") {
            if (request[key].trim() === '') {
                errors[key] = errMessage;
                isAllValid = false
            } else {
                errors[key] = ''
            }
        }
        else if (key === "cheque_no") {
            if (request[key].trim() === '') {
                errors[key] = errMessage;
                isAllValid = false
            }
        }
        else if (key === "description") {
            if (request[key] === '') {
                errors[key] = errMessage;
                isAllValid = false
            } else {
                errors[key] = ''
            }
        }
        else if (key === "date") {
            if (request[key] === '') {
                errors[key] = errMessage;
                isAllValid = false
            } else {
                errors[key] = ''
            }
        }
        else if (key === "bank") {
            if (request[key] === '') {
                errors[key] = errMessage;
                isAllValid = false
            } else {
                errors[key] = ''
            }
        }
        
    }
    return isAllValid
}



export function JobTransferToOtherProfessionalValidation(errors, request) {
    let isAllValid = true;
    for (let key in request) {
        if (key === "job") {
            console.log(key)
            if (request[key] === 0) {
                errors[key] = errMessage;
                isAllValid = false
            } else {
                errors[key] = ''
            }
        }
        else if (key === "professional") {
            console.log(request[key])
            if (request[key] === 0) {
                errors[key] = errMessage;
                isAllValid = false
            } else {
                errors[key] = ''
            }
        }
    }
    return isAllValid
}