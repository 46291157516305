const KEY_FCM_TOKEN_ID = 'fcm_token_id';
const KEY_SELECTED_SERVICE_ID = 'selected_service_id';
export const KEY_AUTH_TOKEN = 'auth_token';
export const KEY_USER_TYPE = 'user_type';
const KEY_FIRST_NAME = 'first_name';
const KEY_LAST_NAME = 'last_name';
const KEY_AVATAR = 'avatar';
const KEY_PROFESSIONAL_ID = 'professional_id';
const KEY_CLIENT_ID = 'client_id';
const KEY_PROFESSIONAL_DETAILS = 'professional_details';
const KEY_USER_ID = 'user_id';
export const BOOK_CONSULTATION = 'book_consultation';
const KEY_CONSULTATION_DETAILS = 'consultation_details';
export const KEY_CITY_ID = 'city_id';
export const KEY_PROFESSION_ID = 'profession_id';
export const KEY_REFERRAL_CODE = 'referral_code';
export const KEY_PATH_NAME = 'path_name';
export const KEY_ONBOARDING_DATA = 'key_onboarding_data';
export const KEY_FORUM_REQUEST_DATA = 'key_forum_request_data';
export const KEY_IS_SUPER_USER = 'key_is_super_user'
export const KEY_AFFILIATE_MARKETING_DETAILS = 'affiliate_marketing_details'
export const KEY_COUNTRY = 'country';
const KEY_LOGEDIN_USER_details = 'logedin_user_details';


class LocalStorage {

    set fcmTokenId(value) {
        window.localStorage.setItem(KEY_FCM_TOKEN_ID, value)
    }
    get fcmTokenId() {
        return window.localStorage.getItem(KEY_FCM_TOKEN_ID)
    }
    set selectedServiceId(value) {
        window.localStorage.setItem(KEY_SELECTED_SERVICE_ID, value)
    }
    get selectedServiceId() {
        return window.localStorage.getItem(KEY_SELECTED_SERVICE_ID)
    }

    get authToken() {
        return window.localStorage.getItem(KEY_AUTH_TOKEN)
    }

    set authToken(value) {
        window.localStorage.setItem(KEY_AUTH_TOKEN, value)
    }
    get userType() {
        return window.localStorage.getItem(KEY_USER_TYPE)
    }

    set userType(value) {
        window.localStorage.setItem(KEY_USER_TYPE, value)
    }

    get firstName() {
        return window.localStorage.getItem(KEY_FIRST_NAME)
    }

    set firstName(value) {
        window.localStorage.setItem(KEY_FIRST_NAME, value)
    }

    get lastName() {
        return window.localStorage.getItem(KEY_LAST_NAME)
    }

    set lastName(value) {
        window.localStorage.setItem(KEY_LAST_NAME, value)
    }
    get avatar() {
        return window.localStorage.getItem(KEY_AVATAR)
    }

    set avatar(value) {
        window.localStorage.setItem(KEY_AVATAR, value)
    }

    get clientUserId() {
        return window.localStorage.getItem(KEY_USER_ID)
    }
    set clientUserId(value) {
        window.localStorage.setItem(KEY_USER_ID, value)
    }

    get professionalId() {
        return window.localStorage.getItem(KEY_PROFESSIONAL_ID)
    }

    set professionalId(value) {
        window.localStorage.setItem(KEY_PROFESSIONAL_ID, value)
    }
    get clientId() {
        return window.localStorage.getItem(KEY_CLIENT_ID)
    }

    set clientId(value) {
        window.localStorage.setItem(KEY_CLIENT_ID, value)
    }
    get logedInUserDetails() {
        return window.localStorage.getItem(KEY_LOGEDIN_USER_details)
    }

    set logedInUserDetails(value) {
        window.localStorage.setItem(KEY_LOGEDIN_USER_details, value)
    }
    get selectedProfessionalDetails() {
        return window.localStorage.getItem(KEY_PROFESSIONAL_DETAILS)
    }

    set selectedProductDetails(value) {
        window.localStorage.setItem(KEY_PROFESSIONAL_DETAILS, value)
    }
    get selectedProductDetails() {
        return window.localStorage.getItem(KEY_PROFESSIONAL_DETAILS)
    }

    set selectedProfessionalDetails(value) {
        window.localStorage.setItem(KEY_PROFESSIONAL_DETAILS, value)
    }
    get isBookConsultation() {
        return window.localStorage.getItem(BOOK_CONSULTATION)
    }

    set isBookConsultation(value) {
        window.localStorage.setItem(BOOK_CONSULTATION, value)
    }

    get consultationDetails() {
        return window.localStorage.getItem(KEY_CONSULTATION_DETAILS)
    }

    set consultationDetails(value) {
        window.localStorage.setItem(KEY_CONSULTATION_DETAILS, value)
    }
    get cityId() {
        return window.localStorage.getItem(KEY_CITY_ID)
    }

    set cityId(value) {
        window.localStorage.setItem(KEY_CITY_ID, value)
    }
    get professionId() {
        return window.localStorage.getItem(KEY_PROFESSION_ID)
    }

    set professionId(value) {
        window.localStorage.setItem(KEY_PROFESSION_ID, value)
    }

    get referralCode() {
        return window.localStorage.getItem(KEY_REFERRAL_CODE)
    }

    set referralCode(value) {
        window.localStorage.setItem(KEY_REFERRAL_CODE, value)
    }

    get pathName() {
        return window.localStorage.getItem(KEY_PATH_NAME)
    }

    set pathName(value) {
        window.localStorage.setItem(KEY_PATH_NAME, value)
    }
    get onBoardingData() {
        return window.localStorage.getItem(KEY_ONBOARDING_DATA)
    }

    set onBoardingData(value) {
        window.localStorage.setItem(KEY_ONBOARDING_DATA, value)
    }

    get forumRequestData() {
        return window.localStorage.getItem(KEY_FORUM_REQUEST_DATA)
    }

    set forumRequestData(value) {
        window.localStorage.setItem(KEY_FORUM_REQUEST_DATA, value)
    }

    get isSuperUser() {
        return window.localStorage.getItem(KEY_IS_SUPER_USER)
    }

    set isSuperUser(value) {
        window.localStorage.setItem(KEY_IS_SUPER_USER, value)
    }
    get affiliateMarketingDetails() {
        return window.localStorage.getItem(KEY_AFFILIATE_MARKETING_DETAILS)
    }

    set affiliateMarketingDetails(value) {
        window.localStorage.setItem(KEY_AFFILIATE_MARKETING_DETAILS, value)
    }

    getTokenWithoutBearer(){
        return this.authToken ? this.authToken.slice(7) : ''
    }

    clear() {
        window.localStorage.clear()
    }

    removeKey(KEY) {
        window.localStorage.removeItem(KEY)
    }

    isLoggedIn() {
        return (
            Boolean(this.authToken)
        )
    }

    isSessionValid() {
        return this.isLoggedIn()

    }

    get country() {
        return window.localStorage.getItem(KEY_COUNTRY)
    }
    set country(value) {
        window.localStorage.setItem(KEY_COUNTRY, value)
    }

}

const instance = new LocalStorage();

export default instance
