import React, { Component, Fragment } from "react";
import $ from "jquery";
import { apiv1, apiv2 } from "../../api";
import { connect } from "react-redux";
import { errorActionDispatchers } from "../../actions/errors";
import LoadingComponent from "../../utils/LoadingComponent";
// import DesignerSingleProduct from "./DesignerSingleProduct";
import localStorage from './../../utils/localStorage'
import queryString from "query-string";
import { ShareProfile } from "./ProfessionalPublicProfile";
// import {siteBaseUrl} from "../../constants/common";
import * as path from "../../constants/path";
import { designer, getProfessionalType, legalAndAccounting } from "../../constants/businessPractiseArea";
// import * as apiUrl from './../../api/apiUrls'
import { mainDomain } from "../../constants/common";
import { freelancers } from "../../constants/keyWord";
import { CreatGigTutorial } from "./ProfessionalBriefBio";
// import Link from "@material-ui/core/Link";
import * as apiUrls from "../../api/apiUrls";
import PaginationWithArrow from "../common/PaginationWithArrow";
import {
    Link
} from "react-router-dom"
import ExpertSuggestedGigs from "../common/ExpertSuggestedGigs";
import voltImage from '../../static/images/volt.png';
import startupRocketImage from '../../static/images/startup-rocket.png';
import standardGigEmptyPlace from '../../static/images/standard-gig-empty-place.png';

function calculatedCommission({ results }) {
    results = results.map(productDetails => {
        let price = parseInt(productDetails.price)
        price = price || 0
        let { commission, commission_tax, professional_amount_tax, price_premium, price_standard, professional_details } = productDetails
        let amountToSplit = price
        commission = commission || 0
        commission_tax = commission_tax || 0
        professional_amount_tax = professional_amount_tax || 0
        price += commission + commission_tax + professional_amount_tax
        price = (parseInt((price + 9) / 10)) * 10
        amountToSplit = Math.abs(price - amountToSplit)
        let wizCut = parseFloat((amountToSplit / 1.18).toFixed(2))
        let wcTax = amountToSplit - wizCut
        commission += wizCut
        commission = Number(commission.toFixed(2))
        commission_tax += wcTax
        commission_tax = Number(commission_tax.toFixed(2))
        professional_amount_tax = Number(professional_amount_tax.toFixed(2))
        return {
            price,
            commission,
            commission_tax,
            professional_amount_tax,
            price_standard,
            price_premium,
            professional_details,
            professionalProfession: {}
        }
    })
    return results[0]
}

class ExpertStandardGigList extends Component {
    constructor(props) {
        super(props)
        this.api = props.is_super_user ? apiv2 : apiv1
        const { professional, history } = props
        let { profession } = professional || {}
        profession = profession || []
        const { location } = history
        const { search } = location
        let { page, searched } = queryString.parse(search)
        page = page ? Number(page) : 1
        let { selectedCategoryId } = queryString.parse(search)
        selectedCategoryId = Number(selectedCategoryId) || ''
        let professionalType = getProfessionalType({ profession })
        this.state = {
            professional: { ...professional },
            practiceAreaIds: this.getPracticeAreaIds({ ...professional }),
            practiceArea: [],
            selectedCategoryId,
            selectedProduct: {},
            loading: false,
            practiceAreaPrice: [],
            errors: {},
            isPriceApiHit: false,
            productLink: '',
            reasonsList: [],
            reasonsIdArr: [],
            professionalType,
            page,
            limit: 10,
            expertCreatedProductList: [],
            professionalProfession: {}
        }
    }

    componentDidMount() {
        const { page } = this.state
        const { is_super_user } = this.props
        if (is_super_user) {
            this.getReasons()
        }
        // this.getProfessionalProfession()
        this.getExpertProduct(page)
    }

    // getProfessionalProfession = () => {
    //     // const {product} = this.state
    //     this.setState({loading: true})
    //     this.api.auth.get(`${apiUrl.professionalProfession}`)
    //         .then(res => {
    //             const data = apiv1.parseAndAutoHandleIssues(res, this.props.doDispatch);
    //             if (data) {
    //                 this.setState({professionalProfession: data}, this.getPracticeArea)
    //             } else {
    //                 this.setState({loading: false})
    //             }
    //         })
    //         .catch(err => {
    //             this.setState({loading: false});
    //             apiv1.handleErrors(err, this.props.doDispatch)
    //         })
    // }

    // componentWillReceiveProps(nextProps, nextContext) {
    //     let {professional} = nextProps
    //     professional = professional || {}
    //     let {profession} = professional || {}
    //     profession = profession || []
    //     const {practiceAreaPrice, isPriceApiHit} = this.state
    //     if (professional.id && practiceAreaPrice.length === 0 && !isPriceApiHit) {
    //         const practiceAreaIds = this.getPracticeAreaIds({...professional});
    //         let professionalType = getProfessionalType({profession})
    //         this.setState({practiceAreaIds, professional, isPriceApiHit: true, professionalType})
    //     }
    // }

    getExpertProduct = (page) => {
        this.setState({ loading: true })
        const { doDispatch } = this.props
        const { limit, professional } = this.state
        let url = `${apiUrls.dashboardDefaultGigList}/?professional=${professional.id}&limit=${limit}&page=${page}`
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                if (data) {
                    const { results, count } = data
                    this.setState({ expertCreatedProductList: results, loading: false, totalRecord: count, page })
                }
            })
            .catch(err => {
                this.setState({ loading: false });
                apiv1.handleErrors(err, doDispatch)
            })
    }


    getReasons = () => {
        apiv1.auth.get('/users/product-rejection-reasons/')
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    this.setState({ reasonsList: data })
                }
            })
            .catch(err => {
                this.setState({ loading: false });
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    updateRejectedProduct = ({ productProfessionalId }) => {
        const request = {
            reject: true,
        }
        this.setState({ loading: true })
        apiv1.auth.patch(`/users/professional-product/${productProfessionalId}/`, request)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    let practiceAreaPrice = [...this.state.practiceAreaPrice]
                    practiceAreaPrice[practiceAreaPrice.findIndex(practiceAreaPriceId => practiceAreaPriceId.id === productProfessionalId)] = data
                    this.setState({ loading: false, practiceAreaPrice })
                } else {
                    this.setState({ loading: false })
                }
            })
            .catch(err => {
                this.setState({ loading: false });
                apiv1.handleErrors(err, this.props.doDispatch)
            })


    }

    rejectGigWithReasons = (productProfessionalId) => {
        let { reasonsIdArr } = this.state
        // const url = `/users/product-rejection-reasons/`;
        const url = `http://20.231.48.250:8000/users/product-rejection-reasons`;
        const request = {
            reason: reasonsIdArr,
            product_professional: productProfessionalId
        };
        apiv1.auth.post(url, request)
            .then(resp => {
                this.updateRejectedProduct({ productProfessionalId })
            })
            .catch(err => {
                console.log("Try Again.")
            })
    }

    handleReasonCheck(e) {
        let isChecked = e.target.checked;
        let { reasonsIdArr } = this.state
        const { value } = e.target
        if (isChecked) {
            reasonsIdArr.push(value)
        } else {
            let index = reasonsIdArr.findIndex(x => x === value)
            delete reasonsIdArr[index]
        }
        this.setState({ reasonsIdArr })

    }

    // getPracticeAreaSelectedPrice = (parentServiceId, professionalId) => {
    //     this.setState({loading: true})
    //     this.api.auth.get(`users/professional-product/?product__practise_area=${parentServiceId}&&professional=${professionalId}`)
    //         .then(resp => {
    //             const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch)
    //             if (data) {
    //                 this.getPracticeAreaPrices({parentServiceId, addedProducts: data})
    //             } else {
    //                 this.setState({loading: false})
    //             }
    //         })
    //         .catch(err => {
    //             this.setState({loading: false});
    //             apiv1.handleErrors(err, this.props.doDispatch)
    //         })
    //
    // }


    // getPracticeAreaPrices = ({parentServiceId, addedProducts}) => {
    //     const {professional} = this.state
    //     this.api.auth.get(`users/product/?practise_area=${parentServiceId}`)
    //         .then(resp => {
    //             const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch)
    //             if (data) {
    //                 const {professionalType} = this.state
    //                 const practiceAreaPrice = data.map(productDetails => {
    //                     const {id} = productDetails
    //                     let addedProduct = addedProducts.find(addedProductDetails => addedProductDetails.product === id)
    //                     if (addedProduct && professionalType === legalAndAccounting) {
    //                         let {samples_migrated, price, verified} = addedProduct
    //                         price = parseInt(price)
    //                         verified = verified ? (price > 0 && samples_migrated && samples_migrated.length > 0) : verified
    //                         addedProduct = {
    //                             ...addedProduct,
    //                             verified,
    //                         }
    //                     }
    //                     return (
    //                         addedProduct ||
    //                         {
    //                             product_details: {...productDetails},
    //                             price: '',
    //                             professional: professional.id,
    //                             product: id
    //                         }
    //                     )
    //
    //                 })
    //                 this.setState({practiceAreaPrice, loading: false})
    //             } else {
    //                 this.setState({loading: false})
    //             }
    //         })
    //         .catch(err => {
    //             this.setState({loading: false});
    //             apiv1.handleErrors(err, this.props.doDispatch)
    //         })
    // }

    getPracticeAreaIds = (professional) => {
        return (professional.practise_area !== undefined ? professional.practise_area : []);
    };

    practiceAreaSerialised = practiceArea => {
        const dataArray = [];
        const { professionalType } = this.state
        practiceArea.map(data => {
            const { parent, is_core_legal_tax, id, name, has_new_products, profession } = data || {}
            if (parent === null) {
                if ((professionalType === legalAndAccounting && is_core_legal_tax) || (professionalType === designer && !is_core_legal_tax)) {
                    dataArray.push({
                        id,
                        name,
                        has_new_products,
                        children: [],
                        profession,
                    })
                }
            }
        });
        for (let i = 0; i < practiceArea.length; i++) {
            if (practiceArea[i].parent !== null) {
                for (let j = 0; j < dataArray.length; j++) {
                    if (practiceArea[i].parent === dataArray[j].id) {
                        dataArray[j].children.push(practiceArea[i])
                    }
                }
            }
        }
        return dataArray
    }

    // getPracticeArea = () => {
    //     const url = `users/practisearea/`
    //     this.setState({loading: true})
    //     this.api.auth.get(url)
    //         .then(resp => {
    //             const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch)
    //             if (data) {
    //                 const practiceArea = this.practiceAreaSerialised(data);
    //                 let {selectedCategoryId, professionalProfession} = this.state
    //                 if (practiceArea.length > 0) {
    //                     if(professionalProfession){
    //                         practiceArea.map(pa=>{
    //                             if(pa.profession === professionalProfession.id)
    //                             {
    //                                 selectedCategoryId = selectedCategoryId ? selectedCategoryId : pa.id
    //                             }
    //                         })
    //                     }
    //                     // selectedCategoryId = selectedCategoryId ? selectedCategoryId : practiceArea[0].id
    //                 }
    //                 if (!selectedCategoryId){
    //                     selectedCategoryId = practiceArea[0].id
    //                 }
    //                 this.setState({practiceArea, selectedCategoryId}, () =>{
    //                     this.getPracticeAreaSelectedPrice(selectedCategoryId, localStorage.professionalId)
    //                 })
    //             } else {
    //                 this.setState({loading: false})
    //             }
    //         })
    //         .catch(err => {
    //             this.setState({loading: false});
    //             apiv1.handleErrors(err, this.props.doDispatch)
    //         })
    // };

    savePracticeArea = (request, professionalId) => {
        return this.api.auth.patch(`/users/professionalprofile/${professionalId}/`, request)
    };

    onEditGigClick = ({ productDetails, product_details, professionalProductId }) => {
        this.setState({ selectedProduct: productDetails }, () => {
            console.log(productDetails)
            // const {name, id} = product_details || {}
            let { history } = this.props
            // const {professionalType} = this.state
            let url = `${path.editExpertStandardGig}`
            // if (professionalType === legalAndAccounting) {
            //     url = path.createLegalAndAccountingGig
            // }
            // url += `?product=${id}&title=${name}`
            if (professionalProductId) {
                url += `?professionalProduct=${professionalProductId}`
            }
            url += `#title_and_desc`
            history.push(url)
        })
    }

    designerProductListUi = () => {
        const { expertCreatedProductList } = this.state
        const { is_super_user } = this.props
        return (
            <Fragment>
                {expertCreatedProductList.map((productDetails, index) => {
                    let { id, product_details, views_count, verified, professional_details, professional_description } = productDetails || {}
                    // samples_migrated = samples_migrated || []
                    // price = parseInt(price)
                    const { name } = product_details || {}
                    const { slug_new } = professional_details || {}
                    let gigPath = `${mainDomain}/${freelancers}/${slug_new}/${product_details.slug_new}`
                    return (
                        <tr key={id}>
                            <td className="col-sm-3 other-skill-table">
                                <div style={{ width: '400px', whiteSpace: 'normal' }}>
                                    <a
                                        style={{ color: '#000' }}
                                        id={id}
                                        onClick={() => this.onEditGigClick({
                                            productDetails,
                                            product_details,
                                            professionalProductId: id
                                        })}
                                    >
                                        <span><b>Category</b>: {name}</span>

                                    </a>
                                    <br />
                                    <a id={id}
                                        style={{ color: '#000' }}
                                        onClick={() => this.onEditGigClick({
                                            productDetails,
                                            product_details,
                                            professionalProductId: id
                                        })}
                                    >
                                        <span>
                                            <b>Gig title</b>: {`${professional_description}`}
                                        </span>

                                    </a>
                                </div>
                            </td>
                            <td className="text-center"><span
                                style={{ color: `${verified ? '#1daa89' : '#5f6883'}` }}>{`${verified ? 'Live' : 'Not Live'}`}</span>
                            </td>
                            {/*<td className="text-center">{views_count > 0 && <span><i className="fa fa-eye" aria-hidden="true"/> {views_count}</span>}</td>*/}
                            <td>
                                {id &&
                                    <Fragment>
                                        <a
                                            className="btn btn-link"
                                            style={{ padding: '5px 0px', color: '#ff337d' }}
                                            onClick={() => this.onEditGigClick({
                                                productDetails,
                                                product_details,
                                                professionalProductId: id
                                            })}
                                        >Edit Gig</a>
                                        <br />
                                        <a
                                            className="btn btn-link"
                                            style={{ padding: '5px 0px', color: '#ff337d' }}
                                            onClick={() => {
                                                window.open(`${gigPath}?isFromDashboard=${true}&professionalProductId=${id}`, '_blank')
                                            }}
                                        >
                                            Preview
                                        </a>
                                    </Fragment>
                                }

                                {is_super_user && id &&
                                    <Fragment>
                                        <br />
                                        <a
                                            className="btn btn-link"
                                            style={{ padding: '5px 0px', color: '#ff337d' }}
                                            onClick={() => {
                                                $(`#rating_improve${id}`).click()
                                            }}
                                        >Reject Gig</a>
                                    </Fragment>
                                }
                            </td>
                            <td>
                                {id &&
                                    <Fragment>
                                        {verified &&
                                            <a
                                                className="btn btn-link"
                                                style={{ color: '#3821d6', fontSize: '16px', padding: '5px 0px' }}
                                                onClick={() => {
                                                    let { price, professional_details } = calculatedCommission({ results: [productDetails] }) || {}
                                                    const { referral_code } = professional_details || {}
                                                    let pathname = `${gigPath}?referral_code=${referral_code}`
                                                    this.setState({ productLink: pathname }, () => {
                                                        $('#share_product_on_social_media').click()
                                                    })
                                                }}
                                            >
                                                <i className="fa fa-share-alt" aria-hidden="true" />
                                            </a>
                                        }
                                    </Fragment>
                                }
                            </td>
                        </tr>
                    )
                })}
            </Fragment>
        )
    }


    // categoryListUi = () => {
    //     const {practiceArea, selectedCategoryId, professional, professionalType, professionalProfession} = this.state
    //     return (
    //         <div className="gig-category-sidebar">
    //             <ul className="list-unstyled catagory-list">
    //                 {practiceArea.map((data, index) => {
    //                     const {id, name, has_new_products, profession} = data || {}
    //                     if(profession===professionalProfession.id){
    //                         return (
    //                             <li className={selectedCategoryId === id ? 'active' : ''}
    //                                 key={id}
    //                                 id={id + 'parent'}
    //                                 onClick={() => {
    //                                     if (selectedCategoryId !== id) {
    //                                         const practiceAreaIds = this.getPracticeAreaIds(professional)
    //                                         this.setState({
    //                                             selectedCategoryId: id,
    //                                             practiceAreaIds,
    //                                             practiceAreaPrice: [],
    //                                             errors: {}
    //                                         }, () => {
    //                                             this.getPracticeAreaSelectedPrice(id, localStorage.professionalId)
    //                                             const {history, pathname} = this.props
    //                                             history.replace({
    //                                                 pathname,
    //                                                 search: `?&selectedCategoryId=${id}`
    //                                             })
    //                                         })
    //                                     }
    //                                 }}
    //                             >
    //                                 <a>
    //                                     {name}
    //                                     {has_new_products &&
    //                                     <span className="label label-default">
    //                                     New
    //                                 </span>
    //                                     }
    //
    //                                 </a>
    //                             </li>
    //                         )
    //                     }
    //
    //                 })}
    //
    //                 {practiceArea.map((data, index) => {
    //                     const {id, name, has_new_products, profession} = data || {}
    //                     if(profession !== professionalProfession.id){
    //                         return (
    //                             <li className={selectedCategoryId === id ? 'active' : ''}
    //                                 key={id}
    //                                 id={id + 'parent'}
    //                                 onClick={() => {
    //                                     if (selectedCategoryId !== id) {
    //                                         const practiceAreaIds = this.getPracticeAreaIds(professional)
    //                                         this.setState({
    //                                             selectedCategoryId: id,
    //                                             practiceAreaIds,
    //                                             practiceAreaPrice: [],
    //                                             errors: {}
    //                                         }, () => {
    //                                             this.getPracticeAreaSelectedPrice(id, localStorage.professionalId)
    //                                             const {history, pathname} = this.props
    //                                             history.replace({
    //                                                 pathname,
    //                                                 search: `?&selectedCategoryId=${id}`
    //                                             })
    //                                         })
    //                                     }
    //                                 }}
    //                             >
    //                                 <a>
    //                                     {name}
    //                                     {has_new_products &&
    //                                     <span className="label label-default">
    //                                     New
    //                                 </span>
    //                                     }
    //
    //                                 </a>
    //                             </li>
    //                         )
    //                     }
    //
    //
    //                 })}
    //
    //             </ul>
    //         </div>
    //     )
    // }

    subCategoryUiWithPrice = () => {
        const { selectedCategoryId } = this.state
        // const {is_super_user} = this.props
        return (
            <table className="table table-hover" style={{ marginTop: '15px' }}>
                <thead>
                    <tr>
                        <th>Product</th>
                        <th className="text-center">Status</th>
                        {/*<th className="text-center">Views</th>*/}
                        <th>Action</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.designerProductListUi()}
                </tbody>
            </table>
        )
    }
    improveProfileRating = ({ index, productProfessionalId }) => {
        const { reasonsList } = this.state
        return (
            <div className="modal fade" id={`rating_improve_popup${productProfessionalId}`} role="dialog">
                <div className="modal-dialog dialog-top-margin">
                    <div className="modal-content">
                        <div className="modal-header" style={{ border: 'none' }}>
                            <button type="button"
                                className="close"
                                data-dismiss="modal"
                            >&times;</button>
                            <h4 className="modal-title">Select Reasons </h4>
                        </div>
                        <div className="modal-body">
                            {reasonsList.map((reasonListData => {
                                const { id, reasons_text } = reasonListData
                                return (
                                    reasons_text && <div className='checkbox'>
                                        < label style={{ fontSize: '14px' }
                                        }>
                                            <input type="checkbox" onChange={e => this.handleReasonCheck(e)}
                                                value={id}
                                            />
                                            <span className='cr'>
                                                <i className='cr-icon glyphicon-ok glyphicon' />
                                            </span>
                                            {reasons_text}
                                        </label>
                                    </div>
                                )
                            }))}

                        </div>
                        <div className="modal-footer"
                            style={{ border: 'none', textAlign: 'center', marginBottom: '15px' }}>
                            {
                                <button type="button"
                                    className="btn btn-save-profile"
                                    data-dismiss="modal"
                                    onClick={() => {
                                        this.rejectGigWithReasons(productProfessionalId)
                                    }}
                                >
                                    Save
                                </button>
                            }
                        </div>
                    </div>
                </div >
            </div >
        )
    }

    render() {
        const { history, isFromProfilePage } = this.props
        const { productLink, expertCreatedProductList, totalRecord, loading, page, limit } = this.state
        return (
            <div className="row">
                <div className={`col-md-8 ${!isFromProfilePage && 'd-parent-common-top'}`}>
                    {/* <div className="d-update-profile">
                        <div className="d-update-profile-body">
                            {!isFromProfilePage &&
                                <p className="para" style={{ marginBottom: '0px' }}><a
                                    onClick={() => {
                                        history.goBack()
                                    }}
                                    style={{ fontSize: '14px', color: '#ff337d' }}
                                ><i className="fa fa-long-arrow-left" /> Go Back</a></p>
                            }
                            <div className="row">
                                <div className="col-sm-8">
                                    <h4 className="d-profile-heading">Service Standard Gigs Store</h4>
                                </div>
                                <div className="col-sm-4">
                                    <Link to={`${path.createExpertStandardGig}#title_and_desc`}
                                        className="btn btn-submit-gig" style={{ marginTop: '0px' }}>Create
                                        New Gig</Link>
                                </div>
                            </div>
                        </div>
                        <div className="d-update-profile-body" style={{ borderBottom: 'none' }}>
                            {loading &&
                                <div className='lazy-loader' style={{ position: 'fixed', zIndex: '10' }}><LoadingComponent
                                    pastDelay />
                                </div>
                            }
                            {expertCreatedProductList.length > 0 ?
                                <div className="row">
                                    <div className="col-sm-12 skill-table">
                                        <h4 className="d-profile-heading">Standard Gigs Created</h4>
                                        {this.subCategoryUiWithPrice()}
                                        {!loading && totalRecord > limit && (
                                            <PaginationWithArrow onChange={this.getExpertProduct}
                                                totalRecord={totalRecord} pageSize={limit}
                                                currentPage={page} />)}
                                        <CreatGigTutorial />
                                        {expertCreatedProductList.map((practiceAreaPricedata, index) => {
                                            const { id } = practiceAreaPricedata
                                            return (
                                                <Fragment>
                                                    <p id={`rating_improve${id}`} data-toggle="modal"
                                                        data-target={`#rating_improve_popup${id}`} />
                                                    {this.improveProfileRating({ index, productProfessionalId: id })}
                                                </Fragment>
                                            )
                                        })}
                                    </div>
                                </div>
                                :
                                <div className="row for-expert-services" style={{ marginBottom: '5%', marginTop: '1%' }}>
                                    <div className="col-sm-6">
                                        <div className="expert-banner-border-img">
                                            <img src={standardGigEmptyPlace}
                                                className="img-responsive"
                                                alt="Grow & Manage Your Services Business." />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <h2 className="crated-gig-empty-place-heading">Hey! This is where you can create
                                            Standard Gigs for services you provide along with the packages and
                                            pricing.</h2>
                                        <ul className="crated-gig-empty-place list-unstyled">
                                            <li>
                                                You can create as many Gigs you want
                                            </li>
                                            <li>You can also create multiple Gigs for same service</li>
                                            <li>All Gigs are verified by our team before they are made live</li>
                                            <li>Once Gigs are live, App users and Website Visitors can see your Gig and
                                                message you to book your services
                                            </li>
                                        </ul>
                                        <a href={`${path.createExpertStandardGig}#title_and_desc`}
                                            className="btn btn-submit-gig">Create your first Standard Gig</a>
                                    </div>
                                </div>
                            }
                        </div>
                        <p id='share_product_on_social_media' data-toggle="modal"
                            data-target="#share_product_on_social_media_popup" />
                        <div className="modal share-profile-modal" id="share_product_on_social_media_popup"
                            role="dialog">
                            <ShareProfile link={productLink} popupHeading={`Share and show some love.`} />
                        </div>
                    </div> */}
                    {/* <ExpertSuggestedGigs /> */}
                </div>
                <div className={`col-md-4 ${!isFromProfilePage && 'd-parent-common-top'}`}>
                    {/* <div className="d-update-profile">
                        <div className="d-update-profile-body text-center">
                            <img src={startupRocketImage} style={{ width: '100px' }} alt={`create gig`} />
                            <h4 className="d-profile-heading">Get 4x more leads with these Tips </h4>
                        </div>
                        <div className="d-update-profile-body">
                            <ul className="list-unstyled create-gig-tips-ul">
                                <li><img src={voltImage} alt={`create gig`} />You can create as many Gigs you want</li>
                                <li><img src={voltImage} alt={`create gig`} />You can create multiple gigs in same service</li>
                                <li><img src={voltImage} alt={`create gig`} />More Gigs you create more leads you get</li>
                                <li><img src={voltImage} alt={`create gig`} />Keep your basic price competitive and affordable with less deliverables</li>
                                <li><img src={voltImage} alt={`create gig`} />Add your best portfolio samples and past work in the proof of work</li>
                            </ul>
                        </div>
                    </div> */}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    ...(errorActionDispatchers(dispatch)),
    onUpdateAProfessionalKey: (action) => {
        dispatch(action);
    },
})

const mapStateToProps = state => ((state) => {
    let { is_super_user } = state.data
    let loggedInUserDetails = JSON.parse(localStorage.logedInUserDetails)
    const { professional } = loggedInUserDetails
    return {
        professional,
        is_super_user
    }

});

export default connect(mapStateToProps, mapDispatchToProps)(ExpertStandardGigList)
