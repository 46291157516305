import React, {Component, Fragment} from "react";
import {apiv1} from "../../api";
import {errorActionDispatchers} from "../../actions/errors";
import connect from "react-redux/es/connect/connect";
import LoadingComponent from "../../utils/LoadingComponent";
import queryString from "query-string";
import {getCity} from "../../actions/geo";
import {productSlugMappedWithId, parentPracticeAreaSlug, siteLogoOnSharedMedia} from './../common/bannerTextForProducts'
// import SiteHeader from "../SiteHeader";
import HiredIcon from "../../static/images/hired-icon.png";
import CreditCard from "../../static/images/credit-card.png";
import ColoredBullseye from "../../static/images/colored-bullseye.png";
import {FaqForAdd} from "../common/taxAddPopup";
import MetaTags from "react-meta-tags";
import * as keyWords from '../../constants/keyWord'
import ProductReviews from "../common/ProductReviews";
import {baseUrl} from "../../constants/common";
// export const showLegalAreaForProductSlug = ['consultation-fees-per-hour-we-encourage-putting-a-reasonable-fee-between-rs-1000-to-rs4000', 'legal-notice-fees', 'appearance-fee-per-hearing']
// export const consultationForProductSlug = ['consultation-fees-per-hour-we-encourage-putting-a-reasonable-fee-between-rs-1000-to-rs4000', '']
export const showLegalAreaForProduct = [1, 2, 3]
export const consultationForProduct = [1, 8]

class ProductOnBoarding extends Component {

    constructor(props){
        super(props);
        let { practicearea, product } = queryString.parse(props.history.location.search)
        product = product || 1
        practicearea = practicearea || 2
        this.state = {
            city: props.city,
            legalArea: [],
            selectedLegalAreaId: '',
            productId: product ? Number(product): 1,
            productSlug: product && productSlugMappedWithId[product] ? productSlugMappedWithId[product].slug: '',
            practiceAreaId: practicearea ? Number(practicearea): 2,
            parentPracticeAreaSlug: practicearea && parentPracticeAreaSlug[practicearea] ? parentPracticeAreaSlug[practicearea].slug: '',
            legalId: '',
            cityId: '',
            errors: {},
        }
    }


    componentDidMount() {
        const {practiceAreaId, city} = this.state
        if (city.length === 0){
            // this.getCity()
        }
        if (practiceAreaId !== undefined) {
            this.getPracticeAreaById(practiceAreaId)
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.city.length === 0){
            // this.getCity()
        }
    }

    getCity = () => {
        this.setState({loading: true});
        apiv1.noAuth.get(`/geo/city-for-user/`)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp);
                if (data) {
                    const city = data.filter(cityData => cityData.slug)
                    this.setState({city, loading: false}, () => {
                        this.props.onGetCity(getCity(city))
                    })
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    getPracticeAreaById = (practiceAreaId) => {
        apiv1.noAuth.get(`/users/practisearea/?parent=${practiceAreaId}`)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp);
                if (data) {
                    this.setState({legalArea: data, loading: false})
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    validateForm = () => {
        const { legalId, cityId, errors} = this.state;

        if (!legalId){
            errors['legalId'] = 'Please select this field.'
        }
        this.setState({errors})
    }

    legalAreaUi = (legalArea, errors) => {
        return(
            <div className="col-sm-10">
                <div className="form-group">
                    <select className="form-control input-lg"
                            name='legalId'
                            onChange={(e) => {
                                const { errors } = this.state;
                                errors[e.target.name] = '';
                                this.setState({[e.target.name]: e.target.value, errors})
                            }}
                    >
                        <option selected={true} disabled>Select Legal Area</option>
                        {legalArea.map((practice => {
                            return <option value={practice.slug} key={practice.id}>{`${practice.name}`}</option>
                        }))}
                    </select>
                    <span className={errors.legalId ? 'error' : 'no-error'}>{errors.legalId}</span>
                </div>
            </div>
        )
    }

    render() {
        const { loading, legalArea, city, errors, productId, practiceAreaId} = this.state;
        const {history} = this.props
        if (loading){
            return (
                <div className="lazy-loader" style={{position: 'fixed', zIndex: '1' }}>
                    <LoadingComponent pastDelay />
                </div>
            )
        }
        return (
            <Fragment>
                <MetaTags>
                    <title>Legal Advice | Lawyers Near Me | Starting $630</title>
                    <meta name="title"
                          content="Legal Advice | Lawyers Near Me | Starting $630"/>
                    <meta name="description"
                          content="Book Legal Advice from verified Lawyers Online. Find a Lawyer according to your budget for Legal Notices and Legal Cases."/>
                    <meta name="url" content={`${baseUrl}/legal/legal-advice`}/>
                    <meta property="og:url" content={`${baseUrl}/legal/legal-advice`}/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:site_name" content="Workbeam"/>
                    <meta property="og:title"
                          content="Legal Advice | Lawyers Near Me | Starting $630"/>
                    <meta property="og:description"
                          content="Book Legal Advice from verified Lawyers Online. Find a Lawyer according to your budget for Legal Notices and Legal Cases."/>
                    <meta property="og:image"
                          content={siteLogoOnSharedMedia}/>
                    <meta name="twitter:card"
                          content="Book Legal Advice from verified Lawyers Online. Find a Lawyer according to your budget for Legal Notices and Legal Cases."/>
                    <meta name="twitter:site" content="Workbeam"/>
                    <meta name="twitter:title"
                          content="Legal Advice | Lawyers Near Me | Starting $630"/>
                    <meta name="twitter:description"
                          content="Book Legal Advice from verified Lawyers Online. Find a Lawyer according to your budget for Legal Notices and Legal Cases."/>
                    <meta name="twitter:image"
                          content={siteLogoOnSharedMedia}/>
                    <link rel="canonical" href={`${baseUrl}/legal/legal-advice`}/>
                </MetaTags>
                <section className="legal-filter-section">
                    <div className="container-fluid legal-filter-container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 className="text-center legal-filter-h1">Ab Legal Advice Easy and Affordable.</h1>
                                <h2 className="legal-filter-h2">Select karo apni Legal Problem and Book Karo verified Lawyers.</h2>
                                <h2 className="legal-filter-h2">Book consultation at only $630.</h2>
                            </div>
                            <div className="col-sm-6 col-sm-offset-3">
                            <form className="submit-form-query filter-city">
                                { this.legalAreaUi(legalArea, errors)}
                                <div className="col-sm-2 text-center">
                                        <div className="form-group">
                                    <span>
                                    <a className="btn btn-select-filter btn-lg"
                                       onClick={() => {
                                           const { legalId, cityId, productSlug, parentPracticeAreaSlug} = this.state;
                                           if (showLegalAreaForProduct.indexOf(productId) > -1){
                                               if (legalId ){
                                                   history.push(`/${keyWords.consultation}/${productSlug}/${parentPracticeAreaSlug}/${legalId}`)
                                                   // history.push(`${productPath[productId].path}?${path.product}=${productId}&&${path.legal}=${legalId}&&${path.city}=${cityId}&&${path.practiceAreaId}=${practiceAreaId}`)
                                               } else {
                                                   this.validateForm()
                                               }

                                           } else if (showLegalAreaForProduct.indexOf(productId) === -1){
                                               if (cityId){
                                                   // history.push(`${productPath[productId].path}?${path.product}=${productId}&&${path.city}=${cityId}&&${path.practiceAreaId}=${practiceAreaId}`)
                                               } else {
                                                   this.validateForm()
                                               }
                                           }
                                       }}
                                    >GO</a>
                                </span>
                                        </div>
                                    </div>
                            </form>
                            </div>
                            <div className="clearfix"></div>
                            <div className="col-sm-7 col-sm-offset-3">
                                <ul className="list-inline assurance-list">
                                    <li><span>&#10003;</span> Verified Experts</li>
                                    <li><span>&#10003;</span> Save 60% cost</li>
                                    <li><span>&#10003;</span> Escrow Payment</li>
                                    <li><span>&#10003;</span> Consultation Follow up</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="page-contant">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h4 className="heading-sm text-center">How It Works</h4>
                            </div>
                            <p className="hidden-xs">&#x00A0;</p>
                            <div className="col-sm-4 work-box-padding">
                                <div className="work-icon-box">
                                    <img src={HiredIcon} style={{width: '60px'}} alt="icon"/>
                                </div>
                                <h3 className="scope-sub-heading">Select Legal Area & Expert</h3>
                                <p className="xs-para">Select Legal category and then Select and hire the Expert based on price, location and profile comparison.</p>
                            </div>
                            <div className="col-sm-4 work-box-padding">
                                <div className="work-icon-box">
                                    <img src={CreditCard} style={{width: '70px'}} alt="icon"/>
                                </div>
                                <h3 className="scope-sub-heading">Schedule Consultation and make Escrow Payment</h3>
                                <p className="xs-para">Make Escrow Payment. Get Workbeam Payment Insurance. Your payment has a 100% Money-Back Guarantee and is given to the expert post your consultation is completed.</p>
                            </div>
                            <div className="col-sm-4 work-box-padding">
                                <div className="work-icon-box">
                                    <img src={ColoredBullseye} style={{width: '70px'}} alt="icon"/>
                                </div>
                                <h3 className="scope-sub-heading">Consult at the scheduled time.</h3>
                                <p className="xs-para">Share any documents and complete your consultation at the scheduled time.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="page-contant">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-10 col-sm-offset-1">
                                <h1 className="heading-sm text-center">Small Business Owners love us</h1>
                                <div id="public_reviews">
                                    <ProductReviews productId = {productId} userRating = {`4.8`} userCount= {`850`}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="page-contant" style={{paddingTop: '0px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-10 col-sm-offset-1">
                                <h4 className="heading-sm text-center">Don't worry, you're safe and in good hands.</h4>
                                <p className="hidden-xs">&#x00A0;</p>
                                <div className="col-sm-4 work-box-padding">
                                    <div className="work-icon-box">
                                        <img src={require("../../static/images/locked-credit-card.png")}
                                             style={{width: '60px'}} alt="private limited"/>
                                    </div>
                                    <h3 className="scope-sub-heading">Payment Protection</h3>
                                    <p className="xs-para">You're protected by 100% Workbeam payment Insurance. Get easy refunds on non delivery.</p>
                                </div>
                                <div className="col-sm-4 work-box-padding">
                                    <div className="work-icon-box">
                                        <img src={require("../../static/images/delivery-truck.png")} style={{width: '60px'}}
                                             alt="private limited"/>
                                    </div>
                                    <h3 className="scope-sub-heading">Track work Delivery</h3>
                                    <p className="xs-para">Track work delivery using milestones.</p>
                                </div>
                                <div className="col-sm-4 work-box-padding">
                                    <div className="work-icon-box">
                                        <img src={require("../../static/images/internet-logo.png")} style={{width: '60px'}}
                                             alt="private limited"/>
                                    </div>
                                    <h3 className="scope-sub-heading">Safety</h3>
                                    <p className="xs-para">Data and Payments are highly secured. 256-bit SSL encryption & ISO 27001 Datacenter</p>
                                </div>
                                <p className="hidden-xs">&#x00A0;</p>
                                <p className="hidden-xs">&#x00A0;</p>
                                <div className="row">
                                    <div className="col-sm-4 work-box-padding">
                                        <div className="work-icon-box">
                                            <img src={require("../../static/images/conversation.png")}
                                                 style={{width: '60px'}} alt="private limited"/>
                                        </div>
                                        <h3 className="scope-sub-heading">Communicate</h3>
                                        <p className="xs-para">Communicate using Workbeam. Chat, Audio or Video Call.</p>
                                    </div>
                                    <div className="col-sm-4 work-box-padding">
                                        <div className="work-icon-box">
                                            <img src={require("../../static/images/inventory.png")} style={{width: '60px'}}
                                                 alt="private limited"/>
                                        </div>
                                        <h3 className="scope-sub-heading">Share Files</h3>
                                        <p className="xs-para">Share important files for getting our work done.</p>
                                    </div>
                                    <div className="col-sm-4 work-box-padding">
                                        <div className="work-icon-box">
                                            <img src={require("../../static/images/live-chat.png")} style={{width: '60px'}}
                                                 alt="private limited"/>
                                        </div>
                                        <h3 className="scope-sub-heading">Dedicated Support</h3>
                                        <p className="xs-para">We assign you a dedicated support manager to help you
                                            throughout.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <FaqForAdd/>

                {/*<section>*/}
                {/*    <div className="container-fluid">*/}
                {/*        <div className="row">*/}
                {/*        <div className="col-sm-6 col-sm-offset-3">*/}
                {/*            <h1 className="text-center heading-sm">Let's find you a great lawyer. Please select the area of legal problem and location.</h1>*/}
                {/*            <form className="submit-form-query filter-city">*/}
                {/*                { showLegalAreaForProduct.indexOf(productId) > -1 && this.legalAreaUi(legalArea, errors)}*/}
                {/*                <div className="col-xs-12">*/}
                {/*                    <div className="form-group">*/}
                {/*                        <select className="form-control"*/}
                {/*                                name='cityId'*/}
                {/*                                onChange={(e) => {*/}
                {/*                                    const { errors } = this.state;*/}
                {/*                                    errors[e.target.name] = '';*/}
                {/*                                    this.setState({[e.target.name]: e.target.value, errors})*/}
                {/*                                }}*/}
                {/*                        >*/}
                {/*                            <option selected={true} disabled>Filter by City</option>*/}
                {/*                            {city.map((cityData => {*/}
                {/*                                return <option value={cityData.slug} key={cityData.id}>{`${cityData.name}, ${cityData.state_data.name}`}</option>*/}
                {/*                            }))}*/}
                {/*                        </select>*/}
                {/*                        <span className={errors.cityId ? 'error' : 'no-error'}>{errors.cityId}</span>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*                {practiceAreaId && productId &&*/}
                {/*                (<div className="text-center">*/}
                {/*                    <p>&#x00A0;</p>*/}
                {/*                    <a className="btn btn-login"*/}
                {/*                            onClick={() => {*/}
                {/*                                const { legalId, cityId, productSlug, parentPracticeAreaSlug} = this.state;*/}
                {/*                                if (showLegalAreaForProduct.indexOf(productId) > -1){*/}
                {/*                                    if (cityId ){*/}
                {/*                                        history.push(`/${productSlug}/${parentPracticeAreaSlug}/${legalId}/${cityId}`)*/}
                {/*                                        // history.push(`${productPath[productId].path}?${path.product}=${productId}&&${path.legal}=${legalId}&&${path.city}=${cityId}&&${path.practiceAreaId}=${practiceAreaId}`)*/}
                {/*                                    } else {*/}
                {/*                                        this.validateForm()*/}
                {/*                                    }*/}

                {/*                                } else if (showLegalAreaForProduct.indexOf(productId) === -1){*/}
                {/*                                    if (cityId){*/}
                {/*                                        // history.push(`${productPath[productId].path}?${path.product}=${productId}&&${path.city}=${cityId}&&${path.practiceAreaId}=${practiceAreaId}`)*/}
                {/*                                    } else {*/}
                {/*                                        this.validateForm()*/}
                {/*                                    }*/}
                {/*                                }*/}
                {/*                            }}*/}
                {/*                    >Done</a>*/}
                {/*                </div>)*/}
                {/*                }*/}
                {/*            </form>*/}
                {/*        </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ((state) => {
    return {city: state.state.city}
});

const mapDispatchToProps = (dispatch) => ({
    onGetCity: (action) => {
        dispatch(action)
    },
    ...(errorActionDispatchers(dispatch)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductOnBoarding)
