import { Component } from "react";
import React from "react";
import { errorActionDispatchers } from "../../actions/errors";
import renderHTML from 'react-render-html';
import connect from "react-redux/es/connect/connect";

class ProposalDetailsWithMilestone extends Component {
    render() {
        let { jobDetails, jobQuestions, jobMilestone } = this.props;
        jobMilestone = jobMilestone || []
        const {title, description, city_data, upper_tags} = jobDetails || {}
        return (
            <div>
                <div className="clearfix" />
                <p className="panel-text">Order Name</p>
                <div className="panel panel-default">
                    <div className="panel-body">
                        <p className="para">{title}</p>
                    </div>
                </div>
                {city_data &&
                <>
                    <p className="panel-text">City</p>
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <p className="para">{city_data.name}</p>
                        </div>
                    </div>
                </>
                }

                <p className="panel-text">Job description</p>
                <div className="panel panel-default">
                    <div className="panel-body">
                        <p className="para">
                            {renderHTML(description)}
                        {/*<Interweave*/}
                            {/*tagName="span"*/}
                            {/*content={description}*/}
                        {/*/>*/}
                        </p>
                    </div>
                </div>
                <div>
                    {/*{city_data  && <span style={{color: '#000'}} className={'li-practice-area'}  >{'City : ' + city_data.name}</span>}*/}
                    {/*{jobDetails.budget !== 'True' && <span className={'li-practice-area' + (jobDetails.city_data ? ' left-margin': '')}> { 'Budget:' + ' Rs ' + jobDetails.budget}</span>}*/}
                    {/*{avg_price && <span className={'li-practice-area' + (city_data ? ' left-margin': '')}> { avg_price ? 'Avg Market Price:' + ' Rs ' + avg_price : ''}</span>}*/}
                    {/**/}
                    {/*<br/>*/}
                    {/*<br/>*/}
                    {/*{document_file_name !== 'True' && <a style={{color: '#000'}} className={'li-practice-area' + ((budget !== "True" || city_data) ? ' ' : '')} href={document} target='_blank'>{document_file_name}</a>}*/}
                </div>
                {jobQuestions && jobQuestions.length > 0 && (
                    <div>
                        <p className="panel-text">Job details</p>
                        <div className="panel panel-default">
                            <div className="panel-body">
                                <ul className="list-inline about-expert-stats" style={{marginBottom:'20px'}}>
                                    {upper_tags && upper_tags.map((tags, index)=>{
                                        return(
                                            <li key={index}>
                                                <div className="e-s-bubble">{tags}</div>
                                            </li>
                                        )
                                    })}
                                </ul>
                                <ol>
                                    {jobQuestions.map((question, i) => {
                                        let {practise_area_question_details, answer} = question
                                        answer = answer || []
                                        const {id, text} = practise_area_question_details
                                        return <li key={id}>
                                            { text}
                                            {answer.map((data, answerIndex) => {
                                                return <div>
                                                    {data}
                                                    {answerIndex > 1 && <span>,</span>}
                                                </div>
                                            })}
                                        </li>
                                    })}
                                </ol>
                            </div>
                        </div>
                    </div>
                )
                }
                {jobMilestone.length > 0 && (
                    <div> <p className="panel-text">Job Milestones</p>
                        <div className="panel panel-default">
                            <div className="panel-body">
                                <ol>
                                    {jobMilestone.map((milestone, milestoneIndex) => {
                                        const {milestone_practise_area_details} = milestone
                                        const {id, text} = milestone_practise_area_details
                                        return (
                                            <li key={id}>{text} </li>
                                        )
                                    })}
                                </ol>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = (dispatch) => ({
    ...(errorActionDispatchers(dispatch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProposalDetailsWithMilestone)
