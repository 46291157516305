import React, {Component, Fragment} from "react";
import Attachment from './../../static/images/attachment.png'
import SendButton from './../../static/images/send-button.png'
import missedCallIcon from './../../static/images/missed-call.png'
import downloadIcon from './../../static/images/download-icon.png'
import socket from './../common/Socket'
import {errorActionDispatchers} from "../../actions/errors";
import connect from "react-redux/es/connect/connect";
import localStorage from './../../utils/localStorage'
import {PROFESSIONAL, USER} from "../../constants/auth";
import {apiv1} from "../../api";
// import TimeAgo from "react-timeago";
import $ from 'jquery'
import {Link} from "react-router-dom";
import zoomIcon from '../../static/images/zoom-icon.png';
import {
    getAvatar,
    getFirstAndLastLetterOfName,
} from "./userAndProfessionalProfileFunctions";
// import LoadingComponent from "../../utils/LoadingComponent";
import {FirebaseDocumentUploader} from "./UploadDocuments";
import {CustomToolTrip, GetFirstNameAndLastName, ParseHtmlToString} from "../../functions/common";
import ExotelCall from "../common/ExotelCall";
import {CreateProfessionalAccountPopUp} from "../user/GeneralOnboarding";
import {ratingUi} from "../functional/beforeHire";
import {getTypeOfData} from "../../functions/typeOfData";
import {
    agoraVideoCallRegex, agoraVoiceCallRegex,
    emailInAStringRegex,
    phoneNoInAStringRegex,
    urlRegex
} from './../common/validation/FormValidator'
import moment from "moment";
import {assignTaskToThisProfessional} from "../../constants/user";
// import popupMessages from "../../constants/popupMessages";
import * as apiUrl from "../../api/apiUrls";
import {superAdmin} from './../../constants/user'
import * as path from "../../constants/path";
import {
    messageSentFrom,
    messageType,
    exotelNumberToNumberCalled,
    callStatus,
    chatButtonType, Workbeam, call_type, media_type, incomingOutgoing, mainDomain
} from './../../constants/common'
// import {initFreshChat} from "../../functions/jqFuntions";
import chatImage from "../../static/images/colored-call-icon.png";
// import PickyDateTime from 'react-picky-date-time';
import CallScheduler from '../common/CallScheduler'
import InfiniteScroll from 'react-infinite-scroll-component'

// import {CallInstructionAfterHire, GigCallingRule, GigCallingRuleForUser} from "./ChatToAUser";

export function WhatHappenNextPopup({isWhatHappenNextPopupActive, removeWhatHappenNextPopupA, professionalDetails, clientDetails}) {
    const {id} = professionalDetails || {}
    const {first_name} = clientDetails || {}
    const {professionalId} = assignTaskToThisProfessional
    return (
        <div className="modal fade" id="how_to_use_wc" role="dialog">
            <div className="modal-dialog dialog-top-margin">
                <div className="modal-content">

                    <div className="modal-body" style={{padding: '10px 25px'}}>
                        <br/>
                        {
                            id === professionalId ?
                                <Fragment>
                                    <div className="text-center">
                                        <img src={require("../../static/images/tick-heart.png")}
                                             style={{width: '70px', marginBottom: '10px'}} alt="client-reviews"/>
                                        <h3 className="xs-para"><b>Hi {first_name}</b></h3>
                                        <h3 className="xs-para"><b>Thanks for Booking Service on Workbeam.</b></h3>
                                    </div>
                                    <h3 className="xs-para text-center">Your Expert will be assigned very soon. You will
                                        get a notification and email. Upload Information and documents in the
                                        meanwhile.</h3>
                                </Fragment>
                                :
                                <Fragment>
                                    <div className="text-center">
                                        <img src={require("../../static/images/tick-heart.png")} style={{width: '80px'}}
                                             alt="client-reviews"/>
                                    </div>
                                    <h3 className="xs-para text-center"><b>Thanks for hiring on Workbeam.</b></h3>
                                    <h3 className="xs-para text-center"><b>What will happen next:</b></h3>
                                    <ol className="information-li">
                                        <li>The Expert will message you back within a few hours.</li>
                                        <li>Start uploading documents mentioned in the <b>document checklist</b> under
                                            the <b>files</b> tab or on the chat.
                                        </li>
                                        {/* <li>Download <b>Workbeam Mobile App</b> to communicate with the Expert
                                            instantly. You can <b>call and chat both</b> using our app.
                                        </li> */}
                                        {/* <li> Remember <b>Workbeam payment protection & acknowledgement is applicable
                                            only when you use Workbeam App</b> for communicating and sharing files.
                                        </li> */}
                                    </ol>
                                </Fragment>
                        }
                    </div>
                    <div className="modal-footer" style={{border: 'none', textAlign: 'center'}}>
                        <button type="button"
                                className="btn btn-login"
                                data-dismiss="modal"
                                onClick={() => {
                                    if (removeWhatHappenNextPopupA) {
                                        removeWhatHappenNextPopupA()
                                    }
                                }}
                        >Okay
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function getTypeOfDocument(sentMessage) {
    const {message} = sentMessage
    let documentType = message.split(',,')[0].split('.')
    documentType = documentType[documentType.length - 1].toUpperCase()
    let documentName = message.split(';;')[1].split(',,')[0]
    let documentUrl = message.split('::;;,,..')
    if (documentUrl) {
        documentUrl = documentUrl[1]
    }
    documentName = <CustomToolTrip text={documentName} maxCharLength={20}/>
    switch (documentType) {
        case 'DOCS':
        case 'DOC':
        case 'DOCX':
            return <p className="uploaded_doc"><a
                href={documentUrl} target={'_blank'}
                style={{color: '#00a1f1', cursor: 'pointer', border: '1px solid #00a1f1'}}
                // onClick={() => {
                //     this.downLoadDocument(documentId, message_id)
                // }}
            >
                {documentName}
            </a>
            </p>;
        case 'PDF':
            return <p className="uploaded_doc"><a
                href={documentUrl} target={'_blank'}
                style={{color: '#f65314', cursor: 'pointer', border: '1px solid #f65314'}}
                // onClick={() => {
                //     this.downLoadDocument(documentId, message_id)
                // }}
            >{documentName}</a></p>;
        case 'JPG':
        case 'JPEG':
        case 'PNG':
            return <p className="uploaded_doc"><a
                href={documentUrl} target={'_blank'}
                style={{color: '#fed049', cursor: 'pointer', border: '1px solid #fed049'}}
                // onClick={() => {
                //     this.downLoadDocument(documentId, message_id)
                // }}
            >{documentName}</a></p>;
        case 'XLS':
        case 'XLSX':
            return <p className="uploaded_doc"><a
                href={documentUrl} target={'_blank'}
                style={{color: 'green', cursor: 'pointer', border: '1px solid green'}}
                // onClick={() => {
                //     this.downLoadDocument(documentId, message_id)
                // }}
            >{documentName}</a></p>;
        // case '.vnd.openxmlformats-officedocument.wordprocessingml.document'.toUpperCase():
        // case 'MSWORD':
        //     return <p className="uploaded_doc"><a style={{ color: 'blue', cursor: 'pointer', border: '1px solid blue' }}
        //         onClick={() => {
        //             this.downLoadDocument(documentId, sentMessage.message_id)
        //         }}
        //     >{documentName}</a></p>
        default:
            return (
                <p className="uploaded_doc"><a
                    href={documentUrl} target={'_blank'}
                    style={{color: '#00a1f1', cursor: 'pointer', border: '1px solid #00a1f1'}}
                    // onClick={() => {
                    //     this.downLoadDocument(documentId, message_id)
                    // }}
                >{documentName}</a>
                </p>
            )
    }
}

export function checkEmailAndPhoneNoInAText(text) {
    text = text.replace(emailInAStringRegex, '***EMAIL HIDDEN***')
    if (phoneNoInAStringRegex.test(text)) {
        text = text.replace(phoneNoInAStringRegex, '***PHONE NUMBER HIDDEN***')
    }
    return text
}

export function convertUnicode(input) {
    return input.replace(/\\u(\w\w\w\w)/g, function (a, b) {
        return String.fromCharCode(parseInt(b, 16))
    })
}

// export function checkHyperlink({singleChatData}) {
//     let {message, sent_from, type} = singleChatData
//     if (sent_from === messageSentFrom.ios) {
//         message = convertUnicode(message)
//     }
//     message = message || ''
//     if (type === messageType.document_message) {
//         return {
//             ...singleChatData,
//             message
//         }
//     }
//     return {
//         ...singleChatData,
//         message: message.replace(urlRegex, function (url) {
//             return `<a href=${url} target="_blank" >${url}</a>`
//         })
//     }
//
// }

export function checkButtons({singleChatData}) {
    let {message, sent_from, type} = singleChatData
    let button_type = null
    message = message || ''
    if (type === messageType.document_message) {
        return {
            ...singleChatData,
            message,
            button_type
        }
    } else if (type === messageType.chat_message) {
        let copyMessage = message.split('::')
        let customProductId = copyMessage[1]
        if (sent_from === messageSentFrom.ios) {
            message = convertUnicode(message)
        }
        if (copyMessage[0] && copyMessage.length === 6 && copyMessage[0].includes('offer')) {
            // button_type = chatButtonType.offer
            let productSlug = copyMessage[2].replace(/\s/g, '-')
            productSlug = productSlug.replace(/\//g, '-')
            productSlug = productSlug.replace(/\?/g, "-")
            message = `<strong>Custom Offer Created</strong>
                    <br/>    
                    Product Name: <strong>${copyMessage[2]}</strong>
                    <br/>
                    Fees: <strong>$${copyMessage[3]}</strong>
                    <br />
                    Description: 
                    <br/>
                    <br/>
                    ${copyMessage[4]}
                    <br/>
                    Delivery time: <strong>${copyMessage[5]}</strong> days
                    <br/>
                    <a class="btn btn-share-offer" href=${`${mainDomain}/for-you${path.gigStoreOffer}/${productSlug}?productId=${customProductId}`} target="_blank">See Full Offer & Book</a>    
                    `
        } else if (urlRegex.test(message)) {
            message = message.replace(urlRegex, function (url) {
                return `<a href=${url} target="_blank" >${url}</a>`
            })
        } else if (message.includes(agoraVoiceCallRegex) || message.includes(agoraVideoCallRegex)) {
            let msgArr = message.split('::::')
            let callType = msgArr[2]
            button_type = callType.toLowerCase()
            message = `Please join me for a ${button_type} call`
        }
    }
    return {
        ...singleChatData,
        message,
        button_type
    }

}

// export function checkOffer({singleChatData}) {
//     let {message} = singleChatData
//     let copyMessage = message.split('::')
//     let customProductId = copyMessage[1]
//     if (copyMessage[0] && copyMessage.length === 6 && copyMessage[0].includes('offer')) {
//         let productSlug = copyMessage[2].replace(/\s/g, '-')
//         message = `<strong>Custom Offer Created</strong>
//                     <br/>
//                     Product Name: <strong>${copyMessage[2]}</strong>
//                     <br/>
//                     Fees: <strong>Rs. ${copyMessage[3]}</strong>
//                     <br />
//                     Description:
//                     <br/>
//                     <br/>
//                     ${copyMessage[4]}
//                     <br/>
//                     Delivery time: <strong>${copyMessage[5]}</strong> days
//                     <br/>
//                     <a class="btn btn-share-offer" href=${`/for-you${path.gigStoreOffer}/${productSlug}?productId=${customProductId}`} target="_blank">See Full Offer & Book</a>
//                     `
//     }
//     return {
//         ...singleChatData,
//         message
//     }
// }

export function getCallDuration({DateCreated, DateUpdated, DialCallDuration}) {
    let callDuration = DialCallDuration ? parseInt(DialCallDuration) : (moment(DateUpdated).diff(moment(DateCreated))) / 1000
    let m = parseInt(callDuration / 60)
    let s = parseInt(callDuration % 60)
    return `${m}m ${s}s`
}


export function callTypeMessage({sentMessage}) {
    const {audio_call_detail, message_id} = sentMessage || {}
    let {DateCreated, DateUpdated, RecordingUrl, Status, DialCallDuration, Direction} = audio_call_detail
    let callDuration = ''
    if ((Direction === exotelNumberToNumberCalled.web_call && Status === callStatus.completed) || (Direction === exotelNumberToNumberCalled.phone_call && RecordingUrl) || (Direction === exotelNumberToNumberCalled.agora_voice && Status === callStatus.completed) || (Direction === exotelNumberToNumberCalled.agora_video && Status === callStatus.completed)) {
        Status = 'success'
        callDuration = getCallDuration({DateCreated, DateUpdated, DialCallDuration})
    }
    switch (Status) {
        case 'success':
            return (
                <p className="missed-call">
                        <span>
                        <a href={RecordingUrl || null}
                           target="_blank">
                            {RecordingUrl && <img src={downloadIcon} alt="file-icon"
                                                  style={{width: '20px', height: 'auto', borderRadius: '0'}}/>}
                        </a>
                            {` Audio call duration: ${callDuration}`}
                        </span>
                </p>
            )
        default:
            return (
                <p className="missed-call">
                        <span>
                        <a>
                            <img src={missedCallIcon} alt="file-icon"
                                 style={{width: '20px', height: 'auto', borderRadius: '0'}}/>
                        </a>
                            {` Missed call`}
                        </span>
                </p>
            )
    }

}

export function zoomCallTypeMessage({sentMessage}) {
    return (
        <p className="missed-call">
                <span>
                 <a  target="_blank" onClick={()=>{
                        var dummy = document.createElement("input");
                        document.body.appendChild(dummy);
                        dummy.setAttribute("id", "copy_pass");
                        document.getElementById("copy_pass").value = sentMessage.zoom_call_detail.recording_passcode
                        dummy.select();
                        document.execCommand("copy")
                        document.body.removeChild(dummy)
                        alert("Password Copied")
                        window.open(sentMessage.zoom_call_detail.recording_vimeo_url, '_blank')
                   }}>
                    {sentMessage.zoom_call_detail.recording_vimeo_url && <img src={downloadIcon} alt="file-icon"
                                          style={{width: '20px', height: 'auto', borderRadius: '0'}}/>}
                </a>
                    {/* {`  call duration: ${callDuration}`} */}
                    {`  Call Recording, Pass: ${sentMessage.zoom_call_detail.recording_passcode}`}
                </span>
        </p>
    )
}


export function getSocketRequestData({message, type}) {
    return {
        message,
        type,
        sent_from: messageSentFrom.web
    }
}

class Message extends Component {
    constructor(props) {
        super(props);
        let {senderId, proposalId, jobId, proposalAmount, chatRoomId} = props
        senderId = getTypeOfData({type: Number, value: senderId})
        proposalId = getTypeOfData({type: Number, value: proposalId})
        jobId = getTypeOfData({type: Number, value: jobId})
        this.socket = ''
        this.state = {
            messageToSend: '',
            receivedMessage: '',
            senderId,
            receiverId: '',
            proposalId,
            chatRoomId,
            jobId,
            inputMessage: '',
            chatHistory: [],
            page: 1,
            limit: 10,
            newSessionChatHistory: [],
            total: 0,
            loading: false,
            proposalAmount,
            isWhatHappenNextPopupActive: false,
            isPaidCallPopupActive: false,
            chatRoomUsers: [],
            isMessageSent: true,
            isSocketConnected: false,
            errors: {},
            zoomCallDetails:{}
        }
    }

    componentDidMount() {
        const {isSomeoneHired} = this.props
        if (isSomeoneHired !== undefined && !isSomeoneHired) {
            this.addEventListenerOnKeyDown();
        }
        this.getChatHistory({page: 1, loading: true})
        this.refreshChatInterval = setInterval(() => {
            this.getChatHistory({page: 1, isAutoRefresh: true, loading: true})
        }, 10000)
        this.addWindowEventListener()
    }

    componentWillUnmount() {
        clearInterval(this.refreshChatInterval)
        this.closeSocket()
    }

    addWindowEventListener = () => {
        $(function () {
            $('.chat_area').css({
                'height': (($(window).height()) - 310) + 'px'
            });
            $(window).bind('resize', function () {
                $('.chat_area').css({
                    'height': (($(window).height()) - 310) + 'px'
                });
            });
        });
    }

    initializeSocket = ({isReinitializing}) => {
        const {jobId, proposalId, senderId} = this.state
        const {is_super_user} = this.props
        if (is_super_user) {
            return
        }
        this.socket = socket.startSocketConnection({
            jobId,
            proposalId,
            counterPartyUserId: senderId,
            onMessage: this.onMessage,
            // onConnectionError: this.onSocketConnectionError,
            onSocketOpen: isReinitializing ? this.sendSocketData : this.onSocketConnected,
            // onConnectionLost: this.onSocketConnectionLost,
        })
    };

    addEventListenerOnKeyDown = () => {
        let input = document.getElementById("test_msg_input")
        if (input) {
            input.addEventListener("keyup", function (event) {
                event.preventDefault();
                const {shiftKey, keyCode} = event
                if (shiftKey) {
                    return
                }
                if (keyCode === 13) {
                    document.getElementById("send_message_btn").click();
                }
            })
        }
    };

    // addScrollEventListener = () => {
    //     let self = this
    //     $('.chat_area').bind('scroll', function () {
    //         console.log('$(this).scrollTop()', $(this).scrollTop())
    //         let pos = $('.chat_area').scrollTop()
    //         console.log('$(this).innerHeight()', $(this).innerHeight())
    //         console.log('(this).scrollTop()', $(this).scrollTop())
    //         if ($(this).scrollTop() < $(this).innerHeight()) {
    //             const {limit, total, page} = self.state
    //             if (total > page * limit) {
    //                 self.getChatHistory({page: page + 1, loading: false, isNextPage: true})
    //             }
    //         }
    //     })
    // }

    // getNextPageHistory = () => {
    //     let pos = $('.chat_area').scrollTop();
    //     if (pos === 0) {
    //         const {page, total, limit} = this.state;
    //         if (total > page * limit) {
    //             this.getChatHistory({page: page + 1, isNextPage: true})
    //         }
    //     }
    // };

    updateProposalAmountOnChat = (proposal_amount) => {
        this.setState({proposalAmount: proposal_amount}, () => {
            if (this.props.updateAmountOnJobPaymentSummary !== undefined) {
                this.props.updateAmountOnJobPaymentSummary(proposal_amount)
            }
        })
    };

    setLoading = () => {
        this.setState((prevState) => ({loading: !prevState.loading}))
    }

    sendAgoraCallNotification = ({callType, isSendingNotification}) => {
        const {logedInUserDetails, doDispatch, chatRoomId, professionalDetails, clientDetails} = this.props
        const {senderId} = this.state
        const {first_name, last_name, avatar, clientUserId} = logedInUserDetails
        let text = `${Workbeam} ${callType === call_type.voiceCall ? media_type.audio : media_type.video} call`
        let label = `You have a call from ${first_name} ${last_name}`
        let channel = `agora_${clientDetails.id}_${professionalDetails.user_id}`
        let type = callType === call_type.voiceCall ? call_type.audioCall : call_type.videoCall
        let counter_first_name = professionalDetails.first_name
        let counter_last_name = professionalDetails.last_name
        let counter_avatar = professionalDetails.avatar
        if (localStorage.userType === PROFESSIONAL) {
            counter_first_name = clientDetails.first_name
            counter_last_name = clientDetails.last_name
            counter_avatar = clientDetails.avatar
        }
        let request = {
            user: senderId,
            data: {
                room_id: chatRoomId,
                counter_user_id: senderId,
                text,
                type,
                label,
                channel,
                user_name: `${first_name} ${last_name}`,
                user_image: avatar,
                my_first_name: first_name,
                my_last_name: last_name,
            }
        }
        let url = `${path.meeting}?calltype=${type.toLowerCase()}&type=${incomingOutgoing.outgoing}&channel=${channel}&chat_room_id=${chatRoomId}&counter_first_name=${counter_first_name}&counter_last_name=${counter_last_name}`
        let client_and_pro_id = `${clientDetails.id}_${professionalDetails.user_id}`
        if (counter_avatar) {
            url += `&counter_avatar=${counter_avatar}`
        }
        if (isSendingNotification) {
            apiv1.auth.post(apiUrl.agoraCallNotification, request)
                .then(resp => {

                    let inputMessage = callType === call_type.videoCall ? `${agoraVideoCallRegex}${client_and_pro_id}` : `${agoraVoiceCallRegex}${client_and_pro_id}`
                    this.setState({inputMessage}, () => {
                        this.sendSocketData()
                        window.open(url)
                    })
                })
                .catch(err => {
                    apiv1.handleErrors(err, doDispatch)
                })
        } else {
            window.open(url)
        }
    }

    startZoomCall = ({callType, isSendingNotification}) => {

        const {chatRoomId, professionalDetails, clientDetails} = this.props
        this.setState({loading: true})
        let userId = 0
        if (localStorage.userType === PROFESSIONAL) {
            userId = clientDetails.id
        }
        else{
            userId = professionalDetails.user_id
        }
        const url = `/chat/create-zoom-meeting/${userId}/${chatRoomId}/`;
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    this.setState({zoomCallDetails: data, loading: false})
                    window.open(data.zoom.start_url, '_blank')
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }


    getChatHistory = ({page, isNextPage, loading, isAutoRefresh}) => {
        const {limit, senderId, chatRoomId} = this.state;
        const {doDispatch, is_super_user} = this.props
        let url = apiUrl.getAdminChatHistory
        url += `?room=${chatRoomId}`
        // let url = `${apiUrl.getUserAndProChatHistory}`
        // if (!is_super_user) {
        //     url += `?user=${senderId}`
        // } else {
        //     url = apiUrl.getAdminChatHistory
        //     url += `?room=${chatRoomId}`
        // }
        url += `&page=${page}&limit=${limit}`
        if (loading) {
            this.setState({loading: true});
        }
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch)
                if (data) {
                    let {results, currentPage, count} = data
                    results = results.map(singleChatData => {
                        return checkButtons({singleChatData})
                    })
                    if (page === 1) {
                        this.initializeSocket({isReinitializing: false})
                    }
                    // results = results.sort((a, b) => (a.message_id > b.message_id) ? 1 : ((b.message_id > a.message_id) ? -1 : 0))
                    let chatHistory = [...this.state.chatHistory]
                    let isNewMessage = false
                    if (isNextPage || isAutoRefresh) {
                        results.forEach(messageDetails => {
                            const {message_id} = messageDetails
                            let indexOfMsg = chatHistory.findIndex(mes => mes.message_id === message_id)
                            let isNotFound = indexOfMsg < 0
                            if (isNotFound) {
                                isNewMessage = isNotFound
                                if (isNextPage){
                                    chatHistory.push({...messageDetails})
                                } else {
                                    chatHistory = [{...messageDetails}].concat(chatHistory)
                                }
                            }
                        })
                        // chatHistory = chatHistory.sort((a, b) => (a.message_id > b.message_id) ? 1 : ((b.message_id > a.message_id) ? -1 : 0))
                    } else {
                        chatHistory = results
                    }
                    let newState = {
                        chatHistory,
                        newSessionChatHistory: [],
                        total: count,
                    }
                    if (page !== 1) {
                        newState.page = currentPage
                    }
                    if (loading) {
                        newState.loading = false
                    }
                    this.setState(newState, () => {
                        if ((isNewMessage && !isNextPage) || !(isNextPage || isAutoRefresh)) {
                            $('.chat_area').animate({scrollTop: 200000}, 'slow')
                        }
                    })
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, doDispatch)
            })
    }

    onSocketConnected = () => {
        this.setState({loading: false, isSocketConnected: true})
    }

    onSocketConnectionLost = () => {
        this.setState({loading: false, isSocketConnected: false})
    }
    onSocketConnectionError = () => {
        this.setState({loading: false, isSocketConnected: false})
    }

    closeSocket = () => {
        if (this.socket) {
            this.socket.close()
        }
    }

    updateChatHistory = ({newSocketData}) => {
        const chatHistory = [{...newSocketData}].concat([...this.state.chatHistory])
        // chatHistory.push(newSocketData)
        this.setState({chatHistory}, () => {
            $('.chat_area').animate({scrollTop: 200000}, 'slow')
        })
    }

    sendSocketDataViaRoomId = ({data}) => {
        const {type, message} = data
        const {chatRoomId} = this.state
        const request = {
            room: chatRoomId,
            chat: {
                message,
                message_type: type
            }
        }
        apiv1.auth.post(apiUrl.insertSocketData, request)
            .then(resp => {
                let data = apiv1.parseAndAutoHandleIssues(resp)
                data = checkButtons({singleChatData: data})
                const errors = {...this.state.errors}
                errors['inputMessage'] = ''
                let newState = {
                    chatHistory: [{...data}].concat([...this.state.chatHistory]),
                    inputMessage: '',
                    isMessageSent: true
                }
                this.setState(newState, () => {
                    $('.chat_area').animate({scrollTop: 200000}, 'slow')
                })
            })
            .catch(err => {
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    sendSocketData = () => {
        let {inputMessage, errors, isMessageSent, isSocketConnected, jobId } = this.state
        if (inputMessage.trim()) {
            if (isMessageSent) {
                this.setState({isMessageSent: false}, () => {
                    if (inputMessage.charCodeAt(inputMessage.length - 1) === 10) {
                        inputMessage = inputMessage.substring(inputMessage.length - 1, 0)
                    }
                    /**
                     * Overriding msg with Object
                     */
                    if(typeof(inputMessage) == 'string') {
                        try {
                            inputMessage = JSON.stringify({ message: inputMessage, job:  jobId });
                        } catch(e) {
                            console.log(':: Error making Json Object');
                        }
                    }
                    // inputMessage = checkEmailAndPhoneNoInAText(inputMessage)
                    const data = getSocketRequestData({message: inputMessage, type: messageType.chat_message})
                    if (isSocketConnected) {
                        this.socket.socket.send(JSON.stringify(data))
                    } else {
                        this.sendSocketDataViaRoomId({data})
                    }
                    $('#test_msg_input').focus()
                })
            } else {
                errors['inputMessage'] = 'Please wait for some time and try again'
                this.setState({errors})
            }
        }
    }

    onSendMessage = (e) => {
        e.preventDefault()
        let {inputMessage, isMessageSent} = this.state;
        if (inputMessage.trim()) {
            if (isMessageSent) {
                this.sendSocketData()
            } else {
                this.closeSocket()
                this.initializeSocket({isReinitializing: true})
            }
        }
    }

    onMessage = (event) => {
        let data = JSON.parse(event.data)
        const {sender_id} = data
        let {senderId} = this.state
        data = checkButtons({singleChatData: data})
        let newState = {
            newSessionChatHistory: [...this.state.newSessionChatHistory].concat([{...data}])
        }
        if (sender_id !== senderId) {
            const errors = {...this.state.errors}
            newState.inputMessage = ''
            newState.isMessageSent = true
            errors['inputMessage'] = ''
        }
        $('.chat_area').animate({scrollTop: 200000}, 'slow');
        this.setState(newState)
    }

    parseMessage = (msg) => {
        try {
            let obj = JSON.parse(msg);
            return obj;
        } catch (e) {
            return { message: msg, job: '-1' };
        }
    }

    getTextMessage = (sentMessage) => {
        const {type, message, sender_id, button_type} = sentMessage
        switch (type) {
            case messageType.chat_message:
                if (button_type === chatButtonType.video || button_type === chatButtonType.voice) {
                    return (
                        <Fragment>
                            <span>{this.parseMessage(message)?.message}</span>
                            <br/>
                            <a className="btn btn-zoom-call" style={{marginTop: '10px'}}
                               onClick={() => this.sendAgoraCallNotification({callType: button_type === chatButtonType.video ? call_type.videoCall : call_type.voiceCall})}
                            >{`Join Meeting`}</a>
                        </Fragment>
                    )
                }
                return (
                    <div className={sender_id === superAdmin.userId ? `notification-error` : `para`}
                         style={{width: `${sender_id === superAdmin.userId ? '50%' : ' '}`}}>
                        <ParseHtmlToString htmlContent={this.parseMessage(message)?.message}/>
                    </div>
                )
            case messageType.document_message:
                return (
                    <Fragment>
                        {getTypeOfDocument(sentMessage)}
                    </Fragment>
                )
            case messageType.audio_miss_call_message:
            case messageType.audio_call_message:
            case messageType.audio_call_blank:
            case messageType.video_call_message:
            case messageType.video_miss_call_message:
                return (
                    <div className="text-center">
                        {callTypeMessage({sentMessage})}
                    </div>
                )
            case messageType.zoom_call_message:
                return (
                    <div className="text-center">
                        {zoomCallTypeMessage({sentMessage})}
                    </div>
                )
            default:
                return ''
        }
    };

    timeAgoFormatter = (value, unit, suffix) => {
        if (unit !== 'second') {
            return [value, unit + (value !== 1 ? 's' : ''), suffix].join(' ')
        }

        if (suffix === 'ago') {
            return 'a few seconds ago'
        }

        if (suffix === 'from now') {
            return 'in a few seconds'
        }
    };

    shouldShowMsg = (receivedMessage) => {
        // check it is related to the same chat or not. 
        let obj = this.parseMessage(receivedMessage?.message);
        return Number(obj?.job) > 0 ? obj?.job == this.state.jobId : true;
    }   

    singleChatUi = (receivedMessage) => {
        const {clientDetails, professionalDetails} = this.props
        const {user_id} = professionalDetails || {}
        // let {chatRoomUsers} = this.state
        let avatar = '';
        let firstName = '';
        let lastName = '';
        let {message_id, sender_id, created, audio_call_detail} = receivedMessage
        if (clientDetails.id === sender_id) {
            firstName = clientDetails.first_name;
            lastName = clientDetails.last_name;
            avatar = getAvatar(clientDetails.client.avatar)
        } else if (sender_id === user_id) {
            firstName = professionalDetails.first_name;
            lastName = professionalDetails.last_name;
            avatar = getAvatar(professionalDetails.avatar)
        } else {
            firstName = receivedMessage?.sender_name?.split(' ')[0];
            lastName = receivedMessage?.sender_name?.split(' ')[1];
            avatar = null
        }
        return (

            (sender_id === superAdmin.userId || audio_call_detail) ?
                <div className={'text-center'}>
                    {this.getTextMessage(receivedMessage)}
                    <p className="time"
                       style={{float: 'none', borderBottom: '1px solid #e8eef3', paddingBottom: '5px'}}>
                        {moment(created).format('lll')}
                    </p>
                </div>
                :
                <div className="media" key={message_id}>
                    {
                        this.shouldShowMsg(receivedMessage) ? 
                        <>
                            <div className="media-left">
                            {avatar && avatar !== 'null' && avatar !== 'undefined' && !avatar.includes('imagekit') ? <img src={avatar} alt="user" className="img-circle"/>
                                :
                                getFirstAndLastLetterOfName(firstName, lastName)
                            }
                            </div>
                            <div className="media-body" key={message_id}>
                                <h4 className="media-heading">
                                    <GetFirstNameAndLastName firstname={firstName} lastname={lastName}/> <span
                                    className="time">{moment(created).format('lll')}</span>
                                </h4>
                                {/* <span>M: {receivedMessage?.message}</span> */}
                                {  this.getTextMessage(receivedMessage)}
                            </div>
                        </>
                        : ''
                    }
                </div>

        )
    };

    internetCallingUi = () => {
        return (
            <div id="voice_calling_popup" className="modal fade center-modal" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button"
                                    className="close"
                                    data-dismiss="modal"

                            >
                                &times;</button>
                            <h4 className="modal-title">Make Calls and Zoom Meetings using Workbeam</h4>
                            {/*<h4 className="modal-title">Do calls using Zoom Meetings on Workbeam</h4>*/}
                        </div>
                        <div className="modal-body" style={{padding: '15px 15px'}}>
                            <div className="calling-rule">
                                <div className="calling-rule-box"
                                     style={{borderRight: '2px solid #e8eef3'}}
                                >
                                    <h4 className="scope-sub-heading text-center">Use Zoom Meeting</h4>
                                    <div className="text-center">
                                        <img src={zoomIcon} alt={`Use Zoom Meeting`}/>
                                    </div>
                                    <ul>
                                        <li>Make sure you have good Wfi or 4G</li>
                                        <li>All calls are recorded for internal monitoring, policy violations and training purposes</li>
                                        <li>Zoom Chats are recorded for transparency and policy violations</li>
                                        <li>When you start the meeting the other party gets a Joining Link on Workbeam Chat </li>
                                    </ul>
                                        {/* Commented by Abdhesh for Agora calling need to implement zoom call  */}
                                    {/* <a className="btn btn-internet-call"
                                       onClick={() => {
                                           this.sendAgoraCallNotification({
                                               callType: call_type.voiceCall,
                                               isSendingNotification: true
                                           })
                                           $('#voice_calling_popup .close').click()
                                       }
                                       }
                                    >
                                        Start Meeting
                                    </a> */}

                            <div className="text-center">
                                <a className="btn btn-internet-call" onClick={() => {
                                           this.startZoomCall({
                                               callType: call_type.voiceCall,
                                               isSendingNotification: true
                                           })
                                           $('#voice_calling_popup .close').click()
                                        }}>
                                        Start Zoom Meeting
                                </a>
                                </div>
                                </div>
                                <div className="calling-rule-box">
                                    <h4 className="scope-sub-heading">Use Normal Sim Card Call</h4>
                                    <div className="text-center hidden-xs" style={{visibility:'hidden'}}>
                                        <img src={zoomIcon} alt={`Use Zoom Meeting`}/>
                                    </div>
                                    <ul>
                                        <li>Make sure you are in a network area</li>
                                        <li>All calls are recorded for internal monitoring, policy violations and training purposes</li>
                                        <li>You will first receive a call from our Virtual Number, please receive it</li>
                                        <li>Workbeam uses Virtual Masked Number to protect number privacy</li>
                                    </ul>
                                    <a className="btn btn-normal-call"
                                       style={{margin: '0px 5px'}}
                                       onClick={() => {
                                           $('#paid_call').click()
                                           $('#voice_calling_popup .close').click()
                                       }}
                                       target='_blank'>
                                        Call Now
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    videoCallPopupUi = () => {
        return (
            <div id="video_calling_popup" className="modal fade center-modal" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button"
                                    className="close"
                                    data-dismiss="modal"

                            >
                                &times;</button>
                            <h4 className="modal-title">Video call instruction</h4>
                        </div>
                        <div className="modal-body" style={{padding: '15px 15px'}}>
                            <div className="calling-rule">
                                <div className="calling-rule-box" style={{width: '100%'}}>
                                    <h4 className="scope-sub-heading">Video Call</h4>
                                    <ul className="list-unstyled">
                                        <ul className="list-styled">
                                            <li>Calls are recorded for internal audit & training purposes</li>
                                            <li>Communicating outside Workbeam revokes refund and payment protection</li>
                                            <li>Communicating outside Workbeam leads to suspension of Seller's Account
                                            </li>
                                        </ul>
                                    </ul>
                                    <a className="btn btn-internet-call"
                                       onClick={() => {
                                           this.sendAgoraCallNotification({
                                               callType: call_type.videoCall,
                                               isSendingNotification: true
                                           })
                                           $('#video_calling_popup .close').click()
                                       }
                                       }
                                    >
                                        Select
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // internetCallingUi = () => {
    //     return(
    //         <div id="internet_calling_popup" className="modal fade" role="dialog">
    //             <div className="modal-dialog">
    //                 <div className="modal-content">
    //                     <div className="modal-header">
    //                         <button type="button"
    //                                 className="close"
    //                                 data-dismiss="modal"
    //
    //                         >
    //                             &times;</button>
    //                         <h4 className="modal-title">Upload Files</h4>
    //                         <p className="para" style={{marginBottom:'0px',marginTop:'10px', marginLeft:'20px'}}>You have more than 1 Order attached to this Chat.  Please select the Order Name to which the files belong. </p>
    //                     </div>
    //                     <div className="modal-body" style={{padding: '15px 10px'}}>
    //                         <a className="btn btn-link"
    //                            style={{margin:'0px 5px'}}
    //                            onClick={() => {
    //                                this.sendAgoraCallNotification({callType: call_type.videoCall, isSendingNotification: true})
    //                            }
    //                            }
    //                         >
    //                             <img src={require('../../static/images/Video-call.png')} style={{width: '30px'}}
    //                                  alt="call icon"/>
    //                         </a>
    //                         <a className="btn btn-link"
    //                            style={{margin: '0px 5px'}}
    //                            onClick={() => {
    //                                this.sendAgoraCallNotification({callType: call_type.voiceCall, isSendingNotification: true})
    //                            }
    //                            }
    //                         >
    //                             Voice call
    //                         </a>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    messages = () => {
        const {chatHistory, receiverId, newSessionChatHistory, inputMessage, loading, jobId, senderId, proposalId, chatRoomId, errors, total, page} = this.state;
        const {professionalDetails, clientDetails, isSomeoneHired, from, doDispatch} = this.props
        const ishired = this.props.ishired === 'true';
        let avatar = '';
        let firstName = '';
        let lastName = '';
        let href = '';
        if (localStorage.userType === USER) {
            href = `${mainDomain}/professional/${professionalDetails.slug_new}`
        }
        if (localStorage.userType === USER && professionalDetails.avatar !== undefined) {
            avatar = getAvatar(professionalDetails.avatar);
            firstName = professionalDetails.first_name;
            lastName = professionalDetails.last_name;
        } else if (localStorage.userType === PROFESSIONAL && clientDetails.client !== undefined) {
            firstName = clientDetails.first_name;
            lastName = clientDetails.last_name;
            avatar = getAvatar(clientDetails.client.avatar);
        }
        return (
            <div className="MESSAGES" style={{marginTop: '0px'}}>
                <div className="row">
                    <div className="col-sm-12 chat_with_cunsultant" style={{marginTop: '0px'}}>
                        <div className="panel panel-default box-shadow">
                            <div className="panel-body">
                                {/*{loading && (*/}
                                {/*<div className='lazy-loader' style={{position: 'fixed', zIndex: '100'}}>*/}
                                {/*<LoadingComponent pastDelay/></div>)*/}
                                {/*}*/}
                                {!ishired &&
                                (<div className="proposal-chat-head">
                                      <ul className="list-inline">
                                          <li>
                                                {(avatar) ? (localStorage.userType === USER ? (
                                                        <a href={href} target='_blank'>
                                                            <img src={avatar} alt="user" className="img-circle dp-image"/>
                                                        </a>
                                                    ) : (<a>
                                                        <img src={avatar} alt="user" className="img-circle dp-image"/>
                                                    </a>))
                                                    :

                                                    (localStorage.userType === USER ?
                                                            (<a href={href} target='_blank'>
                                                                    {getFirstAndLastLetterOfName(firstName, lastName)}
                                                                </a>
                                                            )
                                                            :
                                                            (<a>
                                                                    {getFirstAndLastLetterOfName(firstName, lastName)}
                                                                </a>
                                                            )
                                                    )
                                                }
                                          </li>
                                          <li>
                                              {localStorage.userType === USER ?
                                                  (<h4  className="expert-name" style={{color:'#fff'}}>
                                                      <GetFirstNameAndLastName firstname={firstName}
                                                                               lastname={lastName}/>

                                                  </h4>)
                                                  :
                                                  (<h4  className="expert-name" style={{color:'#fff'}}>
                                                      <GetFirstNameAndLastName firstname={firstName}
                                                                               lastname={lastName}/>
                                                  </h4>)
                                              }
                                          </li>
                                            </ul>
                                            <ul className="list-inline">
                                                <li>
                                                {!ishired &&
                                                (<span className="text-INR">INR {this.state.proposalAmount}</span>
                                                )
                                                }
                                                </li>
                                                <li style={{marginLeft:'25px'}}>
                                                    {!ishired && localStorage.userType === USER &&
                                                    (
                                                        <Link className="btn btn-pink"
                                                              to={`/job-hiring/${this.props.jobId}/${this.props.proposalId}/summary`}>
                                                            HIRE
                                                        </Link>
                                                    )
                                                    }
                                                </li>
                                            </ul>
                                </div>)
                                }
                                <div className="col-sm-12" style={{padding: '0px', paddingBottom: '20px'}}>
                                    <div
                                        id="scrollableDiv"
                                        className='chat_area cunsult-chat-box'
                                        style={{
                                            overflow: 'auto', display: 'flex', flexDirection: 'column-reverse'
                                        }}
                                    >
                                        <InfiniteScroll
                                            dataLength={chatHistory.length}
                                            next={() => this.getChatHistory({
                                                page: page + 1,
                                                isNextPage: true,
                                                loading: true
                                            })}
                                            style={{display: 'flex', flexDirection: 'column-reverse'}}
                                            inverse={true}
                                            hasMore={total > chatHistory.length}
                                            loader={loading && <h4 className='text-center'>Loading...</h4>}
                                            scrollableTarget="scrollableDiv"
                                        >
                                            {chatHistory.map((chat, index) => {
                                                const {id} = chat
                                                return <Fragment key={id}>
                                                    {this.singleChatUi(chat)}
                                                </Fragment>
                                            })}
                                        </InfiniteScroll>
                                    </div>
                                    {newSessionChatHistory.map((chat, index) => {
                                        const {id} = chat
                                        return <div key={id}>
                                            <div>
                                                {this.singleChatUi(chat)}
                                            </div>
                                        </div>
                                    })}

                                    {/*<div className="chat_area cunsult-chat-box"*/}
                                    {/*// onScroll={this.getNextPageHistory}*/}
                                    {/*>*/}
                                    {/*{chatHistory.map(chat => {*/}
                                    {/*return this.singleChatUi(chat)*/}
                                    {/*})}*/}
                                    {/*{newSessionChatHistory.map(chat => {*/}
                                    {/*return this.singleChatUi(chat)*/}
                                    {/*})}*/}
                                    {/*</div>*/}
                                    {isSomeoneHired !== undefined && !isSomeoneHired ?
                                        <Fragment>
                                            <div className="clearfix"/>
                                            <div className="col-sm-12"
                                                 style={{borderTop: '1px solid #e8eef3', padding: '0px'}}>
                                                <div className="row">
                                                    <div className="col-sm-4 col-xs-8 col-sm-offset-1">
                                                        <ul className="ul-upload-info">
                                                            <li>
                                                                <label className="btn-bs-file"
                                                                       style={{cursor: 'pointer'}}>
                                                                    <FirebaseDocumentUploader
                                                                        jobId={jobId}
                                                                        receiverId={receiverId}
                                                                        senderId={senderId}
                                                                        chatRoomId={chatRoomId}
                                                                        socket={this.socket}
                                                                        setLoading={this.setLoading}
                                                                        doDispatch={doDispatch}
                                                                        needToSendOnChat={true}
                                                                        updateChatHistory={this.updateChatHistory}
                                                                    >
                                                                        <a className="btn btn-link"> <img
                                                                            src={Attachment}
                                                                            width="25px"
                                                                            alt="attech-file"/></a>
                                                                    </FirebaseDocumentUploader>
                                                                </label>

                                                            </li>
                                                            <li>
                                                                <Fragment>
                                                                    <span id="call-icon">
                                                                         <a className="btn btn-link"
                                                                            style={{margin: '0px 5px'}}
                                                                            onClick={() => {
                                                                                $('#voice_calling').click()
                                                                            }}
                                                                         >
                                                                            <img src={chatImage} style={{width: '20px'}}
                                                                                 alt="call icon"/>
                                                                        </a>
                                                                    <a
                                                                        className="btn btn-link"
                                                                        style={{margin: '0px 5px'}}
                                                                        onClick={() => {
                                                                            $('#schedule_call').click()
                                                                        }}> <div className="ui-tooltip">
                                                                        <img
                                                                            src={require('../../static/images/schedule_icon.png')}
                                                                            style={{width: '20px'}}
                                                                            alt="call icon"/>
                                                                        <span className="tooltiptext hidden-xs">you can schedule your call with {localStorage.userType === USER ? 'expert' : 'client'}</span>
                                                                    </div>
                                                                         </a>
                                                                </span>
                                                                </Fragment>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <ExotelCall from={from}
                                                                proposalId={proposalId}
                                                                jobId={jobId}
                                                                toggleLoading={this.setLoading}
                                                                doDispatch={doDispatch}
                                                                receiverUserId={senderId}
                                                                isBothPartyPhoneAvailable={false}
                                                                chatRoomId={chatRoomId}
                                                    />
                                                    <div className="modal fade" id="open_call_popup_for_job"
                                                         role="dialog">
                                                        <CreateProfessionalAccountPopUp url={''}
                                                                                        name={''}
                                                                                        closePopup={() => {
                                                                                            $(`#paid_call`).click()

                                                                                        }} message={''}
                                                        >
                                                            <div className="col-sm-12 text-left">
                                                                <h4 className="heading-sm">Calling Instructions:</h4>
                                                                {ishired ?
                                                                    (
                                                                        <ol className="ol-list">
                                                                            <li>Please fix a time with the Expert before
                                                                                the call.
                                                                            </li>
                                                                            <li>when click below <b>"Okay"</b> button
                                                                                you will get a call first. Please
                                                                                receive it.
                                                                            </li>
                                                                            <li>Make sure you use Workbeam to
                                                                                communicate and make payments.
                                                                            </li>
                                                                        </ol>
                                                                    )
                                                                    :
                                                                    (
                                                                        <ol className="ol-list">
                                                                            <li>This is a 30 min Proposal discussion
                                                                                call.
                                                                            </li>
                                                                            <li>You will get the call first on your
                                                                                number.
                                                                                Please receive it.
                                                                            </li>
                                                                            <li>Fix time on chat before calling.</li>
                                                                            <li>Make sure you use Workbeam to hire the
                                                                                Expert.
                                                                            </li>
                                                                        </ol>
                                                                    )
                                                                }
                                                            </div>
                                                        </CreateProfessionalAccountPopUp>
                                                    </div>
                                                </div>
                                                <div className="col-xs-10 col-sm-offset-1" style={{padding: '0px'}}>
                                                    {errors.inputMessage &&
                                                    <div className='error'>{errors.inputMessage}</div>}
                                                    <textarea className="form-control"
                                                              rows="2"
                                                              id='test_msg_input'
                                                              name='inputMessage'
                                                              placeholder="Type a message..."
                                                              value={inputMessage}
                                                              onChange={(e) => {
                                                                  this.setState({[e.target.name]: e.target.value})
                                                              }}
                                                              style={{
                                                                  border: '1px solid #e8eef3',
                                                                  borderRadius: '0px',
                                                                  marginTop: '5px'
                                                              }}>

                                                    </textarea>
                                                </div>
                                                <div className="col-xs-1">
                                                    <button
                                                        style={{marginTop: '10px'}}
                                                        id='send_message_btn'
                                                        className={"btn btn-link" + (inputMessage.trim() === '' ? ' hidden' : '')}
                                                        name='messageToSend'
                                                        onClick={this.onSendMessage}
                                                    >
                                                        <img src={SendButton}
                                                             style={{
                                                                 width: '35px',
                                                                 marginBottom: '0px',
                                                                 marginLeft: '0px'
                                                             }} alt="send_file"/>
                                                    </button>
                                                </div>
                                                <div className="clearfix"/>
                                                <p id="call_popup_for_job"
                                                   data-toggle="modal"
                                                   data-target="#open_call_popup_for_job">

                                                </p>
                                            </div>
                                        </Fragment>
                                        :
                                        <div className='text-center ' style={{color: '#ff337d'}}>Unfortunately, this job was either closed or some other professional got hired.</div>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 hidden" id="empty_message">
                        <h4 className="heading-md text-center">No conversations!!</h4>
                    </div>
                </div>
                <p id="voice_calling" data-toggle="modal" data-target="#voice_calling_popup"
                   style={{margin: '0px'}}/>
                {this.internetCallingUi()}
                <p id="video_calling" data-toggle="modal" data-target="#video_calling_popup"
                   style={{margin: '0px'}}/>
                {this.videoCallPopupUi()}
                <p id="schedule_call" data-toggle="modal" data-target="#schedule_call_popup"
                   style={{margin: '0px'}}/>
                {<CallScheduler jobId={jobId} proposalId={proposalId} clientUserId={clientDetails.id}
                                professionalUserId={professionalDetails.id}
                                pageName="callscheduler" props={this.props} chatRoomId={chatRoomId}
                />
                }
            </div>
        )
    };

    render() {
        return (
            <div>
                {this.messages()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {data} = state
    const {client, professional} = data
    let from = ''
    if (localStorage.userType === USER) {
        const {phone} = client
        from = phone ? phone.phone : ''
    } else {
        const {phone} = professional
        from = phone ? phone.phone : ''
    }
    return {
        is_super_user: JSON.parse(localStorage.isSuperUser),
        from,
        logedInUserDetails: JSON.parse(localStorage.logedInUserDetails),
    }
};

const mapDispatchToProps = (dispatch) => ({
    ...(errorActionDispatchers(dispatch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Message)
