import * as types from "../constants/auth";

const initialState = {
    stats: {
        net_earning: 0,
        earning_in_progress: 0,
        no_of_customers: 0,
        no_of_order_completed: 0
    },
    discount_details: {},
    payout_details: {neft: {}},
    payout_mode: 'none'
}

export default function affiliate (state = initialState, action) {
    let out = {
        ...state
    };
    switch (action.type) {
        case types.AFFILIATE_PARTNER_STATS:
            const {stats, discount_details, payout_details, payout_mode} = action.payload
            const {unique_customers, jobs_count_completed, affiliate_cut, affiliate_realised} = stats
            out.stats = {
                ...out.stats,
                net_earning: affiliate_cut,
                earning_in_progress: affiliate_cut-affiliate_realised,
                no_of_customers: unique_customers,
                no_of_order_completed: jobs_count_completed,
                ...stats
            }
            out.discount_details = {
                ...out.discount_details,
                ...discount_details,
            }
            out.payout_details = {
                ...out.payout_details,
                ...payout_details
            }
            out.payout_mode = payout_mode
            return out;
        default:
            return state
    }
}