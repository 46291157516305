import * as types from "../constants/auth";

export const getState = (stateData) => {
    return {
        type: types.GET_STATE,
        payload: stateData
    }
};

export const getCity = (cityData) => {
    return {
        type: types.GET_CITY,
        payload: cityData
    }
};
