import * as types from '../constants/errors'

export const pushError = (payload) => {
    return {
        type: types.PUSH_ERROR,
        payload: payload
    }
};

export const popError = () => {
    return {
        type: types.POP_ERROR,
        payload: null
    }
};

export const clearErrors = () => {
    return {
        type: types.CLEAR_ERRORS,
        payload: null
    }
};

export const clearAndShowError = (payload) => {
    return {
        type: types.CLEAR_AND_SHOW_ERROR,
        payload: payload
    }
};

export const clearAndShowPopupError = (payload) => {
    return {
        type: types.CLEAR_AND_SHOW_POPUP,
        payload: payload
    }
};


export function errorActionDispatchers (dispatch) {
    return {
        queueError: (payload) => {
            dispatch(pushError(payload))
        },
        showError: (payload) => {
            dispatch(clearAndShowError(payload))
        },
        clearErrors: () => {
            dispatch(clearErrors())
        },
        doDispatch: (action) => {
            dispatch(action)
        }
    }
}
