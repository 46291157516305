import React, {Component, Fragment} from "react";
import axios from "axios";
import {apiv1} from "../../api";
import {errorActionDispatchers} from "../../actions/errors";
import connect from "react-redux/es/connect/connect";
import {isPaymentDetailsValid} from "../common/validation/paymentDetailsValidator"
import {updateBankDetails} from "../../actions/auth"
import localStorage from './../../utils/localStorage'
import FileBase64 from "react-file-base64";
import gstDocument from "../../static/downloadableDocuments/Declaration_of_GST.docx"
import documentImage from "../../static/images/upload-file.png"
import {showSnackbar} from "../functional/afterHire";
import {allowedDocumentType, uploadDocumentMessage} from "./ProfessionalProfile";
import {CustomToolTrip} from "../../functions/common";

class ProfessionalPaymentDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ifsc_code: props.bankAccount.ifsc_code !== undefined ? props.bankAccount.ifsc_code : '',
            account_number: props.bankAccount.account_number !== undefined ? props.bankAccount.account_number : '',
            account_holder_name: props.bankAccount.account_holder_name !== undefined ? props.bankAccount.account_holder_name : '',
            branch_name: props.bankAccount.branch_name !== undefined ? props.bankAccount.branch_name : '',
            professionalId: localStorage.professionalId,
            bank_name: props.bankAccount.branch_name !== undefined ? props.bankAccount.branch_name.split(',')[0] : '',
            confirm_account_number: '',
            errors: {},
            userDocuments: [],
        }
    }

    componentDidMount() {
        this.getUserDocument()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.bankAccount !== undefined) {
            this.setStateToOriginalForm(nextProps)
        }
    }

    setStateToOriginalForm = (nextProps) => {
        this.setState({
            ifsc_code: nextProps.bankAccount.ifsc_code !== undefined ? nextProps.bankAccount.ifsc_code : '',
            account_number: nextProps.bankAccount.account_number !== undefined ? nextProps.bankAccount.account_number : '',
            account_holder_name: nextProps.bankAccount.account_holder_name !== undefined ? nextProps.bankAccount.account_holder_name : '',
            branch_name: nextProps.bankAccount.branch_name !== undefined ? nextProps.bankAccount.branch_name : '',
            bank_name: nextProps.bankAccount.branch_name !== undefined ? nextProps.bankAccount.branch_name.split(',')[0] : '',
            errors: {}
        })
    }

    getUserDocument = () => {
        this.setState({loading: true});
        apiv1.auth.get('/users/professionaldocument').then(resp => {
            const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
            if (data) {
                this.setState({userDocuments: data, loading: false})
            } else {
                this.setState({loading: false})
            }
        })
            .catch(err => {
                apiv1.handleErrors(err, this.props.doDispatch);
                this.setState({loading: false})
            })
    }

    onChange = (e) => {
        const errors = {...this.state.errors}
        const {name, value} = e.target
        errors[name] = '';
        this.setState({[name]: value.trim(), errors})
    }

    checkValidDocument = ({file}) => {
        const {base64, name} = file
        let document_base64 = '';
        let document_file_name = name
        let isDocumentValid = false
        for (let i = 0; i < allowedDocumentType.length; i++) {
            if (base64.includes(allowedDocumentType[i])) {
                document_base64 = base64.replace(allowedDocumentType[i], "")
                isDocumentValid = true
            }
        }
        if (document_base64 === '') {
            alert('not a valid type document');
        }
        return {isDocumentValid, document_base64, document_file_name}
    }

    uploadDocuments = (file, documentId) => {
        const userDocuments = [...this.state.userDocuments]
        const {professionalId} = this.state
        const errors = {}
        const {isDocumentValid, document_base64, document_file_name} = this.checkValidDocument({file})
        if (!isDocumentValid) {
            return
        }
        const request = {
            document_base64,
            document_type: documentId,
            professional: professionalId || localStorage.professionalId,
            document_file_name
        }

        this.setState({loading: true});
        const uploadedDocument = userDocuments.find(obj => obj.document_type === documentId)
        if (!uploadedDocument) {
            apiv1.auth.post('/users/professionaldocument/', request)
                .then(resp => {
                    const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                    if (data) {
                        userDocuments.push(data);
                        this.setState({loading: false, updatedSuccessfully: true, userDocuments})
                        showSnackbar({message: uploadDocumentMessage})
                    } else {
                        this.setState({loading: false, updatedSuccessfully: false})
                    }
                })
                .catch(err => {
                    const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
                    for (let key in resErrors) {
                        errors[key] = resErrors[key][0]
                    }
                    this.setState({loading: false, updatedSuccessfully: false, errors})
                })
        } else {
            apiv1.auth.patch(`/users/professionaldocument/${uploadedDocument.id}/`, request)
                .then(resp => {
                    const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                    if (data) {
                        userDocuments[userDocuments.indexOf(uploadedDocument)] = data;
                        this.setState({loading: false, updatedSuccessfully: true, userDocuments})
                        showSnackbar({message: uploadDocumentMessage})
                    } else {
                        this.setState({loading: false, updatedSuccessfully: false})
                    }
                })
                .catch(err => {
                    const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
                    for (let key in resErrors) {
                        errors[key] = resErrors[key][0]
                    }
                    this.setState({loading: false, updatedSuccessfully: false, errors})
                })
        }

    };

    searchBank = (e) => {
        e.preventDefault();
        const {ifsc_code} = this.state;
        if (!ifsc_code) {
            alert('Please enter IFSC code');
            return
        }
        const errors = {};
        axios({
            method: 'get',
            url: `https://ifsc.razorpay.com/${ifsc_code}`
        })
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    const branch_name = `${data.BANK},${data.BRANCH},${data.CITY}`;
                    this.setState({bank_name: data.BANK, branch_name, loading: false})
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
                if (err.response !== undefined && (err.response.status === 401 || err.response.status === 400 || err.response.status === 404)) {
                    errors['ifsc_code'] = 'IFSC code is not valid'
                }
                this.setState({loading: false, errors, branch_name: '', bank_name: ''})
            })
    };

    saveBankDetails = () => {
        const {
            ifsc_code,
            account_number,
            account_holder_name,
            branch_name,
            confirm_account_number,
            professionalId
        } = this.state
        const {api} = this.props
        if (!ifsc_code) {
            alert('Please enter IFSC code and search');
            return
        }
        if (!branch_name) {
            alert('Please hit search bar');
            return
        }
        const errors = {};
        const request = {
            professional: {
                bank_account: {
                    ifsc_code: ifsc_code,
                    account_number: account_number,
                    account_holder_name: account_holder_name,
                    branch_name: branch_name,
                    professional: professionalId || localStorage.professionalId
                }
            }
        }
        const paymentObjForValidation = {
            ifsc_code,
            account_number,
            account_holder_name,
            confirm_account_number,
            branch_name
        };
        const isValid = isPaymentDetailsValid(errors, paymentObjForValidation);
        if (isValid) {
            let apiRequest = ''
            if (api) {
                apiRequest = api.auth.patch('/rest-auth/user/', request)
            } else {
                apiRequest = apiv1.auth.patch('/rest-auth/user/', request)
            }
            apiRequest
                .then((resp) => {
                    const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                    if (data) {
                        this.props.onSaveBankDetails(updateBankDetails(data.professional.bank_account));
                        this.setState({loading: false}, () => {
                            showSnackbar({message: 'Saved'})
                        })
                    } else {
                        this.setState({loading: false})
                    }
                })
                .catch((err) => {
                    const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
                    for (let key in resErrors) {
                        errors[key] = resErrors[key][0]
                    }
                    this.setState({loading: false, updatedSuccessfully: false, errors})
                })
        } else {
            this.setState({loading: false, errors})
        }
    }

    documentImageUi = (documentId) => {
        const {userDocuments} = this.state;
        const document = userDocuments.find(obj => obj.document_type === documentId)
        return document !== undefined ? (
                <p className="text-blue" style={{cursor: 'pointer'}}>
                    <span>Change File</span>
                </p>
            ) :
            (<img src={documentImage} style={{cursor: 'pointer'}} id="output" alt="User-pic"/>)
    }

    downloadDocument = (documentId) => {
        const {userDocuments} = this.state;
        const document = userDocuments.find(obj => obj.document_type === documentId);
        return document !== undefined ? (
                <a href={document.document} target='_blank'
                >
                    <span className='text-blue'><CustomToolTrip text={document.document_file_name}
                                                                maxCharLength={40}/></span>
                </a>) :
            (<span> </span>)
    }

    documentUploadUi = () => {
        return (
            <Fragment>

                <ol className="expert-kyc">
                    <li>
                        <div>
                            Identity verification Proof
                            <br/>
                            <small>(Passport,Voter ID, or driving license)</small>
                            <br/>
                            {this.downloadDocument(1)}
                        </div>
                        <div>
                            <label className="btn-bs-file">

                                {this.documentImageUi(1)}
                                <FileBase64 onDone={(file) => this.uploadDocuments(file, 1)}/>
                            </label>
                        </div>
                    </li>
                    <li>
                        <div>
                            Address verification proof
                            <br/>
                            <small>( Utility bill, rent agreement etc. )</small>
                            <br/>
                            {this.downloadDocument(2)}
                        </div>
                        <div>
                            <label className="btn-bs-file">
                                {this.documentImageUi(2)}
                                <FileBase64
                                    onDone={(file) => this.uploadDocuments(file, 2)}/>
                            </label>
                        </div>
                    </li>
                    <li>
                        <div>
                            Education Qualification verification proof
                            <br/>
                            <small>( COP, Member Id card , Degree copy )</small>
                            <br/>
                            {this.downloadDocument(3)}
                        </div>
                        <div>
                            <label className="btn-bs-file">
                                {this.documentImageUi(3)}
                                <FileBase64
                                    onDone={(file) => this.uploadDocuments(file, 3)}/>
                            </label>
                        </div>
                    </li>
                    <li>
                        <div>
                            GST Declaration Proof
                            <br/>
                            {this.downloadDocument(4)}

                            <p><a href={gstDocument} style={{color: '#ff337d'}}><b><i
                                className="fa fa-download"/>&#x00A0;GST Exemption
                                declaration</b></a>
                            </p>
                        </div>
                        <div>
                            <label className="btn-bs-file">
                                {this.documentImageUi(4)}
                                <FileBase64
                                    onDone={(file) => this.uploadDocuments(file, 4)}/>
                            </label>
                        </div>
                    </li>
                </ol>
                <p className="text-note">*Upload at least 1 document</p>
            </Fragment>
        )
    };

    render() {
        const {ifsc_code, account_number, account_holder_name, branch_name, errors, bank_name} = this.state
        const {bankAccount, is_super_user, style} = this.props
        return (
            <div className="new-dashboard-container">
                <div className="row">
                    <div className={`${style ? style : 'col-sm-10 col-sm-offset-1'}`}>
                        <div className="d-update-profile">
                            <div className="d-update-profile-body">
                                <h4 className="d-profile-heading">Bank Transfer Details</h4>
                                <form className="work-experience" style={{marginTop: '20px'}}>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label>IFSC Code</label>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control input-lg"
                                                    style={{borderRadius: '0px', boxShadow: 'none'}}
                                                    id="ifsc_code"
                                                    name="ifsc_code"
                                                    value={ifsc_code}
                                                    onChange={this.onChange}
                                                    placeholder="IFSC Code"/>
                                                <span className="input-group-addon btn btn-info"
                                                      onClick={this.searchBank}
                                                >
                                                <i className="fa fa-search"/>
                                                </span>
                                            </div>
                                            <p className="error">{errors.ifsc_code}</p>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Bank Name</label>
                                                <input type="text" disabled={true}
                                                       className="form-control input-lg" id="Bank_name"
                                                       name="bank_name"
                                                       placeholder="Bank Name"
                                                       value={bank_name}
                                                />
                                            </div>
                                        </div>
                                        <div className="clearfix"/>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Branch Name</label>
                                                <input type="text" disabled={true}
                                                       className="form-control input-lg"
                                                       id="Branch_name"
                                                       name="branch_name"
                                                       placeholder="Branch Name"
                                                       value={branch_name}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>A/C Holder Name</label>
                                                <input type="text"
                                                       className="form-control input-lg"
                                                       id="Ac_name"
                                                       value={account_holder_name}
                                                       name="account_holder_name"
                                                       placeholder="Account Name"
                                                       onChange={(e) => {
                                                           const errors = {...this.state.errors}
                                                           const {name, value} = e.target
                                                           errors[name] = '';
                                                           this.setState({[name]: value, errors})
                                                       }}
                                                />
                                                <span className="error">{errors.account_holder_name}</span>
                                            </div>
                                        </div>
                                        <div className="clearfix"/>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>A/C Number</label>
                                                <input type="text"
                                                       className="form-control input-lg"
                                                       id="Ac_number"
                                                       name="account_number"
                                                       value={account_number}
                                                       onChange={this.onChange}
                                                       placeholder="Account Number"/>
                                                <span className="error">{errors.account_number}</span>
                                            </div>
                                        </div>
                                        {bankAccount.id === undefined
                                        &&
                                        (<div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Confirm A/C Number</label>
                                                <input type="text"
                                                       className="form-control input-lg"
                                                       id="Ac_number"
                                                       name="confirm_account_number"
                                                       onChange={this.onChange}
                                                       placeholder="Confirm Account Number"/>
                                                <span className="error">{errors.confirm_account_number}</span>
                                            </div>
                                        </div>)
                                        }
                                    </div>
                                </form>
                                {bankAccount.id === undefined
                                &&
                                (<div style={{marginTop: '15px', textAlign: 'center'}}>
                                        <button className="btn btn-save-profile-gig"
                                                onClick={this.saveBankDetails}
                                        >Save
                                        </button>
                                    </div>
                                )
                                }
                                <p>&#x00A0;</p>
                            </div>
                        </div>
                    </div>
                </div>
                {!is_super_user &&
                <div className="row">
                    <div className={`${style ? style : 'col-sm-10 col-sm-offset-1'}`}>
                        <div className="d-update-profile">
                            <div className="d-update-profile-body">
                                <h4 className="d-profile-heading">KYC Documentation</h4>
                                {this.documentUploadUi()}
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    bankAccount: state.data.professional.bank_account || {},
    is_super_user: state.data.is_super_user
})

const mapDispatchToProps = (dispatch) => ({
    onSaveBankDetails: (action) => {
        dispatch(action);
    },
    ...(errorActionDispatchers(dispatch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalPaymentDetails)