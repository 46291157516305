import React, { Component, Fragment } from "react";
import Suggestion from 'search-suggestion';
import { apiv1 } from "../../api";
import * as apiUrl from "../../api/apiUrls";
import { withRouter } from "react-router-dom";
import connect from "react-redux/lib/connect/connect";
import { errorActionDispatchers } from "../../actions/errors";
import { getFirstAndLastLetterOfName } from "../common/userAndProfessionalProfileFunctions"
import { category, freelancers } from "../../constants/keyWord"
import { mainDomain } from "../../constants/common";


class ProductSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            datasource: [],
            searchInput: '',
        }
    }

    handleChange = e => {
        const { value } = e.target
        if (e.key === 'Enter') {
            let url = `/search-result`
            url += `/${value}/`
            window.location.href = `${mainDomain}${url}`
            // this.props.history.push(`${mainDomain}${url}`)
        }
        if (value.length > 2) {
            this.setState({
                searchInput: value
            }, () => this.getAllServices({ search: value }))
        }
    };


    getAllServices = ({ search }) => {
        this.setState({ loading: true })
        let url = `${apiUrl.globalSearch}`
        if (search) {
            url += `/${search}/`
        }
        apiv1.noAuth.get(url)
            .then(res => {
                let data = apiv1.parseAndAutoHandleIssues(res, this.props.doDispatch);
                if (data) {
                    const { datasource } = data
                    this.setState({
                        loading: false, datasource
                    })
                } else {
                    this.setState({ loading: false })
                }
            })
            .catch(err => {
                this.setState({ loading: false });
            })
    }
    render() {
        const { datasource, searchInput } = this.state
        const { isFromHeader } = this.props
        return (
            <Fragment>
                <Suggestion
                    items={datasource}
                    onSelectedItem={service => {
                        const { slug } = service || {}
                        const { history } = this.props
                        if (slug) {
                            history.push({
                                pathname: slug
                            })
                        }
                    }} >
                    {({
                        getInputProps,
                        getListItemProps,
                        getItemProps,
                        selectedItem,
                        highlightedIndex,
                        items: datasource,
                        isOpen,
                    }) => (
                        <div className="input-group">
                            {!isFromHeader && <span className="search-icon"><i className="fa fa-search" aria-hidden="true" /></span>}
                            <input className="form-control input-lg"
                                autoComplete="off"
                                {...getInputProps({
                                    placeholder: `${isFromHeader ? 'Find Services...' : 'Try "Logo" "Expert Name" '}`,
                                    onChange: this.handleChange,
                                    onKeyDown: this.handleChange
                                })}
                            />
                            <div className="input-group-btn">
                                <button className="btn btn-header-search" onClick={() => {
                                    let e = {
                                        key: 'Enter',
                                        target: {
                                            value: searchInput
                                        }
                                    }
                                    this.handleChange(e)
                                }}>
                                    Search
                                </button>
                            </div>
                            {isOpen && (
                                <ul {...getListItemProps()} className="list-group search-product">
                                    {datasource.map((service, index) => {
                                        return service.products !== undefined && service.products.map((product, proindex) => {
                                            const { slug } = product;
                                            const { name, id, practise_area_slug, slug_new } = product;
                                            return (
                                                <Fragment>
                                                    {proindex === 0 && (<li className="list-group-item search-list-head">Book Services</li>)}
                                                    <li className="list-group-item">
                                                        <a {...getItemProps({ service, index })}
                                                            key={id}
                                                            href={`${mainDomain}/${category}/${practise_area_slug}/${slug_new}`}
                                                            style={{
                                                                backgroundColor:
                                                                    highlightedIndex === index
                                                                        ? 'rgb(255, 255, 255)'
                                                                        : 'white',
                                                                fontWeight:
                                                                    selectedItem && selectedItem === service
                                                                        ? 'bold'
                                                                        : 'normal'
                                                            }}
                                                        >
                                                            {name}
                                                        </a>
                                                    </li>
                                                </Fragment>
                                            )
                                        })
                                    })}
                                    {datasource !== undefined && datasource.map((service, index) => {
                                        return service.pros !== undefined && service.pros.map((product, proindex) => {
                                            const { avatar_thumbnail, first_name, last_name, slug, slug_new } = product;
                                            const { name, id } = product;
                                            return (
                                                <Fragment>
                                                    {proindex === 0 && (<li className="list-group-item search-list-head">Verified Experts</li>)}
                                                    <li className="list-group-item">

                                                        <a {...getItemProps({ service, index })} className="search-expert"
                                                            key={id}
                                                            href={`${mainDomain}/${freelancers}/${slug_new}`}
                                                            style={{
                                                                backgroundColor:
                                                                    highlightedIndex === index
                                                                        ? 'rgb(255, 255, 255)'
                                                                        : 'white',
                                                                fontWeight:
                                                                    selectedItem && selectedItem === service
                                                                        ? 'bold'
                                                                        : 'normal'
                                                            }}
                                                        >
                                                            <div className="media-left media-middle">
                                                                {avatar_thumbnail ? (<img src={avatar_thumbnail} alt="user" className="search-expert-dp" loading="lazy" />) : getFirstAndLastLetterOfName(first_name, last_name)}
                                                            </div>
                                                            <div className="media-body">
                                                                <h4 className="media-heading">{name}</h4>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </Fragment>
                                            )
                                        })
                                    })}
                                </ul>
                            )}
                        </div>
                    )}
                </Suggestion>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = (dispatch) => ({
    ...(errorActionDispatchers(dispatch)),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductSearch))