import React, {Component} from "react";
import {Link} from "react-router-dom";
import {parentPracticeAreaSlug, productSlugMappedWithId, siteLogoOnSharedMedia} from "./bannerTextForProducts";
import HiredIcon from "../../static/images/hired-icon.png";
import CreditCard from "../../static/images/credit-card.png";
import ColoredBullseye from "../../static/images/colored-bullseye.png";
import {PaymentSecurityInformation} from "../user/GeneralOnboarding";
// import {parentPracticeArea} from "../../constants/parentPracticeArea";
import {ClientReviews, InTheNews} from "./AllOwlCrousel";
import {ratingUi} from "../functional/beforeHire";
import {BrowseMore} from "./professionalByCities";
import Header from "../functional/beforeHire";
import {baseUrl} from "../../constants/common";

export function FaqForAdd() {
    return (
        <section className="service-ui-box" style={{border: 'none', paddingBottom: '20px'}}>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h2 className="heading-sm text-center" style={{marginTop: '20px'}}>Let's clear your doubts!</h2>
                        <p className="hidden-xs">&#x00A0;</p>
                    </div>
                    <br className="visible-xs"/>
                    <div className="col-sm-8 col-sm-offset-2">
                        <div className="panel-group d-accordion">
                            <div className="panel panel-default">
                                <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                                     href="#1accordian">
                                    <h4 className="panel-title">Do I have to make payment before?
                                        <i className="fa fa-angle-down pull-right"></i></h4>
                                </div>
                                <div id="1accordian" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <p className="para">Yes, but your payment is 100% insured with Workbeam
                                            Payment Protection. We receive your payment in escrow and release it to the
                                            expert/freelancer after work is completed or according to your consent.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                                     href="#2accordian">
                                    <h4 className="panel-title">What happens if my work is not completed? <i
                                        className="fa fa-angle-down pull-right"></i></h4>
                                </div>
                                <div id="2accordian" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <p className="para">We will either find you a new expert in the same price to
                                            get your work done successfully or issue you a full refund.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                                     href="#3accordian">
                                    <h4 className="panel-title">Is my payment and files and information secure?
                                        <i className="fa fa-angle-down pull-right"></i>
                                    </h4>
                                </div>
                                <div id="3accordian" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <p className="para">Absolutely, Workbeam uses ISO 27001 Data center and
                                            256-bit encryption. We use google cloud services which are highly
                                            secure.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                                     href="#4accordian">
                                    <h4 className="panel-title">What can I do inside my Dashboard?
                                        <i className="fa fa-angle-down pull-right"></i>
                                    </h4>
                                </div>
                                <div id="4accordian" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <p className="para">You can communicate with your expert using Chat, Audio and
                                            Video Calling. You can share files and track work delivery using milestones
                                            for better completion. You can also rehire the expert from your
                                            dashboard.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                                     href="#5accordian">
                                    <h4 className="panel-title">When is Workbeam Payment Insurance not applicable?
                                        <i className="fa fa-angle-down pull-right"></i>
                                    </h4>
                                </div>
                                <div id="5accordian" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <p className="para">Make sure you talk to the expert using Workbeam Dashboard
                                            and make payments only through Workbeam. If you get in touch
                                            with or make payments to Expert outside of Workbeam, then Workbeam
                                            Payment Insurance is invalid and we do not take responsibility for your
                                            work.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                                     href="#6accordian">
                                    <h4 className="panel-title help-faq-heading">Order Pricing, Booking, Service Fee and Seller Settlement
                                        <i className="fa fa-angle-down pull-right"></i></h4>
                                </div>
                                <div id="6accordian" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <h3 className="help-faq-heading">What are the Order booking slabs for Buyers on Workbeam?</h3>
                                        <p className="help-faq-para">The Buyer who books a service with a  Seller has to pay a booking amount to book the service. The booking is calculated as per below slab:</p>
                                        <p className="help-faq-para">Order Fees Up to $3000 = Full<br/>
                                            Order Fees from to $3000 to $10,000 = $3000<br/>
                                            Order Fees from to $10000 to $20000 = $6000<br/>
                                            Order Fees from $20000 to $40,000 = $8000<br/>
                                            Order Fees from $40,000 to $80,000 = $12000<br/>
                                            Order Fees above $80,000 = $15,000
                                        </p>
                                        <h3 className="help-faq-heading">How is total Order Fees calculated on Workbeam for Standard Gigs?</h3>
                                        <p className="help-faq-para"><b>For Domestic Buyers:</b></p>
                                        <p className="help-faq-para">Total Order Fees or Total Order Value = Seller Fees +  Workbeam GST (18%)</p>
                                        <p className="help-faq-para"><b>For Domestic Buyers:</b></p>
                                        <p className="help-faq-para">Total Order Fees or  Total Order Value = Seller Fees + Workbeam Service Fee (5%)</p>
                                        <p className="help-faq-para"><b>Here is an Example:</b></p>
                                        <p className="help-faq-para">Let’s say you have priced your standard Gig as below:</p>
                                        <p className="help-faq-para">Basic Package: $1000<br/>
                                            Standard Package : $2000<br/>
                                            Premium Packag e: $3000
                                        </p>
                                        <p className="help-faq-para">Therefore, <b>Total Order Fees for Domestic Buyer</b> would be:</p>
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th></th>
                                                <th>Basic</th>
                                                <th>Standard</th>
                                                <th>Premium</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Seller Fees</td>
                                                <td>$1000</td>
                                                <td>$2000</td>
                                                <td>$3000</td>
                                            </tr>
                                            <tr>
                                                <td>Workbeam GST 18%</td>
                                                <td>$180</td>
                                                <td>$360</td>
                                                <td>$540</td>
                                            </tr>
                                            <tr>
                                                <td><b>Total</b></td>
                                                <td><b>$1180</b></td>
                                                <td><b>$2360</b></td>
                                                <td><b>$3540</b></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <p className="help-faq-para"><b>But for International Buyer, the same will be as below:</b></p>
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th></th>
                                                <th>Basic</th>
                                                <th>Standard</th>
                                                <th>Premium</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Seller Fees</td>
                                                <td>$1000</td>
                                                <td>$2000</td>
                                                <td>$3000</td>
                                            </tr>
                                            <tr>
                                                <td>Workbeam Service Fee 5%</td>
                                                <td>$50</td>
                                                <td>$100</td>
                                                <td>$150</td>
                                            </tr>
                                            <tr>
                                                <td><b>Total</b></td>
                                                <td><b>$1050</b></td>
                                                <td><b>$2100</b></td>
                                                <td><b>$3150</b></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <h3 className="help-faq-heading">How is total Order Fees calculated on Workbeam for Custom Gigs?</h3>
                                        <p className="help-faq-para"><b>For a Custom Order or Custom Gig created.</b></p>
                                        <p className="help-faq-para"><b>The Custom Gig Value will be:<br/> For Domestic Buyers:</b></p>
                                        <p className="help-faq-para">Total Order Fees or Total Order Value = Seller Fees +  Workbeam GST (18%)</p>
                                        <p className="help-faq-para"><b>Example:</b></p>
                                        <p className="help-faq-para">Seller Fees for Custom Gig: $1000<br/> Therefore Total Order Value = $1000 + 180 ( Workbeam GST 18%) = $1180</p>
                                        <p className="help-faq-para"><b>For International Buyers ( GST is exempted):</b></p>
                                        <p className="help-faq-para">Total Order Fees or Total Order Value = Seller Fees +  Workbeam Service Fee 5%</p>
                                        <p className="help-faq-para"><b>Example:</b></p>
                                        <p className="help-faq-para">Seller Fees for Custom Gig: $1000<br/> Therefore Total Order Value = $1000 + 50 (Workbeam Service Fee 5%) = $1050</p>
                                        <h3 className="help-faq-heading">Does Workbeam charge Service Fee from Buyers? </h3>
                                        <p className="help-faq-para"><b>For Domestic Buyers:</b></p>
                                        <p className="help-faq-para">Workbeam does not charge any Service Fee from Domestic Buyers</p>
                                        <p className="help-faq-para"><b>For International Buyers:</b></p>
                                        <p className="help-faq-para">Workbeam charges 5% Service Fee from International Buyers. This is because International Payment Gateway charges and transaction fees are usually 5% and higher.</p>
                                        <h3 className="help-faq-heading">Does Workbeam charge Service Fee from Sellers?</h3>
                                        <p className="help-faq-para">Yes, Workbeam charges Service Fee from all Sellers for a completed Order as per below slab:</p>
                                        <p className="help-faq-note"><b>For Orders booked before 1st May 2021:</b></p>
                                        <ul className="help-ul">
                                            <li>10% of the Seller Fees  for All Services/Gigs <b>except</b></li>
                                            <li>5% of the Seller Fees for Private Limited Company Registration</li>
                                            <li>5% of the Seller Fees for LLP Registration</li>
                                            <li>5% of the Seller Fees for Central Fssai License</li>
                                            <li>5% of the Seller Fees for One Person Company Registration</li>
                                        </ul>
                                        <p className="help-faq-note"><b>For Orders booked on or after 1st May 2021:</b></p>
                                        <ul className="help-ul">
                                            <li>15% of the Seller Fees for Sellers who have earned less than $1 Lacs on Workbeam for all Services/Gigs</li>
                                            <li>20% of the Seller Fees for Sellers who have earned $1 Lacs or more on Workbeam for all Services/Gigs</li>
                                        </ul>
                                        <h3 className="help-faq-heading">What is deducted from Seller Fees at time of Settlement? </h3>
                                        <p className="help-faq-note">Here, how the Seller Settlement is calculated:</p>
                                        <p className="help-faq-note"><b>Seller Settlement = Seller Fees - Workbeam Service Fees - TDS</b></p>
                                        <p className="help-faq-para">As per Indian Income Tax Laws, it is compulsory to deduct TDS from service providers as per provisions of Section of Section 194J and Section 194C.</p>
                                        <p className="help-faq-para">To check all TDS related questions and percentage of TDS applicable to you please carry on to read the FAQs. </p>
                                        <p className="help-faq-para"><b>Example:</b></p>
                                        <p className="help-faq-para">A Seller has priced a Gig at $1000.</p>
                                        {/*<p className="help-faq-para">Seller Fees Settlement:</p>*/}
                                        {/*<p className="help-faq-para">$1000 -  $100 (10% Workbeam Fee ) = $900</p>*/}
                                        {/*<p className="help-faq-para">$900 - $10 (TDS) = $890</p>*/}
                                        <h3 className="help-faq-heading">When and how is the Seller Fees Settled?</h3>
                                        <p className="help-faq-para">The Seller Fees is only settled when the Seller has completed the Order, the Buyer has marked the Order as completed and <b>the Seller has generated an Invoice from the Dashboard to Workbeam.</b></p>
                                        <p className="help-faq-para">Workbeam settles the Seller Fees on 15th and 30th of every month. </p>
                                        <h3 className="help-faq-heading">How to generate Seller Invoice to Workbeam for completed Order?</h3>
                                        <p className="help-faq-para">Follow this procedure only when the Order has been marked as completed:</p>
                                        <ul className="help-ul">
                                            <li>Go to Web Dashboard</li>
                                            <li>On top Right Corner, profile dropdown, click on Invoicing and Settlement Summary</li>
                                            <li>On the Summary page, spot the Order and click on <b>“generate invoice”</b></li>
                                        </ul>
                                        <h3 className="help-faq-heading">Is it compulsory to generate Seller Invoice to Workbeam for Fee Settlement on a Completed Order?</h3>
                                        <p className="help-faq-para">Yes, it is compulsory to generate Seller Invoice to Workbeam in order to get your Fees settled in your bank account. </p>

                                        <h3 className="help-faq-heading">What to do if the Buyer is not marking the Order as Completed?</h3>
                                        <p className="help-faq-para">If the Buyer does not mark the Order has completed because the Buyer has become inactive and is not responding, then the Order will be marked as completed after 14 days from the date of Order Completion Request initiated by the Seller.</p>
                                        <h3 className="help-faq-heading">What are the TDS Rates applicable on different categories of Sellers or services?</h3>
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th>Type of Service/Seller</th>
                                                <th>TDS Rate Applicable Normally</th>
                                                <th>TDS Rate during Covid Time</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Content Writing</td>
                                                <td>1% u/s 194c</td>
                                                <td>.75%</td>
                                            </tr>
                                            <tr>
                                                <td>Graphic and Visual Design</td>
                                                <td>1% u/s 194c</td>
                                                <td>.75%</td>
                                            </tr>
                                            <tr>
                                                <td>Marketing</td>
                                                <td>1% u/s 194c</td>
                                                <td>.75%</td>
                                            </tr>
                                            <tr>
                                                <td>Web Development</td>
                                                <td>10% u/s 194j</td>
                                                <td>7.5%</td>
                                            </tr>
                                            <tr>
                                                <td>Web Maintenance & Optimisation</td>
                                                <td>1% u/s 194c</td>
                                                <td>.75%</td>
                                            </tr>
                                            <tr>
                                                <td>Tax, Compliance, Professional Services</td>
                                                <td>10%</td>
                                                <td>7.5%</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export function GeneralFaqForClient({productName}) {
    return (
        <div className="row">
                    <div className="col-sm-12">
                        <h3 className="heading-sm text-center" style={{marginTop: '20px'}}>Let's clear your doubts!</h3>
                        <p className="hidden-xs">&#x00A0;</p>
                    </div>
                    <br className="visible-xs"/>
                    <div className="col-sm-8 col-sm-offset-2">
                        <div className="panel-group d-accordion">
                            <div className="panel panel-default">
                                <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                                     href="#1accordian">
                                    <h2 className="panel-title">Why book {productName} on Workbeam?
                                        <i className="fa fa-angle-down pull-right"></i></h2>
                                </div>
                                <div id="1accordian" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <h3 className="para">Usually Businesses have to put a lot of time in finding and getting quotes from various service providers, Workbeam helps you save a lot of time by comparing and negotiating quotes for all your business services on one platform with different verified Service Providers. Workbeam further allows you to book services with these Experts with 100% refund protection, option to pay in parts, track service delivery & dedicated support  leading to full trust & transparency.  </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                                     href="#2accordian">
                                    <h2 className="panel-title">Are Workbeam Service Experts reliable? <i
                                        className="fa fa-angle-down pull-right"></i></h2>
                                </div>
                                <div id="2accordian" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <h3 className="para">Yes, all our Seller Partners are verified, screened and quality checked before approval. We approve only 10% - 20% best profiles of total registered applications. Further, our Seller Partners follow strict guidelines to complete your Service Orders. We work on training and upskilling our Seller Partners on a regular basis.
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                                     href="#3accordian">
                                    <h2 className="panel-title">How will I pay for services on Workbeam?
                                        <i className="fa fa-angle-down pull-right"></i>
                                    </h2>
                                </div>
                                <div id="3accordian" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <h3 className="para">You shall book the Service on Workbeam by paying a minimum booking amount . You can then make the balance payments as per project milestones or as per progress of your Service Order. Payment can be made easily on Workbeam Website.
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                                     href="#4accordian">
                                    <h2 className="panel-title">How will I communicate with Expert & track service delivery?
                                        <i className="fa fa-angle-down pull-right"></i>
                                    </h2>
                                </div>
                                <div id="4accordian" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <h3 className="para">You can use Workbeam Website Dashboard to communicate with the Expert using our Chat and Audio Calling Tools. Workbeam uses masked calling where your number is kept private and not shared with our Partners to protect your privacy. You can also share files, track service delivery with regular updates on our Website. Workbeam’s 100% Refund Protection Policy is not valid if you communicate with our Seller Partners outside of Workbeam.</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                                     href="#5accordian">
                                    <h2 className="panel-title">What happens if I am not happy with the services?
                                        <i className="fa fa-angle-down pull-right"></i>
                                    </h2>
                                </div>
                                <div id="5accordian" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <h3 className="para">In a case, where your Service Order is not delivered as per committed quality and timeline, we transfer your order to another Workbeam Expert or issue you a 100% Refund.</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                                     href="#6accordian">
                                    <h2 className="panel-title help-faq-heading">Does Workbeam provide security & support?
                                        <i className="fa fa-angle-down pull-right"></i></h2>
                                </div>
                                <div id="6accordian" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <h3 className="para">Workbeam provided dedicated support to all support queries and concerns. We further provide 100% Refund Protection to the Buyers. Workbeam uses Google Cloud Services to keep your data secure and safe. Our Data centres are ISO 27001 certified. Workbeam uses a 128 bit SSL encryption for transmission of data and enables complete data privacy. </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    )
}
export function GeneralProductFaqs({productName}) {
    return (
        <div className="row">
            <div className="col-sm-12">
                <h3 className="heading-sm text-center">Frequently asked questions</h3>
            </div>
            <div className="clearfix"/>
            <div className="col-sm-12">
                <ul className="list-inline faqs-list">
                    <li>
                        <h2 className="text-faq-question">Why book {productName} on Workbeam?</h2>
                        <h3 className="text-faq-para">Usually Businesses have to put a lot of time in finding and getting quotes from various service providers, Workbeam helps you save a lot of time by comparing and negotiating quotes for all your business services on one platform with different verified Service Providers. Workbeam further allows you to book services with these Experts with 100% refund protection, option to pay in parts, track service delivery & dedicated support  leading to full trust & transparency.</h3>
                    </li>
                    <li>
                        <h2 className="text-faq-question">Are Workbeam Service Experts reliable?</h2>
                        <h3 className="text-faq-para">Yes, all our Seller Partners are verified, screened and quality checked before approval. We approve only 10% - 20% best profiles of total registered applications. Further, our Seller Partners follow strict guidelines to complete your Service Orders. We work on training and upskilling our Seller Partners on a regular basis.</h3>
                    </li>
                    <li>
                        <h2 className="text-faq-question">How will I pay for services on Workbeam?</h2>
                        <h3 className="text-faq-para">You shall book the Service on Workbeam by paying a minimum booking amount . You can then make the balance payments as per project milestones or as per progress of your Service Order. Payment can be made easily on Workbeam Website.</h3>
                    </li>
                    <li>
                        <h2 className="text-faq-question">How will I communicate with Expert & track service delivery?</h2>
                        <h3 className="text-faq-para">You can use Workbeam Website Dashboard to communicate with the Expert using our Chat and Audio Calling Tools. Workbeam uses masked calling where your number is kept private and not shared with our Partners to protect your privacy. You can also share files, track service delivery with regular updates on our Website. Workbeam’s 100% Refund Protection Policy is not valid if you communicate with our Seller Partners outside of Workbeam.</h3>
                    </li>
                    <li>
                        <h2 className="text-faq-question">What happens if I am not happy with the services?</h2>
                        <h3 className="text-faq-para">In a case, where your Service Order is not delivered as per committed quality and timeline, we transfer your order to another Workbeam Expert or issue you a 100% Refund.</h3>
                    </li>
                    <li>
                        <h2 className="text-faq-question">Does Workbeam provide security & support?</h2>
                        <h3 className="text-faq-para">Workbeam provided dedicated support to all support queries and concerns. We further provide 100% Refund Protection to the Buyers. Workbeam uses Google Cloud Services to keep your data secure and safe. Our Data centres are ISO 27001 certified. Workbeam uses a 128 bit SSL encryption for transmission of data and enables complete data privacy.</h3>
                    </li>
                </ul>
            </div>
        </div>
    )
}
export function FaqForGigDetail() {
    return (
        <div className="row">
                    <div className="col-sm-12">
                        <h3 className="heading-sm text-center" style={{marginTop: '20px'}}>Let's clear your doubts!</h3>
                        <p className="hidden-xs">&#x00A0;</p>
                    </div>
                    <br className="visible-xs"/>
                    <div className="col-sm-8 col-sm-offset-2">
                        <div className="panel-group d-accordion">
                            <div className="panel panel-default">
                                <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                                     href="#1accordian">
                                    <h2 className="panel-title">Do I have to make payment before?
                                        <i className="fa fa-angle-down pull-right"></i></h2>
                                </div>
                                <div id="1accordian" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <h3 className="para">Yes, but your payment is 100% insured with Workbeam
                                            Payment Protection. We receive your payment in escrow and release it to the
                                            expert/freelancer after work is completed or according to your consent.</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                                     href="#2accordian">
                                    <h2 className="panel-title">What happens if my work is not completed? <i
                                        className="fa fa-angle-down pull-right"></i></h2>
                                </div>
                                <div id="2accordian" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <h3 className="para">We will either find you a new expert in the same price to
                                            get your work done successfully or issue you a full refund.</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                                     href="#3accordian">
                                    <h2 className="panel-title">Is my payment and files and information secure?
                                        <i className="fa fa-angle-down pull-right"></i>
                                    </h2>
                                </div>
                                <div id="3accordian" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <h3 className="para">Absolutely, Workbeam uses ISO 27001 Data center and
                                            256-bit encryption. We use google cloud services which are highly
                                            secure.</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                                     href="#4accordian">
                                    <h2 className="panel-title">What can I do inside my Dashboard?
                                        <i className="fa fa-angle-down pull-right"></i>
                                    </h2>
                                </div>
                                <div id="4accordian" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <h3 className="para">You can communicate with your expert using Chat, Audio and
                                            Video Calling. You can share files and track work delivery using milestones
                                            for better completion. You can also rehire the expert from your
                                            dashboard.</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                                     href="#5accordian">
                                    <h2 className="panel-title">When is Workbeam Payment Insurance not applicable?
                                        <i className="fa fa-angle-down pull-right"></i>
                                    </h2>
                                </div>
                                <div id="5accordian" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <h3 className="para">Make sure you talk to the expert using Workbeam Dashboard
                                            and make payments only through Workbeam. If you get in touch
                                            with or make payments to Expert outside of Workbeam, then Workbeam
                                            Payment Insurance is invalid and we do not take responsibility for your
                                            work.</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading" data-toggle="collapse" data-parent=".d-accordion"
                                     href="#6accordian">
                                    <h2 className="panel-title help-faq-heading">Order Pricing, Booking, Service Fee and Seller Settlement
                                        <i className="fa fa-angle-down pull-right"></i></h2>
                                </div>
                                <div id="6accordian" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <h3 className="help-faq-heading">What are the Order booking slabs for Buyers on Workbeam?</h3>
                                        <p className="help-faq-para">The Buyer who books a service with a  Seller has to pay a booking amount to book the service. The booking is calculated as per below slab:</p>
                                        <p className="help-faq-para">Order Fees Up to $3000 = Full<br/>
                                            Order Fees from to $3000 to $10,000 = $3000<br/>
                                            Order Fees from to $10000 to $20000 = $6000<br/>
                                            Order Fees from $20000 to $40,000 = $8000<br/>
                                            Order Fees from $40,000 to $80,000 = $12000<br/>
                                            Order Fees above $80,000 = $15,000
                                        </p>
                                        <h3 className="help-faq-heading">How is total Order Fees calculated on Workbeam for Custom Gigs?</h3>
                                        <p className="help-faq-para"><b>For a Custom Order or Custom Gig created.</b></p>
                                        <p className="help-faq-para"><b>The Custom Gig Value will be:<br/> For Domestic Buyers:</b></p>
                                        <p className="help-faq-para">Total Order Fees or Total Order Value = Seller Fees +  Workbeam GST (18%)</p>
                                        <p className="help-faq-para"><b>Example:</b></p>
                                        <p className="help-faq-para">Seller Fees for Custom Gig: $1000<br/> Therefore Total Order Value = $1000 + 180 ( Workbeam GST 18%) = $1180</p>
                                        <p className="help-faq-para"><b>For International Buyers ( GST is exempted):</b></p>
                                        <p className="help-faq-para">Total Order Fees or Total Order Value = Seller Fees +  Workbeam Service Fee 5%</p>
                                        <p className="help-faq-para"><b>Example:</b></p>
                                        <p className="help-faq-para">Seller Fees for Custom Gig: $1000<br/> Therefore Total Order Value = $1000 + 50 (Workbeam Service Fee 5%) = $1050</p>
                                        <h3 className="help-faq-heading">Does Workbeam charge Service Fee from Buyers? </h3>
                                        <p className="help-faq-para"><b>For Domestic Buyers:</b></p>
                                        <p className="help-faq-para">Workbeam does not charge any Service Fee from Domestic Buyers</p>
                                        <p className="help-faq-para"><b>For International Buyers:</b></p>
                                        <p className="help-faq-para">Workbeam charges 5% Service Fee from International Buyers. This is because International Payment Gateway charges and transaction fees are usually 5% and higher.</p>
                                        <h3 className="help-faq-heading">Does Workbeam charge Service Fee from Sellers?</h3>
                                        <p className="help-faq-para">Yes, Workbeam charges Service Fee from all Sellers for a completed Order as per below slab:</p>
                                        <p className="help-faq-note"><b>For Orders booked before 1st May 2021:</b></p>
                                        <ul className="help-ul">
                                            <li>10% of the Seller Fees  for All Services/Gigs <b>except</b></li>
                                            <li>5% of the Seller Fees for Private Limited Company Registration</li>
                                            <li>5% of the Seller Fees for LLP Registration</li>
                                            <li>5% of the Seller Fees for Central Fssai License</li>
                                            <li>5% of the Seller Fees for One Person Company Registration</li>
                                        </ul>
                                        <p className="help-faq-note"><b>For Orders booked on or after 1st May 2021:</b></p>
                                        <ul className="help-ul">
                                            <li>15% of the Seller Fees for Sellers who have earned less than $1 Lacs on Workbeam for all Services/Gigs</li>
                                            <li>20% of the Seller Fees for Sellers who have earned $1 Lacs or more on Workbeam for all Services/Gigs</li>
                                        </ul>
                                        <h3 className="help-faq-heading">What is deducted from Seller Fees at time of Settlement? </h3>
                                        <p className="help-faq-note">Here, how the Seller Settlement is calculated:</p>
                                        <p className="help-faq-note"><b>Seller Settlement = Seller Fees - Workbeam Service Fees - TDS</b></p>
                                        <p className="help-faq-para">As per Indian Income Tax Laws, it is compulsory to deduct TDS from service providers as per provisions of Section of Section 194J and Section 194C.</p>
                                        <p className="help-faq-para">To check all TDS related questions and percentage of TDS applicable to you please carry on to read the FAQs. </p>
                                        <p className="help-faq-para"><b>Example:</b></p>
                                        <p className="help-faq-para">A Seller has priced a Gig at $1000.</p>
                                        {/*<p className="help-faq-para">Seller Fees Settlement:</p>*/}
                                        {/*<p className="help-faq-para">$1000 -  $100 (10% Workbeam Fee ) = $900</p>*/}
                                        {/*<p className="help-faq-para">$900 - $10 (TDS) = $890</p>*/}
                                        <h3 className="help-faq-heading">When and how is the Seller Fees Settled?</h3>
                                        <p className="help-faq-para">The Seller Fees is only settled when the Seller has completed the Order, the Buyer has marked the Order as completed and <b>the Seller has generated an Invoice from the Dashboard to Workbeam.</b></p>
                                        <p className="help-faq-para">Workbeam settles the Seller Fees on 15th and 30th of every month. </p>
                                        <h3 className="help-faq-heading">How to generate Seller Invoice to Workbeam for completed Order?</h3>
                                        <p className="help-faq-para">Follow this procedure only when the Order has been marked as completed:</p>
                                        <ul className="help-ul">
                                            <li>Go to Web Dashboard</li>
                                            <li>On top Right Corner, profile dropdown, click on Invoicing and Settlement Summary</li>
                                            <li>On the Summary page, spot the Order and click on <b>“generate invoice”</b></li>
                                        </ul>
                                        <h3 className="help-faq-heading">Is it compulsory to generate Seller Invoice to Workbeam for Fee Settlement on a Completed Order?</h3>
                                        <p className="help-faq-para">Yes, it is compulsory to generate Seller Invoice to Workbeam in order to get your Fees settled in your bank account. </p>

                                        <h3 className="help-faq-heading">What to do if the Buyer is not marking the Order as Completed?</h3>
                                        <p className="help-faq-para">If the Buyer does not mark the Order has completed because the Buyer has become inactive and is not responding, then the Order will be marked as completed after 14 days from the date of Order Completion Request initiated by the Seller.</p>
                                        <h3 className="help-faq-heading">What are the TDS Rates applicable on different categories of Sellers or services?</h3>
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th>Type of Service/Seller</th>
                                                <th>TDS Rate Applicable Normally</th>
                                                <th>TDS Rate during Covid Time</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Content Writing</td>
                                                <td>1% u/s 194c</td>
                                                <td>.75%</td>
                                            </tr>
                                            <tr>
                                                <td>Graphic and Visual Design</td>
                                                <td>1% u/s 194c</td>
                                                <td>.75%</td>
                                            </tr>
                                            <tr>
                                                <td>Marketing</td>
                                                <td>1% u/s 194c</td>
                                                <td>.75%</td>
                                            </tr>
                                            <tr>
                                                <td>Web Development</td>
                                                <td>10% u/s 194j</td>
                                                <td>7.5%</td>
                                            </tr>
                                            <tr>
                                                <td>Web Maintenance & Optimisation</td>
                                                <td>1% u/s 194c</td>
                                                <td>.75%</td>
                                            </tr>
                                            <tr>
                                                <td>Tax, Compliance, Professional Services</td>
                                                <td>10%</td>
                                                <td>7.5%</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    )
}

export class TaxAddPopup extends Component {
    constructor(props) {
        super(props)
        const {history, match} = props
        const {location} = history
        let {pathname} = location
        pathname = pathname || ''
        let pageLink = baseUrl + '/' + pathname.substring(1)
        this.state = {
            pageLink,
        }
    }
    render() {
        const {pageLink} = this.state
        let title = `Tax filing Online | Chartered Accountants Near me | Workbeam`
        let metaDesc = `Hire Chartered Accountants near you for Tax Filing online at affordable prices. Track Filing Process, get dedicated Tax support and payment insurance.`
        return (
            <div>
                <Header link={pageLink} avatar={siteLogoOnSharedMedia} metaDescription={metaDesc} title={title}/>
                {/*<SiteHeader isServicesDataPassed={false} {...this.props}/>*/}
                <section className="hero-main">
                    <div className="container-fluid add-banner">
                        <div className="row">
                            <div className="imageBottomRight">
                                <img src={require("../../static/images/kapil.webp")} alt="find legal solution"/>
                                <div className="banner-rating">
                                    <span className="text-white">Kapil Dev</span>
                                    <br/>
                                                <span>
                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                </span>
                                </div>
                            </div>
                            <div className="col-sm-8 col-xs-8 left-offset-1">
                                <h1 className="banner-text-heading">
                                    File your Taxes proudly. Cheer for Team India.<br/> Be a Proud Citizen.
                                </h1>
                                <p className="text-white-para hidden-xs" style={{color: '#000'}}>
                                    Hire Expert, Communicate, Share files, Track ITR filing process,<br className="hidden-xs"/> Dedicated
                                    Support Manager and get 100 % Payment Insurance.
                                </p>
                            </div>
                        </div>
                        <div className="hero-client-container legal-sm">
                            <div className="col-sm-12">
                               <InTheNews/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="page-contant" style={{paddingTop: '0px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 ui-add-col">
                                <p className="heading-sm text-center" style={{color: '#000'}}>
                                    Please select your Income Type and then hire the expert:
                                </p>

                                <br/>
                                <div className="col-sm-6 col-md-4 col-lg-3">
                                    <div className="add-offer-box">
                                        <span className="offer-text pull-right">Upto 50% Off</span>
                                        <span className="label-info">Offer</span>
                                        <h4 className="offer-heading">ITR filing for Salaried<br/><br
                                            className="hidden-xs"/></h4>
                                        <div className="text-center offer-btn">
                                            <h4 className="offer-heading"><span>Starting $350</span></h4>
                                            <Link to={`/${productSlugMappedWithId['4'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                  className="btn btn-offer-add">Hire Tax Expert</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3">
                                    <div className="add-offer-box">
                                        <span className="offer-text pull-right">Upto 50% Off</span>
                                        <span className="label-success">Offer</span>
                                        <h4 className="offer-heading"> ITR Filing for Salaried plus Capital
                                            Gain</h4>
                                        <div className="text-center offer-btn">
                                            <h4 className="offer-heading"><span>Starting $1100</span></h4>
                                            <Link to={`/${productSlugMappedWithId['5'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                  className="btn btn-offer-add">Hire Tax Expert</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3">
                                    <div className="add-offer-box">
                                        <span className="offer-text pull-right">Upto 50% Off</span>
                                        <span className="label-primary">Offer</span>
                                        <h4 className="offer-heading">ITR Filing for Professional/Freelancer</h4>
                                        <div className="text-center offer-btn">
                                            <h4 className="offer-heading"><span>Starting $550</span></h4>
                                            <Link to={`/${productSlugMappedWithId['18'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                  className="btn btn-offer-add">Hire Tax Expert</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3">
                                    <div className="add-offer-box">
                                        <span className="offer-text pull-right">Upto 50% Off</span>
                                        <span className="label-temp">Offer</span>
                                        <h4 className="offer-heading">ITR Filing for Small Business/Trader/NGO</h4>
                                        <div className="text-center offer-btn">
                                            <h4 className="offer-heading"><span>Starting $1100</span></h4>
                                            <Link to={`/${productSlugMappedWithId['41'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                  className="btn btn-offer-add">Hire Tax Expert</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="page-contant">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h4 className="heading-sm text-center">What people are saying</h4>
                                <p>&#x00A0;</p>
                                <ClientReviews/>
                                <p>&#x00A0;</p>
                                <p>&#x00A0;</p>
                                <h4 className="heading-sm text-center">How It Works</h4>
                            </div>
                            <p className="hidden-xs">&#x00A0;</p>
                            <p>&#x00A0;</p>
                            <div className="col-sm-4 work-box-padding">
                                <div className="work-icon-box">
                                    <img src={HiredIcon} style={{width: '60px'}} alt="icon"/>
                                </div>
                                <h3 className="scope-sub-heading">Select Income Type & Expert</h3>
                                <p className="xs-para">Select your Income Type and then Select and hire the CA or Tax
                                    Expert based on price, location and profile comparison.</p>
                            </div>
                            <div className="col-sm-4 work-box-padding">
                                <div className="work-icon-box">
                                    <img src={CreditCard} style={{width: '70px'}} alt="icon"/>
                                </div>
                                <h3 className="scope-sub-heading">Simplified Secure Payment</h3>
                                <p className="xs-para">Make Payment. Get Workbeam Payment Insurance. Your payment is
                                    100% protected and insured. Easy refunds on Non-Delivery.</p>
                            </div>
                            <div className="col-sm-4 work-box-padding">
                                <div className="work-icon-box">
                                    <img src={ColoredBullseye} style={{width: '70px'}} alt="icon"/>
                                </div>
                                <h3 className="scope-sub-heading">Collaborate &amp; File Tax Return</h3>
                                <p className="xs-para">Communicate , Share Documents required for Tax Filing with
                                    Expert, Track Filing process and file your ITR.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <PaymentSecurityInformation/>
                <FaqForAdd/>
                <BrowseMore/>
            </div>
        );
    }

}

export class AnnualROCAdd extends Component {
    constructor(props) {
        super(props)
        const {history, match} = props
        const {location} = history
        let {pathname} = location
        pathname = pathname || ''
        let pageLink = baseUrl + '/' + pathname.substring(1)
        this.state = {
            pageLink,
        }
    }
    render() {
        const {pageLink} = this.state
        let title = `ROC Filing services in India | Workbeam`
        let metaDesc = `Hire Verified CAs & ROC Experts for ROC filing in your budget. Communicate, share files, track work delivery with 100% money-back guarantee.`
        return (
            <div>
                <Header link={pageLink} avatar={siteLogoOnSharedMedia} metaDescription={metaDesc} title={title}/>
                {/*<SiteHeader isServicesDataPassed={false} {...this.props}/>*/}
                <section className="hero-main">
                    <div className="container-fluid add-banner">
                        <div className="row">
                            <div className="imageBottomRight">
                                <img src={require("../../static/images/kapil.webp")} alt="find legal solution"/>
                                <div className="banner-rating">
                                    <p className="blockquote">
                                        "Faster, smarter, affordable"
                                    </p>
                                    <span className="text-white">Kapil Dev</span>
                                    <br/>
                                    <span>
                                                   {ratingUi(5)}
                                                </span>
                                </div>
                            </div>
                            <div className="col-sm-8 col-xs-8 left-offset-1">
                                <h1 className="banner-text-heading">
                                    ROC Filing Done Right!
                                </h1>
                                <p className="text-white-para hidden-xs" style={{color: '#000'}}>
                                    <b>Last date of Filing is 30th September.</b>
                                </p>
                                <p className="text-white-para hidden-xs" style={{color: '#000'}}>
                                    Hire verified CSs and ROC Experts, Share files, Track ITR filing process,<br className="hidden-xs"/> Dedicated
                                    Support Manager and get 100 % Payment Insurance.
                                </p>
                            </div>
                        </div>
                        <div className="hero-client-container legal-sm">
                            <div className="col-sm-12">
                                <InTheNews/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="page-contant" style={{paddingTop: '0px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 ui-add-col">
                                <p className="heading-sm text-center" style={{color: '#000'}}>
                                    Select package and then hire the Expert:
                                </p>
                                <div className="col-sm-10 col-sm-offset-1">
                                <div className="col-sm-6">
                                    <div className="add-offer-box">
                                        <span className="offer-text pull-right">Upto 50% Off</span>
                                        <span className="label-primary">Offer</span>
                                        <h4 className="offer-heading text-center">Annual ROC Filing Services.</h4>
                                        <div className="text-center offer-btn">
                                            <h4 className="offer-heading"><span>Starting $1500</span></h4>
                                            <Link to={`/${productSlugMappedWithId['16'].slug}/${parentPracticeAreaSlug['3'].slug}`}
                                                  className="btn btn-offer-add">Hire ROC Expert</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="add-offer-box">
                                        <span className="offer-text pull-right">Upto 50% Off</span>
                                        <span className="label-temp">Offer</span>
                                        <h4 className="offer-heading text-center">Annual ROC Compliance Package.</h4>
                                        <div className="text-center offer-btn">
                                            <h4 className="offer-heading"><span>Starting $3000</span></h4>
                                            <Link to={`/${productSlugMappedWithId['17'].slug}/${parentPracticeAreaSlug['3'].slug}`}
                                                  className="btn btn-offer-add">Hire ROC Expert</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="page-contant">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h4 className="heading-sm text-center">What people are saying</h4>
                                <p>&#x00A0;</p>
                                <ClientReviews/>
                                <p>&#x00A0;</p>
                                <p>&#x00A0;</p>
                                <h4 className="heading-sm text-center">How It Works</h4>
                            </div>
                            <p className="hidden-xs">&#x00A0;</p>
                            <p>&#x00A0;</p>
                            <div className="col-sm-4 work-box-padding">
                                <div className="work-icon-box">
                                    <img src={HiredIcon} style={{width: '60px'}} alt="icon"/>
                                </div>
                                <h3 className="scope-sub-heading">Select package & Expert</h3>
                                <p className="xs-para">Select your package and then Select and hire the CA or ROC
                                    Expert based on price, location and profile comparison.</p>
                            </div>
                            <div className="col-sm-4 work-box-padding">
                                <div className="work-icon-box">
                                    <img src={CreditCard} style={{width: '70px'}} alt="icon"/>
                                </div>
                                <h3 className="scope-sub-heading">Simplified Secure Payment</h3>
                                <p className="xs-para">Make Payment. Get Workbeam Payment Insurance. Your payment is
                                    100% protected and insured. Easy refunds on Non-Delivery.</p>
                            </div>
                            <div className="col-sm-4 work-box-padding">
                                <div className="work-icon-box">
                                    <img src={ColoredBullseye} style={{width: '70px'}} alt="icon"/>
                                </div>
                                <h3 className="scope-sub-heading">Collaborate &amp; File ROC Compliance</h3>
                                <p className="xs-para">Communicate , Share Documents required for ROC Compliance with
                                    Expert, Track Filing process and file your ROC Compliance.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <PaymentSecurityInformation/>
                <FaqForAdd/>
                <BrowseMore/>
            </div>
        );
    }

}

export class GstAddPage extends Component {
    constructor(props) {
        super(props)
        const {history, match} = props
        const {location} = history
        let {pathname} = location
        pathname = pathname || ''
        let pageLink = baseUrl + '/' + pathname.substring(1)
        this.state = {
            pageLink,
        }
    }
    render() {
        const {pageLink} = this.state
        let title = `GST Services in India | Workbeam`
        let metaDesc = `Hire verified CAs & GST Experts for GST Registration and GST Filing`
        return (
            <div>
                <Header link={pageLink} avatar={siteLogoOnSharedMedia} metaDescription={metaDesc} title={title}/>
                {/*<SiteHeader isServicesDataPassed={false} {...this.props}/>*/}
                <section className="hero-main">
                    <div className="container-fluid add-banner">
                        <div className="row">
                            <div className="imageBottomRight">
                                <img src={require("../../static/images/kapil.webp")} alt="find legal solution"/>
                                <div className="banner-rating">
                                    <p className="blockquote">
                                        "Faster, smarter, affordable"
                                    </p>
                                    <span className="text-white">Kapil Dev</span>
                                    <br/>
                                    <span>
                                        {ratingUi(5)}


                                                </span>
                                </div>
                            </div>
                            <div className="col-sm-8 col-xs-8 left-offset-1">
                                <h1 className="banner-text-heading">
                                    Be GST Compliant easily & affordably.
                                </h1>
                                <p className="text-white-para hidden-xs" style={{color: '#000'}}>
                                    Hire verified CAs & GST Experts, Share files, Track ITR filing process,<br
                                    className="hidden-xs"/> Dedicated
                                    Support Manager and get 100 % Payment Insurance.
                                </p>
                            </div>
                        </div>
                        <div className="hero-client-container legal-sm">
                            <div className="col-sm-12">
                                <InTheNews/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="page-contant" style={{paddingTop: '0px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 ui-add-col">
                                <p className="heading-sm text-center" style={{color: '#000'}}>
                                    Select package and then hire the Expert:
                                </p>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="add-offer-box">
                                            <span className="offer-text pull-right">Upto 50% Off</span>
                                            <span className="label-temp">Offer</span>
                                            <h4 className="offer-heading text-center">Get GST Registration.</h4>
                                            <p>&#x00A0;</p>
                                            <div className="text-center offer-btn">
                                                <h4 className="offer-heading"><span>Starting $400</span></h4>
                                                <Link
                                                    to={`/${productSlugMappedWithId['20'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                    className="btn btn-offer-add">Hire GST Expert</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="add-offer-box">
                                            <span className="offer-text pull-right">Upto 50% Off</span>
                                            <span className="label-info">Offer</span>
                                            <h4 className="offer-heading text-center">GST Return Filing</h4>
                                            <p className="para text-center">(Upto 10 Monthly Invoices)</p>
                                            <div className="text-center offer-btn">
                                                <h4 className="offer-heading"><span>Starting $350</span></h4>
                                                <Link
                                                    to={`/${productSlugMappedWithId['35'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                    className="btn btn-offer-add">Hire GST Expert</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="add-offer-box">
                                            <span className="offer-text pull-right">Upto 50% Off</span>
                                            <span className="label-primary">Offer</span>
                                            <h4 className="offer-heading text-center">GST Return Filing</h4>
                                            <p className="para text-center">(Upto 30 Monthly Invoices)</p>
                                            <div className="text-center offer-btn">
                                                <h4 className="offer-heading"><span>Starting $550</span></h4>
                                                <Link
                                                    to={`/${productSlugMappedWithId['42'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                    className="btn btn-offer-add">Hire GST Expert</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="add-offer-box">
                                            <span className="offer-text pull-right">Upto 50% Off</span>
                                            <span className="label-success">Offer</span>
                                            <h4 className="offer-heading text-center">GST Return Filing</h4>
                                            <p className="para text-center">(Upto 100 Monthly Invoices)</p>
                                            <div className="text-center offer-btn">
                                                <h4 className="offer-heading"><span>Starting $1000</span></h4>
                                                <Link
                                                    to={`/${productSlugMappedWithId['43'].slug}/${parentPracticeAreaSlug['1'].slug}`}
                                                    className="btn btn-offer-add">Hire GST Expert</Link>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="page-contant">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h4 className="heading-sm text-center">What people are saying</h4>
                                <p>&#x00A0;</p>
                                <ClientReviews/>
                                <p>&#x00A0;</p>
                                <p>&#x00A0;</p>
                                <h4 className="heading-sm text-center">How It Works</h4>
                            </div>
                            <p className="hidden-xs">&#x00A0;</p>
                            <p>&#x00A0;</p>
                            <div className="col-sm-4 work-box-padding">
                                <div className="work-icon-box">
                                    <img src={HiredIcon} style={{width: '60px'}} alt="icon"/>
                                </div>
                                <h3 className="scope-sub-heading">Select package & Expert</h3>
                                <p className="xs-para">Select your package and then Select and hire the CA or GST
                                    Expert based on price, location and profile comparison.</p>
                            </div>
                            <div className="col-sm-4 work-box-padding">
                                <div className="work-icon-box">
                                    <img src={CreditCard} style={{width: '70px'}} alt="icon"/>
                                </div>
                                <h3 className="scope-sub-heading">Simplified Secure Payment</h3>
                                <p className="xs-para">Make Payment. Get Workbeam Payment Insurance. Your payment is
                                    100% protected and insured. Easy refunds on Non-Delivery.</p>
                            </div>
                            <div className="col-sm-4 work-box-padding">
                                <div className="work-icon-box">
                                    <img src={ColoredBullseye} style={{width: '70px'}} alt="icon"/>
                                </div>
                                <h3 className="scope-sub-heading">Collaborate &amp; File GST Return</h3>
                                <p className="xs-para">Communicate , Share Documents required for GST Return with
                                    Expert, Track Registration process and file your GST.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <PaymentSecurityInformation/>
                <FaqForAdd/>
                <BrowseMore/>
            </div>
        );
    }

}

