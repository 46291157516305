import * as apiUrl from "./apiUrls";
import {apiv1} from "./index";
import {couponCodeMsg} from "../constants/common";
import {_roundOfCommission, getDiscount, getFloatType} from "../utils/clientSide";
import $ from "jquery";

function closeLoginPopUp({newStateObj}){
    $('#login_with_otp_pop_up_on_general_onboarding .close').click()
    return Promise.resolve({newStateObj, isProceedToPayment: false})
}

export function validateCouponCode({isLoggedIn, productId, discountDetails, professionalDetails}){
    const {coupon_code} = discountDetails
    // const {doDispatch} = this.props
    let url = `${apiUrl.discountCoupon}?coupon_code=${coupon_code}`
    let errors = {}
    let newStateObj = {
        discountDetails: {
            is_valid_coupon_code: false
        },
        // isValidCouponCode: false,
        errors,
    }

    let couponCodeApi = isLoggedIn ? apiv1.auth.get(url) : apiv1.noAuth.get(url)
    return couponCodeApi
        .then(resp => {
            const data = apiv1.parseAndAutoHandleIssues(resp);
            if (data) {
                const {id, has_user_redeemed, product, is_expired} = data[0] ? data[0] : {}
                errors.coupon_code = couponCodeMsg.invalid
                newStateObj = {
                    ...newStateObj,
                    discountDetails: data[0] || {},
                    // loading: false,
                    errors
                }
                let isProceedToPayment = false
                if (id) {
                    if (!is_expired) {
                        if (!has_user_redeemed) {
                            errors.coupon_code = ''
                            newStateObj = {
                                ...newStateObj,
                                errors,
                            }
                            let {price, actual_amount_enter_by_professional, commission_tax, service_fee} = professionalDetails
                            if (product) {
                                if (product === productId) {
                                    let {fee_pro, fee_commission, fee_gst, fee_total} = data[0]
                                    fee_pro = parseFloat(fee_pro)
                                    fee_commission = parseFloat(fee_commission)
                                    fee_gst = parseFloat(fee_gst)
                                    fee_total = parseFloat(fee_total)
                                    newStateObj = {
                                        ...newStateObj,
                                        discountDetails: {
                                            ...newStateObj.discountDetails,
                                            is_valid_coupon_code: true,
                                        },
                                        // isValidCouponCode: true,
                                        professionalDetails: {
                                            ...professionalDetails,
                                            price: fee_total,
                                            commission: fee_commission,
                                            commission_tax: fee_gst,
                                            actual_amount_enter_by_professional: fee_pro,
                                        }
                                    }
                                    isProceedToPayment = true
                                } else {
                                    errors.coupon_code = couponCodeMsg.not_applicable
                                    newStateObj = {
                                        ...newStateObj,
                                        errors,
                                        isLoggedIn
                                    }
                                }
                            } else {
                                let discountedAmounts = getDiscount({discountSlab: data[0], professionalDetails})
                                let roundedOffDiscountedCommission = _roundOfCommission({
                                    service_fee: getFloatType(service_fee - discountedAmounts.discount_amount_of_service_fee),
                                    commission_tax: getFloatType(commission_tax- discountedAmounts.discount_amount_of_commission_tax),
                                    price: getFloatType(price - discountedAmounts.discount_amount_of_professional_fee-discountedAmounts.discount_amount_of_commission_tax-discountedAmounts.discount_amount_of_service_fee),
                                    actual_amount_enter_by_professional: getFloatType(actual_amount_enter_by_professional - discountedAmounts.discount_amount_of_professional_fee),
                                })
                                newStateObj = {
                                    ...newStateObj,
                                    // isValidCouponCode: true,
                                    discountDetails: {
                                        ...newStateObj.discountDetails,
                                        is_valid_coupon_code: true,
                                    },
                                    professionalDetails: {
                                        ...professionalDetails,
                                        ...roundedOffDiscountedCommission
                                    },
                                }
                                isProceedToPayment = true
                            }
                        } else {
                            errors.coupon_code = couponCodeMsg.already_used
                            newStateObj = {
                                ...newStateObj,
                                errors,
                                isLoggedIn
                            }
                        }
                    } else {
                        errors.coupon_code = couponCodeMsg.expired
                        newStateObj = {
                            ...newStateObj,
                            errors,
                            isLoggedIn
                        }
                    }
                }
                if ((is_expired || has_user_redeemed)) {
                   return  closeLoginPopUp({newStateObj})
                }
                return Promise.resolve({newStateObj, isProceedToPayment})
            }
        })
        .catch(err => {
        })
}