import React, {Component, Fragment} from "react";
import {onlyZeroToNine, validateProfile} from "./validation/FormValidator";
import {apiv1} from "../../api";
import {errorActionDispatchers} from "../../actions/errors";
import connect from "react-redux/es/connect/connect"
import LoadingComponent from "../../utils/LoadingComponent";
import PhoneInput, {getCountryCallingCode, parsePhoneNumber} from "react-phone-number-input";
import {indiaCallingCode} from "../../constants/common";

class AppDownloadInput extends Component {
    constructor(props) {
        super(props);
        const {isUserApp} = this.props
        this.state = {
            loading: false,
            errors: {},
            phone: '',
            isSubmitted: false,
            isUserApp,
            countryCallingCode: indiaCallingCode
        }
    }
    componentDidMount() {
        // window.scrollTo(0, 0);
    }
    removeSuccessMessage = () => {
        this.setState({isSubmitted: false})
    }

    submitRequest = () => {
        const {phone, errors, countryCallingCode, isUserApp} = this.state;
        this.setState({loading: true})
        const {nationalNumber} = parsePhoneNumber(phone) || {}
        let validateObj = {
            phone: nationalNumber,
        };
        const isFormValidated = validateProfile(errors, validateObj);
        if (isFormValidated) {
            const request = {
                phone: nationalNumber,
                country_code: countryCallingCode
            }
            let userAppLink = `/users/app-download-user`
            let proAppLink = `/users/app-download-professional`
            apiv1.noAuth.post(isUserApp ? userAppLink : proAppLink, request)
                .then((resp) => {
                    const data = apiv1.parseAndAutoHandleIssues(resp);
                    if (data) {
                        this.setState({
                            loading: false,
                            phone: '',
                            isSubmitted: true
                        }, () => setTimeout(this.removeSuccessMessage, 2000));
                    } else {
                        this.setState({loading: false})
                    }
                })
                .catch((err) => {
                    const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
                    for (let key in resErrors) {
                        errors[key] = resErrors[key][0]
                    }
                    this.setState({loading: false, errors, email: ''})
                })
        } else {
            this.setState({loading: false, errors})
        }
    }

    onChange = (e) => {
        const newErrors = {...this.state.errors};
        const {name, value} = e.target;
        newErrors[e.target.name] = '';
        let message = '';
        if (name === 'mobile') {
            newErrors['phone'] = ''
            message = 'Phone no is no valid';
            onlyZeroToNine(newErrors, name, value, message)
        }
        this.setState({[name]: value, errors: newErrors})
    };

    render() {
        const {loading, errors, phone, isSubmitted, isUserApp} = this.state
        return (
            <Fragment>
                                {loading &&
                                <div className='lazy-loader' style={{position: 'fixed', zIndex: '100'}}>
                                    <LoadingComponent pastDelay/>
                                </div>
                                }
                                <form className="price">
                                    {isSubmitted ?
                                    <div className="alert alert-success" style={{marginTop:'10px'}}>
                                        <button type="button" className="close" data-dismiss="alert" aria-hidden="true"
                                                style={{fontSize:'24px'}}
                                                onClick={() => {
                                                    this.setState({isSubmitted: false})
                                                }}
                                        >
                                            ×
                                        </button>
                                        <p className="text-success" style={{color:'green'}}>Thanks. Link has been sent.</p>
                                    </div>
                                    :
                                        <div className="form-group">
                                            <PhoneInput
                                                international
                                                countryCallingCodeEditable={false}
                                                className="form-control input-lg"
                                                name="phone"
                                                value={phone}
                                                onChange={(phone) => {
                                                    let e = {
                                                        target: {
                                                            name: 'phone',
                                                            value: phone
                                                        }
                                                    }
                                                    console.log(phone)
                                                    const newErrors = {...this.state.errors}
                                                    const {name, value} = e.target
                                                    newErrors[name] = ''
                                                    this.setState({[name]: value, errors: newErrors})
                                                }}
                                                onCountryChange={(country) => {
                                                    country = country || ''
                                                    if (country) {
                                                        let countryCallingCode = `+${getCountryCallingCode(country)}`
                                                        this.setState({countryCallingCode})
                                                    }
                                                }}
                                                placeholder="Mobile E.g. 99XXXXXX99 "
                                                defaultCountry="CA"
                                                style={{borderRadius:'0px', border: errors.phone ? '1px solid #ff337d' : ''}}
                                            />
                                            <span className={errors.phone ? 'error' : 'no-error'}>{errors.phone}</span>

                                        </div>
                                    }


                                            <div className="text-center">
                                                <a className={`btn btn-book-now ${loading ? 'disabled' : '' }`}
                                                        onClick={() => {
                                                            this.submitRequest()
                                                        }}
                                                title={`Get Our App Link On Your Phone`}>Get Our App Link</a>
                                            </div>
                                </form>


            </Fragment>

        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch) => ({
    ...(errorActionDispatchers(dispatch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppDownloadInput)
