export const userLogin = '/login';
export const userRegistration = '/user-registration';
export const requestFreeProposalSignUp = '/user-registration/request-free-proposal'
export const professionalLogin = '/login';
export const professionalRegistration = '/professional-registration';
export const userOnboarding = '/user-onboarding';
export const generalOnboarding = '/hiring-summary';
export const professionalDashboard = '/professional-dashboard';
export const expertHome = '/expert-home';
export const professionalProfile = '/professional-profile';
export const paymentSetting = '/expert-payment-setting';
export const createDeveloperGig = '/create-developer-gig'
export const createLegalAndAccountingGig = '/create-legal-and-accounting-gig'
export const createExpertStandardGig = '/create-standard-gig'
export const editExpertStandardGig = '/edit-standard-gig'
export const seeStandardGigList = '/see-standard-gig'
export const jobFeed = '/job-feed';
export const documents = '/documents';
export const statusTrack = '/status-track';
export const jobHistory = '/job-history';
export const jobCompleted = '/job-completed';
export const allInvoice = '/all-invoice';
export const consultHistory = '/consult-history';
export const notifications = '/notifications';
export const chats = '/chats';
export const proposalSent = '/proposal-sent';
export const proposalSentLive = '/live-proposals';
export const proposalSentNotLive = '/proposals-not-live';
export const professionalJobInProgress = '/professional/job-in-progress';
export const userJobInProgress = '/user/job-in-progress';
export const userDashboard = '/user-dashboard';
export const professionalsListForClient = '/user-agents'
export const projectsList = '/projects'
export const root = '/';
export const home = '/';
export const userProfile = '/user-profile';
export const meeting = '/meeting';

// export const jobInProgress = '/job-in-progress'
export const jobPosting = '/job-posting';
export const userDocuments = '/user-documents';
export const postedJobs = '/user/posted-orders';
export const postedLiveJobs = '/user/posted-live-jobs';
export const postedNotLive = '/user/posted-not-live';
export const track = '/track';
export const usersProfile = '/user-profile';
export const userHistory = '/user-history';
export const postAJob = '/job-post';
export const editJob = '/edit-job';
export const manuallyJobCreate = '/manually-job-create';
export const assignJobToProfessional = '/assign-job-to-professional';
export const jobTransferToOtherProfessional = '/job-transfer-to-other-professional';
export const assignStandardPackage = '/assign-standard-package';
export const assignStandardPackageOfConsultation = '/assign-standard-package-of-consultation';
export const changeProfessionalOnConsultation = '/change-professional-of-consultation';
export const addChequePayment = '/cheque-payment';
export const professionalList = '/professional-list';
export const adminProfessionalList = '/admin-professional-list';
export const invite = 'invite';
export const perHour = 'per-hour';
export const halfAnHour = 'half-an-hour';
export const forConsultation = 'for-consultation';
export const inviteForConsultationPerHour = '/invite-for-consultation/per-hour';
export const inviteForConsultationHalfAnHour = '/invite-for-consultation/half-an-hour';
export const findAProfessionalByLocation = '/we-will-find-for-you';
export const jobInProgressDocument = `/job-document`
export const jobInProgressTrack = `/job-track`
export const jobInProgressDetails = `/job-details`

// export const userDocuments = '/user-documents'

//Professional paths
export const resetPasswordLink = '/reset-password-link';
export const confirmPassword = '/password-reset/confirm'
export const eamilConfirmed = '/registration/account-confirm-email';
export const thankYou = '/thank-you';
export const professionalLanding = '/freelance-marketplace';
export const feedbackForConsultation = '/consultation-feedback-to';
export const jobFeedback = '/job-feedback-to';
export const practiceArea = '/your-practice-area';
export const yourSkills = '/your-skills';
export const selectSkills = '/select-your-skills';
export const yourServices = '/your-services';
export const yourBio = '/your-bio'
export const briefBio = '/brief-bio'
export const expertReviews = '/add-reviews'
export const editSetting = '/edit-setting'
export const rewardTC = '/agree-term-and-condition';
export const contentGuidelines = '/content-guidelines';
export const AgreedStandardOperatingProcedure = '/agreed-Standard-Operating-procedure';
export const yourBasicInfo = '/your-basic-info';
export const seeMoreLink = '/see-more';
export const verifyOtp = '/verify-otp';

//user
export const userConsultationList = '/consultations-list';
export const documentCheckList = '/check-list';
export const invoice = '/invoice-for';
export const deliveryInfo = '/delivery-info';
export const job='job'
export const list='list'
export const proposalList='proposal-list'

// chat
export const chatToUser = '/chat-to-user';
export const chatToProfessional = '/chat-to-professional';
export const userChatHistory = 'user-chat-history';
export const professionalChatHistory = '/professional-chat-history';
export const chatWithUserAfterHire = '/chat-to-user-after-hire/';
export const yourChatHistory = '/your-chat-history';
export const myChatHistory = '/my-chat-history';


//Learning center Question with answer
export const homeLearningCenter = '/income-tax-india-kanoon';
export const Learningcenter = '/income-tax-india-kanoon';
export const ApiTesting = '/api-testing';
export const categoryPage = '/gigzoe-web-old-blog';
export const EnterpriseServices = '/for-startups';
export const oldServiceFlow = '/old-service';
export const testimonials = '/customer-stories';
export const wizRewards = '/rewards';
//About site
export const termsAndConditions = '/terms-of-service';
export const privacyPolicy = '/privacy-policy';
export const getInTouch = '/about-us';
export const searchResult = 'search-result';
export const forum = '/our-forum';
export const forumDetails = '/forum-details';
export const publicCommunity = '/public-community';
export const raiseSupportTicket = '/raise-support-ticket';
export const covidHelpCenter = '/msme-help-center';
export const msmeFreeService = '/MSME-help-center-India';
export const helpCenter = '/knowledge-base';
export const article = '/knowledge-base/articles';
export const vedeoExample = '/knowledge-base/video-demo';
export const ProfessionalHowitwork = '/professional-how-it-work';

//Interviewer Flow
export const assignslots = '/assign-slot';
export const selectslot = '/select-time-slot';
export const addInterviewSlot = '/basic-interview-slot';
export const addAdvanceInterviewSlot = '/advance-interview-slot';
export const scheduledInterview = '/scheduled-interview';
export const joinSession = '/join-session';
export const upcomingInterview = '/upcoming-interview';
export const pickSlot = '/pick-interview-slot';
export const pastInterviews = '/past-interviews';
export const interviewerFeedback = '/see-detail-feedbak';

//Business formation services
export const product = 'product';
export const practiceAreaId = 'practicearea';
export const productFilter = 'product-filter';
export const legal = 'legal';
export const city = 'city';
export const profession = 'profession';
export const requestFreeProposal = 'request-free-proposal';
export const consultationDetailsPage = '/consultation-details-new';
export const selectConsultationTimeSlots = '/select-consultation-time-slot';
export const consultationOnboarding = '/consultation-onboarding';
export const ecomServicesLanding = '/ecommerce-development-services';
export const covid19FreeSignup = '/grow-your-business-online';
export const covid19FreeSignupContentMarketing = '/talk-to-sales/content-marketing';
export const covid19FreeSignupWebDev = '/talk-to-sales/web-development';
export const leadThankYou = '/form-confirmation-thank-you';
export const askLegalQuestion = '/ask-legal-question';
export const askTaxQuestion = '/ask-tax-question';
export const chatToProfessionalAfterHire = 'chat-to-professional-after-hire';
export const legalAdvice = '/legal/legal-advice';
export const jobDetails = 'job-details';
 export const findAlawyer = '/find-a-lawyer/lawsuits-and-disputes';
export const findLawyerOnline = '/find-lawyers-online';
export const findCaOnline = '/chartered-accountants-near-me';
export const findBookKeeperOnline = '/find-bookkeepers-online';
export const findCsOnline = '/find-company-secretaries-online';
export const standardService = '/standard-service';
export const productProfessional = '/professional-product'
export const byCity = `by-city`


//Add
export const fileItr = '/ca-for-tax-filing-online';
export const annualRoc = "/roc-filing-online";
export const gstAdd = "/gst-services";
export const accountingDetails = '/accounting-details';
export const adminMilestoneMap = '/milestone-map';
export const addQuestion = '/add-questions';
export const sendProposal = '/apply-now';
export const addTitleDescription = '/for-seo';
export const addLinkForSeo = '/add-link-for-seo';
export const seeBlog = '/create-blog';
export const seeMiscellaneousEmail = '/see-email-list';
export const seeComplianceEmailer = '/see-compliance-emailer';
export const seeTrendingEmailer = '/see-trending-emailer';
export const seeBlogOfTheDayEmailer = '/see-blog-of-the-day-emailer';
export const addEmail = '/add-email';
export const editEmail = '/edit-email';
export const addComplianceEmailer = '/add-compliance-emailer';
export const editComplianceEmailer = '/edit-compliance-emailer';
export const addTrendingEmailer = '/add-trending-emailer';
export const editTrendingEmailer = '/edit-trending-emailer';
export const addBlogOfTheDayEmailer = '/add-blog-of-the-day-emailer';
export const editBlogOfTheDayEmailer = '/edit-blog-of-the-day-emailer';
export const addBlog = '/add-blog';
export const addCommunityPost = '/add-community-post';
export const addFakeCommunityPost = '/add-fake-community-post';
export const editBlog = '/edit-blog';
export const orderMarkComplete = '/order-mark-completed';
export const skillTest = '/skill-test';
export const talentOnboard = '/talent-onboard';
export const addSkill = '/add-your-skill';
export const addEducation = '/add-education';
export const expertBasic = '/expert-basic-info';
export const skillsList = '/skills-for-test';
export const testCompleted = '/test-completed';
export const seeJobDetail = '/see-job-detail';

//Admin
export const jobsSummary = '/jobs-summary';
export const consultationsSummary = '/consultations-summary';
export const leadAnalysis = '/lead-analysis';
export const gigChat = '/gig-chat';
export const feedbackList = '/feedback-list';
export const communityPostAprove = '/community-post-aprove';
export const communityPostComment = '/community-post-comment';
export const conCall = '/con-call-request-list';
export const freeConsultation = '/free-lead-request';
export const sendNotification = '/send-notification';
export const commonButtons = '/common-buttons';
export const adminRaisedTicket = '/admin-raised-ticket-list';
export const createChatRoom = '/create-chat-room-and-admin-user';


// Stat
export const productStats = '/product-stats';
export const proInvoiceGenerate = '/pro-invoice-list';
export const invoiceWhenPayment = '/invoice-when-payment';
export const proInvoiceToWiz = '/pro-invoice-to-gigzoe';
export const monthWiseStats = '/monthly-stats';
export const monthWiseDataCalculations = '/monthly-data-calculations';
export const emailMarketing = '/email-marketing';
export const stat = '/stat';
export const paidCustomerDataExport = '/paid-customer-data-export';
export const supplyLeadTracker = '/supply-lead-crm-tracker';
export const adminAccounting = '/admin-accounting';
export const standardGigInReview = '/gigs-in-review';
export const newServiceSupply = '/new-service-supply';
export const jobInProgressSuccess = '/job-in-progress-success';
export const clientFeedback = '/client-feedback';
export const customGigDataExport = '/custom-gig-data-export';
export const adminAffiliateGenerateInvoice = '/admin-affiliate-generate-invoice';

//Service For Brand
export const ServiceForBrand = '/service-brand'
export const ServiceBrandSummary = '/service-brand-summary'

//Explore service
export const incomeTax = '/category/itr-services';
export const accountingServices = '/category/accounting-packages';
export const gstServices = '/category/gst-services';
export const businessFormationServices = '/category/business-formation-services';
export const businessLicensesServices = '/category/business-licenses-services';
export const rocCompliance = '/category/ROC-compliance';
export const trademarksServices = '/category/trademarks-&-copyrights';
export const graphicDesign = '/category/graphic-and-ui-design';
export const videoAnimation = '/category/video-and-animation';
export const websiteDevelopment  = '/category/website-development-services';
export const ecommerceServices  = '/category/ecommerce-services';
export const content   = '/category/content';
export const Marketing   = '/category/marketing';
export const developmentServices   = `development-services`

//Articles
export const article1 = '/knowledge-base/article/how-to-complete-Workbeam-profile';
export const article2 = '/knowledge-base/article/profile-verification-approval-process';
export const article3 = '/knowledge-base/article/profile-bio-and-product-descriptions';
export const article4 = '/knowledge-base/article/examples-of-photoshop-editing';
export const article5 = '/knowledge-base/article/examples-of-social-media-graphics-and-banners';
export const article6 = '/knowledge-base/article/examples-of-flyer-brochure-and-standee';
export const article7 = '/knowledge-base/article/examples-of-menu-design-and-invitation-design';
export const article8 = '/knowledge-base/article/examples-of-website-and-mobile-UI-design';
export const article9 = '/knowledge-base/article/examples-of-package-design-product-photography';

//Custom prod
export const gigStoreOffer = '/gig-store-offer';
export const seeCustomProductList = '/see-custom-product';
export const addCustomProduct = '/add-custom-product';
export const editCustomProduct = '/edit-custom-product';
export const gigTrending = '/trending-pro-gig';

//Standard Product
export const popularPackageForYou = '/popular-package-for-you';

//Affiliate
export const myReferral = '/my-referral';
export const affiliateHome = '/affiliate-program';
export const affiliateDashboard = '/affiliate-dashboard';
export const affiliateCommissionTracker = '/affiliate-commission-tracker';
export const affiliateSettlementsAndKyc = '/affiliate-settlements-and-kyc';
export const affiliateHowToSell = '/affiliate-how-to-sell';
export const affiliateGenerateInvoice = '/affiliate-generate-invoice';

// Expert Count 
export const adminProfessionalProductWise = '/admin-professional-product-wise';
export const adminProfessionalProfessionWise = '/admin-professional-profession-wise';