import React, {Component, Fragment} from "react";
import {userExhotelCall, phoneNumberByUserId} from "../../api/apiUrls";
import {apiv1} from "../../api";
import localStorage from './../../utils/localStorage'
import {USER} from "../../constants/auth";
import {parsePhoneNumber} from "react-phone-number-input";

class ExotelCall extends Component {
    constructor(props) {
        super(props)
        const {from, to, proposalId, jobId, chatRoomId} = props
        this.state = {
            from,
            to,
            proposalId,
            jobId,
            chatRoomId
        }
    }

    getNumber = (from, to, receiverUserId) => {
        const {doDispatch, toggleLoading} = this.props
        const {nationalNumber} = parsePhoneNumber(from || '') || {}
        from = nationalNumber
        let url = `${phoneNumberByUserId}?`
        if (localStorage.userType === USER) {
            url += `professional_profile__user=${receiverUserId}`
        } else {
            url += `client_profile__user=${receiverUserId}`
        }
        toggleLoading()
        apiv1.auth.get(url).then(resp => {
            const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
            if (data) {
                to = data[0].phone
                const {nationalNumber} = parsePhoneNumber(to || '') || {}
                to = nationalNumber
                this.numberToNumberCall(from, to, false)
            }
        })
            .catch(err => {
                toggleLoading()
                apiv1.handleErrors(err, doDispatch)
            })
    }
    numberToNumberCall = (from, to, shouldToggleLoading) => {
        const {doDispatch, toggleLoading} = this.props
        const {chatRoomId} = this.state
        let url = `${userExhotelCall}?from=${from}&to=${to}`
        url += `&roomId=${chatRoomId}`
        let api = apiv1.auth.get(url)
        // if (jobId && proposalId) {
        //     url += `&proposalId=${proposalId}`
        //     url += `&jobId=${jobId}`
        //
        // } else {
        //     // url = `${'/job/for-call'}?`
        //     // url += `&call_from=0${from}`
        //     // url += `&call_to=0${to}`
        //     url += `&roomId=${chatRoomId}`
        //     // let request = {
        //     //     call_from: `0${from}`,
        //     //     call_to: `0${to}`,
        //     //     chat_room: chatRoomId
        //     // }
        //     api = apiv1.auth.get(url)
        // }
        if (shouldToggleLoading) {
            toggleLoading()
        }
        api.then(resp => {
            const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
            if (data) {
                toggleLoading()
            }
        })
            .catch(err => {
                toggleLoading()
                apiv1.handleErrors(err, doDispatch)
            })
    }

    render() {
        const {isBothPartyPhoneAvailable} = this.props
        return (
            <Fragment>
                <div id='paid_call' className="button-call" onClick={(e) => {
                    const {from, to, receiverUserId} = this.props
                    if (!isBothPartyPhoneAvailable) {
                        this.getNumber(from, to, receiverUserId)
                    } else {
                        this.numberToNumberCall(from, to, true)
                    }
                }}
                >
                </div>
            </Fragment>
        );
    }
}

export default ExotelCall;