import {professionalType as professional_type} from "../constants/businessPractiseArea";
import {
    external_link_,
    noOfExternalLink,
} from "../components/professional/DesignerSingleProduct";
import {
    media_type,
    videoThumbnails,
    commissionPercentage,
    indiaCountryCodeCode,
    roundOffDivider,
    httpConst,
    httpsConst
} from "../constants/common";
import localStorage from '../utils/localStorage'
import {getGeoLocation} from "../api/apiUrls";
import axios from 'axios'
import {isBefore_1_06_21} from "../functions/inProgressHelper";

export function getGeoInfo({cb}) {
    axios.get(getGeoLocation).then((response) => {
        const {data} = response
        let {country_code} = data
        localStorage.country = country_code
        if (cb) {
            cb()
        }
    }).catch((error) => {
        console.log(error);
    });
}


export function getLoggedInUserDetails() {
    let loggedInUserDetails = JSON.parse(localStorage.logedInUserDetails)
    loggedInUserDetails = loggedInUserDetails || {}
    return {
        ...loggedInUserDetails
    }
}

export function removeDashFromString(text) {
    return text.replace(/-/g, ' ')
}

export function getFirstCharOfFirstAndLastName({name}) {

    name = name.split(' ')
    let first_name = name[0] || ''
    let last_name = name[1] || ''
    return {
        first_name,
        last_name
    }
}

export function mappedWithFirstChar({data}) {
    let mappedWithAlphabet = new Map()
    data.forEach(details => {
        const {name} = details
        let firstCharOfCity = name.charAt(0)
        if (mappedWithAlphabet.get(firstCharOfCity)) {
            let {cities} = mappedWithAlphabet.get(firstCharOfCity)
            cities.push({...details})
        } else {
            let cities = [{...details}]
            mappedWithAlphabet.set(firstCharOfCity, {cities})
        }
    })
    return mappedWithAlphabet
}

export function roundOffCommission({
                                       commission_tax,
                                       actual_amount_enter_by_professional,
                                       round_off_divider,
                                       is_round_off_adding_to_wc_gst,
                                       service_fee
                                   }) {
    if (!round_off_divider) {
        round_off_divider = localStorage.country === indiaCountryCodeCode ? roundOffDivider.one_point_one_eight : roundOffDivider.one_point_one_five
    }
    round_off_divider = round_off_divider || roundOffDivider.one_point_one_eight
    service_fee = service_fee || 0
    commission_tax = parseFloat((commission_tax).toFixed(2))
    actual_amount_enter_by_professional = parseFloat((actual_amount_enter_by_professional).toFixed(2))
    service_fee = parseFloat((service_fee).toFixed(2))
    let price = actual_amount_enter_by_professional + commission_tax + service_fee
    if (price % 1 !== 0) {
        let round_off_total = Math.ceil(price)
        // let round_off_total = price
        let amountToSplit = Math.abs(round_off_total - price)
       // let amountToSplit = 0
        price = round_off_total
        let professionalCut = parseFloat((amountToSplit / round_off_divider).toFixed(2))
        let wcTax = amountToSplit - professionalCut
        if (!commission_tax) {
            price = round_off_total
            is_round_off_adding_to_wc_gst = false
            actual_amount_enter_by_professional += amountToSplit
            professionalCut = 0
            wcTax = 0
        }
        if (is_round_off_adding_to_wc_gst) {
            wcTax = 0
            professionalCut = 0
            wcTax += amountToSplit
        }
        commission_tax += wcTax
        actual_amount_enter_by_professional += professionalCut
        commission_tax = parseFloat((commission_tax).toFixed(2))
        actual_amount_enter_by_professional = parseFloat((actual_amount_enter_by_professional).toFixed(2))
    }

    return {
        commission_tax,
        actual_amount_enter_by_professional,
        price,
        service_fee
    }
}


export function roundOffCommissionForNationalAndInternationalAnd({
                                                                     actual_amount_enter_by_professional,
                                                                     country_code,
                                                                     is_round_off_adding_to_wc_gst,
                                                                     commission_tax,
                                                                     is_commission_calculating_in_frontend,
                                                                     hasGst,
                                                                     hiring_date,
                                                                     service_fee
                                                                 }) {
    actual_amount_enter_by_professional = parseFloat(actual_amount_enter_by_professional)
    commission_tax = commission_tax || 0
    // commission_tax = country_code === indiaCountryCodeCode ? actual_amount_enter_by_professional * commissionPercentage.national : actual_amount_enter_by_professional * commissionPercentage.international
    if (country_code !== indiaCountryCodeCode) {
        commission_tax = actual_amount_enter_by_professional * commissionPercentage.international
        service_fee = 0
    }
    if (country_code === indiaCountryCodeCode && is_commission_calculating_in_frontend) {
        let is_before_1_06_21 = isBefore_1_06_21(hiring_date)
        if (is_before_1_06_21 || !is_before_1_06_21 && hasGst) {
            commission_tax = actual_amount_enter_by_professional * commissionPercentage.national
        }
        if (service_fee) {
            service_fee = parseFloat((commission_tax + actual_amount_enter_by_professional) * commissionPercentage.service_fee_for_indian_customer)
        }
    }
    // let round_off_divider = country_code === indiaCountryCodeCode ? roundOffDivider.one_point_one_eight : roundOffDivider.one_point_one_five
    // let rounded_of_values = roundOffCommission({
    //     commission_tax,
    //     actual_amount_enter_by_professional,
    //     round_off_divider,
    //     is_round_off_adding_to_wc_gst
    // })
    // let price = rounded_of_values.price
    // commission_tax = rounded_of_values.commission_tax
    // actual_amount_enter_by_professional = rounded_of_values.actual_amount_enter_by_professional
    let price = commission_tax + actual_amount_enter_by_professional
    if (service_fee) {
        service_fee = parseFloat(service_fee)
        price += service_fee
    }
    let parsedToFloat = _parseToFloatCommission({commission_tax, actual_amount_enter_by_professional, service_fee, price})
    commission_tax = parsedToFloat.commission_tax
    actual_amount_enter_by_professional = parsedToFloat.actual_amount_enter_by_professional
    price = parsedToFloat.price
    service_fee = parsedToFloat.service_fee
    return {
        commission_tax,
        actual_amount_enter_by_professional,
        price,
        service_fee
    }
}

export function _parseToFloatCommission({
                                            commission,
                                            commission_tax,
                                            professional_amount_tax,
                                            actual_amount_enter_by_professional,
                                            price,
                                            service_fee
                                        }) {
    price = price || 0
    commission = commission || 0
    commission_tax = commission_tax || 0
    professional_amount_tax = professional_amount_tax || 0
    actual_amount_enter_by_professional = actual_amount_enter_by_professional || 0
    service_fee = service_fee || 0


    commission = parseFloat(commission.toFixed(2))
    commission_tax = parseFloat(commission_tax.toFixed(2))
    professional_amount_tax = parseFloat(professional_amount_tax.toFixed(2))
    actual_amount_enter_by_professional = parseFloat(actual_amount_enter_by_professional.toFixed(2))
    price = parseFloat(price.toFixed(2))
    service_fee = parseFloat(service_fee.toFixed(2))

    return {
        commission,
        commission_tax,
        professional_amount_tax,
        actual_amount_enter_by_professional,
        price,
        service_fee
    }
}

export function _roundOfCommission({
                                       commission,
                                       commission_tax,
                                       professional_amount_tax,
                                       actual_amount_enter_by_professional,
                                       service_fee
                                   }) {
    commission = commission || 0
    commission_tax = commission_tax || 0
    professional_amount_tax = professional_amount_tax || 0
    service_fee = service_fee || 0
    actual_amount_enter_by_professional = actual_amount_enter_by_professional || 0
    commission = parseFloat(commission.toFixed(2))
    commission_tax = parseFloat(commission_tax.toFixed(2))
    professional_amount_tax = parseFloat(professional_amount_tax.toFixed(2))
    actual_amount_enter_by_professional = parseFloat(actual_amount_enter_by_professional.toFixed(2))
    service_fee = parseFloat(service_fee.toFixed(2))

    let round_off_divider = localStorage.country === indiaCountryCodeCode ? roundOffDivider.one_point_one_eight : roundOffDivider.one_point_one_five
    let rounded_of_values = roundOffCommission({
        commission_tax,
        actual_amount_enter_by_professional,
        round_off_divider,
        service_fee
    })
    actual_amount_enter_by_professional = rounded_of_values.actual_amount_enter_by_professional
    commission_tax = rounded_of_values.commission_tax
    let price = rounded_of_values.price
    // price = actual_amount_enter_by_professional + commission + commission_tax + professional_amount_tax
    // let amountToSplit = price
    // price = (parseInt((price + 4) / 5)) * 5
    // amountToSplit = Math.abs(price - amountToSplit)
    // let wizCut = parseFloat((amountToSplit / 1.18).toFixed(2))
    // let wcTax = amountToSplit - wizCut
    // commission += wizCut
    // commission_tax += wcTax
    return {
        commission,
        commission_tax,
        professional_amount_tax,
        actual_amount_enter_by_professional,
        price,
        service_fee
    }
}

export function getFloatType(amt) {
    return parseFloat(amt.toFixed(2))
}

export function dataWithCalculatedCommission({
                                                 results,
                                                 isConsultation,
                                                 product_type,
                                                 isValidCouponCode,
                                                 discountDetails,
                                                 professionalDetails,
                                                 _renderVideo,
                                                 parentComponent
                                             }) {
    results = results.map(professional => {
        let price = Number(professional.price)
        let discountedAmountsKeys = isValidCouponCode ? getDiscount({
            discountSlab: {...discountDetails},
            professionalDetails: {...professionalDetails, ...professional, actual_amount_enter_by_professional: price}
        }) : {}
        if (!discountedAmountsKeys.discount_amount_of_professional_fee) {
            discountedAmountsKeys.discount_amount_of_professional_fee = 0
        }
        let {
            commission,
            commission_tax,
            professional_amount_tax,
            is_core_legal_tax_product,
            price_standard,
            price_premium,
            samples,
            links,
            samples_migrated,
            service_fee
        } = professional
        if (!discountedAmountsKeys.discount_amount_of_commission_tax) {
            discountedAmountsKeys.discount_amount_of_commission_tax = 0
        }
        if (!discountedAmountsKeys.discount_amount_of_service_fee) {
            discountedAmountsKeys.discount_amount_of_service_fee = 0
        }
        if (!discountedAmountsKeys.total_discount) {
            discountedAmountsKeys.total_discount = 0
        }
        if (price <= 0 || (product_type === professional_type.designer && is_core_legal_tax_product) || (product_type === professional_type.legalAndAccounting && !is_core_legal_tax_product)) {
            return {}
        }
        price_premium = price_premium ? Number(price_premium) : 0
        price_standard = price_standard ? Number(price_standard) : 0
        service_fee = service_fee ? Number(service_fee) : 0
        let actual_amount_enter_by_professional = price
        let is_premium_active = price_premium !== 0
        let is_standard_active = price_standard !== 0
        professional_amount_tax = 0
        commission = 0
        if (isConsultation) {
            price = price / 2
            commission = commission / 2
            commission_tax = commission_tax / 2
            professional_amount_tax = professional_amount_tax / 2
            actual_amount_enter_by_professional = actual_amount_enter_by_professional / 2
        }
        let roundedOffCommission = _roundOfCommission({
            actual_amount_enter_by_professional,
            professional_amount_tax,
            commission,
            commission_tax,
            service_fee
        })

        commission = roundedOffCommission.commission
        commission_tax = roundedOffCommission.commission_tax
        actual_amount_enter_by_professional = roundedOffCommission.actual_amount_enter_by_professional
        professional_amount_tax = roundedOffCommission.professional_amount_tax
        price = roundedOffCommission.price
        service_fee = roundedOffCommission.service_fee
        let parseToFloatCommission = _parseToFloatCommission({
            commission,
            commission_tax,
            actual_amount_enter_by_professional,
            professional_amount_tax,
            price,
            service_fee
        })
        commission = parseToFloatCommission.commission
        commission_tax = parseToFloatCommission.commission_tax
        professional_amount_tax = parseToFloatCommission.professional_amount_tax
        service_fee = parseToFloatCommission.service_fee
        let original_price = {
            ...roundedOffCommission
        }
        let discountedCommission = {
            price: getFloatType(original_price.price - discountedAmountsKeys.discount_amount_of_commission_tax - discountedAmountsKeys.discount_amount_of_service_fee - discountedAmountsKeys.discount_amount_of_professional_fee),
            commission,
            commission_tax: getFloatType(commission_tax - discountedAmountsKeys.discount_amount_of_commission_tax),
            professional_amount_tax,
            service_fee: getFloatType(service_fee - discountedAmountsKeys.discount_amount_of_service_fee),
            actual_amount_enter_by_professional: getFloatType(actual_amount_enter_by_professional - discountedAmountsKeys.discount_amount_of_professional_fee),
            discountAmount: getFloatType(discountedAmountsKeys.total_discount)
        }
        let roundedOffDiscountedCommission = _roundOfCommission({
            ...discountedCommission
        })
        samples = samples_migrated || []
        let externalSiteLink = links || []
        externalSiteLink = externalSiteLink.filter(link => link)
        externalSiteLink = _externalLinkWithHtt({link: externalSiteLink})
        samples = samplesData({professionalProductDetails: professional, samples, _renderVideo, parentComponent})
        return {
            ...professional,
            base_multiplier: 1,
            samples,
            externalSiteLink,
            commission,
            commission_tax,
            professional_amount_tax,
            actual_amount_enter_by_professional,
            price: actual_amount_enter_by_professional,
            service_fee,
            price_standard,
            price_premium,
            is_standard_active,
            is_premium_active,
            original_price,
            first_installment: _bookingAmountSlab({amount: price}),
            quantity_count: 1,
            calculated_commissions: {
                ...roundedOffDiscountedCommission
            }
        }
    }).filter(details => details.price)
    return {ListData: results}
}

function _externalLinkWithHtt({link}) {
    return link.map(link => {
        if (link.substring(0, 8) !== httpsConst && link.substring(0, 7) !== httpConst) {
            return httpsConst + link
        }
        return link
    })
}


export function _externalSiteLink({professionalProductDetails, externalSiteLink}) {
    noOfExternalLink.forEach((linkNumber) => {
        let linkKey = `${external_link_}${linkNumber}`
        if (professionalProductDetails[linkKey]) {
            let linkData = professionalProductDetails[linkKey] || ''
            if (linkData.substring(0, 8) !== httpsConst && linkData.substring(0, 7) !== httpConst) {
                linkData = httpsConst + linkData
            }
            externalSiteLink.push(linkData)
        }
    })
}

export function samplesData({samples, _renderVideo, parentComponent}) {
    let isPhoto = ''
    let embedUrl = ''
    let original = ''
    let originalAlt = ''
    let thumbnail = ''
    let thumbnailAlt = ''
    return samples.map(details => {
        const {document, sample_type, document_thumbnail, vimeo_link, document_thumbnail_medium} = details
        isPhoto = sample_type === media_type.image
        original = isPhoto ? document : videoThumbnails
        originalAlt = document
        thumbnail = isPhoto ? document_thumbnail : videoThumbnails
        thumbnailAlt = document_thumbnail
        // thumbnail = isPhoto ? document_thumbnail_medium : videoThumbnails
        embedUrl = isPhoto ? '' : document
        return {
            ...details,
            original,
            originalAlt,
            thumbnail,
            embedUrl,
            renderItem: embedUrl && _renderVideo && parentComponent ? _renderVideo.bind(parentComponent) : undefined,
            isPhoto,
            sample: document,
            vimeo_link
        }
    })
}

export function _bookingAmountSlab({amount}) {

    switch (true) {
        case amount <= 5000:
            return amount
        case amount > 5000 && amount <= 80000:
            return parseInt(amount * 0.4) > 5000 ? parseInt(amount * 0.4) : 5000
        // case amount > 10000 && amount <= 20000:
        //     return 6000
        // case amount > 20000 && amount <= 40000:
        //     return 8000
        // case amount > 40000 && amount <= 80000:
        //     return 15000
        // case amount > 80000 && amount <= 150000:
        //     return parseInt(amount*0.25)
        default:
            return parseInt(amount * 0.3)
    }
}

export function getDiscount({discountSlab, professionalDetails}) {
    const {
        discount_slab_1,
        discount_slab_2,
        discount_slab_3,
        discount_slab_4,
        discount_slab_5,
        slab_1,
        slab_2,
        slab_3,
        slab_4,
        max_discount_limit,
        is_flat
    } = discountSlab
    let {actual_amount_enter_by_professional, service_fee, commission_tax} = professionalDetails || {}
    let totalPrice = Number(actual_amount_enter_by_professional)
    let discountPercentage = 0
    let discount_amount_of_professional_fee = 0
    if (!is_flat) {
        discountPercentage = discount_slab_1 / 100
    }
    switch (true) {
        case (totalPrice <= slab_1):
            discount_amount_of_professional_fee = !is_flat ? Math.floor(totalPrice * discountPercentage) : discount_slab_1
            break
        case (totalPrice > slab_1 && totalPrice <= slab_2):
            discount_amount_of_professional_fee = !is_flat ? Math.floor(totalPrice * discountPercentage) : discount_slab_2
            break
        case (totalPrice > slab_2 && totalPrice <= slab_3):
            discount_amount_of_professional_fee = !is_flat ? Math.floor(totalPrice * discountPercentage) : discount_slab_3
            break
        case (totalPrice > slab_3 && totalPrice <= slab_4):
            discount_amount_of_professional_fee = !is_flat ? Math.floor(totalPrice * discountPercentage) : discount_slab_4
            break
        default:
            discount_amount_of_professional_fee = discount_slab_5
    }
    if (discount_amount_of_professional_fee > max_discount_limit) {
        discount_amount_of_professional_fee = max_discount_limit
    }
    let discountRatio = 0
    if (totalPrice) {
        discountRatio = discount_amount_of_professional_fee / totalPrice
    }
    let discount_amount_of_service_fee = getFloatType(service_fee * discountRatio)
    let discount_amount_of_commission_tax = getFloatType(commission_tax * discountRatio)
    discount_amount_of_professional_fee = getFloatType(discount_amount_of_professional_fee)
    let total_discount = getFloatType(discount_amount_of_professional_fee + discount_amount_of_commission_tax + discount_amount_of_service_fee)
    return {
        discount_amount_of_professional_fee,
        discount_amount_of_service_fee,
        discount_amount_of_commission_tax,
        total_discount
    }
}