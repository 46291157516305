import {Component, Fragment} from "react";
import {apiv1, App_env} from "../api";
import {errorActionDispatchers} from "../actions/errors";
import connect from "react-redux/es/connect/connect";
import * as path from "../constants/path";
// import {online} from '../constants/keyWord'
import React from "react";
import {withRouter} from "react-router-dom";
import GigzoeLogo from "../static/images/gigzoe-logo.png";
import localStorage from "../utils/localStorage";
import sessionStorage from '../utils/sessionStorage'

import $ from 'jquery'
import {
    // parentPracticeAreaSlug,
    // productIdMappedWithSlug,
    businessRegistrationServices,
    businessClouserServices,
    trademarkRegistrationServices,
    businessLicenseServices,
    rocComplianceServices,
    incomeTaxServices,
    taxAccountingServices,
    tdsServices,
    taxGstServices,
    businessAndBrandServices,
    printDesignServices,
    socialMarketingService,
    packagingandLabelServices,
    photoshopEditingServices,
    uiDesignServices,
    videoAnimationServices,
    onlineEcomServices,
    webAndAppServices,
    webAndUXContent,
    blogsAndArticles,
    translationAndProofreading,
    digitalMarketingServices,
    socialandCopywriting
} from "./common/bannerTextForProducts";
import {getFirstAndLastLetterOfName} from "./common/userAndProfessionalProfileFunctions";
import {becomeExpert, category} from "../constants/keyWord"
import {ProfessionalSignUpLink} from "./professional/ProfessionalLandingPage";
import LoginWithPhone from "./common/LoginWithPhone";
import {sessionLogout} from "../actions/auth";
import {showDefault, mainDomain} from './../constants/common'
import {GetFirstNameAndLastName} from "../functions/common";
import * as apiUrl from "../api/apiUrls";
import {getLoggedInUserDetails} from "../utils/clientSide";


// const excludeChildServicesIds = [9];
// const excludeServicesIds = [6];

export function fixedMobileMenu() {
    $('[data-toggle="slide-collapse"]').on('click', function () {
        let navMenuCont = $($(this).data('target'));
        navMenuCont.animate({
            'width': 'toggle'
        },);
        if ($("#site_header_drawer").css('display') === 'none') {
            $(".menu-overlay").fadeIn()
            // $(".menu-overlay").fadeOut();
        } else {
            $(".menu-overlay").fadeOut()
        }
    });
    $(".menu-overlay").click(function (event) {
        $(".navbar-toggle").click();
    });
}

function megaMenu(props) {
    $(".mega-dropdown").hover(
        function () {
            $('.dropdown-menu', this).not('.in .dropdown-menu').stop(true, true).slideDown("400");
            $(this).toggleClass('open');
        },
        function () {
            $('.dropdown-menu', this).not('.in .dropdown-menu').stop(true, true).slideUp("400");
            $(this).toggleClass('open');
        }
    );
    // if(localStorage.isSessionValid()){
    //     $('.mega-dropdown-menu li>a').attr('target', '_blank');
    // }
    // else{
    //     $('.mega-dropdown-menu .browse-more ul>li a').attr('target', '_self');
    // }
    if ($(window).width() > 768) {
        $('.whatsapp-link').attr('href', 'https://web.whatsapp.com/send?phone=+919999974258&text=Hi Workbeam Team, I need help with ...')
    } else {
        $('.whatsapp-link').attr('href', 'whatsapp://send?phone=+919999974258&text=Hi Workbeam Team, I need help with ...')
    }
}

export function ForMobileMenu() {
    return (
        <Fragment>
            <p className="scope-sub-heading" style={{color: '#898989', fontSize: '18px', marginLeft: '15px'}}>Explore
                Services</p>
            <li className="dropdown">
                <a className="dropdown-toggle" data-toggle="dropdown">Website & App Development
                    <i className="fa fa-chevron-down" />
                </a>
                <ul className="dropdown-menu">
                    {returnHeaderOnlyChildListOnMobileWeb(webAndAppServices)}
                </ul>
            </li>
            <li className="dropdown">
                <a className="dropdown-toggle" data-toggle="dropdown">Content Writing Services
                    <i className="fa fa-chevron-down" />
                </a>
                <ul className="dropdown-menu">
                    {returnHeaderOnlyChildListOnMobileWeb(webAndUXContent)}
                    {returnHeaderOnlyChildListOnMobileWeb(blogsAndArticles)}
                    {returnHeaderOnlyChildListOnMobileWeb(translationAndProofreading)}
                    {returnHeaderOnlyChildListOnMobileWeb(socialandCopywriting)}
                </ul>
            </li>
            <li className="dropdown">
                <a className="dropdown-toggle" data-toggle="dropdown">Digital Marketing Services
                    <i className="fa fa-chevron-down" />
                </a>
                <ul className="dropdown-menu">
                    {returnHeaderOnlyChildListOnMobileWeb(digitalMarketingServices)}
                </ul>
            </li>
            <li className="dropdown">
                <a className="dropdown-toggle" data-toggle="dropdown">Design, Video & photography
                    <i className="fa fa-chevron-down" />
                </a>
                <ul className="dropdown-menu">
                    {returnHeaderOnlyChildListOnMobileWeb(businessAndBrandServices)}
                    {returnHeaderOnlyChildListOnMobileWeb(printDesignServices)}
                    {returnHeaderOnlyChildListOnMobileWeb(socialMarketingService)}
                    {returnHeaderOnlyChildListOnMobileWeb(packagingandLabelServices)}
                    {returnHeaderOnlyChildListOnMobileWeb(photoshopEditingServices)}
                    {returnHeaderOnlyChildListOnMobileWeb(uiDesignServices)}
                    {returnHeaderOnlyChildListOnMobileWeb(videoAnimationServices)}
                </ul>
            </li>
            <li className="dropdown">
                <a className="dropdown-toggle" data-toggle="dropdown">Income Tax Filing Services
                    <i className="fa fa-chevron-down" />
                </a>
                <ul className="dropdown-menu">
                    {returnHeaderOnlyChildListOnMobileWeb(taxGstServices)}
                    {returnHeaderOnlyChildListOnMobileWeb(incomeTaxServices)}
                    {returnHeaderOnlyChildListOnMobileWeb(tdsServices)}
                </ul>
            </li>
            <li className="dropdown">
                <a className="dropdown-toggle" data-toggle="dropdown">Business Formation
                    <i className="fa fa-chevron-down" />
                </a>
                <ul className="dropdown-menu">
                    {returnHeaderOnlyChildListOnMobileWeb(businessRegistrationServices)}
                    {returnHeaderOnlyChildListOnMobileWeb(businessClouserServices)}
                    {returnHeaderOnlyChildListOnMobileWeb(trademarkRegistrationServices)}
                </ul>
            </li>
            <li className="dropdown">
                <a className="dropdown-toggle" data-toggle="dropdown">Business Licenses
                    <i className="fa fa-chevron-down" />
                </a>
                <ul className="dropdown-menu">
                    {returnHeaderOnlyChildListOnMobileWeb(businessRegistrationServices)}
                </ul>
            </li>
            <li className="dropdown">
                <a className="dropdown-toggle" data-toggle="dropdown">Accounting & Bookkeeping
                    <i className="fa fa-chevron-down" />
                </a>
                <ul className="dropdown-menu">
                    {returnHeaderOnlyChildListOnMobileWeb(taxAccountingServices)}
                </ul>
            </li>
        </Fragment>
    )
}

function SiteLogoHeader({history, onLoginClicked, logout, isShowDefaultHeader, loggedInUserDetails}) {
    const {location} = history
    const {pathname} = location
    let avatar = ''
    if (loggedInUserDetails.avatar) {
        avatar = loggedInUserDetails.avatar
    }
    return (
        <nav id="main-header"
             className={"main-header navbar navbar-default navbar-fixed-top  nav-shadow" + `${pathname !== path.testimonials && pathname !== path.getInTouch ? ' ' : ''}`}
             style={{zIndex: '1031'}}>
            <div className="container-fluid">
                <div className="navbar-header">
                    <button type="button"
                            className="navbar-toggle collapsed"
                            data-toggle="slide-collapse"
                            aria-expanded="false"
                            data-target="#fixedNavbar"
                    >
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>
                    {isShowDefaultHeader ?
                        <a className="navbar-brand"
                           href={mainDomain} title={`Workbeam - Hire Trusted Freelancers and Service Experts`}
                        >
                            <img src={GigzoeLogo} className="header-logo"
                                 alt="Workbeam - Hire Trusted Freelancers and Service Experts"/>
                        </a>
                        :
                        <a className="navbar-brand"
                           onClick={() => history.goBack()}
                        >
                            <img src={GigzoeLogo} className="header-logo" alt="Workbeam - Hire Trusted Freelancers and Service Experts"/>
                        </a>
                    }
                </div>
                <div className="collapse navbar-collapse"
                     id="fixedNavbar"
                >
                    <ul className="nav navbar-nav navbar-right mobile-menu visible-xs"
                    >
                        {/*{pathname !== path.professionalLanding && (*/}
                        {/*<li>*/}
                        {/*{isShowDefaultHeader &&*/}
                        {/*<Link to={path.covid19FreeSignup}*/}
                        {/*className="btn btn-navbar">{requestProposals}</Link>*/}
                        {/*}*/}
                        {/*</li>*/}
                        {/*)}*/}
                        {localStorage.isSessionValid() &&
                        <li style={{display: 'flex', marginTop: '5px', marginBottom: '-10px'}}>
                            <a href={path.userLogin} className="xs-dp-img">
                                {avatar ?
                                    <img src={avatar} alt={`${localStorage.firstName}`}
                                         className="img-circle dp-image"/>
                                    :
                                    getFirstAndLastLetterOfName(localStorage.firstName, localStorage.lastName)
                                }
                            </a>
                            <a style={{
                                fontSize: '16px',
                                paddingLeft: '0px',
                                textTransform: 'capitalize'
                            }}><GetFirstNameAndLastName firstname={localStorage.firstName}
                                                        lastname={localStorage.lastName}/></a>
                        </li>
                        }

                        {isShowDefaultHeader &&
                        <Fragment>

                            <ForMobileMenu/>
                            <li style={{borderTop: '1px solid #cecece', padding: '0px 0px'}} title={`Public Community`}>
                                <a href={path.publicCommunity}>Community</a>
                            </li>
                            {/*<li style={{borderBottom: '1px solid #cecece', padding: '0px 0px'}}>*/}
                                {/*<a href={path.testimonials} title={`Customer Stories`}>Customer Stories</a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*<Link to={`${path.popularPackageForYou}${`#website_development_tab`}`}>Explore*/}
                            {/*Services</Link>*/}
                            {/*</li>*/}
                            {localStorage.isSessionValid() &&
                            <Fragment>
                                <li><a className="btn m-login-button" href={path.userLogin}>
                                    Go to dashboard</a>
                                </li>
                                <li>
                                    <a
                                        className="btn expert-button"
                                        href={path.yourChatHistory}
                                    >Messages</a>
                                </li>
                            </Fragment>
                            }
                            {!localStorage.isSessionValid() &&
                            <Fragment>
                                <li><a className="btn m-login-button"
                                       onClick={onLoginClicked}
                                >Login</a></li>
                                {pathname === path.professionalLanding ? (
                                        <li>
                                            <ProfessionalSignUpLink text={`Register Free`}
                                                                    classes={'btn expert-button'}/>
                                        </li>
                                    )
                                    :
                                    (
                                        <li><a href={`${mainDomain}${path.professionalLanding}`}
                                               className="btn expert-button">{becomeExpert}</a>
                                        </li>
                                    )
                                }
                            </Fragment>
                            }
                        </Fragment>
                        }
                        {localStorage.isSessionValid() &&
                        <li style={{marginTop: '25px', borderTop: '1px solid #cecece'}}>
                            <a style={{color: '#ff337d'}}
                               onClick={() => logout({isNavbarToggling: true})}
                            >Sign Out</a>
                        </li>
                        }
                    </ul>
                    <ul className="nav navbar-nav navbar-right hidden-xs"
                    >
                        {/*<li>*/}
                        {/*<Link to={`${path.popularPackageForYou}${`#website_development_tab`}`}>Business*/}
                        {/*Packages</Link>*/}
                        {/*</li>*/}
                        <li>
                            <a href={path.publicCommunity} title={`Public Community`}>Community</a>
                        </li>
                        {/*<li>*/}
                            {/*<a href={path.testimonials} title={`Customer Stories`}>Customer Stories</a>*/}
                        {/*</li>*/}
                        {!localStorage.isSessionValid() &&
                        <li>
                            <a onClick={onLoginClicked}
                               title={`Login`}>Login</a>
                        </li>
                        }
                        {isShowDefaultHeader &&
                        <Fragment>
                            {pathname === path.professionalLanding ? (
                                    <li>
                                        <ProfessionalSignUpLink text={`Register Free`}
                                                                classes={'btn navbar-btn signup-expert-button'}/>
                                    </li>
                                )
                                :
                                (
                                    !localStorage.isSessionValid() && 
                                    <li><a href={`${mainDomain}${path.professionalLanding}`}
                                           className="btn navbar-btn signup-expert-button"
                                           title={`Sign Up Free`}>{becomeExpert}</a>
                                    </li>
                                )
                            }
                        </Fragment>
                        }
                        {localStorage.isSessionValid() &&
                        <li className="dropdown">
                            <a className="dropdown-toggle" data-toggle="dropdown">
                                {avatar ?
                                    <img src={avatar} alt={`${localStorage.firstName}`}
                                         className="img-circle dp-image"/>
                                    :
                                    getFirstAndLastLetterOfName(localStorage.firstName, localStorage.lastName)
                                }
                                <div className="dropdown-icon"><i className="fa fa-chevron-down" /></div>
                            </a>
                            <ul className="dropdown-menu" style={{minWidth: '100%'}}>
                                <li><a href={path.userLogin}>
                                    Go to dashboard</a></li>
                                <li>
                                    <a
                                        onClick={() => logout({})}
                                    >Sign Out</a>
                                </li>
                            </ul>
                        </li>
                        }
                    </ul>
                </div>
            </div>
        </nav>
    )
}

// export function MsmeHelpStrip({isDisplay}) {
//     return (
//         <Fragment>
//             {isDisplay &&
//             <div className="alert alert-success msme-strip hidden-xs">
//                 <button type="button" className="close pull-right" data-dismiss="alert" aria-hidden="true"
//                         style={{fontSize: '24px', color: '#ff337d', marginTop: '-5px'}}>
//                     ×
//                 </button>
//                 <p className="text-success text-center">COVID-19 HelpCentre: Ask Free questions, Book Free Services &
//                     Join MSME Community. <a href={path.covidHelpCenter} target="_blank">See Helpcentre</a></p>
//                 {process.env.REACT_APP_ENV !== App_env.production &&
//                 <a href={path.popularPackageForYou}>General package </a>
//                 }
//             </div>
//             }
//         </Fragment>
//     )
// }

// function returnHeaderParentAndChildList(parentCategory) {
//     return Object.keys(parentCategory).map(parentSlug => {
//         const {display_name, children} = parentCategory[parentSlug]
//         return <Fragment>
//             <h4 className="xs-heading">{display_name}</h4>
//             <ul className='list-unstyled browse-more'>
//                 {children.map(details => {
//                     const {slug, display_name} = details
//                     return <li><a href={`${mainDomain}/${category}/${parentSlug}/${slug}`}> {display_name} </a></li>
//                 })}
//             </ul>
//         </Fragment>
//     })
// }

// function returnHeaderOnlyChildList(parentCategory) {
//     return Object.keys(parentCategory).map(parentSlug => {
//         const {display_name, children} = parentCategory[parentSlug]
//         return <Fragment>
//             <a className="dropdown-toggle" data-toggle="dropdown">{display_name}
//                 <i className="fa fa-chevron-down visible-xs"/>
//             </a>
//             <ul className="dropdown-menu">
//                 {children.map(details => {
//                     const {slug, display_name} = details
//                     return <li><a href={`${mainDomain}/${category}/${parentSlug}/${slug}`}> {display_name} </a></li>
//                 })}
//             </ul>
//         </Fragment>
//     })
// }

function returnHeaderOnlyChildListOnMobileWeb(parentCategory) {
    return Object.keys(parentCategory).map(parentSlug => {
        const {display_name, children} = parentCategory[parentSlug]
        return <Fragment>
            {children.map(details => {
                const {slug, display_name} = details
                return <li><a href={`${mainDomain}/${category}/${parentSlug}/${slug}`}> {display_name} </a></li>
            })}
        </Fragment>
    })
}

class SiteHeader extends Component {
    constructor(props) {
        super(props);
        const affiliateMarketingDetails = JSON.parse(sessionStorage.affiliateMarketingDetails) || {}
        let isHideDefaultHeader = false
        if (affiliateMarketingDetails.header_ui) {
            isHideDefaultHeader = affiliateMarketingDetails.header_ui && affiliateMarketingDetails.header_ui === showDefault.hh
        }
        this.state = {
            services: [],
            isLoginWitOtpRendered: false,
            loggedIn: localStorage.isSessionValid(),
            isHideDefaultHeader,
            isSubmitted: true
        }
    }

    componentDidMount() {
        const {isServicesDataPassed, services} = this.props;
        if (isServicesDataPassed) {
            this.setState({services})
        } else {
        }
        megaMenu();
        fixedMobileMenu()
    }

    componentWillUnmount() {
        $(document).off("scroll", fixedMobileMenu());
    }

    onLoginClicked = () => {
        const {history} = this.props
        if (localStorage.isSessionValid()) {
            history.push(path.userLogin)
        } else {
            this.setState({isLoginWitOtpRendered: true}, () => {
                if ($('#login_with_otp_pop_up_id')) {
                    $('#login_with_otp_pop_up_id').click()
                } else {
                    history.push(path.userLogin)
                }
            })
        }
    }

    logout = ({isNavbarToggling}) => {
        const {onLogout, doDispatch} = this.props
        if (onLogout) {
            this.setState({loggedIn: false}, () => {
                if (localStorage.fcmTokenId) {
                    apiv1.auth.delete(`${apiUrl.customFcmToken}${localStorage.fcmTokenId}`)
                        .then(resp => {
                            apiv1.parseAndAutoHandleIssues(resp, doDispatch)
                            onLogout(sessionLogout())
                            localStorage.clear();
                        }).catch(err => {
                        this.setState({loading: false})
                        apiv1.handleErrors(err, doDispatch)
                    })
                } else {
                    onLogout(sessionLogout())
                }
                if (isNavbarToggling) {
                    $(".navbar-toggle").click();
                }
            })
        }
    }

    render() {
        const {isLoginWitOtpRendered, isHideDefaultHeader} = this.state
        const {loggedInUserDetails} = this.props
        // const {history} = this.props
        // const {location} = history
        return (
            <Fragment>
                <header id="service-global-header"
                        className={!isHideDefaultHeader ? 'with-second-nav-margin' : 'header-bottom-margin'}>
                    {/*{pathname === path.root && <MsmeHelpStrip isDisplay={true}/>}*/}
                    <SiteLogoHeader {...this.props}
                                    onLoginClicked={this.onLoginClicked}
                                    logout={this.logout}
                                    isShowDefaultHeader={!isHideDefaultHeader}
                                    loggedInUserDetails={loggedInUserDetails}

                    />
                    {/*{!isHideDefaultHeader &&*/}
                    {/*<nav className="main-header navbar navbar-default navbar-fixed-top secondary-nav hidden-xs">*/}
                        {/*<div className="container-fluid">*/}
                            {/*<ul className="nav navbar-nav nav-justify-content-center">*/}
                                {/*<li className="dropdown mega-dropdown">*/}
                                    {/*<a className="dropdown-toggle" data-toggle="dropdown">Start your business</a>*/}
                                    {/*<ul className="dropdown-menu mega-dropdown-menu">*/}
                                        {/*<div className="container">*/}
                                            {/*<div className="row">*/}
                                                {/*<div className="col-sm-4">*/}
                                                    {/*{returnHeaderParentAndChildList(businessRegistrationServices)}*/}
                                                {/*</div>*/}
                                                {/*<div className="col-sm-4">*/}
                                                    {/*{returnHeaderParentAndChildList(businessClouserServices)}*/}
                                                    {/*{returnHeaderParentAndChildList(trademarkRegistrationServices)}*/}
                                                {/*</div>*/}
                                                {/*<div className="col-sm-4">*/}
                                                    {/*{returnHeaderParentAndChildList(businessLicenseServices)}*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}

                                    {/*</ul>*/}
                                {/*</li>*/}
                                {/*<li className="dropdown">*/}
                                    {/*{returnHeaderOnlyChildList(rocComplianceServices)}*/}
                                {/*</li>*/}
                                {/*<li className="dropdown mega-dropdown">*/}
                                    {/*<a className="dropdown-toggle" data-toggle="dropdown">Tax & Accounting</a>*/}
                                    {/*<ul className="dropdown-menu mega-dropdown-menu">*/}
                                        {/*<div className="container">*/}
                                            {/*<div className="row">*/}
                                                {/*<div className="col-sm-6 col-md-4">*/}
                                                    {/*{returnHeaderParentAndChildList(incomeTaxServices)}*/}
                                                {/*</div>*/}
                                                {/*<div className="col-sm-6 col-md-4">*/}
                                                    {/*{returnHeaderParentAndChildList(taxAccountingServices)}*/}
                                                    {/*{returnHeaderParentAndChildList(tdsServices)}*/}
                                                {/*</div>*/}
                                                {/*<div className="col-sm-6 col-md-4">*/}
                                                    {/*{returnHeaderParentAndChildList(taxGstServices)}*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                    {/*</ul>*/}
                                {/*</li>*/}
                                {/*<li className="dropdown mega-dropdown">*/}
                                    {/*<a className="dropdown-toggle" data-toggle="dropdown">Graphics & Video</a>*/}
                                    {/*<ul className="dropdown-menu mega-dropdown-menu">*/}
                                        {/*<div className="container">*/}
                                            {/*<div className="row">*/}
                                                {/*<div className="col-sm-4">*/}
                                                    {/*{returnHeaderParentAndChildList(businessAndBrandServices)}*/}
                                                    {/*{returnHeaderParentAndChildList(printDesignServices)}*/}
                                                {/*</div>*/}
                                                {/*<div className="col-sm-4">*/}
                                                    {/*{returnHeaderParentAndChildList(socialMarketingService)}*/}
                                                    {/*{returnHeaderParentAndChildList(packagingandLabelServices)}*/}
                                                    {/*{returnHeaderParentAndChildList(photoshopEditingServices)}*/}
                                                {/*</div>*/}
                                                {/*<div className="col-sm-4">*/}
                                                    {/*{returnHeaderParentAndChildList(uiDesignServices)}*/}
                                                    {/*{returnHeaderParentAndChildList(videoAnimationServices)}*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}

                                    {/*</ul>*/}
                                {/*</li>*/}
                                {/*<li className="dropdown">*/}
                                    {/*{returnHeaderOnlyChildList(onlineEcomServices)}*/}
                                {/*</li>*/}
                                {/*<li className="dropdown">*/}
                                    {/*{returnHeaderOnlyChildList(webAndAppServices)}*/}
                                {/*</li>*/}
                                {/*<li className="dropdown mega-dropdown">*/}
                                    {/*<a className="dropdown-toggle" data-toggle="dropdown">Content & Marketing</a>*/}
                                    {/*<ul className="dropdown-menu mega-dropdown-menu"*/}
                                        {/*style={{transform: 'translateX(0%)', right: '5%'}}>*/}
                                        {/*<div className="row">*/}
                                            {/*<div className="col-md-6">*/}
                                                {/*{returnHeaderParentAndChildList(webAndUXContent)}*/}
                                                {/*{returnHeaderParentAndChildList(blogsAndArticles)}*/}
                                            {/*</div>*/}
                                            {/*<div className="col-md-6">*/}
                                                {/*{returnHeaderParentAndChildList(translationAndProofreading)}*/}
                                                {/*{returnHeaderParentAndChildList(socialandCopywriting)}*/}
                                                {/*{returnHeaderParentAndChildList(digitalMarketingServices)}*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                    {/*</ul>*/}
                                {/*</li>*/}
                            {/*</ul>*/}
                        {/*</div>*/}
                    {/*</nav>*/}
                    {/*}*/}
                    <div id="site_header_drawer" className="menu-overlay"/>
                    <p id='login_with_otp_pop_up_id' data-toggle="modal" data-target="#login_with_otp_pop_up"/>
                    <div className="modal center-modal" id="login_with_otp_pop_up" role="dialog">
                        <LoginWithPhone {...this.props} emailButtonLoginName={'Login'} otpLoginBtnName={'Get OTP'}/>
                    </div>

                </header>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    loggedInUserDetails: getLoggedInUserDetails()
});

const mapDispatchToProps = (dispatch) => ({
    ...(errorActionDispatchers(dispatch)),
    onLogout: (action) => {
        dispatch(action);
    },
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteHeader))
