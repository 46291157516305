import React, {Fragment} from "react";
import * as path from "../../constants/path";
import GigzoeLogo from "../../static/images/gigzoe-logo.png";
import {professionalFlagColor, professionalBadge, siteBaseUrl, Workbeam, mainDomain} from "../../constants/common";
import OwlCarousel from "react-owl-carousel";
import {getFirstAndLastLetterOfName, workExperienceDesigner} from "../common/userAndProfessionalProfileFunctions";
import {GetFirstNameAndLastName, ParseHtmlToString, ShortDescription} from "../../functions/common";
import ReactPixel from "react-facebook-pixel"
import {App_env} from "../../api";
import MetaTags from "react-meta-tags"
import {Link} from "react-router-dom";
import {freelancers} from "../../constants/keyWord";
import ReactPlayer from 'react-player/vimeo'

function OnboardingPageHeading({currentScreen, questionAnswers}) {
    let subHeading = ''
    let heading = 'Create a Job'
    if (currentScreen === 2 && questionAnswers.length === 0) {
        heading = ''
    }
    if (currentScreen === 1) {
        subHeading = `Select Service and describe it.`
    } else if (currentScreen === 2) {
        if (questionAnswers.length > 0) {
            subHeading = `Answer these questions to let experts know the scope of work.`
        } else {
            subHeading = `Just a few details and you will start getting proposals.`
        }
    } else if (currentScreen === 3) {
        subHeading = `Just a few details and you will start getting proposals.`
    }
    return (
        <div className="col-sm-12">
            <h3 className="heading-onboarding text-center">
                {heading}
            </h3>
            <p className="sub-heading-onboarding text-center">
                {subHeading}
            </p>
        </div>
    )
}


function ratingUi(rating) {
    let starRating = [];
    for (let i = 0; i < rating; i++) {
        starRating.push(<span className="fa fa-star" key={i} />)
    }
    if (rating && rating.isInteger) {
        starRating.push(<span className="fa fa-star-half-o" key={5} />)
    }
    return starRating
}

function ratingUiWith_I_Tag(rating) {
    let starRating = [];
    for (let i = 0; i < rating; i++) {
        starRating.push(<i className="fa fa-star" key={i} />)
    }
    if (rating.isInteger) {
        starRating.push(<i className="fa fa-star-half-o" key={5} />)
    }
    return starRating
}

function OnboardingPageHeadingEdit() {
    return (
        <div className="col-sm-12">
            <h3 className="heading-onboarding text-center">Edit Job Posting</h3>
        </div>
    )
}

function DatePickerCustomInput(props) {
    const {value, onClick, placeholder} = props
    return (
        <input
            className={["uk-input"].join(" ")}
            onClick={onClick}
            value={value}
            placeholder={placeholder}
            type="text"
            readOnly={true}
        />
    )
}

export function Header({title, metaDescription, link, avatar, keyWord, isIndexing}) {
    avatar = avatar || ''
    if (isIndexing !== false){
        isIndexing = process.env.REACT_APP_ENV === App_env.production
    }
    return (
        <MetaTags>
            <title>{title}</title>
            <meta name="title" content={title}/>
            <meta name="description" content={metaDescription}/>
            {/*<meta name='robots' content={`${isIndexing ? 'index, follow ' : 'noindex, nofollow'}`}/>*/}
            <meta name="url" content={link}/>
            <meta property="og:url" content={link}/>
            <meta property="fb:app_id" content="1886111634835962" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content={Workbeam}/>
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={metaDescription}/>
            <meta property="og:image:width" content="300"/>
            <meta property="og:image:height" content="300"/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:site" content="@Workbeam"/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={metaDescription}/>
            {avatar &&
                <Fragment>
                    <meta property="og:image" content={avatar}/>
                    <meta name="twitter:image" content={avatar}/>
                </Fragment>
            }
            <link rel="canonical" href={link} />
        </MetaTags>
    )
}

export default Header

function LogoHeader({history, isLogoLinkDisabled, children, clases, navPath}) {
    return (
        <nav className="navbar navbar-default navbar-fixed-top nav-shadow">
            <div className="container-fluid">
                <div className="navbar-header logged-in">
                    <button type="button" className={`navbar-toggle ${clases}`} data-toggle="collapse"
                            data-target="#fixedNavbarHelp">
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                    </button>
                    <a className="navbar-brand" onClick={() => {
                        if (!isLogoLinkDisabled && history) {
                            if (navPath){
                                window.location.href = mainDomain
                            } else {
                                history.push(path.root)
                            }
                        }
                    }}
                    >
                        <img src={GigzoeLogo} className="header-logo" alt="logo"/>
                    </a>
                </div>
                {children}
            </div>
        </nav>
    )
}

function CustomProductLogoHeader({children}) {
    return (
        <nav className="navbar navbar-default navbar-fixed-top nav-shadow">
            <div className="container-fluid">
                <div className="navbar-header logged-in">
                    <span className="navbar-brand">
                        <img src={require('../../static/images/GigStore.png')} className="gig-header-logo" alt="logo"/>
                    </span>
                    <span className="navbar-brand pull-right visible-xs">
                        <img src={GigzoeLogo} className="header-logo" alt="logo"/>
                    </span>
                </div>
                <div className="navbar-header logged-in pull-right hidden-xs">
                    <span className="navbar-brand">
                        <img src={GigzoeLogo} className="header-logo" alt="logo"/>
                    </span>
                </div>
                {children}
            </div>
        </nav>
    )
}

export function fbAdClickTrack() {
    ReactPixel.track('Giglead');
}

export function SamplesUi({samples, gigLink, productDisplayName, isOriginalShowing}) {
    return (
        samples.map((sampleDetails, index) => {
            let {sample, isPhoto, thumbnail, id, document_file_name, vimeo_link} = sampleDetails
            return (
                <div className="item" key={id}>
                    <a href={gigLink} target={`${gigLink ? '_blank' : '_self'}`}>
                        <div className="slider-crousel">
                            {isPhoto ?
                                <img src={isOriginalShowing ? sample : thumbnail} className="img-responsive"
                                     alt={productDisplayName ? productDisplayName : document_file_name || thumbnail}
                                />
                                :
                                <div className="embed-responsive embed-responsive-16by9">
                                        <ReactPlayer
                                            className='react-player'
                                            controls={true}
                                            pip={true}
                                            responsive={true}
                                            url={vimeo_link}
                                        />
                                    {/*<video controls controlsList="nodownload">*/}
                                        {/*<source src={sample}/>*/}
                                    {/*</video>*/}
                                </div>
                            }
                        </div>
                    </a>
                </div>
            )
        })
    )
}

export function BadgeUi({badge}) {
    const {name} = badge
    return (
        <Fragment>
            <div className="professional-badge">
                {name === professionalBadge.GigzoePreferred &&
                <div className="badge-gigzoe-preferred">
                    {name}
                </div>
                }
                {name === professionalBadge.Pro &&
                <div className="badge-pro">
                    {name}
                </div>
                }
                {name === professionalBadge.SuperPro &&
                <div className="badge-super-pro">
                    {name}
                </div>
                }
                {name === professionalBadge.Recommended &&
                <div className="badge-recommended">
                    {name}
                </div>
                }
            </div>
        </Fragment>
    )
}
export function AgencyBadge({isAgency}) {
    return (
            <div className="professional-badge hidden-xs">
                 <div className="agency-badge">
                     Agency
                 </div>
            </div>
    )
}
export function ChatResponceTime({chat_average_response_time}) {
   return(
       <Fragment>
           {chat_average_response_time < 60 ? <span>30 min</span> : chat_average_response_time < 180 ? <span>75 min</span> : chat_average_response_time < 360 ? <span>2 hrs</span> : chat_average_response_time < 720 ? <span>4 hrs</span> : <span>6 hrs</span>}
       </Fragment>
   )
}
export function MinimulBadgeUi({badge}) {
    const {name} = badge
    return (
        <div className="minimul-badge">
            {name === professionalBadge.GigzoePreferred &&
            <span className="badge-gigzoe-preferred-text">
                    {/*<i className="fa fa-bookmark" aria-hidden="true"></i>*/}
                {name}
                </span>
            }
            {name === professionalBadge.Pro &&
            <span className="badge-pro-text">
                    {/*<i className="fa fa-bookmark" aria-hidden="true"></i> */}
                {name}
                </span>
            }
            {name === professionalBadge.SuperPro &&
            <span className="badge-super-pro-text">
                    {/*<i className="fa fa-bookmark" aria-hidden="true"></i> */}
                {name}
                </span>
            }
            {name === professionalBadge.Recommended &&
            <span className="badge-recommended-text">
                    {/*<i className="fa fa-bookmark" aria-hidden="true"></i> */}
                {name}
                </span>
            }
        </div>
    )
}

export function ProfessionalFlag({flag, flag_note }) {
    return (
        <div className="flag-box">
            {flag === professionalFlagColor.YellowFlag &&
            <div className="yellow-flag">
                <p className="flag-text"><span className="flag-icon"><i className="fa fa-flag" /></span> This is your
                    1st warning of 3 warnings. After 3 warnings, your profile will be delisted and account money will be
                    forfeited. Flag will reset in 30 days if no more policy & platform violations are committed in next
                    30 days.</p>
                { flag_note && <p>{flag_note}</p> }
            </div>
            }
            {flag === professionalFlagColor.OrangeFlag &&
            <div className="orange-flag">
                <p className="flag-text"><span className="flag-icon"><i className="fa fa-flag" /></span> This is your
                    2nd warning of 3 warnings. After 3 warnings, your profile will be delisted and account money will be
                    forfeited. Flag will reset in 30 days if no more policy & platform violations are committed in next
                    30 days.</p>
                { flag_note && <p>{flag_note}</p> }
            </div>
            }
            {flag === professionalFlagColor.RedFlag &&
            <div className="red-flag">
                <p className="flag-text"><span className="flag-icon"><i className="fa fa-flag" /></span> This is your
                    3rd warning of 3 warnings. After 3 warnings, your profile will be delisted and account money will be
                    forfeited. Flag will reset in 30 days if no more policy & platform violations are committed in next
                    30 days.</p>
                { flag_note && <p>{flag_note}</p> }
            </div>
            }
            {flag === professionalFlagColor.BlackFlag &&
            <div className="black-flag">
                <p className="flag-text"><span className="flag-icon"><i className="fa fa-flag" /></span> Your profile
                    has been delisted and your account will be deactivated due to repeated policy and platform violations. Please reach out
                    to our support for help. </p>
                { flag_note && <p>{flag_note}</p> }
            </div>
            }
        </div>
    )
}

function ExperienceAndRatingUi({rating, work_experience, chat_average_response_time, job_completed_count, is_agency, badge}) {
    return (
        <ul className="list-inline text-left">
            {rating &&
            <li className="span-text">
            <span className="fa fa-star" /> <span className="span-text">{rating.toFixed(1) + ' '}</span>
            </li>
            }
            {work_experience &&
            <li className="span-text hidden-xs">
                <span>{workExperienceDesigner(work_experience)}</span>
            </li>
            }
            {job_completed_count > 0 &&
            <li className="span-text">
                {job_completed_count} Orders
            </li>
            }
            {chat_average_response_time > 0 &&
            <li className="span-text">
                <img src={require('../../static/images/chat-timer.png')} alt={chat_average_response_time} style={{width:'15px'}}/> <ChatResponceTime chat_average_response_time={chat_average_response_time}/>
            </li>}
            {is_agency &&
            <li className="span-text visible-xs">
                <img src={require('../../static/images/agency-icon.png')} alt={`Agency`}/> {is_agency && 'Agency'}
            </li>
            }
        </ul>
    )
}
function ExperienceAndRatingMobileUi({rating, chat_average_response_time, job_completed_count, is_agency}) {
    return (
        <ul className="list-inline text-left">
            {job_completed_count > 0 &&
            <li className="span-text">
                {job_completed_count} Orders
            </li>
            }
            {chat_average_response_time > 0 &&
            <li className="span-text">
                <img src={require('../../static/images/clock-line.png')}  alt={chat_average_response_time}/> <ChatResponceTime chat_average_response_time={chat_average_response_time}/>
            </li>}
            {is_agency &&
            <li className="span-text">
                <img src={require('../../static/images/agency-icon.png')}  alt={`Agency`}/> {is_agency && 'Agency'}
            </li>
            }
        </ul>
    )
}

function AvatarAndProDescription({avatar_thumbnail, first_name, last_name, city, rating, work_experience, chat_average_response_time, job_completed_count, is_agency, badge}) {
    return (
        <div className="media">
            <div className="media-left">
                {avatar_thumbnail ?
                    <img src={avatar_thumbnail} className="designer-dp img-circle"
                         alt="hire designer"/>
                    :
                    getFirstAndLastLetterOfName(first_name, last_name)
                }
            </div>
            <div className="media-body">
                <h4 className="designer-name">
                    <GetFirstNameAndLastName firstname={first_name}
                                             lastname={last_name}/>
                      <span className="pull-right">
                                {badge.priority > 0 && <span className="minimul-badge"><MinimulBadgeUi badge={badge}/></span>}
                           </span>
                </h4>
                <p className="designer-city"><span>{city}</span> {city && work_experience && <span className="visible-xs">&#x2022;</span>} {work_experience && <span className="visible-xs">{work_experience} Yrs Exp.</span>}</p>
            </div>
            <ExperienceAndRatingUi work_experience={work_experience} rating={rating} badge={badge} chat_average_response_time={chat_average_response_time} job_completed_count={job_completed_count} is_agency={is_agency}/>
        </div>
    )
}
function AvatarAndProDescriptionMobileUi({avatar_thumbnail, first_name, last_name, city, rating, work_experience, chat_average_response_time, job_completed_count, badge}) {
    return (
        <div className="media">
            {badge.priority > 0 ? <MinimulBadgeUi badge={badge}/> :
                <div className="minimul-badge hidden-xs" style={{marginBottom: '0px', marginTop: '0px'}}>
                    <span className="badge-gigzoe-new">
                    </span>
                </div>
            }
            <div className="media-left">
                {avatar_thumbnail ?
                    <img src={avatar_thumbnail} className="designer-dp img-circle"
                         alt="hire designer"/>
                    :
                    getFirstAndLastLetterOfName(first_name, last_name)
                }
            </div>
            <div className="media-body">

                <h4 className="designer-name">
                    <GetFirstNameAndLastName firstname={first_name}
                                             lastname={last_name}/>
                    <span className="pull-right"><span className="fa fa-star" /> <span className="span-text">{rating.toFixed(1) + ' '}</span></span>
                </h4>
                <p className="designer-city">{city} {city && work_experience && <span>&#x2022;</span>} {work_experience && <span>{work_experience} Yrs Exp.</span>}</p>
            </div>
        </div>
    )
}

function ContinueTODetailsUi({shareLink, price}) {
    return (
        <a href={shareLink} target="_blank"
           className="btn hire-designer-button btn-track-job"
           onClick={() => fbAdClickTrack()}
        title={`From $${price}`}>
            From ${price}
            <span className="pull-right">
                Discuss & Hire <i className="fa fa-long-arrow-right hidden-xs"/>
                                            </span>
        </a>
    )
}

function DeveloperCardUi({professionalProduct, professionalListIndex, productSlug, discountCouponCode}) {
    const {professional_details, samples, calculated_commissions} = professionalProduct
    const {price} = calculated_commissions
    const {avatar_thumbnail, first_name, last_name, rating, work_experience, city, is_agency, job_completed_count, chat_average_response_time, badge, slug_new} = professional_details
    // const {practise_area_slug} = product_details || {}
    let shareLink = siteBaseUrl.substring(0, siteBaseUrl.length - 1)
    shareLink += `/${freelancers}/${slug_new}/${productSlug}`
    if (discountCouponCode){
        shareLink += `?coupon_code=${discountCouponCode}`
    }
    return (
        <div className="col-sm-6 col-md-4" key={professionalListIndex}>
            <div className="gap-xs">
            <div className="designer-profile-card">
                <OwlCarousel
                    className="owl-theme"
                    loop={true}
                    dots={false}
                    margin={10}
                    nav={true}
                    navText={['<div class="fa fa-angle-left prev-slide" style="font-size:20px"></div>', '<div class="fa fa-angle-right prev-slide " style="font-size:20px"></div>']}
                    items={1}
                    responsiveClass={true}
                    autoplay={false}
                    // autoplayTimeout={3000}
                    autoplayHoverPause={true}
                    lazyLoad={true}
                >
                    <SamplesUi samples={samples} gigLink={shareLink}/>
                </OwlCarousel>
                {is_agency && <AgencyBadge/>}
                <a href={shareLink} target="_blank">
                    <div className="profile-card-body">
                        <AvatarAndProDescription avatar_thumbnail={avatar_thumbnail} first_name={first_name}
                                                 last_name={last_name}
                                                 city={city} badge={badge} rating={rating}
                                                 work_experience={work_experience}
                                                 job_completed_count={job_completed_count}
                                                 chat_average_response_time={chat_average_response_time}
                                                 is_agency={is_agency}/>
                    </div>
                    <div className="profile-card-footer">
                        <ContinueTODetailsUi price={price} shareLink={shareLink}/>
                    </div>
                </a>
            </div>
            </div>
            <a href={shareLink} target="_blank" className="hidden"
               onClick={() => fbAdClickTrack()}>
                <div className="m-media hidden-xs">
                    <div className="col-xs-4" style={{paddingRight: '0px'}}>
                        <OwlCarousel
                            className="owl-theme"
                            loop={false}
                            dots={false}
                            margin={5}
                            // nav={true}
                            // navText={['<div class="fa fa-angle-left prev-slide" style="font-size:20px"></div>', '<div class="fa fa-angle-right prev-slide " style="font-size:20px"></div>']}
                            items={1}
                            responsiveClass={true}
                            autoplay={false}
                            autoplayHoverPause={true}
                            lazyLoad={true}
                        >
                            <SamplesUi samples={samples}/>
                        </OwlCarousel>
                    </div>
                    <div className="col-xs-8">
                        <div className="profile-card-body">
                            <AvatarAndProDescriptionMobileUi avatar_thumbnail={avatar_thumbnail} first_name={first_name}
                                                     last_name={last_name}
                                                     city={city} badge={badge} work_experience={work_experience}
                                                     rating={rating}/>
                        </div>
                    </div>
                    <div className="clearfix"/>
                    <div className="col-xs-12">
                    <ExperienceAndRatingMobileUi chat_average_response_time={chat_average_response_time} job_completed_count={job_completed_count} is_agency={is_agency}/>
                    </div>
                    <div className="clearfix" />
                    <div className="m-media-footer">
                        <span className="service-price">From ${price}</span>
                        <a className="btn hire-designer-button btn-track-job">
                            <span>Discuss & Book</span></a>
                    </div>
                </div>
            </a>
        </div>
    )
}

function PlatformDeveloperCard({professionalProduct, professionalListIndex, productSlug, parentPracticeAreaSlug}) {
    const {professional_details, calculated_commissions, professional} = professionalProduct
    const {price} = calculated_commissions
    const {avatar_thumbnail, first_name, last_name, slug, rating, work_experience, bio_detailed, city, bio_caption, practise_area_details, review_count} = professional_details
    let shortBio = `${bio_detailed.substring(0, 200)}...`
    // let shareLink = siteBaseUrl.substring(0, siteBaseUrl.length - 1)
    // shareLink += `/${productSlug}/${parentPracticeAreaSlug}`
    // shareLink += `?professionalId=${professional}&fee=${price}&professional=${`${first_name}-${last_name}`}`
    return (
            <div className="platform-expert-card" key={professionalListIndex}>
                <div className="expert-details-box">
                <div className="media">
                    <div className="media-left media-middle">
                        {avatar_thumbnail ? (<img src={avatar_thumbnail} alt="user" className="skill-expert-dp" />) : getFirstAndLastLetterOfName(first_name, last_name)}

                    </div>
                    <div className="media-body">
                        <h4 className="expert-name">
                           <GetFirstNameAndLastName firstname={first_name} lastname={last_name}/>
                        </h4>
                        <p className="expert-bio"> <ShortDescription text={bio_caption} maxCharLength={25}/></p>
                        <p className="expert-city" style={{marginBottom:'2px'}}>{city}</p>
                        <ul className="list-inline expert-rating-reviews">
                            {work_experience !== null && (<li><span className="span-text">{work_experience} Yrs Exp.</span></li>)}
                            <li> <span className="span-text"><i className="fa fa-star" /> {rating.toFixed(1) + ' '}</span></li>
                            {review_count !== 0 && (<li><span className="span-text">{review_count} Reviews</span></li>)}
                        </ul>
                    </div>
                </div>
                    <p className="expert-brief-bio">
                        <ParseHtmlToString htmlContent={shortBio}/>
                    </p>
                <p className="search-list-head">Skills</p>
                <ul className="list-inline card-practice-area">
                    {practise_area_details.map((details, index) => {
                        const {name} = details
                        return (
                            <li key={index}>{name}</li>
                        )
                    })}
                </ul>
                </div>
                <div className="expert-see-details-box">
                    <div className="expert-btn">
                    <Link className="btn btn-see-expert-gig" to={`/professional/${slug}/profile`}
                          target='_blank'>
                        Message & Discuss
                    </Link>
                    <Link className="btn btn-see-expert-profile" to={`/professional/${slug}/profile`}
                          target='_blank'>
                        See Profile
                    </Link>
                    </div>
                </div>
            </div>
    )
}

export {
    OnboardingPageHeadingEdit,
    OnboardingPageHeading,
    ratingUi,
    ratingUiWith_I_Tag,
    DatePickerCustomInput,
    LogoHeader,
    CustomProductLogoHeader,
    DeveloperCardUi,
    PlatformDeveloperCard
}