import GigzoeLogo from "../../static/images/gigzoe-logo.png";
import React, {Component} from "react";
import * as path from '../../constants/path'
import {PROFESSIONAL} from '../../constants/auth'
import localStorage from '../../utils/localStorage'
import LoginWithPhone from "../common/LoginWithPhone";
import $ from "jquery";

class LogInSignUpHeader extends Component {
    onLoginClick = () => {
        const {location, history} = this.props
        const {search} = location
        if (localStorage.userType === PROFESSIONAL) {

            if (this.props.mode === 'login') {
                window.location = path.professionalRegistration
            } else {
                window.location = path.professionalLogin
            }
        } else {
            const {isRequestFreeProposal} = this.props.match.params
            if (this.props.mode === 'login') {
                history.push({
                    pathname: `${path.userRegistration}${isRequestFreeProposal !== undefined && isRequestFreeProposal ? '/' + isRequestFreeProposal : ''}`,
                    search,
                })
            } else {
                history.push({
                    pathname: `${path.userLogin}${isRequestFreeProposal !== undefined && isRequestFreeProposal ? '/' + isRequestFreeProposal : ''}`,
                    search,
                })
            }
        }
    };
    onLoginClicked = () => {
        const {history} = this.props
        if (localStorage.isSessionValid()) {
            history.push(path.userLogin)
        } else {
            this.setState({isLoginWitOtpRendered: true}, () => {
                if ($('#login_with_otp_pop_up_id_signup')) {
                    $('#login_with_otp_pop_up_id_signup').click()
                } else {
                    history.push(path.userLogin)
                }
            })
        }
    }
    render() {
        const LoginSignUp = this.props.mode === 'login' ? 'SIGN UP' : 'LOGIN';
        const LoginSignUpText = this.props.mode === 'login' ? 'Don’t have an account ?' : 'Already have an account ?';

        return (
            <div className="row">
                <div className="col-xs-2">
                    <a href="/">
                        <img src={GigzoeLogo}
                             className="inside-logo"
                             alt='Logo'
                        />
                    </a>
                </div>
                <div className="col-sm-10 login-page-header">
                    <div className="pull-right">

                        <ul className="list-inline">
                            <li className="hidden-xs">
                                <span style={{marginRight: '3.5px'}}>{LoginSignUpText}</span>
                            </li>
                            <li>
                                {this.props.mode === 'login' ?
                                    <a
                                        className="btn btn-login"
                                        style={{marginLeft: '10px'}}
                                        href={path.professionalRegistration}
                                    >{LoginSignUp}</a>
                                    :
                                    <a
                                        className="btn btn-login"
                                        style={{marginLeft: '10px'}}
                                        onClick={this.onLoginClicked}
                                    >{LoginSignUp}</a>
                                }

                            </li>
                        </ul>
                    </div>
                </div>
                <p id='login_with_otp_pop_up_id_signup' data-toggle="modal" data-target="#login_with_otp_pop_up_id_signup_pop_up"/>
                <div className="modal center-modal" id="login_with_otp_pop_up_id_signup_pop_up" role="dialog">
                    <LoginWithPhone {...this.props} emailButtonLoginName={'Login'} otpLoginBtnName={'Get OTP'}/>
                </div>
            </div>
        )
    }
}

export default (LogInSignUpHeader)