
function htmlToString(text) {
    let doc = new DOMParser().parseFromString(text, 'text/html')
    return doc.body.textContent || ""
}



module.exports = {
    htmlToString,
}


