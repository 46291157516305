import {App_env} from "../api";

export const assignTaskToThisProfessional = {
    professionalId: process.env.REACT_APP_ENV === App_env.production ? 1004 : 11,
    first_name: 'Awaiting',
    useId: process.env.REACT_APP_ENV === App_env.production ? 2037 : 39,
}

export const superAdmin = {
    userId: process.env.REACT_APP_ENV === App_env.production ? 32 : 1
}

