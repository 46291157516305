export const requestProposals = 'Talk to Sales Team'
export const becomeAGigzoeAffiliate = 'Become a gigzoe affiliate'
export const requestQuote = 'Talk to our Sales Team'
export const becomeExpert = 'Apply as a Professional'
export const online = 'online';
export const consultation = 'consultation';
export const completing = 'completing';
export const partnership = 'partnership'
export const standardPackage = 'package'
export const community = 'community'
export const hire = 'hire'
export const developer = 'developer'
export const _in_ = '-in-'
export const best = 'best'
export const freelancers = 'freelancers'
export const category = 'categories'
export const jobs = 'jobs'