
class UserJobsHolder {
    constructor() {
        this._suggestedProduct = []
        this._postedJobs = []
        this._inProgressJob = []
        this._allProduct = []
    }

    set suggestedProduct(product) {
        this._suggestedProduct = product
    }

    get suggestedProduct () {
        return this._suggestedProduct || []
    }

    set postedJobs(posted) {
        this._postedJobs = posted || []
    }

    get postedJobs () {
        return this._postedJobs || []
    }

    set jobInProgressData(inProgressData) {
        this._inProgressJob = inProgressData || []
    }

    get jobInProgressData () {
        return this._inProgressJob || []
    }

    get allProduct () {
        return this._allProduct || []
    }

    set allProduct(products) {
        this._allProduct = products || []
    }

    updatePostedJob({jobId, jobData}) {
        jobId = jobId ? Number(jobId) : ''
        let IndexOfJob = this._postedJobs.findIndex(jobDetails => jobDetails.id === jobId)
        if (IndexOfJob > -1){
            this._postedJobs[IndexOfJob] = jobData
        }
    }

    removeJonInProgressJob({jobId}){
        this._inProgressJob.splice(this._inProgressJob.findIndex(jobDetails => jobDetails.id === jobId))
            // = this._inProgressJob.filter(jobDetails => jobDetails.id === jobId)
    }

}

const instance = new UserJobsHolder()
export default instance


