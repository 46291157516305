export const iWillText = 'I will'
export const imageType = 'image'
export const videoType = 'video'
export const imageOrVideo = 'image_or_video'
export const imageOrVideoOrDoc = 'image_or_video_or_doc'
export const _preview = '_preview'
export const sample = 'sample'
export const _base64 = '_base64'
export const _file_name = '_file_name'
export const external_link_ = 'external_link_'
export const _is_photo = '_is_photo'
export const _thumbnail = '_thumbnail'
export const description_const = 'description'
export const avg_price_const = 'avg_price'
export const price_const = 'price'
export const delivery_time_const = 'delivery_time'
export const NONE = 'NONE'
export const remove = 'remove'
export const desc = 'desc'
export const base_multiplier = 'base_multiplier'
export const video_gig_convince = 'video_gig_convince'
export const _active = '_active'
export const is = 'is'
export const _standard = '_standard'
export const _premium = '_premium'

export const priceType = [
    {
        service: 'Basic',
        price_type: '',
        delivery_type: '_base',
        multiplier: 'base_multiplier'
    },
    {
        service: 'Standard',
        price_type: '_standard',
        delivery_type: '_standard',
        multiplier: 'standard_multiplier'
    },
    {
        service: 'Premium',
        price_type: '_premium',
        delivery_type: '_premium',
        multiplier: 'premium_multiplier'
    }
]
export const noOfSamples = [1, 2, 3, 4, 5, 6, 7, 8]
export const noOfCoverImages = [1, 2, 3, 4]
export const noOfConvincingVideo = [1]
export const noOfExternalLink = [1, 2, 3, 4, 5, 6, 7, 8]
export const deliveryDay = Array(30).fill().map((e, i) => i + 1);
