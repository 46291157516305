import React, {Fragment} from "react";
// export function ServicePageTab({children}) {
//     return(
//         <ul className="nav nav-tabs tab-bar" role="tablist">
//
//             {/*<li role="presentation" className="active"><a href="#Expert_tab" aria-controls="Expert_tab" role="tab" data-toggle="tab">*/}
//             {/*    <span>Experts</span></a></li>*/}
//             {/*<li role="presentation"><a href="#Info_guid" aria-controls="profile" role="tab" data-toggle="tab">*/}
//             {/*    <span>Info Guide</span></a></li>*/}
//             {/*<li role="presentation"><a href="#public_reviews" aria-controls="public_reviews" role="tab" data-toggle="tab">*/}
//             {/*    <span>Reviews</span></a></li>*/}
//             {/*<li role="presentation"><a href="#wc_assurance" aria-controls="wc_assurance" role="tab" data-toggle="tab">*/}
//             {/*    <span>WC Assurance</span></a></li>*/}
//             <li className="active"><a href="#Expert_tab">
//                 <span>Package</span></a></li>
//             <li className="active"><a href="#Expert_tab">
//                 <span>Experts</span></a></li>
//             <li><a href="#Info_guid">
//                 <span>Info Guide</span></a></li>
//             <li><a href="#public_reviews" >
//                 <span>Reviews</span></a></li>
//             <li><a href="#wc_assurance">
//                 <span>WC Assurance</span></a></li>
//             {children}
//         </ul>
//     )
// }

export function OldServicePageTab({children}) {
    return (
        <ul className="nav nav-tabs tab-bar filter-tab" role="tablist">

            {/*<li role="presentation" className="active"><a href="#package" aria-controls="Expert_tab" role="tab" data-toggle="tab">*/}
            {/*<span>Package</span></a></li>*/}
            {/*<li role="presentation"><a href="#our_expert" aria-controls="profile" role="tab" data-toggle="tab">*/}
            {/*<span>Our Expert</span></a></li>*/}
            {/*<li role="presentation"><a href="#FAQS" aria-controls="public_reviews" role="tab" data-toggle="tab">*/}
            {/*<span>FAQS</span></a></li>*/}

            {children}
        </ul>
    )
}

export function serviceFirstSection(headingText, subheadingText, backgroundImage, startingPriceText, image) {
    return (
        <div className="container">
            <div className="row">
                {/*<div className="imageBottomRight hidden-xs">*/}
                {/*    <img src={image} alt="find legal solution"/>*/}
                {/*</div>*/}
                <div className="col-sm-11">
                    <h1 className="banner-text-heading">
                        {headingText}
                    </h1>
                    <p className="text-white-para" style={{color: '#000'}}>
                        {subheadingText}
                    </p>
                    <p className="text-white-para" style={{color: '#000'}}>
                        <b>Have Questions? <br className="visible-xs"/>Talk to an Expert now at <a
                            href="tel:+91-9354920614" style={{color: '#ff337d'}}>+91 9354-920-614</a></b>
                    </p>
                    {/*<ul className="list-inline assurance-list hidden-xs">*/}
                    {/*    <li><span>&#10003;</span> Verified Experts</li>*/}
                    {/*    <li><span>&#10003;</span> Save 60% cost</li>*/}
                    {/*    <li><span>&#10003;</span> Dedicated Support</li>*/}
                    {/*    <li><span>&#10003;</span> Track work delivery</li>*/}
                    {/*    <li><span>&#10003;</span> 100% Money Back</li>*/}
                    {/*</ul>*/}
                    {/*<p className="text-white-para" style={{color:'#000'}}*/}
                    {/*id='intercom_clicked_id'*/}
                    {/*>*/}
                    {/*<a>Support? <br className="visible-xs"/> call now <a href="tel:+91-9711169362" style={{color:'#ff337d'}}>+91 9711-169-362</a></a>*/}
                    {/*</p>*/}
                </div>
            </div>
        </div>
    )
}

export function serviceFirstSectionAdd({headingText, subheadingText, backgroundColor, backgroundImage, startingPriceText, hashTag, userRating, userCount, image, bannerImage}) {
    return (
        <div className={bannerImage ? '' : 'row'}>
            <div className={`col-md-${bannerImage ? `8 col-sm-7` : 12}`}>
                <h1 className="banner-text-heading">
                    {headingText}
                    {bannerImage &&
                    <span className="label label-warning hidden-xs">{userRating} &#x00A0;<i
                        className="fa fa-star"></i> &#x00A0;{userCount}+ Booked</span>
                    }
                </h1>
                <p className="text-white-para">{subheadingText}</p>
                {!bannerImage &&
                <p className="banner-text-sub-heading"><b>{`Why book on Workbeam?`}</b></p>
                }
                {bannerImage &&
                <Fragment>
                    <p className="text-white-para">
                        <b>{hashTag}</b>
                    </p>
                    <p className="visible-xs text-center">
                        <span className="label label-warning">{userRating} &#x00A0;<i
                            className="fa fa-star"></i> &#x00A0;{userCount}+ Booked</span></p>
                </Fragment>
                }
            </div>
            {bannerImage &&
            <div className="col-md-4 col-sm-5 hidden-xs">
                <img src={bannerImage} alt="gigzoe"/>
            </div>
            }
        </div>

    )
}
