import {
    PUSH_ERROR,
    POP_ERROR,
    CLEAR_ERRORS,
    CLEAR_AND_SHOW_ERROR,
    CLEAR_AND_SHOW_POPUP
} from '../constants/errors'

export default function errors (state = {currentId: 0, currentError: null, queue: [], popupErrId: 0}, action) {
    switch (action.type) {
        case PUSH_ERROR:
            if (state.currentId <= 0) {
                return {
                    currentId: (new Date().getTime()),
                    currentError: action.payload,
                    queue: []
                }
            } else {
                return {
                    currentId: state.currentId,
                    currentError: state.currentError,
                    queue: [
                        ...state,
                        action.payload
                    ]
                }
            }
        case POP_ERROR:
            if (state.queue.length <= 0) {
                return {
                    currentId: 0,
                    currentError: null,
                    queue: []
                }
            } else {
                return {
                    currentId: (new Date().getTime()),
                    currentError: state.queue[0],
                    queue: state.queue.slice(1)
                }
            }
        case CLEAR_AND_SHOW_ERROR:
            return {
                currentId: (new Date().getTime()),
                currentError: action.payload,
                queue: []
            };
        case CLEAR_ERRORS:
            return {
                currentId: 0,
                currentError: null,
                queue: []
            };
        case CLEAR_AND_SHOW_POPUP:
            return {
                popupErrId: (new Date().getTime()),
                currentId: 0,
                currentError: action.payload,
                queue: []
            };
        default:
            return state
    }
}
