import React, {Component, Fragment} from "react";
import SeeFullJobDetails from './SeeFullJobDetails'
import '../../static/js/myjs'
import {errorActionDispatchers} from "../../actions/errors";
import connect from "react-redux/es/connect/connect";
import {apiv1} from "../../api";
import * as apiUrl from "../../api/apiUrls"
import TimeAgo from "react-timeago";
import LoadingComponent from "../../utils/LoadingComponent";
import {Link} from "react-router-dom";
import PaginationWithArrow from "../common/PaginationWithArrow";
import * as path from './../../constants/path'
import {getAvatar, getFirstAndLastLetterOfName} from "../common/userAndProfessionalProfileFunctions";
import {PROPOSAL_SENT} from "./SendProposal";
import queryString from "query-string";
import {GetFirstNameAndLastName} from "../../functions/common";
import $ from "jquery";
import SearchByUserName from "../admin/SearchByUserName";
import {USER} from "../../constants/auth";
import localStorage from './../../utils/localStorage'
import {status} from "../../constants/job";
import renderHTML from "react-render-html";


export function proposalSentAndMessageTabs(tabNo = 1) {
    return (
        <div className="row">
            <div className="col-sm-12">
                <ul className="nav nav-tabs">
                    <li className={tabNo === 1 ? 'active' : ''}>
                        <Link to={`/proposal-sent`}>
                            PROPOSALS SENT
                        </Link>
                    </li>
                    <li className={"col-xs-offset-1" + (tabNo === 2 ? ' active' : '')}>
                        <Link to={path.professionalChatHistory}>
                            MESSAGE
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

class ProfessionalProposalSentList extends Component {
    constructor(props) {
        super(props);
        // const {page, is_live, jobTitle, jobId} = queryString.parse(props.location.search)
        const {history} = this.props
        let {location} = history
        let {search} = location
        let {page, is_live, jobId, searched} = queryString.parse(search)
        searched = searched ? searched : ''
        this.api = apiv1
        this.state = {
            data: props.data,
            proposalList: [],
            loading: false,
            jobMilestone: [],
            jobQuestions: [],
            currentProposal: {},
            page: page !== undefined ? Number(page) : 1,
            limit: 10,
            total: 0,
            shouldPaymentTermsRender: false,
            selectedProposalId: '',
            is_live: is_live !== undefined ? is_live : '',
            // jobTitle: jobTitle !== undefined ? jobTitle: '',
            // searchedProposal: []
            jobList: [],
            jobId: jobId,
            searched
        }
    }

    componentDidMount() {
        const {page, jobTitle} = this.state
        const {is_super_user} = this.props
        this.getProposalList(page, false)
        // if (jobTitle) {
        //     this.getProposalByTitle(jobTitle)
        // }
        if (is_super_user) {
            // this.getJobsForAdmin()
        }
    }

    componentWillReceiveProps(nextProps) {
        const {is_super_user, data,} = nextProps
        const {professional} = data
        if (!is_super_user && professional && professional.id && professional.id !== this.state.data.professional.id) {
            this.setState({data: nextProps.data})
        }
    }

    updateList = ({searchedList, count, searched}) => {
        this.setState({postedJobs: searchedList, totalRecord: count, loading: false, searched}, () => {
            this.resetToDefault({searched})
        })
    }

    resetToDefault = ({searched}) => {
        if (!searched) {
            this.setState({searched}, () => {
                this.getProposalList(1)
            })
        } else {
            this.getProposalList(1)
        }
        const {history} = this.props
        let {pathname, location} = history
        let {search} = location
        let {is_live} = queryString.parse(search)
        is_live = is_live ? is_live : ''
        searched = searched ? searched : ''
        search = `?is_live=${is_live}&search=${searched}`
        history.replace({
            pathname,
            search
        })
    }

    getProposalList = (page) => {
        const {data, limit, is_live, jobId, searched} = this.state;
        const {is_super_user} = this.props
        let url = `/job/proposal-professional/?page=${page}&limit=${limit}`
        if (searched) {
            url += `&search=${searched}`
        }
        if (!is_super_user) {
            let professionalId = localStorage.professionalId || ''
            url += `&sender=${professionalId}`
        }
        if (is_live) {
            url += `&is_live=${is_live}`
        }
        if (jobId) {
            url += `&job=${jobId}`
        }
        this.setState({loading: true});
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    const {results, count} = data
                    this.setState({loading: false, proposalList: results, total: count, page})
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })

    };
    getJobMilestoneAndQuestionDetails = (job_id) => {
        this.setState({getDetails: false});
        const url = `/job/job-details-hiring-summary/${job_id}/`;
        this.api.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                //console.log(data)
                if (data) {
                    this.setState({loading: false, jobMilestone: data.job_milestone, jobQuestions: data.job_question})
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    };
    getCurrentProposalDetails = (proposalId) => {
        this.setState({getDetails: false});
        const url = `job/proposal-professional/${proposalId}/`;
        this.api.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    this.setState({loading: false, currentProposal: data})
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    getJobsForAdmin = () => {
        const url = `${apiUrl.proposalListWithUserDetails}`
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    this.setState({jobList: data})
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    // getProposalByTitle = (jobTitle) => {
    //     const url = `${proposalFilterByTitle}?job__title__startswith=${jobTitle}`
    //     apiv1.auth.get(url)
    //         .then(resp => {
    //             const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
    //             if (data) {
    //                 this.setState({ loading: false, searchedProposal: data })
    //             } else {
    //                 this.setState({ loading: false })
    //             }
    //         })
    //         .catch(err => {
    //             this.setState({ loading: false });
    //             apiv1.handleErrors(err, this.props.doDispatch)
    //         })
    // }

    // searchProposalByTitle = () => {
    //     const {jobTitle, searchedProposal} = this.state
    //     return(
    //         <div className="custom-search-box">
    //             <div className="searchbar">
    //                 <input className="search_input"
    //                        style={{width:'90%'}}
    //                        type="text"
    //                        name="jobTitle"
    //                        placeholder="Search..."
    //                        value={jobTitle}
    //                        onChange={(e) => {
    //                            e.preventDefault()
    //                            const {name, value} = e.target
    //                            let searchedProposal = [...this.state.searchedProposal]
    //                            if (!value.trim()){
    //                                searchedProposal = []
    //                            }
    //                            this.setState({[name]: value, searchedProposal}, () => {
    //                                if (value){
    //                                    this.getProposalByTitle(value)
    //                                }
    //                            })
    //                        }}
    //                        autocomplete="off"
    //                 />
    //                 <span className="search_icon">
    //                                         <i className="fa fa-search"
    //                                            onClick={(e) => {
    //                                                e.preventDefault()
    //                                                if (jobTitle){
    //                                                    this.getProposalByTitle(jobTitle)
    //                                                }
    //                                            }
    //                                            }
    //                                         >
    //
    //                                         </i>
    //                                     </span>
    //             </div>
    //             {searchedProposal.length > 0 &&
    //             <div className="custom-search-box-item">
    //                 <ul className="list-group">
    //                     {searchedProposal.map(proposalByTitle => {
    //                         const proposalId = proposalByTitle.id
    //                         const {job_details, sender_detail} = proposalByTitle
    //                         const {user, title, id} = job_details
    //                         const {first_name, last_name, email, avatar} = sender_detail
    //                         return(
    //                             <li className="list-group-item">
    //                                 <a onClick={() => {
    //                                     const {history, location} = this.props
    //                                     let {search, pathname} = location
    //                                     search = search + '&jobTitle=' + title
    //                                     const indexOfPageNo = search.indexOf('&page=')
    //                                     search = (indexOfPageNo > -1 ? search.substring(0, indexOfPageNo) : search) + `&page=${1}`
    //                                     history.replace({
    //                                         pathname,
    //                                         search,
    //                                     })
    //                                     history.push(`${path.sendProposal}?proposalId=${proposalId}&jobId=${id}&jobTitle=${title}&STATUS=${PROPOSAL_SENT}&firstName=${user.first_name}&lastName=${user.last_name}`)
    //                                 }}
    //                                 >
    //                                     <p style={{color:'#ff337d'}}>{title}</p>
    //                                     <p>
    //                                     {'Client : ' + user.first_name + ' ' + user.last_name}
    //                                 </p>
    //                                     <p>{'Professional : ' + first_name + ' ' + last_name}</p>
    //                                     <p>{'Professional Email : ' + email}</p>
    //                                 </a>
    //                             </li>
    //                         )
    //                     })}
    //                 </ul>
    //             </div>}
    //
    //         </div>
    //     )
    // }


    filterStripUi = () => {
        const {jobList, jobId, page} = this.state
        return (
            <section className="filter-strip">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <form className="filter-form-strip">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <select className="form-control"
                                                name='jobId'
                                                id='selected_job'
                                                onChange={(e) => {
                                                    const {name, value} = e.target
                                                    this.setState({[name]: value}, () => {
                                                        this.getProposalList(1, true)
                                                        const {history, location} = this.props
                                                        let {search, pathname} = location
                                                        // search = search + '&jobId=' + proposal.job
                                                        const indexOfPageNo = search.indexOf('&page=')
                                                        const indexOfJobId = search.indexOf('&jobId=')
                                                        search = (indexOfJobId > -1 ? search.substring(0, indexOfJobId) : search) + `&jobId=${value}`
                                                        search = (indexOfPageNo > -1 ? search.substring(0, indexOfPageNo) : search)
                                                        history.replace({
                                                            pathname,
                                                            search,
                                                        })
                                                    })
                                                }}
                                        >
                                            <option selected={true} value={-1} disabled>Filter by Job</option>
                                            {jobList.map((jobDetails => {
                                                const {user, title, id} = jobDetails
                                                const {email, first_name, last_name} = user
                                                return <option selected={id === Number(jobId)} value={id} key={id}>
                                                    {`${title},  ${first_name} ${last_name}, ${email} `}
                                                </option>
                                            }))}
                                        </select>
                                    </div>
                                </div>
                                <a className='btn hidden-xs btn-filter-apply'
                                   onClick={() => {
                                       this.setState({jobId: ''}, () => {
                                           this.getProposalList(1, false)
                                           $('#selected_job').val(-1)
                                           const {history, location} = this.props
                                           let {search, pathname} = location
                                           const indexOfPageNo = search.indexOf('&page=')
                                           const indexOfJobId = search.indexOf('&jobId=')
                                           search = indexOfJobId > -1 ? search.substring(0, indexOfJobId) : search
                                           search = (indexOfPageNo > -1 ? search.substring(0, indexOfPageNo) : search)
                                           history.replace({
                                               pathname,
                                               search,
                                           })
                                       })
                                   }}
                                >Reset</a>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        )
    }


    singleProposal = (proposal) => {
        const {id, job_details, professional_details, job, created, about_professional, send_notification_to_all} = proposal
        let {title, user_details, upper_tags, lower_tags, description} = job_details || {}
        user_details = user_details || {}
        let {client} = user_details
        client = client || {}
        let avatar = getAvatar(client.avatar)
        let firstName = user_details.first_name;
        let lastName = user_details.last_name;
        const {bio_caption} = professional_details
        const {is_super_user} = this.props
        const {jobId, page} = this.state
        // const {shouldPaymentTermsRender, selectedProposalId} = this.state
        return (
            <Fragment>
                <div className="job-posting-panel" key={id}>
                    <div className="job-posting-panel-body">
                        <span className="posted-success"><i className="fa fa-check"/> Applied</span>
                        <h4 className="order-posted-heading">{title}
                        </h4>
                        <p className="order-posted-date">
                            <TimeAgo date={created}/>
                        </p>
                        <ul className="list-inline about-expert-stats">
                            {upper_tags && upper_tags.map((tags, index)=>{
                                return(
                                    <li key={index}>
                                        <div className="e-s-bubble">{tags}</div>
                                    </li>
                                )
                            })}
                        </ul>
                        <p className="job-description">{renderHTML(description)}</p>
                        <ul className="list-inline about-expert-stats">
                            {lower_tags && lower_tags.map((skillData, index)=>{
                                return(
                                    <li key={index}><div className="e-s-bubble" style={{background:'#FBF0EE'}}>{skillData}</div></li>
                                )
                            })}
                        </ul>
                        {/*<div className="media posting-flex-box">*/}
                            {/*<div className="media-left media-middle">*/}
                                {/*{avatar ? (<img src={avatar} alt="user" className="img-circle p-expert-feed-dp"/>)*/}
                                    {/*:*/}
                                    {/*getFirstAndLastLetterOfName(firstName, lastName)*/}
                                {/*}*/}
                            {/*</div>*/}
                            {/*<div className="media-body">*/}
                                {/*<h4 className="p-expert-name">*/}
                                    {/*<GetFirstNameAndLastName firstname={firstName} lastname={lastName}/>*/}
                                {/*</h4>*/}
                            {/*</div>*/}
                        {/*</div>*/}


                        {is_super_user
                        &&
                        (
                            <Fragment>
                                <p className="cover-heading">Professional</p>
                                <div className="media posting-flex-box">
                                    <div className="media-left media-middle">
                                        {professional_details.avatar_thumbnail ? (
                                                <img src={professional_details.avatar_thumbnail} alt="user"
                                                     className="img-circle p-expert-feed-dp"/>)
                                            :
                                            getFirstAndLastLetterOfName(professional_details.first_name, professional_details.last_name)
                                        }
                                    </div>
                                    <div className="media-body">
                                        <h4 className="p-expert-name">
                                            <GetFirstNameAndLastName firstname={professional_details.first_name}
                                                                     lastname={professional_details.last_name}/>
                                        </h4>
                                        <p className="p-expert-bio">{bio_caption}</p>
                                    </div>
                                </div>
                                <p className="cover-heading">
                                    {send_notification_to_all ?
                                        <img src={require('../../static/images/success.png').default}
                                             style={{width: '20px'}}/> : 'Not Live'}
                                </p>
                            </Fragment>

                        )}
                        {/*<ul className="list-inline job-posting-ul">*/}
                            {/*/!*<li>*!/*/}
                            {/*/!*<a href="#" data-toggle="modal" style={{color: '#ff337d'}}*!/*/}
                            {/*/!*className="btn btn-link"*!/*/}
                            {/*/!*data-target={'#' + id}*!/*/}
                            {/*/!*onClick={(e) => this.getJobMilestoneAndQuestionDetails(job_details.id)}*!/*/}
                            {/*/!*>See full job details</a>*!/*/}
                            {/*/!*</li>*!/*/}
                            {/*<li>*/}
                                {/*<a className="btn btn-link"*/}
                                    {/*// onClick={() => {*/}
                                    {/*//     const {history, location} = this.props*/}
                                    {/*//     let {search, pathname} = location*/}
                                    {/*//     if (is_super_user && jobId) {*/}
                                    {/*//         search = search + '&jobId=' + proposal.job*/}
                                    {/*//         const indexOfPageNo = search.indexOf('&page=')*/}
                                    {/*//         search = (indexOfPageNo > -1 ? search.substring(0, indexOfPageNo) : search) + `&page=${page}`*/}
                                    {/*//         history.push({*/}
                                    {/*//             pathname,*/}
                                    {/*//             search,*/}
                                    {/*//         })*/}
                                    {/*//     }*/}
                                    {/*//     history.push(`${path.sendProposal}?proposalId=${id}&jobId=${job}&jobTitle=${title}&STATUS=${PROPOSAL_SENT}&firstName=${firstName}&lastName=${lastName}&show_ctc=${answers.show_ctc}`)*/}
                                    {/*// }}*/}
                                   {/*onClick={() => {*/}
                                       {/*const {history, location} = this.props*/}
                                       {/*let {search} = location*/}
                                       {/*search += `${search.indexOf('?') > -1 ? '' : '?'}`*/}
                                       {/*search += `is_default=${true}`*/}
                                       {/*this.props.history.push({*/}
                                           {/*pathname: `${path.sendProposal}`,*/}
                                           {/*state: {singalJobDetail: {jobId: job, proposalId: id, jobTitle: title, STATUS: PROPOSAL_SENT, firstName: firstName, lastName: lastName, show_ctc: answers.show_ctc, time: created}},*/}
                                           {/*search*/}
                                       {/*})*/}

                                   {/*}}*/}
                                {/*>*/}
                                    {/*Edit Proposal*/}
                                {/*</a>*/}
                            {/*</li>*/}
                        {/*</ul>*/}
                    </div>
                    <SeeFullJobDetails jobDetails={job_details} jobId={job} proposalId={proposal.id}
                                       jobQuestions={this.state.jobQuestions} jobMilestone={this.state.jobMilestone}/>
                </div>
                {/*<div className="panel panel-default" key={proposal.id}>*/}
                {/*    <div className="panel-body">*/}
                {/*        <div className="alert alert-default fade in">*/}
                {/*            <div className="pull-right">*/}
                {/*                /!*<button className="btn btn-md btn-login"*!/*/}
                {/*                /!*        data-toggle="modal"*!/*/}
                {/*                /!*        data-target={"#" + proposal.id + 'see_proposal'}*!/*/}
                {/*                /!*        onClick={(e) => (this.getCurrentProposalDetails(proposal.id), this.getJobMilestoneAndQuestionDetails(proposal.job_details.id))}>*!/*/}
                {/*                /!*    See Proposal*!/*/}
                {/*                /!*</button>*!/*/}
                {/*                /!*<br/>*!/*/}
                {/*                <Link*/}
                {/*                    className="btn btn-md btn-login"*/}
                {/*                      // data-toggle="modal"*/}
                {/*                      // data-target="#PAYMENTS"*/}
                {/*                   // onClick={() => {*/}
                {/*                   //     this.setState({shouldPaymentTermsRender: true, selectedProposalId: proposal.id})*/}
                {/*                   // }}*/}
                {/*                      to={`${path.sendProposal}?proposalId=${proposal.id}&jobId=${proposal.job}&jobTitle=${proposal.job_details.title}&STATUS=${PROPOSAL_SENT}&firstName=${firstName}&lastName=${lastName}`}*/}
                {/*                      // className="btn btn-md btn-login"*/}
                {/*                >*/}
                {/*                    Edit Proposal*/}
                {/*                </Link>*/}
                {/*            </div>*/}
                {/*            <div className="media">*/}
                {/*                <div className="media-left">*/}
                {/*                    {avatar ? (<img src={avatar} alt="user" className="img-circle" />)*/}
                {/*                        :*/}
                {/*                        getFirstAndLastLetterOfName(firstName, lastName)*/}
                {/*                    }*/}
                {/*                    /!*<img src={proposal.job_details.user.client.avatar ? proposal.job_details.user.client.avatar : UserImage}*!/*/}
                {/*                        /!*className="media-object img-circle" />*!/*/}
                {/*                </div>*/}
                {/*                <div className="media-body">*/}
                {/*                    <p className="media-heading">*/}
                {/*                        <GetFirstNameAndLastName firstname={firstName} lastname={lastName}/>*/}
                {/*                        /!*{firstName + ' ' + lastName}*!/*/}
                {/*                        <br />*/}
                {/*                        <CustomToolTrip text={proposal.job_details.title} maxCharLength={50} />*/}
                {/*                    </p>*/}
                {/*                    <p className="text-muted">*/}
                {/*                        <Description  description={proposal.about_professional}/>*/}
                {/*                        /!*<br/>*!/*/}
                {/*                        <Description  description={proposal.message}/></p>*/}
                {/*                    <p><a href="#" data-toggle="modal"*/}
                {/*                        data-target={'#' + proposal.id}*/}
                {/*                        onClick={(e) => this.getJobMilestoneAndQuestionDetails(proposal.job_details.id)}*/}
                {/*                    >See full job details</a>*/}
                {/*                    </p>*/}
                {/*                    <p className="time">*/}
                {/*                        <TimeAgo date={proposal.created} />*/}
                {/*                    </p>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            {is_super_user*/}
                {/*            &&*/}
                {/*            (*/}
                {/*                <div className="media">*/}
                {/*                    <p className="text-bold">Professional</p>*/}
                {/*                    <div className="media-left">*/}
                {/*                        {proposal.professional_detail.avatar ? (<img src={proposal.professional_detail.avatar} alt="user" className="img-circle" />)*/}
                {/*                            :*/}
                {/*                            getFirstAndLastLetterOfName(proposal.professional_detail.first_name, proposal.professional_detail.last_name)*/}
                {/*                        }*/}
                {/*                        /!*<img src={proposal.job_details.user.client.avatar ? proposal.job_details.user.client.avatar : UserImage}*!/*/}
                {/*                        /!*className="media-object img-circle" />*!/*/}
                {/*                    </div>*/}
                {/*                    <div className='media-body'>*/}
                {/*                        <p className="media-heading">*/}
                {/*                            {proposal.professional_detail.first_name + ' ' + proposal.professional_detail.last_name}*/}
                {/*                        </p>*/}
                {/*                    </div>*/}
                {/*                    <div className='media-body'>*/}
                {/*                        <p className="media-heading">*/}
                {/*                            {send_notification_to_all ? <img src={require('../../static/images/success.png').default} style={{width: '20px'}} /> : 'Not Live'}*/}
                {/*                        </p>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            )}*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*    <SeeFullJobDetails jobDetails={proposal.job_details} jobId={proposal.job} proposalId={proposal.id} jobQuestions={this.state.jobQuestions} jobMilestone={this.state.jobMilestone} />*/}
                {/*    /!*<SeeProposal jobDetails={proposal.job_details} jobId={proposal.job} proposalId={proposal.id} jobQuestions={this.state.jobQuestions} jobMilestone={this.state.jobMilestone} currentProposal={this.state.currentProposal} />*!/*/}
                {/*</div >*/}
            </Fragment>
        )
    };

    render() {
        const {proposalList, loading, limit, page, total, shouldPaymentTermsRender, selectedProposalId, is_live, searched} = this.state
        const {is_super_user, history} = this.props
        if (loading) return (<div style={{marginTop: '25%', marginLeft: '50%'}}><LoadingComponent pastDelay/></div>);
        return (
                <div className="row">
                    <div className="col-sm-12">
                        <p className="para">
                            <b> {is_super_user ? (is_live && Boolean(JSON.parse(is_live.toLowerCase())) ? 'Live Proposals' : 'Proposals Not Live') : ' Sent Proposal'}</b>
                        </p>
                        {/*{is_super_user && this.filterStripUi()}*/}
                        {/*{proposalSentAndMessageTabs(1)}*/}
                        {is_super_user &&
                        <SearchByUserName apiPath={`/job/proposal-professional/`} searchedType={USER.toLowerCase()}
                                          updateList={this.updateList} resetToDefault={this.resetToDefault}
                                          searched={searched}/>}

                    </div>
                    <div className="col-sm-12">
                        {
                            !loading && proposalList.length === 0
                                ?
                                <div>
                                    <p>&#x00A0;</p>
                                    <h4 className="text-empty-space">This is where we show proposals you have sent on
                                        different jobs. Looks like you have not sent any proposals.</h4>
                                </div>
                                :
                                (<div id="poposal_sent_tab" className="tab-pane fade in active Proposal-list">
                                        {proposalList.map(proposal => {
                                            return this.singleProposal(proposal)
                                        })}
                                        {!loading && proposalList.length > 0 && <div className='text-center'>
                                            <span>{`${proposalList.length} of ${total}`}</span>
                                        </div>
                                        }

                                        {total > 10 && (
                                            <PaginationWithArrow onChange={this.getProposalList} totalRecord={total}
                                                                 pageSize={limit} currentPage={page}/>)}
                                    </div>
                                )
                        }


                    </div>
                </div>
        )
    }
}

const mapStateToProps = state => ({
    data: state.data,
    is_super_user: state.data.is_super_user
});

const mapDispatchToProps = (dispatch) => ({
    ...(errorActionDispatchers(dispatch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalProposalSentList)

