import * as types from '../constants/auth'
// import * as type from './../actions/types'

const states = {
    state: [],
    city: []
};

export default function geoState (state = states, action) {
    let out = {
        ...state
    };
    switch (action.type) {
        case types.GET_STATE:
            out = {
                ...out,
                state: [...action.payload],
            };
            return out;
        case types.GET_CITY:
            out = {
                ...out,
                city: [...action.payload],
            };
            return out;

        default:
            return state
    }
}

