import * as types from './types'

export const updateProfilePic = (payload) => ({
    type: types.users.UPDATE_PROFILE_PIC,
    payload
})

export const updateTrainingAndQuestion = (payload) => ({
    type: types.users.UPDATE_TRAINING_AND_QUESTION,
    payload
})