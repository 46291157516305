import React, {Component, Fragment} from "react";
import {onlyZeroToNine, validateProfile} from "./validation/FormValidator";
import {apiv1} from "../../api";
import {errorActionDispatchers} from "../../actions/errors";
import connect from "react-redux/es/connect/connect";
import LoadingComponent from "../../utils/LoadingComponent";
import PhoneInput, {getCountryCallingCode, parsePhoneNumber} from "react-phone-number-input";
import {indiaCallingCode} from "../../constants/common";
import ReactPixel from "react-facebook-pixel";
import * as path from "../../constants/path";


export const FreeConsultationCategory = [
    {
        id: 1,
        name: 'Tax and Accounting',
    },
    {
        id: 5,
        name: 'Business Registration',
    },
    {
        id: 20,
        name: 'GST Services',
    },
    {
        id: 112,
        name: 'Website Development',
    },
    {
        id: 110,
        name: 'Graphic & UI Design Services',
    },
    {
        id: 184,
        name: 'Content writing service',
    },
    {
        id: 212,
        name: 'Digital Marketing Services',
    },

]

export function fbLeadsTrack() {
    ReactPixel.track('Lead');
}

class TalkToSalesForm extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: false,
            category: '',
            first_name: '',
            last_name: '',
            email: '',
            company_name: '',
            errors: {},
            phone: '',
            message: '',
            isSubmitted: false,
            countryCallingCode: indiaCallingCode
        }
    }

    componentDidMount() {
        // window.scrollTo(0, 0);


    }

    removeSuccessMessage = () => {
        this.setState({isSubmitted: false})
    }

    submitRequest = () => {
        const {category, first_name, company_name, email, phone, countryCallingCode, errors, message} = this.state;
        const {pathHistory} = this.props
        this.setState({loading: true})
        const {nationalNumber} = parsePhoneNumber(phone) || {}
        let validateObj = {
            category,
            first_name,
            email,
            phone: nationalNumber,
            message,
        };
        const isFormValidated = validateProfile(errors, validateObj);
        if (isFormValidated) {
            const request = {
                category: category,
                first_name,
                email: email,
                phone: nationalNumber,
                country_code: countryCallingCode,
                company_name,
                message,
            }
            apiv1.noAuth.post('/consultants/free-cons/', request)
                .then((resp) => {
                    const data = apiv1.parseAndAutoHandleIssues(resp);
                    if (data) {
                        this.setState({
                                loading: false,
                                category: '',
                                first_name: '',
                                last_name: '',
                                email: '',
                                phone: '',
                                message: '',
                                company_name: '',
                                isSubmitted: true
                            }
                            // , () => setTimeout(this.removeSuccessMessage, 10000)
                        );
                        fbLeadsTrack()
                        pathHistory.push({
                            pathname: `${path.leadThankYou}`,

                        })
                    } else {
                        this.setState({loading: false})
                    }
                })
                .catch((err) => {
                    const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
                    for (let key in resErrors) {
                        errors[key] = resErrors[key][0]
                    }
                    this.setState({loading: false, errors, email: ''})
                })
        } else {
            this.setState({loading: false, errors})
        }
    }

    onChange = (e) => {
        const newErrors = {...this.state.errors};
        const {name, value} = e.target;
        newErrors[e.target.name] = '';
        let message = '';
        if (name === 'mobile') {
            newErrors['phone'] = ''
            message = 'Phone no is no valid';
            onlyZeroToNine(newErrors, name, value, message)
        }
        this.setState({[name]: value, errors: newErrors})
    };

    render() {
        const {isFromExpertList, isHome} = this.props
        const {loading, category, email, errors, message, first_name, last_name, company_name, phone, isSubmitted} = this.state

        return (
            <Fragment>
                {loading &&
                <div className='lazy-loader' style={{position: 'fixed', zIndex: '100'}}><LoadingComponent pastDelay/>
                </div>
                }

                <form className="price" style={{marginTop: '0px'}}>
                    <div className={`${isHome ? 'col-sm-6' : 'col-sm-12'}`}>
                        <div className="form-group">
                            {/*<label>Enter Full Name</label>*/}
                            <input type="text"
                                   className="form-control input-lg"
                                   value={first_name}
                                   onChange={this.onChange}
                                   name="first_name"
                                   placeholder="Name"
                                   style={{border: errors.first_name ? '1px solid #ff337d' : ''}}
                            />
                            <span
                                className={errors.first_name ? 'error' : 'no-error'}>{errors.first_name}</span>
                        </div>
                    </div>
                    <div className={`${isHome ? 'col-sm-6' : 'col-sm-12'}`}>
                        <div className="form-group">
                            {/*<label>Enter Valid Phone Number</label>*/}
                            <PhoneInput
                                className="form-control input-lg"
                                name="phone"
                                international
                                countryCallingCodeEditable={false}
                                value={phone}
                                onChange={(phone) => {
                                    let e = {
                                        target: {
                                            name: 'phone',
                                            value: phone
                                        }
                                    }
                                    console.log(phone)
                                    const newErrors = {...this.state.errors}
                                    const {name, value} = e.target
                                    newErrors[name] = ''
                                    this.setState({[name]: value, errors: newErrors})
                                }}
                                onCountryChange={(country) => {
                                    country = country || ''
                                    if (country) {
                                        let countryCallingCode = `+${getCountryCallingCode(country)}`
                                        this.setState({countryCallingCode})
                                    }
                                }}
                                placeholder="Phone Number"
                                defaultCountry="CA"
                                style={{border: errors.phone ? '1px solid #ff337d' : ''}}
                            />
                            <span className={errors.phone ? 'error' : 'no-error'}>{errors.phone}</span>
                        </div>
                    </div>
                    <div className={`${isHome ? 'col-sm-6' : 'col-sm-12'}`}>
                        <div className="form-group">
                            {/*<label>Enter Valid Email</label>*/}
                            <input type="email"
                                   className="form-control input-lg"
                                   value={email}
                                   onChange={this.onChange}
                                   name="email"
                                   placeholder="Email"
                                   style={{border: errors.email ? '1px solid #ff337d' : ''}}
                            />
                            <span className={errors.email ? 'error' : 'no-error'}>{errors.email}</span>
                        </div>
                    </div>
                    {!isFromExpertList || !isHome &&
                    <div className="col-sm-12">
                        <div className="form-group">
                            {/*<label>Enter Valid Email</label>*/}
                            <input type="email"
                                   className="form-control input-lg"
                                   value={company_name}
                                   onChange={this.onChange}
                                   name="company_name"
                                   placeholder="Enter Company Name (Optional)"
                                // style={{border: errors.email ? '1px solid #ff337d' : ''}}
                            />
                            {/*<span className={errors.email ? 'error' : 'no-error'}>{errors.email}</span>*/}
                        </div>
                    </div>
                    }
                    <div className={`${isHome ? 'col-sm-6' : 'col-sm-12'}`}>
                        <div className="form-group">
                            {/*<label>Select Category</label>*/}
                            <select className="form-control input-lg"
                                    name='category'
                                    onChange={(e) => {
                                        const {value} = e.target
                                        const errors = {...this.state.errors}
                                        errors['category'] = ''
                                        this.setState({category: value, errors})
                                    }}
                            >
                                <option selected={true} value={''} key={'blah.'}>Select Service needed</option>
                                {FreeConsultationCategory.map((data, index) => {
                                    const {id, name} = data
                                    return <option value={name} selected={category === name}
                                                   key={id}>{name}</option>
                                })}
                            </select>
                            <span
                                className={errors.category ? 'error' : 'no-error'}>{errors.category}</span>

                        </div>
                    </div>
                    <div className="col-sm-12">
                        {!isFromExpertList &&
                        <div className="form-group">
                            {/*<label>Write a proper description of your problem</label>*/}
                            <textarea rows="3"
                                      placeholder="Description of service needed..."
                                      name='message'
                                      value={message}
                                      onChange={this.onChange}
                                      style={{border: errors.message ? '1px solid #ff337d' : ''}}
                            >

                                                              </textarea>
                        </div>
                        }

                        <div className="text-center">
                            <a className='btn btn-submit btn-block'
                               style={{fontSize: '18px'}}
                               onClick={() => {
                                   this.submitRequest()
                               }}
                            title={`Submit Your Details`}>Submit</a>
                        </div>
                    </div>
                </form>


            </Fragment>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch) => ({
    ...(errorActionDispatchers(dispatch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TalkToSalesForm)

export const aboutDescription = 100