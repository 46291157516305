import * as types from "../constants/auth";
const initialAmount = {
    job_in_progress_amount: 0,
    user_invoice_total_amount: 0,
    user_invoice_total_amount_tax : 0,
    wizcounsel_invoice_total_amount: 0,
    wizcounsel_invoice_total_amount_tax: 0,
    user_invoice_total_amount_job_in_progress: 0 ,
    user_invoice_total_amount_tax_job_in_progress: 0 ,
    wizcounsel_invoice_total_amount_job_in_progress: 0,
    wizcounsel_invoice_total_amount_tax_job_in_progress: 0 ,
    user_invoice_total_amount_consultations: 0 ,
    user_invoice_total_amount_tax_consultations: 0,
    wizcounsel_invoice_total_amount_consultations: 0 ,
    wizcounsel_invoice_total_amount_tax_consultations: 0,
    advance_payment: 0,
    total_payment: 0,
    total_invoice_payment: 0,
};

export default function accounting (state = initialAmount, action) {
    var out = {
        ...state
    };
    const {type, payload} = action
    switch (type) {
        case types.ACCOUNTING:
            const {job_in_progress_amount,
                user_invoice_total_amount,
                user_invoice_total_amount_tax,
                wizcounsel_invoice_total_amount,
                wizcounsel_invoice_total_amount_tax,
                user_invoice_total_amount_job_in_progress ,
                user_invoice_total_amount_tax_job_in_progress ,
                wizcounsel_invoice_total_amount_job_in_progress ,
                wizcounsel_invoice_total_amount_tax_job_in_progress ,
                user_invoice_total_amount_consultations ,
                user_invoice_total_amount_tax_consultations,
                wizcounsel_invoice_total_amount_consultations ,
                wizcounsel_invoice_total_amount_tax_consultations,
                advance_payment,
                total_payment,
                total_invoice_payment
            } = payload
            return {
                ...out,
                job_in_progress_amount,
                user_invoice_total_amount,
                user_invoice_total_amount_tax,
                wizcounsel_invoice_total_amount,
                wizcounsel_invoice_total_amount_tax,
                user_invoice_total_amount_job_in_progress ,
                user_invoice_total_amount_tax_job_in_progress ,
                wizcounsel_invoice_total_amount_job_in_progress ,
                wizcounsel_invoice_total_amount_tax_job_in_progress ,
                user_invoice_total_amount_consultations ,
                user_invoice_total_amount_tax_consultations,
                wizcounsel_invoice_total_amount_consultations ,
                wizcounsel_invoice_total_amount_tax_consultations,
                advance_payment,
                total_payment,
                total_invoice_payment
            }
        default:
            return state
    }
}