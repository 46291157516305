import { combineReducers } from 'redux'
import errors from './errors'
import data from './auth'
import professions from './professions'
import job_count from './job_count'
import state from './geo'
import counter_party from './counter_party_details'
import accounting from './accounting'
import affiliate from './affiliate'
const rootReducer = combineReducers({
    data,
    errors,
    professions,
    job_count,
    state,
    counter_party,
    accounting,
    affiliate
});

export default rootReducer
