import React, {Component} from "react";
import {apiv1} from "../../api";
import {errorActionDispatchers} from "../../actions/errors";
import connect from "react-redux/es/connect/connect";
import {USER} from "../../constants/auth";

class SearchByUserName extends Component {
    constructor(props) {
        super(props)
        let {apiPath, query, searchedType, searched} = props
        searched = searched ? searched : ''
        this.state = {
            searched,
            page: 1,
            limit: 10,
            searchedList: [],
            loading: false,
            apiPath,
            query,
            searchedType,
        }
    }

    componentDidMount() {
        this.addEventListenerOnSearchClick()
    }

    getList = (page) => {
        let {apiPath, limit, searched} = this.state
        const {updateList} = this.props
        apiPath += `?${page}&limit=${limit}&search=${searched}`
        const {doDispatch} = this.props
        apiv1.auth.get(apiPath)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                if (data) {
                    const {results, count} = data
                    if (updateList) {
                        updateList({searchedList: results, count})
                    }
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, doDispatch)
            })
    }

    searchForQuery = (isFromReset) => {
        const {updateList} = this.props
        const {searched} = this.state
        if (updateList) {
            updateList({searchedList: [], searched, count: 0})
        }
    }

    addEventListenerOnSearchClick = () => {
        document.getElementById("search_by_name").addEventListener("keyup", (event) => {
            const {searched} = this.state
            const {keyCode} = event
            if (keyCode === 13) {
                if (searched) {
                    this.searchForQuery(false)
                }
            }
        })
    }

    searchBarUi = () => {
        const {searched, searchedType} = this.state
        return (
            <div className="row">
                <div className="col-sm-11">
                    <div className="searchbar"
                         id='search_by_name'
                    >
                        <input className="search_input"
                               type="text"
                               autoComplete="off"
                               name="searched"
                               placeholder={`Search for ${searchedType}`}
                               value={searched}
                               onChange={(e) => {
                                   e.preventDefault()
                                   const {name, value} = e.target
                                   this.setState({[name]: value})
                               }}
                        />
                        <span className="search_icon">
                    <i className="fa fa-search"></i>
                </span>
                    </div>
                </div>
                <div className="col-sm-1">
                     <span className='btn btn-filter-apply btn-block'
                           style={{marginTop: '10px', marginLeft: '-10px'}}
                           onClick={(e) => {
                               const {resetToDefault} = this.props
                               if (resetToDefault) {
                                   this.setState({searched: ''}, () => {
                                       resetToDefault({searched: ''})
                                   })
                               }
                           }}
                     >
                    Reset
                </span>
                </div>
            </div>
        )
    }
    userListUi = () => {
        const {searchedList} = this.state
        return searchedList.map(userDetails => {
            return <p></p>
        })
    }

    render() {
        const {searchedType} = this.state
        return (
            <div>
                {this.searchBarUi()}
                <div className="clearfix"></div>
                <p>&#x00A0;</p>
                {searchedType === USER.toLowerCase() && this.userListUi()}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    ...(errorActionDispatchers(dispatch)),
});

export default connect(null, mapDispatchToProps,)(SearchByUserName)
