import {isGstNoValid, isPanNoValid} from "./businessDetailsValidation";
import {aboutProposalWordLimit} from "../../professional/SendProposal";
import {indiaCallingCode} from "../../../constants/common";
import {_active, _premium, _standard, is} from "../../professional/DesignerSingleProduct"
import {tabs as gigTab} from '../../professional/CreateDefaultGig'
import {htmlParser} from "../../../functions/common";

export const formErrorMsg = {
    first_name: 'Please enter first name',
    last_name: 'Please enter last name',
    phone: 'Please enter phone',
    email: 'Please enter email',
    password: 'Please enter password',
    password1: 'Please enter password',
    bio_caption: 'Can not be blank',
    bio_detailed: 'Can not be blank',
    confirm_password: 'Password and confirm password is not same',
    about_professional: 'Minimum 200 character required.',
    popupEmail: 'Please enter email',
    popupPassword: 'Please enter password',
};
export const proposalErrorMsg = {
    message: 'message',
    fixed_amount: 'fixed_amount',
    schedule_unit_proposed: 'schedule_unit_proposed',
    notValid: 'Please enter a valid amount',
    amount: 'amount',
    due_date: 'due_date',
    current_notice_period_in_days: 'current_notice_period_in_days',
    about_professional: 'about_professional',
}
export const quality_check_question = 'quality_check_question'

export const thisFieldIsRequired = 'This field is required'
const minimum80CharRequired = 'Maximum 80 character required.'
const maximum80CharRequired = 'Minimum 25 character required.'
const bioDetailsLength = 60

const NotAValidEmail = 'Email is not valid';
const NotAValidLink = 'Link is not valid';
export const emailInAStringRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
export const phoneNoInAStringRegex = /([a-z]\[\\s]\+91[\-\s]?)?[0]?(91)?[6789]\d{9}/
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const linkValidator = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
export const upiRegex = /^[\w.-]+@[\w.-]+$/


export const urlRegex = /(https?:\/\/[^\s]+)/g
export const agoraVoiceCallRegex = `::::agora::::voice::::agora_`
export const agoraVideoCallRegex = `::::agora::::video::::agora_`


export const zeroToNineRegex = /^[1-9]\d*$/;
const onlyDecimalNumber = /^\d+(\.\d{1,2})?$/
const onlyIntReg = /^[0-9+]*$/;
export const htmlRegex = /<[a-z][\s\S]*>/i
const enterValidAmount = 'Enter a valid amount.'
const enterValidTime = 'Enter a valid time.'
const enterValidNumber = 'Enter a valid number.'

export function validatePhone(errors, request) {
    let isAllValid = true;
    if (request["phone"].length !== 13 && request['phone'].trim() !== '+91') {
        errors["phone"] = 'Phone not valid';
        isAllValid = false
    }
    return isAllValid
}
export function upiValidator(errors, request) {
    let isAllValid = true
    for (let key in request) {
        if (!request[key].trim()) {
            errors[key] = businessDetails.please_enter;
            isAllValid = false
        } else {
            if (!upiRegex.test(request[key].trim())){
                errors[key] = 'Upi is not valid'
                isAllValid = false
            }
        }
    }
    return isAllValid
}

export function validChangePassword(errors, request) {
    let isAllValid = true;
    for (let key in request) {
        if (request[key].trim() === '') {
            errors[key] = businessDetails.please_enter;
            isAllValid = false
        }
    }
    let message = '';
    if (request['old_password'].trim() !== '' && request['old_password'].trim().length < 8) {
        errors['old_password'] = 'Old password was min of 8 character'
    }
    if (request['old_password'].trim().length >= 8 && request["new_password1"].trim() !== request["new_password2"].trim()) {
        errors["new_password2"] = "Confirm password is not same as new password";
        isAllValid = false
    }
    if (request['old_password'].trim().length >= 8 && (request["new_password1"].trim() !== '' || request["new_password2"].trim() !== null) && request["new_password1"].trim() === request["new_password2"].trim() && (request['new_password1'].trim().length < 8 || request['new_password2'].trim().length < 8)) {
        errors["new_password2"] = "Password must be min of 8 characters";
        errors["new_password1"] = "Password must be min of 8 characters";
        isAllValid = false
    }
    return isAllValid
}

const businessDetails = {
    please_enter: 'Please enter this field'
};

export function industryValidation({errors, request, validatorObj}) {
    let isAllValid = true;
    for (let key in request) {
        let validatorReqKey = `is_${key}_required`
        if ((validatorReqKey !== 'is_framework_required' && validatorReqKey !== 'is_subject_expertise_level_required') && validatorObj[validatorReqKey]){
            if (!request[key]){
                errors[key] = thisFieldIsRequired
                isAllValid = false
            } else {
                if (key === 'client_email' && !emailRegex.test(request[key].trim())){
                    errors[key] = NotAValidEmail;
                    isAllValid = false
                }
                if (key === 'link' && !linkValidator.test(request[key].trim())){
                    errors[key] = NotAValidLink;
                    isAllValid = false
                }
            }
        }
        if (validatorObj[`is_${key}_optional`]){
            if (key === 'link' && request[key] && !linkValidator.test(request[key].trim())){
                errors[key] = NotAValidLink;
                isAllValid = false
            }
        }
    }
    return isAllValid
}

export function validateProfile(errors, request) {
    let isAllValid = true;

    for (let key in request) {
        if (key === 'address') {
            for (let key2 in request[key]) {
                if (key2 === 'city' && (request[key][key2] === null || request[key][key2].toString().trim() === '')) {
                    errors['state'] = 'Please select state';
                    if (request['address']['state']) {
                        errors['city'] = 'Please select city';
                        errors['state'] = ''
                    }
                    isAllValid = false
                }
                else if (key2 === 'state' && (request[key][key2] === null || request[key][key2].toString().trim() === '')) {
                    errors['state'] = 'Please select city';
                    isAllValid = false
                }
                else if ((key2 === 'line_one') && !request[key][key2]) {
                    errors[key2] = businessDetails.please_enter;
                    isAllValid = false
                }
                else if (key2 === 'zipcode') {
                    if ((request[key][key2] === undefined || request[key][key2] === null || request[key][key2].trim() === '')) {
                        errors[key2] = businessDetails.please_enter;
                        isAllValid = false
                    }
                }
            }
        }
        if (key === 'first_name' && request[key].trim() === '') {
            errors[key] = formErrorMsg[key];
            isAllValid = false
        } else if (key === 'last_name' && request[key].trim() === '') {
            errors[key] = formErrorMsg[key];
            isAllValid = false
        } else if (key === 'email') {
            if (request[key].trim() === '') {
                errors[key] = formErrorMsg[key];
                isAllValid = false
            } else if (!emailRegex.test(request[key].trim())) {
                errors[key] = NotAValidEmail;
                isAllValid = false
            }
        }
        else if (key === 'popupEmail') {
            if (request[key].trim() === '') {
                errors[key] = formErrorMsg[key];
                isAllValid = false
            } else if (!emailRegex.test(request[key].trim())) {
                errors[key] = NotAValidEmail;
                isAllValid = false
            }
        }
        if (key === 'address') {
            for (let key in request){
                // if (key === 'gst_number') {
                //     if (request[key] && !isGstNoValid(errors, key, request[key])) {
                //         isAllValid = false
                //     }
                // }
                // else if (key === 'pan_number'){
                //     if (request[key] === null || request[key].trim() === ''){
                //         errors[key] = businessDetails.please_enter;
                //         isAllValid = false
                //     } else if (!isPanNoValid(errors, key, request[key])) {
                //         isAllValid = false
                //     }
                // }
            }
        }
        // if (key === 'gst_number') {
        //     if (request[key] && !isGstNoValid(errors, key, request[key])) {
        //         isAllValid = false
        //     }
        // }
        // else if (key === 'pan_number'){
        //     if (request[key] === null || request[key].trim() === ''){
        //         errors[key] = businessDetails.please_enter;
        //         isAllValid = false
        //     } else if (!isPanNoValid(errors, key, request[key])) {
        //         isAllValid = false
        //     }
        // }
        else if (key === 'password1') {
            const passValue = request[key].trim()
            if (passValue === '') {
                errors[key] = formErrorMsg[key];
                isAllValid = false
            } else if (request[key].length < 8) {
                errors[key] = 'Password should be minimum of 8 characters';
                isAllValid = false
            }
            else if (!/[A-Z]/.test(passValue))
            {
                errors[key] = "Password should has one UpperCase character"
                isAllValid = false
            }
            else if (!/[a-z]/.test(passValue))
            {
                errors[key] = "Password should has one LowerCase character"
                isAllValid = false
            }
            else if (!/[!@#$%^&*,.?|":{}<>]/.test(passValue))
            {
                errors[key] = "Password should has one Special character(!@#$%^&*,.?|:{}<></>)"
                isAllValid = false
            }

        } else if (key === 'password' && request[key].trim() === '') {
            errors[key] = formErrorMsg[key];
            isAllValid = false
        }
        else if (key === 'popupPassword' && request[key].trim() === '') {
            errors[key] = formErrorMsg[key];
            isAllValid = false
        }
        else if (key === 'phone') {
            const message = 'Phone no is not valid';
            if (!request[key]) {
                errors[key] = formErrorMsg[key];
                isAllValid = false
            } else if (!isPhoneValid(errors, key, request[key], message, request['country_code'])) {
                isAllValid = false
            }

        } else if (key === 'bio_caption' && request[key].trim() === '') {
            errors[key] = formErrorMsg.bio_caption;
            isAllValid = false
        } else if (key === 'bio_detailed') {
            let bioText =  htmlParser(request[key])
            bioText = bioText || ''
            let bioWordLength = bioText.split(' ').length
            if (!request[key]){
                errors[key] = formErrorMsg.bio_detailed
                isAllValid = false
            } else if (request[key] && bioWordLength < bioDetailsLength){
                errors[key] = `Please write atleast ${bioDetailsLength} words`
                isAllValid = false
            }
        }
        else if (key === 'work_experience'){
            if (!onlyZeroToNine(errors, key, request[key], enterValidNumber)){
                isAllValid = false
            }
        }
        else if (key === 'question' && request[key].trim() === '') {
            errors[key] = businessDetails.please_enter;
            isAllValid = false
        }
        else if (key === 'profession' && request[key].length === 0) {
            errors[key] = 'Please select profession'
            isAllValid = false
        }
        // else if (key === 'price_per_hour' && !request[key]){
        //     errors[key] = thisFieldIsRequired
        //     isAllValid = false
        // }
    }
    return isAllValid

}

export function validateProfileForPayment(errors, request) {
    let isAllValid = true;
    for (let key in request) {
        if (key === 'address') {
            for (let key2 in request[key]) {
                // if (key2 === 'city' && (request[key][key2] === null || request[key][key2].toString().trim() === '')) {
                //     errors['state'] = 'Please select state';
                //     if (request['address']['state']) {
                //         errors['city'] = 'Please select city';
                //         errors['state'] = ''
                //     }
                //     isAllValid = false
                // }
            }
        } else if (key === 'phone') {
            const message = 'Phone no is not valid';
            if (request[key] === undefined || request[key] === null || request[key].trim() === '') {
                errors[key] = formErrorMsg[key];
                isAllValid = false
            } else if (!isPhoneValid(errors, key, request[key], message)) {
                isAllValid = false
            }

        }
        if (key === 'gst_number') {
            if (request[key] && !isGstNoValid(errors, key, request[key])) {
                isAllValid = false
            }
        }
    }
    return isAllValid

}

export function onlyZeroToNine(errors, key, value, message) {
    if (value === '') {
        errors[key] = 'Please enter this field';
        return false
    }
    // if (!/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(value)) {
    //     errors[key] = message;
    //     return false
    // }
    return true
}

export function decimalValidator(errors, key, value, message) {
    if (value === '') {
        errors[key] = 'Please enter this field';
        return false
    }
    if (!onlyDecimalNumber.test(value)) {
        errors[key] = message;
        return false
    }
    return true
}

export function isIndianCallingCode({country_code}){
    return country_code === indiaCallingCode
}

export function isPhoneValid(errors, key, value, message, country_code) {
    value = value || ''
    if (!onlyZeroToNine(errors, key, value, message)) {
        return false
    }
    if (country_code){
        if (isIndianCallingCode({country_code})){
            if (value && value.length !== 10) {
                errors[key] = 'Phone no must be 10 digits';
                return false
            }
        } else if(value && (value.length < 8 || value.length > 12)) {
            errors[key] = 'Phone no is not valid'
            return false
        }
    } else {
        if (value && (value.length < 10 || value.length > 13)){
            errors[key] = 'Phone no is not valid'
            return false
        }
    }
    return true
}

export function isAllInteger(errors, key, value, message) {
    if (isNaN(value)) {
        errors[key] = message;
        return false
    }
    return true
}

export function validateContractTermination(errors, request, type, feedbackIsAvailable) {
    let isAllValid = true;

    if (type === 'Professional' && feedbackIsAvailable === false) {
        for (let key in request) {

            if (key === 'rating' && request[key] === 0) {
                errors[key] = 'Please select rating for professional';
                isAllValid = false
            } else if (key === 'reason_for_termination' && request[key].trim() === '') {
                errors[key] = 'Please select reason for termination';
                isAllValid = false
            } else if (key === 'comment' && request[key].trim() === '') {
                errors[key] = 'Please write review for professional';
                isAllValid = false
            } else if (key === 'amountNeedToPay' && request[key] === null) {
                if (request['reason_for_termination'] !== 'Job completed successfully') {
                    errors[key] = 'Please select contract amount';
                    isAllValid = false
                }
            }
        }
    }
    if (type === 'Professional' && feedbackIsAvailable === true) {
        for (let key in request) {
            if (key === 'amountNeedToPay' && request[key] === null) {
                errors[key] = 'Please select contract amount';
                isAllValid = false
            }
        }
    }
    if (type === 'User' && feedbackIsAvailable === false) {
        for (let key in request) {
            if (key === 'rating' && request[key] === 0) {
                errors[key] = 'Please select rating for professional';
                isAllValid = false
            } else if (key === 'reason_for_termination' && request[key].trim() === '') {
                errors[key] = 'Please select reason for termination';
                isAllValid = false
            } else if (key === 'comment' && request[key].trim() === '') {
                errors[key] = 'Please write review for professional';
                isAllValid = false
            }
        }
    }
    return isAllValid
}

export function isEmailValid(errors, key, value, message) {
    if (!emailRegex.test(value)) {
        errors[key] = message;
        return false
    }
    return true

}

export function validateFeedback(errors, request, type) {
    let isAllValid = true;

    if (type === 'Professional') {
        for (let key in request) {
            if (key === 'rating' && request[key] === 0) {
                errors[key] = 'Please select rating for professional';
                isAllValid = false
            } else if (key === 'comment' && request[key].trim() === '') {
                errors[key] = 'Please write review for professional';
                isAllValid = false
            }

        }
    }
    return isAllValid
}

export function validateFeedbackFOrJob(errors, request, type) {
    let isAllValid = true;

    if (type === 'Professional') {
        for (let key in request) {
            console.log('request', request)
            if (key === 'rating' && request[key].toString() === '') {
                errors[key] = 'Please select rating for professional';
                isAllValid = false
            } else if (key === 'comment' && request[key].toString() === '') {
                errors[key] = 'Please write review for professional';
                isAllValid = false
            }
            else if (key === 'responsive_rating' && request[key].toString() === '') {
                errors[key] = thisFieldIsRequired
                isAllValid = false
            }
            else if (key === 'milestone_tracking_rating' && request[key].toString() === '') {
                errors[key] = thisFieldIsRequired
                isAllValid = false
            }

        }
    }
    return isAllValid
}


export function validateProposal(errors, request) {
    let isAllValid = true;
    for (let key in request) {
        if (key === proposalErrorMsg.message && !request[key]) {
            errors[key] = businessDetails.please_enter;
            isAllValid = false
        }
        else if (key === proposalErrorMsg.about_professional) {
            if (!request[key]){
                errors[key] = businessDetails.please_enter
                isAllValid = false
            }
            else if(request[key].length < aboutProposalWordLimit){
                errors[key] = formErrorMsg.about_professional
                isAllValid = false
            }
        }
        else if (key === proposalErrorMsg.schedule_unit_proposed || key === proposalErrorMsg.delivery_time) {
            if (!request[key] || !onlyIntReg.test(request[key])) {
                errors[key] = "Please enter valid completion time.";
                isAllValid = false
            }
        }
        else if (key === proposalErrorMsg.current_notice_period_in_days) {
            if (!request[key] || !onlyIntReg.test(request[key])) {
                errors[key] = "Please enter valid day.";
                isAllValid = false
            }
        }
        else if (key === proposalErrorMsg.fixed_amount) {
            if (!onlyDecimalNumber.test(request[key])) {
                errors[key] = "Enter valid amount.";
                isAllValid = false
            }
        }
        else if (key === proposalErrorMsg.amount) {
            if (!onlyDecimalNumber.test(request[key])) {
                errors[key] = "Enter valid amount.";
                isAllValid = false
            }
        }
        else if (key === proposalErrorMsg.due_date && !request[key]) {
            errors[key] = businessDetails.please_enter;
            isAllValid = false
        }
        // else if ((key === proposalErrorMsg.schedule_unit_proposed || key === proposalErrorMsg.fixed_amount)) {
        //     if (!onlyZeroToNine(errors, key, request[key], proposalErrorMsg.notValid)) {
        //         isAllValid = false
        //     }
        // }

    }

    return isAllValid
}

export function enterPriceInRange({errors, key, value, max, min}) {
    if (value > max  || value < min){
        errors[key] = `Please enter price between Rs ${min} and Rs ${max}`
        return false
    }
    return true
}

export function validateWhatsIncluded({errors, request, is_package_active_data}) {
    is_package_active_data = is_package_active_data || {}
    let isAllValid = true;
    request.forEach(queAns => {
        const {answer, id, mandatory, text} = queAns
        errors[id] = {
            answer: []
        }
        answer.forEach((ans, q_index) => {
            let {selected_ans} = ans
            selected_ans = selected_ans || []
            let selectedOps = selected_ans.join()
            let singleErrorObj = {
                error_text: '',
                field_name: ''
            }
            if (mandatory && !selectedOps && (q_index === 0 || is_package_active_data[`${is}${_premium}${_active}`] && q_index === 2)){
                errors[id].answer[q_index] = errors[id].answer[q_index] || []
                 singleErrorObj = {
                    error_text: thisFieldIsRequired,
                    field_name: text
                }
                errors[id].answer[q_index] = singleErrorObj
                isAllValid = false
            }
            else {
                errors[id].answer[q_index] = singleErrorObj
            }
        })
    })
    return isAllValid
}

export function validateProfileVerificationQAndA({errors, answers}) {
    let isAllValid = true
    errors['training_question_answer_details'] = errors['training_question_answer_details'] || {}
    answers.forEach((ansDetails, q_index) => {
        let {id, answer} = ansDetails
        if (!answer){
            errors['training_question_answer_details'][id] = thisFieldIsRequired
            isAllValid = false
        }
        else {
            errors['training_question_answer_details'][id] = ''
        }
    })

    return isAllValid
}

export function checkPortfolioLinks({portfolioLinks, product_details}){
    let noOfLink = 0
    const {links_required} = product_details
    portfolioLinks.map((pl, index) => {
        if(pl.link)
        {
            noOfLink+=1
        }
    })
    if(noOfLink < links_required){
        alert("At least "+ links_required + " portfolio links of past work are compulsory")
    }
    else{
        return true
    }
}

export function gigValidator({errors, request, is_package_active_data, tab_name, proof_of_work_validator_obj}) {
    let isAllValid = true;
    for (let key in request){
        if (tab_name === gigTab[0].tab_id){
            // if (key === 'professional_description' && (request[key].length > 80 || request[key].length < 20) ) {
            //     if(request[key].length > 80){
            //         errors[key] = minimum80CharRequired
            //     } else{
            //         errors[key] = maximum80CharRequired
            //     }
            //     isAllValid = false
            // }
            if (key === 'professional_description' && !request[key]) {
                errors[key] = thisFieldIsRequired
                // if(request[key].length > 80){
                //     errors[key] = minimum80CharRequired
                // } else{
                //     errors[key] = maximum80CharRequired
                // }
                isAllValid = false
            }
            // if (!errors[quality_check_question]){
            //     errors[quality_check_question] = {}
            // }
            // gigNonPublicQuestions.forEach(details => {
            //     const {gig_question_answer} = details
            //     const {gig_question, answer} = gig_question_answer
            //     if (!answer){
            //         isAllValid = false
            //         errors[quality_check_question][gig_question] = thisFieldIsRequired
            //     }
            // })
        }
        // if (key === 'desc'){
        //     let text =  htmlParser(request[key])
        //     if (!text){
        //         errors[key] = thisFieldIsRequired
        //         isAllValid = false
        //     }
        // }
        if (key === 'practise_area' && !request[key]){
            errors[key] = thisFieldIsRequired
            isAllValid = false
        }
        if (key === 'product' && !request[key]){
            errors[key] = thisFieldIsRequired
            isAllValid = false
        }
        if (key ==='framework' && proof_of_work_validator_obj.is_framework_required && !request[key]){
            // isAllValid = industryValidation({errors, request: {framework: request.framework}, validatorObj: proof_of_work_validator_obj})
            errors[key] = thisFieldIsRequired
            isAllValid = false
        }
        if (key === 'expertise_level' && !request[key]){
            errors[key] = thisFieldIsRequired
            isAllValid = false
        }
        if ((key === 'price')) {
            if (!onlyDecimalNumber.test(request[key])){
                errors[key] = enterValidAmount
                isAllValid = false
            }
            if (!enterPriceInRange({errors, key, value: request[key], max: request['max'], min: request['min']})){
                isAllValid = false
            }
        }
        //  if (key === 'price_standard') {
        //      if (is_package_active_data[`${is}${_standard}${_active}`] && !onlyDecimalNumber.test(request[key])){
        //          errors[key] = enterValidAmount
        //          isAllValid = false
        //      }
        //      if (is_package_active_data[`${is}${_standard}${_active}`] && !enterPriceInRange({errors, key, value: request[key], max: request['max_standard'], min: request['min_standard']})){
        //          isAllValid = false
        //      }
        // }
        //  if (key === 'price_premium') {
        //      if (!onlyDecimalNumber.test(request[key])){
        //          errors[key] = enterValidAmount
        //          isAllValid = false
        //      }
        //      if (!enterPriceInRange({errors, key, value: request[key], max: request['max_premium'], min: request['min_premium']})){
        //          isAllValid = false
        //      }
        //  }

        if (key === 'price_premium') {
            if (is_package_active_data[`${is}${_premium}${_active}`] && !onlyDecimalNumber.test(request[key])){
                errors[key] = enterValidAmount
                isAllValid = false
            }
            if (is_package_active_data[`${is}${_premium}${_active}`] && !enterPriceInRange({errors, key, value: request[key], max: request['max_premium'], min: request['min_premium']})){
                isAllValid = false
            }
        }
        if (key === 'delivery_time_base' && !zeroToNineRegex.test(request[key])) {
            errors[key] = enterValidTime
            isAllValid = false
        }
        // if (key === 'delivery_time_standard' && is_package_active_data[`${is}${_standard}${_active}`] && !zeroToNineRegex.test(request[key])) {
        //     errors[key] = enterValidTime
        //     isAllValid = false
        // }
        if (key === 'number_of_projects'){
            if (!onlyZeroToNine(errors, key, request[key], enterValidNumber)){
                isAllValid = false
                errors[key] = enterValidNumber
            }
            // isAllValid = false
            // errors[key] = thisFieldIsRequired
            // if (!request[key]){
            //     errors[key] = thisFieldIsRequired
            //     isAllValid = false
            // } if (!onlyIntReg.test(request[key])){
            //     errors[key] = enterValidNumber
            //     isAllValid = false
            // }
        }
        if (key === 'delivery_time_premium' && is_package_active_data[`${is}${_premium}${_active}`] && !zeroToNineRegex.test(request[key])) {
            errors[key] = enterValidTime
            isAllValid = false
        }

        if (key === 'sample1_base64' && !request[key]){
            errors[key] = thisFieldIsRequired
            isAllValid = false
        }
        if (key === 'expertise_level' && !request[key]){
            errors[key] = thisFieldIsRequired
            isAllValid = false
        }

    }
    return isAllValid
}

export function validateDesignerProduct({errors, request, is_package_active_data, gigNonPublicQuestions, tab_name, proof_of_work_validator_obj}) {
    let isAllValid = true;
     for (let key in request){
         if (tab_name === gigTab[0].tab_id){
             if (key === 'professional_description' && request[key] && (request[key].length > 80 || request[key].length < 20) ) {
                 if(request[key].length > 80){
                     errors[key] = minimum80CharRequired
                 } else{
                     errors[key] = maximum80CharRequired
                 }
                 isAllValid = false
             }
             if (!errors[quality_check_question]){
                 errors[quality_check_question] = {}
             }
             gigNonPublicQuestions.forEach(details => {
                 const {gig_question_answer} = details
                 const {gig_question, answer} = gig_question_answer
                 if (!answer){
                     isAllValid = false
                     errors[quality_check_question][gig_question] = thisFieldIsRequired
                 }
             })
         }
         if (key === 'desc'){
             let text =  htmlParser(request[key])
             if (!text){
                 errors[key] = thisFieldIsRequired
                 isAllValid = false
             }
         }
         if (key === 'practise_area' && !request[key]){
             errors[key] = thisFieldIsRequired
             isAllValid = false
         }
         if (key === 'product' && !request[key]){
             errors[key] = thisFieldIsRequired
             isAllValid = false
         }
         if (key ==='framework' && proof_of_work_validator_obj.is_framework_required && !request[key]){
             // isAllValid = industryValidation({errors, request: {framework: request.framework}, validatorObj: proof_of_work_validator_obj})
             errors[key] = thisFieldIsRequired
             isAllValid = false
         }
         if (key === 'expertise_level' && !request[key]){
             errors[key] = thisFieldIsRequired
             isAllValid = false
         }
         if ((key === 'price')) {
             if (!onlyDecimalNumber.test(request[key])){
                 errors[key] = enterValidAmount
                 isAllValid = false
             }
             if (!enterPriceInRange({errors, key, value: request[key], max: request['max'], min: request['min']})){
                 isAllValid = false
             }
         }
         if (key === 'price_standard') {
             if (is_package_active_data[`${is}${_standard}${_active}`] && !onlyDecimalNumber.test(request[key])){
                 errors[key] = enterValidAmount
                 isAllValid = false
             }
             if (is_package_active_data[`${is}${_standard}${_active}`] && !enterPriceInRange({errors, key, value: request[key], max: request['max_standard'], min: request['min_standard']})){
                 isAllValid = false
             }
        }
        //  if (key === 'price_premium') {
        //      if (!onlyDecimalNumber.test(request[key])){
        //          errors[key] = enterValidAmount
        //          isAllValid = false
        //      }
        //      if (!enterPriceInRange({errors, key, value: request[key], max: request['max_premium'], min: request['min_premium']})){
        //          isAllValid = false
        //      }
        //  }

         if (key === 'price_premium') {
             if (is_package_active_data[`${is}${_premium}${_active}`] && !onlyDecimalNumber.test(request[key])){
                 errors[key] = enterValidAmount
                 isAllValid = false
             }
             if (is_package_active_data[`${is}${_premium}${_active}`] && !enterPriceInRange({errors, key, value: request[key], max: request['max_premium'], min: request['min_premium']})){
                 isAllValid = false
             }
         }
         if (key === 'delivery_time_base' && !zeroToNineRegex.test(request[key])) {
             errors[key] = enterValidTime
             isAllValid = false
         }
         if (key === 'delivery_time_standard' && is_package_active_data[`${is}${_standard}${_active}`] && !zeroToNineRegex.test(request[key])) {
             errors[key] = enterValidTime
             isAllValid = false
         }
         if (key === 'number_of_projects'){
              onlyZeroToNine(errors, key, request[key], enterValidNumber)
             // if (!request[key]){
             //     errors[key] = thisFieldIsRequired
             //     isAllValid = false
             // } else if (!onlyIntReg.test(request[key])){
             //     errors[key] = enterValidNumber
             //     isAllValid = false
             // }
         }
         if (key === 'delivery_time_premium' && is_package_active_data[`${is}${_premium}${_active}`] && !zeroToNineRegex.test(request[key])) {
             errors[key] = enterValidTime
             isAllValid = false
         }

         if (key === 'sample1_base64' && !request[key]){
             errors[key] = thisFieldIsRequired
             isAllValid = false
         }
         if (key === 'expertise_level' && !request[key]){
             errors[key] = thisFieldIsRequired
             isAllValid = false
         }

    }
    return isAllValid
}
export function validatePracticeAreaFee(errors, request) {
    let isAllValid = true;
    for (let i = 0; i < request.product.length; i++) {
        const {price, price_standard, price_premium} = (request.product && request.product[i]) || {}
        if (price && !onlyDecimalNumber.test(price)) {
            errors[request.product[i].product] = {
                price: "Enter a valid amount.",
                ...errors[request.product[i].product]
            }
            isAllValid = false
        }
        if (price_standard && !onlyDecimalNumber.test(price_standard)) {
            errors[request.product[i].product] = {
                price_standard: "Enter a valid amount.",
                ...errors[request.product[i].product]
            }
            isAllValid = false
        }
        if (price_premium && !onlyDecimalNumber.test(price_premium)) {
            errors[request.product[i].product] = {
                price_premium: "Enter a valid amount.",
                ...errors[request.product[i].product]
            }
            isAllValid = false
        }
        if (price_premium && !onlyDecimalNumber.test(price_premium)) {
            errors[request.product[i].product] = {
                price_premium: "Enter a valid amount.",
                ...errors[request.product[i].product]
            }
            isAllValid = false
        }
    }
    return isAllValid
}

export function bookConsultationsValidateProfile(errors, request) {
    let isAllValid = true;

    for (let key in request) {
        if (key === 'address') {
            for (let key2 in request[key]) {
                if (key2 === 'city' && (request[key][key2] === null || request[key][key2].toString().trim() === '')) {
                    errors['state'] = 'Please select city';
                    if (request['address']['state']) {
                        errors['city'] = 'Please select city';
                        errors['state'] = ''
                    }
                    isAllValid = false
                } else if (key2 === 'state' && (request[key][key2] === null || request[key][key2].toString().trim() === '')) {
                    errors['state'] = 'Please select city';
                }
                else if ((key2 === 'line_one') && (request[key][key2] === null || request[key][key2].trim() === '')) {
                    errors[key2] = businessDetails.please_enter;
                    isAllValid = false
                }
                // else if (key2 === 'zipcode') {
                //     if ((request[key][key2] === undefined || request[key][key2] === null || request[key][key2].trim() === '')) {
                //         errors[key2] = businessDetails.please_enter;
                //         isAllValid = false
                //     } else if (!onlyIntReg.test(request[key][key2])) {
                //         errors[key2] = 'Pin code is not valid';
                //         isAllValid = false
                //     } else if (request[key][key2].length !== 6) {
                //         errors[key2] = 'Pin code must be 6 digit';
                //         isAllValid = false
                //     }
                // }
            }
        } else if (key === 'first_name' && request[key].trim() === '') {
            errors[key] = formErrorMsg[key];
            isAllValid = false
        } else if (key === 'last_name' && request[key].trim() === '') {
            errors[key] = formErrorMsg[key];
            isAllValid = false
        } else if (key === 'email') {
            if (request[key].trim() === '') {
                errors[key] = formErrorMsg[key];
                isAllValid = false
            } else if (!emailRegex.test(request[key])) {
                errors[key] = NotAValidEmail;
                isAllValid = false
            }
        }
        if (key === 'gst_number') {
            if (request[key] && !isGstNoValid(errors, key, request[key])) {
                isAllValid = false
            }
        } else if (key === 'password1') {
            if (request[key].trim() === '') {
                errors[key] = formErrorMsg[key];
                isAllValid = false
            } else if (request[key].length < 8) {
                errors[key] = 'Password should be minimum of 8 characters';
                isAllValid = false
            }
        } else if (key === 'password' && request[key].trim() === '') {
            errors[key] = formErrorMsg[key];
            isAllValid = false
        } else if (key === 'phone') {
            const message = 'Phone no is not valid';
            if (request[key] === undefined || request[key] === null || request[key].trim() === '') {
                errors[key] = formErrorMsg[key];
                isAllValid = false
            } else if (!isPhoneValid(errors, key, request[key], message)) {
                isAllValid = false
            }

        } else if (key === 'bio_caption' && request[key].trim() === '') {
            errors[key] = formErrorMsg.bio_caption;
            isAllValid = false
        } else if (key === 'bio_detailed' && request[key].trim() === '') {
            errors[key] = formErrorMsg.bio_detailed;
            isAllValid = false
        }
    }
    return isAllValid

}

export function validateCustomProduct(errors, request) {
    let isAllValid = true;
    for (let key in request){
        // if (key === 'professional_description' && !request[key] && (request[key].length > 80 || request[key].length < 20) ) {
        //     errors[key] = thisFieldIsRequired
        //     // if(request[key].length > 80){
        //     //     errors[key] = minimum80CharRequired
        //     // } else{
        //     //     errors[key] = maximum80CharRequired
        //     // }
        //     // isAllValid = false
        // }
         if (key === 'actual_amount_enter_by_professional' && !onlyDecimalNumber.test(request[key])) {
            errors[key] = enterValidAmount
            isAllValid = false
        }

        else if (key === 'delivery_time' && !onlyDecimalNumber.test(request[key])) {
            errors[key] = enterValidTime
            isAllValid = false
        }
        else if (key === 'practise_area' && !request[key]) {
            errors[key] = thisFieldIsRequired
            isAllValid = false
        }
        else if (key === 'title' && !request[key]) {
            errors[key] = thisFieldIsRequired
            isAllValid = false
        }
        else if (key === 'mapped_product_id' && !request[key]) {
            errors[key] = thisFieldIsRequired
            isAllValid = false
        } else if (key === 'description' && !request[key]) {
            errors[key] = thisFieldIsRequired
            isAllValid = false
        }
    }
    return isAllValid
}