import React, {Component} from "react";
import {onlyZeroToNine, validateProfileForPayment} from "../common/validation/FormValidator";
import connect from "react-redux/es/connect/connect";
import {apiv1} from "../../api";
import axios from "axios";
import {updatePhone, userDetails} from "../../actions/auth";
import $ from "jquery";
import LoadingComponent from "../../utils/LoadingComponent";
import {errorActionDispatchers} from "../../actions/errors";

class UserUpdateAddress extends Component {
    constructor(props) {
        super(props);
        let {geoCity, cityId, phoneNo} = props
        geoCity = geoCity || []
        this.state = {
            city: geoCity,
            state_data: {},
            city_data: {},
            errors: {},
            address: this.workAddressObj(cityId),
            phone: phoneNo,
            loading: false,
            company_name: '',
            gst_number: '',
            cityId
        }
    }

    componentWillReceiveProps(nextProps) {
        const {geoCity, cityId, phoneNo} = nextProps
        if (geoCity && geoCity.length > 0) {
            this.setState({city: geoCity})
        }
        if (phoneNo) {
            this.setState({phone: phoneNo})
        }
        if (cityId) {
            const address = this.workAddressObj(cityId)
            this.setState({address, cityId})
        }
    }

    workAddressObj = (cityId) => {
        return {city: cityId}
    };

    updatePhone = (e) => {
        const request = {};
        request.profile_type = "client";
        request.phone = '+91' + this.state.phone;
        request.send_otp = false;
        return apiv1.auth.post('users/otp/send', request).then((resp) => {
            return apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch)
        })
    };

    updateBasicDetails = (request) => {
        return apiv1.auth.patch('/rest-auth/user/', request).then((resp) => {
            return apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch)
        })
    };

    updateBasicInfoAndPhone = (e) => {
        e.preventDefault();
        const {phone, address, state_data, cityId, company_name, gst_number} = this.state;
        const {phoneNo, createJob, OnUpdatePhone, onUserDetails, updateProfileAndPostJob} = this.props
        let isPhoneApiHit, isBasicInfoApiHit
        isBasicInfoApiHit = isBasicInfoApiHit = false
        const errors = {};
        const requestApis = []
        const client = {
            address,
            company_name,
            gst_number,
        }
        const validatorObj = {
            ...client,
        }
        const request = {
            client
        }
        if (!cityId) {
            isBasicInfoApiHit = true
            requestApis.push(this.updateBasicDetails(request))
        }
        if (!phoneNo) {
            isPhoneApiHit = true
            client.phone = '+91' + phone;
            validatorObj.phone = phone;
            requestApis.push(this.updatePhone(e))
        }
        // validatorObj.address = {...address};
        validatorObj.address.state = state_data.id === undefined ? '' : state_data.id
        let self = this;
        if (validateProfileForPayment(errors, validatorObj)) {
            this.setState({loading: true});
            axios.all(requestApis)
                .then(axios.spread(function (basicinfo, phoneinfo) {
                    self.setState({loading: false})
                    let phoneObj = {}
                    if (isBasicInfoApiHit && isPhoneApiHit) {
                        phoneObj = phoneinfo
                    } else if (!isBasicInfoApiHit && isPhoneApiHit) {
                        phoneObj = basicinfo
                    }
                    $('.modal-header .close').click();
                    if (isBasicInfoApiHit) {
                        onUserDetails(userDetails(basicinfo))
                    }
                    if (isPhoneApiHit) {
                        OnUpdatePhone(updatePhone(phoneObj))
                    }
                    if (createJob !== undefined) {
                        createJob({cityId: request.client.address.city, phone})
                    } else if (updateProfileAndPostJob) {
                        basicinfo = isBasicInfoApiHit ? {...request} : {}
                        basicinfo = isPhoneApiHit ? {
                            client: {
                                ...basicinfo.client,
                                phone: phoneObj
                            }
                        } : {...basicinfo}
                        let data = {
                            ...basicinfo
                        }
                        updateProfileAndPostJob({data})
                    }
                }))
                .catch((err) => {
                    const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
                    for (let key in resErrors) {
                        errors[key] = resErrors[key][0]
                    }
                    this.setState({loading: false})
                })
        } else {
            this.setState({errors})
        }
    }

    onChange = (e) => {
        let errors = {...this.state.errors}
        const {name, value} = e.target
        errors[name] = ''
        let message = ''
        if (name === 'phone') {
            message = 'Phone no is no valid'
            onlyZeroToNine(errors, name, value, message)
        }
        this.setState({[name]: value, errors})
    };

    workAddressUi = () => {
        const {city, city_data, errors, phone, cityId} = this.state;
        const {phoneNo} = this.props
        return (
            <div>
                <div className="row work-experience">
                    {!phoneNo &&
                    (<div className="col-sm-12">
                        <div className="form-group">
                            <input type="text"
                                   className="form-control input-lg"
                                   name="phone"
                                   value={phone}
                                   placeholder={`Phone Number`}
                                   onChange={this.onChange}
                            />
                            <span className={errors.phone ? 'error' : 'no-error'}>{errors.phone}</span>
                        </div>
                    </div>)
                    }
                    {!cityId &&
                    (
                        <div className="col-sm-12">
                            <div className="form-group">
                                <select className="form-control input-lg"
                                        id={city_data.id}
                                        name='city'
                                        onChange={(e) => {
                                            const address = {...this.state.address};
                                            address.city = e.target.value;
                                            const errors = {...this.state.errors};
                                            errors.city = '';
                                            this.setState({address, errors})
                                        }}
                                >
                                    <option value='-1' className='hidden'>City</option>
                                    {city.map(city => {
                                        return <option value={city.id} key={city.id}
                                                       selected={city.id === city_data.id}>{city.name}</option>
                                    })}
                                </select>
                                <span className={errors.city ? 'error' : 'no-error'}>{errors.city}</span>
                            </div>
                        </div>
                    )
                    }
                </div>
            </div>
        )
    };

    render() {
        const {loading, company_name, gst_number, errors} = this.state;
        return (
            <div className="modal fade" id="consultation_fee" role="dialog">
                <div className="modal-dialog dialog-top-margin">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button"
                                    className="close"
                                    data-dismiss="modal"
                            >&times;</button>
                            <h3 className="modal-title">Update Profile</h3>
                        </div>
                        <div className="modal-body">
                            <div className="col-sm-12">
                                <p className="para">Please enter following details to make the payment.</p>
                                {this.workAddressUi()}
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <input type="text"
                                           className="form-control input-lg"
                                           value={company_name}
                                           onChange={this.onChange}
                                           name="company_name" placeholder="Company Name (optional)"
                                           style={{borderBottom: errors.company_name ? '1px solid #ff337d' : ''}}
                                    />
                                    <span
                                        className={errors.company_name ? 'error' : 'no-error'}>{errors.company_name}</span>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <input type="text"
                                           className="form-control input-lg"
                                           value={gst_number}
                                           onChange={this.onChange}
                                           name="gst_number" placeholder="Gst Number (optional)"
                                           style={{borderBottom: errors.gst_number ? '1px solid #ff337d' : ''}}
                                    />
                                    <span
                                        className={errors.gst_number ? 'error' : 'no-error'}>{errors.gst_number}</span>
                                </div>
                            </div>
                            {loading && (
                                <div style={{marginTop: '20%', marginLeft: '50%'}}><LoadingComponent pastDelay/></div>)}
                            <div className="modal-footer">
                                <div className="text-center">
                                    <button className='btn btn-login'
                                            onClick={(e) => {
                                                this.updateBasicInfoAndPhone(e)
                                            }}
                                    >update Profile
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (() => {
    return {}
});

const mapDispatchToProps = dispatch => {
    return {
        OnUpdatePhone: (action) => {
            dispatch(action);
        },
        onUserDetails: (action) => {
            dispatch(action);
        },
        ...(errorActionDispatchers(dispatch)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserUpdateAddress)
