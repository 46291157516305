
export const KEY_AFFILIATE_MARKETING_DETAILS = 'affiliate_marketing_details'

class SessionStorage {
    get affiliateMarketingDetails() {
        return window.sessionStorage.getItem(KEY_AFFILIATE_MARKETING_DETAILS)
    }

    set affiliateMarketingDetails(value) {
        window.sessionStorage.setItem(KEY_AFFILIATE_MARKETING_DETAILS, value)
    }

    clear() {
        window.sessionStorage.clear()
    }

    removeKey(KEY) {
        window.sessionStorage.removeItem(KEY)
    }

}

const instance = new SessionStorage();

export default instance
