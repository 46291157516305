import React, {Component, Fragment} from "react";
// import {footer, header} from "../AboutUs";
import * as path from '../../constants/path'
import localStorage from "../../utils/localStorage";
import {PROFESSIONAL} from "../../constants/auth";
// import {Link} from "react-router-dom";
import $ from "jquery"
// import {
//     ExpertReviewsCard,
//     InTheNews,
//     subHeadingvideoTextForProfessionalfirst,
//     subHeadingvideoTextForProfessionalsecond, WizCounselValue
// } from "../common/AllOwlCrousel";
import {connect} from "react-redux";
// import AppDownloadInput from "../common/AppDownloadInput";
import {siteLogoOnSharedMedia} from "../common/bannerTextForProducts";
import Header from "../functional/beforeHire";
import {baseUrl} from "../../constants/common";

// import SiteHeader from "../SiteHeader";
export function spyScroll(){
    $('.target-link').on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $("#Info_guide").offset().top
        }, 1000);
    });
    //     $('.sticky-tab-bar li').click(function () {
    //         $('.sticky-tab-bar li').removeClass("active");
    //         $(this).addClass("active");
    // });
}
const gigsterReviews = [
    {
        text: 'Workbeam is the best online platform for the Indian freelancers to get genuine clients easily. It\'s very easy to use and the customer support is awesome. They were available and solve the problems all the time, whenever I needed it. I really recommend Workbeam to other freelancers as well.',
        image: 'https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/Sreejith-Ravi-Graphic-Designer-.webp',
        name: 'Sreejith R.',
        bio: 'Web/App Developer'
    },
    {
        text: 'My journey on Workbeam is going well and pretty much good and the team is very supportive and active. I would recommend other freelancers because the platform has very good supportive teams. It is Different from other platforms because they have a new concept with a lot of features, easily manageable, you can connect with the team easily, calling features are there etc.',
        image: 'https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/Paras-Bindal-Graphic-designer.webp',
        name: 'Paras B.',
        bio: 'Graphic designer'
    },
    {
        text: 'Workbeam is the Top leading Platform in the Indian Market that helps experts get quality clients. From onboarding to support, Workbeam Team leaves no stone unturned in building seamless (vendor/seller) experience. I highly recommend professionals out there to be a part of this platform.',
        image: 'https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/Capelon-creations-Manishi-Graphic-designer.webp',
        name: 'Manishi',
        bio: 'Graphic designer'
    },
    {
        text: 'Workbeam is very awesome and simple to me. It helps me to get many more projects and in my growth. Workbeam is a much better version of freelancing portals. Feature rich and support rich. ',
        image: 'https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/Surya-pratap-Web_app-developer-.webp',
        name: 'Surya P.',
        bio: 'Web/App Developer',
    },
    {
        text: 'My experience with Workbeam has been very smooth and rewarding. Workbeam helped me to scale my freelancing career and have a regular income. The team also helped me to polish my skills through various workshops. As a freelancer, it can be difficult for you at times but team Workbeam has always helped me and made this journey easier than I ever thought.',
        image: 'https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/jaipal.webp',
        name: 'Jaypal S.',
        bio: 'Website Developer'
    },
    {
        text: 'My experience with Workbeam has been the best. If you are in quest of genuine and high-paying clients, then this is the place to be. Also, Workbeam offers you a myriad of features that makes managing and dealing with clients a cakewalk. Their highly amicable and hyperactive team is par excellence and always ready to assist or help you.',
        image: 'https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/jasmit.webp',
        name: 'Jasmit S.',
        bio: 'Content Writer',
    },
]
export const firstClientTestimonialData = [
    {
        quote: 'Very easy to use the App, specially for someone like me who is lazy in filing Taxes etc. Shikha was a efficient resource in guiding me for my Annual I.T return. All my queries and issues were answered patiently by Shikha. I highly recommend her . Thank you, Workbeam. Looking forward to use it more often. cheers!',
        image: "https://workbeam.blob.core.windows.net/wiz-public-cdn/client-testimonials/001.jpg",
        name: 'Radhika A.',
        bio: 'Marketing Professional'
    }
]
export const secondClientTestimonialData = [
    {
        quote: 'I was really struggling with managing GST. I hired a CA for GST filing and it went really well. I later hired another Freelance Expert for my company registration and trademark registration.',
        image: "https://workbeam.blob.core.windows.net/wiz-public-cdn/client-testimonials/client-08.png",
        name: 'Sahib S.',
        bio: 'Founder, Saheb Productions'
    }
]
const firstExpertTestimonialData = [
    {
        quote: 'A very Good Platform. The interface is user-friendly and good work opportunities are provided. The team is supportive. The good part is it is free for experts given the hard work Team Workbeam is putting. The various features like paying in instalments and tracking work delivery help in customer satisfaction and help expert retain clients and work professionally.',
        image: "https://workbeam.blob.core.windows.net/wiz-public-cdn/client-testimonials/Anjali.png",
        name: 'Anjali R.',
        bio: 'Chartered Accountant'
    }
]
const secondExpertTestimonialData = [
    {
        quote: 'It\'s a great platform for people seeking tax, accounting and GST Services.  Also, the team is very helpful & supportive. Overall very satisfied with the service. I have earned around 40,000 in a short period of time. Design is very friendly. The best part is how team and platform work in retaining clients.',
        image: "https://workbeam.blob.core.windows.net/wiz-public-cdn/client-testimonials/Ledger360.jpg",
        name: 'Ledger 360',
        bio: 'Tax & Accounting Expert'
    }
]

export function ProfessionalSignUpLink({text, classes}) {
    return(
        !localStorage.isSessionValid() && <a className={classes} href={localStorage.isSessionValid() ? path.userLogin : path.professionalRegistration} title={`Join Now`}>{text}</a>
    )
}
export function  ClientTestimonials({testimonials}){
    return(
        <Fragment>
            {testimonials.map(testimonialData => {
                const {quote, image, name, bio} = testimonialData
                return(
                    <Fragment>
                        <blockquote>
                        <p className="quote">
                            {`"${quote}"`}
                        </p>
                        </blockquote>
                        <div className="media">
                            <div className="media-left">
                                <img src={image} className="media-image" alt={name}/>
                            </div>
                            <div className="media-body">
                                <h3 className="media-heading">{name}</h3>
                                <p className="para">{bio}</p>
                            </div>
                        </div>
                    </Fragment>
                )
            })}
        </Fragment>
    )
}
export function CommunicateVideo({comnicateSubHeadingVideoText, clientTestimonials, expertTestimonial, children}) {
   return(
       <section className="video-section-ui">
           <div className="container">
               <div className="row display-flex">
                   <div className="col-sm-6">
                       <video width="100%" height="100%" autoPlay loop muted playsInline preload>
                           <source src="https://workbeam.blob.core.windows.net/wiz-public-cdn/video/Chat-with-experts-upload-files.mp4"/>
                           <source src="https://workbeam.blob.core.windows.net/wiz-public-cdn/video/Chat-with-experts-upload-files.webm"/>
                       </video>
                   </div>
                   <div className="col-sm-6">
                   <div className="col-padding left-offset-1">
                       <h2 className="text-black-sub-heading">Communicate & Execute Seamlessly</h2>
                       <h3 className="text-normal-para">Communicate and share files using Workbeam Dashboard. Use Audio Calling & chatting and inbuilt cloud storage to execute your projects.</h3>
                       {children}
                   </div>
                   </div>
               </div>
           </div>
       </section>
   )
}
export function MilestoneTrackVideo({subHeadingvideoText, clientTestimonials, expertTestimonial, children}) {
  return(
      <section className="video-section-ui">
          <div className="container">
              <div className="row">
                  <div className="col-sm-6">
                      <div className="col-padding">
                      <h2 className="text-black-sub-heading">Track Work Delivery & Payments</h2>
                      <h3 className="text-normal-para">{subHeadingvideoText}</h3>
                          {children}
                      </div>
                  </div>
                  <div className="col-sm-6">
                      <video width="100%" height="100%" autoPlay loop playsInline preload muted>
                          <source src="https://workbeam.blob.core.windows.net/wiz-public-cdn/video/Mildstone-tracking.mp4"/>
                          <source src="https://workbeam.blob.core.windows.net/wiz-public-cdn/video/Milestone-tracking.webm"/>
                      </video>
                  </div>
              </div>
          </div>
      </section>
  )
}
export function professionalheader(props) {
    const {history} = props
    const {location} = history
    let {pathname} = location
    pathname = pathname || ''
    let pageLink = baseUrl + '/' + pathname.substring(1)
    let title = `Workbeam - Marketplace and CRM for Freelancers`
    let metaDesc = `The fastest growing Freelance Marketplace in India and a powerful Freelance CRM to sell and deliver services like a pro.`
    return (
        <div>
            <Header link={pageLink} avatar={siteLogoOnSharedMedia} metaDescription={metaDesc} title={title}/>
        </div>
    )
}

class ProfessionalLandingPage extends Component {

    componentDidMount() {
        localStorage.userType = PROFESSIONAL;
    }
    render() {
        return (
            <Fragment>
                {professionalheader(this.props)}
                <section className="affiliate-home-section for-expert-section" style={{background:'#fff'}}>
                    <div className="container">
                        <div className="row for-expert-services" style={{marginTop:'0px'}}>
                            <div className="col-sm-6 col-md-6 col-xs-12">
                                <h1 className="text-black-heading">Monetise Your Skills. Sell & Deliver Services like a Pro.</h1>
                                <h2 className="banner-text-para">Workbeam works as a marketplace and as a SAAS to make it easy for you to pursue your passion.</h2>
                                <h2 className="banner-text-sub-para">For Freelancers | Service Agencies | Creators</h2>
                                <ProfessionalSignUpLink text={'Register as Professional'} classes={'btn btn-for-business'}/>
                              </div>
                            <div className="col-sm-6 col-md-5 col-xs-12 left-offset-1">
                                <div className="expert-banner-border-img">
                                    <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/expert-banner-img.webp" className="img-responsive" alt="Grow & Manage Your Services Business."/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*<section className="page-contant">*/}
                    {/*<div className="main_counter_area">*/}
                            {/*<div className="container">*/}
                                {/*<div className="row">*/}
                                    {/*<div className="main_counter_content text-center white-text wow fadeInUp">*/}
                                        {/*<div className="col-sm-4">*/}
                                            {/*<div className="single_counter p-y-2">*/}
                                                {/*<img src={require('../../static/images/SMB.png')} alt={`2000+ SMBs have hired`}/>*/}
                                                {/*<p className="statistic-counter">2000+</p>*/}
                                                {/*<p>SMBs have hired</p>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-sm-4">*/}
                                            {/*<div className="single_counter p-y-2">*/}
                                                {/*<img src={require('../../static/images/Experts.png')}  alt={`1000+ Verified Experts`}/>*/}
                                                {/*<p className="statistic-counter">1000+</p>*/}
                                                {/*<p>Verified Experts</p>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-sm-4">*/}
                                            {/*<div className="single_counter p-y-2">*/}
                                                {/*<img src={require('../../static/images/Transacted.png')}  alt={`$80 lac+ Transacted`}/>*/}
                                                {/*<p className="statistic-counter">$2cr+</p>*/}
                                                {/*<p>Value Created</p>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                    {/*</div>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                    {/*</div>*/}
                {/*</section>*/}
                <section className="professional-social-ui">
                    <div className="container-fluid">
                        <div className="row">
                           <div className="col-sm-12">
                               <p className="xs-para text-center">Trusted by 3000+ Businesses</p>
                               <ul className="list-inline for-brand-section">
                                   <li><img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/fab_black_logo.webp" alt="Fab Hotel - Workbeam"/></li>
                                   <li><img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/etm_black_logo.webp" alt="Etmony - Workbeam"/></li>
                                   <li><img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/icici_black_logo.webp" alt="Icici Bank - Workbeam"/></li>
                                   <li><img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/isuzu_black_logo.webp" alt="Isuzu - Workbeam" style={{width:'8vw'}}/></li>
                                   <li><img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/timesInternet_black_logo.webp" alt="Time internet - Workbeam" style={{width:'8vw'}}/></li>
                               </ul>
                           </div>
                        </div>
                    </div>
                </section>
                <section className="affiliate-home-section for-expert-section professional-social-ui">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                    <div className="row">
                                        <div className="col-sm-10 col-sm-offset-1">
                                            <h2 className="text-black-sub-heading text-center">Whatever it takes to help Creators start, grow and build their Business & Careers.</h2>
                                        </div>
                                    </div>
                                    <div className="row for-expert-services">
                                        <div className="col-md-5 col-sm-6 col-md-offset-1">
                                            <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/expert_value_props.webp" className="img-responsive" alt="Why become a Seller Partner with Workbeam?"/>
                                        </div>
                                        <p className="visible-xs">&#x00A0;</p>
                                        <div className="col-md-5 col-sm-6 left-offset-1">
                                            <div className="media">
                                                <div className="media-body">
                                                    <h2 className="media-heading">Register for Free. Pay only when you earn.</h2>
                                                    <p className="xs-para">Workbeam is totally free to enrol and setup your Services Store. However, we only approve Freelance Professionals & Service Experts who complete their profile and create proper gigs with proof of work.</p>
                                                </div>
                                            </div>
                                            <div className="media">
                                                <div className="media-body">
                                                    <h2 className="media-heading">Get Leads and Business opportunities</h2>
                                                    <p className="xs-para">Our Listing algorithm makes sure you get right leads. Our Tech enables you to convert leads successfully.</p>
                                                </div>
                                            </div>
                                            <div className="media">
                                                <div className="media-body">
                                                    <h2 className="media-heading">Deliver delightful service experiences</h2>
                                                    <p className="xs-para">Keep customers happy with inbuilt features like milestones tracking, Zoom meetings, deadline tracking and requesting payments.</p>
                                                </div>
                                            </div>
                                            <div className="media">
                                                <div className="media-body">
                                                    <h2 className="media-heading">Learn, upskill & network on Workbeam Community</h2>
                                                    <p className="xs-para">Be part of our growing Community of SMEs and Service Professionals. Learn, network, get rewards and access to free resources.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>

                        </div>
                    </div>
                </section>

                {/*<section className="page-contant">*/}
                    {/*<div className="container why-become-partner">*/}
                        {/*<div className="row">*/}
                            {/*<div className="col-sm-12">*/}
                                {/*<h2 className="scope-heading text-center">Why become Seller Partner with Workbeam<span className="dot-pink">.</span></h2>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                        {/*<div className="row">*/}
                                {/*<div className="col-sm-6 col-md-3 column-padding text-center">*/}
                                    {/*<p className="emoji">&#128515;</p>*/}
                                    {/*<p className="xs-para">Free - Workbeam is totally free to register. We only charge when you earn. when you grow we grow.</p>*/}
                                {/*</div>*/}
                                {/*<div className="col-sm-6 col-md-3 column-padding text-center">*/}
                                    {/*<p className="emoji">&#129297;</p>*/}
                                    {/*<p className="xs-para">Earnings - Our Listing algorithm makes sure you get leads. Our Seller Partners earn up to $60K a month.</p>*/}
                                {/*</div>*/}
                               {/*<p className="visible-xs visible-sm"></p>*/}
                                {/*<div className="col-sm-6 col-md-3 column-padding text-center">*/}
                                    {/*<p className="emoji">&#128525;</p>*/}
                                    {/*<p className="xs-para">Payment Protection - Buyers make an advance escrow payment before booking your gigs & then balance payments as per milestones.</p>*/}
                                {/*</div>*/}
                                {/*<div className="col-sm-6 col-md-3 column-padding text-center">*/}
                                    {/*<p className="emoji">&#129303;</p>*/}
                                    {/*<p className="xs-para">Order Management - Our tech empowers you to manage and complete an Order with full transparency with tracking of predefined milestones & deliverables.</p>*/}
                                {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</section>*/}
                  {/*<section className="affiliate-home-section professional-social-ui">*/}
                    {/*<div className="container">*/}
                        {/*<div className="row">*/}
                            {/*<div className="col-sm-9 col-sm-offset-1">*/}
                                {/*<h2 className="text-black-sub-heading text-center">Setup your services store, get orders and deliver delightful service experiences to your customers</h2>*/}
                                {/*<div className="home-industry-cards" style={{justifyContent:'center'}}>*/}
                                    {/*<div className="value-props-wrapper">*/}
                                        {/*<div className="home-industry__icon">*/}
                                        {/*<img src={require("../../static/images/complete-icon.png")} style={{width:'40px'}} alt="Complete your profile and create Gigs as per guidelines and then submit for approval."/>*/}
                                        {/*</div>*/}
                                        {/*<p className="para">Complete your profile and create Gigs as per guidelines and then submit for approval.</p>*/}
                                    {/*</div>*/}
                                    {/*<div className="value-props-wrapper">*/}
                                        {/*<div className="home-industry__icon">*/}
                                            {/*<img src={require("../../static/images/resume-icon.png")} alt="Go through our training videos and standard operating procedure and fill a questionnaire"/>*/}
                                        {/*</div>*/}
                                        {/*<p className="para">Go through our training videos and standard operating procedure and fill a questionnaire</p>*/}
                                    {/*</div>*/}
                                    {/*<div className="value-props-wrapper">*/}
                                        {/*<div className="home-industry__icon">*/}
                                            {/*<img src={require("../../static/images/question-icon.png")} alt="After questionnaire submission, we do a 30-minute call to*/}
                                            {/*know you better."/>*/}
                                        {/*</div>*/}
                                        {/*<p className="para">After questionnaire submission, we do a 30-minute call to*/}
                                            {/*know you better.</p>*/}
                                    {/*</div>*/}
                                    {/*<div className="value-props-wrapper">*/}
                                        {/*<div className="home-industry__icon">*/}
                                            {/*<img src={require("../../static/images/flag.png")} style={{width:'40px'}} alt="We approve you to be our partner and sell on Workbeam."/>*/}
                                        {/*</div>*/}
                                        {/*<p className="para">We approve you to be our partner and sell on Workbeam.</p>*/}
                                    {/*</div>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</section>*/}
                <section className="affiliate-home-section professional-social-ui">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h2 className="text-black-sub-heading text-center">Setup your services store, get orders and deliver delightful<br className="hidden-xs"/> service experiences to your customers</h2>

                            </div>
                        </div>
                        <div className="row for-expert-services">
                            <div className="col-sm-6 col-md-5 col-md-offset-1">
                                <div className="expert-banner-border-img">
                                <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/create-your-service.webp" className="img-responsive" alt="Grow & Manage Your Services Business."/>
                                </div>
                            </div>
                                <div className="col-sm-6 col-md-5 left-offset-1">
                                    <h2 className="text-black-sub-heading">Create Your Service Gigs</h2>
                                    <h3 className="text-normal-para">Setup your services store by creating Gigs with defined packages, prices, timelines and deliverables. Get filtered as per your skills and industry expertise.</h3>
                                </div>
                        </div>
                    </div>
                </section>
                <section className="affiliate-home-section professional-social-ui">
                    <div className="container">
                        <div className="row for-expert-services">
                            <div className="col-sm-6 col-md-5 col-md-offset-1">
                                <div className="expert-banner-border-img">
                                <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/share-custom-quotes.webp" className="img-responsive" alt="Grow & Manage Your Services Business."/>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-5 left-offset-1">
                                <h2 className="text-black-sub-heading">Get matched with right leads, communicate, share custom quotes </h2>
                                <h3 className="text-normal-para">Get notified on leads and business opportunities. Communicate and convert leads to customers.</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="affiliate-home-section professional-social-ui">
                    <div className="container">
                        <div className="row for-expert-services">
                            <div className="col-sm-6 col-md-5 col-md-offset-1">
                                <div className="expert-banner-border-img">
                                <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/manage-order-delivery.webp" className="img-responsive" alt="Grow & Manage Your Services Business."/>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-5 left-offset-1">
                                <h2 className="text-black-sub-heading">Manage and deliver Service Orders successfully</h2>
                                <h3 className="text-normal-para">Manage service deadlines, create and track milestones, request payments. Keep customers happy and retain them. </h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="affiliate-home-section professional-social-ui">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <h2 className="text-black-sub-heading">On a mission to build a happy community of Creators, <br className="hidden-xs"/>Freelancers & Service Professionals. </h2>
                            </div>
                            <div className="col-sm-8 col-sm-offset-2 text-center">
                                <p>&#x00A0;</p>
                                <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/big-shoes-having-fun.webp" className="img-responsive happy-community" alt="Grow & Manage Your Services Business."/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="professional-social-ui affiliate-reviews-section" style={{background:'#FF9CC0'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h2 className="text-black-sub-heading text-center">What Gigzters are saying</h2>
                                <ul className="customer-review-wrapper list-unstyled">
                                    {gigsterReviews.map(reviewsData =>{
                                        const {text, name, bio, image} = reviewsData
                                        return(
                                            <li>
                                                <p className="text-para-reviews">{text}</p>

                                                <div className="r-author-detail">
                                                    <div className="media">
                                                        <div className="media-left">
                                                            <img src={image} className="expert-dp" style={{marginRight:'10px'}} alt={name}/>
                                                        </div>
                                                        <div className="media-body">
                                                            <p className="text-name">{name}</p>
                                                            <p className="text-bio">{bio}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                {/*<section className="video-section-ui">*/}
                    {/*<div className="container">*/}
                        {/*<div className="row">*/}
                            {/*<div className="col-sm-12">*/}
                                {/*<h2 className="scope-heading text-center">What Gigzters are saying<span className="dot-pink">.</span></h2>*/}
                                {/*<p>&#x00A0;</p>*/}
                                {/*<p className="hidden-xs">&#x00A0;</p>*/}
                            {/*</div>*/}
                            {/*<div className="col-sm-6 col-sm-offset-3">*/}
                                {/*<ExpertReviewsCard/>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</section>*/}
                {/*<CommunicateVideo comnicateSubHeadingVideoText={subHeadingvideoTextForProfessionalfirst}>*/}
                    {/*<ClientTestimonials testimonials={firstExpertTestimonialData}/>*/}
                {/*</CommunicateVideo>*/}
                {/*<MilestoneTrackVideo subHeadingvideoText={subHeadingvideoTextForProfessionalsecond}>*/}
                    {/*<ClientTestimonials testimonials={secondExpertTestimonialData}/>*/}
                {/*</MilestoneTrackVideo>*/}
                {/*<section className="affiliate-home-section professional-social-ui">*/}
                    {/*<div className="container-fluid ui-padding">*/}
                        {/*<div className="row col-mokeup display-flex">*/}
                            {/*<div className="col-sm-4 col-sm-offset-1 hidden-xs">*/}
                                {/*<img src={PhoneMoke} className="img-responsive" alt={`Be more responsive Download Gizoe Mobile App`} title={`Be more responsive Download Gizoe Mobile App`}/>*/}
                            {/*</div>*/}
                            {/*<div className="col-sm-6 left-offset-1">*/}
                                {/*<h2 className="lg-white-heading">Be more responsive<span className="dot-pink">.</span>*/}
                                {/*</h2>*/}
                                {/*<h2 className="text-sub-heading">Download Workbeam <br className="hidden-xs"/>Mobile*/}
                                    {/*App.</h2>*/}
                                {/*<p className="text-white-para">Make business, respond promptly and track*/}
                                    {/*<br/>work on the run.</p>*/}
                                {/*<p className="hidden-xs">&#x00A0;</p>*/}
                                {/*<ul className="list-inline">*/}
                                    {/*<li><a*/}
                                        {/*href="https://play.google.com/store/apps/details?id=com.blizzcraft.wizpro&hl=en"*/}
                                        {/*target="_blank">Android</a>*/}
                                    {/*</li>*/}
                                    {/*<li className="col-xs-offset-1"><a*/}
                                        {/*href="https://itunes.apple.com/us/app/wizcounsel-professionals/id1447086305?ls=1&mt=8"*/}
                                        {/*target="_blank">iOS</a>*/}
                                    {/*</li>*/}
                                {/*</ul>*/}

                                {/*<AppDownloadInput isUserApp={false}/>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</section>*/}
            </Fragment>
        );
    }
}

export default connect('', '')(ProfessionalLandingPage)