import React, { Component } from 'react'
import ringLoader from '../static/images/ring_loader.svg'
import error from '../static/images/error.svg'
import Loader from 'react-loader-spinner'

class LoadingComponent extends Component {
  renderUI (img, msg) {
    return (
      <div className='Loader'>
        {/*<img style={{display: 'block'}} src={img} alt='error'/>*/}
          <Loader
              type="Oval"
              color="red"
              height="60"
              width="60"
          />
        {/*<div>{msg}</div>*/}
      </div>
    )
  }

  render () {
    if (this.props.error) {
      return this.renderUI(error, 'Oops, something went wrong ...')
    } else if (this.props.pastDelay) {
      return this.renderUI(ringLoader, '')
    } else {
      return null
    }
  }
}

class DoLoadComponent extends Component {
  renderUI (img, msg) {
    return (
        <div className='lazy-loader' style={{position: 'fixed'}}>
          <Loader
              type="Oval"
              color="red"
              height="60"
              width="60"
          />
        </div>
    )
  }

  render () {
    if (this.props.error) {
      return this.renderUI(error, 'Oops, something went wrong ...')
    } else if (this.props.pastDelay) {
      return this.renderUI(ringLoader, '')
    } else {
      return null
    }
  }
}

function doLoad (exp) {
  return {
    loader: () => exp(),
    loading: DoLoadComponent
  }
}

function doLoadWithDelay (exp) {
  return {
    loader: () => {
      return new Promise(resolve => {
        setTimeout(resolve, 500)
      }).then(() => exp())
    },
    loading: LoadingComponent
  }
}

export { doLoad, doLoadWithDelay }
export default LoadingComponent
