import React, { Fragment, useState } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import * as path from '../../constants/path'
import { consultation, category } from '../../constants/keyWord'
import GigzoeLogo from "../../static/images/gigzoe-logo.png";
import { getAvatar, getFirstAndLastLetterOfName } from "./userAndProfessionalProfileFunctions";
import { ratingUi, ratingUiWith_I_Tag } from "../functional/beforeHire";
import { parentPracticeAreaSlug, productSlugMappedWithId } from './bannerTextForProducts'
import Link from "react-router-dom/es/Link";
import { fiveStar } from "../AboutUs";
import EcoLogo from "../../static/images/eco-times-logo.png";
import StoryLogo from "../../static/images/your-story-logo.png";
import LiveMint from "../../static/images/live-mint.png";
import Inc42 from "../../static/images/inc42.png";
import TimesNow from "../../static/images/timesNow-edited.png";
import Ecosmall from "../../static/images/et.png";
import TheHindu from "../../static/images/Thehindu.png";
import mlsoft from "../../static/images/mlsoft.gif"
import Interweave from "interweave";
import ProductSearch from "../services/ProductSearch";
import { requestQuote } from "../../constants/keyWord";
import AppDownloadInput from "./AppDownloadInput";
import PopularServices from "./PopularServices";
import { GetFirstNameAndLastName } from "../../functions/common";
import TalkToSalesForm from "./TalkToSalesForm";
import HomePageCustomerReviews from "./HomePageCustomerReviews"
import { baseUrl, mainDomain } from "../../constants/common";
import DataScienceIcon from "../../static/images/categories/data_science.png";
import DataScienceHoverIcon from "../../static/images/categories/data_science1.png";
import NaturalLangIcon from "../../static/images/categories/nlp.png";
import NaturalLangHoverIcon from "../../static/images/categories/nlp1.png";
import ComputerVisionIcon from "../../static/images/categories/computer_vision.png";
import ComputerVisionHoverIcon from "../../static/images/categories/computer_vision1.png";
import DeepLearningIcon from "../../static/images/categories/deep_learning.png";
import DeepLearningHoverIcon from "../../static/images/categories/deep_learning1.png";
import ReinforcementIcon from "../../static/images/categories/re_learning.png";
import ReinforcementHoverIcon from "../../static/images/categories/re_learning1.png";
import UnsupervisedIcon from "../../static/images/categories/un_learning.png";
import UnsupervisedHoverIcon from "../../static/images/categories/un_learning1.png";
import FHIRIcon from "../../static/images/categories/FHIR.png";
import FHIRHoverIcon from "../../static/images/categories/FHIR1.png";
import MoneyIcon from "../../static/images/categories/money.png";
import MoneyHoverIcon from "../../static/images/categories/money1.png";
import CustomerIcon from "../../static/images/categories/customer_churn.png";
import CustomerHoverIcon from "../../static/images/categories/customer_churn1.png";
import PandasIcon from "../../static/images/categories/pandas.png";
import PandasHoverIcon from "../../static/images/categories/pandas1.png";
import D3Icon from "../../static/images/categories/d3.png";
import D3HoverIcon from "../../static/images/categories/d31.png";
import ExpertsImage from "../../static/images/design2.png";

export const responsiveLegalItems = {
    0: {
        items: 2,
        nav: false,
        dots: true,
        margin: 10,
        stagePadding: 20,
        loop: true,
        center: false
    },
    600: {
        items: 3,
        nav: false,
        dots: true,
        margin: 10,
        stagePadding: 20,
        loop: true,
        center: false
    },
    1024: {
        items: 4,
        dots: true,
        stagePadding: 5,
    }
}
export const responsiveSuggestedItems = {
    0: {
        items: 1,
        nav: false,
        dots: false,
        margin: 20,
        stagePadding: 20,
        loop: true,
        center: false
    },
    600: {
        items: 1,
        nav: false,
        dots: false,
        margin: 10,
        stagePadding: 20,
        loop: true,
        center: false
    },
    1024: {
        items: 4
    }
}
export const responsiveTrendingItems = {
    0: {
        items: 1,
        nav: false,
        dots: true,
        margin: 10,
        stagePadding: 10,
        loop: true,
        center: false
    },
    600: {
        items: 1,
        nav: false,
        dots: true,
        margin: 10,
        stagePadding: 10,
        loop: true,
        center: false
    },
    1024: {
        items: 3
    }
}
export const responsiveItemsExpert = {
    0: {
        items: 1,
        nav: false,
        dots: false,
        margin: 10,
        stagePadding: 10,
        loop: true,
        center: false
    },
    400: {
        items: 1,
        nav: false,
        dots: false,
        margin: 20,
        stagePadding: 50,
        loop: true,
        center: false
    },
    600: {
        items: 1,
        nav: false,
        dots: false,
        margin: 10,
        stagePadding: 50,
        loop: true,
        center: false
    },
    1024: {
        items: 4,
        margin: 20,

    }
}
export function OurBrand() {
    return (
        <div className="row">
            <div className="col-sm-12 text-center">
                <h2 className="heading-sm text-center" style={{ marginBottom: '10px' }}>Our Partner brands</h2>
                <p className="xs-para text-center"><b>Loved by Businesses of all shapes & sizes</b></p>
                <div className="our-brand-logo">
                    <div className="our-brand-logo-wrapper">
                        <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/reward-brand/udaan.png" alt="Udaan" />
                    </div>
                    <div className="our-brand-logo-wrapper">
                        <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/reward-brand/times-internet.png" alt="Times Internet" />
                    </div>
                    <div className="our-brand-logo-wrapper">
                        <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/reward-brand/fitternity.png" alt="Fitternity" />
                    </div>
                    <div className="our-brand-logo-wrapper">
                        <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/reward-brand/paytm.png" alt="Paytm" />
                    </div>
                    <div className="our-brand-logo-wrapper">
                        <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/reward-brand/icici-bank.png" alt="Icici bank" />
                    </div>
                    <div className="our-brand-logo-wrapper">
                        <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/reward-brand/xoxoday.png" alt="xoxoday" />
                    </div>
                    <div className="our-brand-logo-wrapper">
                        <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/reward-brand/etmony.png" alt="etmony" />
                    </div>
                    <div className="our-brand-logo-wrapper">
                        <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/reward-brand/fab-hotel.png" alt="Fab Hotel" />
                    </div>
                    <div className="our-brand-logo-wrapper">
                        <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/reward-brand/sarc.png" alt="Sarc Associate" />
                    </div>
                    <div className="our-brand-logo-wrapper">
                        <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/reward-brand/indifi.png" alt="Indifi" />
                    </div>
                    <div className="our-brand-logo-wrapper">
                        <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/reward-brand/nagarro.png" alt="nagarro" />
                    </div>
                    <div className="our-brand-logo-wrapper">
                        <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/reward-brand/sgasolar.png" alt="Workbeam partner" />
                    </div>
                </div>
            </div>
        </div>
    )
}
export function TalkTosales() {
    return (
        <section className="professional-social-ui">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-default quote-panel text-center" style={{ backgroundColor: '#E0FFF3' }}>
                            <div className="panel-body">
                                <h2 className="heading-sm">Have a custom requirement or need help in booking the service?</h2>
                                {/*<p className="text-primary">&#x00A0;</p>*/}
                                <br />
                                <Link to={path.covid19FreeSignup} className="btn btn-job-post" title={requestQuote}>{requestQuote}</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}
export function ProfessionalCard({ professionalDetails, discountedAmount, discountDetails, changeProductCountCallBack }) {
    const { product_name, package_description, quantity_count, first_name, last_name, avatar, bio_caption, original_price } = professionalDetails
    const { price } = original_price
    let { is_valid_coupon_code } = discountDetails || {}
    return (
        <div className="selected-professional-card">
            <h4 className="xs-heading text-center" style={{ marginTop: '0px' }}>Order Summary</h4>
            {first_name &&
                <Fragment>
                    <h4 className="side-card-heading">Professional</h4>
                    <div className="media">
                        <div className="media-left">
                            {avatar ? (<img src={avatar} alt="user" className="dp-image img-circle" />)
                                :
                                getFirstAndLastLetterOfName(first_name, last_name)
                            }
                        </div>
                        <div className="media-body">
                            <h4 className="profile-name"><GetFirstNameAndLastName firstname={first_name} lastname={last_name} />
                            </h4>
                            <p className="profile-description">{bio_caption}</p>
                        </div>
                    </div>
                </Fragment>
            }
            <h4 className="text-bold">Service</h4>
            <p className="para">{product_name}</p>
            <p className="text-bold">Quantity <span className="pull-right" style={{ color: '#2c16dc' }}>{quantity_count}</span></p>
            {/*{quantity_count && changeProductCountCallBack &&*/}
            {/*<ProductIncrease  changeProductCountCallBack={changeProductCountCallBack} quantity_count={quantity_count}/>*/}
            {/*}*/}
            {/*code by Abdhesh on 16/05/2020 start */}
            {price > 0 && (
                <h4 className="text-bold">Amount
                    <span className="pull-right" style={{ color: '#2c16dc' }}>
                        {`${price > 0 ? `$${price}` : 'Free'}`}
                    </span>
                </h4>
            )}
            {/*{price === 0 && (*/}
            {/*<h4 className="text-bold">Amount <span className="pull-right" style={{color: '#2c16dc'}}>Free</span></h4>*/}
            {/*)}*/}
            {/*code by Abdhesh on 16/05/2020 end */}

            {/*<h4 className="text-bold">Amount <span className="pull-right" style={{color: '#2c16dc'}}>${price}</span></h4>*/}
            {is_valid_coupon_code &&
                <p className="text-bold">
                    After discount
                    <span className="pull-right" style={{ color: '#2c16dc' }}>${price - discountedAmount}</span>
                </p>
            }
            <p className="text-note text-right">
                * Inclusive of all Taxes & Fees.
            </p>

            <div className="whats-include" style={{ marginTop: '20px' }}>
                <Interweave
                    tagName="span"
                    content={package_description}
                />
            </div>
        </div>
    )

}
export const serviceGridData = [
    {
        name: 'Data Science',
        path: `/${category}/machine-learning/data-science`,
        parentId: 1,
        type: 'MACHINE LEARNING ALGORITHMS',
        headingText: 'Income Tax Services',
        subHeadingText: 'Book Income Tax Filing and GST Services with Workbeam\'s verified Chartered Accountants & Tax Consultants',
        userRating: '4.9',
        userCount: '1k',
        icon: DataScienceIcon,
        hoverIcon: DataScienceHoverIcon,
    },
    {
        name: "Natural Language Processing (NLP)",
        path: `/${category}/machine-learning/natural-language-processing`,
        parentId: 1,
        type: "NATURAL LANGUAGE PROCESSING (NLP)",
        headingText: "Natural Language Processing (NLP)",
        subHeadingText:
            "Developing algorithms and models to understand and analyze human language, enabling tasks such as sentiment analysis, language translation, chatbots, and voice assistants",
        userRating: "4.9",
        userCount: "1k",
        icon: NaturalLangIcon,
        hoverIcon: NaturalLangHoverIcon,
    },
    {
        name: "Computer Vision",
        path: `/${category}/machine-learning/computer-vision`,
        parentId: 5,
        headingText: "Computer Vision",
        subHeadingText:
            "Applying machine learning techniques to images and video to enable tasks like object recognition, facial recognition, image segmentation, and autonomous vehicles",
        userRating: "4.8",
        userCount: "1k",
        icon: ComputerVisionIcon,
        hoverIcon: ComputerVisionHoverIcon,
    },
    {
        name: "Deep Learning",
        path: `/${category}/machine-learning/deep-learning`,
        parentId: 106,
        headingText: "Deep Learning",
        subHeadingText:
            "Utilizing neural networks with multiple layers to solve complex problems that require advanced pattern recognition, such as image, speech, or text-based tasks.",
        userRating: "4.7",
        userCount: "570",
        icon: DeepLearningIcon,
        hoverIcon: DeepLearningHoverIcon,
    },
    {
        name: "Reinforcement Learning",
        path: `/${category}/machine-learning/reinforcement-learning`,
        parentId: 3,
        headingText: "Reinforcement Learning",
        subHeadingText:
            "Designing algorithms that enable an AI system to learn and improve its behavior based on feedback from its environment, often used in robotic control, game playing, and optimization",
        userRating: "4.8",
        userCount: "540",
        icon: ReinforcementIcon,
        hoverIcon: ReinforcementHoverIcon,
    },
    {
        name: "Unsupervised Learning",
        path: `/${category}/machine-learning/unsupervised-learning`,
        parentId: 3,
        headingText: "Unsupervised Learning",
        subHeadingText:
            "Designing algorithms that enable an AI system to learn and improve its behavior based on feedback from its environment, often used in robotic control, game playing, and optimization",
        userRating: "4.8",
        userCount: "540",
        icon: UnsupervisedIcon,
        hoverIcon: UnsupervisedHoverIcon,
    },
    {
        name: "FHIR Report",
        path: `/${category}/health-care/fhir-report`,
        parentId: 3,
        headingText: "Healthcare",
        subHeadingText:
            "Applying AI/ML techniques to healthcare data for tasks like disease diagnosis, drug discovery, personalized medicine, electronic health records analysis, and predicting patient outcomes.",
        userRating: "4.7",
        userCount: "430",
        icon: FHIRIcon,
        hoverIcon: FHIRHoverIcon,
    },
    {
        name: "Money Laundering",
        path: `/${category}/finance/money-laundering`,
        parentId: 4,
        headingText: "Money Laundering",
        subHeadingText:
            "Developing AI/ML models for financial forecasting, fraud detection, algorithmic trading, credit scoring, risk assessment, and portfolio optimization",
        userRating: "4.9",
        userCount: "1k",
        icon: MoneyIcon,
        hoverIcon: MoneyHoverIcon,
    },
    {
        name: "Customer Churn",
        path: `/${category}/crm/customer-churn`,
        parentId: 5,
        headingText: "Customer Churn",
        subHeadingText:
            "Implementing AI/ML solutions for customer segmentation, churn prediction, personalized marketing, recommendation systems, and customer support automation",
        userRating: "4.8",
        userCount: "630",
        icon: CustomerIcon,
        hoverIcon: CustomerHoverIcon,
    },
    {
        name: "Pandas",
        path: `/${category}/exploratory-data-analysis/pandas`,
        parentId: 5,
        headingText: "Pandas",
        subHeadingText: "Exploratory Data Analysis on Big Data using Pandas & D3.",
        userRating: "4.8",
        userCount: "630",
        icon: PandasIcon,
        hoverIcon: PandasHoverIcon,
    },
    {
        name: "D3",
        path: `/${category}/exploratory-data-analysis/d3`,
        parentId: 5,
        headingText: "D3",
        subHeadingText: "Exploratory Data Analysis on Big Data using Pandas & D3.",
        userRating: "4.8",
        userCount: "630",
        icon: D3Icon,
        hoverIcon: D3HoverIcon,
    }
]
export const ExpertReviewsData = [
    {
        name: 'Divya Kathpalia',
        bio: 'Web/App Developer',
        text: 'My journey with Workbeam is simply great!! they give immense opportunities to potential candidates to earn money while enjoying freedom to work at their own terms and make a successful career while being a travel nomad or Home sitter. Workbeam is a really upstanding platform that bridges the gap between client needs and freelancers aspirations .I appreciate the personal connection of Workbeam team to convert the deals and the empathetic support.',
        image: 'https://workbeam.blob.core.windows.net/wiz-public-cdn/client-testimonials/Divya-Kathpalia-Web_App-Developer.jpg',
        cardColor: '#EEE8FF'
    },
    {
        name: 'Sreejith ravi',
        bio: 'Graphic designer',
        text: 'Workbeam is the best online platform for the Indian freelancers to get genuine clients easily. It\'s very easy to use and the customer support is awesome. They were available and solve the problems all the time, whenever I needed it. I really recommend Workbeam to other freelancers as well.',
        image: 'https://workbeam.blob.core.windows.net/wiz-public-cdn/client-testimonials/Sreejith-Ravi-Graphic-Designer-.jpg',
        cardColor: '#EEE8FF'
    },
    {
        name: 'Paras Bindal',
        bio: 'Graphic designer',
        text: 'My journey on Workbeam is going well and pretty much good and the team is very supportive and active. I would recommend other freelancers because the platform has very good supportive teams. It is Different from other platforms because they have a new concept with a lot of features, easily manageable, you can connect with the team easily, calling features are there etc.',
        image: 'https://workbeam.blob.core.windows.net/wiz-public-cdn/client-testimonials/Paras-Bindal-Graphic-designer.jpg',
        cardColor: '#FFF4E8'
    },
    {
        name: 'Manishi (Capleon creations )',
        bio: 'Graphic designer',
        text: 'Workbeam is the Top leading Platform in the Indian Market that helps experts get quality clients. From onboarding to support, Workbeam Team leaves no stone unturned in building seamless (vendor/seller) experience. I highly recommend professionals out there to be a part of this platform.',
        image: 'https://workbeam.blob.core.windows.net/wiz-public-cdn/client-testimonials/Capelon-creations-Manishi-Graphic-designer.jpg',
        cardColor: '#E8FFF4'
    },
    {
        name: 'Surya Pratap',
        bio: 'Web/App Developer',
        text: 'Workbeam is very awesome and simple to me. It helps me to get many more projects and in my growth. Workbeam is a much better version of freelancing portals. Feature rich and support rich. ',
        image: 'https://workbeam.blob.core.windows.net/wiz-public-cdn/client-testimonials/Surya-pratap-Web_app-developer-.jpg',
        cardColor: '#FFE8EB'
    },
    {
        name: 'Sahil Saxena',
        bio: 'Video editor',
        text: '“Guys are just fantastic at Workbeam. I have been wanting to really ‘up the ante’ with my web presence. Now we will have increased revenue, increased exposure and increased success for our business…that is what Workbeam has helped us to do. I would recommend Workbeam to anyone”',
        image: 'https://workbeam.blob.core.windows.net/wiz-public-cdn/client-testimonials/Sahil-saxena-Video-editor-.jpg',
        cardColor: '#FFF4E8'
    },
    {
        name: 'Rizwana Parveen',
        bio: 'Graphic designer',
        text: 'Workbeam has a really appreciative and that "hatke" feature of milestone creation which helped me alot with my project completion. This website has opportunities for every type of skilled worker with a wide range of services that could be applied for here. I would definitely love to recommend Workbeam to more freelancers.',
        image: 'https://workbeam.blob.core.windows.net/wiz-public-cdn/client-testimonials/Rizwana-parveen-Graphic-designer.jpg',
        cardColor: '#FFF4E8'
    },
]


export function ExpertReviewsCard() {
    return (
        <OwlCarousel
            className="owl-theme reviews-card"
            loop={true}
            margin={10}
            dots={true}
            items={1}
            autoplayHoverPause={true}
            autoplay={true}
            autoplayTimeout={5000}
        >
            {ExpertReviewsData.map(ReviewsData => {
                const { name, bio, text, image, cardColor } = ReviewsData
                return (
                    <div className="item">
                        <div className="text-center">
                            <img src={image} className="expert-dp" alt={name} />
                            <h3 className="text-name" style={{ lineHeight: '1.3em' }}>{name} <br /><span className="bio">{bio}</span><br />{ratingUi(5)}</h3>
                            <div className="reviews-card-container" style={{ backgroundColor: cardColor }}>
                                <p className="text-para-reviews">{text}</p>
                            </div>
                        </div>
                    </div>
                )
            })}

        </OwlCarousel>
    )

}

export function ServiceGridUi({ serviceData }) {
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    return (
        serviceData.map((serviceUiData, index) => {
            let { name, icon, path, parentId, type, hoverIcon } = serviceUiData
            const isHovered = hoveredIndex === index;
            if (type) {
                path += `?type=${type}`
            }
            return (
                <li className="service-grid-cards-wrapper text-center"
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                >
                    <a href={`${mainDomain}${path}`} title={name}>
                        <div className="service-grid-cards-icon">
                            <img src={isHovered ? hoverIcon : icon} alt={name} />
                        </div>
                        <h4 className="service-grid-cards-heading">{name}</h4>
                    </a>
                </li>
            )
        })
    )
}
export function InTheNews() {
    return (
        <div className="press-release-box">
            <div className="press-release-logo hidden-xs"><a
                href="https://economictimes.indiatimes.com/small-biz/startups/newsbuzz/wizcounsel-raises-funds-led-by-kapil-dev-and-others/articleshow/69985623.cms"
                target="_blank"><img src={EcoLogo} style={{ width: '180px' }} alt="Workbeam raises funds led by Kapil Dev and others" /></a></div>
            <div className="press-release-logo visible-xs"><a
                href="https://economictimes.indiatimes.com/small-biz/startups/newsbuzz/wizcounsel-raises-funds-led-by-kapil-dev-and-others/articleshow/69985623.cms"
                target="_blank"><img src={Ecosmall} style={{ width: '100px' }} alt="Workbeam raises funds led by Kapil Dev and others" /></a></div>
            <div className="press-release-logo"><a
                href="https://www.thehindubusinessline.com/specials/emerging-entrepreneurs/curefit-secures-830-cr-funding/article28322550.ece"
                target="_blank"><img src={TheHindu} style={{ width: '80px' }} alt="Kapil Dev, others invest in Workbeam" /></a></div>
            <div className="press-release-logo hidden-xs"><a
                href="https://www.livemint.com/companies/start-ups/wizcounsel-backed-by-kapil-dev-grows-5x-amid-covid-19-lockdown-11588076077372.html"
                target="_blank"><img src={LiveMint} style={{ width: '90px' }} alt="Workbeam backed by Kapil Dev grows 5x amid covid-19 lockdown" /></a></div>
            <div className="press-release-logo hidden-xs"><a
                href="https://inc42.com/buzz/top-indian-startup-funding-galore-110-june/" target="_blank"><img
                    src={Inc42} style={{ width: '90px' }} alt="Indian Startup Funding: Workbeam Delhi-based online market network" /></a></div>
            <div className="press-release-logo"><a
                href="https://www.timesnownews.com/business-economy/companies/article/wizcounsel-raises-rs-1-crore-fund-led-by-kapil-dev-others/445209"
                target="_blank"><img src={TimesNow} style={{ width: '80px' }} alt="Workbeam raises Rs 1 crore fund led by Kapil Dev, others" /></a></div>
            <div className="press-release-logo"><a
                href="https://yourstory.com/2019/06/delhi-startup-wizcounsel-angel-funding-kapil-dev"
                target="_blank"><img src={StoryLogo} style={{ width: '90px' }} alt="delhi-startup-gigzoe-angel-funding-kapil-dev" /></a></div>
        </div>
    )
}

export function ClientReviews() {
    return (

        <OwlCarousel
            className="owl-theme"
            loop={true}
            dots={false}
            margin={50}
            nav={false}
            navText={['<i class="fa fa-angle-left" style="font-size:24px"></i>', '<i class="fa fa-angle-right" style="font-size:24px"></i>']}
            items={3}
            responsiveClass={true}
            autoplay={true}
            autoplayTimeout={10000}
            autoplayHoverPause={true}
            responsive={responsiveItemsExpert}
        >
            <div className="item">

                <div>
                    <p className="text-name">Kunak K.
                        {fiveStar()}
                    </p>
                    <p className="public-review-para">“Hire Suraj for Bookkeeping Services. Going really good. Get
                        Monthly Report. Friendly app & support.”</p>
                </div>

            </div>
            <div className="item">
                <div>
                    <p className="text-name">Chetan B.
                        {fiveStar()}
                    </p>
                    <p className="public-review-para">“Easily Hired CA and filed my return smoothly. Great Customer
                        Experience.”</p>
                </div>
            </div>
            <div className="item">
                <div>
                    <p className="text-name">Pratik S.
                        {fiveStar()}
                    </p>
                    <p className="public-review-para">“Excellent support, Hired professional CS to register my Company.
                        Easy sharing of files, and milestone tracking.”</p>
                </div>
            </div>
            <div className="item">
                <div>
                    <p className="text-name">Sidhant M.
                        {fiveStar()}
                    </p>
                    <p className="public-review-para">“Best platform to Hire professional CAs for ITR filing. Super easy
                        and convenient.”</p>
                </div>
            </div>
        </OwlCarousel>
    )
}

export function GeneralOnboardHeader(props) {
    return (
        <div className="row">
            <div className="col-sm-1 col-xs-1">
                <Link to="/">
                    <img src={GigzoeLogo}
                        className="inside-logo"
                        alt='Logo'
                    />
                </Link>
            </div>
            <div className="col-sm-11 col-xs-10 general-header">

            </div>
        </div>
    )
}
export function WizCounselValue() {
    return (
        <OwlCarousel
            className="owl-theme"
            loop={true}
            margin={20}
            dots={false}
            items={1}
            autoplay={true}
            autoplayTimeout={6000}
            animateOut='fadeOut'
            id="owl-crousel-slider"
        >
            <div className="item">
                <img src={ExpertsImage} className="img-responsive" alt="800+ Experts in Workbeam Community" width="640" height="360" />
                <p className="scope-sub-heading">800+ Experts in Workbeam Community</p>
            </div>
        </OwlCarousel>
    )
}
export function OurExpertSample() {
    return (
        <OwlCarousel
            className="owl-theme"
            loop={true}
            margin={10}
            dots={true}
            items={1}
            autoplay={true}
            autoplayTimeout={6000}
            animateOut='fadeOut'
            id="owl-crousel-slider"
        >
            <div className="item">
                <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/slide-1.webp" className="img-responsive" alt="Workbeam" />
            </div>
            <div className="item">
                <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/slide-2.webp" className="img-responsive" alt="Workbeam" />
            </div>
            <div className="item">
                <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/slide-3.webp" className="img-responsive" alt="Workbeam" />
            </div>
            <div className="item">
                <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/slide-4.webp" className="img-responsive" alt="Workbeam" />
            </div>
            <div className="item">
                <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/slide-5.webp" className="img-responsive" alt="Workbeam" />
            </div>
            <div className="item">
                <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/slide-6.webp" className="img-responsive" alt="Workbeam" />
            </div>
            <div className="item">
                <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/slide-7.webp" className="img-responsive" alt="Workbeam" />
            </div>
            <div className="item">
                <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/slide-8.webp" className="img-responsive" alt="Workbeam" />
            </div>
            <div className="item">
                <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/slide-10.webp" className="img-responsive" alt="Workbeam" />
            </div>
            <div className="item">
                <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/slide-11.webp" className="img-responsive" alt="Workbeam" />
            </div>
            <div className="item">
                <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/slide-12.webp" className="img-responsive" alt="Workbeam" />
            </div>
        </OwlCarousel>
    )
}
export function BannerCrousel() {
    return (
        <OwlCarousel
            className="owl-theme"
            loop={true}
            margin={20}
            dots={false}
            items={1}
            autoplay={true}
            autoplayTimeout={5000}
            animateOut='fadeOut'
            id="owl-crousel-slider"
        >
            <div className="item">
                <div className="slider-crousel">
                    {/*<img src={require('../../static/images/Kapil-sir-home-banner.jpg')} alt="hire lawyer online"/>*/}
                    <div className="carousel-caption">
                        <p className="caption-name">Kapil Dev | <span>Investor in Workbeam.</span></p>
                        <p className="caption-para">Used Workbeam for <span>GST Filing and Legal Drafting.</span><img
                            src={require('../../static/images/love-letter.png')} alt="hire professional online" /></p>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className="slider-crousel">
                    {/*<img src={require('../../static/images/slide-04.jpg')} alt="CA online"/>*/}
                    <div className="carousel-caption">
                        <p className="caption-name">Deepankar C. | <span>Small Business Owner</span></p>
                        <p className="caption-para">Used Workbeam for <span>Accounting & GST Compliance</span><img
                            src={require('../../static/images/love-letter.png')} alt="hire professional online" /></p>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className="slider-crousel">
                    {/*<img src={require('../../static/images/slide-01.jpg')} alt="hire professional online"/>*/}
                    <div className="carousel-caption">
                        <p className="caption-name">Mahima K. | <span>Social Media Influencer</span></p>
                        <p className="caption-para">Used Workbeam
                            for <span>Contract Drafting &amp; Tax Return Filing</span><img
                                src={require('../../static/images/love-letter.png')} alt="hire professional online" />
                        </p>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className="slider-crousel">
                    {/*<img src={require('../../static/images/slide-02.jpg')} alt="hire professional online"/>*/}
                    <div className="carousel-caption">
                        <p className="caption-name">Aram M. | <span>Freelance Photographer</span></p>
                        <p className="caption-para">Used Workbeam
                            for <span>Contract Drafting &amp; Accounting</span><img
                                src={require('../../static/images/love-letter.png')} alt="hire professional online" />
                        </p>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className="slider-crousel">
                    {/*<img src={require('../../static/images/slide-03.jpg')} alt="hire professional online"/>*/}
                    <div className="carousel-caption">
                        <p className="caption-name">Roveena T. | <span>TEDx speaker </span></p>
                        <p className="caption-para">Used Workbeam for <span>Legal Advice for LGBTQ Rights</span><img
                            src={require('../../static/images/love-letter.png')} alt="hire professional online" /></p>
                    </div>
                </div>
            </div>
        </OwlCarousel>
    )
}

const IncreaseNum = () => {
    const count1 = 0
    const [count, settingCount] = useState(count1)
    const IncNum = () => {
        settingCount(count + 1)
        console.log('clicked value is', count)
    }
    const DecNum = () => {
        settingCount(count - 1)
        console.log('clicked value is', count)
    }
    return (
        <Fragment>
            <h1>
                {count}
            </h1>
            <a onClick={() => { IncNum() }}>Add num</a>
            <a onClick={() => { DecNum() }} className={`${count === 0 ? 'disable-tab' : ''}`}>Dec num</a>
        </Fragment>
    )
}
export function AllOwlCrousel(history) {
    return (<Fragment>
        <section className="hero-main" id="background" style={{ backgroundColor: 'rgb(255, 255, 255)' }}>
            <div className="container-fluid" style={{ width: '100%', paddingRight: '0px', paddingLeft: '0px' }}>
                {/* <div className="row" > */}
                <div className="hero-main-container" style={{ width: '100%' }}>
                    <div className="col-sm-7 col-md-7 col-lg-6">
                        <h1 className="hero-heading">
                            Are you looking for Certified
                            <br /> & Skilled Professionals ?
                            <span className="dot-pink">.</span>
                        </h1>
                        <h2 className="text-primary">
                            Hire Great Certified & Skilled Professionals, Fast. Workbeam
                            helps you hire elite Professionals at a moment's notice
                        </h2>

                        <div className="clearfix" />
                        <div className="row">
                            <div className="col-sm-12 product-search-box">
                                <ProductSearch />

                                {/*<IncreaseNum/>*/}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 col-lg-6 col-sm-5">
                        <img
                            className="img-responsive banner-img"
                            width="640"
                            height="360"
                            alt={`Book Digital Services for Your  Busines`}
                            src={mlsoft}
                        />
                        {/* <img src="/static/images/design1.png" className="img-responsive banner-img" width="640" height="360" alt={`Book Digital Services for Your  Busines`} /> */}
                    </div>
                    {/* </div> */}
                </div>
                {/* hero-client-container inthe-news legal-sm */}
                <div className="">
                    <div className="col-sm-12" style={{ maxWidth: '100%' }}>
                        <InTheNews />
                    </div>
                </div>
                {/* </div> */}

                <div className="hero-client-container legal-sm">
                    {/* <div className="col-sm-12">
                        <InTheNews/>
                    </div> */}
                </div>
            </div>
        </section>
        <section className="professional-social-ui">
            <div className="container">
                {/* <div className="row"> */}
                <div className="col-sm-12">
                    <h2 className="text-black-sub-heading text-center">From Ecommerce Development to Marketing, book services with 100% confidence<span className="dot-pink">.</span></h2>
                    <p className="hidden-xs">&#x00A0;</p>
                    <ul className="service-grid-cards">
                        <ServiceGridUi serviceData={serviceGridData} />
                    </ul>
                    <div className="clearfix"></div>
                    <p>&#x00A0;</p>
                    <h2 className="text-black-sub-heading">Popular Services</h2>
                    <p>&#x00A0;</p>
                    <PopularServices />
                    {/*<div className="clearfix"></div>*/}
                    {/*<p>&#x00A0;</p>*/}
                    {/*<p className="hidden-xs">&#x00A0;</p>*/}
                    {/*<h2 className="text-black-sub-heading">Trending Pro Gigs</h2>*/}
                    {/*<p className="see-all pull-right"><a className="btn btn-link" href={path.gigTrending}>See More</a></p>*/}
                    {/*<div className="clearfix"></div>*/}
                    {/*<TrandingServices/>*/}
                </div>
                {/* </div> */}
            </div>
        </section>
        <section className="professional-social-ui">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h3 className="text-black-sub-heading text-center">Works just like Ecommerce but for Business Services. </h3>
                    </div>
                    <p className="hidden-xs">&#x00A0;</p>
                    <div className="col-sm-4">
                        <p className="step-label"><span>
                            1.
                        </span>
                        </p>
                        <h4 className="scope-sub-heading">Search or Select Service you need</h4>
                        <p className="xs-para">Use our category dropdown or search to find service you need</p>
                    </div>
                    <div className="col-sm-4">
                        <p className="step-label"><span>
                            2.
                        </span>
                        </p>
                        <h4 className="scope-sub-heading">Compare & Message Service Experts</h4>
                        <p className="xs-para">First Shortlist Experts then message & call them to discuss service price & deliverables</p>
                    </div>
                    <div className="col-sm-4">
                        <p className="step-label"><span>
                            3.
                        </span>
                        </p>
                        <h4 className="scope-sub-heading">Book the service</h4>
                        <p className="xs-para">Pay first instalments, book the service, track delivery & make balance payments</p>
                    </div>
                </div>
            </div>
        </section>
        <section className="professional-social-ui">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="our-partner-trust" style={{ paddingTop: '10px' }}>
                            <div className="row">
                                <div className="col-sm-12">
                                    <h2 className="text-black-sub-heading text-center">Why Small Businesses trust Workbeam for all their services?</h2>
                                    <p>&#x00A0;</p>
                                    <p className="hidden-xs">&#x00A0;</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6 col-md-offset-1">
                                    <WizCounselValue />
                                </div>
                                <p className="visible-xs">&#x00A0;</p>
                                <div className="col-md-5 col-sm-6 col-md-offset-1">
                                    <div className="media">
                                        <div className="media-body">
                                            <h2 className="media-heading">Verified & Trained Experts</h2>
                                            <p className="para">Service Experts across 8+ Service Categories verified & trained by Workbeam</p>
                                        </div>
                                    </div>
                                    <div className="media">
                                        <div className="media-body">
                                            <h2 className="media-heading">Pay in Instalments</h2>
                                            <p className="para">No burden on Small Business to pay in full. Pay as per your service progress.</p>
                                        </div>
                                    </div>
                                    <div className="media">
                                        <div className="media-body">
                                            <h2 className="media-heading">100% Refund Protection</h2>
                                            <p className="para">Easy refunds on non delivery of Service. Your payments are 100% secure.</p>
                                        </div>
                                    </div>
                                    <div className="media">
                                        <div className="media-body">
                                            <h2 className="media-heading">Track Service Delivery</h2>
                                            <p className="para">On time service delivery. Track service delivery with full transparency </p>
                                        </div>
                                    </div>
                                    <div className="media">
                                        <div className="media-body">
                                            <h2 className="media-heading">Customer Support</h2>
                                            <p className="para">We pride ourselves on our customer support. You will love it.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <HomePageCustomerReviews userRating={`4.8`} userCount={`2000`} />
        <section className="professional-social-ui">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="our-partner-trust">
                            <div className="row">
                                <div className="col-sm-5">
                                    <h2 className="text-black-sub-heading">Looking for a Service for your Business?</h2>
                                    <h2 className="text-normal-heading" style={{ color: '#3155ec', marginTop: '15px' }}>Let our Sales Team help you!</h2>

                                </div>
                                <div className="col-sm-7">
                                    <div className="row">
                                        <TalkToSalesForm pathHistory={history} isFromExpertList={false} isHome={true} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <section className="professional-social-ui m-app-box">
            <div className="container">
                <div className="row display-flex">
                    <div className="col-sm-6  top-margin">
                        <p className="hidden-xs">&#x00A0;</p>
                        <h2 className="text-black-sub-heading">Use Workbeam App to communicate and track work delivery<span className="dot-pink">.</span>
                        </h2>
                        <p>&#x00A0;</p>
                        <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/Chat-Milestone-mobile-mockup.webp" className="img-responsive visible-xs" alt={`Use Workbeam App to Communicate and track Work Delivery`}/>
                        <p className="visible-xs">&#x00A0;</p>
                        <ul className="list-inline app-button">
                            <li><a
                                href="https://itunes.apple.com/us/app/wizcounsel/id1458978743?ls=1&mt=8"
                                target="_blank" title={`Download Workbeam App from Apple App Store`}><img src={require('../../static/images/Ios-Button.png').default} alt="Download Workbeam App from Apple App Store"/></a>
                            </li>
                            <li><a
                                href="https://play.google.com/store/apps/details?id=com.blizzcraft.wizuser"
                                target="_blank" title={`Download Workbeam App from Google App Store`}><img src={require('../../static/images/Android-Button.png').default} alt="Download Workbeam App from Google Play Store"/></a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-6 hidden-xs">
                        <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/images/webp/Chat-Milestone-mobile-mockup.webp" className="img-responsive" alt={`Use Workbeam App to Communicate and track Work Delivery`}/>
                    </div>
                </div>
            </div>
        </section> */}
    </Fragment>)
}

function professionalListUi(professionals) {
    return (
        professionals.map(professional => {
            const avatar = getAvatar(professional.avatar);
            const lastChar = professional.last_name ? professional.last_name.charAt(0) : '';
            const experience = professional.work_experience !== 1 ? `${professional.work_experience} years experience` : `${professional.work_experience} year experience`
            return (
                <div className="item" key={professional.id}>
                    <div>
                        <div className="expert-card">
                            <div className="row">
                                <div className="col-xs-2">
                                    {avatar ? (<img src={avatar} alt="user" className="expert-dp" />)
                                        :
                                        getFirstAndLastLetterOfName(professional.first_name, professional.last_name)
                                    }
                                </div>
                                <div className="col-xs-9 left-offset">
                                    <ul className="list-unstyled card-list">
                                        <li><b>{professional.first_name + ' ' + lastChar + '.'}</b><img
                                            src={require("../../static/images/correct.png")} className="verified-icon"
                                            style={{ width: '20px' }} alt="verified expert" /></li>
                                        <li>{professional.caption}</li>
                                        <li>{professional.state}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="star-experience-box">
                                <span>
                                    {ratingUiWith_I_Tag(professional.rating)}
                                </span>
                                <span>{experience}</span>
                            </div>
                            <div style={{ borderTop: '1px solid #e8eef3' }}>
                                <p className="xs-heading">Expertise Area</p>
                                <ul className="list-inline card-practice-area">
                                    {professional.practise_area.slice(0, 4).map(area => {
                                        return <li key={area.id}>{area.name},</li>
                                    })}
                                </ul>

                            </div>
                            <div className="text-center side-card-btn-box">
                                <Link className="btn btn-card" to={`/professional/${professional.slug}/profile`}
                                    target='_blank'>
                                    See Profile
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    )
}
export function SecreterialCrousel(props) {
    const { secretarialList } = props
    return (
        <OwlCarousel
            className="owl-theme"
            loop={true}
            dots={false}
            margin={5}
            nav={true}
            navText={['<i class="fa fa-angle-left" style="font-size:24px"></i>', '<i class="fa fa-angle-right" style="font-size:24px"></i>']}
            items={4}
            responsiveClass={true}
            autoplay={true}
            autoplayTimeout={10000}
            autoplayHoverPause={true}
            responsive={responsiveItemsExpert}

        >
            {professionalListUi(secretarialList)}
        </OwlCarousel>
    )
}

export const subHeadingvideoTextForProfessionalfirst = 'Communicate and share files using Workbeam Dashboard. Use Audio Calling & chatting and inbuilt cloud storage to execute your projects.'
export const subHeadingvideoTextForProfessionalsecond = 'Create milestones, track work delivery and manage payments efficiently. Become a pro at keeping your customers assured and happy.'