import React, {Component} from "react";
import * as path from "../../constants/path";
import {isPhoneValid, validateProfile} from "../common/validation/FormValidator";
import {apiv1} from "../../api";
import {USER} from "../../constants/auth";
import {setLocalStorage} from "./../user/UserSignUp";
import * as apiUrl from "../../api/apiUrls";
import $ from "jquery";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";
import {sessionLogin} from "../../actions/auth";
import {errorActionDispatchers} from "../../actions/errors";
import LoadingComponent from "../../utils/LoadingComponent";
import {indiaCallingCode} from './../../constants/common'
import PhoneInput, {getCountryCallingCode, parsePhoneNumber} from "react-phone-number-input";

class LoginWithPhone extends Component {

    constructor(props) {
        super(props)
        let {city} = props
        city = city ? city : []
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            password1: '',
            password: '',
            loading: false,
            errors: {},
            phone: '',
            geoCity: city,
            cityId: '',
            isSignUp: true,
            isValidReferralCode: false,
            firebaseVerificationId: '',
            otpInput: otpInputFields,
            countryCallingCode: indiaCallingCode,
        }
    }

    componentDidMount() {
        this.addEventListenerOnKeyDown()
        this.updateParent()
    }

    componentWillUnmount() {
        if (document.body.removeEventListener){
            document.body.removeEventListener('keyup', this.signInClickByEmail);
        }
    }

    addEventListenerOnKeyDown = () => {
        if (document.getElementById("otp_password")){
            document.getElementById("otp_password").addEventListener("keyup", (event) => this.signInClickByEmail(event))
        }
        if (document.getElementById("otp_email")){
            document.getElementById("otp_email").addEventListener("keyup", (event) => this.signInClickByEmail(event))
        }
        if (document.getElementById('login-with-otp-phone')){
            document.getElementById('login-with-otp-phone').addEventListener("keyup", (event) => this.signInClickByEmail(event))
        }
        if ($('#otp_password') && $('#otp_email')){
            $('#otp_email').focus()
        }
    }

    signInClickByEmail = (event) => {
        event.preventDefault()
        if (event.keyCode === 13) {
            const {email, password, firebaseVerificationId, phone, isOtpSent} = this.state
            // if (phone && firebaseVerificationId){
            //     $('#login-wit-otp').click()
            // }
            if (phone && isOtpSent){
                $('#login-wit-otp').click()
            }
            else if (phone){
                $('#get-otp').click()
            } else if (email && password){
                $('#login_button').click()
            }
        }
    }

    updateParent = () => {
        const {updateState, name} = this.props
        if (updateState) {
            updateState({name})
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {city} = nextProps
        const {geoCity} = this.state
        if (geoCity.length === 0 && city && city.length !== 0) {
            this.setState({geoCity: city})
        }
    }

    proceedToNextTask = ({key}) => {
        const {fetchUserAndProData, onLogin, history} = this.props
        setLocalStorage({auth_token: key, USER, first_name: '', last_name: ''})
        if (fetchUserAndProData) {
            fetchUserAndProData({authToken: key, isSignUp: false,})
        } else {
            $('.modal-body .close').click()
            onLogin(key)
            history.push(path.userLogin)
        }
    }

    VerifyOtpWitCountryCodeApiOnWizcounsel = () => {
        const {phone, otpInput, countryCallingCode} = this.state
        const {doDispatch, setLoading} = this.props
        let otp = otpInput.join('')
        const errors = {}
        // let path = `${apiUrl.userLoginWithPhone}+91${phone}/${firebaseVerificationId}/web`
        let {nationalNumber} = parsePhoneNumber(phone) || {}
        if (setLoading) {
            setLoading()
        }
        let request = {
            phone: nationalNumber,
            otp,
            country_code: countryCallingCode,
        }
        this.setState({loading: true})
        apiv1.noAuth.post(apiUrl.wizcounselVerifyOtpWithCountryCode, request)
            .then((resp) => {
                const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                if (data) {
                    const {key} = data
                    this.proceedToNextTask({key})
                } else {
                    this.setState({loading: false})
                }
            })
            .catch((err) => {
                const resErrors = apiv1.handleErrors(err, doDispatch);
                for (let key in resErrors) {
                    errors[key] = resErrors[key][0]
                }
                this.setState({
                    loading: false,
                    errors,
                    email: '',
                    password: '',
                    otpInput: otpInputFields
                }, () => {
                    if (setLoading) {
                        setLoading()
                    }
                })
            })
    }

    // VerifyOtpApiOnWizcounsel = () => {
    //     const {phone, otpInput} = this.state
    //     const {doDispatch, setLoading} = this.props
    //     let otp = otpInput.join('')
    //     const errors = {}
    //     // let path = `${apiUrl.userLoginWithPhone}+91${phone}/${firebaseVerificationId}/web`
    //     if (setLoading) {
    //         setLoading()
    //     }
    //     let request = {
    //         phone,
    //         otp
    //     }
    //     this.setState({loading: true})
    //     apiv1.noAuth.post(apiUrl.verifyOtpOnWizcounsel, request)
    //         .then((resp) => {
    //             const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
    //             if (data) {
    //                 const {key} = data
    //                 this.proceedToNextTask({key})
    //             } else {
    //                 this.setState({loading: false})
    //             }
    //         })
    //         .catch((err) => {
    //             const resErrors = apiv1.handleErrors(err, doDispatch);
    //             for (let key in resErrors) {
    //                 errors[key] = resErrors[key][0]
    //             }
    //             this.setState({
    //                 loading: false,
    //                 errors,
    //                 email: '',
    //                 password: '',
    //                 otpInput: otpInputFields
    //             }, () => {
    //                 if (setLoading) {
    //                     setLoading()
    //                 }
    //             })
    //         })
    // }

    // signInWithPhoneNo = () => {
    //     const {phone, firebaseVerificationId} = this.state
    //     const {doDispatch, setLoading} = this.props
    //     const errors = {}
    //     let path = `${apiUrl.userLoginWithPhone}+91${phone}/${firebaseVerificationId}/web`
    //     if (setLoading) {
    //         setLoading()
    //     }
    //     this.setState({loading: true})
    //     apiv1.noAuth.get(path)
    //         .then((resp) => {
    //             const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
    //             if (data) {
    //                 const {key} = data
    //                 this.proceedToNextTask({key})
    //             } else {
    //                 this.setState({loading: false})
    //             }
    //         })
    //         .catch((err) => {
    //             const resErrors = apiv1.handleErrors(err, doDispatch);
    //             for (let key in resErrors) {
    //                 errors[key] = resErrors[key][0]
    //             }
    //             this.setState({
    //                 loading: false,
    //                 errors,
    //                 email: '',
    //                 password: '',
    //                 otpInput: otpInputFields
    //             }, () => {
    //                 if (setLoading) {
    //                     setLoading()
    //                 }
    //             })
    //         })
    // }

    onSignInClick = () => {
        const {doDispatch, setLoading} = this.props
        const {email, password} = this.state;
        let request = {
            email,
            password
        };
        const errors = {};
        const isFormValidated = validateProfile(errors, request);
        if (isFormValidated === true) {
            if (setLoading) {
                setLoading()
            }
            this.setState({loading: true})
            apiv1.noAuth.post(apiUrl.restAuthLogin, request)
                .then((resp) => {
                    const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                    if (data) {
                        const {key} = data
                        this.proceedToNextTask({key})
                    } else {
                        this.setState({loading: false})
                    }
                })
                .catch((err) => {
                    const resErrors = apiv1.handleErrors(err, doDispatch);
                    for (let key in resErrors) {
                        errors[key] = resErrors[key][0]
                    }
                    this.setState({loading: false, errors, password: ''}, () => {
                        if (setLoading) {
                            setLoading()
                        }
                    })
                })
        } else {
            this.setState({loading: false, errors})
        }

    };

    // verifyFirebaseOtp = () => {
    //     let {otpInput} = this.state;
    //     const self = this
    //     let otp = otpInput.join('')
    //     if (otp && otp.length === 6) {
    //         this.setState({loading: true})
    //         window.confirmationResult.confirm(otp).then(resp => {
    //             self.signInWithPhoneNo()
    //         })
    //             .catch(err => {
    //                 this.setState({loading: false, otpInput: otpInputFields});
    //                 apiv1.handleErrors(err, this.props.doDispatch)
    //             })
    //     }
    // };

    // authenticatePhoneNo = (phNo) => {
    //     const errors = {};
    //     const self = this;
    //     this.setState({loading: true})
    //     auth().signInWithPhoneNumber(phNo, window.recaptchaVerifier)
    //         .then(function (confirmationResult) {
    //             window.confirmationResult = confirmationResult;
    //             const {verificationId} = confirmationResult
    //             $('.otp-box').removeClass('hidden');
    //             self.setState({loading: false, isOtpSent: true, firebaseVerificationId: verificationId}, () => {
    //                 const {generalOnboarding} = self.props
    //                 if (document.getElementById(`${generalOnboarding}otp${5}`)){
    //                     document.getElementById(`${generalOnboarding}otp${5}`).addEventListener("keyup", (event) => self.signInClickByEmail(event))
    //                 }
    //             })
    //         }).catch(function (error) {
    //         if (error.message) {
    //             errors['phone'] = error.message;
    //             self.setState({loading: false, isOtpSent: true, errors, otpInput: otpInputFields})
    //         }
    //     });
    // }


    sendOtpOnWizcounsel = ({phone}) => {
        const {doDispatch, setLoading} = this.props
        const {countryCallingCode} = this.state
        let request = {
            phone,
            country_code: countryCallingCode
        };
        let errors = {}
        this.setState({loading: true})
        apiv1.noAuth.post(apiUrl.wizcounselSendOtpWithCountryCode, request)
            .then((resp) => {
                const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                if (data) {
                    this.setState({loading: false, isOtpSent: true}, () => {
                        const {generalOnboarding} = this.props
                        if (document.getElementById(`${generalOnboarding}otp${5}`)){
                            $(`#${generalOnboarding}otp${0}`).focus()
                            document.getElementById(`${generalOnboarding}otp${5}`).addEventListener("keyup", (event) => this.signInClickByEmail(event))
                        }
                    })
                } else {
                    this.setState({loading: false})
                }
            })
            .catch((err) => {
                const resErrors = apiv1.handleErrors(err, doDispatch);
                for (let key in resErrors) {
                    errors[key] = resErrors[key][0]
                }
                this.setState({loading: false, errors}, () => {
                    if (setLoading) {
                        setLoading()
                    }
                })
            })
        }


    // firebaseOtp = (phNo) => {
    //     if (apps[0]) {
    //         initializeApp(firebaseConfig)
    //     }
    //     if (window.recaptchaVerifier === undefined) {
    //         window.recaptchaVerifier = new auth.RecaptchaVerifier('sign-in-button-with-firebase-otp', {
    //             'size': 'invisible',
    //             'callback': function (response) {
    //             }
    //         });
    //     }
    //     this.authenticatePhoneNo(phNo)
    // };

    verifyPhone = () => {
        let {phone, countryCallingCode} = this.state;
        const errors = {};
        const {nationalNumber} = parsePhoneNumber(phone) || {}
        const message = 'Phone no is not valid';
        if (isPhoneValid(errors, 'phone', nationalNumber, message, countryCallingCode)) {
            // phone = '+91' + phone
            this.sendOtpOnWizcounsel({phone: nationalNumber})
            // this.firebaseOtp(phone)
        } else {
            this.setState({errors, loading: false})
        }

    };


    onChange = (e) => {
        const {name, value} = e.target
        const newErrors = {...this.state.errors};
        newErrors[name] = '';
        this.setState({[name]: value, errors: newErrors})
    };

    termsAndConditions = () => {
        return (
            <div className="col-sm-12">
                <h4 className="text-dark">By clicking Submit button you agree to our
                    <br/>
                    <b>
                        <a href={path.termsAndConditions} target="_blank">Terms of Service</a> &amp;
                        <a href={path.privacyPolicy} style={{marginLeft: '5px'}} target="_blank">Privacy policy</a>
                    </b>
                </h4>
            </div>
        )
    }

    onOtpChange = (e, index) => {
        const eventCode = e.which || e.keyCode
        const {value} = e.target
        if (this.getOtpInputBox(index).value.length === 1) {
            if (index !== 5) {
                this.getOtpInputBox(index + 1).focus()
            }
        }
        if (eventCode === 8 && index !== 0) {
            this.getOtpInputBox(index - 1).focus();
        }
        const {otpInput} = this.state
        otpInput[index] = value ? Number(value) : ''
        this.setState({otpInput})
    }

    getOtpInputBox = (index) => {
        const {generalOnboarding} = this.props
        return document.getElementById(`${generalOnboarding}otp${index}`);
    }

    onOtpFocus = (e, index) => {
        for (let item = 1; item < index; item++) {
            const currentElement = this.getOtpInputBox(item);
            if (!currentElement.value) {
                currentElement.focus();
                break;
            }
        }
    }


    otpLogin = () => {
        const {errors, email, password, phone, loading, firebaseVerificationId, isOtpSent} = this.state
        const {generalOnboarding, emailButtonLoginName} = this.props
        return (
            <div className="modal-dialog">
                <div className="modal-content">
                    {loading && (
                        <div className="lazy-loader" style={{position: 'fixed', zIndex: '10'}}>
                            <LoadingComponent pastDelay/></div>)
                    }
                    <div className="modal-body">
                        <button type="button"
                                className="close"
                                data-dismiss="modal"
                                onClick={() => {
                                    this.setState({errors: {}, loading: false})
                                }}
                        >&times;</button>
                        <div className="col-sm-12">
                            <h1 className="ui-sign-heading text-center">Happy to see you logging in…</h1>
                        </div>
                        <p className="hidden-xs">&#x00A0;</p>
                        <div className="row grid-divider col-divider">
                            <div className="col-sm-6">
                                <div className="otp-col-padding">
                                    <h1 className="ui-sign-sub-heading text-center">Login with OTP </h1>
                                    <div className="signup-form" style={{marginTop: '10px'}}>
                                        <div className="form-group input-box">

                                            <PhoneInput
                                                className="form-control input-lg"
                                                name="phone"
                                                value={phone}
                                                international
                                                countryCallingCodeEditable={false}
                                                onChange={(phone) => {
                                                    let e = {
                                                        target: {
                                                            name: 'phone',
                                                            value: phone
                                                        }
                                                    }
                                                    const newErrors = {...this.state.errors}
                                                    const {name, value} = e.target
                                                    newErrors[name] = ''
                                                    this.setState({[name]: value, errors: newErrors})
                                                }}
                                                onCountryChange={(country) => {
                                                    country = country || ''
                                                    if (country) {
                                                        let countryCallingCode = `+${getCountryCallingCode(country)}`
                                                        this.setState({countryCallingCode})
                                                    }
                                                }}
                                                placeholder="Mobile E.g. 99XXXXXX99 "
                                                defaultCountry="CA"
                                                style={{borderBottom: errors.phone ? '1px solid #ff337d' : ''}}
                                            />

                                            {/*<input type="text"*/}
                                                   {/*className="form-control input-lg"*/}
                                                   {/*name="phone"*/}
                                                   {/*id='login-with-otp-phone'*/}
                                                   {/*value={phone}*/}
                                                   {/*onChange={this.onChange}*/}
                                                   {/*placeholder="Mobile E.g. 99XXXXXX99 "*/}
                                                   {/*style={{borderBottom: errors.phone ? '1px solid #ff337d' : ''}}*/}
                                            {/*/>*/}
                                            {/*<span className="unit">+91</span>*/}
                                            <span className={errors.phone ? 'error' : 'no-error'}>{errors.phone}</span>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>


                                    {isOtpSent &&
                                        <div className="text-center">
                                            {otpInputArrayFields.map((otpInput, index) => {
                                                return <input className="otp_input"
                                                              id={`${generalOnboarding}otp${index}`}
                                                              onKeyUp={(e) => this.onOtpChange(e, index)}
                                                              onFocus={(e) => this.onOtpFocus(e, index)} maxLength="1"/>
                                            })}
                                        </div>
                                      }
                                        <div className="text-center">
                                            {isOtpSent ?
                                                <button className="btn btn-link"
                                                        disabled={loading}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            this.verifyPhone()
                                                        }}
                                                >Resend
                                                </button>
                                                :
                                                <button className="btn log-in-button btn-block"
                                                        id='get-otp'
                                                        disabled={loading}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            this.verifyPhone()
                                                        }}
                                                >
                                                    Get OTP
                                                </button>
                                            }
                                        </div>
                                        {isOtpSent &&
                                        <div className="text-center">
                                            <button className="btn log-in-button btn-block"
                                                    id='login-wit-otp'
                                                    disabled={loading}
                                                    onClick={() => {
                                                        const {otpInput} = this.state
                                                        let otp = otpInput.join('')
                                                        if (otp && otp.length === 6){
                                                            this.VerifyOtpWitCountryCodeApiOnWizcounsel()
                                                        }

                                                    }}
                                            >{emailButtonLoginName ? emailButtonLoginName : 'Login'}
                                            </button>
                                        </div>
                                    }

                                    {/*<div className="text-center">*/}
                                        {/*{!firebaseVerificationId &&*/}
                                            {/*<button className="btn log-in-button btn-block"*/}
                                                    {/*id='get-otp'*/}
                                                    {/*disabled={loading}*/}
                                                    {/*onClick={(e) => {*/}
                                                        {/*e.preventDefault()*/}
                                                        {/*this.verifyPhone()*/}
                                                    {/*}}*/}
                                            {/*>*/}
                                                {/*Get OTP*/}
                                            {/*</button>*/}
                                        {/*}*/}
                                        {/*{firebaseVerificationId &&*/}
                                        {/*<button className="btn btn-link"*/}
                                                {/*disabled={loading}*/}
                                                {/*onClick={(e) => {*/}
                                                    {/*e.preventDefault()*/}
                                                    {/*this.verifyPhone()*/}
                                                {/*}}*/}
                                        {/*>Resend*/}
                                        {/*</button>*/}
                                        {/*}*/}
                                    {/*</div>*/}

                                    {/*{firebaseVerificationId &&*/}
                                    {/*<Fragment>*/}
                                        {/*<div className="text-center">*/}
                                            {/*{otpInputArrayFields.map((otpInput, index) => {*/}
                                                {/*return <input className="otp_input"*/}
                                                              {/*id={`${generalOnboarding}otp${index}`}*/}
                                                              {/*onKeyUp={(e) => this.onOtpChange(e, index)}*/}
                                                              {/*onFocus={(e) => this.onOtpFocus(e, index)} maxLength="1"/>*/}
                                            {/*})}*/}
                                        {/*</div>*/}
                                        {/*<div className="text-center">*/}
                                            {/*<button className="btn log-in-button btn-block"*/}
                                                    {/*id='login-wit-otp'*/}
                                                    {/*disabled={loading}*/}
                                                    {/*onClick={() => {*/}
                                                        {/*const {firebaseVerificationId} = this.state*/}
                                                        {/*if (firebaseVerificationId) {*/}
                                                            {/*this.verifyFirebaseOtp()*/}
                                                        {/*}*/}
                                                    {/*}}*/}
                                            {/*>{emailButtonLoginName ? emailButtonLoginName : 'Login'}*/}
                                            {/*</button>*/}
                                        {/*</div>*/}
                                    {/*</Fragment>*/}
                                    {/*}*/}

                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="otp-col-padding left-offset-1">
                                    <h1 className="ui-sign-sub-heading text-center">Login with Email</h1>
                                    <div
                                        className={(errors.non_field_errors) ? 'msg msg-danger msg-danger-text' : 'hidden'}>
                                        <span className="glyphicon glyphicon-remove pull-right"
                                              style={{cursor: 'pointer'}}
                                              onClick={() => this.setState({errors: {}})}
                                        >

                                        </span>
                                        {errors.non_field_errors}
                                    </div>
                                    <div className="clearfix"></div>
                                    <form className="signup-form" style={{marginTop: '10px'}}>
                                        <div className="form-group">
                                            <input type="text"
                                                   className="form-control input-lg"
                                                   style={{borderBottom: errors.email ? '1px solid #ff337d' : ''}}
                                                   name="email"
                                                   id='otp_email'
                                                   value={email}
                                                   onChange={this.onChange}
                                                   placeholder="Email"
                                                   autoFocus
                                            />
                                            <span
                                                className={(errors.email) ? 'error' : 'no-error'}>{errors.email}</span>
                                        </div>
                                        <div className="clearfix"></div>
                                        <div className="form-group">
                                            <input type="password"
                                                   className="form-control input-lg"
                                                   value={password}
                                                   onChange={this.onChange}
                                                   name="password"
                                                   id='otp_password'
                                                   style={{borderBottom: errors.password ? '1px solid #ff337d' : ''}}
                                                   placeholder="Password"
                                            />
                                            <span
                                                className={errors.password ? 'error' : 'no-error'}>{errors.password}</span>
                                            <a className="pull-right text-dark"
                                               style={{color: '#2c3af4'}}
                                               href={path.resetPasswordLink}
                                               target='_blank'
                                            >Forgot Password ?</a>
                                        </div>
                                    </form>
                                    <div className="clearfix"></div>
                                    <div className="text-center">
                                        <button className="btn log-in-button btn-block"
                                                id='login_button'
                                                disabled={loading}
                                                onClick={this.onSignInClick}
                                        >
                                         {emailButtonLoginName ? emailButtonLoginName : 'Login'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer"></div>
                </div>
            </div>
        )
    }
    render() {
        return (
            <div>
                {this.otpLogin()}
                <div id="sign-in-button-with-firebase-otp" name="sign-in-button-with-firebase-otp"></div>
            </div>
        );
    }
}

const mapStateToProps = state => (() => {
    return {}
});


const mapDispatchToProps = dispatch => {
    return {
        onLogin: (authToken) => {
            dispatch(sessionLogin({
                auth_token: authToken,
            }))
        },
        onFetchDetails: (action) => {
            dispatch(action);
        },
        ...(errorActionDispatchers(dispatch))
    }
};
export let otpInputFields = [[], [], [], [], [], []]
export let otpInputArrayFields = [1, 2, 3, 4, 5, 6]

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginWithPhone))