import React, {Component} from "react";
import {apiv1} from "../../api";
import LoadingComponent from "../../utils/LoadingComponent";
import {errorActionDispatchers} from "../../actions/errors";
import connect from "react-redux/es/connect/connect";
import $ from 'jquery'
import TimeAgo from 'react-timeago'
import BottomScrollListener from 'react-bottom-scroll-listener'
import SeeFullJobDetails from "./SeeFullJobDetails";
import {getAvatar, getFirstAndLastLetterOfName} from "../common/userAndProfessionalProfileFunctions";
import {Link} from "react-router-dom";
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import InfiniteScroll from 'react-infinite-scroll-component'
import sendProposalPopupVerification from './../../static/images/sendProposalPopupVerification.png'
import Interweave from 'interweave'
import {htmlRegex} from './../common/validation/FormValidator'
import nl2br from "react-newline-to-break";
import * as path from './../../constants/path'
import {status} from "../../constants/job";
import {CreateProfessionalAccountPopUp} from "../user/GeneralOnboarding";
import {CustomToolTrip, GetFirstNameAndLastName, ShortDescription} from "../../functions/common";
import localStorage from './../../utils/localStorage'
import renderHTML from 'react-render-html';
import ProfessionalProposalSentList from "./ProfessionalProposalSentList";

export function Description({description, desLength}) {
    return (
        htmlRegex.test(description) ?
            <Interweave
                tagName="div"
                content={description}
            />
            :
            nl2br(ShortDescription({text: description, maxCharLength: desLength ? desLength : 2000}))
    )
}

const feedTabs = [
    {
        tabName: 'Recommended jobs',
        id: 'related_jobs',
    },
    // {
    //     tabName: 'Other Jobs',
    //     id: 'others'
    // },
    // {
    //     tabName: 'Job Invites',
    //     id: 'invites'
    // }
]

export function feedTabsUi({hash, professionalId, history, getRelatedJobFeed, getOtherJobFeed, totalRelatedJobFeed, totalUnrelatedJobFeed, totalJobInvites, changeState}) {
    let tabDetails = feedTabs.find(tabDetails => {
        return `#${tabDetails.id}` === hash
    })
    return (
        <div className="col-sm-10 col-sm-offset-1 no-padding">
            <div className="col-sm-12 d-title-container">
                <h4 className="d-title-heading" style={{textIndent: 'none', display: 'flex', alignItems:'center',justifyContent: 'space-between'}}>
                    {tabDetails && tabDetails.tabName}
                    <Link className="btn btn-link" to={path.proposalSent}>Sent Proposals List</Link>
                </h4>
                {/*<ul className="nav nav-tabs nav-justified">*/}
                {/*{feedTabs.map((tabDetails, index) => {*/}
                {/*let {tabName, id} = tabDetails*/}
                {/*if (index === 2) {*/}
                {/*tabName += `(${totalJobInvites})`*/}
                {/*}*/}
                {/*return <li className={`${hash === `#${id}` ? 'active' : ''}`}*/}
                {/*key={tabName}*/}
                {/*>*/}
                {/*<a data-toggle="tab" data-target={`#${id}`}*/}
                {/*onClick={() => {*/}
                {/*hash = `#${id}`*/}
                {/*if (professionalId) {*/}
                {/*let url = (index === 0 || index === 1) ? `/job-feed${hash}` : `/professional/${professionalId}/job-invites${hash}`*/}
                {/*history.replace(url)*/}
                {/*if (changeState) {*/}
                {/*changeState({hash})*/}
                {/*}*/}
                {/*}*/}
                {/*if (index === 0 && totalRelatedJobFeed === 0 && getRelatedJobFeed) {*/}
                {/*getRelatedJobFeed(1)*/}
                {/*}*/}
                {/*if (index === 1 && totalUnrelatedJobFeed === 0 && getOtherJobFeed) {*/}
                {/*getOtherJobFeed(1)*/}
                {/*}*/}

                {/*}*/}
                {/*}*/}
                {/*> {tabName}</a>*/}
                {/*</li>*/}
                {/*})}*/}
                {/*</ul>*/}
            </div>
        </div>
    )
}


class jobFeed extends Component {
    constructor(props) {
        super(props)
        let {data, totalJobInvites, history} = props
        data = data || {}
        const {location} = history || {};
        let {hash, pathname} = location;
        let url = `${pathname}${hash}`
        url = hash ? url : `${url}#${`related_jobs`}`
        history.replace(url)
        this.state = {
            feed: false,
            seeJob: false,
            loading: false,
            relatedJobFeed: [],
            unrelatedJobFeed: [],
            totalRelatedJobFeed: 0,
            totalUnrelatedJobFeed: 0,
            relatedJobCurrPageNo: 1,
            unrelatedJobCurrPageNo: 0,
            limit: 10,
            message: '',
            fixed_amount: '',
            schedule_unit_proposed: '',
            sender: '',
            data,
            proposalSent: false,
            totalJobInvites,
            jobMilestone: [],
            jobQuestions: [],
            gstAmount: 0,
            wcFees: 0,
            wcGst: 0,
            totalAmount: 0,
            errors: {},
            hash
        }
    }

    componentDidMount() {
        const {history} = this.props
        const {location} = history || {}
        let {hash} = location;
        hash = hash || `related_jobs`
        if (hash === `#${`related_jobs`}`) {
            this.getRelatedJobFeed(1)
        }
        // if (hash === `#${feedTabs[1].id}`) {
        //     this.getUnrelatedJobFeed(1)
        // }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data && nextProps.data !== this.state.data) {
            this.setState({data: nextProps.data})
        }
        if (nextProps.totalJobInvites >= 0) {
            this.setState({totalJobInvites: nextProps.totalJobInvites})
        }
    }

    changeState = ({hash}) => {
        this.setState({hash})
    }

    handleScroll = (e) => {
        const {relatedJobCurrPageNo, unrelatedJobCurrPageNo, totalRelatedJobFeed, totalUnrelatedJobFeed, limit} = this.state;
        if (totalRelatedJobFeed > relatedJobCurrPageNo * limit) {
            this.getRelatedJobFeed(relatedJobCurrPageNo + 1)
        } else if (totalUnrelatedJobFeed >= unrelatedJobCurrPageNo * limit) {
            this.getUnrelatedJobFeed(unrelatedJobCurrPageNo + 1)
        }
    };

    getRelatedJobFeed = (page) => {
        const {limit} = this.state;
        const url = `/job/related-feed/?page=${page}&limit=${limit}`;
        this.setState({loading: true});
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    const {results, count, currentPage} = data
                    const relatedJobFeed = [...this.state.relatedJobFeed];
                    relatedJobFeed.push(...results);
                    this.setState({
                        relatedJobFeed,
                        loading: false,
                        totalRelatedJobFeed: count,
                        relatedJobCurrPageNo: currentPage
                    });
                    if (data.count === 0) {
                        // this.getUnrelatedJobFeed(1)
                    }
                } else {
                    this.setState({loading: false})
                }

            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    };

    getUnrelatedJobFeed = (page) => {
        const {limit} = this.state;
        const url = `/job/unrelated-feed/?page=${page}&limit=${limit}`;
        this.setState({loading: true});
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    const unrelatedJobFeed = [...this.state.unrelatedJobFeed];
                    unrelatedJobFeed.push(...data.results);
                    this.setState({
                        unrelatedJobFeed,
                        loading: false,
                        totalUnrelatedJobFeed: data.count,
                        unrelatedJobCurrPageNo: data.currentPage
                    })
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    };

    goFeedPage = (jobId) => {
        const relatedJobFeed = [...this.state.relatedJobFeed];
        const unrelatedJobFeed = [...this.state.unrelatedJobFeed];
        let job = relatedJobFeed.find(obj => obj.id === jobId);
        if (job !== undefined) {
            relatedJobFeed.splice(relatedJobFeed.indexOf(job), 1)
        } else {
            job = unrelatedJobFeed.find(obj => obj.id === jobId);
            unrelatedJobFeed.splice(unrelatedJobFeed.indexOf(job), 1)
        }
        this.setState({loading: false, proposalSent: false, relatedJobFeed, unrelatedJobFeed})
    };

    getJobMilestoneAndQuestionDetails = (job_id) => {
        this.setState({getDetails: false, loading: true});
        const url = `/job/job-details-hiring-summary/${job_id}/`;
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    this.setState({loading: false, jobMilestone: data.job_milestone, jobQuestions: data.job_question})
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    };
    rejectProposal = (e, jobId) => {
        const request = {};
        request.rejecter = this.state.data.professional.id;
        request.job = jobId;
        this.setState({loading: true});
        apiv1.auth.post('/job/job-not-intrested/', request)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp);
                if (data) {
                    const relatedJobFeed = [...this.state.relatedJobFeed].filter(obj => obj.id !== jobId)
                    const unrelatedJobFeed = [...this.state.unrelatedJobFeed];
                    let job = relatedJobFeed.find(obj => obj.id === jobId)
                    // if (job !== undefined) {
                    //     relatedJobFeed.splice(relatedJobFeed.indexOf(job), 1)
                    // } else {
                    //     job = unrelatedJobFeed.find(obj => obj.id === jobId);
                    //     unrelatedJobFeed.splice(unrelatedJobFeed.indexOf(job), 1)
                    // }
                    this.setState({loading: false, relatedJobFeed, unrelatedJobFeed})
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })

    };

    verifyProfilePopup = () => {
        return (
            <div className="modal fade" id="verify_profile" role="dialog">
                <div className="modal-dialog dialog-top-margin">
                    <div className="modal-content" style={{padding: '10px 20px'}}>
                        <div className="modal-header" style={{border: 'none', padding: '0px'}}>
                            <button type="button" className="close" data-dismiss="modal"
                            >&times;</button>
                        </div>
                        <div className="modal-body">
                            <div className="text-center" style={{marginBottom: '30px', marginTop: '20px'}}>
                                <img src={sendProposalPopupVerification} style={{width: '15%'}}/>
                            </div>
                            <p className="para">We are really sorry but you cannot send proposals since your profile is
                                not yet verified. Please complete your profile and get it verified. Reach out to us
                                at <a href="mailto:support@workbeam.com"
                                      style={{color: '#ff337d'}}>support@workbeam.com</a>.</p>

                        </div>
                        <br/>
                    </div>
                </div>
            </div>
        )
    };

    singleJobFeed = (job) => {
        const {user_details, id, title, answers, description, city_data, avg_price, document_file_name, created, budget, document, lower_tags, upper_tags} = job
        const {first_name, last_name, client} = user_details
        const {avatar} = client
        let imageUrl = getAvatar(avatar);
        const {history} = this.props
        const {location} = history
        return (
            <div className="job-posting-panel" key={id}>
                <div className="job-posting-panel-body">
                    <h4 className="order-posted-heading">{title}
                    </h4>
                    <p className="order-posted-date">
                        <TimeAgo date={created}/>
                    </p>

                    <ul className="list-inline about-expert-stats">
                        {upper_tags && upper_tags.map((tags, index)=>{
                            return(
                                <li key={index}>
                                    <div className="e-s-bubble">{tags}</div>
                                </li>
                            )
                        })}
                    </ul>
                    <p className="job-description">{renderHTML(description)}</p>
                    <ul className="list-inline about-expert-stats">
                        {lower_tags && lower_tags.map((skillData, index)=>{
                            return(
                                <li key={index}><div className="e-s-bubble" style={{background:'#FBF0EE'}}>{skillData}</div></li>
                            )
                        })}
                    </ul>
                    <ul className="list-inline job-posting-ul">
                        <li>
                            <a className="btn btn-link"
                               onClick={()=>{
                                   let {search} = location
                                   search += `${search.indexOf('?') > -1 ? '' : '?'}`
                                   search += `is_default=${true}`
                                   this.props.history.push({
                                       pathname: path.seeJobDetail,
                                       search,
                                       state: { jobDetail: job}
                                   })
                               }}>
                                Read more & apply
                            </a>

                        </li>
                        <li className="pull-right">
                            <a className="btn btn-link see-detail pull-right"
                               onClick={(e) =>

                                   confirmAlert({
                                       title: 'Not interested',
                                       message: 'Are you sure ?',
                                       buttons: [
                                           {
                                               label: 'Yes',
                                               onClick: () => {
                                                   this.rejectProposal(e, id)
                                               }
                                           },
                                           {
                                               label: 'No',
                                               onClick: () => {
                                               }
                                           }
                                       ]
                                   })

                               }
                            >Not for you? Archive post
                            </a>
                        </li>
                    </ul>


                    {/*<a data-toggle="modal"*/}
                    {/*className="btn btn-link see-detail"*/}
                    {/*data-target={'#' + id + 'see_details'}*/}
                    {/*onClick={(e) => this.getJobMilestoneAndQuestionDetails(id)}>*/}
                    {/*Read more & apply*/}
                    {/*</a>*/}
                </div>
                {/*<div className="job-posting-panel-footer">*/}
                {/*<ul className="list-inline job-posting-ul space-bwt">*/}
                {/*<li>*/}
                {/*<a data-toggle="modal"*/}
                {/*className="btn btn-see-job-posting"*/}
                {/*data-target={'#' + id + 'see_details'}*/}
                {/*onClick={(e) => this.getJobMilestoneAndQuestionDetails(id)}>*/}
                {/*See job details*/}
                {/*</a>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*<a*/}
                {/*onClick={() => {*/}
                {/*if (professional.verified) {*/}
                {/*this.props.history.push(`${path.sendProposal}?jobId=${id}&jobTitle=${title}&STATUS=${status.Posted}&firstName=${first_name}&lastName=${last_name}&show_ctc=${show_ctc}`)*/}
                {/*} else {*/}
                {/*$('#verify_account').click()*/}
                {/*}*/}
                {/*}}*/}
                {/*className="btn btn-edit-job-posting"*/}
                {/*>*/}
                {/*Send Proposal</a>*/}
                {/*</li>*/}
                {/*<li className="pull-right">*/}
                {/*<a className="btn btn-link"*/}
                {/*onClick={(e) =>*/}

                {/*confirmAlert({*/}
                {/*title: 'Not interested',*/}
                {/*message: 'Are you sure ?',*/}
                {/*buttons: [*/}
                {/*{*/}
                {/*label: 'Yes',*/}
                {/*onClick: () => {*/}
                {/*this.rejectProposal(e, id)*/}
                {/*}*/}
                {/*},*/}
                {/*{*/}
                {/*label: 'No',*/}
                {/*onClick: () => {*/}
                {/*}*/}
                {/*}*/}
                {/*]*/}
                {/*})*/}

                {/*}*/}
                {/*>Not for you? Archive post*/}
                {/*</a>*/}
                {/*</li>*/}
                {/*</ul>*/}
                {/*</div>*/}
                {<SeeFullJobDetails jobDetails={job}
                                    jobId={id}
                                    dataTargetId={id + 'see_details'}
                                    jobQuestions={this.state.jobQuestions}
                                    jobMilestone={this.state.jobMilestone}
                />}
            </div>
        )
    };

    render() {
        const {loading, relatedJobFeed, unrelatedJobFeed, totalJobInvites, totalRelatedJobFeed, totalUnrelatedJobFeed,} = this.state;
        const {history} = this.props
        const {location} = history
        const {hash} = location
        return (
            <div className="row">
                {loading && <div className="lazy-loader" style={{position: 'fixed', zIndex: '100'}}
                >
                    <LoadingComponent pastDelay/></div>
                }
                <div className="col-sm-12">
                    <h4 className="heading-onboarding">Apply to open job positions</h4>
                    <ul className="list-inline jop-feed-tab">
                        <li className="active">
                            <a data-toggle="tab" href="#related_jobs"
                               onClick={()=>{
                                   history.replace(`${path.jobFeed}#related_jobs`)
                               }}
                            >New Jobs</a>
                        </li>
                        <li>
                            <a data-toggle="tab" href="#applied_job"
                               onClick={()=>{
                                   history.replace(`${path.jobFeed}#applied_job`)
                               }}
                            >Applied Jobs</a>
                        </li>
                    </ul>
                </div>

                <div className="col-sm-12">
                    <div className="tab-content">
                        <div id="related_jobs" className={`tab-pane fade in active`}>
                            {totalRelatedJobFeed > 0 &&
                            <p className="para"><b>Showing {totalRelatedJobFeed} open roles for </b></p>
                            }
                            <InfiniteScroll
                                dataLength={relatedJobFeed.length}
                                pageStart={0}
                                // loadMore={this.getRelatedJobFeed.bind(this)}
                                hasMore={totalRelatedJobFeed > relatedJobFeed.length}
                                loader={<div className="loader" key={0}>Loading ...</div>}
                                useWindow={false}
                                // getScrollParent={() => this.scrollParentRef}
                                // loadMore={() => this.handleScroll}
                                // useWindow={true}

                                // loading={loaderComp}
                            >
                                <div className="col-sm-12 no-padding">
                                    {(relatedJobFeed.length === 0)
                                    &&
                                    <div>
                                        <p>&#x00A0;</p>
                                        <h4 className="text-empty-space">We are sorry but we could not find
                                            any jobs for you.</h4>
                                    </div>
                                    }
                                </div>
                                {relatedJobFeed.map(job => {
                                    return this.singleJobFeed(job)
                                })}

                            </InfiniteScroll>

                        </div>
                        <div id="applied_job" className={`tab-pane fade`}>
                            <ProfessionalProposalSentList history={history} location={location}/>
                        </div>
                    </div>
                </div>
                {!loading &&
                <BottomScrollListener onBottom={() => {
                    const {relatedJobCurrPageNo, unrelatedJobCurrPageNo, totalRelatedJobFeed, totalUnrelatedJobFeed, limit} = this.state;
                    if (hash === `#${feedTabs[0].id}` && totalRelatedJobFeed > relatedJobCurrPageNo * limit) {
                        this.getRelatedJobFeed(relatedJobCurrPageNo + 1)
                    }
                    // if (hash === `#${feedTabs[1].id}` && totalUnrelatedJobFeed > unrelatedJobCurrPageNo * limit) {
                    //     this.getUnrelatedJobFeed(unrelatedJobCurrPageNo + 1)
                    // }
                }}/>
                }
                <p id="verify_account" data-toggle="modal" data-target="#verify_your_account" />
                <div className="modal fade" id="verify_your_account" role="dialog">
                    <CreateProfessionalAccountPopUp url={''} name={''}
                                                    image={require("../../static/images/sendProposalPopupVerification.png")}
                                                    message={''}>
                        <p className="para">We are really sorry but you cannot send proposals since your profile is
                            not yet verified. Please complete your profile and get it verified. Reach out to us
                            at <a href="mailto:support@workbeam.com"
                                  style={{color: '#ff337d'}}>support@workbeam.com</a>.</p>
                        <button className="btn btn-login"
                                onClick={() => this.props.history.push(path.professionalProfile)}
                        >UPDATE PROFILE
                        </button>
                    </CreateProfessionalAccountPopUp>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    data: state.data,
    totalJobInvites: state.job_count.professional.jobInviteCount !== undefined ? state.job_count.professional.jobInviteCount : 0
});

const mapDispatchToProps = (dispatch) => ({
    ...(errorActionDispatchers(dispatch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(jobFeed)
