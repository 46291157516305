import React from "react";

import * as path from "../../constants/path";
// import Link from "react-router-dom/es/Link";
export const Footer = () => {
    const footerStyle = {
        padding: '0px'
    };
    return (
        <footer className="blizzcraft-footer">
                <div className="container">
                    <div className="footer">
                    <div className="row display-flex">
                        <div className="col-sm-8" style={footerStyle}>
                            <h5>&copy; iVedha Inc.</h5>
                        </div>
                        <div className="col-sm-4 text-right" style={footerStyle}>
                                <a href={path.termsAndConditions} target='_blank'>Terms of Service</a>
                                <a href={path.privacyPolicy} target='_blank'>Privacy</a>

                        </div>
                    </div>
                </div>
            </div>
        </footer>)
};

