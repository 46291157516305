import React, {Component, Fragment} from 'react'
import {apiv1} from "../../api";
import LoadingComponent from "../../utils/LoadingComponent";
import {decimalValidator, onlyZeroToNine, proposalErrorMsg, validateProposal} from "../common/validation/FormValidator";
import queryString from 'query-string'
import $ from "jquery";
import {CreateProfessionalAccountPopUp} from "../user/GeneralOnboarding";
import {errorActionDispatchers} from "../../actions/errors";
import connect from "react-redux/es/connect/connect";
import mail from './../../static/images/mail.png'
import * as path from './../../constants/path'
import {status} from "../../constants/job";
import {AMOUNT, DUE_DATE} from "../common/Milestones";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {TEXT} from "../user/OnBoarding";
import moment from "moment";
import SeeFullJobDetails from "./SeeFullJobDetails";
import axios from "axios";
import Checkbox from '@material-ui/core/Checkbox';
import {userDetails} from "../../actions/auth";
import * as apiUrl from "../../api/apiUrls";
import {DatePickerCustomInput} from "../functional/beforeHire";
// import {UploadDocuments} from "../common/UploadDocuments";
import {getProfessionalType, professionalType as professional_type} from "../../constants/businessPractiseArea";
import {roundOffCommissionForNationalAndInternationalAnd} from "../../utils/clientSide";
import { parsePhoneNumber } from "react-phone-number-input";
import localStorage from "../../utils/localStorage";
import {commissionPercentage, indiaCountryCodeCode} from "../../constants/common";
import FileBase64 from 'react-file-base64';
import {allowedDocumentType} from "./ProfessionalProfile";
import TimeAgo from 'react-timeago'


function checkValidAmount({milestoneTotalAmount, proposalTotalAmount}) {
    if (Number(milestoneTotalAmount) > Number(proposalTotalAmount)) {
        alert(`Total milestone amount (Rs ${milestoneTotalAmount}) can't be greater than total proposal amount(Rs ${proposalTotalAmount})`)
        return false
    }
    return true
}

class SendProposal extends Component {
    constructor(props) {
        super(props)
        const {about_professional, data, hasGst, history} = props
        let {location} = history
        let {state} = location
        let {singalJobDetail} = state
        const {professional} = data
        let {profession} = professional || {}
        profession = profession || []
        let professionalType = getProfessionalType({profession})
        const {jobId, firstName, lastName, jobTitle, STATUS, show_ctc, proposalId, time} = singalJobDetail
        this.proposal_message = STATUS === status.Posted ? `Your proposal is sent. Please wait for ${firstName} to get back to you.` : `Your Proposal has been revised. Thank you.`
        this.isCommissionCalculated = true
        this.state = {
            loading: false,
            about_professional,
            message: '',
            actual_amount_enter_by_professional: 0,
            professional_amount_tax: 0,
            commission: 0,
            commission_tax: 0,
            fixed_amount: 0,
            milestoneTotalAmount: 0,
            schedule_unit_proposed: 30,
            sender: '',
            data: props.data,
            proposalSent: false,
            // proposalMilestone: [{}, {}, {}, {}, {}],
            proposalMilestone: [],
            jobMilestone: [],
            proposalDocument: [],
            portfolioLinks: [{link: ''}, {link: ''}],
            gstAmount: 0,
            wcFees: 0,
            wcGst: 0,
            totalAmount: 0,
            errors: {},
            show_ctc,
            jobId,
            jobTitle,
            firstName,
            lastName,
            time,
            jobStatus: STATUS,
            proposalId,
            shouldSeeFullJobDetailsRender: false,
            jobDetails: {},
            jobQuestion: [],
            send_notification_to_all: false,
            send_notification_to_all_in_super_admin: '',
            professionalType,
            hasGst,
            documentLink: '',
            document_file_name: '',
            document_base64: '',
            current_ctc: 0,
            current_notice_period_in_days: ''

    }
    }

    componentDidMount() {
        const {jobStatus, jobId} = this.state
        if (jobStatus === PROPOSAL_SENT) {
            this.getProposalDetails()
            // this.getProposalMilestones(proposalId)
            this.getQuestionsOfJob(jobId)
            // this.getProposalDocuments(proposalId)
            // this.getPaymentTerms(jobId)
        } else {
            this.getJobDetails()
        }
    }

    componentWillReceiveProps(nextProps) {
        const {about_professional, data} = nextProps
        if (data !== this.state.data) {
            const {professional} = data
            let {profession} = professional || {}
            profession = profession || []
            let professionalType = getProfessionalType({profession})
            this.setState({data, about_professional, profession, professionalType})
        }
        if (nextProps.totalJobInvites >= 0) {
            this.setState({totalJobInvites: nextProps.totalJobInvites})
        }
    }

    getProposalDocuments = (proposalId) => {
        const {doDispatch} = this.props
        const url = `${apiUrl.proposalDocument}?proposal=${proposalId}`
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                if (data) {
                    this.setState({loading: false, proposalDocument: data})
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, doDispatch)
            })
    }
    getQuestionsOfJob = (job_id) => {
        this.setState({getDetails: false});
        const url = `/job/job-details-hiring-summary/${job_id}/`;
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    const {job_milestone, job_question} = data
                    this.setState({loading: false, jobQuestion: job_question, jobMilestone: job_milestone})
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    };

    getProposalMilestones = (proposalId) => {
        this.setState({loading: true})
        const url = `${apiUrl.customMilestone}?proposal=${proposalId}`
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    let {milestoneTotalAmount} = this.state
                    data.map(milestone => {
                        const {amount} = milestone
                        milestoneTotalAmount += Number(amount)
                    })
                    this.setState({proposalMilestone: data, loading: false, milestoneTotalAmount})
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    getPaymentTerms = (proposalId) => {
        const url = `/job/proposal-update/${proposalId}/`;
        this.setState({loading: true});
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    let {fixed_amount, professional_amount_tax, commission, commission_tax, actual_amount_enter_by_professional, schedule_unit_proposed} = data
                    this.setState({
                        loading: false,
                        schedule_unit_proposed,
                        fixed_amount,
                        actual_amount_enter_by_professional,
                        totalAmount: fixed_amount,
                        gstAmount: professional_amount_tax,
                        wcFees: commission,
                        wcGst: commission_tax
                    })
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    };


    getProposalDetails = (e) => {
        const {proposalId} = this.state
        const url = `/job/proposal-user-professional/${proposalId}/`;
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    let {message, commission_tax, commission, professional_amount_tax, actual_amount_enter_by_professional, current_ctc, fixed_amount, schedule_unit_proposed, about_professional, send_notification_to_all, portfolio_links, job_details, user_details, document, document_file_name, service_fee, current_notice_period_in_days} = data
                    const jobDetails = {
                        ...job_details,
                        user_details,
                    }
                    portfolio_links = portfolio_links ? portfolio_links : []
                    service_fee = service_fee || 0
                    this.setState({
                        loading: false,
                        jobTitle: job_details.title,
                        message,
                        about_professional,
                        jobDetails,
                        shouldSeeFullJobDetailsRender: true,
                        actual_amount_enter_by_professional,
                        current_ctc: current_ctc,
                        current_notice_period_in_days: current_notice_period_in_days,
                        professional_amount_tax,
                        commission,
                        commission_tax,
                        fixed_amount,
                        documentLink: document,
                        document_file_name: document_file_name,
                        totalAmount: fixed_amount,
                        gstAmount: professional_amount_tax,
                        wcFees: commission,
                        wcGst: commission_tax,
                        service_fee,
                        schedule_unit_proposed,
                        send_notification_to_all,
                        send_notification_to_all_in_super_admin: send_notification_to_all,
                        portfolioLinks: portfolio_links.map(linkDetails => {
                            return {
                                link: linkDetails
                            }
                        })
                    })

                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    getJobDetails = (e) => {
        const {jobId} = this.state
        const url = `job/job-details-hiring-summary/${jobId}/`;
        apiv1.auth.get(url)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    const {job_question, user_details, job_milestone} = data
                    const jobDetails = {
                        ...data,
                        user_details,
                    }
                    this.setState({
                        loading: false,
                        jobDetails,
                        shouldSeeFullJobDetailsRender: true,
                        // proposalMilestone: [{}, {}, {}, {}, {}],
                        jobQuestion: job_question,
                        jobMilestone: job_milestone,
                        // portfolioLinks: portfolio_links
                    })

                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    };


    createCustomMilestonesApi = (request) => {
        return apiv1.auth.post(apiUrl.customMilestone, request).then(resp => {
            return apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
        })
    }

    updateCustomMilestonesApi = (request) => {
        const url = `${apiUrl.customMilestone}${request.id}/`
        return apiv1.auth.patch(url, request).then(resp => {
            return apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
        })
    }

    updateAboutProfessional = () => {
        const {about_professional} = this.state
        const {doDispatch, onFetchDetails} = this.props
        const request = {
            professional: {
                about_professional
            }
        }
        apiv1.auth.patch(apiUrl.restAuth, request)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                if (data) {
                    onFetchDetails(userDetails(data));
                }
            })
            .catch(err => {
                apiv1.handleErrors(err, doDispatch);
            })

    };

    saveMilestone = (requestApi, proposalId) => {
        const self = this
        axios.all(requestApi)
            .then(axios.spread(function (...resp) {
                // $('#proposal_sent_popup').click()
                // const {jobId, firstName, lastName, jobTitle, STATUS} = queryString.parse(self.props.location.search)
                // const {history} = self.props
                const proposalMilestone = resp.map(milestoneResponse => milestoneResponse.data)
                self.setState({
                    loading: false,
                    proposalId,
                    proposalMilestone,
                }, () => {
                    // if (STATUS === status.Posted) {
                    //     history.replace(`${path.sendProposal}?proposalId=${proposalId}&jobId=${jobId}&jobTitle=${jobTitle}&STATUS=${PROPOSAL_SENT}&firstName=${firstName}&lastName=${lastName}`)
                    // }
                    // const {about_professional} = self.state
                    // if (about_professional !== self.props.about_professional) {
                    //     self.updateAboutProfessional()
                    // }
                })
            }))

    }

    saveDocument = (requestApi, proposalId) => {
        const self = this
        axios.all(requestApi)
            .then(axios.spread(function (...resp) {
                // const {jobId, firstName, lastName, jobTitle, STATUS} = queryString.parse(self.props.location.search)
                // const {history} = self.props
                const proposalDocument = resp.map(jobDocumentResponse => jobDocumentResponse.data)
                self.setState({
                    loading: false,
                    proposalId,
                    proposalDocument,
                }, () => {
                    // if (STATUS === status.Posted) {
                    //     history.replace(`${path.sendProposal}?proposalId=${proposalId}&jobId=${jobId}&jobTitle=${jobTitle}&STATUS=${PROPOSAL_SENT}&firstName=${firstName}&lastName=${lastName}`)
                    // }
                })
            }))
    }
    sendProposal = (e, jobId) => {
        e.preventDefault();
        let {message, schedule_unit_proposed, data, document_file_name, document_base64, gstAmount, current_ctc, current_notice_period_in_days, wcFees, wcGst, jobStatus, proposalId, proposalMilestone, proposalDocument, actual_amount_enter_by_professional, about_professional, milestoneTotalAmount, send_notification_to_all, send_notification_to_all_in_super_admin, portfolioLinks, service_fee, totalAmount} = this.state
        portfolioLinks = portfolioLinks.filter(linkDetails => linkDetails.link)
        const {is_super_user, doDispatch, history} = this.props
        const {location} = history
        const {search} = location
        let id = ''
        const fixed_amount = Number((parseFloat(actual_amount_enter_by_professional) + parseFloat(gstAmount) + parseFloat(wcFees) + parseFloat(wcGst)).toFixed(2))
        if (!checkValidAmount({milestoneTotalAmount, proposalTotalAmount: fixed_amount})) {
            return
        }
        let url = ''
        let api = ''
        const errors = {};
        // const requestApi = []
        // const documentRequestApi = []
        let request = {
            // about_professional,
            message,
            fixed_amount: totalAmount,
            schedule_unit_proposed,
            commission: wcFees,
            commission_tax: wcGst,
            professional_amount_tax: gstAmount,
            actual_amount_enter_by_professional,
            current_ctc: current_ctc,
            current_notice_period_in_days: current_notice_period_in_days,
            service_fee,
            job: jobId,
        }
        if (document_base64){
            request = {
                ...request,
                document_base64: document_base64,
                document_file_name: document_file_name,
            }
        }
        if (portfolioLinks.length > 0) {
            request = {
                ...request,
                portfolio_links: portfolioLinks.map(linkDetails => linkDetails.link)
            }
        }
        const isFormValidated = validateProposal(errors, request);
        if (isFormValidated) {
            const createCustomMilestoneApi = apiUrl.customMilestone
            this.setState({loading: true})
            if (jobStatus === PROPOSAL_SENT) {
                if (is_super_user && send_notification_to_all !== send_notification_to_all_in_super_admin) {
                    request.send_notification_to_all = send_notification_to_all
                }
                url = `${apiUrl.updateProposal}/${proposalId}/`
                api = apiv1.auth.patch(url, request)
            } else {
                id = data.professional.id
                request.sender = id
                url = apiUrl.sendProposal
                api = apiv1.auth.post(url, request)
            }
            api.then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch);
                if (data) {
                    const {id} = data
                    // proposalMilestone.forEach(milestone => {
                    //     const {text, id} = milestone
                    //     if (!id) {
                    //         milestone = {
                    //             ...milestone,
                    //             proposal: data.id
                    //         }
                    //     }
                    //     if (jobStatus === status.Posted && text) {
                    //         requestApi.push(apiv1.auth.post(createCustomMilestoneApi, milestone))
                    //     } else if (text) {
                    //         let updateCustomMilestoneApi = `${apiUrl.customMilestone}${id}/`
                    //         if (!id) {
                    //             requestApi.push(apiv1.auth.post(createCustomMilestoneApi, milestone))
                    //         } else {
                    //             requestApi.push(apiv1.auth.patch(updateCustomMilestoneApi, milestone))
                    //         }
                    //     }
                    // })
                    // this.saveMilestone(requestApi, data.id)
                    // proposalDocument.forEach(document => {
                    //     const {id} = document
                    //     if (!id) {
                    //         document = {
                    //             ...document,
                    //             proposal: data.id
                    //         }
                    //         documentRequestApi.push(apiv1.auth.post(apiUrl.proposalDocument, document))
                    //     }
                    // })
                    // this.saveDocument(documentRequestApi, data.id)
                    const {about_professional} = this.state
                    if (about_professional !== this.props.about_professional && !is_super_user) {
                        // this.updateAboutProfessional()
                    }
                    this.setState({jobStatus: PROPOSAL_SENT, proposalId: id})
                    const {jobId, firstName, lastName, jobTitle, STATUS} = queryString.parse(search)
                    if (STATUS === status.Posted) {
                        history.replace(`${path.sendProposal}?proposalId=${id}&jobId=${jobId}&jobTitle=${jobTitle}&STATUS=${PROPOSAL_SENT}&firstName=${firstName}&lastName=${lastName}`)
                    }
                    $('#proposal_sent_popup').click()
                } else {
                    this.setState({loading: false, proposalSent: false})
                }
            })
                .catch(err => {
                    const resErrors = apiv1.handleErrors(err, doDispatch);
                    for (let key in resErrors) {
                        errors[key] = resErrors[key][0]
                        if (key === 'non_field_errors') {
                            $('#proposal_sent_popup').click()
                        }
                    }
                    this.setState({loading: false, errors})

                })
        } else {
            this.setState({errors}, () => {
                const firstError = Object.keys(errors)[0]
                const slideToHtml = $(`#${firstError}`)
                if (slideToHtml) {
                    $('html, body').animate({
                        scrollTop: slideToHtml.offset().top - 100
                    }, 200);
                }
            })
        }
    };

    calculateCommission = (enteredAmount) => {
        const {hasGst, jobDetails} = this.state
        const {user_details} = jobDetails
        let {client_phone} = user_details
        client_phone = client_phone || ''
        const {country} = parsePhoneNumber(client_phone) || {};
        enteredAmount = parseFloat(enteredAmount)
        let {price} = roundOffCommissionForNationalAndInternationalAnd({
            actual_amount_enter_by_professional: enteredAmount,
            country_code: country,
            is_round_off_adding_to_wc_gst: true,
            is_commission_calculating_in_frontend: true,
            hasGst,
        })
        let professional_amount_tax = hasGst ? enteredAmount*commissionPercentage.national : 0
        let commission = 0
        let commission_tax = 0
            // enteredAmount = parseFloat(enteredAmount)
            // commission = enteredAmount * 0.1
            // commission_tax = commission * .18
            price = enteredAmount + professional_amount_tax
            let service_fee = country === indiaCountryCodeCode ? price* commissionPercentage.service_fee_for_indian_customer : price*commissionPercentage.international
            service_fee = parseFloat((service_fee).toFixed(2))
            professional_amount_tax = parseFloat((professional_amount_tax).toFixed(2))
            price = service_fee + price
            // if (price % 10 !== 0) {
            //
            //     let round_off_total = Math.ceil(price / 10) * 10
            //     let amountToSplit = Math.abs(round_off_total - price)
            //     price = round_off_total
            //
            //     let wcCut = parseFloat((amountToSplit / 1.18).toFixed(2))
            //     let wcTaxCut = amountToSplit - wcCut
            //
            //     commission += wcCut
            //     commission_tax += wcTaxCut
            // }
            // commission_tax = parseFloat((commission_tax).toFixed(2))
            // commission = parseFloat((commission).toFixed(2))
            price = parseFloat((price).toFixed(2))
        let totalAmount = price
        this.isCommissionCalculated = true
        this.setState({gstAmount: professional_amount_tax, wcFees: commission, wcGst: commission_tax, totalAmount, service_fee})
    }

    // calculateCommission = (enteredAmount) => {
    //     const {errors} = this.state
    //     this.isCommissionCalculated = false
    //     apiv1.auth.post(`/job/calculate-proposal-amount`,
    //         {
    //             actual_amount_enter_by_professional: enteredAmount
    //         }
    //     )
    //         .then(resp => {
    //             const data = apiv1.parseAndAutoHandleIssues(resp);
    //             if (data) {
    //                 let {actual_amount_enter_by_professional, commission, commission_tax, professional_amount_tax} = data
    //                 professional_amount_tax = 0
    //                 let totalAmount = actual_amount_enter_by_professional + professional_amount_tax + commission + commission_tax
    //                 totalAmount = totalAmount.toFixed(2)
    //                 this.isCommissionCalculated = true
    //                 this.setState({
    //                     gstAmount: professional_amount_tax.toFixed(2),
    //                     wcFees: commission.toFixed(2),
    //                     wcGst: commission_tax.toFixed(2),
    //                     totalAmount,
    //                     errors
    //                 })
    //             }
    //         })
    //         .catch(err => {
    //             const resErrors = apiv1.handleErrors(err, this.props.doDispatch);
    //             for (let key in resErrors) {
    //                 errors[key] = resErrors[key][0]
    //             }
    //             this.setState({loading: false, errors})
    //         })
    // }

    onchangeLink = ({e, index}) => {
        let {name, value} = e.target
        const portfolioLinks = [...this.state.portfolioLinks]
        portfolioLinks[index] = {
            ...portfolioLinks[index],
            [name]: value
        }
        this.setState({portfolioLinks})
    }
    onChange = (e) => {
        let {name, value} = e.target
        const {errors} = this.state
        errors[name] = ''
        if (name === 'actual_amount_enter_by_professional' || name === 'current_ctc') {
            let changedName = 'fixed_amount'
            errors[changedName] = '';
            if (onlyZeroToNine(errors, changedName, value, proposalErrorMsg.notValid)) {
                this.calculateCommission(value)
            }
        }
        this.setState({[name]: value})
    }

    onChangeMilestone = (e, index, inputType) => {
        const proposalMilestone = [...this.state.proposalMilestone]
        let {errors} = this.state
        if (inputType === TEXT || inputType === AMOUNT) {
            const {name, value} = e.target
            let milestoneTotalAmount = 0
            if (inputType === AMOUNT) {
                errors[name] = ''
                decimalValidator(errors, name, value, proposalErrorMsg.notValid)
            } else {
                errors['message'] = ''
            }
            proposalMilestone[index] = {
                ...proposalMilestone[index],
                [name]: value,
            }
            proposalMilestone.map(milestone => {
                const {amount} = milestone
                milestoneTotalAmount += amount ? Number(amount) : 0
            })
            this.setState({proposalMilestone, errors, milestoneTotalAmount}, () => console.log(proposalMilestone))
        } else {
            errors[DUE_DATE.toLocaleLowerCase()] = ''
            const dueDate = new Date(e)
            dueDate.setHours(dueDate.getHours() + 5)
            dueDate.setMinutes(dueDate.getMinutes() + 30)
            proposalMilestone[index] = {
                ...proposalMilestone[index],
                due_date: dueDate,
            }
            this.setState({proposalMilestone, errors}, () => console.log(proposalMilestone))
        }
    }

    deleteMilestone = (milestone) => {
        this.setState({loading: true})
        apiv1.auth.delete(`${apiUrl.customMilestone}${milestone.id}/`)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data === '') {
                    const {proposalMilestone} = this.state
                    proposalMilestone.splice(proposalMilestone.indexOf(milestone), 1)
                    this.setState({loading: false, proposalMilestone})
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })

    }
    uploadDocument = (files) => {
        const currentUrl = files.base64;
        let newUrl = '';
        for (let i = 0; i < allowedDocumentType.length; i++) {
            if (currentUrl.includes(allowedDocumentType[i])) {
                newUrl = currentUrl.replace(allowedDocumentType[i], "")
            }
        }
        if (newUrl === '') {
            alert('not a valid type document')
        } else {
            this.setState({document_base64: newUrl, document_file_name: files.name, documentLink: ''});
        }
    };
    updateDocuments = ({documentArray}) => {
        let proposalDocument = [...this.state.proposalDocument]
        proposalDocument = proposalDocument.concat(documentArray)
        this.setState({proposalDocument})
    }

    setLoading = () => {
        this.setState(preState => ({loading: !preState.loading}))
    }

    documentUi = () => {
        const {proposalDocument} = this.state
        return (
            <ul className="list-unstyled link-list">
                {proposalDocument.map((documentDetails) => {
                    const {document, document_file_name, id} = documentDetails
                    return <li key={id}>
                        <a href={document}
                           target="_blank">{document_file_name}</a>
                    </li>
                })}
            </ul>
        )

    }

    portfolioLinkUi = () => {
        const {portfolioLinks} = this.state
        return <div>
            <Fragment>
                {portfolioLinks.map((linkDetails, index) => {
                    const {link} = linkDetails
                    return (
                        <div className="col-sm-6 form-group">
                            <label>Portfolio Link</label>
                            <input type="text" className="form-control" name={'link'} value={link}
                                   onChange={(e) => this.onchangeLink({e, index})}/>
                        </div>
                    )
                })}
                <div className="clearfix"/>

                {portfolioLinks.length < 5 &&
                <div className="col-sm-12">
                    <a className="btn btn-add"
                       onClick={() => {
                           const portfolioLinks = [...this.state.portfolioLinks]
                           portfolioLinks.push({link: ''})
                           this.setState({portfolioLinks})
                       }
                       }
                    >
                        {`${portfolioLinks.length === 0 ? 'Add Portfolio Link' : 'Add More Link'}`}
                    </a>
                </div>
                }
            </Fragment>
        </div>
    }

    createBulkMilestone = () => {
        const {proposalMilestone} = this.state
        return (
            <div className="col-sm-12">
                <hr/>
                <p className="text-bold">Create Job Milestones with fees breakup</p>
                <table className="table" style={{marginBottom: '0px'}}>
                    {
                        proposalMilestone.length > 0 &&
                        <thead>
                        <tr>
                            <th className="col-sm-6">Milestone</th>
                            <th className="col-sm-3">Fees In $</th>
                            <th className="col-sm-3">Date</th>
                        </tr>
                        </thead>
                    }
                    <tbody>
                    {proposalMilestone.map((milestone, index) => {
                        const {text, amount, due_date, id} = milestone
                        return (
                            <tr>
                                <td>
                                    <div className="form-group" style={{padding: '0px'}}>
                                        <input type="text"
                                               className="form-control"
                                               name='text'
                                               placeholder="Milestone Name"
                                               value={text}
                                               onChange={(e) => this.onChangeMilestone(e, index, TEXT)}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group" style={{padding: '0px'}}>
                                        <input type="text"
                                               className="form-control"
                                               name='amount'
                                               placeholder="Enter Amount In Rupees"
                                               value={amount}
                                               onChange={(e) => this.onChangeMilestone(e, index, AMOUNT)}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group" style={{padding: '0px'}}>
                                        <DatePicker
                                            customInput={<DatePickerCustomInput/>}
                                            selected={due_date ? moment(milestone.due_date) : ''}
                                            className="form-control"
                                            onChange={(e) => this.onChangeMilestone(e, index, DUE_DATE)}
                                            name="due_date"
                                            placeholderText="Milestone Due Date"
                                            dateFormat="DD/MM/YYYY" required
                                        />
                                    </div>
                                </td>
                                {/*{ id &&*/}
                                {/*    <td>*/}
                                {/*        <a className="btn btn-danger"*/}
                                {/*           onClick={(e) => {*/}
                                {/*               e.preventDefault()*/}
                                {/*               confirmAlert({*/}
                                {/*                   message: 'Are you sure ?.',*/}
                                {/*                   buttons: [*/}
                                {/*                       {*/}
                                {/*                           label: 'Yes',*/}
                                {/*                           onClick: () => {*/}
                                {/*                               this.deleteMilestone(milestone)*/}
                                {/*                           }*/}
                                {/*                       },*/}
                                {/*                       {*/}
                                {/*                           label: 'No',*/}
                                {/*                           onClick: () => {*/}
                                {/*                           }*/}
                                {/*                       }*/}
                                {/*                   ]*/}
                                {/*               })*/}
                                {/*           }}*/}
                                {/*        ><i className="fa fa-trash"></i></a>*/}
                                {/*    </td>*/}
                                {/*}*/}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                <button type="button"
                        className="btn btn-login"
                        onClick={() => {
                            const proposalMilestone = [...this.state.proposalMilestone]
                            proposalMilestone.push({})
                            this.setState({proposalMilestone})
                        }}
                >
                    {`${proposalMilestone.length === 0 ? 'Add Milestone' : 'Add more'}`}
                </button>
                <br className="visible-xs"/>
            </div>
        )
    }

    render() {
        const {firstName, lastName, jobTitle, time, proposalId, jobId, show_ctc, message, documentLink, document_file_name, schedule_unit_proposed, gstAmount, wcFees, wcGst, totalAmount, loading, errors, jobStatus, jobDetails, shouldSeeFullJobDetailsRender, actual_amount_enter_by_professional, current_ctc, current_notice_period_in_days, about_professional, jobQuestion, send_notification_to_all, professionalType, portfolioLinks, jobMilestone, service_fee} = this.state;
        const {is_super_user, history} = this.props
        // const url = jobStatus === status.Posted ? path.jobFeed : path.proposalSent
        const url = path.jobFeed
        return (
            <div id="row" style={{marginTop: '40px'}}>
                {loading && (
                    <div className="lazy-loader" style={{zIndex: '1000'}}
                    >
                        <LoadingComponent/></div>)}
                        <div className="col-sm-12">
                            <div className="posted-jobs-detail">
                                <div className="posted-jobs-m-body" style={{paddingBottom: '5px', paddingTop:'5px'}}>
                                    <ul className="breadcrumb breadcrumb-ul">
                                        <li>
                                            <a
                                               onClick={()=>{
                                                   history.push({
                                                       pathname: path.jobFeed
                                                   })
                                               }}
                                            ><i className="fa fa-long-arrow-left" style={{color: '#ff337d'}}/> Jobs Feeds</a>
                                        </li>
                                        <li>
                                            <a
                                               onClick={() => history.goBack()}
                                            >
                                                {jobTitle}
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                                Apply now
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="posted-jobs-detail">
                                <div className="posted-jobs-m-container">
                                    <div className="posted-jobs-m-body">
                                        <h3 className="order-posted-heading">{jobTitle}</h3>
                                        <p className="order-posted-date">
                                            <TimeAgo date={time}/>
                                        </p>
                                    </div>
                                    <div className="posted-jobs-m-body">
                                        {/*<h4 className="xs-para text-center">*/}
                                                {/*<a className="pull-right btn btn-sent" data-toggle="modal"*/}
                                                {/*data-target={jobStatus === status.Posted ? '#' + jobId + 'see_details' : '#' + proposalId}*/}
                                                {/*>See full job details</a>*/}
                                                {/*<b>{jobStatus === status.Posted ? 'Let’s send ' : 'Revise'} your proposal*/}
                                                    {/*to {firstName + ' ' + lastName}. {jobStatus === status.Posted ? 'You need to specify a few details. ' : ''}*/}
                                                {/*</b>*/}
                                            {/*</h4>*/}
                                            <div className="row basic-detail-form">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label>Write a cover letter:</label>
                                                        <textarea rows="8" className="form-control"
                                                            // placeholder={'Why' + ' ' + firstName + ' ' + 'should hire you ? Make it look convincing.'}
                                                                  name='message'
                                                                  id='message'
                                                                  value={message}
                                                                  onChange={(e) => this.onChange(e)}
                                                        >
                                                   </textarea>
                                                        <span className={errors.message ? 'error' : 'no-error'}>{errors.message}</span>

                                                    </div>
                                                    <p className="text-bold" style={{marginTop: '0px'}}>Cover letter tips:</p>
                                                    <ul className="tips-ul">
                                                        <li>Introduce Yourself</li>
                                                        <li>Include your skills and experience and portfolio link</li>
                                                        <li>Explain why are you the right for this job</li>
                                                        <li>Do not mention personal email, website or contact info</li>
                                                    </ul>
                                                    <hr/>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Current Annual CTC</label>
                                                        <input type="text"
                                                               className="form-control input-lg"
                                                               id="current_ctc"
                                                               name="current_ctc"
                                                               value={current_ctc}
                                                               onChange={(e) => this.onChange(e)}
                                                        />
                                                        <span
                                                            className={errors.fixed_amount ? 'error' : 'no-error'}>{errors.fixed_amount}</span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Expected Annual CTC</label>
                                                        <input type="text"
                                                               className="form-control input-lg"
                                                               id="fixed_amount"
                                                               name="actual_amount_enter_by_professional"
                                                               value={actual_amount_enter_by_professional}
                                                               onChange={(e) => this.onChange(e)}
                                                        />
                                                        <span
                                                            className={errors.fixed_amount ? 'error' : 'no-error'}>{errors.fixed_amount}</span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    {/*<table className="table">*/}
                                                        {/*<tbody>*/}
                                                        {/*{gstAmount > 0 &&*/}
                                                        {/*<tr>*/}
                                                            {/*<td><b>Your GST</b></td>*/}
                                                            {/*<td><span>Rs. {gstAmount}</span></td>*/}
                                                        {/*</tr>*/}
                                                        {/*}*/}
                                                        {/*<tr>*/}
                                                            {/*<td><b>Service Fee</b></td>*/}
                                                            {/*<td><span>Rs. {service_fee}</span></td>*/}
                                                        {/*</tr>*/}
                                                        {/*<tr>*/}
                                                        {/*<td><b>Wc Fee</b></td>*/}
                                                        {/*<td><span>Rs. {wcFees}</span></td>*/}
                                                        {/*</tr>*/}
                                                        {/*<tr>*/}
                                                        {/*<td><b>Wc GST</b></td>*/}
                                                        {/*<td><span>Rs. {wcGst}</span></td>*/}
                                                        {/*</tr>*/}
                                                        {/*<tr>*/}
                                                            {/*<td><b>Total</b></td>*/}
                                                            {/*<td><span>Rs. {totalAmount}</span></td>*/}
                                                        {/*</tr>*/}
                                                        {/*</tbody>*/}
                                                    {/*</table>*/}
                                                    <p className="text-note">* Fees include professional fee plus Govt. Fees</p>
                                                    <p className="text-note">* Fees can be negotiated once client chats with you after getting your proposal</p>
                                                </div>

                                                <div className="col-sm-12" style={{marginTop:'20px'}}>
                                                    <div className="form-group">
                                                        <label>Your current notice period (In days)</label>
                                                        <input type="text"
                                                               className="form-control input-lg"
                                                               id="current_notice_period_in_days"
                                                               name="current_notice_period_in_days"
                                                               placeholder="Enter your notice period"
                                                               value={current_notice_period_in_days}
                                                               onChange={(e) => this.onChange(e)}
                                                        />
                                                        <span
                                                            className={errors.current_notice_period_in_days ? 'error' : 'no-error'}>{errors.current_notice_period_in_days}</span>
                                                    </div>
                                                </div>

                                                {/*<div className="col-sm-6" style={{marginTop:'20px'}}>*/}
                                                    {/*<div className="form-group">*/}
                                                        {/*<label>Completion Time</label>*/}
                                                        {/*<input type="text"*/}
                                                               {/*className="form-control input-lg"*/}
                                                               {/*id="schedule_unit_proposed"*/}
                                                               {/*name='schedule_unit_proposed'*/}
                                                               {/*value={schedule_unit_proposed}*/}
                                                               {/*onChange={(e) => this.onChange(e)}*/}
                                                        {/*/>*/}
                                                        {/*<p className={errors.schedule_unit_proposed ? 'error' : 'no-error'}>{errors.schedule_unit_proposed}</p>*/}
                                                        {/*<p className="text-note"*/}
                                                           {/*style={{marginTop: '0px'}}>*/}
                                                            {/** Days from complete submission of documents and information*/}
                                                            {/*by client</p>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}


                                                <div className="col-sm-12">
                                                    <hr/>
                                                    <p className="text-bold">Upload Your updated Resume</p>
                                                    <label className="btn-upload-file btn-bs-file" style={{
                                                        color: '#ff337d',
                                                        fontSize: '16px',
                                                        fontFamily: 'sofiaLight',
                                                        cursor: 'pointer',
                                                        marginBottom:'15px'
                                                    }}>
                                                        <i className="fa fa-cloud-upload"></i> {`${documentLink ? 'Change Resume' : 'Upload Resume'}`}
                                                        <FileBase64
                                                            onDone={this.uploadDocument}>
                                                        </FileBase64>
                                                    </label>
                                                    <p className="para"><a href={documentLink} target={`_blank`} style={{color:'#ff337d', fontSize:'16px'}}>{document_file_name}</a></p>
                                                </div>
                                                {/*<div>*/}
                                                {/*{(professionalType === professional_type.designer || is_super_user) && (*/}
                                                {/*<div className="col-sm-12">*/}
                                                {/*<hr/>*/}
                                                {/*<p className="text-bold">Upload Sample or Portfolio Attachments (Don't Include*/}
                                                {/*Personal Information)</p>*/}
                                                {/*<div className="text-center">*/}
                                                {/*<label className="dropzone-area">*/}
                                                {/*<UploadDocuments*/}
                                                {/*proposalId={proposalId}*/}
                                                {/*consultationId={''}*/}
                                                {/*isOnlyForGeneratingBase64={!proposalId}*/}
                                                {/*setLoading={this.setLoading}*/}
                                                {/*doDispatch={``}*/}
                                                {/*needToSendOnChat={false}*/}
                                                {/*receiverId={''}*/}
                                                {/*senderId={''}*/}
                                                {/*updateDocuments={this.updateDocuments}*/}
                                                {/*>*/}
                                                {/*<i className="fa fa-cloud-upload" aria-hidden="true"/>*/}
                                                {/*<br/>*/}
                                                {/*Drag & Drop Files*/}
                                                {/*<p className="text-center text-bold">Or</p>*/}
                                                {/*<a className="btn btn-continue">Upload files</a>*/}
                                                {/*</UploadDocuments>*/}
                                                {/*</label>*/}
                                                {/*<div style={{textAlign: 'left'}}>*/}
                                                {/*{this.documentUi()}*/}
                                                {/*</div>*/}
                                                {/*</div>*/}
                                                {/*<p className="text-bold">Upload Sample and Portfolio Links (Don't include links*/}
                                                {/*to personal sites)</p>*/}
                                                {/*{this.portfolioLinkUi()}*/}
                                                {/*</div>*/}
                                                {/*)}*/}
                                                {/*</div>*/}
                                                {/*{this.createBulkMilestone()}*/}

                                                <div className="col-sm-12 text-center">
                                                    {is_super_user &&
                                                    <Fragment>
                                                        <Checkbox
                                                            checked={send_notification_to_all}
                                                            color="primary"
                                                            onChange={() => {
                                                                this.setState({send_notification_to_all: !send_notification_to_all})
                                                            }
                                                            }
                                                        />
                                                        <span
                                                            style={{marginRight: '20px'}}>{send_notification_to_all ? 'Live' : 'Not Live'}</span>
                                                    </Fragment>
                                                    }
                                                    <button type="button"
                                                            className="btn btn-see-job-post"
                                                            style={{marginTop:'20px'}}
                                                            disabled={!this.isCommissionCalculated}
                                                            onClick={(e) => {
                                                                this.sendProposal(e, jobId)
                                                            }}
                                                    >{jobStatus === status.Posted ? 'Send proposal' : 'Save Changes'}</button>
                                                    <p>&#x00A0;</p>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                <p id="proposal_sent_popup" data-toggle="modal" data-target="#open_poup" />
                <div className="modal fade" id="open_poup" role="dialog">
                    <CreateProfessionalAccountPopUp url={url} message={this.proposal_message}
                                                    // name={jobStatus === status.Posted ? 'Go To Job Feed' : 'Go To Proposal Sent'}
                                                    name={'Go To Job Feed'}
                                                    image={mail}/>
                </div>
                {shouldSeeFullJobDetailsRender &&
                <SeeFullJobDetails dataTargetId={proposalId ? undefined : jobId + 'see_details'}
                                   jobDetails={jobDetails} jobId={jobId} proposalId={proposalId}

                                   jobQuestions={jobQuestion}
                                   jobMilestone={jobMilestone}/>}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    data: state.data,
    about_professional: (state.data.professional && state.data.professional.about_professional) ? state.data.professional.about_professional : '',
    is_super_user: state.data.is_super_user,
    hasGst: !!(JSON.parse(localStorage.logedInUserDetails).pro_gst_number)
});

const mapDispatchToProps = (dispatch) => ({
    onFetchDetails: (action) => {
        dispatch(action);
    },
    ...(errorActionDispatchers(dispatch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendProposal)
export const PROPOSAL_SENT = 'PROPOSAL SENT'
export const aboutProposalWordLimit = 150

