var $ = require('jquery');
// $(document).ready(function(){
// 	$('.ui-white-box').css('height', $(window).height());
// });
// $(window).resize(function(){
// 	$('.ui-white-box').css('height', $(window).height());
// });
$(document).ready(function() {
	function setHeight() {
		var windowHeight = $(window).innerHeight();
		$('.ui-white-box').css('min-height', windowHeight);
    }
    setHeight();

	$(window).resize(function() {
		setHeight();
	});
});
$(document).ready(function () {
	$('[data-toggle="offcanvas"]').click(function () {
		$("#navigation").toggleClass("hidden-xs");
	});
	// $('.navi>ul>li').click(function () {
	// 	$('.navi>ul>li').removeClass("active");
	// 	$(this).addClass("active");
	// });
	$('.ui-rating-field>ul>li').click(function () {
		$('.ui-rating-field>ul>li').removeClass("active");
		$(this).addClass("active");
	});
	$(".background-white").click(function () {
		$(this).toggleClass("background-pink");
	});
	$('#INVITE_JOB .modal-footer .btn-login').click(function () {
		$('#INVITE_JOB form').addClass('hidden');
		$('#INVITE_JOB .empty-space-box').removeClass('hidden');
		$('#INVITE_JOB .modal-footer .btn-login').addClass('hidden');
	});
	$('.empty-space-box button').click(function () {
		$('#INVITE_JOB .empty-space-box').addClass('hidden');
		$('#INVITE_JOB form').removeClass('hidden');
		$('#INVITE_JOB .modal-footer .btn-login').removeClass('hidden');
	});
	$('#INVITE_JOB .close').click(function () {
		$('#INVITE_JOB .empty-space-box').addClass('hidden');
		$('#INVITE_JOB form').removeClass('hidden');
		$('#INVITE_JOB .modal-footer .btn-login').removeClass('hidden');

	});
	$('#invite_p_one').click(function () {
		$('#professional_one').removeClass('hidden');
		$('#professional_two').addClass('hidden');
		$('.pro_name').html('Gagan');
	});
	$('#invite_p_two').click(function () {
		$('#professional_one').addClass('hidden');
		$('#professional_two').removeClass('hidden');
		$('.pro_name').html('Kunal');
	});
	$('#accept-detail .col-sm-6 .text-center>.btn-login').click(function () {

		$('#accept-detail .sent-proposal-box').addClass('hidden');
		$('#accept-detail .empty-space-box').removeClass('hidden');
		$('#accept-detail .modal-dialog').css({ 'width': '50%', 'margin': '30px auto' });

	});
	$('.empty-space-box button').click(function () {
		$('#accept-detail .empty-space-box').addClass('hidden');
		$('#accept-detail .sent-proposal-box').removeClass('hidden');
		$('#accept-detail .modal-dialog').css({ 'width': '80%', 'margin-right': '2%' });
	});
	$('#accept-detail .close').click(function () {
		$('#accept-detail .empty-space-box').addClass('hidden');
		$('#accept-detail .sent-proposal-box').removeClass('hidden');
		$('#accept-detail .modal-dialog').css({ 'width': '80%', 'margin-right': '2%' });
	});

	$('#verify_email').click(function () {
		$('#verify_text').removeClass('hidden');
	});
	$('#mobile_verify').click(function () {
		$('.otp-box').removeClass('hidden');
	});
	$('.btn-login').click(function () {
		$('.otp-box').addClass('hidden');
		$('#verify_text').addClass('hidden');
	});
	// $('.member_list>ul>li').click(function () {
	// 	$('.member_list>ul>li').removeClass("active");
	// 	$(this).addClass("active");
	// });
	$('.hired-professional').click(function () {
		$('#dashboard-name').addClass('hidden');
		$('#v-align').removeClass('hidden');
		$('#isDate').addClass('hidden');
		$('#p_job_date').removeClass('hidden');
		$('#isUser').addClass('hidden');
		$('#p_job_name').removeClass('hidden');
	});
	// $('#post_tab').click(function () {
	// 	$('#dashboard-name').removeClass('hidden');
	// 	$('#v-align').addClass('hidden');
	// 	$('.info-text').html('Job Postings');
	// });
	// $('#progress_tab').click(function () {
	// 	$('#dashboard-name').removeClass('hidden');
	// 	$('#v-align').addClass('hidden');
	// 	$('.info-text').html('Jobs in Progress');
	// });
	// $('#doc_tab').click(function () {
	// 	$('#dashboard-name').removeClass('hidden');
	// 	$('#v-align').addClass('hidden');
	// 	$('.info-text').html('Document');
	// });
	// $('#track_tab').click(function () {
	// 	$('#dashboard-name').removeClass('hidden');
	// 	$('#v-align').addClass('hidden');
	// 	$('.info-text').html('Track');
	// });
	// $('#profile_tab').click(function () {
	// 	$('#dashboard-name').removeClass('hidden');
	// 	$('#v-align').addClass('hidden');
	// 	$('.info-text').html('Profile');
	// });
	// $('#history-tab').click(function () {
	// 	$('#dashboard-name').removeClass('hidden');
	// 	$('#v-align').addClass('hidden');
	// 	$('.info-text').html('History');
	// });
	// $('#feed_tab').click(function () {
	// 	$('#dashboard-name').removeClass('hidden');
	// 	$('#v-align').addClass('hidden');
	// 	$('.info-text').html('Job Feed');
	// });
	// $('#proposal_tab').click(function () {
	// 	$('#dashboard-name').removeClass('hidden');
	// 	$('#v-align').addClass('hidden');
	// 	$('.info-text').html('Proposal Sent');
	// });
	// $('#dashboard-tab').click(function () {
	// 	$('#v-align').removeClass('hidden');
	// 	$('#dashboard-name').addClass('hidden');
	// 	$('#isDate').removeClass('hidden');
	// 	$('#p_job_date').addClass('hidden');
	// 	$('#isUser').removeClass('hidden');
	// 	$('#p_job_name').addClass('hidden');
	// });
	//
	//
	//
	// $('.notification_icon').click(function () {
	// 	$('#dashboard-name').removeClass('hidden');
	// 	$('#v-align').addClass('hidden');
	// 	$('#infom_text .info-text').html('Notifications');
	// });
	// $('.chat_icon').click(function () {
	// 	$('#dashboard-name').removeClass('hidden');
	// 	$('#v-align').addClass('hidden');
	// 	$('#infom_text .info-text').html('Messages');
	// });
	$("#search_item").on("keyup", function () {
		var value = $(this).val().toLowerCase();
		$(".member_list li").filter(function () {
			$(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
		});
	});

	$('#add').click(function () {
		var toAdd = $("#add_company").val();
		if (toAdd != '') {


			$('#client_reviews .list-unstyled').append('<li class="alert">' + toAdd + '<input type="button" class="btn btn-link pull-right" data-dismiss="alert" value="Remove"></li>');
			$('#add_company').val('');
		}
	});
	// var text_max = 25;
	// $('#count_message').html(text_max);
	//
	// $('#job_title').keyup(function () {
	// 	var text_length = $('#job_title').val().length;
	// 	var text_remaining = text_max - text_length;
	//
	// 	$('#count_message').html(text_remaining);
	// });
});

$(function () {
	$('.common_area').css({
		'height': (($(window).height()) - 80) + 'px'
	});
	$(window).bind('resize', function () {
		$('.common_area').css({
			'height': (($(window).height()) - 80) + 'px'
		});
	});
});
$(function () {

	$(".contact_sec .text-muted").each(function (i) {

		var len = $(this).text().length;

		if (len > 20) {

			$(this).text($(this).text().substr(0, 35) + '...');

		}
	});
});

// $(function () {
// 	$('.member_list').css({
// 		'height': (($(window).height()) - 120) + 'px'
// 	});
// 	$(window).bind('resize', function () {
// 		$('.member_list').css({
// 			'height': (($(window).height()) - 120) + 'px'
// 		});
// 	});
// });
// $(function () {
// 	$('.chat_area').css({
// 		'height': (($(window).height()) - 270) + 'px'
// 	});
// 	$(window).bind('resize', function () {
// 		$('.chat_area').css({
// 			'height': (($(window).height()) - 270) + 'px'
// 		});
// 	});
// });
// $(function () {
// 	$('.cunsult-chat-box').css({
// 		'height': (($(window).height()) - 350) + 'px'
// 	});
// 	$(window).bind('resize', function () {
// 		$('.cunsult-chat-box').css({
// 			'height': (($(window).height()) - 350) + 'px'
// 		});
// 	});
// });

$(function () {

	$(".text-container>p>span").each(function (i) {

		var len = $(this).text().length;

		if (len > 100) {

			$(this).text($(this).text().substr(0, 250) + '...');

		}
	});
});

