import React, { Component, Fragment } from "react"
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Loadable from 'react-loadable'
import * as path from '../constants/path'
import * as keyWords from '../constants/keyWord'
import { PROFESSIONAL, USER } from '../constants/auth'
import AboutUs, { SiteFooter } from './AboutUs'
import localStorage from '../utils/localStorage'
import sessionStorage from '../utils/sessionStorage'
// import { callType } from "./common/VideoCallForConsultation"
import { productIdMappedWithSlug, productSlugMappedWithId } from "./common/bannerTextForProducts"
// import {TaxAddPopup, AnnualROCAdd, GstAddPage} from "./common/taxAddPopup"
// import cityDetailsWithSlug from "../slug/cityDetailsWithSlug";
import { doLoad } from "../utils/LoadingComponent"
import { AllOwlCrousel } from './common/AllOwlCrousel'
import SiteHeader from "./SiteHeader"
import queryString from "query-string"
// import {App_env} from "../api";
import $ from 'jquery'
import { categoryPage } from "../constants/path"
import BusinessFormationPvtLtd from './services/BusinessFormationPvtLtd'
import Errors from '../components/Errors'
import { parentPracticeAreaSlugForPath } from './common/bannerTextForProducts'
import {
    professionalType as professional_type,
} from './../constants/businessPractiseArea'
// import {partnershipCompanies} from '../constants/partnershipWithcompanies'
import { showDefault, downloadApp, mainDomain } from "../constants/common"
import { getGeoInfo } from '../utils/clientSide'
import { developmentServices } from "../constants/path"
// import {best} from "../constants/keyWord"
import { setLocalStorage } from "./user/UserSignUp";
import { apiv1 } from "../api";
import * as apiUrl from "../api/apiUrls";
import { getProfile } from "../reducers/auth";
import { LogoHeader } from "./functional/beforeHire";
import { errorActionDispatchers } from "../actions/errors";
let helpArticlePath = [path.article1, path.article2, path.article3, path.article4, path.article5, path.article6, path.article7, path.article8, path.article9]
let exploreServiceList = [path.incomeTax, path.accountingServices, path.gstServices, path.businessFormationServices, path.businessLicensesServices, path.rocCompliance, path.trademarksServices, path.graphicDesign, path.videoAnimation, path.websiteDevelopment, path.ecommerceServices, path.content, path.Marketing]


class Root extends Component {
    constructor(props) {
        super(props)
        const { location } = props
        const { search } = location
        let { referral_code, path, tracking_id, coupon_code, auth_token } = queryString.parse(search)
        tracking_id = tracking_id || ''
        if (referral_code) {
            localStorage.referralCode = referral_code
        }
        let affiliateMarketingDetails = JSON.parse(sessionStorage.affiliateMarketingDetails) || {}
        const stored_coupon_code = affiliateMarketingDetails.coupon_code
        if (!coupon_code) {
            coupon_code = stored_coupon_code
        }
        let loading = false
        if (auth_token) {
            setLocalStorage({ auth_token })
            loading = true
        }
        if (tracking_id || coupon_code) {
            let manageHeaderAndFooter = tracking_id.split('_')
            affiliateMarketingDetails = {
                header_ui: manageHeaderAndFooter[0] && manageHeaderAndFooter[0].substring(0, 2),
                footer_ui: manageHeaderAndFooter[0] && manageHeaderAndFooter[0].substring(2, 4),
                tracking_id: manageHeaderAndFooter[1],
                coupon_code: coupon_code || '',
                download_app: manageHeaderAndFooter[2] === downloadApp
            }
            sessionStorage.affiliateMarketingDetails = JSON.stringify(affiliateMarketingDetails)
        }
        let isHideDefaultHeader = affiliateMarketingDetails.header_ui === showDefault.hf
        let isHideDefaultFooter = affiliateMarketingDetails.footer_ui === showDefault.hf
        // if (path) {
        //     // localStorage.pathName = path.substring(1, path.length-1)
        // }
        this.state = {
            isHideDefaultHeader,
            isHideDefaultFooter,
            affiliateMarketingDetails,
            loading,
            isAuthenticationError: false
        }
    }

    componentDidMount() {
        const { loading } = this.state
        const { history } = this.props
        history.listen((location, action) => {
            this.onPathChange({ location, action })
        })
        if (loading) {
            this.fetchUserAndProData()
        }
        if (localStorage.pathName && !localStorage.isLoggedIn()) {
            history.replace(path.userLogin)
        }
        // if (process.env.REACT_APP_ENV === App_env.production) {
        //     setTimeout(() => {
        //         // intercomScript()
        //         // initiateCall({})
        //     }, 5000)
        // }
        if (!localStorage.country) {
            getGeoInfo({})
        }
    }


    fetchUserAndProData = () => {
        const { doDispatch } = this.props
        apiv1.auth.get(apiUrl.restAuth)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp);
                if (data) {
                    getProfile(data)
                }
                this.setState({ loading: false })

            })
            .catch(err => {
                this.setState({ loading: false, isAuthenticationError: true });
                apiv1.handleErrors(err, doDispatch)
            })
    };


    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }

    onPathChange = ({ location, action }) => {
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove()
        $('.modal.center-modal').click()
    }

    helpCenterArticles = () => {
        return (
            <Fragment>
                <Switch>
                    <Route
                        render={(props) => (
                            <Fragment>
                                {helpArticlePath.map(path => {
                                    return <Route exact path={path} render={(props) => <AsyncHelpArticle {...props}
                                        doDispatch={this.props.doDispatch} />} />
                                })}
                            </Fragment>
                        )}
                    />
                </Switch>
            </Fragment>
        )
    }
    // exploreServiceCategory = () => {
    //     return (
    //         <Fragment>
    //             <Switch>
    //                 <Route render={(props) => (
    //                     <Fragment>
    //                         {exploreServiceList.map(path => {
    //                             return <Route exact path={`${path}/:productParentId?`}
    //                                           render={(props) => <AsyncSubCategory {...props}
    //                                                                                              doDispatch={this.props.doDispatch}/>}/>
    //                         })}
    //                     </Fragment>
    //                 )}/>
    //             </Switch>
    //         </Fragment>
    //     )
    // }

    renderRouteWithoutDefaultHeader = (splittedPath) => {
        // const {pathname} = this.props.location
        const { history } = this.props
        //  if (path.gigStoreOffer.indexOf(splittedPath[2]) > -1) {
        //     return <Route path={`/:professionalSlug${path.gigStoreOffer}`}
        //                   render={(props) => <AsyncProfessionalCustomProduct {...props} />}/>
        // }
        if (`/${splittedPath[1]}` === path.meeting) {

            return <Route path={`${path.meeting}`}
                render={(props) => <AsyncAgoraAudioVideoCall {...props} history={history} />} />
        }
    }

    renderServices = (splittedPath) => {
        const { pathname } = this.props.location
        const { history } = this.props
        const { isHideDefaultFooter, affiliateMarketingDetails } = this.state
        if (productSlugMappedWithId.hasOwnProperty(splittedPath[1]) && splittedPath[2] && splittedPath[2] === keyWords.online && splittedPath[3] && parentPracticeAreaSlugForPath.hasOwnProperty(splittedPath[3])) {
            return <Route path={`/:productSlug/${keyWords.online}/:childPracticeAreaSlug?/:citySlug?`}
                render={(props) => <AsyncLegalAndAccountingService {...props} />} />
        }
        if (splittedPath[1] && productSlugMappedWithId.hasOwnProperty(splittedPath[1])) {
            return <Route path={`/:productSlug/:parentPracticeAreaSlug?/:citySlug?`}
                render={(props) => <BusinessFormationPvtLtd {...props}
                    isHideDefaultFooter={isHideDefaultFooter}
                    affiliateMarketingDetails={affiliateMarketingDetails} />}/>
        }            
        else if (splittedPath[1] && splittedPath[1] === keyWords.standardPackage && splittedPath[2] && productSlugMappedWithId.hasOwnProperty(splittedPath[2])) {
            let productDetails = productSlugMappedWithId[splittedPath[2]] || {}
            if (productDetails.product_id && productIdMappedWithSlug[productDetails.product_id].product_type === professional_type.designer) {
                return <Route path={`/${splittedPath[1]}/:productSlug`}
                    render={(props) => <DesignerCategoryPackage {...props} />} />
            }
        }
        // else if (splittedPath[1] === category){

        //     if (!splittedPath[2]){
        //         return <Route
        //             path={`/${splittedPath[1]}`}
        //             render={(props) => <AsyncCategoryServices {...props} />}/>
        //     } else if (!splittedPath[3] && splittedPath[2] && parentPracticeAreaSlugForPath.hasOwnProperty(splittedPath[2])){
        //         return <Route exact path={`/${splittedPath[1]}/:parentPracticeAreaSlug`}
        //                       render={(props) => <AsyncSubCategoryServices {...props}
        //                                                            doDispatch={this.props.doDispatch}/>}/>
        //     }
        //     else if (splittedPath[3] && (productSlugMappedWithId.hasOwnProperty(splittedPath[3]) || productSlugMappedWithId.hasOwnProperty(splittedPath[3].slice(0, splittedPath[3].indexOf(keyWords._in_))))){
        //         if (splittedPath[4] === path.byCity){
        //             return <Route
        //                 path={`/${splittedPath[1]}/:parentPracticeAreaSlug/:productSlug/${path.byCity}`}
        //                 render={(props) => <AsyncProfessionalGigByCity {...props} />}/>
        //         }
        //         return <Route
        //             path={`/${splittedPath[1]}/:parentPracticeAreaSlug/:productSlug`}
        //             render={(props) => <AsyncPublicGigList {...props}
        //                                                    affiliateMarketingDetails={affiliateMarketingDetails}/>}/>
        //     }
        // }
        else if (splittedPath[1] === keyWords.freelancers) {
            if (productSlugMappedWithId.hasOwnProperty(splittedPath[3])) {
                return <Route path={`/${splittedPath[1]}/:professionalSlug/:productSlug`}
                    render={(props) => <AsyncDefaultGigDetails {...props}
                        isHideDefaultFooter={isHideDefaultFooter}
                        affiliateMarketingDetails={affiliateMarketingDetails} />} />
            }
            return <Route exact path={`/${splittedPath[1]}/:professionalSlug`}
                render={(props) => <AsyncProfessionalPublicProfile {...props}
                    doDispatch={this.props.doDispatch} />} />
        }
        else if (splittedPath[1] === keyWords.hire) {
            if (!splittedPath[2]) {
                return <Route
                    path={`/${keyWords.hire}`}
                    render={(props) => <AsyncHire {...props} />} />

            }
            else if (splittedPath[2] && splittedPath[3] !== path.byCity) {
                return <Route
                    path={`/${keyWords.hire}/:platformDevelopment/:citySlug?`}
                    render={(props) => <AsyncLanguageDeveloperProfessionalList {...props} />} />

            } else if (splittedPath[3] === path.byCity) {
                return <Route
                    path={`/${keyWords.hire}/:platformDevelopment/by-city`}
                    render={(props) => <AsyncLanguageDeveloperByCity {...props} />} />
            }

        }
        if (splittedPath[1] === path.developmentServices) {
            return <Route
                path={`/${developmentServices}`}
                render={(props) => <AsyncLanguageAndPlatformList {...props} />} />
        }

        if (path.categoryPage.indexOf(splittedPath[1]) > -1 && splittedPath[2] !== undefined && splittedPath[3] !== undefined) {
            return <Route path={`${path.categoryPage}/:parentCategory?/:categorySlug?`}
                render={(props) => <AsyncLearningCenter {...props} />} />
        } else if (path.categoryPage.indexOf(splittedPath[1]) > -1 && splittedPath[2] !== undefined) {
            return <Route path={`${categoryPage}/:categorySlug?`}
                render={(props) => <AsyncCategoryLearningCenter {...props} />} />
        } else if (splittedPath[1] === keyWords.community) {
            return <Route path={`/${splittedPath[1]}/:postSlug`}
                render={(props) => <AsyncCommunity {...props} />} />
        }
        // if (splittedPath[1] && (partnershipCompanies.indexOf(splittedPath[1]) > -1)) {
        //     return <Route path={`/${splittedPath[1]}/:couponCode?`}
        //                   render={(props) => <AsyncServiceForBrand {...props} />}/>
        // }
        // else if (pathname === path.popularPackageForYou) {
        //     return <Route path={pathname} render={(props) => <AsyncCommonStandardPackages {...props} />}/>
        // }
        // else if (pathname === path.getInTouch) {
        //     return <AsyncGetInTouch history={history}/>;
        // }
        // else if (splittedPath[1] === path.searchResult) {
        //     return <Route path={`/${splittedPath[1]}/:keyword/`} render={(props) => <AsyncProductSearchResult {...props} />}/>
        //     // return <AsyncProductSearchResult history={history}/>;
        // }
        // else if (pathname === path.gigTrending) {
        //     return <AsyncTrendingServiceList history={history}/>;
        // }
        // else if (pathname === path.root) {
        //     return <AboutUs history={history}/>
        // } else if (pathname === path.professionalLanding) {
        //     localStorage.userType = PROFESSIONAL;
        //     return <AsyncProfessionalLandingPage history={history} type={PROFESSIONAL}/>;
        // }
        // else if (pathname === path.fileItr) {
        //     return <TaxAddPopup history={history}/>
        // } else if (pathname === path.annualRoc) {
        //     return <AnnualROCAdd history={history}/>
        // } else if (pathname === path.gstAdd) {
        //     return <GstAddPage history={history}/>
        // }
        // else if (pathname === path.privacyPolicy) {
        //     return <AsyncPrivacyPolicy history={history}/>;
        // } else if (pathname === path.termsAndConditions) {
        //     return <AsyncTermsAndCondition history={history}/>;
        // }
        //services path
        // else if (pathname === path.legalAdvice) {
        //     return <AsyncProductOnBoarding history={history}/>
        // }
        else if (pathname === path.categoryPage) {
            return <AsyncHomeLearningCenter history={history} />
        } else if (pathname === path.publicCommunity) {
            return <AsyncCommunityFeed history={history} />
        }
        // else if (pathname === path.forumDetails) {
        //     return <AsyncForumDetaile history={history}/>
        // }
        // else if (pathname === path.covidHelpCenter) {
        //     return <AsyncCovid19Help history={history}/>
        // } else if (pathname === path.msmeFreeService) {
        //     return <AsyncMsmeBookFreeService history={history}/>
        // }
        // else if (pathname === path.testimonials) {
        //     return <AsyncTestimonials history={history}/>
        // }
        // else if (pathname === path.wizRewards) {
        //     return <AsyncWizReward history={history}/>
        // } else if (splittedPath[1] === best && splittedPath[3] !== path.byCity) {
        //     return <Route path={`/best/:professionSlugWitCity?`}
        //                   render={(props) => <AsyncProfessionalListByProfession
        //                       {...props} />}/>
        // }
        // else if (splittedPath[1] === best && splittedPath[3] === path.byCity) {
        //     return <Route path={`/best/:professionSlug/${path.byCity}`}
        //                   render={(props) => <AsyncCityWiseProfessional
        //                       {...props} />}/>
        // }
        // else if (pathname.indexOf(path.askLegalQuestion) > -1 || pathname.indexOf(path.askTaxQuestion) > -1) {
        //     return <AsyncAskFreeQuestion history={history}/>
        // }
        // else if (pathname === path.covid19FreeSignup || pathname === path.covid19FreeSignupContentMarketing || pathname === path.covid19FreeSignupWebDev) {
        //     return <AsyncCovid19FreeConsultation history={history}/>
        // }
        // else if (pathname === path.ecomServicesLanding) {
        //     return <AsyncEcomServiceLanding history={history}/>
        // }
        // else if (pathname === path.leadThankYou) {
        //     return <AsyncLeadThankYouScreen history={history}/>
        // }
        // else if (pathname === path.generalOnboarding) {
        //     return <AsyncGeneralOnBoarding history={history}/>
        // }
    };

    renderPrimary(pathnameArr) {

        const { pathname } = this.props.location
        const { history } = this.props
        if (pathname === path.eamilConfirmed) {
            return <AsyncEmailVerificationThankYouScreen history={history} />
        }
        if (this.renderServices(pathnameArr) !== undefined) {
            if (localStorage.isSessionValid()) {
                $('body').css('background-color', '#fff')
            }
            const { isHideDefaultFooter } = this.state
            return <Fragment>
                <SiteHeader />
                <AsyncErrorBoundary>
                    {this.renderServices(pathnameArr)}
                </AsyncErrorBoundary>
                <SiteFooter isHideDefaultFooter={isHideDefaultFooter} history={history} />
            </Fragment>
        }
        if (this.renderRouteWithoutDefaultHeader(pathnameArr)) {
            if (localStorage.isSessionValid()) {
                $('body').css('background-color', '#fff')
            }
            return this.renderRouteWithoutDefaultHeader(pathnameArr)
        }
        if (helpArticlePath.indexOf(pathname) > -1) {
            return this.helpCenterArticles()
        }
        if (exploreServiceList.indexOf('/' + pathnameArr[1] + '/' + pathnameArr[2]) > -1) {
            return this.exploreServiceCategory()
        }
        if (localStorage.isSessionValid()) {
            // if ((pathname.indexOf(path.userOnboarding) > -1 && localStorage.userType === USER)) {
            //     return <AsyncOnBoarding {...this.props} type={USER}/>
            // }

            // {this.renderRouteWithoutDefaultHeader(pathnameArr)}
            // if (pathname.indexOf(path.findAProfessionalByLocation) > -1 && localStorage.userType === USER) {
            //     return <Route path={pathname} render={(props) => <AsyncFindAProfessionalByLocation {...props} />}/>
            // }
            if (pathname === `${path.userRegistration}/${path.requestFreeProposal}` || pathname === `${path.userLogin}/${path.requestFreeProposal}` || pathname === `${path.userRegistration}/${path.requestFreeProposal}`) {
                return <Redirect to={path.postAJob} />;
            } else if (pathname === path.userRegistration || pathname === `${path.userLogin}/${path.forConsultation}` || pathname === `${path.userRegistration}/${path.forConsultation}`) {
                return <Redirect to={`${path.findAProfessionalByLocation}/true`} />;
            } else if (pathname === path.professionalRegistration) {
                return <Redirect to={this.props.location.ref || path.professionalDashboard} />;
            }
            return <AsyncHome location={pathname} history={history} />;
        }

        // if ((pathname.indexOf(path.userOnboarding) > -1)) {
        //     return <AsyncOnBoarding {...this.props} type={USER}/>
        // }
        if (pathname.indexOf(path.userLogin) > -1) {
            return <Route path={`${path.userLogin}/:isRequestFreeProposal?`}
                render={(props) => <AsyncUserAndProLogIn history={history} type={USER} {...props} />} />
        } else if (pathname.indexOf(path.userRegistration) > -1) {
            localStorage.userType = USER;
            return <Route path={`${path.userRegistration}/:isRequestFreeProposal?`}
                render={(props) => <AsyncUserSignup {...props} />} />
        } else if (pathname === path.professionalRegistration) {
            localStorage.userType = PROFESSIONAL;
            return <AsyncProfessionalSignup history={history} type={PROFESSIONAL} />;
        } else if (pathname === path.professionalLogin) {
            return <AsyncUserAndProLogIn history={history} type={PROFESSIONAL} />;
        } else if (pathname === path.resetPasswordLink) {
            return <AsyncResetPasswordLink history={history} />;
        } else if (pathname.indexOf(path.confirmPassword) > -1) {
            return <Route path={`${path.confirmPassword}/:uid/:token`}
                render={(props) => <AsyncResetPassword {...props} />} />
        } else if (pathname === '/thank-you') {
            return <AsyncResetLinkSentMessages history={history} />
        }
        return (
            <Redirect
                to={{
                    pathname: path.root,
                }}
            />
        )
    }

    render() {
        const { props, state } = this
        const { loading, isAuthenticationError } = state
        const { location, history, doDispatch } = props
        const { pathname } = location
        const pathnameArr = pathname.split('/')
        if (loading || isAuthenticationError) {
            return <LogoHeader {...this.props} isLogoLinkDisabled={false} navPath={mainDomain} />
        }
        if (pathnameArr[1] === 'professional' && pathnameArr[3] === 'profile') {
            return (
                <div>
                    <Switch>
                        <Route exact path='/professional/:professionalSlug/profile'
                            render={(props) => <AsyncProfessionalPublicProfile {...props}
                                doDispatch={doDispatch} />} />
                    </Switch>
                    <Errors />
                </div>
            )
        }

        return (
            <div className='App'>
                {pathname === path.root
                    ?
                    (
                        <div>
                            <SiteHeader
                                isServicesDataPassed={false}
                                {...props}
                                history={history}
                            />
                            <AllOwlCrousel history={history} />
                            <AboutUs />
                            <SiteFooter />
                        </div>
                    ) :
                    this.renderPrimary(pathnameArr)
                }
                <Errors />
                <div id='custom-snackbar' className='custom-snackbar' />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    ...(errorActionDispatchers(dispatch)),
});

const ConnectedRoot = withRouter(connect(
    (dispatch) => ({
        doDispatch: (action) => {
            dispatch(action)
        },
    }),
    mapDispatchToProps
)(Root));

export default ConnectedRoot

const AsyncHome = Loadable(doLoad(() => import('./Home')))
const AsyncHomeLearningCenter = Loadable(doLoad(() => import('./common/HomeLearningCenter')))
const AsyncCategoryLearningCenter = Loadable(doLoad(() => import('./common/CategoryPageLearning')))
// const AsyncAskFreeQuestion = Loadable(doLoad(() => import('./common/AskFreeQuestion')))
// const AsyncProfessionalListByProfession = Loadable(doLoad(() => import('./professional/ProfessionalListByProfession')))
// const AsyncConsultationDetails = Loadable(doLoad(() => import('./user/ConsultationDetails')))
// const AsyncProductOnBoarding = Loadable(doLoad(() => import('./user/ProductOnBoarding')))
// const AsyncForum = Loadable(doLoad(() => import('./services/Forum')))
const AsyncCommunityFeed = Loadable(doLoad(() => import('./services/CommunityFeed')))
// const AsyncForumDetaile = Loadable(doLoad(() => import('./services/ForumDetaile')))
// const AsyncCovid19Help = Loadable(doLoad(() => import('./services/Covid19Help')))
// const AsyncMsmeBookFreeService = Loadable(doLoad(() => import('./services/MsmeBookFreeService')))
// const AsyncTestimonials = Loadable(doLoad(() => import('./services/Testimonials')))
// const AsyncWizReward = Loadable(doLoad(() => import('./services/WizReward')))
// const AsyncHelpCenter = Loadable(doLoad(() => import('./services/HelpCenter')))
// const AsyncArticleHome = Loadable(doLoad(() => import('./services/ArticleHome')))
// const AsyncVideosExample = Loadable(doLoad(() => import('./services/VideosExample')))
const AsyncLearningCenter = Loadable(doLoad(() => import('./common/Learningcenter')))
const AsyncPublicGigList = Loadable(doLoad(() => import('./services/PublicGigList')))
// const AsyncFindAProfessionalByLocation = Loadable(doLoad(() => import('./user/FindAProfessionalByLocation')))
// const AsyncProfessionalLandingPage = Loadable(doLoad(() => import('./professional/ProfessionalLandingPage')))
// const AsyncTermsAndCondition = Loadable(doLoad(() => import('./common/TermsAndCondition')))
// const AsyncPrivacyPolicy = Loadable(doLoad(() => import('./common/PrivacyPolicy')))
// const AsyncServiceForBrand = Loadable(doLoad(() => import('./services/ServiceForBrand')))
// const AsyncTrendingServiceList = Loadable(doLoad(() => import('./services/TrendingServiceList')))
const DesignerCategoryPackage = Loadable(doLoad(() => import('./services/DeveloperCategoryPackage')))
// const AsyncBrandServiceSummary = Loadable(doLoad(() => import('./user/BrandServiceSummary')))
// const AsyncProfessionalCustomProduct = Loadable(doLoad(() => import('./professional/ProfessionalCustomProduct')))
// const AsyncGetInTouch = Loadable(doLoad(() => import('./common/GetInTouch')))
// const AsyncAffiliateHome = Loadable(doLoad(() => import('./affiliate/AffiliateHome')))
// const AsyncProductSearchResult = Loadable(doLoad(() => import('./common/ProductSearchResult')))
const AsyncEmailVerificationThankYouScreen = Loadable(doLoad(() => import('./common/EmailVerificationThankYouScreen')))
const AsyncResetLinkSentMessages = Loadable(doLoad(() => import('./common/ResetLinkSentMessages')))
const AsyncResetPassword = Loadable(doLoad(() => import('./common/ResetPassword')))
const AsyncResetPasswordLink = Loadable(doLoad(() => import('./common/ResetPasswordLink')))
// const AsyncOnBoarding = Loadable(doLoad(() => import('./user/OnBoarding')))
const AsyncUserSignup = Loadable(doLoad(() => import('./user/UserSignUp')))
const AsyncProfessionalSignup = Loadable(doLoad(() => import('./professional/ProfessionalSignUp')))
const AsyncUserAndProLogIn = Loadable(doLoad(() => import('./common/UserAndProLogIn')))
// const AsyncVideoCallForConsultation = Loadable(doLoad(() => import("./common/VideoCallForConsultation")))
const AsyncProfessionalPublicProfile = Loadable(doLoad(() => import('./professional/ProfessionalPublicProfile')))
const AsyncLegalAndAccountingService = Loadable(doLoad(() => import('./services/LegalAndAccountingService')))
// const AsyncGeneralOnBoarding = Loadable(doLoad(() => import('./user/GeneralOnboarding')))
// const AsyncEcomServiceLanding = Loadable(doLoad(() => import('./common/EcomServiceLanding')))
// const AsyncCovid19FreeConsultation = Loadable(doLoad(() => import('./common/Covid19FreeConsultation')))
// const AsyncLeadThankYouScreen = Loadable(doLoad(() => import('./common/LeadThankYouScreen')))
// const AsyncCommonStandardPackages = Loadable(doLoad(() => import('./services/CommonStandardPackages')))
const AsyncHelpArticle = Loadable(doLoad(() => import('./services/HelpArticle')))
const AsyncSubCategoryServices = Loadable(doLoad(() => import('./services/SubCategoryServices')))
const AsyncAgoraAudioVideoCall = Loadable(doLoad(() => import('./common/AgoraAudioVideoCall')))
const AsyncCommunity = Loadable(doLoad(() => import('./common/Community')))
const AsyncErrorBoundary = Loadable(doLoad(() => import('./common/ErrorBoundary')))
const AsyncProfessionalGigByCity = Loadable(doLoad(() => import('./common/ProfessionalGigByCity')))
const AsyncLanguageDeveloperProfessionalList = Loadable(doLoad(() => import('./common/LanguageDeveloperProfessionalList')))
const AsyncHire = Loadable(doLoad(() => import('./common/Hire')))
const AsyncLanguageAndPlatformList = Loadable(doLoad(() => import('./common/LanguageAndPlatformList')))
const AsyncLanguageDeveloperByCity = Loadable(doLoad(() => import('./common/LanguageDeveloperByCity')))
// const AsyncCityWiseProfessional = Loadable(doLoad(() => import('./common/CityWiseProfessional')))
const AsyncDefaultGigDetails = Loadable(doLoad(() => import('./services/DefaultGigDetails')))
const AsyncCategoryServices = Loadable(doLoad(() => import('./services/CategoryServices')))


