// import React, {Component, Fragment} from "react";
// import {apiv1, apiv2} from "../../api";
// import {
//     _active,
//     _base64,
//     _preview,
//     avg_price_const,
//     delivery_time_const,
//     deliveryDay,
//     description_const,
//     imageOrVideo,
//     iWillText,
//     NONE,
//     noOfExternalLink,
//     noOfSamples,
//     price_const,
//     priceType,
//     sample, video_gig_convince,
//     videoType,
//     is, _standard,
//     _premium,
//     imageOrVideoOrDoc,
// } from "./DesignerSingleProduct"
// import queryString from "query-string"
// import * as apiUrl from './../../api/apiUrls'
// import {htmlToString} from "../../functions/htmlToString"
// import LoadingComponent from "../../utils/LoadingComponent"
// import * as path from "../../constants/path"
// import Dropzone from "react-dropzone"
// import {allowedDocumentType, allowedImageType, allowedVideoType} from "./ProfessionalProfile"
// import RadioGroup from '@material-ui/core/RadioGroup'
// import Radio from '@material-ui/core/Radio';
// import FormControlLabel from '@material-ui/core/FormControlLabel'
// import {
//     // checkPortfolioLinks,
//     industryValidation,
//     quality_check_question,
//     validateDesignerProduct,
//     validateWhatsIncluded
// } from "../common/validation/FormValidator"
// import axios from "axios"
// import {updateProfessionalKey} from "../../actions/auth"
// import {connect} from "react-redux"
// import {errorActionDispatchers} from "../../actions/errors"
// import {mainDomain, media_type} from './../../constants/common'
// import {showSnackbar} from "../functional/afterHire"
// import Checkbox from '@material-ui/core/Checkbox'
// import {confirmAlert} from "react-confirm-alert"
// import localStorage from './../../utils/localStorage'
// import {freelancers} from "../../constants/keyWord"
// import {
//     parentPracticeAreaSlug,
//     webDevelopmentPractiseArea,
//     MarketingPractiseArea
// } from "../common/bannerTextForProducts"
// import $ from "jquery"
// import nl2br from "react-newline-to-break"
// import {GigGalleryExample} from "./ProfessionalBriefBio";
// import {CustomToolTrip} from "../../functions/common";

export const tabs = [
    {
        tab_name: 'Gig Title & Proof of work',
        tab_id: 'title_and_desc',

    },
    {
        tab_name: 'Gig Gallery',
        tab_id: 'public_portfolio',
    },
    {
        tab_name: 'Pricing & Packages',
        tab_id: 'pricing',
    },
    // {
    //     tab_name: 'Non-Public Portfolio',
    //     tab_id: 'none_public_portfolio',
    // },
    {
        tab_name: 'Submit for approval',
        tab_id: 'submit',
    },
]

// class CreateDefaultGig extends Component {
//     constructor(props) {
//         super(props)
//         let {selectedProduct, is_super_user, history, hasGst} = props
//         const {location} = history
//         const {search} = location
//         this.api = is_super_user ? apiv2 : apiv1
//         let {product, professionalProduct, title} = queryString.parse(search)
//         professionalProduct = professionalProduct || ''
//         product = product || ''
//         selectedProduct = selectedProduct || {};
//         let portfolioLinks = [];
//         let links_private = []
//         const {
//             package_descriptions,
//             price_description,
//             delivery_time_description,
//             is_package_active_keys_data
//         } = this.returnPriceAndDeliveryTime({selectedProduct: {}})
//
//         this.state = {
//             selectedProduct,
//             professionalProduct,
//             loading: false,
//             professional_description: '',
//             professional_description_detailed: '',
//             ...package_descriptions,
//             ...price_description,
//             ...delivery_time_description,
//             ...is_package_active_keys_data,
//             portfolioLinks,
//             links_private,
//             product_details: {name: title},
//             copy_of_product_details: {},
//             errors: {},
//             product,
//             isProductVerified: false,
//             whatsIncludedQuestionAnswer: [],
//             samples_migrated: [],
//             samples_private: [],
//             convincing_video: [],
//             gigNonPublicQuestions: [],
//             industryList: [],
//             frameworkList: [],
//             professional_details: {},
//             hasGst,
//             proof_of_work_obj: this.getProofOfWorkObj(),
//             proof_of_work_validator_obj: {},
//             proof_of_work_placeholder_obj: {},
//             tutorial: [],
//             links_with_industry: []
//         }
//     }
//
//     componentDidMount() {
//         const {professionalProduct} = this.state
//         if (professionalProduct) {
//             this.getPracticeAreaSelectedPrice(professionalProduct)
//         } else {
//             this.getProductDetails()
//             this.getGigQuestion()
//         }
//         this.getNonPublicGigQuestion()
//     }
//
//     getProofOfWorkObj = (obj) => {
//         let {
//             client_name,
//             industry,
//             client_phone,
//             link,
//             client_email,
//             framework,
//             subject_expertise_level,
//             type_of_video,
//             is_public,
//             doc_type,
//             doc,
//             doc_id,
//             doc_name
//         } = obj || {}
//         client_name = client_name || ''
//         industry = industry || ''
//         client_phone = client_phone || ''
//         link = link || ''
//         // screenshot = screenshot || ''
//         client_email = client_email || ''
//         framework = framework || ''
//         subject_expertise_level = subject_expertise_level || ''
//         type_of_video = type_of_video || ''
//         is_public = is_public || false
//         doc = doc || ''
//         doc_type = doc_type || ''
//         doc_name = doc_name || ''
//         return {
//             client_name,
//             industry,
//             client_phone,
//             link,
//             // screenshot,
//             client_email,
//             framework,
//             subject_expertise_level,
//             type_of_video,
//             is_public,
//             doc_type,
//             doc_id,
//             doc_name,
//             doc
//         }
//     }
//
//     onChangeIndustryInput = (e) => {
//         let proof_of_work_obj = {...this.state.proof_of_work_obj}
//         const {name, value} = e.target
//         let errors = {...this.state.errors}
//         errors[name] = ''
//         proof_of_work_obj = {
//             ...proof_of_work_obj,
//             [name]: value
//         }
//         this.setState({proof_of_work_obj, errors})
//     }
//
//     uploadDocsForIndustryUi = (doc) => {
//         let {proof_of_work_placeholder_obj, proof_of_work_obj, proof_of_work_validator_obj} = this.state
//         const {allowed_doc_type} = proof_of_work_validator_obj
//         const {doc_placeholder} = proof_of_work_placeholder_obj
//         const {doc_name} = proof_of_work_obj
//         return (
//             <div className="dropzone dropzone-doc">
//                 <Dropzone
//                     className='dropzone'
//                     activeClassName='active-dropzone'
//                     onDrop={(files) => {
//                         const event = {
//                             sampleBase64: '',
//                             sampleFileName: '',
//                             can_save: imageOrVideoOrDoc,
//                         }
//                         this.handleDrop({
//                             files,
//                             event,
//                         })
//                     }}
//                     multiple={false}
//                 >
//                     {({getRootProps, getInputProps}) => (
//                         <div {...getRootProps()}>
//                             <input {...getInputProps()}
//                                    accept={`${allowed_doc_type ? allowed_doc_type : `image/*, video/*`}`}
//                             />
//                             {(doc) ?
//                                 <Fragment>
//                                     {/*{is_video ?*/}
//                                     {/*<video controls>*/}
//                                     {/*<source src={doc}*/}
//                                     {/*type="video/mp4"/>*/}
//                                     {/*</video>*/}
//                                     {/*:*/}
//                                     {/*<img src={doc} alt={doc}/>*/}
//                                     {/*}*/}
//                                     {doc_name}
//                                 </Fragment>
//                                 :
//                                 doc_placeholder || 'Upload Design ( PNG, JPEG )'
//                             }
//                         </div>
//                     )}
//                 </Dropzone>
//             </div>
//         )
//     }
//
//     proofOfWorkUi = () => {
//         let {
//             industryList,
//             frameworkList,
//             proof_of_work_obj,
//             proof_of_work_validator_obj,
//             links_with_industry,
//             errors,
//             product_details,
//             loading,
//             proof_of_work_placeholder_obj
//         } = this.state
//         const {links_required, practise_area} = product_details
//         const {
//             client_name,
//             industry,
//             link,
//             doc,
//             client_email,
//             is_proof_of_work_editing,
//             mediaPreview,
//             framework,
//             is_public,
//             subject_expertise_level,
//             type_of_video,
//             doc_name
//         } = proof_of_work_obj
//         const {
//             is_industry_required,
//             is_client_email_required,
//             is_link_required,
//             is_client_name_required,
//             is_doc_required,
//             is_framework_required,
//             is_subject_expertise_level_required,
//             is_type_of_video_required,
//             is_link_optional,
//             is_client_email_optional
//         } = proof_of_work_validator_obj
//         const {
//             framework_placeholder,
//             industry_placeholder,
//             link_placeholder,
//             subject_expertise_level_placeholder,
//             type_of_video_placeholder
//         } = proof_of_work_placeholder_obj
//         return (
//             <Fragment>
//                 <h4 className="d-profile-heading">Proof of work & Portfolio</h4>
//                 <p className="para" style={{color: '#ff337d'}}>*Atleast {links_required} proof of work are compulsory.
//                     You can add up to 8.</p>
//                 <div className="expert-reviews-added-list" id='add_proof_of_work'
//                 >
//                     <div className="row">
//                         {is_framework_required &&
//                         <div className="col-sm-6">
//                             <div className="form-group">
//                                 <select className="form-control input-lg"
//                                         name="framework"
//                                         id='framework'
//                                         placeholder={framework_placeholder || 'Select Framework'}
//                                         onChange={this.onChangeIndustryInput}
//                                 >
//                                     <option value={''} disabled={true}
//                                             selected={true}>{framework_placeholder || 'Select Framework'}</option>
//                                     {frameworkList.map(details => {
//                                         const {option} = details
//                                         return <option value={option}
//                                                        key={option}
//                                                        selected={option === framework}
//                                         >{option}</option>
//                                     })}
//                                 </select>
//                                 {errors[`framework`] && <span className='error'>{errors[`framework`]}</span>}
//                             </div>
//                         </div>
//                         }
//                         {is_industry_required &&
//                         <div className="col-sm-6">
//                             <div className="form-group">
//                                 <select className="form-control input-lg"
//                                         name="industry"
//                                         id='industry'
//                                         onChange={this.onChangeIndustryInput}
//                                 >
//                                     <option value={''} disabled={true}
//                                             selected={true}>{industry_placeholder || 'Select Industry to which project belongs'}</option>
//                                     {industryList.map(industry => {
//                                         return <option value={industry}
//                                                        key={industry}
//                                                        selected={industry === proof_of_work_obj.industry}
//                                         >{industry}</option>
//                                     })}
//                                 </select>
//                                 {errors[`industry`] && <span className='error'>{errors[`industry`]}</span>}
//                             </div>
//                         </div>
//                         }
//                         {is_subject_expertise_level_required &&
//                         <div className="col-sm-6">
//                             <div className="form-group">
//                                 <select className="form-control input-lg"
//                                         name="subject_expertise_level"
//                                         id='subject_expertise_level'
//                                         placeholder={subject_expertise_level || 'Subject Expertise Level'}
//                                         onChange={this.onChangeIndustryInput}
//                                 >
//                                     <option value={''} disabled={true}
//                                             selected={true}>{subject_expertise_level_placeholder || 'Subject Expertise Level'}</option>
//                                     {subjectExpertiseLevel.map(level => {
//                                         return <option value={level}
//                                                        key={level}
//                                                        selected={level === subject_expertise_level}
//                                         >{level}</option>
//                                     })}
//                                 </select>
//                                 {errors[`subject_expertise_level`] &&
//                                 <span className='error'>{errors[`subject_expertise_level`]}</span>}
//                             </div>
//                         </div>
//                         }
//                         {is_type_of_video_required &&
//                         <div className="col-sm-6">
//                             <div className="form-group">
//                                 <select className="form-control input-lg"
//                                         name="type_of_video"
//                                         id='type_of_video'
//                                         placeholder={type_of_video_placeholder || 'Subject Expertise Level'}
//                                         onChange={this.onChangeIndustryInput}
//                                 >
//                                     <option value={''} disabled={true}
//                                             selected={true}>{type_of_video_placeholder || 'Type of Video'}</option>
//                                     {typeOfVideo.map(video_type => {
//                                         return <option value={video_type}
//                                                        key={video_type}
//                                                        selected={video_type === type_of_video}
//                                         >{video_type}</option>
//                                     })}
//                                 </select>
//                                 {errors[`type_of_video`] && <span className='error'>{errors[`type_of_video`]}</span>}
//                             </div>
//                         </div>
//                         }
//                         {(is_link_required || is_link_optional) &&
//                         <div className="col-sm-6">
//                             <div className="form-group">
//                                 <input type="text"
//                                        className="form-control input-lg"
//                                        placeholder={link_placeholder || 'Link'}
//                                        name="link"
//                                        value={link}
//                                        onChange={this.onChangeIndustryInput}
//                                 />
//                                 {errors[`link`] && <span className='error'>{errors[`link`]}</span>}
//                             </div>
//                         </div>
//                         }
//                         {is_doc_required &&
//                         <div className="col-sm-6">
//                             <div className="form-group">
//                                 {this.uploadDocsForIndustryUi(mediaPreview ? mediaPreview : doc)}
//                                 {errors[`doc`] && <span className='error'>{errors[`doc`]}</span>}
//                             </div>
//                         </div>
//                         }
//                         {is_client_name_required &&
//                         <div className="col-sm-6">
//                             <div className="form-group">
//                                 <input type="text"
//                                        className="form-control input-lg"
//                                        placeholder={`Client/Brand Name`}
//                                        name="client_name"
//                                        value={client_name}
//                                        onChange={this.onChangeIndustryInput}
//                                 />
//                                 {errors[`client_name`] && <span className='error'>{errors[`client_name`]}</span>}
//                             </div>
//                         </div>
//                         }
//                         {(is_client_email_required || is_client_email_optional) &&
//                         <div className="col-sm-6">
//                             <div className="form-group">
//                                 <input type="text"
//                                        className="form-control input-lg"
//                                        placeholder={`Client Email (Optional)`}
//                                        name="client_email"
//                                        value={client_email}
//                                        onChange={this.onChangeIndustryInput}
//                                 />
//                                 {errors[`client_email`] && <span className='error'>{errors[`client_email`]}</span>}
//                             </div>
//                         </div>
//                         }
//                         <div className="clearfix"/>
//                         {webDevelopmentPractiseArea.indexOf(practise_area) > -1 &&
//                         <div className="col-sm-12">
//                             <p className="text-note">*Most Developers fake their portfolio with fake screenshots or case
//                                 studies therefore we verify at times to check authenticity and avoid scam gigs</p>
//                         </div>
//                         }
//                         {MarketingPractiseArea.indexOf(practise_area) > -1 &&
//                         <div className="col-sm-12">
//                             <p className="text-note">*Most Marketers fake their portfolio with fake screenshots or case
//                                 studies therefore we verify at times to check authenticity and avoid scam gigs</p>
//                         </div>
//                         }
//                         <div className="col-sm-12">
//                             <div className='checkbox'>
//                                 <label style={{fontSize: '16px'}}>
//                                     <input type="checkbox"
//                                            name='is_public'
//                                            checked={is_public}
//                                            onChange={(e) => {
//                                                const {name, checked} = e.target
//                                                this.onChangeIndustryInput(e = {
//                                                    ...e,
//                                                    target: {
//                                                        ...e.target,
//                                                        value: checked,
//                                                        name: name
//                                                    }
//                                                })
//                                            }}
//                                     />
//                                     <span className='cr'>
//                             <i className='cr-icon glyphicon-ok glyphicon'/>
//                             </span>
//                                     Make this portfolio public to get more leads and orders.
//                                 </label>
//                             </div>
//                             <div className="add-work-footer">
//                                 <button
//                                     disabled={loading}
//                                     className="btn btn-add-proof"
//                                     onClick={() => {
//                                         // let {links_with_industry} = selectedProduct
//                                         let links_with_industry = [...this.state.links_with_industry] || []
//                                         let proof_of_work_obj = {...this.state.proof_of_work_obj}
//                                         const {is_proof_of_work_editing, index} = proof_of_work_obj
//                                         let isValidProofOfWork = industryValidation({
//                                             request: proof_of_work_obj,
//                                             errors,
//                                             validatorObj: proof_of_work_validator_obj
//                                         })
//                                         let newState = {}
//                                         if (isValidProofOfWork) {
//                                             if (proof_of_work_obj.mediaPreview) {
//                                                 // this.uploadIndustrySample()
//                                             } else {
//                                                 let validProofOfWorkObj = this.getProofOfWorkObj(proof_of_work_obj)
//                                                 if (is_proof_of_work_editing) {
//                                                     links_with_industry[index] = {
//                                                         ...validProofOfWorkObj,
//                                                     }
//                                                 } else {
//                                                     let validProofOfWorkObj = this.getProofOfWorkObj(proof_of_work_obj)
//                                                     links_with_industry.push({...validProofOfWorkObj})
//                                                 }
//                                                 proof_of_work_obj = this.getProofOfWorkObj()
//                                                 newState = {
//                                                     ...newState,
//                                                     links_with_industry,
//                                                     proof_of_work_obj
//                                                 }
//                                             }
//                                         } else {
//                                             newState = {
//                                                 ...newState,
//                                                 errors
//                                             }
//                                         }
//                                         this.setState(newState, () => {
//                                             if (isValidProofOfWork && (is_proof_of_work_editing || proof_of_work_obj.mediaPreview)) {
//                                                 this.saveProduct({is_desc: true, is_adding_proof_of_work: true})
//                                             }
//                                         })
//                                     }}
//                                 >{is_proof_of_work_editing ? 'Update' : 'Add Proof Of Work'}</button>
//                                 {/*<span>Please make sure all information is correct for Gig Approval</span>*/}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 {links_with_industry.length > 0 &&
//                 <Fragment>
//                     <p>&#x00A0;</p>
//                     <h4 className="d-profile-heading">Proof of work & portfolio added by you</h4>
//                     {links_with_industry.map((details, index) => {
//                         const {
//                             industry,
//                             link,
//                             client_name,
//                             client_email,
//                             doc,
//                             framework,
//                             type_of_video,
//                             subject_expertise_level,
//                             doc_type,
//                             doc_name
//                         } = details
//                         return <div className="expert-reviews-added-list">
//                             <div className="row display-flex">
//                                 <div className="col-sm-8">
//                                     {is_framework_required &&
//                                     <ul className="list-unstyled added-work-list">
//                                         <li>Framework:</li>
//                                         <li>{framework}</li>
//                                     </ul>
//                                     }
//                                     {is_industry_required &&
//                                     <ul className="list-unstyled added-work-list">
//                                         <li>Industry:</li>
//                                         <li>{industry}</li>
//                                     </ul>
//                                     }
//                                     {is_subject_expertise_level_required &&
//                                     <ul className="list-unstyled added-work-list">
//                                         <li>Subject Expertise Level:</li>
//                                         <li>{subject_expertise_level}</li>
//                                     </ul>
//                                     }
//                                     {is_type_of_video_required &&
//                                     <ul className="list-unstyled added-work-list">
//                                         <li>Type of video:</li>
//                                         <li>{type_of_video}</li>
//                                     </ul>
//                                     }
//                                     {(is_link_required || is_link_optional) &&
//                                     <ul className="list-unstyled added-work-list">
//                                         <li>Link:</li>
//                                         <li>{link ? link : 'NA'}</li>
//                                     </ul>
//                                     }
//                                     {is_client_name_required &&
//                                     <ul className="list-unstyled added-work-list">
//                                         <li>Client Name:</li>
//                                         <li>{client_name}</li>
//                                     </ul>
//                                     }
//                                     {(is_client_email_required || is_client_email_optional) &&
//                                     <ul className="list-unstyled added-work-list">
//                                         <li>Client Email:</li>
//                                         <li>{client_email}</li>
//                                     </ul>
//                                     }
//                                     <button
//                                         className='btn btn-save'
//                                         style={{marginTop: '10px', padding: '6px 20px'}}
//                                         onClick={() => {
//                                             let proof_of_work_obj = this.getProofOfWorkObj(details)
//                                             proof_of_work_obj = {
//                                                 ...proof_of_work_obj,
//                                                 is_proof_of_work_editing: true,
//                                                 index
//                                             }
//                                             $('html, body').animate({
//                                                 scrollTop: $("#add_proof_of_work").offset().top - 70
//                                             }, 200);
//                                             this.setState({proof_of_work_obj, errors: {}})
//                                         }
//                                         }
//                                     >Edit
//                                     </button>
//                                 </div>
//                                 <div className="col-sm-4" style={{marginBottom: '15px'}}>
//                                     <p className="para" style={{fontSize: '14px'}}><b>Attachment</b></p>
//                                     {is_doc_required &&
//                                     <Fragment>
//                                         <p>
//                                             <a href={doc} target='_blank' style={{color: '#ff337d'}}>
//                                                 <CustomToolTrip text={doc_name} maxCharLength={30}/>
//                                             </a>
//                                         </p>
//                                         {doc_type === media_type.image &&
//                                         <img src={doc} alt={doc}
//                                              style={{width: '130px'}}
//                                         />
//                                         }
//                                         {doc_type === media_type.video &&
//                                         <video controls
//                                                style={{width: '200px'}}
//                                         >
//                                             <source src={doc}
//                                                     type="video/mp4"/>
//                                         </video>
//                                         }
//                                         {(doc_type === media_type.pdf || doc_type === media_type.docx) &&
//                                         <iframe src={doc} width={`100%`} height={`100%`}/>
//                                         }
//                                     </Fragment>
//                                     }
//                                 </div>
//                             </div>
//                         </div>
//                     })}
//                 </Fragment>
//                 }
//             </Fragment>
//         )
//     }
//
//
//     getProductDetails = () => {
//         const {product} = this.state
//         this.setState({loading: true})
//         this.api.auth.get(`${apiUrl.productList}${product}/`)
//             .then(res => {
//                 const data = apiv1.parseAndAutoHandleIssues(res, this.props.doDispatch);
//                 if (data) {
//                     const {practise_area} = data
//                     let {
//                         default_gig_creation_validator,
//                         default_gig_place_holder,
//                         tutorial
//                     } = this.getValidatorObj({practise_area})
//                     let proof_of_work_validator_obj = default_gig_creation_validator
//                     let proof_of_work_placeholder_obj = default_gig_place_holder
//                     tutorial = tutorial || []
//                     this.setState({
//                         loading: false,
//                         product_details: data,
//                         proof_of_work_validator_obj,
//                         tutorial,
//                         proof_of_work_placeholder_obj
//                     }, () => {
//                         const {links_required} = data
//                         if (links_required) {
//                             const {is_framework_required, is_industry_required} = proof_of_work_validator_obj
//                             if (is_industry_required) {
//                                 this.getIndustriesByProduct()
//                             }
//                             if (is_framework_required) {
//                                 this.getFramework()
//                             }
//                         }
//                     })
//                 } else {
//                     this.setState({loading: false})
//                 }
//             })
//             .catch(err => {
//                 this.setState({loading: false});
//                 apiv1.handleErrors(err, this.props.doDispatch)
//             })
//     }
//
//     // getProfessionalProfession = () => {
//     //     const {product} = this.state
//     //     this.setState({loading: true})
//     //     this.api.auth.get(`${apiUrl.professionalProfession}`)
//     //         .then(res => {
//     //             const data = apiv1.parseAndAutoHandleIssues(res, this.props.doDispatch);
//     //             if (data) {
//     //                 this.setState({loading: false, professionalProfession: data})
//     //             } else {
//     //                 this.setState({loading: false})
//     //             }
//     //         })
//     //         .catch(err => {
//     //             this.setState({loading: false});
//     //             apiv1.handleErrors(err, this.props.doDispatch)
//     //         })
//     // }
//
//
//     // getPortfolioLinkCheck = () => {
//     //     const {product} = this.state
//     //     this.setState({loading: true})
//     //     this.api.auth.get(`${apiUrl.portfolioLinkCheck}?product_id=${product}`)
//     //         .then(res => {
//     //             const data = apiv1.parseAndAutoHandleIssues(res, this.props.doDispatch);
//     //             if (data) {
//     //                 this.setState({loading: false, professionalProfession: data.product_profession})
//     //             } else {
//     //                 this.setState({loading: false})
//     //             }
//     //         })
//     //         .catch(err => {
//     //             this.setState({loading: false});
//     //             apiv1.handleErrors(err, this.props.doDispatch)
//     //         })
//     // }
//
//
//     submitForApprovalProduct = (professionalProduct, request) => {
//         const {doDispatch, history} = this.props
//         this.setState({loading: true})
//         apiv1.auth.patch(`users/professional-product/${professionalProduct}/`, request)
//             .then(res => {
//                 const data = apiv1.parseAndAutoHandleIssues(res, doDispatch);
//                 if (data) {
//                     showSnackbar({message: 'Submitted'})
//                     history.goBack()
//                     this.setState({loading: false})
//                 } else {
//                     this.setState({loading: false})
//                 }
//             })
//             .catch(err => {
//                 this.setState({loading: false})
//                 apiv1.handleErrors(err, doDispatch)
//             })
//     }
//
//     returnPriceAndDeliveryTime = ({selectedProduct}) => {
//         let {product_details} = selectedProduct || {}
//         product_details = product_details || {}
//         let copy_of_product_details = {
//             ...product_details
//         }
//         let package_descriptions = {}
//         let avg_price_data = {}
//         let price_description = {}
//         let delivery_time_description = {}
//         let is_package_active_keys_data = {}
//         priceType.forEach((priceDetails, index) => {
//             const {price_type, delivery_type} = priceDetails
//             let packageDescriptionKey = `${description_const}${price_type}`
//             let avgPriceKey = `${avg_price_const}${price_type}`
//             let deliveryTimeKey = `${delivery_time_const}${delivery_type}`
//             let priceKey = `${price_const}${price_type}`
//             let is_package_active_key = `${is}${price_type}${_active}`
//             copy_of_product_details[packageDescriptionKey] = htmlToString(product_details[packageDescriptionKey]) || ''
//             copy_of_product_details[avgPriceKey] = product_details[avgPriceKey] || ''
//             avg_price_data[avgPriceKey] = copy_of_product_details[avgPriceKey] || ''
//             package_descriptions[packageDescriptionKey] = copy_of_product_details[packageDescriptionKey] || ''
//             if (index > 0) {
//                 is_package_active_keys_data[is_package_active_key] = selectedProduct[is_package_active_key] !== undefined ? selectedProduct[is_package_active_key] : true
//             }
//             price_description[priceKey] = parseInt(selectedProduct[priceKey]) || ''
//             delivery_time_description[deliveryTimeKey] = selectedProduct[deliveryTimeKey] || ''
//         })
//         return {
//             copy_of_product_details,
//             package_descriptions,
//             avg_price_data,
//             price_description,
//             delivery_time_description,
//             is_package_active_keys_data,
//         }
//     }
//
//     getValidatorObj = ({practise_area}) => {
//         let {default_gig_creation_validator, default_gig_place_holder, tutorial} = parentPracticeAreaSlug[practise_area]
//         default_gig_creation_validator = default_gig_creation_validator || {}
//         default_gig_place_holder = default_gig_place_holder || {}
//         tutorial = tutorial || []
//         return {
//             default_gig_creation_validator,
//             default_gig_place_holder,
//             tutorial
//         }
//     }
//
//     formattedProfessionalProductData = ({selectedProduct}) => {
//         let {
//             id,
//             verified,
//             professional_description,
//             professional_description_detailed,
//             product_details,
//             product,
//             samples_migrated,
//             links,
//             links_private,
//             professional_details,
//             samples_private,
//             links_with_industry
//         } = selectedProduct || {}
//         samples_migrated = samples_migrated || []
//         samples_private = samples_private || []
//         links_with_industry = links_with_industry || []
//         let convincing_video = samples_migrated.filter(sampleDetails => sampleDetails.sample_type === video_gig_convince)
//         let proof_of_work_validator_obj = {}
//         let proof_of_work_placeholder_obj = {}
//         let _tutorial = []
//         samples_migrated = samples_migrated.filter(sampleDetails => sampleDetails.sample_type !== video_gig_convince)
//         links = links || []
//         links_private = links_private || []
//         links = links.map(link => {
//             return {
//                 link
//             }
//         })
//         links_private = links_private.map(link => {
//             return {
//                 link
//             }
//         })
//         let portfolioLinks = links
//         const {
//             copy_of_product_details,
//             package_descriptions,
//             avg_price_data,
//             price_description,
//             delivery_time_description,
//             is_package_active_keys_data
//         } = this.returnPriceAndDeliveryTime({selectedProduct})
//         professional_description = professional_description ? professional_description.replace(`${iWillText} `, '') : ''
//         professional_description_detailed = professional_description_detailed || ''
//         if (product_details.id) {
//             const {practise_area} = product_details
//             let {
//                 default_gig_creation_validator,
//                 default_gig_place_holder,
//                 tutorial
//             } = this.getValidatorObj({practise_area})
//             proof_of_work_validator_obj = default_gig_creation_validator
//             proof_of_work_placeholder_obj = default_gig_place_holder
//             _tutorial = tutorial || []
//         }
//         return {
//             professional_description,
//             professional_description_detailed,
//             selectedProduct,
//             product_details,
//             copy_of_product_details,
//             ...price_description,
//             ...package_descriptions,
//             ...avg_price_data,
//             ...delivery_time_description,
//             ...is_package_active_keys_data,
//             portfolioLinks,
//             links_private,
//             loading: false,
//             errors: {},
//             professionalProduct: id,
//             product,
//             isProductVerified: verified,
//             samples_migrated,
//             samples_private,
//             convincing_video,
//             professional_details,
//             links_with_industry,
//             proof_of_work_validator_obj,
//             proof_of_work_placeholder_obj,
//             tutorial: _tutorial
//         }
//     }
//
//     getNonPublicGigQuestion = () => {
//         const {product} = this.state
//         this.api.auth.get(`${apiUrl.gigPrivateQuestion}?product=${product}`)
//             .then(resp => {
//                 const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch)
//                 if (data) {
//                     let errors = {...this.state.errors}
//                     let gigNonPublicQuestions = data.map(questionDetails => {
//                         let {gig_question_answer} = questionDetails
//                         gig_question_answer = gig_question_answer || {}
//                         return {
//                             ...questionDetails,
//                             gig_question_answer
//                         }
//                     })
//                     this.setState({gigNonPublicQuestions, loading: false, errors})
//                 } else {
//                     this.setState({loading: false})
//                 }
//             })
//             .catch(err => {
//                 this.setState({loading: false});
//                 apiv1.handleErrors(err, this.props.doDispatch)
//             })
//     }
//
//     getGigQuestion = () => {
//         const {product} = this.state
//         this.api.auth.get(`${apiUrl.getProductIncludedQuestion}${product}`)
//             .then(resp => {
//                 const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch)
//                 if (data) {
//                     let errors = {...this.state.errors}
//                     let whatsIncludedQuestionAnswer = data.map(details => {
//                         let {answer, type, text, id} = details
//                         answer = answer.map(ans => {
//                             let selected_ans = type === multi ? ans.split(',') : [ans]
//                             selected_ans = parseInt(selected_ans[0]) === 0 ? [] : selected_ans
//                             return {
//                                 selected_ans
//                             }
//                         })
//                         return {
//                             ...details,
//                             answer,
//                             is_whats_included: text === whatsInclude,
//                             is_does_not_include: text === doesNotInclude,
//                             mandatory: (text !== whatsInclude && text !== doesNotInclude)
//                         }
//                     })
//                     this.setState({whatsIncludedQuestionAnswer, loading: false, errors})
//                 } else {
//                     this.setState({loading: false})
//                 }
//             })
//             .catch(err => {
//                 this.setState({loading: false});
//                 apiv1.handleErrors(err, this.props.doDispatch)
//             })
//     }
//
//     getIndustriesByProduct = () => {
//         const {product} = this.state
//         this.setState({loading: true})
//         this.api.auth.get(`${apiUrl.getIndustryByProduct}${product}`)
//             .then(resp => {
//                 let data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch)
//                 if (data) {
//                     data = data.sort(function (a, b) {
//                         return a < b ? -1 : 1
//                     })
//                     this.setState({industryList: data})
//                 } else {
//                     this.setState({loading: false})
//                 }
//             })
//             .catch(err => {
//                 this.setState({loading: false});
//                 apiv1.handleErrors(err, this.props.doDispatch)
//             })
//
//     }
//
//     getFramework = () => {
//         const {product_details} = this.state
//         const {slug_new} = product_details
//         this.setState({loading: true})
//         this.api.auth.get(`${apiUrl.languageDeveloperGig}/${slug_new}`)
//             .then(resp => {
//                 const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch)
//                 if (data) {
//                     let {service_options} = data
//                     if (service_options === true) {
//                         service_options = []
//                     }
//                     service_options = service_options.sort(function (a, b) {
//                         return a.option < b.option ? -1 : 1
//                     })
//                     this.setState({frameworkList: service_options})
//                 } else {
//                     this.setState({loading: false})
//                 }
//             })
//             .catch(err => {
//                 this.setState({loading: false});
//                 apiv1.handleErrors(err, this.props.doDispatch)
//             })
//
//     }
//
//     getPracticeAreaSelectedPrice = (professionalProduct) => {
//         this.setState({loading: true})
//         this.api.auth.get(`${apiUrl.professionalProduct}${professionalProduct}`)
//             .then(resp => {
//                 const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch)
//                 if (data) {
//                     const newState = this.formattedProfessionalProductData({selectedProduct: data})
//                     this.setState(newState, () => {
//                         const {product_details, proof_of_work_validator_obj} = newState
//                         const {links_required} = product_details
//                         if (links_required) {
//                             const {is_framework_required, is_industry_required} = proof_of_work_validator_obj
//                             if (is_industry_required) {
//                                 this.getIndustriesByProduct()
//                             }
//                             if (is_framework_required) {
//                                 this.getFramework()
//                             }
//                         }
//                         this.getGigQuestion()
//                     })
//                 } else {
//                     this.setState({loading: false})
//                 }
//             })
//             .catch(err => {
//                 this.setState({loading: false});
//                 apiv1.handleErrors(err, this.props.doDispatch)
//             })
//
//     }
//
//
//     migrateSamples = () => {
//         let {professionalProduct} = this.state
//         this.api.auth.get(`${apiUrl.professionalProductSamples}${professionalProduct}`)
//             .then(resp => {
//
//             })
//             .catch(err => {
//
//             })
//     }
//
//     uploadIndustrySample = () => {
//         let proof_of_work_obj = {...this.state.proof_of_work_obj}
//         const {document_file_name, sampleBase64, mediaType} = proof_of_work_obj
//         let request = {
//             document_file_name,
//             document_base64: sampleBase64,
//         }
//         if (mediaType) {
//             request = {
//                 ...request,
//                 sample_type: mediaType,
//             }
//         }
//         this.setState({loading: true})
//         this.api.auth.post(`${apiUrl.customProductSample}`, request)
//             .then(resp => {
//                 const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch)
//                 if (data) {
//                     const {document, id, document_file_name} = data
//                     const {is_proof_of_work_editing, index} = proof_of_work_obj
//                     let links_with_industry = [...this.state.links_with_industry]
//                     proof_of_work_obj = {
//                         ...proof_of_work_obj,
//                         doc: document,
//                         doc_id: id,
//                         doc_name: document_file_name
//                     }
//                     let newProofOfWorkObj = this.getProofOfWorkObj(proof_of_work_obj)
//                     if (is_proof_of_work_editing) {
//                         links_with_industry[index] = {
//                             ...newProofOfWorkObj,
//                         }
//                     } else {
//                         links_with_industry.push({...newProofOfWorkObj})
//                     }
//                     this.setState({links_with_industry, proof_of_work_obj: newProofOfWorkObj}, () => {
//                         this.saveProduct({
//                             is_desc: true,
//                             is_from_upload_doc_of_proof_of_work: true,
//                             is_adding_proof_of_work: true
//                         })
//                     })
//                 } else {
//                     this.setState({loading: false})
//                 }
//             })
//             .catch(err => {
//                 this.setState({loading: false});
//                 apiv1.handleErrors(err, this.props.doDispatch)
//             })
//     }
//
//     uploadSamples = ({sampleId, sampleIndex, is_sample_type, samples, is_private}) => {
//         let {professionalProduct} = this.state
//         let {document_file_name, sampleBase64, mediaType} = samples[sampleIndex]
//         let request = {
//             document_file_name,
//             document_base64: sampleBase64,
//             product_professional: professionalProduct,
//             sample_type: mediaType,
//         }
//         let apiRequest = ''
//         this.setState({loading: true})
//         if (sampleId) {
//             apiRequest = this.api.auth.patch(`${apiUrl.customProductSample}${sampleId}/`, request)
//         } else {
//             apiRequest = this.api.auth.post(`${apiUrl.customProductSample}`, request)
//         }
//         apiRequest.then(resp => {
//             const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch)
//             if (data) {
//                 samples[sampleIndex] = {
//                     ...samples[sampleIndex],
//                     ...data
//                 }
//                 let newState = {
//                     loading: false
//                 }
//                 if (is_private) {
//                     newState = {
//                         ...newState,
//                         samples_private: samples
//                     }
//                 } else if (is_sample_type) {
//                     newState = {
//                         ...newState,
//                         samples_migrated: samples
//                     }
//                 } else {
//                     newState = {
//                         ...newState,
//                         convincing_video: samples
//                     }
//                 }
//                 this.setState(newState, () => {
//                     this.migrateSamples()
//                     showSnackbar({message: 'Uploaded'})
//                 })
//             } else {
//                 this.setState({loading: false})
//             }
//         })
//             .catch(err => {
//                 this.setState({loading: false});
//                 apiv1.handleErrors(err, this.props.doDispatch)
//             })
//     }
//
//     deleteDocument = ({sampleId, is_private}) => {
//         this.setState({loading: true})
//         apiv1.auth.delete(`${apiUrl.customProductSample}${sampleId}/`)
//             .then(resp => {
//                 const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
//                 if (data === '') {
//                     let samples_migrated = [...this.state.samples_migrated]
//                     let convincing_video = [...this.state.convincing_video]
//                     let samples_private = [...this.state.samples_private]
//                     let deletedIndex = -1
//                     if (is_private) {
//                         deletedIndex = samples_private.findIndex(documentDetails => documentDetails.id === sampleId)
//                         samples_private.splice(deletedIndex, 1)
//                     } else {
//                         deletedIndex = samples_migrated.findIndex(documentDetails => documentDetails.id === sampleId)
//                         if (deletedIndex > -1) {
//                             samples_migrated.splice(deletedIndex, 1)
//                         } else {
//                             deletedIndex = samples_migrated.findIndex(documentDetails => documentDetails.id === sampleId)
//                             convincing_video.splice(deletedIndex, 1)
//                         }
//                     }
//                     this.setState({loading: false, samples_migrated, samples_private, convincing_video}, () => {
//                         this.migrateSamples()
//                         showSnackbar({message: 'Deleted'})
//                     })
//                 } else {
//                     this.setState({loading: false})
//                 }
//             })
//             .catch(err => {
//                 this.setState({loading: false});
//                 apiv1.handleErrors(err, this.props.doDispatch)
//             })
//     }
//
//     handleDrop = ({files, event, sampleIndex, sampleNumber, sampleId, is_private}) => {
//         files.map((file, index) => {
//             const reader = new FileReader()
//             reader.readAsDataURL(file)
//             reader.onabort = () => console.log('file reading was aborted')
//             reader.onerror = () => console.log('file reading has failed')
//             reader.onload = () => {
//                 // console.log(reader.result)
//                 const errors = {...this.state.errors}
//                 const currentUrl = reader.result
//                 file = Object.assign(file, {
//                     preview: URL.createObjectURL(file)
//                 })
//                 let {sampleBase64, can_save, is_sample_type, is_convincing_video} = event
//                 let {name, preview, type} = file
//                 type = type || ''
//                 type = type.split('/')
//                 let docType = type[0]
//                 // docType = docType[0]
//                 if (docType === media_type.video || docType === media_type.image) {
//                     type = docType
//                 } else {
//                     docType = type[1]
//                     if (docType === media_type.pdf) {
//                         // docType = docType[1]
//                         type = docType
//                     } else {
//                         type = ''
//                     }
//                 }
//                 let newUrl = '';
//                 errors[sampleBase64] = ''
//                 let self = this
//                 if (can_save === media_type.image) {
//                     for (let i = 0; i < allowedImageType.length; i++) {
//                         if (currentUrl.includes(allowedImageType[i])) {
//                             newUrl = currentUrl.replace(allowedImageType[i], "")
//                             break
//                         }
//                     }
//                 } else if (can_save === imageOrVideo) {
//                     for (let i = 0; i < allowedVideoType.length; i++) {
//                         if (currentUrl.includes(allowedVideoType[i])) {
//                             newUrl = currentUrl.replace(allowedVideoType[i], "")
//                             break
//                         }
//                     }
//                     for (let i = 0; i < allowedImageType.length; i++) {
//                         if (currentUrl.includes(allowedImageType[i])) {
//                             newUrl = currentUrl.replace(allowedImageType[i], "")
//                             break
//                         }
//                     }
//                 } else if (can_save === videoType) {
//                     for (let i = 0; i < allowedVideoType.length; i++) {
//                         if (currentUrl.includes(allowedVideoType[i])) {
//                             newUrl = currentUrl.replace(allowedVideoType[i], "")
//                             break
//                         }
//                     }
//                 } else if (can_save === imageOrVideoOrDoc) {
//                     for (let i = 0; i < allowedDocumentType.length; i++) {
//                         if (currentUrl.includes(allowedDocumentType[i])) {
//                             newUrl = currentUrl.replace(allowedDocumentType[i], "")
//                             break
//                         }
//                     }
//                     for (let i = 0; i < allowedVideoType.length; i++) {
//                         if (currentUrl.includes(allowedVideoType[i])) {
//                             newUrl = currentUrl.replace(allowedVideoType[i], "")
//                             break
//                         }
//                     }
//                     for (let i = 0; i < allowedImageType.length; i++) {
//                         if (currentUrl.includes(allowedImageType[i])) {
//                             newUrl = currentUrl.replace(allowedImageType[i], "")
//                             break
//                         }
//                     }
//                 }
//                 if (newUrl === '') {
//                     alert(`${name} is not valid`);
//                     return
//                 }
//                 let newDocObj = {
//                     document_file_name: name,
//                     sampleBase64: newUrl,
//                     mediaType: type,
//                     mediaPreview: preview
//                 }
//                 if (is_sample_type || is_private) {
//                     let samples_migrated = [...this.state.samples_migrated]
//                     let samples_private = [...this.state.samples_private]
//                     if (is_private) {
//                         samples_private[sampleIndex] = {
//                             ...samples_private[sampleIndex],
//                             newDocObj
//                         }
//                     } else {
//                         samples_migrated[sampleIndex] = {
//                             ...samples_migrated[sampleIndex],
//                             ...newDocObj
//                         }
//                     }
//                     if (type === media_type.image) {
//                         let img = new Image();
//                         img.src = preview
//                         img.onload = function () {
//                             if (this.width < 600 || this.height < 400) {
//                                 alert('Image dimension must be at least 600 pixels width and 400 pixels height');
//                                 return
//                             }
//                             self.setState({
//                                 samples_migrated,
//                                 samples_private,
//                                 errors,
//                             }, () => self.uploadSamples({
//                                 sampleId,
//                                 sampleIndex,
//                                 is_sample_type,
//                                 samples: is_private ? samples_private : samples_migrated,
//                                 is_private
//                             }))
//                         };
//                     } else {
//                         self.setState({
//                             samples_migrated,
//                             samples_private,
//                             errors,
//                         }, () => self.uploadSamples({
//                             sampleId,
//                             sampleIndex,
//                             is_sample_type,
//                             samples: is_private ? samples_private : samples_migrated,
//                             is_private
//                         }))
//                     }
//                 } else if (is_convincing_video) {
//                     const convincing_video = [...this.state.convincing_video]
//                     convincing_video[sampleIndex] = {
//                         ...convincing_video[sampleIndex],
//                         ...newDocObj
//                     }
//                     self.setState({
//                         convincing_video,
//                         errors,
//                     }, () => self.uploadSamples({sampleId, sampleIndex, is_sample_type, samples: convincing_video}))
//
//                 } else {
//                     let proof_of_work_obj = {...this.state.proof_of_work_obj}
//                     let errors = {...this.state.errors}
//                     errors['doc'] = ''
//                     proof_of_work_obj = {
//                         ...proof_of_work_obj,
//                         ...newDocObj,
//                         doc: newUrl,
//                         doc_type: type,
//                         doc_name: name
//                     }
//                     this.setState({proof_of_work_obj, errors})
//                 }
//             }
//         })
//     }
//
//     saveNonPortfolioQuestion = () => {
//         let {gigNonPublicQuestions} = this.state
//         const {doDispatch} = this.props
//         let apiList = []
//         gigNonPublicQuestions.forEach(questionDetails => {
//             const {id, gig_question_answer} = questionDetails
//             let apiUrlPath = apiUrl.gigNonPublicQuestion
//             let {answer} = gig_question_answer
//             answer = answer || ''
//             let request = {
//                 answer,
//                 gig_question: id,
//                 professional: parseInt(localStorage.professionalId),
//             }
//             if (gig_question_answer.id) {
//                 apiUrlPath += `${gig_question_answer.id}/`
//                 apiList.push(this.api.auth.patch(apiUrlPath, request))
//             } else {
//                 apiList.push(this.api.auth.post(apiUrlPath, request))
//             }
//         })
//         axios.all(apiList)
//             .then(axios.spread((...resp) => {
//                 let qAndAns = resp.map(res => {
//                     return apiv1.parseAndAutoHandleIssues(res, doDispatch)
//                 })
//                 gigNonPublicQuestions = gigNonPublicQuestions.map(qDetails => {
//                     let {gig_question_answer, id} = qDetails
//                     let indexOfQ = qAndAns.findIndex(gig_q_a => gig_q_a.gig_question === id)
//                     if (indexOfQ > -1) {
//                         return {
//                             ...qDetails,
//                             gig_question_answer: {
//                                 ...gig_question_answer,
//                                 ...qAndAns[indexOfQ]
//                             }
//                         }
//                     }
//                     return qDetails
//                 })
//                 this.setState({gigNonPublicQuestions})
//             }))
//             .catch(err => {
//                 apiv1.handleErrors(err, doDispatch);
//                 this.setState({loading: false})
//                 // $('.modal-header .close').click()
//             })
//         axios.all(apiList).then(axios.spread())
//     }
//
//     savePracticeAreaFee = ({request, product, professionalProduct}) => {
//         let apiUrlPath = apiUrl.professionalProduct
//         if (professionalProduct) {
//             apiUrlPath += `${professionalProduct}/`
//             return this.api.auth.patch(apiUrlPath, request)
//         }
//         return this.api.auth.post(apiUrlPath, request)
//     }
//
//     saveProduct = ({
//                        isWhatsIncludedSave,
//                        isAdmin,
//                        is_desc,
//                        is_from_upload_doc_of_proof_of_work,
//                        is_adding_proof_of_work,
//                        is_gig_gallery
//                    }) => {
//         const {
//             professionalProduct,
//             price,
//             professional_description,
//             price_standard,
//             price_premium,
//             delivery_time_base,
//             delivery_time_standard,
//             delivery_time_premium,
//             product_details,
//             product,
//             portfolioLinks,
//             isProductVerified,
//             links_private,
//             whatsIncludedQuestionAnswer,
//             gigNonPublicQuestions,
//             links_with_industry,
//             proof_of_work_obj,
//             samples_migrated
//         } = this.state
//         const {links_required} = product_details
//         const errors = {...this.state.errors}
//         const {professional, doDispatch, onUpdateAProfessionalKey, history} = this.props
//         const {location} = history
//         const {pathname} = location
//         let isWhatsIncludedValid = true
//         let isProofOfWorkValid = true
//         let whatsIncludedRequest = {}
//         let is_package_active_data = {}
//         let request = {
//             professional: professional.id,
//             product,
//             is_core_legal_tax_product: pathname === path.createLegalAndAccountingGig,
//         }
//         let tab_name = ''
//         if (is_desc) {
//             tab_name = tabs[0].tab_id
//             request = {
//                 ...request,
//                 professional_description: `${iWillText} ${professional_description}`,
//                 professional_description_detailed: '',
//             }
//             if (links_required) {
//                 request = {
//                     ...request,
//                     links_with_industry
//                 }
//             }
//
//         } else if (isWhatsIncludedSave) {
//             request = {
//                 ...request,
//                 professional_description: `${iWillText} ${professional_description}`,
//                 professional_description_detailed: '',
//                 delivery_time_base,
//                 delivery_time_standard,
//                 delivery_time_premium,
//                 price,
//                 price_standard,
//                 price_premium,
//             }
//             priceType.forEach((serviceType, index) => {
//                 const {price_type} = serviceType
//                 let is_package_active_key = `${is}${price_type}${_active}`
//                 if (index > 0) {
//                     is_package_active_data[is_package_active_key] = this.state[is_package_active_key]
//                 }
//             })
//             request = {
//                 ...request,
//                 ...is_package_active_data,
//             }
//             whatsIncludedRequest = {
//                 answers: whatsIncludedQuestionAnswer.map((details) => {
//                     let {type, text, answer} = details
//                     answer = answer.map((answerDetails, index) => {
//                         let {selected_ans} = answerDetails
//                         selected_ans = selected_ans || []
//                         if (!is_package_active_data[`${is}${_standard}${_active}`] && index === 1 || !is_package_active_data[`${is}${_premium}${_active}`] && index === 2) {
//                             selected_ans = ['']
//                             if (!is_package_active_data[`${is}${_standard}${_active}`]) {
//                                 request.delivery_time_standard = 1
//                                 request.price_standard = 0
//                             }
//                             if (!is_package_active_data[`${is}${_premium}${_active}`]) {
//                                 request.delivery_time_premium = 1
//                                 request.price_premium = 0
//                             }
//                         }
//                         return selected_ans.join(",")
//                     })
//                     return {
//                         question: text,
//                         type,
//                         answer
//                     }
//                 })
//             }
//             isWhatsIncludedValid = validateWhatsIncluded({
//                 errors,
//                 request: whatsIncludedQuestionAnswer,
//                 is_package_active_data
//             })
//
//         } else {
//             if (is_gig_gallery) {
//                 if (samples_migrated.length < 1) {
//                     alert(`One image/video is compulsory`)
//                     return
//                 }
//             }
//             request = {
//                 ...request,
//                 links: portfolioLinks.filter(linkDetails => linkDetails).map(linkDetails => {
//                     const {link} = linkDetails
//                     return link
//                 }),
//                 links_private: links_private.filter(linkDetails => linkDetails).map(linkDetails => {
//                     const {link} = linkDetails
//                     return link
//                 }),
//             }
//         }
//         if (isAdmin && professionalProduct) {
//             request.verified = isProductVerified
//         }
//         let maxMinValidator = {}
//         priceType.forEach((serviceType) => {
//             const {price_type} = serviceType
//             const max_price = `max${price_type}`
//             const min_price = `min${price_type}`
//             maxMinValidator[max_price] = product_details[max_price]
//             maxMinValidator[min_price] = product_details[min_price]
//         })
//         let isProfessionalProductValid = validateDesignerProduct({
//             errors,
//             request: {...request, ...maxMinValidator},
//             is_package_active_data,
//             tab_name,
//             gigNonPublicQuestions
//         })
//         if (is_desc && links_required) {
//             if (is_adding_proof_of_work) {
//                 if (proof_of_work_obj.mediaPreview && isProfessionalProductValid && !is_from_upload_doc_of_proof_of_work) {
//                     this.uploadIndustrySample()
//                     return
//                 }
//             } else {
//                 if (links_required && links_with_industry.length < links_required) {
//                     alert(`${links_required} proof of work ${links_required > 1 ? 'are' : 'is'} compulsory to proceed`)
//                     return
//                 }
//             }
//         }
//         if (isProfessionalProductValid && isWhatsIncludedValid && isProofOfWorkValid) {
//             this.setState({loading: true})
//             axios.all([this.savePracticeAreaFee({request, product: product, professionalProduct})])
//                 .then(axios.spread((resp) => {
//                     const data = apiv1.parseAndAutoHandleIssues(resp, doDispatch)
//                     let {id, professional_details, product_details} = data
//
//                     const {
//                         price_description,
//                     } = this.returnPriceAndDeliveryTime({selectedProduct: data})
//                     let newState = {
//                         loading: false,
//                         professionalProduct: id,
//                         professional_details,
//                         product_details,
//                         ...price_description,
//                         errors: {},
//                     }
//                     if (is_desc) {
//                         this.saveNonPortfolioQuestion()
//                         newState = {
//                             ...newState,
//                             proof_of_work_obj: this.getProofOfWorkObj(),
//                         }
//                         $('#framework').val('')
//                         $('#industry').val('')
//                         $('#subject_expertise_level').val('')
//                         $('#type_of_video').val('')
//                     }
//
//                     this.setState(newState, () => {
//                         const {location} = history
//                         const {search, hash} = location
//                         let {product} = queryString.parse(search)
//                         const {professional_product_added} = professional
//                         if (onUpdateAProfessionalKey && !professional_product_added) {
//                             onUpdateAProfessionalKey(updateProfessionalKey({
//                                 value: true,
//                                 key: 'professional_product_added'
//                             }))
//                         }
//                         if (is_desc && links_required && links_with_industry.length >= links_required) {
//                             history.replace({
//                                 search: `product=${product}&professionalProduct=${id}`,
//                                 hash: `#${tabs[1].tab_id}`
//                             })
//
//                         } else {
//                             history.replace({
//                                 search: `product=${product}&professionalProduct=${id}`,
//                                 hash: `${hash}`
//                             })
//                         }
//                         if (isWhatsIncludedSave) {
//                             this.saveWhatsIncluded({request: whatsIncludedRequest})
//                         }
//                         showSnackbar({message: 'Saved'})
//                     })
//                 }))
//                 .catch(err => {
//                     apiv1.handleErrors(err, doDispatch);
//                     this.setState({loading: false})
//                     // $('.modal-header .close').click()
//                 })
//         } else {
//             this.setState({errors}, () => {
//                 console.log('errors', errors)
//             })
//         }
//     }
//
//     saveWhatsIncluded = ({request}) => {
//         const {professionalProduct} = this.state
//         this.api.auth.post(`${apiUrl.setProductIncludedQuestion}${professionalProduct}`, request)
//             .then(resp => {
//                 const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch)
//                 if (data) {
//
//                 } else {
//                     this.setState({loading: false})
//                 }
//             })
//             .catch(err => {
//                 this.setState({loading: false});
//                 apiv1.handleErrors(err, this.props.doDispatch)
//             })
//
//     }
//
//     onChange = (e) => {
//         const {name, value} = e.target
//         const {errors} = this.state
//         errors[name] = ''
//         this.setState({[name]: value, errors})
//     }
//
//     onchangeLink = ({e, index}) => {
//         let {name, value} = e.target
//         const portfolioLinks = [...this.state.portfolioLinks]
//         portfolioLinks[index] = {
//             ...portfolioLinks[index],
//             [name]: value
//         }
//         this.setState({portfolioLinks})
//     }
//
//     onchangeNonPortfolioLink = ({e, index}) => {
//         let {name, value} = e.target
//         const links_private = [...this.state.links_private]
//         links_private[index] = {
//             ...links_private[index],
//             [name]: value
//         }
//         this.setState({links_private})
//     }
//
//
//     servicePriceUi = () => {
//         const {id, errors, copy_of_product_details, hasGst} = this.state
//         return (
//             <div className="row" key={id}>
//                 <div className="col-sm-12">
//                     <p className="d-profile-heading">Price & Delivery Date</p>
//                 </div>
//                 <div className="tutorial-box">
//                     {priceType.map((serviceType, index) => {
//                         const {service, price_type, delivery_type} = serviceType
//                         const deliveryTime = `delivery_time${delivery_type}`
//                         const servicePrice = `price${price_type}`
//                         if (copy_of_product_details[`${description_const}${price_type}`] === NONE) {
//                             return ''
//                         }
//                         return (
//                             <div className="col-sm-4" key={index}>
//                                 <div className="price-card">
//                                     <div className="card-header">
//                                         <span>{service}</span>
//                                     </div>
//                                     <div className="card-footer">
//                                         <select className="input-lg"
//                                                 name={deliveryTime}
//                                                 value={this.state[deliveryTime]}
//                                                 onChange={this.onChange}
//                                         >
//                                             <option selected aria-disabled={true}>Delivery Time</option>
//                                             {deliveryDay.map(days => {
//                                                 return <option value={days} key={days}>{`${days} day`}</option>
//                                             })}
//                                         </select>
//                                         {errors[deliveryTime] && <span className='error'>{errors[deliveryTime]}</span>}
//                                         <input type="text"
//                                                placeholder="Enter Price In Rupees"
//                                                className="form-control input-lg"
//                                                name={servicePrice}
//                                                value={this.state[servicePrice]}
//                                                onChange={this.onChange}
//                                         />
//                                         {errors[servicePrice] && <span className='error'>{errors[servicePrice]}</span>}
//                                     </div>
//                                 </div>
//                             </div>
//                         )
//                     })}
//                     <div className="tutorial-tips" style={{top: '30px'}}>
//                         <p className="tutorial-para">
//                             <ul className="tips-ul">
//                                 <li>Keep your Basic Package price low with fewer deliverables</li>
//                                 <li>Standard Package should have more deliverables than Basic</li>
//                                 <li>Premium should be the best package and better priced.</li>
//                                 <li>Competitive pricing in Basic Package gets you more leads</li>
//                             </ul>
//                         </p>
//                     </div>
//                 </div>
//                 <div className="clearfix"/>
//                 <div className="col-sm-12">
//                     {hasGst ?
//                         <Fragment>
//                             <p className="text-note">*Since you are GST registered Seller, 18% GST will be added to your
//                                 Price</p>
//                             <p className="text-note">*GST is applicable to only Indian Customers</p>
//                             <p className="text-note">*5% Service Fee is added for International Customers</p>
//                         </Fragment>
//                         :
//                         <Fragment>
//                             <p className="text-note">*No GST will be charged</p>
//                             <p className="text-note">*5% Service Fee is added for International Customers</p>
//                         </Fragment>
//
//                     }
//                 </div>
//             </div>
//         )
//     }
//
//     sampleUi = () => {
//         const {errors, samples_migrated} = this.state
//         const {history} = this.props
//         const {location} = history
//         const {pathname} = location
//         return (
//             <div className="row">
//                 {pathname === path.createLegalAndAccountingGig ?
//                     <div className="col-sm-12">
//                         <p className="skill-heading">Please add a graphic or a screenshot depicting this service.</p>
//                         <ul className="tips-ul">
//                             <li>Atleast 1 image is needed</li>
//                             <li>Should not contain personal number, email or website details</li>
//                         </ul>
//                     </div>
//                     :
//                     <div className="col-sm-12">
//                         <p className="d-profile-heading">Upload Marketing Creatives</p>
//                         <p className="para">Atleast 1 Image is mandatory</p>
//                         {/*<p className="skill-heading">Upload your best Media Samples, Portfolio samples</p>*/}
//                         {/*<p className="para">Upload photos in JPEG, JPG, PNG and ensure they’re at least 600 pixels width*/}
//                         {/*x*/}
//                         {/*400 pixels height. We suggest uploading them in landscape format to make better use of the*/}
//                         {/*space.</p>*/}
//                         {/*<ul className="tips-ul">*/}
//                         {/*<li>Upload your original work. Faking someone else's work is strictly not allowed.</li>*/}
//                         {/*<li>Upload trademark & copyright free images</li>*/}
//                         {/*<li>Upload screenshots of work done by you</li>*/}
//                         {/*</ul>*/}
//                     </div>
//                 }
//                 <div className="clearfix"/>
//                 <div className="col-md-8">
//                     <div className="row">
//                         {noOfSamples.map((sampleNumber, sampleIndex) => {
//                             const {document, sample_type, id} = samples_migrated[sampleIndex] || []
//                             const sampleBase64 = `${sample}${sampleNumber}${_base64}`
//                             let sample_type_image = sampleIndex === 0
//                             let sampleUrl = document
//                             let isVideoType = sample_type === media_type.video
//                             let mediaPreview = this.state[`${sampleBase64}${_preview}`]
//                             let uiText = (!sampleUrl && !mediaPreview) ? `${sample_type_image ? 'upload image' : 'upload image/video'}` : ''
//                             return (
//                                 <div className="col-sm-3" key={sampleNumber}>
//                                     <div className="dropzone embed-responsive embed-responsive-16by9">
//                                         <Dropzone
//                                             className='dropzone'
//                                             activeClassName='active-dropzone'
//                                             multiple={false}
//                                             onDrop={(files) => {
//                                                 const event = {
//                                                     sampleBase64: '',
//                                                     sampleFileName: '',
//                                                     can_save: sample_type_image ? media_type.image : imageOrVideo,
//                                                     is_sample_type: true
//                                                 }
//                                                 this.handleDrop({
//                                                     files,
//                                                     event,
//                                                     sampleIndex,
//                                                     sampleNumber,
//                                                     sampleId: id || ''
//                                                 })
//                                             }}
//                                         >
//                                             {({getRootProps, getInputProps}) => (
//                                                 <div {...getRootProps()}>
//                                                     <input {...getInputProps()}
//                                                            id={`${sample}${sampleNumber}`}
//                                                            accept={`${sample_type_image ? 'image/*' : `image/*, video/*`}`}/>
//                                                     {isVideoType ?
//                                                         <div>
//                                                             <video controls id={sampleBase64}>
//                                                                 <source src={mediaPreview ? mediaPreview : sampleUrl}
//                                                                         type="video/mp4"/>
//                                                             </video>
//                                                         </div>
//                                                         :
//                                                         <Fragment>
//                                                             {uiText ? uiText :
//                                                                 <img src={mediaPreview ? mediaPreview : sampleUrl}
//                                                                      alt="user" className="img-responsive"/>}
//                                                         </Fragment>
//                                                     }
//                                                 </div>
//                                             )}
//                                         </Dropzone>
//                                         {(sampleUrl || mediaPreview) && (
//                                             <div className="dropzone-overlay">
//                                                 <div className="dropzone-button-box">
//                                                     <button className="btn btn-danger"
//                                                             onClick={() => {
//                                                                 this.deleteDocument({
//                                                                     sampleId: id,
//                                                                     sampleNumber,
//                                                                     is_private: false
//                                                                 })
//                                                             }
//                                                             }
//                                                     >{'Remove'}</button>
//
//                                                 </div>
//                                             </div>
//                                         )}
//
//                                     </div>
//                                     {sampleNumber === 1 && errors['sample1_base64'] &&
//                                     <span className='error'>{errors['sample1_base64']}</span>}
//                                 </div>
//                             )
//                         })}
//                         {/*<div className="tutorial-tips" style={{top: '230px'}}>*/}
//                         {/*<p className="tutorial-para">Upload Sample Portfolio in given size specifically for this*/}
//                         {/*product. The samples should be clear, neat, beautiful and attractive. Those who have strong*/}
//                         {/*portfolio have 90% chances of getting hired. We market and share your portfolio on our*/}
//                         {/*Social Network and Community.</p>*/}
//                         {/*<p className="tutorial-para">Example:</p>*/}
//                         {/*<p className="tutorial-link"><a href={path.article4}*/}
//                         {/*target="_blank">http://www.workbeam.com/knowledge-base/article/examples-of-photoshop-editing</a>*/}
//                         {/*</p>*/}
//                         {/*</div>*/}
//                     </div>
//                 </div>
//                 <div className="col-sm-4">
//                     <div className="profile-tutorial-container">
//                         <div className="row">
//                             <div className="col-sm-12">
//                                 <h2 className="profile-tutorial-heading"><img
//                                     src={require('../../static/images/tips.png').default}
//                                     style={{width: '30px', float: 'left', marginRight: '5px'}} alt={`Create Gig Tips`}/>These
//                                     images appear on your Gig Booking Page.</h2>
//                                 <h2 className="profile-tutorial-sub-heading">Upload Marketing Creative with a call to
//                                     action or upload designs & screenshots of your best work, infographics, skills
//                                     etc.</h2>
//                                 <ul className="profile-tutorial-ul list-unstyled">
//                                     <li><img src={require('../../static/images/graph-line.png').default}
//                                              alt={`What does a good Bio includes?`}/><a data-toggle="modal"
//                                                                                         data-target="#gig_gallery_example">Click
//                                         here to see how to create one and samples of our Top Sellers</a></li>
//                                 </ul>
//                             </div>
//                         </div>
//                     </div>
//                     <GigGalleryExample/>
//                 </div>
//             </div>
//         )
//     }
//     // videoSampleUi = () => {
//     //     const {convincing_video} = this.state
//     //     return (
//     //         <div className="row">
//     //             <div className="col-sm-12">
//     //                 <p className="skill-heading">1 Video (Convincing buyers to hire you on Workbeam)</p>
//     //             </div>
//     //             {noOfConvincingVideo.map((sampleNumber, sampleIndex) => {
//     //                 const {document, id} = convincing_video[sampleIndex] || []
//     //                 let sampleUrl = document
//     //                 return (
//     //                     <div className="col-sm-6 col-sm-offset-3 tutorial-box">
//     //                         <div className="dropzone dropzone-video">
//     //                             <div className="embed-responsive embed-responsive-16by9">
//     //                                 <Dropzone
//     //                                     className='dropzone'
//     //                                     activeClassName='active-dropzone'
//     //                                     onDrop={(files) => {
//     //                                         const event = {
//     //                                             sampleBase64: '',
//     //                                             sampleFileName: '',
//     //                                             can_save: videoType,
//     //                                             is_convincing_video: true
//     //                                         }
//     //                                         this.handleDrop({
//     //                                             files,
//     //                                             event,
//     //                                             sampleIndex,
//     //                                             sampleNumber,
//     //                                             sampleId: id || ''
//     //                                         })
//     //                                     }}
//     //                                     multiple={false}
//     //                                 >
//     //                                     {({getRootProps, getInputProps}) => (
//     //                                         <div {...getRootProps()}>
//     //                                             <input {...getInputProps()} accept={`video/*`}/>
//     //                                             {(sampleUrl) ?
//     //                                                 <video id='samplevideo_base64' controls>
//     //                                                     <source src={sampleUrl} type="video/mp4"/>
//     //                                                     {/*<source src="https://workbeam.blob.core.windows.net/wiz-public-cdn/video/Get-Direct-hired-Send-Proposal.webm"/>*/}
//     //                                                 </video>
//     //                                                 :
//     //                                                 'upload video'
//     //                                             }
//     //                                         </div>
//     //                                     )}
//     //                                 </Dropzone>
//     //                                 {sampleUrl && (
//     //                                     <div className="dropzone-overlay">
//     //                                         <div className="dropzone-button-box">
//     //                                             <button className="btn btn-danger"
//     //                                                     onClick={() => {
//     //                                                         this.deleteDocument({
//     //                                                             sampleId: id,
//     //                                                             sampleNumber,
//     //                                                             is_private: false
//     //                                                         })
//     //                                                     }
//     //                                                     }
//     //                                             >{'Remove'}</button>
//     //
//     //                                         </div>
//     //                                     </div>
//     //                                 )}
//     //
//     //                             </div>
//     //                         </div>
//     //                         <div className="tutorial-tips" style={{top: '10px'}}>
//     //                             <p className="tutorial-para">Create a convincing video or yourself telling customers why
//     //                                 they should hire. We market and share your Video on our Social Network and
//     //                                 Community.</p>
//     //                         </div>
//     //                     </div>
//     //                 )
//     //             })}
//     //
//     //         </div>
//     //     )
//     // }
//
//     portfolioLinkUi = () => {
//         const {portfolioLinks} = this.state
//         return <div>
//             <Fragment>
//                 {portfolioLinks.map((linkDetails, index) => {
//                     const {link} = linkDetails
//                     return (
//                         <div className="col-sm-6 form-group">
//                             <label>Portfolio Link</label>
//                             <input type="text" className="form-control" name={'link'} value={link}
//                                    onChange={(e) => this.onchangeLink({e, index})}/>
//                         </div>
//
//                     )
//                 })}
//                 <div className="clearfix"/>
//
//                 {portfolioLinks.length < 5 &&
//                 <div className="col-sm-12">
//                     <a className="btn btn-add"
//                        onClick={() => {
//                            const portfolioLinks = [...this.state.portfolioLinks]
//                            portfolioLinks.push({link: ''})
//                            this.setState({portfolioLinks})
//                        }
//                        }
//                     >
//                         {`${portfolioLinks.length === 0 ? 'Add Portfolio Link' : 'Add More Link'}`}
//                     </a>
//                 </div>
//                 }
//             </Fragment>
//         </div>
//     }
//
//     externalLinksUi = () => {
//         const {portfolioLinks} = this.state
//         return (
//             <div className="row">
//                 <div className="col-sm-12">
//                     <p className="skill-heading">Portfolio Links</p>
//                     <ul className="tips-ul">
//                         <li>Links to websites & apps you have developed</li>
//                         <li>Google drive links to content you have written or created</li>
//                         <li>Google drive links to designs to you have created</li>
//                         <li>Don't put links to personal websites or personal info details</li>
//                     </ul>
//                 </div>
//                 <div className="tutorial-box">
//                     <Fragment>
//                         {noOfExternalLink.map((linkNo, index) => {
//                             const {link} = portfolioLinks[index] || []
//                             return (
//                                 <Fragment>
//                                     <div className="col-sm-8 col-xs-9 form-group">
//                                         <label>Portfolio Link {index + 1}</label>
//                                         <input type="text" className="form-control" name={'link'} value={link}
//                                                onChange={(e) => this.onchangeLink({e, index})}/>
//                                     </div>
//                                 </Fragment>
//                             )
//                         })}
//                         <div className="clearfix"/>
//                     </Fragment>
//                 </div>
//             </div>
//         )
//     }
//
//     nonPortfolioSamples = () => {
//         const {samples_private, errors} = this.state
//         return (
//             <div className="row">
//                 <div className="col-sm-12">
//                     <p className="skill-heading">Non Public Portfolio for Workbeam</p>
//                     <p className="para" style={{color: '#1d2adf'}}>This portfolio is not shown to public. Here you can
//                         share any copyrighted work you have done but dont want to show in public.</p>
//                     <p className="para" style={{color: '#1d2adf'}}>This is for Workbeam’s own record to verify your
//                         quality of work and approve your gig.</p>
//                     <p className="para" style={{marginBottom: '0px'}}><b>Non Public Gig Images ( optional )</b></p>
//                 </div>
//                 <div className="tutorial-box">
//                     {noOfSamples.map((sampleNumber, sampleIndex) => {
//                         const {document, sample_type, id} = samples_private[sampleIndex] || []
//                         const sampleBase64 = `${sample}${sampleNumber}${_base64}`
//                         let sample_type_image = sampleIndex === 0
//                         let sampleUrl = document
//                         let isVideoType = sample_type === media_type.video
//                         let mediaPreview = this.state[`${sampleBase64}${_preview}`]
//                         let uiText = (!sampleUrl && !mediaPreview) ? `${sample_type_image ? 'upload image' : 'upload image/video'}` : ''
//                         return (
//                             <div className="col-sm-3" key={sampleNumber}>
//                                 <div className="dropzone embed-responsive embed-responsive-16by9">
//                                     <Dropzone
//                                         className='dropzone'
//                                         activeClassName='active-dropzone'
//                                         multiple={false}
//                                         onDrop={(files) => {
//                                             const event = {
//                                                 sampleBase64: '',
//                                                 sampleFileName: '',
//                                                 can_save: sample_type_image ? media_type.image : imageOrVideo,
//                                                 is_sample_type: true
//                                             }
//                                             this.handleDrop({
//                                                 files,
//                                                 event,
//                                                 sampleIndex,
//                                                 sampleNumber,
//                                                 sampleId: id || '',
//                                                 is_private: true
//                                             })
//                                         }}
//                                     >
//                                         {({getRootProps, getInputProps}) => (
//                                             <div {...getRootProps()}>
//                                                 <input {...getInputProps()}
//                                                        id={`${'nonportfolio'}${sample}${sampleNumber}`}
//                                                        accept={`${sample_type_image ? 'image/*' : `image/*, video/*`}`}/>
//                                                 {isVideoType ?
//                                                     <div>
//                                                         <video controls id={sampleBase64}>
//                                                             <source src={mediaPreview ? mediaPreview : sampleUrl}
//                                                                     type="video/mp4"/>
//                                                         </video>
//                                                     </div>
//                                                     :
//                                                     <Fragment>
//                                                         {uiText ? uiText :
//                                                             <img src={mediaPreview ? mediaPreview : sampleUrl}
//                                                                  alt="user" className="img-responsive"/>}
//                                                     </Fragment>
//                                                 }
//                                             </div>
//                                         )}
//                                     </Dropzone>
//                                     {(sampleUrl || mediaPreview) && (
//                                         <div className="dropzone-overlay">
//                                             <div className="dropzone-button-box">
//                                                 <button className="btn btn-danger"
//                                                         onClick={() => {
//                                                             this.deleteDocument({
//                                                                 sampleId: id,
//                                                                 sampleNumber,
//                                                                 is_private: true
//                                                             })
//                                                         }
//                                                         }
//                                                 >{'Remove'}</button>
//
//                                             </div>
//                                         </div>
//                                     )}
//
//                                 </div>
//                                 {sampleNumber === 1 && errors['sample1_base64'] &&
//                                 <span className='error'>{errors['sample1_base64']}</span>}
//                             </div>
//                         )
//                     })}
//                     <div className="tutorial-tips" style={{top: '230px'}}>
//                         <p className="tutorial-para">Upload Sample Portfolio in given size specifically for this
//                             product. The samples should be clear, neat, beautiful and attractive. Those who have strong
//                             portfolio have 90% chances of getting hired. We market and share your portfolio on our
//                             Social Network and Community.</p>
//                         <p className="tutorial-para">Example:</p>
//                         <p className="tutorial-link"><a href={path.article4}
//                                                         target="_blank">http://www.workbeam.com/knowledge-base/article/examples-of-photoshop-editing</a>
//                         </p>
//                     </div>
//                 </div>
//             </div>
//         )
//     }
//
//     nonPublicLinksUi = () => {
//         const {links_private} = this.state
//         return (
//             <div className="row">
//                 <div className="col-sm-12">
//                     <p className="skill-heading">Non Public Portfolio Links ( optional )</p>
//                 </div>
//                 <div className="tutorial-box">
//                     <Fragment>
//                         {noOfExternalLink.map((linkNo, index) => {
//                             const {link} = links_private[index] || []
//                             return (
//                                 <div className="col-sm-6 form-group">
//                                     <label>Portfolio Link {index + 1}</label>
//                                     <input type="text" className="form-control" name={'link'} value={link}
//                                            onChange={(e) => this.onchangeNonPortfolioLink({e, index})}/>
//                                 </div>
//                             )
//                         })}
//                         <div className="clearfix"/>
//                     </Fragment>
//                 </div>
//             </div>
//         )
//     }
//
//     onChangeWhatsIncluded = ({e, index, q_index, questionAnswerDetails, answer, isMulti, selected_ans, q_id}) => {
//         const {value, checked} = e.target
//         const Answer = [...answer]
//         let {errors} = this.state
//         Answer[index] = {
//             ...Answer[index],
//             selected_ans: [value]
//         }
//         if (isMulti) {
//             let selected_ans_index = selected_ans.indexOf(value)
//             if (checked) {
//                 selected_ans = selected_ans.concat([value])
//
//             } else {
//                 selected_ans.splice(selected_ans_index, 1)
//             }
//             Answer[index] = {
//                 ...Answer[index],
//                 selected_ans
//             }
//         }
//         const whatsIncludedQuestionAnswer = [...this.state.whatsIncludedQuestionAnswer]
//         whatsIncludedQuestionAnswer[q_index] = {
//             ...whatsIncludedQuestionAnswer[q_index],
//             answer: Answer
//         }
//         if (errors[q_id]) {
//             let {answer} = errors[q_id]
//             answer = answer || []
//             answer[index] = ''
//             errors[q_id] = {
//                 ...errors[q_id],
//                 answer
//             }
//         }
//         this.setState({whatsIncludedQuestionAnswer, errors})
//     }
//
//     onChangeNonPortfolioQuestion = ({e, index, q_id}) => {
//         const {value, name} = e.target
//         let gigNonPublicQuestions = [...this.state.gigNonPublicQuestions]
//         const errors = {...this.state.errors}
//         if (!errors[quality_check_question]) {
//             errors[quality_check_question] = {}
//         }
//         errors[quality_check_question][q_id] = ''
//         let selectedQuestionDetails = gigNonPublicQuestions[index]
//         let {gig_question_answer} = selectedQuestionDetails || {}
//         gigNonPublicQuestions[index] = {
//             ...gigNonPublicQuestions[index],
//             gig_question_answer: {
//                 ...gig_question_answer,
//                 [name]: value,
//             }
//         }
//         this.setState({gigNonPublicQuestions, errors})
//     }
//
//     gigNonPublicQuestionUi = () => {
//         const {gigNonPublicQuestions, errors} = this.state
//         return (
//             <Fragment>
//                 <div className="row gap-margin">
//                     <div className="col-md-12">
//                         {gigNonPublicQuestions.length > 0 &&
//                         <Fragment>
//                             <p className="panel-text">Gig Questions</p>
//                             <p className="text-note">*These questions are not shown to public. These are for Workbeam Team
//                                 to quality check your profile.</p>
//                         </Fragment>
//                         }
//                         {gigNonPublicQuestions.map((questionAnswerDetails, index) => {
//                             let {type, text, id, gig_question_answer, question} = questionAnswerDetails
//                             const {answer, gig_question} = gig_question_answer
//                             type = _text
//                             switch (type) {
//                                 case _text:
//                                     return (
//                                         <div className="form-group" style={{border: 'none'}}>
//                                             <label style={{fontSize: '14px'}}>{question}</label>
//                                             <input type="text"
//                                                    className="form-control input-lg"
//                                                    name={'answer'}
//                                                    value={answer}
//                                                    onChange={(e) => this.onChangeNonPortfolioQuestion({
//                                                        e,
//                                                        index,
//                                                        q_id: id
//                                                    })}
//                                             />
//                                             {errors[quality_check_question] && errors[quality_check_question][gig_question] &&
//                                             <span
//                                                 className='error'>{errors[quality_check_question][gig_question]}</span>}
//                                         </div>
//                                     )
//                                 default:
//                                     return ''
//
//                             }
//                         })}
//                     </div>
//                 </div>
//             </Fragment>
//         )
//     }
//
//     previewGigPage = () => {
//         const {professional_details, product_details, professionalProduct, price} = this.state
//         if (!professionalProduct || !price) {
//             return ''
//         }
//         return (
//             <button className="btn btn-see-skill-outline"
//                     onClick={(e) => {
//                         const {slug_new} = professional_details
//                         window.open(`${mainDomain}/${freelancers}/${slug_new}/${product_details.slug_new}?isFromDashboard=${true}`, '_blank')
//                     }}
//             >Preview Gig Page
//             </button>
//         )
//     }
//
//     whatsIncludedQuestionsUi = () => {
//         const {errors, product_details, whatsIncludedQuestionAnswer} = this.state
//         return (
//             <Fragment>
//                 <div className="d-update-gig-price" style={{paddingTop: '20px'}}>
//                     <div className="row">
//                         <div className="col-md-12">
//                             <h4 className="d-profile-heading">Define Deliverables</h4>
//                             <p className="para">Very important to clearly mark what is included and what
//                                 is not included.</p>
//                         </div>
//                         <div className="clearfix"/>
//                         <div className="col-md-8">
//                             <div className="gig-price-table">
//                                 <p className="text-note">All (*) mark field are mandatory</p>
//                                 <table className="table table-bordered">
//                                     <thead>
//                                     {product_details.id &&
//                                     <tr>
//                                         <th>Inclusions</th>
//                                         {priceType.map((details, index) => {
//                                             const {service, price_type} = details
//                                             let service_needed_key = `${is}${price_type}${_active}`
//                                             let isServiceNotNeeded = !this.state[service_needed_key]
//                                             return <Fragment>
//                                                 <th>{service}
//                                                     <br/>
//                                                     {index > 0 &&
//                                                     <Fragment>
//                                                         <div className='checkbox'>
//                                                             <label style={{
//                                                                 fontSize: '14px',
//                                                                 color: '#2b47fb',
//                                                                 marginTop: '5px'
//                                                             }}>
//                                                                 <input type="checkbox"
//                                                                        checked={isServiceNotNeeded}
//                                                                        name={service_needed_key}
//                                                                        onChange={(e) => {
//                                                                            let {target} = e
//                                                                            let {checked} = target
//                                                                            target = {
//                                                                                ...target,
//                                                                                value: !checked,
//                                                                                name: service_needed_key
//                                                                            }
//                                                                            e = {
//                                                                                ...e,
//                                                                                target
//                                                                            }
//                                                                            this.onChange(e)
//                                                                        }}
//                                                                 />
//                                                                 <span className='cr'><i
//                                                                     className='cr-icon glyphicon-ok glyphicon'/></span>
//                                                                 Tick if {service} not needed
//                                                             </label>
//                                                         </div>
//                                                     </Fragment>
//                                                     }
//                                                 </th>
//                                             </Fragment>
//                                         })}</tr>
//                                     }
//                                     </thead>
//                                     <tbody>
//                                     {whatsIncludedQuestionAnswer.map((questionAnswerDetails, q_index) => {
//                                         const {
//                                             type,
//                                             text,
//                                             answer,
//                                             answer_options,
//                                             is_does_not_include,
//                                             is_whats_included,
//                                             id,
//                                             mandatory
//                                         } = questionAnswerDetails
//                                         switch (type) {
//                                             case multi:
//                                                 return (
//                                                     <tr key={id}>
//                                                         <td className="col-sm-4">{text}
//                                                             {mandatory &&
//                                                             <span style={{color: '#ff337d', fontSize: '18px'}}>*</span>}
//                                                         </td>
//                                                         {answer.map((ans, index) => {
//                                                             const {selected_ans} = ans
//                                                             return <td key={index}>
//                                                                 <div className="form-group">
//                                                                     {answer_options.map((opt, indx) => {
//                                                                         return (<div className="checkbox" key={indx}>
//                                                                             <label>
//                                                                                 <input type="checkbox"
//                                                                                        value={opt}
//                                                                                        checked={selected_ans.indexOf(opt) > -1}
//                                                                                        name={''}
//                                                                                        onChange={(e) => this.onChangeWhatsIncluded({
//                                                                                            e,
//                                                                                            index,
//                                                                                            q_index,
//                                                                                            questionAnswerDetails,
//                                                                                            answer,
//                                                                                            isMulti: true,
//                                                                                            selected_ans,
//                                                                                            q_id: id
//                                                                                        })}
//                                                                                 />
//                                                                                 <span className="cr"><i
//                                                                                     className="cr-icon glyphicon glyphicon-ok"/></span>
//                                                                                 {opt}
//                                                                             </label>
//                                                                         </div>)
//                                                                     })}
//                                                                     {errors[id] && errors[id].answer && errors[id].answer[index] &&
//                                                                     <span
//                                                                         className='error'>{errors[id].answer[index].error_text}</span>}
//                                                                 </div>
//                                                             </td>
//                                                         })}
//                                                     </tr>
//                                                 )
//                                             case single:
//                                                 return (
//                                                     <tr key={id}>
//                                                         <td className="col-sm-4">{text}
//                                                             {mandatory &&
//                                                             <span style={{color: '#ff337d', fontSize: '18px'}}>*</span>}
//                                                         </td>
//                                                         {answer.map((ans, index) => {
//                                                             const {selected_ans} = ans
//                                                             return <td key={index}>
//                                                                 <div className="form-group">
//                                                                     <select className="form-control"
//                                                                             value={selected_ans[0]}
//                                                                             onChange={(e) => this.onChangeWhatsIncluded({
//                                                                                 e,
//                                                                                 index,
//                                                                                 q_index,
//                                                                                 questionAnswerDetails,
//                                                                                 answer,
//                                                                                 isMulti: false,
//                                                                                 selected_ans,
//                                                                                 q_id: id
//                                                                             })}
//                                                                     >
//                                                                         <option selected aria-disabled={true}>Select
//                                                                         </option>
//                                                                         {answer_options.map((opt, indx) => {
//                                                                             return <option key={indx}
//                                                                                 // selected={opt === selected_ans[0]}
//                                                                                            value={opt}>{opt}</option>
//                                                                         })}
//                                                                     </select>
//                                                                     {errors[id] && errors[id].answer && errors[id].answer[index] &&
//                                                                     <span
//                                                                         className='error'>{errors[id].answer[index].error_text}</span>}
//                                                                 </div>
//                                                             </td>
//                                                         })}
//                                                     </tr>
//                                                 )
//                                             case yesno:
//                                                 return (
//                                                     <tr key={id}>
//                                                         <td className="col-sm-4">{text}
//                                                             {mandatory &&
//                                                             <span style={{color: '#ff337d', fontSize: '18px'}}>*</span>}
//                                                         </td>
//                                                         {answer.map((ans, index) => {
//                                                             const {selected_ans} = ans
//                                                             return <td key={index}>
//                                                                 <div className="form-group">
//                                                                     <RadioGroup
//                                                                         value={selected_ans[0]}
//                                                                         onChange={(e) => this.onChangeWhatsIncluded({
//                                                                             e,
//                                                                             index,
//                                                                             q_index,
//                                                                             questionAnswerDetails,
//                                                                             answer,
//                                                                             isMulti: false,
//                                                                             selected_ans,
//                                                                             q_id: id
//                                                                         })}
//                                                                         className="inlineRadio"
//
//                                                                     >
//                                                                         {answer_options.map((opt, indx) => {
//                                                                             return (
//                                                                                 <FormControlLabel
//                                                                                     key={indx}
//                                                                                     value={opt}
//                                                                                     control={<Radio/>}
//                                                                                     label={opt}
//                                                                                 />
//                                                                             )
//                                                                         })}
//                                                                     </RadioGroup>
//                                                                     {errors[id] && errors[id].answer && errors[id].answer[index] &&
//                                                                     <span
//                                                                         className='error'>{errors[id].answer[index].error_text}</span>}
//                                                                 </div>
//                                                             </td>
//                                                         })}
//                                                     </tr>
//
//                                                 )
//                                             case _text:
//                                                 return (
//                                                     <tr key={id}>
//                                                         <td className="col-sm-4">{text}
//                                                             {mandatory &&
//                                                             <span style={{color: '#ff337d', fontSize: '18px'}}>*</span>}
//                                                             {(is_does_not_include || is_whats_included) ?
//                                                                 <p className="text-note"
//                                                                    style={{fontSize: '14px'}}>*Write text separated by
//                                                                     Comma"
//                                                                     ( Eg. Hosting & SSL, Product Listing, Content &
//                                                                     Design, Trademark Approval"</p> : ''}
//                                                         </td>
//                                                         {answer.map((ans, index) => {
//                                                             const {selected_ans} = ans
//                                                             return <td key={index}>
//                                                                 <div className="form-group">
//                                                                     {(is_whats_included || is_does_not_include) ?
//                                                                         <textarea
//                                                                             rows="4"
//                                                                             className="form-control"
//                                                                             name={ans}
//                                                                             value={selected_ans[0]}
//                                                                             onChange={(e) => this.onChangeWhatsIncluded({
//                                                                                 e,
//                                                                                 index,
//                                                                                 q_index,
//                                                                                 questionAnswerDetails,
//                                                                                 answer,
//                                                                                 isMulti: false,
//                                                                                 selected_ans,
//                                                                                 q_id: id
//                                                                             })}
//                                                                         />
//                                                                         :
//                                                                         <input type="text"
//                                                                                className="form-control"
//                                                                                name={ans}
//                                                                                value={selected_ans[0]}
//                                                                                onChange={(e) => this.onChangeWhatsIncluded({
//                                                                                    e,
//                                                                                    index,
//                                                                                    q_index,
//                                                                                    questionAnswerDetails,
//                                                                                    answer,
//                                                                                    isMulti: false,
//                                                                                    selected_ans,
//                                                                                    q_id: id
//                                                                                })}
//                                                                         />
//                                                                     }
//                                                                     {errors[id] && errors[id].answer && errors[id].answer[index] &&
//                                                                     <span
//                                                                         className='error'>{errors[id].answer[index].error_text}</span>}
//                                                                 </div>
//                                                             </td>
//                                                         })}
//                                                     </tr>
//                                                 )
//                                             default:
//                                                 return ''
//
//                                         }
//                                     })}
//                                     </tbody>
//                                 </table>
//                             </div>
//                             {this.servicePriceUi()}
//                             {Object.keys(errors).map(key => {
//                                 let error = ''
//                                 if (errors[key] && errors[key].answer && errors[key].answer.length > 0) {
//                                     if (errors[key].answer[0] && errors[key].answer[0].field_name) {
//                                         error = errors[key].answer[0].field_name
//                                     }
//                                     if (errors[key].answer[1] && errors[key].answer[1].field_name) {
//                                         error = errors[key].answer[1].field_name
//                                     }
//                                     if (errors[key].answer[2] && errors[key].answer[2].field_name) {
//                                         error = errors[key].answer[2].field_name
//                                     }
//                                     if (error) {
//                                         error += ` field is required`
//                                     }
//                                 }
//                                 return <p className='error' style={{marginTop: '10px'}}>
//                                     {error}
//                                 </p>
//                             })}
//                             <button className="btn btn-save-profile-gig"
//                                     onClick={() => {
//                                         this.saveProduct({isWhatsIncludedSave: true})
//                                     }}
//                             >Save
//                             </button>
//                             {this.previewGigPage()}
//                         </div>
//                         <div className="col-sm-4">
//                             <p>&#x00A0;</p>
//                             <div className="profile-tutorial-container">
//                                 <div className="row">
//                                     <div className="col-sm-12">
//                                         <h2 className="profile-tutorial-heading"><img
//                                             src={require('../../static/images/tips.png')}
//                                             style={{width: '30px', float: 'left', marginRight: '5px'}}
//                                             alt={`Create Gig Tips`}/>It is not necessary to fill all 3 Basic, Standard
//                                             and premium. You can just fill 1 or even 2. However, filling all 3 increases
//                                             chances of getting hired.</h2>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//
//                 </div>
//             </Fragment>
//         )
//     }
//
//     gigTutorialUi = () => {
//         const {tutorial} = this.state
//         // console.log('tutorial', tutorial)
//         return (
//             <div className="col-sm-4 hidden-xs gap-margin">
//                 <div className="profile-tutorial-container">
//                     <div className="row">
//                         <div className="col-sm-12">
//                             {tutorial.map(details => {
//                                 const {header, children} = details[0]
//                                 return <Fragment>
//                                     <h2 className="profile-tutorial-heading">{header}</h2>
//                                     <ul className="profile-tutorial-ul list-unstyled">
//                                         {children.map(txt => {
//                                             return <li><img src={require('../../static/images/tips.png')} alt={txt}/>
//                                                 <a>{nl2br(txt)}</a>
//                                                 {/*{txt}*/}
//                                             </li>
//                                         })}
//                                     </ul>
//                                 </Fragment>
//                             })}
//                             {/*<h2 className="profile-tutorial-heading"><img src={require('../../static/images/tips.png')} style={{width:'30px',float:'left', marginRight:'5px'}} alt={`Create Gig Tips`}/>*/}
//
//                             {/*<br/>*/}
//                             {/*/!*By adding at least 8 Projects you get 4x more leads on Workbeam.*!/*/}
//                             {/*</h2>*/}
//                             {/*<ul className="profile-tutorial-ul list-unstyled">*/}
//                             {/*<li><img src={require('../../static/images/graph-line.png')} alt={`See how Projects are shown on your Gigs`}/><a data-toggle="modal" data-target="#expert_client_reviews_tips_popup">See how Projects are shown on your Gigs </a></li>*/}
//                             {/*</ul>*/}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         )
//     }
//
//     titleAndDesUi = () => {
//         const {professional_description, errors, product_details, tutorial} = this.state
//         const {one_liner, links_required} = product_details || {}
//         return (
//             <div className="d-update-gig-price basic-detail-form">
//                 <div className="row">
//                     <div className="col-sm-8">
//                         <p className="panel-text">Gig Title</p>
//                         <div className="form-group tutorial-box i-will-input">
//                             <input
//                                 className="form-control input-lg"
//                                 name='professional_description'
//                                 value={professional_description}
//                                 onChange={this.onChange}
//                                 placeholder="do a beautiful and a minimal logo design your business."
//                                 maxLength={71}
//                             />
//                             <span className="prefix-unit">{iWillText}</span>
//                             {professional_description.length > 69 &&
//                             <span className="pull-right error">Maximum 70 character required. </span>
//                             }
//                             {errors['professional_description'] &&
//                             <span className='error'>{errors['professional_description']}</span>}
//                             <div className="tutorial-tips">
//                                 <p className="tutorial-para">Write an attractive short one-liner starting with I
//                                     will.</p>
//                                 <p className="tutorial-para">Example:</p>
//                                 <p className="tutorial-sub-para">{one_liner}</p>
//                             </div>
//                         </div>
//                         {this.gigNonPublicQuestionUi()}
//                     </div>
//                     <div className="clearfix"/>
//                     <div className="col-sm-8 gap-margin">
//                         {links_required > 0 &&
//                         this.proofOfWorkUi()
//                         }
//                     </div>
//                     {tutorial.length > 0 &&
//                     this.gigTutorialUi()
//                     }
//                     <div className="clearfix"/>
//                     <div className="col-sm-12">
//                         <button className="btn btn-save-profile-gig"
//                                 onClick={() => this.saveProduct({is_desc: true})}
//                         >Save & Continue
//                         </button>
//                         {this.previewGigPage()}
//                     </div>
//
//                 </div>
//             </div>
//         )
//     }
//
//     render() {
//         const {
//             product,
//             product_details,
//             professionalProduct,
//             loading,
//             isProductVerified,
//             price,
//             samples_migrated,
//             links_with_industry
//         } = this.state
//         const {name, links_required} = product_details || {}
//         const {history, professional, is_super_user} = this.props
//         const {location} = history
//         let {hash, search, pathname} = location
//         return (
//             <div className="row">
//                 <div className="col-md-12 d-parent-common-top no-padding">
//                     <div className="create-gig-title" style={{boxShadow: 'none'}}>
//                         <p><a style={{fontSize: '14px', color: '#000'}}
//                               onClick={() => history.goBack()}
//                         >
//                             <i className="fa fa-long-arrow-left"/> Create Gigs
//                         </a>  &gt; <a style={{color: '#ff337d'}}>{name}</a></p>
//                         <p className="d-profile-heading text-center">{name}</p>
//                     </div>
//                     {loading &&
//                     <div className='lazy-loader' style={{position: 'fixed', zIndex: '10'}}>
//                         <LoadingComponent pastDelay/>
//                     </div>}
//                     <div className="create-gig-title">
//                         <ul className="list-inline gig-process-tab">
//                             {tabs.map((details, index) => {
//                                 const {tab_name, tab_id} = details
//                                 // if (pathname === path.createLegalAndAccountingGig && index === 3) {
//                                 //     return ''
//                                 // }
//                                 return <li
//                                     className={`${`#${tab_id}` === hash ? 'active ' : ' '}${(index > 0 && links_with_industry.length < links_required || !professionalProduct && index > 0 || index > 1 && samples_migrated.length < 1 || index > 2 && !price) ? 'disable-tab' : ''}`}
//                                     key={tab_id}
//                                 >
//                                     <a
//                                         onClick={(e) => {
//                                             hash = `#${tab_id}`
//                                             history.replace({
//                                                 search,
//                                                 hash: `#${tab_id}`
//                                             })
//                                         }}
//                                         data-toggle="tab"
//                                         href={`#${tab_id}`}>{(pathname === path.createLegalAndAccountingGig && index === 1 ? 'Gig Graphics' : tab_name)}</a>
//                                 </li>
//                             })}
//                         </ul>
//                     </div>
//                     {product && professional.id &&
//                     <Fragment>
//                         <div className="tab-content">
//                             {tabs.map((details, index) => {
//                                 const {tab_id} = details
//                                 let tabName = `${tab_id}`
//                                 // if (pathname === path.createLegalAndAccountingGig && index === 3) {
//                                 //     return ''
//                                 // }
//                                 return <div id={tabName}
//                                             className={`tab-pane ${hash === `#${tab_id}` ? 'in active' : 'fade'}`}
//                                             key={tab_id}
//                                 >
//                                     {index === 0 && <Fragment>
//                                         {this.titleAndDesUi()}
//                                     </Fragment>}
//                                     {index === 1 &&
//                                     <div className="d-update-gig-price">
//                                         {this.sampleUi()}
//                                         {/*{this.videoSampleUi()}*/}
//                                         {/*{pathname !== path.createLegalAndAccountingGig &&*/}
//                                         {/*<Fragment>*/}
//                                         {/*{this.externalLinksUi()}*/}
//                                         {/*</Fragment>*/}
//                                         {/*}*/}
//                                         <br/>
//                                         <button className="btn btn-save-profile-gig"
//                                                 onClick={() => this.saveProduct({is_gig_gallery: true})}
//                                         >Save
//                                         </button>
//                                         {this.previewGigPage()}
//
//                                     </div>
//                                     }
//                                     {index === 2 &&
//                                     <Fragment>
//                                         {this.whatsIncludedQuestionsUi()}
//                                     </Fragment>
//                                     }
//                                     {/*{index === 3 &&*/}
//                                     {/*<div className="d-update-gig-price">*/}
//                                     {/*<div className="row">*/}
//                                     {/*<div className="col-md-8">*/}
//                                     {/*{this.nonPortfolioSamples()}*/}
//                                     {/*{pathname !== path.createLegalAndAccountingGig &&*/}
//                                     {/*<Fragment>*/}
//                                     {/*{this.nonPublicLinksUi()}*/}
//                                     {/*</Fragment>*/}
//                                     {/*}*/}
//                                     {/*<br/>*/}
//                                     {/*<button className="btn btn-save-profile-gig"*/}
//                                     {/*onClick={this.saveProduct}*/}
//                                     {/*>Save*/}
//                                     {/*</button>*/}
//                                     {/*</div>*/}
//                                     {/*<div className="col-sm-4">*/}
//                                     {/*<div className="embed-responsive embed-responsive-16by9">*/}
//                                     {/*<iframe className="embed-responsive-item"*/}
//                                     {/*src="https://www.youtube.com/embed/e2ctKktg1VI"*/}
//                                     {/*frameBorder="0"*/}
//                                     {/*allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"*/}
//                                     {/*allowFullScreen style={{border: '2px solid #979797'}}>*/}
//                                     {/*</iframe>*/}
//                                     {/*</div>*/}
//                                     {/*</div>*/}
//                                     {/*</div>*/}
//                                     {/*</div>*/}
//                                     {/*}*/}
//                                     {index === 3 &&
//                                     <div className="d-update-gig-price">
//                                         <div className="row">
//                                             <div className="col-md-6 col-sm-offset-3 text-center">
//                                                 <h4 className="heading-onboarding text-center">Hi, we hope you have
//                                                     created an amazing Gig. </h4>
//                                                 <button className="btn btn-see-profile-gig"
//                                                         onClick={(e) => {
//                                                             const {professional_details} = this.state
//                                                             const {slug_new} = professional_details
//                                                             window.open(`${mainDomain}/${freelancers}/${slug_new}/${product_details.slug_new}?isFromDashboard=${true}`, '_blank')
//                                                         }}
//                                                 >Preview Gig Page
//                                                 </button>
//                                                 <p>&#x00A0;</p>
//                                                 <p>&#x00A0;</p>
//                                                 <h4 className="heading-onboarding text-center">Submit for Approval</h4>
//                                                 <p className="para">Workbeam quality checks the Gigs for deliverables,
//                                                     descriptions, samples, graphics and content and we then make it live
//                                                     for public. </p>
//                                                 <button className="btn btn-submit-gig"
//                                                     // onClick={() => history.goBack()}
//                                                         onClick={() => {
//                                                             if (samples_migrated.length > 0) {
//                                                                 confirmAlert({
//                                                                     title: 'Submit For Approval',
//                                                                     message: 'Are you sure to Submit For Approval?',
//                                                                     buttons: [
//                                                                         {
//                                                                             label: 'Yes',
//                                                                             onClick: () => {
//                                                                                 let request = {request_verified: true}
//                                                                                 this.submitForApprovalProduct(professionalProduct, request)
//                                                                             }
//                                                                         },
//                                                                         {
//                                                                             label: 'No',
//                                                                             onClick: () => {
//                                                                             }
//                                                                         }
//                                                                     ]
//                                                                 })
//                                                             } else {
//                                                                 alert('Please complete public portfolio tab')
//                                                             }
//                                                         }
//                                                         }
//                                                 >Submit
//                                                 </button>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     }
//
//                                 </div>
//                             })}
//                         </div>
//                         {is_super_user &&
//                         <div className="create-gig-title text-center">
//                             <Checkbox
//                                 checked={isProductVerified}
//                                 color="primary"
//                                 onChange={() => {
//                                     this.setState({isProductVerified: !isProductVerified})
//                                 }
//                                 }
//                             />
//                             <span style={{marginRight: '20px'}}>{isProductVerified ? 'Live' : 'Not Live'}</span>
//                             <button
//                                 className='btn btn-book-now'
//                                 style={{marginTop: '0px'}}
//                                 onClick={() => this.saveProduct({isAdmin: is_super_user})}
//                             >Make Live
//                             </button>
//                         </div>
//                         }
//                     </Fragment>
//                     }
//                 </div>
//             </div>
//         );
//     }
// }

// const mapDispatchToProps = (dispatch) => ({
//     ...(errorActionDispatchers(dispatch)),
//     onUpdateAProfessionalKey: (action) => {
//         dispatch(action);
//     },
// })
//
// const mapStateToProps = state => ((state) => {
//     let {professional, is_super_user} = state.data
//     if (professional.id === undefined) {
//         professional = ''
//     }
//     return {
//         professional,
//         is_super_user,
//         hasGst: !!(JSON.parse(localStorage.logedInUserDetails).pro_gst_number)
//     }
//
// });
//
// export default connect(mapStateToProps, mapDispatchToProps)(CreateDefaultGig)
export const multi = 'multi'
export const yesno = 'yesno'
export const single = 'single'
export const _text = 'text'
export const doesNotInclude = 'Does not include'
export const whatsInclude = 'Other inclusions'

const typeOfVideo = [
    '2d',
    '3d',
    'Whiteboard',
]

const subjectExpertiseLevel = [
    'Advanced Subject Expertise ( Subject Matter Expert )',
    'Generic Subject Expertise',
    'Intermediate Subject Expertise',
]