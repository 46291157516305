import React, {Component} from "react";
import ProposalDetailsWithMilestone from "./ProposalDetailsWithMilestone";
import {errorActionDispatchers} from "../../actions/errors";
import connect from "react-redux/es/connect/connect";
import {getAvatar, getFirstAndLastLetterOfName} from "../common/userAndProfessionalProfileFunctions";
import {GetFirstNameAndLastName} from "../../functions/common";

class SeeFullJobDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            currentProposal: {},
            jobMilestone: [],
            jobQuestions: []
        }
    }

    senderProfilePic = (user_details) => {
        const {first_name, last_name, client} = user_details
        let {avatar} = client || {}
        let imageUrl = getAvatar(avatar);
        return (
            <div className="media posting-flex-box">
                <div className="media-left media-middle">
                    {imageUrl ? (<img src={imageUrl} alt="user" className="img-circle p-expert-feed-dp"/>)
                        :
                        getFirstAndLastLetterOfName(first_name, last_name)
                    }
                </div>
                <div className="media-body">
                    <h4 className="p-expert-name"><GetFirstNameAndLastName firstname={first_name} lastname={last_name}/>
                    </h4>
                </div>
            </div>
        )
    };

    render() {
        const {jobDetails, proposalId, dataTargetId, jobQuestions, jobMilestone} = this.props;
        let {user_details} = jobDetails || {}
        user_details = user_details || {}
        // const {currentProposal} = this.state;
        return (
            <div className="modal job_detail fade" id={dataTargetId !== undefined ? dataTargetId : proposalId}
                 role="dialog">
                <div className="modal-dialog dialog-top-margin">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="job-posting-panel job-posting-panel-modal" key={proposalId}
                                 style={{boxShadow: 'none', border: 'none'}}>
                                <div className="job-posting-panel-body">
                                    {this.senderProfilePic(user_details)}
                                    {(jobDetails !== undefined) && (
                                        <ProposalDetailsWithMilestone jobDetails={jobDetails}
                                                                      jobMilestone={jobMilestone}
                                                                      jobQuestions={jobQuestions}/>)}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-save-profile-gig" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch) => ({
    ...(errorActionDispatchers(dispatch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SeeFullJobDetails)
