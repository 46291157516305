import * as types from "../constants/auth";

const initialState = {
    user: {},
    professional: {}
};

export default function counter_party (state = initialState, action) {
    var out = {
        ...state
    };
    switch (action.type) {
        case types.USER_DETAILS:
            out.user = action.payload;
            return out;
        case types.PROFESSIONAL_DETAILS:
            out.professional = action.payload;
            return out;
        default:
            return state
    }
}