import * as types from '../constants/auth'

export const sessionLogin = (authToken) => {
    return {
        type: types.AUTH_TOKEN,
        payload: authToken
    }
};
export const registration = (loginData) => {
    return {
        type: types.SIGNUP,
        payload: loginData
    }
};

export const sessionLogout = () => {
    return {
        type: types.LOGOUT,
        payload: null
    }
};

export const userDetails = (data) => {
    return {
        type: types.DETAILS,
        payload: data
    }
};

export const updateProfilePic = (data) => {
    return {
        type: types.UPLOAD_PROFILEPIC,
        payload: data
    }
};
export const updatePhone = (phoneNo) => {
    return {
        type: types.UPDATE_USER_PHONE,
        payload: phoneNo
    }
};
export const updateProfessionalPhone = (phoneNo) => {
    return {
        type: types.UPDATE_PROFESSIONAL_PHONE,
        payload: phoneNo
    }
};
export const updateBankDetails = (bankDetails) => {
    return {
        type: types.UPDATE_PROFESSIONAL_BANK_DETAILS,
        payload: bankDetails
    }
};

export const onSuperUser = (data) => {
    return {
        type: types.SUPER_USER,
        payload: data
    }
};
export const onStaffUser = (data) => {
    return {
        type: types.SUPER_USER,
        payload: data
    }
};

export const storeGetTransactionAmount = (data) => {
    return {
        type: types.ACCOUNTING,
        payload: data
    }
};

export const updateReferralClaimedMoney = (data) => {
    return {
        type: types.CLAIM_REFERRED_MONEY,
        payload: data
    }
};

export const updateProfessionalPractiseArea = (data) => {
    return {
        type: types.UPDATE_PRACTISE_AREA,
        payload: data
    }
};
export const updateProfessionalKey = ({value, key}) => {
    return {
        type: types.UPDATE_A_PROFESSIONAL_KEY,
        payload: value,
        key
    }
};