import React from "react";
import {professionalType} from './../../constants/businessPractiseArea'
import Header from "../functional/beforeHire"
import {baseUrl} from "./../../constants/common";
import {_in_, category} from "../../constants/keyWord";

export const siteLogoOnSharedMedia = `https://workbeam.blob.core.windows.net/wiz-public-cdn/images/logowhatsapp.png`
export const commonLineForMetaDescription = `100% refund protection, pay in instalments, track service delivery with dedicated support.`
export const placeholder = 'placeholder'
export const webDevelopmentPractiseArea = [112]
export const MarketingPractiseArea = [212]

export const parentPracticeAreaSlug = {
    1: {
        parent_practise_are_id: 1,
        name: 'Income Tax & GST services',
        slug: 'income-tax-gst-services',
        "title": "Income Tax Services | GST Services - Workbeam",
        "meta_description": "Income Tax Services | GST Services - Workbeam",
        default_gig_creation_validator: {},
        tutorial: []
    },
    2: {
        parent_practise_are_id: 2,
        name: 'Legal',
        slug: 'lawsuits-and-disputes',
        title: "Legal",
        meta_description: "Legal",
        default_gig_creation_validator: {},
        tutorial: []
    },
    3: {
        parent_practise_are_id: 3,
        name: 'ROC Compliance Services',
        slug: 'roc-compliance-services',
        title: "ROC Compliance Services - Workbeam",
        meta_description: "ROC Compliance Services - Workbeam",
        default_gig_creation_validator: {},
        tutorial: []
    },
    4: {
        parent_practise_are_id: 4,
        name: 'Trademark Registration Services',
        slug: 'trademark-registration-services',
        title: "Trademark Registration Services Online - Workbeam",
        meta_description: "Trademark Registration Services Online - Workbeam",
        default_gig_creation_validator: {},
        tutorial: []
    },
    5: {
        parent_practise_are_id: 5,
        name: 'Business Registration Services',
        slug: 'business-registration-services',
        title: "Business Registration Services Online - Workbeam",
        meta_description: "Business Registration Services Online - Workbeam",
        default_gig_creation_validator: {},
        tutorial: []
    },
    6: {
        parent_practise_are_id: 6,
        name: 'Contracts and Agreements',
        slug: 'contracts-and-agreements',
        title: "Contracts and Agreements",
        meta_description: "Contracts and Agreements",
        default_gig_creation_validator: {},
        tutorial: []
    },
    7: {
        parent_practise_are_id: 7,
        name: 'Fundraising and Esop Compliance',
        slug: 'fundraising-and-esop-compliance',
        title: "Fundraising and Esop Compliance - Workbeam",
        meta_description: "Fundraising and Esop Compliance - Workbeam",
        default_gig_creation_validator: {},
        tutorial: []
    },
    106: {
        parent_practise_are_id: 106,
        name: 'Business License Services',
        slug: 'business-license-services',
        title: "Business License Services - Workbeam",
        meta_description: "Business License Services - Workbeam",
        default_gig_creation_validator: {},
        tutorial: []
    },
    110: {
        parent_practise_are_id: 110,
        name: 'Graphics Design',
        slug: 'graphics-design',
        title: "Graphics Design Services | Trusted Freelance Graphic Designers - Workbeam",
        meta_description: "Graphics Design Services | Trusted Freelance Graphic Designers - Workbeam",
        default_gig_creation_validator: {
            is_framework_required: false,
            is_industry_required: true,
            is_link_required: false,
            is_doc_required: true,
            is_client_name_required: true,
            is_client_email_required: false,
            is_client_phone_required: false,
            is_type_of_video_required: false,
            is_project_cost_required: true
        },
        default_gig_place_holder: {
            industry_placeholder: 'Select Industry to which project belongs',
            doc_placeholder: 'Upload Design ( PNG, JPEG )',
        },
        tutorial: [[{header: 'Your proof of work is the real judge of your skills & competency. At Workbeam, we want to match right Customers with right Experts for \n' +
                'the best experience. We are strictly against faking someone else’s portfolio or work.',
            children: ['Please dont upload stock design/videos or samples. We shall not approve the gig.', 'Please upload proof of work for all industries/subjects you have expertise in', 'Leads apply filters on Workbeam for industries to filter right Design/Animation Experts.', 'This will help us match your Gigs to right customers in right industry/domain filters']
        }]]
    },
    111: {
        parent_practise_are_id: 111,
        name: 'Video Editing & Animation services',
        slug: 'video-editing-animation-services',
        title: "Video Editing Services | Video Animation Services - Workbeam",
        meta_description: "Video Editing Services | Video Animation Services - Workbeam",
        default_gig_creation_validator: {
            is_framework_required: false,
            is_industry_required: true,
            is_link_required: false,
            is_doc_required: true,
            is_client_name_required: true,
            is_client_email_required: false,
            is_client_phone_required: false,
            is_type_of_video_required: true,
            is_project_cost_required: true
        },
        default_gig_place_holder: {
            type_of_video_placeholder: '',
            industry_placeholder: 'Select Industry to which project belongs',
            doc_placeholder: 'Upload video',
        },
        tutorial: []
    },
    112: {
        parent_practise_are_id: 112,
        name: 'Web Design & Development',
        slug: 'website-design-development-services',
        title: "Web Design and Development Services - Workbeam",
        description: "Web Design and Development Services - Workbeam",
        default_gig_creation_validator: {
            is_framework_required: true,
            is_industry_required: true,
            is_link_required: true,
            is_doc_required: true,
            is_client_name_required: true,
            is_client_email_optional: true,
            is_client_email_required: false,
            is_client_phone_required: false,
            is_project_cost_required: true
        },
        default_gig_place_holder: {
            framework_placeholder: 'Framework/CMS/Language used',
            industry_placeholder: ' Select Industry to which Website/App belongs',
            link_placeholder: 'Link of Website Developed by you',
            doc_placeholder: 'Upload Screenshot',
        },
        tutorial: [[{header: 'Your proof of work is the real judge of your skills & competency. At Workbeam, we want to match right Customers with right Experts for the best experience. We are strictly against faking someone else’s portfolio or work.',
            children: ['To get your Gig displayed in Frameworks you have expertise in, please add at least 2 proof of work for each framework.', 'Leads apply filters on Workbeam for CMS and Frameworks to filter right Experts.' ,'Please upload proof of work for all web frameworks you have expertise in.', 'This will show your Gig in filter of that particular Framework.', 'For example, \n' +
            'If you have expertise in \n' +
            '\n' +
            '- Wordpress\n' +
            '- Shopify \n' +
            '- PHP \n' +
            '\n' +
            'Upload atleast 3 proof of work for all frameworks for leads to find you in these frameworks and book your services.']
        }]]
    },
    136: {
        parent_practise_are_id: 136,
        name: 'Ecommerce Services',
        slug: 'ecommerce-services',
        title: "Ecommerce Services - Workbeam",
        meta_description: "Ecommerce Services - Workbeam",
        default_gig_creation_validator: {
            is_framework_required: false,
            is_industry_required: true,
            is_link_required: true,
            is_doc_required: false,
            is_client_name_required: true,
            is_client_email_required: false,
            is_client_phone_required: false,
            is_type_of_video_required: false,
            is_project_cost_required: true
        },
        default_gig_place_holder: {
            framework_placeholder: '',
            industry_placeholder: 'Select Industry to which project belongs',
            link_placeholder: 'Link of Amazon/Flipkart Account or Website',
            doc_placeholder: ' Upload Video',
            subject_expertise_level_placeholder: '',
        },
        tutorial: [[{header: 'Your proof of work is the real judge of your skills & competency. At Workbeam, we want to match right Customers with right Experts for the best experience. We are strictly against faking someone else’s portfolio or work.',
            children: ['Please upload proof of work for all industries/subjects you have expertise in', 'This will show your Gig in filter of that particular industry/subject', 'Share links of amazon account, flipkart accounts and Ecommerce websites for whom you provide Ecommerce services.']
        }]
        ]
    },
    184: {
        parent_practise_are_id: 184,
        name: 'Content Writing services',
        slug: 'content-writing-services',
        title: "Content Writing Services - Workbeam",
        meta_description: "Content Writing Services - Workbeam",
        default_gig_creation_validator: {
            is_framework_required: false,
            is_industry_required: true,
            is_link_required: false,
            is_link_optional: true,
            is_doc_required: true,
            is_client_name_required: true,
            is_client_email_optional: true,
            is_client_email_required: false,
            is_client_phone_required: false,
            is_subject_expertise_level_required: true,
            is_project_cost_required: true,
            allowed_doc_type: `image/*, video/*, .pdf, .docx`
        },
        default_gig_place_holder: {
            framework_placeholder: '',
            industry_placeholder: 'Select Industry to which Content belongs',
            link_placeholder: 'Google Drive Link/Live Content Link (Optional)',
            doc_placeholder: 'Upload PDF or DOC of Content Written',
            subject_expertise_level_placeholder: ''
        },
        tutorial: [[{header: 'Your proof of work is the real judge of your skills & competency. At Workbeam, we want to match right Customers with right Experts for the best experience.',
            children: ['Please upload proof of work for all industries/domains you have expertise in', 'Leads apply filters on Workbeam for industries to filter right Content Writers.', 'This will help us match your Gigs to right customers in right industry/domain filters', 'Select the subject expertise as per your knowledge and experience in that subject or industry']
        }],
        ]
    },
    212: {
        parent_practise_are_id: 212,
        name: 'Digital Marketing Services',
        slug: 'digital-marketing-services',
        title: "Digital Marketing Services - Workbeam",
        meta_description: "Digital Marketing Services - Workbeam",
        default_gig_creation_validator: {
            is_framework_required: false,
            is_industry_required: true,
            is_link_optional: true,
            is_link_required: false,
            is_doc_required: true,
            is_client_name_required: true,
            is_client_email_optional: true,
            is_client_email_required: false,
            is_client_phone_required: false,
            is_subject_expertise_level_required: true,
            is_project_cost_required: true,
            allowed_doc_type: `image/*, video/*, .pdf, .docx`
        },
        default_gig_place_holder: {
            framework_placeholder: '',
            industry_placeholder: ' Select Industry to which project belongs',
            link_placeholder: 'Google Drive Link/Live Website Link (Optional)',
            doc_placeholder: ' Upload PDF/DOC/Image of Case Studies',
            subject_expertise_level_placeholder: ''
        },
        tutorial: [[{header: 'Your proof of work is the real judge of your skills & competency. At Workbeam, we want to match right Customers with right Experts for \n' +
                'the best experience.',
            children: ['Please upload proof of work for all industries/domains you have expertise in', 'Leads apply filters on Workbeam for industries to filter right Marketing Experts.', 'This will help us match your Gigs to right customers in right industry/domain filters', 'Share Marketing case studies showing campaign results or in case of SMM share accounts link being managed']
        }]
        ]
    }
}

export const parentPracticeAreaSlugForPath = {
    'income-tax-gst-services': {
        name: 'Income Tax & GST services',
        slug: 'income-tax-gst-services',
        parent_practise_are_id: 1
    },
    'lawsuits-and-disputes': {
        name: 'Legal',
        slug: 'lawsuits-and-disputes',
        parent_practise_are_id: 2
    },
    'roc-compliance-services': {
        name: 'ROC Compliance Services',
        slug: 'roc-compliance-services',
        parent_practise_are_id: 3
    },
    'trademark-registration-services': {
        name: 'Trademark Registration Services',
        slug: 'trademark-registration-services',
        parent_practise_are_id: 4
    },
    'business-registration-services': {
        name: 'Business Registration Services',
        slug: 'business-registration-services',
        parent_practise_are_id: 5
    },
    'contracts-and-agreements': {
        name: 'Contracts and Agreements',
        slug: 'contracts-and-agreements',
        parent_practise_are_id: 6
    },
    'fundraising-and-esop-compliance': {
        name: 'Fundraising and Esop Compliance',
        slug: 'fundraising-and-esop-compliance',
        parent_practise_are_id: 7
    },
    'business-license-services': {
        name: 'Business License Services',
        slug: 'business-license-services',
        parent_practise_are_id: 106
    },
    'graphics-design': {
        name: 'Graphics Design',
        slug: 'graphics-design',
        parent_practise_are_id: 110
    },
    'video-editing-animation-services': {
        name: 'Video Editing & Animation services',
        slug: 'video-editing-animation-services',
        parent_practise_are_id: 111
    },
    'website-design-development-services': {
        name: 'Web Design & Development',
        slug: 'website-design-development-services',
        parent_practise_are_id: 112
    },
    'ecommerce-services': {
        name: 'Ecommerce Services',
        slug: 'ecommerce-services',
        parent_practise_are_id: 136
    },
    'content-writing-services': {
        name: 'Content Services',
        slug: 'content-writing-services',
        parent_practise_are_id: 184
    },
    'digital-marketing-services': {
        name: 'Digital Marketing Services',
        slug: 'digital-marketing-services',
        parent_practise_are_id: 212
    }
}

export const productSlugMappedWithId = {
    "find-a-lawyer": {
        "product_id": 1,
    },
    "find-lawyer-near-you": {
        "product_id": 2,
    },
    "need-a-lawyer": {
        "product_id": 3,
    },
    "income-tax-filing-salaried-income": {
        "product_id": 4,
    },
    "income-tax-filing-professional-freelancer": {
        "product_id": 5,
    },
    "accounting-bookkeeping-services-online": {
        "product_id": 6,
    },
    "monthly-accounting-bookkeeping": {
        "product_id": 7,
    },
    "tax-consultation": {
        "product_id": 8,
    },
    "private-limited-company-registration-services": {
        "product_id": 9,
    },
    "llp-registration-services-online": {
        "product_id": 10,
    },
    "partnership-firm-registration-basic": {
        "product_id": 11,
    },
    "one-person-company-registration-online": {
        "product_id": 12,
    },
    "sole-proprietorship-registration-services": {
        "product_id": 13,
    },
    "ngo-section-8-company-registration-services": {
        "product_id": 14,
    },
    "company-strike-off-name-removal": {
        "product_id": 15,
    },
    "annual-roc-filing-services": {
        "product_id": 16,
    },
    "annual-roc-compliance-services": {
        "product_id": 17,
    },
    "income-tax-filing-capital-gains-income": {
        "product_id": 18,
    },
    "bookkeeping-services-online": {
        "product_id": 19,
    },
    "apply-gst-registration-online": {
        "product_id": 20,
    },
    "trademark-registartion-for-companies": {
        "product_id": 21,
    },
    "trademark-registration-services-for-small-businesses": {
        "product_id": 22,
    },
    "licensing-agreement": {
        "product_id": 23,
    },
    "web-agreements-terms-of-use-privacy-policy": {
        "product_id": 24,
    },
    "franchisee-agreement": {
        "product_id": 25,
    },
    "intellectual-property-assignment-agreement": {
        "product_id": 26,
    },
    "sale-deed-agreement": {
        "product_id": 27,
    },
    "vendor-service-contract": {
        "product_id": 28,
    },
    "shareholding-agreement": {
        "product_id": 29,
    },
    "term-sheet-equity-convertibledebt": {
        "product_id": 30,
    },
    "founders-agreement": {
        "product_id": 31,
    },
    "debt-financing-loan-agreement": {
        "product_id": 32,
    },
    "employee-stock-option-policy": {
        "product_id": 33,
    },
    "employment-agreement": {
        "product_id": 34,
    },
    "gst-return-filing-services": {
        "product_id": 35,
    },
    "rent-agreement-online": {
        "product_id": 36,
    },
    "non-disclosure-agreement-nda-online": {
        "product_id": 37,
    },
    "income-tax-filing-online-small-business": {
        "product_id": 41,
    },
    "gst-filing": {
        "product_id": 42,
    },
    "gst-filing-online": {
        "product_id": 43,
    },
    "consumer-law-legal-notice": {
        "product_id": 49,
    },
    "mutual-divorce-lawyers": {
        "product_id": 50,
    },
    "rera-delayed-possession-legal-notice": {
        "product_id": 51,
    },
    "rera-property-lawyers": {
        "product_id": 52,
    },
    "digital-signature-certificate-class-2": {
        "product_id": 53,
    },
    "digital-signature-certificate-class-3": {
        "product_id": 54,
    },
    "digital-signature-for-foreign-citizens-and-nri": {
        "product_id": 55,
    },
    "dir-3-kyc-services": {
        "product_id": 56,
    },
    "reserve-company-name": {
        "product_id": 57,
    },
    "import-export-code-online": {
        "product_id": 58,
    },
    "apply-import-export-code": {
        "product_id": 59,
    },
    "apply-fssai-license-registration-online": {
        "product_id": 60,
    },
    "fssai-license-state": {
        "product_id": 61,
    },
    "fssai-license-central": {
        "product_id": 62,
    },
    "professional-tax-registration-services": {
        "product_id": 63,
    },
    "apply-msme-registration-online": {
        "product_id": 64,
    },
    "shop-establishment-act-license": {
        "product_id": 65,
    },
    "logo-design-services": {
        "product_id": 66,
    },
    "product-packaging-box-label-design-services": {
        "product_id": 67,
    },
    "design-social-media-graphics": {
        "product_id": 68,
    },
    "menu-design-services": {
        "product_id": 69,
    },
    "invitation-card-design-services": {
        "product_id": 70,
    },
    "photoshop-editing-services": {
        "product_id": 71,
    },
    "flyer-leaflet-design-services": {
        "product_id": 72,
    },
    "brochure-design-services": {
        "product_id": 73,
    },
    "standee-design": {
        "product_id": 74,
    },
    "mobile-app-designers": {
        "product_id": 75,
    },
    "web-ui-design-services": {
        "product_id": 76,
    },
    "logo-animation-services": {
        "product_id": 77,
    },
    "video-editing-services": {
        "product_id": 78,
    },
    "voice-over-services": {
        "product_id": 79,
    },
    "marketing-promotional-video-services": {
        "product_id": 80,
    },
    "product-photographers": {
        "product_id": 81,
    },
    "sell-on-amazon-services": {
        "product_id": 82,
    },
    "sell-on-flipkart-services": {
        "product_id": 83,
    },
    "amazon-seller-account-management-services": {
        "product_id": 84,
    },
    "flipkart-seller-account-management-services": {
        "product_id": 85,
    },
    "digital-marketing-services/uk-vat-registration": {
        "product_id": 86,
    },
    "amazon-account-reinstatement": {
        "product_id": 87,
    },
    "product-listing-cataloging": {
        "product_id": 88,
    },
    "ecommerce-website-design-development-services": {
        "product_id": 89,
    },
    "blog-website-design-services": {
        "product_id": 90,
    },
    "business-website-development-services": {
        "product_id": 91,
    },
    "business-card-stationary-design": {
        "product_id": 92,
    },
    "income-tax-filing-for-salaried-multiple-form-16": {
        "product_id": 93,
    },
    "income-tax-filing-for-ngos": {
        "product_id": 94,
    },
    "income-tax-filing-for-traders": {
        "product_id": 95,
    },
    "accounting-services": {
        "product_id": 97,
    },
    "monthly-accounting-compliance-package": {
        "product_id": 99,
    },
    "accounting-compliance-services-standard": {
        "product_id": 100,
    },
    "accounting-compliance-services-premium": {
        "product_id": 101,
    },
    "gst-filing-online-premium": {
        "product_id": 102,
    },
    "gst3b-filing": {
        "product_id": 103,
    },
    "catalog-design-services": {
        "product_id": 104,
    },
    "book-album-cover-design": {
        "product_id": 105,
    },
    "infographic-design": {
        "product_id": 106,
    },
    "partnership-firm-registration-services": {
        "product_id": 107,
    },
    "basic-amazon-photo-editing": {
        "product_id": 108,
    },
    "amazon-photo-editing-experts": {
        "product_id": 109,
    },
    "animated-explainer-video-services": {
        "product_id": 110,
    },
    "social-media-video": {
        "product_id": 111,
    },
    "demo-videos": {
        "product_id": 112,
    },
    "monthly-gst-filing-services-nil-return": {
        "product_id": 113
    },
    "android-app-development-services": {
        "product_id": 114,
    },
    "website-content-writing-services": {
        "product_id": 115,
    },
    "travel-food-content-writing-services": {
        "product_id": 116,
    },
    "fashion-lifestyle-content-writers": {
        "product_id": 117,
    },
    "technical-content-writers": {
        "product_id": 118,
    },
    "finance-content-writers": {
        "product_id": 119,
    },
    "legal-content-writers": {
        "product_id": 120,
    },
    "business-blog-writing-services": {
        "product_id": 121,
    },
    "legal-opinion": {
        "product_id": 122,
    },
    "design-website-banners": {
        "product_id": 123,
    },
    "translation-services": {
        "product_id": 124,
    },
    "ecommerce-product-descriptions": {
        "product_id": 125,
    },
    "proofreading-editing-services": {
        "product_id": 126,
    },
    "copywriting-social-media-content-writers": {
        "product_id": 127,
    },
    "social-media-marketing-services": {
        "product_id": 128,
    },
    "facebook-ads-services": {
        "product_id": 129,
    },
    "google-ads-services": {
        "product_id": 130,
    },
    "roc-compliance-opc": {
        "product_id": 131,
    },
    "tds-return-filing": {
        "product_id": 132,
    },
    "tds-filing": {
        "product_id": 133,
    },
    "income-tax-filing-for-small-business": {
        "product_id": 134,
    },
    "seo-audit-services": {
        "product_id": 148,
    },
    "seo-services": {
        "product_id": 149,
    },
    "scriptwriting-services": {
        "product_id": 150
    },
    "website-hosting-services": {
        "product_id": 152,
    },
    "website-optimisation-services": {
        "product_id": 153,
    },
    "website-maintenance": {
        "product_id": 154,
    },
    "game-design-services": {
        "product_id": 172,
    },
    "merchandise-tshirt-design-services": {
        "product_id": 173,
    },
    "email-writers": {
        "product_id": 174,
    },
    "academic-writers": {
        "product_id": 175,
    },
    "business-presentation-specialist": {
        "product_id": 176,
    },
    "multivendor-ecommerce-website-development": {
        "product_id": 177,
    },
    "illustration-graphic-design-services": {
        "product_id": 178,
    },
    "income-tax-filing-freelancers-professionals": {
        "product_id": 5,
        // is_old: 'yes'
    },
    "accounting-services-small-business": {
        "product_id": 7,
        // is_old: 'yes'
    },
    "pvt-ltd-company-registration": {
        "product_id": 9,
        // is_old: 'yes'
    },
    "llp-registration-online": {
        "product_id": 10,
        // is_old: 'yes'
    },
    "sole-proprietorship-registration-india": {
        "product_id": 13,
        // is_old: 'yes'
    },
    "ngo-section-8-company-registration-online": {
        "product_id": 14,
        // is_old: 'yes'
    },
    "annul-roc-filing": {
        "product_id": 16,
        // is_old: 'yes'
    },
    "annual-roc-compliance": {
        "product_id": 17,
        // is_old: 'yes'
    },
    "gst-registration-online": {
        "product_id": 20,
        // is_old: 'yes'
    },
    "trademark-registration-for-company": {
        "product_id": 21,
        // is_old: 'yes'
    },
    "trademark-registration-for-individuals-freelancers": {
        "product_id": 22,
        // is_old: 'yes'
    },
    "gst-return-filing-online": {
        "product_id": 35,
        // is_old: 'yes'
    },
    "dsc-generation-india": {
        "product_id": 53,
        // is_old: 'yes'
    },
    "dsc-generation-Class3": {
        "product_id": 54,
        // is_old: 'yes'
    },
    "dsc-generation-nri-foriegn": {
        "product_id": 55,
        // is_old: 'yes'
    },
    "dir3-kyc-Verification": {
        "product_id": 56,
        // is_old: 'yes'
    },
    "Import-export-code": {
        "product_id": 59,
        // is_old: 'yes'
    },
    "fssai-license-basic": {
        "product_id": 60,
        // is_old: 'yes'
    },
    "professional-tax-registration": {
        "product_id": 63,
        // is_old: 'yes'
    },
    "msme-registration": {
        "product_id": 64,
        // is_old: 'yes'
    },
    "logo-design": {
        "product_id": 66,
        // is_old: 'yes'
    },
    "package-design": {
        "product_id": 67,
        // is_old: 'yes'
    },
    "social-media-design": {
        "product_id": 68,
        // is_old: 'yes'
    },
    "menu-design-for-restaurants-cafes": {
        "product_id": 69,
        // is_old: 'yes'
    },
    "invitation-card-design": {
        "product_id": 70,
        // is_old: 'yes'
    },
    "photoshop-editing": {
        "product_id": 71,
        // is_old: 'yes'
    },
    "flyer-design": {
        "product_id": 72,
        // is_old: 'yes'
    },
    "brochure-design": {
        "product_id": 73,
        // is_old: 'yes'
    },
    "mobile-app-design": {
        "product_id": 75,
        // is_old: 'yes'
    },
    "website-design": {
        "product_id": 76,
        // is_old: 'yes'
    },
    "logo-animation": {
        "product_id": 77,
        // is_old: 'yes'
    },
    "video-editing": {
        "product_id": 78,
        // is_old: 'yes'
    },
    "voice-over-artists-online": {
        "product_id": 79,
        // is_old: 'yes'
    },
    "social-media-video-experts": {
        "product_id": 80,
        // is_old: 'yes'
    },
    "product-photography": {
        "product_id": 81,
        // is_old: 'yes'
    },
    "amazon-seller-account": {
        "product_id": 82,
        // is_old: 'yes'
    },
    "flipkart-seller-account": {
        "product_id": 83,
        // is_old: 'yes'
    },
    "Amazon-Seller-Account-Management": {
        "product_id": 84,
        // is_old: 'yes'
    },
    "Flipkart-Seller-Account-Management": {
        "product_id": 85,
        // is_old: 'yes'
    },
    "UK-VAT-Registration": {
        "product_id": 86,
        // is_old: 'yes'
    },
    "Amazon-Seller-Account-Reinstatement": {
        "product_id": 87,
        // is_old: 'yes'
    },
    "Ecommerce-Website-Development": {
        "product_id": 89,
        // is_old: 'yes'
    },
    "blog-website": {
        "product_id": 90,
        // is_old: 'yes'
    },
    "website-development": {
        "product_id": 91,
        // is_old: 'yes'
    },
    "Business-Card-Design": {
        "product_id": 92,
        // is_old: 'yes'
    },
    "Income-tax-filing-salaried-multiple-Form16": {
        "product_id": 93,
        // is_old: 'yes'
    },
    "income-tax-filing-ngo": {
        "product_id": 94,
        // is_old: 'yes'
    },
    "income-tax-filing-traders": {
        "product_id": 95,
        // is_old: 'yes'
    },
    "accounting-compliance-services-basic": {
        "product_id": 99,
        // is_old: 'yes'
    },
    "book-cover-design": {
        "product_id": 105,
        // is_old: 'yes'
    },
    "amazon-photo-editing": {
        "product_id": 108,
        // is_old: 'yes'
    },
    "product-explainer-video": {
        "product_id": 110,
        // is_old: 'yes'
    },
    "gst-return-filing-small-business": {
        "product_id": 113,
        // is_old: 'yes'
    },
    "android-mobile-app": {
        "product_id": 114,
        // is_old: 'yes'
    },
    "website-content-writers": {
        "product_id": 115,
        // is_old: 'yes'
    },
    "travel-content-writers": {
        "product_id": 116,
        // is_old: 'yes'
    },
    "technology-content-writers": {
        "product_id": 118,
        // is_old: 'yes'
    },
    "tax-finance-content-writers": {
        "product_id": 119,
        // is_old: 'yes'
    },
    "general-business-blogs": {
        "product_id": 121,
        // is_old: 'yes'
    },
    "website-banners-design": {
        "product_id": 123,
        // is_old: 'yes'
    },
    "translation-services-india": {
        "product_id": 124,
        // is_old: 'yes'
    },
    "ecommerce-product-description": {
        "product_id": 125,
        // is_old: 'yes'
    },
    "proofreading-services": {
        "product_id": 126,
        // is_old: 'yes'
    },
    "social-media-content-writing": {
        "product_id": 127,
        // is_old: 'yes'
    },
    "social-media-marketing-agencies-experts": {
        "product_id": 128,
        // is_old: 'yes'
    },
    "facebook-ads-experts": {
        "product_id": 129,
        // is_old: 'yes'
    },
    "google-ads-experts": {
        "product_id": 130,
        // is_old: 'yes'
    },
    "tds-filing-online": {
        "product_id": 132,
        // is_old: 'yes'
    },
    "itr-filing-online-small-business": {
        "product_id": 134,
        // is_old: 'yes'
    },
    "seo-audit-report": {
        "product_id": 148,
        // is_old: 'yes'
    },
    "complete-seo-package": {
        "product_id": 149,
        // is_old: 'yes'
    },
    "website-hosting-and-SSL": {
        "product_id": 152,
        // is_old: 'yes'
    },
    "website-optimisation": {
        "product_id": 153,
        // is_old: 'yes'
    },
}


export const productIdMappedWithSlug = {
    1: {
        "product_id": 1,
        "title": "Legal Advice Online from Verified Lawyers in India | Workbeam",
        "meta_description": "Book legal Consultation with Verified Lawyers online instantly. Get legal advice online or through a meeting. Attorney search made easy & affordable.",
        name: '',
        display_name: '',
        slug: '',
    },
    2: {
        "product_id": 2,
        "title": "Find Lawyers in India for Legal Services | Workbeam",
        "meta_description": "Find & hire Lawyers online for various Legal Services at affordable prices. Get Payment Insurance and dedicated legal manager.",
        name: '',
        display_name: '',
        slug: '',
    },
    3: {
        "product_id": 3,
        "title": "Find Lawyers In India | Hire Lawyers near you | Workbeam",
        "meta_description": "Find Lawyers near you to fight your case in the court. Find an attorney as per your budget and legal problem. Get Payment Insurance and dedicated legal manager.",
        name: '',
        display_name: '',
        slug: '',
    },
    4: {
        "product_id": 4,
        "title": "Income Tax Filing  for Salaried Income - Workbeam",
        "meta_description": `Looking for income tax filing  - salaried income? Visit Workbeam.com and book verified income tax consultants. ${commonLineForMetaDescription}`,
        name: 'Income Tax Filing  - Salaried Income',
        display_name: 'Income Tax Filing  - Salaried Income',
        slug: 'income-tax-filing-salaried-income'
    },
    5: {
        "product_id": 5,
        "title": "Income Tax Filing for Professional | Income Tax Filing for Freelancers - Workbeam",
        "meta_description": `Looking for income tax filing - professional or freelancer? Visit Workbeam.com and book verified income tax consultants. ${commonLineForMetaDescription}`,
        name: 'Income Tax Filing - Professional/Freelancer',
        display_name: 'Income Tax Filing - Professional/Freelancer',
        slug: 'income-tax-filing-professional-freelancer',
    },
    6: {
        "product_id": 6,
        "title": "Accountants for small business | Freelance Accountants | Workbeam",
        "meta_description": "Find Accountants online for accounting for small businesses. Share files, Track Accounting Process, Get Reports and Payment Insurance.",
        name: '',
        display_name: '',
        slug: '',
    },
    7: {
        "product_id": 7,
        "title": "Monthly Accounting Services | Monthly Bookkeeping Services - Workbeam",
        "meta_description": `Looking for monthly accounting and bookkeeping? Visit Workbeam.com and book verified chartered accountants. ${commonLineForMetaDescription}`,
        name: 'Monthly Accounting & Bookkeeping',
        display_name: 'Monthly Accounting & Bookkeeping',
        slug: 'monthly-accounting-bookkeeping',
    },
    8: {
        "product_id": 8,
        "title": "Tax Consultation Online | Chartered Accountants Near Me | Workbeam",
        "meta_description": "Find Accountants near you for Accounting and Bookkeeping services online. Share files, Track Accounting Process, Get Reports and Payment Insurance",
        name: '',
        display_name: '',
        slug: '',
    },
    9: {
        "product_id": 9,
        "title": "Private Limited Company Registration Services - Workbeam",
        "meta_description": `Looking for private limited company registration services? Visit Workbeam.com and book verified private limited company registration consultants. ${commonLineForMetaDescription}`,
        name: 'Private Limited Company Registration Services',
        display_name: 'Private Limited Company Registration',
        slug: 'private-limited-company-registration-services',
    },
    10: {
        "product_id": 10,
        "title": "LLP Registration Online | LLP Firm Registration - Workbeam",
        "meta_description": `Looking for LLP registration services online? Visit Workbeam.com and book verified LLP registration consultants. ${commonLineForMetaDescription}`,
        name: 'LLP Registration Services Online',
        display_name: 'LLP Registration Services',
        slug: 'llp-registration-services-online',
    },
    12: {
        "product_id": 12,
        "title": "One Person Company Registration Online | OPC Registration - Workbeam",
        "meta_description": `Looking for one person company registration online? Visit Workbeam.com and book verified one person company registration consultants. ${commonLineForMetaDescription}`,
        name: 'One Person Company Registration Online',
        display_name: 'One Person Company Registration',
        slug: 'one-person-company-registration-online',
    },
    13: {
        "product_id": 13,
        "title": "Sole Proprietorship Registration Services | Proprietorship Firm Registration - Workbeam",
        "meta_description": `Looking for sole proprietorship registration services? Visit Workbeam.com and book verified sole proprietorship registration consultants. ${commonLineForMetaDescription}`,
        name: 'Sole Proprietorship Registration Services',
        display_name: 'Sole Proprietorship Registration',
        slug: 'sole-proprietorship-registration-services',
    },
    14: {
        "product_id": 14,
        "title": "NGO Company Registration | Section 8 Company Registration - Workbeam",
        "meta_description": `Looking for NGO/Section 8 company registration services? Visit Workbeam.com and book verified NGO registration consultants. ${commonLineForMetaDescription}`,
        name: 'NGO/Section 8 Company Registration Services',
        display_name: 'NGO/Section 8 Company Registration',
        slug: 'ngo-section-8-company-registration-services',
    },
    15: {
        "product_id": 15,
        "title": "Company Name Strike Off | Company name Removal Services - Workbeam",
        "meta_description": `Looking for company strike off or compnay name removal? Visit Workbeam.com and book verified company name removal experts. ${commonLineForMetaDescription}`,
        name: 'Company Strike Off/Name Removal',
        display_name: 'Company Strike Off/Name Removal',
        slug: 'company-strike-off-name-removal',
    },
    16: {
        "product_id": 16,
        "title": "Annual ROC Filing Services - Workbeam",
        "meta_description": `Looking for annual ROC filing services? Visit Workbeam.com and book verified annual ROC filing service consultant. ${commonLineForMetaDescription}`,
        name: 'Annual ROC Filing Services',
        display_name: 'Annual ROC Filing Services',
        slug: 'annual-roc-filing-services',
    },
    17: {
        "product_id": 17,
        "title": "Annual ROC Compliance Services - Workbeam",
        "meta_description": `Looking for annual ROC compliance services? Visit Workbeam.com and book verified annual ROC compliance service consultant. ${commonLineForMetaDescription}`,
        name: 'Annual ROC Compliance Services',
        display_name: 'Annual ROC Compliance Services',
        slug: 'annual-roc-compliance-services',
    },
    18: {
        "product_id": 18,
        "title": "Income Tax  Filing  for Capital Gains Income - Workbeam",
        "meta_description": `Looking for income tax  filing  - capital gains income? Visit Workbeam.com and book verified income tax consultants. ${commonLineForMetaDescription}`,
        name: 'Income Tax  Filing  - Capital Gains Income',
        display_name: 'Income Tax  Filing  - Capital Gains Income',
        slug: 'income-tax-filing-capital-gains-income',
    },
    19: {
        "product_id": 19,
        "title": "Accounting & Bookkeeping Services online by verified Experts | Workbeam",
        "meta_description": "Find Accountants & Bookkeepers online who will duly maintain your books, prepare profit & Loss account and Balance Sheet.",
        name: '',
        display_name: '',
        slug: '',
    },
    20: {
        "product_id": 20,
        "title": "Apply GST Registration Online - Workbeam",
        "meta_description": `Looking to apply GST registration online? Visit Workbeam.com and book verified GST consultants. ${commonLineForMetaDescription}`,
        name: 'Apply GST Registration Online',
        display_name: 'Apply GST Registration Online',
        slug: 'apply-gst-registration-online',
    },
    21: {
        "product_id": 21,
        "title": "Trademark Registartion for Companies - Workbeam",
        "meta_description": `Looking for trademark registartion for companies? Visit Workbeam.com and book verified trademark registartion consultant. ${commonLineForMetaDescription}`,
        name: 'Trademark Registration for Companies',
        display_name: 'Trademark Registration for Companies',
        slug: 'trademark-registartion-for-companies',
    },
    22: {
        "product_id": 22,
        "title": "Trademark Registration Services for Small Businesses - Workbeam",
        "meta_description": `Looking for trademark registration services for small businesses? Visit Workbeam.com and book verified trademark registration consultant. ${commonLineForMetaDescription}`,
        name: 'Trademark Registration Services for Small Business',
        display_name: 'Trademark Registration Services for Small Business',
        slug: 'trademark-registration-services-for-small-businesses',
    },
    23: {
        "product_id": 23,
        "title": "Licensing Agreement Drafting | Workbeam",
        "meta_description": "Hire Verified Lawyers to draft Licensing agreement for you. Share files, Track drafting process, get dedicated support & payment insurance.",
        name: '',
        display_name: '',
        slug: '',
    },
    24: {
        "product_id": 24,
        "title": "Web Agreements - Terms of Use & Privacy Policy Drafting | Workbeam",
        "meta_description": "Hire Verified technology Lawyers to draft Terms of Use & privacy policy for your Website. Share files, Track drafting process, get dedicated support & payment insurance.",
        name: '',
        display_name: '',
        slug: '',
    },
    25: {
        "product_id": 25,
        "title": "Franchisee Agreement Drafting | Workbeam",
        "meta_description": "Hire verified Lawyers to draft Franchisee agreement for you. Share files, Track drafting process, get dedicated support & payment insurance.",
        name: '',
        display_name: '',
        slug: '',
    },
    26: {
        "product_id": 26,
        "title": "Intellectual Property Assignment Contract Drafting | Workbeam",
        "meta_description": "Hire Verified IPR Lawyers to draft IPR agreement for your business. Share files, Track drafting process, get dedicated support & payment insurance.",
        name: '',
        display_name: '',
        slug: '',
    },
    27: {
        "product_id": 27,
        "title": "Sales/Purchase Deed or Agreement drafting | Workbeam",
        "meta_description": "Hire verified Lawyers to draft Sale/Purchase agreement. Share files, Track drafting process, get dedicated support & payment insurance.",
        name: '',
        display_name: '',
        slug: '',
    },
    28: {
        "product_id": 28,
        "title": "Vendor Contract Agreement Drafting | Workbeam",
        "meta_description": "Get your vendors to take work relationship seriously. Bind them with a secure vendor contract. Hire Experts to get Vendor Agreement drafted.",
        name: '',
        display_name: '',
        slug: '',
    },
    29: {
        "product_id": 29,
        "title": "Shareholding Agreement Drafting by Corporate Lawyers | Workbeam",
        "meta_description": "Hire verified Corporate Lawyers to draft your Shareholding agreement. Track drafting process, get dedicated support & payment insurance.",
        name: '',
        display_name: '',
        slug: '',
    },
    30: {
        "product_id": 30,
        "title": "Get your Term Sheet drafted by verified Lawyers | Workbeam",
        "meta_description": "Hire verified Startup & Corporate Lawyers for Term Sheet drafting. Track drafting process, get dedicated support & payment insurance.",
        name: '',
        display_name: '',
        slug: '',
    },
    31: {
        "product_id": 31,
        "title": "Founder's Agreement Drafting for Startups | Workbeam",
        "meta_description": "Hire verified Startup & Corporate Lawyers for Term Sheet drafting. Track drafting process, get dedicated support & payment insurance.",
        name: '',
        display_name: '',
        slug: '',
    },
    32: {
        "product_id": 32,
        "title": "Debt Financing/Loan Agreement Drafting | Workbeam",
        "meta_description": "Hire verified Lawyers to draft Loan Agreement. Share files, Track drafting process, get dedicated support & payment insurance.",
        name: '',
        display_name: '',
        slug: '',
    },
    33: {
        "product_id": 33,
        "title": "Get your Employee Stock Option Policy in Place | Workbeam",
        "meta_description": "Hire verified Startup & Corporate Lawyers for drafting ESOP Policy. Track drafting process, get dedicated support & payment insurance.",
        name: '',
        display_name: '',
        slug: '',
    },
    34: {
        "product_id": 34,
        "title": "Employment Agreement Drafting by Verified Lawyers Workbeam",
        "meta_description": "Hire verified Startup & Corporate Lawyers for drafting Employment Agreement. Track drafting process, get dedicated support & payment insurance.",
        name: '',
        display_name: '',
        slug: '',
    },
    35: {
        "product_id": 35,
        "title": "GST Return Filing Services | GST Return Filing Online - Workbeam",
        "meta_description": `Looking for GST return filing services? Visit Workbeam.com and book verified GST consultants. ${commonLineForMetaDescription}`,
        name: 'Monthly GST Return Filing',
        display_name: 'Monthly GST Return Filing',
        slug: 'gst-return-filing-services',
    },
    36: {
        "product_id": 36,
        "title": "Get Rent agreement drafted. Avoid Landlord Tenant Dispute. | Workbeam",
        "meta_description": "Hire verified Experts for drafting your Rent Agreement. Track Drafting process, share files, get dedicated support & payment insurance.",
        name: '',
        display_name: '',
        slug: '',
    },
    37: {
        "product_id": 37,
        "title": "Get NDA drafted. Secure confidential Information about your business",
        "meta_description": "Hire verified Experts for drafting Non Disclosure Agreement. Track Drafting process, share files, get dedicated support & payment insurance.",
        name: '',
        display_name: '',
        slug: '',
    },
    41: {
        "product_id": 41,
        "title": "Income Tax Filing for Small Business - Workbeam",
        "meta_description": "Book ITR Filing Online and Tax Preparation Services with verified Tax Experts with 100% payment protection, service assurance and delivery tracking.",
        name: '',
        display_name: '',
        slug: '',
    },
    42: {
        "product_id": 42,
        "title": "GST Filing by verified GST Experts | Workbeam",
        "meta_description": "Struggling with GST? Hire Verified CAs & GST Experts for GST Filing. Communicate, share files, track delivery with 100% Money Back Guarantee.",
        name: '',
        display_name: '',
        slug: '',
    },
    43: {
        "product_id": 43,
        "title": "GST Return Filing by Chartered Accountants | Workbeam",
        "meta_description": "Struggling with GST? Hire Verified CAs & GST Experts for GST Filing. Communicate, share files, track delivery with 100% Money Back Guarantee.",
        name: '',
        display_name: '',
        slug: '',
    },
    49: {
        "product_id": 49,
        "title": "Consumer protection Lawyers | Workbeam",
        "meta_description": "Got a faulty product or facing consumer harassment? Find and hire Lawyers to send Consumer Protection Notice.",
        name: '',
        display_name: '',
        slug: '',
    },
    50: {
        "product_id": 50,
        "title": "Find Lawyers near you for Mutual Divorce | Workbeam",
        "meta_description": "Hire Lawyers near you for Mutual Consent Divorce in your budget. Get dedicate Legal Manager, Case Support, Pay in Instalments and get 100% payment protection.",
        name: '',
        display_name: '',
        slug: '',
    },
    51: {
        "product_id": 51,
        "title": "Delayed Possession of Property | Workbeam",
        "meta_description": "Hire RERA and Property Lawyers to send legal notice for delayed possession of property. ",
        name: '',
        display_name: '',
        slug: '',
    },
    52: {
        "product_id": 52,
        "title": "Advance Refund and Delayed Possession | Workbeam",
        "meta_description": "Hire RERA and Property Lawyers to send legal notice for delayed possession of property and refund of advance amount.",
        name: '',
        display_name: '',
        slug: '',
    },
    53: {
        "product_id": 53,
        "title": "Digital Signature Certificate Class 2 | DSC Class 2 Services - Workbeam",
        "meta_description": `Looking for digital signature certificate class 2? Visit Workbeam.com and book verified digital signature certificate class 2 consultant. ${commonLineForMetaDescription}`,
        name: 'Digital Signature Certificate Class 2',
        display_name: 'Digital Signature Certificate Class 2',
        slug: 'digital-signature-certificate-class-2',
    },
    54: {
        "product_id": 54,
        "title": "Digital Signature Certificate Class 3 | DSC Class 3 Services - Workbeam",
        "meta_description": `Looking for digital signature certificate class 3? Visit Workbeam.com and book verified digital signature certificate class 3 consultant. ${commonLineForMetaDescription}`,
        name: 'Digital Signature Certificate Class 3',
        display_name: 'Digital Signature Certificate Class 3',
        slug: 'digital-signature-certificate-class-3',
    },
    55: {
        "product_id": 55,
        "title": "Digital Signature for Foreign Citizens | Digital Signature for NRI - Workbeam",
        "meta_description": `Looking for digital signature for foreign citizens and NRI? Visit Workbeam.com and book verified digital signature consultant. ${commonLineForMetaDescription}`,
        name: 'Digital Signature for Foreign citizens and NRI',
        display_name: 'Digital Signature for Foreign citizens and NRI',
        slug: 'digital-signature-for-foreign-citizens-and-nri',
    },
    56: {
        "product_id": 56,
        "title": "DIR 3 KYC Services - Workbeam",
        "meta_description": `Looking for DIR 3 KYC services? Visit Workbeam.com and book verified DIR 3 KYC consultant. ${commonLineForMetaDescription}`,
        name: 'DIR 3 KYC',
        display_name: 'DIR 3 KYC',
        slug: 'dir-3-kyc-services',
    },
    57: {
        "product_id": 57,
        "title": "Reserve Company Name - Workbeam",
        "meta_description": `Looking for reserve company name? Visit Workbeam.com and book verified consultancy. ${commonLineForMetaDescription}`,
        name: 'Reserve Company Name',
        display_name: 'Reserve Company Name',
        slug: 'reserve-company-name',
    },
    58: {
        "product_id": 58,
        "title": "Get Import Export Code with DSC online | Workbeam",
        "meta_description": "Import Export Code is mandatory to carry out any Import Export of goods in India. Get Import Export Code from verified Experts on Workbeam.",
        name: '',
        display_name: '',
        slug: '',
    },
    59: {
        "product_id": 59,
        "title": "Apply Import Export Code | Apply IEC Code Online - Workbeam",
        "meta_description": `Looking to apply import export code? Visit Workbeam.com and book verified import export consultant. ${commonLineForMetaDescription}`,
        name: 'Apply Import Export Code',
        display_name: 'Apply Import Export Code',
        slug: 'apply-import-export-code',
    },
    60: {
        "product_id": 60,
        "title": "Apply FSSAI License Registration Online - Workbeam",
        "meta_description": `Looking to apply FSSAI license registration online? Visit Workbeam.com and book verified FSSAI consultant. ${commonLineForMetaDescription}`,
        name: 'Apply FSSAI License Registration Online',
        display_name: 'FSSAI License Registration Online',
        slug: 'apply-fssai-license-registration-online',
    },
    61: {
        "product_id": 61,
        "title": "Get FSSAI State License online | Workbeam",
        "meta_description": "Hire verified Experts to get your Company reserved. Get dedicated support with 100% payment protection.",
        name: '',
        display_name: '',
        slug: '',
    },
    62: {
        "product_id": 62,
        "title": "Get Central FSSAI License online | Workbeam",
        "meta_description": "Hire verified Experts to get your Company reserved. Get dedicated support with 100% payment protection.",
        name: '',
        display_name: '',
        slug: '',
    },
    63: {
        "product_id": 63,
        "title": "Professional Tax Registration Services Online",
        "meta_description": `Looking for professional tax registration services? Visit Workbeam.com and book verified professional tax consultant. ${commonLineForMetaDescription}`,
        name: 'Professional Tax Registration Services',
        display_name: 'Professional Tax Registration Services',
        slug: 'professional-tax-registration-services',
    },
    64: {
        "product_id": 64,
        "title": "Apply MSME Registration Online | MSME Registration for Company - Workbeam",
        "meta_description": `Looking to apply MSME registration online? Visit Workbeam.com and book verified MSME registration consultant. ${commonLineForMetaDescription}`,
        name: 'Apply MSME Registration Online',
        display_name: 'Apply MSME Registration Online',
        slug: 'apply-msme-registration-online',
    },
    65: {
        "product_id": 65,
        "title": "Shop and Establishment Act License | Shop and Establishment Act Registration - Workbeam",
        "meta_description": `Looking for shop and establishment act license? Visit Workbeam.com and book verified shop establishment consultant. ${commonLineForMetaDescription}`,
        name: 'Shop & Establishment Act License',
        display_name: 'Shop & Establishment Act License',
        slug: 'shop-establishment-act-license',
    },
    66: {
        "product_id": 66,
        product_type: professionalType.designer,
        "title": "Logo Design Services by Top Rated Logo Designers - Workbeam",
        "meta_description": `Looking for logo design services? Visit Workbeam.com and book verified logo designers. ${commonLineForMetaDescription}`,
        name: 'Logo Design Services',
        display_name: 'Logo Design Services',
        slug: 'logo-design-services',
    },
    67: {
        "product_id": 67,
        product_type: professionalType.designer,
        "title": "Packaging Design Services | Box Design | Label Design Services - Workbeam",
        "meta_description": `Looking for packaging, label and box design services? Visit Workbeam.com and book verified packaging designers. ${commonLineForMetaDescription}`,
        name: 'Packaging, Label & Box Design Services',
        display_name: 'Packaging, Label & Box Design Services',
        slug: 'product-packaging-box-label-design-services',
    },
    68: {
        "product_id": 68,
        product_type: professionalType.designer,
        "title": "Social Media Graphics Design Services - Workbeam",
        "meta_description": `Looking for social media graphic design services? Visit Workbeam.com and book verified graphic designers. ${commonLineForMetaDescription}`,
        name: 'Design Social Media Graphics',
        display_name: 'Design Social Media Graphics',
        slug: 'design-social-media-graphics',
    },
    69: {
        "product_id": 69,
        product_type: professionalType.designer,
        "title": "Menu Design Services | Restaurant Menu Card Design - Workbeam",
        "meta_description": `Looking for menu design services? Visit Workbeam.com and book verified graphic designers. ${commonLineForMetaDescription}`,
        name: 'Menu Design Services',
        display_name: 'Menu Design Services',
        slug: 'menu-design-services',
    },
    70: {
        "product_id": 70,
        product_type: professionalType.designer,
        "title": "Beautiful Invitation Card Design by Top Graphic Designers | Workbeam",
        "meta_description": "Book Invitation Designs on Workbeam. 100+ Freelance Graphic Designers for hire. Book with 100% payment protection and sevice assurance.",
        name: 'Invitation Card Design Services',
        display_name: 'Invitation Card Design Services',
        slug: 'invitation-card-design-services',
    },
    71: {
        "product_id": 71,
        product_type: professionalType.designer,
        "title": "Photoshop Editing Retoucing services | Photoshop Expert - Workbeam",
        "meta_description": `Looking for photoshop editing services? Visit Workbeam.com and book verified photoshop expert. ${commonLineForMetaDescription}`,
        name: 'Photoshop Editing Services',
        display_name: 'Photoshop Editing Services',
        slug: 'photoshop-editing-services',
    },
    72: {
        "product_id": 72,
        product_type: professionalType.designer,
        "title": "Beautiful Flyer Design for Business | Workbeam",
        "meta_description": "Book Flyer and Brochure Design Services on Workbeam. 100+ Freelance Graphic Designers for hire. Book with 100% payment protection and sevice assurance",
        name: 'Flyer/leaflet Design Services',
        display_name: 'Flyer/leaflet Design Services',
        slug: 'flyer-leaflet-design-services',
    },
    73: {
        "product_id": 73,
        product_type: professionalType.designer,
        "title": "Brochure Design services | Brochure Designers - Workbeam",
        "meta_description": `Looking for brochure design services? Visit Workbeam.com and book verified brochure designers. ${commonLineForMetaDescription}`,
        name: 'Brochure Design Services',
        display_name: 'Brochure Design Services',
        slug: 'brochure-design-services',
    },
    74: {
        "product_id": 74,
        product_type: professionalType.designer,
        "title": "Standee Design Services for Business |  Workbeam",
        "meta_description": "Book Standee Design Services on Workbeam. 100+ Freelance Graphic Designers for hire. Book with 100% payment protection and sevice assurance",
        name: 'Standee Design',
        display_name: 'Standee Design',
        slug: 'standee-design',
    },
    75: {
        "product_id": 75,
        product_type: professionalType.designer,
        "title": "Web App UI Design Services - Workbeam",
        "meta_description": `Looking for web app designers? Visit Workbeam.com and book verified web app UI designers. ${commonLineForMetaDescription}`,
        name: 'Web App UI Design',
        display_name: 'Web App UI Design',
        slug: 'mobile-app-designers',
    },
    76: {
        "product_id": 76,
        product_type: professionalType.designer,
        "title": "Web and Mobile UI Design Services | UI Designers - Workbeam",
        "meta_description": `Looking for web UI design services? Visit Workbeam.com and book verified UI designers. ${commonLineForMetaDescription}`,
        name: 'Web UI Design Services',
        display_name: 'Web UI Design Services',
        slug: 'web-ui-design-services',
    },
    77: {
        "product_id": 77,
        product_type: professionalType.designer,
        "title": "Logo Animation Services | Logo Animation Experts - Workbeam",
        "meta_description": `Looking for logo animation services? Visit Workbeam.com and book verified logo animation experts. ${commonLineForMetaDescription}`,
        name: 'Logo Animation Services',
        display_name: 'Logo Animation Services',
        slug: 'logo-animation-services',
    },
    78: {
        "product_id": 78,
        product_type: professionalType.designer,
        "title": "Video Editing Services - Workbeam",
        "meta_description": `Looking for video editing services? Visit Workbeam.com and book verified video editors. ${commonLineForMetaDescription}`,
        name: 'Video Editing Services',
        display_name: 'Video Editing Services',
        slug: 'video-editing-services',
    },
    79: {
        "product_id": 79,
        product_type: professionalType.designer,
        "title": "Professional Voice Over Services | Voice Over Experts - Workbeam",
        "meta_description": `Looking for voice over services? Visit Workbeam.com and book voice over artists. ${commonLineForMetaDescription}`,
        name: 'Voice Over Services',
        display_name: 'Voice Over Services',
        slug: 'voice-over-services',
    },
    80: {
        "product_id": 80,
        product_type: professionalType.designer,
        "title": "Marketing Videos | Promotional Videos - Workbeam ",
        "meta_description": `Looking for marketing and promotional video services? Visit Workbeam.com and book verified visual designer desigers and animated video companies. ${commonLineForMetaDescription}`,
        name: 'Marketing & Promotional Video Services',
        display_name: 'Marketing & Promotional Video',
        slug: 'marketing-promotional-video-services',
    },
    81: {
        "product_id": 81,
        product_type: professionalType.designer,
        "title": "Product Photography Services | Product Photographers - Workbeam",
        "meta_description": `Looking for product photography services? Visit Workbeam.com and book verified freelance photographers. ${commonLineForMetaDescription}`,
        name: 'Product Photographers',
        display_name: 'Product Photographers',
        slug: 'product-photographers',
    },
    82: {
        "product_id": 82,
        product_type: professionalType.designer,
        "title": " Sell on Amazon Services | Open Amazon Seller Acount - Workbeam",
        "meta_description": `Looking for sell on Amazon services? Visit Workbeam.com and book verified Amazon seller experts. ${commonLineForMetaDescription}`,
        name: 'Sell on Amazon Services',
        display_name: 'Sell on Amazon Services',
        slug: 'sell-on-amazon-services',
    },
    83: {
        "product_id": 83,
        product_type: professionalType.designer,
        "title": "Open Flipkart Seller Account | Flipkart Seller Account Services - Workbeam",
        "meta_description": `Looking for Flipkart seller account? Visit Workbeam.com and book verified Flipkart seller consultants. ${commonLineForMetaDescription}`,
        name: 'Sell on Flipkart Services',
        display_name: 'Sell on Flipkart Services',
        slug: 'sell-on-flipkart-services',
    },
    84: {
        "product_id": 84,
        product_type: professionalType.designer,
        "title": "Amazon Seller Account Management Services | Amazon Seller Experts Workbeam",
        "meta_description": `Looking for Amazon seller account management services? Visit Workbeam.com and book verified amazon seller experts. ${commonLineForMetaDescription}`,
        name: 'Amazon Seller Account Management Services',
        display_name: 'Amazon Seller Account Management',
        slug: 'amazon-seller-account-management-services',
    },
    85: {
        "product_id": 85,
        product_type: professionalType.designer,
        "title": "Flipkart Account Management Services | Flipkart Account Consultant - Workbeam",
        "meta_description": `Looking for Flipkart account management services? Visit Workbeam.com and book verified Flipkart account consultants. ${commonLineForMetaDescription}`,
        name: 'Flipkart Seller Account Management Services',
        display_name: 'Flipkart Seller Account Management',
        slug: 'flipkart-seller-account-management-services',
    },
    86: {
        "product_id": 86,
        product_type: professionalType.designer,
        "title": "UK VAT Registration Services | UK VAT Consultants - Workbeam",
        "meta_description": `Looking for UK VAT registration? Visit Workbeam.com and book verified UK VAT consultants. ${commonLineForMetaDescription}`,
        name: 'UK VAT Registration',
        display_name: 'UK VAT Registration',
        slug: 'digital-marketing-services/uk-vat-registration',
    },
    87: {
        "product_id": 87,
        product_type: professionalType.designer,
        "title": "Amazon Account Reinstatement Services - Workbeam",
        "meta_description": `Looking for Amazon Account Reinstatement? Visit Workbeam.com and book verified amazon reinstatement specialist. ${commonLineForMetaDescription}`,
        name: 'Amazon Account Reinstatement',
        display_name: 'Amazon Account Reinstatement',
        slug: 'amazon-account-reinstatement',
    },
    88: {
        "product_id": 88,
        product_type: professionalType.designer,
        "title": "Product Listing Services | Cataloging Services - Workbeam",
        "meta_description": `Looking for product listing or cataloging? Visit Workbeam.com and book verified product listing experts. ${commonLineForMetaDescription}`,
        name: 'Product Listing/Cataloging',
        display_name: 'Product Listing/Cataloging',
        slug: 'product-listing-cataloging',
    },
    89: {
        "product_id": 89,
        product_type: professionalType.designer,
        "title": "Ecommerce Website Design Services | Ecommerce Development Services - Workbeam",
        "meta_description": `Looking for ecommerce website design and development services? Visit Workbeam.com and book verified web developers. ${commonLineForMetaDescription}`,
        name: 'Ecommerce Website Design & Development Services',
        display_name: 'Ecommerce Website Design & Development',
        slug: 'ecommerce-website-design-development-services',
    },
    90: {
        "product_id": 90,
        product_type: professionalType.designer,
        "title": "Blog Website Design Services - Workbeam",
        "meta_description": `Looking for blog website design services? Visit Workbeam.com and book verified blog developers. ${commonLineForMetaDescription}`,
        name: 'Blog Website Design Services',
        display_name: 'Blog Website Design',
        slug: 'blog-website-design-services',
    },
    91: {
        "product_id": 91,
        product_type: professionalType.designer,
        "title": "Business Website Development Services - Workbeam",
        "meta_description": `Looking for business website development services? Visit Workbeam.com and book verified website developers. ${commonLineForMetaDescription}`,
        name: 'Business Website Development Services',
        display_name: 'Business Website Development',
        slug: 'business-website-development-services',
    },
    92: {
        "product_id": 92,
        product_type: professionalType.designer,
        "title": "Business Card Design Services | Stationary Design Services - Workbeam",
        "meta_description": `Looking for business card & stationary design services? Visit Workbeam.com and book verified stationary designers. ${commonLineForMetaDescription}`,
        name: 'Business Card & Stationary Design',
        display_name: 'Business Card & Stationary Design',
        slug: 'business-card-stationary-design',
    },
    93: {
        "product_id": 93,
        "title": "Income Tax Filing for Salaried (Multiple Form 16) - Workbeam",
        "meta_description": `Looking for income tax filing for salaried - multiple form 16? Visit Workbeam.com and book verified income tax consultants. ${commonLineForMetaDescription}`,
        name: 'Income Tax Filing for Salaried - Multiple Form 16',
        display_name: 'Income Tax Filing for Salaried - Multiple Form 16',
        slug: 'income-tax-filing-for-salaried-multiple-form-16',
    },
    94: {
        "product_id": 94,
        "title": "Income Tax Filing for NGOs | NGO ITR Return FIling - Workbeam",
        "meta_description": "Income Tax Filing Service online for NGOs by verified Tax Experts with 100% payment protection, service assurance and delivery tracking.",
        name: 'Income Tax Filing for NGOs',
        display_name: 'Income Tax Filing for NGOs',
        slug: 'income-tax-filing-for-ngos',
    },
    95: {
        "product_id": 95,
        "title": "Income Tax Filing for NGOs | NGO ITR Return FIling - Workbeam",
        "meta_description": "Looking for income tax filing for NGOs? Visit Workbeam.com and book verified Income tax consultants. ${commonLineForMetaDescription}",
        name: 'Income Tax Filing for Traders',
        display_name: 'Income Tax Filing for Traders',
        slug: 'income-tax-filing-for-traders',
    },
    97: {
        "product_id": 97,
        "title": "Accounting & Bookkeeping Services online by verified Experts | Workbeam",
        "meta_description": "Find Accountants & Bookkeepers online who will duly maintain your books, prepare profit & Loss account and Balance Sheet.",
        name: '',
        display_name: '',
        slug: '',
    },
    99: {
        "product_id": 99,
        "title": "Monthly Accounting and Compliance Package - Workbeam",
        "meta_description": `Looking for monthly accounting and compliance package? Visit Workbeam.com and book verified chartered accountants. ${commonLineForMetaDescription}`,
        name: 'Monthly Accounting & Compliance Package',
        display_name: 'Monthly Accounting & Compliance Package',
        slug: 'monthly-accounting-compliance-package',
    },
    100: {
        "product_id": 100,
        "title": "Accounting & Bookkeeping Services online by verified Experts | Workbeam",
        "meta_description": "Find Accountants & Bookkeepers online who will duly maintain your books, prepare profit & Loss account and Balance Sheet.",
        name: '',
        display_name: '',
        slug: '',
    },
    101: {
        "product_id": 101,
        "title": "Accounting & Bookkeeping Services online by verified Experts | Workbeam",
        "meta_description": "Find Accountants & Bookkeepers online who will duly maintain your books, prepare profit & Loss account and Balance Sheet.",
        name: '',
        display_name: '',
        slug: '',
    },
    102: {
        "product_id": 102,
        "title": "GST Registration online by GST Experts | Workbeam",
        "meta_description": "Get GST Registration Online by verified GST Experts at affordable prices.",
        name: '',
        display_name: '',
        slug: '',
    },
    103: {
        "product_id": 103,
        "title": "GST Registration online by GST Experts | Workbeam",
        "meta_description": "Get GST Registration Online by verified GST Experts at affordable prices.",
        name: '',
        display_name: '',
        slug: '',
    },
    104: {
        "product_id": 104,
        product_type: professionalType.designer,
        "title": "Catalog Design Services | Catalog Designers - Workbeam",
        "meta_description": `Looking for catalog design services? Visit Workbeam.com and book verified catalog designers. ${commonLineForMetaDescription}`,
        name: 'Catalog Design Services',
        display_name: 'Catalog Design Services',
        slug: 'catalog-design-services',
    },
    105: {
        "product_id": 105,
        product_type: professionalType.designer,
        "title": "Book Cover Desing Services | Album Cover Design Services - Workbeam",
        "meta_description": `Looking fto book or album cover design services? Visit Workbeam.com and book verified designers. ${commonLineForMetaDescription}`,
        name: 'Book/Album Cover Design',
        display_name: 'Book/Album Cover Design',
        slug: 'book-album-cover-design',
    },
    106: {
        "product_id": 106,
        product_type: professionalType.designer,
        "title": "Infographic Design Services - Workbeam",
        "meta_description": `Looking for infographic design services? Visit Workbeam.com and book verified infographic designers. ${commonLineForMetaDescription}`,
        name: 'Infographic Design',
        display_name: 'Infographic Design',
        slug: 'infographic-design',
    },
    107: {
        "product_id": 107,
        "title": "Partnership Firm Registration Online - Workbeam",
        "meta_description": `Looking for partnership firm registration online? Visit Workbeam.com and book verified partnership firm registration consultants. ${commonLineForMetaDescription}`,
        name: 'Partnership Firm Registration Services',
        display_name: 'Partnership Firm Registration Services',
        slug: 'partnership-firm-registration-services',
    },
    108: {
        "product_id": 108,
        product_type: professionalType.designer,
        "title": "Basic Amazon Photo Editing Services - Workbeam",
            "meta_description": `Looking for basic Amazon photo editing services? Visit Workbeam.com and book verified basic Amazon photo editor. ${commonLineForMetaDescription}`,
        name: 'Basic Amazon Photo Editing',
        display_name: 'Basic Amazon Photo Editing',
        slug: 'basic-amazon-photo-editing',
    },
    109: {
        "product_id": 109,
        product_type: professionalType.designer,
        "title": "Basic Amazon Photo Editing Services - Workbeam",
        "meta_description": "Amazon Photo Editing Services by Freelance Graphic Designers in your budget. 4.9 Rating, get 100% payment protection, pay in instalments, service assurance and service delivery tracking.",
        name: '',
        display_name: '',
        slug: '',
    },
    110: {
        "product_id": 110,
        product_type: professionalType.designer,
        "title": "Animated Explainer Videos | Explainer Video Company - Workbeam",
        "meta_description": `Looking for animated explainer video services? Visit Workbeam.com and book verified visual designers and explainer video companies. ${commonLineForMetaDescription}`,
        name: 'Animated Explainer Video Services',
        display_name: 'Animated Explainer Video Services',
        slug: 'animated-explainer-video-services',
    },
    112: {
        "product_id": 111,
        product_type: professionalType.designer,
        "title": "Website and Product Demo Videos by Top Video Designers | Workbeam",
        "meta_description": "Mobile & Website Demo Videos to market your business. Explore verified Video Designers on Workbeam and book service with 100% payment protection and service assurance.",
        name: '',
        display_name: '',
        slug: '',
    },
    113: {
        "product_id": 113,
        "title": "Monthly GST Filing services ( NIL Return ) - Workbeam",
        "meta_description": `Looking for monthly GST filing services ( NIL Return )? Visit Workbeam.com and book verified GST consultants. ${commonLineForMetaDescription}`,
        name: 'Monthly GST Filing Services ( NIL Return )',
        display_name: 'Monthly GST Filing Services ( NIL Return )',
        slug: 'monthly-gst-filing-services-nil-return',
    },
    114: {
        "product_id": 114,
        product_type: professionalType.designer,
        "title": "Android App Development Services - Workbeam",
        "meta_description": `Looking for android app development services? Visit Workbeam.com and book verified android app developers. ${commonLineForMetaDescription}`,
        name: 'Android App Development Services',
        display_name: 'Android App Development Services',
        slug: 'android-app-development-services',
    },
    115: {
        "product_id": 115,
        product_type: professionalType.designer,
        "title": "Website Content Writing Services - Workbeam",
        "meta_description": `Looking for website content writing services? Visit Workbeam.com and book verified website writers. ${commonLineForMetaDescription}`,
        name: 'Website Content Writing Services',
        display_name: 'Website Content Writing Services',
        slug: 'website-content-writing-services',
    },
    116: {
        "product_id": 116,
        product_type: professionalType.designer,
        "title": "Travel Content Writing Services | Food Content Writing Services - Workbeam",
        "meta_description": `Looking for travel and food content writing services? Visit Workbeam.com and book travel and food writer. ${commonLineForMetaDescription}`,
        name: 'Travel & Food Content Writing Services',
        display_name: 'Travel & Food Content Writing',
        slug: 'travel-food-content-writing-services',
    },
    117: {
        "product_id": 117,
        product_type: professionalType.designer,
        "title": "Fashion Content Writing Services | Lifestyle Content Writing Services - Workbeam",
        "meta_description": `Looking for fashion and lifestyle content writers? Visit Workbeam.com and book verified fashion and lifestyle writers. ${commonLineForMetaDescription}`,
        name: 'Fashion & Lifestyle Content Writers',
        display_name: 'Fashion & Lifestyle Content Writers',
        slug: 'fashion-lifestyle-content-writers',
    },
    118: {
        "product_id": 118,
        product_type: professionalType.designer,
        "title": "Technical Content Writers | Technology Content Writing Services - Workbeam",
        "meta_description": `Looking for technical content writers? Visit Workbeam.com and book verified technical writers. ${commonLineForMetaDescription}`,
        name: 'Technical Content Writers',
        display_name: 'Technical Content Writers',
        slug: 'technical-content-writers',
    },
    119: {
        "product_id": 119,
        product_type: professionalType.designer,
        "title": "Finance Writers | Finance Content Writing Services - Workbeam",
        "meta_description": `Looking for finance content writers? Visit Workbeam.com and book verified finance writers. ${commonLineForMetaDescription}`,
        name: 'Finance Content Writers',
        display_name: 'Finance Content Writers',
        slug: 'finance-content-writers',
    },
    120: {
        "product_id": 120,
        product_type: professionalType.designer,
        "title": "Legal Content Writers | Legal Content Writing Services - Workbeam",
        "meta_description": `Looking for legal content writers? Visit Workbeam.com and book verified legal writer. ${commonLineForMetaDescription}`,
        name: 'Legal Content Writers',
        display_name: 'Legal Content Writers',
        slug: 'legal-content-writers',
    },
    121: {
        "product_id": 121,
        product_type: professionalType.designer,
        "title": "Business Blog Writing Services | Blog Writing Services - Workbeam",
        "meta_description": `Looking for business blog writing services? Visit Workbeam.com and book verified business writers. ${commonLineForMetaDescription}`,
        name: 'Business Blog Writing services',
        display_name: 'Business Blog Writing services',
        slug: 'business-blog-writing-services',
    },
    122: {
        "product_id": 122,
        "title": "Find Lawyers in India for Legal Services | Workbeam",
        "meta_description": "Find & hire Lawyers online for various Legal Services at affordable prices. Get Payment Insurance and dedicated legal manager.",
        name: '',
        display_name: '',
        slug: '',
    },
    123: {
        "product_id": 123,
        product_type: professionalType.designer,
        "title": "Website Banner Design Services - Workbeam",
        "meta_description": `Looking for design website banners services? Visit Workbeam.com and book verified graphic designers. ${commonLineForMetaDescription}`,
        name: 'Design Website Banners',
        display_name: 'Design Website Banners',
        slug: 'design-website-banners',
    },
    124: {
        "product_id": 124,
        product_type: professionalType.designer,
        "title": "Content Translation Services - Workbeam",
        "meta_description": `Looking for translation services? Visit Workbeam.com and book verified content translators. ${commonLineForMetaDescription}`,
        name: 'Translation Services',
        display_name: 'Translation Services',
        slug: 'translation-services',
    },
    125: {
        "product_id": 125,
        product_type: professionalType.designer,
        "title": "High Selling Ecommerce Product Descriptions - Workbeam",
        "meta_description": `Looking for ecommerce product descriptions? Visit Workbeam.com and book verified ecommerce writer. ${commonLineForMetaDescription}`,
        name: 'Ecommerce Product Descriptions',
        display_name: 'Ecommerce Product Descriptions',
        slug: 'ecommerce-product-descriptions',
    },
    126: {
        "product_id": 126,
        product_type: professionalType.designer,
        "title": "Proofreading and Editing Services - Workbeam",
        "meta_description": `Looking for proof reading and editing services? Visit Workbeam.com and book verified proofreader. ${commonLineForMetaDescription}`,
        name: 'Proof Reading & Editing Services',
        display_name: 'Proof Reading & Editing Services',
        slug: 'proofreading-editing-services',
    },
    127: {
        "product_id": 127,
        product_type: professionalType.designer,
        "title": "Copywriting Services | Hire Copywriters | Social Media Content Writers - Workbeam",
        "meta_description": `Looking for copywriting social media content writers? Visit Workbeam.com and book verified Social Media Writers. ${commonLineForMetaDescription}`,
        name: 'Copywriting & Social Media Content',
        display_name: 'Copywriting & Social Media Content',
        slug: 'copywriting-social-media-content-writers',
    },
    128: {
        "product_id": 128,
        product_type: professionalType.designer,
        "title": "Social Media Marketing Services - Workbeam",
        "meta_description": `Looking for social media marketing services? Visit Workbeam.com and book verified social media marketer. ${commonLineForMetaDescription}`,
        name: 'Social Media Marketing Services',
        display_name: 'Social Media Marketing',
        slug: 'social-media-marketing-services',
    },
    129: {
        "product_id": 129,
        product_type: professionalType.designer,
        "title": "Facebook Ads Services | Facebook Ads Experts - Workbeam",
        "meta_description": `Looking for Facebook ads services? Visit Workbeam.com and book verified Facebook ads expert. ${commonLineForMetaDescription}`,
        name: 'Facebook Ads Services',
        display_name: 'Facebook Ads Services',
        slug: 'facebook-ads-services',
    },
    130: {
        "product_id": 130,
        product_type: professionalType.designer,
        "title": "Google Ads Services | Google Ads Experts - Workbeam",
        "meta_description": `Looking for Google ads services? Visit Workbeam.com and book verified Google ads experts. ${commonLineForMetaDescription}`,
        name: 'Google Ads Services',
        display_name: 'Google Ads Services',
        slug: 'google-ads-services',
    },
    131: {
        "product_id": 131,
        "title": "ROC Compliance services in India | Workbeam",
        "meta_description": "Hire Verified CAs & ROC Experts for ROC filing in your budget. Communicate, share files, track work delivery with 100% money-back guarantee.",
        name: '',
        display_name: '',
        slug: '',
    },
    132: {
        "product_id": 132,
        "title": "Online TDS Return Filing - Workbeam",
        "meta_description": `Looking for TDS return filing? Visit Workbeam.com and book verified tax consultants. ${commonLineForMetaDescription}`,
        name: '',
        display_name: 'TDS Return Filing',
        slug: 'tds-return-filing',
    },
    133: {
        "product_id": 133,
        "title": "TDS Filing services in India | Workbeam",
        "meta_description": "TDS Filing services by verified Chartered Accountants. Get dedicated Tax support, track filing process and pay online securely.",
        name: '',
        display_name: '',
        slug: '',
    },
    134: {
        "product_id": 134,
        "title": "Income Tax Filing for Small Business - Workbeam",
        "meta_description": `Looking for income tax filing for small business? Visit Workbeam.com and book verified income tax consultants. ${commonLineForMetaDescription}`,
        name: 'Income Tax Filing for Small Business',
        display_name: 'Income Tax Filing for Small Business',
        slug: 'income-tax-filing-for-small-business',
    },
    148: {
        "product_id": 148,
        product_type: professionalType.designer,
        "title": "SEO Audit Services | SEO Consultants - Workbeam",
        "meta_description": `Looking for SEO audit services? Visit Workbeam.com and book verified SEO consultants. ${commonLineForMetaDescription}`,
        name: 'SEO Audit Services',
        display_name: 'SEO Audit Services',
        slug: 'seo-audit-services',
    },
    149: {
        "product_id": 149,
        product_type: professionalType.designer,
        "title": "SEO Services - Workbeam",
        "meta_description": `Looking for SEO services? Visit Workbeam.com and book verified SEO consultants. ${commonLineForMetaDescription}`,
        name: 'SEO Services',
        display_name: 'SEO Services',
        slug: 'seo-services',
    },
    150: {
        "product_id": 150,
        product_type: professionalType.designer,
        "title": "Scriptwriting Services - Workbeam",
        "meta_description": `Looking for scriptwriting services? Visit Workbeam.com and book verified scriptwriter. ${commonLineForMetaDescription}`,
        name: '',
        display_name: 'Scriptwriting Services',
        slug: 'scriptwriting-services',
    },
    152: {
        "product_id": 152,
        product_type: professionalType.designer,
        "title": "Website Hosting Services - Workbeam",
        "meta_description": `Looking for website hosting services? Visit Workbeam.com and book verified web developers. ${commonLineForMetaDescription}`,
        name: 'Website Hosting Services',
        display_name: 'Website Hosting Services',
        slug: 'website-hosting-services',
    },
    153: {
        "product_id": 153,
         product_type: professionalType.designer,
        "title": "Website Optimisation Services - Workbeam",
        "meta_description": `Looking for website optimisation services? Visit Workbeam.com and book verified website optimization expert. ${commonLineForMetaDescription}`,
        name: 'Website Optimisation Services',
        display_name: 'Website Optimisation Services',
        slug: 'website-optimisation-services',
    },
    154: {
        "product_id": 154,
        product_type: professionalType.designer,
        "title": "Website Maintenance Services by Freelance Web Developers & Agencies | Workbeam",
        "meta_description": "Website Maintenance Services by Best Freelance Web Developers & Website Development Agencies on Workbeam. 200+ Verified Web Developers & Agencies, 4.9 Rating, 100% payment protection.",
        name: '',
        display_name: '',
        slug: '',
    },
    172: {
        "product_id": 172,
        product_type: professionalType.designer,
        "title": "Game Design Services | Game UI Design - Workbeam",
        "meta_description": `Looking for game design services? Visit Workbeam.com and book verified game UI designers. ${commonLineForMetaDescription}`,
        name: 'Game Design Services',
        display_name: 'Game Design Services',
        slug: 'game-design-services',
    },
    173: {
        "product_id": 173,
        product_type: professionalType.designer,
        "title": `Merchandise Design Services | Tshirt Design Services - Workbeam`,
        "meta_description": `Looking for merchandise and tshirt design services? Visit Workbeam.com and book verified merchandise designers. ${commonLineForMetaDescription}`,
        name: 'Merchandise/T-shirt Design Services',
        display_name: 'Merchandise/T-shirt Design Services',
        slug: 'merchandise-tshirt-design-services',
    },
    174: {
        "product_id": 174,
        product_type: professionalType.designer,
        "title": "Email Writers | Email Content Writing Services - Workbeam",
        "meta_description": `Looking for email writing services? Visit Workbeam.com and book verified email writers. ${commonLineForMetaDescription}`,
        name: 'Email Writing',
        display_name: 'Email Writing',
        slug: 'email-writers',
    },
    175: {
        "product_id": 175,
        product_type: professionalType.designer,
        "title": "Academic Writers | Academic Writing Services - Workbeam",
        "meta_description": `Looking for academic content writing? Visit Workbeam.com and book verified academic writer. ${commonLineForMetaDescription}`,
        name: 'Academic Content Writing',
        display_name: 'Academic Content Writing',
        slug: 'academic-writers',
    },
    176: {
        "product_id": 176,
        product_type: professionalType.designer,
        "title": "Business PPT and Pitch Deck Services | Business Presentations Specialist - Workbeam",
        "meta_description": `Looking for business presentation services? Visit Workbeam.com and book verified business presentation specialist. ${commonLineForMetaDescription}`,
        name: 'Business Presentation Services',
        display_name: 'Business Presentation Services',
        slug: 'business-presentation-specialist',
    },
    177: {
        "product_id": 177,
        product_type: professionalType.designer,
        "title": "Multivendor Ecommerce Website Development Services - Workbeam",
        "meta_description": `Looking for multivendor ecommerce website development? Visit Workbeam.com and book verified ecommerce developers. ${commonLineForMetaDescription}`,
        name: 'Multivendor Ecommerce Website Development',
        display_name: 'Multivendor Ecommerce Website Development',
        slug: 'multivendor-ecommerce-website-development',
    },
    178: {
        "product_id": 178,
        product_type: professionalType.designer,
        "title": "Illustration Graphic Design Services | Verified Illustration Designer - Workbeam",
        "meta_description": `Looking for Illustrator Graphic Design Services? Visit Workbeam.com and book verified illustrator designers. ${commonLineForMetaDescription}`,
        name: 'Illustration Graphic Design Services',
        display_name: 'Illustration Graphic Design Services',
        slug: 'illustration-graphic-design-services',
    },
}

export const businessRegistrationServices = {
    'business-registration-services':{
        id: 5,
        name: 'Business Registration Services',
        display_name: 'Business Registration',
        children:[
            {
                ...productIdMappedWithSlug[9]
            },
            {
                ...productIdMappedWithSlug[10]
            },
            {
                ...productIdMappedWithSlug[107]
            },
            {
                ...productIdMappedWithSlug[12]
            },
            {
                ...productIdMappedWithSlug[13]
            },
            {
                ...productIdMappedWithSlug[14]
            },
            {
                ...productIdMappedWithSlug[57]
            },
        ]
    }
}
export const businessClouserServices = {
    'business-registration-services':{
        id: 5,
        name: 'Business Registration Services',
        display_name: 'Business Closure',
        children:[
            {
                ...productIdMappedWithSlug[15]
            },
        ]
    }
}
export const incomeTaxServices = {
    'income-tax-gst-services':{
        id: 1,
        name: 'Income Tax & GST services',
        display_name: 'Income Tax',
        children:[
            {
                ...productIdMappedWithSlug[4]
            },
            {
                ...productIdMappedWithSlug[5]
            },
            {
                ...productIdMappedWithSlug[18]
            },
            {
                ...productIdMappedWithSlug[94]
            },
            {
                ...productIdMappedWithSlug[95]
            },
            {
                ...productIdMappedWithSlug[134]
            },
        ]
    }
}
export const taxAccountingServices = {
    'income-tax-gst-services':{
        id: 1,
        name: 'Income Tax & GST services',
        display_name: 'Accounting Services',
        children:[
            {
                ...productIdMappedWithSlug[7]
            },
            {
                ...productIdMappedWithSlug[99]
            },
        ]
    }
}
export const tdsServices = {
    'income-tax-gst-services':{
        id: 1,
        name: 'Income Tax & GST services',
        display_name: 'TDS Filing',
        children:[
            {
                ...productIdMappedWithSlug[132]
            },
        ]
    }
}
export const taxGstServices = {
    'income-tax-gst-services':{
        id: 1,
        name: 'Income Tax & GST services',
        display_name: 'GST Services',
        children:[
            {
                ...productIdMappedWithSlug[20]
            },
            {
                ...productIdMappedWithSlug[35]
            },
            {
                ...productIdMappedWithSlug[113]
            },
        ]
    }
}
export const trademarkRegistrationServices = {
    'trademark-registration-services':{
        id: 4,
        name: 'Trademark Registration Services',
        display_name: 'Trademark Registration Services',
        children:[
            {
                ...productIdMappedWithSlug[21]
            },
            {
                ...productIdMappedWithSlug[22]
            },
        ]
    }
}
export const businessLicenseServices = {
    'business-license-services':{
        id: 106,
        name: 'Business License Services',
        display_name: 'Business License Services',
        children:[
            {
                ...productIdMappedWithSlug[60]
            },
            {
                ...productIdMappedWithSlug[59]
            },
            {
                ...productIdMappedWithSlug[63]
            },
            {
                ...productIdMappedWithSlug[64]
            },
            {
                ...productIdMappedWithSlug[65]
            },
        ]
    }
}
export const rocComplianceServices = {
    'roc-compliance-services':{
        id: 3,
        name: 'ROC Compliance Services',
        display_name: 'ROC Compliance',
        children:[
            {
                ...productIdMappedWithSlug[16]
            },
            {
                ...productIdMappedWithSlug[17]
            },
            {
                ...productIdMappedWithSlug[53]
            },
            {
                ...productIdMappedWithSlug[54]
            },
            {
                ...productIdMappedWithSlug[56]
            },
            {
                ...productIdMappedWithSlug[55]
            },
        ]
    }
}
export const businessAndBrandServices = {
    'graphics-design':{
        id: 110,
        name: 'Graphics Design',
        display_name: 'Business & Brand',
        children:[
            {
                ...productIdMappedWithSlug[66]
            },
            {
                ...productIdMappedWithSlug[123]
            },
            {
                ...productIdMappedWithSlug[92]
            },
        ]
    }
}
export const printDesignServices = {
    'graphics-design':{
        id: 110,
        name: 'Graphics Design',
        display_name: 'Print Design',
        children:[
            {
                ...productIdMappedWithSlug[69]
            },
            {
                ...productIdMappedWithSlug[104]
            },
            {
                ...productIdMappedWithSlug[73]
            },
        ]
    }
}
export const socialMarketingService = {
    'graphics-design':{
        id: 110,
        name: 'Graphics Design',
        display_name: 'Marketing',
        children:[
            {
                ...productIdMappedWithSlug[68]
            },
            {
                ...productIdMappedWithSlug[106]
            },
        ]
    }
}
export const packagingandLabelServices = {
    'graphics-design':{
        id: 110,
        name: 'Graphics Design',
        display_name: 'Packaging and Label',
        children:[
            {
                ...productIdMappedWithSlug[67]
            },
            {
                ...productIdMappedWithSlug[105]
            },
        ]
    }
}
export const photoshopEditingServices = {
    'graphics-design':{
        id: 110,
        name: 'Graphics Design',
        display_name: 'Photoshop Editing',
        children:[
            {
                ...productIdMappedWithSlug[71]
            },
            {
                ...productIdMappedWithSlug[108]
            },
        ]
    }
}
export const uiDesignServices = {
    'graphics-design':{
        id: 110,
        name: 'Graphics Design',
        display_name: 'UI Design',
        children:[
            {
                ...productIdMappedWithSlug[75]
            },
            {
                ...productIdMappedWithSlug[76]
            },
        ]
    }
}
export const videoAnimationServices = {
    'video-editing-animation-services':{
        id: 111,
        name: 'Video Editing & Animation services',
        display_name: 'Video & Animation',
        children:[
            {
                ...productIdMappedWithSlug[77]
            },
            {
                ...productIdMappedWithSlug[78]
            },
            {
                ...productIdMappedWithSlug[79]
            },
            {
                ...productIdMappedWithSlug[80]
            },
            {
                ...productIdMappedWithSlug[110]
            },
        ]
    }
}
export const onlineEcomServices = {
    'ecommerce-services':{
        id: 136,
        name: 'Ecommerce Services',
        display_name: 'Ecommerce',
        children:[
            {
                ...productIdMappedWithSlug[82]
            },
            {
                ...productIdMappedWithSlug[83]
            },
            {
                ...productIdMappedWithSlug[84]
            },
            {
                ...productIdMappedWithSlug[108]
            },
            {
                ...productIdMappedWithSlug[85]
            },
            {
                ...productIdMappedWithSlug[86]
            },
            {
                ...productIdMappedWithSlug[88]
            },
            {
                ...productIdMappedWithSlug[89]
            },
        ]
    }
}
export const webAndAppServices = {
    'website-design-development-services':{
        id: 112,
        name: 'Web Design & Development',
        display_name: 'Web & App',
        children:[
            {
                ...productIdMappedWithSlug[89]
            },
            {
                ...productIdMappedWithSlug[90]
            },
            {
                ...productIdMappedWithSlug[91]
            },
            {
                ...productIdMappedWithSlug[114]
            },
            {
                ...productIdMappedWithSlug[152]
            },
            {
                ...productIdMappedWithSlug[153]
            },
            {
                ...productIdMappedWithSlug[154]
            },
        ]
    }
}
export const webAndUXContent = {
    'content-writing-services':{
        id: 184,
        name: 'Content Writing services',
        display_name: 'Web & UX Content',
        children:[
            {
                ...productIdMappedWithSlug[115]
            },
            {
                ...productIdMappedWithSlug[125]
            },
        ]
    }
}
export const blogsAndArticles = {
    'content-writing-services':{
        id: 184,
        name: 'Content Writing services',
        display_name: 'Blogs & Articles',
        children:[
            {
                ...productIdMappedWithSlug[116]
            },
            {
                ...productIdMappedWithSlug[117]
            },
            {
                ...productIdMappedWithSlug[121]
            },
            {
                ...productIdMappedWithSlug[118]
            },
            {
                ...productIdMappedWithSlug[119]
            },
            {
                ...productIdMappedWithSlug[120]
            },
        ]
    }
}
export const translationAndProofreading = {
    'content-writing-services':{
        id: 184,
        name: 'Content Writing services',
        display_name: 'Translation & Proofreading',
        children:[
            {
                ...productIdMappedWithSlug[124]
            },
            {
                ...productIdMappedWithSlug[126]
            },
        ]
    }
}
export const socialandCopywriting = {
    'content-writing-services':{
        id: 184,
        name: 'Content Writing services',
        display_name: 'Social and Copywriting',
        children:[
            {
                ...productIdMappedWithSlug[127]
            },
        ]
    }
}
export const digitalMarketingServices = {
    'digital-marketing-services':{
        id: 212,
        name: 'Digital Marketing Services',
        display_name: 'Digital Marketing Services',
        children:[
            {
                ...productIdMappedWithSlug[128]
            },
            {
                ...productIdMappedWithSlug[129]
            },
            {
                ...productIdMappedWithSlug[130]
            },
            {
                ...productIdMappedWithSlug[148]
            },
            {
                ...productIdMappedWithSlug[149]
            },
        ]
    }
}
export function productPackages({productId}) {
    let packageDetails = {}
    switch (productId) {
        case 49:
        case 50:
        case 51:
        case 52:
        case 122:
            return {
                ...packageDetails,
                packageIds: [
                    {
                        display_name: '',
                        id: 49,
                    },
                    {
                        display_name: '',
                        id: 122,
                    },
                    {
                        display_name: '',
                        id: 50,
                    },
                    {
                        display_name: '',
                        id: 51,
                    },
                    {
                        display_name: '',
                        id: 52,
                    },
                ]
            }
        case 4:
        case 5:
        case 18:
        case 41:
        case 93:
        case 94:
        case 95:
        case 134:
            return {
                ...packageDetails,
                packageIds: [
                    {
                        display_name: '',
                        id: 4,
                    },
                    {
                        display_name: '',
                        id: 5,
                    },
                    {
                        display_name: '',
                        id: 18,
                    },
                    {
                        display_name: '',
                        id: 41,
                    },
                    {
                        display_name: '',
                        id: 93,
                    },
                    {
                        display_name: '',
                        id: 94,
                    },
                    {
                        display_name: '',
                        id: 95,
                    },
                    {
                        display_name: '',
                        id: 134,
                    },
                ]
            }
        case 6:
        case 7:
        case 19:
        case 97:
            return {
                ...packageDetails,
                packageIds: [
                    {
                        display_name: '',
                        id: 6,
                    },
                    {
                        display_name: '',
                        id: 7,
                    },
                    {
                        display_name: '',
                        id: 19,
                    },
                    {
                        display_name: '',
                        id: 97,
                    },
                ]
            }
        case 99:
        case 100:
        case 101:
            return {
                ...packageDetails,
                packageIds: [
                    {
                        display_name: '',
                        id: 99,
                    },
                    {
                        display_name: '',
                        id: 100,
                    },
                    {
                        display_name: '',
                        id: 101,
                    },
                ]
            }
        case 132:
        case 133:
            return {
                ...packageDetails,
                packageIds: [
                    {
                        display_name: '',
                        id: 132,
                    },
                    {
                        display_name: '',
                        id: 133,
                    },
                ]
            }
        case 20:
        case 35:
        case 42:
        case 43:
        case 102:
        case 103:
        case 113:
            return {
                ...packageDetails,
                packageIds: [
                    {
                        display_name: '',
                        id: 20,
                    },
                    {
                        display_name: '',
                        id: 35,
                    },
                    {
                        display_name: '',
                        id: 42,
                    },
                    {
                        display_name: '',
                        id: 43,
                    },
                    {
                        display_name: '',
                        id: 102,
                    },
                    {
                        display_name: '',
                        id: 103,
                    },
                    {
                        display_name: '',
                        id: 113,
                    },
                ]
            }
        case 9:
        case 10:
        case 12:
        case 13:
        case 14:
        case 15:
        case 57:
        case 107:
            return {
                ...packageDetails,
                packageIds: [
                    {
                        display_name: '',
                        id: 9,
                    },
                    {
                        display_name: '',
                        id: 10,
                    },
                    {
                        display_name: '',
                        id: 107,
                    },
                    {
                        display_name: '',
                        id: 12,
                    },
                    {
                        display_name: '',
                        id: 13,
                    },
                    {
                        display_name: '',
                        id: 14,
                    },
                    {
                        display_name: '',
                        id: 15,
                    },
                    {
                        display_name: '',
                        id: 57,
                    },
                ]
            }
        case 58:
        case 59:
        case 60:
        case 61:
        case 62:
        case 63:
        case 64:
        case 65:
            return {
                ...packageDetails,
                packageIds: [
                    {
                        display_name: '',
                        id: 58,
                    },
                    {
                        display_name: '',
                        id: 59,
                    },
                    {
                        display_name: '',
                        id: 60,
                    },
                    {
                        display_name: '',
                        id: 61,
                    },
                    {
                        display_name: '',
                        id: 62,
                    },
                    {
                        display_name: '',
                        id: 63,
                    },
                    {
                        display_name: '',
                        id: 64,
                    },
                    {
                        display_name: '',
                        id: 65,
                    },
                ]
            }
        case 23:
        case 24:
        case 25:
        case 26:
        case 27:
        case 28:
        case 36:
        case 37:
            return {
                ...packageDetails,
                packageIds: [
                    {
                        display_name: '',
                        id: 23,
                    },
                    {
                        display_name: '',
                        id: 24,
                    },
                    {
                        display_name: '',
                        id: 25,
                    },
                    {
                        display_name: '',
                        id: 26,
                    },
                    {
                        display_name: '',
                        id: 27,
                    },
                    {
                        display_name: '',
                        id: 28,
                    },
                    {
                        display_name: '',
                        id: 36,
                    },
                    {
                        display_name: '',
                        id: 37,
                    },
                ]
            }
        case 21:
        case 22:
            return {
                ...packageDetails,
                packageIds: [
                    {
                        display_name: '',
                        id: 21,
                    },
                    {
                        display_name: '',
                        id: 22,
                    },
                ]
            }
        case 16:
        case 17:
        case 53:
        case 54:
        case 55:
        case 56:
        case 131:
            return {
                ...packageDetails,
                packageIds: [
                    {
                        display_name: '',
                        id: 16,
                    },
                    {
                        display_name: '',
                        id: 17,
                    },
                    {
                        display_name: '',
                        id: 53,
                    },
                    {
                        display_name: '',
                        id: 54,
                    },
                    {
                        display_name: '',
                        id: 55,
                    },
                    {
                        display_name: '',
                        id: 56,
                    },
                    {
                        display_name: '',
                        id: 131,
                    },
                ]
            }
        case 66:
            return {
                ...packageDetails,
                packageIds: [
                    {
                        display_name: '',
                        id: 66,
                    },
                    {
                        display_name: '',
                        id: 67,
                    },
                    {
                        display_name: '',
                        id: 68,
                    },
                    {
                        display_name: '',
                        id: 69,
                    },
                    {
                        display_name: '',
                        id: 104,
                    },
                ]
            }
        case 89:
            return {
                ...packageDetails,
                packageIds: [
                    {
                        display_name: '',
                        id: 89,
                    },
                    {
                        display_name: '',
                        id: 91,
                    },
                ]
            }
        case 115:
            return {
                ...packageDetails,
                packageIds: [
                    {
                        display_name: '',
                        id: 115,
                    },
                    {
                        display_name: '',
                        id: 121,
                    },
                    {
                        display_name: '',
                        id: 125,
                    },
                    {
                        display_name: '',
                        id: 127,
                    },

                ]
            }
        case 128:
            return {
                ...packageDetails,
                packageIds: [
                    {
                        display_name: '',
                        id: 128,
                    },
                    {
                        display_name: '',
                        id: 129,
                    },
                    {
                        display_name: '',
                        id: 130,
                    },
                    {
                        display_name: '',
                        id: 149,
                    },

                ]
            }

        default:
            return {
                ...packageDetails,
                packageIds: []
            }
    }
}

function metaTagForAProfessionalOnProduct({productSlug, parentPracticeAreaSlug, childPracticeAreaSlug, citySlug, cityName, productId, professionalName, professionalFee, productDisplayName, professionalAvatar, pathname, productLinkWitProfessionalFee}) {
    professionalName = professionalName || ''
    cityName = cityName || ''
    let title = `${professionalName} | ${productDisplayName} From ${cityName}, India - Hire me on Workbeam`
    let metaDescription = `Looking for ${productDisplayName} in ${cityName}, India? visit ${professionalName} on Workbeam. Book & Get 100% Refund Protection, Pay in Instalments, Track Service with dedicated support.`
    let link = baseUrl
    professionalAvatar = professionalAvatar ? professionalAvatar : siteLogoOnSharedMedia
    link += `${productLinkWitProfessionalFee}`
    return <Header link={link} avatar={professionalAvatar} metaDescription={metaDescription} title={title}/>
}

function metaTag({productSlug, parentPracticeAreaSlug, childPracticeAreaSlug, citySlug, productId, isIndexing}) {
    let title = ''
    productSlug = productSlug || ''
    if (citySlug) {
        if (childPracticeAreaSlug) {
            title += `${childPracticeAreaSlug.replace(/-/g, ' ').split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')
                } in ${citySlug.replace(/-/g, ' ')} | Workbeam`
        } else {
            title += `${productSlug.replace(/-/g, ' ').split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')
                } in ${citySlug.replace(/-/g, ' ')} | Workbeam`
        }
    } else {
        title = productIdMappedWithSlug[productId] ? productIdMappedWithSlug[productId].title : ''
    }
    let metaDescription = productIdMappedWithSlug[productId] ? productIdMappedWithSlug[productId].meta_description : ''
    let link = baseUrl
    if (productSlug) {
        link += `/${category}/${parentPracticeAreaSlug}/${productSlug}`
    }
    if (citySlug) {
        link += `${_in_}${citySlug}`
    }
    // if (productLinkWitProfessionalFee){
    //     link += productLinkWitProfessionalFee
    // } else {
    //     if (productSlug) {
    //         link += `/${productSlug}`
    //     }
    //     if (citySlug) {
    //         link += `${_in_}${citySlug}`
    //     }
    // }
    return <Header link={link} avatar={siteLogoOnSharedMedia} metaDescription={metaDescription} title={title} isIndexing={isIndexing}/>
}


export function productDetails({productId, productSlug, parentPracticeAreaSlug, childPracticeAreaSlug, citySlug, cityName, professionalId, professionalSlug, professionalName, professionalFee, productDisplayName, professionalAvatar, pathname, productLinkWitProfessionalFee, product_type, isIndexing}) {
    productId = productId ? parseInt(productId) : ''
    let headerDetails = {
        product_id: productId,
        meta_tag: (professionalId || professionalSlug) ? metaTagForAProfessionalOnProduct({
            productSlug,
            parentPracticeAreaSlug,
            childPracticeAreaSlug,
            citySlug,
            productId,
            professionalName,
            professionalFee,
            productDisplayName,
            pathname,
            professionalAvatar,
            productLinkWitProfessionalFee,
            product_type,
            cityName
        }) : metaTag({
            productSlug,
            parentPracticeAreaSlug,
            childPracticeAreaSlug,
            citySlug,
            productId,
            professionalName,
            professionalFee,
            productDisplayName,
            productLinkWitProfessionalFee,
            isIndexing
        }),
    }
    switch (productId) {
        case 1:
            return {
                ...headerDetails,
                parent_practice_area_id: 2,
                heading_text: {
                    headingText: 'Online Legal Advice from Verified Lawyers.',
                    subheadingText: 'Hire Lawyers on demand for legal consultation.',
                    startingPriceText: '',
                    backgroundColor: '',
                    image: '',
                },
            }
        case 2:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 2,
                heading_text: {
                    headingText: `Don't hold back, go for justice.`,
                    subheadingText: 'Hire Lawyers to send your legal notice. Get dedicated Workbeam Legal Manager to support your case.',
                    startingPriceText: '',
                    backgroundColor: '',
                    image:  '',
                },
            }
        case 3:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 2,
                heading_text: {
                    headingText: 'Hire Lawyers to fight for you in court.',
                    subheadingText: 'Find & hire verified & sincere Lawyers to represent you. Get dedicated Workbeam Legal Manager to support you throughout your case.',
                    startingPriceText: '',
                    backgroundColor: '',
                    image: '',
                },
            }
        case 49:
        case 50:
        case 51:
        case 52:
        case 122:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 2,
                heading_text: {
                    headingText: `Hire Experts For Legal Services Online`,
                    subheadingText: 'Hire verified Lawyers for Legal Service in your budget.',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaLegalExpert',
                    backgroundColor: '',
                    userRating: 4.7,
                    userCount: 450,
                    image: '',
                    bannerImage: '',
                },
            }
        case 134:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 1,
                heading_text: {
                    headingText: `Income Tax Filing for Small Business`,
                    subheadingText: 'Income Tax Return Filing for Small Businesses by verified Income Tax Consultants',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaTaxExpert',
                    userRating: 4.9,
                    userCount: 350,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 4:
            return {
            ...headerDetails,
            // product_id: productId,
            parent_practice_area_id: 1,
            heading_text: {
                headingText: `Income Tax Filing for Salaried Income`,
                subheadingText: 'Income Tax Return Filing for Salaried people by verified Chartered Accountants',
                startingPriceText: '',
                hashTag: '#AapkaApnaTaxExpert',
                userRating: 4.9,
                userCount: 750,
                backgroundColor: '',
                image: '',
                bannerImage: '',
            },
        }
        case 5:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 1,
                heading_text: {
                    headingText: `Income Tax Filing for Professional and Freelancers`,
                    subheadingText: 'Income Tax Return Filing for Freelancers and Professionals by verified Chartered Accountants',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaTaxExpert',
                    userRating: 4.9,
                    userCount: 750,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 18:
            return {
            ...headerDetails,
            // product_id: productId,
            parent_practice_area_id: 1,
            heading_text: {
                headingText: `Income Tax Filing for Capital Gains Income`,
                subheadingText: 'Income Tax Return Filing for Capital Gains Income by verified Income Tax Consultants',
                startingPriceText: '',
                hashTag: '#AapkaApnaTaxExpert',
                userRating: 4.9,
                userCount: 750,
                backgroundColor: '',
                image: '',
                bannerImage: '',
            },
        }
        case 41:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 1,
                heading_text: {
                    headingText: `Income Tax Filing for Small Business`,
                    subheadingText: 'Hire Chartered Accountants Online to file your return in due time and get you maximum refund.',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaTaxExpert',
                    userRating: 4.9,
                    userCount: 450,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 93:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 1,
                heading_text: {
                    headingText: `Income Tax Filing for Salaried - Multiple Form 16`,
                    subheadingText: 'Income Tax Return Filing services for NGOs by verified Tax Experts.',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaTaxExpert',
                    userRating: 4.9,
                    userCount: 250,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 94:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 1,
                heading_text: {
                    headingText: `Income Tax Filing for NGOs`,
                    subheadingText: 'Income Tax Return Filing for your NGO by verified Income Tax Consultants',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaTaxExpert',
                    userRating: 4.9,
                    userCount: 650,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 95:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 1,
                heading_text: {
                    headingText: `Income Tax Filing for Traders`,
                    subheadingText: 'Income Tax Return Filing for Intraday and Stock Traders by verified Chartered Accountants',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaTaxExpert',
                    userRating: 4.9,
                    userCount: 750,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 132:
        case 133:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 1,
                heading_text: {
                    headingText: `TDS Return Filing Services`,
                    subheadingText: 'File your TDS Return on time. Book TDS Filing Services with verified Tax Consultants',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaTaxExpert',
                    userRating: 4.9,
                    userCount: 220,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 6:
        case 19:
        case 97:
        case 100:
        case 101:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 1,
                heading_text: {
                    headingText: 'Monthly Accounting and Compliance Package',
                    subheadingText: 'Book complete Accounting & Compliance package with verified Accountants & Compliance Experts. Chat & Consult and book as per your business needs.',
                    startingPriceText: '',
                    hashTag: ' #AapkaApnaAccountingExpert',
                    userRating: 4.8,
                    userCount: 550,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 7:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 1,
                heading_text: {
                    headingText: 'Monthly Accounting and Bookkeeping Services',
                    subheadingText: 'Get your Books of Accounts, Balance Sheet and Profit/Loss Statement prepared by verified Chartered Accountants',
                    startingPriceText: '',
                    hashTag: ' #AapkaApnaAccountingExpert',
                    userRating: 4.8,
                    userCount: 550,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 8:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 1,
                heading_text: {
                    headingText: `Income Tax Filing for Sole Proprietor `,
                    subheadingText: 'Get your Income Tax Return Filed by Verified Experts in your budget.',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaTaxExpert',
                    userRating: 4.9,
                    userCount: 350,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 99:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 1,
                heading_text: {
                    headingText: 'Monthly Accounting and Compliance Package',
                    subheadingText: 'Make sure your Business maintains accurate books of accounts and is fully compliant with ROC Laws',
                    startingPriceText: '',
                    hashTag: ' #AapkaApnaAccountingExpert',
                    userRating: 4.8,
                    userCount: 450,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 15:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 5,
                heading_text: {
                    headingText: 'Company Strike Off/Name Removal Services',
                    subheadingText: ``,
                    startingPriceText: '',
                    hashTag: '#AapkaApnaBusinessExpert',
                    userRating: 4.9,
                    userCount: 620,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 9:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 5,
                heading_text: {
                    headingText: 'Private Limited Company Registration Services',
                    subheadingText: `Hire Workbeam's verified Company Registration Experts to register your Private Limited Company`,
                    h1: 'Company Registration in your Budget.',
                    h2: 'India\'s Trusted Marketplace to hire Company Registration Experts. Pay in Instalments, Track service delivery, Get Dedicated Support with 100% Money Back Guarantee.',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaBusinessExpert',
                    userRating: 4.9,
                    userCount: 620,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 10:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 5,
                heading_text: {
                    headingText: 'Online LLP Registration Services',
                    subheadingText: `Hire Workbeam's verified LLP Registration Experts to register a LLP online`,
                    startingPriceText: '',
                    hashTag: '#AapkaApnaBusinessExpert',
                    userRating: 4.9,
                    userCount: 820,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 12:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 5,
                heading_text: {
                    headingText: 'One Person Company Registration Service Online',
                    subheadingText: `Hire Workbeam's verified Company Registration Experts to register a One Person Company`,
                    startingPriceText: '',
                    hashTag: '#AapkaApnaBusinessExpert',
                    userRating: 4.7,
                    userCount: 250,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 13:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 5,
                heading_text: {
                    headingText: 'Sole Proprietorship Registration Services',
                    subheadingText: `Take free consultation and register your Sole Proprietorship Business `,
                    startingPriceText: '',
                    hashTag: '#AapkaApnaBusinessExpert',
                    userRating: 4.9,
                    userCount: 820,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 14:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 5,
                heading_text: {
                    headingText: 'NGO and Section 8 Company Registration Services',
                    subheadingText: `Hire Workbeam's verified Company Registration Experts to register your Section 8 Company, Trust or NGO`,
                    startingPriceText: '',
                    hashTag: '#AapkaApnaBusinessExpert',
                    userRating: 4.8,
                    userCount: 570,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 16:
        case 131:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 3,
                heading_text: {
                    headingText: 'Annual ROC Filing Services',
                    subheadingText: '',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaTrademarkExpert',
                    userRating: 4.8,
                    userCount: 470,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 17:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 3,
                heading_text: {
                    headingText: 'Annual ROC Compliance Services',
                    subheadingText: '',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaTrademarkExpert',
                    userRating: 4.8,
                    userCount: 470,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 53:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 3,
                heading_text: {
                    headingText: 'Digital Signature Certificate Class 2',
                    subheadingText: '',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaTrademarkExpert',
                    userRating: 4.8,
                    userCount: 370,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 54:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 3,
                heading_text: {
                    headingText: 'Digital Signature Certificate Class 3',
                    subheadingText: '',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaTrademarkExpert',
                    userRating: 4.8,
                    userCount: 330,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 55:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 3,
                heading_text: {
                    headingText: 'Digital Signature for Foreign citizens and NRI',
                    subheadingText: '',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaTrademarkExpert',
                    userRating: 4.8,
                    userCount: 330,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 56:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 3,
                heading_text: {
                    headingText: 'DIR 3 KYC Services',
                    subheadingText: '',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaTrademarkExpert',
                    userRating: 4.8,
                    userCount: 260,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 42:
        case 43:
        case 102:
        case 103:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 1,
                heading_text: {
                    headingText: 'GST Services',
                    subheadingText: 'GST Registration & GST Filing Services by Verified Experts in your budget.',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaGSTExpert',
                    userRating: 4.9,
                    userCount: 940,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 20:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 1,
                heading_text: {
                    headingText: 'Apply GST Registration Online',
                    subheadingText: 'Get GST Registration for your Business by Workbeam\'s verified GST Consultants',
                    h1: 'GST Registration Online in your budget.',
                    h2: 'Book GST Registration service within minutes and in your budget, 100% Workbeam Assured with easy refunds.',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaBusinessExpert',
                    userRating: 4.9,
                    userCount: 460,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 35:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 1,
                heading_text: {
                    headingText: 'GST Return Filing services Online',
                    subheadingText: 'File your GST Return on time. Book GST Filing Services with verified GST Consultants',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaGSTExpert',
                    userRating: 4.9,
                    userCount: 940,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 113:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 1,
                heading_text: {
                    headingText: 'Monthly GST Filing services (NIL Return)',
                    subheadingText: 'GST Filing Services for NIL Return',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaGSTExpert',
                    userRating: 4.9,
                    userCount: 940,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 21:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 4,
                heading_text: {
                    headingText: 'Trademark Registartion for Companies',
                    subheadingText: '',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaTrademarkExpert',
                    userRating: 4.7,
                    userCount: 320,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 22:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 4,
                heading_text: {
                    headingText: 'Trademark Registration services for Small Businesses',
                    subheadingText: 'Protect your Brand Name and Logo. Book Trademark Registration Services with verified Trademark Consultants.',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaTrademarkExpert',
                    userRating: 4.7,
                    userCount: 320,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 23:
        case 24:
        case 25:
        case 26:
        case 27:
        case 28:
        case 36:
        case 37:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 6,
                heading_text: {
                    headingText: 'Contracts & Agreements Drafting Services',
                    subheadingText: 'Get your contracts drafted super easily and in your budget by Verified Lawyers.',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaLegalExpert',
                    userRating: 4.7,
                    userCount: 220,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 29:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 7,
                heading_text: {
                    headingText: 'Shareholding Agreement Drafting Services.',
                    subheadingText: 'Hire verified Corporate Lawyers to draft your Shareholding agreement. Track drafting process, get dedicated support & payment insurance.',
                    startingPriceText: '',
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 30:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 7,
                heading_text: {
                    headingText: 'Term Sheet drafted Services',
                    subheadingText: 'Hire verified Startup & Corporate Lawyers for Term Sheet drafting. Track drafting process, get dedicated support & payment insurance.',
                    startingPriceText: '',
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 31:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 7,
                heading_text: {
                    headingText: `Founder's Agreement Drafting Services`,
                    subheadingText: 'Hire verified Startup & Corporate Lawyers for Term Sheet drafting. Track drafting process, get dedicated support & payment insurance.',
                    startingPriceText: '',
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 32:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 7,
                heading_text: {
                    headingText: `Debt Financing/Loan Agreement Drafting.`,
                    subheadingText: 'Hire verified Lawyers to draft Loan Agreement. Share files, Track drafting process, get dedicated support & payment insurance.',
                    startingPriceText: '',
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 33:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 7,
                heading_text: {
                    headingText: 'Employee Stock Option Policy Services',
                    subheadingText: 'Hire verified Startup & Corporate Lawyers for drafting ESOP Policy. Track drafting process, get dedicated support & payment insurance.',
                    startingPriceText: '',
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 34:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 7,
                heading_text: {
                    headingText: 'Employment Agreement Drafting Services',
                    subheadingText: 'Hire verified Startup & Corporate Lawyers for drafting Employment Agreement. Track drafting process, get dedicated support & payment insurance.',
                    startingPriceText: '',
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 57:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 5,
                heading_text: {
                    headingText: 'Reservation of Company Name',
                    subheadingText: ``,
                    startingPriceText: '',
                    hashTag: '#AapkaApnaBusinessExpert',
                    userRating: 4.6,
                    userCount: 320,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 58:
        case 61:
        case 62:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 106,
                heading_text: {
                    headingText: 'Business License Services',
                    subheadingText: 'Get FSSAI and other business Licenses. Keep your Business running.',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaBusinessExpert',
                    userRating: 4.9,
                    userCount: 750,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 59:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 106,
                heading_text: {
                    headingText: 'Apply Import Export Code (IEC Code) for Goods',
                    subheadingText: `Planning to start an Import Export Business? Apply for Import Export Code online`,
                    startingPriceText: '',
                    hashTag: '#AapkaApnaBusinessExpert',
                    userRating: 4.7,
                    userCount: 350,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 60:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 106,
                heading_text: {
                    headingText: 'Online FSSAI License Registration Services',
                    subheadingText: `Apply for FSSAI License with help of Workbeam's verified Fssai Consultants. Chat and take free consultation.`,
                    startingPriceText: '',
                    hashTag: '#AapkaApnaBusinessExpert',
                    userRating: 4.7,
                    userCount: 480,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 63:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 106,
                heading_text: {
                    headingText: 'Professional Tax Registration Services Online',
                    subheadingText: `Get your Professional Tax Registration done to avoid any penalties and charges`,
                    startingPriceText: '',
                    hashTag: '#AapkaApnaBusinessExpert',
                    userRating: 4.8,
                    userCount: 285,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 64:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 106,
                heading_text: {
                    headingText: 'Online MSME Registration Services ',
                    subheadingText: `Apply for MSME Registration Online with help of Workbeam's verified MSME Experts. Chat and take free consultation.`,
                    startingPriceText: '',
                    hashTag: '#AapkaApnaBusinessExpert',
                    userRating: 4.8,
                    userCount: 345,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 65:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 106,
                heading_text: {
                    headingText: 'Shop and Establishment Act Registration/ Act License',
                    subheadingText: ``,
                    startingPriceText: '',
                    hashTag: '#AapkaApnaBusinessExpert',
                    userRating: 4.8,
                    userCount: 325,
                    backgroundColor: '',
                    image: '',
                    bannerImage: '',
                },
            }
        case 66:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 110,
                heading_text: {
                    headingText: 'Logo Design Services',
                    subheadingText: 'Hire Workbeam\'s verified Logo Designers to give your brand a unique creative Logo',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Logo Design Packages ',
                    h2: 'You select the package and we assign our top rated Logo Design expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaDesignExpert',
                    userRating: 4.8,
                    userCount: 315,
                    backgroundColor: '',
                    bannerImage: '',
                },
                new_product_package: {
                    h1: 'Book Graphic & Brand Design Packages',
                    h2: 'You select the package and we assign our top-rated Design expert. Request for samples, Pay in instalments, track work delivery with 100% assurance.',

                }
            }
        case 67:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 110,
                heading_text: {
                    headingText: 'Product Packaging, Label & Box Design Services',
                    subheadingText: 'Well designed Packaging means better product sales. Book Packaging Design, Label Design & Box Design Services.',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Packaging Design Packages ',
                    h2: 'You select the package and we assign our top rated Packaging Design expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaDesignExpert',
                    userRating: 4.6,
                    userCount: 152,
                    backgroundColor: '',
                    bannerImage: '',
                },
                new_product_package: {
                    h1: 'Book Graphic & Brand Design Packages',
                    h2: 'You select the package and we assign our top-rated Design expert. Request for samples, Pay in instalments, track work delivery with 100% assurance.',

                }
            }
        case 68:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 110,
                heading_text: {
                    headingText: 'Social Media Posts Design Services',
                    subheadingText: 'Better Design leads to better engagement on Social Media. ',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaDesignExpert',
                    userRating: 4.7,
                    userCount: 187,
                    backgroundColor: '',
                    image: '',
                },
                new_product_package: {
                    h1: 'Book Graphic & Brand Design Packages',
                    h2: 'You select the package and we assign our top-rated Design expert. Request for samples, Pay in instalments, track work delivery with 100% assurance.',

                }
            }
        case 69:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 110,
                heading_text: {
                    headingText: 'Menu Design Services',
                    subheadingText: 'Upgrade your Restaurant or Cafe\'s Menu Card Design.',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaDesignExpert',
                    userRating: 4.8,
                    userCount: 425,
                    backgroundColor: '',
                    image: ' ',
                },
                new_product_package: {
                    h1: 'Book Graphic & Brand Design Packages',
                    h2: 'You select the package and we assign our top-rated Design expert. Request for samples, Pay in instalments, track work delivery with 100% assurance.',

                }
            }
        case 70:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 110,
                heading_text: {
                    headingText: 'Invitation Card Design Services',
                    subheadingText: '',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaDesignExpert',
                    userRating: 4.8,
                    userCount: 468,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 71:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 110,
                heading_text: {
                    headingText: 'Professional Photoshop Editing Services',
                    subheadingText: '',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaDesignExpert',
                    userRating: 4.9,
                    userCount: 562,
                    backgroundColor: '',
                    image: ' ',
                },
                new_product_package: {
                    h1: 'Book Graphic & Brand Design Packages',
                    h2: 'You select the package and we assign our top-rated Design expert. Request for samples, Pay in instalments, track work delivery with 100% assurance.',

                }
            }
        case 72:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 110,
                heading_text: {
                    headingText: 'Flyer/leaflet Design Services',
                    subheadingText: '',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaDesignExpert',
                    userRating: 4.6,
                    userCount: 225,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 73:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 110,
                heading_text: {
                    headingText: 'Brochure Design Services',
                    subheadingText: 'Get professional brochures designed for your business. ',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaDesignExpert',
                    userRating: 4.7,
                    userCount: 575,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 74:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 110,
                heading_text: {
                    headingText: 'Standee Design Services',
                    subheadingText: '',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaDesignExpert',
                    userRating: 4.6,
                    userCount: 368,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 75:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 110,
                heading_text: {
                    headingText: 'Web App UI Design Services',
                    subheadingText: 'Give your Web App a clean, attractive, user friendly and intuitive design with help of Workbeam\'s verified Web App UI Designers',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaDesignExpert',
                    userRating: 4.8,
                    userCount: 452,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 76:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 110,
                heading_text: {
                    headingText: 'Website UI Design Services',
                    subheadingText: 'Give your website a clean, attractive, user friendly and intuitive design ',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Website Packages ',
                    h2: 'You select the package and we assign our top rated website expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaDesignExpert',
                    userRating: 4.8,
                    userCount: 635,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 77:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 111,
                heading_text: {
                    headingText: 'Logo Animation Services',
                    subheadingText: 'Get an Animated Logo for loading screens and social media marketing. Book Logo Animation Services.',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Logo Animation Packages',
                    h2: 'You select the package and we assign our top rated Logo Animation expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaDesignExpert',
                    userRating: 4.9,
                    userCount: 415,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 78:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 111,
                heading_text: {
                    headingText: 'Video Editing and Animation Services',
                    subheadingText: 'Explore and Book from Workbeam\'s Video Editing & Animation Services',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaDesignExpert',
                    userRating: 4.8,
                    userCount: 385,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 79:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 111,
                heading_text: {
                    headingText: 'Professional Voice Over Services',
                    subheadingText: 'Hire Workbeam\'s verified Professional for Voice Over services in different languages.',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaDesignExpert',
                    userRating: 4.6,
                    userCount: 175,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 80:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 111,
                heading_text: {
                    headingText: 'Marketing & Promotional Video Services',
                    subheadingText: 'Promote your Business on social media with well designed and animated Marketing Videos',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.7,
                    userCount: 362,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 81:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 111,
                heading_text: {
                    headingText: 'Product Photography Services',
                    subheadingText: 'Get stunning Product shoot done for your products. Book Product Photography services with verified freelance photographers.',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.8,
                    userCount: 279,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 82:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 136,
                heading_text: {
                    headingText: 'Open Amazon Seller Account and Sell on Amazon',
                    subheadingText: 'Hire Amazon Seller Experts to open your Amazon Seller Account',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.8,
                    userCount: 176,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 83:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 136,
                heading_text: {
                    headingText: 'Open Flipkart Seller Account',
                    subheadingText: 'Hire Flipkart Seller Experts to open your Flipkart Seller Account',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.8,
                    userCount: 169,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 84:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 136,
                heading_text: {
                    headingText: 'Amazon Seller Account Management Services ',
                    subheadingText: 'Improve performance of your Amazon Store with Workbeam\'s verified Amazon Seller Experts',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.7,
                    userCount: 167,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 85:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 136,
                heading_text: {
                    headingText: 'Flipkart Seller Account Management Services ',
                    subheadingText: 'Improve performance of your Flipkart Store with Workbeam\'s verified Flipkart Seller Experts',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.7,
                    userCount: 162,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 86:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 136,
                heading_text: {
                    headingText: 'UK VAT Registration Services',
                    subheadingText: '',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.8,
                    userCount: 125,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 87:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 136,
                heading_text: {
                    headingText: 'Amazon Account Reinstatement Services',
                    subheadingText: '',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.6,
                    userCount: 135,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 88:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 136,
                heading_text: {
                    headingText: 'Product and Cataloging Listing Services',
                    subheadingText: 'Manage your Ecommerce store better with Workbeam\'s product listing services',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.6,
                    userCount: 240,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 89:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 112,
                heading_text: {
                    headingText: 'Ecommerce Website Design & Development Services',
                    subheadingText: 'Start your Ecommerce Business with Workbeam\'s verified Ecommerce Developers ',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.9,
                    userCount: 734,
                    backgroundColor: '',
                    image: ' ',
                    isEcomWebsiteAndApp: true
                },
                new_product_package: {
                    h1: 'Workbeam\'s Website Development Packages',
                    h2: 'You select the package and we assign our top-rated website expert. Request for samples, Pay in instalments, track work delivery with 100% assurance.',

                }
            }
        case 90:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 112,
                heading_text: {
                    headingText: 'Blog Website Design and Development Services',
                    subheadingText: 'Get your Blog up and running with Workbeam\'s verified Blog Website Developers',
                    h1: 'Workbeam Standard Blog Website Packages ',
                    h2: 'You select the package and we assign our top rated website expert. Faster, Simple and affordable!',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.9,
                    userCount: 295,
                    backgroundColor: '',
                    image: ' ',
                },
                new_product_package: {
                    h1: 'Workbeam\'s Website Development Packages',
                    h2: 'You select the package and we assign our top-rated website expert. Request for samples, Pay in instalments, track work delivery with 100% assurance.',

                }
            }
        case 91:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 112,
                heading_text: {
                    headingText: 'Business Website Development Services',
                    subheadingText: 'Give your business a professional website with Workbeam\'s verified Website Developers',
                    h1: 'Workbeam Standard Normal Website Packages ',
                    h2: 'You select the package and we assign our top rated website expert. Faster, Simple and affordable!',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.9,
                    userCount: 765,
                    backgroundColor: ' add-crowsel-3',
                    image: ' ',
                },
                new_product_package: {
                    h1: 'Workbeam\'s Website Development Packages',
                    h2: 'You select the package and we assign our top-rated website expert. Request for samples, Pay in instalments, track work delivery with 100% assurance.',

                }
            }
        case 92:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 110,
                heading_text: {
                    headingText: 'Business Card and Stationary Design Services',
                    subheadingText: '',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaDesignExpert',
                    userRating: 4.8,
                    userCount: 240,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 104:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 110,
                heading_text: {
                    headingText: 'Catalog Design Services',
                    subheadingText: 'Get professional, informative and minimal catalogs designed for your products.',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaDesignExpert',
                    userRating: 4.6,
                    userCount: 225,
                    backgroundColor: '',
                    image: ' ',
                },
                new_product_package: {
                    h1: 'Book Graphic & Brand Design Packages',
                    h2: 'You select the package and we assign our top-rated Design expert. Request for samples, Pay in instalments, track work delivery with 100% assurance.',

                }
            }
        case 105:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 110,
                heading_text: {
                    headingText: 'Book Cover and Album Design Services',
                    subheadingText: '',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaDesignExpert',
                    userRating: 4.7,
                    userCount: 355,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 106:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 110,
                heading_text: {
                    headingText: 'Infographic Design Services',
                    subheadingText: 'Get Infographics designed for your blogs, content, website, presentations and brochures',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaDesignExpert',
                    userRating: 4.6,
                    userCount: 150,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 107:
            return {
                ...headerDetails,
                // product_id: productId,
                parent_practice_area_id: 5,
                heading_text: {
                    headingText: 'Partnership Firm Registration Services',
                    subheadingText: ``,
                    startingPriceText: '',
                    hashTag: '#AapkaApnaBusinessExpert',
                    backgroundColor: '',
                    userRating: 4.9,
                    userCount: 820,
                    image: '',
                    bannerImage: '',
                },
            }
        case 108:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 136,
                heading_text: {
                    headingText: 'Amazon Photo Editing Services',
                    subheadingText: '',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.9,
                    userCount: 375,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 109:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 110,
                heading_text: {
                    headingText: 'Amazon Photo Editing Services',
                    subheadingText: '',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.9,
                    userCount: 375,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 110:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 111,
                heading_text: {
                    headingText: 'Animated Explainer Video Services',
                    subheadingText: 'Animated Explainer Videos are the best way to explain your Products & Services and market your business',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.7,
                    userCount: 265,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 112:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 111,
                heading_text: {
                    headingText: 'Animated Explainer Video Services',
                    subheadingText: 'Animated Explainer Videos are the best way to explain your Products & Services and market your business',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.7,
                    userCount: 265,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 114:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 112,
                heading_text: {
                    headingText: 'Android App Development Services',
                    subheadingText: 'Make your business mobile first with Workbeam\'s Android App Development services ',
                    startingPriceText: '',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.9,
                    userCount: 235,
                    backgroundColor: '',
                    image: ' ',
                    isEcomWebsiteAndApp: true,
                },
            }
        case 115:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 184,
                heading_text: {
                    headingText: 'Website Content Writing Services',
                    subheadingText: 'Content that tells your Brand\'s story. Book SEO friendly Website Content Writing Services.',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.8,
                    userCount: 425,
                    backgroundColor: '',
                    image: ' ',
                },
                new_product_package: {
                    h1: 'Book Quality Content Writing Services',
                    h2: 'You select the package and we assign our top-rated content expert. Request for samples, Pay in instalments, track work delivery with 100% assurance.',

                }
            }
        case 116:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 184,
                heading_text: {
                    headingText: 'Travel and Food Content Writing Services',
                    subheadingText: 'Get high quality content written for your Travel & Hospitality Business',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.9,
                    userCount: 135,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 117:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 184,
                heading_text: {
                    headingText: 'Fashion and Lifestyle Content Writing Services',
                    subheadingText: '',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.7,
                    userCount: 115,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 118:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 184,
                heading_text: {
                    headingText: 'Technology Content Writing Services',
                    subheadingText: 'Content Writing Services for SAAS products, AI/ML, Blockchain, Programming Languages and Emerging Tech',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.6,
                    userCount: 135,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 119:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 184,
                heading_text: {
                    headingText: 'Finance Content Writing Services',
                    subheadingText: 'Content Writing Services in Finance, Stock markets, Insurance & Accounting Domains',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.9,
                    userCount: 112,
                    backgroundColor: ' add-crowsel-1',
                    image: ' ',
                },
            }
        case 120:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 184,
                heading_text: {
                    headingText: 'Legal Content Writing Services',
                    subheadingText: '',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.7,
                    userCount: 155,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 121:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 184,
                heading_text: {
                    headingText: 'Business Blog Writing Services',
                    subheadingText: 'Rank your blogs with high quality SEO friendly blog writing services',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.9,
                    userCount: 235,
                    backgroundColor: '',
                    image: ' ',
                },
                new_product_package: {
                    h1: 'Book Quality Content Writing Services',
                    h2: 'You select the package and we assign our top-rated content expert. Request for samples, Pay in instalments, track work delivery with 100% assurance.',

                }
            }
        case 123:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 110,
                heading_text: {
                    headingText: 'Website Banner Design Services',
                    subheadingText: 'Design Website Banners showing offers, products, services and value proposition of your Website',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Logo Design Packages ',
                    h2: 'You select the package and we assign our top rated Logo Design expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaDesignExpert',
                    userRating: 4.8,
                    userCount: 225,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 124:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 184,
                heading_text: {
                    headingText: 'Content Translation Services',
                    subheadingText: 'Content Translation Services by Professional Translators',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Logo Design Packages ',
                    h2: 'You select the package and we assign our top rated Logo Design expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaDesignExpert',
                    userRating: 4.8,
                    userCount: 115,
                    backgroundColor: ' add-crowsel-2',
                    image: ' ',
                },
            }
        case 125:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 184,
                heading_text: {
                    headingText: 'High Selling Ecommerce Product Descriptions',
                    subheadingText: 'Hire Workbeam\'s Ecommerce Writers for high selling product descriptions',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Logo Design Packages ',
                    h2: 'You select the package and we assign our top rated Logo Design expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaDesignExpert',
                    userRating: 4.8,
                    userCount: 215,
                    backgroundColor: '',
                    image: ' ',
                },
                new_product_package: {
                    h1: 'Book Quality Content Writing Services',
                    h2: 'You select the package and we assign our top-rated content expert. Request for samples, Pay in instalments, track work delivery with 100% assurance.',

                }
            }
        case 126:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 184,
                heading_text: {
                    headingText: 'Proof Reading and Editing Services',
                    subheadingText: 'Make sure your content is well proof read, edited and ready to publish',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Logo Design Packages ',
                    h2: 'You select the package and we assign our top rated Logo Design expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaDesignExpert',
                    userRating: 4.8,
                    userCount: 165,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 127:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 184,
                heading_text: {
                    headingText: 'Copywriting Services & Social Media Content Writing',
                    subheadingText: 'Create engaging social media content and creative copies ',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Logo Design Packages ',
                    h2: 'You select the package and we assign our top rated Logo Design expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaDesignExpert',
                    userRating: 4.8,
                    userCount: 125,
                    backgroundColor: '',
                    image: ' ',
                },
                new_product_package: {
                    h1: 'Book Quality Content Writing Services',
                    h2: 'You select the package and we assign our top-rated content expert. Request for samples, Pay in instalments, track work delivery with 100% assurance.',

                }
            }
        case 128:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 212,
                heading_text: {
                    headingText: 'Social Media Marketing Services',
                    subheadingText: 'Create brand awareness with Workbeam\'s social media marketing services',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Logo Design Packages ',
                    h2: 'You select the package and we assign our top rated Logo Design expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaDesignExpert',
                    userRating: 4.8,
                    userCount: 158,
                    backgroundColor: '',
                    image: ' ',
                },
                new_product_package: {
                    h1: 'Workbeam\'s Digital Marketing Packages',
                    h2: 'You select the package and we assign our top-rated marketing expert. Request for samples. Pay in instalments, track work delivery with 100% assurance.',

                }
            }
        case 129:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 212,
                heading_text: {
                    headingText: 'Facebook and Instagram Ads Services',
                    subheadingText: 'Drive brand awareness, generate leads using Facebook and Instagram Ads',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Logo Design Packages ',
                    h2: 'You select the package and we assign our top rated Logo Design expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaDesignExpert',
                    userRating: 4.8,
                    userCount: 235,
                    backgroundColor: '',
                    image: ' ',
                },
                new_product_package: {
                    h1: 'Workbeam\'s Digital Marketing Packages',
                    h2: 'You select the package and we assign our top-rated marketing expert. Request for samples. Pay in instalments, track work delivery with 100% assurance.',

                }
            }
        case 130:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 212,
                heading_text: {
                    headingText: 'Google Ads Services',
                    subheadingText: 'Drive leads and boost sales using Google Ads Services by verified Google Ads Experts',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Logo Design Packages ',
                    h2: 'You select the package and we assign our top rated Logo Design expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaDesignExpert',
                    userRating: 4.8,
                    userCount: 165,
                    backgroundColor: '',
                    image: ' ',
                },
                new_product_package: {
                    h1: 'Workbeam\'s Digital Marketing Packages',
                    h2: 'You select the package and we assign our top-rated marketing expert. Request for samples. Pay in instalments, track work delivery with 100% assurance.',

                }
            }
        case 148:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 212,
                heading_text: {
                    headingText: 'SEO Audit Services',
                    subheadingText: 'Can\'t rank on Google? Get an SEO Audit for your website by verified SEO Specialists',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.9,
                    userCount: 125,
                    backgroundColor: '',
                    image: ' ',
                },
            }
        case 149:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 212,
                heading_text: {
                    headingText: 'SEO Services',
                    subheadingText: 'Boost your organic traffiic with Workbeam\'s SEO Services by verified SEO Experts',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.9,
                    userCount: 135,
                    backgroundColor: '',
                    image: ' ',
                },
                new_product_package: {
                    h1: 'Workbeam\'s Digital Marketing Packages',
                    h2: 'You select the package and we assign our top-rated marketing expert. Request for samples. Pay in instalments, track work delivery with 100% assurance.',

                }
            }
        case 150:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 184,
                heading_text: {
                    headingText: 'Book Creative Scripting Writing Services',
                    subheadingText: 'Scriptwriters for videos, plays, short films and ads',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.9,
                    userCount: 105,
                    backgroundColor: '',
                    image: ' ',
                },
                new_product_package: {
                    h1: 'Workbeam\'s Digital Marketing Packages',
                    h2: 'You select the package and we assign our top-rated marketing expert. Request for samples. Pay in instalments, track work delivery with 100% assurance.',

                }
            }
        case 152:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 112,
                heading_text: {
                    headingText: 'Best Web Hosting Services',
                    subheadingText: 'Choose the right hosting server with Workbeam\'s verified web hosting experts',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.9,
                    userCount: 265,
                    backgroundColor: '',
                    image: ' ',
                },
                new_product_package: {
                    h1: 'Workbeam\'s Digital Marketing Packages',
                    h2: 'You select the package and we assign our top-rated marketing expert. Request for samples. Pay in instalments, track work delivery with 100% assurance.',

                }
            }
        case 153:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 112,
                heading_text: {
                    headingText: 'Website Optimisation Services',
                    subheadingText: 'Improve your website to give your visitors the best experience',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.9,
                    userCount: 175,
                    backgroundColor: '',
                    image: ' ',
                },
                new_product_package: {
                    h1: 'Workbeam\'s Digital Marketing Packages',
                    h2: 'You select the package and we assign our top-rated marketing expert. Request for samples. Pay in instalments, track work delivery with 100% assurance.',

                }
            }
        case 154:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 112,
                heading_text: {
                    headingText: 'Website & App Maintenance Services',
                    subheadingText: '',
                    startingPriceText: '',
                    h1: 'Workbeam Standard Social Media Graphics Packages ',
                    h2: 'You select the package and we assign our top rated Social Media Graphics expert. Faster, Simple and affordable!',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.9,
                    userCount: 195,
                    backgroundColor: '',
                    image: ' ',
                },
                new_product_package: {
                    h1: 'Workbeam\'s Digital Marketing Packages',
                    h2: 'You select the package and we assign our top-rated marketing expert. Request for samples. Pay in instalments, track work delivery with 100% assurance.',

                }
            }
        case 172:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 112,
                heading_text: {
                    headingText: 'Game UI Design & Asset Design Services',
                    subheadingText: 'Get Assets, Characters and UI designed for Web and Mobile Games ',
                    startingPriceText: '',
                    h1: 'Game UI Design & Asset Design Services',
                    h2: 'Get Assets, Characters and UI designed for Web and Mobile Games ',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.8,
                    userCount: 75,
                    backgroundColor: '',
                    image: ' ',
                },
                new_product_package: {
                    h1: '',
                    h2: '',

                }
            }
        case 173:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 112,
                heading_text: {
                    headingText: 'Merchandise & Tshirt Design Services',
                    subheadingText: 'Book Merchandise & Tshirt Design Services. Customers and Employees love brand merchandise & goodies',
                    startingPriceText: '',
                    h1: 'Merchandise & Tshirt Design Services',
                    h2: 'Book Merchandise & Tshirt Design Services. Customers and Employees love brand merchandise & goodies',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.7,
                    userCount: 115,
                    backgroundColor: '',
                    image: ' ',
                },
                new_product_package: {
                    h1: '',
                    h2: '',

                }
            }
        case 174:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 112,
                heading_text: {
                    headingText: 'Email Writing Services',
                    subheadingText: 'Good Email Marketing is all about creating high engaging and non spamming email content.',
                    startingPriceText: '',
                    h1: 'Email Writing Services',
                    h2: 'Good Email Marketing is all about creating high engaging and non spamming email content.',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.8,
                    userCount: 195,
                    backgroundColor: ' add-crowsel-2',
                    image: ' ',
                },
                new_product_package: {
                    h1: '',
                    h2: '',

                }
            }
        case 175:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 112,
                heading_text: {
                    headingText: 'Academic Writing Services',
                    subheadingText: 'Explore Academic Writers for content writing services in Education Industry ',
                    startingPriceText: '',
                    h1: 'Academic Writing Services',
                    h2: 'Explore Academic Writers for content writing services in Education Industry ',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.9,
                    userCount: 110,
                    backgroundColor: ' add-crowsel-2',
                    image: ' ',
                },
                new_product_package: {
                    h1: '',
                    h2: '',

                }
            }
        case 176:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 112,
                heading_text: {
                    headingText: 'Business Presentation and Pitch Deck Services',
                    subheadingText: 'Prepare impactful Business Presentations and Decks for your Business',
                    startingPriceText: '',
                    h1: 'Business Presentation and Pitch Deck Services',
                    h2: 'Prepare impactful Business Presentations and Decks for your Business',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.7,
                    userCount: 55,
                    backgroundColor: '',
                    image: ' ',
                },
                new_product_package: {
                    h1: '',
                    h2: '',

                }
            }
        case 177:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 112,
                heading_text: {
                    headingText: 'Multivendor Ecommerce Website Development',
                    subheadingText: 'Develop a multivendor or an aggregator ecommerce website on Workbeam',
                    startingPriceText: '',
                    h1: 'Multivendor Ecommerce Website Development',
                    h2: 'Develop a multivendor or an aggregator ecommerce website on Workbeam',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.9,
                    userCount: 145,
                    backgroundColor: '',
                    image: ' ',
                },
                new_product_package: {
                    h1: '',
                    h2: '',

                }
            }
        case 178:
            return {
                ...headerDetails,
                //product_id: productId,
                parent_practice_area_id: 110,
                heading_text: {
                    headingText: 'Illustrator Graphic Design Services',
                    subheadingText: 'Get creative Illustrations designed from Workbeam\'s verified Illustration Designers',
                    startingPriceText: '',
                    h1: 'Illustrator Graphic Design Services',
                    h2: 'Get creative Illustrations designed from Workbeam\'s verified Illustration Designers',
                    hashTag: '#AapkaApnaExpert',
                    userRating: 4.9,
                    userCount: 145,
                    backgroundColor: '',
                    image: ' ',
                },
                new_product_package: {
                    h1: '',
                    h2: '',

                }
            }
        default:
            return {}
    }

}

