import { onlyIntReg } from './businessDetailsValidation'

export const formErrorMsg = {
    account_holder_name: 'Please enter this field',
    account_number: 'Please enter this field',
    confirm_account_number: 'Please enter this field',
    ifsc_code: 'Please enter this field',
};
export function isPaymentDetailsValid(errors ,request){
    let isAllValid = true;
    for (let key in request) {
        if (key === 'ifsc_code' && !request[key]){
            errors[key] = formErrorMsg[key];
            isAllValid = false
        }
        if (key === 'account_holder_name' && (!request[key])){
            errors[key] = formErrorMsg[key];
            isAllValid = false
        }
        if (key === 'confirm_account_number' && (request[key] !== request['account_number'])){
            errors[key] = `Account no and confirm account don't match.`;
            isAllValid = false
        }
        if (key === 'account_number'){
            if (!(request[key])){
                errors[key] = formErrorMsg[key]
                isAllValid = false
            }
            if (!onlyIntReg.test(request[key])){
                errors[key] = 'Account no is not valid.'
                isAllValid = false
            }
        }
        if (key === 'confirm_account_number' && !(request[key])){
            errors[key] = formErrorMsg[key];
            if (!onlyIntReg.test(request[key])){
                errors[key] = 'Confirm account no is not valid.'
            }
            isAllValid = false
        }

    }
    return isAllValid
}