import React, { Component } from "react";
import socket from './Socket'
import { errorActionDispatchers } from "../../actions/errors";
import connect from "react-redux/es/connect/connect";
import { apiv1 } from "../../api";
import $, { data } from 'jquery'

import {
    getSocketRequestData,
} from "./Message";
import * as apiUrl from "../../api/apiUrls";
import localStorage from '../../utils/localStorage'
import {USER } from "../../constants/auth";
import { messageType } from '../../constants/common'
import moment from "moment";
import { withRouter } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import PickyDateTime from 'react-picky-date-time';
export const HoursList = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
export const MinutesList = ['00','01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '35', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59']

class CallScheduler extends Component {
    constructor(props) {
        super(props)
        let { senderId, receiverUserId, proposalId, jobId, chatRoomId, proposalAmount, jobTitle, product_professional_details, is_super_user } = props
        let { has_professional_messaged } = product_professional_details || {}
        senderId = senderId || ''
        receiverUserId = receiverUserId || ''
        proposalId = proposalId || ''
        jobId = jobId || ''
        chatRoomId = chatRoomId || ''
        this.socket = '';
        let newDate = new Date()
        this.state = {
            messageToSend: '',
            receivedMessage: '',
            senderId,
            receiverUserId,
            proposalId,
            inputMessage: '',
            jobId,
            chatHistory: [],
            page: 1,
            limit: 25,
            newSessionChatHistory: [],
            total: 0,
            loading: false,
            proposalAmount: proposalAmount ? proposalAmount : '',
            jobTitle: jobTitle ? jobTitle : '',
            isEventListenerAdded: false,
            shouldMilestoneRender: false,
            shouldPaymentTermsRender: false,
            chatRoomId,
            chatRoomUsers: [],
            conferenceRequests: [],
            has_professional_messaged,
            isMessageSent: true,
            isSocketConnected: false,
            errors: {},
            jobList: [],
            leadCount: {},
            startDate: moment(),
            endDate: moment(),
            minDate: moment(),
            maxDate: moment().add(0, 'd'),
            // calander 
            showPickyDateTime: true,
            date: newDate.getDate(),
            month: newDate.getMonth() + 1,
            year: newDate.getFullYear(),
            hour: 12,
            minute: 59,
            second: newDate.getSeconds(),
            meridiem: 'AM'
        }
    }


    componentWillReceiveProps(nextProps, nextContext) {
        let { product_professional_details, jobTitle, is_super_user } = nextProps
        jobTitle = jobTitle || ''
        const { chatHistory, newSessionChatHistory } = this.state
        let newState = {
            jobTitle,
            product_professional_details
        }
        if (chatHistory.length === 1 && newSessionChatHistory.length === 0 && localStorage.userType === USER && !is_super_user) {
            newState['inputMessage'] = this.getUserDefaultInputText({ jobTitle })
        }
        this.setState(newState)
    }


    //  calander function 

    onYearPicked(res) {
        const { year } = res;
        this.setState({ year: year });
    }

    onMonthPicked(res) {
        const { month, year } = res;
        this.setState({ year: year, month: month });
    }

    onDatePicked(res) {
        const { date, month, year } = res;
        this.setState({ year: year, month: month, date: date });
    }

    onResetDate(res) {
        const { date, month, year } = res;
        this.setState({ year: year, month: month, date: date });
    }

    onResetDefaultDate(res) {
        const { date, month, year } = res;
        this.setState({ year: year, month: month, date: date });
    }

    onSecondChange(res) {
        this.setState({ second: res.value });
    }

    onMinuteChange(res) {
        this.setState({ minute: res.value });
    }

    onHourChange(res) {
        this.setState({ hour: res.value });
    }

    onMeridiemChange(res) {
        this.setState({ meridiem: res });
    }

    onResetTime(res) {
        this.setState({
            
            second: res.clockHandSecond.value,
            minute: res.clockHandMinute.value,
            hour: res.clockHandHour.value
        });
    }

    onResetDefaultTime(res) {
        this.setState({
            second: res.clockHandSecond.value,
            minute: res.clockHandMinute.value,
            hour: res.clockHandHour.value
        });
    }

    onClearTime(res) {
        this.setState({
            second: res.clockHandSecond.value,
            minute: res.clockHandMinute.value,
            hour: res.clockHandHour.value
        });
    }

    // just toggle your outter component state to true or false to show or hide <PickyDateTime/>
    openPickyDateTime() {
        this.setState({ showPickyDateTime: true });
    }

    onClose() {
        this.setState({ showPickyDateTime: false });
    }
    sendSocketDataViaRoomId = ({data}) => {
        const {type, message} = data
        const {chatRoomId} = this.state
        const request = {
            room: chatRoomId,
            chat: {
                message,
                message_type: type
            }
        }
        apiv1.auth.post(apiUrl.insertSocketData, request)
            .then(resp => {

            })
            .catch(err => {
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    sendToChat = (e) => {
        $('.modal .close').click()
        let { date, month, year, hour, minute, second, meridiem, chann } = this.state;
        month = month.toString()
        let message = "Hi, I have scheduled a call with you on " + date.toString() + ' ' + moment(month).format('MMMM').toString()+ ' '  + year.toString() + " at the time " + hour.toString() + ':' + minute.toString() + meridiem.toString()

        const messageToSend = getSocketRequestData({message, type: messageType.chat_message})
        if (socket && socket.isReady()) {
            socket.socket.send(JSON.stringify(messageToSend))
        } else {
            this.sendSocketDataViaRoomId({data: messageToSend})
        }
    }


    saveSchedule = () => {
        const { date, month, year, hour, minute, second, meridiem, chann } = this.state;
        const { chatRoomId, clientUserId, professionalUserId} = this.props
        let channel = `agora_${clientUserId}_${professionalUserId}`  
        var hoursFinal =  parseInt(hour)
        if(meridiem=== 'PM'){
            hoursFinal+=12
        }
        var request = {
            "call_time": year + '-' + month + '-' + date + 'T' + hoursFinal + ':' + minute + ':' + second + '.929Z',   // "2021-01-05T11:57:56.929Z",
            // "reminder_email_30min_sent": true,
            // "reminder_email_10min_sent": true,
            // "reminder_whats_app_sent": true,
            "channel_name": channel,
            "room": chatRoomId
        }

        apiv1.auth.post(apiUrl.callScheduler+'/', request)
            .then(resp => {
                const data = apiv1.parseAndAutoHandleIssues(resp, this.props.doDispatch);
                if (data) {
                    this.sendToChat()
                } else {
                    this.setState({loading: false})
                }
            })
            .catch(err => {
                this.setState({loading: false});
                apiv1.handleErrors(err, this.props.doDispatch)
            })
    }

    render() {
        const { showPickyDateTime } = this.state;
        return (
            <div id="schedule_call_popup" className="modal fade" role="dialog">
                <div className="modal-dialog top-margin">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button"
                                className="close"
                                data-dismiss="modal">
                                &times;</button>
                            <h4 className="modal-title">Schedule Call</h4>
                        </div>
                        <div className="modal-body" style={{ padding: '10px 15px' }}>
                            <div className="row">
                                <div className="col-sm-7">
                                    <PickyDateTime size="m"
                                                   mode={0}
                                                   show={showPickyDateTime}
                                                   locale="en-us"
                                                   onClose={() => this.setState({ showPickyDateTime: false })}
                                                   onYearPicked={res => this.onYearPicked(res)}
                                                   onMonthPicked={res => this.onMonthPicked(res)}
                                                   onDatePicked={res => this.onDatePicked(res)}
                                                   onResetDate={res => this.onResetDate(res)}
                                                   onSecondChange={res => this.onSecondChange(res)}
                                                   onMinuteChange={res => this.onMinuteChange(res)}
                                                   onHourChange={res => this.onHourChange(res)}
                                                   onMeridiemChange={res => this.onMeridiemChange(res)}
                                                   onResetTime={res => this.onResetTime(res)}
                                                   onClearTime={res => this.onClearTime(res)}
                                                   supportDateRange={[
                                                    moment().format('MM/DD/YYYY'),
                                                    moment().add(1, "year").format('MM/DD/YYYY'),
                                                  ]}/>
                                </div>
                                <p className="visible-xs">&#x00A0;</p>
                                <div className="col-sm-5">
                                    <ul className="list-unstyled">
                                        <li>
                                            <div className="form-group">
                                                <label>Select hour</label>
                                                <select className="form-control" onChange={(e) => {
                                                    const {value} = e.target
                                                    this.setState({ hour: value})
                                                }}>
                                                    <option value={`-1`} disabled={`true`}>Select hour</option>
                                                    {HoursList.map((hourData => {
                                                        return <option value={hourData} key={hourData}
                                                                       selected={hourData === `${hourData}`}>{hourData}</option>
                                                    }))}
                                                </select>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form-group">
                                                <label>Select minute</label>
                                                <select className="form-control"  onChange={(e) => {
                                                    const {value} = e.target
                                                    this.setState({ minute: value})
                                                }}>
                                                    <option value={`-1`} disabled={`true`} >Select minute</option>
                                                    {MinutesList.map((minuteData => {
                                                        return <option value={minuteData} key={minuteData}
                                                                       selected={minuteData === `${minuteData}`}>{minuteData}</option>
                                                    }))}
                                                </select>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form-group">
                                                <label>Select AM/PM</label>
                                                <select className="form-control"  onChange={(e) => {
                                                    const {value} = e.target
                                                    this.setState({ meridiem: value})
                                                }}>
                                                    <option value={`-1`} disabled={`true`}>Select AM/PM</option>
                                                    <option>AM</option>
                                                    <option>PM</option>
                                                </select>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer" style={{textAlign:'center', marginBottom:'15px'}}>
                            <button className="btn btn-save-profile"
                                    onClick={() => {
                                        this.saveSchedule()
                                    }}
                            >Schedule</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { data } = state
    let { professional, client } = data
    professional = professional || {}
    client = client || {}
    const { phone } = localStorage.userType === USER ? client : professional
    let from = phone ? phone.phone : ''
    return {
        from
    }
};

const mapDispatchToProps = (dispatch) => ({
    ...(errorActionDispatchers(dispatch)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CallScheduler))