import moment from "moment";
import { useCallback, useEffect, useState } from "react";

const ExperienceModal = ({ mode, onSubmit, experienceData,  setModalData }) => {
  const [data, setData] = useState({
    company: experienceData?.company ?? '',
    title: experienceData?.title ?? '',
    location: experienceData?.location ?? '',
    companyURL: experienceData?.company_linkedin_profile_url ?? experienceData?.companyURL,
    starts_at: experienceData?.starts_at ? new Date(experienceData?.starts_at) ?? '' : '',
    ends_at: experienceData?.ends_at ? new Date(experienceData?.ends_at) ?? '' : '',
    current: !experienceData?.ends_at ?? '',
    description: experienceData?.description ?? '',
  });
  const [formSubmit, setFormSubmit] = useState(false);

  useEffect(() => {
    console.log("::: experienceData", experienceData)
  },[experienceData])

  const handleSave = () => {
    setFormSubmit(true);
    const {company,
    title,
    location,
    companyURL,
    starts_at,
    ends_at,
    current,
    description} = data;
    if( company && title && location && companyURL && starts_at && (current || ends_at) && description) {
      onSubmit(data);
    }
  };

  const renderError = useCallback((formKey) => {
    return (!data[formKey] && formSubmit) ? <p style={{color: 'red'}}>this is required field</p> : '' 
  }, [formSubmit, data]);
  return (
    <>
      <div className="form-field">
        <div>
          <label>Company Name</label>
        </div>
        <input
          value={data.company || ""}
          onChange={(event) => {
            setData({ ...data, company: event.target.value });
          }}
          type="text"
        />
        {renderError('company')}
      </div>
      <div className="form-field">
        <div>
          <label>Title</label>
        </div>
        <input
          value={data.title || ""}
          onChange={(e) => {
            setData({ ...data, title: e.target.value });
          }}
          type="text"
        />
         {renderError('title')}
      </div>
      <div className="form-field">
        <div>
          <label>Location</label>
        </div>
        <input
          value={data.location || ""}
          onChange={(e) => {
            setData({ ...data, location: e.target.value });
          }}
          type="text"
        />
         {renderError('location')}
      </div>
      <div className="form-field">
        <div>
          <label>Company URL</label>
        </div>
        <input
          value={data.companyURL || ""}
          onChange={(e) => {
            setData({ ...data, companyURL: e.target.value });
          }}
          type="text"
        />
        {renderError('companyURL')}
      </div>
      <div className="d-flex gap-2">
        <div className="form-field">
          <div>
            <label>Starts</label>
          </div>
          <input
            value={data.starts_at || ""}
            onChange={(e) => {
              setData({ ...data, starts_at: e.target.value });
            }}
            type="date"
          />
          {renderError('starts_at')}
        </div>
       {!data.current && <div className="form-field">
          <div>
            <label>Ends</label>
          </div>
          <input
            value={data.ends_at || ""}
            onChange={(e) => {
              setData({ ...data, ends_at: e.target.value });
            }}
            type="date"
          />
          {renderError('ends_at')}
        </div>}
        <div className="form-field">
          <input
            value={data.current || ""}
            onChange={(e) => {
              setData({ ...data, current: e.target.checked });
            }}
            type="checkbox"
            checked ={data.current}
          />
          <label>Current</label>
        </div>
      </div>
      <div className="form-field">
        <div>
          <label>Description</label>
        </div>
        <textarea
          value={data.description || ""}
          onChange={(e) => {
            setData({ ...data, description: e.target.value });
          }}
        />
        {renderError('description')}
      </div>
      <div className="footer-button" style={{ textAlign: "right" }}>
        {mode === "add" && (
          <button onClick={handleSave} className="btn btn-save">
            Add Experience
          </button>
        )}
        {mode === "edit" && (
          <button onClick={handleSave} className="btn btn-save">Update Experience</button>
        )}
        <button className="btn btn-cancel" onClick={() => {setModalData({show: false, key: null})}}>Cancel</button>
      </div>
    </>
  );
};

export default ExperienceModal;
