import React, {Component, Fragment} from "react";
import * as path from '../../constants/path'
// import {footer} from "../AboutUs";
import {errorActionDispatchers} from "../../actions/errors";
import connect from "react-redux/es/connect/connect";
// import SiteHeader from "../SiteHeader";
import {serviceGridData, ServiceGridUi} from "../common/AllOwlCrousel";
// import {parentPracticeAreaSlug, productSlug} from "../common/bannerTextForProducts";
import Link from "react-router-dom/Link";
// import {requestQuote} from "../../constants/keyWord";
import AppDownloadInput from "../common/AppDownloadInput";
import {siteLogoOnSharedMedia} from "../common/bannerTextForProducts";
import Header from "../functional/beforeHire";
import {baseUrl} from "../../constants/common";

export function WizCommunity() {
return(
    <section className="page-contant covid-app-section">
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/testimonial-images/app-banner-covid.png" className="img-responsive hidden-xs hidden-sm"  alt="Tax Service"/>
                </div>
                <div className="col-sm-10 col-md-5 col-md-offset-3 col-sm-offset-1 covid-text-box">
                    <h1 className="scope-heading">Ask Free Questions, share knowledge and stay updated on our Mobile App.</h1>
                    <img src="https://workbeam.blob.core.windows.net/wiz-public-cdn/testimonial-images/community-mokup.png" className="img-responsive visible-xs"/>
                    <p>&#x00A0;</p>
                    <AppDownloadInput isUserApp={true}/>
                    <p>&#x00A0;</p>
                    <ul className="list-inline app-button text-center">
                        <li><a
                            href="https://itunes.apple.com/us/app/wizcounsel/id1458978743?ls=1&mt=8"
                            target="_blank"><img src={require('../../static/images/Ios-Button.png')} alt="Workbeam on iOS"/></a>
                        </li>
                        <li><a
                            href="https://play.google.com/store/apps/details?id=com.blizzcraft.wizuser"
                            target="_blank"><img src={require('../../static/images/Android-Button.png')} alt="Workbeam on android"/></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

)
}

export const serviceGridDataForCovid = [
    {
        name:'Tax and Accounting',
        icon: require("../../static/images/Accounting-icon.png")
    },
    {
        name:'GST',
        icon: require("../../static/images/Gst.png")
    },
    {
        name:'Start A Business',
        icon: require("../../static/images/business-formation-icon.png")
    },
    {
        name:'E-commerce',
        icon: require("../../static/images/ecart-icon.png")
    },
    {
        name:'Design',
        icon: require("../../static/images/coder-icon.png")
    },
    {
        name:'Content & Marketing',
        icon: require("../../static/images/for-marketing.png")
    },

]
export function CovidServiceGridUi({serviceData}) {
    return(
        serviceData.map(serviceUiData => {
            const {name, icon} = serviceUiData
            return(
                <li className="text-center">
                    <div className="icon-box">
                    <img src={icon} alt="Book consultation"/>
                    </div>
                    <h4 className="service-grid-cards-heading">{name}</h4>
                </li>
            )
        })
    )
}
class Covid19Help extends Component {

    constructor(props) {
        super(props);
        const {history} = props
        const {location} = history
        let {pathname} = location
        pathname = pathname || ''
        let pageLink = baseUrl + '/' + pathname.substring(1)
        this.state = {
            loading: false,
            pageLink
        }
    }

    render() {
        const {pageLink} = this.state
        let title = `MSME Help Center | Free Consultations & Services | Workbeam`
        let metaDescription = `MSME Covid help Center by Workbeam. Ask free questions, share business issues, book free consultations and services for your Business.`
        return (
            <Fragment>
                <Header link={pageLink} avatar={siteLogoOnSharedMedia} metaDescription={metaDescription} title={title}/>
                {/*<SiteHeader isServicesDataPassed={false} {...this.props}/>*/}
                <p className="visible-xs">&#x00A0;</p>
                <section className="page-contant">
                    <div className="container-fluid">
                        <div className="jumbotron-box covid-first-section">
                            <div className="col-md-6 col-md-offset-3">
                            <h1 className="text-center help-center-h1">Helping MSMEs <span style={{color:'#ff337d'}}>fight Covid19</span> </h1>
                            <h2 className="text-center help-center-h2">Ask free questions, Join SME Community, book services with Workbeam Experts.</h2>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="page-contant" style={{paddingTop:'0px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <h2 className="heading-sm">Book Free Gigs and Services by WizHeroes</h2>

                                <ul className="list-inline covid-help-ul">
                                    <CovidServiceGridUi serviceData={serviceGridDataForCovid}/>
                                </ul>

                                <a className="btn btn-book-now" href={path.msmeFreeService}>Book Our Free Services</a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="page-contant" style={{marginBottom:'-20px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h2 className="heading-sm text-center">Join our MSME Community and Ask Free Questions</h2>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <WizCommunity/> */}
                <section className="professional-social-ui">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 className="heading-sm text-center">Book Business Services at Discount and be WizAssured<span className="dot-pink">.</span></h1>
                                <p className="hidden-xs">&#x00A0;</p>
                                <ul className="service-grid-cards">
                                    <ServiceGridUi serviceData={serviceGridData}/>
                                </ul>
                                <p>&#x00A0;</p>
                                <div className="clearfix"></div>
                                <div className="panel panel-default quote-panel text-center" style={{backgroundColor:'#e9e1ff'}}>
                                    <div className="panel-body">
                                        <h3 className="heading-sm">Workbeam Learning Center</h3>
                                        <p className="text-primary">Read articles and blogs on various industry topics. Keep learning and keep yourself updated.</p>
                                        <br/>
                                        <Link to={path.categoryPage} className="btn btn-book-now">Read Blogs</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch) => ({
    ...(errorActionDispatchers(dispatch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Covid19Help)